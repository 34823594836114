<template lang="pug">
.datepicker
   .datepicker__box(v-show="isCalendarOpen")
     .columns.has-text-centered.datepicker__month-name
       .column.is-2
         p.datepicker__arrow(@click="decrementMonth") <
       .column
         p.datepicker__arrow {{ monthName }} {{ year }}
       .column.is-2
         p.datepicker__arrow(@click="incrementMonth") >
     .datepicker__weeks
       .columns.has-text-centered
         .column
           p.datepicker__element S
         .column
           p.datepicker__element M
         .column
           p.datepicker__element T
         .column
           p.datepicker__element W
         .column
           p.datepicker__element T
         .column
           p.datepicker__element F
         .column
           p.datepicker__element S
     .columns.has-text-centered.is-marginless(v-for="week in weeks") 
       .column(v-for="day in week") 
         .datepicker__element( 
           @click.prevent="emitChangeDate(day); selectDay(day.getDate()); selectMonth(day.getMonth())"
           :class="{'datepicker__element_selected': day.getDate() === selectedDay && day.getMonth() === selectedMonth && day.getMonth() == month, 'datepicker__element_disabled': day.getMonth() !== month,'datepicker__element_invalid': getDisableStatus(day) && day.getMonth() == month }"
         )
           p.datepicker__day {{ day.getDate() }} 
           
</template>

<script>
export default {
  props: {
    date: { type: String, default: "" },
    shipmntdate: { type: String, default: "" },
    isCalendarOpen: { type: Boolean, default: false }
  },
  data() {
    return {
      year: this.today().getFullYear(),
      month: this.today().getMonth(),
      selectedDay: null,
      selectedMonth: null
    }
  },
  computed: {
    startMonthDate() {
      return new Date(this.year, this.month, 1)
    },
    monthName() {
      return this.startMonthDate.toLocaleString("en", { month: "long" })
    },
    startCalendarDate() {
      const diff = this.startMonthDate.getDay()
      return this.adjustDateWithDays(this.startMonthDate, -diff)
    },

    weeks() {
      return [0, 1, 2, 3, 4, 5].map(weekNumber => {
        const weekStart = this.adjustDateWithDays(this.startCalendarDate, weekNumber * 7)

        return [0, 1, 2, 3, 4, 5, 6].map(weekDay => {
          return this.adjustDateWithDays(weekStart, weekDay)
        })
      })
    }
  },
  created() {
    if (this.date) {
      let selectedDate = new Date(this.date)
      this.selectedDay = selectedDate.getDate()
      this.selectedMonth = selectedDate.getMonth()
    }
  },
  methods: {
    getDisableStatus(day) {
      let disableLimit = new Date(this.shipmntdate + " 00:00:00 ")
      return this.shipmntdate && disableLimit && day < disableLimit
    },
    today() {
      return new Date()
    },
    padDatePart(number) {
      return ("00" + number).slice(-2)
    },
    emitChangeDate(date) {
      const year = date.getFullYear()
      const month = this.padDatePart(date.getMonth() + 1)
      const day = this.padDatePart(date.getDate())

      this.$emit("changeDate", [year, month, day].join("-"))
    },
    adjustDateWithDays(date, days) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days)
    },
    decrementMonth() {
      const date = new Date(this.year, this.month - 1)

      this.month = date.getMonth()
      this.year = date.getFullYear()
    },
    incrementMonth() {
      const date = new Date(this.year, this.month + 1)

      this.month = date.getMonth()
      this.year = date.getFullYear()
    },
    selectDay(day) {
      this.selectedDay = day
    },
    selectMonth(month) {
      this.selectedMonth = month
    }
  }
}
</script>

<style lang="sass">
.datepicker
  float: left

  &__icon
    cursor: pointer
    position: absolute
    z-index: 10
    right: 15px
    top: 7px

  &__box
    min-height: 300px
    width: 300px
    border-right: 1px solid #CBCBCB
    border-left: 1px solid #CBCBCB
    border-bottom: 1px solid #CBCBCB
    z-index: 1
    padding-top: 10px

  &__month-name
    padding: 5px
    font-size: 14px
    color: #161616

  &__arrow
    cursor: pointer

  &__day
    height: 35px
    padding-top: 10px

  &__element
    font-size: 14px
    color: #161616
    cursor: pointer
    width: 35px
    height: 35px

  &__element_disabled
    color: #ffffff
    pointer-events: none

  &__element_selected
    background-color: #9A8C8D
    color: white
    border-radius: 50%
  &__element_invalid
    background-color: #DCDCDC
    color: white
    border-radius: 50%
    pointer-events: none

  &__weeks
    margin-top: -10px
</style>
