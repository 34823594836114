<template>
  <div class="category-select-list">
    <div
      ref="tags"
      class="category-select-list__tags"
      :class="{
        'category-select-list__active': isOpen,
        'category-select-list__disabled': disabled
      }"
      @click.stop="activate()"
    >
      <span
        class="category-select-list__placeholder-text"
        :style="isOpen || (selected && selected.size > 0) ? 'color: #4A8A5D;' : 'color: #BFBFBF;'"
      >
        {{ tagValue ? tagValue : placeholder }}
      </span>
      <a-icon
        :type="isOpen ? 'caret-up' : 'caret-down'"
        :style="isOpen ? 'color: #4A8A5D;' : 'color: #8C8C8C;'"
        class="category-select-list__right-arrow"
      />
    </div>
    <transition>
      <div v-if="isOpen" ref="list" tabindex="-1" class="category-select-list__content-wrapper">
        <div v-if="list !== undefined && list.length > 0" style="padding-left: 10px;">
          <a-row
            v-for="(item, index) in list"
            :key="`category_select_${index}`"
            style="margin-bottom: 6px;"
          >
            <a-checkbox
              v-model="item.isChecked"
              @change="selectItem(item)"
              @click.stop="clickCheckBox"
            >
              <span style="text-transform: uppercase;">{{ item.description }}</span>
            </a-checkbox>
          </a-row>
        </div>
        <a-row>
          <a-checkbox
            v-model="isNewChecked"
            style="margin-left: 10px;"
            @change="selectNewAdded"
            @click.stop="clickCheckBox"
          />
          <a-input
            v-model="newValue"
            :placeholder="$t('Admins.AmdinMessage.addCategory')"
            style="width: 200px; margin-left: 12px;"
            @click.stop
            @blur="addNewItem"
          />
        </a-row>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: { type: String, default: () => "" },
    selectedData: { type: Array, default: () => [] }
    // list: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      isOpen: false,
      optimizedHeight: 228,
      selected: new Set(),
      newValue: "",
      isNewChecked: false,
      newAdded: {},
      tagValue: undefined
    }
  },
  computed: {
    list() {
      let data = this.$store.getters["reference/messageCategory"]
      console.log(data)
      if (data !== undefined && data.length > 0) {
        let selectData = undefined
        if (this.selected && this.selected.size > 0) {
          selectData = [...this.selected][0]
        }
        data.forEach(item => {
          console.log(item)
          if (selectData && item.id === selectData.id) {
            item.isChecked = true
          } else {
            item.isChecked = false
          }
        })

        return data
      }
      return []
    }
  },
  // watch: {
  //   list(newValue) {
  //     console.log("..... new value.....")
  //     if (newValue) {
  //       this.dataList = newValue
  //     }
  //   }
  // },
  created() {
    console.log("....get category data....")
    this.getData()
  },
  methods: {
    deActivate() {
      this.isOpen = false
    },
    activate() {
      /* istanbul ignore else */
      if (this.disabled) return
      if (this.isOpen) {
        this.isOpen = false
        this.$emit("close")
      } else {
        this.isOpen = true
        this.$emit("open")
      }
    },
    clickCheckBox() {
      console.log(".....click check box.....")
      this.$emit("isClickCheckbox", true)
    },
    getData() {
      //this.$store.dispatch("adminMessage/fetchCategorys")
      this.$store.dispatch("reference/setReference")
    },
    selectItem(item) {
      console.log(item)
      console.log(this.selected)
      if (this.selected.size > 0) {
        for (var data of this.selected) {
          data.isChecked = false
        }
      }
      this.selected.clear()
      this.selected.add(item)
      this.setTagValue()
      this.$forceUpdate()
      this.$emit("onChange", [...this.selected][0])
      this.deActivate()
    },
    setTagValue() {
      if (this.selected && this.selected.size > 0) {
        let data = [...this.selected]
        this.tagValue = data[0].description
      } else {
        this.tagValue = undefined
      }
    },
    addNewItem() {
      console.log(".... add new item.....")
      console.log(this.newValue)
      if (this.newValue && this.newValue.length > 0) {
        // let data = this.$store.getters["reference/messageCategory"]
        let data = []
        data.push({ name: this.newValue, isActive: true, id: this.newValue })
        let param = { messageCategorys: data }
        this.$store
          .dispatch("reference/updateMessageCategory", param)
          .then(() => {
            this.$message.success("Save new Category Success!")
            // this.newAdded.isChecked = true
            // this.selectItem(this.newAdded)
            // // new value add into the dataList
            // // clean the newValue, user can add another new item
            // this.isNewChecked = false
            this.newValue = undefined
            // this.getData()
          })
          .catch(err => {
            this.$message.error(err.toString())
          })
      }
    },
    selectNewAdded() {
      if (this.isNewChecked) {
        if (!this.newValue || this.newValue.length === 0) {
          this.$message.error("please input the new category name, then can choose it.")
          this.isNewChecked = false
        } else {
          this.$message.info("waiting add the category")
          this.isNewChecked = false
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.category-select-list {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 35px;
  text-align: left;
  color: #35495e;
  z-index: 1000;
  &__tags {
    min-height: 35px;
    display: block;
    padding: 8px 40px 8px 8px;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
    background: #fff;
    font-size: 14px;
    line-height: 22px;
    color: #bfbfbf;
  }
  &__placeholder-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    word-wrap: break-word;
    word-break: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__active {
    border: 1px solid #4a8a5d;
  }
  &__disabled {
    border: 1px solid #bfbfbf;
  }
  &__right-arrow {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 20px;
  }
  &__content-wrapper {
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-top: none;
    border-radius: 0px 0px 2px 2px;
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    z-index: 50;
    box-sizing: border-box;
    padding: 10px;
  }
}
</style>
