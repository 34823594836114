import CoreEntity from "@/models/CoreEntity";
export class FragranceNote extends CoreEntity {
    constructor(model) {
        super(model);
        this.name = '';
        this.isActive = 0;
        this.fragranceNoteAttributes = [];
        this.productFragranceNotes = [];
        if(model) {
            this.name = model.name;
            this.isActive = model.isActive
            this.fragranceNoteAttributes = model.fragranceNoteAttributes;
            this.productFragranceNotes = model.productFragranceNotes.map(n => new ProductFragranceNote(n));
        }
    }
}
export class ProductFragranceNote {
    constructor(model) {
        if(model) {
            this.privateId = model.privateId;
            this.productPrivateId = model.productPrivateId;
            this.fragranceNotePrivateId = model.fragranceNotePrivateId;
            this.fragranceNoteType = model.fragranceNoteType;
            this.createdAt = model.createdAt ? new Date(model.createdAt) : null;
            this.createdBy = model.createdBy || null;
        }
    }
}
export const FragranceNoteType = {
    Top: 1,
    Heart: 2,
    Base: 3
}