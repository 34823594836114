<template>
  <a-row class="comment-item">
    <a-col
      :span="2"
      :class="
        comment.createdBy === userId ? 'comment-item__float-left' : 'comment-item__float-right'
      "
      class="comment-item__header"
    >
      <a-avatar class="comment-item__header-avarta" size="large" style="background: #4a8a5d;">
        <img v-if="userHead" slot="icon" :src="userHead" />
      </a-avatar>
    </a-col>
    <a-col
      :span="22"
      :class="
        comment.createdBy === userId ? 'comment-item__float-right' : 'comment-item__float-left'
      "
      style="padding-right: 10px;padding-left: 4px;"
    >
      <a-col
        :span="20"
        :class="
          comment.createdBy === userId ? 'comment-item__float-left' : 'comment-item__float-right'
        "
      >
        <div
          :class="
            comment.createdBy === userId
              ? 'comment-item__message-right'
              : 'comment-item__message-left'
          "
          :style="
            member && member.type && member.type.id
              ? member.type.id === 'brand'
                ? 'background: rgba(74, 138, 93, 0.11);border: 1px solid rgba(74, 138, 93, 0.11)'
                : 'background: #fef2e2;border: 1px solid #fef2e2'
              : 'background: #F5F5F5;border: 1px solid #F5F5F5'
          "
          class="comment-item__message"
          v-html="comment.message"
        >
          <!--          {{ comment.message }}-->
        </div>
        <div
          class="comment-item__message-date"
          :style="comment.createdBy === userId ? 'text-align: left;' : 'text-align: right;'"
        >
          {{ humanReadableDate(comment.createdAt) }}
        </div>
      </a-col>
      <a-col
        :span="4"
        :class="
          comment.createdBy === userId ? 'comment-item__float-right' : 'comment-item__float-left'
        "
      ></a-col>
    </a-col>
  </a-row>
</template>
<script>
export default {
  props: {
    brand: { type: Object, default: () => {} },
    retailer: { type: Object, default: () => {} },
    comment: { type: Object, default: () => undefined }
  },
  data() {
    return {
      member: null,
      userHead: undefined
    }
  },
  computed: {
    userId() {
      return this.$store.getters["user/id"]
    }
  },
  watch: {
    comment(newValue) {
      if (newValue) {
        this.member = this.commentAuthor(newValue)
        this.setUserHeadUrl()
      }
    }
  },
  created() {
    if (this.comment) {
      this.member = this.commentAuthor(this.comment)
      console.log(this.member)
      this.setUserHeadUrl()
    }
  },
  methods: {
    setUserHeadUrl() {
      if (this.member && this.member.logoAsset.url) {
        this.userHead = this.member.logoAsset.url
      }
    },
    commentAuthor(item) {
      let result = {}
      let clientId = item.createdBy
      let retailerMembers = this.retailer.members || []
      let brandMembers = this.brand.members || []
      result = retailerMembers.find(member => member.user.id == clientId)
      if (result) {
        return this.retailer
      }
      result = brandMembers.find(member => member.user.id == clientId)
      if (result) {
        return this.brand
      }
      return { name: "Landing Admin", logoAsset: {} }
    },
    humanReadableDate(isoString) {
      const date = new Date(isoString)
      const time = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false
      })
      const month = date.toLocaleString("en-US", { month: "short" })
      const week = date.toLocaleString("en-US", { weekday: "short" })
      return `${week},${date.getDate()} ${month} ${date.getFullYear()} ${time}`
    }
  }
}
</script>
<style lang="scss" scoped>
.comment-item {
  width: 100%;
  margin-bottom: 29px;
  &__header {
    height: 100%;
    display: inline-block;
  }
  &__header-avarta {
    position: absolute;
    bottom: 30px;
  }
  &__float-left {
    float: right;
  }
  &__float-right {
    float: left;
  }
  &__message {
    min-height: 80px;
    padding: 20px;
  }
  &__message-left {
    width: 100%;
    border-radius: 12px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  &__message-right {
    width: 100%;
    border-radius: 12px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  &__message-date {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.13125px;
    color: #c4c4c4;
  }
}
</style>
