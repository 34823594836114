<template>
  <div class="retailers-az">
    <div class="name">
      <div class="text__title">All Retailers</div>
    </div>
    <div class="letter-bar">
      <div class="filter-item" @click="fetchRetailers('')">
        <a :class="{ 'dot': retailersStartsWith == '' }">All</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('A')">
        <a :class="{ 'dot': retailersStartsWith == 'A' }">A</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('B')">
        <a :class="{ 'dot': retailersStartsWith == 'B' }">B</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('C')">
        <a :class="{ 'dot': retailersStartsWith == 'C' }">C</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('D')">
        <a :class="{ 'dot': retailersStartsWith == 'D' }">D</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('E')">
        <a :class="{ 'dot': retailersStartsWith == 'E' }">E</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('F')">
        <a :class="{ 'dot': retailersStartsWith == 'F' }">F</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('G')">
        <a :class="{ 'dot': retailersStartsWith == 'G' }">G</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('H')">
        <a :class="{ 'dot': retailersStartsWith == 'H' }">H</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('I')">
        <a :class="{ 'dot': retailersStartsWith == 'I' }">I</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('J')">
        <a :class="{ 'dot': retailersStartsWith == 'J' }">J</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('K')">
        <a :class="{ 'dot': retailersStartsWith == 'K' }">K</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('L')">
        <a :class="{ 'dot': retailersStartsWith == 'L' }">L</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('M')">
        <a :class="{ 'dot': retailersStartsWith == 'M' }">M</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('N')">
        <a :class="{ 'dot': retailersStartsWith == 'N' }">N</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('O')">
        <a :class="{ 'dot': retailersStartsWith == 'O' }">O</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('P')">
        <a :class="{ 'dot': retailersStartsWith == 'P' }">P</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('Q')">
        <a :class="{ 'dot': retailersStartsWith == 'Q' }">Q</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('R')">
        <a :class="{ 'dot': retailersStartsWith == 'R' }">R</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('S')">
        <a :class="{ 'dot': retailersStartsWith == 'S' }">S</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('T')">
        <a :class="{ 'dot': retailersStartsWith == 'T' }">T</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('U')">
        <a :class="{ 'dot': retailersStartsWith == 'U' }">U</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('V')">
        <a :class="{ 'dot': retailersStartsWith == 'V' }">V</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('W')">
        <a :class="{ 'dot': retailersStartsWith == 'W' }">W</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('X')">
        <a :class="{ 'dot': retailersStartsWith == 'X' }">X</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('Y')">
        <a :class="{ 'dot': retailersStartsWith == 'Y' }">Y</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('Z')">
        <a :class="{ 'dot': retailersStartsWith == 'Z' }">Z</a>
      </div>
      <div class="filter-item" @click="fetchRetailers('#')">
        <a :class="{ 'dot': retailersStartsWith == '#' }">#</a>
      </div>
    </div>
    <div class="directory">
      <ul v-if="retailers.filter(retailer => retailer.name.charAt(0).match(/^[0-9]+$/)).length">
        <li>#</li>
        <li v-for="retailer in retailers.filter(retailer => retailer.name.charAt(0).match(/^[0-9]+$/))" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <span @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </span>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailers.filter(retailer => retailer.name.charAt(0).match(/^[0-9]+$/)).length==0 && retailersStartsWith=='#' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('A').length">
        <li>A</li>
        <li v-for="retailer in retailersGroup('A')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('A').length==0 && retailersStartsWith=='A' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('B').length">
        <li>B</li>
        <li v-for="retailer in retailersGroup('B')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('B').length==0 && retailersStartsWith=='B' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('C').length">
        <li>C</li>
        <li v-for="retailer in retailersGroup('C')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('C').length==0 && retailersStartsWith=='C' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('D').length">
        <li>D</li>
        <li v-for="retailer in retailersGroup('D')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('D').length==0 && retailersStartsWith=='D' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('E').length">
        <li>E</li>
        <li v-for="retailer in retailersGroup('E')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('E').length==0 && retailersStartsWith=='E' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('F').length">
        <li>F</li>
        <li v-for="retailer in retailersGroup('F')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('F').length==0 && retailersStartsWith=='F' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('G').length">
        <li>G</li>
        <li v-for="retailer in retailersGroup('G')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('G').length==0 && retailersStartsWith=='G' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('H').length">
        <li>H</li>
        <li v-for="retailer in retailersGroup('H')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('H').length==0 && retailersStartsWith=='H' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('I').length">
        <li>I</li>
        <li v-for="retailer in retailersGroup('I')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('I').length==0 && retailersStartsWith=='I' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('J').length">
        <li>J</li>
        <li v-for="retailer in retailersGroup('J')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('J').length==0 && retailersStartsWith=='J' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('K').length">
        <li>K</li>
        <li v-for="retailer in retailersGroup('K')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('K').length==0 && retailersStartsWith=='K' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('L').length">
        <li>L</li>
        <li v-for="retailer in retailersGroup('L')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('L').length==0 && retailersStartsWith=='L' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('M').length">
        <li>M</li>
        <li v-for="retailer in retailersGroup('M')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('M').length==0 && retailersStartsWith=='M' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('N').length">
        <li>N</li>
        <li v-for="retailer in retailersGroup('N')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('N').length==0 && retailersStartsWith=='N' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('O').length">
        <li>O</li>
        <li v-for="retailer in retailersGroup('O')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('O').length==0 && retailersStartsWith=='O' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('P').length">
        <li>P</li>
        <li v-for="retailer in retailersGroup('P')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('P').length==0 && retailersStartsWith=='P' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('Q').length">
        <li>Q</li>
        <li v-for="retailer in retailersGroup('Q')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('Q').length==0 && retailersStartsWith=='Q' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('R').length">
        <li>R</li>
        <li v-for="retailer in retailersGroup('R')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('S').length==0 && retailersStartsWith=='R' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('S').length">
        <li>S</li>
        <li v-for="retailer in retailersGroup('S')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('S').length==0 && retailersStartsWith=='S' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('T').length">
        <li>T</li>
        <li v-for="retailer in retailersGroup('T')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('T').length==0 && retailersStartsWith=='T' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('U').length">
        <li>U</li>
        <li v-for="retailer in retailersGroup('U')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('U').length==0 && retailersStartsWith=='U' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('V').length">
        <li>V</li>
        <li v-for="retailer in retailersGroup('V')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('V').length==0 && retailersStartsWith=='V' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('W').length">
        <li>W</li>
        <li v-for="retailer in retailersGroup('W')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('W').length==0 && retailersStartsWith=='W' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('X').length">
        <li>X</li>
        <li v-for="retailer in retailersGroup('X')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('X').length==0 && retailersStartsWith=='X' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('Y').length">
        <li>Y</li>
        <li v-for="retailer in retailersGroup('Y')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('Y').length==0 && retailersStartsWith=='Y' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
      <ul v-if="retailersGroup('Z').length">
        <li>Z</li>
        <li v-for="retailer in retailersGroup('Z')" :key="retailer.id">
          <div class="retailer-item" v-if="isAdmin">
            <router-link :to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}">
              {{ retailer.name }}
            </router-link>
          </div>
          <div class="retailer-item" v-else>
            <a @click="goToRetailerProfile(retailer)">
              {{ retailer.name }}
            </a>
          </div>
        </li>
      </ul>
      <a class="no-retailers" v-if="retailersGroup('Z').length==0 && retailersStartsWith=='Z' && !fetchInProgress">
        <a-empty description="No Retailers"/>
      </a>
    </div>
    <BrandTableDialog ref="retailerAZUpgradePlanDialog" :brandType="brandType"/>
  </div>

</template>

<script>
import constants from "@/config"
import BrandTableDialog from "@/components/brands/mainPage/components/BrandTableDialog"
export default {
  components: {
    BrandTableDialog
  },
  data() {
    return {
      laoding: true,
      query: { _limit: "1" },
      fetchInProgress: false,
      retailersStartsWith: ''
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandType() {
      return (
          this.brand.subscriptions &&
          this.brand.subscriptions[0].type &&
          this.brand.subscriptions[0].type.id
      )
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isAdminBrandrRoute() {
      return constants.adminBrandRoutes.includes(this.$route.name)
    },
    retailers() {
      let retailerArray = this.$store.getters["retailer/retailers"]
      return retailerArray.filter(retailer => this.selectedRetailersGroup(retailer) === true)
    }
  },
  async created() {
    if (this.isAdmin) {
      this.query["_jwt-c_id"] = this.$route.params.retailerId
      this.query["_jwt-c_type"] = "retailer"
    }
    const brandId = this.$store.getters["user/businessId"]
    if (brandId) {
      this.$store.dispatch("brand/getBrand", brandId)
    }
    this.fetchRetailers('')
  },
  methods: {
    async fetchRetailers(startWord) {
      this.retailersStartsWith=startWord
      try {
        this.loading = true
        this.fetchInProgress = true
        await this.$store.dispatch("retailer/fetchAllRetailers", {
          params: { status: "approved" }
        })
        this.loading = false
        this.fetchInProgress = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    selectedRetailersGroup(retailer) {
      if (this.retailersStartsWith == "#") {
        if (retailer.name.charAt(0).match(/^[0-9]+$/)) {
          return true
        }
      }
      if (
        retailer.name.startsWith(this.retailersStartsWith.toUpperCase()) ||
        retailer.name.startsWith(this.retailersStartsWith.toLowerCase())
      ) {
        return true
      }
      return false
    },
    showUpdatePlanModal() {
      this.$refs["retailerAZUpgradePlanDialog"].openDialog()
    },
    goToRetailerProfile(retailer) {
      if (this.brandType === 'basic') {
        this.showUpdatePlanModal()
      } else {
        this.$router.push({
          name: "brandRetailerProfile",
          params: {
            retailerId: retailer.id
          }
        })
      }
    },
    retailersGroup(alphabet) {
      return this.retailers.filter(
        retailer =>
          retailer.name.startsWith(alphabet.toUpperCase()) ||
          retailer.name.startsWith(alphabet.toLowerCase())
      )
    }
  }
}
</script>

<style lang="sass" scoped>
@font-face
  font-family: "Proxima Nova"
  src: url("../../../assets/fonts/Proxima-Nova-Light.otf") format("truetype")

.retailer-az
  width: 100%

.name
  display: flex
  // background-color: green

.letter-bar
  padding-bottom: 18px
  min-width: 597px
  border-bottom: 1px solid #ddd
  display: flex
  align-items: center
  flex-wrap: wrap
.filter-item
  font-family: Proxima
  font-size: 14px
  cursor: pointer

  a
    font-size: 12px
    margin: 6px
    color: #8C8C8C
    position: relative
    font-weight: 600
    text-transform: uppercase
    &:hover
      text-decoration: underline
.filter-item:nth-child(1) a
  display: inline-block
  width: 30px !important
  height: 30px !important
  border-radius: 50%
.directory
  flex-wrap: wrap
  display: flex
  align-items: flex-start
  width: 100%
  height: 100%
  padding: 20px 0px
  min-width: 597px
  // background-color: yellow

  ul
    list-style: none
    padding: 0
    margin: 0 33px 30px
    flex-basis: 15%

    li
      color: #000000
      a
        font-size: 12px
        color: #000
        font-weight: 300
        &:hover
          text-decoration: underline
  ul:nth-child(4n)
    margin-right: 0
  ul:nth-child(4n-3)
    margin-left: 0

.text
  &__title
    font-size: 48px
    font-weight: 650

.dot
  color: white !important
  height: 40px !important
  width: 40px !important
  padding: 5px !important
  box-sizing: border-box
  background-color: #4a8a5d !important
  border-radius: 25px !important
.loading_wrap
  width: 100%
  display: flex
  justify-content: center
.text__title
  font-family: Playfair Display
  font-weight: bold
  font-size: 48px
  color: #262626
.retailer-item
  a
    font-family: "Proxima Nova"
    color: #000000
    font-weight: 400
    color: #000000
</style>
