<template lang="pug">
.memberss__wrap
    .members-details-table
        .members-details-table-header
            .column.members__title(v-if="members.length > 1") {{ members.length }} {{$t('Retailers.RetailerSettings.members')}}
            .column.members__title(v-else) {{$t('Retailers.RetailerSettings.oneMember')}}
            .column.invite_member(v-if="isOwner")
                .button(@click="onClickRequestInvite()") {{$t('Retailers.RetailerSettings.requestInvitations')}}
        .members-details-table-body
            .table-header
                .column
                    p.brand-settings__account-information_text {{$t('Retailers.RetailerSettings.name')}}
                .column
                    p.brand-settings__account-information_text {{$t('Retailers.RetailerSettings.email')}}
                .column
                    p.brand-settings__account-information_text {{$t('Retailers.RetailerSettings.title')}}
                .column
                    p.brand-settings__account-information_text {{$t('Retailers.RetailerSettings.phoneNumber')}}
                .column
                    p.brand-settings__account-information_text {{$t('Retailers.RetailerSettings.status')}}
                .column.is-1

            .table-body
                MemberItem(
                @addedMember="addedMember"
                :isOwner="isOwner"
                :member="retailerMembers"
                :key="index"
                v-if="retailerMembers.length")

    InviteMemberModal(
      v-if="showRequestInvite"
      :showInviteMember="showRequestInvite" 
      @inviteMemberModalClose="closeInviteMemberModal"
      @addedMember="addedMember"
      @onClickClose="showRequestInvite=false")
      
    MemberInvitedModal(
      v-if="successfullyAddedMember"
      :showMemberInvited="successfullyAddedMember"
      :memberAdded="memberAdded"
      @memberInvitedModalClose="memberInvitedModalClose"
      @inviteMorePeople="inviteMorePeople")

</template>

<script>
import InviteMemberModal from "@/components/shared/InviteMemberModal"
import MemberInvitedModal from "@/components/shared/MemberInvitedModal"
import MemberItem from "@/components/brands/settings/MemberItem"

export default {
  components: {
    InviteMemberModal,
    MemberItem,
    MemberInvitedModal
  },
  data() {
    return {
      retailerMembers: [],
      showRequestInvite: false,
      showMemberActions: false,
      successfullyAddedMember: false,
      memberAdded: {},
      isOwner: false
    }
  },
  computed: {
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    members() {
      let data = (this.retailer && this.retailer.members) || []
      if (data.length > 0) {
        data = data.filter(item => {
          if (item && item.membershipStatus) {
            return item.membershipStatus.id !== "delete"
          }
          return true
        })
      }
      return data
    },
    userId() {
      return this.$store.getters["user/id"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  async created() {
    const retailerId =
      this.$route.name == "adminRetailerSettings"
        ? this.$route.params.retailerId
        : this.$store.getters["user/businessId"]
    await this.$store.dispatch("retailer/fetchRetailer", retailerId)
    var retailer = this.$store.getters["retailer/retailer"]
    this.retailerMembers = retailer.members ? [...retailer.members] : []
    this.retailerMembers.sort(function(a, b) {
      var c = new Date(a.createdAt)
      var d = new Date(b.createdAt)
      return c - d
    })
    let members = retailer.members
    let owner = members.find(member => member.role.id == "owner")
    if (this.userId == owner.user.id || this.isAdmin) {
      this.isOwner = true
    }
  },
  methods: {
    addedMember(member) {
      this.fetchRetailer()
      this.successfullyAddedMember = true
      this.memberAdded = member
    },
    async fetchRetailer() {
      const retailerId =
        this.$route.name == "adminRetailerSettings"
          ? this.$route.params.retailerId
          : this.$store.getters["user/businessId"]
      await this.$store.dispatch("retailer/fetchRetailer", retailerId)
      this.retailerMembers = this.retailer.members
      this.retailerMembers.sort(function(a, b) {
        var c = new Date(a.createdAt)
        var d = new Date(b.createdAt)
        return c - d
      })
    },
    onClickRequestInvite() {
      this.showRequestInvite = true
    },
    closeInviteMemberModal() {
      this.showRequestInvite = false
    },
    memberInvitedModalClose() {
      this.successfullyAddedMember = false
    },
    inviteMorePeople() {
      this.successfullyAddedMember = false
      this.showRequestInvite = true
    }
  }
}
</script>

<style lang="sass"></style>
