import axios from "@/api/axios"

export default {
  signUp(payload) {
    const role = Object.keys(payload).find(key => key !== "user")
    return axios.post(`/${role}s/registration`, JSON.stringify(payload))
  },
  sendPasswordRecoveryLink(email) {
    const params = { mode: "request", type: "resetToken", email }
    return axios.post("/auth/action", null, { params })
  },
  sendPasswordRecoveryLinkForLegacyUser(emailHash) {
    const params = { mode: "request", type: "resetToken", id: emailHash }
    return axios.post("/auth/action", null, { params })
  },
  sendEmailVerifyLink(email) {
    const params = { mode: "request", type: "verificationToken", email }
    return axios.post("/auth/action", null, { params })
  },
  resetPassword(token, password) {
    const params = { mode: "request", type: "reset", token }
    const body = { password }

    return axios.post("/auth/action", body, { params })
  },
  verifyEmail(token) {
    const params = { mode: "verify", type: "email", token }
    return axios.post("/auth/action", null, { params })
  },
  verifyResetToken(token) {
    const params = { mode: "verify", type: "reset", token }
    return axios.post("/auth/action", null, { params })
  },
  fetch(userId) {
    return axios.get(`/users/${userId}`)
  },
  update(user, userId) {
    return axios.patch(`/users/${userId}`, JSON.stringify(user))
  },
  updateUser(user, userId) {
    return axios.patch(`/users/${userId}/admin`, JSON.stringify(user))
  },
  checkMember(brandId) {
    return axios.get(`/brands/${brandId}/check-members`)
  }
}
