<template>
  <div ref="brandRRCodeDialog" class="brand-qr-code-download__main">
    <a-modal
      title="Share Link"
      :visible="visible"
      :footer="null"
      :get-container="() => $refs.brandRRCodeDialog"
      @cancel="cancel"
    >
      <div class="qr-code-content-modal">
        <div id="qrcodeText" ref="QRCODE" style="width:150px; height:150px;"></div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2"

export default {
  name: "BrandQRCode",
  props: {
    url: { type: String, default: "" }
  },
  data() {
    return {
      visible: false,
      qrUrl: ""
    }
  },
  watch: {
    visible(newVisible) {
      if (newVisible) {
        this.$nextTick(function() {
          this.openDownloadQR()
        })
      }
    }
  },
  methods: {
    openDownloadQR() {
      this.visible = true
      // const text = `${this.url}`
      // const qr = new QRCode(document.getElementById("qrcodeText"), {
      //   text: "vvv.sasd",
      //   width: "100px",
      //   height: "100px",
      //   colorDark: "#000000",
      //   colorLight: "#ffffff",
      //   correctLevel: QRCode.CorrectLevel.H
      // })
      new QRCode(document.getElementById("qrcodeText"), this.url)
    },
    cancel() {
      document.getElementById("qrcodeText").innerHTML = ""
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.brand-qr-code-download__main {
  .qr-code-content-modal {
    margin-left: 156px;
  }
}
</style>
