<template>
  <div class="admin-system-promocode-history">
    <div style="margin-bottom: 10px;">
      <a-button icon="arrow-left" @click="goBack" />
    </div>
    <a-table
      :columns="columns"
      :data-source="couponList.list"
      class="admin-system-promocode"
      :pagination="pagination"
    >
      <span slot="brandNameTitle">
        {{ $t("Admins.AdminSystem.brandName") }}
        <a-icon
          type="caret-up"
          style="margin-left: 10px;"
          :rotate="isSortAsc ? 0 : 180"
          @click="doSortByBrandName"
        />
      </span>
      <span slot="brandName" slot-scope="text">
        <a-tooltip
          v-if="text !== undefined && text.length > 18"
          placement="bottomLeft"
          :title="text"
        >
          <span>{{ text }}</span>
        </a-tooltip>
        <span v-else>{{ text }}</span>
      </span>
      <span slot="promoCode" slot-scope="text">
        <a-tooltip
          v-if="text !== undefined && text.length > 9"
          placement="bottomLeft"
          :title="text"
        >
          <span>{{ text }}</span>
        </a-tooltip>
        <span v-else>{{ text }}</span>
      </span>
      <span slot="usedDate" slot-scope="text">
        {{ formatDate(text) }}
      </span>
      <span slot="startDate" slot-scope="text">
        {{ formatDate(text) }}
      </span>
      <span slot="expirationDate" slot-scope="text">
        {{ formatDate(text) }}
      </span>
      <span slot="promoCodeValue" slot-scope="text, record">
        {{ record.percentage ? `-${text}%` : `-${priceFormat(text)}` }}
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          // title: this.$t('Admins.AdminSystem.brandName'),
          dataIndex: "clientName",
          key: "clientName",
          ellipsis: true,
          slots: { title: "brandNameTitle" },
          scopedSlots: { customRender: "brandName" },
          width: "18%"
        },
        {
          title: this.$t("Admins.AdminSystem.usedDate"),
          dataIndex: "createdAt",
          key: "createdAt",
          scopedSlots: { customRender: "usedDate" },
          width: "12%"
        },
        {
          title: this.$t("Admins.AdminSystem.promoCode"),
          dataIndex: "couponCode",
          key: "couponCode",
          ellipsis: true,
          scopedSlots: { customRender: "promoCode" },
          width: "13%"
        },
        {
          title: this.$t("Admins.AdminSystem.promoCodeValue"),
          dataIndex: "amount",
          key: "amount",
          scopedSlots: { customRender: "promoCodeValue" },
          width: "13%"
        },
        {
          title: this.$t("Admins.AdminSystem.startDate"),
          dataIndex: "recordStartTime",
          key: "recordStartTime",
          scopedSlots: { customRender: "startDate" },
          width: "13%"
        },
        {
          title: this.$t("Admins.AdminSystem.expirationDate"),
          dataIndex: "recordEndTime",
          key: "recordEndTime",
          scopedSlots: { customRender: "expirationDate" },
          width: "15%"
        }
      ],
      isSortAsc: false,
      pageSize: 50,
      sortParam: {},
      pagination: {
        onChange: page => {
          this.getHistoryData(page)
        },
        size: "small",
        pageSize: 50,
        total: 0
      }
    }
  },
  computed: {
    couponList() {
      return this.$store.getters["adminConsole/couponHistoryData"]
    }
  },
  watch: {
    couponList() {
      this.pagination.total = this.couponList.total
    }
  },
  created() {
    this.getHistoryData(1)
  },
  methods: {
    doSortByBrandName() {
      this.isSortAsc = !this.isSortAsc
      this.sortParam = {
        sortByBrandName: this.isSortAsc
      }
      this.getHistoryData(1)
    },
    getBaseParam(page) {
      return {
        _limit: this.pageSize,
        "page-index": page
      }
    },
    getHistoryData(page) {
      let param = Object.assign({}, this.getBaseParam(page), this.sortParam)
      this.$store.dispatch("adminConsole/getCouponHistory", param)
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-system-promocode-history {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
</style>
<style lang="scss">
.admin-system-promocode .ant-pagination-item-active {
  border-color: #4a8a5d;
}
.admin-system-promocode .ant-pagination-item-active a {
  color: #4a8a5d;
}
.admin-system-promocode-history .ant-btn:hover,
.admin-system-promocode-history .ant-btn:focus,
.admin-system-promocode-history .ant-btn:active {
  color: #4a8a5d;
  border-color: #4a8a5d;
}
</style>
