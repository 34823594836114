<template lang="pug">
layout
  .reset--section--wrapper
    .reset--section--inner
      .reset--section--container
        p.reset--section--header
          span.reset--section--header__red Welcome
          span back!
        p.reset--section--content {{ content }}
        p.reset--section--content {{ content1 }}
        p.reset--footer--content
          span {{ text }}
          a(href="#" @click="sendPasswordRecovery") resend email
      .image--group
        img.left--dots--group(src='@/assets/webflowAssets/Reset/left_dots_pattern.svg' alt='Dots')
        img.ellipse(src='@/assets/webflowAssets/Reset/ellipse.svg' alt='Ellipse')
        img.right--dots--group(src='@/assets/webflowAssets/Reset/right_dots_pattern.svg' alt='Dots')
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      content:
        "A lot of things have changed on the new platform. Due to security reasons, you must reset your password to continue signing in.",
      content1: "We just sent you a link to a page where you can reset your password.",
      text: "Didn’t receive an email?"
    }
  },
  methods: {
    async sendPasswordRecovery() {
      const email = this.$route.query.email
      try {
        await this.$store.dispatch("user/sendPasswordRecoveryLink", email)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.reset--section--wrapper {
  padding: 180px 0px;
  overflow: hidden;
}

.reset--section--inner {
  position: relative;
}

.reset--section--container {
  max-width: 590px;
  margin: 0 auto;
  padding: 0 20px;
}

.reset--section--header {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 27px;
}

.reset--section--header__red {
  color: #f15a42;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: rgba(241, 90, 66, 0.1);
}

.reset--section--content {
  font-family: Mr Eaves Mod OT;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.reset--section--wrapper .left--dots--group {
  position: absolute;
  left: 0;
  top: 10px;
}

.reset--section--wrapper .ellipse {
  position: absolute;
  right: 0;
  top: 80px;
}

.reset--section--wrapper .right--dots--group {
  position: absolute;
  right: 0;
  top: 80px;
}

.reset--footer--content {
  text-align: center;
}

.reset--footer--content a {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #f15a42;
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .reset--section--wrapper {
    padding: 40px 0;
  }

  .reset--section--header {
    font-weight: 400;
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .reset--section--header span:nth-child(1) {
    margin-right: 0;
  }

  .reset--section--content {
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
  }

  .reset--section--wrapper .left--dots--group {
    width: 70px;
    height: 120px;
    object-fit: cover;
    top: -21px;
  }

  .reset--section--wrapper .ellipse {
    width: 25px;
    top: -16px;
  }

  .reset--section--wrapper .right--dots--group {
    width: 130px;
    height: 155px;
    right: -60px;
    top: -70px;
    object-fit: cover;
  }

  .reset--footer--content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .reset--footer--content a {
    margin-top: 20px;
    margin-left: 0;
  }
}
</style>
