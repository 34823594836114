<template>
  <a-row class="admin-retailer-search">
    <a-col :span="24" class="admin-console-brand-retailer-product-table" style="margin-top: 21px;">
      <div>
        <div class="admin-product-search__left-title">Best Seller</div>
        <div style="float: right; margin-bottom: 10px;" class="admin-product-search__right-view">
          View
          <a-select
            v-model="bestSellerSelect"
            class="admin-product-search__dropdown-view"
            @change="handleChange"
          >
            <a-select-option value="30">
              Last 30 days
            </a-select-option>
            <a-select-option value="90">
              Last 90 days
            </a-select-option>
            <a-select-option value="365">
              Last 365 days
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        bordered
        :data-source="bestSellerResult.list"
        :columns="columns"
        :pagination="false"
        :row-key="record => record.privateId"
      >
        <template slot="productName" slot-scope="text, record">
          <a-tooltip v-if="text && text.length > 20" placement="bottom" :title="text">
            <span class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
              text
            }}</span>
          </a-tooltip>
          <span v-else class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
            text
          }}</span>
        </template>
        <template slot="msrp" slot-scope="text">
          <span>{{ priceFormat(text) }}</span>
        </template>
        <template slot="brandName">
          <span> Brand Name </span>
          <a-icon
            type="caret-up"
            :rotate="isBestSortAsc ? 0 : 180"
            @click="doSortByBrandNameBest"
          />
        </template>
        <template slot="productName">
          <span>Product Name </span>
          <a-icon
            type="caret-up"
            :rotate="isBestProductSortAsc ? 0 : 180"
            @click="doSortByProductBest"
          />
        </template>
        <template slot="sku">
          <span>SKU </span>
          <a-icon type="caret-up" :rotate="isBestSkuSortAsc ? 0 : 180" @click="doSortBySkuBest" />
        </template>
        <template slot="barCode">
          <span>UPC </span>
          <a-icon type="caret-up" :rotate="isBestUpcSortAsc ? 0 : 180" @click="doSortByUpcBest" />
        </template>
        <template slot="size">
          <span>Size </span>
          <a-icon type="caret-up" :rotate="isBestSizeSortAsc ? 0 : 180" @click="doSortBySizeBest" />
        </template>
        <template slot="category">
          <span>Category </span>
          <a-icon
            type="caret-up"
            :rotate="isBestCategorySortAsc ? 0 : 180"
            @click="doSortByCategoryBest"
          />
        </template>
        <template slot="msrpSort">
          <span>MSRP</span>
          <a-icon type="caret-up" :rotate="isBestMsrpSortAsc ? 0 : 180" @click="doSortByMsrpBest" />
        </template>
        <template slot="sizeColumn" slot-scope="text, record">
          <span> {{ `${text} ${record.sizeUnitID}` }}</span>
        </template>
      </a-table>
      <div class="admin-product-search__see-all" @click="goToBestSellerPage">
        See All
      </div>

      <div style="margin-top: 55px;">
        <div class="admin-product-search__left-title">New Arrivals</div>
        <div style="float: right; margin-bottom: 10px;" class="admin-product-search__right-view">
          View
          <a-select
            v-model="newArrivalSelect"
            class="admin-product-search__dropdown-view"
            @change="handleChangeArrival"
          >
            <a-select-option value="30">
              Last 30 days
            </a-select-option>
            <a-select-option value="90">
              Last 90 days
            </a-select-option>
            <a-select-option value="365">
              Last 365 days
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        bordered
        :data-source="newArrivalResult.list"
        :columns="columns"
        :pagination="false"
        :row-key="record => record.privateId"
      >
        <template slot="productName" slot-scope="text, record">
          <a-tooltip v-if="text && text.length > 20" placement="bottom" :title="text">
            <span class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
              text
            }}</span>
          </a-tooltip>
          <span v-else class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
            text
          }}</span>
        </template>
        <template slot="msrp" slot-scope="text">
          <span>{{ priceFormat(text) }}</span>
        </template>
        <template slot="brandName">
          <span> Brand Name </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalSortAsc ? 0 : 180"
            @click="doSortByBrandNameArrival"
          />
        </template>
        <template slot="productName">
          <span>Product Name </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalProductSortAsc ? 0 : 180"
            @click="doSortByProductArrival"
          />
        </template>
        <template slot="sku">
          <span>SKU </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalSkuSortAsc ? 0 : 180"
            @click="doSortBySkuArrival"
          />
        </template>
        <template slot="barCode">
          <span>UPC </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalUpcSortAsc ? 0 : 180"
            @click="doSortByUpcArrival"
          />
        </template>
        <template slot="size">
          <span>Size </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalSizeSortAsc ? 0 : 180"
            @click="doSortBySizeArrival"
          />
        </template>
        <template slot="category">
          <span>Category </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalCategorySortAsc ? 0 : 180"
            @click="doSortByCategoryArrival"
          />
        </template>
        <template slot="msrpSort">
          <span>MSRP</span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalMsrpSortAsc ? 0 : 180"
            @click="doSortByMsrpArrival"
          />
        </template>
        <template slot="sizeColumn" slot-scope="text, record">
          <span> {{ `${text} ${record.sizeUnitID}` }}</span>
        </template>
      </a-table>
      <div class="admin-product-search__see-all" @click="goToNewArrivalPage">
        See All
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { priceFormat } from "@/utils/validate"
export default {
  data() {
    return {
      priceFormat,
      isArrivalSortAsc: false,
      isArrivalMsrpSortAsc: false,
      isArrivalCategorySortAsc: false,
      isArrivalSizeSortAsc: false,
      isArrivalUpcSortAsc: false,
      isArrivalProductSortAsc: false,
      isArrivalSkuSortAsc: false,
      sortArrivalParam: {},
      sortBestParam: {},
      isBestSortAsc: false,
      isBestMsrpSortAsc: false,
      isBestCategorySortAsc: false,
      isBestSizeSortAsc: false,
      isBestUpcSortAsc: false,
      isBestProductSortAsc: false,
      isBestSkuSortAsc: false,
      pageSize: 10,
      bestSellerSelect: "30",
      newArrivalSelect: "90",
      columns: [
        {
          dataIndex: "rank",
          title: "#",
          width: "50px"
        },
        {
          dataIndex: "brandName",
          width: "15%",
          slots: { title: "brandName" }
        },
        {
          //title: "Product Name",
          dataIndex: "name",
          ellipsis: true,
          width: "24%",
          slots: { title: "productName" },
          scopedSlots: { customRender: "productName" }
        },
        {
          //title: "SKU",
          dataIndex: "sku",
          slots: { title: "sku" },
          width: "10%"
        },
        {
          //title: "UPC",
          dataIndex: "barCode",
          slots: { title: "barCode" },
          width: "15%"
        },
        {
          //title: "Size",
          dataIndex: "size",
          slots: { title: "size" },
          scopedSlots: { customRender: "sizeColumn" },
          width: "10%"
        },
        {
          //title: "Category",
          dataIndex: "category",
          slots: { title: "category" },
          width: "14%"
        },
        {
          //title: "MSRP",
          dataIndex: "msrpUsd",
          slots: { title: "msrpSort" },
          width: "10%",
          scopedSlots: { customRender: "msrp" }
        }
      ],
      selectedRecords: new Set()
    }
  },
  computed: {
    bestSellerResult() {
      return this.$store.getters["adminConsole/bestSellers"]
    },
    newArrivalResult() {
      return this.$store.getters["adminConsole/newArrivals"]
    }
  },
  created() {
    this.getBestSellerData()
    this.getNewArrivalData()
    this.$store.commit("adminConsole/SET_BEST_SELLER_PARAM", this.bestSellerSelect)
    this.$store.commit("adminConsole/SET_ARRIVAL_SELECT_PARAM", this.newArrivalSelect)
  },
  methods: {
    goToBestSellerPage() {
      this.$router.push({
        name: "adminProductSearchBest",
        params: {
          searchParam: this.bestSellerSelect
        }
      })
    },
    goToNewArrivalPage() {
      this.$router.push({
        name: "adminProductSearchArrival",
        params: {
          searchParam: this.newArrivalSelect
        }
      })
    },

    doSortByBrandNameArrival() {
      this.isArrivalSortAsc = !this.isArrivalSortAsc
      this.sortArrivalParam = {
        "_sort-by": "brand_name",
        "_order-by": this.isArrivalSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData()
    },
    doSortByProductArrival() {
      this.isArrivalProductSortAsc = !this.isArrivalProductSortAsc
      this.sortArrivalParam = {
        "_sort-by": "name",
        "_order-by": this.isArrivalProductSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData()
    },
    doSortBySkuArrival() {
      this.isArrivalSkuSortAsc = !this.isArrivalSkuSortAsc
      this.sortArrivalParam = {
        "_sort-by": "sku",
        "_order-by": this.isArrivalSkuSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData()
    },
    doSortByUpcArrival() {
      this.isArrivalUpcSortAsc = !this.isArrivalUpcSortAsc
      this.sortArrivalParam = {
        "_sort-by": "bar_code",
        "_order-by": this.isArrivalUpcSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData()
    },
    doSortBySizeArrival() {
      this.isArrivalSizeSortAsc = !this.isArrivalSizeSortAsc
      this.sortArrivalParam = {
        "_sort-by": "size",
        "_order-by": this.isArrivalSizeSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData()
    },
    doSortByCategoryArrival() {
      this.isArrivalCategorySortAsc = !this.isArrivalCategorySortAsc
      this.sortArrivalParam = {
        "_sort-by": "category_name",
        "_order-by": this.isArrivalCategorySortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData()
    },
    doSortByMsrpArrival() {
      this.isArrivalMsrpSortAsc = !this.isArrivalMsrpSortAsc
      this.sortArrivalParam = {
        "_sort-by": " msrp_usd",
        "_order-by": this.isArrivalMsrpSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData()
    },
    doSortByBrandNameBest() {
      this.isBestSortAsc = !this.isBestSortAsc
      this.sortBestParam = {
        "_sort-by": "brand_name",
        "_order-by": this.isBestSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData()
    },
    doSortByProductBest() {
      this.isBestProductSortAsc = !this.isBestProductSortAsc
      this.sortBestParam = {
        "_sort-by": "name",
        "_order-by": this.isBestProductSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData()
    },
    doSortBySkuBest() {
      this.isBestSkuSortAsc = !this.isBestSkuSortAsc
      this.sortBestParam = {
        "_sort-by": "sku",
        "_order-by": this.isBestSkuSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData()
    },
    doSortByUpcBest() {
      this.isBestUpcSortAsc = !this.isBestUpcSortAsc
      this.sortBestParam = {
        "_sort-by": "bar_code",
        "_order-by": this.isBestUpcSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData()
    },
    doSortBySizeBest() {
      this.isBestSizeSortAsc = !this.isBestSizeSortAsc
      this.sortBestParam = {
        "_sort-by": "size",
        "_order-by": this.isBestSizeSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData()
    },
    doSortByCategoryBest() {
      this.isBestCategorySortAsc = !this.isBestCategorySortAsc
      this.sortBestParam = {
        "_sort-by": "category_name",
        "_order-by": this.isBestCategorySortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData()
    },
    doSortByMsrpBest() {
      this.isBestMsrpSortAsc = !this.isBestMsrpSortAsc
      this.sortBestParam = {
        "_sort-by": " msrp_usd",
        "_order-by": this.isBestMsrpSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData()
    },
    async getBestSellerData() {
      await this.$store.dispatch("adminConsole/fetchBestSellers", {
        ...this.sortBestParam,
        _limit: this.pageSize,
        _index: 1,
        "last-days": this.bestSellerSelect
      })
    },
    async getNewArrivalData() {
      await this.$store.dispatch("adminConsole/fetchNewArrival", {
        ...this.sortArrivalParam,
        _limit: this.pageSize,
        _index: 1,
        "last-days": this.newArrivalSelect
      })
    },
    handleChange() {
      this.$store.commit("adminConsole/SET_BEST_SELLER_PARAM", this.bestSellerSelect)
      this.getBestSellerData()
    },
    handleChangeArrival() {
      this.$store.commit("adminConsole/SET_ARRIVAL_SELECT_PARAM", this.newArrivalSelect)
      this.getNewArrivalData()
    },
    clickToProductPage(item) {
      this.$router.push({
        name: "adminProduct",
        params: {
          id: item.id
        }
      })
    }
  }
}
</script>
