import httpclient from "@/api/http-client";
import Order from "@/models/orders/Order";
import moment from "moment";

export default {
  async getOrders(orderSortType, orderFilter) {
    var url = "/api/orders/filter";
    let params = new URLSearchParams(url.search);
    if (orderSortType) {
      params.append("sortType", orderSortType);
    }
    if (orderFilter) {
      for (const prop in orderFilter) {
        let value = orderFilter[prop];
        if (value) {
          if (value instanceof Date) {
            params.append(prop, moment(value).format("YYYY-MM-DD"));
          } else if (Array.isArray(value)) {
            for (var aValue of value) {
              params.append(prop, aValue);
            }
          } else {
            params.append(prop, value);
          }
        }
      }
    }
    const queryString = params.toString();
    if (queryString) {
      url = `${url}?${queryString}`;
    }
    return await httpclient.get(url).then((res) => {
      var paginatedList = res.data;
      //console.log(`list: ${paginatedList}`);
      paginatedList.data = paginatedList.data.map((r) => new Order(r));
      return paginatedList;
    });
  },
};
