import SimpleDescriptionEntity from "@/models/SimpleDescriptionEntity";
export default class Product extends SimpleDescriptionEntity {
  constructor(model) {
    super(model);
    this.brandId = null;
    if (model) {
      this.brandId = model.brandId;
    }
  }

  toModel() {
    const data = super.toModel();
    data.brandId = this.brandId;
    return data;
  }
}
