<template>
  <a-modal
    v-model="isShow"
    width="403px"
    dialog-class="adminProductTrainingDialog"
    :footer="null"
    :destroy-on-close="true"
  >
    <a-row class="admin-modal-title"> {{ title }}</a-row>
    <div class="admin-modal-content admin-training-retailer-dialog">
      <a-row class="admin-training-retailer-dialog__row-margin">
        <!-- <a-col :span="6" class="admin-training-retailer-dialog__left-label">Name</a-col> -->
        <a-col :span="24">
          <span v-if="isEdit"> {{ retailerData.retailerName }} </span>
          <!-- <a-input v-else v-model="retailerData.name" placeholder="input retailer name" /> -->
          <a-select
            v-else
            v-model="retailer"
            show-search
            label-in-value
            placeholder="Name"
            style="width: 100%;"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearch"
            @change="handleChange"
          >
            <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
            <a-select-option v-for="item in retailerOptions" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="admin-training-retailer-dialog__row-margin">
        <!-- <a-col :span="6" class="admin-training-retailer-dialog__left-label">Annual Price</a-col> -->
        <a-col :span="24" style="text-align: right;">
          <span style="margin-right: 10px;">General</span>
          <a-switch
            v-model="isPreferred"
            class="admin-training-switch admin-training-retailer-switch"
            style="background-image: none"
            :disabled="true"
            @change="onChangeGeneral"
          />
          <span style="margin-left: 10px;">Preferred</span>
        </a-col>
      </a-row>
      <div v-if="isPreferred">
        <a-row class="admin-training-retailer-dialog__row-margin">
          <!-- <a-col :span="6" class="admin-training-retailer-dialog__left-label">Annual Price</a-col> -->
          <a-col :span="18">
            <a-input
              v-model="annuallyPrice"
              placeholder="Annually price"
              prefix="$"
              style="text-align: right;padding-right:12px;"
              @change="changePrice"
            />
          </a-col>
          <a-col :span="6" class="admin-training-retailer-dialog__left-label">Annual</a-col>
        </a-row>
        <a-row class="admin-training-retailer-dialog__row-margin">
          <!-- <a-col :span="6" class="admin-training-retailer-dialog__left-label">Annual Price</a-col> -->
          <a-col :span="18">
            <a-input
              v-model="quertyPrice"
              placeholder="Quarterly price"
              prefix="$"
              style="text-align: right;padding-right:12px;"
              @change="changeQuarterPrice"
            />
          </a-col>
          <a-col :span="6" class="admin-training-retailer-dialog__left-label">Quarterly</a-col>
        </a-row>
        <a-row class="admin-training-retailer-dialog__row-margin">
          <!-- <a-col :span="6" class="admin-training-retailer-dialog__left-label">Annual Price</a-col> -->
          <a-col :span="18">
            <a-input
              v-model="monthPrice"
              placeholder="Monthly price"
              prefix="$"
              style="text-align: right;padding-right:12px;"
              @change="changeMonthPrice"
            />
          </a-col>
          <a-col :span="6" class="admin-training-retailer-dialog__left-label">Monthly</a-col>
        </a-row>
      </div>
      <a-row style="margin-top: 136px;">
        <a-button class="admin-training-landing-deep-red-btn" @click="handleCancel"
          >Cancel</a-button
        >
        <a-button
          class="admin-training-landing-light-green-btn"
          :loading="isLoad"
          @click="handleSave"
          >Save</a-button
        >
      </a-row>
    </div>
  </a-modal>
</template>
<script>
import { priceFormat } from "@/utils/validate"
export default {
  data() {
    return {
      isShow: false,
      isEdit: false,
      title: "",
      retailer: undefined,
      retailerData: undefined,
      fileList: undefined,
      editRetailer: undefined,
      annuallyPrice: undefined,
      monthPrice: undefined,
      quertyPrice: undefined,
      isLoad: false,
      isPreferred: false //need to modify the value when open dialog
      //   existedRetailers: [],
      //   isExisted: false
    }
  },
  computed: {
    retailerOptions() {
      //   let data = []
      //   data.push({
      //     id: "*",
      //     name: "General",
      //     shortName: "General",
      //   })
      //   let preferredRetailer = this.$store.getters["retailer/retailerList"]
      //   if (preferredRetailer && preferredRetailer.length > 0) {
      //     data.concat(preferredRetailer)
      //   }
      //   return data
      return this.$store.getters["retailer/retailerList"]
    }
  },
  methods: {
    openForAdd(titleStr) {
      this.isShow = true
      this.isEdit = false
      this.title = titleStr
      this.retailerData = undefined
      this.annuallyPrice = undefined
      this.quertyPrice = undefined
      this.monthPrice = undefined
      this.isPreferred = false
      //   this.existedRetailers = existedRetailer
      //   this.isExisted = false
    },
    openDialog(titleStr, data) {
      this.isShow = true
      this.isEdit = true
      this.title = titleStr
      //   this.existedRetailers = []
      //   this.isExisted = false
      if (data) {
        this.retailerData = data
        this.isPreferred = data.isPreferred
        this.annuallyPrice = priceFormat(this.retailerData.annuallyPrice).substring(1)
        this.quertyPrice = priceFormat(this.retailerData.quarterlyPrice).substring(1)
        this.monthPrice = priceFormat(this.retailerData.monthlyPrice).substring(1)
        this.$store.dispatch("retailer/fetchRetailer", this.retailerData.retailerId).then(() => {
          this.editRetailer = this.$store.getters["retailer/retailer"]
        })
      }
      console.log(this.retailerData)
    },
    onChangeGeneral() {
      console.log("change retailer to be general or preferred.")
    },
    async handleSearch(value) {
      console.log(value)
      let param = {
        retailer: value,
        "grade-type": "preferred",
        fields: "name,id,shortName,status,grades",
        _limit: 9999
      }
      await this.$store.dispatch("retailer/fetchNoPriceRetailers", {
        params: { ...param, status: "approved" }
      })
    },
    handleChange(value) {
      console.log(value)
      if (value) {
        let result = this.retailerOptions.filter(item => item.id === value.key)
        if (result && result.length > 0) {
          console.log(result)
          this.isPreferred = result[0].grades[0].type.id === "preferred"
          //   if (this.existedRetailers && this.existedRetailers.length > 0) {
          //     let eResult = this.existedRetailers.filter(item => item.retailerId === value.key)
          //     if (eResult && eResult.length > 0) {
          //       this.isExisted = true
          //     } else {
          //       this.isExisted = false
          //     }
          //   } else {
          //     this.isExisted = false
          //   }
        }
      }
    },
    handleCancel() {
      this.annuallyPrice = undefined
      this.monthPrice = undefined
      this.quertyPrice = undefined
      this.isLoad = false
      this.isShow = false
      //   this.existedRetailers = []
      //   this.isExisted = false
    },
    priceStringToNumber(priceStr) {
      if (priceStr && priceStr.indexOf(",") >= 0) {
        priceStr = priceStr.replace(new RegExp(",", "g"), "")
      }
      return priceStr
    },
    changePrice() {
      console.log(parseFloat(this.priceStringToNumber(this.annuallyPrice)))
      this.monthPrice = priceFormat(
        parseFloat(this.priceStringToNumber(this.annuallyPrice)) / 12
      ).substring(1)
      this.quertyPrice = priceFormat(
        parseFloat(this.priceStringToNumber(this.annuallyPrice)) / 4
      ).substring(1)
    },
    changeQuarterPrice() {
      this.annuallyPrice = priceFormat(
        parseFloat(this.priceStringToNumber(this.quertyPrice)) * 4
      ).substring(1)
      this.monthPrice = priceFormat(
        parseFloat(this.priceStringToNumber(this.quertyPrice)) / 3
      ).substring(1)
    },
    changeMonthPrice() {
      this.annuallyPrice = priceFormat(
        parseFloat(this.priceStringToNumber(this.monthPrice)) * 12
      ).substring(1)
      this.quertyPrice = priceFormat(
        parseFloat(this.priceStringToNumber(this.monthPrice)) * 3
      ).substring(1)
    },
    handleSave() {
      //do save action
      this.isLoad = true
      let param = {}
      let priceValue = this.annuallyPrice
      if (this.annuallyPrice.toString().indexOf(",") > 0) {
        priceValue = this.annuallyPrice.replace(/,/g, "")
      }
      console.log(this.annuallyPrice)
      let price = { annuallyPrice: priceValue }
      if (!this.isEdit) {
        let data = this.$store.getters["retailer/noPriceRetailerById"](this.retailer.key)
        if (data) {
          param = Object.assign({}, data)
        }
        param["trainingPrices"] = [price]
        param["_skipNulls"] = true
      } else {
        param = {
          id: this.retailerData.retailerId,
          name: this.retailerData.retailerName,
          _skipNulls: true,
          status: this.editRetailer.status,
          shortName: this.editRetailer.shortName,
          //trainingPrices: this.editRetailer.trainingPrices
          trainingPrices: [
            {
              privateId: this.editRetailer.trainingPrices[0].privateId,
              retailerPrivateId: this.editRetailer.trainingPrices[0].retailerPrivateId,
              annuallyPrice: priceValue
            }
          ]
        }
        // if (param.trainingPrices && param.trainingPrices.length > 0) {
        //   param.trainingPrices.push(price)
        // } else {
        //   param["trainingPrices"] = [price]
        // }
      }
      console.log(param)
      this.$store
        .dispatch("retailer/updateRetailer", param)
        .then(() => {
          this.$message.success("Update retailer annually price success ")
          this.$emit("updateSuccess")
          this.handleCancel()
        })
        .catch(err => {
          console.log(err)
          //this.$message.error("Then annually price can't over 10000 now.")
          this.isLoad = false
        })
    },
    doUpload(data) {
      let file = data.file
      let fileReader = new FileReader()
      const formData = new FormData()
      fileReader.readAsText(file)
      let info = this.$t("Admins.AdminOrders.uploadFailInfo")
      fileReader.onloadend = e => {
        let data = new Blob([e.target.result], { type: "text/csv" })
        formData.append("file", data, file.name)
        this.uploading = true
        this.$store
          .dispatch("inventory/uploadInventory", formData)
          .then(res => {
            if (res.type === "success") {
              this.uploadSuccess = true
              this.$message.success("Upload success!", this.messageShowTime)
            } else {
              let msg = res.reports
              if (msg && msg.length > 0) {
                this.$message.error(msg[0], this.messageShowTime)
              } else {
                this.$message.error(info, this.messageShowTime)
              }
            }
          })
          .catch(error => {
            this.$message.error(info, this.messageShowTime)
            console.log(error)
          })
          .finally(() => {
            this.uploading = false
          })
      }
    },
    beforeUpload(file) {
      let isFormatRight = this.isCsv(file.name)
      if (!isFormatRight) {
        let info = this.$t("Admins.AdminOrders.uploadFailInfo")
        this.$message.error(info, this.messageShowTime)
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-training-retailer-dialog {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__left-label {
    color: #262626;
    text-align: left;
    padding-left: 10px;
    margin-top: 6px;
  }
  &__row-margin {
    margin-bottom: 14px;
  }
}
</style>
<style lang="scss">
.admin-training-switch.admin-training-retailer-switch.ant-switch.ant-switch-disabled {
  background-color: #f27c59;
}
.admin-training-switch.admin-training-retailer-switch.ant-switch-checked.ant-switch-disabled {
  background-color: #9fd19a;
}
</style>
