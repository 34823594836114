<template>
  <div class="brand-message-page">
    <div class="brand-message-page__outer">
      <div class="brand-message-page__main">
        <div class="brand-message-header-box">
          <div class="message-title">Messaging</div>
          <div class="message-title-button">
            <in-box-operation
              @compose="composeMessage"
              @delete="deleteMessage"
              @search="searchPannel"
            />
          </div>
        </div>
        <div class="brand-message-table-box">
          <div class="message-table-title">
            <p
              v-for="item in messageTableTitle"
              :key="item.title"
              :class="[item.isActive ? 'active-table-title' : '']"
              @click="changeTitleTable(item)"
            >
              {{ item.name }}
            </p>
          </div>
          <div class="message-table-content">
            <template v-for="index in 2">
              <MessageTable
                v-if="index - 1 === currentTab"
                :key="`retailer_inbox_${index}`"
                :list="tableData[index - 1]"
                :is-loading="isLoading[index - 1]"
                :is-show-footer="isShowSearchResult[index - 1]"
                @onChecked="getSelectedRecord"
                @clearSerach="clearSearchAction"
                @getMessageData="getMessageData"
                @pageChange="getData"
                @sortData="sortData"
              ></MessageTable>
            </template>
          </div>
        </div>
      </div>
      <search-panel
        v-if="isShowSearchPanel"
        class="brand-message-page__search-panel"
        @applySearch="applySearchAction"
      />
    </div>
    <confirm-dialog ref="confirmDelete" @doConfirm="doDeleteMessage" />
    <retailer-compose-message-modal ref="retailerComposeDialog" @getMessageData="getMessageData" />
  </div>
</template>

<script>
import InBoxOperation from "@/components/message/InBoxOperation.vue"
import MessageTable from "@/components/message/components/messageTable"
import SearchPanel from "@/components/message/SearchPanel.vue"
import ConfirmDialog from "@/components/admin/ConfirmDialog.vue"
import RetailerComposeMessageModal from "@/components/message/RetailerComposeMessageModal"
import { messageItemizeType } from "@/utils/const"
import { getBrandRetailerMessageParam, getAdminMessageBatchOperaiontParam } from "@/utils/validate"
export default {
  components: {
    MessageTable,
    InBoxOperation,
    SearchPanel,
    ConfirmDialog,
    RetailerComposeMessageModal
  },
  data() {
    return {
      tableData: [{}, {}],
      messageTableTitle: [
        { name: "Inbox", isActive: true, type: messageItemizeType.INBOX_TYPE },
        { name: "Sent", isActive: false, type: messageItemizeType.SENT_TYPE }
      ],
      isShowSearchPanel: false,
      selectedRecord: [[], []],
      isShowSearchResult: [false, false],
      isLoading: [false, false],
      currentTab: 0,
      searchParam: [undefined, undefined],
      type: messageItemizeType.INBOX_TYPE,
      currentPage: [1, 1],
      sortParam: [undefined, undefined]
    }
  },
  computed: {
    isBrand() {
      let role = this.$store.getters["user/role"]
      return role === "brand"
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    clientId() {
      return this.$store.getters["user/businessId"]
    }
  },
  async created() {
    this.$store.dispatch("reference/setReference")
    this.$store.dispatch("brand/fetchAllBrands", {
      params: { fields: "id, name, shortName,follows,logoAsset" }
    })
    this.$store.dispatch("retailer/fetchAllRetailers", {
      params: { status: "approved", fields: "name,id,follows,logoAsset" }
    })
    this.$store.dispatch("product/getProducts", {
      params: { fields: "name,id,msrpUsd,images,sku,brand", _limit: 9000 }
    })
    if (this.$route.query.tab) {
      const data = {
        type: this.$route.query.tab,
        name: this.$route.query.tab === messageItemizeType.INBOX_TYPE ? "Inbox" : "Sent"
      }
      this.type = data.type
      await this.changeTitleTable(data)
    } else {
      await this.getMessageData()
    }
  },
  methods: {
    async changeTitleTable(item) {
      let param
      for (let index = 0; index < this.messageTableTitle.length; index++) {
        if (this.messageTableTitle[index].name === item.name) {
          this.messageTableTitle[index].isActive = true
          this.currentTab = index
        } else {
          this.messageTableTitle[index].isActive = false
        }
      }
      param = item.type
      this.type = item.type
      await this.getMessageData(param)
    },
    async sortData(data) {
      this.sortParam[this.currentTab] = Object.assign({}, data)
      await this.getMessageData(this.$route.query.tab)
    },
    getMessageParamsInfo(type, page) {
      let params = getBrandRetailerMessageParam(
        this.clientId,
        type ? type : messageItemizeType.INBOX_TYPE,
        page,
        this.isBrand
      )
      if (this.searchParam[this.currentTab]) {
        params = {
          ...params,
          ...this.searchParam[this.currentTab]
        }
      }
      if (this.sortParam[this.currentTab]) {
        params = {
          ...params,
          ...this.sortParam[this.currentTab]
        }
      }
      return params
    },
    async getMessageData(type) {
      this.isLoading[this.currentTab] = true
      const params = this.getMessageParamsInfo(type, this.currentPage[this.currentTab])
      this.$store
        .dispatch("adminMessage/fetchSearchMessage", { params })
        .then(res => {
          this.$set(this.tableData, this.currentTab, res)
          this.$set(this.isLoading, this.currentTab, false)
          this.$router.push({
            name: this.$route.name,
            query: {
              tab: params.itemize
            }
          })
        })
        .catch(() => {
          this.$set(this.isLoading, this.currentTab, false)
        })
    },
    getData(page) {
      this.currentPage[this.currentTab] = page
      this.getMessageData(this.type)
    },
    composeMessage() {
      console.log("to Compose")
      //show retailer createNew
      console.log("retailer compose....")
      this.showRetailerComposeMessageModal()
    },

    showRetailerComposeMessageModal() {
      this.$refs["retailerComposeDialog"].openDialog()
    },
    deleteMessage() {
      let canShowDialog = false
      if (this.selectedRecord[this.currentTab] && this.selectedRecord[this.currentTab].length > 0) {
        canShowDialog = true
      }
      if (canShowDialog) {
        this.showDeleteConfirm()
      } else {
        this.$message.warning("Please choose records which you want to delete.")
      }
    },
    getOperateParam(isDelete) {
      return getAdminMessageBatchOperaiontParam(
        this.type,
        isDelete,
        this.selectedRecord[this.currentTab],
        false
      )
    },
    doDeleteMessage() {
      console.log("delete selected message....")
      this.$store
        .dispatch("adminMessage/updateBatchMessages", this.getOperateParam(true))
        .then(res => {
          console.log(res)
          this.$message.success("Delete messages success.")
          this.getData(this.currentPage[this.currentTab])
        })
        .catch(err => {
          console.log(err)
          this.$message.error(`Delete messages fail, the err:${err.toString()}`)
        })
    },
    searchPannel() {
      this.isShowSearchPanel = !this.isShowSearchPanel
    },
    getSelectedRecord(data) {
      this.selectedRecord[this.currentTab] = data
    },
    showDeleteConfirm() {
      this.$refs["confirmDelete"].openDialogConfirm(
        "Delete Message",
        "Are you sure you wish to delete? This action cannot be undone. ",
        "No",
        "Yes"
      )
    },
    applySearchAction(param) {
      this.isShowSearchPanel = false
      this.setSearchParam(param)
      this.$set(this.isShowSearchResult, this.currentTab, true)
    },
    setSearchParam(param) {
      this.searchParam[this.currentTab] = Object.assign({}, param)
      this.currentPage[this.currentTab] = 1
      this.getMessageData(this.$route.query.tab)
    },
    clearSearchAction() {
      this.$set(this.isShowSearchResult, this.currentTab, false)
      // invoke api to get message list again, no search param
      console.log("...get message list...")
      this.setSearchParam()
    }
  }
}
</script>

<style lang="scss">
.brand-message-page {
  width: 100%;
  min-height: 750px !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &__outer {
    margin: 0 auto;
  }
  &__search-panel {
    float: left;
    margin-top: 26px;
  }
}
.brand-message-page::after {
  clear: both;
  content: " ";
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}
.brand-message-page__main {
  width: 952px;
  margin: 0 auto;
  float: left;
  padding-top: 10px;
  .brand-message-header-box {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .message-title {
      font-family: "Playfair Display Bold", "Playfair Display";
      font-size: 48px;
      color: #000000;
    }
    .message-title-button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        width: 87px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #e3e3e3;
        background-color: #f5f5f5;
        margin-right: 11px;
        text-align: center;
        font-size: 12px;
        margin-bottom: 0;
        cursor: pointer;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
      }
      p:not(:last-child) {
        margin-right: 2px;
      }
    }
  }
  .brand-message-table-box {
    margin-top: 11px;
    .message-table-title {
      display: flex;
      p {
        width: 477px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #f5f5f5;
        cursor: pointer;
        font-size: 14px;
        color: #000000;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 0;
      }
      .active-table-title {
        background-color: #e2eeec;
      }
    }
  }
}
</style>
