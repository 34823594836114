<template lang="pug">
<div class="order-list">
    <a-table :columns="columns" :data-source="orders.data" :row-key="r => r.privateId" :loading="loading" @change="handleTableChange" :row-selection="rowSelection" :pagination="pagination" :customRow="customRow">
      <template slot="price" slot-scope="price">{{ priceFormat(price) }}</template>
      <template slot="date" slot-scope="date">{{ dateFormat(date) }}</template>
      <template slot="emptydash" slot-scope="emptydash">{{ formatEmtpyDash(emptydash) }}</template>
      <template slot="actualShipDate" slot-scope="actualShipDate">{{ actualShipDateFormat(actualShipDate) }}</template>
      <template slot="paymentDate" slot-scope="paymentDate">{{ dateFormat(paymentDate) }}</template>
      <template slot="referenceId" slot-scope="order"><span>{{ order.landingReferenceId || "-" }}</span><a-tooltip placement="topLeft" title="Inactive Order"><a-icon  v-if="order.osvTag == 1" class="old-order-icon" type="double-left" /></a-tooltip></template>
    </a-table></div>    
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

const allColumns = [
  {
    title: "Reference ID",
    dataIndex: "",
    sorter: true,
    scopedSlots: { customRender: "referenceId" },
  },
  {
    title: "Retailer",
    dataIndex: "retailer.name",
    sorter: true,
  },
  { title: "Brand", dataIndex: "brand.name", sorter: true },

  {
    title: "Subtotal",
    dataIndex: "calculatedWholesaleCostUsd",
    scopedSlots: { customRender: "price" },
    sorter: true,
  },
  {
    title: "Total",
    dataIndex: "calculatedRetailCostUsd",
    scopedSlots: { customRender: "price" },
    sorter: true,
  },
  {
    title: "Order Date",
    dataIndex: "orderDate",
    scopedSlots: { customRender: "date" },
    sorter: true,
  },
  {
    title: "Ship by Date",
    dataIndex: "shipDate",
    scopedSlots: { customRender: "date" },
    sorter: true,
  },
  {
    title: "Payment",
    dataIndex: "payment",
    scopedSlots: { customRender: "price" },
    sorter: false,
  },
  {
    title: "Payment Date",
    dataIndex: "paymentDate",
    scopedSlots: { customRender: "paymentDate" },
    sorter: false,
  },
  {
    title: "Actual Ship Date",
    dataIndex: "actualShipDate",
    scopedSlots: { customRender: "actualShipDate" },
    sorter: true,
  },

  {
    title: "Cancel Date",
    dataIndex: "cancelDate",
    scopedSlots: { customRender: "date" },
    sorter: true,
  },
  {
    title: "PO Number",
    dataIndex: "retailerReferenceId",
    scopedSlots: { customRender: "emptydash" },
    sorter: true,
  },
  {
    title: "Tracking Number",
    dataIndex: "trackingNumber",
    scopedSlots: { customRender: "emptydash" },
    sorter: true,
  },
  {
    title: "Invoice #",
    dataIndex: "invoiceId",
    scopedSlots: { customRender: "emptydash" },
    sorter: true,
  },
  { title: "Status", dataIndex: "status.name", sorter: true },
];

export default {
  props: {
    orderFilter: {},
    showColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      pagination: { pageSize: 50 },
      clickedRow: null,
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          // console.log(
          //   `selectedRowKeys: ${selectedRowKeys}`,
          //   "selectedRows: ",
          //   selectedRows
          // );
          this.$emit("rowSelectionChanged", selectedRows);
        },
        // onSelect: (record, selected, selectedRows) => {
        //    console.log(record, selected, selectedRows);
        // },
        onSelectAll: (selected, selectedRows) => {
          // console.log(selected, selectedRows, changeRows);
          this.$emit("rowSelectionChanged", selectedRows, true);
        },
      },
      selectedRowKeys: [],
      columns: [],
    };
  },
  computed: {
    // orders: () => {
    //   //const data = this.$store.getters["orders/getOrders"];
    //   const data = this.$store.state.orders.orders;
    //   return data;
    // },

    ...mapGetters({
      orders: "orders/getOrders",
    }),
  },
  watch: {
    showColumns(value) {
      //console.log(`showColumns:${value}`);
      this.updateColumns(value);
      this.pagination.current = 1;
    },
    orderFilter() {
      this.fetch();
    },
  },
  created() {
    this.updateColumns(this.showColumns);
  },
  mounted() {
    this.fetch();
  },

  methods: {
    async fetch(params = {}) {
      //console.log(params);
      const orderSortType = this.getOrderSortType(params);
      //console.log(orderSortType);
      //console.log(this.orderFilter);
      this.loading = true;
      if (this.orderFilter.pageSize) {
        this.pagination.pageSize = this.orderFilter.pageSize;
      }
      this.orderFilter.pageIndex = params.page;
      await this.$store
        .dispatch("orders/setOrders", {
          orderSortType,
          orderFilter: this.orderFilter,
        })
        .then((data) => {
          const pagination = { ...this.pagination };
          pagination.total = data.total;
          this.pagination = pagination;
          return data.data;
        })
        .finally(() => (this.loading = false));
    },
    updateColumns(showColumns) {
      //const showColumns = this.showColumns;

      this.columns = allColumns.filter((c) => {
        return showColumns.includes(c.title);
      });
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.rowClick(record);
          },
        },
      };
    },
    rowClick(record) {
      let routePath = {};
      if (record.Tag == 1) {
        routePath = {
          path: `oldOrders/${record.id}`,
        };
      } else {
        routePath = {
          path: `orders/${record.id}`,
        };
      }
      this.$router.push(routePath);
    },
    getOrderSortType({ sortField, sortOrder }) {
      const sortItem = `${sortField}-${sortOrder}`;
      let orderSortType = 0;
      switch (sortItem) {
        case "landingReferenceId-ascend":
          orderSortType = 1;
          break;
        case "landingReferenceId-descend":
          orderSortType = 2;
          break;
        case "retailer.name-ascend":
          orderSortType = 3;
          break;
        case "retailer.name-descend":
          orderSortType = 4;
          break;
        case "brand.name-ascend":
          orderSortType = 5;
          break;
        case "brand.name-descend":
          orderSortType = 6;
          break;
        case "calculatedWholesaleCostUsd-ascend":
          orderSortType = 7;
          break;
        case "calculatedWholesaleCostUsd-descend":
          orderSortType = 8;
          break;
        case "calculatedRetailCostUsd-ascend":
          orderSortType = 9;
          break;
        case "calculatedRetailCostUsd-descend":
          orderSortType = 10;
          break;
        case "orderDate-ascend":
          orderSortType = 11;
          break;
        case "orderDate-descend":
          orderSortType = 12;
          break;
        case "shipDate-ascend":
          orderSortType = 13;
          break;
        case "shipDate-descend":
          orderSortType = 14;
          break;
        case "cancelDate-ascend":
          orderSortType = 15;
          break;
        case "cancelDate-descend":
          orderSortType = 16;
          break;
        case "retailerReferenceId-ascend":
          orderSortType = 17;
          break;
        case "retailerReferenceId-descend":
          orderSortType = 18;
          break;
        case "trackingNumber-ascend":
          orderSortType = 19;
          break;
        case "trackingNumber-descend":
          orderSortType = 20;
          break;
        case "invoiceId-ascend":
          orderSortType = 21;
          break;
        case "invoiceId-descend":
          orderSortType = 22;
          break;
        case "status.name-ascend":
          orderSortType = 23;
          break;
        case "status.name-descend":
          orderSortType = 24;
          break;
      }

      return orderSortType;
    },
    handleTableChange(pagination, filters, sorter) {
      //console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
      this.$emit("onSortChanged", sorter);
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return numberFormat.format(price);
    },
    dateFormat(date) {
      return date ? moment(date).format("YYYY-MM-DD") : "-";
    },
    actualShipDateFormat(date) {
      if (date) {
        return this.dateFormat(date);
      }
      return "--";
    },
    formatEmtpyDash(emptydash) {
      const value = `${emptydash || ""}`.trim();
      return value.length == 0 ? "-" : value;
    },
  },
};
</script>
<style lang="scss">
.order-list .ant-table-thead > tr > th .ant-table-column-sorter {
  display: inline-flex;
  justify-content: center;
  vertical-align: initial;
}
table {
  a {
    color: #333;
  }
  .old-order-icon {
    position: absolute;
    left: -4px;
    top: 19px;
    color: #1c7430;
  }
}
</style>
