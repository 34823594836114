<template>
  <div>
    <div class="header-brand">
      <div class="banner-text">
        <h1 class="banner-title">{{ text_list.BannerTitle }}</h1>
        <h4 class="banner-subtitle">{{ text_list.BannerSubtitle }}</h4>
        <p class="banner-content">
          {{ text_list.BannerTextone }}<br />{{ text_list.BannerTexttwo }}
        </p>
        <p class="banner-link">
          {{ text_list.BannerRegister
          }}<router-link :to="{ name: 'retailerRegistration' }">{{
            text_list.BannerRegisterLink
          }}</router-link>
        </p>
      </div>
    </div>
    <div class="brand-body">
      <div class="container" style="max-width:1140px !important;">
        <div class="register-body">
          <div class="register-item">
            <h1 class="title-font">{{ text_list.PlatformTitle }}</h1>
            <ul class="register-list">
              <li v-for="item in text_list.ListText" :key="item">{{ item }}</li>
            </ul>
          </div>
          <div class="register-item">
            <div v-if="isNotRegistered">
              <div class="slider-content">
                <a href="#slide-1"></a>
                <a href="#slide-2" :style="isClick ? 'background: black' : 'background: #eee '"></a>
              </div>
              <div class="carousel-body">
                <div id="slide-1">
                  <h1 class="fillup-title">{{ text_list.FillTitleone }}</h1>
                  <p class="fillup-text">{{ text_list.FillTextone }}</p>
                  <div class="form-body">
                    <a-row :gutter="[24, 10]">
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>First Name<br /></label>
                        <a-tooltip
                          :visible="
                            $v.baseInfo.firstName.$error &&
                              !$v.baseInfo.firstName.required &&
                              baseInfo.showValidations
                          "
                          placement="top"
                          :get-popup-container="getPopupContainer"
                          overlay-class-name="retailerRegistrationTooltip"
                          :title="$t('Retailer.registration.firstNameRequired')"
                        >
                          <a-input
                            v-model.trim="$v.baseInfo.firstName.$model"
                            type="text"
                            :class="{
                              'is-danger': $v.baseInfo.firstName.$error && baseInfo.showValidations
                            }"
                            placeholder="First Name"
                            @focus="resetBasicInfoValidate"
                            @change="resetBasicInfoValidate"
                          />
                        </a-tooltip>
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Last Name<br /></label>
                        <a-tooltip
                          :visible="
                            $v.baseInfo.lastName.$error &&
                              !$v.baseInfo.lastName.required &&
                              baseInfo.showValidations
                          "
                          placement="top"
                          :get-popup-container="getPopupContainer"
                          overlay-class-name="retailerRegistrationTooltip"
                          :title="$t('Retailer.registration.lastNameRequired')"
                        >
                          <a-input
                            v-model.trim="$v.baseInfo.lastName.$model"
                            type="text"
                            :class="{
                              'is-danger': $v.baseInfo.lastName.$error && baseInfo.showValidations
                            }"
                            placeholder="Last Name"
                            @change="resetBasicInfoValidate"
                          />
                        </a-tooltip>
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Create password<br /></label>
                        <a-tooltip
                          :visible="
                            $v.baseInfo.createPwd.$error &&
                              (!$v.baseInfo.createPwd.required ||
                                !$v.baseInfo.createPwd.minLength) &&
                              baseInfo.showValidations
                          "
                          placement="top"
                          :get-popup-container="getPopupContainer"
                          overlay-class-name="retailerRegistrationTooltip"
                          :title="$t('Retailer.registration.createPwdRequired')"
                        >
                          <a-input
                            v-model.trim="$v.baseInfo.createPwd.$model"
                            autocomplete="false,"
                            type="password"
                            :class="{
                              'is-danger': $v.baseInfo.createPwd.$error && baseInfo.showValidations
                            }"
                            placeholder="Input Password"
                            @change="resetBasicInfoValidate"
                          />
                        </a-tooltip>
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Confirm password<br /></label>
                        <a-tooltip
                          :visible="
                            $v.baseInfo.confirmPwd.$error &&
                              !$v.baseInfo.confirmPwd.required &&
                              baseInfo.showValidations
                          "
                          placement="top"
                          :get-popup-container="getPopupContainer"
                          overlay-class-name="retailerRegistrationTooltip"
                          :title="$t('Retailer.registration.confirmPwdError')"
                        >
                          <a-input
                            v-model.trim="$v.baseInfo.confirmPwd.$model"
                            autocomplete="false,"
                            type="password"
                            :class="{
                              'is-danger': $v.baseInfo.confirmPwd.$error && baseInfo.showValidations
                            }"
                            placeholder="Confirm Password"
                          />
                        </a-tooltip>
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Phone Number<br /></label>
                        <a-tooltip
                          :visible="
                            $v.baseInfo.phoneNumber.$error &&
                              !$v.baseInfo.phoneNumber.required &&
                              baseInfo.showValidations
                          "
                          placement="top"
                          :get-popup-container="getPopupContainer"
                          overlay-class-name="retailerRegistrationTooltip"
                          :title="$t('Retailer.registration.phoneNumberRequired')"
                        >
                          <a-input
                            v-model.trim="$v.baseInfo.phoneNumber.$model"
                            type="text"
                            :class="{
                              'is-danger':
                                $v.baseInfo.phoneNumber.$error && baseInfo.showValidations
                            }"
                            placeholder="Phone Number"
                          />
                        </a-tooltip>
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Email Address<br /></label>
                        <a-tooltip
                          :visible="$v.baseInfo.email.$error"
                          placement="top"
                          :get-popup-container="getPopupContainer"
                          overlay-class-name="retailerRegistrationTooltip"
                          :title="
                            !$v.baseInfo.email.required
                              ? $t('Retailer.registration.emailRequired')
                              : $t('Retailer.registration.emailError')
                          "
                        >
                          <a-input
                            v-model.trim="$v.baseInfo.email.$model"
                            type="text"
                            :class="{
                              'is-danger': $v.baseInfo.email.$error && baseInfo.showValidations
                            }"
                            placeholder="Email Address"
                          />
                        </a-tooltip>
                      </a-col>
                    </a-row>
                    <div class="slide-button">
                      <a-button shape="round" @click="clickToNextStep">Next</a-button>
                    </div>
                  </div>
                </div>
                <div id="slide-2">
                  <h1 class="fillup-title">{{ text_list.FillTitletwo }}</h1>
                  <p class="fillup-text">{{ text_list.FillTexttwo }}</p>
                  <div class="form-body">
                    <a-row :gutter="[24, 10]">
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Brand Name<br /></label>
                        <a-tooltip
                          :visible="
                            $v.secondPage.brandName.$error && !$v.secondPage.brandName.required
                          "
                          placement="left"
                          :get-popup-container="getPopupContainer"
                          overlay-class-name="retailerRegistrationTooltip"
                          title="Brand Name required."
                        >
                          <a-input
                            v-model.trim="$v.secondPage.brandName.$model"
                            type="text"
                            :class="{
                              'is-danger':
                                $v.secondPage.brandName.$error && secondPage.showValidations
                            }"
                            placeholder="Brand Name"
                            @change="resetSecondInfoValidate"
                          />
                        </a-tooltip>
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Street Address<br /></label>
                        <a-tooltip
                          :visible="
                            $v.secondPage.streetAddr.$error &&
                              !$v.secondPage.streetAddr.required &&
                              secondPage.showValidations
                          "
                          placement="top"
                          :get-popup-container="getPopupContainer"
                          overlay-class-name="retailerRegistrationTooltip"
                          title="Street Address required."
                        >
                          <a-input
                            v-model.trim="$v.secondPage.streetAddr.$model"
                            autocomplete="false,"
                            :class="{
                              'is-danger':
                                $v.secondPage.streetAddr.$error && secondPage.showValidations
                            }"
                            placeholder="Street Address"
                            @change="resetSecondInfoValidate"
                          />
                        </a-tooltip>
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Brand Website<br /></label>
                        <a-tooltip
                          :visible="
                            $v.secondPage.brandWeb.$error &&
                              !$v.secondPage.brandWeb.required &&
                              secondPage.showValidations
                          "
                          placement="left"
                          :get-popup-container="getPopupContainer"
                          overlay-class-name="retailerRegistrationTooltip"
                          title="Brand Website required."
                        >
                          <a-input
                            v-model.trim="$v.secondPage.brandWeb.$model"
                            :class="{
                              'is-danger':
                                $v.secondPage.brandWeb.$error && secondPage.showValidations
                            }"
                            placeholder="Brand Website"
                            @change="resetSecondInfoValidate"
                          />
                        </a-tooltip>
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Address 2 (Optional)<br /></label>
                        <a-input
                          v-model.trim="secondPage.streetAddr2"
                          placeholder="Address 2 (Optional)"
                        />
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <label>Describe Your Brand<br /></label>
                        <a-textarea
                          id="description"
                          v-model.trim="secondPage.describleBrand"
                          placeholder="A one-paragraph description of your brand, its key differentiators, and its mission. "
                          style="height: 200px;"
                        >
                        </a-textarea>
                      </a-col>
                      <a-col :sm="24" :md="12" :xl="12">
                        <a-row class="form-group">
                          <label>City<br /></label>
                          <a-input v-model.trim="secondPage.city" placeholder="City" />
                        </a-row>
                        <a-row class="form-group">
                          <a-col :sm="24" :md="12" :xl="12">
                            <label>State/Region<br /></label>
                            <a-input
                              v-model.trim="secondPage.stateRegion"
                              style="width: 90%;"
                              placeholder="State/Region"
                            />
                          </a-col>
                          <a-col :sm="24" :md="12" :xl="12">
                            <label>Postal Code<br /></label>
                            <a-input v-model.trim="secondPage.postalCode" style="width: 90%;" />
                          </a-col>
                        </a-row>
                        <a-row class="form-group">
                          <label style="display: block;">Country</label>
                          <a-select
                            v-model.trim="secondPage.country"
                            placeholder="Select Country"
                            name="country"
                            tabindex="9"
                            style="width: 100%;"
                          >
                            <a-select-option
                              v-for="item in countryList"
                              :key="item.id"
                              :value="item.id"
                              >{{ item.name }}</a-select-option
                            >
                          </a-select>
                        </a-row>
                      </a-col>
                    </a-row>
                    <!-- <div class="slide-button">
                      <a-button shape="round" html-type="submit">Submit Application</a-button >
                    </div> -->
                    <div
                      class="text-center"
                      style="margin-top: 40px; width: 100%; clear: both; float: left;"
                    >
                      <div class="form-check" style="margin-top: 6px;">
                        <a-checkbox
                          v-model="secondPage.isAgree"
                          class="retailerRegistrationCheckbox"
                        >
                          I agree to the
                          <router-link
                            to="terms"
                            target="_blank"
                            style="color: rgb(22, 22, 22); text-decoration: underline;"
                            >Terms &amp; Conditions</router-link
                          >
                          and
                          <router-link
                            to="privacy-policy"
                            target="_blank"
                            style="color: rgb(22, 22, 22); text-decoration: underline;"
                            >Privacy Policy.</router-link
                          ><br />
                        </a-checkbox>
                      </div>
                      <a-button style="margin-top: 10px;" type="round" @click.stop="handleSubmit">
                        SUBMIT APPLICATION
                      </a-button>
                      <a
                        style="display: block; margin-top: 20px; color: rgb(22, 22, 22); text-decoration: underline;"
                        @click.stop="backToPrevPage"
                        >Back</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="thanks-item">
              <h1>Thanks for registering!<br /></h1>

              <p tyle="text-align: center; font-size: 16px">
                We’ve received your application. We’ll review <br />
                your information within 1-3 business days.<br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="brand-flyer">
      <h1 class="flyer-title">{{ text_list.FlyerTitle }}</h1>
      <p class="flyer-text">
        {{ text_list.Flyertext1 }}<br />
        {{ text_list.Flyertext2 }}
      </p>
      <router-link :to="{ name: 'home' }"
        ><a-button shape="round">{{ text_list.Flyerbutton }}</a-button></router-link
      >
    </div>
  </div>
</template>

<script>
import { email, required, minLength, sameAs } from "vuelidate/lib/validators"
export default {
  validations: {
    baseInfo: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      createPwd: {
        required,
        minLength: minLength(10)
      },
      confirmPwd: {
        sameAsPassword: sameAs("createPwd")
      },
      phoneNumber: {
        required
      },
      email: {
        required,
        email
      }
    },
    secondPage: {
      brandName: {
        required
      },
      streetAddr: {
        required
      },
      brandWeb: {
        required
      }
    }
  },
  data() {
    return {
      isClick: false,
      isNotRegistered: true,
      baseInfo: {
        showValidations: false,
        firstName: "",
        lastName: "",
        createPwd: "",
        confirmPwd: "",
        phoneNumber: "",
        email: ""
      },
      secondPage: {
        isAgree: false,
        showValidations: false,
        brandName: null,
        streetAddr: null,
        brandWeb: null,
        streetAddr2: "",
        describleBrand: "",
        city: "",
        stateRegion: "",
        postalCode: "",
        country: ""
      }
    }
  },
  computed: {
    text_list() {
      return this.$t("Auth.Register.Brand")
    },
    form_list() {
      return this.text_list.Form
    },
    app_list() {
      return this.text_list.Application
    },
    countryList() {
      return this.$store.getters["reference/countries"]
    }
  },
  watch: {
    $route(to) {
      if (to.hash === "#slide-2") {
        this.isClick = true
      } else {
        this.isClick = false
      }
    }
  },
  beforeCreate() {
    this.fory = this.$form.createForm(this, { name: "register" })
  },
  async created() {
    await this.$store.dispatch("reference/setReference")
  },
  methods: {
    // compareToFirstPassword(rule, value, callback) {
    //   const form = this.fory
    //   if (value && value != form.getFieldValue("Create password")) {
    //     callback("Two Passwords does not match")
    //   } else {
    //     callback()
    //   }
    // },
    getPopupContainer(trigger) {
      return trigger.parentElement
    },
    resetBasicInfoValidate() {
      this.baseInfo.showValidations = false
    },
    resetSecondInfoValidate() {
      this.secondPage.showValidations = false
    },
    getBaseLocationHref() {
      let currentLink = window.location.href
      let index = currentLink.indexOf("#")
      if (index > 0) {
        currentLink = currentLink.toString().substring(0, index)
      }
      return currentLink
    },
    clickToNextStep() {
      this.$v.baseInfo.$touch()
      this.baseInfo.showValidations = this.$v.baseInfo.$anyError
      if (this.$v.baseInfo.$anyError) {
        return
      }
      window.location.href = this.getBaseLocationHref() + "#slide-2"
    },
    backToPrevPage() {
      window.location.href = this.getBaseLocationHref() + "#slide-1"
      this.isClick = false
    },
    async handleSubmit() {
      if (this.secondPage.isAgree) {
        this.$v.secondPage.$touch()
        this.secondPage.showValidations = this.$v.secondPage.$anyError
        if (this.$v.secondPage.$anyError) {
          return
        }
        let param = {
          user: {
            email: this.baseInfo.email,
            password: this.baseInfo.createPwd,
            confirmPassword: this.baseInfo.confirmPwd,
            givenName: this.baseInfo.firstName,
            familyName: this.baseInfo.lastName,
            phoneNumber: this.baseInfo.phoneNumber
          },
          brand: {
            name: this.secondPage.brandName,
            website: this.secondPage.brandWeb,
            about: this.secondPage.describleBrand,
            addresses: [
              {
                label: "hq",
                streetAddressLine_1: this.secondPage.streetAddr,
                streetAddressLine_2: this.secondPage.streetAddr2,
                city: this.secondPage.city,
                region: this.secondPage.stateRegion,
                postalCode: this.secondPage.postalCode,
                country: {
                  id: this.secondPage.country
                },
                hq: true,
                defaultShipping: false
              }
            ]
          }
        }
        this.$store
          .dispatch("brand/brandRegister", param)
          .then(response => {
            console.log(response)
            this.isNotRegistered = false
          })
          .catch(err => {
            // if (err.response && err.response.data) {
            //   let errArrays = err.response.data.underlying
            //   let msg = ""
            //   for (let i = 0; i < errArrays.length; i++) {
            //     msg += errArrays[i].userMessage
            //     if (i < errArrays.length - 1) {
            //       msg += ","
            //     }
            //   }
            //   this.$message.error(msg)
            // } else {
            this.$message.error(err.message)
            // }
            this.isNotRegistered = true
          })
      } else {
        this.$message.info("Please choose to agree the policy, then can register brand.")
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@font-face
  font-family: "Playfair Display Bold"
  src: url("~@/assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype")

@font-face
  font-family: "Proximanova Semibold"
  src: url("~@/assets/fonts/Proxima-Nova-Semibold.otf") format("truetype")

@font-face
  font-family: "ProvimaNova-Regular"
  src: url("~@/assets/fonts/Proxima-Nova-Regular.otf") format("truetype")

.thanks-item>h1
  text-align: center
  font-size: 28px
  font-family: 'Proxima Nova'
.thanks-item>p
  text-align: center
  line-height: 28px
.form-group
  margin-bottom: 14px
.text-center
  text-align: center
.form-check
  display:-ms-flexbox
  display:flex
  -ms-flex-align:center
  align-items:center
  -ms-flex-pack:center
  justify-content:center
  width:auto
  padding-left:0

.header-brand
  position: relative
  height: 544px
  background: url('~@/assets/img/banner/Rectangle25.png') center bottom/ auto no-repeat
  .banner-text
    position: absolute
    top: 200px
    right: 10%
    color: #12433D
    .banner-title
      font-family:"Playfair Display Bold"
      font-size: 55px
      text-align: right
    @media (max-width: 768px)
      .banner-title
        text-align: center
    .banner-subtitle
      font-size: 28px
      font-family:"Proximanova Semibold"
    .banner-content
      font-size: 16px
      font-family: ProximaNova-Regular
      text-align: right
      color: rgb(0,0,0)
    .banner-link
      font-size: 18px
      color: #000000
      bottom: -130px
      position: absolute
      right: 0px
      font-family: 'ProximaNova', 'Roboto', sans-serif
      > a
        color: rgb(255, 97, 50)
        font-weight: bold
  @media (max-width:768px)
    .banner-text
      width: 100%
      text-align: center
      right: 0
      top: 100px
      padding: 30px 10%
      .banner-content
        text-align: center
      .banner-link
        position: initial

.brand-flyer
  background: #E2EEEC
  text-align: center
  padding-top: 80px
  padding-bottom: 40px
  .flyer-title
    font-family: "Playfair Display Bold"
    font-size: 48px
    line-height: 52px
    color: #12433D
  .flyer-text
    margin-top: 24px
    font-family: "ProvimaNova-Regular",sans-serif
    font-size: 18px
    line-height: 27px
    color: #000000
  .ant-btn
    margin-top: 24px
    margin-bottom: 32px
    border: 2px solid #FF6132
    background-color: transparent
    width: 268px
    color: #000
    font-size: 16px
    height: 43px
    &:hover
      background-color: #FF6132
      color: white

.ant-input
  border-radius: 0px

.brand-body
  margin-bottom: 100px

.register-body
    margin-top:60px
    display: flex
    flex-wrap: wrap
    .register-item
      flex:0 0 50%
      align-self: flex-start
      overflow: hidden
      position: relative
      .slider-content
        text-align: center
        > a
          display: inline-flex
          width: 15px
          height: 15px
          background: black
          border: 3px solid #eee
          text-decoration: none
          align-items: center
          justify-content: center
          margin: 5px 5px
          border-radius: 50%
          &:last-child
            background: #eee
      .title-font
        font-size: 28px
        font-family: 'Playfair Display Bold'
        line-height: 37px
        color: #12433D
      .register-list
        list-style-type: disc
        list-style-position: inside
        font-family: 'ProximaNova-Regular', 'Roboto', sans-serif
        color: #000000
        font-size: 16px
        line-height: 26px
        > li
          margin-bottom: 15px

      .fillup-title
        font-family: "ProvimaNova-Regular"
        font-size: 28px
        line-height: 33px
        color: #333333
        text-align: center
      .fillup-text
        font-family: "ProvimaNova-Regular"
        font-size: 16px
        line-height: 28px
        color: #333333
        text-align: center
      .ant-btn
        margin-top: 24px
        border-color: #FF6132
        background-color: #FF6132
        width: 280px
        color: #fff
        font-size: 16px
        height: 43px
      .form-body
        .ant-form-item
          font-family: 'Proxima'
          margin-bottom: 0px
        .ant-row
          justify-content: center
        .slide-button
          text-align: center

    @media (max-width:768px)
      .register-item:first-child
        flex:0 0 100%
        max-width: 100%
        text-align: center
      .register-item:last-child
        flex:0 0 100%
        max-width: 100%
        padding-top: 120px

.carousel-body
    display: -webkit-box
    overflow-x: hidden
    scroll-snap-type: x mandatory
    scroll-behavior: smooth
    > div
      scroll-snap-align: start
</style>
