<template lang="pug">
.categories-filter
  .categories-filter__item(v-for="category in categories" :key="category.id")
    .categories-filter__item--title(@click="makeCategoryActive(category.id)") {{ category.name}}
</template>

<script>
export default {
  props: { categoryFilterItems: { type: Array, default: () => [] } },
  computed: {
    categories() {
      return this.$store.getters["reference/categories"]
    }
  },
  methods: {
    makeCategoryActive(categoryId) {
      this.categoryFilterItems.find(category => category.id === categoryId).checked = true
      this.$emit("filterSwitch")
    }
  }
}
</script>

<style lang="sass">
.categories-filter
  padding-top: 10px
  padding-bottom: 10px

.categories-filter__item--title
  cursor: pointer
  font-size: 14px
  font-weight: 300
  &:hover
    font-weight: 700
</style>
