//
<template lang="pug">
// .admin-order
//   .admin-order-navigation
//     span {{ `Reference ID #${order.retailerReferenceId}` }}
//     span {{ `Batch ID #${order.batchId}` }}
//   .admin-order-id {{$t('Admins.AdminOrder.orderID')}} {{ order.id }}

//   OrderStatus(:order="order")

//   .admin-order-status-banner
//     .columns
//       .column.is-narrow.admin-order-status-banner__icon
//         img(src="@/assets/img/orders/goal-icon.svg")
//       .column.is-narrow
//         .admin-order-status-banner__title {{$t('Admins.AdminOrder.adminOrderStatusBannerTitle')}} {{ retailer.name }}.
//         .admin-order-status-banner__details {{$t('Admins.AdminOrder.adminOrderStatusBannerDetails')}}

//   .columns
//     .column.is-two-thirds.admin-order-table__container
//       .columns.is-multiline
//         .column.is-12
//           .admin-order-table
//             p.admin-order-table__name {{$t('Admins.AdminOrder.products')}}
//             table.table.is-fullwidth
//               thead
//                 tr
//                   th.admin-order-table__head {{$t('Admins.AdminOrder.sku')}}
//                   th.admin-order-table__head {{$t('Admins.AdminOrder.name')}}
//                   th.admin-order-table__head {{$t('Admins.AdminOrder.qty')}}
//                   th.admin-order-table__head {{$t('Admins.AdminOrder.testers')}}
//                   th.admin-order-table__head {{$t('Admins.AdminOrder.msrp')}}
//                   th.admin-order-table__head {{$t('Admins.AdminOrder.unitPrice')}}
//                   th.admin-order-table__head {{$t('Admins.AdminOrder.amt')}}
//               tbody
//                 tr(v-for="item in filteredItems")
//                   td.admin-order-table__cell {{ productById(item.product.id).sku }}
//                   td.admin-order-table__cell {{ productById(item.product.id).name }}
//                   td.admin-order-table__cell {{ item.quantity }}
//                   td.admin-order-table__cell -
//                   td.retailer-order-table__cell {{ item.recordedMsrpUsd }}
//                   td.retailer-order-table__cell {{ priceFormat(item.calculatedWholesalePriceUsd) }} ({{ item.recordedMargin }}%)
//                   td.retailer-order-table__cell {{ priceFormat(item.calculatedWholesalePriceUsd * item.quantity) }}
//             .admin-order-calculations
//               .columns.is-multiline.is-gapless
//                 .column.is-10
//                   .admin-order-calculations__subtotal {{$t('Admins.AdminOrder.itemsSubtotal')}} 
//                 .column.is-2
//                   .admin-order-calculations__subtotal {{ priceFormat(order.calculatedWholesaleCostUsd) }}
//                 .column.is-10
//                   .admin-order-calculations__subtotal {{$t('Admins.AdminOrder.damagesBudget')}} ({{ retailer.terms.damagesBudget }}%)
//                 .column.is-2
//                   .admin-order-calculations__subtotal {{ priceFormat(damagesBudget()) }}
//                 .column.is-10
//                   .admin-order-calculations__full-subtotal.admin-order-calculations__full-subtotal-title {{$t('Admins.AdminOrder.orderAmount')}}
//                 .column.is-2
//                   .admin-order-calculations__full-subtotal {{ priceFormat(orderAmount()) }}

//               .has-text-right
//                 div(@click='showPopup = !showPopup')
//                   span.admin-order-calculations__payout-icon
//                     img(src="@/assets/img/orders/info-icon.svg")
//                   span.admin-order-calculations__payout {{$t('Admins.AdminOrder.estimatedPayout')}} 
//                     span.admin-order-calculations__payout-popup(:class="{ show: showPopup }")
//                       .columns.is-marginless
//                         .column.has-text-left
//                           .admin-order-calculations__popup-item-name {{$t('Admins.AdminOrder.itemSubtotal')}} 
//                         .column.has-text-right
//                           .admin-order-calculations__popup-item-value {{ priceFormat(order.calculatedWholesaleCostUsd) }}

//                       .admin-order-calculations__popup-fees
//                         .columns.is-multiline
//                           .column.is-3.has-text-left
//                             .admin-order-calculations__popup-item-name {{$t('Admins.AdminOrder.landingFees')}} 
//                           .column.is-6.has-text-left
//                             .admin-order-calculations__popup-item-details {{$t('Admins.AdminOrder.landingcomission')}} 
//                           .column.is-3.has-text-right
//                             .admin-order-calculations__popup-item-value ({{ priceFormat(landingCommission()) }})
//                           .column.is-3
//                           .column.is-6.has-text-left
//                             .admin-order-calculations__popup-item-details {{$t('Admins.AdminOrder.landingFulfillmntCharge')}} 
//                           .column.is-3.has-text-right
//                             .admin-order-calculations__popup-item-value ({{ priceFormat(fulfillment()) }})
//                           .column.is-3.has-text-left
//                             .admin-order-calculations__popup-item-name {{$t('Admins.AdminOrder.retailerFees')}} 
//                           .column.is-6.has-text-left
//                             .admin-order-calculations__popup-item-details {{$t('Admins.AdminOrder.marketingCoop')}} 
//                           .column.is-3.has-text-right
//                             .admin-order-calculations__popup-item-value ({{ priceFormat(marketingCoOp()) }})
//                           .column.is-3
//                           .column.is-6.has-text-left
//                             .admin-order-calculations__popup-item-details {{$t('Admins.AdminOrder.shippingChargeUS')}} 
//                           .column.is-3.has-text-right
//                             .admin-order-calculations__popup-item-value ({{ priceFormat(shippingCharge()) }})
//                           .column.is-3
//                           .column.is-6.has-text-left
//                             .admin-order-calculations__popup-item-details {{$t('Admins.AdminOrder.damagesBudget')}} 
//                           .column.is-3.has-text-right
//                             .admin-order-calculations__popup-item-value ({{ priceFormat(damagesBudget()) }})
//                           .column.has-text-right
//                             .admin-order-calculations__popup-item-value
//                               span.admin-order-calculations__popup-item-details {{$t('Admins.AdminOrder.chargeSubtotal')}} 
//                               span ({{ priceFormat(chargeSubtotal()) }})
//                       .columns
//                         .column.has-text-left
//                           .admin-order-calculations__popup-item-name {{$t('Admins.AdminOrder.estimatedPayout')}} 
//                         .column.has-text-right
//                           .admin-order-calculations__popup-item-value {{ priceFormat(estimatedPayout()) }}

//         .column.is-12
//           OrderActivity(v-if="order" :user="brand" :brand="brand" :retailer="retailer" :order="order")

//     .column
//       .columns.is-multiline
//         .column.is-12(v-if="isPostpayBrand() && isTrackingStatuses()")
//           .admin-order-information
//             .admin-order-information__container
//               .admin-order-information__title {{$t('Admins.AdminOrder.tracking')}} 
//               .admin-order-information__header {{$t('Admins.AdminOrder.shippingCarrier')}} 
//               p {{$t('Admins.AdminOrder.ups')}} 
//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.trackingNumber')}} 
//               p.admin-order-information__track-number {{$t('Admins.AdminOrder.trackingNumberEx')}} 
//         .column.is-12
//           .admin-order-information
//             .admin-order-information__container
//               .columns
//                 .column
//                   .admin-order-information__title {{$t('Admins.AdminOrder.shipping')}} 
//                   .admin-order-information__header {{$t('Admins.AdminOrder.buyer')}} 
//                   .admin-order-information__link {{ retailer.name }}
//                 .column.is-narrow
//                   .admin-order-information__logo
//                     img(:src="logoUrl(retailer)")
//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.shippingAddress')}} 
//               template(v-if="order.shipToAddress")
//                 p {{ order.shipToAddress.addressee }}
//                 p {{ order.shipToAddress.streetAddressLine_1 }}, {{ order.shipToAddress.streetAddressLine_2}}
//                 p {{ order.shipToAddress.city }}, {{ order.shipToAddress.region }} {{ order.shipToAddress.postalCode }}, {{ order.shipToAddress.country.id }}

//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.contactInfo')}} 
//               p(v-if="order.contact") {{ order.contact.name }}
//               p(v-if="order.contact && order.contact.phone") {{ order.contact && order.contact.phone }}
//               p(v-else) {{$t('Admins.AdminOrder.phoneNotProvided')}} 

//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.shipDate')}} 
//               p {{ order.shipDate }}
//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.cancelDate')}} 
//               p {{ order.cancelDate }}
//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.estimatedPaymentDate')}} 
//               p {{ order.calculatedPaidToBrandDate }}

//         .column.is-12
//           .admin-order-information.admin-order-information__terms
//             .admin-order-information__container
//               .admin-order-information__title {{$t('Admins.AdminOrder.terms')}} 
//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.paymentTerms')}} 
//               p {{ retailer.terms.paymentTerm.name }}
//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.shippingTermsUS')}} 
//               p {{$t('Admins.AdminOrder.brandPays')}} 
//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.additionalTerms')}} 
//               p {{$t('Admins.AdminOrder.requiresUSFulfillment')}} 
//             .admin-order-information__container
//               .admin-order-information__header {{$t('Admins.AdminOrder.requirements')}} 
//               p {{$t('Admins.AdminOrder.margin')}}: {{ parseInt(retailer.terms.margin) }}%
//               p {{$t('Admins.AdminOrder.marketingCo_Op')}} : {{ retailer.terms.marketingCoOp }}%
//               p {{$t('Admins.AdminOrder.damagesBudget')}} : {{ retailer.terms.damagesBudget }}%
//               p(v-if="retailer.terms.productTicketing") {{$t('Admins.AdminOrder.productTicketing')}} 
//               p(v-if="retailer.terms.ediRequired") {{$t('Admins.AdminOrder.retailerEDI')}} 
//               p(v-if="retailer.terms.routingGuideRequired") {{$t('Admins.AdminOrder.routingGuide')}} 
// </template>

//
<script>
// import OrderActivity from "@/components/orders/OrderActivity"
// import OrderStatus from "@/components/brands/orders/OrderStatus"

// export default {
//   components: {
//     OrderActivity,
//     OrderStatus
//   },
//   data() {
//     return {
//       filteredItems: [],
//       showPopup: false
//     }
//   },
//   computed: {
//     order() {
//       return this.$store.getters["order/order"]
//     },
//     retailer() {
//       return this.$store.getters["retailer/retailer"]
//     },
//     brand() {
//       return this.$store.getters["brand/brand"]
//     }
//   },
//   async created() {
//     await this.$store.dispatch("order/getOrder", this.$route.params.id)
//     this.filteredItems = this.order.items.filter(item => item.quantity != 0)
//     const productIds = this.filteredItems.map(x => x.product.id)
//     await this.$store.dispatch("product/getProductsByIds", { productIds })

//     await this.$store.dispatch("retailer/fetchRetailer", this.order.retailer.id)
//     await this.$store.dispatch("brand/getBrand", this.order.brand.id)
//   },
//   methods: {
//     productById(productId) {
//       return this.$store.getters["product/findInCartProducts"](productId) || {}
//     },
//     logoUrl(user) {
//       return user.logoAsset && user.logoAsset.url
//     },
//     formattedDate() {
//       return new Date(this.order.createdAt)
//     },
//     isTrackingStatuses() {
//       return (
//         this.order.status && this.order.status.id != "approved" && this.order.status.id != "pending"
//       )
//     },
//     isPostpayBrand() {
//       return (
//         this.order.recordedBrandPaymentTerm && this.order.recordedBrandPaymentTerm.id === "postpay"
//       )
//     },
//     damagesBudget() {
//       return (
//         this.retailer.terms.damagesBudget &&
//         parseFloat(
//           (this.retailer.terms.damagesBudget / 100) * this.order.calculatedWholesaleCostUsd
//         )
//       )
//     },
//     marketingCoOp() {
//       return (
//         this.retailer.terms.marketingCoOp &&
//         parseFloat(
//           (this.retailer.terms.marketingCoOp / 100) * this.order.calculatedWholesaleCostUsd
//         )
//       )
//     },
//     orderAmount() {
//       return parseFloat(this.order.calculatedWholesaleCostUsd) + this.damagesBudget()
//     },
//     landingCommission() {
//       if (this.order.charges) {
//         this.order.charges.find(charge => charge.chargeType.id === "commission").value
//       }
//       return this.order.calculatedWholesaleCostUsd * 0.1
//     },
//     fulfillment() {
//       if (this.order.charges) {
//         this.order.charges.find(charge => charge.chargeType.id === "fulfillment").value
//       }
//       return this.order.calculatedWholesaleCostUsd * 0.125
//     },
//     shippingCharge() {
//       return this.order.calculatedWholesaleCostUsd && this.order.calculatedWholesaleCostUsd * 0.05
//     },
//     chargeSubtotal() {
//       return (
//         parseFloat(this.landingCommission()) +
//         parseFloat(this.fulfillment()) +
//         parseFloat(this.marketingCoOp()) +
//         parseFloat(this.shippingCharge()) +
//         parseFloat(this.damagesBudget())
//       )
//     },
//     estimatedPayout() {
//       return (
//         this.order.calculatedWholesaleCostUsd &&
//         parseFloat(this.order.calculatedWholesaleCostUsd) - this.chargeSubtotal()
//       )
//     },

//     priceFormat(price) {
//       const numberFormat = new Intl.NumberFormat("en-US", {
//         style: "currency",
//         currency: "USD"
//       })
//       return numberFormat.format(price)
//     }
//   }
// }
//
</script>

//
<style lang="sass">
// .admin-order
//   padding: 40px 80px

//   &-navigation
//     font-size: 14px
//     color: #4A90E2

//     span:first-child
//       padding-right: 20px

//   &-id
//     padding-top: 10px
//     padding-bottom: 10px
//     font-size: 28px

//   &-table
//     border: 1px solid #E0E0E0
//     padding: 20px

//     &__name
//       color: #333333
//       font-size: 16px
//       font-weight: 500
//       letter-spacing: 0.25px
//       margin-bottom: 30px
//       margin-left: 10px

//     &__head
//       color: #888888 !important
//       font-size: 14px
//       font-weight: 500
//       border-bottom: 1px solid #E0E0E0 !important

//     &__cell
//       color: #333333 !important
//       font-size: 14px
//       font-weight: 500
//       border-bottom: 1px solid #E0E0E0 !important
//       margin-top: 10px
//       position: relative

//     &__container
//       margin-right: 20px

//   &-information
//     border: 1px solid #E0E0E0

//     &__title
//       color: #333333
//       font-size: 16px
//       padding-bottom: 20px

//     &__container
//       padding: 20px
//       border-bottom: 1px solid #E0E0E0

//     &__link
//       color: #006FBB
//       font-weight: 600
//       font-size: 14px

//     &__header
//       text-transform: uppercase
//       color: #888888
//       font-size: 12px
//       padding-bottom: 15px

//     &__track-number
//       color: #4A90E2

//     &__logo
//       width: 30px
//       height: 30px

//     &__terms
//       margin-top: 20px

//   &-status-banner
//     background-color: #FFFBFA
//     border: 1px solid #E0E0E0
//     border-radius: 0 0 3px 3px
//     border-top: 3px solid #9A8A8A
//     height: 80px
//     margin-bottom: 20px
//     padding-left: 20px

//     &__title
//       padding-top: 10px
//       font-size: 16px
//       color: #212B36

//     &__details
//       font-size: 14px
//       color: #212B36

//     &__icon
//       display: flex
//       padding-right: 10px

//   &-calculations

//     &__subtotal
//       display: flex
//       justify-content: flex-end
//       padding-bottom: 20px

//     &__full-subtotal
//       display: flex
//       justify-content: flex-end
//       border-top: 1px solid #E0E0E0
//       padding-top: 15px

//     &__full-subtotal-title
//       font-weight: bold
//       color: #333333

//     &__payout-icon
//       padding-right: 5px
//       cursor: pointer

//     &__payout
//       position: relative
//       display: inline-block
//       cursor: pointer
//       -webkit-user-select: none
//       -moz-user-select: none
//       -ms-user-select: none
//       user-select: none

//     &__payout &__payout-popup
//       visibility: hidden
//       width: 387px
//       background-color: white
//       color: #333333
//       text-align: center
//       padding: 8px 0
//       position: absolute
//       right: 75%
//       top: 100%
//       z-index: 1
//       border: 1px solid #E0E0E0

//     &__payout &__payout-popup::after
//       content: ""
//       position: absolute
//       bottom: 100%
//       left: 89%
//       margin-left: -5px
//       border-width: 7px
//       border-style: solid
//       border-color: transparent transparent #E0E0E0 transparent

//     &__payout .show
//       padding: 20px
//       visibility: visible
//       -webkit-animation: fadeIn 1s
//       animation: fadeIn 1s

//     &__popup-fees
//       border-top: 1px solid #979797
//       border-bottom: 1px solid #979797
//       padding-top: 15px
//       padding-bottom: 15px
//       margin-bottom: 10px

//     &__popup-item-name
//       font-size: 12px
//       color: #333333
//       font-weight: bold

//     &__popup-item-details
//       font-size: 12px
//       color: #333333
//       font-weight: 500

//     &__popup-item-value
//       font-size: 14px
//       color: #333333
//       font-weight: bold

//       span:first-child
//         padding-right: 8px
//
</style>

<template lang="pug">
OrderDetails(:orderId="orderId")
</template>

<script>
import OrderDetails from "@/components/orders/OrderDetails"
export default {
  components: {
    OrderDetails
  },
  data() {
    return {
      orderId: this.$route.params.id
    }
  }
}
</script>
