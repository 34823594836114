<template>
    <div class="new-brand-registration">
        <div class="new-brand-registration" v-if="plans && plans.length">
            <div class="left">
                <div v-if="isRequiredSignUp" class="account">
                    <div class="title">
                        <a-avatar class="num-symbol">1</a-avatar>
                        Create Your Account
                    </div>

                    <SignUp class="sign-up" ref="signUp"></SignUp>
                </div>
                <div class="frequence">
                    <div class="title">
                        <a-avatar class="num-symbol">2</a-avatar>
                        Select Billing Frequency
                    </div>
                    <a-radio-group v-model="selectedPlanFrequency" @change="changeBillingFrequence"
                        v-if="selectedPlan != void (0)" class="plan-freq-group">
                        <a-radio v-for="(billFreq, index) in selectedPlan.billFrequencies" :key="index"
                            class="plan-freq" :value="billFreq">
                            <div class="fx-c left">
                                <span class="title">{{ billFreq.name }} Plan</span>
                                <div>
                                    <span class="savings" :class="{ 'savings-on': billFreq.discountPercentage > 0 }">{{
                                        billFreq.discountPercentage |
                                        toPercentage }} SAVINGS</span>
                                </div>

                            </div>
                            <div class="col-spacer"></div>
                            <div v-if="billFreq.discountPercentage > 0" class="save-up-to">save up to {{
                                (selectedPlan.amount *
                                    billFreq.discountPercentage) | toCurrency }}</div>

                            <div class="fx-c right">
                                <div class="payment-amount">
                                    <span>{{ (selectedPlan.amount * (1 - billFreq.discountPercentage)) /
                                        billFreq.numOfPayment |
                                        toCurrency
                                        }}</span><span class="freq">/ {{ billFreq.name | toNoAdv }}</span>
                                </div>

                                <div class="num-payment">{{ billFreq.numOfPayment | toPaymentMsg }}</div>
                            </div>

                        </a-radio>
                    </a-radio-group>

                </div>
                <div class="payment">
                    <div class="title">
                        <a-avatar class="num-symbol">3</a-avatar>
                        Select Payment Method
                    </div>
                    <a-radio-group v-model="paymentMethod" class="payment-method">
                        <a-radio :value="paymentMethodType.creditCard" style="margin-right: 24px">{{ this.brandId !=
                            void
                            (0)
                            ? 'New Card' : 'Credit Card' }}</a-radio>
                        <a-radio v-if="showExistCardOption()" :value="paymentMethodType.existCards"
                            style="margin-right: 24px">Credit Card</a-radio>
                        <a-radio :value="paymentMethodType.transfer">Wire Transfer</a-radio>
                    </a-radio-group>
                    <Payment v-if="paymentMethod === paymentMethodType.creditCard" class="payment" ref="payment">
                    </Payment>
                    <PaymentExistCards v-if="paymentMethod === paymentMethodType.existCards"
                        :payment-profiles="paymentProfiles" ref="paymentProfiles">
                    </PaymentExistCards>
                    <PaymentTransfer v-if="paymentMethod === paymentMethodType.transfer" ref="transfer">
                    </PaymentTransfer>
                </div>
            </div>
            <div class="right">
                <div class="bg subscription" v-if="selectedPlan != void (0)">
                    <div class="title">Your Subscription</div>
                    <div class="col2"><span>Your Plan: {{ selectedPlan.name }}</span><span
                            class="col-spacer"></span><span>{{
                                selectedPlan.amount | toCurrency
                            }}</span>
                    </div>
                    <div><a-dropdown>
                            <a class="ant-dropdown-link change-plan" @click.prevent>
                                CHANGE PLAN <a-icon type="down"></a-icon>
                            </a>
                            <template #overlay>
                                <a-menu>
                                    <a-menu-item v-for="(item, index) in plans" :key="index" @click="changePlan(item)">
                                        <span>{{ item.name }}</span>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                        </a-dropdown></div>
                    <div v-if="appliedCoupon != null" class="col2">
                        <span>Promo Code - {{ appliedCoupon.name }}</span>
                        <span class="col-spacer"></span>
                        <span>({{ totalCouponDiscountAmount | toCurrency }})</span>
                    </div>
                    <a-divider />
                    <div class="col2 sub-total">
                        <span>Subtotal</span><span class="col-spacer"></span><span>{{ subTotal |
                            toCurrency }}</span>
                    </div>

                    <div class="col2 discount-amount" v-if="paymentTermDiscountAmount > 0">
                        <span>{{ selectedPlanFrequency | toSavingLabel }}</span>
                        <span class="col-spacer"></span>
                        <span>({{ paymentTermDiscountAmount | toCurrency }})</span>
                    </div>


                    <div class="col2 total-after-discount">
                        <span>Total after Discount</span>
                        <span class="col-spacer"></span>
                        <span>{{ (totalPaymentAmount) | toCurrency }}</span>
                    </div>
                    <a-divider />
                    <div class="col2"><span>Payment Due Today</span><span class="col-spacer"></span><span>{{
                        paymentTermAmount |
                        toCurrency }}</span></div>
                </div>
                <div class="bg promo-code">
                    <PromoCode @promoCoupon="promoCouponChange"></PromoCode>
                </div>

                <div>
                    <p class="agree-terms-condition">
                        <a-checkbox v-model="isAgreeTerms">
                            <span> By clicking on this button, you accept the <a
                                    href="https://www.landinginternational.com/terms-of-use/" target="_blank"
                                    class="nav-link">Terms
                                    &amp; Conditions</a> and <a
                                    href="https://www.landinginternational.com/privacy-policy" target="_blank"
                                    class="nav-link">Privacy Policy</a></span>
                        </a-checkbox>
                    </p>

                    <div class="has-error" v-if="isSubmit && !isAgreeTerms">Please accept the terms and privcy policy
                        to proceed
                    </div>
                </div>
                <!-- <a-form layout="horizontal" :form="form">



                <a-form-item class="agree-terms-condition">
                    <a-checkbox v-decorator="[
                        'agreement',
                        {
                            valuePropName: 'checked',
                            rules: [
                                // {
                                //   required: true,
                                //   message:
                                //     'Please accept the terms and privcy policy to proceed',
                                // },
                                {
                                    validator: validateAgreement,
                                },
                            ],
                        },
                    ]">
                        By clicking on this button, you accept the <a
                            href="https://www.landinginternational.com/terms-of-use/" target="_blank"
                            class="nav-link">Terms
                            &amp; Conditions</a> and <a href="https://www.landinginternational.com/privacy-policy"
                            target="_blank" class="nav-link">Privacy Policy</a>
                    </a-checkbox>
                </a-form-item>
            </a-form> -->

                <div>
                    <a-button class="submit-style" :loading="isLoading"
                        @click="submit">SUBMIT&nbsp;YOUR&nbsp;SUBSCRIPTION
                    </a-button>
                    <!-- <p class="cancel-style" @click="clickToStepOne">CANCEL</p> -->
                </div>
            </div>
            <error-dialog ref="errorDialog" />
        </div>
        <div v-else>
            {{ noPlanAvaiableMsg }}
        </div>

    </div>

</template>

<script>
import SignUp from '@/views/Registrations/CreateAccount'
import Payment from "@/views/Registrations/Payment";
import PaymentExistCards from "@/views/Registrations/PaymentExistCards";
import PaymentTransfer from "@/views/Registrations/PaymentTransfer";
import PromoCode from "@/views/Registrations/PromoCode";
import SubscriptionsService from '@/services/subscriptions-service'

import { SubscriptionTypeEnum } from "@/models/SubscriptionTypeEnum";
const NoPlanAvaiableMessage = "There are no additional plans available for selection or upgrades at this moment. All existing plans are presently subscribed to.";
export default {
    components: {
        SignUp,
        Payment,
        PaymentExistCards,
        PaymentTransfer,
        PromoCode,

    },
    props: {
        isRequiredSignUp: { type: Boolean, default: true },
        brandId: { type: String, default: null }
    },
    data() {
        const paymentMethodType = {
            existCards: 'existCards',
            creditCard: 'creditCard',
            transfer: 'transfer'
        }
        return {
            isLoading: false,
            isSubmit: false,
            selectedNumOfPayment: 1,
            selectedPlan: null,
            selectedPlanFrequency: null,
            plans: [],
            appliedCoupon: null,
            totalAmount: 0,
            totalCouponDiscountAmount: 0,
            subTotal: 0,
            paymentTermDiscountAmount: 0,
            totalPaymentAmount: 0,
            paymentTermAmount: 0,
            isAgreeTerms: false,
            paymentMethodType,
            paymentMethod: paymentMethodType.creditCard,
            paymentProfiles: null,
            noPlanAvaiableMsg: '',

        }
    },
    filters: {
        toCurrency: (value) => {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
            });

            return formatter.format(value);
        },
        toPercentage: (value) => {
            if (typeof value !== 'number') {
                return value;
            }

            value *= 100;
            return value.toFixed() + '%';
        },
        toPaymentMsg: (value) => {
            return value == 1 ? "One-time payment" : `${value} payments`;
        },
        toNoAdv: (value) => {
            return `${value}`.replace('ly', '');
        },
        toSavingLabel: (value) => {
            let name = value.name;
            const discountPercentage = value.discountPercentage;
            const percentage = (discountPercentage * 100).toFixed();
            if (value.numOfPayment == 1) {
                name = 'Annual';
            }
            return `${name} Plan Savings - ${percentage}%`;
        },
    },
    beforeCreate() {
        // this.form = this.$form.createForm(this, { name: "agreements" });
    },
    async created() {

        if (this.brandId) {

            this.paymentProfiles = await SubscriptionsService.getPaymentMethods(this.brandId);
            if (this.showExistCardOption()) {
                this.paymentMethod = this.paymentMethodType.existCards;
            }
        }
        this.plans = this.filterPlans(await SubscriptionsService.getPlans(this.brandId));
        this.noPlanAvaiableMsg = NoPlanAvaiableMessage;
        const plan = this.getRoutePlan();
        this.selectedNumOfPayment = this.getRoutePaymentTerm();
        this.changePlan(plan);
        // console.log(this.$refs);
    },
    // mounted() {
    //     console.log(this.$refs.signUp);
    //     console.log(this.$refs.payment);
    // },
    methods: {
        filterPlans(plans) {
            const hasPremium = plans.some(p => p.subscriptionType.privateId === SubscriptionTypeEnum.Premium && p.isSubscribed);
            // if plan already subscribed filtered out
            // if premium plan only can choose other premium
            return plans.filter(p => {
                let isSubScribed = p.isSubscribed;
                if (isSubScribed) {
                    return false;
                }
                else {
                    return !hasPremium || p.subscriptionType.privateId === SubscriptionTypeEnum.Premium;
                }

            });
        },
        getCustomerPaymentProfileId() {
            let selectedCustomerPaymentProfileId = null;
            const refPaymentProfile = this.$refs.paymentProfiles;
            if (refPaymentProfile && this.paymentMethod === this.paymentMethodType.existCards) {
                selectedCustomerPaymentProfileId = refPaymentProfile.selectedCustomerPaymentProfileId
            }
            return selectedCustomerPaymentProfileId;
        },
        showExistCardOption() {
            return this.brandId != void (0) && this.paymentProfiles != null && this.paymentProfiles.length > 0;
        },
        async getAccontInfo() {
            const signupForm = this.$refs.signUp.form;
            return new Promise((resolve) => {
                signupForm.validateFields((err, values) => {
                    if (!err) {
                        resolve({
                            type: +this.type,
                            name: values.name,
                            website: values.url,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            password: values.password,
                        });
                    } else {
                        resolve(null);
                    }
                });
            })
        },
        async submit() {
            if (this.isLoading) return;
            const emptyString = '';
            this.isSubmit = true;
            let accountInfo = null;
            let subscriptionPayment = null;
            let paymentForm = null;
            let paymentTransferForm = null;
            let customerPaymentProfileId = this.getCustomerPaymentProfileId();;
            let paymentDetail = null;

            if (this.isRequiredSignUp) {
                accountInfo = await this.getAccontInfo();
            }
            if (this.paymentMethod === this.paymentMethodType.creditCard) {

                paymentForm = this.$refs.payment.form;
                paymentForm.validateFields((err, values) => {
                    if (!err) {

                        const billingAddress = {
                            addressLine1: values.address1,
                            addressLine2: values.address2 || emptyString,
                            city: values.city,
                            state: values.state,
                            zipCode: values.zipCode,
                            country: emptyString,
                        };

                        if (this.paymentMethod === this.paymentMethodType.creditCard) {
                            paymentDetail = {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                cardNumber: values.cardNumber,
                                cvvCode: values.cvvNumber,
                                expirationDate: values.expirationDate,
                                billingAddress,
                            };
                        }

                        subscriptionPayment = {
                            customerPaymentProfileId,
                            creditCardPayment: paymentDetail,
                            promoCode: this.appliedCoupon || emptyString,

                            discountAmount: this.discountAmount,

                        };

                    }
                });
            }
            else if (this.paymentMethod === this.paymentMethodType.existCards) {
                subscriptionPayment = {
                    customerPaymentProfileId,
                    creditCardPayment: paymentDetail,
                    promoCode: this.appliedCoupon || emptyString,
                    discountAmount: this.discountAmount,
                };
            }
            else {
                paymentTransferForm = this.$refs.transfer.form;
                paymentTransferForm.validateFields((err, values) => {
                    if (!err) {
                        const transferInfo = {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                        };
                        subscriptionPayment = {
                            customerPaymentProfileId,
                            wireTransferPayment: transferInfo,

                            promoCode: this.appliedCoupon || emptyString,

                            discountAmount: this.discountAmount,

                        };

                    }
                });
            }

            //console.log(accountInfo);
            //console.log(subscriptionPayment);


            if (this.isAgreeTerms &&
                (!this.isRequiredSignUp || accountInfo != null)
                && subscriptionPayment != null
                && this.selectedPlan != null
                && this.selectedPlanFrequency != null
            ) {
                this.isLoading = true;
                const data = {
                    accountInfo,
                    subscriptionPayment,
                    selectedPlan: this.selectedPlan,
                    selectedPlanFrequency: this.selectedPlanFrequency
                }
                const postData = {
                    brandId: this.isRequiredSignUp ? null : this.brandId,
                    signUp: this.isRequiredSignUp ? {
                        name: data.accountInfo.name,
                        website: data.accountInfo.website,
                        firstName: data.accountInfo.firstName,
                        lastName: data.accountInfo.lastName,
                        email: data.accountInfo.email,
                        password: data.accountInfo.password
                    } : null,
                    subscriptionDetails: {
                        type: data.selectedPlan.subscriptionType.privateId,
                        terms: data.selectedPlanFrequency.term.privateId,
                        retailerId: (data.selectedPlan.retailer && data.selectedPlan.retailer.id) || null,
                    },
                    subscriptionPayment: {
                        customerPaymentProfileId: data.subscriptionPayment.customerPaymentProfileId || null,
                        wireTransferPayment: data.subscriptionPayment.wireTransferPayment || null,
                        paymentDetail: data.subscriptionPayment.creditCardPayment || null,
                        promoCode: (data.subscriptionPayment.promoCode && data.subscriptionPayment.promoCode.code) || ''
                    },

                }
                await SubscriptionsService.signUp(postData).then(() => {
                    this.isLoading = false;
                    this.$emit("success");
                    this.$router.push({
                        name: "SubscribeSuccess"
                    })
                })
                    //.catch(e => this.$refs.errorDialog.openModel$(e.data || e))
                    .finally(() => this.isLoading = false)
            }
        },

        getRoutePlan() {
            let defaultPlan = this.plans[0];
            const plan = `${this.$route.params.plan}`;

            // after api ready need to change the map
            let planMapId = null;
            switch (plan.toLowerCase()) {
                case 'basic':
                    planMapId = 'basic';
                    break;
                case 'plus':
                    planMapId = 'plus';
                    break;
                case 'premium':
                    planMapId = 'premium';
                    break;
            }

            // should only have one plan beside premium
            let foundPlans = this.plans.filter(p => p.name.toLowerCase().includes(planMapId));

            if (foundPlans.length > 0) {
                if (foundPlans.length > 1) {
                    foundPlans = foundPlans.filter(p => p.name.toLowerCase().includes('jcp'));
                }

                if (foundPlans.length > 0) {
                    defaultPlan = foundPlans[0]
                }
            }

            return defaultPlan;
        },
        getRoutePaymentTerm() {
            const term = `${this.$route.params.term}`;
            // temp use this logic for the default route until api ready
            // console.log(plan)
            let numOfPayment = 1;
            switch (term.toLowerCase()) {
                case 'yearly':
                    numOfPayment = 1;
                    break;
                case 'quarterly':
                    numOfPayment = 4;
                    break;
                case 'monthly':
                    numOfPayment = 12;
            }
            return numOfPayment;
        },
        changePlan(plan) {
            this.selectedPlan = plan;
            this.changeFrequency(this.selectedNumOfPayment);

        },
        changeFrequency(numOfPayment) {
            this.selectedNumOfPayment = numOfPayment;
            this.selectedPlanFrequency = this.selectedPlan.billFrequencies.find(p => p.numOfPayment == numOfPayment);
            this.calculatePayment();
        },
        promoCouponChange(coupon) {
            this.appliedCoupon = coupon;
            this.calculatePayment();
        },
        calculatePayment() {
            this.totalAmount = 0;
            if (this.selectedPlan != void (0)) {
                this.totalAmount = this.selectedPlan.amount;
            }
            this.totalCouponDiscountAmount = 0;
            this.subTotal = 0;
            if (this.appliedCoupon != null && this.totalAmount > 0) {
                this.totalCouponDiscountAmount = this.appliedCoupon.isPercentage ? this.totalAmount * this.appliedCoupon.amount / 100 : this.appliedCoupon.amount;
                if (this.totalCouponDiscountAmount > this.totalAmount) {
                    this.totalCouponDiscountAmount = this.totalAmount;
                }
            }
            this.subTotal = this.totalAmount - this.totalCouponDiscountAmount;

            this.paymentTermDiscountAmount = 0;
            this.paymentTermAmount = 0;
            this.totalPaymentAmount = 0;
            if (this.selectedPlanFrequency != void (0)) {
                this.paymentTermDiscountAmount = this.subTotal * this.selectedPlanFrequency.discountPercentage;
                this.totalPaymentAmount = this.subTotal - this.paymentTermDiscountAmount;
                if (this.totalPaymentAmount > this.subTotal) {
                    this.totalPaymentAmount = this.subTotal;
                }
                this.paymentTermAmount = this.totalPaymentAmount / this.selectedPlanFrequency.numOfPayment;
            }
        },
        changeBillingFrequence() {
            this.calculatePayment();
        }

    }
}
</script>

<style lang="scss">
.new-brand-registration {
    font-family: 'Proxima Nova', 'Roboto', sans-serif;
    margin: 0 auto;
    display: flex;
    max-width: 1440px;
    gap: 80px;
    flex-flow: row wrap;
    justify-content: center;
    padding: 20px;

    .left {
        flex: 1 1;
    }

    .right {
        flex: 0 0 479px;
    }

    .num-symbol {
        color: #F15A42;
        background-color:
            rgba(237, 142, 131, .2);
        margin-right: 18px
    }

    .title {
        text-transform: uppercase;
        font-family: "Niveau Grotesk";
        font-size: 18px;
        font-size: 18px;
        font-weight: bold;
        color: rgb(26, 24, 24);

    }

    .change-plan {
        color: #F15A42;
        font-size: 13px;
        font-weight: bold
    }

    .right {
        .bg {
            background-color: rgba(207, 229, 204, 0.2);
            border-radius: 10px;
            padding: 42px;
            margin-bottom: 35px;
            font-family: "Proxima Nova", Roboto, sans-serif;
            font-size: 16px;
        }
    }

    .ant-divider {
        background-color: #f27c59;
    }

    .col2 {
        display: flex;
        width: 100%;


    }

    .sub-total,
    .discount-amount {
        margin-bottom: 12px;
    }

    .plan-freq-group {
        display: flex;
        flex-direction: column;

        .plan-freq {
            border: 1px solid rgba(26, 24, 24, .2);
            border-radius: 6px;
            color: #1a1818;
            display: flex;
            flex-direction: row;
            padding: 20px;
            margin-bottom: 16px;

            >span {
                display: flex;
                flex-direction: row;
                flex: 1 1 auto;
            }

            .payment-amount {
                font-size: 25px;
                font-family: DMSans;

                .freq {
                    font-weight: bold;
                    font-family: "Proxima Nova", Roboto, sans-serif;
                    font-size: 16px;
                    text-transform: lowercase;
                }

                .num-payment {
                    font-family: "Proxima Nova", Roboto, sans-serif;
                    font-size: 16px;
                }
            }


            .ant-radio {
                flex: 0;
                color: #f15a42;
            }

            .left,
            .right,
            .savings {

                flex: 0;
            }

            .right {
                padding-left: 10px;
                text-align: right;
            }

            .left {

                text-align: left;
            }

            .col-spacer {
                flex: 1 1 auto;
            }
        }

        .savings {
            border-radius: 30px;
            border: 1px solid #1a1818;
            color: #1a1818;
            padding: 3px 10px;
            font-size: 14px;
            font-weight: 700;
        }

        .savings-on {
            border: 1px solid #f15a42;
            color: #f15a42;
        }

        .save-up-to {
            background-color: rgba(250, 201, 44, .3);
            border-radius: 3px;
            font-weight: 700;
            font-size: 14px;
            height: 32px;
            color: #f15a42;
            line-height: 32px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    .col-spacer {
        flex: 1 1 auto;
    }

    .fx-c {
        display: flex;
        flex-direction: column;

    }

    .sign-up,
    .payment,
    .frequence {
        padding-bottom: 12px;
    }

    .submit-style {
        width: 480px;
        line-height: 57px;
        height: 57px;
        text-align: center;
        border-radius: 50px;
        background-color: #f15a42;
        color: #ffffff;
        margin-top: 45px;
        font-size: 18px;
        font-family: "Niveau Grotesk";
        cursor: pointer;
        margin-bottom: 29px;

        &:hover,
        &:focus {
            background-color: #f15a42;
            color: #ffffff;
        }
    }

    .cancel-style {
        width: 480px;
        text-align: center;
        font-size: 18px;
        font-family: DMSans;
        cursor: pointer;
    }

    .nav-link {
        color: rgba(0, 0, 0, .6);
        text-decoration: underline;
    }

    .has-error {
        color: #f5222d;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #f15a42 !important;
    }

    .ant-radio-checked::after {
        border: 1px solid #f15a42;
    }

    .ant-radio-inner::after {
        background-color: #f15a42 !important;
    }

    .ant-radio-wrapper {
        margin-top: -4px;
    }

    .payment-method {
        padding-left: 50px;
        padding-bottom: 30px;
        display: flex;
    }
}
</style>