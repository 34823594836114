import OrderStatus from "./OrderStatus";
import OrderAirShipment from "./OrderAirShipment";
import OrderItem from "./OrderItem";
import OrderStateTransition from "./OrderStateTransition";
import dateUtils from "@/utils/date-utils";
export default class Order {
  getTrackingNumber() {
    return this.airShipments != null
      ? this.airShipments.map((a) => a.trackingId).join(", ")
      : "";
  }
  getActualShipDate() {
    if (this.airShipments && this.airShipments.length > 0) {
      return this.airShipments[0].actualShipDate;
    }
    return null;
  }
  getPaymentDate() {
    const itemLength = this.stateTransitions.length;
    if (itemLength > 0) {
      let lastItem = this.stateTransitions[itemLength - 1];
      if (lastItem.isComplete) {
        return this.updatedAt;
      } else if (itemLength >= 2) {
        for (let i = itemLength - 1; i >= 0; i--) {
          if (this.stateTransitions[i].isComplete) {
            lastItem = this.stateTransitions[i];
            return lastItem.transitionedAt || lastItem.updatedAt;
          }
        }
      }
    }
    return null;
  }
  getOrderRetailerPayAmount() {
    let amount = Math.round(this.calculatedWholesaleCostUsd * 100) / 100;
    if (this.charges && this.charges.length > 0) {
      for (let i = 0; i < this.charges.length; i++) {
        let charge = this.charges[i];
        const calculatedCostUsd =
          Math.round(charge.calculatedCostUsd * 100) / 100;
        if (
          charge.ownedByClientTypePrivateId == 4 &&
          charge.paidByClientTypePrivateId == 3
        ) {
          amount -= calculatedCostUsd;
        } else if (
          charge.ownedByClientTypePrivateId == 3 &&
          charge.paidByClientTypePrivateId == 4
        ) {
          amount += calculatedCostUsd;
        }
      }
    }

    return amount;
  }

  getOrderBrandPayAmount() {
    let total = 0;
    if (this.charges && this.charges.length > 0) {
      for (let i = 0; i < this.charges.length; i++) {
        let charge = this.charges[i];
        const calculatedCostUsd =
          Math.round(charge.calculatedCostUsd * 100) / 100;

        if (charge.ownedByClientTypePrivateId == 1) {
          if (charge.paidByClientTypePrivateId == 4) {
            total -= calculatedCostUsd;
          } else {
            total += calculatedCostUsd;
          }
        }
        if (
          charge.ownedByClientTypePrivateId == 3 &&
          charge.paidByClientTypePrivateId == 1
        ) {
          total += calculatedCostUsd;
        }
      }
    }
    return total;
  }
  constructor(model) {
    this.privateId = 0;
    this.id = "";
    this.version = 0;
    this.statusPrivateId = 0;
    this.osvTag = 0;
    this.isComplete = 0;
    this.isDeclined = 0;
    this.isRelatedInventory = 0;
    this.inventoryTagPrivateId = null;
    this.batchId = null;
    this.brandId = "";
    this.retailerId = "";
    this.isBrandAccepted = null;
    this.isLandingAccepted = null;
    this.isRetailerAccepted = 0;
    this.brandReferenceId = null;
    this.landingReferenceId = null;
    this.retailerReferenceId = null;
    this.recordedBrandPaymentTermPrivateId = null;
    this.recordedRetailerPaymentTermPrivateId = 0;
    this.invoiceId = null;
    this.paymentAdviceNote = null;
    this.paymentAdviceStatus = 0;
    this.orderDate = null;
    this.shipDate = null;
    this.cancelDate = null;
    this.calculatedPaidToBrandDate = null;
    this.calculatedPaidByRetailerDate = null;
    this.calculatedRetailCostUsd = 0;
    this.calculatedWholesaleCostUsd = 0;
    this.calculatedCommission = 0;
    this.shipToAddressee = null;
    this.shipToStreetAddressLine1 = null;
    this.shipToStreetAddressLine2 = null;
    this.shipToCity = null;
    this.shipToRegion = null;
    this.shipToPostalCode = null;
    this.shipToCountryCode = null;
    this.contactName = null;
    this.contactPhone = null;
    this.createdAt = new Date();
    this.createdBy = null;
    this.updatedAt = null;
    this.updatedBy = null;
    this.status = null;
    this.airShipments = [];
    this.items = [];
    this.trackingNumber = "";
    this.actualShipDate = null;
    this.paymentDate = null;
    this.charges = [];
    this.payment = 0;
    if (model) {
      this.privateId = model.privateId;
      this.id = model.id;
      this.version = model.version;
      this.statusPrivateId = model.statusPrivateId;
      this.osvTag = model.osvTag;
      this.isComplete = model.isComplete;
      this.isDeclined = model.isDeclined;
      this.isRelatedInventory = model.isRelatedInventory;
      this.inventoryTagPrivateId = model.inventoryTagPrivateId;
      this.batchId = model.batchId;
      this.brandId = model.brandId;
      this.retailerId = model.retailerId;
      this.isBrandAccepted = model.isBrandAccepted;
      this.isLandingAccepted = model.isLandingAccepted;
      this.isRetailerAccepted = model.isRetailerAccepted;
      this.brandReferenceId = model.brandReferenceId;
      this.landingReferenceId = model.landingReferenceId;
      this.retailerReferenceId = model.retailerReferenceId;
      this.recordedBrandPaymentTermPrivateId =
        model.recordedBrandPaymentTermPrivateId;
      this.recordedRetailerPaymentTermPrivateId =
        model.recordedRetailerPaymentTermPrivateId;
      this.invoiceId = model.invoiceId;
      this.paymentAdviceNote = model.paymentAdviceNote;
      this.paymentAdviceStatus = model.paymentAdviceStatus;
      this.orderDate = dateUtils.parseDateOnly(model.orderDate);
      this.shipDate = dateUtils.parseDateOnly(model.shipDate);
      this.cancelDate = dateUtils.parseDateOnly(model.cancelDate);
      this.calculatedPaidToBrandDate = dateUtils.parseDateOnly(
        model.calculatedPaidToBrandDate
      );
      this.calculatedPaidByRetailerDate = dateUtils.parseDateOnly(
        model.calculatedPaidByRetailerDate
      );
      this.calculatedRetailCostUsd = model.calculatedRetailCostUsd;
      this.calculatedWholesaleCostUsd = model.calculatedWholesaleCostUsd;
      this.calculatedCommission = model.calculatedCommission;
      this.shipToAddressee = model.shipToAddressee;
      this.shipToStreetAddressLine1 = model.shipToStreetAddressLine1;
      this.shipToStreetAddressLine2 = model.shipToStreetAddressLine2;
      this.shipToCity = model.shipToCity;
      this.shipToRegion = model.shipToRegion;
      this.shipToPostalCode = model.shipToPostalCode;
      this.shipToCountryCode = model.shipToCountryCode;
      this.contactName = model.contactName;
      this.contactPhone = model.contactPhone;
      this.createdAt = new Date(model.createdAt);
      this.createdBy = model.createdBy;
      this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null;
      this.updatedBy = model.updatedBy;
      this.retailer = model.retailer;
      this.brand = model.brand;
      this.status = model.status ? new OrderStatus(model.status) : null;
      this.airShipments = model.airShipments
        ? model.airShipments.map((a) => new OrderAirShipment(a))
        : [];
      this.items = model.items ? model.items.map((i) => new OrderItem(i)) : [];
      this.stateTransitions = model.stateTransitions.map(
        (i) => new OrderStateTransition(i)
      );
      this.charges = model.charges || [];
      this.trackingNumber = this.getTrackingNumber();
      this.actualShipDate = this.getActualShipDate();
      this.paymentDate = this.getPaymentDate();
      this.payment =
        this.getOrderRetailerPayAmount() - this.getOrderBrandPayAmount();
    }
  }
}
