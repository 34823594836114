<template lang="pug">
.clients-filter
  .clients-filter__header-name(@click="openListAndHideExtendedItems()") {{ title }}
    span.clients-filter__header--icon(v-show="!isOpen")
      img(src="@/assets/img/inputs/filters-arrow-icon.svg")
    span.products-filter__header--icon(v-show="isOpen")
      img(src="@/assets/img/inputs/filters-arrow-icon-up.svg")
    span.clients-filter__header--reset(v-show="isOpen && isAnyItemsChecked" @click="resetAllCheckedItems()") {{$t('Components.Filters.ClientsFilter.resetAll')}}
  .clients-filter__body
    ul.clients-filter__items(:class="{ 'clients-filter__items--visible': isOpen }")
      li.clients-filter__item(
        v-for="item in items"
        :key="item.id"
        @click="switchItem(item)"
      )
        span.clients-filter__icon
          img(src="@/assets/img/checkbox.png")
          img.clients-filter__item--checked(v-show="item.checked" src="@/assets/img/inputs/checked.svg")
        span.clients-filter__item-name {{ item.name }}
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      isOpen: true,
      isExtended: false
    }
  },
  computed: {
    isAnyItemsChecked() {
      return this.items.filter(item => item.checked).length
    }
  },
  methods: {
    switchItem(item) {
      item.checked = !item.checked
      this.$emit("filterSwitch")
    },
    openListAndHideExtendedItems() {
      this.isOpen = !this.isOpen
      if (this.isExtended === true) {
        this.isExtended = !this.isExtended
      }
    },
    resetAllCheckedItems() {
      this.items.forEach(item => (item.checked = false))
      this.$emit("filterSwitch")
    }
  }
}
</script>

<style lang="sass">
.clients-filter__item--checked
  position: absolute
  right: 3px
  top: 3px
  width: 18px
  height: 18px

.clients-filter
  ::-webkit-scrollbar
    width: 10px

  ::-webkit-scrollbar-track
    border-radius: 10px

  ::-webkit-scrollbar-thumb
    background: #E5E5E5
    border-radius: 10px

  ::-webkit-scrollbar-thumb:hover
    background: #B3B3B3

  &__item
    height: 27px
    font-size: 12px
    font-weight: 300
    position: relative

  &__items
    display: none
    overflow: hidden
    margin-top: 10px

  &__see-all
    color: #B3B3B3
    font-size: 12px
    font-weight: 300

  &__items--visible
    display: block

  &__icon
    position: absolute
    left: 0
    margin-right: 20px

  &__header-name
    position: relative
    display: block
    cursor: pointer
    border-bottom: 1px solid #EBEFF2
    padding: 16px 0px 16px 0px
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

  &__header--icon
    float: right
    padding-right: 10px

  &__header--reset
    float: right
    color: #B3B3B3

  &__item-name
    margin-left: 24px
    font-size: 14px
</style>
