<template lang="pug">
.retailer-contact-information
  .retailer-contact-information__title {{$t('Components.Retailers.Checkout.RetailerContactInformation.contactPerson')}}
   span.retailer-contact-information__edit-form(@click="displayModal = true")
     a {{$t('Components.Retailers.Checkout.RetailerContactInformation.edit')}}

  .retailer-contact-information__address
    p.retailer-contact-information__paragraph {{ name }}
    p.retailer-contact-information__paragraph {{ phone }}

  .modal.retailer-contact-information-modal(:class="{ 'is-active': displayModal }")
    .modal-background(@click="displayModal = false")
    .modal-card.retailer-contact-information-modal__content
      header.retailer-contact-information-modal__header {{$t('Components.Retailers.Checkout.RetailerContactInformation.editContactPerson')}}
        img.retailer-contact-information-modal__close-button(src="@/assets/img/close-button.png" @click="displayModal = false")
      section.modal-card-body
        .retailer-contact-information-modal__body
          .columns
            .column
              .retailer-contact-information-modal__input-label {{$t('Components.Retailers.Checkout.RetailerContactInformation.firstName')}}
              input.input(v-model="firstName")
            .column
              .retailer-contact-information-modal__input-label {{$t('Components.Retailers.Checkout.RetailerContactInformation.lastName')}}
              input.input(v-model="lastName")
          .retailer-contact-information-modal__input-label {{$t('Components.Retailers.Checkout.RetailerContactInformation.phoneNumber')}}
          input.input(v-model="phoneNumber")
        .has-text-right.retailer-contact-information-modal__buttons
          a.button(@click="displayModal = false") {{$t('Components.Retailers.Checkout.RetailerContactInformation.cancel')}}
          a.button.is-black.retailer-contact-information-modal__save-button(@click="updateContactInfo") {{$t('Components.Retailers.Checkout.RetailerContactInformation.save')}}
</template>

<script>
export default {
  props: { name: { type: String, default: "" }, phone: { type: String, default: "" } },
  data() {
    return {
      displayModal: false,
      firstName: "",
      lastName: "",
      phoneNumber: ""
    }
  },
  computed: {
    retailerId() {
      return this.$store.getters["user/businessId"]
    }
  },
  methods: {
    updateContactInfo() {
      this.displayModal = false
      const name = [this.firstName, this.lastName].join(" ")
      this.$emit("contactInfoChange", name, this.phoneNumber)
    }
  }
}
</script>

<style lang="sass">
.retailer-contact-information

  &__edit-form
    text-decoration: underline
    color: #161616
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.16px
    line-height: 18px
    margin-left: 11px

  &__paragraph
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 26px

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.25px
    line-height: 24px
    margin-bottom: 24px

  a
    color: #161616

.retailer-contact-information-modal
  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 635px
    height: 325px

  &__body
    padding: 10px 20px 45px 20px
    border-bottom: 1px solid #E1E4E9

  &__input-label
    padding-bottom: 5px

  &__buttons
    padding: 10px 20px

  &__close-button
    position: absolute
    top: 24px
    right: 24px
    cursor: pointer

  &__save-button
    margin-left: 10px
</style>
