<template>
  <div class="landing_search__main">
    <div class="landing_search clearfix">
      <div class="landing_search_filter">
        <div class="landing_search_filter_list">
          <TypeBtn
            v-for="(item, index) in typeButton"
            :key="index"
            :item.sync="item"
            :index="index"
            :current-index="currentIndex"
            @itemClick="typeBtnClick"
          >
          </TypeBtn>
        </div>
      </div>
      <div class="landing_search_content">
        <div v-if="isRetailerAZ">
          <RetailerAZ></RetailerAZ>
        </div>
        <div v-else>
          <SearchContent
            :retailers="retailers"
            :is-empty="isEmpty"
            :loading="loading"
            @typeClick="locationsBtnClick"
          ></SearchContent>
        </div>
      </div>
    </div>
    <BrandTableDialog ref="brandSearchUpgradePlan" :brand-type="brandType" />
    <membershipExpiringDialog
      ref="membershipExpiringDialog"
      @addCardList="openAddMoreCardDialog"
    ></membershipExpiringDialog>
    <addNewCardDialog
      ref="addNewCardDialog"
      @openAddMoreCardDialog="openAddMoreCardDialog"
    ></addNewCardDialog>
    <cardListDialog
      ref="cardListDialog"
      @openAddPaymentDialog="openAddPaymentDialog"
    ></cardListDialog>
  </div>
</template>

<script>
import TypeBtn from "@/components/brands/search/TypeBtn.vue"
import SearchContent from "@/components/brands/search/searchContent"
import RetailerAZ from "@/components/brands/search/RetailerAZ"
import retailerClient from "@/api/retailer-client"
import BrandTableDialog from "@/components/brands/mainPage/components/BrandTableDialog"
import membershipExpiringDialog from "@/components/webflowComponent/MembershipExpiringDialog"
import addNewCardDialog from "@/components/brands/settings/addPaymentCardDialog"
import cardListDialog from "@/components/brands/settings/addMoreCardDialog"
import constants from "@/config"

const condition = {
  "0": "channel-type",
  "1": "location-type",
  "2": "other-type",
  "3": "retailer-az",
  "4": "location-group",
  "5": "hq-country"
}
// If the tag of each type changes, this place also corresponds to an increase or decrease
// It may be a bug in the future, or it may require a backend refactoring of the interface
const defaultValue = {
  "channel-type": "1,2,3,4,5,6,7,8,9,10,11,12",
  "location-type": "1,2,3,4,5,6",
  "location-group": "1,2,3,4",
  "other-type": "1,2",
  "hq-country": "au,ca,cn,fr,de,hk,id,il,it,jp,ph,nl,my,pl,sg,za,kr,ch,tw,th,gb,us,vn"
}

export default {
  name: "BrandSearch",
  components: {
    TypeBtn,
    SearchContent,
    RetailerAZ,
    BrandTableDialog,
    membershipExpiringDialog,
    addNewCardDialog,
    cardListDialog
  },
  data() {
    return {
      currentIndex: null,
      retailers: [],
      selectHqCountry: [],
      isEmpty: false,
      loading: false,
      typeButton: [
        {
          title: "Channel Type",
          ref: "channel_type",
          id: "0",
          iconName: "tag"
        },
        {
          title: "Location Type",
          ref: "location_type",
          id: "1",
          iconName: "compass"
        },
        {
          title: "Other Type",
          ref: "other_type",
          id: "2",
          iconName: "ellipsis"
        },
        {
          title: "Retailer A-Z",
          ref: "retailer_az",
          id: "3",
          iconName: "search"
        },
        // {
        //   title: "Suggested Retailers",
        //   ref: "suggested_retailers",
        //   id: "4",
        //   iconName: "check-icon.svg",
        //   iconOnName: "check-icon-on.svg"
        // },
        {
          title: "# of Locations",
          ref: "num_locations",
          id: "4",
          iconName: "home"
        },
        {
          title: "Locations",
          ref: "locations",
          id: "5",
          iconName: "global"
        }
      ]
    }
  },
  computed: {
    isRetailerAZ() {
      return this.$route.query.id === "3"
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    }
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.searchValue) {
          this.fetchRetailers({ retailer: this.$route.query.searchValue })
        }
      }
    }
  },
  async mounted() {
    if (this.brandType === "basic" && this.isBrand) {
      this.$refs["membershipExpiringDialog"].openDialog()
      return
    }
    await this.getReference()
    const key = Object.keys(this.$route.query)[1]
    const value = Object.values(this.$route.query)[1]
    const params = {
      [key]: value
    }
    await this.fetchRetailers(params)
  },
  async created() {
    const brandId = this.$store.getters["user/businessId"]
    await this.$store.dispatch("brand/getBrand", brandId)
  },
  methods: {
    openAddMoreCardDialog() {
      const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
      this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    },
    openAddPaymentDialog() {
      this.$refs["addNewCardDialog"].openAddCardDialog()
    },
    async getReference() {
      this.$store.dispatch("reference/setReference")
    },
    async typeBtnClick(item) {
      const key = condition[item.id]
      this.selectHqCountry = []
      this.$store.commit("reference/RESET_FILTERS")
      this.$router.push({
        name: "BrandSearch",
        query: {
          id: item.id,
          [key]: ""
        }
      })
      await this.fetchRetailers()
    },
    showUpdatePlanModal() {
      this.$refs["brandSearchUpgradePlan"].openDialog(true)
    },
    // Click the filter button to invoke the method
    async locationsBtnClick(item) {
      if (this.brandType === "basic") {
        this.showUpdatePlanModal()
      } else {
        this.currentIndex = null
        this.paramSplit(item)
        const { id } = this.$route.query
        let key = condition[id]
        this.$router.push({
          name: "BrandSearch",
          query: {
            id,
            [key]: this.selectHqCountry.join(",")
          }
        })
        await this.filterBtnClick(item)
      }
    },
    filterBtnClick(item) {
      this.loading = true
      this.isEmpty = false
      item.checked = true
      const key = Object.keys(this.$route.query)[1]
      const value = Object.values(this.$route.query)[1]
      this.fetchRetailers({ [key]: value })
    },
    async fetchRetailers(param) {
      // If param is undefined or no value, it gets the data for the current type
      if (!param || Object.values(param)[0] === "") {
        const key = condition[this.$route.query.id]
        param = {
          [key]: defaultValue[key]
        }
      }
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "retailSearch",
        userId: this.$store.getters["user/id"],
        firstName: this.$store.getters["user/givenName"],
        lastName: this.$store.getters["user/familyName"],
        emailAddress: this.$store.getters["user/email"],
        searchParams: param,
        userType: "Brand"
      })
      try {
        this.retailers = []
        const params = {
          _limit: 1000,
          status: "approved",
          ...param
        }
        for (let key in params) {
          if (!params[key]) {
            delete params[key]
          }
        }
        const res = await retailerClient.fetchRetailers(params)
        this.loading = false
        this.retailers = res.data
        this.isEmpty = res.data.length === 0
      } catch (e) {
        this.loading = false
        this.isEmpty = e.response.status === 404
      }
    },
    paramSplit(item) {
      if (!item || !item.id) {
        return
      }
      const index = this.selectHqCountry.findIndex(i => i === item.id)
      if (index !== -1) {
        this.selectHqCountry.splice(index, 1)
      } else {
        this.selectHqCountry.push(item.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.landing_search__main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing_search {
  padding-top: 73px;
  padding-bottom: 70px;
  box-sizing: border-box;

  &_filter {
    float: left;
    margin-top: 17px;
    width: 340px !important;
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-right: 25px;
    margin-bottom: 150px;

    &_search {
      height: 60px;
      padding: 0 18px;
      display: flex;
      border-bottom: 1px solid #d9d9d9;
      align-items: center;

      .icon {
        margin-right: 5px;
        cursor: pointer;
      }

      .inp {
        flex: 1;

        .ant-input {
          border: none;
        }
      }
    }

    &_loca {
      padding: 12px 18px 0;
      cursor: pointer;

      &_title {
        font-family: Proxima Nova;
        font-size: 16px;
        color: #262626;
        margin-bottom: 13px;
      }
    }

    &_list {
      border-top: 1px solid #d9d9d9;
    }
  }

  &_content {
    float: left !important;
    width: 508px;
  }

  .location-style {
    font-size: 20px;
    margin-right: 20px;
  }
}
</style>
