<template>
  <div class="search_index">
    <div class="search_index_title">
      {{ $route.query.id | vF1001 }}
    </div>
    <div class="search_index_filter">
      <filter-btn
        v-for="(item, index) in filterBtnList"
        :key="index"
        :item.sync="item"
        @itemClick="locationsBtnClick"
      ></filter-btn>
    </div>
    <div class="loading_wrap">
      <a-spin :spinning="loading"></a-spin>
    </div>
    <div class="search_index_content">
      <retailer-card v-for="(item, index) in retailers" :key="index" :item="item"></retailer-card>
      <a-empty v-if="isEmpty" description="No Retailers"></a-empty>
    </div>
  </div>
</template>
<script>
import RetailerCard from "./RetailerCard.vue"
import FilterBtn from "./FilterBtn.vue"

export default {
  name: "SearchIndex",
  components: {
    RetailerCard,
    FilterBtn
  },
  filters: {
    vF1001: val => {
      let text
      switch (val) {
        case "0":
          text = "Channel Type"
          break
        case "1":
          text = "Location Type"
          break
        case "2":
          text = "Other Types"
          break
        case "4":
          text = "# of Locations"
          break
        case "5":
          text = "Locations"
          break
        default:
          text = "All Retailers"
          break
      }
      return text
    }
  },
  props: {
    retailers: {
      type: Array,
      default: () => {
      }
    },
    loading: {
      type: Boolean
    },
    isEmpty: {
      type: Boolean
    }
  },
  data() {
    return {
      typeId: 0,
      title: "All Retailers",
      currentIndex: null,
      isFirst: true
    }
  },
  computed: {
    filterBtnList() {
      let searchList
      if (this.$route.query.id === "0") {
        let list = this.$store.getters["reference/channelType"]
        list = this.filterCheck(list)
        return this.sortFilterBtnList(list)
      } else if (this.$route.query.id === "1") {
        searchList = this.$store.getters["reference/locationType"]
      } else if (this.$route.query.id === "4") {
        searchList = this.$store.getters["reference/locationGroup"]
      } else if (this.$route.query.id === "2") {
        searchList = this.$store.getters["reference/otherType"]
      } else if (this.$route.query.id === "5") {
        searchList = this.$store.getters["reference/countryFilterItems"]
      } else {
        searchList = []
      }
      return this.filterCheck(searchList)
    }
  },
  methods: {
    sortFilterBtnList(btnList) {
      let tempArr = []
      const condition = [
        "Department Store",
        "Home Shopping",
        "Off Price Retailer",
        "Super Market",
        "Subscription Box",
        "Fashion Retailer",
        "Beauty Retailer",
        "Flash Sale Sites",
        "Warehouse Club",
        "Professional Channel",
        "Beauty Supply",
        "Pharmacy"
      ]
      condition.forEach(item => {
        const result = btnList.filter(jItem => jItem.name === item)
        if (result.length > 0) {
          tempArr.push(result[0])
        }
      })
      return tempArr
    },
    // Filter filter-btn selection effect
    filterCheck(list) {
      const value = Object.values(this.$route.query)[1]
      let arr = value.split(",") || []
      return list.map(item => {
        if ((arr && arr.length === 0) || item.checked) {
          item.checked = false
        }
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === item.id) {
            item.checked = true
          }
        }
        return item
      })
    },
    async locationsBtnClick(item) {
      this.$emit("typeClick", item)
    }
  }
}
</script>
<style lang="scss" scoped>
.search_index {
  &_title {
    font-family: Playfair Display;
    font-weight: bold;
    font-size: 48px;
    color: #262626;
  }

  &_filter {
    margin: 32px 0 25px;
  }

  &_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .loading_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-empty {
    margin: 0 auto;
  }

  .filter_btn {
    margin-bottom: 18px !important;
  }
}
</style>
