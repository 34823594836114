<template>
  <a-row ref="messageItemRef" class="message-item-new">
    <a-col
      :span="2"
      :class="
        message.isCurrentUserSend ? 'message-item-new__float-left' : 'message-item-new__float-right'
      "
      class="message-item-new__header"
      :style="
        message.isCurrentUserSend && isSmallStatus === false
          ? 'padding-left: 16px;'
          : 'text-align: left;'
      "
    >
      <a-avatar
        class="message-item-new__header-avarta"
        size="large"
        style="background: #4a8a5d;"
        :style="isSmallStatus ? '' : 'width: 56px; height:56px;'"
      >
        <img v-if="message.userHead" slot="icon" :src="message.userHead" />
      </a-avatar>
    </a-col>
    <a-col
      :span="22"
      :class="
        message.isCurrentUserSend ? 'message-item-new__float-right' : 'message-item-new__float-left'
      "
      style="padding-right: 10px;padding-left: 4px;"
    >
      <a-col
        :span="24"
        :class="
          message.isCurrentUserSend
            ? 'message-item-new__float-left'
            : 'message-item-new__float-right'
        "
      >
        <div
          :class="
            message.isCurrentUserSend
              ? 'message-item-new__message-right'
              : 'message-item-new__message-left'
          "
          class="message-item-new__message"
        >
          <p v-if="isForward">Fwd: Subject: {{ message.subject }}</p>
          <p
            v-if="!(message.messageType && message.messageType.id === '4')"
            class="message-item-new__message-title"
          >
            {{ message.from }}
          </p>
          <!-- <p v-if="isForward">
            To <a-input v-model="forwardTo" style="width: 80%;" @change="changeForwardAddress" />
          </p> -->
          <div v-if="!isForward">
            <p v-if="!(message.messageType && message.messageType.id === '4')">
              To: {{ message.to }}
            </p>
          </div>
          <div
            v-if="!(message.messageType && message.messageType.id === '4')"
            class="message-item-new__message-divider"
          ></div>
          <template v-if="message && message.messageForwards && message.messageForwards.length > 0">
            <message-item
              v-for="(messageItem, index) in forwardMessages"
              :key="`forward_message_${message.id}_${messageItem.id}_${index}`"
              :message="messageItem"
              :is-in-forward-message="true"
            />
          </template>
          <!-- <forward-message-item-content
            v-if="message && message.messageForwards && message.messageForwards.length > 0"
            :message="message"
            :forward-messages="forwardMessages"
          /> -->
          <template v-else-if="message.messageType && message.messageType.id === '1'">
            <div class="message-item-new__message-product-title">
              New Products from {{ message.from }}
            </div>
            <div
              v-if="message.message.indexOf('<img') < 0"
              class="message-item-new__message-product-detail"
              v-html="message.message"
            >
              <!--              {{ message.message }}-->
            </div>
            <div
              v-else
              class="message-item-new__message-product-detail"
              v-html="message.message"
            ></div>
            <div
              v-for="(product, index) in message.messageProducts"
              :key="`product_${product.product.id}_${index}`"
              class="message-item-new__message-product-outer"
              @click="clickToProductDetail(product.product)"
            >
              <img
                v-if="product.product.imageUrl && product.product.imageUrl.length > 0"
                style="width: 100%; max-height: 100px;"
                :src="product.product.imageUrl"
              />
              <div class="message-item-new__intro-product-list-item-text">
                <div style="color: #000; font-size: 10px;">
                  <p>{{ product.product.name }}</p>
                  <p style="color: #595959; font-size: 7px;">{{ product.product.sku }}</p>
                  <p style="color: #262626; font-size: 9px;">
                    {{ priceFormat(product.product.msrpUsd) }}
                  </p>
                </div>
              </div>
            </div>
          </template>
          <div v-else style="margin-bottom: 20px;">
            <div v-html="message.message"></div>
          </div>
          <!--          <div v-if="message.messageFiles && message.messageFiles.length > 0">-->
          <!--            <p-->
          <!--              v-for="(file, index) in message.messageFiles"-->
          <!--              :key="`file_${message.id}_${file.privateId}_${index}`"-->
          <!--            >-->
          <!--              {{ file.fileAsset.id }}-->
          <!--            </p>-->
          <!--          </div>-->
          <div style="margin-top: 4px;">
            <p v-html="message.postInfo ? message.postInfo : ''"></p>
          </div>
          <div
            v-if="message.messageFiles && message.messageFiles.length !== 0"
            class="file-list-editor-message-item"
          >
            <div
              v-for="(item, index) in message.messageFiles"
              :key="index"
              style="width:200px;height:24px;margin-right: 10px;background: #e2eeec;"
            >
              <p
                style="width:200px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display:inline-block;padding: 0 10px;cursor: pointer"
              >
                <a :href="item.fileAsset.url" style="color: #262626">
                  {{
                    item.fileAsset.originalFileName
                      ? item.fileAsset.originalFileName
                      : item.fileAsset.url
                  }}
                </a>
                <span style="color: #8c8c8c"
                  >({{ item.fileAsset.size ? getFileSize(item.fileAsset.size) : 0 }})</span
                >
              </p>
            </div>
          </div>
          <div
            v-if="!isForward && !isInForwardMessage && !noOperation"
            class="message-item-new__message-date"
          >
            <span style="mrgin-right: 14px; margin-top: 4px;">{{
              humanReadableDate(message.createdAt)
            }}</span>
            <a-dropdown placement="topLeft">
              <a-icon
                type="more"
                style="font-size: 18px; font-weight: bold; font-color: #8C8C8C;"
              />
              <a-menu
                slot="overlay"
                overlay-class-name="message-item-new__message-menu"
                @click="handleMenuClick"
              >
                <a-menu-item v-if="!noForward" key="1">
                  Forward
                </a-menu-item>
                <a-menu-item key="2">
                  Delete
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </a-col>
    </a-col>
    <ConfirmDialog ref="confirmDelete" @doConfirm="doDeleteMessage" />
  </a-row>
</template>
<script>
import ConfirmDialog from "@/components/admin/ConfirmDialog"
export default {
  name: "MessageItem",
  components: {
    ConfirmDialog
  },
  props: {
    message: { type: Object, default: () => undefined },
    isForward: { type: Boolean, default: () => false },
    isInSmallModal: { type: Boolean, default: () => false },
    isInForwardMessage: { type: Boolean, default: () => false },
    noForward: { type: Boolean, default: () => false },
    isSmallStatus: { type: Boolean, default: () => false },
    noOperation: { type: Boolean, default: () => false }
  },
  data() {
    return {
      forwardMessages: []
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    retailers() {
      return this.$store.getters["retailer/retailers"]
    },
    brands() {
      return this.$store.getters["brand/brands"]
    },
    userId() {
      return this.$store.getters["user/businessId"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  created() {
    if (this.message && this.message.messageForwards && this.message.messageForwards.length > 0) {
      this.getForwardMessages(this.message)
    }
  },
  updated() {
    this.$emit("uHeight", this.$refs.messageItemRef.$el.offsetHeight)
  },
  methods: {
    getForwardMessages(item) {
      const forwardIds = item.messageForwards.map(item => item.forwardMessagePrivateId).join(",")
      const params = {
        "private-id": forwardIds,
        "is-forward": true
      }
      this.$store
        .dispatch("adminMessage/getMessageDetail", params)
        .then(res => {
          if (res && res.messages && res.messages.length > 0) {
            res.messages.forEach(item => {
              this.composeMessageData(item)
            })
            this.forwardMessages = res.messages
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getFindClient(id) {
      let findBrand = undefined
      if (this.brands) {
        findBrand = this.brands.find(item => item.id === id)
      }
      if (!findBrand) {
        findBrand = this.retailers.find(item => item.id === id)
      }
      return findBrand
    },
    getProduct(id) {
      return this.$store.getters["product/find"](id)
    },
    composeMessageData(data) {
      let isCurrent =
        data.senderClientId === "*"
          ? this.isAdmin
            ? true
            : false
          : data.senderClientId === this.userId
          ? true
          : false
      data.isCurrentUserSend = isCurrent
      // get from name and avarta
      if (data.senderClientId != "*") {
        let findBrand = this.getFindClient(data.senderClientId)
        data.userHead = findBrand && findBrand.logoAsset ? findBrand.logoAsset.url : undefined
        data.from = findBrand ? findBrand.name : ""
      } else {
        data.from = "Landing"
      }
      if (data.messageType && data.messageType.id === "1") {
        // this is the intro brand product message
        data.messageProducts.forEach(item => {
          let product = this.getProduct(item.product.id)
          if (product) {
            item.product.name = product.name
            item.product.sku = product.sku
            item.product.msrpUsd = product.msrpUsd
            item.product.images = product.images
            item.product.brand = product.brand
            item.product.imageUrl =
              product.images && product.images.length > 0 ? product.images[0].asset.url : undefined
          }
        })
        data.postInfo = `Sincerely,<br/>${data.from}`
      }
      //get receiver name
      if (data.messageReceivers && data.messageReceivers.length > 0) {
        let receiverNames = ""
        if (this.isAdmin) {
          for (let i = 0; i < data.messageReceivers.length; i++) {
            if (data.messageReceivers[i].receiverClientId === "*") {
              receiverNames += "Landing"
            } else {
              let find = this.getFindClient(data.messageReceivers[i].receiverClientId)
              if (find) {
                receiverNames += find.name
              }
            }
            if (i < data.messageReceivers.length - 1) {
              receiverNames += ","
            }
          }
        } else {
          let find = this.getFindClient(this.userId)
          if (data.messageReceivers.length === 1) {
            find = this.getFindClient(data.messageReceivers[0].receiverClientId)
          }
          if (find) {
            receiverNames = find.name
          }
        }
        data.to = receiverNames
      }
    },
    humanReadableDate(isoString) {
      const date = new Date(isoString)
      const time = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      })
      const month = date.toLocaleString("en-US", { month: "short" })
      //   const week = date.toLocaleString("en-US", { weekday: "short" })
      return `${month},${date.getDate()} ${time}`
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price) + " MSRP"
    },
    handleMenuClick(e) {
      if (e.key === "1") {
        this.$emit("forwardMessage", this.message)
      } else if (e.key === "2") {
        this.showDeleteConfirm()
      }
    },
    showDeleteConfirm() {
      this.$refs["confirmDelete"].openDialogConfirm(
        "Delete Message",
        "Are you sure you wish to delete? ",
        "No",
        "Yes"
      )
    },
    // changeForwardAddress() {
    //   this.$emit("changeFwd", this.forwardTo)
    // },
    doDeleteMessage() {
      this.$emit("deleteMessage", this.message)
    },
    clickToProductDetail(item) {
      if (this.role === "admin") {
        this.$router.push({
          name: "adminBrandProduct",
          params: {
            brandId: item.brand.id,
            id: item.id
          }
        })
      } else if (this.role === "retailer") {
        this.$router.push({
          name: "retailerProduct",
          params: {
            id: item.id
          }
        })
      } else if (this.role === "brand") {
        this.$router.push({
          name: "brandProduct",
          params: {
            id: item.id
          }
        })
      }
    },
    getFileSize(size) {
      if (!size) return 0

      const num = 1024.0 //byte

      if (size < num) return size + "B"
      if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + "K" //kb
      if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + "M" //M
      if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + "G" //G
      return (size / Math.pow(num, 4)).toFixed(2) + "T" //T
    }
  }
}
</script>
<style lang="scss" scoped>
.message-item-new {
  width: 100%;
  margin-bottom: 29px;
  p {
    margin-bottom: 0px;
  }
  &__header {
    display: inline-block;
  }
  &__header-avarta {
    position: absolute;
  }
  &__float-left {
    float: right;
  }
  &__float-right {
    float: left;
  }
  &__message {
    min-height: 80px;
    padding: 10px 20px;
  }
  &__message-divider {
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin: 10px 0px;
  }
  &__message-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  &__message-left {
    width: 100%;
    border-radius: 14px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    background: #f5f5f5;
  }
  &__message-right {
    width: 100%;
    border-radius: 14px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    background-color: rgba(74, 138, 93, 0.11);
  }
  &__message-date {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.13125px;
    color: #bfbfbf;
    text-align: right;
  }
  &__message-menu {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
    color: #8c8c8c;
    background: #fafafa;
  }
  &__message-product-title {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    color: #000000;
  }
  &__message-product-detail {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  &__message-product-outer {
    width: 123px;
    height: 185px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 18px;
    display: inline-block;
    position: relative;
    margin-bottom: 4px;
  }
  &__intro-product-list-item-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    position: absolute;
    bottom: 7px;
    width: 119px;
    padding: 0px 2px;
    text-align: center;
  }
}
.message-item-new__message-product-outer:hover {
  cursor: pointer;
}
.message-item-new__intro-product-list-item-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
.file-list-editor-message-item {
  //position: absolute;
  //left: 12px;
  //bottom: 8px;
  margin-top: 20px;
  color: #262626;
  padding: 2px 4px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: center;
  //z-index: 300;
}
</style>
