<template>
  <div class="unfiltered-layout">
    <UnfilteredHeader />
    <router-view />
    <UnfilteredFooter />
  </div>
</template>

<script>
import UnfilteredHeader from "./unfiltered/Header"
import UnfilteredFooter from "./unfiltered/Footer"

export default {
  components: {
    UnfilteredHeader,
    UnfilteredFooter
  }
}
</script>

<style lang="sass">
.unfiltered-layout
  min-height: 100vh
</style>
