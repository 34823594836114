import CategoriesService from "@/services/categories-service";
import StepsService from "@/services/steps-service";
import BestUsedForsService from "@/services/best-used-fors-service";
export default {
  setSaving({ commit }, isSaving) {
    commit("SET_SAVING", isSaving);
  },
  async httpRequest(
    { commit },
    { stateProperties, propertyName, promiseRequest }
  ) {
    commit("SET_LOADING", {
      stateProperties,
      propertyName,
      status: true,
    });
    try {
      return promiseRequest.finally(() =>
        commit("SET_LOADING", {
          stateProperties,
          propertyName,
          status: false,
        })
      );
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setCategories({ commit, dispatch }) {
    const stateProperties = [];
    const propertyName = "categories";
    await dispatch("httpRequest", {
      stateProperties: [],
      propertyName: "categories",
      promiseRequest: CategoriesService.getCategories().then((data) =>
        commit("SET_VALUE", {
          stateProperties,
          propertyName,
          data,
        })
      ),
    });
  },
  async setTrainingStepsByCategoryPrivateId(
    { commit, dispatch },
    categoryPrivateId
  ) {
    const stateProperties = ["categoryTrainingSteps"];
    const propertyName = `${categoryPrivateId}`;
    await dispatch("httpRequest", {
      stateProperties,
      propertyName,
      promiseRequest: StepsService.getSteps(categoryPrivateId).then((data) =>
        commit("SET_VALUE", {
          stateProperties,
          propertyName,
          data,
        })
      ),
    });
  },
  async updateTrainingStepsByCategoryPrivateId({ dispatch }, param) {
    const type = param.type;
    const categoryPrivateId = param.parentKey;
    const data = param.data;
    switch (type) {
      case 1:
        await CategoriesService.addSteps(categoryPrivateId, data);
        break;
      case 2:
        // await StepsService.updateSteps(data).catch((error) =>
        //   this.$message.error(error)
        // )
        await Promise.all(
          data.map((i) => CategoriesService.updateStep(categoryPrivateId, i))
        );
        break;
      case 3:
        await Promise.all(
          data.map((i) => StepsService.deleteStep(i.privateId))
        );
        break;
    }
    dispatch("setTrainingStepsByCategoryPrivateId", categoryPrivateId);
  },
  async updateTrainingStepsByCategoryPrivateIdMock({ commit }, param) {
    commit("UPDATE_TRAINING_STEPS_BY_CATEGORY_PRIVATE_ID", param);
  },
  async updateTrainingBestUsedForsByCategoryPrivateId({ dispatch }, param) {
    const type = param.type;
    const categoryPrivateId = param.parentKey;
    const data = param.data;
    switch (type) {
      case 1:
        await CategoriesService.addBestUsedFors(categoryPrivateId, data);
        break;
      case 2:
        await Promise.all(
          data.map((i) =>
            CategoriesService.updateBestUsedFor(categoryPrivateId, i)
          )
        );
        break;
      case 3:
        await Promise.all(
          data.map((i) => BestUsedForsService.deleteBestUsedFor(i.privateId))
        );
        break;
    }
    dispatch("setTrainingBestUsedForsByCategoryPrivateId", categoryPrivateId);
  },
  async setTrainingBestUsedForsByCategoryPrivateId(
    { commit, dispatch },
    categoryPrivateId
  ) {
    const stateProperties = ["categoryTrainingBestUsedFors"];
    const propertyName = `${categoryPrivateId}`;
    await dispatch("httpRequest", {
      stateProperties,
      propertyName,
      promiseRequest: BestUsedForsService.getBestUsedFors(
        categoryPrivateId
      ).then((data) =>
        commit("SET_VALUE", {
          stateProperties,
          propertyName,
          data,
        })
      ),
    });
  },
  //async updateTrainingBestUsedForsByCategoryPrivateId({ commit }, data) {},
};
