<template>
  <a-row class="admin-retailer-search">
    <a-col :span="5" style="padding-right: 12px; margin-top: 82px;">
      <a-input-search
        v-model="searchValue"
        placeholder="Brand name, Product name, SKU, UPC"
        style="width: 100%; margin-bottom: 10px; padding-left: 10px;"
        :allow-clear="true"
        @search="onSearch"
        @change="doSearchKeyWord"
      />
      <SearchItems :items="searchItems" @doSearch="doSearch" />
    </a-col>
    <a-col :span="19">
      <router-view />
    </a-col>
  </a-row>
</template>

<script>
import SearchItems from "@/components/admin/SearchItems"
export default {
  components: {
    SearchItems
  },
  data() {
    return {
      brandName: [],
      categories: [],
      categoryList: [],
      curations: [],
      brandValues: [],
      searchItems: [],
      searchParam: undefined,
      searchValue: ""
    }
  },
  created() {
    this.getReferenceData()
    this.getCategoryData()
    this.getBrandName()
  },
  methods: {
    getReferenceData() {
      this.$store.dispatch("reference/setReference").then(() => {
        this.curations = this.$store.getters["reference/curations"]
        this.brandValues = this.$store.getters["reference/brandValue"]
        if (this.curations && this.curations.length > 0) {
          this.curations.forEach(data => {
            if (data.description) {
              let name = data.name
              data.name = data.description
              data.description = data.id
              data.id = name
            }
          })
        }
        this.initData(this.curations)
        this.initData(this.brandValues)
        this.initSearchItems()
      })
    },
    getCategoryData() {
      this.$store.dispatch("reference/getProductConsole").then(() => {
        this.categories = this.$store.getters["reference/productConsole"].productCategorys
        this.initData(this.categories, true)
        this.initSearchItems()
      })
    },
    getBrandName() {
      this.$store.dispatch("brand/fetchAllBrands", { params: { fields: "id, name" } }).then(() => {
        let retailers = this.$store.getters["brand/brands"]
        if (retailers && retailers.length > 0) {
          this.brandName = []
          retailers.forEach(element => {
            this.brandName.push({ name: element.name, id: element.id })
          })
          this.initData(this.brandName)
          this.initSearchItems()
        }
      })
    },
    initSearchItems() {
      this.searchItems = []
      this.searchItems.push({
        name: "Brand Name",
        paramName: "brand",
        isUseId: true,
        canSearch: true,
        child: this.brandName
      })
      this.searchItems.push({
        name: "Category",
        paramName: "category",
        subParam: "subcategory",
        microParam: "micro-category",
        isUseId: true,
        canSearch: false,
        child: this.categories
      })
      this.searchItems.push({
        name: "Curations",
        paramName: "brand-curation",
        isUseId: true,
        canSearch: false,
        child: this.curations
      })
      this.searchItems.push({
        name: "Product Value",
        paramName: "sell-side-value",
        canSearch: false,
        child: this.brandValues
      })
      this.searchItems.push({
        name: "Upload Date",
        paramName: "upload-date-range",
        isDate: true,
        canSearch: false
      })
    },
    initData(data, hasC = false) {
      if (data && data.length > 0) {
        data.forEach(item => {
          item.isChecked = false
          item.hasChild = hasC
        })
      }
    },
    doSearchKeyWord() {
      if (this.searchValue.length === 0) {
        this.onSearch("")
      }
    },
    onSearch(value) {
      let param = {}
      if (value && value.length > 0) {
        param = { keyword: value }
      }
      this.$store.commit("adminConsole/SET_PRODUCT_SEARCH_KEYWORD", param)
      this.toSearchResultPage()
    },
    doSearch(param) {
      this.searchParam = param
      this.$store.commit("adminConsole/SET_PRODUCT_SEARCH_PARAM", this.searchParam)
      this.toSearchResultPage()
    },
    toSearchResultPage() {
      if (this.$route.name !== "adminProductSearchResult") {
        this.$router.push({
          name: "adminProductSearchResult"
        })
      }
    }
  }
}
</script>

<style lang="scss">
.admin-product-search {
  &__left-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 22px;
    color: #333333;
    float: left;
  }
  &__right-view {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
    color: #595959;
  }
  &__see-all {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #000000;
    margin-top: 21px;
    text-align: center;
    cursor: pointer;
  }
}

.admin-product-search__dropdown-view .ant-select-selection {
  background: rgba(74, 138, 93, 0.11);
  border: 1px dashed rgba(74, 138, 93, 0.46);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4a8a5d;
  width: 200px;
  margin-left: 10px;
  // margin-right: 10px;
}
</style>
