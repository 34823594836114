<template lang="pug">
footer.footer
  .columns
    .column.footer--column.start--demo.mobile
      my-button(variant="is-primary" label="Start a Demo" @onClick="showDialog")
    .column.footer--column.is-one-quarter
      .footer--logo
        img(:src="footerLogo" alt="Logo")
      .social--icons
        a.social--icon(href="https://www.facebook.com/landinginternational/")
          img(:src="faceBook" alt="Facebook")
        a.social--icon(href="https://twitter.com/landingintl")
          img(:src="twitter" alt="Twitter")
        a.social--icon(href="https://www.instagram.com/accounts/login/?next=/landinginternational/")
          img(:src="instagram" alt="Instagram")
        a.social--icon(href="https://www.linkedin.cn/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.cn%2Finjobs%2Fcompany%2Flandinginternational%2F")
          img(:src="linkedin" alt="Linkedin")
    .column.columns
      .column.footer--column
        p.footer--column--content Solutions
        ul
          li
            a(href="https://www.landinginternational.com/brands") For Brands
          li
            a(href="https://www.landinginternational.com/retailers") For Retailers
      .column.footer--column
        p.footer--column--content Discover
        ul
          li
            a(href="https://blog.landinginternational.com/") Blog
          li
            a(href="https://www.landinginternational.com/mission") Mission
          li
            a(href="https://www.landinginternational.com/team") Team
          li
            a(href="https://www.landinginternational.com/press") Press
      .column.footer--column
        p.footer--column--content More
        ul
          li
            a(href="https://www.landinginternational.com/contact") Contact
          li
            a(href="https://www.landinginternational.com/terms-of-use") Terms &amp; Conditions
          li
            a(href="https://www.landinginternational.com/privacy-policy") Privacy Policy
      .column.footer--column.start--demo.desktop
        my-button(variant="is-primary" label="START A DEMO" @onClick="showDialog")
  RequestADemo(ref="requestADemo")
</template>

<script>
import MyButton from "@/components/webflowComponent/Button"
import faceBook from "@/assets/webflowAssets/Footer/facebook.svg"
import footerLogo from "@/assets/webflowAssets/Footer/footer_logo.svg"
import instagram from "@/assets/webflowAssets/Footer/instagram.svg"
import linkedin from "@/assets/webflowAssets/Footer/linkedin.svg"
import twitter from "@/assets/webflowAssets/Footer/twitter.svg"
import RequestADemo from "@/components/shared/RequestADemo"

export default {
  name: "Footer",
  components: {
    MyButton,
    RequestADemo
  },
  data() {
    return {
      footerLogo,
      faceBook,
      instagram,
      linkedin,
      twitter
    }
  },
  methods: {
    showDialog() {
      console.log(".....show dialog......")
      this.$refs["requestADemo"].openDialog()
    }
  }
}
</script>

<style scoped>
footer.footer {
  background-color: white;
  border-top: 1px solid #f15a42;
  padding: 38.5px 36.5px;
}

footer.footer .footer--logo {
  display: flex;
  margin-bottom: 36px;
}

.social--icons a.social--icon {
  width: 24px;
  height: 24px;
}

.social--icons a.social--icon + a.social--icon {
  margin-left: 24px;
}

.footer--column .footer--column--content {
  font-family: "Niveau Grotesk";
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #1a1818;
  margin-bottom: 19.77px;
}

.footer--column li {
  margin-bottom: 15px;
}

.footer--column li a {
  font-family: "Mr Eaves Mod OT";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #f15a42;
}

.footer--column.start--demo {
  display: flex;
  justify-content: flex-end;
}

.footer--column.start--demo.desktop {
  display: block;
}

.footer--column.start--demo.mobile {
  display: none;
}

@media screen and (max-width: 1023px) {
  footer.footer {
    padding: 50px 50px 23px;
  }

  footer.footer .columns {
    display: block;
  }

  footer.footer .columns .is-one-quarter {
    width: 100%;
    margin-bottom: 1rem;
  }

  .footer .footer--column {
    text-align: center;
  }

  .footer .footer--column .footer--logo {
    justify-content: center;
  }

  .footer--column.start--demo.desktop {
    display: none;
  }

  .footer--column.start--demo.mobile {
    display: block;
    margin-bottom: 1rem;
  }
}
</style>
