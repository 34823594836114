<template lang="pug">
.column
    form
    .column.is-3.margin-label-header-wrapper.padding-style-header
      .columns.is-8.retailer-settings__title {{$t('Retailers.RetailerSettings.margins')}}
    .column.is-3.margin-label-wrapper.brand-line(v-if="defaultMarginCount >= 1 && overridenMarginsCount == 0")
      p.margin-label {{$t('Retailers.RetailerSettings.allBrands')}}
      p.margin-label-data {{$t('Retailers.RetailerSettings.allProducts')}}
      p.margin-label {{defaultMargin}}
    .column.is-3.margin-label-wrapper.brand-line(v-for="margin in retailer.margins", v-if="margin.brand.id!='*' && margin.product.id!='*' && overridenMarginsCount >= 1")
      p.margin-label {{brandName(margin.brand)}}
      p.margin-label-data {{productName(margin.product, margin.brand)}}
      p.margin-label {{marginPercent(margin.brand.id, margin.product.id, margin.margin)}}
    .column.is-3.margin-label-wrapper.brand-line(v-for="margin in retailer.margins", v-if="margin.brand.id!='*' && margin.product.id=='*'")
      p.margin-label {{allProductsBrandName(margin.brand)}}
      p.margin-label-data {{allProductsOrOtherProducts(margin.product, margin.brand)}}
      p.margin-label {{allProductsMarginPercent(margin.margin)}}
    .column.is-3.margin-label-wrapper.brand-line(v-if="defaultMarginCount >= 1 && overridenMarginsCount >= 1")
      p.margin-label {{$t('Retailers.RetailerSettings.allOtherBrands')}}
      p.margin-label-data {{$t('Retailers.RetailerSettings.allOtherProducts')}}
      p.margin-label {{defaultMargin}}
</template>

<script>
export default {
  data() {
    return {
      overridenMarginsCount: 0,
      defaultMarginCount: 0,
      defaultMargin: ""
    }
  },
  computed: {
    retailer() {
      return this.$store.getters["retailer/retailer"]
    }
  },
  watch: {
    async retailer() {
      this.overridenMargins()
      this.defaultMarginPercent()
    }
  },
  created() {
    this.overridenMargins()
    this.defaultMarginPercent()
  },
  methods: {
    brandName(brand) {
      if (brand.id != "*") {
        return brand.name
      }
    },
    productName(product) {
      if (product.id != "*") {
        return product.name
      }
    },
    marginPercent(brandId, productId, margin) {
      if (brandId != "*" && productId != "*") {
        return +((margin / 100) * 100).toFixed(2).toString() + "%"
      }
    },
    allProductsBrandName(brand) {
      if (brand.id != "*") {
        return brand.name
      }
    },
    allProductsOrOtherProducts(product, brand) {
      const count = this.retailer.margins.filter(item => item.brand.id === brand.id).length
      if (count > 1 && product.id == "*") {
        return "All Other Products"
      } else if (count == 1 && product.id == "*") {
        return "All Products"
      }
    },
    allProductsMarginPercent(margin) {
      return +((margin / 100) * 100).toFixed(2).toString() + "%"
    },
    defaultMarginPercent() {
      var retailerMargins = this.retailer.margins
      for (var index in retailerMargins) {
        if (retailerMargins[index].brand.id == "*" && retailerMargins[index].product.id == "*") {
          this.defaultMarginCount = this.defaultMarginCount + 1
        }
      }
      if (this.defaultMarginCount > 1) {
        for (var index1 in retailerMargins) {
          if (
            retailerMargins[index1].retailer.id != "*" &&
            retailerMargins[index1].brand.id == "*" &&
            retailerMargins[index1].product.id == "*"
          ) {
            this.defaultMargin =
              +((retailerMargins[index1].margin / 100) * 100).toFixed(2).toString() + "%"
          }
        }
      } else if (this.defaultMarginCount == 1) {
        for (var index2 in retailerMargins) {
          if (
            retailerMargins[index2].retailer.id == "*" &&
            retailerMargins[index2].brand.id == "*" &&
            retailerMargins[index2].product.id == "*"
          ) {
            this.defaultMargin =
              +((retailerMargins[index2].margin / 100) * 100).toFixed(2).toString() + "%"
          }
        }
      }
    },
    overridenMargins() {
      for (var index in this.retailer.margins) {
        if (this.retailer.margins[index].product.id != "*") {
          this.overridenMarginsCount = this.overridenMarginsCount + 1
        }
      }
    }
  }
}
</script>

<style lang="sass">
.retailer-settings
  padding-left: 80px !important
  padding-bottom: 300px
  .margin-label-wrapper
   display: flex
   width: 100%
   border-top: 1px solid #ebeff2
   flex-direction: row
   height: auto
   min-height: 50px
   .margin-label
    font-size: 14px
    color: #333333
    margin-left: 70px
    margin-right: 50px
    width: 150px
    font-weight: 500
    margin-top: 15px
    white-space: normal
    word-break: break-all
   .margin-label-data
    font-size: 14px
    color: #333333
    width: 228px
    font-weight: 500
    padding-right: 85px
    margin-top: 15px
    white-space: normal
    word-break: break-all
  .margin-label-header-wrapper
   display: flex
   flex-direction: row
   height: 50px
   .margin-label
    font-size: 14px
    color: #333333
    margin-left: 70px
    min-width: 150px
    font-weight: 500
    margin-top: 15px
   .margin-label-data
    font-size: 14px
    color: #333333
    min-width: 228px
    font-weight: 500
    padding-right: 85px
    margin-top: 15px
  .padding-style-header
   margin-top: 37px
   margin-bottom: 17px
   &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 24px
</style>
