<template lang="pug">
b-button(
  :class="classes"
  :size="size"
  :style="{'font-size': fontSize}"
  @click="onClick"
) {{ label }}
</template>

<script>
export default {
  name: "MyButton",

  props: {
    label: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: "is-primary"
    },
    size: {
      type: String,
      default: "is-medium",
      validator: function(value) {
        return ["is-small", "is-medium", "is-large"].indexOf(value) !== -1
      }
    },
    fontSize: {
      type: String
    },
    backgroundColor: {
      type: String
    }
  },

  computed: {
    classes() {
      return {
        button: true,
        [this.variant]: true
      }
    },
    style() {
      return {
        backgroundColor: this.backgroundColor
      }
    }
  },

  methods: {
    onClick() {
      console.log("....click the button....")
      this.$emit("onClick")
    }
  }
}
</script>
<style lang="scss">
button.button {
  font-family: Niveau Grotesk;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 17px 35px;
  height: fit-content;
}

.button.is-medium {
  font-size: 14px;
  line-height: 16px;
}

.button.is-large {
  font-size: 18px;
  line-height: 21px;
}

.button.is-primary {
  color: white !important;
  background-color: #f15a42 !important;
}
</style>
