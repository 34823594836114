<template>
  <a-carousel
    ref="imgList"
    class="carousel"
    arrows
    :after-change="onChange"
    :dot-position="dotPosition"
    :dots="false"
  >
    <div
      v-for="(item, index) in dataList"
      :key="index"
      :class="
        `carousel-item ${
          carouselAttrs[index].params
            ? carouselAttrs[index].params.map(item => {
                return item
              })
            : ' '
        }`
      "
    >
      <div class="arrow-area">
        <button class="arrow-prev" @click="carouselPage('prev')">
          <img src="@/assets/img/icon/arrow-left.svg" />
        </button>
        <button class="arrow-next" @click="carouselPage('next')">
          <img src="@/assets/img/icon/arrow-right.svg" />
        </button>
      </div>
      <!-- <router-link v-if="item.params" :to="{ path: '/AgencyDetail', query: { id: index } }" class="img-box" tag="div">
				<img :src="require(`@/assets/img/agency/${item.imageSrc}`)" />
			</router-link> -->
      <!--      <router-link-->
      <!--        :style="-->
      <!--          `background-image: url(${require(`@/assets/img/agency/${carouselAttrs[index].imageSrc}`)})`-->
      <!--        "-->
      <!--        :to="{ path: '/AgencyDetail', query: { id: index } }"-->
      <!--        class="img-box"-->
      <!--        tag="div"-->
      <!--      >-->
      <!--        &lt;!&ndash; <img :src="require(`@/assets/img/agency/${item.imageSrc}`)" /> &ndash;&gt;-->
      <!--      </router-link>-->
      <div
        :style="
          `background-image: url(${require(`@/assets/img/agency/${carouselAttrs[index].imageSrc}`)})`
        "
        class="img-box"
      >
        <!-- <img :src="require(`@/assets/img/agency/${item.imageSrc}`)" /> -->
      </div>
      <div
        :class="carouselAttrs[index].fontPosition == 'left' ? 'text-wrap left' : 'text-wrap right'"
      >
        <div class="title">{{ item.title }}</div>
        <div class="subhead">{{ item.subhead }}</div>
      </div>
      <div v-if="item.content">
        <agency-detail :data="item"></agency-detail>
      </div>
    </div>
  </a-carousel>
</template>

<script>
import { Carousel } from "ant-design-vue"
import AgencyDetail from "./AgencyDetailSection.vue"

export default {
  components: {
    "a-carousel": Carousel,
    AgencyDetail
  },
  props: {
    dataList: {
      type: Array,
      default: null
    },
    carouselAttrs: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      dotPosition: "bottom"
    }
  },
  methods: {
    carouselPage(e) {
      if (e == "next") {
        this.$refs.imgList.next()
      } else if (e == "prev") {
        this.$refs.imgList.prev()
      }
    },
    onChange() {}
    // checkUrl() {
    // if (window.location.pathname.indexOf(e) != -1) {
    //   return false
    // }
    // return true
    // }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/shared/_fonts";

// this is font and style variable
$titleFont: "Poppins-Bold";
$titleColor: #12433d;
$titleSize: 48px;
$subheadSize: 31px;
$subheadHeight: 52px;
$contentFont: "Proxima-Nova";
$color: #000000;
$fontSize: 18px;
$lineHeight: 52px;

.carousel {
  width: 100%;
  height: auto;
  text-align: center;
  font-family: "Playfair Display";

  &.ant-carousel .slick-dots-bottom {
    top: 420px;
  }

  .slick-initialized .slick-slide {
    height: auto;
  }

  h3 {
    font-size: 50px;
  }

  .img-box {
    width: 100%;
    height: 443px;
    // height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;

    @media screen and (max-width: 1440px) {
      background-size: auto 100%;
    }
  }

  .arrow-area {
    display: flex;
    right: 35px;
    position: absolute;
    top: 380px;

    .arrow-prev {
      margin-right: 24px;
    }

    button {
      width: 40px;
    }
  }

  .carousel-item {
    position: relative;

    .text-wrap {
      position: absolute;
      top: 120px;

      &.left {
        left: 10%;
        text-align: left;
      }

      &.right {
        right: 10%;
        text-align: right;
      }

      .title {
        font-size: 55px;
        color: $titleColor;
        font-weight: 700;
        line-height: 52px;
        font-family: "Playfair Display";
        white-space: pre-line;
      }

      .subhead {
        font-family: "Proxima Nova";
        font-size: 25px;
        line-height: 28px;
        color: #2c2e30;
        white-space: pre-line;
      }
    }
  }
}

.carousel.ant-carousel .slick-slider .slick-track {
  height: auto !important;
}
</style>
