<template>
  <a-row class="admin-message-custom-filters">
    <a-col v-if="isEdit" :span="4" class="admin-message-custom-filters__desc">Recipient</a-col>
    <a-col :span="isEdit ? 20 : 24">
      <a-select
        :value="selectRecipient"
        label-in-value
        style="width: 100%;"
        :disabled="isDisalbed"
        dropdown-class-name="admin-message-custom-filters__dropdown"
        :placeholder="$t('Admins.AmdinMessage.selectReci')"
        option-label-prop="label"
        mode="multiple"
        :show-arrow="true"
        @select="chooseRecipient"
        @deselect="unchooseRecipient"
      >
        <template #suffixIcon>
          <a-icon
            type="caret-down"
            style="font-size: 20px; color: #4A8A5D; position: absolute; right: 2px;"
          />
        </template>
        <a-select-option
          v-for="(option, index) in recipientOptions"
          :key="`option_${index}`"
          :value="option.key"
          :label="option.label"
        >
          <a-checkbox :checked="option.isCheck" style="margin-right: 8px;"></a-checkbox>
          {{ option.label }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col
      v-if="isEdit"
      :span="4"
      class="admin-message-custom-filters__desc admin-message-custom-filters__marginTop"
      >Filter</a-col
    >
    <a-col
      :span="isEdit ? 20 : 24"
      :class="
        isEdit
          ? 'admin-message-custom-filters__marginTop'
          : 'admin-message-custom-filters__noTopBorder'
      "
    >
      <dropdown-list
        ref="filterDataRef"
        :placeholder="$t('Admins.AmdinMessage.selectFilter')"
        :selected-data="selectedFilter"
        style="z-index: 1030"
        :list="filterOptions"
        :disabled="isDisalbed"
        @isClickCheckbox="checkBoxClick"
        @apply="applyFilterData"
      />
    </a-col>
    <a-col
      v-if="isEdit && condition && condition.isAllTime"
      :span="4"
      class="admin-message-custom-filters__desc admin-message-custom-filters__marginTop"
      >Date Range</a-col
    >
    <a-col
      v-if="!isEdit || (isEdit && condition && condition.isAllTime)"
      :span="isEdit && condition && condition.isAllTime ? 20 : 24"
      :class="
        isEdit && condition && condition.isAllTime
          ? 'admin-message-custom-filters__marginTop'
          : 'admin-message-custom-filters__noTopBorder'
      "
    >
      <dropdown-list
        ref="filterDateRef"
        :is-date-list="true"
        :selected-data="
          isEdit && condition && condition.isAllTime ? [{ id: 1, name: 'All Time' }] : []
        "
        :list="dateList"
        :disabled="isDisalbed"
        :placeholder="$t('Admins.AmdinMessage.selectDateRange')"
        @isClickCheckbox="checkBoxClick"
        @apply="applyDateRange"
      />
    </a-col>
  </a-row>
</template>

<script>
import DropdownList from "./DropdownList.vue"
export default {
  components: { DropdownList },
  props: {
    condition: { type: Object, default: () => undefined },
    isEdit: { type: Boolean, default: () => false },
    brandList: { type: Array, default: () => [] },
    retailerList: { type: Array, default: () => [] },
    dateList: { type: Array, default: () => [] },
    isDisalbed: { type: Boolean, default: () => false }
  },
  data() {
    return {
      selectRecipient: this.condition ? this.condition.selectRecipient : undefined,
      selectedFilter: this.condition ? this.condition.selectFilter : undefined,
      selectedRange: this.condition ? this.condition.selectDate : undefined,
      filterOptions: [],
      recipientOptions: [
        {
          key: "Brand",
          label: "Brand",
          isCheck: false
        },
        {
          key: "Retailer",
          label: "Retailer",
          isCheck: false
        }
      ],
      customApplyData: {
        recipient: undefined,
        filterType: this.selectRecipient,
        filterId: undefined,
        filterName: undefined,
        filterData: undefined,
        filterStartTime: undefined,
        filterEndTime: undefined,
        filterDateOption: undefined
      }
    }
  },
  watch: {
    dateList(newValue) {
      if (newValue) {
        this.dateList = newValue
      }
    }
  },
  created() {
    this.customApplyData = {}
    if (this.isEdit && this.condition && this.condition.selectRecipient) {
      this.chooseRecipient(this.condition.selectRecipient)
      this.customApplyData.filterId = this.condition.filterClientId
      this.customApplyData.filterName = this.condition.filterClientName
      if (this.condition.isAllTime) {
        this.customApplyData.filterStartTime = "All Time"
      }
    }
  },
  methods: {
    deActive() {
      this.$refs["filterDataRef"].deActivate()
      this.$refs["filterDateRef"].deActivate()
    },
    checkBoxClick(isClick) {
      this.$emit("isClickCheckbox", isClick)
    },
    unchooseRecipient(value, option) {
      console.log(option)
      this.recipientOptions.find(item => item.key === value.key).isCheck = false
      this.selectRecipient = this.recipientOptions.filter(item => item.isCheck === true)
    },
    chooseRecipient(value) {
      console.log(value)
      console.log(this.selectRecipient)
      this.selectRecipient = []
      this.recipientOptions.forEach(item => (item.isCheck = false))
      let obj = this.recipientOptions.find(item => item.key === value.key)
      obj.isCheck = true
      this.selectRecipient = [obj]

      if (value.key === "Brand") {
        this.filterOptions = this.brandList
      } else {
        this.filterOptions = this.retailerList
      }
      this.customApplyData.filterType = this.selectRecipient[0]
      this.customApplyData.recipient = this.selectRecipient[0].key
      if (this.$refs && this.$refs["filterDataRef"]) {
        this.$refs["filterDataRef"].clearSelectedData()
      }
      this.$forceUpdate()
    },
    applyFilterData(data) {
      console.log(data)
      this.customApplyData.filterId = data.filterId
      this.customApplyData.filterName = data.filterName
      this.customApplyData.filterData = data.data
      //this.$message.info("Please select the Date Range.")
      //this.$emit("apply", this.customApplyData)
      if (this.isEdit) {
        this.$emit("apply", this.customApplyData)
      }
    },
    applyDateRange(data) {
      if (data) {
        this.customApplyData.filterDateOption = data.option
        this.customApplyData.filterStartTime = data.startTime
        this.customApplyData.filterEndTime = data.endTime
        console.log("the custom filter data.....")
        console.log(this.customApplyData)
        this.$emit("apply", this.customApplyData)
      } else {
        this.$emit("apply", undefined)
      }
    }
  }
}
</script>

<style lang="scss">
.admin-message-custom-filters {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__dropdown {
    line-height: 32px;
    color: #262626;
    border: 1px solid #4a8a5d;
    border-top: none;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: none;
  }
  &__desc {
    line-height: 22px;
    color: #595959;
    padding-top: 6px;
  }
  &__marginTop {
    margin-top: 20px;
  }
  &__date-range-icon {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 40px;
    align-items: center;
    text-align: center;
    color: #000000;
  }
}
.admin-message-custom-filters .ant-select-selection--single {
  height: 36px;
}
.admin-message-custom-filters .ant-select-selection {
  border: 1px solid #4a8a5d;
  box-sizing: border-box;
  border-radius: 2px;
}
.admin-message-custom-filters .admin-message-custom-filters__noTopBorder .ant-select-selection {
  border-top: none;
}
.admin-message-custom-filters .ant-select-selection-selected-value {
  color: #4a8a5d;
}
.admin-message-custom-filters .ant-select-selection:active,
.admin-message-custom-filters .ant-select-open .ant-select-selection,
.admin-message-custom-filters .ant-select-focused .ant-select-selection,
.admin-message-custom-filters .ant-select-selection:hover {
  border-color: #4a8a5d;
  box-shadow: none;
}
.admin-message-custom-filters__dropdown .ant-select-dropdown-menu-item-active,
.admin-message-custom-filters__dropdown
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  color: #262626;
  background-color: rgba(74, 138, 93, 0.15);
}
.admin-message-custom-filters__marginTop .ant-input {
  border-radius: 2px;
}
.admin-message-custom-filters__marginTop .ant-input:focus,
.admin-message-custom-filters__marginTop .ant-input:hover {
  border-color: #4a8a5d;
  box-shadow: none;
}
.admin-message-custom-filters__marginTop .ant-calendar-picker:hover,
.admin-message-custom-filters__marginTop .ant-calendar-picker:focus {
  border-color: #4a8a5d;
  box-shadow: none;
}
.admin-message-custom-filters__dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item
  .ant-select-selected-icon,
.admin-message-custom-filters__dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item:hover
  .ant-select-selected-icon {
  display: none;
}
</style>
