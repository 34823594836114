<template>
  <div class="chip-panel">
    <div
      v-show="isEdit"
      ref="editElm"
      contenteditable="true"
      class="chip-edit"
      :class="{
        red: !editOnly || isFocus,
        green: !isFocus,
      }"
      @input.stop="handleInput"
      @focus="handleFocus"
      @blur="handleDone"
      @keydown.enter="handleDone"
      @keypress.stop="handleKeyPress"
      v-text="displayValue"
    ></div>
    <div v-show="!isEdit" class="chip" contenteditable="false">
      <span @click.stop="changeToEdit">{{ displayValue }}</span
      ><a-icon
        v-if="isEditable"
        type="edit"
        @click.stop="changeToEdit"
      ></a-icon>
      <a-icon
        v-if="showExpansionIcon"
        type="down"
        :rotate="isExpanded ? 180 : 0"
      ></a-icon>
      <a-icon
        v-if="isShowDeleteIcon"
        type="close"
        @click="deleteItem()"
      ></a-icon>
    </div>
  </div>
</template>
<script>
export default {
  name: "LdChip",
  components: {},
  props: {
    index: { type: Number, required: true },
    item: { required: true },
    items: {},
    getDisplayFn: { type: Function },
    displayValue: { type: String, required: true },
    editOnly: { type: Boolean },
    isEditable: { type: Boolean },
    isShowDeleteIcon: { type: Boolean },
    showExpansionIcon: { type: Boolean },
    isExpanded: { type: Boolean },
    allowInsertParagraph: { type: Boolean },
  },
  data() {
    return {
      isFocus: false,
      isEdit: false,
      editValue: "",
    };
  },
  created() {
    this.editOnlyChange();
  },
  methods: {
    isValid() {
      let errorMessage = "";
      const value = this.getEditValue();
      if (!value) {
        errorMessage = "Value cannot be empty";
      } else {
        if (this.items && this.items.length) {
          const foundDuplicate = this.items.some((item, i) => {
            if (i != this.index) {
              const checkValue = this.getDisplayFn(item).toLowerCase();
              return checkValue === value.toLowerCase();
            }
            return false;
          });
          if (foundDuplicate) {
            errorMessage = "Duplicate values are not allowed";
          }
        }
      }

      if (errorMessage !== "") {
        this.$message.error(errorMessage);
        return false;
      }

      return true;
    },
    setEdit(isEdit) {
      this.isEdit = this.editOnly || (this.isEditable && isEdit);
    },
    changeToEdit() {
      this.setEdit(true);
      if (this.isEdit) {
        this.$nextTick(() => {
          this.$refs.editElm.focus();
        });
      }
    },
    handleInput() {},
    handleKeyPress() {},
    handleFocus() {
      this.isFocus = true;
    },
    handleDone() {
      if (!this.isValid()) {
        return;
      }
      this.isFocus = false;
      if (this.editOnly) {
        this.confirmEdit();
      } else {
        this.openConfirmDialog();
      }
    },
    openConfirmDialog() {
      this.$emit("open-confirm-dialog", this.index);
    },
    confirmEdit() {
      this.setEdit(false);
      // dialog confirm
      this.$emit("on-change", {
        index: this.index,
        item: this.item,
        newValue: this.getEditValue(),
      });
    },
    confirmCancel() {
      this.setEdit(false);
    },
    getEditValue() {
      let value = this.$refs.editElm.innerText || "";
      return value.trim();
    },
    editOnlyChange() {
      this.setEdit(this.editOnly);
    },
    deleteItem() {
      this.$emit("delete-item", { index: this.index, item: this.item });
    },
  },
};
</script>
<style lang="scss">
.chip-panel {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  border-radius: 2px;
  display: flex;
}
.chip-panel > * {
  padding: 6px 8px;
}

.chip {
  background-color: #fff;
  border: 1px solid #4a8a5d;
  white-space: nowrap;
  color: #4a8a5d;
  > * {
    margin-right: 4px;
  }
  > i {
    font-size: 12px;
  }
  &.red {
    border: 1px solid #ff6132;
    color: #ff6132;
  }
  &.green {
    border: 1px solid #4a8a5d;
    color: #4a8a5d;
  }
}

.chip-edit {
  background-color: #fff;
  color: #bfbfbf;
  &.red {
    border: 1px solid #ff6132;
  }
  &.green {
    border: 1px solid #4a8a5d;
  }
}
</style>
