<template>
  <div class="column">
    <div
      :class="
        isRetailer
          ? 'common-profile-categories__card-shadowed'
          : 'common-profile-categories__outlined'
      "
    >
      <div class="common-profile-categories__title">
        <span>
          {{
            isRetailer
              ? $t("Retailers.RetailerProfile.categories")
              : $t("Components.Brands.Profile.BrandProfile.productCategories")
          }}
        </span>
        <span v-if="!isRetailer">
          <RetailerPopup
            :text="$t('Components.Brands.Profile.BrandProfile.productCategoryToolTip')"
          />
        </span>
      </div>
      <div>
        <span v-if="categoriesForApi && categoriesForApi.length == 0">
          No Category
        </span>
        <span
          v-for="(category, index) in categoriesForApi"
          v-else
          :key="index"
          class="common-profile-categories__content"
        >
          {{ isRetailer ? categoryName(category.id) : category.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RetailerPopup from "@/components/retailers/profile/RetailerPopup"
export default {
  components: {
    RetailerPopup
  },
  props: {
    categoriesForApi: { type: Array, default: () => [] },
    isRetailer: { type: Boolean, default: true }
  },
  methods: {
    categoryName(id) {
      return this.$store.getters["reference/categoryName"](id)
    }
  }
}
</script>

<style lang="sass">
.common-profile-categories
    &__card-shadowed
        -webkit-box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
        border-radius: 2px
        padding: 32px 16px 46px 14px
    &__outlined
        border: 1px solid rgba(151, 151, 151, 0.234149)
        border-radius: 3px
        padding: 32px 7px 45px 16px
    &__space_width
        padding: 6px !important
    &__content
        max-width: 94px
        background: #F5F5F5
        border: 1px solid #D9D9D9
        box-sizing: border-box
        border-radius: 2px
        text-align: center
        font-family: Proxima Nova
        font-style: normal
        font-weight: normal
        font-size: 16px
        line-height: 24px
        color: #595959
        margin-right: 20px
        padding: 1px 8px
    &__content:last-child
        margin-right: 0px !important
    &__title
        font-size: 24px !important
        font-weight: normal !important
        line-height: 24px !important
        color: #000000 !important
        margin-bottom: 33px !important
</style>
