import axios from "@/api/axios"

export default {
  searchRetailer(params = {}) {
    return axios.get("/console/searchRetailer", { params })
  },
  searchBrand(params = {}) {
    return axios.get("/console/searchBrand", { params })
  },
  searchBestSeller(params = {}) {
    return axios.get("/console/search-best-sellers", { params })
  },
  searchNewArrival(params = {}) {
    return axios.get("/console/search-new-arrivals", { params })
  },
  searchProducts(params = {}) {
    return axios.get("/product/list", { params })
  },
  downloadBrandCsv(params = {}) {
    return axios.get("/searchBrandConsoleToCSV", { params })
  },
  downloadRetailerCsv(params = {}) {
    return axios.get("/searchRetailerConsoleToCSV", { params })
  },
  addCoupon(param) {
    return axios.put("/console/addCouponCode", JSON.stringify(param))
  },
  searchCoupon(params) {
    return axios.get("/console/searchCouponCode", { params })
  },
  getCouponHistory(params) {
    return axios.get("/console/searchCouponHistory", { params })
  },
  getCouponValue(params) {
    return axios.get("/console/coupon-code-verification", { params })
  }
}
