<template>
  <div style="margin: 0 auto; width: 300px; height: 300px; line-height: 300px;">
    <a :href="`lmapp://landingintl.com/verify-app?action=resetPassword&token=${token}`"
      ><a-button type="primary">Open Beautyfluent App</a-button></a
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      token: ""
    }
  },
  created() {
    console.log(this.$route)
    this.token = this.$route.query.token
    window.location.href = `lmapp://landingintl.com/verify-app?action=resetPassword&token=${this.token}`
  }
}
</script>
