import httpclient from "@/api/http-client";
//import firebase from "@/services/firebase-service";
const emailRegex = /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/;
export default {
  async isBrandNameExists(brandName) {
    return await httpclient
      .get(`/api/Brands/does-exist/name/${brandName}`)
      .then((res) => res.data)
      .catch(() => true);
  },
  async isBrandWebsiteExists(brandWebsite) {
    return await httpclient
      .get(`/api/Brands/does-exist/website/${encodeURIComponent(brandWebsite)}`)
      .then((res) => res.data)
      .catch(() => true);
  },
  async isEmailExists(email) {
    return await httpclient
      .get(`/api/Users/by-email/${encodeURIComponent(email)}/does-exist`)
      .then((res) => res.data)
      .catch(() => true);
  },
  validateEmail(email) {
    //const auth = firebase.auth();
    //return auth.validateEmail(email);
    return emailRegex.test(email);
  },
};
