<template>
  <a-row
    class="admin-message-brand-create-new-modal__choose-model-layer"
    :style="isSmallStatus ? 'padding: 14px 20px;' : 'padding: 40px 30px;'"
  >
    <div class="admin-message-brand-create-new-modal__intro-product">
      <div class="admin-message-brand-create-new-modal__intro-brand-title">
        {{ brand.name }}
      </div>
      <div class="admin-message-brand-create-new-modal__intro-brand-subtitle">
        To:
        <span :style="isTypeError ? 'color: #FF4D4F;' : 'color:#000000;'">{{ retailer.name }}</span>
      </div>
      <div class="admin-message-brand-create-new-modal__intro-brand-divider"></div>
      <div class="admin-message-brand-create-new-modal__intro-product-desc">
        <!-- {{ $t("Retailers.RetailerMessage.introProductMessage") }} -->
        {{ message ? message : "" }}
      </div>
      <div>
        <a-button
          class="admin-message-brand-create-new-modal__add-product"
          @click="clickToShowProductList"
        >
          <a-icon type="plus" />
          Add Product
        </a-button>
      </div>
      <a-row style="margin-top: 20px;">
        <draggable
          v-if="selectedProducts && selectedProducts.length > 0"
          v-model="selectedProducts"
          style="float: left;"
          filter=".nodrag"
          :move="checkMove"
        >
          <div
            v-for="(item, index) in selectedProducts"
            :key="`product_${item.id}`"
            class="admin-message-brand-create-new-modal__intro-product-list-item-outer"
            style="cursor: move;"
          >
            <a-icon
              type="minus-circle"
              class="admin-message-brand-create-new-modal__intro-product-list-item-icon"
              @click="removeProducts(index)"
            />
            <div class="admin-message-brand-create-new-modal__intro-product-list-item">
              <img
                v-if="item.images && item.images.length > 0"
                style="width: 100%; max-height: 100px;"
                :src="item.images[0].asset.url"
              />
              <div class="admin-message-brand-create-new-modal__intro-product-list-item-text">
                <div style="color: #000; font-size: 10px;">
                  <p>{{ item.name }}</p>
                  <p style="color: #595959; font-size: 7px;">{{ item.sku }}</p>
                  <p style="color: #262626; font-size: 9px;">
                    {{ priceFormat(item.msrpUsd) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <template v-if="unselectedProductNumber > 0">
            <div
              v-for="item in unselectedProductNumber"
              :key="`no_select_product_${item}`"
              class="admin-message-brand-create-new-modal__intro-product-list-item-outer nodrag"
            >
              <div
                class="admin-message-brand-create-new-modal__intro-product-list-item-no-product"
              ></div>
            </div>
          </template>
        </draggable>
        <div v-else>
          <div
            v-for="item in unselectedProductNumber"
            :key="`no_select_product_${item}`"
            class="admin-message-brand-create-new-modal__intro-product-list-item-outer"
          >
            <div
              class="admin-message-brand-create-new-modal__intro-product-list-item-no-product"
            ></div>
          </div>
        </div>
      </a-row>
      <div class="admin-message-brand-create-new-modal__sign-txt">
        Sincerely, <br />
        COSRX
      </div>
    </div>
    <intro-product-choose-modal ref="showProductChooseModal" @apply="applySelectedProducts" />
  </a-row>
</template>
<script>
import IntroProductChooseModal from "@/components/message/IntroProductChooseModal"
import draggable from "vuedraggable"
export default {
  components: {
    IntroProductChooseModal,
    draggable
  },
  props: {
    retailer: { type: Object, default: () => undefined },
    isTypeError: { type: Boolean, default: () => false },
    isSmallStatus: { type: Boolean, default: () => true },
    message: { type: String, default: () => undefined }
  },
  data() {
    return {
      selectedProducts: [],
      unselectedProductNumber: 6
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    }
  },
  methods: {
    clickToShowProductList() {
      this.$refs["showProductChooseModal"].openDialog(this.brand.id, this.selectedProducts)
    },
    applySelectedProducts(products) {
      this.selectedProducts = products
      if (!this.selectedProducts) {
        this.selectedProducts = []
      }
      this.unselectedProductNumber = 6 - this.selectedProducts.length
      this.$emit("products", this.selectedProducts)
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price) + " MSRP"
    },
    removeProducts(index) {
      this.selectedProducts.splice(index, 1)
      this.unselectedProductNumber = 6 - this.selectedProducts.length
      this.$forceUpdate()
    },
    checkMove(evt, originalEvent) {
      console.log(evt)
      console.log(originalEvent)
      if (!evt.relatedContext) {
        return false
      }
      return true
    }
  }
}
</script>
