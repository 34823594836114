<template lang="pug">
.dates-filter(v-click-outside="hideCalendars")
  .dates-filter__header-body(:class="{ 'dates-filter__header-body--extended': isCalendarsOpen }")
    .dates-filter__header-name(@click="openList()") {{ title }}
      span.dates-filter__header--icon(v-show="!isOpen")
        img(src="@/assets/img/inputs/filters-arrow-icon.svg")
      span.dates-filter__header--icon(v-show="isOpen && !isCalendarsOpen")
        img(src="@/assets/img/inputs/filters-arrow-icon-up.svg")
      span.dates-filter__header--reset(v-show="isOpen && isCalendarsOpen" @click="resetFilter()") {{$t('Components.Orders.DatesFilter.resetAll')}}
    .dates-filter__items(:class="{ 'dates-filter__items--visible': isOpen}")
      .control.has-icons-right
        input.input(placeholder="From - To" readonly :value="formattedDates" @focus="displayCalendars(true)")
        img.is-small.is-right.dates-filter__icon(src="~@/assets/img/calendar.png" )

      .d-wrapper
        Datepicker(:date="shipDate" @changeDate="changeShipDate" :isCalendarOpen='isCalendarsOpen')
        Datepicker(:date="cancelDate", @changeDate="changeCancelDate" :isCalendarOpen="isCalendarsOpen")
</template>

<script>
import Datepicker from "@/components/orders/Datepicker"

export default {
  components: { Datepicker },
  props: {
    title: { type: String, default: "" }
  },
  data() {
    return {
      shipDate: "",
      cancelDate: "",
      isOpen: false,
      isCalendarsOpen: false,
      query: {}
    }
  },
  computed: {
    formattedDates() {
      if (!this.shipDate && !this.cancelDate) {
        return
      }
      return [this.shipDate, this.cancelDate].join(" - ")
    }
  },
  methods: {
    displayCalendars(state = true) {
      this.isCalendarsOpen = state
    },
    openList() {
      this.isOpen = !this.isOpen
    },
    async resetFilter() {
      this.isCalendarsOpen = false
      this.shipDate = ""
      this.cancelDate = ""
      await this.fetchOrdersByDate()
    },
    async changeShipDate(date) {
      this.shipDate = date
      if (this.cancelDate) {
        this.isCalendarsOpen = false
      }
      await this.fetchOrdersByDate()
    },
    async changeCancelDate(date) {
      this.cancelDate = date
      if (this.shipDate) {
        this.isCalendarsOpen = false
      }
      await this.fetchOrdersByDate()
    },
    async fetchOrdersByDate() {
      this.syncQueryWithDates()
      try {
        await this.$store.dispatch("order/fetchOrdersByParams", {
          params: this.query
        })
      } catch (error) {
        console.error(error)
      }
    },
    syncQueryWithDates() {
      if (this.title === "Order Date") {
        this.query["order-date"] = [this.shipDate, this.cancelDate].join(",")
      }
    },
    hideCalendars() {
      this.isCalendarsOpen = false
    }
  }
}
</script>

<style lang="sass">
.dates-filter
  .d-wrapper
    width: 600px
    z-index: 10
    position: absolute
    background: white
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3)
    max-height: 1000px

  &__items
    display: none
    overflow: hidden
    margin-top: 10px

  &__icon
    cursor: pointer
    position: absolute
    right: 15px
    top: 7px

  &__subtitle
    font-weight: 600
    font-size: 14px

  &__items--visible
    display: block

  &__header-body
    position: relative
    display: block
    cursor: pointer
    padding: 16px 0px 16px 0px
    border-top: 1px solid #EBEFF2
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

    &--extended
      height: 420px

  &__header--icon
    float: right
    padding-right: 10px

  &__header--reset
    float: right
    color: #B3B3B3

  &__input
    width: 200px
</style>
