<template>
  <div class="retailer-cart">
    <h3 class="retailer-cart__header">
      {{ $t("Retailers.RetailerCart.shoppingCart") }}
    </h3>
    <div class="columns is-mobile retailer-cart__tabs">
      <div
        class="column is-narrow is-paddingless retailer-cart__tab"
        :class="{ 'retailer-cart__tab--active': isActiveTab('cart') }"
        @click.prevent="toggleTab('cart')"
      >
        {{ $t("Retailers.RetailerCart.cart") }} &nbsp;
      </div>
      <div
        class="column is-narrow is-paddingless retailer-cart__tab"
        :class="{ 'retailer-cart__tab--active': isActiveTab('saved') }"
        @click.prevent="toggleTab('saved')"
      >
        {{ $t("Retailers.RetailerCart.saved") }} &nbsp;
      </div>
    </div>
    <div class="columns">
      <div class="column is-8">
        <div class="columns is-multiline">
          <div
            v-for="(cartProductGroup, index) in cartProductsByTab"
            v-show="cartProductGroup.length"
            :key="index"
            class="column is-12"
          >
            <div class="retailer-cart__container">
              <div class="retailer-cart__banner">
                <div class="retailer-cart__banner-border">
                  <div class="columns">
                    <div class="column is-2 retailer-cart__banner-title">
                      <h1>{{ brandName(cartProductGroup) }}</h1>
                    </div>
                    <div class="column is-1 retailer-cart__banner-price">
                      <h1 v-show="currentTab === 'cart'">
                        {{ priceFormat(cartProductGroupSubtotal(cartProductGroup)) }}
                      </h1>
                    </div>
                    <div class="column retailer-cart__banner-links">
                      <a
                        v-show="currentTab === 'cart'"
                        class="retailer-cart__banner-links--link"
                        @click="saveProductGroupForLater(cartProductGroup)"
                      >
                        {{ $t("Retailers.RetailerCart.saveForLater") }}
                        <div class="retailer-cart__banner-links--link-underlined"></div>
                      </a>
                      <a
                        v-show="currentTab === 'saved'"
                        class="retailer-cart__banner-links--link"
                        @click="moveProductGroupToCart(cartProductGroup)"
                      >
                        {{ $t("Retailers.RetailerCart.moveToCart") }}
                        <div class="retailer-cart__banner-links--link-underlined"></div>
                      </a>
                      <p class="retailer-cart__banner-links--divider">|</p>
                      <a
                        v-show="currentTab === 'cart'"
                        class="retailer-cart__banner-links--link"
                        @click="checkoutOnlyThisBrand(cartProductGroup)"
                      >
                        {{ $t("Retailers.RetailerCart.checkoutThisBrand") }}
                        <div class="retailer-cart__banner-links--link-underlined"></div>
                      </a>
                      <a
                        v-show="currentTab === 'saved'"
                        class="retailer-cart__banner-links--link"
                        @click="removeProductGroupFromCart(cartProductGroup)"
                      >
                        {{ $t("Retailers.RetailerCart.delete") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="currentTab === 'cart'" class="retailer-cart__subtitle-row">
                <div class="columns">
                  <div class="column is-6">
                    <div class="retailer-cart__subtitle-row-column">
                      {{ $t("Retailers.RetailerCart.item") }}
                    </div>
                  </div>
                  <div class="column is-2">
                    <div class="retailer-cart__subtitle-row-column">
                      {{ $t("Retailers.RetailerCart.orderType") }}
                    </div>
                  </div>
                  <div class="column is-1">
                    <div class="retailer-cart__subtitle-row-column">
                      {{ $t("Retailers.RetailerCart.quantity") }}
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <div class="retailer-cart__subtitle-row-column--padding">
                      {{ $t("Retailers.RetailerCart.amount") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="retailer-cart__product">
                <template v-for="(cartProduct, index) in cartProductGroup">
                  <SavedCartProductGroup
                    v-if="cartProduct.isSaved"
                    :key="index"
                    :cart-product="cartProduct"
                    @removeProductFromCart="removeProductFromCart"
                    @moveProductToCart="moveProductToCart"
                  />
                  <CartProductGroup
                    v-else
                    :key="index"
                    :cart-product="cartProduct"
                    @removeOverStockProduct="removeOverStockProduct"
                    @addOverStockProduct="addOverStockProduct"
                    @removeProductFromCart="removeProductFromCart"
                    @moveProductToCart="moveProductToCart"
                    @syncCartWithAPI="syncCartWithAPI"
                    @saveProductForLater="saveProductForLater"
                  />
                </template>
              </div>
              <div v-show="currentTab === 'cart'" class="retailer-cart__subtotals">
                <div class="columns">
                  <div class="column is-9 has-text-right">
                    <div class="retailer-cart__subtotals-item">
                      {{ $t("Retailers.RetailerCart.itemSubtotal") }}
                    </div>
                  </div>
                  <div class="column retailer-cart__subtotals-prices">
                    <div class="retailer-cart__subtotals-item">
                      {{ priceFormat(cartProductGroupSubtotal(cartProductGroup)) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="retailer-cart__summary">
          <div v-show="currentTab === 'cart'" class="retailer-cart__summary--title">
            {{ $t("Retailers.RetailerCart.orderSummary") }}
          </div>
          <div v-show="currentTab === 'saved'" class="retailer-cart__summary--title">
            {{ $t("Retailers.RetailerCart.inYourCart") }}
          </div>
          <div
            v-for="(cartProductGroup, index) in regularCartProductGroups"
            v-show="cartProductGroup.length"
            :key="index"
            class="retailer-cart__summary--brand-order"
          >
            <p>{{ brandName(cartProductGroup) }}</p>
            <p>{{ priceFormat(cartProductGroupSubtotal(cartProductGroup)) }}</p>
          </div>
          <div class="retailer-cart__summary--brand-order">
            <p>{{ $t("Retailers.RetailerCart.estimatedTotal") }}</p>
            <p>{{ priceFormat(cartProductsTotal()) }}</p>
          </div>
        </div>
        <div class="retailer-cart__button-position" :class="{ disabled: !isCartEmpty() }">
          <button
            class="button retailer-cart__button is-black is-fullwidth"
            style="background-color: black;"
            :disabled="!canCheckOut"
            @click="onClickProceedCheckout"
          >
            {{ $t("Retailers.RetailerCart.proceedToCheckout") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CartProductGroup from "@/components/retailers/cart/CartProductGroup"
import SavedCartProductGroup from "@/components/retailers/cart/SavedCartProductGroup"
import OrderClient from "@/api/order-client"
export default {
  components: { CartProductGroup, SavedCartProductGroup },
  data() {
    return {
      currentTab: "cart",
      cartProductsByBrand: [],
      currentRoute: this.$route,
      overStockPurchaseProductId: new Set(),
      overStockTesterProductId: new Set(),
      canCheckOut: true,
      isUpdateCart: false,
      isNeedCheckOut: false,
      order: {
        isRetailerAccepted: true,
        retailerReferenceId: "",
        orderDate: new Date().toISOString().split("T")[0],
        shipDate: "",
        cancelDate: "",
        shipToAddress: {
          addressee: "",
          streetAddressLine_1: "",
          streetAddressLine_2: "",
          city: "",
          region: "",
          postalCode: "",
          country: { id: "" }
        },
        contact: {
          name: "",
          phone: ""
        }
      }
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    products() {
      return this.$store.getters["product/productsForCart"]
    },
    orderTypes() {
      return this.$store.getters["reference/orderTypes"]
    },
    purchaseTypes() {
      return this.$store.getters["reference/purchaseTypes"]
    },
    sampleTypes() {
      return this.$store.getters["reference/sampleTypes"]
    },
    orderBatchId() {
      return this.$store.getters["order/orderBatchId"]
    },
    cartProducts() {
      let data = this.$store.getters["retailer/cartProducts"]
      return data
    },
    cartProductsByTab() {
      if (this.currentTab === "cart") {
        return this.regularCartProductGroups
      } else {
        return this.savedCartProductGroups
      }
    },
    regularCartProductGroups() {
      let data = this.cartProductsByBrand.map(cartProductGroup =>
        cartProductGroup.filter(cartProduct => !cartProduct.isSaved)
      )
      return data
    },
    savedCartProductGroups() {
      return this.cartProductsByBrand.map(cartProductGroup =>
        cartProductGroup.filter(cartProduct => cartProduct.isSaved)
      )
    },
    orderDetailsForAPI() {
      const flattenGroups = this.cartProductsByBrand
        .map(cartProductGroup => cartProductGroup)
        .flat()
      flattenGroups.forEach(item => {
        item.orderDetails.forEach(data => {
          data["brand"] = item.product.brand
          data.product.name = item.product.name
        })
      })
      return flattenGroups.map(cartProduct => cartProduct.orderDetails).flat()
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    cartProductGroups() {
      return this.$store.getters["retailer/cartProductGroups"]
    }
  },
  watch: {
    async cartProductGroups() {
      this.initCartProductLogistic()
      this.cartProductsByBrand = this.cartProductGroups.map(
        cartProductGroup => cartProductGroup.products
      )
    }
  },
  async created() {
    let params = {}
    if (this.$route.name == "adminRetailerCart") {
      params = { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
      await this.$store.dispatch("retailer/fetchCart", params)
    }
    await this.$store.dispatch("reference/setReference")
    const productIds = this.$store.getters["retailer/cartProducts"].map(
      cartProduct => cartProduct.product.id
    )
    if (productIds && productIds.length !== 0) {
      await this.$store.dispatch("product/getProductsByIds", { productIds, params })
    }
    this.$store.dispatch("retailer/setCartProductGroups")
    await this.$store.dispatch("brand/fetchAllBrands", { params: { fields: "name,id" } })
    this.brands = this.$store.getters["brand/brands"]
    this.cartProductsByBrand = this.cartProductGroups.map(
      cartProductGroup => cartProductGroup.products
    )
  },
  methods: {
    isActiveTab(tab) {
      return this.currentTab === tab
    },
    isCartEmpty() {
      return this.cartProducts.filter(
        cartProduct =>
          cartProduct.saved === false &&
          cartProduct.orderType.id === "purchase" &&
          cartProduct.quantity > 0
      ).length
    },
    removeProductFromCart(productId) {
      this.cartProductsByBrand = this.cartProductsByBrand.map(cartProductGroup =>
        cartProductGroup.filter(cartProduct => cartProduct.id !== productId)
      )
      let params =
        this.$route.name == "adminRetailerCart"
          ? { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
          : {}
      this.$store.dispatch("retailer/removeProductFromCart", { productId, params })
    },
    removeProductGroupFromCart(productGroup) {
      productGroup.map(product => this.removeProductFromCart(product.id))
    },
    syncCartWithAPI(orderDetail, key, value) {
      if (value === "") {
        value = "0"
      }
      orderDetail[key] = value
      this.updateCart()
    },
    showErrorInfo(error) {
      let array = JSON.parse(error)
      let errNotEnoughMsg = ""
      let errNotInMsg = ""
      let products = this.orderDetailsForAPI.map(data => data.product).flat()
      if (array && array.length > 0) {
        array.forEach(item => {
          let key = Object.keys(item)
          let product = products.filter(pro => pro.id === key[0])
          if (item[key[0]] === -1) {
            errNotEnoughMsg += product[0].name + ","
          } else {
            errNotInMsg += product[0].name + ","
          }
        })
        if (errNotEnoughMsg.length > 0) {
          errNotEnoughMsg =
            errNotEnoughMsg.substring(0, errNotEnoughMsg.length - 1) + " inventory not enough!"
        }
        if (errNotInMsg.length > 0) {
          errNotInMsg = errNotInMsg.substring(0, errNotInMsg.length - 1) + " not in inventory!"
        }
        this.$message.error(`${errNotEnoughMsg} ${errNotInMsg}`)
      }
      // this.$message.error(data.developerMessage)
    },
    updateCart() {
      this.isUpdateCart = true
      let orderDetails = this.orderDetailsForAPI
      let params =
        this.$route.name == "adminRetailerCart"
          ? { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
          : {}
      this.$store
        .dispatch("retailer/updateCart", { orderDetails, params })
        .then(data => {
          this.isUpdateCart = false
          if (data && data.developerMessage) {
            this.showErrorInfo(data.developerMessage)
          } else {
            if (this.$route.name == "adminRetailerCart") {
              params = { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
              this.$store.dispatch("retailer/fetchCart", params)
            } else {
              this.$store.dispatch("retailer/fetchCart", {})
            }
          }
          if (this.isNeedCheckOut) {
            this.onClickProceedCheckout()
          }
        })
        .catch(err => {
          this.isUpdateCart = false
          this.showErrorInfo(err.response.data)
          if (this.isNeedCheckOut) {
            this.onClickProceedCheckout()
          }
        })
    },
    orderTypeName(orderTypeId) {
      const orderType = this.orderTypes.find(orderType => orderType.id === orderTypeId)
      return orderType && orderType.name
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    moveProductToCart(cartProduct, isPurchase) {
      cartProduct.isPurchase = isPurchase
      cartProduct.orderDetails = this.$store.getters["retailer/orderDetailsForCartProductGroup"](
        cartProduct.orderDetails,
        isPurchase
      )
      cartProduct.orderDetails.forEach(orderDetail => (orderDetail.saved = false))
      cartProduct.isSaved = false
      this.updateCart()
    },
    moveProductGroupToCart(cartProductGroup) {
      cartProductGroup.map(cartProduct => this.moveProductToCart(cartProduct, true))
    },
    saveProductForLater(cartProduct) {
      cartProduct.isSaved = true
      cartProduct.orderDetails.forEach(orderDetail => (orderDetail.saved = true))
      this.updateCart()
    },
    saveProductGroupForLater(cartProductGroup) {
      cartProductGroup.map(cartProduct => this.saveProductForLater(cartProduct))
    },
    toggleTab(tab) {
      this.currentTab = tab
    },
    brandName(cartProductGroup) {
      if (cartProductGroup.length) {
        const brandId = cartProductGroup[0].product.brand.id
        const brand = this.$store.getters["brand/brandById"](brandId)
        return brand && brand.name
      }
    },
    cartProductsTotal() {
      return this.regularCartProductGroups
        .map(cartProductGroup => this.cartProductGroupSubtotal(cartProductGroup))
        .reduce((a, b) => {
          return a + b
        }, 0)
    },
    cartProductGroupSubtotal(cartProductGroup) {
      const productsPrices = cartProductGroup.map(cartProduct =>
        cartProduct.orderDetails
          .map(orderDetail =>
            orderDetail.orderType.id === "purchase"
              ? parseFloat(orderDetail.quantity) * parseFloat(cartProduct.product.wholesalePriceUsd)
              : 0
          )
          .reduce((a, b) => {
            return a + b
          }, 0)
      )
      return productsPrices.reduce((a, b) => {
        return a + b
      }, 0)
    },
    async checkoutOnlyThisBrand(cartProductGroup) {
      const brandId = cartProductGroup[0].product.brand.id
      // let params =
      //   this.$route.name == "adminRetailerCart"
      //     ? { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
      //     : {}
      await this.$store.dispatch("retailer/checkoutOnlyOneBrandProducts", { brandId })
      this.onClickProceedCheckout(brandId)
    },
    async onClickProceedCheckout(id) {
      if (this.isUpdateCart) {
        this.isNeedCheckOut = true
        return
      } else {
        this.isNeedCheckOut = false
      }
      let brandId = null
      if (typeof id === "string") {
        brandId = id
      }
      try {
        let order = this.order
        order.retailer = { id: this.retailer.id }
        let params =
          this.$route.name == "adminRetailerCart"
            ? { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
            : {}
        await this.$store.dispatch("order/create", { order, params, brandId })
        this.$route.name == "adminRetailerCart"
          ? this.$router.push({
              name: "adminRetailerCheckout",
              params: { orderBatchId: this.orderBatchId }
            })
          : this.$router.push({
              name: "retailerCheckout",
              params: { orderBatchId: this.orderBatchId }
            })
      } catch (error) {
        this.displayError(error)
      }
    },
    removeOverStockProduct(productId, isPurchase) {
      if (isPurchase) {
        this.overStockPurchaseProductId.delete(productId)
      } else {
        this.overStockTesterProductId.delete(productId)
      }
      this.isCanCheckout()
    },
    addOverStockProduct(productId, isPurchase) {
      if (isPurchase) {
        this.overStockPurchaseProductId.add(productId)
      } else {
        this.overStockTesterProductId.add(productId)
      }
      this.isCanCheckout()
    },
    isCanCheckout() {
      if (
        (this.overStockPurchaseProductId && this.overStockPurchaseProductId.size > 0) ||
        (this.overStockTesterProductId && this.overStockTesterProductId.size > 0)
      ) {
        this.canCheckOut = false
      } else {
        this.canCheckOut = true
      }
    },
    initCartProductLogistic() {
      if (this.cartProductGroups && this.cartProductGroups.length > 0) {
        this.cartProductGroups.forEach(item => {
          OrderClient.updateLogisticTerms(item.brandId, "get")
            .then(res => {
              if (res && res.data && res.data.length > 0) {
                let isLogistic = this.isLogisticOpen(res.data, item.brandId, this.retailer.id)
                item.products.forEach(product => (product["logistic"] = isLogistic))
                this.$store.commit("retailer/SET_BRAND_LOGISTIC", {
                  brandId: item.brandId,
                  isLogistic: isLogistic
                })
              } else {
                item.products.forEach(product => (product["logistic"] = false))
                this.$store.commit("retailer/SET_BRAND_LOGISTIC", {
                  brandId: item.brandId,
                  isLogistic: false
                })
              }
            })
            .catch(() => {
              item.products.forEach(product => (product["logistic"] = false))
              this.$store.commit("retailer/SET_BRAND_LOGISTIC", {
                brandId: item.brandId,
                isLogistic: false
              })
            })
        })
      }
    },
    isLogisticOpen(data, brandId, retailerId) {
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].standard &&
          data[i].brandId === brandId &&
          data[i].enabled &&
          data[i].accepted
        ) {
          return true
        } else if (
          !data[i].standard &&
          data[i].brandId === brandId &&
          data[i].retailerId === retailerId &&
          data[i].enabled &&
          data[i].accepted
        ) {
          return true
        }
      }
      return false
    }
  }
}
</script>
<style lang="sass">
.cart-product-group
  min-height: 220px

.cart-product-group__link
  color: #161616
  font-size: 14px
  font-weight: 300
  letter-spacing: 0.16px
  line-height: 18px
  text-decoration: underline !important
  text-align: center

.retailer-cart
  margin-bottom: 32px
  min-height: 80vh
  margin-left: 124px
  margin-right: 122px

  &__navbar
    margin-top: 20px
    padding-bottom: 10px
    border-bottom: 1px solid #DFE3E8

  &__tabs
    margin-top: 25px
    border-bottom: 1px solid #DFE3E8
    height: 40px
    box-sizing: border-box

  &__container
    border: 1px solid #E5E5E5
    margin-bottom: 25px

  &__header
    padding-top: 30px
    width: 200px
    color: #212B36
    font-size: 28px
    font-weight: 300

  &__tab
    font-size: 14px
    color: #292929
    font-weight: 600
    width: 115px
    cursor: pointer
    width: 100px
    text-align: center
    margin-right: 5px

  &__tab--active
    border-bottom: 2px solid black
    letter-spacing: 0.1px

  &__banner
    height: 80px
    border: 1px solid #E5E5E5
    background-color: #F4F6F8
    position: relative

    &-border
      height: 3px
      background-color: rgba(145,158,171,0.54)
      position: absolute
      top: 0px
      width: 100%

    &-title
      align-items: center
      display: flex
      justify-content: space-evenly
      justify-items: start
      height: 83px
      margin-left: 23px
      color: #333333
      font-size: 16px
      font-weight: 500
      line-height: 24px

    &-price
      align-items: center
      display: flex
      justify-content: space-evenly
      justify-items: start
      height: 83px
      margin-left: 40px
      color: #AAAAAA
      font-size: 16px
      line-height: 20px

    &-links
      display: flex
      height: 83px
      align-items: center
      justify-content: flex-end
      margin-right: 23px

      &--divider
        margin: 0px 16px 0px 16px

      &--link
        text-transform: capitalize
        color: #161616
        font-size: 14px
        font-weight: 300
        letter-spacing: 0.16px
        line-height: 18px
        position: relative

        &-underlined
          border-bottom: 1px solid #393939
          position: absolute
          top: 17px
          width: 100%

  &__input
    width: 74px
    height: 41px
    position: relative
    text-align: center

    &-plus
      position: absolute
      top: 0px
      right: 0px
      border-left: 1px solid #E5E5E5
      border-bottom: 1px solid #E5E5E5
      height: 21px
      width: 21px
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer

    &-minus
      position: absolute
      top: 20px
      right: 0px
      border-left: 1px solid #E5E5E5
      height: 21px
      width: 21px
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer

  &__input_right
    text-align: right !important

  &__title
    color: #292929 !important
    font-size: 14px
    line-height: 18px
    margin: 24px 0px 5px 0px

  &__subtitle_black
    color: #000000
    font-size: 16px
    font-weight: 600
    line-height: 22px
    margin-bottom: 3px

  &__subtitle-crossed-out
    text-decoration: line-through
    position: absolute
    top: 93px
    color: #8D8D8D
    font-size: 14px
    line-height: 18px
    left: 58px

  &__subtitle
    color: #9B9B9B
    font-size: 14px
    line-height: 20px
    margin-bottom: 24px

  &__subtitle-brown
    color: #442B2D
    font-size: 14px
    line-height: 22px

  &__type
    color: #000000
    font-size: 14px
    line-height: 22px

  &__product
    display: block

  &__product-border
    border-bottom: 1px solid #dbdbdb

  &__picture
    padding: 30px

  &__price
    padding: 7px
    text-align: justify
    position: relative

  &__price-input
    width: 60px
    margin-top: 5px

  &__footer
    padding: 0px 10px 20px
    font-size: 11px

  &__total
    padding: 0px 10px 20px
    font-size: 13px
    display: flex
    justify-content: flex-end

  &__type-price
    color: #000000
    font-size: 14px
    font-weight: 600

  &__type
    font-size: 14px

  &__product-info
    padding: 30px 0px 30px 0px

  &__image
    display: flex
    justify-content: center

    img
      max-height: 112px
      max-width: 112px

  &__table-row
    height: 90px
    margin-left: -2px

  &__table-border
    padding-top: 20px
    border-bottom: 1px solid #E5E5E5

  &__column-with-image
    display: grid !important
    justify-content: center
    padding: 24px 0px 20px 0px
    align-content: space-between

  &__column-with-table
    margin-top: 6px

  &__summary
    border: 1px solid #E5E5E5
    margin-left: 25px

    &--title
      color: #000000
      font-size: 18px
      letter-spacing: 0.25px
      line-height: 24px
      padding: 24px 23px

    &--brand-order
      display: flex
      justify-content: space-between
      height: 44px
      align-items: center
      margin: 0px 19px
      border-top: 1px solid #E5E5E5
      margin: 0px 16px

  &__button
    height: 46px
    font-size: 13px
    line-height: 20px
    margin-top: 8px

  &__button-position
    margin-left: 25px

  &__table
    width: 100%

  &__close-button
    cursor: pointer

  &__alert
    color: #D0021B
    margin-top: -25px

  &__prices
    padding-top: 30px

  &__subtitle--line-through
    color: #8D8D8D
    font-size: 14px
    text-decoration: line-through

  &__subtotals
    padding-top: 10px
    font-size: 14px
    letter-spacing: 0.25px
    color: #000000

  &__subtotals-item
     padding-bottom: 10px
     padding-left: 25px

     &--bold
       font-weight: bold

  &__subtotals-prices
    padding-left: 25px

  &__subtitle-row
    padding-top: 15px
    padding-bottom: 15px
    border-bottom: 1px solid #E5E5E5

  &__subtitle-row-column
    font-size: 14px
    color: #292929
    margin-left: 20px

    &--padding
     font-size: 14px
     color: #292929
     padding-left: 50px

.disabled
  pointer-events: none

.quantity-input
  max-width: 80px
.retailer-cart__banner-title >h1
  font-size: 16px
  color: #333
  line-height: 24px
  font-weight: normal
.retailer-cart__banner-price >h1
  font-size: 16px
  color: #333
  line-height: 20px
  font-weight: normal
</style>
