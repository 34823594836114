import adminClient from "@/api/admin-console-client"

export default {
  namespaced: true,

  state: {
    retailers: {},
    brands: {},
    products: {},
    bestSellers: {},
    newArrivals: {},
    productSearchParam: {},
    productSearchKeyWords: "",
    bestSelectDays: undefined,
    newArrivalDays: undefined,
    couponListData: {},
    isSuccess: false,
    couponHistoryData: {}
  },

  getters: {
    retailers(state) {
      return state.retailers
    },
    brands(state) {
      return state.brands
    },
    products(state) {
      return state.products
    },
    productSearchParam(state) {
      return state.productSearchParam
    },
    productSearchKeyWords(state) {
      return state.productSearchKeyWords
    },
    bestSellers(state) {
      return state.bestSellers
    },
    newArrivals(state) {
      return state.newArrivals
    },
    bestSellersSelect(state) {
      return state.bestSelectDays
    },
    newArrivalSelect(state) {
      return state.newArrivalDays
    },
    coupons(state) {
      return state.couponListData
    },
    isSuccess(state) {
      return state.isSuccess
    },
    couponHistoryData(state) {
      return state.couponHistoryData
    }
  },

  mutations: {
    SET_RETAILERS(state, clients) {
      state.retailers = clients
      if (state.retailers && state.retailers.list && state.retailers.list.length > 0) {
        let start = (Number(state.retailers.index) - 1) * Number(state.retailers.limit) + 1
        for (let i = 0; i < state.retailers.list.length; i++) {
          state.retailers.list[i].rank = Number(start) + Number(i)
        }
      }
    },
    SET_BRANDS(state, clients) {
      state.brands = clients
      if (state.brands && state.brands.list && state.brands.list.length > 0) {
        let start = (Number(state.brands.index) - 1) * Number(state.brands.limit) + 1
        for (let i = 0; i < state.brands.list.length; i++) {
          state.brands.list[i].rank = Number(start) + Number(i)
        }
      }
    },
    SET_PRODUCTS(state, clients) {
      state.products = clients
      if (state.products && state.products.products && state.products.products.length > 0) {
        let start = (Number(state.products.index) - 1) * Number(state.products.limit) + 1
        for (let i = 0; i < state.products.products.length; i++) {
          state.products.products[i].rank = Number(start) + Number(i)
        }
      }
    },
    SET_PRODUCT_SEARCH_PARAM(state, param) {
      state.productSearchParam = param
    },
    SET_PRODUCT_SEARCH_KEYWORD(state, param) {
      state.productSearchKeyWords = param
    },
    SET_BEST_SELLER_PARAM(state, param) {
      state.bestSelectDays = param
    },
    SET_ARRIVAL_SELECT_PARAM(state, param) {
      state.newArrivalDays = param
    },
    SET_BEST(state, clients) {
      state.bestSellers = clients
      if (state.bestSellers && state.bestSellers.list && state.bestSellers.list.length > 0) {
        let start = (Number(state.bestSellers.index) - 1) * Number(state.bestSellers.limit) + 1
        for (let i = 0; i < state.bestSellers.list.length; i++) {
          state.bestSellers.list[i].rank = Number(start) + Number(i)
        }
      }
    },
    SET_ARRIVAL(state, clients) {
      state.newArrivals = clients
      if (state.newArrivals && state.newArrivals.list && state.newArrivals.list.length > 0) {
        let start = (Number(state.newArrivals.index) - 1) * Number(state.newArrivals.limit) + 1
        for (let i = 0; i < state.newArrivals.list.length; i++) {
          state.newArrivals.list[i].rank = Number(start) + Number(i)
        }
      }
    },
    SET_COUPONS(state, data) {
      state.couponListData = data
    },
    SET_IS_SUCCESS(state, data) {
      if (data) {
        state.isSuccess = true
      } else {
        state.isSuccess = false
      }
    },
    SET_COUPON_HISTORY(state, data) {
      state.couponHistoryData = data
    }
  },

  actions: {
    async fetchRetailers({ commit }, params) {
      try {
        const { data } = await adminClient.searchRetailer(params)
        // data.sort((a, b) => a.name.localeCompare(b.name))
        commit("SET_RETAILERS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchBrands({ commit }, params) {
      try {
        const { data } = await adminClient.searchBrand(params)
        // data.sort((a, b) => a.name.localeCompare(b.name))
        commit("SET_BRANDS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchProducts({ commit }, params) {
      try {
        params["is-download-csv"] = false
        const { data } = await adminClient.searchProducts(params)
        commit("SET_PRODUCTS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchBestSellers({ commit }, params) {
      try {
        params["is-download-csv"] = false
        const { data } = await adminClient.searchBestSeller(params)
        commit("SET_BEST", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchNewArrival({ commit }, params) {
      try {
        params["is-download-csv"] = false
        const { data } = await adminClient.searchNewArrival(params)
        commit("SET_ARRIVAL", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async downloadBrand({ commit }, params) {
      try {
        const { data } = await adminClient.downloadBrandCsv(params)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async downloadRetailer({ commit }, params) {
      try {
        const { data } = await adminClient.downloadRetailerCsv(params)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async downloadBestSellers({ commit }, params) {
      try {
        params["is-download-csv"] = true
        const { data } = await adminClient.searchBestSeller(params)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async downloadNewArrival({ commit }, params) {
      try {
        params["is-download-csv"] = true
        const { data } = await adminClient.searchNewArrival(params)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async downloadProduct({ commit }, params) {
      try {
        params["is-download-csv"] = true
        const { data } = await adminClient.searchProducts(params)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getCoupons({ commit }, params) {
      try {
        const { data } = await adminClient.searchCoupon(params)
        commit("SET_COUPONS", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async addCoupon({ commit }, param) {
      try {
        const { data } = await adminClient.addCoupon(param)
        commit("SET_IS_SUCCESS", data)
        return data
      } catch (error) {
        commit("SET_IS_SUCCESS", undefined)
        return Promise.reject(error)
      }
    },
    async getCouponHistory({ commit }, param) {
      try {
        const { data } = await adminClient.getCouponHistory(param)
        commit("SET_COUPON_HISTORY", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async getCouponSignUpValue({}, param) {
      try {
        const { data } = await adminClient.getCouponValue(param)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
