import httpclient from "@/api/http-client";
import Ingredient from "../models/Ingredient";

export default {
  async addIngredient(name) {
    return await httpclient
      .post(`/api/ingredients/by-name?name=${encodeURI(name)}`)
      .then((res) => new Ingredient(res.data));
  },
};
