export default class OrderFilter {
  constructor() {
    this.statusIds = null;
    this.referenceId = null;
    this.retailerName = null;
    this.brandName = null;
    this.retailerIds = null;
    this.brandIds = null;
    this.orderDateStart = null;
    this.orderDateEnd = null;
    this.pageIndex = null;
    this.pageSize = null;
  }
}
