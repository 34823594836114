<template>
  <a-row class="admin-retailer-edit">
    <a-col :span="4" style="padding-top: 190px;">
      <a-anchor @change="linkChange">
        <a-anchor-link
          v-for="link in links"
          :key="link.id"
          :href="'#' + link.id"
          :title="link.name"
        />
      </a-anchor>
    </a-col>
    <a-col :span="20" style="padding-left: 64px; padding-top: 71px;">
      <div class="admin-retailer-edit__title">
        Edit/Add Training When and Best Used For
      </div>

      <ConsoleCascadeContent
        v-for="(item, index) in data"
        :id="links[index].id"
        :key="index"
        :is-focus="linkSelectedValue == links[index].id"
        :data="item"
      >
      </ConsoleCascadeContent>
    </a-col>
  </a-row>
</template>

<script>
import ConsoleCascadeContent from "@/components/admin/ConsoleCascadeContent";
import ConsoleCascadeContentItem from "@/models/admin/ConsoleCascadeContentItem";
import ProductTrainingStep from "@/models/ProductTrainingStep";
import ProductTrainingBestUsedFor from "@/models/ProductTrainingBestUsedFor";
export default {
  components: {
    ConsoleCascadeContent,
  },
  data() {
    const traingStepConfig = new ConsoleCascadeContentItem({
      title: "When",
      isEditable: false,
      computed: {
        items: (store) => store.getters["adminTrainingConsole/getCategories"],
        isLoading: (store) =>
          store.getters["adminTrainingConsole/isLoading"](["categories"]),
      },
      getKeyFn: (item) => item.privateId,
      getDisplayFn: (item) => item.name,
      next: new ConsoleCascadeContentItem({
        title: "",
        addTitle: "When",
        isEditable: true,
        computed: {
          items: (store, parentKey) =>
            store.getters["adminTrainingConsole/getTrainingStepsByCategory"](
              parentKey
            ),
          isLoading: (store, parentKey) =>
            store.getters["adminTrainingConsole/isLoading"]([
              "categoryTrainingSteps",
              `${parentKey}`,
            ]),
          fetchData: async (store, parentKey) =>
            await store.dispatch(
              "adminTrainingConsole/setTrainingStepsByCategoryPrivateId",
              parentKey
            ),
          updateItems: async (store, data) =>
            await store.dispatch(
              "adminTrainingConsole/updateTrainingStepsByCategoryPrivateId",
              data
            ),
        },
        getKeyFn: (item) => item.privateId,
        getDisplayFn: (item) => item.name,
        setItemFn: (value, parentKey, entity) => {
          const updateEntity = entity || new ProductTrainingStep();
          updateEntity.categoryPrivateId = parentKey;
          updateEntity.name = value;
          return updateEntity;
        },
        next: null,
      }),
    });
    const trainingBestUsedForConfig = new ConsoleCascadeContentItem({
      title: "Best Used For",
      isEditable: false,
      computed: {
        items: (store) => store.getters["adminTrainingConsole/getCategories"],
        isLoading: (store) =>
          store.getters["adminTrainingConsole/isLoading"](["categories"]),
      },
      getKeyFn: (item) => item.privateId,
      getDisplayFn: (item) => item.name,
      next: new ConsoleCascadeContentItem({
        title: "",
        addTitle: "Best Used For",
        isEditable: true,
        computed: {
          items: (store, parentKey) =>
            store.getters[
              "adminTrainingConsole/getTrainingBestUsedForsByCategory"
            ](parentKey),
          isLoading: (store, parentKey) =>
            store.getters["adminTrainingConsole/isLoading"]([
              "categoryTrainingBestUsedFors",
              `${parentKey}`,
            ]),
          fetchData: async (store, parentKey) =>
            await store.dispatch(
              "adminTrainingConsole/setTrainingBestUsedForsByCategoryPrivateId",
              parentKey
            ),
          updateItems: async (store, data) =>
            await store.dispatch(
              "adminTrainingConsole/updateTrainingBestUsedForsByCategoryPrivateId",
              data
            ),
        },
        getKeyFn: (item) => item.privateId,
        getDisplayFn: (item) => item.name,
        setItemFn: (value, parentKey, entity) => {
          const updateEntity = entity || new ProductTrainingBestUsedFor();
          updateEntity.categoryPrivateId = parentKey;
          updateEntity.name = value;
          return updateEntity;
        },
        next: null,
      }),
    });
    const data = [traingStepConfig, trainingBestUsedForConfig];
    return {
      linkSelectedValue: "",
      data: data,
      links: [
        { id: "when", name: "When" },
        { id: "bestUsedFor", name: "Best Used For" },
      ],
    };
  },
  async created() {
    await this.$store.dispatch("adminTrainingConsole/setCategories");
  },
  destroyed() {
    this.$store.commit("adminTrainingConsole/SET_STATE_RESET");
  },
  methods: {
    linkChange(value) {
      const linkId = (value || "").substring(1);
      this.linkSelectedValue = linkId;
    },
  },
};
</script>
