<template>
  <div class="retailer-settings" style="padding-bottom: 0px;" @click="clickOuter">
    <div class="columns retailer-settings__wrap" style="height: auto;">
      <div class="column is-2 retailer-settings__links">
        <p
          v-if="!isAdmin"
          class="retailer-settings__link"
          :class="{ 'is-selected': isMenuItem('accountInformation') }"
          @click.prevent="toggleMenuItem('accountInformation')"
        >
          {{ $t("Retailers.RetailerSettings.myAccount") }}
        </p>
        <p
          v-if="!isAdmin"
          class="retailer-settings__sub-menuItem-link sub-menuItem"
          :class="{ 'is-selected': isMenuItem('password') }"
          @click.prevent="toggleMenuItem('password')"
        >
          {{ $t("Retailers.RetailerSettings.password") }}
        </p>
        <p
          v-if="!isAdmin"
          class="retailer-settings__sub-menuItem-link sub-menuItem"
          :class="{ 'is-selected': isMenuItem('emailPreferences') }"
          @click.prevent="toggleMenuItem('emailPreferences')"
        >
          {{ $t("Retailers.RetailerSettings.emailPreferences") }}
        </p>
        <p
          class="retailer-settings__link"
          :class="{ 'is-selected': isMenuItem('retailerAccount') }"
          @click.prevent="toggleMenuItem('retailerAccount')"
        >
          {{ $t("Retailers.RetailerSettings.retailerAccount") }}
        </p>
        <p
          class="retailer-settings__sub-menuItem-link sub-menuItem"
          :class="{ 'is-selected': isMenuItem('beautyFluent') }"
          @click.prevent="toggleMenuItem('beautyFluent')"
        >
          {{ $t("Retailers.RetailerSettings.beautyFluent") }}
        </p>
        <p
          class="retailer-settings__sub-menuItem-link sub-menuItem"
          :class="{ 'is-selected': isMenuItem('shippingAddress') }"
          @click.prevent="toggleMenuItem('shippingAddress')"
        >
          {{ $t("Retailers.RetailerSettings.shippingAddress") }}
        </p>
        <p
          class="retailer-settings__sub-menuItem-link sub-menuItem"
          :class="{ 'is-selected': isMenuItem('members') }"
          @click.prevent="toggleMenuItem('members')"
        >
          {{ $t("Retailers.RetailerSettings.members") }}
        </p>
        <p
          class="retailer-settings__sub-menuItem-link sub-menuItem"
          :class="{ 'is-selected': isMenuItem('margins') }"
          @click.prevent="toggleMenuItem('margins')"
        >
          {{ $t("Retailers.RetailerSettings.margins") }}
        </p>
        <p class="retailer-settings__link" style="cursor: default;">
          {{ $t("Retailers.RetailerSettings.cleanPrecheck") }}
        </p>
        <p
          class="retailer-settings__sub-menuItem-link sub-menuItem"
          :class="{ 'is-selected': isMenuItem('cleanStandards') }"
          @click.prevent="openCleanStandardsMenu('cleanStandards')"
        >
          {{ $t("Retailers.RetailerProfile.cleanStandards") }}
        </p>
      </div>
      <div
        v-show="currentMenuItem === 'accountInformation'"
        v-if="!isAdmin"
        class="column is-7 retailer-settings__account-information"
      >
        <MyAccount />
      </div>
      <div
        v-show="currentMenuItem === 'password'"
        v-if="!isAdmin"
        class="column is-6 retailer-settings__account-information"
      >
        <ChangePassword />
      </div>
      <div
        v-show="currentMenuItem === 'retailerAccount'"
        class="column is-8 retailer-settings__account-information"
      >
        <div class="retailer-settings__title titleText">
          {{ $t("Retailers.RetailerSettings.statusTitle") }}
        </div>
        <div class="columns retailer-settings__border-line">
          <div class="column is-3">
            <p class="retailer-settings__retailer-account retailer-settings__title-style">
              {{ $t("Retailers.RetailerSettings.status") }}
            </p>
          </div>
          <div class="column retailer-settings__status-align">
            <p class="retailer-settings__retailer-account textfont">
              {{ status }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="currentMenuItem === 'beautyFluent'">
        <RetailerType />
      </div>
      <div
        v-show="currentMenuItem === 'shippingAddress'"
        class="column retailer-settings__shipping-addresses"
      >
        <form></form>
        <div class="columns is-8 retailer-settings__title shipping-block">
          {{ $t("Retailers.RetailerSettings.shippingAddress") }}
          <div class="column"></div>
          <button
            v-if="!addressRemoved"
            class="button is-black retailer-settings__saved-addresses-button"
            @click="openModal()"
          >
            {{ $t("Retailers.RetailerSettings.addNewAddress") }}
          </button>
          <UndoAddressRemove v-if="addressRemoved" @undoChanges="undoChanges" />
        </div>
        <template v-for="(address, index) in savedAddresses">
          <div
            v-if="!address.hq"
            :key="`address_${index}`"
            class="columns is-8"
            :class="{
              'retailer-settings__saved-addresses_box_default': address.defaultShipping,
              'retailer-settings__saved-addresses_box': !address.defaultShipping
            }"
          >
            <div class="column">
              <p class="retailer-settings__saved-addresses_box_title">
                {{ address.addressee }}
              </p>
              <p class="retailer-settings__saved-addresses_box_subtitle">
                {{ address.streetAddressLine_1 }},{{
                  hasStreetAddressLine2(address.streetAddressLine_2)
                }}
                {{ address.city }}, {{ address.region }}
              </p>
              <p class="retailer-settings__saved-addresses_box_subtitle">
                {{ address.postalCode }}, {{ countryName(address.country.id) }}
              </p>
              <div class="retailer-settings__saved-addresses_box__buttons"></div>
            </div>
            <div class="column">
              <div class="retailer-settings__modify-buttons">
                <a v-if="address.defaultShipping" class="retailer-settings__default-address">
                  {{ $t("Retailers.RetailerSettings.default") }}
                </a>
                <a v-else @click="defaultAddress(address, index)">
                  {{ $t("Retailers.RetailerSettings.makeDefault") }}
                </a>
              </div>
              <div class="retailer-settings__modify-buttons">
                <a @click="openModal(address, index + 1)">
                  {{ $t("Retailers.RetailerSettings.edit") }}
                </a>
              </div>
              <div class="retailer-settings__modify-buttons">
                <a @click="removeAddress(index)">
                  {{ $t("Retailers.RetailerSettings.remove") }}
                </a>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="currentMenuItem === 'members'" class="column brand-settings__account-information">
        <RetailerMembers />
      </div>
      <div
        v-show="currentMenuItem === 'margins'"
        class="column retailer-settings__saved-addresses padding-top"
      >
        <RetailerMargins />
      </div>
      <div
        v-if="currentMenuItem === 'emailPreferences'"
        class="column retailer-settings__account-information"
      >
        <EmailPreferences />
      </div>
      <div
        class="column retailer-settings__saved-addresses padding-top"
        style="margin-right: 5%;"
        @click="clickOuter"
      >
        <div
          v-show="currentMenuItem === 'cleanStandards'"
          id="retailerSettingCPCContent"
          class="retailer-settings-cpc__edit-modal--form_terms"
        >
          <div
            class="field retailer-settings-cpc__edit-modal--form_container"
            style="margin-top: 33px;"
          >
            <div id="addBannedIngredient" style="width: 100%; z-index: 50; " class="multiselect">
              <div class="multiselect__tags">
                <div
                  v-if="!showTags"
                  class="multiselect__tags-wrap"
                  style="height: 29px;"
                  @click.stop="clickAddBanned"
                >
                  <span class="retailer-settings-cpc__add-ingredient-placeholder">
                    <a-icon type="plus" style="color: #5E6871;margin-right: 11px" />
                    {{ $t("Retailers.RetailerProfile.addBannedIngredients") }}
                  </span>
                </div>
                <div
                  v-else
                  id="retailerBannedIngredientWrap"
                  class="multiselect__tags-wrap"
                  style="height: 79px;overflow: hidden;"
                  @click.stop="clickAddBanned"
                >
                  <div
                    id="countBannedIngredient"
                    class="retailer-settings-cpc__select-ingredients-individual-tag"
                    style="display: inline-block; margin-right: 8px;"
                  >
                    <a-tag
                      class="retailer-settings-cpc__select-ingredients-tag"
                      @click.stop="clickTag"
                    >
                      <div>
                        <span>{{ `+ ${selectedTags.length}` }}</span>
                        <a-icon
                          type="down"
                          :rotate="isShowAllTags ? 180 : 0"
                          style="margin-left: 6px; color: #FEC206;"
                        />
                      </div>
                    </a-tag>
                  </div>
                  <a-tag
                    v-for="(option, index) in selectedTags"
                    :key="`selected_banned_ingredient_tag_${index}`"
                    closable
                    class="retailer-settings-cpc__select-ingredients-tag retailer-settings-cpc__select-ingredients-individual-tag"
                    @click.stop
                    @close="removeSelectedItem(option)"
                  >
                    <a-tooltip
                      v-if="option.ingredientId && option.ingredientId.length > 28"
                      placement="topLeft"
                      overlay-class-name="retailer-settings-cpc__tooltip-text"
                    >
                      <template slot="title">
                        <span>
                          {{ option.ingredientId }}
                        </span>
                      </template>
                      <div class="retailer-settings-cpc__select-ingredients-tag-text-outer">
                        <span class="retailer-settings-cpc__text-ellipsis">
                          {{ option.ingredientId }}
                        </span>
                      </div>
                    </a-tooltip>
                    <div v-else class="retailer-settings-cpc__select-ingredients-tag-text-outer">
                      <span class="retailer-settings-cpc__text-ellipsis">
                        {{ option.ingredientId }}
                      </span>
                    </div>
                  </a-tag>
                </div>
                <div class="multiselect__spinner" style="display: none;"></div>
              </div>
              <div
                v-if="showOption"
                tabindex="-1"
                class="multiselect__content-wrapper retailer-settings__box-shadow"
                style="overflow: hidden;"
                :style="{ 'max-height': dropdownHeight }"
              >
                <div style="background: rgb(255, 255, 255); margin-bottom: 10px;">
                  <div class="ant-row" style="margin-top: 10px; width: 100%;">
                    <div class="ant-col ant-col-9">
                      <a-icon
                        type="close"
                        style="margin-left: 10px;cursor: pointer;font-size: 12px;"
                        @click="clickOuter"
                      />
                    </div>
                    <div class="ant-col ant-col-8">
                      <a-tooltip placement="top" overlay-class-name="categoryTooltip1">
                        <template slot="title">
                          {{ $t("Retailers.RetailerSettings.categoryTooltip") }}
                        </template>
                        <a-button
                          style="margin-right: 16px;"
                          :class="
                            isCategory
                              ? 'retailer-settings-cpc__btn-clicked'
                              : 'retailer-settings-cpc__btn-unclicked'
                          "
                          @click.stop="isCategory = true"
                        >
                          {{ $t("Retailers.RetailerSettings.category") }}
                        </a-button>
                      </a-tooltip>
                      <a-tooltip placement="top" overlay-class-name="categoryTooltip">
                        <template slot="title">
                          {{ $t("Retailers.RetailerSettings.individualTooltip") }}
                        </template>
                        <a-button
                          :class="
                            !isCategory
                              ? 'retailer-settings-cpc__btn-clicked'
                              : 'retailer-settings-cpc__btn-unclicked'
                          "
                          @click.stop="isCategory = false"
                        >
                          {{ $t("Retailers.RetailerSettings.individual") }}
                        </a-button>
                      </a-tooltip>
                    </div>
                    <div class="ant-col ant-col-7" style="text-align: right; padding-right: 10px;">
                      <a-button
                        class="retailer-settings-cpc__btn-save"
                        :disabled="
                          isClickSave ||
                            !(
                              (selectedIngredients && selectedIngredients.size > 0) ||
                              (selectedRemoveIngredients && selectedRemoveIngredients.size > 0)
                            )
                        "
                        @click.stop="saveSelectedIngredients()"
                      >
                        {{ $t("Retailers.RetailerSettings.save") }}
                      </a-button>
                    </div>
                  </div>
                  <div class="ant-row" style="margin-top: 10px;">
                    <div class="retailer-settings-cpc__add-ingredient-result ant-col ant-col-24">
                      {{
                        $t("Retailers.RetailerSettings.searchResultNo", {
                          number: isCategory ? totalCategorys : totalIngredients
                        })
                      }}
                    </div>
                  </div>
                  <a-input
                    class="retailer-settings-cpc__search-input"
                    style="width: 98%;margin-left: 6px; padding-left: 4px;"
                    placeholder="Search for Ingredient(s)"
                    size="small"
                    allow-clear
                    @click.stop
                    @change.stop="handleSearch"
                  >
                    <a-icon slot="prefix" type="search" style="color: #5E6871" />
                  </a-input>
                </div>
                <ul
                  class="multiselect__content"
                  style="display: inline-block; overflow-y: auto;width: inherit;"
                  :style="{ height: ulHeight }"
                >
                  <li
                    v-for="(option, index) in isCategory
                      ? searchCategoryIngredientsResults
                      : searchIngredientsResults"
                    :key="`banned_ingredient_option_${index}`"
                    class="multiselect__element"
                    style="margin-bottom: 6px;"
                    :class="{ 'retailer-settings-cpc__option-selected': option.isSelected }"
                  >
                    <span class="multiselect__option">
                      <div
                        v-if="!isCategory"
                        class="retailer-settings-cpc__dropdown-list-item"
                        :class="{ 'retailer-settings-cpc__already-added': option.isAdded }"
                        @click.stop="selectIngredient(option)"
                      >
                        <a-tooltip
                          v-if="option.ingredientId && option.ingredientId.length > maxLength"
                          placement="topLeft"
                          overlay-class-name="retailer-settings-cpc__tooltip-text"
                        >
                          <template slot="title">
                            {{ option.ingredientId }}
                          </template>
                          <a-row style="height: 17px;">
                            <a-col
                              :span="option.isAdded ? 21 : 24"
                              style="height: 17px; padding-left: 6px;"
                            >
                              <span class="retailer-settings-cpc__text-ellipsis">{{
                                `${option.ingredientId}`
                              }}</span>
                            </a-col>
                            <a-col v-if="option.isAdded" :span="3" style="height: 17px;">
                              {{ $t("Retailers.RetailerSettings.added") }}
                            </a-col>
                          </a-row>
                        </a-tooltip>
                        <a-row v-else style="height: 17px;">
                          <a-col
                            :span="option.isAdded ? 21 : 24"
                            style="height: 17px; padding-left: 6px;"
                          >
                            <span class="retailer-settings-cpc__text-ellipsis">{{
                              option.ingredientId
                            }}</span>
                          </a-col>
                          <a-col v-if="option.isAdded" :span="3" style="height: 17px;">
                            {{ $t("Retailers.RetailerSettings.added") }}
                          </a-col>
                        </a-row>
                      </div>
                      <div
                        v-else
                        id="retailerCategoryIngredients"
                        class="retailer-settings-cpc__dropdown-list-container"
                        :class="{
                          'retailer-settings-cpc__already-added':
                            option.isAllAdded || option.addedCount > 0,
                          'retailer-settings-cpc__option-selected': option.isSelected
                        }"
                        @click.stop
                      >
                        <a-collapse expand-icon-position="right" :bordered="false">
                          <template #expandIcon="dataProps">
                            <div>
                              <a-icon
                                type="down"
                                :rotate="dataProps.isActive ? 180 : 0"
                                :class="{
                                  'retailer-settings-cpc__expand-icon': dataProps.isActive
                                }"
                                style="font-size: 10px;"
                              />
                            </div>
                          </template>
                          <a-collapse-panel class="retailer-settings-cpc__panel">
                            <template #header>
                              <a-row class="retailer-settings-cpc__panel-header">
                                <a-col :span="18" style="margin-left: 8px;">
                                  <a-checkbox
                                    v-model="option.isAllChecked"
                                    style="margin-right: 10px"
                                    @click.stop
                                    @change="selectAllCategoryIngredient(option)"
                                  >
                                    <span>
                                      {{ option.category.name }}
                                    </span>
                                  </a-checkbox>
                                </a-col>
                                <a-col v-if="option.isAllAdded || option.addedCount > 0" :span="2">
                                  {{
                                    option.isAllAdded
                                      ? $t("Retailers.RetailerSettings.allAdded")
                                      : option.addedCount > 0
                                      ? `+ ${option.addedCount}`
                                      : ""
                                  }}
                                </a-col>
                              </a-row>
                            </template>
                            <div class="retailer-settings-cpc__panel-content">
                              <a-row
                                v-for="(data, index) in option.categoryIngredients"
                                :key="`ingredients_${index}`"
                                :class="{ 'retailer-settings-cpc__already-added': data.isAdded }"
                                style="margin-bottom: 4px;"
                              >
                                <a-col :span="data.isAdded ? 20 : 24">
                                  <a-checkbox
                                    :key="`ingredient_ck_${data.privateId}`"
                                    v-model="data.isChecked"
                                    class="retailer-settings-cpc__category-ingredient-ck retailer-settings-cpc__text-ellipsis"
                                    @click.stop
                                    @change="clickIngredientsUnderCategory(option, data)"
                                  >
                                    {{ data.ingredientId }}
                                  </a-checkbox>
                                </a-col>
                                <a-col v-if="data.isAdded" :span="4">
                                  {{ $t("Retailers.RetailerSettings.added") }}
                                </a-col>
                              </a-row>
                            </div>
                          </a-collapse-panel>
                        </a-collapse>
                      </div>
                    </span>
                    <!---->
                  </li>
                  <div
                    v-if="
                      isCategory
                        ? curCategoryPage < totalCategoryPage
                        : curIngredientsPage < totalIngredientPage
                    "
                    style="margin: 0 auto;text-align: center;"
                    @click.stop="loadMoreSearchData"
                  >
                    <span class="retailer-settings-cpc__add-load-more">Load More</span>
                    <a-icon type="redo" class="retailer-settings-cpc__add-load-more" />
                  </div>
                </ul>
              </div>
            </div>
            <div id="retailerAddedIngredients" class="retailer-settings-cpc__added-layer">
              <a-row style="margin-bottom: 20px;">
                <a-col
                  :span="24"
                  class="retailer-settings-cpc__added-dirty-filter-number"
                  style="color: #FEC206;"
                >
                  {{
                    `${
                      searchAddedDirtyResult ? searchAddedDirtyResult.length : 0
                    } Prohibited Ingredients Selected`
                  }}
                </a-col>
              </a-row>
              <div id="filterLayer">
                <div class="retailer-settings-cpc__alpha-area">
                  <span
                    v-for="(item, index) in cleanNumberFiler"
                    :key="`number-filter_${index}`"
                    class="retailer-settings-cpc__alpha-filter"
                    :disabled="!item.isValid"
                    :class="
                      item.isValid
                        ? item.isChecked
                          ? 'retailer-settings-cpc__alpha-selected'
                          : 'retailer-settings-cpc__alpha-valid'
                        : 'retailer-settings-cpc__alpha-invalid'
                    "
                    @click="filterAddIngredients(item)"
                  >
                    {{ item.name }}
                  </span>
                </div>
                <br />
                <div class="retailer-settings-cpc__alpha-area">
                  <span
                    v-for="(item, index) in cleanAlphaFilter"
                    :key="index"
                    class="retailer-settings-cpc__alpha-filter"
                    :disabled="!item.isValid"
                    :class="
                      item.isValid
                        ? item.isChecked
                          ? 'retailer-settings-cpc__alpha-selected'
                          : 'retailer-settings-cpc__alpha-valid'
                        : 'retailer-settings-cpc__alpha-invalid'
                    "
                    @click="filterAddIngredients(item)"
                  >
                    {{ item.name }}
                  </span>
                </div>
                <a-row style="margin-top: 28px;">
                  <a-col :span="24">
                    <a-input
                      id="retailerDirtyIngredientSearch"
                      v-model="searchAddedIngredient"
                      class="retailer-settings-cpc__search-input"
                      placeholder="Search for Added Ingredient(s)"
                      size="small"
                      allow-clear
                      @change="searchAddedDrityIngredientByKeyWord"
                    >
                      <a-icon slot="prefix" type="search" style="color: #5E6871" />
                    </a-input>
                  </a-col>
                </a-row>
              </div>
              <a-config-provider>
                <template #renderEmpty>
                  <div class="retailer-settings-cpc__list-empty-layer">
                    <span class="retailer-settings-cpc__list-empty-text" style="margin-right: 2px;"
                      >Select</span
                    >
                    <span class="retailer-settings-cpc__list-empty-border">
                      <a-icon
                        type="plus"
                        class="retailer-settings-cpc__list-empty-text"
                        style="margin-right: 2px;"
                      />
                      <span class="retailer-settings-cpc__list-empty-text"
                        >Add Prohibited Ingredient</span
                      >
                    </span>
                    <span class="retailer-settings-cpc__list-empty-text" style="margin-left: 2px;"
                      >to cultivate list.</span
                    >
                  </div>
                </template>
                <a-list
                  id="addedDirtyList"
                  :pagination="pagination"
                  :data-source="searchAddedDirtyResult"
                  style="min-height: 200px;"
                >
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a-row class="retailer-settings-cpc__added-ingredient-item">
                      <a-col :span="22" class="retailer-settings-cpc__added-ingredients-item">
                        <a-tooltip
                          v-if="item.ingredientName && item.ingredientName.length > 71"
                          placement="topRight"
                          overlay-class-name="retailer-settings-cpc__tooltip-text"
                        >
                          <template slot="title">
                            {{ item.ingredientName }}
                          </template>
                          <span>
                            {{ item.ingredientName }}
                          </span>
                        </a-tooltip>
                        <span v-else>{{ item.ingredientName }}</span>
                      </a-col>
                      <a-col :span="2" class="retailer-settings-cpc__added-item-delete">
                        <a-popover
                          placement="rightTop"
                          overlay-class-name="retailer-settings-cpc__delete"
                          :visible="deletedItem !== undefined && deletedItem.id === item.id"
                          trigger="click"
                          arrow-point-at-center
                        >
                          <template slot="content">
                            <img
                              src="@/assets/img/clean-pre-check/retailer_item_remove.svg"
                              style="display: block; width: 20px; height: 20px;"
                              @click.stop="removeAdded"
                            />
                          </template>
                          <img
                            src="@/assets/img/clean-pre-check/retailer_item_delete.svg"
                            @click.stop="clickToRemoveAdded(item)"
                          />
                        </a-popover>
                      </a-col>
                    </a-row>
                  </a-list-item>
                </a-list>
              </a-config-provider>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" :class="{ 'is-active': isNewModalOpen }">
        <RetailerSettingsAddressModal
          v-if="isNewModalOpen"
          :retailer-id="retailer.id"
          :address="selectedAddress"
          @modalClose="closeModal"
          @updateAddress="updateAddress"
          @createAddress="createAddress"
        />
      </div>
    </div>
    <a-modal
      v-model="sureVisible"
      :centered="true"
      :footer="null"
      :width="700"
      :title="null"
      :body-style="noPadding"
    >
      <span slot="closeIcon">
        <a-icon type="close-circle" style="font-size: 21px" />
      </span>
      <div class="retailer-settings__dialog-info-before-submit">
        <p class="retailer-settings__dialog-info-title">Welcome to Clean PreCheck</p>
        <p class="dialog-content-style">
          {{ $t("Retailers.RetailerProfile.cleanPreCheckToolTip") }}
        </p>
        <p class="retailer-settings__dialog-close-btn" @click="SubmitDialog">OK</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import MyAccount from "@/components/shared/RetailerMyAccount"
import ChangePassword from "@/components/shared/ChangePassword"
import RetailerSettingsAddressModal from "@/components/retailers/settings/RetailerSettingsAddressModal"
import UndoAddressRemove from "@/components/retailers/settings/UndoAddressRemove"
import RetailerMargins from "@/components/retailers/settings/RetailerMargins"
import RetailerMembers from "@/components/retailers/settings/RetailerMembers"
import EmailPreferences from "@/components/shared/EmailPreferences"
import RetailerType from "@/components/retailers/settings/RetailerType"

export default {
  components: {
    MyAccount,
    ChangePassword,
    RetailerSettingsAddressModal,
    UndoAddressRemove,
    RetailerMargins,
    EmailPreferences,
    RetailerMembers,
    RetailerType
  },
  data() {
    return {
      savedAddresses: [],
      retailerMembers: [],
      currentMenuItem:
        this.$route.name == "adminRetailerSettings" ? "retailerAccount" : "accountInformation",
      isNewModalOpen: false,
      showPopup: false,
      selectedAddress: { id: null },
      addressRemoved: false,
      showPlan: false,
      showRequestInvite: false,
      showMemberActions: false,
      successfullyAddedMember: false,
      memberAdded: {},
      isOwner: false,
      // clean precheck
      showTags: false,
      showOption: false,
      isShowAllTags: false,
      searchAddedIngredient: "", // used for record the words for searching added ingredient
      searchAddedIngredientByAlpha: new Set(),
      searchAddedDirtyResult: [],
      addPageSize: 50,
      cleanIngredientsFilter: "",
      cleanAlphaFilter: [
        { name: "A", isValid: false },
        { name: "B", isValid: false },
        { name: "C", isValid: false },
        { name: "D", isValid: false },
        { name: "E", isValid: false },
        { name: "F", isValid: false },
        { name: "G", isValid: false },
        { name: "H", isValid: false },
        { name: "I", isValid: false },
        { name: "J", isValid: false },
        { name: "K", isValid: false },
        { name: "L", isValid: false },
        { name: "M", isValid: false },
        { name: "N", isValid: false },
        { name: "O", isValid: false },
        { name: "P", isValid: false },
        { name: "Q", isValid: false },
        { name: "R", isValid: false },
        { name: "S", isValid: false },
        { name: "T", isValid: false },
        { name: "U", isValid: false },
        { name: "V", isValid: false },
        { name: "W", isValid: false },
        { name: "X", isValid: false },
        { name: "Y", isValid: false },
        { name: "Z", isValid: false }
      ],
      cleanNumberFiler: [
        { name: "0", isValid: false },
        { name: "1", isValid: false },
        { name: "2", isValid: false },
        { name: "3", isValid: false },
        { name: "4", isValid: false },
        { name: "5", isValid: false },
        { name: "6", isValid: false },
        { name: "7", isValid: false },
        { name: "8", isValid: false },
        { name: "9", isValid: false }
      ],
      loadingIngredients: false,
      loaddingCategoryIngredients: false,
      ingredientsBusy: false,
      loadingCategoryIngredients: false,
      categoryIngredientsBusy: false,
      showDeleteDialog: false,
      searchIngredientsResults: [],
      searchCategoryIngredientsResults: [], // data which used for search category ingredients
      selectedRemoveIngredients: new Set(), // data which need to be removed
      selectedRemoveIngreidentIds: new Set(),
      selectedIngredients: new Set(), // data which need to add for retailer
      selectedIngredientIds: new Set(),
      selectedCategory: new Set(), // tags data
      selectedCategoryTag: [],
      selectedIngredientsValue: new Set(), // ingredients tag value
      selectedIngredientsTag: [],
      selectedTags: [],
      totalIngredients: 100, // total ingredients when search ingredients in individual mode
      totalCategorys: 100, // total category list when search category name in category mode
      totalCategoryPage: 1,
      totalIngredientPage: 1,
      curIngredientsPage: 1,
      curCategoryPage: 1,
      searchIngredientName: "",
      searchCategoryName: "",
      isCategory: true,
      isClickSave: false,
      selectedDeleteId: "",
      maxLength: 69,
      deletedItem: undefined,
      selectForbidden: undefined,
      sureVisible: false,
      noPadding: {
        padding: "4px 16px 16px 16px"
      },
      pagination: {
        onChange: page => {
          console.log(page)
        },
        size: "small",
        pageSize: 36
      },
      forbiddenIngredients: undefined,
      dropdownHeight: "1050px",
      ulHeight: "930px"
    }
  },
  computed: {
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    retailerForbiddenList() {
      return this.$store.getters["retailer/cleanStandardsList"]
    },
    members() {
      return (this.retailer && this.retailer.members) || []
    },
    countries() {
      return this.$store.getters["reference/countries"]
    },
    status() {
      var retailer = this.$store.getters["retailer/retailer"]
      return retailer && retailer.status && retailer.status.name
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    email() {
      if (this.$route.name == "adminRetailerSettings") {
        return this.$store.getters["user/memberEmail"]
      } else {
        return this.$store.getters["user/email"]
      }
    },
    name() {
      return this.$store.getters["user/memberName"]
    },
    jobtTitle() {
      if (this.$route.name == "adminRetailerSettings") {
        return this.$store.getters["user/memberTitle"]
      } else {
        return this.$store.getters["user/jobTitle"]
      }
    },
    phoneNumber() {
      if (this.$route.name == "adminRetailerSettings") {
        return this.$store.getters["user/memberPhone"]
      } else {
        return this.$store.getters["user/phoneNumber"]
      }
    },
    userId() {
      return this.$store.getters["user/id"]
    }
  },
  watch: {
    retailerForbiddenList(newValue) {
      if (newValue) {
        this.retailer.forbiddenIngredients = newValue
        this.initAlpha()
        if (this.searchAddedIngredientByAlpha && this.searchAddedIngredientByAlpha.size > 0) {
          this.filterAddIngredients()
        } else {
          this.searchAddedDrityIngredientByKeyWord()
        }
        this.initCategoryResultAddedSelectMark()
        this.initSearchIngredientAddedMark()
      }
    }
  },
  async created() {
    const retailerId =
      this.$route.name == "adminRetailerSettings"
        ? this.$route.params.retailerId
        : this.$store.getters["user/businessId"]
    await this.$store.dispatch("retailer/fetchRetailer", retailerId)
    await this.$store.dispatch("reference/setReference")
    this.getRetailerForbiddenList(retailerId)
    this.handleSearchIngredients("")
    this.handleIngredientsWithCategory("")
    var retailer = this.$store.getters["retailer/retailer"]
    this.savedAddresses = retailer.addresses ? [...retailer.addresses] : []
    this.retailerMembers = retailer.members ? [...retailer.members] : []
    this.retailerMembers.sort(function(a, b) {
      var c = new Date(a.createdAt)
      var d = new Date(b.createdAt)
      return c - d
    })
    this.defaultPrimaryAddress(this.savedAddresses)
    let members = retailer.members
    let owner = members.find(member => member.role.id == "owner")
    if (this.userId == owner.user.id || this.isAdmin) {
      this.isOwner = true
    }
  },
  updated() {
    let child = document.getElementById("addedDirtyList")
    let childOne = document.getElementById("addBannedIngredient")
    let childTwo = document.getElementById("filterLayer")
    if (child) {
      let child_height = child.offsetHeight
      if (childOne) {
        child_height += childOne.offsetHeight
      }
      if (childTwo) {
        child_height += childTwo.offsetHeight
      }
      let father = document.getElementById("retailerSettingCPCContent")
      if (father) {
        father.style.height = `${child_height + 130}px`
      }
      this.dropdownHeight = `${child_height}px`
      this.ulHeight = `${child_height - 130}px`
    }
  },
  methods: {
    countryName(id) {
      return this.$store.getters["reference/countryName"](id)
    },
    isMenuItem(item) {
      return this.currentMenuItem === item
    },
    toggleMenuItem(item) {
      this.currentMenuItem = item
    },
    openCleanStandardsMenu(item) {
      this.toggleMenuItem(item)
      this.sureVisible = true
    },
    SubmitDialog() {
      this.sureVisible = false
    },
    openModal(address, index) {
      this.isNewModalOpen = true
      this.selectedAddress = address
      if (index) this.selectedAddress.id = index
    },
    profileIcon(firstname, lastname) {
      if (firstname && lastname) {
        var result = firstname.charAt(0) + lastname.charAt(0)
        return result
      }
      return " "
    },
    hasStreetAddressLine2(addressLine2) {
      if (!addressLine2) {
        return ""
      } else {
        return " " + addressLine2 + ","
      }
    },
    async updateAddress(addressModal) {
      try {
        this.savedAddresses[addressModal.id - 1] = addressModal
        await this.$store.dispatch("retailer/updateAddress", this.savedAddresses)
        this.selectedAddress = null
        this.$vs.notify({
          title: "Notification",
          text: "Address updated successfully",
          color: "green"
        })
      } catch (error) {
        console.error(error)
      }
    },
    async createAddress(addressModal) {
      try {
        this.savedAddresses.push(addressModal)
        await this.$store.dispatch("retailer/createAddress", this.savedAddresses)
        this.defaultPrimaryAddress(this.savedAddresses)
        this.selectedAddress = null
        this.$vs.notify({
          title: "Notification",
          text: "Address added successfully",
          color: "green"
        })
      } catch (error) {
        console.error(error)
      }
    },
    async removeAddress(index) {
      try {
        this.savedAddresses.splice(index, 1)
        this.addressRemoved = true
        setTimeout(() => {
          if (this.addressRemoved) {
            this.$store.dispatch("retailer/updateAddress", this.savedAddresses)
            this.$vs.notify({
              title: "Notification",
              text: "Address removed successfully",
              color: "green"
            })
          }
          this.addressRemoved = false
          this.defaultPrimaryAddress(this.savedAddresses)
        }, 5000)
      } catch (error) {
        console.error(error)
      }
    },
    async undoChanges() {
      this.addressRemoved = false
      var retailer = this.$store.getters["retailer/retailer"]
      this.savedAddresses = [...retailer.addresses]
    },
    async defaultAddress(address, index) {
      address.defaultShipping = true
      this.savedAddresses[index] = address
      for (var savedAddress in this.savedAddresses) {
        if (this.savedAddresses[savedAddress] != this.savedAddresses[index]) {
          this.savedAddresses[savedAddress].defaultShipping = false
        }
      }
      if (index != 0) {
        var firstAddress = this.savedAddresses[0]
        this.savedAddresses[0] = this.savedAddresses[index]
        this.savedAddresses[index] = firstAddress
      }
      await this.$store.dispatch("retailer/updateAddress", this.savedAddresses)
      this.$vs.notify({
        title: "Notification",
        text: "Default address changed",
        color: "green"
      })
    },
    defaultPrimaryAddress(savedAddress) {
      if (savedAddress.filter(item => !item.hq).length === 1) {
        savedAddress.filter(item => !item.hq)[0].defaultShipping = true
        this.$store.dispatch("retailer/updateAddress", savedAddress)
      }
    },
    closeModal() {
      this.isNewModalOpen = false
    },
    // clean precheck
    clickAddBanned() {
      this.showOption = true
      this.showTags = true
      this.showLimitAddBannedTag()
    },
    clickTag() {
      if (!this.isShowAllTags) {
        this.showOption = false
        this.showAllAddedBannedTag()
      } else {
        this.showOption = true
        this.showLimitAddBannedTag()
      }
    },
    showAllAddedBannedTag() {
      let obj = document.getElementById("retailerBannedIngredientWrap")
      let parent = document.getElementById("addBannedIngredient")
      if (obj) {
        obj.style.height = "100%"
        obj.style["overflow-y"] = "auto"
        obj.style["overflow-x"] = "hidden"
        obj.style["z-index"] = "50"
      }
      if (parent) {
        parent.style["background-color"] = "#fff"
        parent.style["z-index"] = "50"
      }
      this.isShowAllTags = true
      if (!this.selectedTags || this.selectedTags.length === 0) {
        this.isShowAllTags = false
        this.showTags = false
        this.resetBottomDataLocation()
      }
    },
    showLimitAddBannedTag() {
      let obj = document.getElementById("retailerBannedIngredientWrap")
      let parent = document.getElementById("addBannedIngredient")
      let bottomData = document.getElementById("retailerAddedIngredients")
      if (obj) {
        obj.style.height = "79px"
        obj.style["overflow"] = "hidden"
        obj.style["z-index"] = "auto"
      }
      if (parent) {
        parent.style["background-color"] = "#fff"
        parent.style["z-index"] = "50"
      }
      if (bottomData) {
        bottomData.style.top = "100px"
      }
      this.isShowAllTags = false
    },
    resetBottomDataLocation() {
      let bottomData = document.getElementById("retailerAddedIngredients")
      if (bottomData) {
        bottomData.style.top = "40px"
      }
    },
    removeSelectedItem(item) {
      let isCategory = item.isUnderCategory !== undefined && item.isUnderCategory
      if (isCategory) {
        let delIngredients = []
        let delCategory = null
        for (let category of this.selectedCategory) {
          if (category.category.privateId === item.dirtyCategoryPrivateId) {
            delIngredients = category.categoryIngredients
            delCategory = category
            delCategory.isAllChecked = false
            if (delIngredients && delIngredients.length > 0) {
              delIngredients.forEach(ingredient => {
                if (ingredient.ingredientPrivateId === item.ingredientPrivateId) {
                  ingredient.isChecked = false
                  delCategory.checkCount -= 1
                }
              })
            }
            if (delCategory.checkCount < 0) {
              delCategory.checkCount = 0
            }
            break
          }
        }
        if (delCategory) {
          let countChecked = 0
          delCategory.categoryIngredients.forEach(ingredient => {
            if (ingredient.isChecked === true) {
              countChecked += 1
            }
          })
          if (countChecked === 0) {
            delCategory.isSelected = false
            this.selectedCategory.delete(delCategory)
          }
        }
        this.selectedCategoryTag = [...this.selectedCategory]
      } else {
        item.isSelected = false
        this.selectedIngredientsValue.delete(item)
        this.selectedIngredientsTag = [...this.selectedIngredientsValue]
      }
      this.selectedIngredients.delete(item)
      this.selectedTags = [...this.selectedIngredients]
      this.$forceUpdate()
    },
    saveSelectedIngredients() {
      if (
        (this.selectedIngredients && this.selectedIngredients.size) ||
        (this.selectedRemoveIngreidentIds && this.selectedRemoveIngreidentIds.size)
      ) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "enterCleanstandards",
          userId: this.$store.getters["user/id"],
          firstName: this.$store.getters["user/givenName"],
          lastName: this.$store.getters["user/familyName"],
          emailAddress: this.$store.getters["user/email"],
          userType: "Retailer"
        })
      }
      if (this.selectedIngredients && this.selectedIngredients.size) {
        this.forbiddenIngredients = []
        let ingredientIds = new Set()
        for (let item of this.selectedIngredients) {
          ingredientIds.add(item.ingredientPrivateId)
        }
        for (let data of ingredientIds) {
          this.forbiddenIngredients.push({
            ingredientPrivateId: data
          })
        }
        this.updateForbiddenIngredients(this.forbiddenIngredients)
      }
      if (this.selectedRemoveIngreidentIds && this.selectedRemoveIngreidentIds.size) {
        let removeParam = []
        let removedObj = [...this.selectedRemoveIngredients]
        for (let data of this.selectedRemoveIngreidentIds) {
          removeParam.push({
            id: data,
            retailerPrivateId: removedObj[0].retailerPrivateId,
            retailerName: removedObj[0].retailerName
          })
        }
        this.doRemoveAddedAction(removeParam, false)
      }
    },
    updateForbiddenIngredients(params) {
      this.$store
        .dispatch("retailer/updateForbiddenIngredients", params)
        .then(() => {
          this.clearSelectedDirty()
          this.initSearchIngredientAddedMark()
          this.initCategoryResultAddedSelectMark()
          this.clickOuter()
          this.getRetailerForbiddenList(this.retailer.id)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isClickSave = false
        })
    },
    doRemoveAddedAction(param, isIndividual) {
      this.$store
        .dispatch("retailer/removeForbiddenIngredients", param)
        .then(() => {
          // this.deletedItem.isShow = false
          this.getRetailerForbiddenList(this.retailer.id)
          if (isIndividual) {
            this.deletedItem = undefined
          } else {
            this.selectedRemoveIngredients.clear()
            this.selectedRemoveIngreidentIds.clear()
            this.clickOuter()
          }
          this.$forceUpdate()
          this.showMessage("Delete success", true)
        })
        .catch(error => {
          console.error(error)
          this.showMessage("Delete Failed", false)
          if (isIndividual) {
            this.cancelDelete()
          } else {
            this.clickOuter()
          }
        })
    },
    clearSelectedDirty() {
      this.unselecteSearchedIngredients()
      this.selectedIngredientIds.clear()
      this.selectedIngredients.clear()
      this.selectedCategory.clear()
      this.selectedCategoryTag = []
      this.selectedIngredientsValue.clear()
      this.selectedIngredientsTag = []
      this.selectedTags = []
    },
    unselecteSearchedIngredients() {
      if (this.searchIngredientsResults && this.searchIngredientsResults.length) {
        this.searchIngredientsResults.forEach(item => (item.isSelected = false))
      }
    },
    async getRetailerForbiddenList(retailerId) {
      await this.$store.dispatch("retailer/getForbiddenCategoryList", retailerId)
    },
    handleSearch(value) {
      let searchValue = value.target.value
      if (this.isCategory) {
        this.handleIngredientsWithCategory(searchValue)
      } else {
        this.handleSearchIngredients(searchValue)
      }
    },
    initAlpha() {
      if (this.retailer.forbiddenIngredients && this.retailer.forbiddenIngredients.length) {
        let alphaStr = ""
        this.retailer.forbiddenIngredients.map(item => {
          alphaStr += item.ingredientName.charAt(0)
        })
        if (alphaStr.length) {
          this.cleanAlphaFilter.map(item => {
            if (alphaStr.toLowerCase().indexOf(item.name.toLowerCase()) >= 0) {
              item.isValid = true
            } else {
              item.isValid = false
            }
            item.isChecked = false
          })
          this.cleanNumberFiler.map(item => {
            if (alphaStr.toLowerCase().indexOf(item.name.toLowerCase()) >= 0) {
              item.isValid = true
            } else {
              item.isValid = false
            }
            item.isChecked = false
          })
        }
        if (this.searchAddedIngredientByAlpha && this.searchAddedIngredientByAlpha.size > 0) {
          for (let checkedAlpha of this.searchAddedIngredientByAlpha) {
            let isCheckedItem = this.cleanAlphaFilter.filter(
              alpha => alpha.name.toLowerCase() === checkedAlpha.name.toLowerCase()
            )
            if (!isCheckedItem || isCheckedItem.length === 0) {
              isCheckedItem = this.cleanNumberFiler.filter(
                alpha => alpha.name.toLowerCase() === checkedAlpha.name.toLowerCase()
              )
            }
            if (isCheckedItem && isCheckedItem.length > 0) {
              isCheckedItem[0].isChecked = true
            }
          }
        }
      } else {
        this.cleanAlphaFilter.map(item => {
          item.isValid = false
          item.isChecked = false
        })
        this.cleanNumberFiler.map(item => {
          item.isValid = false
          item.isChecked = false
        })
      }
    },
    resetAlphaFilter() {
      this.searchAddedIngredientByAlpha.clear()
      this.cleanAlphaFilter.forEach(item => (item.isChecked = false))
      this.cleanNumberFiler.forEach(item => (item.isChecked = false))
    },
    searchAddedDrityIngredientByKeyWord() {
      this.resetAlphaFilter()
      if (this.searchAddedIngredient && this.searchAddedIngredient.length > 0) {
        let reg = RegExp(`${this.searchAddedIngredient.toLowerCase()}`)
        let dirtyDatas = this.retailer.forbiddenIngredients.slice()
        this.searchAddedDirtyResult = dirtyDatas.filter(item => {
          if (reg.test(item.ingredientName.toLowerCase())) {
            return true
          } else {
            return false
          }
        })
      } else {
        // show all the data
        if (this.retailer.forbiddenIngredients && this.retailer.forbiddenIngredients.length) {
          this.searchAddedDirtyResult = this.retailer.forbiddenIngredients.slice()
        } else {
          this.searchAddedDirtyResult = []
        }
      }
    },
    /**
     * handle search ingredients in individual
     * search by the ingredients name
     * @param value
     */
    handleSearchIngredients(value) {
      this.searchIngredientName = value
      this.loadingIngredients = true
      this.searchIngredientsResults = []
      this.curIngredientsPage = 1
      this.$store
        .dispatch("cleanPreCheck/fetchIngredientsByName", {
          "ingredient-name": value,
          "page-index": this.curIngredientsPage
        })
        .then(() => {
          let data = this.$store.getters["cleanPreCheck/ingredientsByName"]
          if (data && data.list && data.list.length) {
            this.searchIngredientsResults = data.list.slice()
            this.initSearchIngredientAddedMark()
            this.totalIngredients = data.total
            this.totalIngredientPage = data.pages
          }
          this.loadingIngredients = false
        })
        .catch(error => {
          console.log(error)
          this.loadingIngredients = false
        })
    },
    initSearchIngredientAddedMark() {
      if (this.searchIngredientsResults && this.searchIngredientsResults.length) {
        this.searchIngredientsResults.map(item => {
          if (this.retailer.forbiddenIngredients && this.retailer.forbiddenIngredients.length) {
            let addObj = this.retailer.forbiddenIngredients.filter(
              data => data.ingredientPrivateId === item.ingredientPrivateId
            )
            if (addObj && addObj.length) {
              item.isAdded = true
            } else {
              item.isAdded = false
            }
          } else {
            item.isAdded = false
          }
        })
      }
    },
    /**
     * handle search ingredients in category mode
     * search by category name
     * once invoke this method, will set the searchCategoryIngredientsResults : []
     * @param value
     */
    handleIngredientsWithCategory(value) {
      // 1. init the searchCategoryIngredientsResults as empty, loadingCategoryIngredients = true
      // 2. invoke the search api with value
      // 3. when get response, then set the searchCategoryIngredientsResults value
      // and set the totalCategorys value, loadingCategoryIngredients = false
      this.searchCategoryName = value
      this.loadingCategoryIngredients = true
      this.searchCategoryIngredientsResults = []
      this.curCategoryPage = 1
      this.$store
        .dispatch("cleanPreCheck/fetchCategoryIngredientsByName", {
          "category-name": value,
          "page-index": this.curCategoryPage
        })
        .then(() => {
          let data = this.$store.getters["cleanPreCheck/categoryIngredients"]
          this.searchCategoryIngredientsResults = data.list.slice()
          this.initCategoryResultAddedSelectMark()
          this.totalCategorys = data.total
          this.totalCategoryPage = data.pages
        })
        .finally(() => {
          this.loadingCategoryIngredients = false
        })
    },
    initCategoryResultAddedSelectMark() {
      if (this.searchCategoryIngredientsResults && this.searchCategoryIngredientsResults.length) {
        this.searchCategoryIngredientsResults.map(item => {
          item.isAllChecked = false
          item.isSelected = false
          item.checkCount = 0
          let countAdded = 0
          if (item.categoryIngredients && item.categoryIngredients.length) {
            item.categoryIngredients.map(ingre => {
              if (ingre) {
                ingre.isChecked = false
                if (
                  this.retailer.forbiddenIngredients &&
                  this.retailer.forbiddenIngredients.length
                ) {
                  let addedIngre = this.retailer.forbiddenIngredients.filter(
                    data => data.ingredientPrivateId === ingre.ingredientPrivateId
                  )
                  if (addedIngre && addedIngre.length) {
                    ingre.isChecked = true
                    countAdded += 1
                    ingre.isAdded = true
                    ingre.id = addedIngre[0].id
                    ingre.ingredientName = addedIngre[0].ingredientName
                    ingre.retailerName = addedIngre[0].retailerName
                    ingre.retailerPrivateId = addedIngre[0].retailerPrivateId
                  } else {
                    ingre.isAdded = false
                    ingre.isChecked = false
                  }
                } else {
                  ingre.isAdded = false
                  ingre.isChecked = false
                }
              }
              return ingre
            })
            if (countAdded === item.categoryIngredients.length) {
              item.isAllAdded = true
              item.isAllChecked = true
            } else {
              item.isAllAdded = false
            }
          }
          item.addedCount = countAdded
          return item
        })
      }
    },
    /**
     * save clean standards
     */

    afterDataChanged(value) {
      if (this.curSelectedDirty.isAdded || this.curSelectedDirty.isAllAdded) {
        let index = this.selectedTags.indexOf(this.curSelectedDirty)
        value.splice(index, 1)
        this.selectedTags.splice(index, 1)
      }
    },
    selectIngredient(value) {
      this.curSelectedDirty = value
      if (!this.isCategory) {
        if (!value.isAdded) {
          this.chooseItem(value)
        }
      } else {
        value.isAllChecked = !value.isAllChecked
        this.selectAllCategoryIngredient(value)
      }
    },
    /**
     *  click the ingredients to add into the selectedIngredients
     */
    chooseItem(item) {
      // modify the item selected tag
      if (item.isSelected) {
        item.isSelected = false
        if (this.selectedIngredientIds.has(item.ingredientPrivateId)) {
          this.selectedIngredients.delete(item)
          this.selectedIngredientIds.delete(item.ingredientPrivateId)
          this.selectedIngredientsValue.delete(item)
        }
      } else {
        item.isSelected = true
        if (!this.selectedIngredientIds.has(item.ingredientPrivateId)) {
          this.selectedIngredients.add(item)
          this.selectedIngredientIds.add(item.ingredientPrivateId)
          this.selectedIngredientsValue.add(item)
        } else {
          this.showWarnMessage("Already added this ingredients")
        }
        if (item.isAdded) {
          this.selectedIngredientIds.delete(item.ingredientPrivateId)
          this.selectedIngredients.delete(item)
          this.selectedIngredientsValue.delete(item)
        }
      }

      this.selectedIngredientsTag = [...this.selectedIngredientsValue]
      this.selectedTags = [...this.selectedIngredients]
      this.$forceUpdate()
    },
    /**
     *  click the list of the category to add  into the selectedIngredients
     */
    selectAllCategoryIngredient(item) {
      if (item.isAllChecked) {
        item.isSelected = true
        item.checkCount = item.categoryIngredients.length - item.addedCount
        if (!this.selectedCategory.has(item)) {
          this.selectedCategory.add(item)
        }
        if (item.categoryIngredients && item.categoryIngredients.length > 0) {
          item.categoryIngredients.forEach(ingredient => {
            if (!ingredient.isAdded) {
              if (!this.selectedIngredientIds.has(ingredient.ingredientPrivateId)) {
                this.selectedIngredients.add(ingredient)
              }
            } else {
              this.selectedRemoveIngredients.delete(ingredient)
              this.selectedRemoveIngreidentIds.delete(ingredient.id)
            }
            ingredient.isChecked = true
          })
        }
      } else {
        item.checkCount = 0
        item.isSelected = false
        if (this.selectedCategory.has(item)) {
          this.selectedCategory.delete(item)
        }
        if (item.categoryIngredients && item.categoryIngredients.length > 0) {
          item.categoryIngredients.forEach(ingredient => {
            if (!ingredient.isAdded) {
              this.selectedIngredientIds.delete(ingredient.ingredientPrivateId)
              this.selectedIngredients.delete(ingredient)
            } else {
              this.selectedRemoveIngredients.add(ingredient)
              this.selectedRemoveIngreidentIds.add(ingredient.id)
            }
            ingredient.isChecked = false
          })
        }
      }
      this.selectedCategoryTag = [...this.selectedCategory]
      this.selectedTags = [...this.selectedIngredients]
      this.$forceUpdate()
    },

    /**
     * click the checkbox in the ingredients under the category
     * params: e -> event
     *         item -> ingredients
     */
    clickIngredientsUnderCategory(categoryData, item) {
      if (item.isAdded) {
        if (item.isChecked) {
          this.selectedRemoveIngredients.delete(item)
          this.selectedRemoveIngreidentIds.delete(item.id)
        } else {
          if (!this.selectedRemoveIngreidentIds.has(item.id)) {
            this.selectedRemoveIngredients.add(item)
            this.selectedRemoveIngreidentIds.add(item.id)
          }
        }
      } else {
        if (item.isChecked) {
          // selected ..
          categoryData.isSelected = true
          if (!this.selectedIngredientIds.has(item.ingredientPrivateId)) {
            this.selectedIngredients.add(item)
            this.selectedIngredientIds.add(item.ingredientPrivateId)
          }
          if (categoryData.checkCount + 1 <= categoryData.categoryIngredients.length) {
            categoryData.checkCount += 1
          }
          if (!this.selectedCategory.has(categoryData)) {
            this.selectedCategory.add(categoryData)
          }
          if (categoryData.checkCount === categoryData.categoryIngredients.length) {
            categoryData.isAllChecked = true
          } else {
            categoryData.isAllChecked = false
          }
        } else {
          this.selectedIngredients.delete(item)
          this.selectedIngredientIds.delete(item.ingredientPrivateId)
          if (categoryData.checkCount - 1 >= 0) {
            categoryData.checkCount -= 1
          }
          categoryData.isAllChecked = false
          if (categoryData.checkCount === 0 && this.selectedCategory.has(categoryData)) {
            this.selectedCategory.delete(categoryData)
          }
          if (categoryData.checkCount === 0) {
            categoryData.isSelected = false
          }
        }
        this.selectedCategoryTag = [...this.selectedCategory]
        this.selectedTags = [...this.selectedIngredients]
      }
      this.$forceUpdate()
    },
    loadMoreSearchData() {
      if (this.isCategory) {
        this.scrollCategoryIngredients()
      } else {
        this.scrollIngredients()
      }
    },

    /**
     * get next page's data in individual mode
     */
    scrollIngredients() {
      // load next page data
      // then push the result into this.searchIngredientsResult
      if (this.searchIngredientsResults.length >= this.totalIngredients) {
        //already loaded all the data
        this.showWarnMessage("Infinite List loaded all")
        this.ingredientsBusy = true
        this.loadingIngredients = false
        return
      } else {
        this.ingredientsBusy = true
        this.loadingIngredients = true
        this.curIngredientsPage += 1
        this.$store
          .dispatch("cleanPreCheck/fetchIngredientsByName", {
            "ingredient-name": this.searchIngredientName,
            "page-index": this.curIngredientsPage
          })
          .then(() => {
            let data = this.$store.getters["cleanPreCheck/ingredientsByName"]
            this.searchIngredientsResults = this.searchIngredientsResults.concat(data.list)
            this.initSearchIngredientAddedMark()
            this.totalIngredients = data.total
            this.totalIngredientPage = data.pages
          })
          .finally(() => {
            this.loadingIngredients = false
            this.ingredientsBusy = false
          })
      }
    },
    /**
     * get next page's data in category mode
     */
    scrollCategoryIngredients() {
      if (this.searchCategoryIngredientsResults.length >= this.totalCategorys) {
        //already loaded all the data
        this.showWarnMessage("Infinite List loaded all")
        this.categoryIngredientsBusy = true
        this.loadingCategoryIngredients = false
        return
      } else {
        this.categoryIngredientsBusy = true
        this.loadingCategoryIngredients = true
        this.curCategoryPage += 1
        this.$store
          .dispatch("cleanPreCheck/fetchCategoryIngredientsByName", {
            "category-name": this.searchCategoryName,
            "page-index": this.curCategoryPage,
            _limit: 50
          })
          .then(() => {
            let data = this.$store.getters["cleanPreCheck/categoryIngredients"]
            if (data && data.list) {
              this.searchCategoryIngredientsResults = this.searchCategoryIngredientsResults.concat(
                data.list
              )
              this.initCategoryResultAddedSelectMark()
            }
            this.totalCategorys = data.total
            this.totalCategoryPage = data.pages
          })
          .finally(() => {
            this.loadingCategoryIngredients = false
            this.categoryIngredientsBusy = false
          })
      }
    },

    // ......add clean standards end .......
    showItemDeleteDialog(item) {
      this.selectedDeleteId = item.id
      this.showDeleteDialog = true
    },
    hideItemDeleteDialog() {
      this.selectedDeleteId = ""
      this.showDeleteDialog = false
    },
    filterAddIngredients(value) {
      if (value) {
        if (value.isChecked) {
          value.isChecked = false
          this.searchAddedIngredientByAlpha.delete(value)
          if (!this.searchAddedIngredientByAlpha || this.searchAddedIngredientByAlpha.size === 0) {
            this.searchAddedDirtyResult = this.retailer.forbiddenIngredients.slice()
          }
        } else {
          value.isChecked = true
          this.searchAddedIngredientByAlpha.add(value)
        }
      } else {
        if (this.searchAddedIngredientByAlpha && this.searchAddedIngredientByAlpha.size > 0) {
          let data = [...this.searchAddedIngredientByAlpha]
          for (let i = 0; i < data.length; i++) {
            if (!data[i].isValid) {
              this.searchAddedIngredientByAlpha.delete(data[i])
            }
          }
        }
      }
      if (this.searchAddedIngredientByAlpha.size > 0) {
        this.searchAddedDirtyResult = []
        let dirtyDatas = this.retailer.forbiddenIngredients.slice()
        if (this.searchAddedIngredientByAlpha && this.searchAddedIngredientByAlpha.size > 0) {
          for (let item of this.searchAddedIngredientByAlpha) {
            let result = dirtyDatas.filter(data => {
              if (data.ingredientName.toLowerCase().indexOf(item.name.toLowerCase()) === 0) {
                return true
              } else {
                return false
              }
            })
            if (!this.searchAddedDirtyResult || this.searchAddedDirtyResult.length === 0) {
              this.searchAddedDirtyResult = [...result]
            } else {
              this.searchAddedDirtyResult = this.searchAddedDirtyResult.concat(result)
            }
          }
        }
      }
      this.$forceUpdate()
    },
    clickOuter() {
      this.cancelDelete()
      this.showOption = false
      if (!this.selectedTags || this.selectedTags.length === 0) {
        this.isShowAllTags = false
        this.showTags = false
        this.resetBottomDataLocation()
      } else {
        this.showLimitAddBannedTag()
      }
    },
    clickToRemoveAdded(item) {
      item.isShow = true
      this.deletedItem = item
      this.$forceUpdate()
    },
    cancelDelete() {
      this.deletedItem = undefined
      this.$forceUpdate()
    },
    removeAdded() {
      let param = []
      param.push({
        id: this.deletedItem.id,
        retailerPrivateId: this.deletedItem.retailerPrivateId,
        retailerName: this.deletedItem.retailerName,
        ingredientPrivateId: this.deletedItem.ingredientPrivateId,
        ingredientName: this.deletedItem.ingredientName
      })
      this.doRemoveAddedAction(param, true)
    },
    showMessage(text, isSuccess) {
      this.$message.config({
        top: "200px"
      })
      if (isSuccess) {
        this.$message.success(text, 1)
      } else {
        this.$message.error(text, 1)
      }
    },
    showWarnMessage(text) {
      this.$message.config({
        top: "200px"
      })
      this.$message.warning(text, 1)
    }
  }
}
</script>

<style lang="sass">
.brand-settings__account-information
  width: 100%
  padding-top: 68px
.brand-line-margin
  border-top: 1px solid #ebeff2
.retailer-settings__wrap
  height: 100%
.retailer-settings__box-shadow
  box-shadow: 2px 2px 10px rgb(163,177,198,0.6)
.retailer-settings
  padding-left: 80px !important
  padding-bottom: 68px
  &__dialog-close-btn
    width: 200px
    height: 33px
    line-height: 33px
    border-radius: 2px
    font-size: 14px
    margin-top: 27px
    background-color: #12433d
    color: white
    text-align: center
    margin-left: auto
    margin-right: auto
    cursor: pointer
  &__dialog-close-btn:hover
    background-color: #4a8a5d
    color: #ffffff
  &__dialog-info-before-submit
    text-align: center
    color: #12433d
    font-size: 13px
    margin-top: 13px
    font-family: "Proxima Nova", "Roboto", sans-serif
  &__dialog-info-title
    text-align: center
    font-size: 16px
    margin-bottom: 23px

  .brand-line
   display: flex
  .table, th, td
   border-bottom: 1px solid #ebeff2
   height: 50px
   padding-left: 41px
   padding-top: 20px
  table
   border: 1px solid #ebeff2 !important
  td
   color: #000000
  .text-align
   text-align: center
  .vertical-margin
   padding-top: 25px
   color: #888888 !important
   font-weight: 100
   padding-bottom: 15px
  .align-value
   text-align: right
   padding-right: 10px
  .shipping-block
   padding-top: 41px
  .header-text
   color: #888888
   font-size: 14px
   weight: 200
  .footer-grid
   border-bottom: 1px solid transparent !important
  .grid-style
   color: #333333
   font-size: 14px
  .footer-grid-style
   color: #333333
   font-size: 16px
   font-weight: 700
  .footer-grid-subtitle
   color: #212B36
   font-size: 14px
   font-weight: 300
   padding-top: 1px
  .sub-menuItem
   margin-left : 21px
  .is-selected
   color: #000000
   font-size: 16px

  &__title-style
    font-weight: bold
    font-size: 14px
    color: #333333

  &__border-line
    border-top: 1px solid #ebeff2

  &__status-align
    margin-left: -1px

  &__sub-menuItem-link
    margin-left : 21px
    color: #7F7F7F
    font-size: 16px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 24px

  &__links
    color: #7f7f7f
    font-size: 14px
    padding-top: 70px
    border-right: 1px solid #ddd
    min-height: 70vh!important
    margin-right: 50px

  &__link
    color: #7f7f7f
    font-size: 16px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__account-information
    margin-top: 41px
    .margin-style
      margin-right: 97px

  &__retailer-account
    padding: 18px
    margin-left: 20px

  &__shipping-addresses
    height: 100%
    overflow: auto

  &__saved-addresses-button
    height: 36px
    width: 198px
    color: white
    font-size: 14px
    font-weight: 600
    letter-spacing: 0.7px
    line-height: 18px
    margin-bottom: 35px
  .is-black
    background-color: #292929

  &__modify-buttons
    text-align: right
    font-size: 14px
    color: #442B2D
    font-weight: 600

  &__saved-addresses_boxes
    margin-right: 40px

  &__default-address
    color: #000000

  &__saved-addresses_box
    box-sizing: border-box
    height: 154px
    border: 1px solid #B3B3B3
    padding: 25px
    font-size: 15px
    &_title
      margin-bottom: 5px
    &_subtitle
      color: black

  &__saved-addresses_box_default
    box-sizing: border-box
    height: 154px
    border: 2px solid #000000
    padding: 25px
    &_title
      color: #292929
      width: 300px
      font-size: 14px
      font-weight: 200
      letter-spacing: 0.25px
      line-height: 18px
      margin-bottom: 10px

    &_subtitle
      color: #292929
      width: 300px
      font-size: 14px
      font-weight: 500
      color: black
      letter-spacing: 0.25px
      line-height: 18px
      margin-bottom: 0px
    &_subtitle-second
      color: #292929
      width: 300px
      font-size: 14px
      font-weight: 500
      color: black
      letter-spacing: 0.25px
      line-height: 18px
      margin-bottom: 57px

    &__buttons
      margin-top: 63px
.memberss__wrap
  .members-details-table
    border: 1px solid #ddd

    .members-details-table-header
      padding: 11px 0px
      border-bottom: 1px solid #ddd
      display: flex

      .members__title
        padding-left: 18px
      .invite_member
        justify-content: flex-end
        display: flex
        padding-right: 20px
        .button
          background-color: black
          color: white
          font-size: 14px

    .members-details-table-body
      .table-header
        padding: 11px 35px
        border-bottom: 1px solid #ddd
        display: flex

        .brand-settings__account-information_text
          font-size: 14px

      .table-body
        padding: 6px 35px
        border-bottom: 1px solid #ddd
        display: flex
        align-items: center
        flex-direction: column

    .members-details-table-footer1
      .banner-status1
        width: 100%
        margin-top: 0px
        margin-left: 0px
        border: none
        display: flex
        box-shadow: 0 -10px 10px -5px rgba(63, 63, 68, 0.05), 0 1px 2px -1px rgba(63, 63, 68, 0.15)
        flex-direction: column
        justify-content: center
        .title-style1
          font-weight: bold

        .title-boxbanners1
          margin-top: 32px
          line-height: 2px
          font-size: 16px
          letter-spacing: 0px
        .title-block1
          padding : 21px 35px
          .title-boxbanners1
            margin-top: 0px
          .title-boxbanners1 + .title-boxbanners1
            margin-top: 25px
            font-size: 13px

        .button-block1
          margin-top: 15px
          margin-left: 35px
          .box-button1
            min-width: 124px
            width: auto
            min-height: 27px
            font-size: 14px
.retailer-settings-cpc
  &__edit-modal--form_terms
    position: sticky
    top: 33px
  &__edit-modal--form_container
    padding-bottom: 5px
  &__ingredients-dropdown-outer
    border: 1px solid #BFBFBF
    padding: 8px 0px
    overflow: hidden
    z-index: 999
    position: relative
    background-color: white
  &__alpha-area
    padding: 0px 4px
    width: 100%
    display: block
  &__alpha-filter
    float: left
    width: 18px
    height: 18px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 18px
    align-items: center
    text-align: center
    margin-right: 1px

  //padding: 2px 3px
  &__alpha-invalid
    color: #D9D9D9
  &__alpha-valid
    color: #262626
    cursor: pointer
  &__alpha-selected
    background-color: #595959
    border-radius: 2px
    color: #ffffff
    cursor: pointer
    text-align: center
  &__add-ingredient-placeholder
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
  &__added-ingredient-item
    display: flex
    width: 100%
    background-color: #FFFFFF
    border-radius: 2px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 22px
    color: #262626
  &__add-ingredients-item-close
    text-align: center
  &__dropdown-list-container
    overflow: hidden
    width: 100%
    padding: 0px !important
  &__expand
    background-color: #4C4C4C
  &__expand-icon
    color: white !important
  &__dropwon-list-loading-container
    width: 100%
    text-align: center
    margin: 0 auto
  &__add-ingredients
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    align-items: center
    color: #262626
  &__select-ingredients-tag-text-outer
    width: 90%
    display: inline-block
    vertical-align: middle
  &__select-ingredients-tag
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 22px
    align-items: center
    color: #262626
    background: #F5F5F5
    border: 1px solid #E8E8E8
    border-radius: 2px
    padding: 1px 8px
    margin-bottom: 2px !important
  &__select-ingredients-individual-tag
    width: 48%
    display: inline-block !important
  &__select-ingredients-category-tag
    width: 100%
  &__text-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 96%
    display: block
  &__category-text-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 90%
    display: inline-block
  &__added-text-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 430px
  &__tooltip-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 15px
    color: #FFFFFF
  &__btn-clicked
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #FFFFFF !important
    border-radius: 2px !important
    background: #595959 !important
    height: 25px
  &__btn-clicked:hover
    border: none !important
  &__btn-clicked:focus
    border: none !important
  &__btn-unclicked
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626 !important
    border-radius: 2px !important
    background: #F5F5F5 !important
    height: 25px

  &__btn-unclicked:hover
    border: none !important
  &__btn-unclicked:focus
    border: none !important
  &__btn-save
    border: 1px solid #595959
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    background: #FEF2E2 !important
    border-radius: 2px !important
    color: #595959 !important
    width: 88px
    height: 25px
  &__btn-save:hover
    border: none !important
  &__btn-save:focus
    border: none !important
  &__add-load-more
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #12433D
  &__add-ingredient-result
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 15px
    text-align: right
    padding-right: 16px !important
    margin-right: 10px
    margin-bottom: 10px
    color: #8C8C8C
  &__added-layer
    width: 100%
    margin-top: 20px
    position: absolute
    top: 40px
    height: 100%
  &__already-added
    background: #D9D9D9 !important
    color: darkgray !important
  &__option-selected
    background: #E8E8E8 !important
  &__dropdown-list-item
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
    width: 100%
    border-bottom: none !important
  &__dropdown-list-item:hover
    background-color: #E8E8E8
  &__dropdown-category-header
    background-color: #FFFFFF
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
    padding: 1px 4px
  &__dropdown-category-header:focus
    background-color: #8C8C8C
  &__search-input
    background-color: #f5f5f5
    border-radius: 0px
    padding-left: 6px
    padding-right: 4px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #595959
    width: 100%
    overflow: hidden
    text-overflow: ellipsis
    margin-left: 8px
    margin-right: 8px
  &__margin-top
    margin-top: 22px
  &__margin-left
    margin-left: 20px
  &__header
    border-bottom: 1px solid #D8D8D8
    padding-bottom: 25px
  &__added-dirty-filter-number
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 15px
    align-items: center
    text-align: left
    color: #8C8C8C
  &__list-empty-layer
    display: table-cell
    vertical-align: middle
    height: 200px
  &__list-empty-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    align-items: center
    text-align: center
    color: #BFBFBF
  &__list-empty-border
    border: 1px solid #BFBFBF
    padding: 2px 4px
  &__added-ingredients-item
    padding-left: 10px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 22px
    color: #262626
    border-radius: 2px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  &__added-item-delete
    padding-left: 20px !important
  //display: none !important
  &__delete
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
  &__category-ingredient-ck
    font-family: "Proxima Nova"
  &__panel-content
    //margin-bottom: -16px
    //margin-left: -16px
    //margin-right: -16px
    background-color: #F5F5F5
  //padding: 6px 6px 6px 16px
  &__panel-header
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
    padding-left: 2px
  &__panel-content
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
#retailerAddedIngredients .ant-list-split .ant-list-item
  border-bottom: 4px solid transparent !important
#retailerAddedIngredients .ant-list-item
  padding: 0px !important
  margin: 0px !important
.retailer-settings-cpc__delete .ant-tooltip-arrow::before
  background-color: #E2EEEC !important
.retailer-settings-cpc__delete .ant-tooltip-inner
  background-color: #E2EEEC !important
  padding: 2px !important
.retailer-settings-cpc__tooltip-text .ant-tooltip-arrow::before
  background-color: #8C8C8C !important
.retailer-settings-cpc__tooltip-text .ant-tooltip-inner
  background-color: #8C8C8C !important
  padding: 2px !important
.retailer-settings-cpc__dropdown-list-container .ant-collapse-borderless > .ant-collapse-item
  border-bottom: none !important
  padding-bottom: 1px
  padding-top: 1px
.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__already-added .ant-collapse-borderless .ant-collapse-item .ant-collapse-header
  background-color: #D9D9D9 !important
.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__already-added .ant-collapse-borderless .ant-collapse-item .ant-collapse-header .retailer-settings-cpc__panel-header
  color: darkgray
.retailer-settings-cpc__edit-modal--form_container .multiselect__option--highlight .retailer-settings-cpc__dropdown-list-container .ant-collapse-borderless
  background: #e8e8e8
.retailer-settings-cpc__dropdown-list-container .ant-collapse-borderless
  background-color: #FFFFFF
.retailer-settings-cpc__dropdown-list-container .ant-collapse-borderless > .ant-collapse-item-active
  background: #8C8C8C !important
.retailer-settings-cpc__dropdown-list-container .ant-collapse-icon-position-right > .ant-collapse-item-active.ant-collapse-item > .ant-collapse-header .retailer-settings-cpc__panel-header
  color: #595959
.retailer-settings-cpc__dropdown-list-container .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header
  padding: 0px !important
.retailer-settings-cpc__dropdown-list-container .ant-collapse-icon-position-right > .ant-collapse-item-active.ant-collapse-item > .ant-collapse-header
  padding: 0px !important
  background: #8C8C8C !important
#retailerAddedIngredients .ant-collapse-borderless > .ant-collapse-item
  border-bottom: none !important
#retailerAddedIngredients .ant-collapse-borderless
  background-color: #FFFFFF
#retailerAddedIngredients .ant-collapse-borderless > .ant-collapse-item-active
  background: #8C8C8C !important
#retailerAddedIngredients .ant-collapse-icon-position-right > .ant-collapse-item-active.ant-collapse-item > .ant-collapse-header .retailer-settings-cpc__panel-header
  color: #FFFFFF
  padding: 2px
  margin-top: 2px
  margin-bottom: 2px
#retailerAddedIngredients .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header
  padding: 0px !important
#retailerAddedIngredients .ant-collapse-icon-position-right > .ant-collapse-item-active.ant-collapse-item > .ant-collapse-header
  padding: 0px !important
  background: #8C8C8C !important
.retailer-settings-cpc__panel .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow
  margin-top: 2px
#addedDirtyList .ant-list-empty-text
  display: table !important
  margin: 0 auto !important
.retailer-settings-cpc__edit-modal--form_container .multiselect__option
  padding: 0px !important
  min-height: 17px !important
.retailer-settings-cpc__edit-modal--form_container .multiselect__option--highlight
  background: #E8E8E8
.retailer-settings-cpc__edit-modal--form_container .multiselect__tags-wrap
  width: 100%
  display: block
.retailer-settings-cpc__select-ingredients-tag:hover
  background: #FEF2E2
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element .multiselect__option--selected
  background: #E8E8E8 !important
  color: #262626 !important
  font-weight: normal !important
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element >.multiselect__option >.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__option-selected .ant-collapse-borderless
  background: #E8E8E8 !important
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element >.multiselect__option >.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__option-selected .ant-collapse-borderless .ant-collapse-item-active .ant-collapse-header span
  color: #FFFFFF
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element >.multiselect__option >.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__option-selected .ant-collapse-borderless .ant-collapse-content > .ant-collapse-content-box
  padding: 0px
.retailer-settings-cpc__edit-modal--form_container  .multiselect__content >.multiselect__element >.multiselect__option .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content
  background: #F5F5F5
  border-radius: 0px
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element >.multiselect__option >.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__option-selected .ant-collapse-borderless .ant-collapse-content > .ant-collapse-content-box .retailer-settings-cpc__category-ingredient-ck
  margin-left: 16px !important
#modifyCategory .ant-collapse-item .ant-collapse-header
  padding: 12px 0px
  padding-right: 16px
//background: #E8E8E8 !important
#modifyCategory .ant-collapse-item .ant-collapse-content
  background: #F5F5F5
#modifyCategory .ant-collapse-borderless .ant-collapse-item-active .ant-collapse-header span
  color: #FFFFFF
#modifyCategory .ant-collapse-borderless .ant-collapse-content > .ant-collapse-content-box
  padding: 0px
#modifyCategory .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content
  background: #F5F5F5
  border-radius: 0px
#modifyCategory .ant-collapse-borderless .ant-collapse-content > .ant-collapse-content-box .retailer-settings-cpc__panel-content
  margin-left: 16px !important
.retailer-settings-cpc__added-item-delete:hover
  display: inline-block !important
.retailer-settings-cpc__delete .ant-popover-inner-content
  padding: 2px
  img
    margin: 0px
.retailer-settings-cpc__panel-header .ant-checkbox-checked .ant-checkbox-inner
  background-color: #D9D9D9 !important
  border: 1px solid #FFFFFF !important
.retailer-settings-cpc__panel-header .ant-checkbox-checked .ant-checkbox-inner:after
  border-color: #595959 !important
.retailer-settings-cpc__category-ingredient-ck .ant-checkbox-checked .ant-checkbox-inner
  background-color: #FFFFFF !important
  border: 1px solid #D9D9D9 !important
.retailer-settings-cpc__category-ingredient-ck .ant-checkbox-checked .ant-checkbox-inner:after
  border-color: #595959 !important
.retailer-settings-cpc__dropdown-list-container .retailer-settings-cpc__panel-header .ant-checkbox-checked .ant-checkbox-inner
  background-color: #D9D9D9 !important
  border: 1px solid #FFFFFF !important
.retailer-settings-cpc__dropdown-list-container .retailer-settings-cpc__panel-header .ant-checkbox-checked .ant-checkbox-inner:after
  border-color: #595959 !important
.retailer-settings-cpc__dropdown-list-container .retailer-settings-cpc__category-ingredient-ck .ant-checkbox-checked .ant-checkbox-inner
  background-color: #FFFFFF !important
  border: 1px solid #D9D9D9 !important
.retailer-settings-cpc__dropdown-list-container .retailer-settings-cpc__category-ingredient-ck .ant-checkbox-checked .ant-checkbox-inner:after
  border-color: #595959 !important
.retailer-settings-cpc__added-ingredient-item:hover
  background-color: #d9d9d9
#retailerDirtyIngredientSearch .ant-input-affix-wrapper .ant-input:not(:last-child)
  background: #f5f5f5
#addedDirtyList .ant-pagination-item-active
  background: #595959
  border-color: #595959
#addedDirtyList .ant-pagination-item
  border-radius: 2px
#addedDirtyList .ant-pagination-item-active a
  color: #FFFFFF
#addBannedIngredient .multiselect__tags
  border-radius: 2px
  padding: 8px
  padding-bottom: 0px
#addBannedIngredient .multiselect__content-wrapper
  border-bottom-left-radius: 2px
  border-bottom-right-radius: 2px
#countBannedIngredient .ant-tag
  background: #FEF2E2
  border: 1px solid #FEC206
  border-radius: 2px
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 17px
  color: #FEC206
.retailer-settings-cpc__search-input > input.ant-input
  background: #f5f5f5
.categoryTooltip1.ant-tooltip
  max-width: 560px
.categoryTooltip1 .ant-tooltip-inner
  font-size: 10px !important
  -webkit-text-size-adjust: none
.categoryTooltip.ant-tooltip
  max-width: 690px
.categoryTooltip .ant-tooltip-inner
  font-size: 10px !important
  -webkit-text-size-adjust: none
</style>
