<template>
  <div class="beauty-fluent-step-three-subscription__main">
    <div class="subscription-plan-style" style="font-family: 'Proxima Nova', 'Roboto', sans-serif">
      <p>
        <span style="font-size: 18px;font-weight: bold;color: #1a1818;font-family:'Niveau Grotesk';"
          >YOUR SUBSCRIPTION</span
        >
      </p>
      <p class="flex-style">
        <span
          >Your Plan: BeautyFluent
          {{ retailerName[0].toUpperCase() + retailerName.substr(1) }}</span
        >
        <span>{{
          priceFormat(billingInfo.filter(item => item.name === "Annual")[0].price.toFixed(2))
        }}</span>
      </p>
      <div style="margin-bottom: 20px">
        <a-dropdown :trigger="['click']" :disabled="canNotChangeRetailer">
          <a
            class="ant-dropdown-link"
            style="color: #F15A42;font-size: 13px;font-weight: bold"
            @click.prevent
          >
            CHANGE PLAN <a-icon type="down"></a-icon>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="item in preferredRetailers" :key="item.retailerId">
                <span
                  :class="item.retailerName === retailerName ? 'click-retailer-plan' : ''"
                  style="cursor: pointer"
                  @click="changePlan(item)"
                  >BeautyFluent
                  {{ item.retailerName[0].toUpperCase() + item.retailerName.substr(1) }}</span
                >
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <p v-if="isSuccess" class="flex-style" style="margin-top: 9px">
          <span>Promo Code - {{ code }}</span>
          <span>({{ priceFormat(couponAmount.toFixed(2)) }})</span>
        </p>
      </div>
      <a-divider style="background-color: #f27c59" />
      <div>
        <p class="flex-style">
          <span>Subtotal</span>
          <span>{{ priceFormat(chooseBillingInfo.subTotalAmount) }}</span>
        </p>
        <p v-if="chooseBillingInfo.savingTxt" class="flex-style">
          <span>{{ chooseBillingInfo.savingTxt }}</span>
          <span>({{ priceFormat(chooseBillingInfo.savingNumber) }})</span>
        </p>
        <p v-if="chooseBillingInfo.savingTxt" class="flex-style" style="margin-top: 30px">
          <span>Total after Discount</span>
          <span>{{
            priceFormat(
              (chooseBillingInfo.subTotalAmount - chooseBillingInfo.savingNumber).toFixed(2)
            )
          }}</span>
        </p>
      </div>
      <a-divider style="background-color: #f27c59" />
      <p class="flex-style" style="font-weight: bold">
        <span style="display: inline-block;width: 170px">{{
          chooseBillingInfo.subscriptionTotalText
        }}</span>
        <span>{{ priceFormat(chooseBillingInfo.sumTotal) }}</span>
      </p>
    </div>
    <div class="subscription-plan-style">
      <p style="display: flex;justify-content: space-between;align-items: center">
        <span style="font-size: 14px;font-weight: bold;color: #1a1818;font-family:'Niveau Grotesk';"
          >PROMO CODE</span
        >
        <span class="remove-code" @click="removePromocode">REMOVE CODE</span>
      </p>
      <p style="position: relative">
        <a-input
          v-model="code"
          loading
          placeholder="PROMO CODE"
          @input="noPromoCode"
          @search="searchPromoCode"
        />
        <span class="promo-code-apply" @click="searchPromoCode">
          APPLY <a-icon type="arrow-right" style="font-size: 12px;margin-left: 15px" />
        </span>
      </p>
      <p v-if="isSuccess" style="color: #4a8a5d">{{ showCodeText.message }}</p>
      <p v-if="isFail" style="color: red">This promo code is invalid</p>
    </div>
    <div>
      <!-- <div class="terms-condition-title-style">
        <p>
          <span
            style="font-size: 18px;font-weight: bold;color: #1a1818;font-family:'Niveau Grotesk';"
            >TERMS & CONDITIONS</span
          >
        </p>
        <p>
          <span style="cursor: pointer" @click="downloadTermsPdf">
            <a-icon type="vertical-align-bottom" style="font-size: 20px;color: #F15A42" />
            <a-icon
              type="printer"
              filled
              style="font-size: 20px;color: #F15A42;margin-left: 20px;"
            />
          </span>
                     <a-icon type="download" style="margin-right: 7px" @click="downloadTermsPdf" />
                     <a-icon type="printer" theme="filled" />
        </p>
      </div>
      <div id="termsCondition" class="terms-condition-style">
        <Terms></Terms>
               <iframe height="100%" width="100%" src="https://www.landinginternational.com/terms-of-use"></iframe>
      </div> -->
      <p class="agree-terms-condition">
        <a-checkbox v-model="checkboxValue" @click="checkValue">
          By clicking on this button, you accept the <a class="nav-link" href="https://www.landinginternational.com/terms-of-use/" target="_blank">Terms & Conditions</a> and <a class="nav-link" href="https://www.landinginternational.com/privacy-policy" target="_blank">Privacy Policy</a>
        </a-checkbox>
      </p>
    </div>
  </div>
</template>

<script>
// import Terms from "@/views/Terms"
import { priceFormat } from "@/utils/validate"

export default {
  name: "BFStep3Subscription",
  // components: {
  //   Terms
  // },
  props: {
    retailerInfo: {
      type: Object,
      default: () => {}
    },
    billingInfo: {
      type: Array,
      default: () => []
    },
    existedRetailer: {
      type: Array,
      default: () => []
    },
    canNotChangeRetailer: {
      type: Boolean,
      default: () => false
    },
    subTotalAmount: {
      type: String || Number,
      default: ""
    },
    savingNumber: {
      type: String || Number,
      default: ""
    },
    sumTotal: {
      type: String || Number,
      default: ""
    }
  },
  data() {
    return {
      activeKey: 1,
      code: undefined,
      checkboxValue: false,
      showCodeText: {},
      codeText: [
        {
          type: "is-success",
          message: "This promo code is valid"
        },
        {
          type: "is-danger",
          message: "This promo code is invalid"
        }
      ],
      isSuccess: false,
      isFail: false,
      retailerId: undefined,
      retailerName: this.retailerInfo.retailerName,
      couponAmount: 0,
      priceFormat
    }
  },
  computed: {
    preferredRetailers() {
      let data = this.$store.getters["retailer/preferredRetailers"]
      const generalRetailer = this.$store.getters["retailer/generalRetailer"]
      if (data && data.length > 0) {
        if (this.existedRetailer && this.existedRetailer.length > 0) {
          for (let i = 0; i < this.existedRetailer.length; i++) {
            data = data.filter(item => item.retailerId !== this.existedRetailer[i].retailerId)
          }
        }
      }
      const list = data.filter(item => item.retailerId !== "*")
      if (this.existedRetailer && this.existedRetailer.length === 0) {
        list.push(generalRetailer)
      }
      return list
    },
    chooseBillingInfo() {
      const chooseBilling = this.billingInfo.filter(item => item.isClick)
      return chooseBilling[0]
    }
    // subTotalAmount() {
    //   const total = this.billingInfo.filter(item => item.name === "Annual")[0].price
    //   if (this.code && this.isSuccess) {
    //     return (total - this.couponAmount).toFixed(2)
    //   }
    //   return total.toFixed(2)
    // },
    // savingNumber() {
    //   const info = this.billingInfo.filter(item => item.isClick)
    //   return (this.subTotalAmount * info[0].savingNum).toFixed(2)
    // },
    // sumTotal() {
    //   const number = (this.subTotalAmount - this.savingNumber).toFixed(2)
    //   if (this.chooseBillingInfo.name === "Quarter") {
    //     const num = Math.round((number / 4) * 100) / 100
    //     return priceFormat(num.toFixed(2))
    //   } else if (this.chooseBillingInfo.name === "Monthly") {
    //     const num = Math.round((this.subTotalAmount / 12) * 100) / 100
    //     return priceFormat(num.toFixed(2))
    //   } else {
    //     return priceFormat(number)
    //   }
    // }
  },
  methods: {
    downloadTermsPdf() {
      const linkUrl =
        "https://original-assets-d.landingintl.com/Landing%20Mobile%20App%20Terms%20%26%20Conditions.pdf"
      window.open(linkUrl)
    },
    changePlan(item) {
      this.retailerId = item.retailerId
      this.retailerName = item.retailerName
      this.$emit("updateRetailerInfo", item)
    },
    removePromocode() {
      this.code = ""
      this.isSuccess = false
      this.isFail = false
      const value = null
      this.couponAmount = 0
      this.$emit("promoCodeSend", value, this.couponAmount)
    },
    async noPromoCode() {
      if (!this.code) {
        await this.searchPromoCode()
      }
    },
    searchPromoCode() {
      let params = {
        couponCode: this.code
      }
      this.$store
        .dispatch("adminConsole/getCouponSignUpValue", params)
        .then(res => {
          this.isSuccess = false
          this.isFail = false
          this.showCodeText = this.codeText[0]
          if (res) {
            if (res.isPercentage) {
              const number = res.amount / 100
              const actualPrice = this.billingInfo.filter(item => item.name === "Annual")[0].price
              this.couponAmount = actualPrice * number
            } else {
              this.couponAmount = res.amount
            }
            this.isSuccess = true
            this.$emit("promoCodeSend", this.code, this.couponAmount)
          }
        })
        .catch(e => {
          console.log(e)
          this.isSuccess = false
          // this.isFail = true
          if (this.code) {
            this.isFail = true
          } else {
            this.isFail = false
          }
          const value = null
          this.couponAmount = 0
          this.$emit("promoCodeSend", value, this.couponAmount)
          // this.promoCode = ""
        })
    },
    checkValue() {
      this.checkboxValue = !this.checkboxValue
      this.$emit("checkRadio", this.checkboxValue)
    }
  }
}
</script>

<style lang="scss">
.beauty-fluent-step-three-subscription__main {
  font-family: "DM Sans";
  max-width: 479px;
  font-size: 16px;
  p {
    margin-bottom: 12px;
  }
  .subscription-plan-style {
    background-color: rgba(207, 229, 204, 0.2);
    padding: 42px 41px 42px 47px;
    margin-bottom: 35px;
    border-radius: 10px;
  }
  .flex-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .terms-condition-title-style {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .terms-title {
      width: 60%;
      font-weight: bold;
      text-align: right;
    }
    span:hover {
      //text-decoration: underline;
      color: #4a8a5d;
    }
  }
  .terms-condition-style {
    padding: 20px 0;
    width: 100%;
    height: 270px;
    overflow: auto;
    border: 1px solid #e2dfdf;
  }
  .agree-terms-condition {
    text-align: left;
    padding: 15px 0;
  }
  .click-retailer-plan {
    background-color: #f15a42;
    color: #ffffff;
  }
  .promo-code-apply {
    font-size: 13px;
    color: #f15a42;
    cursor: pointer;
    font-family: "Niveau Grotesk";
    position: absolute;
    top: 17px;
    right: 15px;
    z-index: 5;
  }
  .remove-code {
    cursor: pointer;
  }
  .remove-code:hover {
    text-decoration: underline;
  }
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-input-password,
  .ant-input-affix-wrapper .ant-input {
    height: 55px;
    border-radius: 100px;
    border-color: #f15a42;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f27c59 !important;
    border-color: #f27c59 !important;
  }
  .ant-checkbox-inner {
    border-radius: 50%;
  }
  .ant-checkbox-inner::after {
    background-color: #f27c59 !important;
  }
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item-active {
  background-color: #f15a42 !important;
  color: #ffffff;
}
.nav-link {
  color: rgba(0,0,0,0.6);
  text-decoration: underline;
}
</style>
