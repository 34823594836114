import retailerMarginClient from "@/api/retailer-margin-client.js"

export default {
  namespaced: true,

  state: {
    retailers: [],
    retailer: [],
    status: false
  },

  getters: {
    retailers(state) {
      return state.retailers
    },
    retailer(state) {
      return state.retailer
    }
  },

  mutations: {
    SET_RETAILERS(state, retailers) {
      state.retailers = retailers
    },
    SET_RETAILER(state, retailer) {
      state.retailer = retailer
    },
    SET_STATUS(state, status) {
      state.status = status
    }
  },

  actions: {
    brandNameById(brandId) {
      const brand = this.$store.getters["brand/brandById"](brandId)
      return brand && brand.name
    },
    productNameById(productId) {
      const product = this.$store.getters["product/find"](productId)
      return product && product.name
    },
    async fetchRetailer({ commit }, retailerId) {
      try {
        const { data } = await retailerMarginClient.getRetailerMargins(retailerId)
        commit("SET_RETAILER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchRetailersMargins({ commit, rootGetters }) {
      try {
        const { data } = await retailerMarginClient.getMarginSetRetailers({
          "have-margin": true,
          fields: "id,name, shortName, margins"
        })
        data.map(retailerMargins => {
          let countAll = 0
          retailerMargins.margins.map(margin => {
            console.log(`id:${margin.retailer.id}, name: ${margin.retailer.name}`)
            if (margin.brand.id === "*" && margin.product.id === "*") {
              countAll += 1
            }
            margin.isShow = true
            if (margin.brand.id === "*") {
              margin.brand.name = "0"
            } else {
              const brand = rootGetters["brand/brandById"](margin.brand.id)
              margin.brand.name = brand && brand.name
            }

            if (margin.product.id === "*") {
              margin.product.name = "All Product"
            } else {
              const product = rootGetters["product/find"](margin.product.id)
              margin.product.name = product && product.name
            }
          })
          retailerMargins.margins.sort((a, b) => {
            if (a.brand && a.brand.name && b.brand && b.brand.name) {
              return a.brand.name.localeCompare(b.brand.name) === 0 ? a.product.name.localeCompare(b.product.name) : a.brand.name.localeCompare(b.brand.name)
            }
            else {
              return 1
            }
          })
          if (countAll > 1) {
            for(let i = 0; i < countAll; i++) {
              if (retailerMargins.margins[i].retailer.id === '*') {
                retailerMargins.margins[i].isShow = false
              }
            }
          }
        })
        commit("SET_RETAILERS", data)
      } catch (error) {
        let index = error.toString().indexOf('404')
        if (index >= 0) {
          commit("SET_RETAILERS", [])
        } else {
          return Promise.reject(error)
        }
      }
    },
    async updateRetailerMargins({ commit }, { params, retailerId }) {
      try {
        const { data } = await retailerMarginClient.updateRetailerMargins(params, retailerId)
        commit("SET_RETAILER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async removeRetailerMargins({ commit }, { params, retailerId }) {
      try {
        console.log("removeRetailerMargins")
        console.log(params)
        console.log(retailerId)
        const { data } = await retailerMarginClient.removeRetailerMargins(params, retailerId)
        console.log(data)
        commit("SET_STATUS", true)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
