<template>
  <div class="brand-product-new__main">
    <div class="training-page-title">
      <div class="training-logo"></div>
      <div class="title-button">
        <a-button
          v-if="showShareBtn"
          id="copyBtn"
          class="btn-style"
          :class="shareText === 'Link Copied' ? 'active-share-button' : ''"
          @click="shareToLink"
        >
          {{ shareText }}
        </a-button>
        <!--          <a-button class="btn-style" @click="openUploadVideo">-->
        <!--            <a-icon type="upload" />-->
        <!--            <span-->
        <!--              v-if="brand.introductionAsset && Object.keys(brand.introductionAsset).length !== 0"-->
        <!--              >Edit Video</span-->
        <!--            >-->
        <!--            <span v-else>Upload Video</span>-->
        <!--          </a-button>-->
        <a-button
          class="btn-style"
          :class="downloadText === 'QR Dowloaded' ? 'active-share-button' : ''"
          style="width: 150px"
          @click="downloadQRCode"
        >
          {{ downloadText }}
        </a-button>
        <a-button class="btn-style" @click="jumpToCreateTraining">
          <!--            {{ this.$t("Training.brandTraining.createButton") }}-->
          View All
        </a-button>
      </div>
      <div class="qr-code-style">
        <QRCodeView ref="qrcodeView"></QRCodeView>
      </div>
    </div>
    <div class="training-page-table">
      <table class="training-table-style">
        <thead>
          <tr class="table-tr-header">
            <th style="width:273px;">
              Name
              <a-icon
                style="cursor: pointer"
                :type="isNameSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('name')"
              ></a-icon>
            </th>
            <th style="width:123px;">
              Hero
              <a-icon
                style="cursor: pointer"
                :type="isHeroSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('hero')"
              ></a-icon>
            </th>
            <th style="width:115px;">
              SKU
              <a-icon
                style="cursor: pointer"
                :type="isSkuSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('sku')"
              ></a-icon>
            </th>
            <th style="width:115px;">
              UPC
              <a-icon
                style="cursor: pointer"
                :type="isUpcSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('upc')"
              ></a-icon>
            </th>
            <th style="width:109px;">
              Modified
              <a-icon
                style="cursor: pointer"
                :type="isModifiedSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('modified')"
              ></a-icon>
            </th>
            <th style="width:109px;"></th>
            <th style="width:109px;">Public</th>
            <th style="width:136px;">Actions</th>
          </tr>
        </thead>
        <div v-if="tableDate && tableDate.length === 0" class="no-table-data">
          <a-empty />
        </div>
        <!--          <tbody v-else>-->
        <draggable
          v-else
          v-model="tableDate"
          :disabled="false"
          animation="500"
          force-fallback="true"
          handle=".move"
          filter=".forbid"
          :move="checkMove"
          tag="tbody"
          @start="onStart"
          @end="onEnd"
        >
          <tr v-for="(item, index) in tableDate" :key="index">
            <td
              style="width:273px;"
              class="name-tab-style"
              :class="index > canNotDrag - 1 ? 'forbid' : 'move'"
            >
              {{ item.name }}
            </td>
            <td
              style="width:123px;"
              :class="index > canNotDrag - 1 ? 'forbid' : 'move'"
            >
              <span
                v-if="item.training && item.training.hero"
                class="product-hero-style"
                >Hero</span
              >
            </td>
            <td
              style="width:115px;"
              class="data-tab-style"
              :class="index > canNotDrag - 1 ? 'forbid' : 'move'"
            >
              {{ item.sku || "--" }}
            </td>
            <td
              style="width:115px;"
              class="data-tab-style"
              :class="index > canNotDrag - 1 ? 'forbid' : 'move'"
            >
              {{
                item && item.barcodes && item.barcodes.length
                  ? item.barcodes[0].code
                  : "--"
              }}
            </td>
            <td
              style="width:115px;"
              class="data-tab-style"
              :class="index > canNotDrag - 1 ? 'forbid' : 'move'"
            >
              {{ item.updatedAt ? item.updatedAt.slice(0, 10) : "--" }}
            </td>
            <td
              style="width:115px;"
              class="data-tab-style"
              :class="index > canNotDrag - 1 ? 'forbid' : 'move'"
            >
              <span
                v-if="item.training && !item.training.published"
                class="draft-info"
                @click="handleProductList(item)"
                >Draft</span
              >
            </td>
            <td style="width:115px;" class="data-tab-style">
              <a-switch
                v-model="item.public"
                :disabled="
                  (item.training && !item.training.published) ||
                    item.activeText === 'Edit'
                "
                :style="
                  `background-color: ${item.publicColor}; background-image: none`
                "
                @change="onChange(item)"
              />
            </td>
            <td style="width:136px" class="click-pro-action">
              <div class="editable-row-operations">
                <span @click="handleProductList(item)">
                  {{ item.activeText }}
                </span>
              </div>
            </td>
          </tr>
        </draggable>
      </table>
    </div>
  </div>
</template>

<script>
import QRCodeView from "@/components/training/__components/QRCode";
import draggable from "vuedraggable";
// import { downloadQR } from "@/utils/validate"
import trainingClient from "@/api/training-client";
// import Clipboard from "clipboard"

export default {
  name: "BrandProduct",
  components: {
    draggable,
    QRCodeView,
  },
  props: {
    tableDate: {
      type: Array,
      default: () => [],
    },
    getTrainingProducts: {
      type: Array,
      default: () => [],
    },
    showShareBtn: {
      type: Boolean,
    },
    shareText: {
      type: String,
      default: "Share Link",
    },
    downloadText: {
      type: String,
      default: "Download QR code",
    },
    clipboard: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isUpdating: false,
      isNameSort: false,
      isHeroSort: false,
      isSkuSort: false,
      isUpcSort: false,
      isModifiedSort: false,
      canNotDrag: 0,
      currentNum: 50,
      list: [],
      // clipboard: null,
    };
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"];
    },
  },
  destroyed() {
    if (this.clipboard && this.shareVisible) {
      this.clipboard.destroyed();
    }
  },
  methods: {
    /**
     * Share Link
     */
    shareToLink() {
      this.$emit("shareToLink");
    },
    /**
     * download QRCode
     */
    downloadQRCode() {
      this.$emit("downloadQRCode");
    },
    /**
     * create training
     */
    jumpToCreateTraining() {
      this.$emit("jumpToCreateTraining");
      // click to old training page
      // if (this.$route.params.brandId) {
      //   this.$router.push({
      //     name: "AdminbrandTrainingCreate"
      //   })
      // } else {
      //   this.$router.push({
      //     name: "BrandTrainingCreate"
      //   })
      // }

      // click to new training page product list
      // this.$router.push({
      //   name: "TrainingProductViewAll",
      //   query: {
      //     id: this.brand.id,
      //     name: this.brand.name
      //   }
      // })
    },
    /**
     * Edit training
     */
    handleProductList(item) {
      if (!this.checkProductInfo(item)) {
        return;
      }
      let isNewTraining = false;
      let product;
      if (!item.training) {
        product = {
          ...item,
          training: {
            hero: false,
          },
        };
      } else {
        product = { ...item };
      }
      if (item.activeText === "Edit") {
        isNewTraining = true;
      } else if (item.training && item.training.states === 0) {
        isNewTraining = true;
      }
      // this.$refs["modifyRetailerBrandProduct"].openDialog(product, isNewTraining)
      this.$emit("handleProductList", product, isNewTraining);
    },
    checkProductInfo(productItem) {
      if (
        productItem.training &&
        Object.keys(productItem.training).length !== 0
      ) {
        return true;
      }
      let errorText;
      if (
        !productItem.size ||
        Object.keys(productItem.sizeUnits).length === 0
      ) {
        errorText = "product Size or sizeUnits is required Field";
        this.$emit("showModalNoInfo", errorText, productItem.id);
        return false;
      } else if (
        !productItem.newBenefits ||
        productItem.newBenefits.length === 0
      ) {
        errorText = "Benefits is required Field";
        this.$emit("showModalNoInfo", errorText, productItem.id);
        return false;
      } else if (!productItem.msrpUsd || !productItem.name) {
        errorText = "MsrpUsd or name is required Field";
        this.$emit("showModalNoInfo", errorText, productItem.id);
        return false;
      } else if (!productItem.images || productItem.images.length === 0) {
        errorText = "Product image is required Field";
        this.$emit("showModalNoInfo", errorText, productItem.id);
        return false;
        // } else if (!productItem.newIngredients || productItem.newIngredients.length === 0) {
        //   errorText = "Ingredients is required Field"
        //   this.$emit("showModalNoInfo", errorText, productItem.id)
        //   return false
      } else if (
        Object.keys(productItem.subcategory).length === 0 ||
        Object.keys(productItem.countryOfOrigin).length === 0
      ) {
        errorText = "subcategory or countryOfOrigin is required Field";
        this.$emit("showModalNoInfo", errorText, productItem.id);
        return false;
      } else if (!productItem.description) {
        errorText = "description is required Field";
        this.$emit("showModalNoInfo", errorText, productItem.id);
        return false;
      } else {
        return true;
      }
    },
    /**
     * 拖拽逻辑
     */
    checkMove(evt, originalEvent) {
      if (evt.relatedContext.index >= this.canNotDrag) {
        return false;
      }
      this.list = evt.relatedContext.list.map((item) => item.id);
      console.log("get info", originalEvent);
      return true;
    },
    //开始拖拽事件
    onStart(e) {
      console.log("drag", e);
      // this.drag = true
    },
    //拖拽结束事件
    async onEnd(e) {
      if (e.newIndex === e.oldIndex) {
        return;
      }
      const haveTrainingList = this.formatProductTableList(
        this.getTrainingProducts
      );
      const trainingLength = haveTrainingList.length;
      let param;
      let data = this.tableDate[e.newIndex];
      if (e.newIndex === 0) {
        data.training.sort = Math.fround(
          this.tableDate[e.newIndex + 1].training.sort / 2
        );
      } else if (e.newIndex + 1 === trainingLength) {
        data.training.sort =
          this.tableDate[e.newIndex - 1].training.sort + 10000;
      } else {
        const first = this.tableDate[e.newIndex + 1];
        const second = this.tableDate[e.newIndex - 1];
        data.training.sort = Math.fround(
          (first.training.sort + second.training.sort) / 2
        );
      }
      param = data;
      const isDrag = true;
      await this.onChange(param, isDrag);
    },
    async onChange(value, isDrag) {
      if (this.isUpdating) {
        return;
      }
      this.isUpdating = true;
      const param = {
        training: {
          demoTips: value.training.demoTips,
          isHero: value.training.hero,
          howToDemo: value.training.howToDemo,
          id: value.training.id,
          isPublished: true,
          states: value.public ? 2 : 1,
          sort: value.training.sort,
        },
        trainingBestUsedFors: value.trainingBestUsedFors,
        trainingVideos: value.trainingVideos,
        trainingCrossSellers: value.trainingCrossSellers,
        trainingSteps: value.trainingSteps,
      };
      if (isDrag) {
        param.training.states = value.training.states;
        param.training.isPublished = value.training.published;
      }

      //await trainingClient.updateTrainingProduct(value.id, param);
      await trainingClient
        .updateTrainingProductAndProTips(value.id, param)
        .finally(() => (this.isUpdating = false));

      // await this.updateNewTrainingList()
      this.$emit("updateNewTrainingList");
    },
    /**
     * sort table
     */
    async sortList(sortValue) {
      const info = this.formatSortValue(sortValue);
      this.handleName(info["_sort-by"], info.isAscend);
      // const sortInfo = this.formatSortValue(sortValue)
      // let params = {
      //   _index: 1,
      //   _limit: this.currentNum,
      //   ...sortInfo
      // }
      // await this.fetchData(params)
    },
    handleName(sorter, isAscend) {
      let index;
      if (sorter === "name") {
        index = 1;
      } else if (sorter === "hero") {
        index = 3;
      } else if (sorter === "upc") {
        index = 2;
      } else if (sorter === "sku") {
        index = 5;
      } else if (sorter === "modified") {
        index = 6;
      }
      // console.log("开始====>", this.tableDate)
      this.sortData(index, isAscend);
      // console.log("总数====>", this.tableDate)
      this.formatTabledataSort(this.tableDate);
    },
    sortData(index, ascend) {
      // console.log(23333333, index, ascend)
      if (index == 1) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.name.localeCompare(b.name);
          } else {
            return b.name.localeCompare(a.name);
          }
        });
      } else if (index == 6) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            if (a.updatedAt != null && b.updatedAt != null) {
              return a.updatedAt.localeCompare(b.updatedAt);
            }
            if (a.updatedAt != null && b.updatedAt == null) {
              return a.updatedAt.localeCompare(b.createdAt);
            }
            if (a.updatedAt == null && b.updatedAt != null) {
              return a.createdAt.localeCompare(b.updatedAt);
            }
          } else {
            if (b.updatedAt != null && a.updatedAt != null) {
              return b.updatedAt.localeCompare(a.updatedAt);
            }
            if (b.updatedAt != null && a.updatedAt == null) {
              return b.updatedAt.localeCompare(a.createdAt);
            }
            if (b.updatedAt == null && a.updatedAt != null) {
              return b.createdAt.localeCompare(a.updatedAt);
            }
          }
        });
      } else if (index == 3) {
        this.tableDate.sort((a, b) => {
          const heroA = a.training && a.training.hero ? a.training.hero : false;
          const heroB = b.training && b.training.hero ? b.training.hero : false;
          if (ascend) {
            if (heroA && !heroB) {
              return -1;
            }
            if (!heroA && heroB) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (heroA && !heroB) {
              return 1;
            }
            if (!heroA && heroB) {
              return -1;
            } else {
              return 0;
            }
          }
        });
      } else if (index == 2) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.barcodes[0].code.localeCompare(b.barcodes[0].code);
          } else {
            return b.barcodes[0].code.localeCompare(a.barcodes[0].code);
          }
        });
      } else if (index === 5) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.sku.localeCompare(b.sku);
          } else {
            return b.sku.localeCompare(a.sku);
          }
        });
      }
    },
    formatTabledataSort(list) {
      const publicList =
        list.filter(
          (item) =>
            item.public &&
            item.training &&
            item.training.published &&
            item.activeText === "View"
        ) || [];
      const publicFalseList =
        list.filter(
          (item) =>
            !item.public &&
            item.training &&
            item.training.published &&
            item.activeText === "View"
        ) || [];
      const draftList =
        list.filter(
          (item) =>
            !item.public &&
            item.training &&
            !item.training.published &&
            item.activeText === "View"
        ) || [];
      const noTrainingProduct =
        list.filter(
          (item) => !item.public && !item.training && item.activeText !== "View"
        ) || [];
      // console.log(111111, publicList, publicFalseList, draftList, noTrainingProduct)
      this.tableDate = publicList
        .concat(publicFalseList)
        .concat(draftList)
        .concat(noTrainingProduct);
    },
    formatSortValue(value) {
      // this.initSort()
      if (value === "name") {
        this.isNameSort = !this.isNameSort;
        this.isHeroSort = false;
        this.isSkuSort = false;
        this.isUpcSort = false;
        this.isModifiedSort = false;
        return {
          "_order-by": this.isNameSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isNameSort,
        };
      }
      if (value === "hero") {
        this.isHeroSort = !this.isHeroSort;
        this.isNameSort = false;
        this.isSkuSort = false;
        this.isUpcSort = false;
        this.isModifiedSort = false;
        return {
          "_order-by": this.isHeroSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isHeroSort,
        };
      }
      if (value === "sku") {
        this.isSkuSort = !this.isSkuSort;
        this.isNameSort = false;
        this.isHeroSort = false;
        this.isUpcSort = false;
        this.isModifiedSort = false;
        return {
          "_order-by": this.isSkuSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isSkuSort,
        };
      }
      if (value === "upc") {
        this.isUpcSort = !this.isUpcSort;
        this.isNameSort = false;
        this.isHeroSort = false;
        this.isSkuSort = false;
        this.isModifiedSort = false;
        return {
          "_order-by": this.isUpcSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isUpcSort,
        };
      }
      if (value === "modified") {
        this.isModifiedSort = !this.isModifiedSort;
        this.isNameSort = false;
        this.isHeroSort = false;
        this.isSkuSort = false;
        this.isUpcSort = false;
        return {
          "_order-by": this.isModifiedSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isModifiedSort,
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.brand-product-new__main {
  .training-page-title {
    width: 100%;
    height: 94px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .training-page-table {
    width: 1200px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      width: 100%;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }
    .training-table-style tr {
      width: 100%;
      position: relative;
    }
    .training-table-style tr:nth-child(2n) {
      background-color: rgba(211, 228, 206, 0.06);
    }
    .training-table-style th {
      border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 16px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
      color: #262626;
    }
    .training-table-style td {
      height: 56px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 16px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training-table-style tr,
    .training-table-style td.move:hover {
      cursor: move;
    }
    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }
    .name-tab-style {
      position: relative;
      span {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
    .data-tab-style {
      //position: relative;
    }
  }
  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }
  .title-button {
    width: 415px;
    text-align: right;
    position: relative;
    //display: flex;
    //justify-content: space-between;
    .btn-style {
      width: 106px;
      height: 30px;
      font-family: "Proxima Nova";
      //line-height: 20px;
      //padding: 5px 16px;
      font-size: 14px;
      border: 2px solid #e1644c;
      border-radius: 7px;
      color: #e1644c;
      background: #ffffff;
    }
    .btn-style:nth-child(2) {
      margin: 0 26px;
      width: 140px;
    }
    .btn-style:nth-child(3) {
      background-color: #e19387;
      border: 2px solid #e19387;
      color: #ffffff;
    }
    .btn-style:hover {
      color: white;
      background: #e1644c;
    }
    .active-share-button {
      background: #9fd19a;
      border-color: #9fd19a;
      color: #ffffff;
    }
  }
  .qr-code-style {
    width: 150px;
    height: 150px;
    margin: 20px auto;
    position: absolute;
    left: 0;
    right: 0;
  }
}
.training-table-style tr:hover {
  .drag-three-circular {
    position: absolute;
    .three-circular {
      position: relative;
      width: 28px;
      height: 28px;
      color: #595959;
      //margin: 50px;
      left: -971px;
      top: 15px;
      z-index: 5;
    }
    .three-circular:after {
      content: "•••";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      font-size: 12px;
      letter-spacing: 2px;
      margin-top: 2px;
    }
  }
  //.no-table-data {
  //  width: 100%;
  //  height: 270px;
  //  text-align: center;
  //  color: #bfbfbf;
  //  font-size: 16px;
  //  padding-top: 80px;
  //  position: relative;
  //  left: 350px;
  //}
}
.no-table-data {
  width: 100%;
  height: 270px;
  text-align: center;
  color: #bfbfbf;
  font-size: 16px;
  padding-top: 80px;
  position: relative;
  left: 350px;
}
</style>
