<template>
  <div class="admin-retailer-setttings">
    <p class="admin-retailer-setttings__title">
      {{ $t("Retailers.RetailerSettings.beautyFluent") }}
    </p>
    <a-row class="admin-retailer-setttings__main-block admin-retailer-setttings__first-row">
      <a-col :span="6" class="admin-retailer-setttings__left-block">
        <p class="admin-retailer-setttings__left-text">
          {{ $t("Retailers.RetailerSettings.retailerType") }}
        </p>
      </a-col>
      <a-col :span="10" class="admin-retailer-setttings__right-block">
        <p class="admin-retailer-setttings__text">
          {{ upperName(retailerType) }}
        </p>
      </a-col>
      <a-col :span="8" class="admin-retailer-setttings__action-col">
        <a
          v-if="isAdmin"
          :disabled="retailerType && retailerType === 'preferred'"
          class="admin-retailer-setttings__btn button"
          @click="clickToShowChangeDialog"
        >
          {{ $t("Retailers.RetailerSettings.changeType") }}
        </a>
      </a-col>
    </a-row>
    <retailer-change-type-dailog
      ref="retailerSettingsChangeType"
      :retailer-type="retailer.id"
      @succss="refreshRetailer"
    />
  </div>
</template>
<script>
import RetailerChangeTypeDailog from "@/components/retailers/settings/RetailerChangeTypeDailog"

export default {
  components: {
    RetailerChangeTypeDailog
  },
  computed: {
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    retailerType() {
      console.log(this.retailer.grades)
      if (this.retailer.grades) {
        console.log(this.retailer.grades[0])
      }
      return this.retailer.grades && this.retailer.grades[0].type && this.retailer.grades[0].type.id
    }
  },
  methods: {
    clickToShowChangeDialog() {
      this.$refs["retailerSettingsChangeType"].openDialog(this.retailerType)
    },
    refreshRetailer() {
      this.$store.dispatch("retailer/fetchRetailer", this.retailer.id)
    },
    upperName(value) {
      console.log(value)
      if (value && value.toString().length > 0) {
        return (
          value
            .toString()
            .charAt(0)
            .toUpperCase() + value.toString().slice(1)
        )
      }
      return value
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-retailer-setttings {
  padding-top: 58px;
  width: 800px;
  font-family: Proxima;
  &__title {
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.32px;
    line-height: 18px;
    padding-bottom: 16px;
  }
  &__line {
    color: #4a4a4a;
    font-size: 1em;
    line-height: 1.5;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  &__first-row {
    border-top: 1px solid #ddd;
  }
  &__main-block {
    width: 100%;
    display: flex;
    padding: 10px 0px 8px 35px;
    border-bottom: 1px solid #ddd;
    min-height: 50px;
  }
  &__left-block {
    padding: 0px !important;
    display: flex;
    align-items: center;
  }
  &__right-block {
    padding: 0px !important;
    display: flex;
    align-items: center;
  }
  &__left-text {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-bottom: 0 !important;
    text-align: left;
  }
  &__text {
    margin-bottom: 0px !important;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    color: #333;
  }
  &__action-col {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  &__btn {
    background-color: #000 !important;
    border-color: transparent;
    color: #fff !important;
    min-width: 124px;
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    cursor: pointer;
    -webkit-box-pack: center;
    justify-content: center;
    -ms-flex-pack: center;
    padding-bottom: calc(0.375em - 1px);
    padding-left: 0.75em;
    padding-right: 0.75em;
    padding-top: calc(0.375em - 1px);
    text-align: center;
    white-space: nowrap;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
  }
}
</style>
