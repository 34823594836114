import axios from "@/api/axios";
import trainingClient from "@/api/training-client";
export default {
  createProduct(product) {
    return axios.post("/products", JSON.stringify(product));
  },
  removeProduct(id) {
    const product = { active: false };
    return axios.put(
      `/products/${id}?_skipNulls=true`,
      JSON.stringify(product)
    );
  },
  updateProductOld(product) {
    return axios.put(`/products/${product.id}`, JSON.stringify(product));
  },
  updateProduct(product) {
    return trainingClient
      .getBrandTrainingProTips(product.id)
      .catch((error) => {
        if (error.status == 404) {
          return null;
        } else {
          throw error;
        }
      })
      .then((productTraining) => {
        return this.updateProductOld(product).then((saveProduct) => {
          return productTraining == void 0
            ? saveProduct
            : trainingClient
                .updateBrandTrainingProTips(product.id, productTraining)
                .then(() => {
                  //console.log(saveTraining);
                  return saveProduct;
                });
        });
      });
  },
  updateProductIngredients(ingredients, productId) {
    return axios.put(
      `/products/${productId}/ingredients`,
      JSON.stringify(ingredients)
    );
  },
  getProduct(id) {
    return axios.get(`/products/${id}`);
  },
  getGuestProduct(id) {
    return axios.get(`/guest/products/${id}`);
  },
  getProductsByParams(params) {
    return axios.get("/products", { params });
  },
  getProductsPageDataByParams(params) {
    return axios.get("/product/list", { params });
  },
  getCart(params) {
    return axios.get("/cart", { params: { ...params } });
  },
  updateCart(cartProducts, params) {
    return axios.put("/cart", JSON.stringify(cartProducts), {
      params: { ...params },
    });
  },
  getIngredients(ingredientName, page) {
    return axios.get("/refdata/ingredients", {
      params: { name: ingredientName, "page-index": page },
    });
  },
  updateBenefits(productId, param) {
    return axios.put(`/products/${productId}/benefits`, JSON.stringify(param));
  },
};
