<template>
  <div ref="orderStatusDelivery" class="order-status-delivery-detail__main-modal">
    <a-modal
      v-model="deliveryVisible"
      width="342px"
      :footer="null"
      :title="'Delivery Details'"
      :closable="true"
      :centered="true"
      :mask-closable="false"
      :get-container="() => $refs.orderStatusDelivery"
    >
      <div class="modal-order-delivery-content">
        <div class="select-or-input">
          <p>Carrier*</p>
          <a-select
            v-model="carrierValue"
            :get-popup-container="triggerNode => triggerNode.parentNode"
            style="width: 294px"
            placeholder="Select shipping carrier"
          >
            <a-select-option v-for="item in carrierList" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="select-or-input">
          <p>Tracking Information*</p>
          <a-input v-model="trackingNumber" placeholder="Enter tracking number" />
        </div>
        <div class="select-or-input">
          <p>Actual Ship Date*</p>
          <a-date-picker
            v-model="shipDate"
            format="YYYY-MM-DD"
            placeholder="MM/DD/YYYY"
            :get-calendar-container="triggerNode => triggerNode.parentNode"
            style="width: 294px"
            @change="onChangeshipDate"
          />
        </div>
        <!--        <div class="select-or-input">-->
        <!--          <p>Total Carton Quantity*</p>-->
        <!--          <a-input v-model="totalCarton" placeholder="Enter Caton Count" />-->
        <!--        </div>-->
        <div class="footer-button">
          <span class="apply-button" @click="saveData">Save</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import OrderClient from "@/api/order-client"
import constants from "@/config.js"
export default {
  name: "DeliveryDetailModal",
  data() {
    return {
      deliveryVisible: false,
      shipDate: null,
      carrierValue: null,
      totalCarton: null,
      trackingNumber: null,
      order: {}
    }
  },
  computed: {
    carrierList() {
      return this.$store.getters["reference/shippingProvider"].filter(item => item.air)
    }
  },
  methods: {
    openDialog(order) {
      this.deliveryVisible = true
      this.order = order
      if (this.order.airShipments && this.order.airShipments.length !== 0) {
        const list = this.order.airShipments.filter(item => item.orderStatus.id === "delivered")
        if (list && list.length !== 0) {
          this.trackingNumber = list[0].trackingId || null
          this.shipDate = list[0].actualShipDate || null
          this.carrierValue = list[0].provider.id || null
        }
      }
    },
    handleChange() {},
    onChangeshipDate(value, dateStr) {
      console.log("date value", value, dateStr)
      this.shipDate = dateStr
    },
    async saveData() {
      this.showError = true
      let params
      const priv = this.carrierList.filter(item => item.id === this.carrierValue)
      // if (priv[0].air) {
      params = [
        {
          provider: {
            id: priv[0].id,
            name: priv[0].name
          },
          actualShipDate: this.shipDate,
          orderStatus: {
            id: constants.orderStatus.delivered
          },
          trackingId: this.trackingNumber
          // totalCartonQuantity: Number(this.totalCarton)
        }
      ]
      await OrderClient.updateAirShipment(this.order.id, params)
      await this.$store.dispatch("order/getOrder", this.$route.params.id)
      this.deliveryVisible = false
    }
  }
}
</script>

<style lang="scss">
.order-status-delivery-detail__main-modal {
  p {
    margin-bottom: 0;
  }
  .modal-order-delivery-content {
  }
  .select-or-input {
    margin-bottom: 20px;
  }
  .footer-button {
    .apply-button {
      display: inline-block;
      width: 88px;
      height: 30px;
      background: #ff6132;
      color: white;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      margin-left: 100px;
    }
  }
}
</style>
