<template>
  <a-modal v-model="warnMessage" 
    width="423px" 
    :title="null" 
    :closable="true" 
    :footer="null" 
    dialogClass="admin-warn-message"
    @cancel="hideDialog">
    <a-row>
      <a-icon type="exclamation-circle" style="color: #FF6132; font-size: 20px;" />
      <span class="admin-message-warn-dialog__message">{{ message }}</span>
    </a-row>
  </a-modal>
</template>

<script>
export default {
  data(){
    return {
      warnMessage: false,
      message: ''
    }
  },
  methods: {
    openDialog(msg) {
      this.warnMessage = true
      this.message = msg
    },
    hideDialog() {
      this.warnMessage = false
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-message-warn-dialog {
  &__message {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
    margin-left: 11px;
  }
}
</style>
<style lang="scss">
.admin-warn-message .ant-modal-content .ant-modal-close .ant-modal-close-x{
    width: 36px;
    height: 36px;
    font-size: 14px;
    line-height: 36px;
}
</style>