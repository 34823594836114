import CoreEntity from "@/models/CoreEntity";

export default class RetailerTerritory extends CoreEntity {
  constructor(model) {
    super(model);
    this.retailerPrivateId = 0;
    this.code = "";
    this.name = "";
    if (model) {
      this.retailerPrivateId = model.retailerPrivateId;
      this.code = model.code;
      this.name = model.name;
    }
  }

  toModel() {
    const data = super.toModel();
    data.retailerPrivateId = this.retailerPrivateId;
    data.code = this.code;
    data.name = this.name;
    return data;
  }
}
