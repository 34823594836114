<template lang="pug">
.old-retailer-order-status-bar
  //- span.old-retailer-order-status-bar__icon
  //-   img(src="@/assets/img/orders/print-icon.svg")
  //- span.old-retailer-order-status-bar__item {{$t('Components.Retailers.Order.OrderStatus.printOrder')}}
  //- span(v-if="this.isAdmin || (!this.isAdmin && pendingStatus)")
    span.old-retailer-order-status-bar__icon
      img(src="@/assets/img/orders/cancel-order-icon.svg")
    span.old-retailer-order-status-bar__item(@click="updateStatus") {{$t('Components.Retailers.Order.OrderStatus.cancelOrder')}}
  span.old-retailer-order-status-bar__item(v-if="this.isAdmin")
    vs-dropdown.dropIcon
      span.old-retailer-order-status-bar__icon
        img(src="@/assets/img/orders/shipped-icon.svg")
      span.old-retailer-order-status-bar__item Mark As
        a.a-icon.markAsIcon(href.prevent='')
          vs-icon(icon='expand_more')
        vs-dropdown-menu
          vs-dropdown-item(@click="onClickStatus(orderStatus.pending)")
            | Pending
          vs-dropdown-item(@click="onClickStatus(orderStatus.confirmed)")
            | Confirmed
          vs-dropdown-item(@click="onClickStatus(orderStatus.supplyShipped)")
            | Supply Shipped
          vs-dropdown-item(@click="onClickStatus(orderStatus.warehouse)")
            | Warehouse
          vs-dropdown-item(@click="onClickStatus(orderStatus.deliverShipped)")
            | Delivery Shipped
          vs-dropdown-item(@click="onClickStatus(orderStatus.delivered)")
            | Delivered
          vs-dropdown-item(@click="onClickStatus(orderStatus.paidByRetailer)")
            | Paid by Retailer
          vs-dropdown-item(@click="onClickStatus(orderStatus.paidToBrand)")
            | Paid to Brand
          vs-dropdown-item(v-if="this.isAdmin" @click="onClickStatus(orderStatus.deleted)")
            | Deleted
  span(v-if="this.isAdmin && !showAddTrackingInfo")
    span.old-retailer-order-status-bar__icon.add-tracking
        img(src="@/assets/img/orders/circle-tick.svg")
    span.old-retailer-order-status-bar__item(@click="showTrackingInfo") Add Tracking Info

  .old-retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.deleted") {{$t('Components.Retailers.Order.OrderStatus.deleteOrder')}}
  .old-retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.pending && updatedStatusDate") {{$t('Components.Retailers.Order.OrderStatus.orderCreatedOn')}} {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .old-retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.confirmed && updatedStatusDate") Order confirmed on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .old-retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.supplyShipped && updatedStatusDate") Order shipped for supply on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .old-retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.warehouse && updatedStatusDate") Order in warehouse on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .old-retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.deliverShipped && updatedStatusDate") Order shipped for deliver on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .old-retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.delivered && updatedStatusDate") Order delivered on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .old-retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.paidByRetailer && (role === 'retailer' || role === 'admin') && updatedStatusDate") Payment made on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .old-retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.paidToBrand && (role === 'brand'|| role === 'admin') && updatedStatusDate") Payment made on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .old-retailer-order-status-bar__block
    .order-status-bar.completed(v-for="item in order.lifecycle")
      img(v-if="item.complete" src="@/assets/img/orders/status-bar-lit.svg")
      img(v-else src="@/assets/img/orders/status-bar-unlit.svg")
      span(v-if="(item.status.id==orderStatus.paidByRetailer || item.status.id==orderStatus.paidToBrand) && role != 'admin'") Paid
      span(v-else) {{item.status.name}}

</template>

<script>
import constants from "@/config.js"
export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      orderStatus: constants.orderStatus
    }
  },
  computed: {
    showAddTrackingInfo() {
      if (this.order.airShipments && this.order.oceanShipments) {
        return this.order.airShipments.length + this.order.oceanShipments.length == 2
      } else {
        return (
          (this.order.airShipments && this.order.airShipments.length == 2) ||
          (this.order.oceanShipments && this.order.oceanShipments.length == 2)
        )
      }
    },
    role() {
      return this.$store.getters["user/role"]
    },
    pendingStatus() {
      return this.order.status && this.order.status.id === "pending"
    },
    statusItems() {
      return this.order.lifecycle && Object.values(this.order.lifecycle).map(item => item.status)
    },
    updatedStatusDate() {
      if (this.order.lifecycle) {
        const currentStatus = this.order.lifecycle.filter(
          item => item.status.id === this.order.status.id
        )
        if (currentStatus[0] && currentStatus[0].transitionedAt) {
          return new Date(currentStatus[0].transitionedAt)
        } else {
          return null
        }
      }
      return null
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  methods: {
    warningOldHistory() {
      this.$message.warning("Old orders cannot be modified")
    },
    updateStatus() {
      this.$emit("statusUpdated")
    },
    formattedDate() {
      return this.order.createdAt && new Date(this.order.createdAt)
    },
    filledStatus(statusId) {
      const statusItemsNames = this.statusItems.map(item => item.id)
      let indexOfActiveStatus = statusItemsNames.indexOf(this.order.status.id)
      if (this.role === "brand" && this.order.status.id === this.orderStatus.paidByRetailer) {
        indexOfActiveStatus = statusItemsNames.indexOf(this.orderStatus.paidToBrand) - 1
      }
      return (
        statusItemsNames.indexOf(statusId) <= indexOfActiveStatus ||
        this.order.status.id == this.orderStatus.paidToBrand
      )
    },
    onClickStatus() {
      this.warningOldHistory()
      // this.$emit("statusUpdated", status)
    },
    showTrackingInfo() {
      this.warningOldHistory()
      // this.$emit("showTrackingInfo")
    }
  }
}
</script>

<style lang="sass" scoped>
.markAsIcon
  position: absolute !important
.old-retailer-order-status-bar
  .old-retailer-order-status-bar__block
    display: flex
    padding-bottom: 45px
    padding-top: 8px
    .order-status-bar
      display: flex
      flex-direction: column
      align-items: self-start
      span
        width: 100%
        font-size: 14px
        color: #333
        padding-top: 16px
        text-align: center
    .order-status-bar.completed
      svg
        .order-status-bar-order-status-bar-inactive
          fill: #FFFBFA !important
  &__statuses
    max-width: 1180px
    padding-top: 5px
    padding-bottom: 40px
    margin: 0 auto
  &__item
    padding-right: 15px
    cursor: pointer
  &__icon
    img
      margin-bottom: -5px
      margin-right: 3px
      cursor: pointer
  &__creation-date
    padding-top: 52px
    color: #212B36
    font-size: 14px
    font-weight: 300
    display: block
  &__line-container
    max-width: 176px
  .disabled
    opacity: 0.6
    pointer-events: none
.dropIcon
  position: absolute !important
.add-tracking
  margin-left: 100px
</style>
