export default {
  // dateString : 01/22 MM/YY
  isDateExpired(dateString) {
    let expirationMonth = parseInt(dateString.split("/")[0]);
    let expirationYear = parseInt(dateString.split("/")[1]);
    let now = new Date();
    let nowMoth = now.getMonth();
    let nowYear = now.getFullYear() % 100;
    if (
      expirationYear < nowYear ||
      (expirationYear === nowYear && expirationMonth < nowMoth + 1)
    ) {
      return true;
    } else {
      return false;
    }
  },
  checkCardCode(cardCode) {
    return /^[0-9]{3,4}$/.test(cardCode);
  },
  /**
   *  dateString : MM/YY
   *  year is just 2 digit
   * @param dateString
   * @returns {boolean}
   */
  checkExpiredDate(dateString) {
    let regResult = /(0[1-9]|1[0-2])\/([0-9]{2})/.test(dateString);
    if (regResult) {
      let year = dateString.split("/")[1];
      return year < 100;
    }
    return regResult;
  },
  checkCardNumber(value) {
    return /^[0-9]{1,16}$/.test(value);
  },
  getExpiredDate(item, info) {
    if (item.expirationDate === "XXXX") return item.expirationDate;
    if (item) {
      let now = new Date();
      return `${item.isExpired ? info : ""}${item.expirationDate.substring(
        0,
        2
      )}/${Math.floor(now.getFullYear() / 100)}${item.expirationDate.substring(
        3
      )}`;
    }
    return "";
  },
};
