import SimpleDescriptionEntity from "@/models/SimpleDescriptionEntity"
import ProductTrainingStep from "@/models/ProductTrainingStep"
import ProductTrainingBestUsedFor from "@/models/ProductTrainingBestUsedFor"

export default class ProductCategory extends SimpleDescriptionEntity {
  constructor(model) {
    super(model)
    this.trainingSteps = []
    this.bestUsedFors = []
    if (model) {
      this.trainingSteps = model.trainingSteps
        ? model.trainingSteps.map(s => new ProductTrainingStep(s))
        : null
      this.bestUsedFors = model.bestUsedFors
        ? model.bestUsedFors.map(b => new ProductTrainingBestUsedFor(b))
        : null
    }
  }

  toModel() {
    const data = super.toModel()
    data.trainingSteps = this.trainingSteps ? this.trainingSteps.map(s => s.toModel()) : null
    data.bestUsedFors = this.bestUsedFors ? this.bestUsedFors.map(b => b.toModel()) : null
    return data
  }
}
