<template lang="pug">
section.page-placeholder.hero.is-medium.is-light.is-bold
  .hero-body
    .container
      p.title {{ title }} 
      p.subtitle {{ subtitle }}
</template>

<style lang="sass">
.page-placeholder
  min-height: calc(100vh - 151px)

  .hero-body
    justify-content: center
    align-items: center
    display: flex
</style>

<script>
export default {
  name: "PagePlaceholder",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: () => "Placeholder"
    }
  }
}
</script>
