<template>
  <div class="filter_btn">
    <a-button
      :type="item.checked ? 'default' : 'dashed'"
      :class="[item.checked ? 'choosed' : 'no-choosed']"
      size="small"
      @click="switchTag(item)"
    >
      <svg-icon v-if="!item.checked" icon-class="add" class="svg-icon-base" />
      {{ item.name }}
      <a-icon v-if="item.checked" type="close" fill="#595959" />
    </a-button>
  </div>
</template>

<script>
export default {
  name: "FilterBtn",
  props: {
    item: {
      type: Object, default: () => {
      }
    }
  },
  methods: {
    async switchTag(item) {
      item.checked = !item.checked
      this.$emit("update:item", item)
      this.$emit("itemClick", item)
    }
  }
}
</script>
<style lang="scss" scoped>
.filter_btn {
  display: inline-block;
  margin: 0 7px 7px 0;

  .choosed {
    background-color: #4a8a5d !important;
    color: #fff !important;
    outline: none;
  }

  .ant-btn {
    font-size: 12px;
  }
}
</style>
