<template>
  <div ref="downloadPayment" class="download-estimated-payment__main">
    <a-modal
      v-model="downloadVisible"
      width="585px"
      :footer="null"
      :closable="true"
      :mask-closable="false"
      :get-container="() => $refs.downloadPayment"
      :body-style="noPadding"
      @cancel="handleCancel"
    >
      <span slot="title" class="estimated-payment__title"> View Estimated Payment </span>
      <div class="estimated-payment__content">
        <PaymentCount :order="order" :retailer-name="retailer.name"></PaymentCount>
      </div>
      <div class="estimated-payment__divider"></div>
      <a-row style="padding: 14px 24px;">
        <a-col :span="24" style="text-align: right;">
          <a-button
            class="estimated-payment__btn"
            :style="`background-color: ${btnColor}; border-color: ${btnColor}`"
            :loading="isDownloadLoading"
            @click="clickToDownload"
            >Download</a-button
          >
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import {
  getOrderBrandPayAmount,
  getOrderRetailerPayAmount,
  downloadCsvData,
  priceFormat
} from "@/utils/validate"
import PaymentCount from "./orderComponent/orderPaymentCount"
export default {
  name: "EstimatedPaymentModal",
  components: {
    PaymentCount
  },
  data() {
    return {
      priceFormat,
      downloadVisible: false,
      order: {},
      btnColor: "",
      retailer: {},
      noPadding: {
        padding: "0px"
      },
      retailerTotalCharges: 0,
      brandPayment: 0,
      isDownloadLoading: false,
      retailerMarketCharge: undefined,
      retailerDamageCharge: undefined,
      retailerTesterCharge: undefined,
      retailerAdditionCharge: undefined,
      brandTransactionCharge: undefined,
      brandFullfillCharge: undefined,
      brandShippingCharge: undefined,
      brandAdditionCharge: undefined
    }
  },
  methods: {
    openDialog(order, btnColor, retailer) {
      this.order = order
      this.btnColor = btnColor
      this.retailer = retailer
      this.downloadVisible = true
      this.retailerTotalCharges = getOrderRetailerPayAmount(this.order)
      this.brandPayment = this.retailerTotalCharges - getOrderBrandPayAmount(this.order)
      this.initChargeItem(order)
    },
    handleCancel() {
      this.downloadVisible = false
    },
    priceFormatForCsv(price) {
      let priceStr = this.priceFormat(price)
      return priceStr.replace(/,/g, "，")
    },
    async clickToDownload() {
      await downloadCsvData(
        this.downloadStrForOrder(this.order),
        `brand_order_list_${new Date().getTime()}.csv`
      )
    },
    downloadStrForOrder(order) {
      const retailerToBrandData = this.getRetailerToBrandList(order)
      const brandToLandingData = this.getBrandToLandingList(order)
      let rTbText = retailerToBrandData.map(item => item.textValue).join(",")
      let rTbList = retailerToBrandData
        .map(item => this.priceFormatForCsv(item.payNumber))
        .join(",")
      let bTLText = brandToLandingData.map(item => item.textValue).join(",")
      let bTLList = brandToLandingData.map(item => this.priceFormatForCsv(item.payNumber)).join(",")
      let csvData = [`Retailer Name Order, SubTotal, ${rTbText}, Total, ${bTLText}, Your Payment`]
      if (order) {
        let dataStr = `${order.landingReferenceId}, ${this.priceFormatForCsv(
          order.calculatedWholesaleCostUsd
        )},${rTbList},${this.priceFormatForCsv(
          this.retailerTotalCharges
        )},${bTLList},${this.priceFormatForCsv(this.brandPayment)},`
        csvData.push(dataStr)
      }
      return csvData
    },
    initChargeItem(order) {
      if (order && order.charges && order.charges.length > 0) {
        order.charges.forEach(item => {
          if (item.chargeType.id === "damages") {
            this.retailerDamageCharge = item
          } else if (item.chargeType.id === "testers") {
            this.retailerTesterCharge = item
          } else if (item.chargeType.id === "marketing") {
            this.retailerMarketCharge = item
          } else if (item.chargeType.id === "custom") {
            if (item.ownedBy.id === "brand") {
              this.retailerAdditionCharge = item
            } else if (item.ownedBy.id === "landing") {
              this.brandAdditionCharge = item
            }
          } else if (
            item.chargeType.id === "commission" ||
            item.chargeType.id === "transaction-fee"
          ) {
            this.brandTransactionCharge = item
          } else if (item.chargeType.id === "fulfillment") {
            this.brandFullfillCharge = item
          } else if (item.chargeType.id === "shipping") {
            this.brandShippingCharge = item
          }
        })
      }
    },
    getRetailerToBrandList(order) {
      if (!order.charges || order.charges.length === 0) {
        return []
      }
      const list = order.charges.filter(
        item =>
          (item.ownedBy.id === "retailer" && item.paidBy.id === "brand") ||
          (item.ownedBy.id === "brand" && item.paidBy.id === "retailer")
      )
      if (list && list.length === 0) {
        return []
      }
      return list.map(value => {
        if (value.chargeType.id === "custom" && value.ownedBy.id === "retailer") {
          value.textValue = "Other Terms"
          value.payNumber = value.calculatedCostUsd
        } else if (value.chargeType.id === "custom" && value.ownedBy.id === "brand") {
          value.textValue = "Additional Charge"
          value.payNumber = value.calculatedCostUsd
        } else {
          value.textValue = value.chargeType.name
          value.payNumber = value.calculatedCostUsd
        }
        return value
      })
    },
    getBrandToLandingList(order) {
      if (order.charges && order.charges.length !== 0) {
        const list = order.charges.filter(
          item =>
            (item.ownedBy.id === "brand" && item.paidBy.id === "landing") ||
            (item.ownedBy.id === "landing" && item.paidBy.id === "brand")
        )
        return list.map(value => {
          if (value.chargeType.id === "custom" && value.ownedBy.id === "brand") {
            value.textValue = "Other Charge"
            if (value.paymentType.id === "credit") {
              value.payNumber = value.calculatedCostUsd
            } else {
              value.payNumber = value.calculatedCostUsd
            }
          } else {
            value.textValue = value.chargeType.name
            value.payNumber = value.calculatedCostUsd
          }
          return value
        })
      }
      return []
    }
  }
}
</script>

<style lang="scss">
.estimated-payment {
  &__title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
  }
  &__content {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    padding: 6px 65px;
    color: #262626;
    &__title {
      color: #8c8c8c;
    }
  }
  &__divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d9d9d9;
  }
  &__btn {
    box-sizing: border-box;
    border-radius: 2px !important;
    align-items: center;
    padding: 5px 16px !important;
    color: white !important;
  }
}
.download-estimated-payment__main .ant-modal-root .ant-modal .ant-modal-content .ant-modal-header {
  padding: 12px 24px !important;
}
.download-estimated-payment__main .ant-modal-close-x {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
}
</style>
