<template>
  <div ref="membershipExpiringDialog" class="cancel-subscription-dialog-new__main">
    <a-modal
      v-model="isCardModalActive"
      :footer="null"
      :destroy-on-close="true"
      :closable="false"
      :mask-closable="false"
      width="520px"
      :get-container="() => $refs.membershipExpiringDialog"
    >
      <div class="cancel-subscription-new">
        <img
          src="@/assets/webflowAssets/Modal/dots_pattern.svg"
          alt="dots_pattern"
          class="dots_pattern"
        />
        <img src="@/assets/webflowAssets/Modal/ellipse.svg" alt="ellipse" class="ellipse" />
        <p style="font-size: 25px">Your Membership Is Expiring</p>
        <p>
          Thank you for using Landing! <b>Basic memberships will no longer be supported</b> on our
          platform as of January 1, 2023. After that date, you will need to subscribe to continue
          accessing our tools.
        </p>
        <div class="cancel-dialog-btn-style">
          <span class="continue-cancel-style" @click="closeModal">CONTINUE</span>
          <span class="close-dialog-style" @click="doCancelSubscription">Start Free Trial</span>
        </div>
        <div class="footer-card-extra">
          <p class="footer-card-extra-content">
            <!--                    <span>Interested in a <b>FREE trial</b>?</span-->
            <!--                    ><a href="#">Get in touch with our team</a>-->
          </p>
        </div>
      </div>
    </a-modal>
    <addNewCardDialog
      ref="addNewCardDialog"
      @openAddMoreCardDialog="openAddMoreCardDialog"
    ></addNewCardDialog>
    <cardListDialog
      ref="cardListDialog"
      @openAddPaymentDialog="openAddPaymentDialog"
    ></cardListDialog>
  </div>
</template>

<script>
import addNewCardDialog from "@/components/brands/settings/addPaymentCardDialog"
import cardListDialog from "@/components/brands/settings/addMoreCardDialog"

export default {
  name: "MembershipExpiringDialog",
  components: {
    addNewCardDialog,
    cardListDialog
  },
  data() {
    return {
      isCardModalActive: false,
      fromRouter: undefined
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    }
  },
  created() {
    if (this.$route.query.isClick) {
      this.isCardModalActive = true
    }
  },
  beforeRouteEnter(to, from, next) {
    this.fromRouter = from.name
    next()
  },
  methods: {
    openAddMoreCardDialog() {
      const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
      this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    },
    openAddPaymentDialog() {
      this.$refs["addNewCardDialog"].openAddCardDialog()
    },
    openDialog() {
      this.isCardModalActive = true
    },
    closeModal() {
      // 2022-11-3 add new logic when route is not brandSettingsSubscriptionBF and brandSettingsSubscription
      if (
        this.fromRouter !== "brandSettingsSubscriptionBF" ||
        this.fromRouter !== "brandSettingsSubscription"
      ) {
        this.$router.push({
          name: "brandMain"
        })
      }
      this.isCardModalActive = false
    },
    async doCancelSubscription() {
      if (this.$route.name === "membershipExpiringDialog") {
        this.$refs["cardListDialog"].openAddCardDialog()
      } else {
        this.$emit("addCardList")
      }
      this.isCardModalActive = false
      // if (this.data) {
      // let params = []
      // params.push({
      //   "unsubscribeTime": moment().format('yyyy-MM-DD'),
      //   "isAutoRenewal":false,
      //   "type": this.data.type,
      //   "subscriptionId": this.data.subscriptionId,
      //   "paidAmountUsd": this.data.paidAmountUsd,
      //   "startDate": this.data.startDate,
      //   "endDate": this.data.endDate
      // })
      // await this.$store.dispatch("brand/updateSubscriptionPlan", params)
      // const param = {
      //   mode: "cancel"
      // }
      // await this.$store.dispatch("brand/updateBrandSubscriptionNew", param)
      // // await this.$store.dispatch("brand/getBrandSubscriptionHistoryList")
      // await this.fetchBrandInfo()
      // this.isCardModalActive = false
      // }
    },
    async fetchBrandInfo() {
      const brandId =
        this.$route.name == "adminBrandSettingsSubscription"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      await this.$store.dispatch("brand/getBrand", brandId)
    }
  }
}
</script>

<style scoped lang="scss">
.cancel-subscription-dialog-new__main {
  .cancel-subscription-new {
    text-align: center;
    padding: 48px 0 0 0;
    position: relative;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      font-family: "Mr Eaves Mod OT";
    }
    .dots_pattern {
      position: absolute;
      top: 0;
      left: 0;
    }
    .ellipse {
      position: absolute;
      bottom: -84px;
      right: -24px;
    }
  }
  .continue-cancel-style {
    color: #000000;
    cursor: pointer;
  }
  .close-dialog-style {
    padding: 17px 35px;
    color: white;
    background: #f15a42;
    font-size: 14px !important;
    font-weight: bold;
    border-radius: 100px;
    margin-left: 30px;
    cursor: pointer;
  }
  .cancel-dialog-btn-style {
    width: 100%;
    margin-top: 50px;
  }
  .footer-card-extra {
    margin-top: 60px;
  }
  .footer-card-extra-content {
    font-size: 14px !important;
    a {
      color: #f15a42;
      margin-left: 10px;
    }
  }
}
</style>
