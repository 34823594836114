import axios from "@/api/axios";

export default {
  createAsset(file, clientId) {
    const formData = new FormData();
    formData.append("file", file);

    const headers = {
      "content-type": "multipart/form-data",
      "X-Client-ID": `${clientId}`,
    };

    return axios.post("/assets", formData, {
      headers: headers,
    });
  },
  getAsset(id) {
    return axios.get(`/assets/${id}`);
  },
  updateAsset(assetId) {
    const payload = { main: true };
    return axios.patch(`/assets/${assetId}`, JSON.stringify(payload));
  },
  deleteAsset(assetId) {
    return axios.delete(`/assets/${assetId}`);
  },
  getSwitchStatus() {
    return axios.get("/sys/feature-switch-status");
    // return axios.get("https://691a2a76-fb5f-49b7-9d94-3f1102ff009f.mock.pstmn.io/sys/feature-switch-status")
  },
};
