<template>
  <div class="landing-terms-transaction__main">
    <p class="transaction-style">Transaction Fee</p>
    <div v-if="isShowLoading" class="spin-style-admin">
      <a-spin tip="Loading..." />
    </div>
    <div v-else class="landing-terms-content">
      <div class="check-standard-style">
        <span class="title-second-style">Standard</span>
        <p
          v-if="
            transactionFStandard &&
              brand.transactionFeeTerms &&
              brand.transactionFeeTerms.length !== 0
          "
          class="table-terms-standard"
        >
          <span class="table-terms-standard-title">All Retailers</span>
          <span class="table-terms-standard-title">{{ transactionFeeValue }}%</span>
          <!--          <a-popconfirm-->
          <!--            title="Are you sure you want to delete this?"-->
          <!--            ok-text="Yes"-->
          <!--            cancel-text="No"-->
          <!--            @confirm="confirmDelete(transactionFStandard)"-->
          <!--          >-->
          <!--            <a-icon slot="icon" type="exclamation-circle" style="color: red" />-->
          <!--            <a-icon type="delete" :style="{ marginRight: '12px' }" />-->
          <!--          </a-popconfirm>-->
        </p>
      </div>
      <div>
        <div class="other-title">
          <span class="title-second-style">Other</span>
          <!--          <span class="title-edit-button" @click="otherEditVisible = true">-->
          <a-icon type="edit" @click="openDialog"></a-icon>
          <!--          </span>-->
        </div>
        <div v-if="transactionFList && transactionFList.length !== 0" class="table-terms-standard">
          <div v-for="(item, index) in transactionFList" :key="index" class="table-terms-other">
            <span class="table-terms-standard-title">{{ item.name }}</span>
            <div class="tab-right-item">
              <span>{{ item.transactionFeeValue }}%</span>
            </div>
            <a-popconfirm
              title="Are you sure you want to delete this?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="confirmOtherDelete(item)"
            >
              <a-icon slot="icon" type="exclamation-circle" style="color: red" />
              <a-icon
                type="delete"
                class="tab-right-item"
                :style="{ margin: '17px 12px 0 100px' }"
              />
            </a-popconfirm>
          </div>
        </div>
      </div>
    </div>
    <div>
      <a-modal
        :mask-closable="false"
        :centered="true"
        width="342px"
        :visible="otherEditVisible"
        :footer="null"
        @cancel="handleCancelAdd"
      >
        <p slot="title" class="logistic-modal-title">Logistics Terms</p>
        <div class="add-other-info">
          <div>Retailer</div>
          <a-select
            show-search
            :filter-option="filterOption"
            :get-popup-container="triggerNode => triggerNode.parentNode"
            style="width: 294px"
            placeholder="value"
            @change="handleSelectValue"
          >
            <a-select-option v-for="item in retailer" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="add-other-info">
          <div>Transaction fee</div>
          <a-input-search v-model="feeValue" style="width: 294px">
            <a-button slot="enterButton" :disabled="true">
              %
            </a-button>
          </a-input-search>
        </div>
        <p class="logistic-modal-button">
          <span class="accept-text-terms" @click="handleEditOther">ADD</span>
        </p>
      </a-modal>
    </div>
  </div>
</template>

<script>
import OrderClient from "@/api/order-client"
export default {
  name: "LandingTermsTransaction",
  data() {
    return {
      otherEditVisible: false,
      retailerValue: null,
      feeValue: 5,
      checkValue: false,
      isShowOther: false,
      retailers: [],
      transactionFList: [],
      transactionFStandard: {},
      isShowLoading: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    retailer() {
      return this.$store.getters["retailer/retailers"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    transactionFeeValue() {
      let result = -1
      if (this.brand && this.brand.transactionFeeTerms) {
        let commissionOverrides = this.brand.transactionFeeTerms.filter(
          item => item.brandId.length > 1 && item.standard && item.retailerId === "*"
        )
        if (commissionOverrides.length) {
          result = commissionOverrides[0].transactionFeeValue
        } else {
          let basicCommision = this.brand.transactionFeeTerms.filter(
            item => item.brandId === "*" && item.standard && item.retailerId === "*"
          )
          if (basicCommision.length) {
            result = basicCommision[0].transactionFeeValue
          }
        }
      }
      if (result >= 0) {
        result = +((result / 100) * 100).toFixed(2)
      } else {
        result = 0
      }
      return result
    }
  },
  async created() {
    this.isShowLoading = true
    await this.$store.dispatch("brand/getBrand", this.brandId)
    await this.$store.dispatch("retailer/fetchAllRetailers", { params: { fields: "name,id" } })
    this.formatParams()
  },
  methods: {
    handleCancelAdd() {
      this.otherEditVisible = false
    },
    formatParams() {
      if (this.brand.transactionFeeTerms && this.brand.transactionFeeTerms.length === 0) {
        this.transactionFList = []
        this.isShowLoading = false
        return
      }
      this.transactionFStandard = this.brand.transactionFeeTerms.filter(item => item.standard)[0]
      this.brand.transactionFeeTerms
        .filter(value => !value.standard)
        .forEach(async item => {
          this.transactionFList = []
          const obj = await this.getRetailer(item.retailerId)
          item.name = obj.name
          this.transactionFList.push(item)
        })
      this.isShowLoading = false
    },
    async handleEditOther(info) {
      this.isShowOther = true
      this.isShowLoading = true
      this.otherEditVisible = false
      const param = [
        {
          ...info,
          retailerId: this.retailerValue,
          transactionFeeValue: this.feeValue ? this.feeValue : 5.0,
          standard: false,
          enabled: true,
          accepted: false
        }
      ]
      // const params = this.brand.transactionFeeTerms.concat(param)
      const action = "add"
      await OrderClient.updateTransaction(this.brandId, action, param)
      await this.$store.dispatch("brand/getBrand", this.brandId)
      this.formatParams()
    },
    async getRetailer(retailerId) {
      let info
      try {
        info = await this.$store.dispatch("retailer/fetchRetailer", retailerId)
      } catch (e) {
        console.log(e)
      }
      return info
    },
    // async updataTermsOther() {
    //   const params = [
    //     {
    //       accepted: true
    //     }
    //   ]
    //   await OrderClient.updateTransaction(this.brandId, params)
    // }
    openDialog() {
      this.otherEditVisible = true
      this.retailerValue = null
      this.feeValue = 5
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSelectValue(value) {
      this.retailerValue = value
    },
    async confirmOtherDelete(item) {
      const action = "remove"
      const param = [item]
      await OrderClient.updateTransaction(this.brandId, action, param)
      await this.$store.dispatch("brand/getBrand", this.brandId)
      this.formatParams()
    },
    async confirmDelete(item) {
      const action = "remove"
      const param = [
        {
          ...item,
          brandId: this.brandId
        }
      ]
      await OrderClient.updateTransaction(this.brandId, action, param)
      await this.$store.dispatch("brand/getBrand", this.brandId)
      this.formatParams()
    }
  }
}
</script>

<style lang="scss">
.landing-terms-transaction__main {
  .transaction-style {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .landing-terms-content {
    .check-standard-style {
      margin-bottom: 80px;
      span {
        margin-right: 20px;
      }
    }
  }
  .title-second-style {
    font-size: 24px;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .title-edit-button {
    //display: inline-block;
    //width: 60px;
    //height: 24px;
    text-align: center;
    //background-color: #f5f5f5;
    //border: 1px solid #595959;
    cursor: pointer;
  }
  .table-terms-standard {
    min-height: 46px;
    line-height: 46px;
    margin-bottom: 0;
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    .table-terms-standard-title {
      display: inline-block;
      width: 280px;
    }
    p {
      width: 300px;
      text-align: right;
      margin-bottom: 0;
    }
  }
  .other-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .spin-style-admin {
    text-align: center;
    margin: 40px 0;
  }
}
</style>
