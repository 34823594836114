<template>
  <div>
    <a-row v-for="item in cardInfoList" :key="item.privateId" class="choose-card-list__row-margin">
      <a-col :span="2">
        <a-radio
          :value="item.privateId"
          :checked="item.privateId === currentMethod.privateId"
          @click="chooseMethod(item)"
        />
      </a-col>
      <a-col :span="14">
        <card-number :card-info="item" />
      </a-col>
      <a-col :span="4">
        <span class="choose-card-list__other-text"> {{ item.cardHolderName }} </span>
      </a-col>
      <a-col :span="4">
        <span
          v-if="!item.isExpired"
          class="choose-card-list__other-text choose-card-list__bottom-margin"
        >
          {{ showExpirationDate(item) }}
        </span>
        <div v-else>
          <p class="choose-card-list__other-text choose-card-list__red-text">
            {{ $t("Components.Brands.Payment.expired") }}
          </p>
          <a
            class="choose-card-list__other-text choose-card-list__green"
            @click="showUpdateDialog(item)"
          >
            {{ $t("Components.Brands.Payment.updateExpired") }}
          </a>
        </div>
      </a-col>
    </a-row>
    <a-modal v-model="isShowUpdateDialog" :footer="null" :destroy-on-close="true">
      <template #closeIcon>
        <img src="@/assets/img/icon/close-circle.svg" />
      </template>
      <edit-card
        :selected-item="selectedItem"
        :edit-all="false"
        :brand-id="brandId"
        @onFinish="hideUpdateDialog"
      />
    </a-modal>
  </div>
</template>

<script>
import CardNumber from "../settings/CardNumber"
import { Row, Col, Radio } from "ant-design-vue"
import EditCard from "@/components/brands/settings/EditCard"
import Utils from "@/common/Utils"
export default {
  components: {
    "card-number": CardNumber,
    "a-row": Row,
    "a-col": Col,
    "a-radio": Radio,
    "edit-card": EditCard
  },
  props: {
    cardInfoList: { type: Array, default: () => [] },
    brandId: { type: String, default: () => undefined },
    currentMethod: { type: Object, default: () => undefined }
  },
  data() {
    return {
      isShowUpdateDialog: false,
      selectedItem: undefined
    }
  },
  methods: {
    showUpdateDialog(item) {
      this.isShowUpdateDialog = true
      this.selectedItem = item
    },
    hideUpdateDialog() {
      this.isShowUpdateDialog = false
      this.selectedItem = undefined
    },
    chooseMethod(item) {
      this.$emit("chooseMethod", item)
    },
    showExpirationDate(item) {
      let info = this.$t("Components.Brands.Payment.expired")
      return Utils.getExpiredDate(item, info)
    }
  }
}
</script>

<style lang="sass">
.choose-card-list
  &__row-margin
    margin-bottom: 10px
  &__bottom-margin
    margin-bottom: 17px
  &__other-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 20px
    margin: 0px !important
  &__red-text
    color: #FF6132
  &__green
    color: #4a8a5d
.ant-radio-inner::after
  background-color: #4a8a5d !important
  margin-top: 0px
  margin-left: 0px
.ant-radio-checked .ant-radio-inner
  border-color: #4a8a5d !important
</style>
