<template>
  <div class="add-retailer-terms-modal__main">
    <a-modal
      v-model="editProductVisible"
      width="990px"
      :footer="null"
      :title="'Retailer Terms'"
      :closable="true"
      :mask-closable="false"
    >
      <table class="table is-fullwidth">
        <thead>
          <th class="retailer-order-table__head">Retailer Terms</th>
          <th class="retailer-order-table__head">Type</th>
          <th class="retailer-order-table__head">Description</th>
          <th class="retailer-order-table__head">Amount</th>
        </thead>
        <tbody v-for="(item, index) in chargeList" :key="index">
          <tr>
            <td v-if="!item.isAddNewValue" class="retailer-order-table__cell">
              {{ item.note || "-" }}
            </td>
            <td v-else class="retailer-order-table__cell">
              <a-input v-model="item.note" @change="modifyName(item)"></a-input>
            </td>
            <td class="retailer-order-table__cell">{{ item.paymentType.name }}</td>
            <td v-if="!item.isAddNewValue" class="retailer-order-table__cell">
              {{ item.recordedValue }}%
            </td>
            <td v-else class="retailer-order-table__cell">
              <a-input-search v-model="item.recordedValue" @change="modifyRecorded">
                <a-button slot="enterButton" :disabled="true">
                  %
                </a-button>
              </a-input-search>
            </td>
            <td
              v-if="!item.isAddNewValue && item.paidBy && item.paidBy.id == 'brand'"
              class="retailer-order-table__cell"
            >
              ({{
                item.paidBy.id === "brand"
                  ? priceFormat(item.calculatedCostUsd)
                  : priceFormat(item.calculatedCostUsd)
              }})
            </td>
            <td v-else class="retailer-order-table__cell">
              <a-input v-model="item.calculatedCostUsd"></a-input>
            </td>
          </tr>
        </tbody>
        <span v-if="!isShowAdd" class="add-new-terms" :style="{ color: color }" @click="addNewTerms"
          >+ADD</span
        >
      </table>
      <p class="apply-button-add-terms">
        <span :style="{ background: color }" @click="AddRetailerTerms">Apply</span>
      </p>
    </a-modal>
  </div>
</template>

<script>
import constants from "@/config.js"
import { priceFormat } from "@/utils/validate"
export default {
  name: "AddRetailerTermsModal",
  data() {
    return {
      priceFormat,
      editProductVisible: false,
      order: {},
      chargeList: [],
      color: "",
      isShowAdd: false
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    retailer() {
      return this.$store.getters["retailer/retailers"]
    }
  },
  methods: {
    async openDialog(obj, buttonColor) {
      this.isShowAdd = false
      this.order = obj
      this.chargeList = obj.charges
      if (this.isRetailer) {
        this.chargeList = this.chargeList.filter(
          item =>
            item.seenByRetailer && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      }
      if (this.isAdmin) {
        this.chargeList = this.chargeList.filter(
          item => item.seenByLanding && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      }
      this.editProductVisible = true
      this.color = buttonColor
      await this.$store.dispatch("retailer/fetchAllRetailers", { params: { fields: "name,id" } })
    },
    addNewTerms() {
      const addBy = this.isAdmin ? "landing" : "retailer"
      const newObj = {
        isAddNewValue: true,
        calculatedCostUsd: "",
        ownedBy: {
          id: "retailer"
        },
        paidBy: {
          id: "brand"
        },
        paymentType: {
          id: "credit",
          name: "Credit"
        },
        addedBy: {
          id: addBy
        },
        chargeType: {
          id: "custom"
        },
        note: "",
        percentage: true,
        seenByBrand: true,
        seenByRetailer: true,
        seenByLanding: true
      }
      this.chargeList = this.chargeList
        .map(item => {
          item.isAddNewValue = false
          return item
        })
        .filter(
          item =>
            !(
              item.chargeType.id === "custom" &&
              item.ownedBy.id === "retailer" &&
              item.paidBy.id === "brand"
            )
        )
      this.chargeList.push(newObj)
      this.isShowAdd = true
    },
    AddRetailerTerms() {
      this.editProductVisible = false
      const additionList = this.order.charges.filter(
        item => !(item.ownedBy.id === "retailer" && item.paidBy.id === "brand")
      )
      const params = this.chargeList.concat(additionList)
      this.$emit("updateRetailerTerms", params, true)
    },
    closeAddTerms() {
      this.editProductVisible = false
      this.isShowAdd = false
      this.chargeList = this.chargeList.filter(item => !item.isAddNewValue)
    },
    modifyRecorded() {
      //
      this.chargeList = this.chargeList.map(item => {
        if (item.isAddNewValue) {
          const num =
            ((item.recordedValue / 100) * 100 * (this.order.calculatedWholesaleCostUsd * 100)) /
            10000
          item.calculatedCostUsd = Math.round(num * 100) / 100
        }
        return item
      })
    },
    modifyName(value) {
      this.chargeList = this.chargeList.map(item => {
        if (item.isAddNewValue) {
          item = value
        }
        return item
      })
    }
  }
}
</script>

<style lang="scss">
.add-retailer-terms-modal__main {
}
.add-new-terms {
  text-decoration: underline;
  cursor: pointer;
}
.apply-button-add-terms {
  text-align: right;
  span {
    padding: 5px 16px;
    color: white;
    cursor: pointer;
  }
}
</style>
