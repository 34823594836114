<template>
  <div class="admin-console-search-item">
    <template v-for="(item, index) in searchItems">
      <div
        v-if="
          (item.child === undefined || item.child.length === 0) && !item.isDate && !item.canSearch
        "
        :key="`search_item_${index}`"
        class="admin-search-items__top-border"
      >
        <span class="admin-search-items__header-title" style="font-weight: normal;">
          {{ item.name }}
        </span>
      </div>
      <a-collapse
        v-else
        :key="`search_item_${index}`"
        bordered
        expand-icon-position="right"
        accordion
      >
        <template #expandIcon="props">
          <a-icon type="down" :rotate="props.isActive ? 180 : 0" />
        </template>
        <a-collapse-panel>
          <template #header="props">
            <span
              class="admin-search-items__header-title"
              :style="
                props && props.isActive
                  ? 'font-weight: bold; color: #FF6132;'
                  : 'font-weight: normal; color: #262626;'
              "
            >
              {{ item.name }}
            </span>
          </template>
          <div v-if="item.isDate" style="padding: 6px;">
            <a-row style="margin-bottom: 6px;">
              <a-date-picker
                v-model="startDate"
                placeholder="Select start date "
                style="width: 100%;"
                :format="dateFormat"
                @change="onChooseStartDate(item)"
              />
            </a-row>
            <a-row>
              <a-date-picker
                v-model="endDate"
                placeholder="Select end date "
                style="width: 100%;"
                :format="dateFormat"
                @change="onChooseEndDate(item)"
              />
            </a-row>
          </div>

          <div v-if="(item.child && item.child.length > 0) || item.canSearch" style="padding: 6px;">
            <a-input-search
              v-if="item.canSearch"
              style="padding-left: 4px;"
              placeholder="Type in name to search"
              @search="value => onSearch(value, item)"
            />
            <div v-if="item.child === undefined || item.child.length === 0"></div>
            <div
              v-else-if="!item.child[0].hasChild"
              style="margin-top: 10px; padding-left: 16px; max-height: 300px; overflow-y: auto"
            >
              <a-row
                v-for="(childOne, childOneIndex) in item.child"
                :key="`${item.name}_${childOne.name}_${childOneIndex}`"
              >
                <a-checkbox v-model="childOne.isChecked" @change="onChange(childOne, item)">
                  {{ childOne.name }}
                </a-checkbox>
              </a-row>
            </div>
            <div v-else class="admin-console-search-item-child">
              <a-collapse :bordered="false" expand-icon-position="right" accordion>
                <a-collapse-panel
                  v-for="(childOne, childOneIndex) in item.child"
                  :key="`search_item_${childOne.name}_${childOneIndex}`"
                  :show-arrow="false"
                >
                  <template #header="props">
                    <div @click="getSubcategories(childOne)">
                      <a-checkbox
                        v-model="childOne.isCheckAll"
                        style="padding-left: 8px;"
                        class="admin-search-items__header-title"
                        :style="
                          props && props.isActive
                            ? 'font-weight: bold; color: #FF6132;'
                            : 'font-weight: normal; color: #262626;'
                        "
                        @click.stop
                        @change.stop="checkAll(childOne, item)"
                      >
                        {{ childOne.name }}
                      </a-checkbox>
                      <a-icon
                        type="down"
                        :rotate="props && props.isActive ? 180 : 0"
                        style="margin-left: 10px;"
                      />
                    </div>
                  </template>
                  <div>
                    <a-spin v-if="isLoading && loadingItem.id === childOne.id" />
                    <a-collapse
                      v-else
                      :bordered="false"
                      expand-icon-position="right"
                      accordion
                      style="padding-left: 20px;"
                    >
                      <template #expandIcon="props">
                        <a-icon type="down" :rotate="props && props.isActive ? 180 : 0" />
                      </template>
                      <a-collapse-panel
                        v-for="(childSecond, childSecIndex) in childOne.child"
                        :key="`search_item_${childSecond.name}_${childSecIndex}`"
                        :show-arrow="false"
                      >
                        <template #header="props">
                          <div @click="getMicrocategories(childSecond)">
                            <a-checkbox
                              v-model="childSecond.isCheckAll"
                              class="admin-search-items__header-title"
                              style="line-height: 14px;"
                              :style="
                                props && props.isActive
                                  ? 'font-weight: bold; color: #FF6132;'
                                  : 'font-weight: normal; color: #262626;'
                              "
                              @click.stop
                              @change.stop="checkSecondAll(childSecond, childOne, item)"
                            >
                              {{ childSecond.name }}
                              <a-icon
                                type="down"
                                :rotate="props && props.isActive ? 180 : 0"
                                style="font-size: 10px;"
                              />
                            </a-checkbox>
                          </div>
                        </template>
                        <a-spin v-if="isLoading && loadingItem.id === childSecond.id" />
                        <div v-else>
                          <a-row
                            v-for="(childThird, childThirdIndex) in childSecond.child"
                            :key="`search_item_${childThird.name}_${childThirdIndex}`"
                            style="padding-left: 24px;"
                          >
                            <a-checkbox
                              v-model="childThird.isChecked"
                              class="admin-search-items__header-title"
                              style="line-height: 14px;"
                              :style="
                                props && props.isActive
                                  ? 'font-weight: bold; color: #FF6132;'
                                  : 'font-weight: normal; color: #262626;'
                              "
                              @change="onChangeThird(childThird, childSecond, childOne, item)"
                            >
                              {{ childThird.name }}
                            </a-checkbox>
                          </a-row>
                        </div>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </template>
  </div>
</template>

<script>
import moment from "moment"
export default {
  props: {
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      paramData: {},
      searchItems: [],
      searchNameItems: {},
      paramNameSet: new Set(),
      startDate: undefined,
      endDate: undefined,
      isLoading: false,
      loadingItem: undefined,
      dateFormat: "MM/DD/YYYY"
    }
  },
  watch: {
    items() {
      this.searchItems = this.items
      this.items.forEach(item => {
        if (item.canSearch) {
          this.searchNameItems[item.name] = { ...item }
        }
      })
      this.$forceUpdate()
    }
  },
  methods: {
    getSubcategories(item) {
      this.isLoading = true
      this.loadingItem = item
      this.$store
        .dispatch("reference/getSubCategory", item.privateId)
        .then(response => {
          item["child"] = response.productSubcategorys
          if (item.child && item.child.length > 0) {
            item.child.forEach(data => {
              data.isCheckAll = item.isCheckAll
              data.isChecked = item.isCheckAll
            })
          }
        })
        .finally(() => {
          this.isLoading = false
          this.loadingItem = undefined
        })
    },
    getMicrocategories(item) {
      this.isLoading = true
      this.loadingItem = item
      this.$store
        .dispatch("reference/getMicroCategory", item.privateId)
        .then(response => {
          item["child"] = response.productMicroCategorys
          if (item.child && item.child.length > 0) {
            item.child.forEach(micro => {
              micro.isChecked = item.isCheckAll
            })
          }
        })
        .finally(() => {
          this.isLoading = false
          this.loadingItem = undefined
        })
    },
    onChooseStartDate(item) {
      if (!this.endDate) {
        this.endDate = moment(this.startDate.format("L")).add(30, "days")
      }
      this.setDateParam(item)
    },
    onChooseEndDate(item) {
      if (!this.startDate) {
        this.startDate = moment(this.endDate.format("L")).subtract(30, "days")
      }
      this.setDateParam(item)
    },
    setDateParam(item) {
      let curParam = this.paramData[item.paramName]
      if (curParam) {
        curParam.clear()
      } else {
        curParam = new Set()
      }
      curParam.add(this.startDate.format("YYYY-MM-DD"))
      curParam.add(this.endDate.format("YYYY-MM-DD"))
      this.paramData[item.paramName] = curParam
      this.paramNameSet.add(item.paramName)
      this.sendToDoSearch()
    },
    onSearch(value, item) {
      let data = this.searchNameItems[item.name]
      let searchResult = data.child.filter(
        item => item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
      )
      item.child = searchResult
    },
    checkAll(childItem, item) {
      if (childItem && childItem.child) {
        childItem.child.forEach(child => {
          child.isCheckAll = childItem.isCheckAll
          child.isChecked = childItem.isCheckAll
          if (child.child && child.child.length > 0) {
            child.child.forEach(cChild => {
              cChild.isChecked = childItem.isCheckAll
            })
          }
        })
      }
      childItem.isChecked = childItem.isCheckAll
      this.setParams(childItem, item.paramName, item.isUseId)
    },
    checkSecondAll(childItem, childOne, item) {
      if (childItem && childItem.child) {
        childItem.child.forEach(child => {
          child.isChecked = childItem.isCheckAll
        })
      }
      childItem.isChecked = childItem.isCheckAll
      if (!childItem.isChecked) {
        // remove category param
        if (childOne.isCheckAll) {
          childOne.isCheckAll = false
          childOne.isChecked = false
          this.setParam(childOne, item.paramName, item.isUseId)
          // add subcategory param
          if (childOne.child) {
            childOne.child.forEach(child => {
              this.setParam(child, item.subParam, item.isUseId)
            })
          }
          this.$forceUpdate()
          this.sendToDoSearch()
        } else {
          // set param and send
          this.setParams(childItem, item.subParam, item.isUseId)
        }
      } else {
        let checkedCount = 0
        childOne.child.forEach(childSecond => {
          if (childSecond.isChecked) {
            checkedCount += 1
          }
        })
        if (checkedCount === childOne.child.length) {
          if (!childOne.isChecked) {
            childOne.isChecked = true
            childOne.isCheckAll = true
            // add category param
            this.setParam(childOne, item.paramName, item.isUseId)
            if (childOne.child) {
              // remove all the sub
              childOne.child.forEach(child => {
                this.removeSubParam(child, item.subParam, item.isUseId)
              })
            }
          }
          this.$forceUpdate()
          this.sendToDoSearch()
        } else {
          this.setParams(childItem, item.subParam, item.isUseId)
        }
      }
    },
    onChange(child, item) {
      this.setParams(child, item.paramName, item.isUseId)
    },
    onChangeThird(child, childSecond, childOne, item) {
      if (child.isChecked) {
        // choose micro
        // 1. check sub category should be checkall or not
        if (!childSecond.isCheckAll) {
          let sCount = 0
          childSecond.child.forEach(childThird => {
            if (childThird.isChecked) {
              sCount += 1
            }
          })
          if (sCount === childSecond.child.length) {
            childSecond.isCheckAll = true
            childSecond.isChecked = true
            childSecond.child.forEach(childThird => {
              this.removeSubParam(childThird, item.microParam, item.isUseId)
            })
            // check category
            let oCount = 0
            childOne.child.forEach(childS => {
              if (childS.isChecked) {
                oCount += 1
              }
            })
            if (oCount === childOne.child.length) {
              childOne.isCheckAll = true
              childOne.isChecked = true
              childOne.child.forEach(childS => {
                this.removeSubParam(childS, item.subParam, item.isUseId)
              })
              this.setParams(childOne, item.paramName, item.isUseId)
            } else {
              this.setParams(childSecond, item.subParam, item.isUseId)
            }
          } else {
            this.setParams(child, item.microParam, item.isUseId)
          }
        }
      } else {
        // not choose mircro
        if (childSecond.isChecked) {
          childSecond.isCheckAll = false
          childSecond.isChecked = false
          if (childOne.isCheckAll) {
            childOne.isCheckAll = false
            childOne.isChecked = false
            this.removeSubParam(childOne, item.paramName, item.isUseId)
            childOne.child.forEach(childS => {
              this.setParam(childS, item.subParam, item.isUseId)
            })
          } else {
            this.removeSubParam(childSecond, item.subParam, item.isUseId)
          }

          childSecond.child.forEach(childThird => {
            this.setParam(childThird, item.microParam, item.isUseId)
          })
          this.$forceUpdate()
          this.sendToDoSearch()
        } else {
          this.setParams(child, item.microParam, item.isUseId)
        }
      }
    },
    removeSubParam(child, paramName, isUseId) {
      let curParam = this.paramData[paramName]
      if (curParam && curParam.size > 0) {
        if (isUseId) {
          curParam.delete(child.id)
        } else {
          curParam.delete(child.name)
        }
        this.paramNameSet.delete(paramName)
        this.paramData[paramName] = curParam
      }
    },
    setParam(child, paramName, isUseId) {
      let curParam = this.paramData[paramName]
      if (!curParam) {
        curParam = new Set()
      }
      if (child.isChecked) {
        if (isUseId) {
          curParam.add(child.id)
        } else {
          curParam.add(child.name)
        }
        this.paramNameSet.add(paramName)
      } else {
        if (isUseId) {
          curParam.delete(child.id)
        } else {
          curParam.delete(child.name)
        }
        if (curParam.size === 0) {
          this.paramNameSet.delete(paramName)
        }
      }
      this.paramData[paramName] = curParam
    },
    setParams(child, paramName, isUseId) {
      this.setParam(child, paramName, isUseId)
      this.$forceUpdate()
      this.sendToDoSearch()
    },
    sendToDoSearch() {
      let param = {}
      for (let paramName of this.paramNameSet) {
        let data = [...this.paramData[paramName]]
        param[paramName] = data.join(",")
      }
      this.$emit("doSearch", param)
    }
  }
}
</script>

<style lang="scss">
.admin-search-items {
  &__header-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13125px;
    text-transform: uppercase;
    display: inline-block;
  }
  &__top-border {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    padding: 12px 16px;
  }
}
.admin-console-search-item .ant-collapse {
  background-color: white;
  border-radius: 0px;
}
.admin-console-search-item .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0px;
}
.admin-console-search-item .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}
.admin-console-search-item .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}
.admin-console-search-item .ant-collapse-borderless {
  background-color: white;
}
.admin-console-search-item .admin-console-search-item-child .ant-collapse:last-child {
  border-bottom: none;
}
.admin-console-search-item-child
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 2px 4px;
  padding-right: 0px;
}
.admin-console-search-item-child .ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.admin-console-search-item .ant-collapse:last-child {
  border-bottom: 1px solid #d9d9d9;
}
.admin-search-items__header-title .ant-checkbox + span {
  padding-left: 6px;
  padding-right: 4px;
}
</style>
