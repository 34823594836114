import inventoryClient from "@/api/inventory_client.js"

export default {
  namespaced: true,
  state: {
    brandReport: {},
    productReport: {},
    expirationReport: {},
    productHistory: {},
    modifyInventory: {},
    downloadInventory: {},
    brandProductReport: {},
    product: {},
    uploadResult: {}
  },

  getters: {
    brandList(state) {
      return state.brandReport.brandInventoryReport
    },
    brandReport(state) {
      return state.brandReport
    },
    brandProductList(state) {
      return state.brandProductReport.productInventoryReport
    },
    brandProductReport(state) {
      return state.brandProductReport
    },
    productReportList(state) {
      return state.productReport.productInventoryReport
    },
    productReport(state) {
      return state.productReport
    },
    expirationReportList(state) {
      return state.expirationReport.inventoryHistory
    },
    expirationReport(state) {
      return state.expirationReport
    },
    productHistoryList(state) {
      return state.productHistory.inventoryHistory
    },
    productHistory(state) {
      return state.productHistory
    },
    productInfo(state) {
      return state.product
    }
  },
  mutations: {
    SET_BRAND_REPORT(state, data) {
      state.brandReport = data
    },
    SET_PRODUCT_REPORT(state, data) {
      state.productReport = data
    },
    SET_EXPIRATION_REPORT(state, data) {
      state.expirationReport = data
    },
    SET_PRODUCT_HISTORY(state, data) {
      state.productHistory = data
    },
    SET_BRAND_PRODUCT_REPORT(state, data) {
      if (data && data.productInventoryReport && data.productInventoryReport.length > 0) {
        if (
          state.brandReport &&
          state.brandReport.brandInventoryReport &&
          state.brandReport.brandInventoryReport.length > 0
        ) {
          let brand = state.brandReport.brandInventoryReport.find(
            item => item.brandId === data.productInventoryReport[0].brandId
          )
          if (brand) {
            brand.productLists = data.productInventoryReport
          }
        }
      }
      state.brandProductReport = data
    },
    SET_UPDATE_INVENTORY(state, data) {
      state.modifyInventory = data
    },
    SET_DOWNLOAD_INVENTORY(state, data) {
      state.downloadInventory = data
    },
    SET_PRODUCT_DATA_REPORT(state, data) {
      state.product = data
    },
    SET_UPLOAD_RESULT(state, data) {
      state.uploadResult = data
    }
  },
  actions: {
    async fetchProductHistory({ commit, rootGetters }, params) {
      try {
        const { data } = await inventoryClient.getProductInventoryHistory(params)
        if (data && data.inventoryHistory && data.inventoryHistory.length) {
          data.inventoryHistory.map(item => {
            if (item) {
              if (item.typePrivateId) {
                const inventoryType = rootGetters["reference/findInventoryType"](item.typePrivateId)
                item.typeName = inventoryType && inventoryType.name
                item.typeName = item.typeName
                  ? item.typeName.charAt(0).toUpperCase() + item.typeName.slice(1)
                  : item.typeName
                item.typePrivateId = item.typePrivateId.toString()
              }
              if (item.actionPrivateId) {
                const inventoryAction = rootGetters["reference/findInventoryAction"](
                  item.actionPrivateId
                )
                item.actionName = inventoryAction && inventoryAction.name
                item.actionName = item.actionName
                  ? item.actionName.charAt(0).toUpperCase() + item.actionName.slice(1)
                  : item.actionName
                item.actionPrivateId = item.actionPrivateId.toString()
              }
              if (item.reason) {
                const reasonObj = rootGetters["reference/findInventoryReasonByName"](item.reason)
                item.reasonPrivateId = reasonObj ? reasonObj.id : undefined
                if (item.reasonPrivateId) {
                  item.reasonPrivateId = item.reasonPrivateId.toString()
                }
                item.reason = item.reason.charAt(0).toUpperCase() + item.reason.slice(1)
              }
              if (item.actionPrivateId === "3") {
                item.description = item.reason
              } else {
                item.description = item.actionId
              }
              item.isChecked = false
              item.lotNo = []
              item.lotQuantity = []
              item.lotAvailable = []
              item.lotExpiration = []
              item.lotLocation = []
              item.lotAllocated = []
              item.historyKey = []
              item.inventoryHistorys = []
              if (item.inventoryHistoryChilds && item.inventoryHistoryChilds.length > 0) {
                let len = item.inventoryHistoryChilds.length
                for (let index = 0; index < len; index++) {
                  let child = item.inventoryHistoryChilds[index]
                  child.modifyDate = item.modifyDate
                  child.typeName = item.typeName
                  child.typePrivateId = item.typePrivateId
                  child.actionName = item.actionName
                  child.actionPrivateId = item.actionPrivateId
                  child.parentTotalQuantity = item.quantity
                  child.units = item.units
                  child.comments = item.comments
                  child.description = item.description
                  child.reason = item.reason
                  child.productName = item.productName
                  child.lotExpiration = child.expirationDate
                  child.brandName = item.brandName
                  child.updatedAt = item.updatedAt
                  if (child.locationPrivateId) {
                    const location = rootGetters["reference/findInventoryLocation"](
                      child.locationPrivateId
                    )
                    child.locationName = location && location.description
                    child.locationPrivateId = child.locationPrivateId.toString()
                    child.lotLocation = child.locationName
                  }
                  child.lotQuantity = child.quantity
                  child.lotAvailable = child.available
                  child.isChecked = false
                }
              } else {
                // item.lotQuantity.push(item.quantity)
              }
              item.historyChilds = item.inventoryHistoryChilds.reduce((p, current) => {
                var key = `${current.lotNo}_${current.expirationDate}`
                if (!p.hasOwnProperty(key)) {
                  item.historyKey.push(key)
                  p[key] = Object.assign({}, current)
                } else {
                  p[key].quantity += current.quantity
                  p[key].lotQuantity += current.lotQuantity
                  p[key].available =
                    p[key].available < current.available ? p[key].available : current.available
                  p[key].lotAvailable =
                    p[key].lotAvailable < current.lotAvailable
                      ? p[key].lotAvailable
                      : current.lotAvailable
                }
                return p
              }, {})
              if (item.historyKey && item.historyKey.length > 0) {
                for (let i = 0; i < item.historyKey.length; i++) {
                  let child = item.historyChilds[item.historyKey[i]]
                  item.lotNo.push(child.lotNo)
                  item.lotAvailable.push(child.available)
                  item.lotExpiration.push(child.expirationDate)
                  item.lotQuantity.push(child.quantity)
                  item.lotAllocated.push(child.remaining)
                  item.lotLocation.push(child.locationName)
                  item.inventoryHistorys.push(child)
                }
              }
            }
          })
        }
        commit("SET_PRODUCT_HISTORY", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchBrandReport({ commit, rootGetters }, params) {
      try {
        const { data } = await inventoryClient.getBrandReport(params)
        if (data && data.brandInventoryReport && data.brandInventoryReport.length > 0) {
          data.brandInventoryReport.map(item => {
            item.isChecked = false
            if (item.productInventoryReport && item.productInventoryReport.length > 0) {
              item.productInventoryReport.map(pItem => {
                const location = rootGetters["reference/findInventoryLocation"](
                  pItem.locationPrivateId
                )
                pItem.locationName = location && location.description
              })
            }
          })
        }
        commit("SET_BRAND_REPORT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchProductReport({ commit, rootGetters }, params) {
      try {
        const { data } = await inventoryClient.getProductReport(params)
        if (data && data.productInventoryReport && data.productInventoryReport.length) {
          data.productInventoryReport.map(item => {
            const location = rootGetters["reference/findInventoryLocation"](item.locationPrivateId)
            item.locationName = location && location.description
            item.isChecked = false
          })
        }
        commit("SET_PRODUCT_REPORT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchProductData({ commit }, params) {
      try {
        const { data } = await inventoryClient.getProductReport(params)
        commit("SET_PRODUCT_DATA_REPORT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchBrandProductReport({ commit, rootGetters }, brandId) {
      try {
        let params = {
          _limit: 9999,
          _page: 1,
          "brand-Id": brandId
        }
        const { data } = await inventoryClient.getProductReport(params)
        if (data && data.productInventoryReport && data.productInventoryReport.length) {
          data.productInventoryReport.map(item => {
            const location = rootGetters["reference/findInventoryLocation"](item.locationPrivateId)
            item.locationName = location && location.description
            item.isChecked = false
          })
        }
        commit("SET_BRAND_PRODUCT_REPORT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchBrandProductReportInBrandView({ commit, rootGetters }, param) {
      try {
        const { data } = await inventoryClient.getProductReport(param)
        if (data && data.productInventoryReport && data.productInventoryReport.length) {
          data.productInventoryReport.map(item => {
            const location = rootGetters["reference/findInventoryLocation"](item.locationPrivateId)
            item.locationName = location && location.description
            item.isChecked = false
          })
        }
        commit("SET_BRAND_PRODUCT_REPORT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchExpirationReport({ commit, rootGetters }, params) {
      try {
        const { data } = await inventoryClient.getProductInventoryHistory(params)
        if (data && data.inventoryHistory && data.inventoryHistory.length) {
          data.inventoryHistory.map(item => {
            const type = rootGetters["reference/findInventoryType"](item.typePrivateId)
            item.typeName = type && type.description
            item.isChecked = false
            if (item.actionPrivateId) {
              const inventoryAction = rootGetters["reference/findInventoryAction"](
                item.actionPrivateId
              )
              item.actionName = inventoryAction && inventoryAction.name
              item.actionName = item.actionName
                ? item.actionName.charAt(0).toUpperCase() + item.actionName.slice(1)
                : item.actionName
              item.actionPrivateId = item.actionPrivateId.toString()
            }
            if (item.inventoryHistoryChilds && item.inventoryHistoryChilds.length > 0) {
              item.lotNo = item.inventoryHistoryChilds[0].lotNo
              item.expirationDate = item.inventoryHistoryChilds[0].expirationDate
              item.available = item.inventoryHistoryChilds[0].available
              item.total = item.inventoryHistoryChilds[0].quantity
              item.allocated =
                item.inventoryHistoryChilds[0].quantity - item.inventoryHistoryChilds[0].available
            }
          })
        }
        commit("SET_EXPIRATION_REPORT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createProductInventory({ commit }, params) {
      try {
        const { data } = await inventoryClient.addReduceInventory(params)
        if (!data.error) {
          commit("SET_UPDATE_INVENTORY", data)
          return data
        } else {
          return Promise.reject(data.error.error)
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateProductInventory({ commit }, params) {
      try {
        const { data } = await inventoryClient.updateInventory(params)
        if (!data.error) {
          commit("SET_UPDATE_INVENTORY", data)
          return data
        } else {
          return Promise.reject(data.error.error)
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          return Promise.reject(error.response.data.error.developerMessage)
        } else {
          return Promise.reject(error)
        }
      }
    },
    async downloadInventory({ commit }, params) {
      try {
        const { data } = await inventoryClient.downloadInventory(params)
        commit("SET_DOWNLOAD_INVENTORY", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async downloadBrandReport({ commit }, params) {
      try {
        const { data } = await inventoryClient.downloadBrandReport(params)
        commit("SET_DOWNLOAD_INVENTORY", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async downloadProductReport(_, params) {
      try {
        const data = await inventoryClient.downloadProductReport(params)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async uploadInventory({ commit }, params) {
      try {
        const { data } = await inventoryClient.uploadFile(params)
        commit("SET_UPLOAD_RESULT", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
