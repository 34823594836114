export var SubscriptionStatusEnum;
(function(SubscriptionStatusEnum) {
  SubscriptionStatusEnum[(SubscriptionStatusEnum["Activated"] = 1)] =
    "Activated";
  SubscriptionStatusEnum[(SubscriptionStatusEnum["Cancelled"] = 2)] =
    "Cancelled";
  SubscriptionStatusEnum[(SubscriptionStatusEnum["Expired"] = 3)] = "Expired";
  SubscriptionStatusEnum[(SubscriptionStatusEnum["Pending"] = 4)] = "Pending";
  SubscriptionStatusEnum[(SubscriptionStatusEnum["Suspended"] = 5)] =
    "Suspended";
})(SubscriptionStatusEnum || (SubscriptionStatusEnum = {}));
