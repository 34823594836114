<template lang="pug">
.old-retailer-order-table.table-border-t-none(v-click-outside="hideDropdown")
  .reold-retailer-order-table__header-container
    p.old-retailer-order-table__name {{$t('Retailers.RetailerOrder.additionalCharge')}}
    button.button.add-charge-button(@click="showAddCharges" v-if="order.status && order.status.id == orderStatus.pending" :disabled="isEditing") {{$t('Components.Orders.OrderDetails.addCharges')}}
  table.table.is-fullwidth
    thead
      tr
        th.old-retailer-order-table__head {{$t('Retailers.RetailerOrder.additionalCharge')}}
        th.old-retailer-order-table__head {{$t('Retailers.RetailerOrder.description')}}
        th.old-retailer-order-table__head {{$t('Retailers.RetailerOrder.amount')}}
    tbody
      tr(v-for="item in termsCharges")
        td.old-retailer-order-table__cell {{item.chargeType.name + ' '}}
          span(v-if="item.percentage") ({{item.recordedValue}}%)
        td.old-retailer-order-table__cell(v-if="!item.note") -
        td.old-retailer-order-table__cell(v-if="item.note") {{item.note}}
        td.old-retailer-order-table__cell(v-if="item.paidBy && item.paidBy.id == 'brand'") ({{priceFormat(item.calculatedCostUsd)}})
        td.old-retailer-order-table__cell(v-else) {{priceFormat(item.calculatedCostUsd)}}
      tr(v-for="(item, index) in otherCharges")
        td.old-retailer-order-table__cell(v-if="editingIndex !== index") {{item.chargeType.name + ' '}}
          span(v-if="item.percentage") ({{item.recordedValue}}%)
        td.old-retailer-order-table__cell(v-if="editingIndex !== index && !item.note") -
        td.old-retailer-order-table__cell(v-if="editingIndex !== index && item.note") {{item.note}}
        td.old-retailer-order-table__cell(v-if="editingIndex !== index")
          span(v-if="item.paidBy && item.paidBy.id == 'brand'") ({{priceFormat(item.calculatedCostUsd)}})
          span(v-else) {{priceFormat(item.calculatedCostUsd)}}
          .brand-product-item__circles(@click="showDropdown(index)" v-if="!item.standard && order.status.id == orderStatus.pending && isBrand && item.ownedBy.id == 'brand' && !showAddChargeFileds")
            img(src="@/assets/img/icon/ellipses.svg")
          .brand-product-item__circles(@click="showDropdown(index)" v-if="!item.standard && order.status.id == orderStatus.pending && isRetailer && item.ownedBy.id == 'retailer' && !showAddChargeFileds")
            img(src="@/assets/img/icon/ellipses.svg")
          .brand-product-item__dropdown(v-if="itemIndex === index")
            .brand-product-item__dropdown-item(@click="enableEditing(index, item)")
              span.brand-product-item__dropdown-icon
                img(src="@/assets/img/icon/edit-dropdown-icon.svg")
              span.brand-product-item__edit-button {{$t('Components.Orders.OrderDetails.edit')}}
            .brand-product-item__dropdown-item(@click="removeCharges(item)")
              span.brand-product-item__dropdown-icon
                img(src="@/assets/img/icon/remove-dropdown-icon.svg")
              span.brand-product-item__remove-button {{$t('Components.Orders.OrderDetails.remove')}}
        td.old-retailer-order-table__cell(v-if="editingIndex === index")
          .select.additional-charge(:class="{ 'is-danger': $v.additionalCharge.chargeType.id.$error }")
            select(v-model="$v.additionalCharge.chargeType.id.$model" @change="onSelectingCharge($event)")
              option(v-for="charge in charges" :key="charge.id" :value="charge.id") {{charge.name}}
          p.help.is-danger(v-if="$v.additionalCharge.chargeType.id.$error") {{$t('Components.Orders.OrderDetails.Validations.required')}}
        td.old-retailer-order-table__cell(v-if="editingIndex === index")
          input.input(type = "text" v-model="$v.additionalCharge.note.$model" :class="{ 'is-danger': $v.additionalCharge.note.$error }")
          p.help.is-danger(v-if="$v.additionalCharge.note.$error") {{$t('Components.Orders.OrderDetails.Validations.required')}}
        td.old-retailer-order-table__cell(v-if="editingIndex === index" :class="{'adjust-width' : isEditing}")
          .amount-input-container
            input.input(type = "text" v-model="$v.additionalCharge.recordedValue.$model" :class="{ 'is-danger': $v.additionalCharge.recordedValue.$error }")
            .additional-charge-dropdown
              .select
                select(v-model="$v.additionalCharge.percentage.$model")
                  option(:value="true") %
                  option(:value="false") $
          p.help.is-danger(v-if="$v.additionalCharge.recordedValue.$error && !$v.additionalCharge.recordedValue.required") {{$t('Components.Orders.OrderDetails.Validations.required')}}
          p.help.is-danger(v-if="$v.additionalCharge.recordedValue.$error && $v.additionalCharge.recordedValue.required && !$v.additionalCharge.recordedValue.positiveNumber") {{$t('Components.Orders.OrderDetails.Validations.positiveNumber')}}
          .additional-charge-buttons
            button.button.save-additional-charge(@click="saveEditedCharges(index)" :disabled="!$v.additionalCharge.$anyDirty") {{$t('Components.Orders.OrderDetails.save')}}
            button.button.cancel-additional-charge(@click="cancelCharges") {{$t('Components.Orders.OrderDetails.cancel')}}
      tr(v-if="showAddChargeFileds")
        td.old-retailer-order-table__cell
          .select.additional-charge(:class="{ 'is-danger': $v.additionalCharge.chargeType.id.$error }")
            select(v-model="$v.additionalCharge.chargeType.id.$model" @change="onSelectingCharge($event)")
              option(v-for="charge in charges" :key="charge.id" :value="charge.id") {{charge.name}}
          p.help.is-danger(v-if="$v.additionalCharge.chargeType.id.$error") {{$t('Components.Orders.OrderDetails.Validations.required')}}
        td.old-retailer-order-table__cell
          input.input(type = "text" v-model="$v.additionalCharge.note.$model" :class="{ 'is-danger': $v.additionalCharge.note.$error }")
          p.help.is-danger(v-if="$v.additionalCharge.note.$error") {{$t('Components.Orders.OrderDetails.Validations.required')}}
        td.old-retailer-order-table__cell(:class="{'adjust-width' : isEditing}")
          .amount-input-container
            input.input(type = "text" v-model="$v.additionalCharge.recordedValue.$model" :class="{ 'is-danger': $v.additionalCharge.recordedValue.$error }")
            .additional-charge-dropdown
              .select
                select(v-model="$v.additionalCharge.percentage.$model")
                  option(:value="true") %
                  option(:value="false") $
          p.help.is-danger(v-if="$v.additionalCharge.recordedValue.$error && !$v.additionalCharge.recordedValue.required") {{$t('Components.Orders.OrderDetails.Validations.required')}}
          p.help.is-danger(v-if="$v.additionalCharge.recordedValue.$error && $v.additionalCharge.recordedValue.required && !$v.additionalCharge.recordedValue.positiveNumber") {{$t('Components.Orders.OrderDetails.Validations.positiveNumber')}}
          .additional-charge-buttons
            button.button.save-additional-charge(@click="saveCharges" :disabled="!$v.additionalCharge.$anyDirty") {{$t('Components.Orders.OrderDetails.save')}}
            button.button.cancel-additional-charge(@click="cancelCharges") {{$t('Components.Orders.OrderDetails.cancel')}}

      tr
        td.brand-order-table__cell.table-border-b-none
        td.brand-order-table__cell.table-border-b-none.subtotal.text-right {{$t('Retailers.RetailerOrder.additionalChargesSubtotal')}}
        td.old-retailer-order-table__cell.table-border-b-none(v-if="totalAdditionalCharge<0") ({{priceFormat(totalAdditionalCharge * -1)}})
        td.old-retailer-order-table__cell.table-border-b-none(v-else) {{priceFormat(totalAdditionalCharge)}}

      tr.total
        td.brand-order-table__cell.table-border-b-none
        td.brand-order-table__cell.table-border-b-none.subtotal.text-right {{$t('Retailers.RetailerOrder.total')}}
        td.old-retailer-order-table__cell.table-border-b-none.subtotal(v-if="totalAmount<0") ({{priceFormat(totalAmount * -1)}})
        td.old-retailer-order-table__cell.table-border-b-none.subtotal(v-else) {{priceFormat(totalAmount)}}
</template>

<script>
import constants from "@/config.js"
import { required } from "vuelidate/lib/validators"
export default {
  props: {
    order: { type: Object, default: () => {} }
  },
  data() {
    return {
      orderStatus: constants.orderStatus,
      showAddChargeFileds: false,
      additionalCharge: {
        chargeType: {
          id: "",
          name: ""
        },
        ownedBy: {
          id: ""
        },
        paidBy: {
          id: ""
        },
        percentage: false,
        seenByBrand: false,
        seenByLanding: false,
        seenByRetailer: false,
        recordedValue: null,
        calculatedCostUsd: 0.0,
        note: ""
      },
      purchaseDetails: [
        {
          calculatedRetailCostUsd: "",
          calculatedWholesaleCostUsd: "",
          calculatedWholesalePriceUsd: "",
          createdAt: "",
          createdBy: "",
          product: {
            id: "",
            name: "",
            sku: ""
          },
          quantity: "",
          quantityType: {
            id: "",
            name: ""
          },
          testersquantity: "",
          recordedMargin: "",
          recordedMsrpUsd: ""
        }
      ],
      itemIndex: -1,
      editingIndex: -1,
      isEditing: false,
      productIndex: -1,
      productEditIndex: -1,
      isSaveDisabled: true
    }
  },
  validations() {
    if (this.additionalCharge.chargeType.id === "other") {
      return {
        additionalCharge: {
          chargeType: {
            id: { required },
            name: {}
          },
          recordedValue: {
            required,
            positiveNumber(msrpUsd) {
              return /^\d+(\.\d{1,2})?$/.test(msrpUsd)
            }
          },
          note: { required },
          percentage: {}
        }
      }
    } else {
      return {
        additionalCharge: {
          chargeType: {
            id: { required },
            name: {}
          },
          recordedValue: {
            required,
            positiveNumber(recordedValue) {
              return /^\d+(\.\d{1,2})?$/.test(recordedValue)
            }
          },
          note: {},
          percentage: {}
        }
      }
    }
  },
  computed: {
    charges() {
      return (
        this.$store.getters["reference/orderChargeType"] &&
        this.$store.getters["reference/orderChargeType"].filter(item => item.id == "custom")
      )
    },
    isRetailer() {
      return this.role === "retailer" || this.$route.name === "adminRetailerOrder"
    },
    isBrand() {
      return this.role === "brand" || this.$route.name === "adminBrandOrder"
    },
    additionalCharges() {
      if (this.isBrand) {
        return this.order.charges && this.order.charges.filter(item => item.seenByBrand)
      } else if (this.isRetailer) {
        return this.order.charges && this.order.charges.filter(item => item.seenByRetailer)
      } else {
        return this.order.charges
      }
    },
    termsCharges() {
      if (this.isRetailer) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              (item.chargeType.id === "damages" || item.chargeType.id === "marketing") &&
              item.seenByRetailer
          )
        )
      } else if (this.isBrand) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              (item.chargeType.id === "damages" || item.chargeType.id === "marketing") &&
              item.seenByBrand
          )
        )
      } else {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item => item.chargeType.id === "damages" || item.chargeType.id === "marketing"
          )
        )
      }
    },
    otherCharges() {
      if (this.isRetailer) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.chargeType.id !== "damages" &&
              item.chargeType.id !== "marketing" &&
              item.seenByRetailer
          )
        )
      } else if (this.isBrand) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.chargeType.id !== "damages" &&
              item.chargeType.id !== "marketing" &&
              item.seenByBrand
          )
        )
      } else {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item => item.chargeType.id !== "damages" && item.chargeType.id !== "marketing"
          )
        )
      }
    },
    role() {
      return this.$store.getters["user/role"]
    },
    totalAdditionalCharge() {
      if (this.additionalCharges) {
        return this.additionalCharges.reduce((total, charge) => {
          if (charge.paidBy.id == "brand") {
            return total - parseFloat(charge.calculatedCostUsd)
          } else {
            return total + parseFloat(charge.calculatedCostUsd)
          }
        }, 0)
      } else {
        return 0
      }
    },
    totalAmount() {
      return this.totalAdditionalCharge + parseFloat(this.order.calculatedWholesaleCostUsd)
    }
  },
  methods: {
    warningOldHistory() {
      this.$message.warning("Old orders cannot be modified")
    },
    saveEditedCharges(index) {
      this.otherCharges[index].chargeType = this.additionalCharge.chargeType
      this.otherCharges[index].note = this.additionalCharge.note
      this.otherCharges[index].recordedValue = this.additionalCharge.recordedValue
      this.otherCharges[index].percentage = this.additionalCharge.percentage
      this.updateCharges()
      this.editingIndex = -1
      this.isEditing = false
    },
    showDropdown(index) {
      this.itemIndex = index
    },
    enableEditing(index, charge) {
      this.editingIndex = index
      this.additionalCharge = { ...charge }
      this.isEditing = true
    },
    removeCharges(charge) {
      this.order.charges = this.order.charges.filter(item => item !== charge)
      this.updateCharges()
      this.hideDropdown()
    },
    hideDropdown() {
      this.itemIndex = -1
    },
    onSelectingCharge(event) {
      const selectedCharge = this.charges.filter(item => item.id === event.target.value)[0]
      this.additionalCharge.note = selectedCharge.note
      this.additionalCharge.chargeType.name = selectedCharge.name
      this.additionalCharge.recordedValue = selectedCharge.amount
      this.additionalCharge.seenByBrand = true
      this.additionalCharge.seenByLanding = true
      this.additionalCharge.seenByRetailer = true
      this.additionalCharge.ownedBy.id = this.role === "retailer" ? "retailer" : "brand"
      this.additionalCharge.paidBy.id = this.role === "retailer" ? "brand" : "retailer"
      if (this.$route.name == "adminBrandOrder") {
        this.additionalCharge.ownedBy.id = "brand"
        this.additionalCharge.paidBy.id = "retailer"
      }
      if (this.$route.name == "adminRetailerOrder") {
        this.additionalCharge.ownedBy.id = "retailer"
        this.additionalCharge.paidBy.id = "brand"
      }
    },
    saveCharges() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        this.order.charges.push(this.additionalCharge)
        this.updateCharges()
        this.showAddChargeFileds = false
      }
    },
    cancelCharges() {
      this.editingIndex = -1
      this.showAddChargeFileds = false
      this.isEditing = false
      this.resetForm()
    },
    showAddCharges() {
      this.warningOldHistory()
      // this.isEditing = true
      // this.showAddChargeFileds = !this.showAddChargeFileds
    },
    resetForm() {
      this.isEditing = false
      this.additionalCharge = {
        chargeType: {
          id: "",
          name: ""
        },
        ownedBy: {
          id: ""
        },
        paidBy: {
          id: ""
        },
        percentage: false,
        seenByBrand: false,
        seenByLanding: false,
        seenByRetailer: false,
        recordedValue: null,
        calculatedCostUsd: 0.0,
        note: ""
      }
      this.$v.additionalCharge.$reset()
    },
    updateAdditionalChargeObject() {
      return {
        id: this.order.id,
        version: Number(this.order.version) + 1,
        charges: this.order.charges,
        isBrandAccepted:
          this.role === "brand" || this.$route.name === "adminBrandOrder"
            ? this.order.brandAccepted
            : false,
        isRetailerAccepted:
          this.role === "retailer" || this.$route.name === "adminRetailerOrder"
            ? this.order.retailerAccepted
            : false
      }
    },
    async updateCharges() {
      let order = this.updateAdditionalChargeObject()
      let params = {}
      if (this.$route.name == "adminBrandOrder") {
        params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      }
      if (this.$route.name == "adminRetailerOrder") {
        params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      }
      try {
        await this.$store.dispatch("order/updateStatus", { order, params })
        this.resetForm()
      } catch (error) {
        console.error()
      }
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      })
      return numberFormat.format(price)
    }
  }
}
</script>

<style lang="sass">
.old-retailer-order-table__header-container
  .button[disabled]
    cursor: default
  display: flex
  justify-content: space-between
  .add-charge-button
    margin-right: 10px
.additional-charge
  width: 100%
  select
    width: 100%
    height: 36px
.amount-input-container
  display: flex
  margin-bottom: 7px
  .input
    width: 70px
    margin-right: 4px
  .additional-charge-dropdown
    .select
      height: 36px
      select
        height: 100%
.additional-charge-buttons
  width: 129px
  display: flex
  justify-content: flex-end
  padding: 5px 0
  .button[disabled]
    cursor: default
  .save-additional-charge
    margin-right: 10px
    font-size: 14px
    height: 25px
    width: 50px
  .cancel-additional-charge
    font-size: 14px
    height: 25px
    width: 50px
    text-decoration: underline
    color: #4250C7
    border: none
.adjust-width
  width: 120px
</style>
