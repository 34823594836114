import httpclient from "@/api/http-client";
import LoginMessage from "@/models/LoginMessage";

export default {
  endPoint: "/api/LoginMessages",
  async getLoginMessage(privateId) {
    return await httpclient
      .get(`${this.endPoint}/${privateId}`)
      .then((res) => new LoginMessage(res.data));
  },
  async updateLoginMessage(privateId, data) {
    return await httpclient
      .patch(`${this.endPoint}/${privateId}`, data)
      .then((res) => new LoginMessage(res.data));
  },
};
