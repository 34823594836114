import { render, staticRenderFns } from "./editor_com.vue?vue&type=template&id=de8b2686"
import script from "./editor_com.vue?vue&type=script&lang=js"
export * from "./editor_com.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./editor_com.vue?vue&type=style&index=1&id=de8b2686&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports