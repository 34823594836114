<template>
  <div class="wrap">
    <carousel :data-list="dataList" :carousel-attrs="attrs"></carousel>
  </div>
</template>

<script>
import carousel from "./components/Carousel.vue"
export default {
  name: "AgencyHomePage",
  components: {
    carousel
  },
  data() {
    return {
      dataList: [],
      attrs: [],
      carouselAttrs: [
        {
          fontPosition: "right",
          hasContent: true,
          imageSrc: "agency_banner_2.png",
          imagePosition: "right"
        },
        {
          fontPosition: "left",
          imageSrc: "agency_banner_3.png",
          imagePosition: "right"
        },
        // {
        //   fontPosition: "left",
        //   imageSrc: "agency_banner_4.png",
        //   imagePosition: "left"
        // },
        {
          fontPosition: "left",
          imageSrc: "agency_banner_5.png",
          imagePosition: "left"
        },
        {
          fontPosition: "left",
          imageSrc: "agency_banner_1.png",
          imagePosition: "left"
        }
      ]
    }
  },
  mounted() {
    this.initCarouselData()
  },
  methods: {
    initCarouselData() {
      if (this.$route.query.id) {
        let queryIndex = Number(this.$route.query.id)
        let list = this.$t("AgencyDetail").dataList
        list = list.slice(queryIndex)
        this.attrs = this.carouselAttrs.slice(queryIndex)
        list.push(...this.$t("AgencyDetail").dataList.slice(0, queryIndex))
        this.attrs.push(...this.carouselAttrs.slice(0, queryIndex))
        this.dataList = list
        return
      }
      this.dataList = this.$t("AgencyDetail").dataList
      this.attrs = this.carouselAttrs
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/styles/shared/_fonts";

// this is font and style variable
$contentWidth: 1440px;
$titleFont: "Poppins-Bold";
$titleColor: #12433d;
$contentFont: "Proxima-Nova";
$fontSize: 18px;
$lineHeight: 22px;

.wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  // padding: 20px 0;

  .wrap-box {
    width: $contentWidth;
    font-family: $contentFont;
    line-height: $lineHeight;

    .page-description {
      max-width: 985px;
      width: 100%;
      padding-top: 94px;
      padding-bottom: 75px;
    }
  }
}
</style>
