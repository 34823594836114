<template>
  <div
    ref="editAddProductDialog"
    class="product-training-edit-add-dialog__main"
  >
    <a-modal
      v-model="isShow"
      width="960px"
      dialog-class="adminProductTrainingDialog"
      :footer="null"
      :get-container="() => $refs.editAddProductDialog"
      @cancel="closeDialog"
    >
      <div slot="title" style="text-align: center;">
        Product Training Details
      </div>
      <a-form-model ref="productTrainingForm" :model="form" :rules="rules">
        <a-row>
          <a-col :span="12" style="padding-right: 10px;">
            <a-row class="training-product-edit__row-margin">
              <a-col :span="4" class="heading1">Basics:</a-col>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <a-col :span="4">Brand:</a-col>
              <a-col :span="20">{{
                product && product.brand ? product.brand.name : ""
              }}</a-col>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <a-col :span="4">Name:</a-col>
              <a-col :span="20">{{ product ? product.name : "" }}</a-col>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <a-col :span="4">SKU:</a-col>
              <a-col :span="20">{{ product ? product.sku : "" }}</a-col>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <a-col :span="4">UPC:</a-col>
              <a-col :span="20">{{
                product && product.barcodes && product.barcodes.length > 0
                  ? product.barcodes[0].code
                  : ""
              }}</a-col>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <a-col :span="4">MSRP:</a-col>
              <a-col :span="20">${{ product ? product.msrpUsd : "" }}</a-col>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <a-col :span="4">Category:</a-col>
              <a-col :span="20">{{ getCategoryPath }}</a-col>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <a-col :span="24" style="text-align: left;">
                <span style="margin-right: 10px;">Hero</span>
                <a-switch
                  v-model="product.training.hero"
                  @change="huandleChangeValue"
                />
              </a-col>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <!-- <div class="heading1 subheader-padding">
                {{
                  this.$t("Training.brandTraining.trainingFormTips.stepLabel")
                }}:
              </div> -->
              <a-form-model-item
                :label="$t('Training.brandTraining.trainingFormTips.stepLabel')"
                prop="trainingSteps"
              >
                <a-select
                  v-model="form.trainingSteps"
                  style="width: 100%;"
                  mode="multiple"
                  :show-arrow="true"
                  :label-in-value="true"
                  placeholder="Select up to 2"
                  @deselect="huandleChangeValue"
                  @blur="huandleChangeValue"
                  @change="handleChange($event, 2)"
                >
                  <a-spin
                    v-if="isLoading.categorySteps"
                    size="small"
                    slot="suffixIcon"
                  />
                  <a-select-option
                    v-for="(step, index) in steps"
                    :key="index"
                    :value="step.id"
                  >
                    {{ step.description }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <!-- <div class="heading1 subheader-padding">
                {{
                  this.$t(
                    "Training.brandTraining.trainingFormTips.useForLabel"
                  )
                }}:
              </div> -->
              <a-form-model-item
                :label="
                  $t('Training.brandTraining.trainingFormTips.useForLabel')
                "
                prop="trainingBestUsedFors"
              >
                <a-select
                  v-model="form.trainingBestUsedFors"
                  mode="multiple"
                  style="width: 100%;"
                  :show-arrow="true"
                  :label-in-value="true"
                  placeholder="Select up to 5"
                  @deselect="huandleChangeValue"
                  @blur="huandleChangeValue"
                  @change="handleChange($event, 5)"
                >
                  <a-spin
                    v-if="isLoading.categoryBestUsedFors"
                    size="small"
                    slot="suffixIcon"
                  />
                  <a-select-option
                    v-for="(item, index) in bestUsedFors"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <div class="heading1 subheader-padding">
                {{
                  this.$t(
                    "Training.brandTraining.trainingFormTips.crossSellersLabel"
                  )
                }}:
              </div>
              <a-select
                v-model="form.trainingCrossSellers"
                mode="multiple"
                style="width: 100%;"
                :show-arrow="true"
                :label-in-value="true"
                :max-tag-count="3"
                placeholder="Select up to 3 (Optional)"
                @deselect="huandleChangeValue"
                @blur="huandleChangeValue"
                @change="handleChange($event, 3)"
              >
                <a-spin
                  v-if="isLoading.crossSellers"
                  size="small"
                  slot="suffixIcon"
                />
                <a-select-option
                  v-for="(item, index) in crossSellers"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-row>
            <div class="ant-col ant-form-item-label">
              <label
                title="Pro Tips"
                class="ant-form-item-required ant-form-item-no-colon"
              >
                Pro Tips: <a-spin v-if="isLoading.proTips" size="small" />
              </label>
            </div>

            <a-row class="training-product-edit__row-margin">
              <a-form-model-item label="" prop="proTips[0]">
                <a-textarea
                  v-model="form.proTips[0]"
                  :max-length="135"
                  class="proTipTxtArea"
                  :placeholder="proTipsPlaceholderText"
                  :auto-size="{ minRows: 5, maxRows: 6 }"
                  @blur="changeValue"
                  :loading="isLoading.proTips"
                ></a-textarea>

                <span class="training-product-edit__number-text"
                  >{{ form.proTips[0].length || 0 }}/135</span
                >
              </a-form-model-item>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <a-form-model-item label="" prop="proTips[1]">
                <a-textarea
                  v-model="form.proTips[1]"
                  :max-length="135"
                  class="proTipTxtArea"
                  :placeholder="proTipsPlaceholderText"
                  :auto-size="{ minRows: 5, maxRows: 6 }"
                  @blur="changeValue"
                  :loading="isLoading.proTips"
                />

                <span class="training-product-edit__number-text"
                  >{{ form.proTips[1].length || 0 }}/135</span
                >
              </a-form-model-item>
            </a-row>
            <a-row class="training-product-edit__row-margin">
              <a-form-model-item label="" prop="proTips[2]">
                <a-textarea
                  v-model="form.proTips[2]"
                  :max-length="135"
                  class="proTipTxtArea"
                  :placeholder="proTipsPlaceholderText"
                  :auto-size="{ minRows: 5, maxRows: 6 }"
                  @blur="changeValue"
                  :loading="isLoading.proTips"
                />
                <span class="training-product-edit__number-text"
                  >{{ form.proTips[2].length || 0 }}/135</span
                >
              </a-form-model-item>
            </a-row>

            <!-- <a-row class="training-product-edit__row-margin">
            <div class="demo-tip-style">
              {{ this.$t("Training.brandTraining.trainingFormTips.demoTipsLabel") }}
            </div>
            <a-textarea
              v-model="demoTips"
              :max-length="250"
              class="textarea-style"
              :placeholder="demoText"
              :auto-size="{ minRows: 5, maxRows: 6 }"
              @blur="changeValue"
            />
            <span class="training-product-edit__number-text">{{ demoTips.length || 0 }}/250</span>
          </a-row> -->
          </a-col>
          <!---right column-->
          <a-col :span="12" style="padding-left: 10px;">
            <div class="vide-image-content-two">
              <div class="video-content-left">
                <p>Videos</p>
                <div class="content-two-style">
                  <div
                    v-for="(item, index) in imgAssetsList.filter(
                      (video) =>
                        video.asset && video.asset.contentType.includes('video')
                    )"
                    :key="index"
                  >
                    <div
                      v-if="item.hasNoUrl"
                      class="video-style-main no-video-style"
                      @click="openVideoDialog(index)"
                    >
                      <span class="retailer-icon-style">+</span>
                    </div>
                    <div v-else class="video-style-main has-video-style">
                      <span class="close-icon" @click="closeVideo(item, index)"
                        ><a-icon type="close"
                      /></span>
                      <div @click="clickToVideo(item, index)">
                        <img
                          class="public-start"
                          width="21"
                          height="21"
                          src="../../assets/training_public_video.jpg"
                          alt=""
                        />
                        <video
                          width="auto"
                          height="65"
                          style="height: 35px !important;margin-top: 16px; margin-left: 2px"
                        >
                          <source :src="item.asset.url" type="video/mp4" />
                          Your browser does not support the HTML5 video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
                <p style="margin-top: 8px;font-size: 8px">
                  Videos must be 500MB or less In .mpeg, .wmv
                </p>
              </div>
              <div class="video-content-left image-content-right">
                <p>Images</p>
                <div class="content-two-style">
                  <div
                    v-for="(item, index) in imgAssetsList.filter(
                      (video) =>
                        video.asset && video.asset.contentType.includes('image')
                    )"
                    :key="index"
                  >
                    <div
                      v-if="item.hasNoUrl"
                      class="video-style-main no-video-style"
                      :class="noImageUrl ? 'no-image-style' : ''"
                      @click="openImageDialog(index)"
                    >
                      <span class="retailer-icon-style">+</span>
                    </div>
                    <div v-else class="video-style-main has-video-style">
                      <span class="close-icon" @click="closeImage(item, index)"
                        ><a-icon type="close"
                      /></span>
                      <div
                        style="text-align: center; height: 65px"
                        @click="clickToViewImages(item, index)"
                      >
                        <img
                          :src="item.asset.url"
                          alt=""
                          style="height: 62px;border-radius: 7px"
                        />
                        <!--                      <a-image style="width: 69px; height: 65px;" :src="item.asset.url"/>-->
                      </div>
                    </div>
                  </div>
                </div>
                <p style="margin-top: 8px;font-size: 8px">
                  Images must be 20MB or less In JPEG，PNG，GIF，TIFF
                </p>
              </div>
            </div>
            <!--      <a-row class="training-product-edit__row-margin">-->
            <!--        <a-col :span="12">-->
            <!--          Videos-->
            <!--        </a-col>-->
            <!--        <a-col :span="12">-->
            <!--          Images-->
            <!--        </a-col>-->
            <!--        <a-col :span="12">-->
            <!--          <a-row>-->
            <!--            <div class="detail-video-info">-->
            <!--              <div v-if="videoUrl" class="video-style" @click="clickToVideo">-->
            <!--                &lt;!&ndash;            <img class="public-start" src="../../../../assets/training_public_video.jpg" alt="" />&ndash;&gt;-->
            <!--                <img class="public-start" src="../../assets/training_public_video.jpg" alt="" />-->
            <!--                &lt;!&ndash;          <span class="public-start background-style"></span>&ndash;&gt;-->
            <!--                <img class="video-img" :src="imageUrl" alt="" />-->
            <!--              </div>-->
            <!--              <div class="add-second-video">-->
            <!--                <a-upload-->
            <!--                  v-model="secondVideo"-->
            <!--                  name="avatar"-->
            <!--                  list-type="picture-card"-->
            <!--                  class="avatar-uploader"-->
            <!--                  :show-upload-list="false"-->
            <!--                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"-->
            <!--                  :before-upload="beforeUpload"-->
            <!--                  @change="handleChange"-->
            <!--                >-->
            <!--                </a-upload>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            &lt;!&ndash;            <a-button&ndash;&gt;-->
            <!--            &lt;!&ndash;              :class="uploadBtn ? 'upload-success' : 'upload-video-style'"&ndash;&gt;-->
            <!--            &lt;!&ndash;              style="margin-left: 10px;"&ndash;&gt;-->
            <!--            &lt;!&ndash;              @click="openDialog"&ndash;&gt;-->
            <!--            &lt;!&ndash;            >&ndash;&gt;-->
            <!--            &lt;!&ndash;              <a-icon v-if="uploadBtn" type="check-circle" />&ndash;&gt;-->
            <!--            &lt;!&ndash;              <a-icon v-else type="upload" />&ndash;&gt;-->
            <!--            &lt;!&ndash;              {{ uploadText }}&ndash;&gt;-->
            <!--            &lt;!&ndash;            </a-button>&ndash;&gt;-->
            <!--          </a-row>-->
            <!--          <a-row style="margin-top: 8px;font-size: 8px">-->
            <!--            Videos must be 500MB or less In .abc, .abc, .abc-->
            <!--          </a-row>-->
            <!--        </a-col>-->
            <!--        <a-col :span="12">-->
            <!--          <a-row>-->
            <!--            <img :src="productImg" alt="" style="width: 69px; height: 65px;" />-->
            <!--          </a-row>-->
            <!--        </a-col>-->
            <!--      </a-row>-->
            <a-row
              class="training-product-edit__row-margin key-benefits-info-style"
            >
              <div class="heading1">
                {{
                  this.$t(
                    "Training.brandTraining.prodEditCardTips.keyBenefits"
                  )
                }}:
              </div>
              <ul id="benefits">
                <li v-for="item in product.newBenefits" :key="item.id">
                  {{ item.name }}
                </li>
              </ul>
            </a-row>
            <a-row>
              <!-- <div class="heading1 padding-bottom">How To Demo:</div> -->
              <div class="ant-col ant-form-item-label">
                <label
                  title="Pro Tips"
                  class="ant-form-item-required ant-form-item-no-colon"
                >
                  How To Demo:
                  <a-spin v-if="isLoading.proTips" size="small" />
                </label>
              </div>
              <a-form-model-item label="" prop="howToDemo">
                <a-textarea
                  v-model="form.howToDemo"
                  class="textarea-style"
                  :placeholder="tipsText"
                  :max-length="300"
                  :auto-size="{ minRows: 5, maxRows: 5 }"
                  @blur="changeValue"
                />
                <span class="training-product-edit__number-text"
                  >{{ form.howToDemo.length || 0 }}/300</span
                >
              </a-form-model-item>
            </a-row>
            <a-row v-if="isFragrance">
              <div class="fragrance-header">{{ $t('Training.brandTraining.trainingFormTips.fragranceNoteHeader') }}</div>
              <div class="frgrance-sub-header">{{ $t('Training.brandTraining.trainingFormTips.fragranceNoteSubHeader') }}</div>
              <a-form-model-item
                :label="
                  $t('Training.brandTraining.trainingFormTips.topNote')
                "
                prop="topNotes"
              >
                <a-select
                  v-model="form.topNotes"
                  mode="multiple"
                  style="width: 100%;"
                  :show-arrow="true"
                  :filterOption ="fragranceFilterOption"
                  :loading="isLoading.fragrances || isLoading.productFragrances"
                  optionFilterProp="name"
                  placeholder="Select up to 5"
                
                  @select="fragranceNoteChange(0, 1, $event)"
                  @deselect="fragranceNoteChange(0, 0, $event)"
                >
                  <a-spin
                    v-if="isLoading.fragrances || isLoading.productFragrances"
                    size="small"
                    slot="suffixIcon"
                  />
                  <a-select-option
                    v-for="(item, index) in fragranceNotes"
                    :key="index"
                    :value="item.privateId"
                    :disabled="fragranceIsDisabled(0, item.privateId)"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                :label="
                  $t('Training.brandTraining.trainingFormTips.heartNote')
                "
                prop="heartNotes"
              >
                <a-select
                  v-model="form.heartNotes"
                  mode="multiple"
                  style="width: 100%;"
                  :show-arrow="true"
                  :filterOption ="fragranceFilterOption"
                  :loading="isLoading.fragrances || isLoading.productFragrances"
                  placeholder="Select up to 5"
                  @select="fragranceNoteChange(1, 1, $event)"
                  @deselect="fragranceNoteChange(1, 0, $event)"
                >
                  <a-spin
                    v-if="isLoading.fragrances || isLoading.productFragrances"
                    size="small"
                    slot="suffixIcon"
                  />
                  <a-select-option
                    v-for="(item, index) in fragranceNotes"
                    :key="index"
                    :value="item.privateId"
                    :disabled="fragranceIsDisabled(1, item.privateId)"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                :label="
                  $t('Training.brandTraining.trainingFormTips.baseNote')
                "
                prop="baseNotes"
              >
                <a-select
                  v-model="form.baseNotes"
                  mode="multiple"
                  style="width: 100%;"
                  :show-arrow="true"
                  :filterOption ="fragranceFilterOption"
                  :loading="isLoading.fragrances || isLoading.productFragrances"
                  placeholder="Select up to 5"
                  
                  @select="fragranceNoteChange(2, 1, $event)"
                  @deselect="fragranceNoteChange(2, 0, $event)"
                >
                  <a-spin
                    v-if="isLoading.categoryBestUsedFors"
                    size="small"
                    slot="suffixIcon"
                  />
                  <a-select-option
                    v-for="(item, index) in fragranceNotes"
                    :key="index"
                    :value="item.privateId"
                    :disabled="fragranceIsDisabled(2, item.privateId)"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-row>
            <!-- <a-row class="training-product-edit__row-margin">
            <div class="demo-tip-style">
              {{ this.$t("Training.brandTraining.trainingFormTips.demoTipsLabel") }}
            </div>
            <a-textarea
              v-model="demoTips"
              :max-length="250"
              class="textarea-style"
              :placeholder="demoText"
              :auto-size="{ minRows: 5, maxRows: 6 }"
              @blur="changeValue"
            />
            <span class="training-product-edit__number-text">{{ demoTips.length || 0 }}/250</span>
          </a-row> -->
          </a-col>
        </a-row>
      </a-form-model>
      <div class="product-training-button">
        <p
          v-if="isNewTraining"
          class="training-save-draft-button"
          @click="saveDraft"
        >
          Save Draft
        </p>
        <p class="training-cancel" @click="closeDialog">Cancel</p>
        <p class="training-public-button" @click="submitProduct">Publish</p>
      </div>
    </a-modal>
    <uploadVideo
      ref="uploadVideo"
      :product="product"
      @handleUploadInfo="handleUploadInfo"
    ></uploadVideo>
    <editVideo
      ref="editUploadVideo"
      :product="product"
      @handleEditVideoInfo="handleEditImageInfo"
    ></editVideo>
    <a-modal
      :visible="modalVisible"
      :footer="null"
      :destroy-on-close="true"
      :body-style="{ padding: 0 }"
      @cancel="handleCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div v-if="videoUrl && videoUrl.length !== 0" class="video-main">
        <video id="video1" ref="videoM" controls>
          <source id="videoSrc" :src="videoUrl" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
    </a-modal>
    <a-modal
      :visible="showImageVisible"
      :footer="null"
      :destroy-on-close="true"
      :body-style="{ padding: 0 }"
      @cancel="handleImgCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div
        style="width: 300px; min-height: 300px; line-height: 300px; margin: 0 auto"
      >
        <img :src="modalImage" alt="" style="width: 100%; height: auto" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import trainingClient from "@/api/training-client";
import { mapState, mapActions, mapGetters } from "vuex";
import uploadVideo from "@/components/training/upLoadVideoDialog";
import editVideo from "@/components/training/editVideoDialog";
import { checkArary } from "@/utils/validate";
import categoriesService from "@/services/categories-service";
import productsService from "@/services/products-service";
import fragrancesService from "@/services/fragrances-service";
import { FragranceNoteType } from "@/models/Fragrance";
// import ProductTraining from "@/models/ProductTraining"
export default {
  components: {
    uploadVideo,
    editVideo,
  },
  data() {
    return {
      maxFragranceSelection: 5,
      product: {
        training: {
          hero: false,
        },
        newBenefits: [],
        category: { id: null, name: null, parent: { id: null, name: null } },
        subcategory: { id: null, name: null, parent: { id: null, name: null } },
        microCategory: {
          id: null,
          name: null,
          parent: { id: null, name: null },
        },
      },
      isShow: false,
      isFragrance: false,
      sendParams: {},
      isNewTraining: false,
      uploadBtn: false,
      modalVisible: false,
      sendInfo: {},
      //howToDemo: "",
      demoTips: "",
      //proTips: ["", "", ""],
      sendFormTraining: {},
      trainingVideos: {},
      secondTrainingVideos: {},
      trainingVideosList: [], // Trainingvideo list
      form: {
        trainingSteps: [],
        trainingBestUsedFors: [],
        trainingCrossSellers: [],
        topNotes:[],
        heartNotes:[],
        baseNotes:[],
        howToDemo: "",
        proTips: ["", "", ""],
      },
      rules: {
        trainingSteps: [
          { required: true, message: "Step is required", trigger: "change" },
        ],
        trainingBestUsedFors: [
          {
            required: true,
            message: "Best Used For is required",
            trigger: "change",
          },
        ],
        topNotes: [
        { validator: this.validateFragranceSelection }
        ],
        heartNotes: [
          { validator: this.validateFragranceSelection }
        ],
        baseNotes: [
        { validator: this.validateFragranceSelection }
        ],
        howToDemo: [
          {
            required: true,
            message: "How To Demo is required",
            trigger: "blur",
          },
        ],
        "proTips[0]": [
          {
            required: true,
            message: "Pro Tips is required",
            trigger: "blur",
          },
        ],
        // "proTips[1]": [
        //   {
        //     required: true,
        //     message: "Pro Tips 2 is required",
        //     trigger: "blur",
        //   },
        // ],
        // "proTips[2]": [
        //   {
        //     required: true,
        //     message: "Pro Tips 3 is required",
        //     trigger: "blur",
        //   },
        // ],
      },
      formLayout: "vertical",
      videoUrl: null,
      imageUrl: null,
      secondVideo: null,
      defaultNoVideo: {
        hasNoUrl: true,
        asset: {
          url: "",
          contentType: "video",
        },
        title: "",
      },
      defaultNoimg: {
        hasNoUrl: true,
        asset: {
          url: "",
          contentType: "image",
        },
        title: "",
      },
      imgAssetsList: [],
      imgVideoNumber: 0,
      imgImageNumber: 0,
      showImageVisible: false,
      modalImage: "",
      noImageUrl: false,
      bestUsedFors: [],
      steps: [],
      stepLookup: new Map(),
      bestUsedForLookup: new Map(),
      crossSellers: [],
      isLoading: {
        steps: true,
        bestUsedFors: true,
        crossSellers: true,
        proTips: true,
        fragrances: true,
        productFragrances: true
      },
      cacheKey: {
        categoryPrivateId: null,
        bandId: null,
      },
      fragranceNotes: [],
      selectedFrgranceNotes: [],
      fragranceNoteSelectedHash: {}
    };
  },
  computed: {
    ...mapState({
      bestUsedFor: (state) => state.reference.productBestUsedFor,
      step: (state) => state.reference.productStep,
      categoryLookup: (state) => state.reference.newData.productCategoryLookup,
      userId: (state) => state.user.businessId,
    }),
    ...mapGetters({
      getCrossSellers: "training/getCrossSellers",
    }),
    getCategoryPath() {
      var catPath =
        this.product && this.product.category ? this.product.category.name : "";
      catPath +=
        this.product && this.product.subcategory
          ? " > " + this.product.subcategory.name
          : "";
      catPath +=
        this.product && this.product.microCategory
          ? " > " + this.product.microCategory.name
          : "";
      return catPath;
    },
    tipsText() {
      return this.$t("Training.brandTraining.textareaDemoInfo");
    },
    demoText() {
      return this.$t("Training.brandTraining.textareaTipsInfo");
    },
    proTipsPlaceholderText() {
      return this.$t("Training.brandTraining.textareaProTipsInfo");
    },
    productImg() {
      const list = this.product.images.filter((item) => item.main === true);
      return list[0].asset.url;
    },
    uploadText() {
      return this.uploadBtn
        ? "Replace Video"
        : this.$t("Training.brandTraining.trainingFormTips.uploadVideoLabel");
    },
  },
  watch: {
    modalVisible() {
      let video = document.getElementById("video1");
      if (video) {
        if (this.modalVisible === false) {
          video.pause();
        } else {
          video.play();
        }
      }
    },
    // product(newValue, old) {
    //   console.log(old)
    //   // console.log(newValue)
    //   this.init(newValue)
    // }
  },
  // async mounted() {
  //   this.init(this.product)
  //   await this.getRefData()
  //   await this.getProductTraining({
  //     brand: this.userId,
  //     "have-training": "have-training",
  //     _limit: 999
  //   })
  // },
  methods: {
    ...mapActions({
      getRefData: "reference/setReference",
      getProductTraining: "training/getProductTraining",
    }),
    async openDialog(data, isNewTraining) {
      // this.noImageUrl = false
      this.product = JSON.parse(JSON.stringify(data));
      this.isFragrance = this.product.category.id === 'fragrance';
      this.resetProTips();
      this.isShow = true;
      this.isNewTraining = isNewTraining;

      await Promise.all([
        this.loadProTips(),
        this.loadFragrances(),
        this.loadProductFragrances(),
        this.loadCategoryData(),
        this.getRefData(),
        this.loadCrossSellerData(),
      ]);

      this.init(data);

      // await this.getProductTraining({
      //   brand: this.userId,
      //   "have-training": "have-training",
      //   _limit: 999
      // })
    },
    closeDialog() {
      this.isShow = false;
      this.product = {
        training: {
          hero: false,
        },
        newBenefits: [],
      };
      this.$refs.productTrainingForm.resetFields();
    },
    fragranceFilterOption(input, option) {
      return option.componentOptions.children[0].text.trim().toLowerCase().startsWith(input.toLowerCase());
    },
    fragranceNoteChange(which, trigger, value) {
      if (value) {
        this.fragranceNoteSelectedHash[value] = trigger ? which : null;
      }
    },

    fragranceIsDisabled(which, value) {
      return this.fragranceNoteSelectedHash[value] != void(0) && this.fragranceNoteSelectedHash[value] != which;
    },
    submitProduct() {
      // 2022-6-29: There is no error without adding pictures
      // const imgResult =
      //   (this.sendParams.trainingAssets &&
      //     this.sendParams.trainingAssets.filter(item =>
      //       item.asset.contentType.includes("image")
      //     )) ||
      //   []
      // if (imgResult && imgResult.length === 0) {
      //   this.noImageUrl = true
      //   this.$message.error("Please add at least 1 image to publish")
      // }
      this.changeValue();
      this.submit(this.sendParams.id, this.sendParams);
    },
    submitOld(id, param) {
      //update pro tip separately using new api:
      this.productTraining.proTip1 = this.form.proTips[0];
      this.productTraining.proTip2 = this.form.proTips[1];
      this.productTraining.proTip3 = this.form.proTips[2];
      trainingClient
        .updateBrandTrainingProTips(id, this.productTraining)
        .then(() => {
          trainingClient
            .updateTrainingProduct(id, param)
            .then(() => {
              console.log("updating product...", id, param);
              // this.$store.dispatch("training/getProductList", this.productData.brand.id)
              this.$emit("updateNewTrainingList");
              this.isShow = false;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },

    async updateAll(productId) {
      return await Promise.all([
        this.updateProTips(productId),
        this.updateProductFragrances(productId)
      ]);
    },
    updateProTips(productId) {
      this.productTraining.proTip1 = this.form.proTips[0];
      this.productTraining.proTip2 = this.form.proTips[1];
      this.productTraining.proTip3 = this.form.proTips[2];
      trainingClient
        .updateBrandTrainingProTips(productId, this.productTraining)
        .then(() => {
          this.$emit("updateNewTrainingList");
          //this.isShow = false;
          this.closeDialog();
        });
    },
    
    submit(id, param) {
      this.$refs.productTrainingForm.validate((valid) => {
        if (valid) {
          this._submit(id, param);
        }
      });
    },
    _submit(id, param) {
      trainingClient
        .updateTrainingProduct(id, param)
        .then(() => {
          console.log("updating product...", id, param);
          if (this.productTraining == null) {
            this.getProTips(id).then((training) => {
              this.productTraining = training;
              this.updateAll(id);
            });
          } else {
            this.updateAll(id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveDraft() {
      this.changeValue();
      // If it is in the Save Draft state, state is 0 and isPublish is false
      const param = {
        ...this.sendParams,
        training: {
          ...this.sendParams.training,
          states: 0,
          isPublished: false,
        },
      };
      await this.submit(this.sendParams.id, param);
      this.$message.success("Your draft has been saved");
      //this.isShow = false;
    },
    formatTrainingAssets(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].imgAsset && Object.values(list[i].imgAsset).length !== 0) {
          const obj = {
            asset: {
              ...list[i].imgAsset,
            },
            title: list[i].title,
          };
          list[i].imgAsset = {};
          list.push(obj);
        }
      }
      return list;
    },
    init(data) {
      this.imgAssetsList = [];
      this.imgVideoNumber = 0;
      this.imgImageNumber = 0;
      this.form.howToDemo = (data.training && data.training.howToDemo) || "";
      this.demoTips = (data.training && data.training.demoTips) || "";
      // this.proTips = ["pro tip 1", "pro tip 2", "pro tip 3"]
      this.uploadBtn = data.trainingAssets && data.trainingAssets.length !== 0;
      /* update name */

      data.trainingSteps &&
        data.trainingSteps.forEach((s) => {
          if (this.stepLookup.has(s.id)) {
            s.name = this.stepLookup.get(s.id).description;
          }
        });
      this.form.trainingSteps = this.checkValue(data.trainingSteps);
      data.trainingBestUsedFors &&
        data.trainingBestUsedFors.forEach((buf) => {
          if (this.bestUsedForLookup.has(buf.id)) {
            buf.name = this.bestUsedForLookup.get(buf.id).description;
          }
        });
      this.form.trainingBestUsedFors = this.checkValue(
        data.trainingBestUsedFors
      );
      this.form.trainingCrossSellers = this.checkValue(
        data.trainingCrossSellers
      );
      // if (data.trainingVideos && data.trainingVideos.length !== 0) {
      //   this.videoUrl = data.trainingVideos[0].asset.url
      //   this.imageUrl = data.trainingVideos[0].imgAsset.url
      // }
      this.imgAssetsList = this.formatTrainingVideos(data.trainingAssets);
      console.log("product", this.product);
      console.log("userId", this.userId);
      //console.log("bestUsedFor", this.bestUsedFor);
      //console.log("step", this.step);
      //console.log("getCrossSellers", this.getCrossSellers)
    },
    resetProTips() {
      this.form.proTips = ["", "", ""];
    },
    validateFragranceSelection(rule, value, callback ) {
      if (value.length > this.maxFragranceSelection) {
        callback(new Error(`Maximum ${this.maxFragranceSelection} notes reached.`));
      } else {

        callback();
      }
    },
    async loadFragrances() {
      this.isLoading.fragrances = true;
      const fragrances = await this.getFragrances().finally(
        () => {
          this.isLoading.fragrances = false;
        }
      )
      this.fragranceNotes = fragrances;
    },
    async loadProductFragrances() {
      this.isLoading.productFragrances = true;
      const productFragrances = await this.getProductFragrances(this.product.id).finally(
        () => {
          this.isLoading.productFragrances = false;
        }
      )
      this.form.topNotes = productFragrances.filter(fn => fn.productFragranceNotes.some(pfn => pfn.fragranceNoteType == FragranceNoteType.Top)).map(fn => {
        this.fragranceNoteChange(0, 1, fn.privateId);
        return fn.privateId;
      });
      this.form.heartNotes = productFragrances.filter(fn => fn.productFragranceNotes.some(pfn => pfn.fragranceNoteType == FragranceNoteType.Heart)).map(fn => {
        this.fragranceNoteChange(1, 1, fn.privateId);
        return fn.privateId;
      });
      this.form.baseNotes = productFragrances.filter(fn => fn.productFragranceNotes.some(pfn => pfn.fragranceNoteType == FragranceNoteType.Base)).map(fn => {
        this.fragranceNoteChange(2, 1, fn.privateId);
        return fn.privateId;
      });
    },
    async updateProductFragrances(productId) {
      const topNotes = this.form.topNotes.map(fid => {
        return {
          fragranceNotePrivateId: fid,
          noteType: FragranceNoteType.Top
        }
      });
      const heartNotes = this.form.heartNotes.map(fid => {
        return {
          fragranceNotePrivateId: fid,
          noteType: FragranceNoteType.Heart
        }
      });
      const baseNotes = this.form.baseNotes.map(fid => {
        return {
          fragranceNotePrivateId: fid,
          noteType: FragranceNoteType.Base
        }
      });
      const notes =  [...topNotes, ...heartNotes, ...baseNotes];;
      return await fragrancesService.updateProductFragrances(productId, notes);
    },
    async loadProTips() {
      this.isLoading.proTips = true;
      this.productTraining = await this.getProTips(this.product.id).finally(
        () => {
          this.isLoading.proTips = false;
        }
      );
      if (this.productTraining != null) {
        var proTip1 =
          this.productTraining.proTip1 != null
            ? this.productTraining.proTip1
            : "";
        var proTip2 =
          this.productTraining.proTip2 != null
            ? this.productTraining.proTip2
            : "";
        var proTip3 =
          this.productTraining.proTip3 != null
            ? this.productTraining.proTip3
            : "";
        this.form.proTips = [proTip1, proTip2, proTip3];
      } else {
        this.resetProTips();
      }

      console.log("proTips", this.proTips, this.productTraining);
    },
    async getProTips(productId) {
      return await trainingClient
        .getBrandTrainingProTips(productId)
        .catch((error) => {
          if (error.status == 404) {
            return null;
          } else {
            throw error;
          }
        });
    },
    async getFragrances() {
      return await fragrancesService.getFragrances()
    },
    async getProductFragrances(productId) {
      return await fragrancesService.getProductFragrances(productId);
    },
    async loadCrossSellerData() {
      const brandId = this.product.brand.id;
      if (this.cacheKey.bandId !== brandId) {
        this.cacheKey.brandId = brandId;
        this.isLoading.crossSellers = true;

        const products = await productsService
          .getProductsByBrand(brandId)
          .finally(() => {
            this.isLoading.crossSellers = false;
          });
        this.crossSellers = products.filter((p) => p.id !== this.product.id);
      }
    },
    async loadCategorySteps(category) {
      this.isLoading.categorySteps = true;
      const data = await categoriesService
        .getCategorySteps(category.privateId)
        .finally(() => {
          this.isLoading.categorySteps = false;
        });
      this.stepLookup.clear();
      this.steps = data.map((s) => {
        const step = {
          id: `${s.privateId}`, // code depend on the string instead of number
          name: s.id,
          description: s.name,
        };
        this.stepLookup.set(step.id, step);
        return step;
      });
    },
    async loadCategoryBestUsedFors(category) {
      this.isLoading.categoryBestUsedFors = true;
      const data = await categoriesService
        .getCategoryBestUsedFors(category.privateId)
        .finally(() => {
          this.isLoading.categoryBestUsedFors = false;
        });
      this.bestUsedForLookup.clear();
      this.bestUsedFors = data.map((buf) => {
        const bestUsedFor = {
          id: `${buf.privateId}`,
          name: buf.id,
          description: buf.name,
        };
        this.bestUsedForLookup.set(bestUsedFor.id, bestUsedFor);
        return bestUsedFor;
      });
      this.selectedFrgranceNotes = [
        this.form.topNotes,
        this.form.heartNotes,
        this.form.baseNotes
      ];
      // this.fragranceNotes = [
      //   { privateId: 1, name: 'test 1'},
      //   { privateId: 2, name: 'test 2'},
      //   { privateId: 3, name: 'test 3'},
      //   { privateId: 4, name: 'test 4'},
      //   { privateId: 5, name: 'test 5'},
      //   { privateId: 6, name: 'test 6'},
      // ];
    },
    async loadCategoryData() {
      const category = this.categoryLookup.get(this.product.category.id);
      if (this.cacheKey.categoryPrivateId !== category.privateId) {
        this.cacheKey.categoryPrivateId = category.privateId;
        await Promise.all([
          this.loadCategorySteps(category),
          this.loadCategoryBestUsedFors(category),
        ]);
      }
    },
    formatTrainingVideos(list) {
      if (!list || list.length === 0) {
        return [
          this.defaultNoVideo,
          this.defaultNoVideo,
          this.defaultNoimg,
          this.defaultNoimg,
        ];
      }
      if (list.length === 4) {
        return list;
      }
      let trainingAssetList = this.formatTrainingAssets(list);
      const trainingAssetImg = this.formatimageList(trainingAssetList);
      const trainingAssetVideo = this.formatVideoList(trainingAssetList);
      return trainingAssetImg.concat(trainingAssetVideo);
    },
    formatimageList(list) {
      let imageList = [];
      const imgResult = list.filter((item) =>
        item.asset.contentType.includes("image")
      );
      switch (imgResult.length) {
        case 0:
          imageList = [this.defaultNoimg, this.defaultNoimg];
          break;
        case 1:
          imageList = [imgResult[0], this.defaultNoimg];
          break;
        case 2:
          imageList = imgResult;
          break;
        default:
          imageList =
            imgResult.filter((item) => item.asset.url).length > 2
              ? imgResult.filter((item) => item.asset.url).slice(0, 2)
              : imgResult.filter((item) => item.asset.url);
      }
      return imageList;
    },
    formatVideoList(list) {
      let videoList = [];
      const videoResult = list.filter((item) =>
        item.asset.contentType.includes("video")
      );
      switch (videoResult.length) {
        case 0:
          videoList = [this.defaultNoVideo, this.defaultNoVideo];
          break;
        case 1:
          videoList = [videoResult[0], this.defaultNoVideo];
          break;
        case 2:
          videoList = videoResult;
          break;
        default:
          videoList =
            videoResult.filter((item) => item.asset.url).length > 2
              ? videoResult.filter((item) => item.asset.url).slice(0, 2)
              : videoResult.filter((item) => item.asset.url);
      }
      return videoList;
    },
    changeValue() {
      // this.sendInfo = {
      //   trainingAssets: this.trainingVideos
      // }
      this.sendParamsValue();
    },
    resetProductItemInfo() {
      this.imgVideoNumber = 0;
      this.imgImageNumber = 0;
      this.form.howToDemo = "";
      this.demoTips = "";
      this.proTips = ["", "", ""];
      this.productTraining = null;
      this.uploadBtn = false;
      this.form.trainingSteps = [];
      this.form.trainingBestUsedFors = [];
      this.form.trainingCrossSellers = [];
      // if (data.trainingVideos && data.trainingVideos.length !== 0) {
      //   this.videoUrl = data.trainingVideos[0].asset.url
      //   this.imageUrl = data.trainingVideos[0].imgAsset.url
      // }
      this.imgAssetsList = [];
    },
    checkValue(arr) {
      if (arr && arr.length !== 0) {
        return arr.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });
      }
    },
    formatTrainingAssetSend(list) {
      return list.filter((item) => item.asset.url);
    },
    sendParamsValue() {
      const dataToUpdate = {
        ...this.sendInfo,
        ...this.sendFormTraining,
        // trainingVideos: Object.keys(this.trainingVideos).length === 0 ? [] : [this.trainingVideos],
        training: {
          ...this.sendFormTraining.training,
          howToDemo: this.form.howToDemo,
          demoTips: this.demoTips,
          states: 1,
          isPublished: true,
          isHero: this.product.training.hero || false,
        },
      };
      // In the case of modification and not draft, it is necessary to add ID in Training
      const list = this.formatTrainingAssetSend(this.imgAssetsList);
      dataToUpdate.trainingAssets = list;
      dataToUpdate.training.howToDemo = this.form.howToDemo || "";
      dataToUpdate.training.demoTips = this.demoTips || "";
      dataToUpdate.training.id = this.product.training.id;
      dataToUpdate.training.states = this.product.training.states || 1;
      dataToUpdate.training.isPublished =
        this.product.training.published || true;
      dataToUpdate.training.sort = this.product.training.sort;
      dataToUpdate.trainingSteps =
        dataToUpdate.trainingSteps && dataToUpdate.trainingSteps.length !== 0
          ? dataToUpdate.trainingSteps
          : this.product.trainingSteps;
      dataToUpdate.trainingBestUsedFors =
        dataToUpdate.trainingBestUsedFors &&
        dataToUpdate.trainingBestUsedFors.length !== 0
          ? dataToUpdate.trainingBestUsedFors
          : this.product.trainingBestUsedFors;
      dataToUpdate.trainingCrossSellers =
        dataToUpdate.trainingCrossSellers &&
        dataToUpdate.trainingCrossSellers.length !== 0
          ? dataToUpdate.trainingCrossSellers
          : this.product.trainingCrossSellers;
      if (this.$route.query.draft) {
        dataToUpdate.training.states = 1;
        dataToUpdate.training.isPublished = true;
      }
      this.sendParams = { id: this.product.id, ...dataToUpdate };
      // return
    },
    formatTraining(data) {
      if (!data || !data.training.howToDemo) {
        return true;
      }
      // Because cross-seller is optional, so it can skip checking for nulls
      if (!checkArary(data.trainingSteps)) {
        return true;
      }
      if (!checkArary(data.trainingBestUsedFors)) {
        return true;
      }
      // if (!checkArary(data.trainingVideos)) {
      //   return true
      // }
    },
    openVideoDialog(index) {
      this.imgVideoNumber = index;
      this.$refs["uploadVideo"].openUploadVideo();
    },
    openImageDialog(index) {
      this.imgImageNumber = index;
      this.$refs["editUploadVideo"].openEditVideo();
    },
    // Upload video
    handleUploadInfo(sendInfo, isShowEdit) {
      const list = this.imgAssetsList.filter((item) =>
        item.asset.contentType.includes("video")
      );
      const otherList = this.imgAssetsList.filter(
        (item) => !item.asset.contentType.includes("video")
      );
      list[this.imgVideoNumber] = {
        asset: {
          url: sendInfo.file.url || "",
          id: sendInfo.file.id || "",
          contentType: sendInfo.file.contentType || "",
        },
      };
      this.imgAssetsList = otherList.concat(list);
      console.log(isShowEdit);
      this.sendParamsValue();
    },
    // Upload image
    handleEditImageInfo(imageInfo, title) {
      const list = this.imgAssetsList.filter((item) =>
        item.asset.contentType.includes("image")
      );
      const otherList = this.imgAssetsList.filter(
        (item) => !item.asset.contentType.includes("image")
      );
      list[this.imgImageNumber] = {
        asset: {
          ...imageInfo,
        },
        title,
      };
      this.uploadBtn = true;
      this.imgAssetsList = otherList.concat(list);
      this.sendParamsValue();
      // this.sendParamsValue()
    },
    handleCancel() {
      this.modalVisible = false;
      let video = document.getElementById("video1");
      if (video) {
        video.pause();
      }
    },
    handleImgCancel() {
      this.showImageVisible = false;
    },
    clickToVideo(item, index) {
      this.showModal = false;
      this.modalVisible = true;
      this.videoUrl = item.asset.url;
      this.imgVideoNumber = index;
      // console.log(this.product.trainingVideos)
      // console.log(this.$refs["videoM"])
      //   this.$refs["videoM"]
    },
    clickToViewImages(item, index) {
      console.log(index);
      this.showImageVisible = true;
      this.modalImage = item.asset.url;
    },
    huandleChangeValue() {
      const params = {
        trainingSteps: this.form.trainingSteps
          ? this.formatValue(this.form.trainingSteps)
          : [],
        trainingBestUsedFors: this.form.trainingBestUsedFors
          ? this.formatValue(this.form.trainingBestUsedFors)
          : [],
        trainingCrossSellers: this.form.trainingCrossSellers
          ? this.formatValue(this.form.trainingCrossSellers)
          : [],
        training: {
          isHero: this.product.training.hero,
        },
      };
      this.sendFormTraining = { ...params };
      this.sendParamsValue();
    },
    formatValue(arr) {
      return arr.map((item) => {
        return {
          id: item.key,
          name: item.label,
        };
      });
    },
    handleChange(value, maxSize) {
      if (value.length > maxSize) {
        let tem = value.slice(-1)[0];
        value.splice(maxSize - 1, value.length - 1, tem);
      }
      Object.assign(this, {
        value,
        data: {},
      });
    },
    closeVideo(item, index) {
      this.imgVideoNumber = index;
      const list = this.imgAssetsList.filter((item) =>
        item.asset.contentType.includes("video")
      );
      const otherList = this.imgAssetsList.filter(
        (item) => !item.asset.contentType.includes("video")
      );
      list[this.imgVideoNumber] = this.defaultNoVideo;
      this.imgAssetsList = otherList.concat(list);
      this.sendParamsValue();
    },
    closeImage(item, index) {
      this.imgImageNumber = index;
      const list = this.imgAssetsList.filter((item) =>
        item.asset.contentType.includes("image")
      );
      const otherList = this.imgAssetsList.filter(
        (item) => !item.asset.contentType.includes("image")
      );
      list[this.imgImageNumber] = this.defaultNoimg;
      this.uploadBtn = true;
      this.imgAssetsList = otherList.concat(list);
      this.sendParamsValue();
    },
  },
};
</script>
<style lang="scss">
.heading1 {
  font-weight: 800;
}
.fragrance-header {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.fragrance-sub-header {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 800;
}

.subheader-padding {
  padding-bottom: 8px;
}
.padding-bottom {
  padding-top: 8px;
  padding-bottom: 15px;
}
.proTipTxtArea {
  min-height: 80px !important;
  height: 50px !important;
}

.product-training-edit-add-dialog__main {
  .adminProductTrainingDialog .ant-modal-footer .ant-btn:hover {
    color: #4a8a5d;
    border-color: #4a8a5d;
  }
  .adminProductTrainingDialog .ant-modal-footer .ant-btn-primary {
    background-color: #4a8a5d;
    border-color: #4a8a5d;
  }
  .adminProductTrainingDialog .ant-modal-footer .ant-btn-primary:hover {
    color: white;
  }
  .adminProductTrainingDialog .ant-select-selection:hover {
    border-color: #4a8a5d;
  }
  .adminProductTrainingDialog .ant-input:hover {
    border-color: #4a8a5d;
  }
  // .ant-switch-checked {
  //   background-color: #4a8a5d !important;
  // }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #4a8a5d;
  }
  // .ant-tabs-nav .ant-tabs-tab:hover {
  //   color: #4a8a5d !important;
  // }
  .product-training-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    margin-top: 20px;
    .training-save-draft-button {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 7px;
      background-color: #8c8c8c;
      color: white;
      cursor: pointer;
    }
    .training-cancel {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 7px;
      background-color: #f27c59;
      color: white;
      margin: 0 13px;
      cursor: pointer;
    }
    .training-public-button {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 7px;
      background-color: #a0d29b;
      color: white;
      cursor: pointer;
    }
  }
  .training-product-edit {
    &__number-text {
      // float: right;
      display: flex;
      justify-content: flex-end;
      font-size: 10px;
      color: #262626;
      line-height: 1;
      padding-right: 2px;
    }
    &__row-margin {
      margin-bottom: 10px;
    }
  }
  .detail-video-info {
    display: inline-block;
    display: flex;
    justify-content: start;
    align-items: center;
    .video-style {
      width: 69px;
      height: 65px;
      margin-top: 16px;
      text-align: center;
      border: 1px solid #262626;
      cursor: pointer;
      position: relative;
      .video-img {
        //width: 100%;
        height: 100%;
      }
      .public-start {
        position: absolute;
        top: 17px;
        right: 14px;
        border-radius: 50%;
      }
      .background-style {
        width: 43px;
        height: 43px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        top: 34px;
        right: 83px;
      }
    }
    .add-second-video {
      width: 69px;
      height: 65px;
      //border: 1px solid #d9d9d9;
    }
  }
  .upload-video-style {
    width: 140px;
    border: 1px solid #4a8a5d;
    color: #4a8a5d;
    background-color: #e2eeec;
    text-align: center;
  }
  .upload-success {
    width: 140px;
    border: 1px solid #4a8a5d;
    color: #ffffff;
    background-color: #4a8a5d;
    text-align: center;
  }
  .key-benefits-info-style {
    margin-top: 46px;
    // height: 202px;
  }
  #benefits {
    list-style: disc;
    list-style-position: outside;
    padding: 10px 20px;
    max-height: 300px;
    overflow-y: auto;
  }
  #benefits li {
    padding: 10px 0;
  }
  //#benefits li {
  //  float: left;
  //}
  .ant-switch-checked {
    background-color: rgba(243, 202, 80, 1);
  }
  .vide-image-content-two {
    width: 100%;
    display: flex;
    justify-content: start;
    //align-items: center;
    .video-content-left {
      width: 49%;
    }
    .image-content-right {
      margin-left: 2%;
    }
    .content-two-style {
      display: flex;
    }
    .video-style-main {
      width: 69px;
      height: 65px;
      border-radius: 7px;
      border: 1px solid #d9d9d9;
      margin-right: 15px;
      cursor: pointer;
    }
    .no-image-style {
      border-color: red;
      color: red;
    }
    .no-video-style {
      text-align: center;
      line-height: 56px;
    }
    .has-video-style {
      position: relative;
    }
    .retailer-icon-style {
      font-size: 36px;
      color: #d9d9d9;
    }
    .public-start {
      position: absolute;
      top: 22px;
      right: 22px;
      border-radius: 50%;
    }
    .close-icon {
      font-size: 10px;
      position: absolute;
      top: -17px;
      right: 0;
      color: #8c8c8c;
      z-index: 999;
    }
    .ant-select-selection::v-deep .anticon-loading {
      color: #0592f2;
    }
    :root .ant-select-arrow {
      color: #0592f2;
    }
  }
}
</style>
