<template lang="pug">
.reset-password.height-for-sticky-footer
  .change-password__box
    .forgot-password__form(v-show="!isResetSuccessful")
      h2.forgot-password__title {{$t('Auth.ResetPassword.resetYourPassword')}}
        form(@submit.prevent="setPassword")
          .field(v-show="!isResetSuccessful")
            .columns
              .column.reset-form
                .control
                  input.input.forgot-password__input(
                    v-model="$v.password.$model"
                    type="password"
                    v-bind:placeholder="$t('Auth.ResetPassword.newPassword')")
                  p.validation-message.help.is-danger(v-if="!$v.password.minLength && showValidations") {{$t('Auth.ResetPassword.Validations.mustBe10Characters')}}
                .control
                  input.input.forgot-password__input(
                    v-model="$v.passwordConfirmation.$model"
                    type="password"
                    placeholder="Confirm New Password")
                  p.validation-message.help.is-danger(v-if="$v.passwordConfirmation.$error && showValidations") {{$t('Auth.ResetPassword.Validations.passwordConfirmation')}}

          .field.has-text-centered(v-show="!isResetSuccessful")
            .control.has-text-centered(v-show="isResetInProgress")
              img(src="@/assets/img/spinner.gif" alt="spinner")
            button.button.forgot-password__button.is-fullwidth.is-black(:disabled="isResetInProgress") {{$t('Auth.ResetPassword.changePassword')}}

    .reset-password-success(v-show="isResetSuccessful")
      h1.reset-password-success__title {{$t('Auth.ResetPassword.youAreAllSet')}}
      p.reset-password-success__paragraph {{$t('Auth.ResetPassword.passwordUpdatedMsg')}}
      .reset-password-success__divider
      .has-text-centered
        router-link.reset-password-success__link(to="/login") {{$t('Auth.ResetPassword.signIn')}}
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators"

export default {
  data() {
    return {
      token: this.$route.query.token,
      password: "",
      passwordConfirmation: "",
      isResetInProgress: false,
      isResetSuccessful: false,
      showValidations: false
    }
  },
  validations: {
    password: { required, minLength: minLength(10) },
    passwordConfirmation: { sameAsPassword: sameAs("password") }
  },
  methods: {
    async setPassword() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isResetInProgress = false
        this.showValidations = true
        return
      }

      try {
        await this.$store.dispatch("user/resetPassword", {
          token: this.token,
          password: this.password
        })
        this.isResetSuccessful = true
      } catch (error) {
        console.error(error)
      } finally {
        this.isResetInProgress = false
      }
    }
  }
}
</script>

<style lang="sass">
.validation-message
  margin-top: -0.75rem !important
.reset-password
  display: flex
  min-height: 80vh
  justify-content: center
  align-items: center


.reset-password-success
  &__link
    color: #008489
    font-size: 14px

  &__panel
    padding: 24px
    padding-bottom: 20px
    min-height: 335px
    width: 362px
    border: 1px solid #E5E5E5
    border-radius: 2px
    background-color: #FFFFFF

  &__title
    color: #000000
    font-family: "Proxima"
    font-size: 16px
    letter-spacing: 0.44px

  &__paragraph
    margin-top: 16px
    color: #8D8D8D
    font-size: 14px
    letter-spacing: 0.25px

  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 200px 0px 25px 0px


.reset-password
  .change-password
    &__box
      width: 360px
      border: 1px solid #E5E5E5
      border-radius: 2px
      padding: 25px

  .reset-form
    width: 170px
    text-align: center

  .input_margin-top
    margin-top: 20px

  .button_margin-top
    margin-top: 10px

  .page-title
    font-family: "Montserrat", sans-serif
    font-size: 20px
    font-weight: 300
</style>
