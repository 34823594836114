import axios from "@/api/axios"

export default {
  createMessageCategory(param) {
    return axios.post("/create/messageCategory", JSON.stringify(param))
  },
  getMessageCategory() {
    return axios.get("/get/messageCategory")
  },
  createMessage(token, param) {
    return axios.post(`/messages?_migration=true&_token=${token}`, JSON.stringify(param))
  },
  updateMessage(id, param) {
    return axios.put(`/messages/${id}`, JSON.stringify(param))
  },
  updateBatchMessages(param) {
    return axios.put("/set-messages", JSON.stringify(param))
  },
  //   getSendMessageList(params = {}) {
  //     return axios.get("/get/sendMessage", { params })
  //   },
  //   getReceiverMessageList(params = {}) {
  //     return axios.get("/get/receiverMessage", { params })
  //   },
  getDraftMessageList(params = {}) {
    return axios.get("/get/draftsMessage", { params })
  },
  //   getArchiveMessageList(params = {}) {
  //     return axios.get("/get/archiveMessage", { params })
  //   },
  searchMessage(params = {}) {
    return axios.get("/search/message", { params })
  },
  deleteMessageDetailsReceiver(param) {
    return axios.post("/delete/messageDetails", JSON.stringify(param))
  },
  deleteMessageList(param) {
    return axios.post("/delete/message", JSON.stringify(param))
  },
  createSearchGroup(param) {
    return axios.post("/searchGroups", JSON.stringify(param))
  },
  updateSearchGroup(param) {
    return axios.put(`/searchGroups/${param.id}`, JSON.stringify(param))
  },
  getSearchGroup() {
    return axios.get("/searchGroup")
  },
  deleteSearchGroup(id) {
    return axios.delete(`/searchGroup/${id}`)
  },
  getGroupClient(params) {
    return axios.get("/order-related-clients", { params })
  },
  getMessageDetail(params) {
    return axios.get(`/search/message/details`, { params })
  },
  hasConnection(brandId, retailerId) {
    return axios.get(`/get/connection?retailerId=${retailerId}&brandId=${brandId}`)
  },
  archiveMessage(param) {
    return axios.put("/set/archive", JSON.stringify(param))
  },
  getMessageType() {
    return axios.get("/get/messageTemplate")
  },
  getAllBrands(params = {}) {
    return axios.get("/brands", { params: { _limit: 9000, ...params } })
  },
  getAllRetailers(params = {}) {
    return axios.get("/retailers", { params: { _limit: 9000, ...params } })
  },
  getSearchMessage(params) {
    return axios.get("/search/message", { params })
  },
  updateSearchMessageList(id, params) {
    return axios.put(`/messages/${id}`, params)
  },
  createMessageInfo(params) {
    return axios.post(`/messages?_migration=true`, JSON.stringify(params))
  },
  batchUpdateMessage(params) {
    const messages = params
    return axios.put("/messages", { messages })
  },
  getUnReadMessage() {
    return axios.get("/search/unread-count")
  }
}
