import assetClient from "@/api/asset-client"

export default {
  namespaced: true,

  state: {
    assets: [],
    routerInfo: []
  },

  mutations: {
    ADD_ASSET(state, asset) {
      state.assets.push(asset)
    },
    ROUTER_LINK_PERMISSIONS(state, routerInfo) {
      state.routerInfo = routerInfo
    }
  },

  getters: {
    find: state => id => {
      return state.assets.find(asset => asset.id === id)
    },
    getRouterPermissions(state) {
      return state.routerInfo
    }
  },

  actions: {
    async getAsset({ commit, getters }, id) {
      let asset = getters.find(id)
      if (!asset) {
        const { data } = await assetClient.getAsset(id)
        asset = data

        commit("ADD_ASSET", asset)
      }

      return asset
    },
    async getSwitchStatusInfo({ commit }) {
      const { data } = await assetClient.getSwitchStatus()
      commit("ROUTER_LINK_PERMISSIONS", data)
      return data
    }
  }
}
