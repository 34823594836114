<template>
  <div class="banner-image-cropper" :style="cssProps">
    <div class="image-upload__profile">
      <div class="image-upload__profile--container">
        <div class="image-upload__size-recommendation">
          {{ $t("Components.Cropper.BannerImageCropper.uploadImageSizeRecommendation") }}
        </div>
        <div class="image-upload__size-recommendation header-img-style">
          SVG format and 200x200 px recommended
        </div>
        <div class="image-upload__profile--banner">
          <BannerOrPlaceholder :url="bannerAssetUrl" />
        </div>
        <div class="image-upload__profile--banner_icon" @click="openBannerInput">
          <img src="@/assets/img/icon/camera-icon.svg" />
        </div>
      </div>
      <input
        id="image-upload-banner"
        ref="bannerInput"
        type="file"
        accept="image/png, image/jpeg"
        label="File"
        :show-size="1024"
        @change="setupCropper($event.target.files[0], 0)"
      />
    </div>
    <div class="modal" :class="{ 'is-active': isCropperModalActive }">
      <div class="modal-background" @click="isCropperModalActive = !isCropperModalActive"></div>
      <div class="modal-card banner-image-cropper__modal">
        <header class="modal-card-head">
          <p class="modal-card-title banner-image-cropper__header">
            {{ $t("Components.Cropper.BannerImageCropper.banner") }}
          </p>
          <img
            class="banner-image-cropper__close-icon"
            src="@/assets/img/icon/close-icon.png"
            @click="isCropperModalActive = !isCropperModalActive"
          />
        </header>
        <clipper-basic
          ref="clipper"
          class="clipperContainer"
          :src="objectUrl"
          :rotate="degree"
          :ratio="2.63"
          layout="corner"
        />
        <footer class="modal-card-foot banner-image-cropper__footer">
          <div class="columns is-multiline">
            <div class="column">
              <button
                class="button is-medium banner-image-cropper__button-change"
                @click="openBannerInput"
              >
                {{ $t("Components.Cropper.BannerImageCropper.changeImage") }}
              </button>
              <input
                id="image-upload-banner1"
                ref="bannerInput"
                type="file"
                accept="image/png, image/jpeg"
                label="File"
                :show-size="1024"
                @change="setupCropper($event.target.files[0], 0)"
              />
            </div>
            <div class="column">
              <button
                class="button is-black is-medium banner-image-cropper__button-save"
                @click="
                  saveBrandAsset()
                  isCropperModalActive = !isCropperModalActive
                "
              >
                {{ $t("Components.Cropper.BannerImageCropper.saveImage") }}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import assetClient from "@/api/asset-client"
import BannerOrPlaceholder from "@/components/cropper/BannerOrPlaceholder"

export default {
  components: {
    BannerOrPlaceholder
  },
  props: {
    initialBannerAssetUrl: { type: String, default: "" },
    clientId: { type: String, default: null }
  },
  data() {
    return {
      objectUrl: null,
      isCropperModalActive: false,
      zoomMin: 0,
      zoomValue: 0,
      min: 0,
      degree: 0,
      borderRadius: 0,
      bannerAssetId: null,
      bannerAssetUrl: this.initialBannerAssetUrl,
      bannerAspectRatio: 4
    }
  },
  computed: {
    cssProps() {
      return {
        "--border-radius": this.borderRadius + "%"
      }
    }
  },
  methods: {
    emitAsset() {
      this.$emit("bannerAssetUpdated", this.bannerAssetId, this.bannerAssetUrl)
    },
    emitImageSaving(status) {
      this.$emit("imageSaving", status)
    },
    openBannerInput() {
      this.$refs.bannerInput.value = null
      this.$refs.bannerInput.click()
      this.min = 0
      this.degree = 0
    },
    setupCropper(selectedFile, borderRadius) {
      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl)
      }
      if (!selectedFile) {
        this.objectUrl = null
        return
      }
      this.borderRadius = borderRadius
      this.objectUrl = window.URL.createObjectURL(selectedFile)
      this.isCropperModalActive = true
    },
    async saveBrandAsset() {
      this.emitImageSaving("saving")
      try {
        const { data } = await assetClient.createAsset(this.fileFromDataUrl(), this.clientId)
        this.bannerAssetId = data[0].file.id
        this.bannerAssetUrl = data[0].file.url
      } catch (error) {
        this.emitImageSaving("error")
      } finally {
        this.emitImageSaving("saved")
        this.emitAsset()
      }
    },
    fileFromDataUrl() {
      let canvas = this.$refs.clipper.clip()
      let resultURL = canvas.toDataURL("image/jpeg", 1)
      let array = resultURL.split(","),
        mime = array[0].match(/:(.*?);/)[1],
        bstr = atob(array[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], "file", { type: mime })
    }
  }
}
</script>

<style lang="sass">
.image-upload__size-recommendation
  position: absolute
  top: 14px
  right: 19px
  font-size: 10px
  letter-spacing: 0.2px
  background: rgba(0,0,0,0.5)
  color: white
  text-align: center
  padding: 4px 8px
  font-family: "Proxima"
.header-img-style
  top: 117px
  right: 141px
.banner-image-cropper__zoom-slider--wrapper
  position: relative
  height: 26px
  margin-right: 30px
  width: 135%

.banner-image-cropper__change-slider--wrapper
  position: relative
  height: 26px
  margin-left: 30px

.banner-image-cropper__slider--scale
  height: 6px
  border-radius: 2px
  width: 100%
  background: #E5E5E5
  position: absolute
  left: 0
  top: calc(50% - 3px)

.banner-image-cropper__slider--scale_active
  height: 6px
  border-radius: 2px
  background: #000
  width: 100%
  left: 0
  top: 0
  position: absolute

.banner-image-cropper__slider--input
  position: absolute
  left: 0
  width: 100%
  pointer-events: none
  background: inherit
  -webkit-appearance: none
  height: 26px

  &:focus
    outline: none

  &::-webkit-slider-thumb
    pointer-events: auto
    background: #292929
    width: 12px
    height: 12px
    border: none
    border-radius: 50%
    -webkit-appearance: none

  &::-moz-range-thumb
    pointer-events: auto
    background: #292929
    width: 12px
    height: 12px
    border: none
    border-radius: 50%

  &::-ms-thumb
    pointer-events: auto
    background: #292929
    width: 12px
    height: 12px
    border: none
    border-radius: 50%

.banner-image-cropper

#image-upload-main, #image-upload-banner, #image-upload-banner1
  display: none

.image-upload__profile--main
  max-height: 64px
  max-width: 64px
  cursor: pointer

.image-upload__profile--banner
  margin-bottom: -50px
  max-height: 200px

.banner-image-cropper__modal
  display: flex
  justifyContent: center
  alignItems: center

.banner-image-cropper__header
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px
  color: #000000
  padding-left: 25px

.banner-image-cropper__close-icon
  cursor: pointer

.banner-image-cropper__footer
  display: flex
  justify-content: center

.banner-image-cropper__text
  color: #ffffff
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px
  padding-top: 20px

.banner-image-cropper__button-save
  font-size: 14px
  font-weight: 500
  letter-spacing: 0.7px
  margin-top: 30px
  margin-bottom: 20px

.image-upload__profile--container
  position: relative

.image-upload__profile--banner_icon
  position: absolute
  background-color: rgba(74,74,74,0.47)
  border-radius: 50%
  padding: 5px
  right: 20px
  top: 45px
  width: 41px
  height: 41px
  cursor: pointer

.banner-image-cropper__button-change
  font-size: 14px
  font-weight: 500
  letter-spacing: 0.7px
  float: right
  margin-top: 30px
  margin-bottom: 20px

.banner-image-cropper__modal--header
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px

.banner-image-cropper__modal--straighten-header
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px
  margin-left: 30px

.image-container
  display: inline-block

.banner-crop-container
  background-color: black
  padding-top: 40px
  padding-bottom: 20px
  overflow: scroll

.clipperContainer
  overflow: scroll
.image-preview
  display: block
  max-height: 229px
  max-width: 100%
</style>

<style lang="sass">
@import '~cropperjs/dist/cropper.css'
.cropper-crop-box, .cropper-view-box
  border-radius: var(--border-radius)

.cropper-view-box
  outline: 0

.cropper-face, .cropper-line, .cropper-point
  background-color: transparent

.cropper-modal
  background-color: rgba(0,0,0,0.59)
</style>
