<template>
  <a-modal
    v-model="isShow"
    width="810px"
    dialog-class="adminProductTrainingDialog"
    :footer="null"
    :destroy-on-close="true"
    :mask-closable="false"
  >
    <a-row class="admin-modal-title"> {{ title }}</a-row>
    <a-row class="admin-modal-content">
      <a-col :span="11">
        <a-form-model ref="ruleLoginMessageForm" :model="form" :rules="rules">
          <a-form-model-item
            prop="category"
            class="admin-training-login-message__form-item-margin"
          >
            <span class="admin-training-login-message__form-label"
              >Category</span
            >
            <a-row>
              <a-select
                v-model="form.messageCategoryId"
                style="width: 100%;"
                placeholder="Select"
                @change="chooseCategory"
              >
                <a-select-option v-for="item in categorys" :key="item.id">
                  {{ item.description }}
                </a-select-option>
              </a-select>
            </a-row>
          </a-form-model-item>
          <a-form-model-item
            class="admin-training-login-message__form-item-margin"
            prop="retailerId"
          >
            <span class="admin-training-login-message__form-label">
              Recipient:
            </span>
            <a-row>
              <a-select
                show-search
                v-model="form.retailerId"
                placeholder="Select"
                style="width: 100%;"
                option-filter-prop="children"
                :filter-option="filterRetailerOption"
                @change="changeRecipient"
              >
                <a-select-option v-for="item in retailers" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <div v-if="showLoginMessageDistrictSelection()">
                <a-form-model-item ref="appliesTo" prop="appliesTo">
                  <a-radio-group
                    v-model="form.appliesTo"
                    name="radioGroup"
                    :default-value="1"
                    @change="changeappliesTo"
                  >
                    <a-radio :value="1">
                      All
                    </a-radio>
                    <a-radio :value="2">
                      In-Store Users
                    </a-radio>
                    <a-radio :value="3">
                      Mobile Users
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="districts" prop="districts">
                  <a-select
                    :disabled="disabledDistrictDropDown"
                    :loading="loadingRetailerDistricts"
                    v-model="form.districts"
                    mode="multiple"
                    option-filter-prop="children"
                    :filter-option="filterDistrictOption"
                    @select="loginMessageDistrcitsSelect"
                    style="width: 100%"
                    placeholder="Select District"
                  >
                    <div slot="dropdownRender" slot-scope="menu">
                      <a-input @mousedown="(e) => e.preventDefault()"></a-input>
                      <v-nodes :vnodes="menu" />
                    </div>
                    <a-select-option value="all">All Districts</a-select-option>
                    <!-- <a-select-option value="in-store-users"
                  >In-Store Users</a-select-option
                >
                <a-select-option value="mobile-users"
                  >Mobile Users</a-select-option
                > -->
                    <a-select-option
                      v-for="item in districts"
                      :key="item.privateId"
                      :value="item.privateId"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </div>

              <a-select
                v-model="form.messageRecipientCategoryId"
                style="width: 100%;"
                placeholder="Select Sub Category"
                :style="
                  `visibility: ${
                    form.retailerId ? 'visible' : 'hidden'
                  }; margin-top: 7px; margin-bottom: 6px;`
                "
                @change="chooseRecipientCategory"
              >
                <a-select-option
                  v-for="item in recipientCategorys"
                  :key="item.id"
                >
                  {{ item.description }}
                </a-select-option>
              </a-select>
            </a-row>
          </a-form-model-item>
          <a-form-model-item
            class="admin-training-login-message__form-item-margin"
            prop="title"
          >
            <span class="admin-training-login-message__form-label">
              Header Text:
            </span>
            <a-row>
              <a-input
                v-model="form.title"
                maxlength="30"
                @change="checkTitleLen"
              />
              <span class="admin-training-login-message__text-count"
                >{{ form.title.length || 0 }}/30</span
              >
            </a-row>
          </a-form-model-item>
          <a-form-model-item
            class="admin-training-login-message__form-item-margin"
            prop="message"
          >
            <span class="admin-training-login-message__form-label">
              Body Text:
            </span>
            <a-row>
              <a-textarea
                v-model="form.message"
                :maxlength="125"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                show-count
                @change="changeBody"
              />
              <span class="admin-training-login-message__text-count"
                >{{ form.message.length || 0 }}/125</span
              >
            </a-row>
          </a-form-model-item>
          <a-form-model-item
            class="admin-training-login-message__form-item-margin"
            prop="ctaBtnText"
          >
            <span class="admin-training-login-message__form-label">
              CTA Button Text:
            </span>
            <a-row>
              <a-input
                v-model="form.ctaButtonText"
                maxlength="20"
                @change="checkTitleLen"
              />
              <span class="admin-training-login-message__text-count"
                >{{ form.ctaButtonText.length || 0 }}/20</span
              >
            </a-row>
          </a-form-model-item>
          <a-form-model-item
            class="admin-training-login-message__form-item-margin"
            prop="ctaBtnText"
          >
            <span class="admin-training-login-message__form-label">
              CTA Button destination URL:
            </span>
            <a-row>
              <a-input v-model="form.ctaButtonDestinationUrl" />
            </a-row>
          </a-form-model-item>
          <a-row>
            <a-col :span="11" class="admin-training-login-message__form-label">
              Start Date:
            </a-col>
            <a-col :span="2"></a-col>
            <a-col :span="11" class="admin-training-login-message__form-label">
              End Date:
            </a-col>
            <a-col :span="11">
              <a-form-model-item
                class="admin-training-login-message__form-item-margin"
                prop="startDate"
              >
                <a-date-picker
                  v-model="form.startDate"
                  :format="dateFormat"
                  :disabled-date="disabledStartDate"
                  style="width: 100%;"
                  placeholder="Select"
                  @change="onChangeStartDate"
                />
              </a-form-model-item>
            </a-col>
            <a-col
              :span="2"
              style="text-align: center;"
              class="admin-training-login-message__form-label"
            >
              To
            </a-col>
            <a-col :span="11">
              <a-form-model-item
                class="admin-training-login-message__form-item-margin"
                prop="endDate"
              >
                <a-date-picker
                  v-model="form.endDate"
                  :format="dateFormat"
                  :disabled-date="disabledDate"
                  placeholder="Select"
                  style="width: 100%;"
                  @change="onChangeEndDate"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item
            class="admin-training-login-message__form-item-margin"
            prop="messageTimeId"
          >
            <span class="admin-training-login-message__form-label">
              Start Time:
            </span>
            <a-select
              v-model="form.messageTimeId"
              style="width: 100%;"
              placeholder="Select time"
              @change="chooseMessageTime"
            >
              <a-select-option v-for="item in startTimeList" :key="item.id">
                {{ item.description }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-row style="margin-top: 7px;">
            <a-col :span="10" class="admin-training-login-message__form-label">
              Paid Message?
            </a-col>
            <a-col :span="14">
              <span
                style="margin-right: 4px;"
                class="admin-training-login-message__text"
                >No</span
              >
              <a-switch
                v-model="form.paid"
                class="admin-training-switch"
                style="background-image: none"
              />
              <span
                style="margin-left: 4px;"
                class="admin-training-login-message__text"
                >Yes</span
              >
            </a-col>
            <a-col
              :span="10"
              style="margin-top: 7px;"
              class="admin-training-login-message__form-label"
            >
              Activate Message
            </a-col>
            <a-col :span="14" style="margin-top: 7px;">
              <span
                style="margin-right: 4px;"
                class="admin-training-login-message__text"
                >No</span
              >
              <a-switch
                v-model="form.isActiveMessage"
                class="admin-training-switch"
                style="background-image: none"
              />
              <span
                style="margin-left: 4px;"
                class="admin-training-login-message__text"
                >Yes</span
              >
            </a-col>
          </a-row>
        </a-form-model>
      </a-col>
      <a-col :span="3"></a-col>
      <a-col :span="10">
        <a-row
          style="margin-bottom: 7px;"
          class="admin-training-login-message__form-label"
          >Preview:</a-row
        >
        <a-row style="height:275px;">
          <div style="width: 252.76px; height: 100%;background:#C4C4C4;">
            <div v-if="form.msgAssetUrl">
              <img width="253" height="275" :src="form.msgAssetUrl" />
              <img
                src="@/assets/img/login_message_close.svg"
                style="position: absolute; top: 8px; right: 70px; cursor: pointer;"
                @click="removeImage"
              />
            </div>

            <div v-else style="padding-top: 92px;">
              <a-upload
                v-model="fileList"
                :custom-request="doUpload"
                :before-upload="beforeUploadFile"
                :multiple="false"
                class="admin-training-login-message__upload"
                @change="handleChange"
              >
                <span class="admin-training-login-message__upload-text"
                  >Upload Image</span
                ><br />
                <span
                  style="margin-left: 2px;"
                  class="admin-training-login-message__text"
                  >.jpg or .png</span
                ><br />
                <span
                  style="margin-left: 11px;"
                  class="admin-training-login-message__text"
                  >235x275</span
                >
              </a-upload>
            </div>
          </div>
        </a-row>
        <div style="height: 124px; width: 253px; border: 1px solid #D9D9D9;">
          <a-row class="admin-training-login-message__title">
            {{ form.title }}
          </a-row>
          <a-row class="admin-training-login-message__message">
            {{ form.message }}
          </a-row>
        </div>

        <a-row>
          <a-input
            v-model="form.upc"
            placeholder="Input a UPC code (optional)"
            style="width: 255px; margin-top: 2px;"
          />
        </a-row>
      </a-col>
    </a-row>
    <a-row style="text-align: right; margin-top: 20px;">
      <a-button
        class="admin-training-landing-deep-red-btn"
        style="width: 160px; margin-right: 14px;"
        @click="handleCancel"
        >Cancel</a-button
      >
      <a-button
        class="admin-training-landing-light-green-btn"
        style="width: 160px;"
        :loading="saving"
        @click="handleSave"
        >Save</a-button
      >
    </a-row>
  </a-modal>
</template>
<script>
// import { getApiErrorString } from "@/utils/validate"
import loginMessagesService from "@/services/login-messages-service";
import retailersService from "@/services/retailers-service";
import LoginMessageDistrict from "@/models/LoginMessageDistrict";
import assetClient from "@/api/asset-client";
import moment from "moment";
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    let checkRetailer = (rule, value, callback) => {
      console.log("....check retailer....");
      console.log(rule);
      console.log(value);
      if (!value || value.length === 0) {
        return callback(new Error("Please choose a retailer"));
      }
      console.log(this.existedRetailer);
      //   if (value && value.length > 0 && this.existedRetailer && this.existedRetailer.length > 0) {
      //     for (let i = 0; i < this.existedRetailer.length; i++) {
      //       if (this.existedRetailer[i] === value) {
      //         return callback(new Error("This retailer already have login message."))
      //       }
      //     }
      //   }
      return callback();
    };
    return {
      saving: false,
      loadingRetailerDistricts: false,
      imageUrl: undefined,
      isCheckAllUser: false,
      selectedRetailer: undefined,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      rules: {
        retailerId: [{ validator: checkRetailer, trigger: "change" }],
        messageCategoryId: [
          {
            required: true,
            message: "Please input title",
            trigger: "blur",
            whitespace: true,
          },
        ],
        title: [
          {
            required: true,
            message: "Please input title",
            trigger: "blur",
            whitespace: true,
          },
          {
            min: 1,
            max: 30,
            messsage: "Length should be 1 to 30 ",
            trigger: "change",
          },
        ],
        message: [
          {
            required: true,
            message: "Please input message",
            trigger: "blur",
            whitespace: true,
          },
          {
            min: 1,
            max: 125,
            messsage: "Length should be 1 to 125 ",
            trigger: "change",
          },
        ],
        startDate: [
          {
            required: true,
            message: "Please choose start date",
            trigger: "change",
          },
        ],
        endDate: [
          {
            required: true,
            message: "Please choose end date",
            trigger: "change",
          },
        ],
        messageTimeId: [
          {
            required: true,
            message: "Please choose start time",
            trigger: "change",
            whitespace: true,
          },
        ],
      },
      headValue: "",
      messageValue: "",
      fileList: [],
      title: "",
      isShow: false,
      isEdit: false,
      dateFormat: "YYYY-MM-DD",
      isDoAction: false,
      startDateTime: "",
      endDateTime: "",
      loginMessageStartTime: "",
      form: {
        messageCategory: undefined,
        retailerId: undefined,
        messageRecipientCategory: undefined,

        title: "",
        ctaButtonText: "",
        ctaButtonDestinationUrl: "",
        message: "",
        msgAssetId: "",
        msgAssetUrl: undefined,
        upc: undefined,
        messageCategoryId: undefined,
        messageRecipientCategoryId: undefined,
        messageTimeId: undefined,
        messageTime: undefined,
        endDate: undefined,
        startDate: undefined,
        paid: false,
        isActiveMessage: true,
        appliesTo: "0",
        districts: [],
      },
      loading: false,
      uploadedFile: null,
      modifyData: null,
      filterRetailerInput: "",
      existedRetailer: [],
      districts: [],
    };
  },
  computed: {
    retailers() {
      let data = this.$store.getters["retailer/retailers"];
      if (data && data.length > 0) {
        data.unshift({ id: "*", name: "All Retailers" });
      }
      return data;
    },
    userId() {
      return this.$store.getters["user/businessId"];
    },
    categorys() {
      return this.$store.getters["reference/loginMessageCategory"];
    },
    recipientCategorys() {
      return this.$store.getters["reference/loginMessageRecipientCategory"];
    },
    startTimeList() {
      return this.$store.getters["reference/loginMessageTime"];
    },
    disabledDistrictDropDown() {
      const disabledDistrict = this.form.appliesTo !== 2;
      return disabledDistrict;
    },
  },
  async created() {
    await this.$store.dispatch("retailer/fetchAllRetailers", {
      params: { fields: "name,id" },
    });
  },
  methods: {
    loginMessageDistrictsChange(value, value1) {
      console.log(value);
      console.log(value1);
    },
    loginMessageDistrcitsSelect(value) {
      if (value === "all") {
        this.form.districts = ["all"];
      } else {
        const i = this.form.districts.indexOf("all");
        if (i != -1) {
          this.form.districts.splice(i, 1);
        }
      }
    },
    filterDistrictOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async getRetatilDistricts(retailerId) {
      if (retailerId) {
        this.loadingRetailerDistricts = true;
        this.districts = await retailersService
          .getTerritories(retailerId)
          .finally(() => (this.loadingRetailerDistricts = false));
      } else {
        this.districts = [];
      }
    },
    filterRetailerOption(input, option) {
      console.log(`option: ${option}`);
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async changeRecipient(value) {
      if (value && value.length > 0) {
        this.$refs.ruleLoginMessageForm.validateField("retailerId");
      }

      await this.getRetatilDistricts(value);
    },
    doUpload(data) {
      assetClient
        .createAsset(data.file, this.userId)
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            this.uploadedFile = response.data[0].file;
            data.onSuccess(response, data.file);
            this.form.msgAssetId = this.uploadedFile.id;
            this.form.msgAssetUrl = this.uploadedFile.url;
          }
        })
        .catch((err) => {
          data.onError(err, err.message);
          console.log(err);
        });
    },
    beforeUploadFile(file, fileList) {
      if (!this.fileList || this.fileList.length === 0) {
        this.fileList = fileList;
        return true;
      } else {
        this.$message.warning(
          "Please remove existed intro deck, then can upload a new one."
        );
        return false;
      }
    },
    disabledStartDate(currentDate) {
      return (
        currentDate <
        moment(new Date())
          .add(-1, "days")
          .endOf("day")
      );
    },
    disabledDate(currentDate) {
      if (this.startDateTime) {
        return (
          currentDate < moment(this.startDateTime, this.dateFormat).endOf("day")
        );
      } else {
        return currentDate <= moment().endOf("day");
      }
    },
    handleChange(info) {
      const status = info.file.status;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
        if (status === "removed") {
          this.fileList = info.fileList;
        }
      }
      if (status === "done") {
        this.$message.success(
          `${info.fileList[0].name} file uploaded successfully.`
        );
      } else if (status === "error") {
        this.$message.error(`${info.fileList[0].name} file upload failed.`);
      }
    },
    handleCancel() {
      this.isShow = false;
      this.fileList = [];
    },
    handleSave() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      this.isDoAction = true;
      console.log(this.$refs.ruleLoginMessageForm);
      this.$refs.ruleLoginMessageForm.validate((valid) => {
        if (valid) {
          this.doSaveAction();
          this.isDoAction = false;
        } else {
          this.isDoAction = false;
          this.saving = false;
          return false;
        }
      });
    },
    removeImage() {
      console.log("....delete image....");
      let imageUrl = this.form.msgAssetUrl;
      let imageId = this.form.msgAssetId;
      this.form.msgAssetUrl = "";
      this.form.msgAssetId = "";
      console.log(
        `form url: ${this.form.msgAssetUrl},imageUrl: ${imageUrl}, imageId: ${imageId}`
      );
      this.fileList = [];
      //assetClient.deleteAsset(imageId)
    },
    checkTitleLen() {
      if (this.form.title && this.form.title.length > 0) {
        this.$refs.ruleLoginMessageForm.validateField("title");
      }
      if (this.form.title && this.form.title.length > 30) {
        this.form.title = this.form.title.substring(0, 30);
      }
    },

    checkCtaButtonTextLen() {
      if (this.form.ctaButtonText && this.form.ctaButtonText.length > 0) {
        this.$refs.ruleLoginMessageForm.validateField("ctaButtonText");
      }
      if (this.form.title && this.form.title.length > 30) {
        this.form.title = this.form.title.substring(0, 30);
      }
    },
    changeappliesTo() {
      if (this.form.appliesTo !== 1) {
        this.form.districts = [];
      } else {
        this.form.districts = ["all"];
      }
    },
    changeBody() {
      if (this.form.message && this.form.message.length > 0) {
        this.$refs.ruleLoginMessageForm.validateField("message");
      }
    },
    saveAction() {
      this.$refs.ruleLoginMessageForm.validate((valid) => {
        if (valid) {
          this.doSaveAction();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    doSaveAction() {
      console.log("....do save action....");
      console.log(this.form);
      let param = {
        sellerId: {
          id: "*",
        },
        title: this.form.title,
        message: this.form.message,
        msgAssetId: this.form.msgAssetId,
        msgAssetUrl: this.form.msgAssetUrl,
        startDate: this.startDateTime,
        endDate: this.endDateTime,
        active: this.form.isActiveMessage,
        paid: this.form.paid,
        messageCategory: this.form.messageCategory,
        messageRecipientCategory: this.form.messageRecipientCategory,
        messageTime: this.form.messageTime,
      };
      if (this.form.retailerId && this.form.retailerId.length > 0) {
        param["recipientRetailer"] = {
          id: this.form.retailerId,
        };
      } else {
        param["recipientRetailer"] = {
          id: "*",
        };
      }
      console.log(param);
      if (this.isEdit) {
        param["privateId"] = this.modifyData.privateId;
        param["id"] = this.modifyData.id;
        param["viewCount"] = this.modifyData.viewCount;
        param["invitedCount"] = this.modifyData.invitedCount;
        this.$store
          .dispatch("seller/updateLoginMessage", {
            id: this.modifyData.id,
            params: param,
          })
          .then(() => {
            loginMessagesService
              .updateLoginMessage(param.privateId, {
                actionText: this.form.ctaButtonText,
                actionUrl: this.form.ctaButtonDestinationUrl,
                appliesTo: this.form.appliesTo, //this.getApplieTo(this.form.appliesTo),
                distlistType: this.getDistListType(this.form.appliesTo),
                districts: this.getLoginMessageDistricts(
                  param.privateId,
                  this.getSelectedDistricts()
                ),
              })
              .then(() => {
                this.saveSuccess();
              })
              .catch((err) => {
                let msg = err.message;
                this.$message.error(
                  "Update login message failed. Error:" + msg ? msg : ""
                );
              });
          })
          .catch((err) => {
            console.log(err);
            let msg = err.message;
            this.$message.error(
              "Update login message failed. Error:" + msg ? msg : ""
            );
          })
          .finally(() => (this.saving = false));
      } else {
        this.$store
          .dispatch("seller/createLoginMessage", param)
          .then((data) => {
            loginMessagesService
              .updateLoginMessage(data.privateId, {
                actionText: this.form.ctaButtonText,
                actionUrl: this.form.ctaButtonDestinationUrl,
                appliesTo: this.form.appliesTo, //this.getApplieTo(this.form.appliesTo),
                distlistType: this.getDistListType(this.form.appliesTo),
                districts: this.getLoginMessageDistricts(
                  param.privateId,
                  this.getSelectedDistricts()
                ),
              })
              .then(() => {
                this.saveSuccess();
              })
              .catch((err) => {
                let msg = err.message;
                this.$message.error(
                  "Create login message failed. Error:" + msg ? msg : ""
                );
              });
          })
          .catch((err) => {
            console.log(err);
            // let msg = getApiErrorString(err)
            let msg = err.message;
            this.$message.error(
              "Create login message failed. Error:" + msg ? msg : ""
            );
          })
          .finally(() => (this.saving = false));
      }
    },
    getSelectedDistricts() {
      return [...this.form.districts].some((d) => d === "all")
        ? []
        : this.form.districts;
    },
    showLoginMessageDistrictSelection() {
      return this.districts.length > 0;
    },
    getApplieTo() {
      let data = "All";
      switch (this.form.appliesTo) {
        case 2:
          data = "InStore";
          break;
        case 3:
          data = "Mobile";
          break;
      }
      return data;
    },
    getDistListType() {
      return this.form.appliesTo == 2 ? 2 : 1; //"Territory" : "None";
    },

    getLoginMessageDistricts(loginMessagePrivateId, districts) {
      const loginMessageDistricts = districts.map((d) => {
        return new LoginMessageDistrict({
          loginMessagePrivateId: loginMessagePrivateId,
          retailerTerritoryPrivateId: d,
        }).toModel();
      });
      const data = loginMessageDistricts;
      console.log(data);
      return districts;
    },
    saveSuccess() {
      this.$message.success(
        (this.isEdit ? "Edit" : "Crate") + " login message success."
      );
      this.$emit("success");
      this.handleCancel();
    },
    async openDialog(edit, titleStr, retailers, data) {
      this.isEdit = edit;
      this.title = titleStr;
      this.existedRetailer = retailers;
      this.fileList = [];
      this.form.ctaButtonText = "";
      this.form.ctaButtonDestinationUrl = "";
      this.form.loginMessagePrivateId = 0;
      if (data) {
        this.form.loginMessagePrivateId = data.privateId;
        loginMessagesService.getLoginMessage(data.privateId).then((m) => {
          this.form.ctaButtonText = m.actionText || "";
          this.form.ctaButtonDestinationUrl = m.actionUrl || "";
          this.form.appliesTo = m.appliesTo;
          const defaultDistricts = m.districtlistType == 2 ? ["all"] : [];
          this.form.districts =
            m.districts.length > 0
              ? m.districts.map((d) => d.retailerTerritoryPrivateId)
              : defaultDistricts;
        });
        console.log(data);
        this.modifyData = data;
        this.imageUrl = data.imageUrl;
        this.headValue = data.title;
        this.messageValue = data.message;
        this.selectedRetailer = data.selectedRetailer;
        this.isCheckAllUser = data.isCheckAllUser;
        this.startDateTime = data.startDate;
        this.endDateTime = data.endDate;
        this.form.title = data.title;
        this.form.message = data.message;
        this.form.paid = data.paid;
        this.form.messageCategoryId = data.messageCategory.id;
        this.form.messageRecipientCategoryId = data.messageRecipientCategory.id;
        this.form.messageTimeId = data.messageTime.id;
        this.form.messageCategory = Object.assign({}, data.messageCategory);
        this.form.messageCategory.id = data.messageCategory.name;
        this.form.messageRecipientCategory = Object.assign(
          {},
          data.messageRecipientCategory
        );
        this.form.messageRecipientCategory.id =
          data.messageRecipientCategory.name;
        this.form.messageTime = Object.assign({}, data.messageTime);
        this.form.messageTime.id = data.messageTime.name;
        this.form.retailerId = data.recipientRetailer.id;
        this.form.msgAssetId = data.msgAssetId;
        this.form.msgAssetUrl = data.msgAssetUrl;
        this.form.endDate = data.endDate;
        this.form.startDate = data.startDate;
        this.form.upc = data.upc;
        this.form.isActiveMessage = data.active;

        await this.getRetatilDistricts(this.form.retailerId);
        console.log(this.form);
      } else {
        this.modifyData = null;
        this.imageUrl = undefined;
        this.headValue = "";
        this.messageValue = "";
        this.selectedRetailer = undefined;
        this.isCheckAllUser = false;
        this.startDateTime = "";
        this.endDateTime = "";
        this.form.title = "";
        this.form.message = "";
        this.form.retailerId = undefined;
        this.form.msgAssetId = "";
        this.form.messageCategoryId = undefined;
        this.form.messageRecipientCategoryId = undefined;
        this.form.messageTimeId = undefined;
        this.form.msgAssetUrl = undefined;
        this.form.endDate = undefined;
        this.form.startDate = undefined;
        this.form.messageCategory = undefined;
        this.form.messageRecipientCategory = undefined;
        this.form.messageTime = undefined;
        this.form.paid = false;
        this.form.isActiveMessage = true;
        this.form.upc = undefined;
        this.form.appliesTo = 1;
        this.form.districts = [];
        console.log(this.form);
      }
      this.isShow = true;
    },
    onChangeStartDate(value, dayStr) {
      console.log(value);
      this.startDateTime = dayStr;
      if (value) {
        this.$refs.ruleLoginMessageForm.validateField("startDate");
      }
    },
    onChangeEndDate(value, dayStr) {
      console.log(value);
      this.endDateTime = dayStr;
      console.log(this.form.endDate);
      if (value) {
        this.$refs.ruleLoginMessageForm.validateField("endDate");
      }
    },
    chooseRecipientCategory(value) {
      console.log(value);
      console.log(this.form.messageRecipientCategoryId);
      console.log(this.form.messageRecipientCategory);
      let data = this.recipientCategorys.filter(
        (item) => item.id === this.form.messageRecipientCategoryId
      );
      console.log(data);
      if (data && data.length > 0) {
        this.form.messageRecipientCategory = Object.assign({}, data[0]);
        this.form.messageRecipientCategory.id = data[0].name;
      }
    },
    chooseCategory() {
      let data = this.categorys.filter(
        (item) => item.id === this.form.messageCategoryId
      );
      if (data && data.length > 0) {
        this.form.messageCategory = Object.assign({}, data[0]);
        this.form.messageCategory.id = data[0].name;
      }
      console.log(this.form.messageCategoryId);
      console.log(this.form.messageCategory);
    },
    chooseMessageTime() {
      console.log(this.form.messageTime);
      console.log(this.form.messageTimeId);
      if (this.form.messageTimeId && this.form.messageTimeId.length > 0) {
        this.$refs.ruleLoginMessageForm.validateField("messageTimeId");
      }
      let data = this.startTimeList.filter(
        (item) => item.id === this.form.messageTimeId
      );
      if (data && data.length > 0) {
        this.form.messageTime = Object.assign({}, data[0]);
        this.form.messageTime.id = data[0].name;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.admin-training-login-message {
  &__text {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #000000;
  }
  &__form-label {
    display: block;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #000000;
  }
  &__form-item-margin {
    margin-bottom: 0px !important;
  }
  &__img {
    width: 200px;
    height: 300px;
  }
  &__row-margin {
    margin-top: 10px;
  }
  &__upload {
    margin-left: 76px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000;
  }
  &__title {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000;
    margin-top: 10px;
  }
  &__message {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000;
    padding: 2px 12px 10px 12px;
  }
  &__upload-text {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: #e1644c;
  }
  &__text-count {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    text-align: right;
    color: #595959;
    float: right;
  }
}
</style>
<style lang="scss">
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
