import Vue from "vue"
import VueI18n from "vue-i18n"
Vue.use(VueI18n)

function getStartingLocale() {
  return process.env.VUE_APP_I18N_LOCALE || "zh"
}

const startingLocale = getStartingLocale()

const dateTimeFormats = {
  "en-US": {
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    }
  }
}

const i18n = new VueI18n({
  locale: startingLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {},
  dateTimeFormats
})

const loadedLanguages = []

export function loadLocaleMessagesAsync(locale) {
  if (loadedLanguages.length > 0 && i18n.locale === locale) {
    return Promise.resolve(locale)
  }

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    i18n.locale = locale
    return Promise.resolve(locale)
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`).then(
    messages => {
      i18n.setLocaleMessage(locale, messages.default)

      loadedLanguages.push(locale)

      i18n.locale = locale

      return Promise.resolve(locale)
    }
  )
}

loadLocaleMessagesAsync(startingLocale)

export default i18n
