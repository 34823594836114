<template>
  <div class="product-edit-card__main">
    <div v-if="!isDialog" class="line"></div>
    <div class="edit-card-content">
      <div class="edit-card-left">
        <div class="left-img">
          <span v-if="!isRead" class="demo-icon-tip">
            <a-tooltip :title="this.$t('Training.trainingTooltip.companyImgTitle')">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <img :src="productImg" alt="" />
        </div>
        <div class="left-content">
          <p class="content-title-p">
            <span v-if="!isRead" class="demo-icon-tip-one">
              <a-tooltip :title="this.$t('Training.trainingTooltip.companyImgTitle')">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            {{ product.name }}
          </p>
          <p class="left-content-one">
            <span v-if="!isRead" class="demo-icon-tip">
              <a-tooltip :title="this.$t('Training.trainingTooltip.companyImgTitle')">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            SKU: &nbsp;&nbsp;{{ product.sku }}
          </p>
          <p class="left-content-one">
            <span v-if="!isRead" class="demo-icon-tip">
              <a-tooltip :title="this.$t('Training.trainingTooltip.companyImgTitle')">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            MSRP: &nbsp;&nbsp;${{ product.msrpUsd }}
          </p>
        </div>
        <div class="upload-content">
          <span class="upload-icon-tip">
            <a-tooltip
              :title="this.$t('Training.brandTraining.trainingFormTips.uploadVideoTip')"
              placement="left"
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-button
            :class="uploadBtn ? 'upload-success' : 'upload-video-style'"
            @click="openDialog"
          >
            <a-icon v-if="uploadBtn" type="check-circle" />
            <a-icon v-else type="upload" />
            {{ uploadText }}
          </a-button>
          <span class="upload-optional-style">Optional</span>
        </div>
        <div v-if="product.trainingVideos" class="video-style" @click="clickToVideo">
          <img class="public-start" src="../../assets/training_public_video.png" alt="" />
          <!--          <span class="public-start background-style"></span>-->
          <img class="video-img" :src="product.trainingVideos[0].imgAsset.url" alt="" />
        </div>
      </div>
      <div class="edit-card-right__main">
        <div class="edit-card-center">
          <div class="center-key-benefits">
            <div class="key-title">
              {{ this.$t("Training.brandTraining.prodEditCardTips.keyBenefits") }}
              <a-tooltip
                v-if="!isRead"
                :title="this.$t('Training.trainingTooltip.companyImgTitle')"
              >
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </div>
            <ul>
              <li v-for="item in product.newBenefits" :key="item.id">
                <i></i>
                <span>{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="edit-card-right">
          <!--create and edit(brand create training)-->
          <prodForm v-if="!isRead" :product="product" @getFormInfo="getFormInfo"></prodForm>
        </div>
        <div class="textarea-bottom">
          <div class="center-demo-textarea">
            <p class="demo-tip-style">How To Demo:</p>
            <a-textarea
              v-model="howToDemo"
              class="textarea-style"
              :placeholder="tipsText"
              :max-length="250"
              :auto-size="{ minRows: 5, maxRows: 5 }"
              @blur="changeValue"
            />
            <span v-if="!isRead" class="demo-icon-tip">
              <a-tooltip :title="this.$t('Training.trainingTooltip.centerTextareaT')">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <span v-if="!isRead" class="demo-number-tip">{{ howToDemo.length || 0 }}/250</span>
          </div>
          <div class="center-demo-textarea">
            <p class="demo-tip-style">
              {{ this.$t("Training.brandTraining.trainingFormTips.demoTipsLabel") }}
            </p>
            <a-textarea
              v-model="demoTips"
              :max-length="250"
              class="textarea-style"
              :placeholder="demoText"
              :auto-size="{ minRows: 5, maxRows: 6 }"
              @blur="changeValue"
            />
            <span class="demo-icon-tip">
              <a-tooltip :title="this.$t('Training.brandTraining.trainingFormTips.demoTip')">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <span class="demo-optional-style">Optional</span>
            <span class="demo-number-tip-style">{{ demoTips.length || 0 }}/250</span>
          </div>
        </div>
      </div>
    </div>
    <uploadVideo
      ref="uploadVideo"
      :product="product"
      @handleUploadInfo="handleUploadInfo"
      @cancelUpload="cancelUpload"
    ></uploadVideo>
    <editVideo
      ref="editUploadVideo"
      :product="product"
      @handleEditVideoInfo="handleEditVideoInfo"
      @replaceVideo="replaceVideo"
      @cancelUpload="cancelUpload"
    ></editVideo>
    <a-modal
      :visible="modalVisible"
      :footer="null"
      :body-style="{ padding: 0 }"
      @cancel="handleCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div v-if="product.trainingVideos && product.trainingVideos.length !== 0" class="video-main">
        <video id="video1" ref="videoM" controls>
          <source :src="product.trainingVideos[0].asset.url" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
    </a-modal>
  </div>
</template>

<script>
import prodForm from "./heroProductForm"
import uploadVideo from "./upLoadVideoDialog"
import editVideo from "./editVideoDialog"
import { checkArary } from "@/utils/validate"

export default {
  name: "ProductEditCard",
  components: {
    prodForm,
    uploadVideo,
    editVideo
  },
  props: {
    product: {
      type: Object
    },
    isRead: {
      type: Boolean
    },
    isDialog: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      uploadBtn: false,
      modalVisible: false,
      sendInfo: {},
      howToDemo: "",
      demoTips: "",
      sendFormTraining: {},
      trainingVideos: {},
      trainingVideosList: [] // Trainingvideo list
    }
  },
  computed: {
    tipsText() {
      return this.$t("Training.brandTraining.textareaDemoInfo")
    },
    demoText() {
      return this.$t("Training.brandTraining.textareaTipsInfo")
    },
    productImg() {
      const list = this.product.images.filter(item => item.main === true)
      return list[0].asset.url
    },
    uploadText() {
      return this.uploadBtn
        ? "Replace Video"
        : this.$t("Training.brandTraining.trainingFormTips.uploadVideoLabel")
    }
  },
  watch: {
    modalVisible() {
      if (this.modalVisible === false) {
        this.$refs["videoM"].pause()
      }
    }
  },
  mounted() {
    console.log(this.product)
    if (this.$route.query.id || this.$route.name === "adminBrandTrainingProduct") {
      this.howToDemo = this.product.training.howToDemo || ""
      this.demoTips = this.product.training.demoTips || ""
      this.uploadBtn = this.product.trainingVideos && this.product.trainingVideos.length !== 0
    }
  },
  methods: {
    getFormInfo(formInfo) {
      console.log(formInfo)
      this.sendFormTraining = { ...formInfo }
      this.sendParamsValue()
    },
    changeValue() {
      this.sendInfo = {
        trainingVideos: this.trainingVideos
      }
      this.sendParamsValue()
    },
    sendParamsValue() {
      const data = {
        ...this.sendInfo,
        ...this.sendFormTraining,
        trainingVideos: Object.keys(this.trainingVideos).length === 0 ? [] : [this.trainingVideos],
        training: {
          ...this.sendFormTraining.training,
          howToDemo: this.howToDemo,
          demoTips: this.demoTips,
          states: 1,
          isPublished: true
        }
      }
      // In the case of modification and not draft, it is necessary to add ID in Training
      if (this.$route.query.id && !this.$route.query.isSaveDraft) {
        if (Object.keys(this.trainingVideos).length === 0) {
          data.trainingVideos = this.product.trainingVideos ? this.product.trainingVideos : []
        } else {
          data.trainingVideos = [this.trainingVideos]
        }
        data.training.howToDemo = this.howToDemo || ""
        data.training.demoTips = this.demoTips || ""
        data.training.id = this.product.training.id
        data.training.states = this.product.training.states
        data.training.isPublished = this.product.training.published
        data.training.sort = this.product.training.sort
        data.trainingSteps =
          data.trainingSteps && data.trainingSteps.length !== 0
            ? data.trainingSteps
            : this.product.trainingSteps
        data.trainingBestUsedFors =
          data.trainingBestUsedFors && data.trainingBestUsedFors.length !== 0
            ? data.trainingBestUsedFors
            : this.product.trainingBestUsedFors
        data.trainingCrossSellers =
          data.trainingCrossSellers && data.trainingCrossSellers.length !== 0
            ? data.trainingCrossSellers
            : this.product.trainingCrossSellers
        if (this.$route.query.draft) {
          data.training.states = 1
          data.training.isPublished = true
        }
        this.$emit("handleSendValue", data, this.product.id)
        return
      }
      this.$emit("showSaveBtn", true)
      this.$emit("hadaleSaveDraft", data, this.product)
      // If the required field is blank, it can be submitted to the parent component
      if (this.formatTraining(data)) {
        return
      }
      this.$emit("handleSendValue", data, this.product.id)
    },
    formatTraining(data) {
      if (!data || !data.training.howToDemo) {
        return true
      }
      // Because cross-seller is optional, so it can skip checking for nulls
      if (!checkArary(data.trainingSteps)) {
        return true
      }
      if (!checkArary(data.trainingBestUsedFors)) {
        return true
      }
      // if (!checkArary(data.trainingVideos)) {
      //   return true
      // }
    },
    openDialog() {
      if (
        this.$route.query.id &&
        this.product.trainingVideos &&
        this.product.trainingVideos.length !== 0
      ) {
        this.$refs["editUploadVideo"].openEditVideo(this.product, 1)
      } else if (this.uploadBtn) {
        this.$refs["editUploadVideo"].openEditVideo(this.trainingVideos, 2)
      } else {
        this.$refs["uploadVideo"].openUploadVideo()
      }
    },
    replaceVideo() {
      this.$refs["uploadVideo"].openUploadVideo()
    },
    // Upload video
    handleUploadInfo(sendInfo, isShowEdit) {
      this.trainingVideos.asset = {
        url: sendInfo.file.url || "",
        id: sendInfo.file.id || "",
        contentType: sendInfo.file.contentType || ""
      }
      this.$refs["uploadVideo"].closeUploadVideo()
      if (isShowEdit) {
        this.$refs["editUploadVideo"].openEditVideo()
      }
    },
    // Upload image
    handleEditVideoInfo(imageInfo, title) {
      if (Object.values(this.trainingVideos).length !== 0) {
        this.trainingVideos.imgAsset = { ...imageInfo }
        this.trainingVideos.title = title
      }
      this.uploadBtn = true
      this.sendTrainingVideo()
      this.sendParamsValue()
    },
    sendTrainingVideo() {
      if (!this.trainingVideos.title) {
        return
      }
      if (!Object.values(this.trainingVideos.asset).includes("")) {
        return
      }
      this.sendParamsValue()
    },
    cancelUpload() {
      this.trainingVideos = {}
    },
    handleCancel() {
      this.modalVisible = false
      this.$refs["videoM"].pause()
    },
    clickToVideo() {
      this.showModal = false
      this.modalVisible = true
      this.$refs["videoM"].play()
    }
  }
}
</script>

<style scoped lang="scss">
.product-edit-card__main {
  width: 100%;
  min-height: 494px;
  //padding-bottom: 25px;
  .line {
    width: 950px;
    height: 2px;
    margin-bottom: 25px;
    background: #595959;
  }
  .edit-card-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .edit-card-left {
      width: 206px;
      .left-img {
        width: 100%;
        min-height: 264px;
        margin-bottom: 16px;
        //background: rgba(191, 191, 191, 0.2);
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        img {
          width: 90%;
          height: 90%;
        }
        .demo-icon-tip {
          position: absolute;
          left: -15px;
          top: -5px;
          z-index: 3;
        }
      }
      .left-content {
        text-align: center;
        color: #000000;
        font-size: 14px;
        position: relative;
        font-family: "ProximaNova-Regular", "Proxima Nova", "Roboto", sans-serif;
        p {
          margin-bottom: 0;
        }
        .left-content-one {
          position: relative;
          .demo-icon-tip {
            position: absolute;
            left: 15px;
            top: 0;
            z-index: 3;
          }
        }
        .demo-icon-tip-one {
          position: absolute;
          left: -15px;
          top: 0;
          z-index: 3;
          i {
            font-size: 12px;
            color: #bfbfbf;
          }
        }
        .content-title-p {
          height: 44px;
          //width: 80%;
          //margin: 0 auto;
          text-align: center;
          word-wrap: break-word;
          word-break: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    .upload-content {
      width: 100%;
      position: relative;
      //text-align: right;
      margin: 16px 0;
      display: flex;
      justify-content: flex-start;
    }
    .video-style {
      width: 196px;
      height: 110px;
      margin-top: 16px;
      text-align: center;
      border: 1px solid #262626;
      cursor: pointer;
      position: relative;
      .video-img {
        //width: 100%;
        height: 100%;
      }
      .public-start {
        position: absolute;
        top: 37px;
        right: 80px;
        border-radius: 50%;
      }
      .background-style {
        width: 43px;
        height: 43px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        top: 34px;
        right: 83px;
      }
    }

    .upload-video-style {
      width: 192px;
      border: 1px solid #4a8a5d;
      color: #4a8a5d;
      background-color: #e2eeec;
      text-align: center;
    }
    .upload-success {
      width: 192px;
      border: 1px solid #4a8a5d;
      color: #ffffff;
      background-color: #4a8a5d;
      text-align: center;
    }
    .upload-icon-tip {
      padding-right: 2px;
      i {
        font-size: 12px;
        color: #bfbfbf;
        margin-top: 20px;
      }
    }
    .edit-card-right__main {
      width: 716px;
      margin-bottom: 20px;
    }
    .edit-card-center {
      //width: 342px;
      .center-key-benefits {
        //height: 221px;
        //margin-bottom: 30px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        .key-title {
          font-size: 16px;
          margin-bottom: 8px;
          color: #000000;
          i {
            font-size: 12px;
            color: #bfbfbf;
          }
        }
        ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          li {
            display: flex;
            justify-content: flex-start;
            width: 228px;
            height: 80px;
            line-height: 16px;
            //list-style-type: disc;
            margin-bottom: 16px;
            padding-left: 8px;
            word-wrap: break-word;
            word-break: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            position: relative;
            i {
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #000000;
              margin-right: 4px;
              margin-bottom: 35px;
              position: absolute;
              top: 4px;
              left: 0;
            }
            span {
              display: inline-block;
              width: 200px;
            }
          }
        }
      }
    }
    .edit-card-right {
      //width: 342px;
    }
  }
  .textarea-bottom {
    display: flex;
    justify-content: space-between;
  }
  .center-demo-textarea {
    width: 350px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    position: relative;
    .demo-icon-tip {
      position: absolute;
      left: 0;
      bottom: 2px;
      z-index: 3;
      i {
        font-size: 12px;
        color: #bfbfbf;
      }
    }
    .demo-number-tip {
      font-size: 8px;
      color: #262626;
      position: relative;
      left: 310px;
      bottom: 4px;
      z-index: 3;
      transform: scale(0.85);
    }
    .tip-content-style {
      width: 323px;
      height: 85px;
      font-size: 13px;
      color: #262626;
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
  }
  .demo-tip-style {
    font-size: 16px;
    margin-bottom: 4px;
    color: #000000;
  }
  .textarea-style {
    height: 109px;
  }
  .demo-icon-tip {
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .demo-tip-content {
    width: 350px;
    position: relative;
  }
  .demo-icon-tip {
    position: relative;
    left: 0;
    bottom: 2px;
    z-index: 3;
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .demo-icon-tip {
    position: relative;
    left: 0;
    bottom: 2px;
    z-index: 3;
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .demo-number-tip-style {
    font-size: 8px;
    color: #262626;
    position: absolute;
    left: 308px;
    bottom: 4px;
    z-index: 3;
  }

  .demo-optional-style {
    font-size: 8px;
    color: #262626;
    position: absolute;
    left: 250px;
    bottom: 4px;
    z-index: 3;
  }
  .upload-optional-style {
    font-size: 8px;
    color: #262626;
    position: absolute;
    left: 158px;
    bottom: -14px;
    z-index: 3;
  }
}
</style>
