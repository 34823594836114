import httpclient from "@/api/http-client";
const endpoint = "/api/upload";
export default {
  async uploadProducts(brandId, userId, file) {
    console.log("");
    const url = `${endpoint}/products/${brandId}?userId=${userId}`;
    return await httpclient
      .postForm(url, file)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  },
  async uploadIngredients(file) {
    console.log("");
    const url = `${endpoint}/ingredients`;
    return await httpclient
      .postForm(url, file)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  },
  async test() {
    const url = `${endpoint}/test`;
    return await httpclient.get(url).then((res) => res);
  },
};
