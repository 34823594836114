<template>
  <a-modal v-model="confirmDialog" width="423px" :title="null" :closable="false" :footer="null">
    <div class="confirm-dialog">
      <a-row>
        <a-icon type="exclamation-circle" style="color: #FF6132; font-size: 20px;" />
        <span class="confirm-dialog__msg">{{ message }}</span>
      </a-row>
      <a-row v-if="subMessage">
        <span class="confirm-dialog__sub-msg"> {{ subMessage }} </span>
      </a-row>
      <a-row style="text-align: center; margin-top: 30px;">
        <a-button class="confirm-dialog__cancel-btn" @click="cancel">
          {{ cancelText }}
        </a-button>
        <a-button class="confirm-dialog__confirm-btn" type="primary" @click="doConfirm">
          {{ okText }}
        </a-button>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      confirmDialog: false,
      message: "",
      subMessage: undefined,
      cancelText: "No",
      okText: "Yes",
      value: null,
      resolvePromise: null,
    };
  },
  methods: {
    cancel() {
      this.confirmDialog = false;
      this.$emit("cancelDialog", this.value);
      this.setResolvePromise(false);
    },
    doConfirm() {
      this.$emit("doConfirm", this.value);
      this.confirmDialog = false;
      this.setResolvePromise(true);
    },
    openDialog(msg, cancel, ok) {
      this.cancelText = cancel;
      this.okText = ok;
      this.openDialogMsg(msg);
    },
    setResolvePromise(value) {
      if (this.resolvePromise != null) {
        this.resolvePromise(value);
        this.resolvePromise = null;
      }
    },
    openDialog$(msg, cancel, ok) {
      const promise = new Promise((resolve) => {
        this.resolvePromise = resolve;
      });
      this.openDialog(msg, cancel, ok);
      return promise;
    },
    openDialogConfirm(msg, submsg, cancel, ok) {
      this.subMessage = submsg;
      this.openDialog(msg, cancel, ok);
    },
    openDialogMsg(msg, value) {
      this.value = value;
      this.message = msg;
      this.confirmDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm-dialog {
  min-height: 100px;
  padding: 8px;

  &__msg {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
    margin-left: 18px;
  }

  &__sub-msg {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
  }

  &__cancel-btn {
    background: #ffffff !important;
    border: 1px solid #bfbfbf !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 5px 16px !important;
    margin-right: 10px;
    color: #595959;
  }

  &__confirm-btn {
    background: #4a8a5d !important;
    border: 1px solid #4a8a5d !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 5px 16px !important;
    color: white;
  }
}
</style>
