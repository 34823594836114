<template>
  <div class="press-body">
    <div class="press-container">
      <div class="press-intro">
        <h1>Press</h1>
      </div>
      <a-row :gutter="[24, 24]">
        <a-col v-for="(item, index) in item_list" :key="index" :sm="24" :md="12" :xl="8">
          <div class="press-box">
            <img :src="item.img" />
            <div class="press-text">
              <span>{{ item.Title }}</span>
              <p>{{ item.Content }}</p>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import Hollywood from "@/assets/img/press/fiverr logo copy 2.png"
import Allure from "@/assets/img/press/andco logo copy 2.png"
import Voyage from "@/assets/img/press/Bitmap 1.png"
import Wwd from "@/assets/img/press/andco logo.png"
import Refine from "@/assets/img/press/fiverr logo.png"
import Lat from "@/assets/img/press/andco logo copy.png"
export default {
  computed: {
    item_list() {
      const img_list = [
        { img: Hollywood },
        { img: Allure },
        { img: Voyage },
        { img: Wwd },
        { img: Refine },
        { img: Lat }
      ]
      const text_list = this.$t("Auth.Press")
      return text_list.map((item, index) => {
        return { ...item, ...img_list[index] }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "Roboto";
  src: url("~@/assets/fonts/Roboto-Medium.ttf") format("truetype");
}

.press-body {
  text-align: center;
  margin-top: 150px;
  .press-intro {
    margin-bottom: 80px;
    font-family: "Playfair Display Bold";
    font-size: 48px;
    h1 {
      color: #12433d;
      margin-top: 40px;
    }
  }
}

.press-container {
  //width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .press-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .press-container {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  .press-container {
    max-width: 1140px

  }
}
.ant-row {
  .ant-col {
    .press-box {
      //border-style: solid;
      width: 330px;
      border: 2px solid #12433d;
      height: 300px;
      margin: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      font-family: "ProximaNova-Regular", sans-serif;
      font-size: 14px;
      font-weight: normal;
      position: relative;
      img {
        position: absolute;
        top: 141px;
        left: 163px;
      }
    }

    .press-box:hover {
      width: 330px;
      height: 300px;
      background-color: #12433d;
      color: #fff;
      //margin: 10px;
      border: 2px solid #fff;
      text-align: center;

      div {
        opacity: 1;
        display: block;
      }
      img {
        display: none;
      }
    }
  }
}

.press-text {
  opacity: 0;
  display: none;
  position: absolute;
  width: 85%;
  padding: 0 15px 0;
  //font-family: "Roboto";
  font-family: "ProximaNova-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;

  span {
    display: inline-block;
    width: 96%;
    //font-size: 16px;
    text-align: center;
    color: #fff;
    font-weight: 600;
  }
}

</style>
