<template>
  <div class="register-popup" @mouseover="showPopup = true" @mouseout="hidePopup">
    <span>
      <img src="@/assets/img/orders/info-icon.svg" />
    </span>
    <span class="register-popuptext" :class="{'show': showPopup}">
      {{text}}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: { type: String, default: "" }
  },
  data() {
    return {
      showPopup: false
    }
  },
  methods: {
    hidePopup() {
      this.showPopup = false
    }
  }
}
</script>

<style>
.register-popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.register-popup .register-popuptext {
  visibility: hidden;
  font-size: 12px;
  width: 200px;
  background-color: #eaeaea;
  color: #8d8d8d;
  text-align: center;
  padding: 12px;
  position: absolute;
  z-index: 100;
  bottom: 95%;
  left: 50%;
  margin-left: -70px;
}

.register-popup .register-popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #eaeaea transparent transparent transparent;
}

.register-popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
