<template lang="pug">
.old-order-activity
  .old-order-activity__title {{$t('Components.Orders.OrderActivity.comments')}}
  .columns.is-mobile.is-centered.old-order-activity__tabs
    .column.is-narrow.is-paddingless.old-order-activity__tab(@click="displayComments()" :class="{ 'old-order-activity__tab--active': showComments }")
      img(src="@/assets/img/orders/comment-icon.svg")
    .column
    .column
  v-app(id="inspire",v-if="order.comments")
    v-timeline(:align-top="alignTop"
      :dense="dense"
      :reverse="reverse")
      v-timeline-item(v-for='comment in order.comments', :key="comment.createdAt"
        :fill-dot="fillDot"
        :hide-dot="hideDot"
        :icon="icon ? 'mdi-star' : ''"
        :icon-color=" iconColor ? 'deep-orange' : ''"
        :left="left"
        :right="right"
        :small="small")
        template(v-slot:icon='')
          v-avatar(v-if="commentAuthor(comment).logoAsset.url")
            img(:src="commentAuthor(comment).logoAsset.url")
          v-avatar(v-else)
            img(src="@/assets/img/default-logo-image.png")
        v-card.elevation-2
          v-card-title.headline
            span.commentAuthor {{commentAuthor(comment).name + ' '}}
            span.commentDate {{'commented on'+ ' '}}
            span.commentDate {{humanReadableDate(comment.createdAt)}}
          v-card-text
            | {{comment.text}}
  .old-order-activity__comment-input-group
    input.input.old-order-activity__comment-input(
      placeholder="Write a comment" v-model.trim="newCommentText"
      @keyup.enter="addComment()"
    )
    a.button.old-order-activity__coment-input-button(
      @click="addComment()"
    ) {{$t('Components.Orders.OrderActivity.post')}}
</template>

<script>
export default {
  props: {
    brand: { type: Object, default: () => {} },
    retailer: { type: Object, default: () => {} },
    order: { type: Object, default: () => {} }
  },
  data() {
    return {
      showComments: true,
      showTimeline: true,
      newCommentText: "",
      alignTop: false,
      avatar: false,
      dense: true,
      fillDot: false,
      hideDot: false,
      icon: false,
      iconColor: false,
      left: false,
      reverse: false,
      right: true,
      small: false
    }
  },
  computed: {
    userId() {
      return this.$store.getters["user/id"]
    }
  },
  methods: {
    commentAuthor(item) {
      let result = {}
      let clientId = item.createdBy
      let retailerMembers = this.retailer.members || []
      let brandMembers = this.brand.members || []
      result = retailerMembers.find(member => member.user.id == clientId)
      if (result) {
        return this.retailer
      }
      result = brandMembers.find(member => member.user.id == clientId)
      if (result) {
        return this.brand
      }
      return { name: "Landing Admin", logoAsset: {} }
    },
    humanReadableDate(isoString) {
      const date = new Date(isoString)
      const time = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false
      })
      const month = date.toLocaleString("en-US", { month: "short" })
      return `${date.getDate()} ${month} ${date.getFullYear()} ${time}`
    },
    addComment() {
      if (!this.newCommentText) {
        return
      }
      const newComment = {
        text: this.newCommentText,
        createdAt: new Date().toISOString(),
        createdBy: this.userId
      }
      let params = {}
      if (this.$route.name == "adminBrandOrder") {
        params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      }
      if (this.$route.name == "adminRetailerOrder") {
        params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      }
      this.$store.dispatch("order/pushOrderComment", {
        orderId: this.order.id,
        comment: newComment,
        params
      })
      this.newCommentText = ""
    }
  }
}
</script>

<style lang="sass">
.v-avatar
  background: white !important
  border-radius: 10%
.old-order-activity
  padding-top: 40px
  &__logo
    width: 36px
    height: auto
  &__tabs
    padding-bottom: 54px
    padding-top: 0
    border-top: 1px solid #DFE3E8
    height: 18px
    box-sizing: border-box
  &__tab
    cursor: pointer
    width: 30px
    height: 26px
    text-align: center
    margin-right: 5px
  &__tab--active
    border-top: 2px solid black
  &__time-tab
    position: absolute
    left: 35px
    padding-right: 8px
    padding-left: 8px
    cursor: pointer
  &__title
    font-size: 16px
    color: #212B36
    padding-bottom: 20px
    padding-left: 20px
  &__timeline-container
    border-left: 2px solid #DFE3E8
    margin-left: 20px
  &__timeline-logo-icon
    margin-left: -16px
    padding: 1px
    border: 1px solid #aaaaaa
    height: 37px
    width: 37px
    background: white
  &__timeline-clock-icon
    margin-left: -9px
    z-index: 999
    img
      background: white
  &__account-name
    margin-left: 10px
    color: #4A90E2
    font-size: 14px
    font-weight: bold
  &__date
    margin-left: 10px
    color: #AAAAAA
    font-size: 14px
    font-weight: 500
    text-transform: lowercase
  &__comment
    margin-left: 10px
    font-size: 14px
    color: #333333
  &__comment-input-group
    margin-top: 20px
    position: relative
    margin-bottom: 20px
  &__comment-input
    height: 44px
    font-size: 16px
  &__comment-input::placeholder
    font-size: 16px !important
  &__coment-input-button
    position: absolute
    margin-left: -60px
    margin-top: 5px
    background-color: #F9FAFB
    color: #919EAB
    font-size: 15px
  &__event:last-child
    background: white
    height: 100%
.old-order-activity__timeline-container
  .time-line__wrap
    height: 100%
    margin-top: 0px
    display: flex
    .time-line-list
      margin: 0
      padding: 0
      list-style: none
      width: 100%
      position: relative
      overflow: auto
      display: flex
      flex-direction: column
      .time-line
        position: relative
      .time-line:after
        display: inline-block
        width: 2px
        height: 100%
        background-color: #D7D9DE
        position: absolute
        top: 27px
        left: 17px
        z-index: 1
        .img-block
          position: absolute
          top: 0
          left: 0
        .old-order-activity__block
          padding: 14px 0px 11px 36px
.commentDate
  color: #AAAAAA
  font-size: 14px
  margin-right: 6px
.commentAuthor
  color: #4A90E2
  font-size: 14px
  font-weight: bold
  margin-right: 6px
.v-application--wrap
  min-height: 0px!important
</style>
