<template>
  <div class="print-order-modal__main">
    <a-modal
      v-model="printVisible"
      width="595px"
      :footer="null"
      :title="null"
      :closable="true"
      :mask-closable="true"
      @cancel="printVisible = false"
    >
      <div id="printContent">
        <div class="modal-main-print-pay-order" style="padding: 11px;">
          <div class="print-order-title">
            <div>
              <p>LANDING INTERNATIONAL</p>
              <p>1330 W. 12th Street, Suite A</p>
              <p>Los Angeles, CA 90015</p>
              <p>P: 310-924-2696</p>
              <p>Orders@landingintl.com</p>
            </div>
            <div>
              <img src="@/assets/img/orders/print-landing-voyagelanding.png" alt="printImg" />
              <p class="modal-title-right">{{ printTabTxt }}</p>
            </div>
          </div>
          <div style="margin-top: 30px;">
            <a-row>
              <a-col :span="16">
                <div
                  class="print-black-txt"
                  style="margin-top: 50px; margin-bottom: 9px;width: 160px;"
                >
                  Retailer:
                </div>
                <div
                  class="print-gray-txt"
                  style="margin-bottom: 6px;width: 160px;line-height: 18px"
                >
                  {{ retailer.name }}
                </div>
                <div
                  class="print-gray-txt"
                  style="margin-bottom: 6px;width: 160px;line-height: 18px"
                >
                  {{ headquarters.streetAddressLine_1 }}
                </div>
                <div
                  v-if="headquarters.streetAddressLine_2"
                  class="print-gray-txt"
                  style="margin-bottom: 6px;width: 200px;line-height: 18px"
                >
                  {{ headquarters.streetAddressLine_2 }}
                </div>
                <div v-if="headquarters.city" class="print-gray-txt">
                  {{ headquarters.city }}, {{ headquarters.region }} {{ headquarters.postalCode }}
                </div>
                <div class="print-gray-txt" style="margin-bottom: 8px;">
                  {{ countryName(headquarters.country.id) }}
                </div>
                <div style="float: right">
                  <div style="float: right; display: inline-block; margin-left: 25px;">
                    <div class="print-black-txt">Cancel Date</div>
                    <div class="print-gray-txt" style="margin-bottom: 6px;">
                      {{ orderItem.cancelDate }}
                    </div>
                  </div>
                  <div style="float: right; display: inline-block;">
                    <div class="print-black-txt">Ship Date</div>
                    <div class="print-gray-txt" style="margin-bottom: 6px;">
                      {{ formatActualShipDate() }}
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="8" style="text-align: right;">
                <div style="margin-bottom: 12px;">
                  <div class="print-black-txt">Invoice #</div>
                  <div class="print-gray-txt">
                    {{ orderItem.invoiceId ? orderItem.invoiceId : "--" }}
                  </div>
                </div>
                <div style="margin-bottom: 12px;">
                  <div class="print-black-txt">Landing Ref ID</div>
                  <div class="print-gray-txt">{{ orderItem.landingReferenceId }}</div>
                </div>
                <div style="margin-bottom: 12px;">
                  <div class="print-black-txt">Reference ID</div>
                  <div class="print-gray-txt">
                    {{ orderItem.retailerReferenceId ? orderItem.retailerReferenceId : "--" }}
                  </div>
                </div>
                <div style="margin-bottom: 12px;">
                  <div class="print-black-txt">Payment Date</div>
                  <div class="print-gray-txt">{{ getPayDate() }}</div>
                </div>
                <div>
                  <div class="print-black-txt">Order Date</div>
                  <div class="print-gray-txt">{{ orderItem.orderDate }}</div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="modal-main-print-pay-order">
          <div class="modal-print-order-table" style="margin-left: -24px; margin-right: -24px;">
            <table class="table is-fullwidth">
              <thead>
                <tr class="table-tr-color-style">
                  <th class="print-order-table-td-style" width="17%">SKU</th>
                  <th class="print-order-table-td-style" width="34%">Name</th>
                  <th class="print-order-table-td-style" width="16%">Quantity</th>
                  <th class="print-order-table-td-style" width="17%">Unit price</th>
                  <th class="print-order-table-td-style" width="16%">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in tableList" :key="item.id">
                  <td class="print-orders__table-cell">{{ item.product.sku }}</td>
                  <td class="print-orders__table-cell">{{ item.product.name }}</td>
                  <td class="print-orders__table-cell">
                    <a-input-number v-model="item.quantity" :min="1" />
                  </td>
                  <td v-if="item.quantityType.id !== 'testers'" class="print-orders__table-cell">
                    {{
                      item.calculatedWholesalePriceUsd || item.calculatedWholesalePriceUsd === 0
                        ? `$${item.calculatedWholesalePriceUsd}`
                        : "-"
                    }}
                  </td>
                  <td v-else class="print-orders__table-cell">
                    {{ "-" }}
                  </td>
                  <td v-if="item.quantityType.id !== 'testers'" class="print-orders__table-cell">
                    {{
                      item.calculatedWholesaleCostUsd || item.calculatedWholesalePriceUsd === 0
                        ? `$${item.calculatedWholesaleCostUsd}`
                        : "-"
                    }}
                  </td>
                  <td v-else class="print-orders__table-cell">
                    {{ "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!--          router-link.retailer-orders__table-row(:to="{ name: isAdmin?'adminRetailerOrder':'retailerOrder', params: { id: order.id }}" v-for="order in retailerOrders" tag="tr")-->
          </div>
          <a-row style="margin-top: 30px; padding-left: 11px; padding-right: 11px;">
            <a-col :span="12" style="min-height: 100px">
              <PaymentCount
                :order="orderItem"
                :has-retailer="true"
                :has-edit="true"
                :is-payment="true"
                @handlePaymentValue="handlePaymentValue"
              ></PaymentCount>
            </a-col>
            <a-col :span="12">
              <div class="bottom-invoice-note">
                <a-textarea
                  v-model="orderItem.paymentAdviceNote"
                  placeholder="Notes"
                  :auto-size="{ minRows: 3 }"
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-row>
        <a-col :span="16"></a-col>
        <a-col :span="8" style="text-align: right;">
          <a-button
            type="primary"
            style="margin-right: 10px; background-color: #FF6132; border-color: #D9D9D9;"
            class="action-btn"
            @click="approveOrShare"
          >
            APPROVE & SHARE
          </a-button>
          <!--          <a-button class="action-btn" @click="clickToClose">Close</a-button>-->
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
// import imgLanding from "@/assets/img/orders/print-landing-voyagelanding.png"
import constants from "@/config.js"
import { priceFormat } from "@/utils/validate"
import PaymentCount from "./orderComponent/orderPaymentCount"

export default {
  name: "PrintOrderModal",
  components: {
    PaymentCount
  },
  // props: {
  //   orderItem: {
  //     type: Object,
  //     default: () => {}
  //   }
  // },
  data() {
    return {
      priceFormat,
      printVisible: false,
      tabId: 4,
      printTabTxt: "",
      orderItem: {},
      total: 0,
      selectType: [
        {
          id: "credit",
          name: "Credit"
        },
        {
          id: "debit",
          name: "Debit"
        }
      ],
      typeValue: null,
      chargeAmount: null
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    countries() {
      return this.$store.getters["reference/countries"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    tableList() {
      if (this.orderItem.items && this.orderItem.items.length !== 0) {
        return this.orderItem.items
          .filter(item => item.quantityType.id === "purchase")
          .map(value => {
            if (value.adjustedQuantity || value.adjustedQuantity === 0) {
              value.quantity = value.adjustedQuantity
            }
            return value
          })
      }
      return []
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    headquarters() {
      if (!this.retailer.addresses) {
        return
      }
      return this.retailer.addresses.filter(item => item.hq)[0]
    }
  },
  methods: {
    openDialog(order) {
      this.printVisible = true
      this.printTabTxt = "Payment Advice"
      this.orderItem = order
    },
    countryName(id) {
      return this.$store.getters["reference/countryName"](id)
    },
    getPayDate() {
      if (this.orderItem && this.orderItem.lifecycle) {
        for (let i = 0; i < this.orderItem.lifecycle.length; i++) {
          let lifeStatus = this.orderItem.lifecycle[i]
          if (lifeStatus.status.id === "paid-to-brand" && lifeStatus.transitionedAt) {
            return lifeStatus.transitionedAt.slice(0, 10)
          }
        }
      }
      return "--"
    },
    clickToPrint() {
      // const btns = document.getElementsByClassName("action-btn")
      // if (btns && btns.length > 0) {
      //   for (let i = 0; i < btns.length; i++) {
      //     btns[i].style.display = "none"
      //   }
      // }
      // window.print()
      // if (btns && btns.length > 0) {
      //   for (let i = 0; i < btns.length; i++) {
      //     btns[i].style.display = "inline-block"
      //   }
      // }
    },
    handlePaymentValue(type, value) {
      this.typeValue = type
      this.chargeAmount = value
    },
    async approveOrShare() {
      const list = this.orderItem.items.filter(item => item.quantityType.id === "testers") || []
      const items = this.tableList.concat(list)
      let params = {
        id: this.orderItem.id,
        version: Number(this.orderItem.version) + 1,
        isBrandAccepted: this.orderItem.brandAccepted,
        isRetailerAccepted: this.orderItem.retailerAccepted,
        status: {
          id: constants.orderStatus.paidToBrand
        },
        items,
        paymentAdviceNote: this.orderItem.paymentAdviceNote ? this.orderItem.paymentAdviceNote : "",
        paymentAdviceStatus: true
      }
      if (this.typeValue && this.chargeAmount) {
        const chargeItem = {
          ownedBy: {
            id: "brand"
          },
          paidBy: {
            id: "landing"
          },
          addedBy: {
            id: this.isAdmin ? "landing" : "brand"
          },
          chargeType: {
            id: "custom"
          },
          calculatedCostUsd: this.chargeAmount,
          paymentType: {
            id: this.typeValue
          },
          percentage: false,
          recordedValue: this.chargeAmount,
          seenByBrand: true,
          seenByRetailer: true,
          seenByLanding: true,
          note: ""
        }
        let list = this.orderItem.charges.filter(
          item =>
            !(
              item.ownedBy.id === "brand" &&
              item.paidBy.id === "landing" &&
              item.chargeType.id === "custom"
            )
        )
        list.push(chargeItem)
        params.charges = list
      }
      this.$emit("updataEditProduct", params)
      this.printVisible = false
    },
    clickToClose() {
      this.printVisible = false
    },
    countryNameById(countryId) {
      const country = this.countries.find(country => country.id === countryId)
      return country && country.name
    },
    formatActualShipDate() {
      if (
        this.orderItem &&
        this.orderItem.airShipments &&
        this.orderItem.airShipments.length !== 0
      ) {
        return this.orderItem.airShipments[0].actualShipDate
      } else {
        return "--"
      }
    }
  }
}
</script>

<style lang="scss">
.print-order-modal__main {
  padding: 0px;
}

.modal-main-print-pay-order {
  p {
    margin-bottom: 0;
  }

  .print-order-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    p {
      color: #000000;
      font-size: 10px;
      transform: scale(0.83, 0.83);
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
    .modal-title-right {
      font-size: 14px;
      color: #000000;
      margin-top: 17px;
      text-align: right;
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
  }
  .print-gray-txt {
    font-family: Proxima Nova Alt;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #8c8c8c;
  }
  .print-black-txt {
    font-family: Proxima Nova Alt;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000;
    margin-bottom: 5px;
  }
  .table-foot-txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    padding-top: 63px;
    padding-left: 35px;
  }
  .modal-print-order-table {
    margin-top: 45px;
  }

  .table-tr-color-style {
    //background-color: #e2eeec;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-size: 10px;

    .print-order-table-td-style {
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .print-orders__table-cell {
    font-size: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  .bottom-content {
    padding-left: 35px;
    padding-right: 25px;
  }
  .bottom-note {
    min-width: 400px;
    min-height: 123px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .bottom-txt-gray {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #8c8c8c;
    float: left;
  }
  .bottom-txt-price {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    float: right;
  }
  .bottom-txt-payment {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .bottom-invoice-note {
    width: 80%;
    display: inline-block;
    min-height: 123px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    position: absolute;
    margin-left: 44px;
  }
  .other-charge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
