import { CreditCard } from "@/models/CreditCard";
export class Payment {
  constructor(model) {
    this.firstName = "";
    this.lastName = "";
    this.creditCard = null;
    this.bankAccount = null;
    if (model) {
      this.firstName = model.firstName;
      this.lastName = model.lastName;
      this.creditCard = model.creditCard
        ? new CreditCard(model.creditCard)
        : null;
      this.bankAccount = model.bankAccount;
      this.customerPaymentProfileId = model.customerPaymentProfileId;
    }
  }
}

export class BillingAddress {
  constructor(model) {
    this.addressLine1 = "";
    this.city = "";
    this.state = "";
    this.zipCode = "";
    if (model) {
      this.addressLine1 = model.addressLine1;
      this.city = model.city;
      this.state = model.state;
      this.zipCode = model.zipCode;
    }
  }
}
export class CustomerPayment {
  constructor(model) {
    this.firstName = "";
    this.lastName = "";
    this.cardNumber = "";
    this.expirationDate = "";
    this.cvvCode = "";
    this.billingAddress = null;
    if (model) {
      this.firstName = model.firstName;
      this.lastName = model.lastName;
      this.cardNumber = model.cardNumber;
      this.expirationDate = model.expirationDate;
      this.cvvCode = model.cvvCode;
      this.billingAddress = new BillingAddress(model.billingAddress);
    }
  }
}

export class SubscriptionTransaction {
  constructor(model) {
    //this.friendlyName = "";
    this.subscriptionName = "";

    this.name = "";
    this.paymentSchedule = null;
    this.amount = 0;
    this.transactions = [];
    if (model) {
      this.name = model.friendlyName;
      this.subscriptionName = model.subscriptionName;
      this.paymentSchedule = new PaymentSchedule(model.paymentSchedule);
      this.amount = model.amount;
      this.transactions = model.transactions
        ? model.transactions.map((t) => new PaymentTransaction(t))
        : [];
    }
  }
}

export class PaymentSchedule {
  constructor(model) {
    this.startDate = null;
    this.totalOccurrences = 0;
    this.trialOccurrences = 0;
    this.interval = null;
    if (model) {
      this.startDate = model.startDate ? new Date(model.startDate) : null;
      this.totalOccurrences = model.totalOccurrences;
      this.trialOccurences = model.trialOccurences;
      this.interval = new PaymentInterval(model.interval);
    }
  }
}

export class PaymentInterval {
  constructor(model) {
    this.length = 0;
    this.intervalType = 0;

    if (model) {
      this.length = model.length;
      this.intervalType = model.intervalType;
    }
  }
}

export class PaymentTransaction {
  constructor(model) {
    this.transactionId = "";
    this.response = "";
    this.submitTimeUTC = null;
    this.paymentNumber = 0;
    this.attempNumber = 1;
    this.amount = 0;
    if (model) {
      this.transactionId = model.transactionId;
      this.response = model.response;
      this.submitTimeUTC = model.submitTimeUTC
        ? new Date(model.submitTimeUTC)
        : null;
      this.paymentNumber = model.paymentNumber;
      this.attempNumber = model.attempNumber;
      this.amount = model.amount;
    }
  }
}
