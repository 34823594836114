<template>
  <div class="brand-buyer-box__main">
    <div class="brand-buyer-title">
      <div class="top-title">
        <span>{{ this.$t("brandBuyerBox.title") }}</span>
      </div>
      <div class="text-info">{{ this.$t("brandBuyerBox.content") }}</div>
    </div>
    <div class="card-content-buyer">
      <div v-for="(item, index) in textList" :key="index" class="card-special-style">
        <div class="buyer-card">
          <div class="buyer-img" :style="{ background: item.color, color: item.textColor }">
            <p v-if="index === 4" class="buyer-img-special">
              <span>{{ item.title1 }}</span><br/>
              <span>{{ item.title2 }}</span>
            </p>
            <span v-else v-text="item.title"></span>
          </div>
          <div class="buyer-content">
            <p class="buyer-content-title">{{ item.month }}</p>
            <p class="buyer-text-content">{{ item.monthContent }}</p>
            <span>{{ item.inspiration }}</span>
            <p class="buyer-text-content" :class="index === 5 ? 'special-width' : ''">
              {{ item.inspirationContent }}
            </p>
            <span>{{ item.deadline }}</span>
            <p class="buyer-text-content">{{ item.deadlineContent }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandBuyerBox",
  computed: {
    textList() {
      return this.$t("brandBuyerBox.cardList")
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Proxima Nova";
  src: url("~@/assets/fonts/ProximaNova-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Playfair Display";
  src: url("~@/assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}

.brand-buyer-box__main {
  padding-bottom: 220px;
  .brand-buyer-title {
    position: relative;
    margin-bottom: 288px;
  }
  .top-title {
    width: 100%;
    height: 367px;
    font-family: "Playfair Display", "ProximaNova-Regular", "Roboto", sans-serif;
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    color: white;
    background-color: #12433d;
    padding-top: 120px;
    span {
      margin-top: 10px;
    }
  }

  .text-info {
    width: 952px;
    height: 380px;
    padding: 32px 69px;
    background-color: white;
    font-size: 26px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    line-height: 36px;
    color: #000000;
    text-align: center;
    position: absolute;
    top: 234px;
    left: calc(50% - 476px);
    //top: calc(50% - 50px);
    z-index: 3;
  }

  .card-content-buyer {
    width: 1205px;
    margin: 0 auto;
    //background-color: #4a8a5d;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    flex-wrap: wrap;
  }
  .buyer-card {
    width: 343px;
    margin-bottom: 56px;
  }
  .buyer-img {
    width: 100%;
    height: 290px;
    font-size: 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: "Playfair Display", "ProximaNova-Regular", "Roboto", sans-serif;
    span {
      line-height: 52px;
    }
    .buyer-img-special {
      line-height: 52px;
    }
  }
  .buyer-content {
    width: 272px;
    font-size: 18px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    padding-left: 17px;
    span {
      font-weight: bold;
      color: #000000;
    }
  }
  .card-special-style:nth-child(1),
  .card-special-style:nth-child(6) {
    .buyer-img span {
      width: 300px;
    }
  }
  .card-special-style:nth-child(2),
  .card-special-style:nth-child(4) {
    .buyer-content {
      width: 272px;
    }
    .buyer-img span {
      width: 280px;
    }
  }
  .buyer-text-content {
    width: 272px;
    color: #000000;
    line-height: 24px;
    word-break: break-word;
    margin-bottom: 16px;
  }
  .buyer-content-title {
    font-size: 20px;
    color: #000000;
    font-weight: bold;
    margin: 20px 0;
  }
  .card-special-style:nth-child(5) {
    .buyer-text-content {
      width: 268px;
    }
  }
  //.card-special-style:nth-child(9) {
  //  .buyer-content {
  //    width: 272px;
  //  }
  //  .buyer-img span {
  //    width: 280px;
  //  }
  //}
  .special-width {
    width: 266px;
  }
}
</style>
