<template>
  <div class="column">
    <div class="brand-profile-right-item__outlined">
      <div class="brand-profile-right-item__title">
        {{ title }}
        <span v-if="title === $t('Components.Brands.Profile.BrandProfile.brandValues')">
          <RetailerPopup :text="$t('Components.Brands.Profile.BrandProfile.brandValueToolTip')" />
        </span>
      </div>
      <div>
        <span class="brand-profile-right-item__content">
          {{ getContent() }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RetailerPopup from "@/components/retailers/profile/RetailerPopup"
export default {
  components: {
    RetailerPopup
  },
  props: {
    title: { type: String, required: true },
    data: { type: Array, default: () => [] }
  },
  methods: {
    getContent() {
      if (this.data && this.data.length > 0) {
        if (this.data.length <= 5) {
          let a = this.data.map(item => item.name).join(", ")
          return a
        } else {
          let msg = ""
          for (let i = 0; i < 5; i++) {
            msg += this.data[i].name
            if (i < 4) {
              msg += ", "
            }
          }
          return msg
        }
      }
      return "N/A"
    }
  }
}
</script>

<style lang="sass">
.brand-profile-right-item
    &__outlined
      border: 1px solid rgba(151, 151, 151, 0.234149)
      border-radius: 2px
      box-sizing: border-box
      padding: 24px
    &__title
        font-size: 24px !important
        font-weight: normal !important
        line-height: 24px !important
        color: #000000 !important
        margin-bottom: 24px !important
        letter-spacing: 0.25px
    &__content
        font-family: Proxima Nova
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 22px
        letter-spacing: 0.13125px
        color: #000000
</style>
