<template lang="pug">
.retailer-order-table.table-border-t-none(ref="editAdditionChargeMain")
  .reretailer-order-table__header-container
    p.retailer-order-table__name {{isAdmin ? 'Brand Additional Charge' : $t('Retailers.RetailerOrder.additionalCharge')}}
      span.edit-addition-charge(@click="showAddCharges" v-if="!isShowPrint && !isRetailer && order.status && order.status.id == orderStatus.pending && !order.declined" :disabled="isEditing")
        a-icon(type="edit")
  table.table.is-fullwidth
    thead
      tr
        th.retailer-order-table__head Type
        th.retailer-order-table__head {{$t('Retailers.RetailerOrder.description')}}
        th.retailer-order-table__head(width="95px") Amount
        //td.retailer-order-table__cell(v-if="totalAdditionalCharge<0") {{priceFormat(totalAdditionalCharge * -1)}}
        //td.retailer-order-table__cell(v-else) {{priceFormat(totalAdditionalCharge)}}
    tbody(v-if="additionalCharges && additionalCharges.length !== 0")
      tr(v-for="item in additionalCharges")
        td.retailer-order-table__cell(style="color: #4a8a5d !important") {{item.paymentType.name}}
        td.retailer-order-table__cell(style="color: #4a8a5d !important") {{item.note}}
        td.retailer-order-table__cell(v-if="additionalCharges.percentage && totalAdditionalCharge<0" style="color: #4a8a5d !important") {{priceFormat(totalAdditionalCharge * -1)}}
        td.retailer-order-table__cell(v-else-if="additionalCharges.percentage && totalAdditionalCharge>0" style="color: #4a8a5d !important") {{priceFormat(totalAdditionalCharge)}}
        td.retailer-order-table__cell(v-else style="color: #4a8a5d !important") ${{Number(item.recordedValue).toFixed(2)}}
  a-modal(v-model="editVisible" width="350px" :footer="null" :title="'Additional Charge'" :closable="true" :centered="true" :get-container="() => $refs.editAdditionChargeMain")
    .select-or-input
      p Type
      a-select(style="width: 294px" v-model="typeValue" placeholder="Select")
        a-select-option(v-for="item in selectType" :key="item.id" :value="item.name") {{ item.name }}
    .select-or-input
      p Amount
      a-input(style="width: 294px" placeholder="Input" v-model="amountValue" type="number")
    .select-or-input
      p Description
      a-textarea(style="width: 294px" :auto-size="{ minRows: 5, maxRows: 7 }" placeholder="Enter description" v-model="descriptionText")
    .button-apply-style
      span(@click="applyAdditional") Apply
</template>

<script>
import constants from "@/config.js"
// import { required } from "vuelidate/lib/validators"
import { priceFormat } from "@/utils/validate"

export default {
  props: {
    order: { type: Object, default: () => {} },
    isShowPrint: { type: Boolean, default: false }
  },
  data() {
    return {
      priceFormat,
      orderStatus: constants.orderStatus,
      showAddChargeFileds: false,
      additionalCharge: {
        chargeType: {
          id: "",
          name: ""
        },
        ownedBy: {
          id: ""
        },
        paidBy: {
          id: ""
        },
        percentage: false,
        seenByBrand: false,
        seenByLanding: false,
        seenByRetailer: false,
        recordedValue: null,
        calculatedCostUsd: 0.0,
        note: ""
      },
      purchaseDetails: [
        {
          calculatedRetailCostUsd: "",
          calculatedWholesaleCostUsd: "",
          calculatedWholesalePriceUsd: "",
          createdAt: "",
          createdBy: "",
          product: {
            id: "",
            name: "",
            sku: ""
          },
          quantity: "",
          quantityType: {
            id: "",
            name: ""
          },
          testersquantity: "",
          recordedMargin: "",
          recordedMsrpUsd: ""
        }
      ],
      itemIndex: -1,
      editingIndex: -1,
      isEditing: false,
      productIndex: -1,
      productEditIndex: -1,
      isSaveDisabled: true,
      editVisible: false,
      typeValue: null,
      amountValue: null,
      descriptionText: null,
      selectType: [
        {
          id: "credit",
          name: "Credit"
        },
        {
          id: "debit",
          name: "Debit"
        }
      ]
    }
  },
  computed: {
    charges() {
      return (
        this.$store.getters["reference/orderChargeType"] &&
        this.$store.getters["reference/orderChargeType"].filter(item => item.id == "custom")
      )
    },
    isRetailer() {
      return this.role === "retailer" || this.$route.name === "adminRetailerOrder"
    },
    isBrand() {
      return this.role === "brand" || this.$route.name === "adminBrandOrder"
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    additionalCharges() {
      let result
      if (this.isBrand) {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item => item.seenByBrand && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      } else if (this.isRetailer) {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByRetailer && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      } else {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByLanding && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      }
      return result
    },
    role() {
      return this.$store.getters["user/role"]
    },
    totalAdditionalCharge() {
      if (this.additionalCharges) {
        return this.additionalCharges.reduce((total, charge) => {
          if (charge.paidBy.id == "brand") {
            return total - parseFloat(charge.calculatedCostUsd)
          } else {
            return total + parseFloat(charge.calculatedCostUsd)
          }
        }, 0)
      } else {
        return 0
      }
    },
    totalAmount() {
      return this.totalAdditionalCharge + parseFloat(this.order.calculatedWholesaleCostUsd)
    }
  },
  methods: {
    showAddCharges() {
      this.isEditing = true
      this.editVisible = true
      this.showAddChargeFileds = !this.showAddChargeFileds
      if (this.additionalCharges && this.additionalCharges.length !== 0) {
        const list = this.additionalCharges.filter(
          item => item.seenByLanding && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
        )
        this.amountValue = list[0].calculatedCostUsd
        this.typeValue = list[0].paymentType.id
        this.descriptionText = list[0].note
      }
    },
    async updateCharges(order) {
      let params = {}
      if (this.$route.name == "adminBrandOrder") {
        params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      }
      if (this.$route.name == "adminRetailerOrder") {
        params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      }
      try {
        await this.$store.dispatch("order/updateStatus", { order, params })
        await this.$store.dispatch("order/getOrder", this.$route.params.id)
      } catch (error) {
        console.error()
      }
    },
    formatUsersAccepted() {
      let obj = {
        isBrandAccepted: false,
        isRetailerAccepted: false,
        isLandingAccepted: false
      }
      if (this.isAdmin) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = true
      }
      if (this.isBrand) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = false
      }
      if (this.isRetailer) {
        obj.isBrandAccepted = false
        obj.isRetailerAccepted = true
        obj.isLandingAccepted = false
      }
      return obj
    },
    async applyAdditional() {
      const acceptInfo = this.formatUsersAccepted()
      const addBy = this.isAdmin ? "landing" : "brand"
      let order = {
        ...acceptInfo,
        id: this.order.id,
        version: Number(this.order.version) + 1,
        charges: [
          ...this.order.charges,
          {
            ownedBy: {
              id: "brand"
            },
            paidBy: {
              id: "retailer"
            },
            addedBy: {
              id: addBy
            },
            chargeType: {
              id: "custom"
            },
            calculatedCostUsd: this.amountValue,
            paymentType: {
              id: this.typeValue
            },
            percentage: false,
            recordedValue: this.amountValue,
            seenByBrand: true,
            seenByRetailer: true,
            seenByLanding: true,
            note: this.descriptionText
          }
        ]
      }
      if (this.additionalCharges && this.additionalCharges.length !== 0) {
        const list = this.additionalCharges.filter(
          item => item.seenByLanding && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
        )
        order = {
          ...acceptInfo,
          id: this.order.id,
          version: Number(this.order.version) + 1,
          charges: [
            ...this.order.charges.filter(
              item =>
                !(
                  item.chargeType.id === "custom" &&
                  item.ownedBy.id === "brand" &&
                  item.paidBy.id === "retailer"
                )
            ),
            {
              ...list[0],
              recordedValue: this.amountValue,
              calculatedCostUsd: this.amountValue,
              paymentType: {
                id: this.typeValue
              },
              addedBy: {
                id: addBy
              },
              note: this.descriptionText
            }
          ]
        }
      }
      await this.updateCharges(order)
      this.resetForm()
    },
    resetForm() {
      this.editVisible = false
      this.amountValue = null
      this.typeValue = null
      this.descriptionText = null
    }
  }
}
</script>

<style lang="sass">
.reretailer-order-table__header-container
  .button[disabled]
     cursor: default
  display: flex
  justify-content: space-between
  .add-charge-button
    margin-right: 10px

.additional-charge
  width: 100%
  select
    width: 100%
    height: 36px
.amount-input-container
  display: flex
  margin-bottom: 7px
  .input
    width: 70px
    margin-right: 4px
  .additional-charge-dropdown
    .select
      height: 36px
      select
        height: 100%
.additional-charge-buttons
  width: 129px
  display: flex
  justify-content: flex-end
  padding: 5px 0
  .button[disabled]
    cursor: default
  .save-additional-charge
    margin-right: 10px
    font-size: 14px
    height: 25px
    width: 50px
  .cancel-additional-charge
    font-size: 14px
    height: 25px
    width: 50px
    text-decoration: underline
    color: #4250C7
    border: none

.adjust-width
  width: 120px
.total-amount-style
  background-color: #595959
.edit-addition-charge
  margin-left: 20px
  font-size: 16px
  text-decoration: underline
  cursor: pointer
.select-or-input
  //padding-left: 367px
  margin-bottom: 20px
  p
    margin-bottom: 0
.button-apply-style
  text-align: right
  span
    padding: 5px 16px
    background-color: #4a8a5d
    color: white
    cursor: pointer
//.additional-text-color
  color: #4a8a5d !important
</style>
