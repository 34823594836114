<template lang="pug">
section.brand-directory-page-placeholder
  .hero-body
    .container
      //router-link.title(class="has-cursor-pointer" to="/brand/brands" tag="li") {{$t('Brands.BrandMainPage.newRetailers')}}
      //li.title Retailer Directory
      .all-users-view__wrap
        .h4.all-brand All Brands
        .user-index__block
          .index-box(@click="brandsStartsWith='' , fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == '' }") All
          .index-box(@click="brandsStartsWith='A', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'A' }") A
          .index-box(@click="brandsStartsWith='B', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'B' }") B
          .index-box(@click="brandsStartsWith='C', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'C' }") C
          .index-box(@click="brandsStartsWith='D', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'D' }") D
          .index-box(@click="brandsStartsWith='E', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'E' }") E
          .index-box(@click="brandsStartsWith='F', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'F' }") F
          .index-box(@click="brandsStartsWith='G', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'G' }") G 
          .index-box(@click="brandsStartsWith='H', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'H' }") H 
          .index-box(@click="brandsStartsWith='I', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'I' }") I 
          .index-box(@click="brandsStartsWith='J', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'J' }") J
          .index-box(@click="brandsStartsWith='K', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'K' }") K
          .index-box(@click="brandsStartsWith='L', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'L' }") L 
          .index-box(@click="brandsStartsWith='M', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'M' }") M 
          .index-box(@click="brandsStartsWith='N', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'N' }") N 
          .index-box(@click="brandsStartsWith='O', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'O' }") O 
          .index-box(@click="brandsStartsWith='P', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'P' }") P 
          .index-box(@click="brandsStartsWith='Q', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'Q' }") Q 
          .index-box(@click="brandsStartsWith='R', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'R' }") R 
          .index-box(@click="brandsStartsWith='S', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'S' }") S 
          .index-box(@click="brandsStartsWith='T', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'T' }") T 
          .index-box(@click="brandsStartsWith='U', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'U' }") U 
          .index-box(@click="brandsStartsWith='V', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'V' }") V 
          .index-box(@click="brandsStartsWith='W', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'W' }") W 
          .index-box(@click="brandsStartsWith='X', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'X' }") X 
          .index-box(@click="brandsStartsWith='Y', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'Y' }") Y 
          .index-box(@click="brandsStartsWith='Z', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == 'Z' }") Z 
          .index-box(@click="brandsStartsWith='#', fetchBrands()")
            a(:class="{ 'dot': brandsStartsWith == '#' }") #

        .all-details__wrap
          ul(v-if="brands.filter(brand => brand.name.charAt(0).match(/^[0-9]+$/)).length")
            li #
            li(v-for="brand in brands.filter(brand => brand.name.charAt(0).match(/^[0-9]+$/))")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brands.filter(brand => brand.name.charAt(0).match(/^[0-9]+$/)).length==0 && brandsStartsWith=='#' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('A').length")
            li A
            li(v-for="brand in brandsGroup('A')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('A').length==0 && brandsStartsWith=='A' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('B').length")
            li B
            li(v-for="brand in brandsGroup('B')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('B').length==0 && brandsStartsWith=='B' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('C').length")
            li C
            li(v-for="brand in brandsGroup('C')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('C').length==0 && brandsStartsWith=='C' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('D').length")
            li D
            li(v-for="brand in brandsGroup('D')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('D').length==0 && brandsStartsWith=='D' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('E').length") 
            li E
            li(v-for="brand in brandsGroup('E')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('E').length==0 && brandsStartsWith=='E' && !fetchInProgress") No Brands 
          ul(v-if="brandsGroup('F').length") 
            li F
            li(v-for="brand in brandsGroup('F')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('F').length==0 && brandsStartsWith=='F' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('G').length") 
            li G
            li(v-for="brand in brandsGroup('G')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('G').length==0 && brandsStartsWith=='G' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('H').length") 
            li H
            li(v-for="brand in brandsGroup('H')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('H').length==0 && brandsStartsWith=='H' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('I').length") 
            li I
            li(v-for="brand in brandsGroup('I')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('I').length==0 && brandsStartsWith=='I' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('J').length") 
            li J
            li(v-for="brand in brandsGroup('J')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('J').length==0 && brandsStartsWith=='J' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('K').length") 
            li K
            li(v-for="brand in brandsGroup('K')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('K').length==0 && brandsStartsWith=='K' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('L').length") 
            li L
            li(v-for="brand in brandsGroup('L')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('L').length==0 && brandsStartsWith=='L' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('M').length") 
            li M
            li(v-for="brand in brandsGroup('M')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('M').length==0 && brandsStartsWith=='M' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('N').length") 
            li N
            li(v-for="brand in brandsGroup('N')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('N').length==0 && brandsStartsWith=='N' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('O').length") 
            li O
            li(v-for="brand in brandsGroup('O')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('O').length==0 && brandsStartsWith=='O' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('P').length") 
            li P
            li(v-for="brand in brandsGroup('P')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('P').length==0 && brandsStartsWith=='P' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('Q').length") 
            li Q
            li(v-for="brand in brandsGroup('Q')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('Q').length==0 && brandsStartsWith=='Q' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('R').length") 
            li R
            li(v-for="brand in brandsGroup('R')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('R').length==0 && brandsStartsWith=='R' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('S').length") 
            li S
            li(v-for="brand in brandsGroup('S')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('S').length==0 && brandsStartsWith=='S' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('T').length") 
            li T
            li(v-for="brand in brandsGroup('T')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('T').length==0 && brandsStartsWith=='T' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('U').length") 
            li U
            li(v-for="brand in brandsGroup('U')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('U').length==0 && brandsStartsWith=='U' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('V').length") 
            li V
            li(v-for="brand in brandsGroup('V')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('V').length==0 && brandsStartsWith=='V' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('W').length") 
            li W
            li(v-for="brand in brandsGroup('W')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('W').length==0 && brandsStartsWith=='W' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('X').length") 
            li X
            li(v-for="brand in brandsGroup('X')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('X').length==0 && brandsStartsWith=='X' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('Y').length") 
            li Y
            li(v-for="brand in brandsGroup('Y')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('Y').length==0 && brandsStartsWith=='Y' && !fetchInProgress") No Brands
          ul(v-if="brandsGroup('Z').length") 
            li Z
            li(v-for="brand in brandsGroup('Z')")
              .brand-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}") {{ brand.name }}
              .brand-item(v-else)
                router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}") {{ brand.name }}
          a.no-brands(v-if="brandsGroup('Z').length==0 && brandsStartsWith=='Z' && !fetchInProgress") No Brands
            

</template>

<script>
import constants from "@/config"
export default {
  data() {
    return {
      query: { _limit: "1" },
      fetchInProgress: false,
      brandsStartsWith: ""
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isAdminRetailerRoute() {
      return constants.adminRetailerRoutes.includes(this.$route.name)
    },
    brands() {
      let brandArray = this.$store.getters["brand/brands"]
      return brandArray.filter(brand => this.selectedBrandsGroup(brand) === true)
    }
  },
  async created() {
    if (this.isAdmin) {
      this.query["_jwt-c_id"] = this.$route.params.brandId
      this.query["_jwt-c_type"] = "brand"
    }
    this.fetchBrands()
  },
  methods: {
    async fetchBrands() {
      this.fetchInProgress = true
      await this.$store.dispatch("brand/fetchAllBrands", {
        params: { status: "approved" }
      })
      this.fetchInProgress = false
    },
    selectedBrandsGroup(brand) {
      if (this.brandsStartsWith == "#") {
        if (brand.name.charAt(0).match(/^[0-9]+$/)) {
          return true
        }
      }
      if (
        brand.name.startsWith(this.brandsStartsWith.toUpperCase()) ||
        brand.name.startsWith(this.brandsStartsWith.toLowerCase())
      ) {
        return true
      }
      return false
    },
    brandsGroup(alphabet) {
      return this.brands.filter(
        brand =>
          brand.name.startsWith(alphabet.toUpperCase()) ||
          brand.name.startsWith(alphabet.toLowerCase())
      )
    }
  }
}
</script>

<style lang="sass">
.no-brands
  font-size: 14px
  color: #000
  text-align: center
  margin-left: 274px

.dot
  height: 19px
  padding: 3px
  background-color: #bbb
  border-radius: 29%

.brand-directory-page-placeholder
  min-height: calc(100vh - 160px)

  .hero-body
    padding-top: 0px !important
    padding-bottom: 0px !important
    justify-content: center
    align-items: flex-start !important
    display: flex

    .container
      .all-users-view__wrap
        padding-top: 20px
        max-width: 684px
        margin: 0 auto
        .all-brand
          font-size: 30px
          color: #000
          margin-bottom: 20px
          text-align: center

        .user-index__block
          padding-bottom: 18px
          min-width: 684px
          border-bottom: 1px solid #ddd
          display: flex
          align-items: center
          justify-content: center
          flex-wrap: wrap

          .index-box
            cursor: pointer
            // margin: 0px 8px
            a
              font-size: 12px
              margin: 8px
              color: #000
              font-weight: 600
              text-transform: uppercase
              &:hover
                text-decoration: underline

        .all-details__wrap
          padding: 20px 0px
          min-width: 860px
          flex-wrap: wrap
          display: flex
          align-items: flex-start

          ul
            list-style: none
            padding: 0
            margin: 0 30px 30px
            flex-basis: 15%
            li
              a
                font-size: 12px
                color: #000
                font-weight: 300
                &:hover
                  text-decoration: underline
</style>
