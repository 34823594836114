<template>
  <!-- <div class="login__container height-for-sticky-footer">
    <div class="columns is-centered is-flex">
      <div v-if="isLegacyUserReset" class="welcome-back">
        <div class="welcome-back__title">
          {{ $t("Auth.Login.welcomeBack") }}
        </div>
        <div class="welcome-back__paragraph">
          {{ $t("Auth.Login.welcomeText1") }}
        </div>
        <div class="welcome-back__paragraph">
          {{ $t("Auth.Login.welcomeText2") }}
        </div>
        <div class="welcome-back__divider"></div>
        <div
          v-if="isLegacyUserResetSecondTry"
          class="welcome-back__footer columns is-mobile is-vcentered"
        >
          <div class="column is-narrow">
            {{ $t("Auth.Login.sentNoEmail") }}
          </div>
          <div class="column">
            <a class="welcome-back__resend-email" href="mailto:support@landingintl.com">
              {{ $t("Auth.Login.contactUs") }}
            </a>
          </div>
        </div>
        <div v-else class="welcome-back__footer columns is-mobile is-vcentered">
          <div class="column is-narrow">
            {{ $t("Auth.Login.didntReceiveEmail") }}
          </div>
          <div class="column">
            <a class="welcome-back__resend-email" href="#" @click.prevent="resendEmailToLegacyUser">
              {{ $t("Auth.Login.resendMail") }}
            </a>
          </div>
        </div>
      </div>
      <div v-else class="column login__box is-paddingless">
        <form @submit.prevent="signIn">
          <div class="field is-marginless">
            <div class="control">
              <input
                v-model.trim="$v.email.$model"
                class="input login__input-email"
                autocomplete="false,"
                :class="{ 'is-danger': $v.email.$error && showValidations }"
                :readonly="!this.$route.query.email == ''"
                :placeholder="$t('Auth.Login.emailAddress')"
                @input="resetValidationFlags"
              />
              <p
                v-if="$v.email.$error && !$v.email.required && showValidations"
                class="help is-danger login__input-error"
              >
                {{ $t("Auth.Login.Validations.emailRequired") }}
              </p>
              <p
                v-if="$v.email.$error && !$v.email.email && showValidations"
                class="help is-danger login__input-error"
              >
                {{ $t("Auth.Login.Validations.validEmail") }}
              </p>
            </div>
          </div>
          <div class="field is-marginless">
            <div class="control has-icons-right">
              <input
                v-model.trim="$v.password.$model"
                class="input login__input-password"
                autocomplete="false"
                :class="{ 'is-danger': $v.password.$error && showValidations }"
                :placeholder="$t('Auth.Login.password')"
                :type="passwordType"
                @input="isInvalidCredentials = false"
              />
              <span class="eyeIcon" @click="Passwordvisibility">
                <img v-if="passwordType == 'password'" src="@/assets/img/icon/passwordEye.svg" />
                <img v-if="passwordType == 'text'" src="@/assets/img/icon/preview-icon.svg" />
              </span>
              <p
                v-if="$v.password.$error && showValidations"
                class="help is-danger login__input-error"
              >
                {{ $t("Auth.Login.Validations.passwordRequired") }}
              </p>
              <p v-show="isInvalidCredentials" class="help is-danger login__input-error">
                {{ $t("Auth.Login.Validations.incorrectUsernamePassword") }}
              </p>
            </div>
            <router-link class="login__question" to="/auth/forgot-password">
              {{ $t("Auth.Login.forgotPasswordQn") }}
            </router-link>
          </div>
          <div v-show="isAuthInProgress" class="field is-marginless">
            <div class="control has-text-centered">
              <img src="@/assets/img/spinner.gif" alt="spinner" />
            </div>
          </div>
          <div class="field is-marginless">
            <div class="control">
              <button class="button is-black login__button btn_background">
                {{ $t("Auth.Login.signIn") }}
              </button>
            </div>
          </div>
          <div class="login__register">
            <p class="login__register-question ">
              {{ $t("Auth.Login.dontHaveAccountQn") }}
            </p>
            <span
              class="login__register-sign-up"
              @click="$store.commit('guest/handleMask', { visible: true })"
            >
              {{ $t("Auth.Login.signUp") }}
            </span>
          </div>
        </form> -->
  <a-row type="flex" justify="center">
    <a-col :xs="{ span: 14, offset: 4 }" :lg="{ span: 12, offset: 10 }">
      <div class="sign-in-title-content">Sign in</div>
    </a-col>
    <div class="login__container height-for-sticky-footer">
      <div class="">
        <div v-if="isLegacyUserReset" class="welcome-back">
          <div class="welcome-back__title">
            {{ $t("Auth.Login.welcomeBack") }}
          </div>
          <div class="welcome-back__paragraph">
            {{ $t("Auth.Login.welcomeText1") }}
          </div>
          <div class="welcome-back__paragraph">
            {{ $t("Auth.Login.welcomeText2") }}
          </div>
          <div class="welcome-back__divider"></div>
          <div
            v-if="isLegacyUserResetSecondTry"
            class="welcome-back__footer columns is-mobile is-vcentered"
          >
            <div class="column is-narrow">
              {{ $t("Auth.Login.sentNoEmail") }}
            </div>
            <div class="column">
              <a
                class="welcome-back__resend-email"
                href="mailto:support@landingintl.com"
              >
                {{ $t("Auth.Login.contactUs") }}
              </a>
            </div>
          </div>
          <div
            v-else
            class="welcome-back__footer columns is-mobile is-vcentered"
          >
            <div class="column is-narrow">
              {{ $t("Auth.Login.didntReceiveEmail") }}
            </div>
            <div class="column">
              <a
                class="welcome-back__resend-email"
                href="#"
                @click.prevent="resendEmailToLegacyUser"
              >
                {{ $t("Auth.Login.resendMail") }}
              </a>
            </div>
          </div>
        </div>
        <div v-else class="column login__box is-paddingless">
          <form id="user-login" @submit.prevent="signIn">
            <div class="field is-marginless">
              <a-col
                :xs="{ span: 14, offset: 4 }"
                :lg="{ span: 16, offset: 4 }"
              >
                <div class="control">
                  <label>
                    <input
                      v-model.trim="$v.email.$model"
                      class="input login__input-email"
                      type="email"
                      autocomplete="false,"
                      :class="{
                        'is-danger': $v.email.$error && showValidations,
                      }"
                      :readonly="!this.$route.query.email == ''"
                      :placeholder="$t('Auth.Login.emailAddress')"
                      @input="resetValidationFlags"
                    />
                  </label>
                  <p
                    v-if="
                      $v.email.$error && !$v.email.required && showValidations
                    "
                    class="help is-danger login__input-error"
                  >
                    {{ $t("Auth.Login.Validations.emailRequired") }}
                  </p>
                  <p
                    v-if="$v.email.$error && !$v.email.email && showValidations"
                    class="help is-danger login__input-error"
                  >
                    {{ $t("Auth.Login.Validations.validEmail") }}
                  </p>
                </div>
              </a-col>
            </div>
            <div class="field is-marginless">
              <a-col
                :xs="{ span: 14, offset: 4 }"
                :lg="{ span: 16, offset: 4 }"
              >
                <div class="control has-icons-right">
                  <label>
                    <input
                      v-model.trim="$v.password.$model"
                      class="input login__input-password"
                      autocomplete="false"
                      :class="{
                        'is-danger': $v.password.$error && showValidations,
                      }"
                      :placeholder="$t('Auth.Login.password')"
                      :type="passwordType"
                      @input="isInvalidCredentials = false"
                    />
                  </label>
                  <span class="eyeIcon" @click="Passwordvisibility">
                    <img
                      v-if="passwordType == 'password'"
                      src="@/assets/img/icon/passwordEye.svg"
                    />
                    <img
                      v-if="passwordType == 'text'"
                      src="@/assets/img/icon/preview-icon.svg"
                      alt=""
                    />
                  </span>
                  <p
                    v-if="$v.password.$error && showValidations"
                    class="help is-danger login__input-error"
                  >
                    {{ $t("Auth.Login.Validations.passwordRequired") }}
                  </p>
                  <p
                    v-show="isInvalidCredentials"
                    class="help is-danger login__input-error"
                  >
                    {{ $t("Auth.Login.Validations.incorrectUsernamePassword") }}
                  </p>
                </div>
                <router-link class="login__question" to="/auth/forgot-password">
                  {{ $t("Auth.Login.forgotPasswordQn") }}
                </router-link>
              </a-col>
            </div>
            <div v-show="isAuthInProgress" class="field is-marginless">
              <div class="control has-text-centered">
                <img src="@/assets/img/spinner.gif" alt="spinner" />
              </div>
            </div>
            <a-col :xs="{ span: 14, offset: 5 }" :lg="{ span: 18, offset: 4 }">
              <button class="button btn_background">
                {{ $t("Auth.Login.signIn") }}
              </button>
            </a-col>
            <a-col :xs="{ span: 14, offset: 5 }" :lg="{ span: 18, offset: 4 }">
              <div class="login__register">
                <p class="login__register-question ">
                  {{ $t("Auth.Login.dontHaveAccountQn") }}
                </p>
                <span
                  class="login__register-sign-up"
                  @click="$store.commit('guest/handleMask', { visible: true })"
                >
                  {{ $t("Auth.Login.signUp") }}
                </span>
              </div>
            </a-col>
          </form>
        </div>
      </div>
      <guest-modal />
    </div>
  </a-row>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import firebase from "@/services/firebase-service";
import guestModal from "@/layouts/guest/Modal";

export default {
  components: {
    guestModal,
  },
  data() {
    return {
      condition: {
        receipient: "Retailer",
        filter: " Retailer Filter: Ada Lip Beauty, Caolionfgdfgdgdfg",
        dateRange: "02/01/2020 - 03/01/2020",
        type: 3,
      },
      email: this.$route.query.email ? this.$route.query.email : "",
      password: "",
      isInvalidCredentials: false,
      isAuthInProgress: false,
      isLegacyUserReset: false,
      isLegacyUserResetSecondTry: false,
      passwordType: "password",
      showValidations: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    role() {
      return this.$store.getters["user/role"];
    },
    postLoginPath() {
      const brandInfo = this.$store.getters["brand/brand"];
      if (this.checkTrialIsEnd(brandInfo)) {
        return "/trial-end";
      }
      if (this.role === "brand") {
        if (this.$store.getters["brand/isBrandPending"]) {
          return "brand/pending";
        }
        return "brand/main?isLogin=true";
      }

      if (this.role === "retailer") {
        if (
          this.$store.getters["retailer/isRetailerRejected"] ||
          this.$store.getters["retailer/isRetailerPending"]
        ) {
          return "retailer/verify-email";
        }
        return "retailer/main";
      }
      const postLoginPaths = {
        admin: "admin/main",
      };
      return postLoginPaths[this.role];
    },
    ready() {
      return this.$store.getters["user/ready"];
    },
  },
  watch: {
    async ready() {
      if (this.ready == true) {
        if (this.role === "brand") {
          this.openBrandDialog();
        } else {
          this.$router.push(this.postLoginPath);
        }
      }
    },
  },
  methods: {
    myBtn() {
      document.getElementById("myModal").style.display = "block";
    },
    async openBrandDialog() {
      // const brandId = this.$store.getters["user/businessId"]
      // await this.$store.dispatch("brand/getBrand", brandId)
      const brand = this.$store.getters["brand/brand"];
      if (this.$store.getters["brand/isBrandPending"]) {
        return this.$router.push("brand/pending");
      }
      if (this.$route.query.hasAccount) {
        return this.$router.push("/bf-payment");
      }
      const brandType =
        brand.subscriptions &&
        brand.subscriptions.length !== 0 &&
        brand.subscriptions[0].type &&
        brand.subscriptions[0].type.id;
      if (this.checkTrialIsEnd(brand)) {
        return this.$router.push("/trial-end");
      }
      const suspendedList =
        brand &&
        brand.brandTrainingSubscriptions &&
        brand.brandTrainingSubscriptions.filter(
          (item) => item.status.id === "suspended"
        );
      const isSuspendedBool = suspendedList && suspendedList.length !== 0;
      if (
        brand &&
        brand.subscriptions &&
        brand.subscriptions.length !== 0 &&
        this.checkTrialIsEndDay(brand)
      ) {
        const day = this.checkTrialDay(brand);
        this.$router.push(`brand/TrialDayDialog?day=${day}`);
      } else if (brandType === "basic" && this.role === "brand") {
        this.$router.push("brand/membershipExpiringDialog?isClick=true");
      } else if (isSuspendedBool) {
        this.$router.push("brand/suspended?isSuspended=true");
      } else {
        this.$router.push("brand/main?isLogin=true");
      }
    },
    Passwordvisibility() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    async sha256(email) {
      const msgBuffer = new TextEncoder("utf-8").encode(email);
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((b) => ("00" + b.toString(16)).slice(-2))
        .join("");

      return hashHex;
    },
    async isLegacyUser(email) {
      return (
        await firebase
          .database()
          .ref("legacy/users/" + (await this.sha256(email)))
          .once("value", (snapshot) => {
            return snapshot.val();
          })
      ).toJSON();
    },
    async resendEmailToLegacyUser() {
      if (this.isLegacyUserResetSecondTry) {
        return;
      }

      this.isLegacyUserResetSecondTry = true;
      this.$store.dispatch(
        "user/sendPasswordRecoveryLinkForLegacyUser",
        await this.sha256(this.email)
      );
    },
    checkTrialIsEnd(brandInfo) {
      let time;
      if (
        brandInfo &&
        brandInfo.subscriptions &&
        brandInfo.subscriptions.length !== 0 &&
        this.role === "brand"
      ) {
        const myDate = new Date(new Date().getTime() + 8 * 60 * 60 * 1000);
        const list = myDate
          .toJSON()
          .split("T")
          .slice(0, 10);
        time = list[0];
        const timeValue = this.getDaysBetween(
          time,
          brandInfo.subscriptions[0].endDate
        );
        // return brandInfo.subscriptions[0].type.id === "trial" && timeValue === 0
        return timeValue === 0;
      } else {
        return false;
      }
    },
    checkTrialDay(brandInfo) {
      let time;
      if (brandInfo && brandInfo.subscriptions && this.role === "brand") {
        const myDate = new Date(new Date().getTime() + 8 * 60 * 60 * 1000);
        const list = myDate
          .toJSON()
          .split("T")
          .slice(0, 10);
        time = list[0];
        return this.getDaysBetween(time, brandInfo.subscriptions[0].endDate);
      }
    },
    checkTrialIsEndDay(brandInfo) {
      let time;
      if (brandInfo && brandInfo.subscriptions && this.role === "brand") {
        const myDate = new Date(new Date().getTime() + 8 * 60 * 60 * 1000);
        const list = myDate
          .toJSON()
          .split("T")
          .slice(0, 10);
        time = list[0];
        const timeValue = this.getDaysBetween(
          time,
          brandInfo.subscriptions[0].endDate
        );
        return (
          brandInfo.subscriptions[0].type.id === "trial" && timeValue !== 0
        );
      } else {
        return false;
      }
    },
    async signIn() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showValidations = true;
        return;
      }

      this.isAuthInProgress = true;
      await this.$store.dispatch("user/signOutWithoutReload");

      const { email, password } = this;
      try {
        await this.$store.dispatch("user/signIn", { email, password });
        setTimeout(() => {
          if (this.ready == true) {
            if (this.role === "brand") {
              this.openBrandDialog();
            } else {
              this.$router.push(this.postLoginPath);
            }
          }
        }, 10);
      } catch (error) {
        // if (await this.isLegacyUser(email)) {
        //   this.isLegacyUserReset = true
        //   this.$store.dispatch(
        //     "user/sendPasswordRecoveryLinkForLegacyUser",
        //     await this.sha256(email)
        //   )
        //   return
        // }

        this.isInvalidCredentials = true;
      }
      this.isAuthInProgress = false;
    },
    resetValidationFlags() {
      this.showValidations = false;
      this.isInvalidCredentials = false;
    },
    getDaysBetween(date1, date2) {
      const startDate = Date.parse(date1);
      const endDate = Date.parse(date2);
      if (startDate > endDate) {
        return 0;
      }
      if (startDate === endDate) {
        return 1;
      }
      return Math.floor((endDate - startDate) / (1 * 24 * 60 * 60 * 1000));
    },
  },
};
</script>

<style lang="sass" scoped>
@import '~@/assets/styles/shared/ProximaNova-Regular.css'

.guest-header, .fixed-footer
  display: block
button.button.btn_background
  background-color: #FF6132 !important
  border-radius: 27.5px
  color: white
  padding: 16px 120px
  margin-top: 24px
  font-family: "ProximaNova-Regular"
.sign-in-title-content
  font-size: 65px
  //width: 400px
  //text-align: center
  color: #1A1818
  font-family: "Niveau Grotesk"
  font-weight: 300
.eyeIcon
  top: 30px !important
  cursor: pointer
  position : absolute
  right: 0 !important
.welcome-back
  padding: 25px
  width: 360px
  border: 1px solid #E5E5E5

  &__title
    color: #000000
    font-family: "Proxima"
    font-size: 16px
    letter-spacing: 0.44px

  &__paragraph
    color: #8D8D8D
    font-family: "Proxima"
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px
    margin-top: 24px

  &__divider
    background: #E5E5E5
    height: 1px
    margin-top: 52px
    margin-bottom: 24px

  &__footer
    font-family: "Proxima"
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px

  &__resend-email
    color: #008489
    font-family: "Proxima"
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 17px
    text-align: right
    display: block

.login
  &__box
    width: 360px
    min-height: 384px
    //border: 1px solid #E5E5E5
    border-radius: 2px
    display: flex
    justify-content: center
    align-items: center
    @media only screen and (max-width: 600px)
      width: auto

  &__container
    //display: flex
    //justify-content: center
    //align-items: center

  &__input
    &-email
      margin: 85px 24px 0px 24px
      width: 312px
      height: 40px

    &-password
      margin: 16px 24px 18px 24px
      width: 312px
      height: 40px

  &__question
    font-size: 20px
    font-weight: 600
    letter-spacing: 0.25px
    line-height: 17px
    color: #008489
    display: flex
    flex-direction: row-reverse
    margin-right: 24px

  &__button
    margin-top: 25px
    margin-left: 150px
    width: 312px
    height: 46px
    text-align: center

  &__border
    border-bottom: 1px solid #e5e5e5
    margin: 0px 24px 25px 24px

  &__register
    display: flex
    justify-content: center
    padding-top: 15px
    padding-left: 24px
    padding-right: 24px

    &-question
      font-size: 20px
      //font-weight: 600
      letter-spacing: 0.25px
      line-height: 21px
      margin-bottom: 23px

    &-sign-up
      font-size: 14px
      font-weight: 600
      letter-spacing: 0.25px
      color: #008489
      margin-bottom: 23px

  &__input-error
    margin: 6px 24px 0px 24px

.login__question
    color: #12433D
    font-family: "Mr Eaves Mod OT"
    font-weight: 500

.login__input-email
    font-family: 'ProximaNova-Regular'

.control.has-icons-right .input
    font-family: 'ProximaNova-Regular'

.login__register-question
    color: #212529

.login__register-sign-up
    cursor: pointer
    color: #F15A42
    margin-left: 8px

.height-for-sticky-footer
  font-family: "Mr Eaves Mod OT"
  height: calc(70vh)
  text-align: center
  .input, .textarea,
  .input:hover, .textarea:hover,
  .input:focus, .textarea:focus
    width: 560px
    border: 1px solid #F15A42
    box-sizing: border-box
    border-radius: 50px
    padding: 16px 24px

    font-size: 20px
    line-height: 22px
    color: #000000

    box-shadow: none
    height: fit-content
</style>
