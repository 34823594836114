/*
 * @Descripttion: DOP
 * @version: 1.0.0
 * @Author: Author
 * @Date: 2019-12-06 11:01:32
 * @LastEditors  : john.kong
 * @LastEditTime : 2019-12-20 12:36:38
 */
import { getSesStorage, setSesStorage } from "@/utils/storageUtil";
import {
  messageSenderAndReceiverType,
  messageItemizeType,
} from "@/utils/const";
import QRCode from "qrcodejs2";
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
export function checkArary(arr) {
  if (!Array.isArray(arr)) {
    return false;
  }
  return arr && arr.length !== 0;
}

/**
 * Export list to excel
 * @param blob
 * @param fileName
 * @returns {Promise<void>}
 */
export async function exportOrderExcel(blob, fileName) {
  let aLink = document.createElement("a");
  let href = window.URL.createObjectURL(blob); //Create a link to download
  aLink.href = href;
  aLink.download = fileName; //fileName+time
  document.body.appendChild(aLink);
  aLink.click(); //click to download
  document.body.removeChild(aLink); //Remove the element after downloading
  window.URL.revokeObjectURL(blob); //Release the blob object
}

/**
 * format time
 * @param date
 * @returns {string}
 */
export function formatTime(date) {
  const time = new Date(date);
  const y = time.getFullYear();
  let m = time.getMonth() + 1;
  let d = time.getDate();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  return `${m}/${d}/${y}`;
}

/**
 * format time
 * @param date
 * @returns {string}
 */
export function formatFullTime(date) {
  const time = new Date(date);
  const y = time.getFullYear();
  let m = time.getMonth() + 1;
  let d = time.getDate();
  let hour = time.getHours();
  let min = time.getMinutes();
  let second = time.getSeconds();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  if (min < 10) {
    min = "0" + min;
  }
  if (second < 10) {
    second = "0" + second;
  }
  return `${m}/${d}/${y} ${hour}:${min}:${second}`;
}

/**
 * Verify that the initial letter is English
 * @param path
 * @returns {boolean}
 */
export function checkFirstLetter(path) {
  //return /^[a-zA-Z](.|\n)*/.test(path)
  return /^[a-zA-Z0-9](.|\n)*/.test(path);
}

export async function downloadCsvData(data, fileName) {
  if (data && data.length) {
    let str = ``;
    for (let i = 0; i < data.length; i++) {
      str += data[i] + "\n";
      // console.log(str)
    }
    let uri = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
    let link = document.createElement("a");
    link.href = uri;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return "Download Success !";
  }
  return "No data selected !";
}
export async function downloadCsvDataStr(str, fileName) {
  if (str && str.length > 0) {
    let uri = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
    let link = document.createElement("a");
    link.href = uri;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return "Download Success !";
  }
  return "No data selected !";
}

/**
 * return ddMMYYYY format date
 */
export function getNowDateStr() {
  const now = new Date();
  const month =
    now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
  const date = now.getDate() < 10 ? `0${now.getDate}` : now.getDate();
  return `${month}${date}${now.getFullYear()}`;
}

export function getOrderRetailerPayAmount(order) {
  let amount = 0;
  if (order) {
    amount = Math.round(order.calculatedWholesaleCostUsd * 100) / 100;
    if (order.charges) {
      for (let i = 0; i < order.charges.length; i++) {
        let data = order.charges[i];
        if (data.ownedBy.id === "retailer" && data.paidBy.id === "brand") {
          amount -= Math.round(data.calculatedCostUsd * 100) / 100;
        } else if (
          data.ownedBy.id === "brand" &&
          data.paidBy.id === "retailer"
        ) {
          amount += Math.round(data.calculatedCostUsd * 100) / 100;
        }
      }
    }
  }
  return amount;
}

export function getOrderRetailerSidePayAmount(order) {
  let amount = 0;
  if (order) {
    amount = Math.round(order.calculatedWholesaleCostUsd * 100) / 100;
    if (order.charges) {
      for (let i = 0; i < order.charges.length; i++) {
        let data = order.charges[i];
        if (data.ownedBy.id === "retailer" && data.paidBy.id === "brand") {
          amount -= Math.round(data.calculatedCostUsd * 100) / 100;
        }
      }
    }
  }
  return amount;
}

export function getOrderBrandPayAmount(order, editPrice, typeValue) {
  let total = 0;
  if (order) {
    if (order.charges) {
      for (let i = 0; i < order.charges.length; i++) {
        let data = order.charges[i];
        if (data.ownedBy.id === "landing") {
          if (data.paidBy.id === "retailer") {
            total -= Math.round(data.calculatedCostUsd * 100) / 100;
          } else {
            total += Math.round(data.calculatedCostUsd * 100) / 100;
          }
        }
        if (data.ownedBy.id === "brand" && data.paidBy.id === "landing") {
          const price = editPrice ? editPrice : data.calculatedCostUsd;
          if (typeValue === "Debit" || data.paymentType.id === "debit") {
            // Because this is counting all the data that needs to be added or subtracted, not the total, you need to use the opposite symbol
            total += Math.round(price * 100) / 100;
          } else if (
            typeValue === "Credit" ||
            data.paymentType.id === "credit"
          ) {
            total -= Math.round(price * 100) / 100;
          } else {
            total += Math.round(price * 100) / 100;
          }
        }
      }
      // The first edit was before Other Charge
      const list = order.charges.filter(
        (item) => item.ownedBy.id === "brand" && item.paidBy.id === "landing"
      );
      if (editPrice && typeValue && list && list.length === 0) {
        if (typeValue === "Debit") {
          // Because this is counting all the data that needs to be added or subtracted, not the total, you need to use the opposite symbol
          total += Math.round(editPrice * 100) / 100;
        } else if (typeValue === "Credit") {
          total -= Math.round(editPrice * 100) / 100;
        }
      }
    }
    return total;
  }
}

export function priceFormat(price) {
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return numberFormat.format(price);
}

export function getCamelCase(name) {
  let result = "";
  console.log(name);
  if (name) {
    let strArray = name.split(" ");
    if (strArray && strArray.length > 0) {
      for (let i = 0; i < strArray.length; i++) {
        result +=
          strArray[i].substring(0, 1).toUpperCase() +
          strArray[i].substring(1).toLowerCase();
        if (i < strArray.length - 1) {
          result += " ";
        }
      }
    }
  }
  return result;
}

export function checkHasSameNameData(name, datas) {
  if (datas && datas.length > 0) {
    let filterResult = datas.filter((data) => name === data.name);
    if (filterResult && filterResult.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function replaceCommaForCsvStr(str) {
  if (str) {
    return str.replace(/,/g, "，");
  }
  return "";
}

export function formatLifecycleDate(timeValue) {
  const dateValue = new Date(timeValue);
  return `${dateValue.getFullYear()}-${dateValue.getMonth() +
    1}-${dateValue.getDate()}`;
}

/**
 * Obtain store tag information, including brand, location, and user of retailer Training
 * @param tagName
 * @returns {Array|string|object}
 */
export function getTrainingTag(tagName) {
  const type = "json";
  return getSesStorage(tagName, type);
}

/**
 * Store tag information, including retailer training brand, location, and user
 * @param tagName
 * @param content
 * @param value,By default, eight tags are stored
 */
export function setTrainingTag(tagName, content, value) {
  if (!content || !content.name || content.name.length === 0) {
    return getTrainingTag(tagName);
  }
  let tagValue = value;
  if (!value) {
    tagValue = 3;
  }
  let list = getTrainingTag(tagName) || [];
  let sameItem = list.filter(
    (item) => item.name.toString().toLowerCase() === content.name.toLowerCase()
  );
  if (!sameItem || sameItem.length === 0) {
    if (list.length === tagValue) {
      list = list.slice(list.length - (tagValue - 1), list.length);
    } else if (list.length > tagValue) {
      list = list.slice(list.length - tagValue, list.length);
    }
    list.push(content);
    setSesStorage(tagName, list);
  }
  return getTrainingTag(tagName);
}

export function deleteTrainingTag(tagName, content) {
  if (!tagName) {
    return;
  }
  setSesStorage(tagName, content);
}

export async function downloadQR(value, fileName) {
  let qrDiv = document.createElement("div");
  qrDiv.id = "qrcode";
  document.body.appendChild(qrDiv);
  var qrCode = new QRCode(qrDiv, {
    text: value,
    width: 150,
    height: 150,
  });
  console.log(qrCode);
  var img = qrDiv.getElementsByTagName("img")[0];
  var canvas = qrDiv.getElementsByTagName("canvas")[0];
  canvas.getContext("2d").drawImage(img, 0, 0, 150, 150);
  let href = canvas.toDataURL("image/png");
  let aLink = document.createElement("a");
  aLink.href = href;
  aLink.download = fileName; //fileName+time
  document.body.appendChild(aLink);
  aLink.click(); //click to download
  document.body.removeChild(aLink); //Remove the element after downloading
  document.body.removeChild(qrDiv);
  //Release the blob object
}

export async function downloadRetailerLocationCsvTemplate() {
  let csvDate = [
    "Store Number,Store Name,Address,City,State,Zip Code,Phone Number",
  ];
  await downloadCsvData(
    csvDate,
    `upload_retailer_location_template_${new Date().getTime()}.csv`
  );
}
export function getApiErrorString(err) {
  if (err && err.response && err.response.data) {
    if (err.response.data.developerMessage) {
      return err.response.data.developerMessage;
    } else if (
      err.response.data.underlying &&
      err.response.data.underlying.length > 0
    ) {
      let errStr = "";
      for (let i = 0; i < err.response.data.underlying.length; i++) {
        errStr +=
          (err.response.data.underlying[i].developerMessage
            ? err.response.data.underlying[i].developerMessage
            : "") + ",";
      }
      return errStr.substring(0, errStr.length - 1);
    }
  }
}

export function checkBrandRetailerConnection(brand, retailer) {
  if (
    brand &&
    retailer &&
    brand.follows &&
    retailer.follows &&
    brand.follows.length > 0 &&
    retailer.follows.length > 0
  ) {
    let brandHasRetailer = brand.follows.find(
      (item) => item.followClient.id === retailer.id && item.active === true
    );
    console.log(brandHasRetailer);
    if (!brandHasRetailer) {
      return false;
    }
    let retailrHasBrand = retailer.follows.find(
      (item) => item.followClient.id === brand.id && item.active === true
    );
    if (!retailrHasBrand) {
      return false;
    }
    return true;
  }
  return false;
}
export function getMessageApiBaseParam(id, type, page) {
  return {
    "client-id": id,
    itemize: type,
    _limit: 12,
    _index: page,
  };
}
export function getBrandRetailerMessageParam(id, type, page, isBrand) {
  let param = getMessageApiBaseParam(id, type, page);
  console.log(isBrand);
  //   if (type === messageItemizeType.INBOX_TYPE) {
  //     if (isBrand) {
  //       param[
  //         "sender-type"
  //       ] = `${messageSenderAndReceiverType.ADMIN_TYPE},${messageSenderAndReceiverType.RETAILER_TYPE}`
  //     } else {
  //       param[
  //         "sender-type"
  //       ] = `${messageSenderAndReceiverType.ADMIN_TYPE},${messageSenderAndReceiverType.BRAND_TYPE}`
  //     }
  //   }
  return param;
}

export function getAdminMessageParam(type, page, adminSendType) {
  let param = getMessageApiBaseParam("*", type, page);
  if (type === messageItemizeType.INBOX_TYPE) {
    if (adminSendType === 1) {
      // admin brand inbox
      param["sender-type"] = messageSenderAndReceiverType.BRAND_TYPE;
    } else if (adminSendType === 2) {
      // admin retailer inbox
      param["sender-type"] = messageSenderAndReceiverType.RETAILER_TYPE;
    }
  }
  return param;
}

export function getAdminMessageBatchOperaiontParam(
  index,
  isDelete,
  records,
  isAdmin
) {
  let params = [];
  for (let i = 0; i < records.length; i++) {
    let param = { privateId: records[i].privateId, id: records[i].id };
    if (records[i].parentPrivateId) {
      param.parentPrivateId = records[i].parentPrivateId;
    }
    params.push(param);
  }
  let type = "received";
  if (isAdmin) {
    if (index === 4) {
      type = "send";
    } else if (index === 5) {
      type = "draft";
    } else if (index === 6) {
      type = "archive";
    }
  } else {
    type = index;
  }

  return {
    messages: params,
    archive: isDelete ? false : true,
    read: true,
    active: isDelete ? false : true,
    itemize: type,
  };
}
