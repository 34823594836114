<template lang="pug">
.product-card
  .columns.is-multiline.product-card__body
    .product-card__btn-layer
      button.product-card__back-btn(@click="backToPrevious" v-if="isFromInventory") Back
    .column.is-12
      .columns
        .column.is-1(v-if="product.images")
          .product-card__thumb(v-for="image in sortedImages()" :class="{ 'product-card__thumb_active': selectedImage.asset.id === image.asset.id }")
            video(
              poster
              v-if="isVideo(image)"
              @click="selectedImage = image"
            )
              source(:src="image.asset.url" type="video/mp4")
            img(
              :src="image.asset.url"
              @click="selectedImage = image"
              v-else
            )
        .column.product-card__image
           template(v-if="product.images")
            .product-card__selected-image
              video(loop muted autoplay controls v-if="isVideo(selectedImage)")
                source(:src="selectedImage.asset.url" type="video/mp4")
              img(:src="selectedImage.asset.url" v-else)
           template(v-else)
             img(:src="fallbackImage()")
        .column.is-4(:class="{ 'wait': this.wait }")
          .product-card__details.open(:class="{'no-border' : !isNeedAddCart}")
            .product-card__breadcrumb-block
              ul.product-card__breadcrumb
                li {{ product.category.name }} > &nbsp
                li(v-if="product.subcategory") {{ product.subcategory.name }} &nbsp
                li(v-if="product.microCategory && product.microCategory.length") > {{ product.microCategory.name }}
            p.product-card__details-brand(v-if="isAdmin")
              router-link(:to="{ name:  isAdminRetailerRoute?'adminBrandProfile':'brandProfileAdmin', params: {brandId: product.brand.id} }") {{ brandName }}
            p.product-card__details-brand(v-else)
              router-link(:to="{ name: 'retailerBrandProfile', params: {brandId: product.brand.id} }") {{ brandName }}
            p.product-card__details-name {{ product.name }}
            .columns
              .column.is-narrow.product-card__details-sku {{$t('Components.Retailers.Products.ProductCard.size')}} {{ product.size.replace(".0", "") }}{{ product.sizeUnits.id }}
              .column.is-narrow •
              .column.product-card__details-sku {{$t('Components.Retailers.Products.ProductCard.sku')}} {{ product.sku }}
            p.product-card__details-msrp {{$t('Components.Retailers.Products.ProductCard.msrp')}} {{ priceFormat(product.msrpUsd) }}
            .columns.is-vcentered(v-if="!isAdmin")
              .column.is-narrow(v-if="product && product.margin")
                p.product-card__details-margin {{ parseFloat(product.margin) }}%
              .column.is-narrow(v-if="product && product.wholesalePriceUsd")
                span.product-card__details-price {{ priceFormat(product.wholesalePriceUsd)}}
              .column.is-narrow(v-if="product && product.wholesalePriceUsd")
                span.product-card__details-price-info {{$t('Components.Retailers.Products.ProductCard.unitPrice')}}
            .columns.is-vcentered(v-if="product && product.countryOfOrigin && product.countryOfOrigin.id")
              .column.is-narrow
                p.product-card__details-country {{$t('Components.Retailers.Products.ProductCard.countryofOrigin')}}
              .column.is-narrow
                p.product-card__details-country {{ countryName(product.countryOfOrigin.id) }}
            p.product-card__details-sample(v-if="product.sample")  {{$t('Components.Retailers.Products.ProductCard.productSample')}}
            p.product-card__details-upcoming(v-if="product.status.id === 'discontinued'") {{$t('Components.Retailers.Products.ProductCard.discontinuedProduct')}}
            p.product-card__details-upcoming(v-if="product.status.id === 'upcoming'") {{$t('Components.Retailers.Products.ProductCard.upcomingProduct')}}
            .columns.is-multiline.is-marginless(v-if="product && product.values && product.values.length > 0")
              .column.is-narrow(v-for="tag in product.values")
                a.button.is-rounded.is-static.tag-button {{ tag.name }}
            .product-card__buttons.has-text-centered(v-if="isNeedAddCart")
              div(:class="{ 'product-card__button-submit--not-allowed-cursor': product.sample }")
                a.button.is-black.product-card__button-submit(
                  v-show="!productAdded && !productInCart &&isShowCartForAdmin"
                  @click='addProductToCart(product, "purchase")'
                  :class="{ 'product-card__button-submit--disabled': product.sample ||  isRetailerNotApproved || product.status.id === 'discontinued' || product.status.id === 'upcoming' }"
                ) {{$t('Components.Retailers.Products.ProductCard.addToCart')}}
                a.button.is-black.product-card__button-submit--added(v-show="productAdded && !productInCart") {{$t('Components.Retailers.Products.ProductCard.addedToCart')}}
                a.button.is-black.is-outlined.product-card__button-submit--in-cart(v-show="productInCart")  {{$t('Components.Retailers.Products.ProductCard.inYourCart')}}
            p.has-text-centered(v-for="document in product.documents")
              a.product-card__data-sheet-link(@click="wait=true, downloadDocument(product.documents[0].id)") Download Safety Data Sheet  
    .column
      .product-card__details-header {{$t('Components.Retailers.Products.ProductCard.productDetails')}}
      .product-card__body-info
        .product-card__body-info--title {{$t('Components.Retailers.Products.ProductCard.description')}}
        .product-card__body-description {{ product.description }}
      .product-card__body-info
        .product-card__body-info--title {{$t('Components.Retailers.Products.ProductCard.ingredients')}}
        .product-card__body-description {{ getIngredientsValue() }}
      .product-card__body-info
        .product-card__body-info--title  {{$t('Components.Retailers.Products.ProductCard.keyBenefits')}}
        .product-card__body-description {{ getBenefitsValue() }}
</template>

<script>
import axios from "@/api/axios"
import { productFallbackImage } from "@/mixins/product-fallback-image.js"
import constants from "@/config"
export default {
  mixins: [productFallbackImage],
  props: {
    product: { type: Object, default: () => {} },
    isFromInventory: { type: Boolean, default: false },
    isNeedAddCart: { type: Boolean, default: true }
  },
  data() {
    return {
      selectedImage: {},
      tags: [],
      productAdded: false,
      isDropdownOpen: false,
      wait: false
    }
  },
  computed: {
    isAdminRetailerRoute() {
      return constants.adminRetailerRoutes.includes(this.$route.name)
    },
    productInCart() {
      if (
        this.$store.getters["retailer/cartProducts"].find(
          cartProduct => cartProduct.product.id === this.product.id
        )
      ) {
        return true
      } else return false
    },
    isRetailerNotApproved() {
      if (this.isAdmin) {
        return
      }

      return this.$store.getters["retailer/isRetailerPending"]
    },
    brandName() {
      const brand = this.$store.getters["brand/brand"]
      return brand && brand.name
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isShowCartForAdmin() {
      if (this.isAdmin) {
        return this.$route.name == "adminRetailerProduct" ? true : false
      } else {
        return true
      }
    }
  },
  async created() {
    this.selectedImage = this.product.images.find(image => image.main)
    this.$store.dispatch("reference/setReference")
    this.tags = this.$store.getters["reference/sellSideTags"]
  },
  methods: {
    backToPrevious() {
      this.$router.go(-1)
    },
    async downloadDocument(documentId) {
      await this.$store.dispatch("asset/getAsset", documentId)
      const asset = this.$store.getters["asset/find"](documentId)

      axios({
        url: asset.url,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", asset.originalFileName)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
        this.wait = false
      })
    },

    isVideo(image) {
      const type = [...image.asset.url.split(".")].pop()
      return !["jpeg", "gif", "png", "jpg"].includes(type)
    },
    countryName(id) {
      return this.$store.getters["reference/countryName"](id)
    },
    tagNameById(id) {
      const tag = this.tags.find(tag => tag.id === id)
      return tag && tag.name
    },
    mainPath() {
      return this.$store.getters["user/mainPath"]
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    canCheckAddCart() {
      const boolList = this.$store.getters["asset/getRouterPermissions"]
      const shoppingCartPermissionsBool = boolList.filter(item => item.id === "shopping-cart")[0]
        .status
      if (!shoppingCartPermissionsBool && !this.isAdmin) {
        this.$message.error(
          "Shopping Cart is unavailable due to a system upgrade. Contact support@landingintl.com with any issues or concerns."
        )
        return false
      } else {
        return true
      }
    },
    async addProductToCart(product, orderTypeId) {
      if (!this.canCheckAddCart()) {
        this.productAdded = false
        return
      }
      this.productAdded = true
      let params = {}
      if (product.sample && orderTypeId === "purchase") {
        return
      }
      if (this.isShowCartForAdmin) {
        params = { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
      }
      const productId = product.id
      await this.$store.dispatch("retailer/addProductToCart", { productId, orderTypeId, params })
    },
    sortedImages() {
      const mainImage = this.product.images.find(image => image.main)
      const sortedImages = this.product.images.filter(image => !image.main)
      sortedImages.unshift(mainImage)
      return sortedImages
    },
    hideDropdown() {
      this.isDropdownOpen = false
    },
    getBenefitsValue() {
      if (this.product.newBenefits && this.product.newBenefits.length > 0) {
        return this.product.newBenefits.map(item => item.name).join("\n")
      }
      return ""
    },
    getIngredientsValue() {
      if (this.product.newIngredients && this.product.newIngredients.length > 0) {
        return this.product.newIngredients.map(item => item.name).join("\n")
      }
      return ""
    }
  }
}
</script>

<style lang="sass">
.no-border
  border: none !important
.product-card__btn-layer
  width: 100%
.product-card__btn-img
  width: 17px
  height: 17px
  margin-left: 6px
.product-card__back-btn
  background: #DEE5EF
  border-radius: 2px
  width: 88px
  height: 30px
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 22px
  color: #000000
  float: right
.product-card__body
  padding-top: 50px
  padding-bottom: 100px

.product-card__button-submit, .product-card__button-submit--added
  height: 46px
  width: 237px
  font-size: 14px
  margin-top: 16px
  border-radius: 0
  background-color: #303030 !important

.product-card__button-submit--in-cart
  height: 46px
  width: 237px
  font-size: 14px
  margin-top: 16px
  border-radius: 0
  color: #8D8D8D !important
  border-color: #8D8D8D !important
  pointer-events: none

.product-card__button-submit--added
  background-color: black !important

.product-card__button-submit:hover
  background-color: black !important

.product-card__button-submit--disabled
  pointer-events: none
  background-color: white !important
  color: black !important
  border-color: #8D8D8D !important
  opacity: 0.6

.product-card__button-submit--not-allowed-cursor
  cursor: not-allowed

.product-card__details-header
  margin-top: 24px
  font-size: 18px
  letter-spacing: 0.25px
  color: #000
  font-weight: 600

.product-card__body-info--title
  letter-spacing: 0.15px
  font-size: 16px
  color: #000
  margin-top: 27px
  font-weight: 600

.product-card__body-info
  border-bottom: 1px solid #E5E5E5

.product-card__body-info:nth-child(4)
  border-bottom: none

.product-card__body-description
  padding-left: 20px
  padding-bottom: 24px
  padding-top: 16px
  color: #4A4A4A
  max-width: 1100px
  white-space: pre-wrap
  overflow-wrap: break-word

.product-card
  padding: 0px 80px 0px 80px

  &__nav
    padding: 16px 0px

  &__nav_border
    border-bottom: 1px solid #e5e5e5
    margin-bottom: 32px

  &__breadcrumb
    display: inline

    li
      display: inline
      color: #000000
      font-size: 14px
      font-weight: 300
      letter-spacing: 0.16px

  &__thumb
    margin-bottom: 15px
    width: 64px
    height: 64px
    border: 1px solid #e5e5e5
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center

    video
      max-width: 100%
      max-height: 100%

    img
      max-width: 100%
      max-height: 100%

    &_active
      border: 2px solid #000000
      border-radius: 2px

  &__details
    border: 1px solid #e5e5e5
    border-radius: 4px
    max-width: 384px
    padding: 25px 23px 0px 23px
    position: relative

    .product-card-details-list
      background: #fff
      width: 268px
      //padding: 10px 20px
      position: absolute
      top: 12px
      right: 8px
      border: 1px solid #e5e5e5
      box-shadow: 0px 4px 12px rgba(0,0,0,0.06)
      display: none

      .list-item-block
        display: flex
        align-items: center
        padding: 10px 20px
        cursor: pointer

        &:hover
          background-color: #f6f6f6

        .icon-block
          min-width: 20px

        .text-block
          padding-left: 10px

          p
            color: #212B36
            font-size: 14px

    .product-card__breadcrumb-block
      display: flex
      .image-icon-block
        margin-left: auto
        cursor: pointer
        &:hover
          color: #737781

  &__details.open
    .product-card-details-list
      display: block

    &-brand
      font-size: 16px
      letter-spacing: 0.15px
      font-weight: 600
      margin-top: 10px

      a
        color: #000

    &-size
      font-size: 18px
      letter-spacing: 0.25px
      color: #000

    &-name
      font-size: 18px
      letter-spacing: 0.25px
      color: #000
      margin-top: 8px

    &-sku
      font-size: 16px
      letter-spacing: 0.4px
      line-height: 22px
      color: #767676
      margin-top: 4px

    &-msrp
      color: #B3B3B3
      font-size: 16px
      letter-spacing: 0.15px
      line-height: 22px
      margin-top: 4px

    &-margin
      color: #AAE4D4
      font-size: 20px

    &-price
      color: #292929
      font-size: 20px
      line-height: 24px
      line-height: 24px
      margin-top: 7px

      &-info
        color: black
        font-size: 14px
        letter-spacing: 0.25px
        line-height: 18px

    &-country
      font-size: 14px
      letter-spacing: 0.25px
      line-height: 18px
      font-weight: 600

    &-sample
      color: #9B9B9B
      font-size: 14px
      letter-spacing: 0.25px
      text-transform: uppercase
      margin-top: 23px

    &-upcoming
      color: #9B9B9B
      font-size: 14px
      letter-spacing: 0.25px
      margin-top: 23px

  &__buttons
    margin: 0 auto
    margin-top: 20px
    margin-bottom: 30px

  &__data-sheet-link
    text-decoration: underline
    color: black
    font-size: 14px
    margin-top: 26px
    margin-bottom: 7px
    display: inline-block

  &__selected-image
    width: 591px
    height: 591px
    display: flex
    justify-content: center
    align-items: center

    video
      max-height: 100%
      max-width: 100%

    img
      max-height: 100%
      max-width: 100%

.tag-button
  cursor: default
  margin-top: 10px
  background-color: #dbdbdb !important
  color: #494949 !important
  height: 32px

.wait
  cursor: wait
</style>
