<template>
    <div>
        <a-radio-group v-model="selectedCustomerPaymentProfileId">
            <a-radio v-for="item in cardList" :key="item.customerPaymentProfileId"
                :value="item.customerPaymentProfileId">
                <div>
                    <card-number :card-info="item" class="new-card-list__row-margin" />
                    <a-row>
                        <a-col :span="12">
                            <p class="new-card-list__other-title">
                                {{ $t("Components.Brands.Payment.nameOnCard") }}
                            </p>
                            <p class="new-card-list__other-text">{{ item.cardHolderName }}</p>
                        </a-col>
                        <a-col :span="12" class="new-card-list__content-right">
                            <p class="new-card-list__other-title">
                                {{ $t("Components.Brands.Payment.expiredDateDesc") }}
                            </p>
                            <p class="new-card-list__other-text">{{ item.expirationDate }}</p>
                        </a-col>
                    </a-row>
                </div>
            </a-radio>
        </a-radio-group>
    </div>
</template>
<script>
import Payment from '@/models/Payment';
import CardNumber from "@/components/brands/settings/CardNumber";
export default {
    components: {
        "card-number": CardNumber,
    },
    props: {
        paymentProfiles: { type: Payment, default: null }
    },
    data() {
        return {
            selectedCustomerPaymentProfileId: null,
            cardList: [],
        };
    },
    async created() {
        this.cardList = this.paymentProfiles.map(p => {
            const data = this.toCardList(p);
            if (p.creditCard.isDefaultPaymentMethod) {
                this.selectedCustomerPaymentProfileId = p.customerPaymentProfileId;
            }
            return data;
        });
        console.log(this.cardList);
    },
    methods: {
        toCardList(payment) {
            var cc = payment.creditCard;
            var r = {
                brandPrivateId: this.brandId,
                cardBrand: cc.cardType,
                cardCode: cc.cardCode,
                cardHolderName: `${payment.firstName || ""} ${payment.lastName || ""}`,
                cardNumber: cc.cardNumber,
                expirationDate: cc.expirationDate,
                isExpired: false,
                default: cc.isDefaultPaymentMethod,
                customerPaymentProfileId: payment.customerPaymentProfileId,
                customerProfileId: 0,
                isAuthorizeNet: true,
            };
            return r;
        },
    }
}
</script>
<style lang="scss">
.ant-radio-group {
    .ant-radio-wrapper {
        display: flex;
        ;
    }
}
</style>