<template>
  <div ref="messageTable" class="message-table__main">
    <a-table
      bordered
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :data-source="formatTableList(list)"
      :columns="columns"
      :get-popup-container="() => $refs.messageTable"
      :custom-row="clickRow"
      :loading="isLoading"
      :pagination="false"
      @change="sortItem"
    >
      <!-- <template slot="checkout" slot-scope="text, record">
        <a-checkbox @change.stop="event => changeItemCheckout(event, text, record)" @click.stop />
      </template>
      <template slot="name" slot-scope="text, record">
        <a-icon type="edit" style="margin-left: 5px" @change="text, record" />
      </template> -->
      <span slot="fromTitle">
        From
        <a-icon
          type="caret-up"
          :rotate="sortFrom.toString().length > 0 && sortFrom === 'descend' ? 180 : 0"
          :class="
            sortFrom.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('client_name')"
        />
      </span>
      <span slot="categoryTitle">
        Category
        <a-icon
          type="caret-up"
          :rotate="sortCategory.toString().length > 0 && sortCategory === 'descend' ? 180 : 0"
          :class="
            sortCategory.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('category_and_message_type')"
        />
      </span>
      <span slot="attTitle">
        ATT
        <a-icon
          type="caret-up"
          :rotate="sortAtt.toString().length > 0 && sortAtt === 'descend' ? 180 : 0"
          :class="
            sortAtt.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('att')"
        />
      </span>
      <span slot="dateTitle">
        Date
        <a-icon
          type="caret-up"
          :rotate="sortDate.toString().length > 0 && sortDate === 'descend' ? 180 : 0"
          :class="
            sortDate.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('date')"
        />
      </span>
      <template slot="att" slot-scope="text, record">
        <a-icon
          v-if="text"
          type="paper-clip"
          style="margin-left: 5px; margin-right: 8px;"
          @change.stop="text, record"
        />
        <img
          v-if="canReply"
          src="../../../assets/img/icon/email_action_reply.svg"
          class="message-table__main__icon"
          @click.stop="forwardMessage(record)"
        />
        <img
          src="../../../assets/img/icon/message_remove.svg"
          class="message-table__main__icon"
          @click.stop="deleteMessage(record)"
        />
        <img
          v-if="isAdmin && canReply && canArchive"
          src="../../../assets/img/icon/message_archive.svg"
          class="message-table__main__icon"
          @click.stop="archiveMessage(record)"
        />
      </template>
      <span
        slot="fromValue"
        slot-scope="text, record"
        :style="record.isRceived && !record.isRead ? 'font-weight: 700;' : ''"
      >
        <a-tooltip
          v-if="text.length > 12"
          placement="bottomLeft"
          :title="text"
          overlay-class-name="message-table__tooltip"
        >
          {{ `${text.substring(0, 12)}...` }}
        </a-tooltip>
        <span v-else> {{ text }} </span>
        <span
          v-if="record.messageCount && record.messageCount > 1"
          style="font-size:12px; font-weight: 400; color: #8c8c8c;margin-left:2px;"
          >{{ record.messageCount }}</span
        >
      </span>
      <span
        slot="categoryValue"
        slot-scope="text, record"
        :style="record.isRceived && !record.isRead ? 'font-weight: 700;' : ''"
      >
        {{ text }}
      </span>
      <span
        slot="subjectValue"
        slot-scope="text, record"
        :style="record.isRceived && !record.isRead ? 'font-weight: 700;' : ''"
      >
        {{ text }}
      </span>
      <span
        slot="dateValue"
        slot-scope="text, record"
        :style="record.isRceived && !record.isRead ? 'font-weight: 700;' : ''"
      >
        {{ text }}
      </span>
      <template slot="footer">
        <a-button
          v-if="isShowFooter"
          class="message-table__main__clear-search"
          @click="clearSearchAction"
          >Clear Search</a-button
        >
      </template>
    </a-table>
    <a-pagination
      class="inventoryPagination"
      style="margin-top: 10px; float: right;"
      :page-size="list && list.pageSize ? list.pageSize : 12"
      :current="list.page"
      :total="list.total"
      size="small"
      :show-quick-jumper="true"
      @change="getPageData"
    />
    <message-list-modal ref="messageDetail" @refresh="refreshData" />
    <confirm-dialog ref="confirmDelete" @doConfirm="doDeleteMessage" />
  </div>
</template>

<script>
import MessageListModal from "@/components/message/MessageListModal.vue"
import ConfirmDialog from "@/components/admin/ConfirmDialog.vue"
import constants from "@/config.js"
import { getAdminMessageBatchOperaiontParam } from "@/utils/validate"
export default {
  components: {
    MessageListModal,
    ConfirmDialog
  },
  props: {
    isShowFooter: { type: Boolean, default: () => false },
    isLoading: { type: Boolean, default: () => false },
    list: { type: Object, default: () => {} },
    canArchive: { type: Boolean, default: () => true },
    canReply: { type: Boolean, default: () => true }
  },
  data() {
    return {
      //dataSource: {},
      count: 2,
      columns: [
        {
          // title: "From",
          slots: { title: "fromTitle" },
          dataIndex: "from",
          scopedSlots: { customRender: "fromValue" },
          filterMultiple: false
          // sorter: true,
          // sortDirections: ["descend", "ascend"]
        },
        {
          // title: "Category",
          dataIndex: "categoryInfo",
          slots: { title: "categoryTitle" },
          width: 181,
          scopedSlots: { customRender: "categoryValue" }
        },
        {
          title: "Subject",
          dataIndex: "subject",
          scopedSlots: { customRender: "subjectValue" },
          width: 360,
          ellipsis: true
          //   sorter: true,
          //   sortDirections: ["descend", "ascend"]
        },
        {
          // title: "ATT",
          dataIndex: "hasAttachments",
          width: 90,
          scopedSlots: { customRender: "att" },
          slots: { title: "attTitle" }
        },
        {
          // title: "Date",
          dataIndex: "date",
          slots: { title: "dateTitle" },
          scopedSlots: { customRender: "dateValue" }
          //   sorter: true,
          //   sortDirections: ["descend", "ascend"]
        }
      ],
      editMessage: undefined,
      selectedRowKeys: [],
      selectedRecord: [],
      sortFrom: "",
      sortCategory: "",
      sortAtt: "",
      sortDate: ""
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    retailers() {
      return this.$store.getters["retailer/retailers"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    brands() {
      return this.$store.getters["brand/brands"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    clientId() {
      return this.$store.getters["user/businessId"]
    }
  },
  watch: {
    isLoading() {
      console.log(this.isLoading)
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRecord = []
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        for (let i = 0; i < this.selectedRowKeys.length; i++) {
          let data = this.list.messages.filter(item => item.id === this.selectedRowKeys[i])
          if (data && data.length > 0) {
            // this.selectedRecord.push(Object.assign({}, data[0]))
            this.selectedRecord = this.selectedRecord.concat(data)
          }
        }
      }
      console.log(this.selectedRecord)
      console.log(selectedRowKeys)
      this.$emit("onChecked", this.selectedRecord)
    },
    changeItemCheckout(event, text, record) {
      console.log(event)
      console.log(text)
      console.log(record)
    },
    setReaded(record) {
      if (record.isRceived && !record.isRead) {
        const messageParam = { privateId: record.privateId, id: record.id }
        if (record.parentPrivateId) {
          messageParam.parentPrivateId = record.parentPrivateId
        }
        const params = { messages: [messageParam] }
        params["itemize"] = "received"
        params["archive"] = false
        params["read"] = true
        params["active"] = true
        this.$store
          .dispatch("adminMessage/updateBatchMessages", params)
          .then(res => {
            console.log(res)
            this.$emit("getMessageData", this.$route.query.tab)
            this.$store.dispatch("adminMessage/getUnReadMessage")
          })
          .catch(err => {
            this.$message.error(err)
          })
      }
    },
    clickRow(record, index) {
      return {
        on: {
          click: () => {
            console.log(index)
            this.$refs["messageDetail"].openDialog(record, !this.canArchive, true)
            this.setReaded(record)
          }
        }
      }
    },
    forwardMessage(record) {
      this.$refs["messageDetail"].openForwardDialog(record, !this.canArchive)
      this.setReaded(record)
    },
    deleteMessage(record) {
      this.editMessage = record
      this.showDeleteConfirm()
    },
    getBatchOperationParam(isDelete) {
      let curUserId = this.clientId
      let type = "received"
      if (this.isAdmin) {
        curUserId = "*"
      }
      if (this.editMessage.senderClientId === curUserId) {
        if (this.isAdmin) {
          if (this.editMessage.drafts) {
            type = "draft"
          } else if (this.editMessage.archive) {
            type = "archive"
          } else {
            type = "send"
          }
        } else {
          type = "send"
        }
      } else {
        if (this.isAdmin) {
          for (let i = 0; i < this.editMessage.messageReceivers.length; i++) {
            if (this.editMessage.messageReceivers[i].receiverClientId === curUserId) {
              if (this.editMessage.messageReceivers[i].archive) {
                type = "archive"
              }
              break
            }
          }
        }
      }
      return getAdminMessageBatchOperaiontParam(type, isDelete, [this.editMessage], false)
    },
    getOperationParam(isDelete) {
      const params = Object.assign({}, this.editMessage)
      let curUserId = this.clientId
      if (this.isAdmin) {
        if (!this.isRetailer && !this.isBrand) {
          curUserId = "*"
        } else if (this.isRetailer) {
          curUserId = this.$route.params.retailerId
        } else if (this.isBrand) {
          curUserId = this.$route.params.brandId
        }
      }
      if (params.senderClientId === curUserId) {
        if (isDelete) {
          params.active = false
        } else {
          params.archive = true
        }
      } else {
        for (let i = 0; i < params.messageReceivers.length; i++) {
          if (params.messageReceivers[i].receiverClientId === curUserId) {
            if (isDelete) {
              params.messageReceivers[i].active = false
            } else {
              params.messageReceivers[i].archive = true
            }
            break
          }
        }
      }
      return params
    },
    refreshData() {
      this.$emit("getMessageData", this.$route.query.tab)
    },
    updateMessage(isDelete) {
      if (!this.canArchive) {
        // inter message
        const messageParam = { privateId: this.editMessage.privateId, id: this.editMessage.id }
        if (this.editMessage.parentPrivateId) {
          messageParam.parentPrivateId = this.editMessage.parentPrivateId
        }
        const params = { messages: [messageParam] }
        params["itemize"] = "interMessaging"
        params["seenByLanding"] = false
        params["archive"] = false
        params["read"] = true
        params["active"] = true
        this.$store
          .dispatch("adminMessage/updateBatchMessages", params)
          .then(res => {
            console.log(res)
            this.$message.success(`${isDelete ? "Delete" : "Archive"} message success!`)
            this.$emit("getMessageData", this.$route.query.tab)
          })
          .catch(err => {
            this.$message.error(err)
          })
      } else {
        let params = this.getBatchOperationParam(isDelete)
        this.$store
          .dispatch("adminMessage/updateBatchMessages", params)
          .then(res => {
            console.log(res)
            this.$message.success(`${isDelete ? "Delete" : "Archive"} message success!`)
            this.$emit("getMessageData", this.$route.query.tab)
          })
          .catch(err => {
            this.$message.error(err)
          })
      }
    },
    doDeleteMessage() {
      this.updateMessage(true)
    },
    showDeleteConfirm() {
      this.$refs["confirmDelete"].openDialogConfirm(
        "Delete Message",
        "Are you sure you wish to delete? This action cannot be undone. ",
        "No",
        "Yes"
      )
    },
    archiveMessage(record) {
      this.editMessage = record
      this.updateMessage(false)
    },
    clearSearchAction() {
      this.$emit("clearSerach")
    },
    getPageData(page) {
      this.selectedRowKeys = []
      this.$emit("pageChange", page)
    },
    getCategoryName(item) {
      if (item.category && item.category.description) {
        return item.category.description
      }
      if (item.messageType && item.messageType.description) {
        return item.messageType.description
      }
      return "--"
    },
    getFromName(item) {
      if (item.senderClientId === "*") {
        return "Landing"
      } else {
        if (this.isBrand && this.brand && this.brand.id === item.senderClientId) {
          return this.brand.name
        } else if (this.isRetailer && this.retailer && this.retailer.id === item.senderClientId) {
          return this.retailer.name
        }
        let findObj = this.brands.find(data => data.id === item.senderClientId)
        if (findObj) {
          return findObj.name
        }
        findObj = this.retailers.find(data => data.id === item.senderClientId)
        if (findObj) {
          return findObj.name
        }
        return item.senderClientId
      }
    },
    formateDate(dateStr) {
      const dateArray = dateStr.split("-")
      return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`
    },
    formatTableList(list) {
      let data = list.messages || []
      data = JSON.parse(JSON.stringify(data))
      return data.map(item => {
        item.categoryInfo = this.getCategoryName(item)
        item.from = this.getFromName(item)
        item.key = item.id
        item.date = this.formateDate(item.createdAt.slice(0, 10))
        if (item.messageReceivers && item.messageReceivers.length > 0) {
          for (let i = 0; i < item.messageReceivers.length; i++) {
            if (this.isAdmin && item.messageReceivers[i].receiverClientId === "*") {
              item.isRead = item.messageReceivers[i].read
              item.isRceived = true
              break
            } else if (item.messageReceivers[i].receiverClientId === this.clientId) {
              item.isRead = item.messageReceivers[i].read
              item.isRceived = true
              break
            }
          }
        }
        return item
      })
    },
    sortByField(fieldName) {
      let sort = "ascend"
      if (fieldName === "client_name") {
        if (this.sortFrom.length === 0) {
          this.sortFrom = "ascend"
        } else if (this.sortFrom === "ascend") {
          this.sortFrom = "descend"
        } else {
          this.sortFrom = "ascend"
        }
        this.sortCategory = ""
        this.sortAtt = ""
        this.sortDate = ""
        sort = this.sortFrom
      } else if (fieldName === "category_and_message_type") {
        if (this.sortCategory.length === 0) {
          this.sortCategory = "ascend"
        } else if (this.sortCategory === "ascend") {
          this.sortCategory = "descend"
        } else {
          this.sortCategory = "ascend"
        }
        this.sortFrom = ""
        this.sortAtt = ""
        this.sortDate = ""
        sort = this.sortCategory
      } else if (fieldName === "att") {
        if (this.sortAtt.length === 0) {
          this.sortAtt = "ascend"
        } else if (this.sortAtt === "ascend") {
          this.sortAtt = "descend"
        } else {
          this.sortAtt = "ascend"
        }
        this.sortFrom = ""
        this.sortCategory = ""
        this.sortDate = ""
        sort = this.sortAtt
      } else if (fieldName === "date") {
        if (this.sortDate.length === 0) {
          this.sortDate = "ascend"
        } else if (this.sortDate === "ascend") {
          this.sortDate = "descend"
        } else {
          this.sortDate = "ascend"
        }
        this.sortFrom = ""
        this.sortCategory = ""
        this.sortAtt = ""
        sort = this.sortDate
      }
      const params = {}
      if (fieldName !== "date") {
        params["_sort-by"] = fieldName
      }
      params["_order-by"] = sort === "ascend" ? "NEXT" : "PREVIOUS"
      this.$emit("sortData", params)
    },
    sortItem(value1, value2, sort) {
      console.info(value1, value2)
      let sortByValue = ""
      if (sort.field === "from") {
        sortByValue = "client_name"
      } else if (sort.field === "categoryInfo") {
        sortByValue = "category"
      } else if (sort.field === "hasAttachments") {
        sortByValue = "at"
      }
      const params = {
        "_order-by": sort.order === "descend" ? "PREVIOUS" : "NEXT",
        "_sort-by": sortByValue
      }
      this.$emit("sortData", params)
    }
  }
}
</script>

<style lang="scss">
.message-table__main {
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: none;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    font-family: "Proxima Nova";
    padding: 4px 6px;
    vertical-align: middle;
    color: #262626;
  }
  .ant-table-tbody > tr > td.ant-table-selection-column .ant-checkbox-wrapper {
    margin-bottom: 4px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 0;
  }
  .ant-table-thead > tr > th:last-child,
  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #e8e8e8;
  }
  .ant-pagination-item-active {
    border-color: #4a8a5d;
  }
  .ant-pagination-item-active a {
    color: #4a8a5d;
  }
  &__icon {
    margin-right: 8px;
    display: none;
    cursor: pointer;
  }
  &__clear-search {
    background: #4a8a5d !important;
    border-radius: 2px !important;
    border-color: #4a8a5d !important;
    color: white !important;
    float: right;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  }
}
.message-table__tooltip .ant-tooltip-inner {
  background: #d9d9d9;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}
.message-table__tooltip.ant-tooltip-placement-bottomLeft {
  padding-top: 0px;
  padding-left: 32px;
}
.message-table__tooltip .ant-tooltip-arrow::before {
  background-color: #d9d9d9;
  display: none;
}
.message-table__main .ant-table-row:hover {
  background: #e2eeec;
}
.message-table__main
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: #e2eeec;
}
.message-table__main
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td
  .message-table__main__icon,
.message-table__main .ant-table-tbody > tr.ant-table-row-selected:hover .message-table__main__icon {
  display: inline-block !important;
}
.message-table__main .ant-table-tbody > tr.ant-table-row-selected td,
.message-table__main .ant-table-tbody > tr.ant-table-row-selected:hover td {
  background: #e2eeec;
}
.message-table__main .ant-table-tbody > tr > td.ant-table-selection-column > span {
  visibility: hidden;
}
.message-table__main
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td.ant-table-selection-column
  > span,
.message-table__main
  .ant-table-tbody
  > tr.ant-table-row-selected
  td.ant-table-selection-column
  > span {
  visibility: visible;
}
.message-table__main .ant-table-thead > tr > th.ant-table-selection-column .ant-checkbox-wrapper {
  visibility: hidden;
}
.message-table__main .ant-table.ant-table-bordered .ant-table-footer {
  border: none;
  border-top: 1px solid #e8e8e8;
}
.message-table__main .ant-table-footer {
  height: 50px;
  padding: 10px 0px;
  background: inherit;
}
</style>
