import { removeFromState, addToState } from "../utils/helpers"
import axios from "@/api/axios"
export default {
  namespaced: true,

  state: {
    hasBanner: false,
    searchTerm: "",
    advancedSearch: {
      location: "",
      channelType: "",
      locationType: "",
      otherType: ""
    },
    tempChannelTypeVariable: [],
    tempLocationVariable: [],
    tempLocationTypeVariable: [],
    tempOtherTypeVariable: [],
    retailersRequestURL: "/retailers?",
    newWindowSearchURL: "",
    isCustomSearch: false,
    returnedCustomRetailers: []
  },

  getters: {
    banners(state) {
      return state.hasBanner
    }
  },

  actions: {
    hasBanner({ commit }, payload) {
      commit("UPDATE_BANNER", payload)
    },
    async fetchCustomRetailers({ commit, state }) {
      console.log("here it comes - commit")
      console.log(state)
      axios.get(state.newWindowSearchURL).then(function(response) {
        // handle success
        commit("UPDATE_CUSTOM_SEARCH_RETAILERS", response.data)
      })
    },

    async updateSearchTerm({ commit }, data) {
      try {
        console.log("updating search term")

        commit("UPDATE_SEARCH_TERM", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async alterAdvancedSearchItem({ commit }, payload) {
      let act = payload.act
      let id = payload.id
      console.log(act, id)

      try {
        if (act == "addLocation") {
          commit("ADD_LOCATION_ITEM", id)
        }
        if (act == "removeLocation") commit("REMOVE_LOCATION_ITEM", id)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },

  mutations: {
    UPDATE_BANNER(state, payload) {
      state.hasBanner = payload
    },
    UPDATE_CUSTOM_SEARCH_RETAILERS(state, payload) {
      console.log("UPDATE_CUSTOM_SEARCH_RETAILERS")
      state.returnedCustomRetailers = payload
      console.log(state)
    },
    ENABLE_CUSTOM_SEARCH(state, payload) {
      state.isCustomSearch = true
      state.newWindowSearchURL = payload.url
    },
    DISABLE_CUSTOM_SEARCH(state) {
      console.log("disabling custom search!")
      state.isCustomSearch = false
      state.newWindowSearchURL = ""
      state.tempLocationVariable = []
    },
    ADD_TEMP_VARIABLE(state, payload) {
      if (payload.type == "channel") {
        addToState(state.tempChannelTypeVariable, payload, payload.type)
      }
      if (payload.type == "location") {
        addToState(state.tempLocationTypeVariable, payload, payload.type)
      }
      if (payload.type == "country") {
        addToState(state.tempLocationVariable, payload, payload.type)
      }
      if (payload.type == "other") {
        addToState(state.tempOtherTypeVariable, payload, payload.type)
      }
    },
    REMOVE_TEMP_VARIABLE(state, payload) {
      if (payload.type == "channel") {
        removeFromState(state.tempChannelTypeVariable, payload, payload.type)
      }
      if (payload.type == "location") {
        removeFromState(state.tempLocationTypeVariable, payload, payload.type)
      }
      if (payload.type == "country") {
        removeFromState(state.tempLocationVariable, payload, payload.type)
      }
      if (payload.type == "other") {
        removeFromState(state.tempOtherTypeVariable, payload, payload.type)
      }
    },

    UPDATE_SEARCH_TERM(state, term) {
      state.searchTerm = term
    },
    ADD_LOCATION_ITEM(state, id) {
      let currentState = state.advancedSearch.location.split(",")
      if (currentState[0] == "") {
        currentState.shift()
      }
      currentState.push(id)
      let currentStateStringed = currentState.join(",")
      state.advancedSearch.location = currentStateStringed
    },
    REMOVE_LOCATION_ITEM(state, id) {
      let currentState = state.advancedSearch.location.split(",")
      if (currentState.includes(id)) {
        let filteredCurrentState = currentState.filter(e => {
          return e !== id
        })
        console.log(filteredCurrentState.join(","))
        state.advancedSearch.location = filteredCurrentState.join(",")
      }
    }
  }
}
