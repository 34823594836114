import SimpleDescriptionEntity from "@/models/SimpleDescriptionEntity";
import { Retailer } from "@/models/Client";
export class SubscriptionPricing {
  constructor(model) {
    this.subscriptionType = null;
    this.retailer = null;
    this.amount = 0;
    this.billFrequencies = [];
    this.isSubscribed = false;

    if (model) {
      this.subscriptionType =
        model.subscriptionType == void 0
          ? null
          : new SubscriptionType(model.subscriptionType);
      this.retailer =
        model.retailer == void 0 ? null : new Retailer(model.retailer);
      this.isSubscribed = model.isSubscribed;
      this.amount = model.amount;
      this.billFrequencies =
        model.billFrequencies == void 0
          ? []
          : model.billFrequencies.map((m) => new BillingFrequence(m));
    }
  }
  get name() {
    return `${this.subscriptionType && this.subscriptionType.name} ${(this
      .retailer &&
      this.retailer.name) ||
      ""}`;
  }
  get retailerId() {
    return (this.retailer && this.retailer.name) || null;
  }
}

export class SubscriptionType extends SimpleDescriptionEntity {
  constructor(model) {
    super(model);
  }
}

export class BillingFrequence {
  constructor(model) {
    this.term = null;
    this.discountPercentage = 0;
    this.numberOfPayments = 0;

    if (model) {
      this.term = model.term;
      this.discountPercentage = model.discountPercentage;
      this.numberOfPayments = model.numberOfPayments;
    }
  }

  get name() {
    return this.numberOfPayments == 1 ? "Yearly" : this.term.name;
  }

  get numOfPayment() {
    return this.numberOfPayments;
  }
}
