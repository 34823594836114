<template>
  <div class="training-brand-retailer-menu-main">
    <!--    <a-menu v-if="isBrand" v-model="currentList" mode="horizontal" @click="handleClick">-->
    <!--      <a-menu-item v-for="item in menuInfo" :key="item.key" class="brand-menu-item-style">-->
    <!--        {{ item.name }}-->
    <!--      </a-menu-item>-->
    <!--    </a-menu>-->
    <!--    <a-menu v-if="isRetailer" v-model="currentList" mode="horizontal" @click="handleClick">-->
    <!--      <a-menu-item v-for="item in menuRetailerInfo" :key="item.key" class="menu-item-style">-->
    <!--        {{ item.name }}-->
    <!--      </a-menu-item>-->
    <!--    </a-menu>-->
    <div class="brand-menu-item-style">
      <div v-for="item in menuInfoActiveList" :key="item.key">
        <span
          class="tab-title-text"
          :class="item.active ? '' : 'tab-title-text-no-active'"
          @click="handleClick(item)"
        >
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
export default {
  name: "BrandRetailerMenu",
  props: {
    current: { type: String, default: "" },
    isRetailer: { type: Boolean, default: false },
    isBrand: { type: Boolean, default: false },
  },
  data() {
    return {
      // current: ["product"],
      retailer: null,
      menuInfo: [
        {
          key: "product",
          name: "Product",
          active: false,
        },
        {
          key: "retailers",
          name: "Retailers",
          active: false,
        },
        {
          key: "reporting",
          name: "Dashboard",
          active: false,
        },
      ],
      menuRetailerInfo: [
        {
          key: "RetailerTrainingBrand",
          name: "Brands",
          active: false,
        },
        {
          key: "retailerTrainingLocation",
          name: "Location",
          active: false,
        },
        {
          key: "retailerTrainingUsers",
          name: "Users",
          active: false,
        },
      ],
      currentList: [this.current],
    };
  },
  async created() {
    if (this.isRetailer) {
      const businessId = this.$store.getters["user/businessId"];
      this.retailer = await this.$store.dispatch(
        "retailer/fetchRetailer",
        businessId
      );
    }
  },
  computed: {
    menuInfoActiveList() {
      let list;
      if (this.isBrand) {
        list = this.menuInfo;
      }
      if (this.isRetailer) {
        list = this.menuRetailerInfo;
        console.log(this.retailer);
        console.log(config.hideTrainingUser.retailers);
        if (
          this.retailer &&
          config.hideTrainingUser.retailers.includes(this.retailer.shortName)
        ) {
          list = list.filter((l) => l.name !== "Users");
        }
      }
      return list.map((item) => {
        if (item.key === this.current) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });
    },
  },
  methods: {
    handleClick(value) {
      this.$emit("clickToNewTab", value.key);
    },
    handleItemClick() {},
  },
};
</script>

<style lang="scss">
.training-brand-retailer-menu-main {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  .brand-menu-item-style {
    display: flex;
    justify-content: start;
    margin-top: 30px;
  }
  .tab-title-text {
    display: inline-block;
    width: 137px;
    height: 30px;
    background: #3a77a9;
    color: white;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
    border-radius: 7px 7px 0 0;
    cursor: pointer;
  }
  .tab-title-text-no-active {
    background: #d9d9d9;
    color: white;
  }
  .tab-title-text:hover {
    background: rgba(58, 119, 169, 0.7);
    color: white;
  }
}
</style>
