<template>
  <div ref="brandTrainingAddStep" class="brand-training-retailer-add-step-two__main">
    <a-modal
      v-model="showModal"
      width="806px"
      :footer="null"
      :get-container="() => $refs.brandTrainingAddStep"
      @cancel="closeDialog"
    >
      <p slot="title" class="modal-title-location">Step 2. Select Payment Frequency</p>
      <p class="step-content-text">
        You have the option of being monthly, quarterly, or annually. Please select one and we'll be
        charging the card on file based on your selection. To cancel,simply close this window.
      </p>
      <!--      <a-divider />-->
      <div class="add-retailer-content">
        <div v-for="item in selectInfo" :key="item.name">
          <div
            class="item-select-style"
            :class="item.isActive ? 'item-select-style-active' : ''"
            @click="chooseValue(item)"
          >
            <p class="select-title">{{ item.name }}</p>
            <!--            <p>{{ item.textOne }}</p>-->
            <!--            <p>{{ item.textTwo }}</p>-->
          </div>
        </div>
      </div>
      <div class="button-step-style">
        <a-button class="cancel-button-style" @click="closeDialog">Cancel</a-button>
        <a-button class="next-button-style" style="margin-left: 15px" @click="nextDialog"
          >Next</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "BrandTrainingRetailerAddStep2Dialog",
  data() {
    return {
      showModal: false,
      selectValue: "",
      selectInfo: [
        {
          name: "Monthly",
          key: "monthly",
          isActive: false,
          textOne: "Lorem ipsum",
          textTwo: "dolor sit amet"
        },
        {
          name: "Annually",
          key: "annually",
          isActive: false,
          textOne: "Lorem ipsum",
          textTwo: "dolor sit amet"
        },
        {
          name: "Quarterly",
          key: "quarterly",
          isActive: false,
          textOne: "Lorem Ipsum",
          textTwo: "dolor sit amet"
        }
      ]
    }
  },
  methods: {
    openDialog() {
      this.showModal = true
    },
    closeDialog() {
      this.showModal = false
      this.selectInfo = this.selectInfo.map(value => {
        value.isActive = false
        return value
      })
    },
    chooseValue(item) {
      this.selectValue = item.key
      this.selectInfo = this.selectInfo.map(value => {
        if (value.key === item.key) {
          value.isActive = true
        } else {
          value.isActive = false
        }
        return value
      })
    },
    nextDialog() {
      const step = 3
      if (this.selectValue) {
        this.$emit("updateStepStatus", step, this.selectValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.brand-training-retailer-add-step-two__main {
  p {
    margin-bottom: 0;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .step-content-text {
    width: 600px;
    text-align: center;
    margin: 0 auto;
  }
  .add-retailer-content {
    width: 560px;
    margin: 80px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px 50px 20px;
    .item-select-style {
      width: 120px;
      height: 120px;
      line-height: 120px;
      border: 1px solid rgba(225, 100, 76, 1);
      text-align: center;
      //padding-top: 20px;
      cursor: pointer;
    }
    .item-select-style:hover {
      background-color: rgba(237, 142, 131, 1);
      color: white;
    }
    .item-select-style-active {
      background-color: rgba(237, 142, 131, 1);
      color: white;
    }
    .select-title {
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
  .button-step-style {
    text-align: right;
    .cancel-button-style {
      width: 160px;
      color: white;
      background-color: rgba(242, 124, 89, 1);
    }
    .next-button-style {
      width: 160px;
      color: white;
      background-color: rgba(145, 211, 149, 1);
    }
  }
}
</style>
