import httpclient from "@/api/http-client"
import ProductCategory from "@/models/ProductCategory"
import ProductTrainingStep from "@/models/ProductTrainingStep"
import ProductTrainingBestUsedFor from "@/models/ProductTrainingBestUsedFor"

export default {
  async getCategories() {
    // return this.getMockCategories().then((res) =>
    //   res.map((m) => new ProductCategory(m))
    // )
    return await httpclient
      .get("/api/categories")
      .then((res) => res.data.map((m) => new ProductCategory(m)))
  },
  async getCategorySteps(categoryPrivateId) {
    // return this.getMockSteps(categoryPrivateId).then((res) =>
    //   res.map((m) => new ProductTrainingStep(m))
    // )
    return await httpclient
      .get(`/api/categories/${categoryPrivateId}/steps`)
      .then((res) => res.data.map((m) => new ProductTrainingStep(m)))
  },
  async getCategoryBestUsedFors(categoryPrivateId) {
    return await httpclient
      .get(`/api/categories/${categoryPrivateId}/best-used-fors`)
      .then((res) => res.data.map((m) => new ProductTrainingBestUsedFor(m)))
  },
  async replaceSteps(categoryPrivateId, steps) {
    return await httpclient
      .put(
        `/api/categories/${categoryPrivateId}/steps`,
        steps.map((s) => s.toModel())
      )
      .then((res) => res.data.map((m) => new ProductTrainingStep(m)))
  },
  async updateStep(categoryPrivateId, step) {
    return await httpclient
      .put(
        `/api/categories/${categoryPrivateId}/steps/${step.privateId}`,
        step.toModel()
      )
      .then((res) => new ProductTrainingStep(res.data))
  },
  async updateBestUsedFor(categoryPrivateId, bestUsedFor) {
    return await httpclient
      .put(
        `/api/categories/${categoryPrivateId}/best-used-fors/${bestUsedFor.privateId}`,
        bestUsedFor.toModel()
      )
      .then((res) => new ProductTrainingStep(res.data))
  },
  async addStep(categoryPrivateId, step) {
    return await httpclient
      .post(`/api/categories/${categoryPrivateId}/steps`, step.toModel())
      .then((res) => new ProductTrainingStep(res.data))
  },
  async addSteps(categoryPrivateId, steps) {
    return await httpclient
      .post(
        `/api/categories/${categoryPrivateId}/steps/many`,
        steps.map((s) => s.toModel())
      )
      .then((res) => res.data.map((m) => new ProductTrainingStep(m)))
  },
  async addBestUsedFor(categoryPrivateId, bestUsedFor) {
    return await httpclient
      .post(
        `/api/categories/${categoryPrivateId}/best-used-fors`,
        bestUsedFor.toModel()
      )
      .then((res) => new ProductTrainingBestUsedFor(res.data))
  },
  async addBestUsedFors(categoryPrivateId, bestUsedFors) {
    return await httpclient
      .post(
        `/api/categories/${categoryPrivateId}/best-used-fors/many`,
        bestUsedFors.map((s) => s.toModel())
      )
      .then((res) => res.data.map((m) => new ProductTrainingBestUsedFor(m)))
  },

  getMockCategories() {
    const data = [
      {
        privateId: 1,
        id: "skincare",
        name: "Skincare",
        description: null,
        isActive: 1,
        createdAt: "2020-07-24T09:03:55",
        createdBy: null,
        updatedAt: "2023-01-17T16:28:09",
        updatedBy: "zcObcGzHSemS04k48goQJg",
        productSubcategories: [
          {
            privateId: 1,
            productCategoryPrivateId: 1,
            id: "cleansers",
            name: "Cleansers",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: "2021-10-29T12:08:28",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 7,
                productSubcategoryPrivateId: 1,
                id: "cleansing-balms",
                name: "Cleansing Balms",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 1,
                productSubcategoryPrivateId: 1,
                id: "foam-cleansers",
                name: "Foam Cleansers",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 8,
                productSubcategoryPrivateId: 1,
                id: "cleansing-wipes",
                name: "Cleansing Wipes",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 2,
                productSubcategoryPrivateId: 1,
                id: "powder-cleansers",
                name: "Powder Cleansers",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 9,
                productSubcategoryPrivateId: 1,
                id: "cleansing-waters",
                name: "Cleansing Waters",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 3,
                productSubcategoryPrivateId: 1,
                id: "gel-cleansers",
                name: "Gel Cleansers",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 10,
                productSubcategoryPrivateId: 1,
                id: "makeup-remover",
                name: "Makeup Remover",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 4,
                productSubcategoryPrivateId: 1,
                id: "bar-soaps",
                name: "Bar Soaps",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 5,
                productSubcategoryPrivateId: 1,
                id: "cream-cleansers",
                name: "Cream Cleansers",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 6,
                productSubcategoryPrivateId: 1,
                id: "cleansing-oils",
                name: "Cleansing Oils",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 2,
            productCategoryPrivateId: 1,
            id: "exfoliants",
            name: "Exfoliants",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: "2021-10-29T12:08:28",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 13,
                productSubcategoryPrivateId: 2,
                id: "peel-pads",
                name: "Peel Pads",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 14,
                productSubcategoryPrivateId: 2,
                id: "exfoliating-powders",
                name: "Exfoliating Powders",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 11,
                productSubcategoryPrivateId: 2,
                id: "face-peels",
                name: "Face Peels",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 12,
                productSubcategoryPrivateId: 2,
                id: "face-scrubs",
                name: "Face Scrubs",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 3,
            productCategoryPrivateId: 1,
            id: "toners",
            name: "Toners",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: "2021-10-29T12:08:28",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 15,
                productSubcategoryPrivateId: 3,
                id: "toners",
                name: "Toners",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 16,
                productSubcategoryPrivateId: 3,
                id: "toning pads",
                name: "Toning Pads",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: "2021-10-29T12:14:53",
                updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 4,
            productCategoryPrivateId: 1,
            id: "skincare-treatments",
            name: "Treatments",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 20,
                productSubcategoryPrivateId: 4,
                id: "skincare-treatments-lip-treatments",
                name: "Lip Treatments",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 21,
                productSubcategoryPrivateId: 4,
                id: "neck-decollete",
                name: "Neck & Decollete",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 17,
                productSubcategoryPrivateId: 4,
                id: "essences",
                name: "Essences",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 18,
                productSubcategoryPrivateId: 4,
                id: "serums",
                name: "Serums",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 19,
                productSubcategoryPrivateId: 4,
                id: "acne-treatments",
                name: "Acne Treatments",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 5,
            productCategoryPrivateId: 1,
            id: "moisturizes",
            name: "Moisturizers",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 26,
                productSubcategoryPrivateId: 5,
                id: "bb-cc-creams",
                name: "BB & CC Creams",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 22,
                productSubcategoryPrivateId: 5,
                id: "moisturizers",
                name: "Moisturizers",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 23,
                productSubcategoryPrivateId: 5,
                id: "moisturizers-spf",
                name: "Moisturizers with SPF",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 24,
                productSubcategoryPrivateId: 5,
                id: "night-creams",
                name: "Night Creams",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 25,
                productSubcategoryPrivateId: 5,
                id: "face-oil",
                name: "Face Oil",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 6,
            productCategoryPrivateId: 1,
            id: "eye-care",
            name: "Eye Care",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 27,
                productSubcategoryPrivateId: 6,
                id: "eye-creams",
                name: "Eye Creams",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 124,
                productSubcategoryPrivateId: 6,
                id: "test",
                name: "Test",
                description: null,
                isActive: 0,
                createdAt: "2021-10-21T06:12:12",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: "2021-10-21T06:12:40",
                updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                productSubcategoryPrivate: null,
              },
              {
                privateId: 28,
                productSubcategoryPrivateId: 6,
                id: "eye-masks",
                name: "Eye Masks",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 7,
            productCategoryPrivateId: 1,
            id: "masks",
            name: "Masks",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 29,
                productSubcategoryPrivateId: 7,
                id: "sheet-masks",
                name: "Sheet Masks",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 30,
                productSubcategoryPrivateId: 7,
                id: "wash-off-masks",
                name: "Wash-Off Masks",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 31,
                productSubcategoryPrivateId: 7,
                id: "peel-off-masks",
                name: "Peel-Off Masks",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 32,
                productSubcategoryPrivateId: 7,
                id: "sleeping-masks",
                name: "Sleeping Masks",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 8,
            productCategoryPrivateId: 1,
            id: "sun-care",
            name: "Sun Care",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 33,
                productSubcategoryPrivateId: 8,
                id: "sunscreen",
                name: "Sunscreen",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 34,
                productSubcategoryPrivateId: 8,
                id: "after-sun-care",
                name: "After Sun Care",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 9,
            productCategoryPrivateId: 1,
            id: "skincare-travel",
            name: "Travel",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 35,
                productSubcategoryPrivateId: 9,
                id: "skincare-minis",
                name: "Skincare Minis",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 36,
                productSubcategoryPrivateId: 9,
                id: "travel-accessories",
                name: "Travel Accessories",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 10,
            productCategoryPrivateId: 1,
            id: "skincare-tools",
            name: "Skincare Tools",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 39,
                productSubcategoryPrivateId: 10,
                id: "anti-aging-tools",
                name: "Anti-Aging Tools",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 37,
                productSubcategoryPrivateId: 10,
                id: "cleansing-tools",
                name: "Cleansing Tools",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 38,
                productSubcategoryPrivateId: 10,
                id: "facial-massage",
                name: "Facial Massage",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 45,
            productCategoryPrivateId: 1,
            id: "test",
            name: "Test",
            description: null,
            isActive: 0,
            createdAt: "2021-09-03T07:17:37",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-09-03T07:17:52",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 46,
            productCategoryPrivateId: 1,
            id: "skincare test",
            name: "Skincare Test",
            description: null,
            isActive: 1,
            createdAt: "2021-09-10T06:40:36",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-09-10T06:41:01",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 122,
                productSubcategoryPrivateId: 46,
                id: "test1",
                name: "Test1",
                description: null,
                isActive: 1,
                createdAt: "2021-09-10T06:41:14",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 123,
                productSubcategoryPrivateId: 46,
                id: "test2",
                name: "Test2",
                description: null,
                isActive: 1,
                createdAt: "2021-09-10T06:41:23",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
        ],
        trainingSteps: [
          {
            privateId: 6,
            id: "before-serum",
            name: "Before serum",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 5,
            id: "after-toner",
            name: "After toner",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 11,
            id: "n/a",
            name: "N/A",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-04-15T08:40:36",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 4,
            id: "before-toner",
            name: "Before toner",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 10,
            id: "as-your-last-step",
            name: "As your last step",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 3,
            id: "after-cleansing",
            name: "After cleansing",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 9,
            id: "after-moisturizer",
            name: "After moisturizer",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 2,
            id: "after-makeup-remover",
            name: "After makeup remover",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 8,
            id: "before-moisturizer",
            name: "Before moisturizer",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 1,
            id: "as-your-first-step",
            name: "As your first step",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 7,
            id: "after-serum",
            name: "After serum",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
        ],
        bestUsedFors: [
          {
            privateId: 5,
            id: "blackheads",
            name: "Blackheads",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 11,
            id: "dark-circles",
            name: "Dark Circles",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 4,
            id: "enlarged-pores",
            name: "Enlarged Pores",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 10,
            id: "eye-puffiness",
            name: "Eye Puffiness",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 3,
            id: "oily-skin",
            name: "Oily Skin",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 9,
            id: "dullness",
            name: "Dullness",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 15,
            id: "n/a",
            name: "N/A",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-05-10T04:46:21",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 2,
            id: "dry-skin",
            name: "Dry Skin",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 8,
            id: "dark-spots-&-uneven-tone",
            name: "Dark Spots & Uneven Tone",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 14,
            id: "uneven-texture",
            name: "Uneven Texture",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 1,
            id: "acne-&-blemishes",
            name: "Acne & Blemishes",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 7,
            id: "sagging-skin",
            name: "Sagging Skin",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 13,
            id: "sensitivity",
            name: "Sensitivity",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 6,
            id: "fine-lines-&-wrinkles",
            name: "Fine Lines & Wrinkles",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
          {
            privateId: 12,
            id: "redness",
            name: "Redness",
            description: null,
            isActive: 1,
            categoryPrivateId: 1,
            createdAt: "2021-01-19T13:46:49",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            category: null,
          },
        ],
      },
      {
        privateId: 2,
        id: "color",
        name: "Color",
        description: null,
        isActive: 1,
        createdAt: "2020-07-24T09:03:55",
        createdBy: null,
        updatedAt: "2023-01-17T16:28:09",
        updatedBy: "zcObcGzHSemS04k48goQJg",
        productSubcategories: [
          {
            privateId: 11,
            productCategoryPrivateId: 2,
            id: "face",
            name: "Face",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 45,
                productSubcategoryPrivateId: 11,
                id: "color-correcting",
                name: "Color Correcting",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 46,
                productSubcategoryPrivateId: 11,
                id: "contour",
                name: "Contour",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 40,
                productSubcategoryPrivateId: 11,
                id: "primer",
                name: "Primer",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 47,
                productSubcategoryPrivateId: 11,
                id: "highlighter",
                name: "Highlighter",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 41,
                productSubcategoryPrivateId: 11,
                id: "concealer",
                name: "Concealer",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 48,
                productSubcategoryPrivateId: 11,
                id: "finishing-powder",
                name: "Finishing Powder",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 42,
                productSubcategoryPrivateId: 11,
                id: "foundation",
                name: "Foundation",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 49,
                productSubcategoryPrivateId: 11,
                id: "setting-spray",
                name: "Setting Spray",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 43,
                productSubcategoryPrivateId: 11,
                id: "tinted-moisturizer",
                name: "Tinted Moisturizer",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 44,
                productSubcategoryPrivateId: 11,
                id: "bb-cc-cream",
                name: "BB & CC Cream",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 12,
            productCategoryPrivateId: 2,
            id: "cheek",
            name: "Cheek",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 50,
                productSubcategoryPrivateId: 12,
                id: "bronzer",
                name: "Bronzer",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 51,
                productSubcategoryPrivateId: 12,
                id: "blush",
                name: "Blush",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 13,
            productCategoryPrivateId: 2,
            id: "lip",
            name: "Lip",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 52,
                productSubcategoryPrivateId: 13,
                id: "color-lip-lip-treatments",
                name: "Lip Treatments",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 53,
                productSubcategoryPrivateId: 13,
                id: "lipstick",
                name: "Lipstick",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 54,
                productSubcategoryPrivateId: 13,
                id: "liquid-lipstick",
                name: "Liquid Lipstick",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 55,
                productSubcategoryPrivateId: 13,
                id: "lip-gloss",
                name: "Lip Gloss",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 56,
                productSubcategoryPrivateId: 13,
                id: "lip-stain",
                name: "Lip Stain",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 57,
                productSubcategoryPrivateId: 13,
                id: "lip-balm",
                name: "Lip Balm",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 14,
            productCategoryPrivateId: 2,
            id: "eye",
            name: "Eye",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 58,
                productSubcategoryPrivateId: 14,
                id: "eyeshadow",
                name: "Eyeshadow",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 59,
                productSubcategoryPrivateId: 14,
                id: "eyeliner",
                name: "Eyeliner",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 60,
                productSubcategoryPrivateId: 14,
                id: "false-lashes",
                name: "False Lashes",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 61,
                productSubcategoryPrivateId: 14,
                id: "brows",
                name: "Brows",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 62,
                productSubcategoryPrivateId: 14,
                id: "mascara",
                name: "Mascara",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 63,
                productSubcategoryPrivateId: 14,
                id: "eye-primer",
                name: "Eye Primer",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 64,
                productSubcategoryPrivateId: 14,
                id: "eye-palette",
                name: "Eye Palette",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 15,
            productCategoryPrivateId: 2,
            id: "color-tools-accessories",
            name: "Tools & Accessories",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 65,
                productSubcategoryPrivateId: 15,
                id: "sponges-applicators",
                name: "Sponges & Applicators",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 66,
                productSubcategoryPrivateId: 15,
                id: "makeup-brushes",
                name: "Makeup Brushes",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 67,
                productSubcategoryPrivateId: 15,
                id: "travel",
                name: "Travel",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 68,
                productSubcategoryPrivateId: 15,
                id: "bags-accessories",
                name: "Bags & Accessories",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
        ],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 3,
        id: "hair",
        name: "Hair",
        description: null,
        isActive: 1,
        createdAt: "2020-07-24T09:03:55",
        createdBy: null,
        updatedAt: "2023-01-17T16:28:09",
        updatedBy: "zcObcGzHSemS04k48goQJg",
        productSubcategories: [
          {
            privateId: 16,
            productCategoryPrivateId: 3,
            id: "shampoo-conditioner",
            name: "Shampoo & Conditioner",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 71,
                productSubcategoryPrivateId: 16,
                id: "co-wash",
                name: "Co-Wash ",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 72,
                productSubcategoryPrivateId: 16,
                id: "conditioner",
                name: "Conditioner",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 73,
                productSubcategoryPrivateId: 16,
                id: "leave-in-conditioner",
                name: "Leave-in Conditioner",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 69,
                productSubcategoryPrivateId: 16,
                id: "shampoo",
                name: "Shampoo",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 70,
                productSubcategoryPrivateId: 16,
                id: "dry-shampoo",
                name: "Dry Shampoo",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 17,
            productCategoryPrivateId: 3,
            id: "conditioner",
            name: "Conditioner",
            description: null,
            isActive: 0,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 18,
            productCategoryPrivateId: 3,
            id: "treatments",
            name: "Treatments",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 77,
                productSubcategoryPrivateId: 18,
                id: "hair-thinning",
                name: "Hair Thinning",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 74,
                productSubcategoryPrivateId: 18,
                id: "hair-masks",
                name: "Hair Masks",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 75,
                productSubcategoryPrivateId: 18,
                id: "hair-oil",
                name: "Hair Oil",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 76,
                productSubcategoryPrivateId: 18,
                id: "scalp-treatments",
                name: "Scalp Treatments",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 19,
            productCategoryPrivateId: 3,
            id: "styling",
            name: "Styling",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 78,
                productSubcategoryPrivateId: 19,
                id: "hairspray",
                name: "Hairspray",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 79,
                productSubcategoryPrivateId: 19,
                id: "wax-gel",
                name: "Wax & Gel",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 80,
                productSubcategoryPrivateId: 19,
                id: "styling-products",
                name: "Styling Products",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 20,
            productCategoryPrivateId: 3,
            id: "hair-tools-accessories",
            name: "Tools & Accessories",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 84,
                productSubcategoryPrivateId: 20,
                id: "hair-tools-accessories-gifts-start-kits",
                name: "Gifts & Starter Kits",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 81,
                productSubcategoryPrivateId: 20,
                id: "brushes-combs",
                name: "Brushes & Combs",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 82,
                productSubcategoryPrivateId: 20,
                id: "hot-tools",
                name: "Hot Tools",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 83,
                productSubcategoryPrivateId: 20,
                id: "hair-accessories",
                name: "Hair Accessories",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 21,
            productCategoryPrivateId: 3,
            id: "travel",
            name: "Travel",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 85,
                productSubcategoryPrivateId: 21,
                id: "hair-minis",
                name: "Hair Minis",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 86,
                productSubcategoryPrivateId: 21,
                id: "travel-tools-accessories",
                name: "Travel Tools & Accessories",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
        ],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 4,
        id: "accessories",
        name: "Accessories",
        description: null,
        isActive: 1,
        createdAt: "2020-07-24T09:03:55",
        createdBy: null,
        updatedAt: "2023-01-17T16:28:09",
        updatedBy: "zcObcGzHSemS04k48goQJg",
        productSubcategories: [
          {
            privateId: 22,
            productCategoryPrivateId: 4,
            id: "accessory",
            name: "Accessory",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: "2022-08-16T21:25:22",
            updatedBy: "jkJIdKehSiOrhK11dJi8Ug",
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 128,
                productSubcategoryPrivateId: 22,
                id: "abc,bcd",
                name: "abc,bcd",
                description: null,
                isActive: 1,
                createdAt: "2023-01-17T16:41:55",
                createdBy: "zcObcGzHSemS04k48goQJg",
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 23,
            productCategoryPrivateId: 4,
            id: "cotton",
            name: "Cotton",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: "2022-08-16T21:25:32",
            updatedBy: "jkJIdKehSiOrhK11dJi8Ug",
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 87,
                productSubcategoryPrivateId: 23,
                id: "cotton-pads",
                name: "Cotton Pads",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 88,
                productSubcategoryPrivateId: 23,
                id: "cotton-balls",
                name: "Cotton Balls",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 89,
                productSubcategoryPrivateId: 23,
                id: "cotton-swabs",
                name: "Cotton Swabs",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 48,
            productCategoryPrivateId: 4,
            id: "asub1",
            name: "Asub1",
            description: null,
            isActive: 0,
            createdAt: "2021-11-05T02:53:18",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-11-05T04:34:25",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 49,
            productCategoryPrivateId: 4,
            id: "best",
            name: "Best",
            description: null,
            isActive: 1,
            createdAt: "2021-11-05T04:36:19",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2022-08-16T21:25:39",
            updatedBy: "jkJIdKehSiOrhK11dJi8Ug",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 50,
            productCategoryPrivateId: 4,
            id: "dsdfs",
            name: "dSdfs",
            description: null,
            isActive: 0,
            createdAt: "2021-11-09T04:41:14",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-11-12T06:36:40",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 53,
            productCategoryPrivateId: 4,
            id: "new",
            name: "new",
            description: null,
            isActive: 0,
            createdAt: "2023-01-17T16:42:49",
            createdBy: "zcObcGzHSemS04k48goQJg",
            updatedAt: "2023-01-17T16:44:05",
            updatedBy: "zcObcGzHSemS04k48goQJg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 55,
            productCategoryPrivateId: 4,
            id: "a",
            name: "a",
            description: null,
            isActive: 0,
            createdAt: "2023-01-17T16:43:48",
            createdBy: "zcObcGzHSemS04k48goQJg",
            updatedAt: "2023-01-17T16:44:05",
            updatedBy: "zcObcGzHSemS04k48goQJg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 56,
            productCategoryPrivateId: 4,
            id: "b",
            name: "b",
            description: null,
            isActive: 0,
            createdAt: "2023-01-17T16:43:48",
            createdBy: "zcObcGzHSemS04k48goQJg",
            updatedAt: "2023-01-17T16:44:05",
            updatedBy: "zcObcGzHSemS04k48goQJg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
        ],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 5,
        id: "bath & body",
        name: "Bath & Body",
        description: null,
        isActive: 1,
        createdAt: "2020-07-24T09:03:55",
        createdBy: null,
        updatedAt: "2023-01-17T16:28:09",
        updatedBy: "zcObcGzHSemS04k48goQJg",
        productSubcategories: [
          {
            privateId: 24,
            productCategoryPrivateId: 5,
            id: "bath-shower",
            name: "Bath & Shower",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 90,
                productSubcategoryPrivateId: 24,
                id: "body-wash",
                name: "Body Wash",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 91,
                productSubcategoryPrivateId: 24,
                id: "body-scrubs-exfoliants",
                name: "Body Scrubs & Exfoliants",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 92,
                productSubcategoryPrivateId: 24,
                id: "bubble-bath",
                name: "Bubble Bath",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 93,
                productSubcategoryPrivateId: 24,
                id: "bath-salts-soaks",
                name: "Bath Salts/Soaks",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 25,
            productCategoryPrivateId: 5,
            id: "body-moisturizers",
            name: "Body Moisturizers",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 97,
                productSubcategoryPrivateId: 25,
                id: "body-oil",
                name: "Body Oil",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 98,
                productSubcategoryPrivateId: 25,
                id: "hand-cream",
                name: "Hand Cream",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 99,
                productSubcategoryPrivateId: 25,
                id: "foot-cream",
                name: "Foot Cream ",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 125,
                productSubcategoryPrivateId: 25,
                id: "dsss",
                name: "DSss",
                description: null,
                isActive: 0,
                createdAt: "2021-11-09T04:41:49",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: "2021-11-12T06:35:17",
                updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                productSubcategoryPrivate: null,
              },
              {
                privateId: 94,
                productSubcategoryPrivateId: 25,
                id: "body-cream",
                name: "Body Cream",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 95,
                productSubcategoryPrivateId: 25,
                id: "body-lotion",
                name: "Body Lotion",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 96,
                productSubcategoryPrivateId: 25,
                id: "body-serum",
                name: "Body Serum ",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 26,
            productCategoryPrivateId: 5,
            id: "special-care",
            name: "Special Care",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 103,
                productSubcategoryPrivateId: 26,
                id: "cellulite-stretch-marks",
                name: "Cellulite & Stretch Marks",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 104,
                productSubcategoryPrivateId: 26,
                id: "hand-foot-care",
                name: "Hand & Foot Care",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 117,
                productSubcategoryPrivateId: 26,
                id: "feminine-care",
                name: "Feminine Care",
                description: null,
                isActive: 1,
                createdAt: "2020-07-29T00:51:07",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 100,
                productSubcategoryPrivateId: 26,
                id: "hair-removal",
                name: "Hair Removal",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 101,
                productSubcategoryPrivateId: 26,
                id: "mom-baby",
                name: "Mom & Baby",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 102,
                productSubcategoryPrivateId: 26,
                id: "tanning",
                name: "Tanning",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 27,
            productCategoryPrivateId: 5,
            id: "bath-body-tools-accessories",
            name: "Tools & Accessories",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 105,
                productSubcategoryPrivateId: 27,
                id: "accessories",
                name: "Accessories",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 106,
                productSubcategoryPrivateId: 27,
                id: "tools-devices",
                name: "Tools & Devices",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 107,
                productSubcategoryPrivateId: 27,
                id: "bath-body-tools-accessories-gifts-start-kits",
                name: "Gifts & Starter Kits",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 28,
            productCategoryPrivateId: 5,
            id: "bath-body-travel",
            name: "Travel",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 109,
                productSubcategoryPrivateId: 28,
                id: "fragrance-minis",
                name: "Fragrance Minis",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 108,
                productSubcategoryPrivateId: 28,
                id: "body-minis",
                name: "Body Minis",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 29,
            productCategoryPrivateId: 5,
            id: "fragrances",
            name: "Fragrances ",
            description: null,
            isActive: 1,
            createdAt: "2020-07-24T09:03:55",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 116,
                productSubcategoryPrivateId: 29,
                id: "hair-fragrance",
                name: "Hair Fragrance",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 110,
                productSubcategoryPrivateId: 29,
                id: "perfume",
                name: "Perfume",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 111,
                productSubcategoryPrivateId: 29,
                id: "solid-perfume",
                name: "Solid Perfume",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 112,
                productSubcategoryPrivateId: 29,
                id: "body-mist",
                name: "Body Mist",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 113,
                productSubcategoryPrivateId: 29,
                id: "rollerballs",
                name: "Rollerballs",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 114,
                productSubcategoryPrivateId: 29,
                id: "cologne",
                name: "Cologne",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
              {
                privateId: 115,
                productSubcategoryPrivateId: 29,
                id: "deodorant",
                name: "Deodorant",
                description: null,
                isActive: 1,
                createdAt: "2020-07-24T09:03:55",
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
        ],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 6,
        id: "test one mbc",
        name: "Test one mbc",
        description: null,
        isActive: 0,
        createdAt: "2021-08-20T08:03:58",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-24T00:31:26",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [
          {
            privateId: 30,
            productCategoryPrivateId: 6,
            id: "sub test one",
            name: "sub test one",
            description: null,
            isActive: 0,
            createdAt: "2021-08-20T08:17:01",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
        ],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 7,
        id: "cate one24 12",
        name: "cate one24 12",
        description: null,
        isActive: 0,
        createdAt: "2021-08-23T06:28:14",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-24T00:31:26",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [
          {
            privateId: 31,
            productCategoryPrivateId: 7,
            id: "sub one",
            name: "sub one",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T06:48:53",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:21:05",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 118,
                productSubcategoryPrivateId: 31,
                id: "abc",
                name: "abc",
                description: null,
                isActive: 0,
                createdAt: "2021-08-23T08:10:25",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: "2021-08-23T23:56:18",
                updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                productSubcategoryPrivate: null,
              },
              {
                privateId: 119,
                productSubcategoryPrivateId: 31,
                id: "micro 11",
                name: "micro 11",
                description: null,
                isActive: 0,
                createdAt: "2021-08-24T00:00:35",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: "2021-08-24T00:20:19",
                updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                productSubcategoryPrivate: null,
              },
              {
                privateId: 120,
                productSubcategoryPrivateId: 31,
                id: "micro 22",
                name: "micro 22",
                description: null,
                isActive: 0,
                createdAt: "2021-08-24T00:00:35",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: "2021-08-24T00:20:19",
                updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 32,
            productCategoryPrivateId: 7,
            id: "sub two2",
            name: "sub two2",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T06:54:44",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:21:05",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 121,
                productSubcategoryPrivateId: 32,
                id: "micro245 67",
                name: "micro245 67",
                description: null,
                isActive: 0,
                createdAt: "2021-08-24T00:03:20",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: "2021-08-24T00:07:56",
                updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 33,
            productCategoryPrivateId: 7,
            id: "sub 2e",
            name: "sub 2e",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T06:56:26",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:21:05",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 34,
            productCategoryPrivateId: 7,
            id: "sub 2e 34",
            name: "sub 2e 34",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T06:58:11",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:21:05",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 35,
            productCategoryPrivateId: 7,
            id: "sube44",
            name: "sube44",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T07:26:24",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:30:29",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 36,
            productCategoryPrivateId: 7,
            id: "sub 5",
            name: "sub 5",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T07:31:13",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:30:29",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 37,
            productCategoryPrivateId: 7,
            id: "sub 167",
            name: "sub 167",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T07:46:56",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:30:29",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 38,
            productCategoryPrivateId: 7,
            id: "sub1311",
            name: "sub1311",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T07:54:17",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:20:39",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 39,
            productCategoryPrivateId: 7,
            id: "sub 31",
            name: "sub 31",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T07:54:34",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:20:39",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 40,
            productCategoryPrivateId: 7,
            id: "sub 32",
            name: "sub 32",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T07:54:34",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:20:39",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
          {
            privateId: 44,
            productCategoryPrivateId: 7,
            id: "sub331",
            name: "sub331",
            description: null,
            isActive: 0,
            createdAt: "2021-08-23T08:06:49",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-08-24T00:20:39",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
        ],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 8,
        id: "cate two",
        name: "cate two",
        description: null,
        isActive: 0,
        createdAt: "2021-08-23T06:28:14",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-23T06:47:32",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 9,
        id: "abc",
        name: "abc",
        description: null,
        isActive: 0,
        createdAt: "2021-08-24T00:41:00",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-24T00:41:13",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 10,
        id: "ds11",
        name: "ds11",
        description: null,
        isActive: 0,
        createdAt: "2021-08-24T00:41:00",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-24T00:41:13",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 11,
        id: "123",
        name: "123",
        description: null,
        isActive: 0,
        createdAt: "2021-08-24T00:45:23",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-24T00:46:09",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 12,
        id: "346q",
        name: "346q",
        description: null,
        isActive: 0,
        createdAt: "2021-08-24T00:45:23",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-24T00:46:09",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 13,
        id: "eee",
        name: "eee",
        description: null,
        isActive: 0,
        createdAt: "2021-08-24T00:49:20",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-24T00:49:37",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 14,
        id: "55",
        name: "55",
        description: null,
        isActive: 0,
        createdAt: "2021-08-24T00:49:20",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-24T00:49:37",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 15,
        id: "42qa",
        name: "42qa",
        description: null,
        isActive: 0,
        createdAt: "2021-08-24T00:51:10",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-08-24T00:51:23",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 16,
        id: "test",
        name: "Test",
        description: null,
        isActive: 0,
        createdAt: "2021-10-21T01:47:05",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-10-21T04:55:38",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 17,
        id: "atest1",
        name: "Atest1",
        description: null,
        isActive: 0,
        createdAt: "2021-11-05T02:52:47",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-11-05T04:28:56",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 18,
        id: "adsbcc",
        name: "aDSbcc",
        description: null,
        isActive: 0,
        createdAt: "2021-11-09T04:40:16",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-11-10T03:48:30",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 20,
        id: "dfsd",
        name: "dfsd",
        description: null,
        isActive: 0,
        createdAt: "2021-11-12T04:07:33",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-11-12T04:46:20",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [
          {
            privateId: 51,
            productCategoryPrivateId: 20,
            id: "2ewer",
            name: "2ewer",
            description: null,
            isActive: 0,
            createdAt: "2021-11-12T04:07:51",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: null,
            updatedBy: null,
            productCategoryPrivate: null,
            productMicroCategories: [
              {
                privateId: 126,
                productSubcategoryPrivateId: 51,
                id: "aaaa",
                name: "aaaa",
                description: null,
                isActive: 0,
                createdAt: "2021-11-12T04:08:03",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: "2021-11-12T04:08:18",
                updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                productSubcategoryPrivate: null,
              },
              {
                privateId: 127,
                productSubcategoryPrivateId: 51,
                id: "bbbb",
                name: "bbbb",
                description: null,
                isActive: 0,
                createdAt: "2021-11-12T04:08:03",
                createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
                updatedAt: null,
                updatedBy: null,
                productSubcategoryPrivate: null,
              },
            ],
          },
          {
            privateId: 52,
            productCategoryPrivateId: 20,
            id: "dgg",
            name: "dgg",
            description: null,
            isActive: 0,
            createdAt: "2021-11-12T04:07:51",
            createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            updatedAt: "2021-11-12T04:09:09",
            updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
            productCategoryPrivate: null,
            productMicroCategories: [],
          },
        ],
        trainingSteps: [],
        bestUsedFors: [],
      },
      {
        privateId: 21,
        id: "dsq",
        name: "dsq",
        description: null,
        isActive: 0,
        createdAt: "2021-11-12T04:07:33",
        createdBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        updatedAt: "2021-11-12T04:10:02",
        updatedBy: "ZyPGIO4fSgiRgQ23cpL9vg",
        productSubcategories: [],
        trainingSteps: [],
        bestUsedFors: [],
      },
    ]

    return new Promise((resolve) => setTimeout(() => resolve(data), 1200))
  },

  getMockSteps(categoryPrivateId) {
    const data = [
      {
        privateId: 1,
        id: "as-your-first-step",
        name: "As your first step",
        description: null,
        isActive: 1,
        categoryPrivateId: categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 2,
        id: "after-makeup-remover",
        name: "After makeup remover",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 3,
        id: "after-cleansing",
        name: "After cleansing",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 4,
        id: "before-toner",
        name: "Before toner",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 5,
        id: "after-toner",
        name: "After toner",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 6,
        id: "before-serum",
        name: "Before serum",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 7,
        id: "after-serum",
        name: "After serum",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 8,
        id: "before-moisturizer",
        name: "Before moisturizer",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 9,
        id: "after-moisturizer",
        name: "After moisturizer",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 10,
        id: "as-your-last-step",
        name: "As your last step",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-01-19T13:46:49",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
      {
        privateId: 11,
        id: "n/a",
        name: "N/A",
        description: null,
        isActive: 1,
        categoryPrivateId,
        createdAt: "2021-04-15T08:40:36",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        category: null,
      },
    ]
    return new Promise((resolve) => setTimeout(() => resolve(data), 1200))
  },
}
