<template>
  <div v-if="maskVisible" class="guest-layout-modal">
    <div
      v-if="maskVisible"
      class="guest-layout-modal__mask"
      @click="$store.commit('guest/handleMask', { visible: false })"
    ></div>
    <div
      :class="[
        'guest-layout-modal__login-modal',
        maskVisible
          ? 'guest-layout-modal__login-modal-show'
          : 'guest-layout-modal__login-modal-hide'
      ]"
    >
      <div
        class="guest-layout-modal__login-modal__close"
        @click="$store.commit('guest/handleMask', { visible: false })"
      ></div>
      <div class="guest-layout-modal__login-modal__title">{{ guest.modal.title }}</div>
      <div class="guest-layout-modal__login-modal__choice-character">
        <a @click="clickToBrandRegistration">{{ guest.modal.brands }}</a>
        <a @click="clickToRetailerRegistration">{{ guest.modal.retailer }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    maskVisible() {
      return this.$store.state.guest.maskVisible
    },
    guest() {
      return this.$t("GuestLayout")
    }
  },
  methods: {
    clickToRetailerRegistration() {
      this.$router.push({
        name: "retailerRegistration"
      })
      this.$store.commit("guest/handleMask", { visible: false })
    },
    clickToBrandRegistration() {
      this.$router.push({
        name: "BrandRegistrationMain"
      })
      this.$store.commit("guest/handleMask", { visible: false })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/shared/_fonts";

.guest-layout-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 29;

  &__login-modal {
    position: fixed;
    z-index: 31;
    top: 200px;
    left: calc(50% - 255px);
    width: 510px;
    height: 322px;
    background-color: #fff;
    border-radius: 4px;

    &__close {
      position: absolute;
      right: 20px;
      top: 19px;
      width: 20px;
      height: 20px;
      background: url(../../assets/img/agency/icon_close.png) no-repeat;
      cursor: pointer;
    }

    &__title {
      margin: 61px auto 0 auto;
      font-size: 34px;
      line-height: 50px;
      text-align: center;
      font-family: "Proxima Nova";
      color: #212b36;
      user-select: none;
    }

    &__choice-character {
      user-select: none;
      margin-top: 24px;
      display: flex;
      justify-content: center;

      > a:first-child {
        margin-right: 77px;
      }

      > a {
        font-family: "Playfair Display";
        font-weight: bold;
        font-size: 40px;
        line-height: 52px;
        color: #fff;
        text-shadow: 0 0 1px #12433d, 0 0 1px #12433d, 0 0 1px #12433d, 0 0 1px #12433d,
          0 0 1px #12433d, 0 0 1px #12433d, 0 0 1px #12433d, 0 0 1px #12433d, 0 0 1px #12433d;
        cursor: pointer;

        &:hover {
          color: #12433d;
          text-shadow: none;
        }
      }
    }
  }

  &__login-modal-show {
    display: block;
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.4s ease-out;
  }

  &__login-modal-hide {
    display: none;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.4s ease-out;
  }

  @keyframes show {
    0% {
      display: none;
      opacity: 0;
      transform: translateY(-100px);
    }

    1% {
      display: block;
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &__mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 30;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
