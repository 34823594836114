<template>
  <a-row v-if="from && toData" style="padding: 12px 20px;">
    <div class="admin-message-brand-create-new-modal__intro-brand">
      <div class="admin-message-brand-create-new-modal__intro-brand-title">
        {{ from ? from.name : "" }}
      </div>
      <div class="admin-message-brand-create-new-modal__intro-brand-subtitle">
        {{ `To: ${toData ? toData.name : ""}` }}
      </div>
      <div class="admin-message-brand-create-new-modal__intro-brand-divider"></div>
      <div class="message-input-content__content">
        <img
          class="admin-message-brand-create-new-modal__intro-brand-img"
          :src="
            from && from.bannerAsset && from.bannerAsset.url && from.bannerAsset.url.length > 0
              ? from.bannerAsset.url
              : ''
          "
        />
        <div class="admin-message-brand-create-new-modal__intro-brand-content">
          <span
            v-html="
              $t('Admins.AmdinMessage.introBrand', {
                brandName: from.name,
                country: countryAddress,
                tags: tagsValue,
                retailerName: toData.name,
                brandId: from.id,
                profileLink: 'https://landinginternational.com/retailer/brand/' + from.id,
                aboutUs: from.introduction,
                brandWebsite: 'http://' + from.website,
                retailerChannel: 'retailerChannel',
                heroProducts: heroProductNames
              })
            "
          >
          </span>
        </div>
      </div>
      <!-- <img
        class="admin-message-brand-create-new-modal__intro-brand-img"
        :src="
          from && from.bannerAsset && from.bannerAsset.url && from.bannerAsset.url.length > 0
            ? from.bannerAsset.url
            : ''
        "
      />
      <div class="admin-message-brand-create-new-modal__intro-brand-content">
        <span
          v-html="
            $t('Admins.AmdinMessage.introBrand', {
              brandName: from.name,
              country: countryAddress,
              tags: tagsValue,
              retailerName: toData.name,
              brandId: from.id,
              profileLink: 'https://landinginternational.com/retailer/brand/' + from.id,
              aboutUs: from.introduction,
              brandWebsite: 'http://' + from.website,
              retailerChannel: 'retailerChannel',
              heroProducts: heroProductNames
            })
          "
        >
        </span>
      </div> -->
    </div>
  </a-row>
</template>
<script>
export default {
  props: {
    from: { type: Object, default: () => undefined },
    toData: { type: Object, default: () => undefined }
  },
  computed: {
    countryAddress() {
      let data = this.$store.getters["brandAddress/headquarters"]
      let countryName = this.$store.getters["reference/countryName"](data.country.id)
      return [data.city, countryName].join(", ")
    },
    tagsValue() {
      let data = this.$store.getters["brand/brand"]
      console.log(data.curations)
      if (data && data.curations) {
        return data.curations.map(item => item.description).join(", ")
      }
      return ""
    },
    heroProductNames() {
      let data = this.$store.getters["brand/brand"]
      if (data && data.heroProducts && data.heroProducts.length <= 5) {
        let a = data.heroProducts.map(item => item.name).join(", ")
        return a
      } else {
        let msg = ""
        for (let i = 0; i < 5; i++) {
          msg += data.heroProducts[i].name
          if (i < 4) {
            msg += ", "
          }
        }
        return msg
      }
    }
  }
}
</script>
