<template>
  <div class="retailer-users-tab__main">
    <!--    <div class="retailer-training-user-Number">-->
    <!--      <div class="total-number total-number-users">-->
    <!--        <p class="retailer-user-title">Total Number of Users</p>-->
    <!--        <p class="retailer-user-number">{{ statisticInfo.sellerCount }}</p>-->
    <!--      </div>-->
    <!--      <div class="total-number total-number-session">-->
    <!--        <p class="retailer-user-title">Total Number of Sessions</p>-->
    <!--        <p class="retailer-user-number">{{ statisticInfo.sessionCount }}</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="retailer-training-search">
      <div class="search-style search-input">
        <p>Search</p>
        <div>
          <a-input-search
            v-model="inputUserSearch"
            placeholder=" Search Name"
            :loading="isLoadingSearch"
            @search="searchUsersInfo"
            @change="changeValue"
          />
        </div>
      </div>
      <div class="search-style search-resent">
        <!--        <p>Recent</p>-->
        <!--        <div>-->
        <!--          <a-tag v-for="(tag, index) in tagList" :key="index" closable @close="closeTagItem(tag)">-->
        <!--            {{ tag.name }}-->
        <!--          </a-tag>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="retailer-brand-training-table">
      <table class="training-table-style">
        <thead>
          <tr class="table-tr-header">
            <th>
              First
              <a-icon
                style="cursor: pointer"
                :type="isFirstSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('first')"
              ></a-icon>
            </th>
            <th>
              Last
              <a-icon
                style="cursor: pointer"
                :type="isLastSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('last')"
              ></a-icon>
            </th>
            <th>
              Store #
              <a-icon
                style="cursor: pointer"
                :type="isStoreSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('storeNumber')"
              ></a-icon>
            </th>
            <!--            <th style="width:109px;">-->
            <!--              Region-->
            <!--              <a-icon-->
            <!--                style="cursor: pointer"-->
            <!--                :type="isRegionSort ? 'caret-down' : 'caret-up'"-->
            <!--                class="icon-sort-style"-->
            <!--                @click="sortList('region')"-->
            <!--              ></a-icon>-->
            <!--            </th>-->
            <th>
              State
              <a-icon
                style="cursor: pointer"
                :type="isStateSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('state')"
              ></a-icon>
            </th>
            <th>
              Country
              <a-icon
                style="cursor: pointer"
                :type="isCountrySort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('country')"
              ></a-icon>
            </th>
            <th>
              City
              <a-icon
                style="cursor: pointer"
                :type="isCitySort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('city')"
              ></a-icon>
            </th>
            <th>
              Created
              <a-icon
                style="cursor: pointer"
                :type="isCreatedSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('created')"
              ></a-icon>
            </th>
            <th>
              Last Login
              <a-icon
                style="cursor: pointer"
                :type="isLastLoginSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('lastLogin')"
              ></a-icon>
            </th>
            <th>
              Sessions
              <a-icon
                style="cursor: pointer"
                :type="isSessionsSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('sessionCount')"
              ></a-icon>
            </th>
            <!--            <th style="width:136px;">Action</th>-->
          </tr>
        </thead>
        <div v-if="tableData && tableData.length === 0" class="no-table-data">
          <a-empty />
        </div>
        <tbody v-else>
          <tr v-for="item in tableData" :key="item.id">
            <td style="width:123px;" class="">{{ item.last }}</td>
            <td style="width:115px;" class="data-tab-style">{{ item.first }}</td>
            <td style="width:135px;" class="data-tab-style">{{ item.location.storeNumber }}</td>
            <!--            <td style="width:115px;" class="data-tab-style">{{ "&#45;&#45;" }}</td>-->
            <td style="width:115px;" class="data-tab-style">
              {{ Object.keys(item.location.state).length !== 0 ? item.location.state.id : "" }}
            </td>
            <td style="width:115px;" class="data-tab-style">
              {{ Object.keys(item.location.country).length !== 0 ? item.location.country.id : "" }}
            </td>
            <td style="width:115px;" class="data-tab-style">{{ item.location.city }}</td>
            <td class="name-tab-style">{{ item.createdAt.slice(0, 10) }}</td>
            <td style="width:115px;" class="data-tab-style">{{ item.lastLogin }}</td>
            <td style="width:115px;" class="data-tab-style">{{ item.sessionCount }}</td>
            <!--            <td style="width:136px" class="click-pro-action">-->
            <!--              <div class="editable-row-operations">-->
            <!--                <a-tooltip>-->
            <!--                  <template slot="title">-->
            <!--                    Suspend-->
            <!--                  </template>-->
            <!--                  <a-switch v-model="item.active" @change="handleViewItem(item)" />-->
            <!--                </a-tooltip>-->
            <!--              </div>-->
            <!--            </td>-->
          </tr>
        </tbody>
      </table>
      <div v-if="showPagination" class="table-pagination-style">
        <a-pagination
          v-model="paginationValue"
          class="inventoryPagination"
          :default-current="1"
          :default-page-size="currentNum"
          :total="statisticInfo.sellerCount"
          size="small"
          :show-quick-jumper="true"
          @change="handlePagination"
        ></a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { retailerUsersTag } from "@/utils/const"

export default {
  name: "RetailerUsersTab",
  data() {
    return {
      isLoadingSearch: false,
      inputUserSearch: "",
      tagList: [],
      tableData: [],
      statisticInfo: {},
      isFirstSort: false,
      isLastSort: false,
      isStoreSort: false,
      isRegionSort: false,
      isStateSort: false,
      isCountrySort: false,
      isCitySort: false,
      isCreatedSort: false,
      isLastLoginSort: false,
      isSessionsSort: false,
      currentNum: 12,
      paginationValue: 1,
      showPagination: true
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    }
  },
  async created() {
    const routerRetailerId = this.retailer.id ? this.retailer.id : this.$route.params.retailerId
    this.statisticInfo = await this.getStatistics(routerRetailerId)
    this.tagList = await this.getTagList(retailerUsersTag)
    const params = {
      _index: 0,
      _limit: this.currentNum
    }
    await this.fetchData(params)
  },
  methods: {
    ...mapActions({
      getTagList: "training/getSearchBrandTags",
      // getUsersInfo: "training/getUsersInfo",
      updateTagList: "training/updateBrandTags",
      getUserList: "seller/getSellerList",
      getStatistics: "seller/getStatisticsRetailer",
      getUserInfo: "user/fetchUser",
      getRetailerInfo: "retailer/fetchRetailer",
      updateSeller: "seller/updateASeller"
    }),
    async handlePagination(page) {
      this.paginationValue = page
      let params = {
        _index: page,
        _limit: this.currentNum
      }
      await this.fetchData(params)
    },
    async fetchData(param) {
      let list
      let params = {
        ...param,
        "retailer-id": this.retailer.id
      }
      if (this.inputUserSearch) {
        params = {
          ...params,
          name: this.inputUserSearch
        }
        try {
          list = await this.getUserList(params)
          this.showPagination = false
        } catch (e) {
          list = []
          this.showPagination = false
        }
      } else {
        list = await this.getUserList(params)
      }
      let arr = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].retailer.id) {
          // const info = await this.getRetailerInfo(list[i].retailer.id)
          list[i].retailerName = list[i].retailer.name
          // const memberInfo = info.members.filter(item => item.role.id === "member") || []
          list[i].first = list[i].user.familyName || "--"
          list[i].last = list[i].user.givenName || "--"
          list[i].email = list[i].user.email || "--"
        }
        list[i].lastLogin = list[i].updatedAt
          ? list[i].updatedAt.slice(0, 10)
          : list[i].createdAt.slice(0, 10)
        arr.push(list[i])
      }
      this.tableData = arr
    },
    async closeTagItem(item) {
      await this.updateTagList({ tagName: retailerUsersTag, tagItem: item.name, type: "delete" })
    },
    getBaseParam() {
      let param = {}
      if (this.inputUserSearch && this.inputUserSearch.length > 0) {
        param["name"] = this.inputUserSearch
      }
      return param
    },
    async changeValue() {
      if (!this.inputUserSearch) {
        let params = {
          _index: this.paginationValue,
          _limit: this.currentNum
        }
        await this.fetchData(params)
      }
    },
    async searchUsersInfo() {
      const param = this.getBaseParam()
      this.isLoadingSearch = true
      if (!this.inputUserSearch) {
        this.isLoadingSearch = false
        return
      }
      //
      const params = {
        ...param,
        _index: this.paginationValue,
        _limit: this.currentNum
      }
      await this.fetchData(params)
      // this.$store.dispatch("seller/getSellerList", param).then(() => {
      //     this.isLoading = false
      //   })
      //   .catch(err => {
      //     console.log(err)
      //     this.isLoading = false
      //   })
      this.tagList = await this.updateTagList({
        tagName: retailerUsersTag,
        tagItem: this.inputUserSearch
      })
      this.isLoadingSearch = false
    },
    async handleViewItem(item) {
      const sellerId = item.id
      const params = {
        ...item,
        _index: this.paginationValue,
        _limit: this.currentNum
        // active: false
      }
      await this.updateSeller({ sellerId, params })
      await this.fetchData()
    },
    async sortList(sortValue) {
      const info = this.formatSortValue(sortValue)
      this.handleName(info["_sort-by"], info.isAscend)
      // const sortInfo = this.formatSortValue(sortValue)
      // let params = {
      //   _index: 1,
      //   _limit: this.currentNum,
      //   ...sortInfo
      // }
      // await this.fetchData(params)
    },
    formatSortValue(value) {
      // this.initSort()
      if (value === "first") {
        this.isFirstSort = !this.isFirstSort
        this.isLastSort = false
        this.isStoreSort = false
        this.isRegionSort = false
        this.isStateSort = false
        this.isCountrySort = false
        this.isCitySort = false
        this.isCreatedSort = false
        this.isLastLoginSort = false
        this.isSessionsSort = false
        return {
          "_order-by": this.isFirstSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isFirstSort
        }
      }
      if (value === "last") {
        this.isLastSort = !this.isLastSort
        this.isFirstSort = false
        this.isStoreSort = false
        this.isRegionSort = false
        this.isStateSort = false
        this.isCountrySort = false
        this.isCitySort = false
        this.isCreatedSort = false
        this.isLastLoginSort = false
        this.isSessionsSort = false
        return {
          "_order-by": this.isLastSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isLastSort
        }
      }
      if (value === "storeNumber") {
        this.isStoreSort = !this.isStoreSort
        this.isFirstSort = false
        this.isLastSort = false
        this.isRegionSort = false
        this.isStateSort = false
        this.isCountrySort = false
        this.isCitySort = false
        this.isCreatedSort = false
        this.isLastLoginSort = false
        this.isSessionsSort = false
        return {
          "_order-by": this.isStoreSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isStoreSort
        }
      }
      if (value === "region") {
        this.isRegionSort = !this.isRegionSort
        this.isFirstSort = false
        this.isLastSort = false
        this.isStoreSort = false
        this.isStateSort = false
        this.isCountrySort = false
        this.isCitySort = false
        this.isCreatedSort = false
        this.isLastLoginSort = false
        this.isSessionsSort = false
        return {
          "_order-by": this.isRegionSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isRegionSort
        }
      }
      if (value === "state") {
        this.isStateSort = !this.isStateSort
        this.isFirstSort = false
        this.isLastSort = false
        this.isRegionSort = false
        this.isStoreSort = false
        this.isCountrySort = false
        this.isCitySort = false
        this.isCreatedSort = false
        this.isLastLoginSort = false
        this.isSessionsSort = false
        return {
          "_order-by": this.isStateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isStateSort
        }
      }
      if (value === "country") {
        this.isCountrySort = !this.isCountrySort
        this.isFirstSort = false
        this.isLastSort = false
        this.isRegionSort = false
        this.isStateSort = false
        this.isStoreSort = false
        this.isCitySort = false
        this.isCreatedSort = false
        this.isLastLoginSort = false
        this.isSessionsSort = false
        return {
          "_order-by": this.isCountrySort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isCountrySort
        }
      }
      if (value === "city") {
        this.isCitySort = !this.isCitySort
        this.isFirstSort = false
        this.isLastSort = false
        this.isRegionSort = false
        this.isStateSort = false
        this.isCountrySort = false
        this.isStoreSort = false
        this.isCreatedSort = false
        this.isLastLoginSort = false
        this.isSessionsSort = false
        return {
          "_order-by": this.isCitySort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isCitySort
        }
      }
      if (value === "created") {
        this.isCreatedSort = !this.isCreatedSort
        this.isFirstSort = false
        this.isLastSort = false
        this.isRegionSort = false
        this.isStateSort = false
        this.isCountrySort = false
        this.isCitySort = false
        this.isStoreSort = false
        this.isLastLoginSort = false
        this.isSessionsSort = false
        return {
          "_order-by": this.isCreatedSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isCreatedSort
        }
      }
      if (value === "lastLogin") {
        this.isLastLoginSort = !this.isLastLoginSort
        this.isFirstSort = false
        this.isLastSort = false
        this.isRegionSort = false
        this.isStateSort = false
        this.isCountrySort = false
        this.isCitySort = false
        this.isCreatedSort = false
        this.isStoreSort = false
        this.isSessionsSort = false
        return {
          "_order-by": this.isLastLoginSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isLastLoginSort
        }
      }
      if (value === "sessionCount") {
        this.isSessionsSort = !this.isSessionsSort
        this.isFirstSort = false
        this.isLastSort = false
        this.isRegionSort = false
        this.isStateSort = false
        this.isCountrySort = false
        this.isCitySort = false
        this.isCreatedSort = false
        this.isLastLoginSort = false
        this.isStoreSort = false
        return {
          "_order-by": this.isSessionsSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isSessionsSort
        }
      }
    },
    handleName(sorter, isAscend) {
      let index
      if (sorter === "first") {
        index = 1
      } else if (sorter === "region") {
        index = 3
      } else if (sorter === "storeNumber") {
        index = 4
      } else if (sorter === "last") {
        index = 2
      } else if (sorter === "state") {
        index = 5
      } else if (sorter === "city") {
        index = 6
      } else if (sorter === "lastLogin") {
        index = 7
      } else if (sorter === "sessionCount") {
        index = 8
      } else if (sorter === "country") {
        index = 9
      } else if (sorter === "created") {
        index = 10
      }
      this.sortData(index, isAscend)
    },
    sortData(index, ascend) {
      if (index == 1) {
        console.log(ascend)
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.first.localeCompare(b.first)
          } else {
            return b.first.localeCompare(a.first)
          }
        })
      } else if (index == 2) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.last.localeCompare(b.last)
          } else {
            return b.last.localeCompare(a.last)
          }
        })
      } else if (index == 4) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.location.storeNumber && b.location.storeNumber) {
              return a.location.storeNumber - b.location.storeNumber
            } else if (a.location.storeNumber && !b.location.storeNumber) {
              return a.location.storeNumber - 0
            } else if (!a.location.storeNumber && b.location.storeNumber) {
              return 0 - b.location.storeNumber
            }
          } else {
            if (a.location.storeNumber && b.location.storeNumber) {
              return b.location.storeNumber - a.location.storeNumber
            } else if (a.location.storeNumber && !b.location.storeNumber) {
              return b.location.storeNumber - 0
            } else if (!a.location.storeNumber && b.location.storeNumber) {
              return 0 - a.location.storeNumber
            }
          }
        })
      } else if (index == 5) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (
              a.location.state &&
              a.location.state.id &&
              b.location.state &&
              b.location.state.id
            ) {
              return a.location.state.id.localeCompare(b.location.state.id)
            }
            if (
              a.location.state &&
              a.location.state.id &&
              (!b.location.state || !b.location.state.id)
            ) {
              return a.location.state.id.localeCompare("")
            }
            if (
              (!a.location.state || !a.location.state.id) &&
              b.location.state &&
              b.location.state.id
            ) {
              return "".localeCompare(b.location.state.id)
            }
          } else {
            if (
              a.location.state &&
              a.location.state.id &&
              b.location.state &&
              b.location.state.id
            ) {
              return b.location.state.id.localeCompare(a.location.state.id)
            }
            if (
              b.location.state &&
              b.location.state.id &&
              (!a.location.state || !a.location.state.id)
            ) {
              return b.location.state.id.localeCompare("")
            }
            if (
              (!b.location.state || !b.location.state.id) &&
              a.location.state &&
              a.location.state.id
            ) {
              return "".localeCompare(a.location.state.id)
            }
          }
        })
      } else if (index == 6) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.location.city && b.location.city) {
              return a.location.city.localeCompare(b.location.city)
            }
            if (a.location.city && !b.location.city) {
              return a.location.city.localeCompare("")
            }
            if (a.location.city && b.location.city) {
              return "".localeCompare(b.location.city)
            }
          } else {
            if (a.location.city && b.location.city) {
              return b.location.city.localeCompare(a.location.city)
            }
            if (b.location.city && !a.location.city) {
              return b.location.city.localeCompare("")
            }
            if (!b.location.city && a.location.city) {
              return "".localeCompare(a.location.city)
            }
          }
        })
      } else if (index === 7) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.lastLogin && b.lastLogin) {
              return a.lastLogin.localeCompare(b.lastLogin)
            } else if (!a.lastLogin && b.lastLogin) {
              return "".localeCompare(b.lastLogin)
            } else if (a.lastLogin && !b.lastLogin) {
              return a.lastLogin.localeCompare("")
            }
          } else {
            if (a.lastLogin && b.lastLogin) {
              return b.lastLogin.localeCompare(a.lastLogin)
            } else if (!b.lastLogin && a.lastLogin) {
              return "".localeCompare(a.lastLogin)
            } else if (b.lastLogin && !a.lastLogin) {
              return b.lastLogin.localeCompare("")
            }
          }
        })
      } else if (index === 8) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.sessionCount - b.sessionCount
          } else {
            return b.sessionCount - a.sessionCount
          }
        })
      } else if (index === 9) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.location.country.id && b.location.country.id) {
              return a.location.country.id.localeCompare(b.location.country.id)
            }
            if (a.location.country.id && !b.location.country.id) {
              return a.location.country.id.localeCompare("")
            }
            if (a.location.country.id && b.location.country.id) {
              return "".localeCompare(b.location.country.id)
            }
          } else {
            if (a.location.country.id && b.location.country.id) {
              return b.location.country.id.localeCompare(a.location.country.id)
            }
            if (b.location.country.id && !a.location.country.id) {
              return b.location.city.localeCompare("")
            }
            if (!b.location.country.id && a.location.country.id) {
              return "".localeCompare(a.location.country.id)
            }
          }
        })
      } else if (index === 10) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.createdAt != null && b.createdAt != null) {
              return a.createdAt.localeCompare(b.createdAt)
            }
            if (a.createdAt != null && b.createdAt == null) {
              return a.createdAt.localeCompare(b.createdAt)
            }
            if (a.createdAt == null && b.createdAt != null) {
              return a.createdAt.localeCompare(b.createdAt)
            }
          } else {
            if (b.createdAt != null && a.createdAt != null) {
              return b.createdAt.localeCompare(a.createdAt)
            }
            if (b.createdAt != null && a.createdAt == null) {
              return b.createdAt.localeCompare(a.createdAt)
            }
            if (b.createdAt == null && a.createdAt != null) {
              return b.createdAt.localeCompare(a.createdAt)
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.retailer-users-tab__main {
  p {
    margin-bottom: 0;
  }
  .retailer-training-user-Number {
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 20px;
    .total-number {
      width: 400px;
      height: 120px;
      display: flex;
      flex-direction: column;
      border: 1px solid #595959;
      padding-top: 30px;
    }
    .retailer-user-number {
      font-size: 24px;
      color: #000000;
    }
  }
  .retailer-training-search {
    display: flex;
    .search-style {
      display: flex;
      flex-direction: column;
    }
    .search-input {
      width: 350px;
      margin-right: 15px;
      p {
        margin-bottom: 10px;
      }
    }
    .search-resent {
      width: 400px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .retailer-brand-training-table {
    //width: 952px;
    margin-top: 30px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      width: 100%;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }
    .training-table-style tr {
      width: 100%;
      position: relative;
    }
    .training-table-style th {
      border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 16px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
    }
    .training-table-style td {
      height: 56px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 16px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training-table-style tr,
    .training-table-style td.move:hover {
    }
    .training-table-style td.click-pro-action:hover {
      //cursor: pointer;
    }
    .name-tab-style {
      position: relative;
      span {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
    .data-tab-style {
      //position: relative;
    }
    .editable-row-operations-share {
      text-align: center;
      padding: 3px 4px;
      background-color: #4a8a5d;
      color: white;
      border-radius: 5px;
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .ant-tag {
    margin-bottom: 3px;
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
  .table-pagination-style {
    text-align: right;
    margin-bottom: 30px;
  }
}
</style>
