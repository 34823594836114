<template lang="pug">
b-navbar
  template(#brand="")
    b-navbar-item(href="https://www.landinginternational.com")
      //img(src="../assets/Logo/Logo.svg" alt="Logo")
      img(:src="logoSvg" alt="Logo")
  template(#start="")
    b-navbar-item.for-brand-and-retailer(href="https://www.landinginternational.com/brands")  For Brands
    b-navbar-item.for-brand-and-retailer(href="https://www.landinginternational.com/retailers")  For Retailers
  template(#end="")
    b-navbar-item(tag="div")
      .buttons
        my-button.button-style-sign-up(variant="is-text" label="LOG IN" @onClick="onLogin" font-size="14px")
        my-button.button-style-sign-up(variant="is-primary" label="SIGN UP" font-size="14px" @onClick="onSignUp")
</template>

<script>
import MyButton from "@/components/webflowComponent/Button"
import logoSvg from "@/assets/webflowAssets/Logo.svg"

export default {
  name: "Header",
  components: {
    MyButton
  },
  data() {
    return {
      logoSvg
    }
  },
  methods: {
    onLogin() {
      this.$router.push({
        path: "/login"
      })
    },
    onSignUp() {
      // this.$router.push({
      //   name: "BrandRegistrationMain"
      // })
      this.$router.push({
        //name: "BrandBeautyFluentRegistration"
        name: "RegistrationBrand"
      })
    },
    onLogout() {
      this.$emit("onLogout")
    },
    onCreateAccount() {
      this.$emit("onCreateAccount")
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: "Niveau Grotesk";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Niveau-Grotesk-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Mr Eaves Mod OT";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/MrEavesXLModOT-Reg.ttf") format("truetype");
}

span.body--small {
  font-family: Niveau Grotesk;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  color: #000000;
}

.body--normal {
  font-family: Mr Eaves Mod OT;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #1a1818;
  margin: 0;
}

.field label {
  text-transform: uppercase;
}

.field .label:not(:last-child) {
  margin-bottom: 20px;
}

.label {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
}

.heading--4 {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  color: #1a1818;
}

.navbar {
  height: 77px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
}

.navbar .navbar-brand {
  position: absolute;
  left: calc(50% - 87px);
  top: 5.5px;
}

.navbar .navbar-brand .navbar-item img {
  width: 150px;
  height: 50px;
  max-height: none;
}

.navbar .navbar-item {
  text-transform: uppercase;
}

.for-brand-and-retailer {
  font-family: "Niveau Grotesk";
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}

.button-style-sign-up {
  font-size: 14px;
}
</style>
