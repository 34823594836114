import httpclient from "@/api/http-client";
import Product from "@/models/Product";

export default {
  async getProductsByBrand(brandPrivateId) {
    return await httpclient
      .get(`/api/products/by-brand/${brandPrivateId}`)
      .then((res) => res.data.map((m) => new Product(m)));
  },
};
