<template>
  <div>
    <div class="subscribe-success__top-bg">
      <div class="subscribe-success__title">
        Thanks for Subscribing!
      </div>
      <div class="subscribe-success__content">
        Our team is reviewing your application and will be in touch in the next 24 to 48 hours.
        <br />
        You will not be charged until your subscription has been approved.
      </div>
      <div style="display: flex;align-items: center;justify-content: center;">
        <a-button class="subscribe-success__btn" @click="clickToTrainingTips"
          >Get Expert Training Tips</a-button
        >
      </div>
    </div>
    <div class="subscribe-success__bottom">
      <div style="text-transform: uppercase;">Questions or concerns? Contact Us</div>
      <div style="font-size: 16px;line-height: 22px; margin-top: 20px; margin-bottom: 80px;">
        <div>
          <span>Billing Support:</span
          ><span class="subscribe-success__mail"> payments@landingintl.com</span>
        </div>
        <div>
          <span>Subscription Support:</span
          ><span class="subscribe-success__mail"> support@landingintl.com</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clickToTrainingTips() {
      console.log("....clickToTrainingTips.......")
      window.open("https://blog.landinginternational.com/")
    }
  }
}
</script>
<style lang="scss" scoped>
.subscribe-success {
  &__top-bg {
    height: 397px;
    width: 100%;
    background: rgba(207, 229, 204, 0.4);
  }
  &__title {
    padding-top: 95px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    color: #1a1818;
  }
  &__content {
    margin-top: 15px;
    margin-bottom: 35px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    color: #1a1818;
  }
  &__btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #f15a42;
    border-radius: 50px;
    font-family: "Niveau Grotesk";
    padding: 17px 35px;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  &__bottom {
    padding-top: 60px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #1a1818;
  }
  &__mail {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
<style lang="scss">
.subscribe-success__btn.ant-btn > span {
  text-transform: uppercase;
}
.subscribe-success__btn.ant-btn:hover,
.subscribe-success__btn.ant-btn:focus,
.subscribe-success__btn.ant-btn:active,
.subscribe-success__btn.ant-btn.active {
  background: #f15a42;
  color: white;
  border: none;
}
</style>
