<template>
  <div>
    <BrandHeader />
    <router-view />
    <BrandFooter v-if="isShowFooter"/>
  </div>
</template>

<script>
import BrandHeader from "./brand/Header"
import BrandFooter from "./retailer/Footer"

export default {
  components: {
    BrandHeader,
    BrandFooter
  },
  data() {
    return {
      isShowFooter: this.$route.name !== "brandPitch"
    }
  },
  updated() {
    this.isShowFooter = this.$route.name !== "brandPitch"
  }
}
</script>

<style lang="sass" scoped>
@import "bulma"

.nav-link
  cursor: pointer
</style>
