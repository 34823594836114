<template>
    <div class="promo-code">
        <div class="col2"><span class="title">Promo Code</span><span class="col-spacer"></span><span class="remove-code"
                @click="removeCode">Remove Code</span></div>
        <div>
            <div class="promo-item" has-feedback :validate-status="promoValidateStatus">
                <a-input class="promo-code-input" v-model="promoCode" placeholder="PROMO CODE" v-decorator="[
                    'promoCode',
                    {
                        rules: [],
                    },
                ]"></a-input>
                <div v-if="promoCode === '' || promoCode !== appliedPromoCode" class="apply-coupon-code-check" @click="applyCode"><span>APPLY</span><a-icon
                        type="arrow-right" /></div>

                <div class="promo-code-status-msg">
                    <span class="error" v-if="promoValidateStatus === 'error'">This promo code is invalid</span>
                    <span class="valid-code" v-if="promoValidateStatus === 'success'">This promo code is valid</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            promoValidateStatus: "",
            appliedPromoCode: null,
            promoCode: ''
        }
    },
    methods: {
        promoCodeChange(value) {
            this.promoCode = value;
        },
        removeCode() {
            this.loading = false,
            this.promoCode = '';
            this.promoValidateStatus = "";
            this.appliedPromoCode = '';
            this.$emit("promoCoupon", null);
        },
        async applyCode() {
            const couponCode = (this.promoCode || "").trim();
            if (!couponCode) {
                return;
            }
            const params = {
                couponCode,
            };

            this.promoValidateStatus = "validating";
            this.loading = true;
            const coupon = await this.$store
                .dispatch("adminConsole/getCouponSignUpValue", params)
                .then(data => {
                    this.promoValidateStatus = "success";
                    this.appliedPromoCode = params.couponCode;
                    return data;
                })
                .finally(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.appliedPromoCode = null;
                    this.promoValidateStatus = "error";
                    return null;
                });
            this.$emit("promoCoupon", coupon);
        },


    }
}
</script>
<style lang="scss">
.promo-code {
    display: flex;
    flex-direction: column;

    .title {
        font-size: 14px;
        font-weight: bold;
        color: rgb(26, 24, 24);
        font-family: "Niveau Grotesk";
    }

    .remove-code {
        text-transform: uppercase;
        cursor: pointer;

        color: #4a4a4a;

        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }
    }

    .col-spacer {
        flex: 1 1 auto;
    }

    .col2 {
        display: flex;
        flex-direction: row;
    }

    input {
        border: 1px solid #f15a42;
        padding: 6px 24px 10px 24px;
        border-radius: 50px;
        font-size: 20px;
        color: #000;
        height: fit-content;
    }

    .apply-coupon-code-check {
        font-size: 13px;
        color: #f15a42;
        cursor: pointer;
        font-family: Niveau Grotesk;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;

        i {
            font-size: 12px;
            margin-left: 15px
        }
    }

    .promo-item {
        position: relative;
    }

    .promo-code-status-msg {
        margin-top: 20px;

        .error {
            color: #f5222d;
        }

        .valid-code {
            color: #60ceb3;
        }



    }
}
</style>