<template>
  <div ref="TrialDayDialog" class="cancel-subscription-dialog-new__main">
    <a-modal
      v-model="isCardModalActive"
      :footer="null"
      :destroy-on-close="true"
      :closable="false"
      width="520px"
      :mask-closable="false"
      :get-container="() => $refs.TrialDayDialog"
    >
      <div class="cancel-subscription-new">
        <img
          src="@/assets/webflowAssets/Modal/dots_pattern.svg"
          alt="dots_pattern"
          class="dots_pattern"
        />
        <img src="@/assets/webflowAssets/Modal/ellipse.svg" alt="ellipse" class="ellipse" />
        <p style="font-size: 25px">Thanks for logging in!</p>
        <p>
          Your <b>FREE</b> trial expires in <b>{{ dayTime }} days</b>.
        </p>
        <div class="cancel-dialog-btn-style">
          <span class="close-dialog-style" @click="doCancelSubscription"
            >Continue to Dashboard</span
          >
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "TrialDayDialog",
  data() {
    return {
      isCardModalActive: false,
      dayTime: 0
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    }
  },
  created() {
    if (this.$route.query.day) {
      this.dayTime = this.$route.query.day
    }
    if (this.dayTime === 21) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "trialBegin",
        userId: this.$store.getters["user/id"],
        firstName: this.$store.getters["user/givenName"],
        lastName: this.$store.getters["user/familyName"],
        emailAddress: this.$store.getters["user/email"],
        userType: "Brand"
      })
    }
    this.isCardModalActive = true
  },
  methods: {
    openTrialDialog(day) {
      this.isCardModalActive = true
      this.dayTime = day
    },
    closeModal() {
      this.isCardModalActive = false
    },
    async doCancelSubscription() {
      this.isCardModalActive = false
      this.$router.push({
        name: "brandMain"
      })
    }
  }
}
</script>

<style scoped lang="scss">
.cancel-subscription-dialog-new__main {
  .cancel-subscription-new {
    text-align: center;
    padding: 48px 0 40px 0;
    position: relative;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      font-family: "Mr Eaves Mod OT";
    }
    .dots_pattern {
      position: absolute;
      top: 0;
      left: 0;
    }
    .ellipse {
      position: absolute;
      bottom: -25px;
      right: -24px;
    }
  }
  .continue-cancel-style {
    color: #000000;
    cursor: pointer;
  }
  .close-dialog-style {
    padding: 17px 35px;
    color: white;
    background: #f15a42;
    font-size: 14px !important;
    font-weight: bold;
    border-radius: 100px;
    margin-left: 30px;
    cursor: pointer;
  }
  .cancel-dialog-btn-style {
    width: 100%;
    margin-top: 50px;
  }
  .footer-card-extra {
    margin-top: 60px;
  }
}
</style>
