<template>
  <div class="identity-card__main">
    <div class="card-title-img">
      <div id="bannerImage" class="header-img"></div>
      <div class="position-header">
        <img :src="headInfo.logoAsset.url || ProfileImage" />
      </div>
      <div
        v-if="isRetailerProfile"
        class="edit-profile-info"
        @click="editProfile"
      >
        <a-icon class="edit-icon-style" type="edit" />
      </div>
      <div
        v-else-if="isBrandProfile"
        class="edit-profile-info"
        @click="editProfile"
      >
        <a-icon class="edit-icon-style" type="edit" />
      </div>
    </div>
    <div class="card-content-text">
      <div class="card-tag-text">
        <a-tag v-for="(item, index) in tags" :key="index" class="card-tag">
          {{ !isBrandProfile ? item.name : item.description }}
        </a-tag>
        <div class="retailer-profile__icons">
          <div
            v-for="socialAccount in headInfo.socialAccounts"
            :key="socialAccount.platform.id"
            class="control"
          >
            <a
              class="is-inline-block"
              target="_blank"
              @click="goToSocialMedia(socialAccount)"
            >
              <img
                v-show="socialAccount.address"
                target="_blank"
                :src="
                  require(`@/assets/img/icon/${socialAccount.platform.id}-icon.svg`)
                "
                width="23"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="card-name">
        {{ headInfo.name }}
      </div>
      <div class="card-name-introduce">
        {{ headInfo.introduction }}
      </div>
      <div class="card-country">
        <span class="flag-margin-right">
          {{
            [headquarters.city, countryName(headquarters.country.id)].join(", ")
          }}
        </span>
        <span id="countryFlag"></span>
      </div>
      <div class="card-button">
        <a-button
          v-if="
            (!isBrand && role === 'brand') || (role === 'retailer' && isBrand)
          "
          class="visit-button"
          @click="goToWebsite"
        >
          {{ $t("Components.Brands.Profile.BrandProfile.visitButton") }}
          <a-icon type="global" />
        </a-button>
        <a-button
          v-if="!isBrand && role !== 'brand'"
          class="email-button"
          :class="[brandType === 'basic' ? '' : 'email-button-click']"
          @click="clickToShowCreateMsg"
        >
          {{ $t("Components.Brands.Profile.BrandProfile.messageButton") }}
          <a-icon type="mail" />
        </a-button>
      </div>
    </div>
    <create-new-message-modal ref="createNewMessage" />
    <BrandTableDialog ref="brandUpgradePlanDialog" :brand-type="brandType" />
    <warn-message-dialog ref="brandIntroBrandWaringRef" />
  </div>
</template>

<script>
import { Tag, Button, Icon } from "ant-design-vue";
import HeroImage from "@/assets/img/profile/HeroImage.png";
import ProfileImage from "@/assets/img/default-logo-image.png";
import CreateNewMessageModal from "../message/CreateNewMessageModal.vue";
import WarnMessageDialog from "@/components/message/WarnMessageDialog";
import BrandTableDialog from "@/components/brands/mainPage/components/BrandTableDialog";
export default {
  components: {
    "a-tag": Tag,
    "a-button": Button,
    "a-icon": Icon,
    CreateNewMessageModal,
    WarnMessageDialog,
    BrandTableDialog,
  },
  props: {
    headInfo: { type: Object, default: () => {} },
    headquarters: { type: Object, default: () => {} },
    tags: { type: Array, default: () => [] },
    isBrand: { type: Boolean, default: () => true },
    role: { type: String, default: () => "" },
  },
  data() {
    return {
      HeroImage,
      ProfileImage,
    };
  },
  computed: {
    isRetailerProfile() {
      return (
        this.$route.name === "retailerProfile" ||
        this.$route.name === "adminRetailerProfile"
      );
    },
    isBrandProfile() {
      return (
        this.$route.name === "brandProfile" ||
        this.$route.name === "adminBrandProfile"
      );
    },
    brand() {
      return this.$store.getters["brand/brand"];
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions.length > 0 &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      );
    },
  },
  mounted() {
    this.showBannerImage();
    this.showCountryFlag();
  },
  updated() {
    this.showBannerImage();
    this.showCountryFlag();
  },
  methods: {
    goToSocialMedia(socialAccount) {
      if (socialAccount.address) {
        window.open("http://" + socialAccount.address);
      }
    },
    goToWebsite() {
      if (this.headInfo && this.headInfo.website) {
        window.open("http://" + this.headInfo.website);
      }
    },
    showBannerImage() {
      let flag = document.getElementById("bannerImage");
      if (
        this.headInfo &&
        this.headInfo.bannerAsset &&
        this.headInfo.bannerAsset.url &&
        this.headInfo.bannerAsset.url.length > 0
      ) {
        flag.style[
          "background-image"
        ] = `url('${this.headInfo.bannerAsset.url}')`;
        flag.style["background-repeat"] = "no-repeat";
        flag.style["background-size"] = "100% 100%";
      } else {
        flag.style.background = "rgba(0, 0, 0, 0.1)";
      }
    },
    showCountryFlag() {
      if (this.headInfo.addresses) {
        let flag = document.getElementById("countryFlag");
        let name = this.countryName(this.headquarters.country.id);
        console.log(`hq : ${this.headquarters.country.id}, ${name}`);
        flag.setAttribute(
          "class",
          "flag-icon flag-icon-" + this.headquarters.country.id
        );
      }
    },
    countryName(id) {
      let data = this.$store.getters["reference/countryName"](id);
      return data;
    },
    editProfile() {
      this.$emit("showEditModal");
    },
    showUpdatePlanModal() {
      this.$refs["brandUpgradePlanDialog"].openDialog(true);
    },
    showWaringSendIntroBrandDialog() {
      let msg =
        "You have already sent a brand introduction to this retailer. We're waiting for their response to continue the conversation.";
      this.$refs["brandIntroBrandWaringRef"].openDialog(msg);
    },
    clickToShowCreateMsg() {
      if (this.brandType === "basic") {
        this.showUpdatePlanModal();
        return;
      }
      let params = {
        "receiver-type": "retailer",
        itemize: "send",
        "message-type-id": "Introduce Brand",
        "retailer-id": this.headInfo.id,
        "client-id": this.brand.id,
      };
      this.$store
        .dispatch("adminMessage/fetchSearchMessage", { params })
        .then((res) => {
          console.log(res);
          if (!res || res.total === 0) {
            // no records, show message dialog
            let retailer = { id: this.headInfo.id, name: this.headInfo.name };
            if (this.headInfo.follows) {
              retailer["follows"] = this.headInfo.follows;
            }
            this.$refs["createNewMessage"].openDialogFirst(retailer);
          } else {
            // this.$message.warning(
            //   "Already send Intro brand message to this retailer, can't send it again."
            // )
            this.showWaringSendIntroBrandDialog();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.identity-card__main {
  font-family: Proxima Nova;
  width: 100%;
  .card-title-img {
    width: 100%;
    height: 360px;
    border-radius: 5px;
    margin-bottom: 60px;
    position: relative;
    .header-img {
      border-radius: 5px;
      width: 100%;
      height: 0;
      padding-bottom: 39%;
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    .position-header {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      position: absolute;
      bottom: -48px;
      border: 1px solid #8c8c8c;
      left: 12px;
      z-index: 3;
      -webkit-box-shadow: 0 3px 1px 1px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .edit-profile-info {
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      border-radius: 50%;
      font-size: 18px;
      background: white;
      position: absolute;
      top: 17px;
      right: 16px;
      z-index: 3;
      .edit-icon-style {
        color: #4a8a5d;
      }
    }
    .edit-profile-info:hover {
      background: #4a8a5d;
      cursor: pointer;
      .edit-icon-style {
        color: white;
      }
    }
  }
  .card-content-text {
    width: 100%;
    .card-name {
      font-family: Poppins;
      font-weight: 600;
      font-size: 26px;
      line-height: 38px;
      color: #262626;
      height: 50px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    .card-name-introduce {
      font-family: Proxima Nova;
      width: 64%;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      color: #595959;
      word-wrap: break-word;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    .card-country {
      font-family: Proxima Nova;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      margin-top: 11px;
      margin-bottom: 20px;
    }
    .card-button {
      .visit-button {
        background: #4a8a5d;
        color: white;
        margin-right: 13px;
      }
      .email-button {
        background: #f5f5f5;
      }
      .email-button-click {
        background: #4a8a5d;
        color: white;
      }
    }
    .card-tag-text {
      position: relative;
      margin-bottom: 8px;
    }
    .retailer-profile__icons {
      width: 129px;
      display: flex;
      align-items: center;
      position: absolute;
      top: -50px;
      right: 0;
      direction: rtl;
      img {
        margin-left: 16px;
        margin-top: 20px;
      }
    }
  }
  .ant-tag {
    background: #f5f5f5 !important;
    border-radius: 2px !important;
    margin-right: 16px !important;
  }
  .flag-margin-right {
    margin-right: 10px;
  }
  .flag-icon {
    width: 24px !important;
  }
  .no-img-style {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
