<template>
  <div>
    <a-row>
      <a-col :span="12">
        <p class="retailer-settings__account-information_text">
          {{ $t("Retailers.RetailerSettings.personalInfo") }}
        </p>
        <p class="retailer-settings__account-information_label">
          {{ $t("Retailers.RetailerSettings.firstName") }}
        </p>
        <a-input
          v-model="$v.myAccount.firstName.$model"
          size="large"
          placeholder="Enter First Name"
          class="retailer-settings__input-pwd"
          :class="{ 'is-danger': $v.myAccount.firstName.$error }"
          @change="resetValidationFlags"
        ></a-input>
        <p v-if="$v.myAccount.firstName.$error" class="help is-danger">
          {{ $t("Retailers.RetailerSettings.Validations.required") }}
        </p>
        <p class="retailer-settings__account-information_label">
          {{ $t("Retailers.RetailerSettings.lastName") }}
        </p>
        <a-input
          v-model="$v.myAccount.lastName.$model"
          size="large"
          placeholder="Enter Last Name"
          class="retailer-settings__input-pwd"
          :class="{ 'is-danger': $v.myAccount.lastName.$error }"
          @change="resetValidationFlags"
        ></a-input>
        <p v-if="$v.myAccount.lastName.$error" class="help is-danger">
          {{ $t("Retailers.RetailerSettings.Validations.required") }}
        </p>
        <p class="retailer-settings__account-information_label">
          {{ $t("Retailers.RetailerSettings.phoneNumber") }}
        </p>
        <a-input
          v-model="$v.myAccount.phoneNumber.$model"
          size="large"
          placeholder="Phone Number"
          class="retailer-settings__input-pwd"
          :class="{ 'is-danger': $v.myAccount.phoneNumber.$error }"
          @change="resetValidationFlags"
        ></a-input>
        <p class="retailer-settings__account-information_label">
          {{ $t("Retailers.RetailerSettings.jobTitle") }}
        </p>
        <a-input
          v-model="$v.myAccount.jobTitle.$model"
          size="large"
          placeholder="Job Title"
          class="retailer-settings__input-pwd"
          :class="{ 'is-danger': $v.myAccount.jobTitle.$error }"
          autocomplete="new-password"
          @change="resetValidationFlags"
        ></a-input>
        <a-row v-show="myAccount.isSaveInProgress" class="is-marginless">
          <img src="@/assets/img/spinner.gif" alt="spinner" />
        </a-row>
        <a-row>
          <a-button id="savePersonInfo" :disabled="isPersonInfoSaveDisabled" @click="updateAccount">
            {{ $t("Retailers.RetailerSettings.save") }}
          </a-button>
        </a-row>
      </a-col>
      <a-col v-if="showEmailPreference" :span="12">
        <EmailPreferences></EmailPreferences>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <p class="retailer-settings__account-information_text">Password</p>
        <p class="retailer-settings__account-information_label">
          {{ $t("Retailers.RetailerSettings.oldPassword") }}
        </p>
        <a-input-pwd
          v-model="$v.password.oldPassword.$model"
          size="large"
          autocomplete="new-password"
          class="retailer-settings__input-pwd"
          :class="{ 'is-danger': $v.password.oldPassword.$error && password.isInvalidCredentials }"
          @change="resetPasswordValidationFlags"
        ></a-input-pwd>
        <p
          v-if="!$v.password.oldPassword.required && password.showValidations"
          class="help is-danger"
        >
          {{ $t("Retailers.RetailerSettings.Validations.required") }}
        </p>
        <p v-show="password.isInvalidCredentials" class="help is-danger">
          {{ $t("Retailers.RetailerSettings.Validations.IncorrectOldPassword") }}
        </p>
        <p class="retailer-settings__account-information_label">
          {{ $t("Retailers.RetailerSettings.newPassword") }}
        </p>
        <a-input-pwd
          v-model="$v.password.newPassword.$model"
          size="large"
          class="retailer-settings__input-pwd"
          :class="{
            'is-danger':
              ($v.password.newPassword.$error || $v.password.passwordConfirmation.$error) &&
              password.showValidations
          }"
          @change="resetPasswordValidationFlags"
        ></a-input-pwd>
        <p
          v-if="!$v.password.newPassword.required && password.showValidations"
          class="help is-danger"
        >
          {{ $t("Retailers.RetailerSettings.Validations.required") }}
        </p>
        <p
          v-if="!$v.password.newPassword.minLength && password.showValidations"
          class="help is-danger"
        >
          {{ $t("Retailers.RetailerSettings.Validations.mustBe10Characters") }}
        </p>
        <p
          v-if="!$v.password.passwordConfirmation.sameAsPassword && password.showValidations"
          class="help is-danger"
        >
          {{ $t("Retailers.RetailerSettings.Validations.passwordsMustMatch") }}
        </p>
        <p v-if="password.upperCaseAndNumberValidation" class="help is-danger">
          {{ $t("Retailers.RetailerSettings.Validations.mustContainUpperCaseAndNumber") }}
        </p>
        <p class="retailer-settings__account-information_label">
          {{ $t("Retailers.RetailerSettings.confirmNewPassword") }}
        </p>
        <a-input-pwd
          v-model="$v.password.passwordConfirmation.$model"
          size="large"
          class="retailer-settings__input-pwd"
          :class="{
            'is-danger': $v.password.passwordConfirmation.$error && password.showValidations
          }"
          @change="resetPasswordValidationFlags"
        ></a-input-pwd>
        <p
          v-if="!$v.password.passwordConfirmation.required && password.showValidations"
          class="help is-danger"
        >
          {{ $t("Retailers.RetailerSettings.Validations.required") }}
        </p>
        <a-row v-show="password.isRestInProgress" class="is-marginless">
          <img src="@/assets/img/spinner.gif" alt="spinner" />
        </a-row>
        <a-row>
          <a-button id="changePwd" :disabled="isPwdDisabled" @click="setPassword">{{
            $t("Retailers.RetailerSettings.save")
          }}</a-button>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Input, Row, Col, Button } from "ant-design-vue"
import { required, sameAs, minLength } from "vuelidate/lib/validators"
import EmailPreferences from "./EmailPreferences"

export default {
  components: {
    "a-input": Input,
    "a-input-pwd": Input.Password,
    "a-row": Row,
    "a-col": Col,
    "a-button": Button,
    EmailPreferences
  },
  validations: {
    myAccount: {
      firstName: { required },
      lastName: { required },
      phoneNumber: { minLength: 0 },
      jobTitle: { minLength: 0 }
    },
    password: {
      oldPassword: { required },
      newPassword: { required, minLength: minLength(10) },
      passwordConfirmation: { required, sameAsPassword: sameAs("newPassword") }
    }
  },
  data() {
    return {
      storePath: "user",
      isAdmin: false,
      myAccount: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        jobTitle: "",
        email: "",
        isSaveInProgress: false,
        isSaveSuccessfull: false
      },
      password: {
        oldPasswordType: "password",
        newPasswordType: "password",
        confirmNewPasswordType: "password",
        oldPassword: "",
        newPassword: "",
        passwordConfirmation: "",
        showValidations: false,
        isInvalidCredentials: false,
        isResetSuccessful: false,
        isRestInProgress: false,
        upperCaseAndNumberValidation: false,
        email: ""
      },
      showEmailPreference: false
    }
  },
  computed: {
    email() {
      return this.$store.getters["user/email"]
    },
    isPersonInfoSaveDisabled() {
      if (this.myAccount.isSaveSuccessfull) {
        return true
      } else {
        return !this.$v.myAccount.$anyDirty
      }
    },
    isPwdDisabled() {
      if (this.password.isResetSuccessful) {
        return true
      } else
        return !(
          this.$v.password.oldPassword.$dirty &&
          this.$v.password.newPassword.$dirty &&
          this.$v.password.passwordConfirmation.$dirty
        )
    }
  },
  created() {
    this.isAdmin = this.$store.getters["user/isAdmin"]
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      if (this.isAdmin) {
        let brandInfo = this.$store.getters["brand/brand"]
        if (!(brandInfo && brandInfo.id)) {
          await this.$store.dispatch("brand/getBrand", this.$route.params.brandId)
          brandInfo = this.$store.getters["brand/brand"]
        }
        this.storePath = "clientUser"
        let owner = brandInfo.members.filter(item => item.role.id === "owner")
        if (owner && owner.length > 0) {
          this.myAccount.userId = owner[0].user.id
          this.myAccount.firstName = owner[0].user.givenName
          this.myAccount.lastName = owner[0].user.familyName
          this.myAccount.phoneNumber = owner[0].user.phoneNumber
          this.myAccount.jobTitle = owner[0].user.jobTitle ? owner[0].user.jobTitle : ""
          this.myAccount.email = owner[0].user.email
        }
      } else {
        this.myAccount.userId = this.$store.getters["user/id"]
        this.storePath = "user"
        await this.$store.dispatch(`${this.storePath}/fetchUser`, this.myAccount.userId)
        this.myAccount.firstName = this.$store.getters[`${this.storePath}/givenName`]
        this.myAccount.lastName = this.$store.getters[`${this.storePath}/familyName`]
        this.myAccount.phoneNumber = this.$store.getters[`${this.storePath}/phoneNumber`]
        this.myAccount.jobTitle = this.$store.getters[`${this.storePath}/jobTitle`]
        this.myAccount.email = this.$store.getters[`${this.storePath}/memberEmail`]
      }
      console.log(this.myAccount)
    },
    userRootObject() {
      return {
        id: this.myAccount.userId,
        givenName: this.myAccount.firstName,
        familyName: this.myAccount.lastName,
        phoneNumber: this.myAccount.phoneNumber,
        email: this.myAccount.email,
        jobTitle: this.myAccount.jobTitle
      }
    },
    async updateAccount() {
      this.$v.$touch()
      if (this.$v.myAccount.$invalid) {
        return
      }
      this.myAccount.isSaveInProgress = true
      try {
        await this.$store.dispatch(`${this.storePath}/updateUser`, this.userRootObject())
        this.$message.success(this.$t("Retailers.RetailerSettings.updateAccountSuccess"))
      } catch (error) {
        console.error(error)
      }
      try {
        await this.$store.dispatch(`${this.storePath}/fetchUser`, this.myAccount.userId)
        this.myAccount.isSaveSuccessfull = true
      } catch (error) {
        this.myAccount.isSaveSuccessfull = false
        console.error(error)
      }
      this.myAccount.isSaveInProgress = false
    },
    passwordCheck() {
      if (
        this.password.newPassword.search(/[A-Z]/) == -1 ||
        this.password.newPassword.search(/[0-9]/) == -1
      ) {
        return true
      }
    },
    async setPassword() {
      this.$v.$touch()
      if (this.$v.password.$invalid) {
        this.password.showValidations = true
        return
      }
      if (this.passwordCheck()) {
        this.password.upperCaseAndNumberValidation = true
        return
      }
      this.password.isRestInProgress = true
      if (this.isAdmin) {
        this.password.email = this.myAccount.email
      } else {
        this.password.email = this.$store.getters["user/email"]
      }
      const email = this.password.email
      const password = this.password.oldPassword
      this.$store
        .dispatch(`${this.storePath}/signIn`, { email, password })
        .then(() => {
          this.updatePassword()
        })
        .catch(err => {
          console.error(err)
          this.password.isInvalidCredentials = true
        })
      this.password.isRestInProgress = false
    },
    updatePassword() {
      this.$store
        .dispatch(`${this.storePath}/updatePassword`, {
          password: this.password.passwordConfirmation
        })
        .then(() => {
          this.password.isResetSuccessful = true
          this.$message.success(this.$t("Retailers.RetailerSettings.updatePwdSuccess"))
          this.password.oldPassword = ""
          this.password.newPassword = ""
          this.password.passwordConfirmation = ""
          this.afterUpdatePassword()
        })
        .catch(err => {
          console.error(err)
          this.password.isResetSuccessful = false
          this.afterUpdatePassword()
        })
    },
    afterUpdatePassword() {
      if (this.isAdmin) {
        this.$store
          .dispatch("clientUser/signOutWithoutReload")
          .then(() => {
            this.signIn()
          })
          .catch(err => {
            console.error(err)
            this.password.isResetSuccessful = false
          })
      }
    },
    async signIn() {
      await this.$store.dispatch("user/signInWithoutInput")
    },
    resetPasswordValidationFlags() {
      this.password.showValidations = false
      this.password.isInvalidCredentials = false
      this.password.isResetSuccessful = false
      this.password.upperCaseAndNumberValidation = false
    },
    resetValidationFlags() {
      this.myAccount.isSaveSuccessfull = false
    }
  }
}
</script>

<style lang="sass">
.retailer-settings
  padding-left: 80px !important
  padding-bottom: 300px
  padding-top: 0px
  &__title-style
    font-weight: bold
    font-size: 14px
    color: #333333

  &__sub-menuItem-link
    margin-left: 21px
    color: #7F7F7F
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 24px

  &__input-pwd
    box-sizing: border-box
    width: 256px !important
    border: 1px solid #D9D9D9 !important
    border-radius: 2px !important
    margin-bottom: 12px !important
    padding-left: 10px !important

  &__input-right
    box-sizing: border-box
    height: 40px
    width: 320px
    border: 1px solid #E5E5E5
    border-radius: 2px

  &__form-section

  &__account-information
    margin-top: 43px
    .margin-style
      margin-right: 97px

    &_edit-column
      display: grid

    &_label
      color: #262626 !important
      display: block
      font-size: 14px !important
      font-weight: normal !important
      line-height: 20px !important
      margin-bottom: 0px !important

    &_label-right
        color: #363636
        display: block
        font-size: 14px
        font-weight: 400

    &_job-title
        margin-top: -28px

    &_text
      color: #000000
      font-size: 18px
      letter-spacing: 0.25px
      line-height: 24px

    &_text-right
      margin-bottom: 32px
      color: #000000
      font-size: 14px
      letter-spacing: 0.25px
      line-height: 18px
      margin-left: 22px
      margin-top: 22px

    &_edit-button
      color: #161616
      font-size: 16px
      font-weight: 300
      letter-spacing: 0.16px
      line-height: 18px
      text-decoration: underline
      cursor: pointer

    &_save-button
      height: 36px
      width: 83px
      color: #292929
      font-weight: 600
      font-size: 14px
      margin-top: 21px
    .is-black
      background-color: #292929

  &__link-my-account
    color: #000000
    font-size: 16px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__link-retailer-account
    color: #7F7F7F
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px
    margin-top: 26px

  &__retailer-account
    padding: 18px
    margin-left: 20px

#savePersonInfo
  color: #FFFFFF
  background: #12433D
  border-radius: 2px
  padding: 5px 16px
  font-weight: normal
  font-size: 14px
  line-height: 20px
  margin-top: 12px
  margin-bottom: 24px
#changePwd
  color: #FFFFFF
  background: #12433D
  border-radius: 2px
  padding: 5px 16px
  font-weight: normal
  font-size: 14px
  line-height: 20px
  margin-top: 12px
  margin-bottom: 24px
</style>
