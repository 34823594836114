<template>
  <a-modal
    v-model="showCreateMessage"
    :width="modalWidth"
    :footer="null"
    :title="null"
    :mask="!isSmallStatus"
    :closable="false"
    :body-style="bodyStyle"
    :mask-closable="false"
    :dialog-style="isSmallStatus ? { bottom: '100px', right: '-364px' } : { top: '10px' }"
    dialog-class="admin-message-brand-create-new-modal"
    @cancel="hideDialog"
  >
    <div :class="{ 'admin-message-brand-create-new-modal__outer': !isSmallStatus }">
      <div :style="!isSmallStatus ? 'width: 952px;' : 'width: 100%;'" style="margin: 0 auto;">
        <a-row v-if="!isSmallStatus" style="margin-bottom: 20px;">
          <a-col :span="12" class="admin-message-brand-create-new-modal__title">
            Messaging
          </a-col>
          <a-col :span="12" style="text-align: right;">
            <a-icon
              type="shrink"
              style="margin-right:12px;"
              class="admin-message-brand-create-new-modal__large-icon"
              @click="clickToSwitch"
            />
            <a-icon
              type="close"
              class="admin-message-brand-create-new-modal__large-icon"
              @click="hideDialog"
            />
          </a-col>
        </a-row>
        <div class="admin-message-brand-create-new-modal__box-content">
          <a-row
            :style="
              isSmallStatus
                ? 'padding-left: 14px; padding-right: 10px; height: 42px; padding-top: 6px; padding-bottom: 6px;'
                : 'padding-left: 30px; padding-left: 20px; height: 40px; padding-top: 6px; padding-bottom: 6px;'
            "
            class="admin-message-brand-create-new-modal__choose-model-layer"
          >
            <a-col
              :span="1"
              :style="isSmallStatus ? 'width: 255px;' : 'width: 702px;'"
              class="admin-message-brand-create-new-modal__subject"
            >
              To:
            </a-col>
            <a-col :span="18">
              <!-- <multi-select
                v-model="toValue"
                :options="dataSource"
                :multiple="true"
                label="id"
                track-by="id"
                :close-on-select="true"
                :custom-label="tagNameById"
                open-direction="bottom"
                @select="onSelectToTarget"
              /> -->
              <a-select
                v-model="toValue"
                show-search
                :class="
                  isToError
                    ? 'admin-message-brand-create-new-modal__toTarget admin-message-brand-to-error'
                    : 'admin-message-brand-create-new-modal__toTarget'
                "
                :filter-option="false"
                style="width: 200px"
                @search="handleSearch"
                @change="onSelectToTarget"
              >
                <a-select-option v-for="item in dataSource" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <!--              <div v-else style="margin-top: 4px;">{{ retailer ? retailer.name : "" }}</div>-->
            </a-col>
            <div v-if="isSmallStatus" style="float: right; display: inline-block; margin-top: 4px;">
              <a-icon
                type="arrows-alt"
                style="margin-right:8px;"
                class="admin-message-brand-create-new-modal__large-icon"
                @click="clickToSwitch"
              />
              <a-icon
                type="close"
                class="admin-message-brand-create-new-modal__large-icon"
                @click="hideDialog"
              />
            </div>
          </a-row>
          <a-row
            :style="
              isSmallStatus
                ? 'padding-left: 14px; padding-right: 10px; height: 42px; padding-top: 6px; padding-bottom: 6px;'
                : 'padding-left: 30px; padding-left: 20px; height: 40px; padding-top: 6px; padding-bottom: 6px;'
            "
            class="admin-message-brand-create-new-modal__choose-model-layer"
          >
            <span
              :style="isSmallStatus ? 'width: 317px;' : 'width: 702px;'"
              class="admin-message-brand-create-new-modal__subject"
            >
              Subject:
              <a-input
                v-model="subjectText"
                style="width: 160px;"
                :style="inputError ? 'border: 1px solid red' : ''"
                size="small"
              />
              <span style="color: red">
                {{ inputError }}
              </span>
            </span>
            <a-select
              v-model="selectCategory"
              style="width: 190px;right: 20px"
              dropdown-class-name="admin-message-brand-create-new-modal__select-dropdown"
              @change="handleChange"
            >
              <a-select-option v-for="item in selectOption" :key="item.id" :value="item.id">
                {{ item.description }}
              </a-select-option>
            </a-select>
            <!--            <div v-if="isSmallStatus" style="float: right; display: inline-block; margin-top: 4px;">-->
            <!--              <a-icon-->
            <!--                type="arrows-alt"-->
            <!--                style="margin-right:8px;"-->
            <!--                class="admin-message-brand-create-new-modal__large-icon"-->
            <!--                @click="clickToSwitch"-->
            <!--              />-->
            <!--              <a-icon-->
            <!--                type="close"-->
            <!--                class="admin-message-brand-create-new-modal__large-icon"-->
            <!--                @click="hideDialog"-->
            <!--              />-->
            <!--            </div>-->
          </a-row>
          <a-row
            class="admin-message-brand-create-new-modal__choose-model-layer"
            :style="isSmallStatus ? 'height: 270px;' : 'height: 83px;'"
          >
          </a-row>
          <a-row
            class="admin-message-brand-create-new-modal__choose-model-layer"
            :style="isSmallStatus ? 'min-height: 138px;' : 'min-height: 165px;'"
          >
            <message-input-component
              :receivers-data="brands"
              :from="retailer"
              :generate-content="contentType"
              @send="sendMessage"
              @sendDraft="sendDraft"
              @hideDialog="hideDialog"
            />
          </a-row>
        </div>
      </div>
      <warn-message-dialog ref="warningDialog" />
    </div>
  </a-modal>
</template>

<script>
import MessageInputComponent from "./MessageInputComponent.vue"
import WarnMessageDialog from "./WarnMessageDialog.vue"
// import {checkBrandRetailerConnection} from "@/utils/validate"

export default {
  components: {
    MessageInputComponent,
    WarnMessageDialog
  },
  data() {
    return {
      messageData: undefined,
      isSmallStatus: true,
      modalWidth: "561px",
      showCreateMessage: false,
      bodyStyle: {
        padding: "0px"
      },
      subjectText: "",
      inputError: "",
      selectCategory: "Category",
      selectedCategoryData: undefined,
      content: "",
      contentType: -1,
      toValue: undefined,
      dataSource: [],
      isToError: false
    }
  },
  computed: {
    selectOption() {
      let data = this.$store.getters["reference/messageCategory"]
      if (data && data.length > 0) {
        data = data.filter(item => parseInt(item.id) < 6)
      }
      return data
    },
    brands() {
      let data = this.$store.getters["brand/brands"]
      if (!data) {
        data = []
      }
      // if (!(data.length > 0 && data[0].id === "*")) {
      //   data.unshift({ id: "*", name: "Landing" })
      // }
      return data
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    originReceiverList() {
      let data = this.$store.getters["brand/brands"]
      if (!data) {
        data = []
      }
      if (!(data.length > 0 && data[0].id === "*")) {
        data.unshift({ id: "*", name: "Landing" })
      }
      return data
    }
  },
  // watch: {
  //   originReceiverList() {
  //     this.dataSource = this.originReceiverList.slice(0)
  //     console.log("......change...", this.originReceiverList)
  //   }
  // },
  // created() {
  //   console.log(".....retailer compose... created..")
  //   //this.getCategoryData()
  //   //this.getBrandName()
  // },
  // updated() {
  //   console.log("....updated.....")
  // },
  methods: {
    onSearch(searchText) {
      this.dataSource = !searchText ? [] : [searchText, searchText.repeat(2), searchText.repeat(3)]
    },
    // onSelect(value) {
    //   console.log("onSelect", value)
    // },
    // onChange(value) {
    //   console.log("onChange", value)
    // },
    hideDialog() {
      this.showCreateMessage = false
      this.messageData = undefined
      this.toValue = undefined
      this.subjectText = ""
      this.inputError = ""
      this.selectCategory = "Category"
      this.content = ""
      this.contentType = -1
    },
    clickToSwitch() {
      if (this.isSmallStatus) {
        this.modalWidth = "1020px"
        this.isSmallStatus = false
      } else {
        this.modalWidth = "561px"
        this.isSmallStatus = true
      }
    },
    openDialog() {
      this.showCreateMessage = true
      this.dataSource = this.originReceiverList.slice(0)
    },
    handleChange(value) {
      console.log(value)
      console.log(this.selectCategory)
      this.selectedCategoryData = this.selectOption.find(item => item.id === this.selectCategory)
      if (this.selectCategory === "4") {
        // choose the order
        let msg = this.$t("Retailers.RetailerMessage.composeOrderMessageWarning")
        this.showWarningMessage(msg)
        this.contentType = 1
      } else {
        this.contentType = 2
      }
    },
    handleSearch(value) {
      if (!value || value.trim().length === 0) {
        this.dataSource = this.originReceiverList.slice(0)
      } else {
        let result = this.originReceiverList.filter(
          item => item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
        this.dataSource = []
        if (result && result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            this.$set(this.dataSource, i, result[i])
          }
        }
      }
    },
    onSelectToTarget(data) {
      console.log(data)
      // console.log(this.toValue)
      // this.retailer = this.dataSource.find(item => item.id === data)
      // if (data !== "*") {
      //   console.log(this.retailer)
      //   this.isToError = !checkBrandRetailerConnection(this.brand, this.retailer)
      // } else {
      //   this.isToError = false
      // }
    },
    showWarningMessage(msg) {
      this.$refs["warningDialog"].openDialog(msg)
    },

    // contentChange() {
    //   console.log("...dddd....")
    // },
    // getBrandName() {
    //   this.$store
    //     .dispatch("brand/fetchAllBrands", { params: { fields: "id, name, shortName,follows" } })
    //     .then(() => {
    //       this.brands = this.$store.getters["brand/brands"]
    //     })
    // },
    sendDraft(param, fileList, brandIds) {
      this.sendMessage(param, fileList, brandIds, true)
    },
    sendMessage(param, fileList, brandId, isDraft) {
      let brandIds = brandId ? brandId : this.toValue
      if (!brandIds) {
        this.$message.error("Please choose who To")
        return
      }
      if (!this.subjectText) {
        this.inputError = "mandatory field"
        return
      }
      let filesParam = []
      // let receiversParam = []
      if (fileList && fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          filesParam.push({
            privateId: i + 1,
            fileAsset: {
              contentType: fileList[i].contentType,
              id: fileList[i].id,
              url: fileList[i].url,
              ...fileList[i]
            }
          })
        }
      }
      // if (brandIds) {
      //   // for (let i = 0; i < brandIds.length; i++) {
      //   //   receiversParam.push({
      //   //     privateId: i + 1,
      //   //     receiverClientId: brandIds,
      //   //     isRead: false,
      //   //     isActive: true,
      //   //     archive: false
      //   //   })
      //   // }
      // }
      let sendParam = {
        senderClientId: this.retailer.id,
        category: {
          id: this.selectedCategoryData.description
        },
        subject: this.subjectText,
        message: param,
        archive: false,
        drafts: isDraft ? isDraft : false,
        isActive: true,
        messageReceivers: [
          {
            privateId: 1,
            receiverClientId: brandIds,
            isRead: false,
            isActive: true,
            archive: false
          }
        ]
      }
      if (filesParam && filesParam.length > 0) {
        sendParam["messageFiles"] = filesParam
      }
      this.$store
        .dispatch("adminMessage/createMessage", sendParam)
        .then(() => {
          this.hideDialog()
          this.$emit("getMessageData")
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-message-brand-create-new-modal {
  &__outer {
    padding-top: 40px;
    padding-bottom: 47px;
    background-color: #fff;
    height: 100%;
  }
  &__title {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    color: #262626;
  }
  &__large-icon {
    color: #595959;
    font-size: 14px;
  }
  &__box-content {
    background: #ffffff;
    border: 1px solid rgba(74, 138, 93, 0.46);
    box-sizing: border-box;
    border-radius: 4px;
  }
  &__choose-model-layer {
    border-bottom: 1px solid rgba(74, 138, 93, 0.46);
    vertical-align: middle;
  }
  &__subject {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    padding-right: 4px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 4px;
  }
  &__intro-brand {
    width: 600px;
    min-height: 300px;
    background: #f5f5f5;
    border-radius: 16px;
    padding: 15px 20px;
  }
  &__intro-brand-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  &__intro-brand-subtitle {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }
  &__intro-brand-divider {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &__intro-brand-img {
    border-radius: 4px;
    width: 680px;
    height: 151px;
    margin-bottom: 20px;
  }
  &__intro-brand-content {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    width: 561px;
  }
  &__intro-product {
    background: #f5f5f5;
    border-radius: 16px;
    padding: 13px 20px;
  }
  &__intro-product-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 38px;
    color: #262626;
  }
  &__intro-product-desc {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    margin-bottom: 14px;
  }
  &__intro-product-list-item-outer {
    width: 141px;
    height: 195px;
    position: relative;
    background-color: inherit;
    // display: inline-block;
    float: left;
  }
  &__intro-product-list-item-icon {
    font-size: 15px;
    color: #4a8a5d;
    position: absolute;
    top: 2px;
    right: 12px;
    // margin-top: 2px;
    // float: right;
    // margin-right: 12px;
  }
  &__intro-product-list-item {
    width: 123px;
    height: 185px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 18px;
    display: inline-block;
    margin-top: 8px;
  }
  &__intro-product-list-item-no-product {
    width: 123px;
    height: 185px;
    background: #ffffff;
    border: 1px dashed #999999;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 18px;
    display: inline-block;
    margin-top: 8px;
  }
  &__intro-product-list-item-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    position: absolute;
    bottom: 7px;
    width: 119px;
    padding: 0px 2px;
    text-align: center;
  }
  &__add-product {
    background: #4a8a5d;
    border-radius: 2px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
  &__sign-txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    margin-top: 14px;
  }
}
</style>
