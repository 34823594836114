<template lang="pug">
.no-select-wrapper
    .box__unselected(v-if="!checked", v-on:click.prevent="click()")
        .tag-icon__add
            img(src="@/assets/img/icon/add-icon.svg")
        .tag-text__unselected {{tagText}}
    .box__selected(v-else, v-on:click.prevent="click()")
        .tag-text__selected {{tagText}}
        .tag-icon__clear
            img(src="@/assets/img/icon/clear-icon.svg")
</template>

<script>
export default {
  components: {},
  props: {
    tagText: { type: String, default: () => "" },
    checked: { type: Boolean, default: () => false },
    id: { type: String, default: "" }
  },
  data() {
    return {
      //   selected: false
    }
  },
  computed: {},
  watch: {},
  async created() {},
  methods: {
    async click() {
      this.$emit("update:checked", !this.checked)
      // console.log("tag status:", this.checked)
      this.$emit("tagClick")

      // console.log("tag status:". this.checked)
      // if (this.checked == true) {
      //     this.$emit("tagId", this.id)
      //     this.$emit("tagStatus", this.checked)
      //     this.$emit("tagClick")
      // } else {
      //     this.$emit("tagId", this.id)
      //     this.$emit("tagStatus", this.checked)
      //     this.$emit("tagClick")
      // }
    }
  }
}
</script>

<style lang="sass">

.no-select-wrapper
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    & :hover
        cursor: pointer

.box
    &__selected
        display: flex
        align-items: center
        // width: fit-content
        height: 25px
        border: 1px solid #e0e0e0
        border-radius: 3px
        background-color: #f5f5f5
        box-shadow: 1px 1px 4px 1px #f5f5f5

    &__unselected
        display: flex
        align-items: center
        width: fit-content
        height: 25px
        border: 1px dashed #e6e6e6
        border-radius: 3px
        background-color: #fcfcfc

        // & :hover
        //     border: 1px dashed #e3e3e3

.tag-text
    &__selected
        display: inline-block
        margin: 3px
        padding: 3px
        position: relative
        text-align: center
        white-space: nowrap
        font-size: 13px
        font-weight: 450
        bottom: 2px
    &__unselected
        display: inline-block
        margin: 3px
        position: relative
        padding: 3px
        text-align: center
        white-space: nowrap
        font-size: 13px
        font-weight: 375
        bottom: 2px
.tag-icon

    &__clear
        display: flex
        // width: 17px
        // height: 17px
        width: 15px
        height: 15px
        filter: invert(61%) sepia(3%) saturate(250%) hue-rotate(128deg) brightness(90%) contrast(79%)
    &__add
        display: flex
        width: 17px
        height: 17px
        filter: invert(61%) sepia(3%) saturate(250%) hue-rotate(128deg) brightness(90%) contrast(79%)
</style>
