<template>
  <div ref="dialogTootip" class="brand-training-details__main">
    <div class="training-create-title">
      <div class="training-page-title">
        <div class="training-logo">
          {{ this.$t("Training.brandTraining.title") }}
        </div>
        <div class="step-title-content">
          <div class="step-title">
            <p class="step-title-one">
              {{ this.$t("Training.brandTraining.detailsTraining.stepOne") }}
            </p>
            <p class="step-title-two">
              {{ this.$t("Training.brandTraining.detailsTraining.stepTwo") }}
            </p>
            <p class="step-title-three">
              {{ this.$t("Training.brandTraining.detailsTraining.stepThree") }}
            </p>
          </div>
          <div class="step-line"></div>
        </div>
        <div class="title-button">
          <a-button class="btn-style" @click="jumpToTraining">
            {{ this.$t("Training.brandTraining.back") }}
          </a-button>
          <a-button v-if="!isPublished" class="btn-style" @click="clickTo">
            {{ this.$t("Training.brandTraining.continue") }}
          </a-button>
          <a-button v-else class="btn-style public-btn" @click="submitProduct">
            {{ this.$t("Training.brandTraining.publish") }}
          </a-button>
          <a-button
            v-if="isShowSaveBtn"
            class="save-draft"
            @click="jumpToTrainingPage"
          >
            {{ this.$t("Training.brandTraining.saveDraft") }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="training-details-content">
      <div class="company-details">
        <div class="content-left">
          <span class="demo-icon-tip">
            <a-tooltip :title="this.$t('Training.trainingTooltip.companyImgT')">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <img :src="userInfo.logoAsset.url" alt="/" />
        </div>
        <div class="content-right">
          <div class="right-top-text-button">
            <div class="right-text-right">
              <div class="title-name">
                {{ userInfo.name }}
                <!--            <span class="demo-icon-tip">-->
                <!--              <a-tooltip :title="this.$t('Training.trainingTooltip.companyImgT')">-->
                <!--                <a-icon type="question-circle-o" />-->
                <!--              </a-tooltip>-->
                <!--            </span>-->
              </div>
              <div class="about-content-main">
                <div class="content-instructions">
                  {{ userInfo.about }}
                </div>
                <span class="demo-icon-tip">
                  <a-tooltip
                    :title="this.$t('Training.trainingTooltip.companyImgT')"
                  >
                    <a-icon type="question-circle-o" />
                  </a-tooltip>
                </span>
              </div>
            </div>
            <div class="right-button-left">
              <div
                v-if="
                  userInfo.introductionAsset &&
                    Object.keys(userInfo.introductionAsset).length !== 0
                "
              >
                <div
                  v-if="userInfo.introductionAsset"
                  class="video-style"
                  @click="clickToVideo"
                >
                  <img
                    class="public-start"
                    src="../../assets/training_public_video.png"
                    alt=""
                  />
                  <video
                    width="250"
                    height="110"
                    :src="userInfo.introductionAsset.url"
                    style="height: 110px !important;"
                  >
                    <source
                      :src="userInfo.introductionAsset.url"
                      type="video/mp4"
                    />
                    Your browser does not support the HTML5 video tag.
                  </video>
                </div>
              </div>
              <span class="upload-icon-tip">
                <a-tooltip
                  title="Upload brand introduction video"
                  placement="left"
                >
                  <a-icon type="question-circle-o" />
                </a-tooltip>
              </span>
              <a-button
                v-if="
                  userInfo.introductionAsset &&
                    Object.keys(userInfo.introductionAsset).length !== 0
                "
                :class="uploadBtn ? 'upload-success' : 'upload-video-style'"
                @click="deleteVideo"
              >
                <a-icon type="delete" />
                Delete
              </a-button>
              <a-button
                :class="uploadBtn ? 'upload-success' : 'upload-video-style'"
                @click="openDialog"
              >
                <a-icon
                  v-if="
                    userInfo.introductionAsset &&
                      Object.keys(userInfo.introductionAsset).length !== 0
                  "
                  type="sync"
                />
                <a-icon v-else type="upload" />
                {{ uploadText }}
              </a-button>
              <span class="upload-optional-style">Optional</span>
            </div>
          </div>
          <div class="other-content">
            <div class="curations">
              <div class="name-curation-value">
                Curations
                <span class="demo-icon-tip">
                  <a-tooltip
                    :title="this.$t('Training.trainingTooltip.companyImgT')"
                  >
                    <a-icon type="question-circle-o" />
                  </a-tooltip>
                </span>
              </div>
              <div>
                <span
                  v-for="(tag, index) in userInfo.curations"
                  :key="index"
                  class="blue-tag"
                >
                  {{ tag.description }}
                </span>
              </div>
            </div>
            <div class="brand-value">
              <div class="name-curation-value">
                Brand Values
                <span class="demo-icon-tip">
                  <a-tooltip
                    :title="this.$t('Training.trainingTooltip.companyImgT')"
                  >
                    <a-icon type="question-circle-o" />
                  </a-tooltip>
                </span>
              </div>
              <div v-if="brandValue && brandValue.length !== 0">
                <span
                  v-for="item in brandValue"
                  :key="item.id"
                  class="green-tag"
                >
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-list">
      <div v-for="(item, index) in proList" :key="index">
        <ProdEditCard
          :product="item"
          @handleSendValue="handleSendValue"
          @hadaleSaveDraft="hadaleSaveDraft"
          @showSaveBtn="showSaveBtn"
        ></ProdEditCard>
      </div>
    </div>
    <a-modal
      v-model="sureVisible"
      :centered="true"
      :footer="null"
      :width="464"
      :get-container="() => $refs.dialogTootip"
      :title="null"
    >
      <span slot="closeIcon">
        <a-icon type="close-circle" style="font-size: 21px" />
      </span>
      <div class="dialog-info-before-submit">
        <p class="dialog-info-title">Congratulations!</p>
        <p class="dialog-content-style">
          Your entry has been published. To make it visible to the public,
          please select the public option on the Training Homepage.
        </p>
        <p class="dialog-close-btn" @click="beforeSubmitDialog">Close</p>
      </div>
    </a-modal>
    <a-modal
      :visible="modalVisible"
      :footer="null"
      :body-style="{ padding: 0 }"
      @cancel="handleCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div
        v-if="userInfo.introductionAsset && userInfo.introductionAsset.url"
        class="video-main"
      >
        <video id="video1" ref="videoM" controls>
          <source :src="userInfo.introductionAsset.url" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
    </a-modal>
    <upLoadVideoDialog
      ref="uploadVideo"
      @sendSuccess="sendSuccess"
    ></upLoadVideoDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Image from "@/assets/img/back-arrow.png";
import ProdEditCard from "@/components/training/productEditCard";
import { getSesStorage, setSesStorage } from "@/utils/storageUtil";
import trainingClient from "@/api/training-client";
import upLoadVideoDialog from "@/components/training/upLoadVideoDialog";

export default {
  name: "BrandTrainingDetails",
  components: {
    ProdEditCard,
    upLoadVideoDialog,
  },
  data() {
    return {
      isUpdating: false,
      Image,
      isShowSaveBtn: false,
      proList: [],
      sendParams: [],
      finalParams: [],
      saveDraft: [],
      isPublished: false,
      isClickClose: false,
      sureVisible: false,
      draftSaveSuccess: false,
      uploadBtn: false,
      modalVisible: false,
      // userInfo:{}
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.businessId,
    }),
    ...mapGetters({
      // getCrossSellers: "training/getCrossSellers"
    }),
    userInfo() {
      return this.$store.getters["brand/brand"];
    },
    brandValue() {
      return !this.userInfo.brandValues ? [] : this.userInfo.brandValues;
    },
    brand() {
      return this.$store.getters["brand/brand"];
    },
    uploadText() {
      return (this.brand.introductionAsset &&
        this.brand.introductionAsset.url) ||
        this.uploadBtn
        ? "Replace Video"
        : this.$t("Training.brandTraining.trainingFormTips.uploadVideoLabel");
    },
  },
  async created() {
    if (this.$route.query.id && !this.$route.query.isSaveDraft) {
      this.proList = JSON.parse(getSesStorage("EDIT_ONE_TRAINING_PRODUCT"));
    } else if (this.$route.query.isSaveDraft && this.$route.query.id) {
      const list = JSON.parse(getSesStorage("SAVE_DRAFT_ARR_LIST"));
      this.proList = list.filter((item) => item.id === this.$route.query.id);
    } else {
      this.proList = JSON.parse(getSesStorage("CREATE_PRODUCT_LIST_VALUE"));
    }
    this.proList = this.proList.map((item) => {
      item.msrpUsd = parseInt(item.msrpUsd, 10).toFixed(2);
      return item;
    });
    await this.getProductTraining({
      brand: this.userId,
      "have-training": "have-training",
      _limit: 999,
    });
  },
  methods: {
    ...mapActions({
      getProductTraining: "training/getProductTraining",
    }),
    jumpToTraining() {
      console.log(this.$route);
      if (this.$route.query.id) {
        if (this.$route.params.brandId) {
          this.$router.push({
            name: "adminBrandTraining",
            query: {
              tab: "product",
            },
          });
        } else {
          this.$router.push({
            name: "BrandTraining",
            query: {
              main: true,
              tab: "product",
            },
          });
        }
      } else {
        this.$router.go(-1);
      }
    },
    clickTo() {
      if (
        this.$route.query.id &&
        this.proList[0].training.howToDemo &&
        this.proList[0].trainingSteps.length !== 0 &&
        this.proList[0].trainingBestUsedFors.length !== 0
      ) {
        this.isPublished = true;
      }
      if (this.draftSaveSuccess) {
        if (this.$route.params.brandId) {
          this.$router.push({
            name: "adminBrandTraining",
            query: {
              tab: "product",
            },
          });
        } else {
          this.$router.push({
            name: "BrandTraining",
            query: {
              // main: true,
              tab: "product",
            },
          });
        }
      }
    },
    hadaleSaveDraft(obj, productInfo) {
      // this.saveDraft = JSON.parse(getSesStorage("SAVE_DRAFT_ARR_LIST")) || []
      if (this.saveDraft.some((item) => item.id === productInfo.id)) {
        this.saveDraft = this.saveDraft.map((value) => {
          const param = value.id === productInfo.id ? obj : value;
          const info = {
            // isSaveDraft: true,
            id: productInfo.id,
            ...productInfo,
            ...param,
          };
          return info;
        });
      } else {
        let params = {
          // isSaveDraft: true,
          id: productInfo.id,
          ...obj,
          ...productInfo,
        };
        this.saveDraft.push(params);
      }
    },
    jumpToTrainingPage() {
      // If it is in the Save Draft state, state is 0 and isPublish is false
      this.saveDraft = this.saveDraft.map((item) => {
        const param = {
          training: {
            ...item.training,
            states: 0,
            isPublished: false,
          },
          trainingVideos: item.trainingVideos,
          trainingSteps: item.trainingSteps,
          trainingCrossSellers: item.trainingCrossSellers,
          trainingBestUsedFors: item.trainingBestUsedFors,
        };
        return { id: item.id, param };
      });
      this.saveDraft.forEach(async (item) => {
        await this.submit(item.id, item.param);
      });
      this.$message.success("Your draft has been saved");
      this.draftSaveSuccess = true;
      // this.$router.push({
      //   name: "BrandTraining"
      // })
    },
    showSaveBtn(isShowSaveBtn) {
      this.isShowSaveBtn = isShowSaveBtn;
    },
    async handleSendValue(obj, id, isPublish) {
      this.isPublished = isPublish;
      if (this.sendParams.some((item) => item.id === id)) {
        this.sendParams = this.sendParams.map((value) => {
          return {
            id: value.id,
            param: value.id === id ? obj : value.param,
          };
        });
      } else {
        let params = {
          id,
          param: obj,
        };
        this.sendParams.push(params);
      }
      // If it is in the publish state, state is 1 and isPublish is true
      this.finalParams = this.sendParams;
      // The total number of items created can only be submitted
      if (this.proList.length === this.sendParams.length) {
        this.isPublished = true;
      }
    },
    async submitProduct() {
      let arr = [];
      this.sureVisible = false;
      this.sendParams.forEach((item) => {
        // await trainingClient.addTrainingProduct(item.id, item.param)
        arr.push(this.submit(item.id, item.param));
      });
      Promise.all(arr).then(() => {
        this.sureVisible = true;
      });
    },
    beforeSubmitDialog() {
      if (this.$route.params.brandId) {
        this.$router.push({
          name: "adminBrandTraining",
          query: {
            tab: "product",
          },
        });
      } else {
        this.$router.push({
          name: "BrandTraining",
          query: {
            // main: true,
            tab: "product",
          },
        });
      }
    },
    async submit(id, param) {
      if (this.isUpdating) {
        return false;
      }
      this.isUpdating = true;
      // Modify the product
      if (this.$route.query.id && !this.$route.query.isSaveDraft) {
        //return await trainingClient.updateTrainingProduct(id, param)
        return await trainingClient
          .updateTrainingProductAndProTips(id, param)
          .finally(() => (this.isUpdating = false));
      }
      // Filter the publish products in the cache list
      if (this.$route.query.isSaveDraft) {
        const list = this.proList.filter((item) => item.id !== id);
        setSesStorage("SAVE_DRAFT_ARR_LIST", list);
      }
      return await trainingClient
        .addTrainingProduct(id, param)
        .finally(() => (this.isUpdating = false));
    },
    openDialog() {
      this.$refs["uploadVideo"].openUploadVideo(this.brand.id);
    },
    async deleteVideo() {
      const params = {
        introductionAsset: {
          id: "",
          url: "",
        },
      };
      if (this.brand.id) {
        await trainingClient.addBrandClientVideo(this.brand.id, params);
        this.uploadBtn = false;
        await this.$store.dispatch("brand/getBrand", this.brand.id);
      }
    },
    async sendSuccess() {
      this.uploadBtn = true;
      await this.$store.dispatch("brand/getBrand", this.brand.id);
    },
    clickToVideo() {
      this.modalVisible = true;
      this.$refs["videoM"].play();
    },
    handleCancel() {
      this.modalVisible = false;
      this.$refs["videoM"].pause();
    },
  },
};
</script>

<style scoped lang="scss">
.brand-training-details__main {
  min-height: 520px;
  width: 952px;
  margin: 0 auto;

  .training-page-title {
    width: 100%;
    height: 94px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }

  .title-button {
    width: 205px;
    text-align: right;
    //display: flex;
    //justify-content: space-between;
    .btn-style {
      width: 88px;
      height: 30px;
      font-family: "Proxima Nova";
      //line-height: 20px;
      //padding: 5px 16px;
      font-size: 14px;
      border: 1px solid #4a8a5d;
      color: #4a8a5d;
      background: #e2eeec;
    }

    .btn-style:nth-child(2) {
      margin-left: 16px;
    }

    .btn-style:hover {
      color: white;
      background: #4a8a5d;
    }
    .public-btn {
      color: white;
      background-color: #12433d;
    }

    .can-not-click {
      width: 88px;
      height: 30px;
      font-family: "Proxima Nova";
      font-size: 14px;
      border: 1px solid #bfbfbf;
      color: #bfbfbf;
      background: #fafafa;
      margin-left: 16px;
    }

    .can-not-click:hover {
      //color: white;
      //background: #4a8a5d;
    }
  }
  .save-draft {
    margin-top: 16px;
    padding: 0 2px;
    width: 88px;
    height: 30px;
    font-family: "Proxima Nova";
    font-size: 14px;
    border: 1px solid #595959;
    color: #595959;
    background: #fef2e2;
  }

  .step-title {
    width: 488px;
    font-size: 13px;
    line-height: 15.83px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .step-title-one {
      width: 102px;
      height: 64px;
    }
    .step-title-two {
      width: 123px;
      height: 64px;
    }
    .step-title-three {
      width: 175px;
      height: 64px;
    }
    p {
      margin-bottom: 0;
    }
  }

  .step-line {
    width: 100%;
    height: 4px;
    border-radius: 1px;
    background: #e2eeec;
    margin-top: 8px;
  }

  .training-details-content {
    width: 100%;
    padding: 0 0 0 22px;
    margin-top: 20px;
    //height: 22px;
    .company-details {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .content-left {
      width: 171px;
      height: 171px;
      line-height: 171px;
      //border: 1px solid #bfbfbf;
      position: relative;
      img {
        width: 100%;
        //height: 100%;
      }
      .demo-icon-tip {
        position: absolute;
        left: -34px;
        bottom: 90px;
        z-index: 3;
        i {
          font-size: 12px;
          color: #bfbfbf;
        }
      }
    }
    .content-right {
      width: 694px;
      position: relative;
      .title-name {
        color: #000000;
        font-size: 24px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        font-weight: bold;
        line-height: 25px;
        margin-bottom: 12px;
        position: relative;
        .demo-icon-tip {
          position: absolute;
          left: 85px;
          bottom: 4px;
          z-index: 3;
        }
      }
      .about-content-main {
        position: relative;
        .demo-icon-tip {
          position: absolute;
          right: -25px;
          bottom: 0;
          z-index: 3;
        }
      }
      .content-instructions {
        height: 64px;
        font-size: 13px;
        line-height: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 12px;
        color: #000000;
        position: relative;
        word-break: break-word;
        overflow-y: auto;
        //word-wrap: break-word;
        //word-break: normal;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-box-orient: vertical;
        //-webkit-line-clamp: 4;
      }
      .other-content {
        display: flex;
        justify-content: flex-start;
      }
      .curations,
      .brand-value {
        width: 325px;
        font-size: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        font-weight: 400;
        span {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          font-size: 12px;
        }
      }
      .name-curation-value {
        //margin-bottom: 8px;
        color: #000000;
      }
      .blue-tag {
        padding: 3px 7px;
        color: #1d4ba1;
        border: 1px solid #1d4ba1;
      }
      .green-tag {
        padding: 3px 7px;
        color: #4a8a5d;
        border: 1px solid #4a8a5d;
      }
      .right-top-text-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right-text-right {
          width: 570px;
        }
        .right-button-left {
          width: 171px;
          margin-left: 30px;
          position: relative;
          .upload-video-style {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #4a8a5d;
            background-color: #e2eeec;
            text-align: center;
            margin-top: 15px;
          }
          .upload-success {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #ffffff;
            background-color: #4a8a5d;
            text-align: center;
            margin-top: 15px;
          }
        }
      }
    }
  }
  .product-list {
    width: 100%;
    margin-top: 25px;
  }
  .demo-icon-tip {
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .ant-btn-primary {
    background-color: #12433d;
  }
  .ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
    float: none;
  }
  .dialog-close-btn {
    width: 88px;
    height: 33px;
    line-height: 33px;
    border-radius: 2px;
    font-size: 14px;
    margin-top: 27px;
    background-color: #12433d;
    color: white;
    text-align: center;
    margin-left: 108px;
    cursor: pointer;
  }
  .upload-optional-style {
    font-size: 8px;
    color: #262626;
    position: absolute;
    left: 124px;
    bottom: -18px;
    z-index: 3;
  }
  .upload-icon-tip {
    padding-right: 2px;
    position: absolute;
    right: -17px;
    bottom: -4px;
    z-index: 4;
    i {
      font-size: 12px;
      color: #bfbfbf;
      margin-top: 20px;
    }
  }
  .video-style {
    width: 196px;
    height: 110px;
    margin-top: 16px;
    margin-left: -13px;
    text-align: center;
    border: 1px solid #262626;
    cursor: pointer;
    position: relative;
    .video-img {
      //width: 100%;
      height: 100%;
    }
    .public-start {
      position: absolute;
      top: 37px;
      right: 80px;
      border-radius: 50%;
    }
    .background-style {
      width: 43px;
      height: 43px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      top: 34px;
      right: 83px;
    }
  }
  .product-list {
    width: 100%;
    margin-top: 25px;
  }
}
.ant-modal-confirm-title {
  text-align: center;
}
.info-dialog-main {
  text-align: center;
  background-color: yellow;
}
.dialog-info-before-submit {
  width: 300px;
  text-align: center;
  color: #12433d;
  font-size: 13px;
  margin-left: 64px;
  margin-top: 23px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
.dialog-info-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 23px;
}
</style>
