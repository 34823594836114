<template>
  <div>
    <search-component
      :search-recent="searchRecent"
      search-hint="Search by Brand"
      @doSearch="onSearch"
      @deleteTag="closeTagItem"
    />
    <a-table
      :columns="tableColumn"
      :data-source="tableDate"
      style="margin-top: 40px;"
      size="middle"
      class="admin-training-table"
      :row-class-name="
        (_recode, index) =>
          index % 2 === 1 ? 'admin-training-table-striped' : null
      "
    >
      <span slot="nameTitle">
        Brand
        <a-icon
          type="caret-up"
          :rotate="
            sortBrand.toString().length > 0 && sortBrand === 'descend' ? 180 : 0
          "
          :class="
            sortBrand.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('name')"
        />
      </span>
      <span slot="productTitle">
        Products
        <a-icon
          type="caret-up"
          :rotate="
            sortProductCount.toString().length > 0 &&
            sortProductCount === 'descend'
              ? 180
              : 0
          "
          :class="
            sortProductCount.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('productCount')"
        />
      </span>
      <span slot="publicTitle">
        Public
        <a-icon
          type="caret-up"
          :rotate="
            sortPublicCount.toString().length > 0 &&
            sortPublicCount === 'descend'
              ? 180
              : 0
          "
          :class="
            sortPublicCount.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('publicCount')"
        />
      </span>
      <span slot="publishTitle">
        Published
        <a-icon
          type="caret-up"
          :rotate="
            sortPublishCount.toString().length > 0 &&
            sortPublishCount === 'descend'
              ? 180
              : 0
          "
          :class="
            sortPublishCount.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('publishedCount')"
        />
      </span>
      <span slot="modifyTitle">
        Modified
        <a-icon
          type="caret-up"
          :rotate="
            sortModified.toString().length > 0 && sortModified === 'descend'
              ? 180
              : 0
          "
          :class="
            sortModified.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('modified')"
        />
      </span>
      <span slot="beautyfluentTitle">
        BeautyFluent
        <a-icon
          type="caret-up"
          :rotate="
            sortBeauty.toString().length > 0 && sortBeauty === 'descend'
              ? 180
              : 0
          "
          :class="
            sortBeauty.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('beautyfluent')"
        />
      </span>
      <span
        slot="beautyfluentValue"
        slot-scope="text"
        :style="text ? 'color: #000;' : 'color: #E33716;'"
      >
        {{ text ? "Member" : " " }}
      </span>
      <span slot="modifyDate" slot-scope="text, record">
        {{
          dateFormat(
            record.productTrainingCount.updatedAt
              ? record.productTrainingCount.updatedAt
              : record.productTrainingCount.createdAt
          )
        }}
      </span>
      <span
        slot="link"
        slot-scope="text"
        class="admin-brand-url"
        @click="clickTo(text)"
      >
        {{ text }}
      </span>
      <span
        slot="trainLink"
        slot-scope="text, record"
        class="tab-link-style"
        style="color: #4A8A5D; cursor: pointer;"
        @click="copyLink($event, text, record)"
      >
        {{ record.isCopyTrainingLink ? "Link Copied" : "Copy Link" }}
      </span>
      <span
        slot="qrCode"
        slot-scope="text, record"
        @click="clickToDownload(record)"
      >
        <span
          class="admin-training-brand__click-oper"
          style="color: #4A8A5D;"
          >{{ record.isDownload ? "Downloaded" : "Download" }}</span
        >
      </span>
      <span slot="publicOper" slot-scope="text, record">
        <a-switch
          :checked="text"
          class="admin-training-switch"
          style="background-image: none"
          @change="onChangePublic(record)"
        />
      </span>
      <span
        slot="actionOper"
        slot-scope="text, record"
        class="admin-training-brand__click-oper"
        @click="clickToView(record)"
      >
        View
      </span>
    </a-table>
    <QRCode ref="brandQrcode" :url="qrText"></QRCode>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { downloadQR } from "@/utils/validate";
import { adminBrandTag } from "@/utils/const";
import SearchComponent from "@/components/training/SearchComponent";
import QRCode from "@/components/training/__components/QRCode";
import Clipboard from "clipboard";
export default {
  components: {
    "search-component": SearchComponent,
    QRCode,
  },
  data() {
    return {
      searchRecent: [],
      searchValue: "",
      pageSize: 50,
      tableColumn: [
        {
          //title: "Brand",
          dataIndex: "name",
          key: "name",
          slots: { title: "nameTitle" },
          // sorter: true
        },
        {
          // title: "Products",
          dataIndex: "productCount",
          key: "productCount",
          slots: { title: "productTitle" },
          // sorter: true
        },
        {
          // title: "Published",
          dataIndex: "publishedCount",
          key: "publishedCount",
          slots: { title: "publishTitle" },
          // sorter: true
        },
        {
          //title: "Public",
          dataIndex: "publicCount",
          key: "publicCount",
          slots: { title: "publicTitle" },
          // sorter: true
        },
        {
          //title: "Public",
          dataIndex: "isBeautyfluentPaid",
          key: "isBeautyfluentPaid",
          slots: { title: "beautyfluentTitle" },
          scopedSlots: { customRender: "beautyfluentValue" },
          // sorter: true
        },
        // {
        //   //title: "Modified",
        //   dataIndex: "modified",
        //   key: "modified",
        //   slots: { title: "modifyTitle" }
        //   // sorter: true
        // },
        {
          title: "Brand URL",
          dataIndex: "brandLink",
          key: "brandLink",
          scopedSlots: { customRender: "link" },
        },
        {
          title: "URL",
          dataIndex: "link",
          key: "link",
          scopedSlots: { customRender: "trainLink" },
        },
        {
          title: "QR Code",
          dataIndex: "qrCode",
          key: "qrCode",
          scopedSlots: { customRender: "qrCode" },
        },
        {
          title: "Public",
          dataIndex: "isPublic",
          key: "isPublic",
          scopedSlots: { customRender: "publicOper" },
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "actionOper" },
        },
      ],
      qrText: "",
      currentPage: 1,
      sortBrand: "",
      sortProductCount: "",
      sortPublicCount: "",
      sortPublishCount: "",
      sortBeauty: "",
      sortModified: "",
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.id,
      // tableDate: state => state.training.adminProductList
    }),
    ...mapGetters({
      searchProductList: "training/searchProductList",
    }),
    // If no data is displayed this button
    showShareBtn() {
      return this.tableDate && this.tableDate.length !== 0;
    },
    tableDate() {
      return this.searchProductList;
    },
  },
  async created() {
    await this.getSearchTrainingPro(this.getBaseParam());
    this.searchRecent = await this.getTagList(adminBrandTag);
  },
  methods: {
    ...mapActions({
      getSearchTrainingPro: "training/getSearchTrainingBrand",
      updateTagList: "training/updateBrandTags",
      getTagList: "training/getSearchBrandTags",
    }),
    async onSearch(value) {
      this.searchValue = value;
      this.searchRecent = await this.updateTagList({
        tagName: adminBrandTag,
        tagItem: this.searchValue,
      });
      this.currentPage = 1;
      await this.getSearchTrainingPro(this.getBaseParam());
    },
    async closeTagItem(item) {
      await this.updateTagList({
        tagName: adminBrandTag,
        tagItem: item.name,
        type: "delete",
      });
    },
    async clearValue() {
      this.closeStatus = false;
      this.searchValue = "";
      this.currentPage = 1;
      await this.getSearchTrainingPro(this.getBaseParam());
    },
    clickTo(value) {
      window.open(`https://${value}`);
    },
    copyLink(e, text, record) {
      if (!record.isCopyTrainingLink) {
        const relativePath = text.substring(text.indexOf("/"));
        const trainingLink = `${window.location.protocol}${window.location.host}${relativePath}`;
        const clipboard = new Clipboard(e.target, {
          text: () => `${trainingLink}`,
        });
        clipboard.on();
        clipboard.on("success", (e) => {
          console.log(e);
          this.$message.success("Copy Success");
          clipboard.off("error");
          clipboard.off("success");
          clipboard.destroy();
        });
        clipboard.on("error", (e) => {
          this.$message.warning("Browser not support copy");
          console.log(e);
          clipboard.off("error");
          clipboard.off("success");
          clipboard.destroy();
        });
        clipboard.onClick(e);
        record.isCopyTrainingLink = true;
        this.$forceUpdate();
      }
    },
    clickToDownload(data) {
      if (!data.isDownload) {
        console.log(data);
        this.qrText = `https://${data.link}`;
        // this.$refs["brandQrcode"].openDownloadQR()
        downloadQR(this.qrText, `${data.name}_qrcode.png`);
        data.isDownload = true;
        this.$forceUpdate();
      }
    },
    clickToView(data) {
      console.log("...click to view....");
      console.log(data);
      this.$router.push({
        name: "adminBrandTrainingProduct",
        params: {
          brandId: data.brandId,
          name: data.name,
          shortName: data.shortName,
        },
      });
    },
    getBaseParam() {
      let param = { _limit: this.pageSize, "page-index": this.currentPage };
      if (this.searchValue && this.searchValue.length > 0) {
        param["brand-product-name"] = this.searchValue;
      }
      return param;
    },
    async sortByField(fieldName) {
      let params = this.getBaseParam();
      let sortField = "";
      let order = "";
      if (fieldName === "name") {
        sortField = "sortBrand";
        if (this.sortBrand.length === 0) {
          this.sortBrand = "ascend";
        } else if (this.sortBrand === "ascend") {
          this.sortBrand = "descend";
        } else {
          this.sortBrand = "ascend";
        }
        this.sortProductCount = "";
        this.sortPublicCount = "";
        this.sortModified = "";
        this.sortPublishCount = "";
        this.sortBeauty = "";
        order = this.sortBrand;
      } else if (fieldName === "publishedCount") {
        sortField = "sortPublished";
        if (this.sortPublishCount.length === 0) {
          this.sortPublishCount = "ascend";
        } else if (this.sortPublishCount === "ascend") {
          this.sortPublishCount = "descend";
        } else {
          this.sortPublishCount = "ascend";
        }
        this.sortProductCount = "";
        this.sortPublicCount = "";
        this.sortModified = "";
        this.sortBrand = "";
        this.sortBeauty = "";
        order = this.sortPublishCount;
      } else if (fieldName === "publicCount") {
        sortField = "sortPublic";
        if (this.sortPublicCount.length === 0) {
          this.sortPublicCount = "ascend";
        } else if (this.sortPublicCount === "ascend") {
          this.sortPublicCount = "descend";
        } else {
          this.sortPublicCount = "ascend";
        }
        this.sortProductCount = "";
        this.sortPublishCount = "";
        this.sortModified = "";
        this.sortBrand = "";
        this.sortBeauty = "";
        order = this.sortPublicCount;
      } else if (fieldName === "productCount") {
        sortField = "sortProducts";
        if (this.sortProductCount.length === 0) {
          this.sortProductCount = "ascend";
        } else if (this.sortProductCount === "ascend") {
          this.sortProductCount = "descend";
        } else {
          this.sortProductCount = "ascend";
        }
        this.sortPublishCount = "";
        this.sortPublicCount = "";
        this.sortModified = "";
        this.sortBrand = "";
        this.sortBeauty = "";
        order = this.sortProductCount;
      } else if (fieldName === "modified") {
        sortField = "sortModified";
        if (this.sortModified.length === 0) {
          this.sortModified = "ascend";
        } else if (this.sortModified === "ascend") {
          this.sortModified = "descend";
        } else {
          this.sortModified = "ascend";
        }
        this.sortProductCount = "";
        this.sortPublicCount = "";
        this.sortPublishCount = "";
        this.sortBrand = "";
        this.sortBeauty = "";
        order = this.sortModified;
      } else if (fieldName === "beautyfluent") {
        sortField = "sortBeautyfluent";
        if (this.sortBeauty.length === 0) {
          this.sortBeauty = "ascend";
        } else if (this.sortBeauty === "ascend") {
          this.sortBeauty = "descend";
        } else {
          this.sortBeauty = "ascend";
        }
        this.sortProductCount = "";
        this.sortPublicCount = "";
        this.sortPublishCount = "";
        this.sortBrand = "";
        this.sortModified = "";
        order = this.sortBeauty;
      }
      params = Object.assign({}, params, {
        "_sort-by": sortField,
        "_order-by": order === "ascend" ? "NEXT" : "PREVIOUS",
      });
      await this.getSearchTrainingPro(params);
    },
    async doSort(sorter, curPage) {
      this.currentPage = curPage;
      let params = this.getBaseParam();
      let sortField = "";
      let index = 0;
      if (sorter.field === "name") {
        sortField = "sortBrand";
        index = 0;
      } else if (sorter.field === "publishedCount") {
        sortField = "sortPublished";
        index = 2;
      } else if (sorter.field === "publicCount") {
        sortField = "sortPublic";
        index = 3;
      } else if (sorter.field === "productCount") {
        sortField = "sortProducts";
        index = 1;
      } else if (sorter.field === "modified") {
        sortField = "sortModified";
        index = 4;
      }
      if (!sorter.order) {
        sorter.order = "ascend";
      }
      this.tableColumn[index].sortOrder = sorter.order;
      for (let i = 0; i < 5; i++) {
        if (i !== index) {
          this.tableColumn[i].sortOrder = false;
        }
      }

      params = Object.assign({}, params, {
        "_sort-by": sortField,
        "_order-by": sorter.order === "ascend" ? "NEXT" : "PREVIOUS",
      });
      await this.getSearchTrainingPro(params);
    },
    async handleName(pagination, filters, sorter) {
      console.log("....hanlderName .....");
      console.log(sorter);
      await this.doSort(sorter, pagination.currentPage);
    },
    onChangePublic(data) {
      this.$store
        .dispatch("brand/updateBrandPublic", {
          id: data.brandId,
          brandProductTrainingCount: {
            public: !data.isPublic,
            publicCount: data.publicCount,
            publishedCount: data.publishedCount,
          },
        })
        .then(() => {
          this.getSearchTrainingPro(this.getBaseParam());
        })
        .catch((e) => {
          console.log(e);
        });
    },
    dateFormat(value) {
      if (value) {
        let date = Date.parse(value);
        return new Intl.DateTimeFormat("en-US").format(date);
      }
      return " ";
    },
  },
};
</script>
<style lang="scss" scoped>
.admin-training-brand {
  &__click-oper {
    cursor: pointer;
    color: #4a8a5d;
  }
}
</style>
