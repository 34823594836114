<template>
  <div class="admin-message-compose" @click="clickOuter">
    <a-row>
      <span class="admin-message-compose__title">{{ $t("Admins.AmdinMessage.messaging") }}</span>
    </a-row>
    <a-row>
      <span class="admin-message-compose__description">{{
        $t("Admins.AmdinMessage.selectRecipient")
      }}</span>
    </a-row>
    <a-row style="margin-bottom: 20px;">
      <div class="admin-message-compose__selection-outer">
        <div class="admin-message-compose__selection-bg">
          {{ $t("Admins.AmdinMessage.brand") }}
        </div>
        <dropdown-list
          ref="brandFilter"
          style="z-index: 1001"
          :placeholder="$t('Admins.AmdinMessage.selectBrand')"
          :list="brandList"
          :disabled="isBrandDisabled"
          @isClickCheckbox="checkBoxClick"
          @apply="applyBrandSearchGroup"
        />
      </div>
      <div class="admin-message-compose__selection-outer">
        <div class="admin-message-compose__selection-bg">
          {{ $t("Admins.AmdinMessage.retailer") }}
        </div>
        <dropdown-list
          ref="retailerFilter"
          style="z-index: 1001"
          :placeholder="$t('Admins.AmdinMessage.selectRetailer')"
          :list="retailerList"
          :disabled="isRetailerDisalbed"
          @isClickCheckbox="checkBoxClick"
          @apply="applyRetailerSearchGroup"
        />
      </div>
      <div class="admin-message-compose__selection-outer">
        <div class="admin-message-compose__selection-bg">
          {{ $t("Admins.AmdinMessage.custom") }}
        </div>
        <custom-filter-select
          ref="cutomFilter"
          style="width: 100%; z-index: 1001;"
          :brand-list="retailerList"
          :retailer-list="brandList"
          :date-list="dateList"
          :is-disalbed="isCustomDisabled"
          @isClickCheckbox="checkBoxClick"
          @apply="applyCustomSearchGroup"
        />
      </div>
    </a-row>
    <a-row
      v-if="
        customGroupList !== undefined &&
          customGroupList.searchGroups !== undefined &&
          customGroupList.searchGroups.length > 0
      "
    >
      <a-col :span="24">
        <span class="admin-message-compose__description">{{
          $t("Admins.AmdinMessage.presetCustom")
        }}</span>
      </a-col>
      <a-col
        v-for="(customGrouop, index) in customGroupList.searchGroups"
        :key="`customGroup_${index}`"
        :span="6"
        style="margin-bottom: 9px;"
        @click="clickToGetRecipient(customGrouop)"
      >
        <CustomCondition
          :condition="customGrouop"
          :brand-list="brandList"
          :is-selected="index === 0"
          :retailer-list="retailerList"
          :date-list="dateList"
          @update="getCustomGroup"
        />
      </a-col>
    </a-row>
    <a-row style="margin-top: 2px;">
      <a-col :span="24">
        <span class="admin-message-compose__description">{{
          $t("Admins.AmdinMessage.recipient")
        }}</span>
      </a-col>
      <a-col :span="21">
        <recipient-box :recipient-address-list="recipients" />
      </a-col>
      <a-col :span="3" style="text-align: right">
        <a-button class="admin-message-compose__save-btn" @click="saveSearchGroup">
          {{ $t("Admins.AmdinMessage.saveGroup") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row class="admin-message-compose__row-margin">
      <a-col :span="24">
        <span class="admin-message-compose__description">{{
          $t("Admins.AmdinMessage.category")
        }}</span>
      </a-col>
      <a-col :span="24">
        <CategorySelectList
          ref="categoryFilter"
          :placeholder="$t('Admins.AmdinMessage.selectCategory')"
          @isClickCheckbox="checkBoxClick"
          @onChange="setCategory"
        />
      </a-col>
    </a-row>
    <a-row class="admin-message-compose__row-margin">
      <a-col :span="24">
        <span class="admin-message-compose__description">{{
          $t("Admins.AmdinMessage.subject")
        }}</span>
      </a-col>
      <a-col :span="24">
        <a-input v-model="subject" style="width: 100%;" />
      </a-col>
    </a-row>
    <a-row class="admin-message-compose__row-margin" style="z-index: 999;">
      <message-input-component :disable="false" @send="sendMessage" @sendDraft="sendDraft" />
    </a-row>
  </div>
</template>
<script>
import DropdownList from "@/components/message/DropdownList"
import CustomFilterSelect from "@/components/message/CustomFilterSelect"
import CustomCondition from "@/components/message/CustomCondiftion"
import RecipientBox from "@/components/message/RecipientBox"
import MessageInputComponent from "@/components/message/MessageInputComponent"
import CategorySelectList from "@/components/message/CategorySelectList"
import moment from "moment"
export default {
  components: {
    DropdownList,
    CustomFilterSelect,
    CustomCondition,
    RecipientBox,
    MessageInputComponent,
    CategorySelectList
  },
  data() {
    return {
      brandList: [],
      retailerList: [],
      dateRange: [],
      selectedCategory: [],
      recipients: [],
      customFilters: {},
      filterType: -1,
      filterDatas: undefined,
      newAddedCategory: "",
      category: undefined,
      subject: undefined,
      isBrandDisabled: false,
      isRetailerDisalbed: false,
      isCustomDisabled: false,
      canDeactive: true,
      dateList: [
        { id: 1, name: "All Time", isChecked: false },
        { id: 2, name: "Month to Date", isChecked: false },
        { id: 3, name: "Last 7 days", isChecked: false },
        { id: 4, name: "Last 3 months", isChecked: false },
        { id: 5, name: "Last 6 months", isChecked: false },
        { id: 6, name: "Last 12 months", isChecked: false },
        { id: 7, name: "Select Date Range", isChecked: false }
      ],
      isDraft: false
    }
  },
  computed: {
    categoryList() {
      let data = this.$store.getters["reference/messageCategory"]
      console.log(data)
      if (data !== undefined && data.list && data.list.length > 0) {
        data.list.forEach(item => {
          item.isChecked = false
        })
        return data.list
      }
      return []
    },
    customGroupList() {
      let data = this.$store.getters["adminMessage/searchGroups"]
      console.log("....searchGroup.....")
      console.log(data)
      this.$forceUpdate()
      return data
    },
    clientType() {
      return this.$store.getters["reference/clientType"]
    }
  },
  async created() {
    await this.$store.dispatch("brand/fetchAllBrands", {
      params: { fields: "id, name, shortName,follows,logoAsset,subscriptions" }
    })
    this.brandList = this.$store.getters["brand/brands"]
    this.brandList.forEach(item => {
      item.isPremium = false
      if (item.subscriptions && item.subscriptions.length > 0) {
        if (item.subscriptions[0].type.id === "premium") {
          item.isPremium = true
        } else {
          item.isPremium = false
        }
      }
    })
    this.brandList.unshift(
      { id: "*", name: "All" },
      { id: "basic", name: "Basic" },
      { id: "premium", name: "Premium" }
    )
    this.initOptionCheckedFalse(this.brandList)
    await this.$store.dispatch("retailer/fetchAllRetailers", {
      params: { status: "approved", fields: "name,id,follows,logoAsset" }
    })
    this.retailerList = this.$store.getters["retailer/retailers"]
    this.retailerList.unshift({ id: "*", name: "All" })
    this.initOptionCheckedFalse(this.retailerList)
    //this.getCategory()
    this.getCustomGroup()
  },
  methods: {
    initOptionCheckedFalse(data) {
      if (data && data.length > 0) {
        data.forEach(item => (item.isChecked = false))
      }
    },
    checkBoxClick(isClick) {
      this.canDeactive = !isClick
      console.log(this.canDeactive)
    },
    clickOuter() {
      console.log("...click outer....")
      setTimeout(() => {
        if (this.canDeactive) {
          this.$refs["brandFilter"].deActivate()
          this.$refs["retailerFilter"].deActivate()
          this.$refs["categoryFilter"].deActivate()
          this.$refs["cutomFilter"].deActive()
        }
      }, 3000)
    },
    // async getCategory() {
    //   await this.$store.dispatch("adminMessage/fetchCategorys")
    // },
    async getCustomGroup() {
      await this.$store.dispatch("adminMessage/fetchSearchGroup")
    },
    resetEnabled() {
      this.isRetailerDisalbed = false
      this.isCustomDisabled = false
      this.isBrandDisabled = false
    },
    applyBrandSearchGroup(datas) {
      this.canDeactive = true
      this.filterType = 1
      this.filterDatas = datas
      this.getRecipientData(1, this.filterDatas.data)
      if (this.filterDatas.data && this.filterDatas.data.length > 0) {
        this.isRetailerDisalbed = true
        this.isCustomDisabled = true
        this.isBrandDisabled = false
      } else {
        this.resetEnabled()
      }
    },
    applyRetailerSearchGroup(datas) {
      this.filterType = 2
      this.canDeactive = true
      this.filterDatas = datas
      this.getRecipientData(2, this.filterDatas.data)
      if (this.filterDatas.data && this.filterDatas.data.length > 0) {
        this.isRetailerDisalbed = false
        this.isCustomDisabled = true
        this.isBrandDisabled = true
      } else {
        this.resetEnabled()
      }
    },
    applyCustomSearchGroup(data) {
      this.filterType = 3
      this.customFilters = data
      this.canDeactive = true
      this.getCustomRecipientData(this.customFilters)
      console.log(data)
      if (data && data.filterData && data.filterData.length > 0 && data.filterStartTime) {
        this.isRetailerDisalbed = true
        this.isCustomDisabled = false
        this.isBrandDisabled = true
      } else {
        this.resetEnabled()
      }
    },
    getCustomRecipientData(cFilterData) {
      console.log(cFilterData)
      if (cFilterData && cFilterData.recipient && cFilterData.filterId) {
        let param = {}
        if (cFilterData.recipient === "Brand") {
          param = {
            "client-type-id": "retailer"
          }
          if (cFilterData.filterId !== "*") {
            param["retailer-ids"] = cFilterData.filterId
          }
        } else if (cFilterData.recipient === "Retailer") {
          param = {
            "client-type-id": "brand"
          }
          if (cFilterData.filterId.indexOf("*") < 0) {
            console.log(cFilterData.filterId.indexOf("basic"))
            if (
              cFilterData.filterId.indexOf("basic") >= 0 ||
              cFilterData.filterId.indexOf("premium") >= 0
            ) {
              let basicIndex = cFilterData.filterId.indexOf("basic")
              let premiumIndex = cFilterData.filterId.indexOf("premium")
              let subscribeType = "basic"
              let idsValue = ""
              if (basicIndex < 0) {
                subscribeType = "premium"
                idsValue = cFilterData.filterId.split("premium").join("")
              } else if (premiumIndex < 0) {
                subscribeType = "basic"
                idsValue = cFilterData.filterId.split("basic").join("")
              } else {
                // basic, premium
                subscribeType = "basic,premium"
                let idsStr = cFilterData.filterId.split("basic").join("")
                idsValue = idsStr.split("premium").join("")
              }
              idsValue = idsValue
                .split(",")
                .filter(item => item.length > 0)
                .join(",")
              console.log(idsValue)
              param["subscription-type-id"] = subscribeType
              if (idsValue) {
                param["brand-ids"] = idsValue
              }
            } else {
              param["brand-ids"] = cFilterData.filterId
            }
          }
        }
        if (
          ((cFilterData.filterDateOption && cFilterData.filterDateOption.id !== 1) ||
            (cFilterData.filterStartTime && cFilterData.filterStartTime !== "1779-01-01")) &&
          cFilterData.filterStartTime &&
          cFilterData.filterEndTime
        ) {
          let startTime =
            cFilterData.filterStartTime.indexOf("-") >= 0
              ? cFilterData.filterStartTime
              : moment(cFilterData.filterStartTime, "MM/DD/YYYY").format("YYYY-MM-DD")
          let endTime =
            cFilterData.filterEndTime.indexOf("-") >= 0
              ? cFilterData.filterEndTime
              : moment(cFilterData.filterEndTime, "MM/DD/YYYY").format("YYYY-MM-DD")
          console.log(startTime)
          console.log(endTime)
          param["order-date"] = `${startTime},${endTime}`
        }
        console.log(param)
        this.$store
          .dispatch("adminMessage/getGroupClient", param)
          .then(data => {
            // searched recipient ids
            if (!data || data.length === 0) {
              this.$message.warning("Haven't found any recipients based on current condition.")
            }
            this.recipients = data
            this.recipients.forEach(item => (item.isDelete = false))
            console.log(data)
          })
          .catch(err => {
            console.log(err.toString())
            this.$message.error(err)
          })
      } else {
        this.$message.warning("Please choose the recipient or the filter.")
      }
    },
    getRecipientData(type, data) {
      if (data && data.length > 0) {
        let recipientSet = undefined
        for (let i = 0; i < data.length; i++) {
          if (data[i].id === "*") {
            if (type === 1) {
              recipientSet = new Set(this.brandList.slice(3))
            } else {
              recipientSet = new Set(this.retailerList.slice(1))
            }
            break
          } else if (data[i].id === "basic") {
            let basic = this.brandList.filter(item => item.isPremium === false)
            if (recipientSet === undefined) {
              recipientSet = new Set(basic)
            } else {
              for (let i = 0; i < basic.length; i++) {
                recipientSet.add(basic[i])
              }
            }
          } else if (data[i].id === "premium") {
            let premium = this.brandList.filter(item => item.isPremium === true)
            if (recipientSet === undefined) {
              recipientSet = new Set(premium)
            } else {
              for (let i = 0; i < premium.length; i++) {
                recipientSet.add(premium[i])
              }
            }
          } else {
            if (recipientSet === undefined) {
              recipientSet = new Set()
            }
            recipientSet.add(data[i])
          }
        }
        this.recipients = [...recipientSet]
      } else {
        this.recipients = []
      }
    },
    getFilterClientType(type) {
      if (type === 1) {
        return { id: "Brand" }
      } else if (type === 2) {
        return { id: "Retailer" }
      }
      return type
    },
    getDateStr(dateStr) {
      if (dateStr.indexOf("/") >= 0) {
        const strArray = dateStr.split("/")
        if (strArray.length >= 3) {
          return `${strArray[2]}-${strArray[0].length === 1 ? "0" + strArray[0] : strArray[0]}-${
            strArray[1].length === 1 ? "0" + strArray[1] : strArray[1]
          }`
        } else if (strArray.length === 2) {
          return `${strArray[1]}-${strArray[0].length === 1 ? "0" + strArray[0] : strArray[0]}-01`
        } else {
          return `${strArray[0]}-01-01`
        }
      }
      return dateStr
    },
    saveSearchGroup() {
      console.log(this.filterType)
      let param = {}
      if (this.filterType === -1) {
        // do nothing
        return
      } else {
        console.log(this.recipients)
        let filterNames = ""
        let filterIds = ""
        if (this.recipients && this.recipients.length > 0) {
          for (let i = 0; i < this.recipients.length; i++) {
            if (!this.recipients[i].isDelete) {
              filterNames += `${this.recipients[i].name},`
              filterIds += `${this.recipients[i].id},`
            }
          }
          filterNames = filterNames.substring(0, filterNames.length - 1)
          filterIds = filterIds.substring(0, filterIds.length - 1)
        }
        param.filterClientType = this.filterType
        param.filterClientId = filterIds
        param.filterClientName = filterNames
        // save group
        if (this.filterType === 3) {
          //for custom
          console.log(this.customFilters)
          if (this.customFilters) {
            param.filterClientType = { id: this.customFilters.recipient }
            param.filterClientName = this.customFilters.filterName
            param.filterClientId = this.customFilters.filterId
            param.startDate = this.getDateStr(this.customFilters.filterStartTime)
            param.endDate = this.getDateStr(this.customFilters.filterEndTime)
          } else {
            param.filterClientType = { id: this.customFilters.recipient }
            param.filterClientName = filterNames
            param.filterClientId = filterIds
            param.startDate = this.customFilters.filterStartTime
            param.endDate = this.customFilters.filterEndTime
          }
        } else {
          //for brand/retailer
          if (this.filterDatas) {
            param.filterClientType = this.getFilterClientType(this.filterType)
            param.filterClientName = this.filterDatas.filterName
            param.filterClientId = this.filterDatas.filterId
          } else {
            param.filterClientType = this.getFilterClientType(this.filterType)
            param.filterClientName = filterNames
            param.filterClientId = filterIds
          }
          console.log(this.filterDatas)
        }
        this.doSaveGroup(param)
      }
    },
    doSaveGroup(param) {
      //invoke api to save group
      console.log(param)
      this.$store
        .dispatch("adminMessage/createSearchGroup", param)
        .then(() => {
          this.$message.success("Save Search Group Success")
          this.filterType = param.filterClientType.id === "Brand" ? 1 : 2
          this.filterDatas = undefined
          this.customFilters = undefined
          this.getCustomGroup()
        })
        .catch(err => {
          this.$message.error(err.toString())
        })
    },
    setCategory(data) {
      this.category = data
    },
    clickToGetRecipient(item) {
      this.filterType = item.filterClientType
      if (item.startDate) {
        let cFilterItem = {
          filterStartTime: item.startDate,
          filterEndTime: item.endDate,
          recipient: item.filterClientType.id,
          filterId: item.filterClientId
        }
        this.getCustomRecipientData(cFilterItem)
      } else {
        this.generateRecipientFromCondition(item)
      }
    },
    generateRecipientFromCondition(item) {
      let filterIds = item.filterClientId.split(",")
      let filterNames = item.filterClientName.split(",")
      let result = []
      for (let i = 0; i < filterIds.length; i++) {
        result.push({ id: filterIds[i], name: filterNames[i] })
      }
      this.getRecipientData(item.recipient, result)
    },
    getSendRecipients() {
      return this.recipients.filter(item => !item.isDelete)
    },
    sendDraft(data) {
      this.isDraft = true
      this.sendMessage(data)
    },
    sendMessage(data) {
      if (!this.category) {
        this.$message.error("Please choose the category value")
        return
      }
      console.log(this.isDraft)
      let param = {
        senderClientId: "*",
        // messageType: "1",
        category: { id: this.category.description },
        //templatePrivateId: 1,
        subject: this.subject,
        drafts: this.isDraft,
        archive: false,
        isActive: true, // false删除 默认true
        message: data,
        messageFiles: data.files ? data.files : [],
        messageProducts: []
        // messageDetailss: [
        //   {
        //     messageDetails: {
        //       message: data.message
        //     },
        //     messageDetailsFiles: data.files
        //   }
        // ]
      }
      console.log(param)

      let type = this.filterType === 3 ? this.customFilters.recipient : this.filterType.toString()
      let receivers = []
      console.log(this.filterType)
      let filterData = this.getSendRecipients()
      console.log(type)
      console.log(filterData)
      for (let i = 0; i < filterData.length; i++) {
        receivers.push({
          isRead: false,
          receiverClientId: filterData[i].id,
          isActive: true,
          archive: false
        })
      }
      console.log(receivers)
      param["messageReceivers"] = receivers
      this.$store
        .dispatch("adminMessage/createMessage", param)
        .then(res => {
          console.log(res)
          this.$message.success("send Message success")
          this.$router.push({
            name: "adminMessaging"
          })
        })
        .catch(err => {
          console.log(err.toString())
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-message-compose {
  width: 953px;
  margin: 0 auto;
  &__title {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #262626;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  &__description {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #262626;
    margin-bottom: 4px;
  }
  &__selection-outer {
    width: 244px;
    margin-right: 20px;
    float: left;
  }
  &__selection-bg {
    background: #f5f5f5;
    width: 100%;
    border-radius: 2px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #000000;
  }
  &__save-btn {
    background: #e2eeec;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #4a8a5d;
  }
  &__row-margin {
    margin-top: 8px;
  }
}
</style>
<style lang="scss">
.admin-message-compose .ant-btn:hover,
.admin-message-compose .ant-btn:focus,
.admin-message-compose .ant-btn:active {
  background: #e2eeec;
  border: 1px solid #4a8a5d;
  color: #4a8a5d;
}
.admin-compose-message__category.ant-select-dropdown {
  z-index: 1050;
  padding-bottom: 20px;
  padding-left: 10px;
}
</style>
