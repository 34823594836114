<template>
  <div class="share-link-dialog__main">
    <a-modal
      v-model="shareVisible"
      width="756px"
      :footer="null"
      :mask="false"
      :mask-closable="false"
    >
      <span slot="closeIcon" @click="cancelDialog">
        <a-icon type="close-circle" style="font-size: 20px" />
      </span>
      <div class="share-link-content">
        <div class="title-text-img">
          <p class="title-img">
            <a-icon type="share-alt" />
          </p>
          <p class="title-text">{{ this.$t("Training.shareTitleText") }}</p>
        </div>
        <div class="link-text">
          {{ copyText }}
        </div>
        <div class="link-btn">
          <a-button id="copyBtn" class="link-btn-text" @click="copyLinkText">
            {{ this.$t("ButtonText.copyLink") }}
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Clipboard from "clipboard"
import { mapState } from "vuex"

export default {
  name: "ShareLinkDialog",
  data() {
    return {
      shareVisible: false,
      clipboard: null
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.businessId,
      userName: state => state.brand.brand.shortName
    }),
    copyText() {
      // return `${window.location.origin}/TrainingProductViewAll?id=${this.userId}&name=${this.userName}`
      return `${window.location.origin}/training/${this.userName}`
    }
  },
  mounted() {
    this.initCopyBtn()
  },
  destroyed() {
    if (this.clipboard && this.shareVisible) {
      this.clipboard.destroyed()
    }
  },
  methods: {
    openDialog(visible) {
      this.shareVisible = visible
    },
    cancelDialog() {
      this.shareVisible = false
    },
    initCopyBtn() {
      this.clipboard = new Clipboard("#copyBtn", { text: () => this.copyText })
    },
    copyLinkText() {
      this.clipboard.once("success", () => {
        this.$message.success("Copy success")
      })
      this.clipboard.once("error", err => {
        console.error(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
//@font-face {
//  font-family: "Proxima Nova";
//  src: url("@/assets/fonts/Proxima-Nova-Light.otf")
//  format('truetype')
//}

.share-link-dialog__main {
}
.share-link-content {
  padding: 150px 212px;
  //width: 80%;
  //margin: 0 auto;
  text-align: center;
  .title-img {
    width: 136px;
    height: 136px;
    line-height: 126px;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 42px;
    color: #4a8a5d;
    background-color: #e2eeec;
    border: 4px solid #4a8a5d;
  }
  .title-text {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    margin: 22px 0;
  }
  .link-text {
    width: 100%;
    padding: 5px 0;
    //height: 18px;
    font-size: 13px;
    line-height: 15px;
    color: #595959;
    border: 1px solid #bfbfbf;
    background-color: #f5f5f5;
    margin-bottom: 22px;
  }
  .link-btn-text {
    width: 94px;
    height: 30px;
    border: 1px solid #4a8a5d;
    color: #4a8a5d;
    background-color: #e2eeec;
  }
}
</style>
