<template>
  <div class="admin-inventory">
    <a-row class="admin-inventory__header">
      <a-col :span="24">
        <a-row>
          <span class="admin-inventory__header__title" style="float: left;">{{
            $t("Admins.AdminInventory.inventory")
          }}</span>
          <a-upload
            name="file"
            :multiple="false"
            :show-upload-list="false"
            :file-list="fileList"
            :custom-request="doUpload"
            :before-upload="beforeUpload"
            style="float: right;"
          >
            <a-button
              class="admin-inventory__download-btn admin-inventory__header__download"
              style="margin-left: 10px;"
            >
              {{ $t("Admins.AdminInventory.upload") }}
            </a-button>
          </a-upload>
          <a-button
            class="admin-inventory__download-btn admin-inventory__header__download"
            style="float: right;"
            @click="clickToDownloadData"
          >
            {{ $t("Admins.AdminInventory.csv") }}
            <a-icon type="download" />
          </a-button>
        </a-row>
        <a-row>
          <div class="admin-inventory__header__search">
            <a-select
              v-if="isViewByProducts === '2'"
              :value="filterInventoryType"
              dropdown-class-name="admin-inventory__header__dropdown-menu-content"
              class="admin-inventory__header__dropdown-menu"
              @change="handleFilterTypeChange"
              @getPopupContainer="filterSelectPopup"
            >
              <template v-for="type in inventoryType">
                <a-select-option
                  v-if="filterInventoryType !== type.description"
                  :key="type.name"
                  :value="type.description"
                >
                  {{ type.description }}
                </a-select-option>
              </template>
            </a-select>
            <a-select
              v-model="searchValue"
              mode="combobox"
              class="admin-inventory__header__search-input"
              :class="{ 'admin-inventory__full-width': isViewByProducts !== '2' }"
              dropdown-class-name="admin-inventory__header__search-dropdown"
              :placeholder="$t('Admins.AdminInventory.searchHint')"
              :allow-clear="true"
              option-label-prop="value"
              @inputKeydown="pressEnterToSearch"
              @select="onSearchChange"
              @change="clearSearchText"
            >
              <a-select-option v-for="item in searchedHistoryArray" :key="item" :value="item">
                <a-row>
                  <a-col :span="23">
                    <a-icon type="search" style="margin-right:  11px;" />
                    <span>{{ item }}</span>
                  </a-col>
                  <a-col :span="1">
                    <a-icon
                      type="close"
                      style="margin-right: 2px; font-size: 14px;"
                      @click.stop="removeCachedData(item)"
                    />
                  </a-col>
                </a-row>
              </a-select-option>
            </a-select>
            <a-icon
              type="search"
              style="font-size: 12px; z-index: 44; position: absolute; right: 47.5%; top: 8px;"
              @click="clickToSearch"
            />
          </div>
          <div class="admin-inventory__header__tab">
            <span class="admin-inventory__header__tab-text">
              <img src="~@/assets/img/inventory/view_by.png" style="width: 20px; height: 20px;" />
              {{ $t("Admins.AdminInventory.viewBy") }}
            </span>
            <span
              class="admin-inventory__header__tab-text"
              :class="{ 'admin-inventory__header__tab-selected': isViewByProducts === '0' }"
              @click="clickToChangeViewBy('0')"
            >
              {{ $t("Admins.AdminInventory.products") }}
            </span>
            <span
              class="admin-inventory__header__tab-text"
              :class="{ 'admin-inventory__header__tab-selected': isViewByProducts === '1' }"
              @click="clickToChangeViewBy('1')"
            >
              {{ $t("Admins.AdminInventory.brands") }}
            </span>
            <span
              class="admin-inventory__header__tab-text"
              :class="{ 'admin-inventory__header__tab-selected': isViewByProducts === '2' }"
              @click="clickToChangeViewBy('2')"
            >
              {{ $t("Admins.AdminInventory.expiration") }}
            </span>
          </div>
        </a-row>
      </a-col>
    </a-row>
    <a-row class="admin-inventory__content">
      <div v-if="!isViewByProducts || isViewByProducts === '0'">
        <a-table
          id="productInventoryTable"
          :columns="productColumns"
          :data-source="productReportList"
          :row-key="record => record.privateId"
          bordered
          :loading="loadingProducts"
          :pagination="false"
        >
          <span slot="customCheckTitle">
            <a-checkbox v-model="isCheckAllProduct" @change="handleSelectAllProductReport" />
          </span>
          <span slot="modifyDate" slot-scope="text">{{ formatDate(text) }}</span>
          <span slot="customModifiedTitle">
            {{ $t("Admins.AdminInventory.modified") }}
            <a-icon
              type="down"
              :rotate="productSortModifiedAes ? 180 : 0"
              @click="sortByModified"
            />
          </span>
          <span slot="customProductTitle">
            {{ $t("Admins.AdminInventory.product") }}
            <a-icon
              type="down"
              :rotate="productSortProductAes ? 180 : 0"
              @click="sortByProductName"
            />
          </span>
          <span slot="customBrandTitle">
            {{ $t("Admins.AdminInventory.brand") }}
            <a-icon type="down" :rotate="productSortBrandAes ? 180 : 0" @click="sortByBrandName" />
          </span>
          <span slot="customSkuTitle">
            {{ $t("Admins.AdminInventory.sku") }}
            <a-icon type="down" :rotate="productSortSkuAes ? 180 : 0" @click="sortBySkuNumber" />
          </span>
          <span slot="customTotalTitle">
            {{ $t("Admins.AdminInventory.total") }}
            <a-icon type="down" :rotate="productSortTotalAes ? 180 : 0" @click="sortByTotal" />
          </span>
          <span slot="customAllocatedTitle">
            {{ $t("Admins.AdminInventory.allocated") }}
            <a-icon
              type="down"
              :rotate="productSortAllocatedAes ? 180 : 0"
              @click="sortByAllocated"
            />
          </span>
          <span slot="customAvailableTitle">
            {{ $t("Admins.AdminInventory.available") }}
            <a-icon
              type="down"
              :rotate="productSortAvailableAes ? 180 : 0"
              @click="sortByAvailable"
            />
          </span>
          <span slot="checkProductAction" slot-scope="text, record">
            <a-checkbox
              :checked="text"
              @change="checkedValue => chooseProduct(checkedValue, record)"
            />
          </span>
          <span
            slot="historyProductView"
            slot-scope="text, record"
            class="admin-inventory__content__product-view-action"
            @click="clickToProductHistory(record)"
          >
            {{ $t("Admins.AdminInventory.view") }}
          </span>
          <span
            slot="productNameValue"
            slot-scope="text, record"
            class="admin-inventory__content__name-value"
            @click="clickToProductPage(record)"
          >
            <a-tooltip v-if="text && text.length > maxTextLen" placement="topLeft" :title="text">
              <span>{{ text }}</span>
            </a-tooltip>
            <span v-else>{{ text }}</span>
          </span>
          <span
            slot="brandNameValue"
            slot-scope="text, record"
            class="admin-inventory__content__name-value"
            @click="clickToBrandInventoryPage(record)"
          >
            <a-tooltip v-if="text && text.length > maxTextLen" placement="topLeft" :title="text">
              <span>{{ text }}</span>
            </a-tooltip>
            <span v-else>{{ text }}</span>
          </span>
        </a-table>
        <a-pagination
          class="inventoryPagination"
          style="margin-top: 10px; float: right;"
          :default-page-size="50"
          :default-current="1"
          :total="productReport.total"
          size="small"
          :show-quick-jumper="true"
          @change="getProductPageData"
        />
      </div>

      <div v-if="isViewByProducts === '1'" id="brandProductInventory">
        <a-row class="admin-inventory__content__brand-table-head">
          <a-col :span="1" class="admin-inventory__content__brand-table-head-col">
            <a-checkbox v-model="isCheckAllBrand" @change="handleSelectAllBrandReport" />
          </a-col>
          <a-col :span="6" class="admin-inventory__content__brand-table-head-col">
            <span class="admin-inventory__float-left">Brand</span>
            <a-icon
              type="down"
              :rotate="brandSortByBrandAes ? 180 : 0"
              class="admin-inventory__float-right"
              @click="sortByBrandName"
            />
          </a-col>
          <a-col :span="10" class="admin-inventory__content__brand-table-head-col">
            <span class="admin-inventory__float-left">
              {{ $t("Admins.AdminInventory.numberOfSku") }}
            </span>
            <a-icon
              type="down"
              :rotate="brandSortBySkuNumberAes ? 180 : 0"
              class="admin-inventory__float-right"
              @click="sortBySkuNumber"
            />
          </a-col>
          <a-col :span="2" class="admin-inventory__content__brand-table-head-col">
            <span class="admin-inventory__float-left">{{ $t("Admins.AdminInventory.total") }}</span>
            <a-icon
              type="down"
              :rotate="brandSortByTotalAes ? 180 : 0"
              class="admin-inventory__float-right"
              @click="sortByTotal"
            />
          </a-col>
          <a-col :span="2" class="admin-inventory__content__brand-table-head-col">
            <span class="admin-inventory__float-left">
              {{ $t("Admins.AdminInventory.allocated") }}
            </span>
            <a-icon
              type="down"
              :rotate="brandSortByAllocatedAes ? 180 : 0"
              class="admin-inventory__float-right"
              @click="sortByAllocated"
            />
          </a-col>
          <a-col :span="2" class="admin-inventory__content__brand-table-head-col">
            <span class="admin-inventory__float-left">
              {{ $t("Admins.AdminInventory.available") }}
            </span>
            <a-icon
              type="down"
              :rotate="brandSortAvailableAes ? 180 : 0"
              class="admin-inventory__float-right"
              @click="sortByAvailable"
            />
          </a-col>
          <a-col :span="1" class="admin-inventory__content__brand-table-head-col">
            {{ $t("Admins.AdminInventory.action") }}
          </a-col>
        </a-row>
        <div
          v-if="!brandReportList || brandReportList.length === 0"
          style="height: 200px; border: 1px solid #d8d8d8;"
        >
          <a-spin v-if="loadingBrands" style="margin-top: 80px;" />
          <a-empty v-else :image="simpleImage" />
        </div>
        <a-collapse v-else v-model="openedBrand" accordion expand-icon-position="right">
          <template #expandIcon="props">
            <a-icon type="down" :rotate="props.isActive ? 180 : 0" />
          </template>
          <a-collapse-panel v-for="data in brandReportList" :key="data.brandId">
            <template #header>
              <a-row>
                <a-col :span="1">
                  <a-checkbox
                    :checked="data.isChecked"
                    @click.stop
                    @change="checkedValue => chooseBrandProduct(checkedValue, data)"
                  />
                </a-col>
                <a-col
                  :span="6"
                  class="admin-inventory__content__brand-name-value admin-inventory__content__brand-table-title-left"
                  @click="clickToBrandInventoryPage(data)"
                >
                  <a-tooltip
                    v-if="data.brandName && data.brandName.length > 18"
                    placement="topLeft"
                    :title="data.brandName"
                  >
                    <span>{{ data.brandName }}</span>
                  </a-tooltip>
                  <span v-else>{{ data.brandName }}</span>
                </a-col>
                <a-col :span="10" class="admin-inventory__content__brand-table-title-left">
                  <a-row>
                    <a-col :span="16">{{ data.noOfSkus }}</a-col>
                    <a-col
                      v-if="openedBrand && openedBrand === data.brandId"
                      :span="8"
                      style="padding-left: 4px;"
                    >
                      {{ $t("Admins.AdminInventory.sku") }}
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="2" class="admin-inventory__content__brand-table-title-left">
                  {{ data.total }}
                </a-col>
                <a-col :span="2" class="admin-inventory__content__brand-table-title-left">
                  {{ data.allocated }}
                </a-col>
                <a-col :span="2" class="admin-inventory__content__brand-table-title-left">
                  {{ data.available }}
                </a-col>
                <a-col :span="1"> </a-col>
              </a-row>
            </template>
            <a-row
              v-if="!data.productInventoryReport || data.productInventoryReport.length === 0"
              class="admin-inventory__content__brand-table-content"
            >
              <a-spin v-if="loadingProducts" />
              <div v-else>
                {{ $t("Admins.AdminInventory.noData") }}
              </div>
            </a-row>
            <div v-else class="admin-inventory__content__brand-table-content">
              <a-row
                v-for="(productData, index) in data.productInventoryReport"
                :key="`brand_product_${index}`"
              >
                <a-col :span="7"></a-col>
                <a-col :span="10">
                  <a-row>
                    <a-col
                      :span="16"
                      class="admin-inventory__content__brand-name-value"
                      @click="clickToProductPage(productData)"
                    >
                      <a-tooltip
                        v-if="productData.productName && productData.productName.length > 18"
                        placement="topLeft"
                        :title="productData.productName"
                      >
                        <span>{{ productData.productName }}</span>
                      </a-tooltip>
                      <span v-else>{{ productData.productName }}</span>
                    </a-col>
                    <a-col :span="8" style="padding-left: 4px;">
                      {{ productData.sku }}
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="2" style="padding-left: 13px;">
                  {{ productData.total }}
                </a-col>
                <a-col :span="2" style="padding-left: 16px;">
                  {{ productData.allocated }}
                </a-col>
                <a-col :span="2" style="padding-left: 19px;">
                  {{ productData.available }}
                </a-col>
                <a-col :span="1" style="padding-left: 22px;">
                  {{ productData.locationName }}
                </a-col>
              </a-row>
            </div>
          </a-collapse-panel>
        </a-collapse>
        <a-pagination
          style="margin-top: 10px; float: right;"
          :default-page-size="pageSize"
          :default-current="1"
          :total="brandReport.total"
          size="small"
          :show-quick-jumper="true"
          @change="clickBrandPagination"
        >
        </a-pagination>
      </div>
      <div v-if="isViewByProducts === '2'">
        <a-table
          id="expirationInventoryTable"
          :columns="expirationColumns"
          :data-source="expirationReportList"
          :row-key="record => `expiration_product_${record.privateId}`"
          :pagination="false"
          :loading="loadingExpiration"
          bordered
        >
          <span slot="customCheckTitle">
            <a-checkbox v-model="isCheckAllExpiration" @change="handleSelectAllExpireReport" />
          </span>
          <span slot="customExpirationTitle">
            {{ $t("Admins.AdminInventory.expiration") }}
            <a-icon
              type="down"
              :rotate="expirationSortByExpirateAes ? 180 : 0"
              @click="sortByExpiration"
            />
          </span>
          <span slot="customProductTitle">
            {{ $t("Admins.AdminInventory.product") }}
            <a-icon
              type="down"
              :rotate="expirationSortProductAes ? 180 : 0"
              @click="sortByProductName"
            />
          </span>
          <span slot="customBrandTitle">
            {{ $t("Admins.AdminInventory.brand") }}
            <a-icon
              type="down"
              :rotate="expirationSortBrandAes ? 180 : 0"
              @click="sortByBrandName"
            />
          </span>
          <span slot="customAvailableTitle">
            {{ $t("Admins.AdminInventory.available") }}
            <a-icon
              type="down"
              :rotate="expirationSortAvailableAes ? 180 : 0"
              @click="sortByAvailable"
            />
          </span>
          <span slot="expirateDate" slot-scope="text">{{ formatDate(text) }}</span>
          <span slot="checkAction" slot-scope="text, record">
            <a-checkbox
              :checked="text"
              @change="checkedValue => chooseExpireProduct(checkedValue, record)"
            />
          </span>
          <span
            slot="expirateProductNameValue"
            slot-scope="text, record"
            class="admin-inventory__content__name-value"
            @click="clickToProductPage(record)"
          >
            <a-tooltip v-if="text && text.length > maxTextLen" placement="topLeft" :title="text">
              <span>{{ text }}</span>
            </a-tooltip>
            <span v-else>{{ text }}</span>
          </span>
          <span
            slot="expirateBrandNameValue"
            slot-scope="text, record"
            class="admin-inventory__content__name-value"
            @click="clickToBrandInventoryPage(record)"
          >
            <a-tooltip v-if="text && text.length > maxTextLen" placement="topLeft" :title="text">
              <span>{{ text }}</span>
            </a-tooltip>
            <span v-else>{{ text }}</span>
          </span>
          <span
            slot="historyView"
            slot-scope="text, record"
            class="admin-inventory__content__product-view-action"
            @click="clickToProductHistory(record)"
          >
            {{ $t("Admins.AdminInventory.view") }}
          </span>
        </a-table>
        <a-pagination
          class="inventoryPagination"
          style="margin-top: 10px; float: right;"
          :default-page-size="50"
          :default-current="1"
          :total="expirationReport.total"
          size="small"
          :show-quick-jumper="true"
          @change="getExpirationPageData"
        />
      </div>
    </a-row>
    <uploadProductDialog ref="uploadProduct" @doUpload="doUpload"></uploadProductDialog>
  </div>
</template>

<script>
import {
  downloadCsvData,
  exportOrderExcel,
  getNowDateStr,
  replaceCommaForCsvStr
} from "@/utils/validate"
import uploadProductDialog from "@/components/brands/catalog/uploadProductDialog"
import { Empty } from "ant-design-vue"
const OPTIONS = ["Apples", "Nails", "Bananas", "Helicopters"]
export default {
  components: {
    uploadProductDialog
  },
  data() {
    return {
      OPTIONS,
      uploading: false,
      productColumns: [
        {
          key: "isCheck",
          dataIndex: "isChecked",
          slots: { title: "customCheckTitle" },
          scopedSlots: { customRender: "checkProductAction" },
          width: "30px"
        },
        {
          dataIndex: "createdAt",
          width: "8%",
          slots: { title: "customModifiedTitle" },
          scopedSlots: { customRender: "modifyDate" }
        },
        {
          dataIndex: "productName",
          slots: { title: "customProductTitle" },
          scopedSlots: { customRender: "productNameValue" },
          ellipsis: true,
          width: "23%"
        },
        {
          dataIndex: "brandName",
          slots: { title: "customBrandTitle" },
          scopedSlots: { customRender: "brandNameValue" },
          ellipsis: true,
          width: "18%"
        },
        {
          slots: { title: "customSkuTitle" },
          dataIndex: "sku",
          width: "7%"
        },
        {
          slots: { title: "customTotalTitle" },
          dataIndex: "total",
          width: "8%"
        },
        {
          slots: { title: "customAllocatedTitle" },
          dataIndex: "allocated",
          width: "8%"
        },
        {
          dataIndex: "available",
          slots: { title: "customAvailableTitle" },
          width: "10%"
        },
        {
          title: this.$t("Admins.AdminInventory.lots"),
          dataIndex: "lotCount",
          width: "5%"
        },
        {
          title: this.$t("Admins.AdminInventory.location"),
          dataIndex: "locationName",
          width: "7%"
        },
        {
          title: this.$t("Admins.AdminInventory.history"),
          dataIndex: "history",
          scopedSlots: { customRender: "historyProductView" },
          width: "7%"
        }
      ],
      expirationColumns: [
        {
          key: "isCheck",
          dataIndex: "isChecked",
          slots: { title: "customCheckTitle" },
          scopedSlots: { customRender: "checkAction" },
          width: "42px"
        },
        {
          dataIndex: "expirationDate",
          slots: { title: "customExpirationTitle" },
          scopedSlots: { customRender: "expirateDate" },
          width: "12%"
        },
        {
          dataIndex: "brandName",
          slots: { title: "customBrandTitle" },
          scopedSlots: { customRender: "expirateBrandNameValue" },
          width: "20%"
        },
        {
          slots: { title: "customProductTitle" },
          dataIndex: "productName",
          scopedSlots: { customRender: "expirateProductNameValue" },
          width: "28%"
        },
        {
          title: this.$t("Admins.AdminInventory.type"),
          dataIndex: "typeName",
          width: "9%"
        },
        {
          title: this.$t("Admins.AdminInventory.lotNo"),
          dataIndex: "lotNo",
          width: "9%"
        },
        {
          slots: { title: "customAvailableTitle" },
          dataIndex: "available",
          width: "9%"
        },
        {
          title: this.$t("Admins.AdminInventory.history"),
          dataIndex: "history",
          scopedSlots: { customRender: "historyView" },
          width: "7%"
        }
      ],
      isNameDesc: false,
      text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,
      // inventory data
      isViewByProducts: "0",
      loadingBrands: false,
      loadingProducts: false,
      loadingExpiration: false,
      isCheckAllProduct: false,
      isCheckAllBrand: false,
      isCheckAllExpiration: false,
      productSortModifiedAes: false,
      productSortProductAes: false,
      productSortBrandAes: false,
      productSortSkuAes: false,
      productSortTotalAes: false,
      productSortAvailableAes: false,
      productSortAllocatedAes: false,
      brandSortAvailableAes: false,
      brandSortByBrandAes: false,
      brandSortBySkuNumberAes: false,
      brandSortByTotalAes: false,
      brandSortByAllocatedAes: false,
      expirationSortByExpirateAes: false,
      expirationSortProductAes: false,
      expirationSortBrandAes: false,
      expirationSortAvailableAes: false,
      inventoryType: [{ id: "0", name: "all", description: "All" }],
      filterInventoryType: "All",
      filterInventoryTypeObj: undefined,
      selectedProductsInProductView: new Set(),
      selectedProductsInExpireView: new Set(),
      selectedProductsInBrandView: new Set(),
      openedBrand: undefined,
      pageSize: 50,
      maxTextLen: 30,
      productCurrentPage: 1,
      brandCurrentPage: 1,
      expirationPage: 1,
      searchValue: "",
      searchedHistory: new Set(),
      searchedHistoryArray: [],
      isShowDown: false,
      fileList: undefined,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      productSortParam: undefined,
      brandSortParam: undefined,
      expirationSortParam: { "_sort-by": "expiration_date", "_order-by": "PREVIOUS" },
      expireSearchValue: "",
      productSearchValue: "",
      brandSearchValue: "",
      messageShowTime: 5
    }
  },
  computed: {
    productReportList() {
      return this.$store.getters["inventory/productReportList"]
    },
    productReport() {
      return this.$store.getters["inventory/productReport"]
    },
    brandReportList() {
      return this.$store.getters["inventory/brandList"]
    },
    brandReport() {
      return this.$store.getters["inventory/brandReport"]
    },
    expirationReportList() {
      return this.$store.getters["inventory/expirationReportList"]
    },
    expirationReport() {
      return this.$store.getters["inventory/expirationReport"]
    }
  },
  async beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
    await this.$store.dispatch("reference/setReference")
    let data = this.$store.getters["reference/inventoryType"]
    this.inventoryType.push(...data)
  },
  created() {
    this.isViewByProducts = localStorage.getItem("adminInventoryViewByValue")
    if (!this.isViewByProducts) {
      this.isViewByProducts = "0"
    }
    let jsonStr = localStorage.getItem("adminInventorySearchHistory")
    if (jsonStr) {
      let searchHistoryArr = JSON.parse(jsonStr)
      if (searchHistoryArr && searchHistoryArr.length > 0) {
        this.searchedHistoryArray = searchHistoryArr
        searchHistoryArr.forEach(item => {
          if (item) {
            this.searchedHistory.add(item)
          }
        })
      }
    } else {
      this.searchedHistoryArray = []
    }
    let param = this.getBaseParam(1)
    param["_order-by"] = "PREVIOUS"
    this.getData(param)
  },
  methods: {
    handleChange(selectedItems) {
      this.selectedItems = selectedItems
    },
    clickBrandPagination(page) {
      this.brandCurrentPage = page
      this.getDataInBrandView(this.getBrandParam())
    },
    filterSelectPopup(triggerNode) {
      return triggerNode.parent
    },
    getBaseParam(page) {
      let param = {
        _limit: this.pageSize,
        _index: page
      }
      return param
    },
    getProductParam() {
      let param = this.getBaseParam(this.productCurrentPage)
      if (this.productSearchValue && this.productSearchValue.length > 0) {
        param = Object.assign({}, param, { keyword: this.productSearchValue })
      }
      if (this.productSortParam) {
        return Object.assign({}, param, this.productSortParam)
      } else {
        param["_order-by"] = "PREVIOUS"
        return param
      }
    },
    getBrandParam() {
      let param = this.getBaseParam(this.brandCurrentPage)
      if (this.brandSearchValue && this.brandSearchValue.length > 0) {
        param = Object.assign({}, param, { keyword: this.brandSearchValue })
      }
      if (this.brandSortParam) {
        return Object.assign({}, param, this.brandSortParam)
      } else {
        param["_order-by"] = "PREVIOUS"
        return param
      }
    },
    getExpireParam() {
      let param = this.getBaseParam(this.expirationPage)
      if (this.expireSearchValue && this.expireSearchValue.length > 0) {
        param = Object.assign({}, param, { keyword: this.expireSearchValue })
      }
      if (this.filterInventoryTypeObj && this.filterInventoryTypeObj.id !== "0") {
        param["type-id"] = this.filterInventoryTypeObj.name
      }
      if (this.expirationSortParam) {
        return Object.assign({}, param, this.expirationSortParam)
      } else {
        param["_order-by"] = "PREVIOUS"
        return param
      }
    },
    handleFilterTypeChange(value) {
      this.filterInventoryType = value
      this.expirationPage = 1
      if (value !== "All") {
        let type = this.inventoryType.filter(item => item.description === this.filterInventoryType)
        if (type && type.length) {
          this.filterInventoryTypeObj = type[0]
          this.getData(this.getExpireParam())
        }
      } else {
        this.filterInventoryTypeObj = {}
        this.getData(this.getExpireParam())
      }
    },
    async getReferenceData() {
      await this.$store.dispatch("reference/setReference")
      let data = this.$store.getters["reference/inventoryType"]
      this.inventoryType.push(...data)
    },
    handleSelectAllProductReport() {
      this.selectedProductsInProductView.clear()
      this.productReportList.forEach(item => {
        item.isChecked = this.isCheckAllProduct
        if (this.isCheckAllProduct) {
          this.selectedProductsInProductView.add(item)
        }
      })
    },
    handleSelectAllExpireReport() {
      this.selectedProductsInExpireView.clear()
      this.expirationReportList.forEach(item => {
        item.isChecked = this.isCheckAllExpiration
        if (this.isCheckAllExpiration) {
          this.selectedProductsInExpireView.add(item)
        }
      })
    },
    handleSelectAllBrandReport() {
      this.selectedProductsInBrandView.clear()
      this.brandReportList.forEach(item => {
        item.isChecked = this.isCheckAllBrand
        this.selectedProductsInBrandView.add(item)
      })
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    chooseProduct(checkedValue, record) {
      record.isChecked = checkedValue.target.checked
      if (checkedValue.target.checked) {
        // add record into the list
        this.selectedProductsInProductView.add(record)
      } else {
        // remove it form list
        this.selectedProductsInProductView.delete(record)
        if (this.isCheckAllProduct) {
          this.isCheckAllProduct = false
        }
      }
    },
    chooseExpireProduct(checkedValue, record) {
      record.isChecked = checkedValue.target.checked
      if (checkedValue.target.checked) {
        // add record into the list
        this.selectedProductsInExpireView.add(record)
      } else {
        // remove it form list
        this.selectedProductsInExpireView.delete(record)
        if (this.isCheckAllExpiration) {
          this.isCheckAllExpiration = false
        }
      }
    },
    chooseBrandProduct(checkedValue, record) {
      record.isChecked = checkedValue.target.checked
      if (checkedValue.target.checked) {
        // add record into the list
        this.selectedProductsInBrandView.add(record)
      } else {
        // remove it form list
        this.selectedProductsInBrandView.delete(record)
      }
    },
    sortByModified() {
      this.productSortModifiedAes = !this.productSortModifiedAes
      this.productCurrentPage = 1
      this.productSortParam = {
        "_sort-by": "create_at",
        "_order-by": this.productSortModifiedAes ? "NEXT" : "PREVIOUS"
      }
      let param = this.getProductParam()
      this.getDataInProdutView(param)
    },
    sortByProductName() {
      if (this.isViewByProducts === "0") {
        this.productSortProductAes = !this.productSortProductAes
        this.productCurrentPage = 1
        this.productSortParam = {
          "_sort-by": "product_name",
          "_order-by": this.productSortProductAes ? "NEXT" : "PREVIOUS"
        }
        let param = this.getProductParam()
        this.getDataInProdutView(param)
      } else if (this.isViewByProducts === "2") {
        this.expirationSortProductAes = !this.expirationSortProductAes
        this.expirationPage = 1
        this.expirationSortParam = {
          "_sort-by": "product_name",
          "_order-by": this.expirationSortProductAes ? "NEXT" : "PREVIOUS"
        }
        let param = this.getExpireParam()
        this.getDataInExpirationDateView(param)
      }
    },
    sortByAvailable() {
      if (this.isViewByProducts === "0") {
        this.productSortAvailableAes = !this.productSortAvailableAes
        this.productCurrentPage = 1
        this.productSortParam = {
          "_sort-by": "available",
          "_order-by": this.productSortAvailableAes ? "NEXT" : "PREVIOUS"
        }
        let param = this.getProductParam()
        this.getDataInProdutView(param)
      } else if (this.isViewByProducts === "1") {
        this.sortBrandView(4)
      } else if (this.isViewByProducts === "2") {
        this.expirationSortAvailableAes = !this.expirationSortAvailableAes
        this.expirationPage = 1
        this.expirationSortParam = {
          "_sort-by": "available",
          "_order-by": this.expirationSortAvailableAes ? "NEXT" : "PREVIOUS"
        }
        let param = this.getExpireParam()
        this.getDataInExpirationDateView(param)
      }
    },
    sortByAllocated() {
      if (this.isViewByProducts === "1") {
        this.sortBrandView(3)
      } else if (this.isViewByProducts === "0") {
        this.productSortAllocatedAes = !this.productSortAllocatedAes
        this.productCurrentPage = 1
        this.productSortParam = {
          "_sort-by": "allocated",
          "_order-by": this.productSortAllocatedAes ? "NEXT" : "PREVIOUS"
        }
        let param = this.getProductParam()
        this.getDataInProdutView(param)
      }
    },
    sortByTotal() {
      if (this.isViewByProducts === "1") {
        this.sortBrandView(2)
      } else if (this.isViewByProducts === "0") {
        this.productSortTotalAes = !this.productSortTotalAes
        this.productCurrentPage = 1
        this.productSortParam = {
          "_sort-by": "total",
          "_order-by": this.productSortTotalAes ? "NEXT" : "PREVIOUS"
        }
        let param = this.getProductParam()
        this.getDataInProdutView(param)
      }
    },
    sortBySkuNumber() {
      if (this.isViewByProducts === "1") {
        this.sortBrandView(1)
      } else if (this.isViewByProducts === "0") {
        this.productSortSkuAes = !this.productSortSkuAes
        this.productCurrentPage = 1
        this.productSortParam = {
          "_sort-by": "sku",
          "_order-by": this.productSortSkuAes ? "NEXT" : "PREVIOUS"
        }
        let param = this.getProductParam()
        this.getDataInProdutView(param)
      }
    },
    sortBrandView(sortValue) {
      this.openedBrand = undefined
      this.brandCurrentPage = 1
      if (sortValue === 4) {
        this.brandSortAvailableAes = !this.brandSortAvailableAes
        this.brandSortParam = {
          "_sort-by": "available",
          "_order-by": this.brandSortAvailableAes ? "NEXT" : "PREVIOUS"
        }
      } else if (sortValue === 3) {
        this.brandSortByAllocatedAes = !this.brandSortByAllocatedAes
        this.brandSortParam = {
          "_sort-by": "allocated",
          "_order-by": this.brandSortByAllocatedAes ? "NEXT" : "PREVIOUS"
        }
      } else if (sortValue === 2) {
        this.brandSortByTotalAes = !this.brandSortByTotalAes
        this.brandSortParam = {
          "_sort-by": "total",
          "_order-by": this.brandSortByTotalAes ? "NEXT" : "PREVIOUS"
        }
      } else if (sortValue === 1) {
        this.brandSortBySkuNumberAes = !this.brandSortBySkuNumberAes
        this.brandSortParam = {
          "_sort-by": "noOfSkus",
          "_order-by": this.brandSortBySkuNumberAes ? "NEXT" : "PREVIOUS"
        }
      } else if (sortValue === 0) {
        this.brandSortByBrandAes = !this.brandSortByBrandAes
        this.brandSortParam = {
          "_sort-by": "brand_name",
          "_order-by": this.brandSortByBrandAes ? "NEXT" : "PREVIOUS"
        }
      }
      this.getDataInBrandView(this.getBrandParam())
    },
    sortByBrandName() {
      if (this.isViewByProducts === "1") {
        this.sortBrandView(0)
      } else if (this.isViewByProducts === "0") {
        this.productSortBrandAes = !this.productSortBrandAes
        this.productCurrentPage = 1
        this.productSortParam = {
          "_sort-by": "brand_name",
          "_order-by": this.productSortBrandAes ? "NEXT" : "PREVIOUS"
        }
        let param = this.getProductParam()
        this.getDataInProdutView(param)
      } else if (this.isViewByProducts === "2") {
        this.expirationSortBrandAes = !this.expirationSortBrandAes
        this.expirationPage = 1
        this.expirationSortParam = {
          "_sort-by": "brand_name",
          "_order-by": this.expirationSortBrandAes ? "NEXT" : "PREVIOUS"
        }
        let param = this.getExpireParam()
        this.getDataInExpirationDateView(param)
      }
    },
    sortByExpiration() {
      if (this.isViewByProducts === "2") {
        this.expirationSortByExpirateAes = !this.expirationSortByExpirateAes
        this.expirationPage = 1
        this.expirationSortParam = {
          "_sort-by": "expiration_date",
          "_order-by": this.expirationSortByExpirateAes ? "NEXT" : "PREVIOUS"
        }
        this.getDataInExpirationDateView(this.getExpireParam())
      }
    },
    clickToChangeViewBy(value) {
      this.isViewByProducts = value
      this.searchValue = ""
      localStorage.setItem("adminInventoryViewByValue", value)
      if (value === "0") {
        this.productSearchValue = ""
        this.productCurrentPage = 1
        this.getDataInProdutView(this.getProductParam())
      }

      if (value === "1" && (!this.brandReportList || this.brandReportList.length === 0)) {
        this.brandSearchValue = ""
        this.brandCurrentPage = 1
        this.getDataInBrandView(this.getBrandParam())
      }
      if (value === "2" && (!this.expirationReportList || this.expirationReportList.length === 0)) {
        this.expireSearchValue = ""
        this.expirationPage = 1
        this.getDataInExpirationDateView(this.getExpireParam())
      }
    },
    pressEnterToSearch(event) {
      if (event.keyCode === 13) {
        this.doSearch(this.searchValue)
      }
    },
    clearSearchText(value) {
      if (!value || value.length === 0) {
        this.doSearch("")
      }
    },
    clickToSearch() {
      this.doSearch(this.searchValue)
    },
    onSearchChange(value) {
      this.doSearch(value)
    },
    getSearchString(value) {
      let search = ""
      for (let i = 0; i < value.length; i++) {
        if (value[i].trim().length > 0) {
          search += value[i]
          if (i < value.length - 1) {
            search += " "
          }
        }
      }
      return search
    },
    /**
     * input search bar action
     * @param value
     */
    doSearch(value) {
      if (value && value.length > 0) {
        this.searchedHistory.add(value)
        let searchArray = [...this.searchedHistory]
        if (this.searchedHistory.size >= 10) {
          searchArray = searchArray.slice(this.searchedHistory.size - 10, this.searchedHistory.size)
        }
        this.searchedHistoryArray = searchArray
        this.searchedHistory.clear()
        this.searchedHistory = new Set(this.searchedHistoryArray)
        searchArray = []
        localStorage.setItem(
          "adminInventorySearchHistory",
          JSON.stringify(this.searchedHistoryArray)
        )
      }
      if (this.isViewByProducts === "0") {
        this.productCurrentPage = 1
        this.productSearchValue = value
        this.getDataInProdutView(this.getProductParam())
      } else if (this.isViewByProducts === "1") {
        this.brandCurrentPage = 1
        this.brandSearchValue = value
        this.openedBrand = undefined
        this.brandSearchValue = value
        this.getDataInBrandView(this.getBrandParam())
      } else if (this.isViewByProducts === "2") {
        this.expirationPage = 1
        this.expireSearchValue = value
        this.getDataInExpirationDateView(this.getExpireParam())
      }
    },
    removeCachedData(item) {
      for (let i = 0; i < this.searchedHistoryArray.length; i++) {
        if (this.searchedHistory.has(item)) {
          this.searchedHistory.delete(item)
        }
        if (this.searchedHistoryArray[i] === item) {
          this.searchedHistoryArray.splice(i, 1)
          localStorage.setItem(
            "adminInventorySearchHistory",
            JSON.stringify(this.searchedHistoryArray)
          )
          break
        }
      }
    },
    clickToProductHistory(item) {
      this.$router.push({
        name: "adminProductHistoryInventory",
        params: {
          productId: item.productId,
          brandId: item.brandId
        }
      })
    },
    clickToProductPage(item) {
      this.$router.push({
        name: "adminProduct",
        params: {
          id: item.productId,
          isFromInvertory: true
        }
      })
    },
    clickToBrandInventoryPage(item) {
      this.$router.push({
        name: "adminBrandInventory",
        params: {
          brandId: item.brandId,
          isFromAdminInventory: true
        }
      })
    },
    async clickToDownloadData() {
      if (this.isViewByProducts === "0") {
        if (this.selectedProductsInProductView && this.selectedProductsInProductView.size > 0) {
          let data = this.generateDownloadStrForProduct()
          await downloadCsvData(data, `admin_inventory_product_${getNowDateStr()}.csv`)
        } else {
          this.$message.warning(
            this.$t("Admins.AdminInventory.downloadTooltip"),
            this.messageShowTime
          )
        }
      } else if (this.isViewByProducts === "2") {
        if (this.selectedProductsInExpireView && this.selectedProductsInExpireView.size > 0) {
          await downloadCsvData(
            this.generateDownloadStrForExpiration(),
            `admin_inventory_product_expiration_${getNowDateStr()}.csv`
          )
        } else {
          this.$message.warning(
            this.$t("Admins.AdminInventory.downloadTooltip"),
            this.messageShowTime
          )
        }
      } else {
        if (this.selectedProductsInBrandView && this.selectedProductsInBrandView.size > 0) {
          // invoke download api for brand view
          this.downloadViewByBrandData()
        } else {
          this.$message.warning(
            this.$t("Admins.AdminInventory.downloadTooltip"),
            this.messageShowTime
          )
        }
      }
    },
    generateDownloadStrForExpiration() {
      if (this.selectedProductsInExpireView && this.selectedProductsInExpireView.size > 0) {
        let csvData = ["Expiration, Brand, Product, Type, Lot No., Available"]
        for (let item of this.selectedProductsInExpireView) {
          let str = `${this.formatDate(item.expirationDate)}, ${replaceCommaForCsvStr(
            item.brandName
          )}, ${replaceCommaForCsvStr(item.productName)}, ${item.typeName}, ${item.lotNo}, ${
            item.available
          }`
          csvData.push(str)
        }
        return csvData
      }
      return []
    },
    generateDownloadStrForProduct() {
      if (this.selectedProductsInProductView && this.selectedProductsInProductView.size > 0) {
        let csvData = ["Modified, Product, Brand, SKU, Total, Allocated, Available, Lots, Location"]
        for (let item of this.selectedProductsInProductView) {
          let str = `${this.formatDate(item.createdAt)}, ${replaceCommaForCsvStr(
            item.productName
          )}, ${replaceCommaForCsvStr(item.brandName)}, ${item.sku}, ${item.total}, ${
            item.allocated
          }, ${item.available}, ${item.lotCount}, ${item.locationName}`
          csvData.push(str)
        }
        return csvData
      }
      return []
    },
    async downloadViewByBrandData() {
      if (this.selectedProductsInBrandView && this.selectedProductsInBrandView.size > 0) {
        let ids = ""
        for (let item of this.selectedProductsInBrandView) {
          ids += item.brandId + ","
        }
        ids = ids.substring(0, ids.length - 1)
        let param = { "brand-Id": ids }
        // add search param
        // if (this.brandSearchValue && this.brandSearchValue.length > 0) {
        //   param = Object.assign({}, param, { keyword: this.brandSearchValue })
        // }
        // if (this.brandSortParam) {
        //   param =  Object.assign({}, param, this.brandSortParam)
        // } else {
        //   param["_order-by"] = "PREVIOUS"
        // }
        const blob = await this.$store.dispatch("inventory/downloadProductReport", param)
        let data = new Blob([blob.data], { type: `${blob.headers["content-type"]};base64` })
        exportOrderExcel(data, `admin_inventory_brand_product_${getNowDateStr()}.csv`)
      }
    },
    clickToOpenBrandProduct() {
      this.loadingProducts = true
      this.$store
        .dispatch("inventory/fetchBrandProductReport", this.openedBrand)
        .then(() => {
          this.loadingProducts = false
        })
        .catch(error => {
          console.error(error)
          this.loadingProducts = false
        })
    },
    getProductPageData(page) {
      this.productCurrentPage = page
      this.getDataInProdutView(this.getProductParam())
    },
    getExpirationPageData(page) {
      this.expirationPage = page
      this.getDataInExpirationDateView(this.getExpireParam())
    },
    getData(params) {
      if (this.isViewByProducts === "0") {
        this.getDataInProdutView(params)
      } else if (this.isViewByProducts === "1") {
        this.getDataInBrandView(params)
      } else if (this.isViewByProducts === "2") {
        this.getDataInExpirationDateView(params)
      }
    },
    // all the get data function, need page, sort and search.
    getDataInProdutView(params) {
      this.loadingProducts = true
      this.$store
        .dispatch("inventory/fetchProductReport", params)
        .then(() => {
          this.loadingProducts = false
        })
        .catch(error => {
          console.error(error)
          this.loadingProducts = false
        })
    },
    getDataInBrandView(params) {
      this.loadingBrands = true
      this.$store
        .dispatch("inventory/fetchBrandReport", params)
        .then(() => {
          this.loadingBrands = false
        })
        .catch(error => {
          console.error(error)
          this.loadingBrands = false
        })
    },
    getDataInExpirationDateView(params) {
      if (!params["action-Id"]) {
        params["action-Id"] = "addition"
      }
      this.loadingExpiration = true
      this.$store
        .dispatch("inventory/fetchExpirationReport", params)
        .then(() => {
          this.loadingExpiration = false
        })
        .catch(error => {
          console.error(error)
          this.loadingExpiration = false
        })
    },
    doUpload(data) {
      let file = data.file
      let fileReader = new FileReader()
      const formData = new FormData()
      fileReader.readAsText(file)
      let info = this.$t("Admins.AdminOrders.uploadFailInfo")
      fileReader.onloadend = e => {
        let data = new Blob([e.target.result], { type: "text/csv" })
        formData.append("file", data, file.name)
        this.uploading = true
        this.$store
          .dispatch("inventory/uploadInventory", formData)
          .then(res => {
            if (res.type === "success") {
              this.uploadSuccess = true
              this.$message.success("Upload success!", this.messageShowTime)
            } else {
              let msg = res.reports
              if (msg && msg.length > 0) {
                this.$message.error(msg[0], this.messageShowTime)
              } else {
                this.$message.error(info, this.messageShowTime)
              }
            }
          })
          .catch(error => {
            if (error.message) {
              let errorReports = error.downloadMessage
              let errors = error.message
              this.$refs["uploadProduct"].showErrorReport(errorReports, errors)
            } else {
              this.$message.error(info, this.messageShowTime)
            }
            this.$refs["uploadProduct"].handleCancelUpload()
          })
          .finally(() => {
            this.uploading = false
          })
      }
    },
    beforeUpload(file) {
      let isFormatRight = this.isCsv(file.name)
      if (!isFormatRight) {
        let info = this.$t("Admins.AdminOrders.uploadFailInfo")
        this.$message.error(info, this.messageShowTime)
        return false
      }
      return true
    },
    isCsv(name) {
      if (name) {
        let index = name.lastIndexOf(".")
        let ext = name.substr(index + 1)
        if (ext.toLowerCase() === "csv") {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.admin-inventory {
  width: 100%;
  margin: 0 auto;
  align: center;
  text-align: center;
  align-items: center;
  padding-left: 10px;
  &__full-width {
    width: 100% !important;
  }
  &__float-left {
    float: left;
    margin-left: 9px;
  }
  &__float-right {
    float: right;
    margin-right: 15px;
    margin-top: 4px;
  }
  &__download-btn {
    background: #fafafa !important;
    border: 1px solid #1d4ba1 !important;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 5px 16px;
    width: 78px;
    height: 34px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 24px;
    color: #1d4ba1 !important;
  }
  &__header {
    width: 960px;
    height: auto;
    margin: 0 auto !important;
    &__title {
      font-family: Playfair Display;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 64px;
      color: #000000;
      margin-bottom: 26px;
    }
    &__download {
      margin-top: 28px;
      margin-bottom: 33px;
    }
    &__search {
      width: 54%;
      float: left;
    }
    &__tab {
      width: 38%;
      float: right;
    }
    &__dropdown-menu {
      width: 84px;
      background-color: #192b46 !important;
      border-radius: 1px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff !important;
      height: 28px !important;
    }
    &__dropdown-menu-content {
      background-color: #394a64 !important;
      border-radius: 1px !important;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 17px;
      color: #ffffff !important;
    }
    &__search-input {
      background-color: #dee5ef !important;
      border-radius: 1px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 17px !important;
      color: #595959 !important;
      width: 80%;
      border: none !important;
      height: 28px !important;
    }
    &__search-dropdown {
      background-color: #f6f9fd !important;
      border-radius: 2px !important;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 17px;
      color: #595959 !important;
    }
    &__tab-text {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #262626;
      padding: 5px 6px;
      margin-left: 2px;
      cursor: pointer;
    }
    &__tab-selected {
      background: #e8e8e8;
      border-radius: 2px;
    }
  }
  &__content {
    max-width: 1290px;
    margin-top: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    &__brand-table-head {
      background: #fafafa;
      border: 1px solid #e8e8e8;
      box-sizing: border-box;
      border-radius: 2px 2px 0px 0px;
    }
    &__brand-table-head-col {
      border-right: 1px solid #e8e8e8;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #595959;
      padding: 7px 0px;
    }
    &__brand-table-content {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      align-items: center;
      color: #595959;
      text-align: left;
    }
    &__brand-table-title-left {
      text-align: left;
      padding-left: 9px !important;
    }
    &__product-view-action {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #4a8a5d;
      cursor: pointer;
    }
    &__name-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 98%;
      display: block;
    }
    &__brand-name-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 98%;
      display: block;
      text-align: left;
    }
  }
}
#brandProductInventory
  > .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  display: block;
  padding: 7px 0px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}
#brandProductInventory > .ant-collapse {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 0px 0px 0px 0px;
  border-bottom: none;
  border-top: none;
}
#brandProductInventory > .ant-collapse:last-child {
  border-bottom-width: 1px;
  border-radius: 0px 0px 2px 2px;
}
#brandProductInventory > .ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0px 0px 0px 0px;
}
#productInventoryTable .ant-table > .ant-table-content > .ant-table-body .ant-table-thead > tr {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}

#productInventoryTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 7px 9px;
}

#productInventoryTable .ant-table.ant-table-bordered {
  border-radius: 0px;
}
#productInventoryTable .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: none;
  padding: 7px;
  vertical-align: middle;
}
#productInventoryTable .ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
#expirationInventoryTable .ant-table.ant-table-bordered {
  border-radius: 0px;
}
#expirationInventoryTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 7px 9px;
}
#expirationInventoryTable .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: none;
  padding: 7px;
  vertical-align: middle;
}
#expirationInventoryTable .ant-table > .ant-table-content > .ant-table-body .ant-table-thead > tr {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}
.admin-inventory__header__search-input input.ant-input {
  background-color: #dee5ef;
  padding-left: 10px;
}
.admin-inventory__header__search-input .ant-input {
  height: 27px;
}
.admin-inventory__header__search-input .ant-select-selection__clear {
  background-color: transparent;
  right: 21px;
}
.admin-inventory__header__search-input.ant-select-focus {
  border: none;
}
.admin-inventory__header__search-input
  .ant-select-selection--multiple
  .ant-select-selection__choice {
  padding: 2px 2px 0 2px;
  background-color: transparent;
  border: none;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #595959;
}
.admin-inventory__header__search-input
  .ant-select-selection--multiple
  .ant-select-selection__choice__remove {
  display: none;
}
.admin-inventory__content__brand-name-value:hover {
  text-decoration: underline;
  cursor: pointer;
}
.admin-inventory__content__name-value:hover {
  text-decoration: underline;
  cursor: pointer;
}
.admin-inventory__header__search-input .ant-select-selection--single {
  height: 28px;
  min-height: 28px;
  background-color: transparent;
  border: none;
}
.admin-inventory__header__search-input .ant-select-selection__placeholder {
  color: #595959 !important;
}
.admin-inventory__header__search-input .ant-select-selection__rendered {
  line-height: 27px;
}
.admin-inventory__header__search-dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item
  .ant-select-selected-icon {
  display: none;
}
.admin-inventory__header__search-dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item {
  padding-right: 10px;
}
.admin-inventory__header__search-dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected
  .ant-select-selected-icon {
  display: none;
}
.admin-inventory__header__search-dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected:hover
  .ant-select-selected-icon {
  display: none;
}
.admin-inventory__header__dropdown-menu .ant-select-selection--single {
  height: 27px;
}
.admin-inventory__header__dropdown-menu .ant-select-selection {
  background-color: transparent;
  border: none;
}
.admin-inventory__header__dropdown-menu
  .ant-select-selection--single
  .ant-select-selection__rendered {
  line-height: 27px;
}
.admin-inventory__header__dropdown-menu .ant-select-selection-selected-value {
  color: white;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
}
.admin-inventory__header__dropdown-menu .ant-select-arrow {
  color: white;
}
.admin-inventory__header__dropdown-menu-content .ant-select-dropdown-menu-item {
  color: white;
}
.admin-inventory__header__dropdown-menu-content
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #192b46;
}
.admin-inventory__header__dropdown-menu-content
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #192b46;
}
.admin-inventory__content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff !important;
  border: 1px solid #bfbfbf !important;
}
.admin-inventory__content .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #595959;
}
.admin-inventory__content__name-value:hover {
  text-decoration: underline;
  cursor: pointer;
}
/* pagination css ...*/
#expirationInventoryTable .ant-pagination-disabled .ant-pagination-item-link {
  color: #5e6871;
}
#expirationInventoryTable .ant-pagination-item-active {
  background: #8c8c8c;
  border-radius: 2px;
  border: none;
}
#expirationInventoryTable .ant-pagination-item.ant-pagination-item-active a {
  color: white;
}
#expirationInventoryTable .ant-pagination-item a {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #262626;
}
#brandProductInventory .ant-pagination-disabled .ant-pagination-item-link {
  color: #5e6871;
}
#brandProductInventory .ant-pagination-item-active {
  background: #8c8c8c;
  border-radius: 2px;
  border: none;
}
#brandProductInventory .ant-pagination-item.ant-pagination-item-active a {
  color: white;
}
#brandProductInventory .ant-pagination-item a {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #262626;
}
</style>
