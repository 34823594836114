<template>
  <div>
    <a-row class="edit-card__row-margin">
      <a-col>
        <span class="edit-card__dialog-title"> {{$t('Components.Brands.Payment.editPayment')}} </span>
      </a-col>
    </a-row>
    <card-number :card-info="selectedItem" class="edit-card__row-margin" />
    <a-row>
      <a-col :span="12">
        <p class="edit-card__other-title">{{ $t('Components.Brands.Payment.nameOnCard') }}</p>
      </a-col>
      <a-col :span="12" class="edit-card__content-right">
        <p class="edit-card__other-title">{{ $t('Components.Brands.Payment.expiredDateDesc') }}</p>
      </a-col>
    </a-row>
    <a-row class="edit-card__row-margin">
      <a-col :span="12">
        <a-input v-model="editName" :placeholder="$t('Components.Brands.Payment.nameOnCard')" v-if="editAll"/>
        <p class="edit-card__other-text" v-else> {{ selectedItem.cardHolderName }} </p>
      </a-col>
      <a-col :span="12" class="edit-card__content-right">
        <a-input placeholder="MM/YY" v-model="editExpired" class="edit-card__input"/>
      </a-col>
    </a-row>
    <a-row class="edit-card__row-action">
      <a-col>
        <a-button type="primary" class="edit-card__btn" @click="doEdit">
          {{$t('Components.Brands.Payment.editConfirm')}}
        </a-button>
        <a-button class="edit-card__btn" @click="sendFinishEvent">
          {{$t('Components.Brands.Payment.cancelAction')}}
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
 import { Row, Col, Button, Input } from "ant-design-vue"
 import CardNumber from "./CardNumber"
 import Utils from "@/common/Utils"
  export default {
    components: {
      "a-row": Row,
      "a-col": Col,
      "a-button": Button,
      "card-number": CardNumber,
      "a-input": Input
    },
    props: {
      selectedItem: { type: Object, default: () => undefined},
      brandId: { type:String, default: () => undefined},
      editAll: { type: Boolean, default: () => true }
    },
    watch: {
      selectedItem: {
        handler(newValue) {
          this.selectedItem = newValue
          if (this.selectedItem) {
            if (this.editAll) {
              this.editName = this.selectedItem.cardHolderName
            }
            this.editExpired = this.selectedItem.expirationDate
          }
        }
      }
    },
    data() {
      return {
        isExpired: false,
        editName: this.selectedItem ? this.selectedItem.cardHolderName : undefined,
        editExpired: this.selectedItem ? this.selectedItem.expirationDate : undefined
      }
    },
    methods: {
      sendFinishEvent() {
        this.$emit('onFinish')
      },
      async doEdit() {
        if (this.selectedItem) {
          if(!Utils.checkExpiredDate(this.editExpired)){
            this.$message.error(this.$t('Components.Brands.Payment.expiredDateFormatError'))
            return
          }
          if (Utils.isDateExpired(this.editExpired)) {
            this.$message.error(this.$t('Components.Brands.Payment.expiredDateExpiredError'))
            return
          }
          if(this.editAll && this.editName !== this.selectedItem.cardHolderName) {
            this.updateName()
          }
          if (this.editExpired !== this.selectedItem.expirationDate) {
            this.updateExpireDate()
          }
          this.sendFinishEvent()
        }
      },
      async updateExpireDate() {
        let params = {
          cardNumber: this.selectedItem.cardNumber,
          cardCode: this.selectedItem.cardCode,
          customerProfileId: this.selectedItem.customerProfileId,
          customerPaymentProfileId: this.selectedItem.customerPaymentProfileId,
          privateId: this.selectedItem.privateId,
          expirationDate: this.editExpired
        }
        try {
          await this.$store.dispatch("brandPayment/updateExpireDate", {
            brandId: this.brandId,
            param: params
          }).then((response) => {
            if (response.error) {
              this.$message.error(response.developerMessage)
            } else {
              this.$message.success(this.$t('Components.Brands.Payment.updateSuccess'))
            }
          })
        } catch (e) {
          this.$message.error(e.toString())
        }
      },
      async updateName() {
        let defaultParam = []
        defaultParam.push({
          privateId: this.selectedItem.privateId,
          cardHolderName: this.editName,
          cardCode: this.selectedItem.cardCode,
          isDefault: this.selectedItem.default ? 1 : 0
        })
        await this.$store.dispatch("brandPayment/setCardDefault" , {
          brandId: this.brandId,
          param:  defaultParam
        }).then((response) => {
          if (response.error) {
            this.showErrorInfo(response.developerMessage)
          }
        }).catch(() => {
          this.showErrorInfo(this.$t('Components.Brands.Payment.addCardFailed'))
        })
      }
    }
  }
</script>

<style lang="sass">
.edit-card
  &__row-margin
    margin-bottom: 10px
  &__row-action
    margin-top: 10px
  &__content-right
    direction: rtl
  &__btn
    min-width: 80px
    margin-left: 10px
    float: right
  &__dialog-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 20px
    margin-bottom: 10px
  &__other-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 12px
    line-height: 20px
    display: flex
    margin-right: 10px
    margin-bottom: 4px !important
    color: #000
  &__other-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 20px
    display: flex
    margin-right: 10px
    margin-bottom: 4px !important
    color: #000
  &__input
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    color: #000
    max-width: 100px
    direction: ltr !important
    text-align: center !important

</style>
