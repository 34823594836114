<template>
  <a-modal
    v-model="isShow"
    width="403px"
    dialog-class="adminProductTrainingDialog"
    :footer="null"
    :destroy-on-close="true"
  >
    <a-row class="admin-modal-title"> {{ title }}</a-row>
    <div class="admin-modal-content admin-training-retailer-location">
      <a-form-model ref="ruleForm" :model="locationData" :rules="rules">
        <a-form-model-item prop="storeNumber">
          <!-- <span v-if="isEdit"> {{ locationData ? locationData.storeId : "" }} </span> -->
          <a-input v-model="locationData.storeNumber" placeholder="Store #" />
        </a-form-model-item>
        <a-form-model-item prop="storeName">
          <!-- <span v-if="isEdit"> {{ locationData ? locationData.storeName : "" }} </span> -->
          <a-input v-model="locationData.storeName" placeholder="Store name" />
        </a-form-model-item>
        <a-form-model-item prop="address">
          <a-input v-model="locationData.address" placeholder="Address" />
        </a-form-model-item>
        <a-form-model-item prop="city">
          <a-input v-model="locationData.city" placeholder="City" />
        </a-form-model-item>
        <a-form-model-item prop="state">
          <a-select
            v-model="locationData.stateName"
            placeholder="State"
            style="width: 100%;"
            @change="changeState"
          >
            <a-select-option v-for="item in countries" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="postalCode">
          <a-input v-model="locationData.postalCode" placeholder="Zip" />
        </a-form-model-item>
        <a-form-model-item prop="phone">
          <a-input v-model="locationData.phone" placeholder="Phone" />
        </a-form-model-item>
        <!-- <a-form-model-item label="Deactive Location">
          <a-switch v-model="locationData.isDeactive" @change="deactionLocation"></a-switch>
        </a-form-model-item> -->
      </a-form-model>
      <a-row v-if="isEdit">
        <a-switch
          :checked="locationData.isDeactive"
          :loading="isDoActiveAction"
          class="admin-training-switch"
          style="float: right;"
          @change="deactionLocation"
        />
        <!-- <a-switch
          :checked="text"
          class="admin-training-switch"
          @change="deactionLocation(record)"
        /> -->
        <span style="margin-right: 8px; float: right;">
          {{ locationData.isDeactive ? "Deactivate Location" : "Active Location" }}
        </span>
      </a-row>
      <a-row v-else>
        <div
          class="admin-modal-click-link-text"
          style="float: left;"
          @click="downloadLocationTemplate"
        >
          Download CSV Template
        </div>
        <div class="admin-modal-click-link-text" style="float: right;" @click="showUploadDialog">
          Upload CSV
        </div>
      </a-row>

      <a-row>
        <a-button class="admin-training-landing-deep-red-btn" @click="handleCancel"
          >Cancel</a-button
        >
        <a-button
          class="admin-training-landing-light-green-btn"
          :loading="isDoAction"
          @click="handleSave"
          >Save</a-button
        >
      </a-row>
    </div>
    <a-modal
      v-model="showUpload"
      width="600px"
      :footer="null"
      dialog-class="admin-training-retailer-location-upload"
      @cancel="handleCancelUpload"
    >
      <div class="upload-modal__body">
        <a-upload-dragger
          name="file"
          :multiple="false"
          :file-list="fileList"
          :show-upload-list="false"
          :before-upload="beforeUpload"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="cloud-upload" style="color: #4A8a5d" />
          </p>
          <p class="ant-upload-text">
            {{ $t("Admins.AdminOrders.dragUpload") }}
          </p>
          <p class="ant-upload-text">
            {{ $t("Admins.AdminOrders.or") }}
          </p>
          <a-button class="upload-modal__btn">
            {{ $t("Admins.AdminOrders.chooseFile") }}
          </a-button>
        </a-upload-dragger>
        <div v-if="fileList.length > 0" class="upload-modal__body">
          <a-row v-for="(item, index) in fileList" :key="index">
            <a-col :span="1">
              <a-icon type="paper-clip" />
            </a-col>
            <a-col :span="19">
              {{ item.name }}
            </a-col>
            <a-col :span="4">
              <a-icon type="delete" @click="handleRemove(item)" />
            </a-col>
          </a-row>
        </div>
        <div class="retailer-location-upload-modal__action">
          <a-button
            class="upload-modal__btn"
            :disabled="fileList.length === 0"
            :loading="uploading"
            @click="handleUpload"
          >
            {{ $t("Admins.AdminOrders.upload") }}
          </a-button>
        </div>
      </div>
    </a-modal>
  </a-modal>
</template>
<script>
import { downloadRetailerLocationCsvTemplate } from "@/utils/validate"
export default {
  data() {
    let checkStoreId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Please input the store id"))
      }
      let reg = /^([0-9]+)$/
      if (!reg.test(value)) {
        return callback(new Error("Please input digits"))
      }
      return callback()
    }
    return {
      isShow: false,
      title: undefined,
      isEdit: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      locationData: {
        storeName: "",
        storeNumber: "",
        address: "",
        city: "",
        stateName: undefined,
        state: {},
        postalCode: "",
        phone: "",
        active: true,
        isDeactive: false,
        delete: false
      },
      rules: {
        storeName: [
          { required: true, message: "Please input store name", trigger: "blur", whitespace: true }
        ],
        storeNumber: [
          { required: true, validator: checkStoreId, trigger: "blur", whitespace: true }
        ],
        address: [
          { required: true, message: "Please input address", trigger: "blur", whitespace: true }
        ],
        city: [{ required: true, message: "Please input city", trigger: "blur", whitespace: true }],
        stateName: [
          { required: true, message: "Please input state", trigger: "blur", whitespace: true }
        ],
        postalCode: [
          { required: true, message: "Please input zip", trigger: "blur", whitespace: true }
        ],
        phone: [
          {
            required: true,
            message: "Please input phone number",
            trigger: "blur",
            whitespace: true
          }
        ]
      },
      retailer: undefined,
      country: "",
      showUpload: false,
      uploading: false,
      fileList: [],
      isDoAction: false,
      isDoActiveAction: false
    }
  },
  computed: {
    countries() {
      return this.$store.getters["reference/stateName"]
    }
  },
  methods: {
    openDialog(edit, titleStr, retailerData, data) {
      this.isShow = true
      this.title = titleStr
      this.isEdit = edit
      this.retailer = retailerData
      //this.initRules()
      if (data) {
        this.locationData = Object.assign({}, data)
        this.locationData.state = Object.assign({}, data.state)
        this.country =
          this.locationData.state && this.locationData.state.id
            ? this.locationData.state.id
            : undefined
        this.locationData.stateName = this.country.toLowerCase()
        this.locationData["isDeactive"] = data.active
      } else {
        this.country = ""
        this.locationData = {
          storeNumber: "",
          storeName: "",
          address: "",
          city: "",
          stateName: undefined,
          state: {},
          postalCode: "",
          phone: "",
          active: true,
          isDeactive: false,
          delete: false
        }
      }
      if (!this.isEdit) {
        this.rules["locationId"] = [
          { min: 0, max: 8, message: "Length should less or equal to 8", trigger: "blur" },
          { validator: this.checkLocationId, trigger: "blur" }
        ]
      }
    },
    changeState() {
      this.$forceUpdate()
    },
    initRules() {
      this.rules = {
        address: [
          { required: true, message: "Please input address", trigger: "blur", whitespace: true }
        ],
        city: [{ required: true, message: "Please input city", trigger: "blur", whitespace: true }],
        stateName: [
          { required: true, message: "Please input state", trigger: "blur", whitespace: true }
        ],
        postalCode: [
          { required: true, message: "Please input zip", trigger: "blur", whitespace: true }
        ],
        phone: [
          {
            required: true,
            message: "Please input phone number",
            trigger: "blur",
            whitespace: true
          }
        ]
      }
    },
    checkLocationId(rule, value, callback) {
      if (value && value.length <= 8 && value.length > 0) {
        let reg = /^([a-zA-Z0-9]+)$/
        if (!reg.test(value)) {
          return callback(new Error("LocationId can only be number or alphabet."))
        }
        return callback()
      }
      return callback()
    },
    handleCancel() {
      this.isShow = false
    },
    handleSave() {
      this.isDoAction = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.doSaveAction()
        } else {
          this.isDoAction = false
          return false
        }
      })
    },
    doSaveAction() {
      this.locationData.state.id = this.locationData.stateName
      let param = {
        id: this.retailer.id,
        name: this.retailer.name,
        shortName: this.retailer.shortName,
        status: this.retailer.status,
        locations: [this.locationData]
      }
      this.$store
        .dispatch("retailer/updateRetailer", param)
        .then(() => {
          this.isDoAction = false
          this.$store.dispatch("retailer/fetchRetailer", this.retailer.id)
          this.$message.success(this.isEdit ? "Update location success " : "Add location success")
          this.isShow = false
        })
        .catch(err => {
          this.isDoAction = false
          // let str = getApiErrorString(err)
          let str = err.message
          if (str) {
            this.$message.error(str)
          } else {
            this.$message.error((this.isEdit ? "Update " : "Add ") + "location failed")
          }
        })
    },
    deactionLocation() {
      this.isDoActiveAction = true
      let loc = Object.assign({}, this.locationData)
      let param = {
        id: this.retailer.id,
        name: this.retailer.name,
        shortName: this.retailer.shortName,
        status: this.retailer.status,
        locations: [
          { privateId: loc.privateId, locationId: loc.locationId, active: !loc.isDeactive }
        ]
      }
      this.$store
        .dispatch("retailer/updateRetailer", param)
        .then(() => {
          this.$message.success(
            loc.isDeactive ? "Deactive location success " : "Active location success "
          )
          this.isDoActiveAction = false
          this.$store.dispatch("retailer/fetchRetailer", this.retailer.id)
          this.isShow = false
        })
        .catch(err => {
          this.isDoActiveAction = false
          this.$message.error(err.message)
        })
    },
    async downloadLocationTemplate() {
      await downloadRetailerLocationCsvTemplate()
    },
    // upload csv
    showUploadDialog() {
      this.showUpload = true
      this.fileList = []
    },
    handleCancelUpload() {
      this.showUpload = false
      this.fileList = []
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = []
      this.fileList.push(file)
      return false
    },
    handleUpload() {
      if (this.fileList && this.fileList.length > 0) {
        let isFormatRight = true
        for (let i = 0; i < this.fileList.length; i++) {
          let name = this.fileList[i].name
          isFormatRight = this.isCsv(name)
          if (!isFormatRight) {
            let info = this.$t("Admins.AdminOrders.uploadFailInfo")
            this.$message.error(info)
            return
          }
        }
        this.doUpload(this.fileList[0])
      }
    },
    isCsv(name) {
      if (name) {
        let index = name.lastIndexOf(".")
        let ext = name.substr(index + 1)
        if (ext.toLowerCase() === "csv") {
          return true
        }
      }
      return false
    },
    doUpload(file) {
      let fileReader = new FileReader()
      const formData = new FormData()
      fileReader.readAsText(file)
      fileReader.onloadend = e => {
        let data = new Blob([e.target.result], { type: "text/csv" })
        formData.append("file", data, file.name)
        this.uploading = true
        this.$store
          .dispatch("training/uploadRetailerLocation", {
            retailerId: this.retailer.id,
            file: formData
          })
          .then(res => {
            if (res.states || (res.status >= 200 && res.status < 300)) {
              console.log(res)
              if (res.data && res.data.error) {
                if (res.data.error.underlying && res.data.error.underlying.length > 0) {
                  let errorMsg = ""
                  for (let i = 0; i < res.data.error.underlying.length; i++) {
                    errorMsg += res.data.error.underlying[i].userMessage
                    if (i < res.data.error.underlying.length - 1) {
                      errorMsg += ","
                    }
                  }
                  this.$message.error(errorMsg)
                }
              } else {
                this.uploadSuccess = true
                this.$store.dispatch("retailer/fetchRetailer", this.retailer.id)
              }
            } else if (res.reports) {
              console.log("upload fail reports....")
              console.log(res.reports)
              this.errorReports = ""
              this.errors = ""
              res.reports.forEach(item => {
                let index = item.indexOf("cause:")
                if (index > 0) {
                  this.errorReports += `${item.substring(0, index)}\n`
                  this.errors += `${item.substring(0, index)}<br/>`
                } else {
                  this.errorReports += `${item}\n`
                  this.errors += `${item}<br/>`
                }
              })
              // this.showErrorReport()
            }
            this.handleCancelUpload()
          })
          .catch(error => {
            console.log(error)
            let info = this.$t("Admins.AdminOrders.uploadFailInfo")
            // if (error.response) {
            //   let messages = error.response.data.underlying
            //   if (messages && messages.length > 0) {
            //     info = ""
            //     for (let i = 0; i < messages.length; i++) {
            //       info += messages[i].userMessage + ","
            //     }
            //     info = info.substring(0, info.length - 1)
            //   }
            // }
            if (error.message) {
              info = error.message
            }
            this.$message.error(info)
          })
          .finally(() => {
            this.uploading = false
            //this.isShiping = false
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-trainig-retailer-location {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__left-label {
    color: #262626;
    text-align: right;
    padding-right: 10px;
  }
  &__row-margin {
    margin-bottom: 14px;
  }
}
</style>
