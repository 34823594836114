<template lang="pug">
.subcategories-filter(v-show="activeCategories.length")
  .subcategories-filter__header(v-for="activeCategory in activeCategories") {{ activeCategory.name }}
  ul.subcategories-filter__subcategories--visible
    li.dropdown-item(
      v-for="subcategory in activeSubcategories"
      :key="subcategory.id"
      @click="makeSubcategoryActive(subcategory.id)"
    ) {{ subcategory.name }}
</template>

<script>
export default {
  props: {
    subcategories: { type: Array, default: () => [] },
    activeCategories: { type: Array, default: () => [] }
  },
  computed: {
    activeCategoryIds() {
      return this.activeCategories.map(category => category.id)
    },
    activeSubcategories() {
      return this.subcategories.filter(subcategory => {
        const categoryId = subcategory.parent.id
        return this.activeCategoryIds.includes(categoryId)
      })
    }
  },
  methods: {
    makeSubcategoryActive(subcategoryId) {
      this.subcategories.find(subcategory => subcategory.id === subcategoryId).checked = true
      this.$emit("filterSwitch")
    }
  }
}
</script>

<style lang="sass">
.subcategories-filter
  .dropdown-item
    cursor: pointer
    height: 27px
    padding: 0px
    padding-left: 14px
    font-size: 14px
    color: rgba(0, 0, 0, 0.65)
    font-weight: 300

  &__subcategories--visible
    display: block
    padding-bottom: 15px

  &__header
    position: relative
    display: block
    cursor: pointer
    font-family: Poppins
    font-size: 14px
    color: #000000
    font-weight: 600
    padding-top: 10px
</style>
