/* eslint-disable prettier/prettier */
<template lang="pug">
.brand-orders
  .progress-download-tips(v-if="isShowPercent")
    p Downloading
    a-progress(:percent="percentNumber" :stroke-color="{'0%': '#87d068','100%': '#1c7430',}")
  .brand-order-content
    //.column.is-3
      .brand-orders__filter
        p.brand-orders__filter-column-name {{$t('Brands.BrandOrders.filter')}}
        OrdersFilter(:title="'Retailer'" :items="retailers" @filterSwitch="fetchOrders")
        //- PriceFilter(:title="'Order Amount'")
        OrdersFilter(:title="'Status'" :items="statuses" @filterSwitch="fetchOrders")
        //- CompletedOrdersFilter(:title="'Show Completed'")
        //- DatesFilter(:title="'Order Date'" @filterSwitch="fetchOrders")
        //- DatesFilter(:title="'Ship Date'")
        //- DatesFilter(:title="'Cancel Date'")
    .column
      .columns.is-marginless
        .column.order-title-button
          p.brand-orders__table-name
            span.search-brand-order(v-if="tabId === 4" @click="openSearchOrders") Search Orders
              a-icon.search-icon-style(type="search")
            span.clear-brand-order(v-if="tabId === 4" @click="clearSearchOrder") Clear Search
          div.csv-style(@click="isClickCSV=!isClickCSV") CSV
            a-icon(type="download")
            p.csv-select(v-if="isClickCSV")
              span.download-csv-select-one(@click="downloadOrderItems") Download Selected
              span.download-csv-select-two(@click="downloadOrder([])") Download All
      .column.is-narrow.brand-orders__sort-options
        .field.is-grouped.select-text-right
          .control
            .select
              select(v-model="value" @change="selectPagination(value)")
                option(v-for="visibleItemsCount in visibleItems" :key="visibleItemsCount.id" :value="visibleItemsCount.number") {{ visibleItemsCount.name }}
        //.has-text-right
          .brand-orders__orders-count {{ brandOrders.length }} {{$t('Brands.BrandOrders.orders')}}
    .order-processing-tabs
        span(v-for="tab in tabText" :key="tab.id" :class="[tab.active ? 'tab-active' : '']" @click="handleTabs(tab)") {{ tab.name }}
    div(v-if="brandOrders && brandOrders.length === 0 && isShowLoading" class="spin-style-brand")
      a-spin(tip="Loading...")
    table.table(v-else style="width: 100%")
      tbody.brand-orders__table-header
        tr
          td.brand-orders__table-cell
            a-checkbox(@change="onChangeAll")
          td.brand-orders__table-head Reference ID
            a-icon(style="cursor: pointer" :type="isReferenceIdSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('landing_reference_id','')")
          td.brand-orders__table-head {{$t('Brands.BrandOrders.retailer')}}
            a-icon(style="cursor: pointer" :type="isRetailerName ? 'caret-down' : 'caret-up'" @click="sortOrderList('retailer_name','')")
          td.brand-orders__table-head {{$t('Brands.BrandOrders.total')}}
            a-icon(style="cursor: pointer" :type="isSubtotalSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('calculated_wholesale_cost_usd','')")
          td.brand-orders__table-head(v-if="tabId === 1 || tabId === 2 || tabId === 4 || tabId === 5") {{$t('Brands.BrandOrders.orderDate')}}
            a-icon(style="cursor: pointer" :type="isOrderDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('order_date','')")
          td.brand-orders__table-head(v-else) Payment
            //a-icon(style="cursor: pointer" :type="isOrderDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('order_date','')")
          td.brand-orders__table-head(v-if="tabId === 1") Ship by Date
            a-icon(style="cursor: pointer" :type="isShipByDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('ship_date','')")
          td.brand-orders__table-head(v-else-if="tabId === 2") Invoice Date
            //a-icon(style="cursor: pointer" :type="isShipByDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('ship_date','')")
          td.brand-orders__table-head(v-else-if="tabId === 3") Payment Date
            //a-icon(style="cursor: pointer" :type="isShipByDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('ship_date','')")
          td.brand-orders__table-head(v-if="tabId === 1") Cancel Date
            a-icon(style="cursor: pointer" :type="isCancelDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('cancel_date','')")
          td.brand-orders__table-head PO Number
            a-icon(style="cursor: pointer" :type="isPONumberSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('retailer_reference_id','')")
          td.brand-orders__table-head(v-if="tabId === 4") Invoice #
            a-icon(style="cursor: pointer" :type="isInvoiceSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('invoice_id','')")
          td.brand-orders__table-head(v-if="tabId === 1 || tabId === 4 || tabId === 5") {{$t('Brands.BrandOrders.status')}}
            a-icon(style="cursor: pointer" :type="isStatusSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('status_private_id','')")
          td.brand-orders__table-head(v-if="tabId === 2 || tabId === 3") Invoice #
            a-icon(style="cursor: pointer" :type="isInvoiceSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('invoice_id','')")
          td.brand-orders__table-head(v-if="tabId === 2") Tracking Number
            a-icon(style="cursor: pointer" :type="isTrackingNumberSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('tracking_id','')")
      tbody(v-for="order in brandOrders")
        tr.brand-orders__table-row
          //(:to="{ name: isAdmin? 'adminBrandOrder' : 'brandOrder', params: { id: order.id }}" v-for="order in brandOrders" tag="tr")
          td.brand-orders__table-cell
            a-checkbox(v-model="order.checkedActive" @change="onChangeItem(order)")
          td.brand-orders__table-cell.old-order-table-brand
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ order.landingReferenceId }}
            a-tooltip(placement="topLeft" title="Inactive Order")
              a-icon(v-if="order.osvTag === 1" class="old-order-icon" type="double-left")
          td.brand-orders__table-cell
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ retailerNameById(order.retailer.id) }}
          td.brand-orders__table-cell
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ priceFormat(order.calculatedWholesaleCostUsd) }}
          //orderDate, Payment
          td.brand-orders__table-cell(v-if="tabId === 1 || tabId === 2 || tabId === 4 || tabId === 5" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ order.orderDate }}
          td.brand-orders__table-cell(v-else )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") ${{ formatPaymentName(order) }}
          //Ship by Date,Invoice Date, Payment Date, Order Date
          td.brand-orders__table-cell(v-if="tabId === 1" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ order.shipDate ? order.shipDate : '-' }}
          td.brand-orders__table-cell(v-else-if="tabId === 2" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ order.airShipments && order.airShipments.length > 0 ? order.airShipments[0].actualShipDate : '-' }}
          td.brand-orders__table-cell(v-else-if="tabId === 3" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ formatPaymentDate(order) }}
          //Cancel Date
          td.brand-orders__table-cell(v-if="tabId === 1" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ order.cancelDate ? order.cancelDate : '-'}}
          // PO Number(all)
          td.brand-orders__table-cell
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ order.retailerReferenceId ? order.retailerReferenceId : '-'}}
          // 4, invoceId
          td.brand-orders__table-cell(v-if="tabId === 4" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ order.invoiceId ? order.invoiceId : '-' }}
          // status
          td.brand-orders__table-cell(v-if="tabId === 1 || tabId === 4" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ checkOrderStatus(order) }}
          td.brand-orders__table-cell(v-if="tabId === 5" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ order.status.name }}
          //tabId === 2、3, Invoice #,tabId=== 2，Tracking Number
          td.brand-orders__table-cell(v-if="tabId === 2 || tabId === 3" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}") {{ order.invoiceId ? order.invoiceId : '-' }}
          td.brand-orders__table-cell(v-if="tabId === 2" )
            router-link(:to="{name: routeLinkToDetail(order),params: { id: order.id }}")
              span.tacking-number-style {{ formatTackingNumber(order) }}
    //.brand-orders__no-orders(v-if="brandOrders && brandOrders.length === 0 && !isShowLoading") {{$t('Brands.BrandOrders.noOrdersYet')}}
    .brand-pagination-style
      a-pagination.inventoryPagination(v-if="brandOrders && brandOrders.length !== 0" :default-current="1" v-model="paginationValue" :defaultPageSize="currentNum" :total="total" @change="handlePagination" size="small"
        :show-quick-jumper="true")
  ModalSearchOrder(ref="searchOrder" @getOrderParams="getOrderParams" :brandOrRetailer="setRetailersInfo" :user="1")
  //membershipExpiringDialog(ref="membershipExpiringDialog" @addCardList="openAddMoreCardDialog")
  //addNewCardDialog(ref="addNewCardDialog" @openAddMoreCardDialog="openAddMoreCardDialog")
  //cardListDialog(ref="cardListDialog" @openAddPaymentDialog="openAddPaymentDialog")
</template>

<script>
import OrdersFilter from "@/components/orders/OrdersFilter"
import PriceFilter from "@/components/orders/PriceFilter"
import DatesFilter from "@/components/orders/DatesFilter"
import CompletedOrdersFilter from "@/components/orders/CompletedOrdersFilter"
import Pagination from "@/components/Pagination"
import ModalSearchOrder from "@/components/brands/orders/modalSearchOrder"
// import membershipExpiringDialog from "@/components/webflowComponent/MembershipExpiringDialog"
// import addNewCardDialog from "@/components/brands/settings/addPaymentCardDialog"
// import cardListDialog from "@/components/brands/settings/addMoreCardDialog"
import {
  downloadCsvData,
  getOrderBrandPayAmount,
  getOrderRetailerPayAmount,
  formatLifecycleDate
} from "@/utils/validate"
import constants from "@/config.js"

export default {
  components: {
    OrdersFilter,
    PriceFilter,
    DatesFilter,
    CompletedOrdersFilter,
    Pagination,
    ModalSearchOrder
    // membershipExpiringDialog,
    // addNewCardDialog,
    // cardListDialog
  },
  data() {
    return {
      orderStatus: constants.orderStatus,
      visibleItems: [
        { id: 1, name: "View 50", number: 50 },
        { id: 2, name: "View 100", number: 100 }
      ],
      query: {},
      statuses: [],
      retailers: [],
      tabId: 1,
      isEmptyCatalog: false,
      isClickCSV: false,
      tabText: [
        {
          id: 1,
          name: "OPEN",
          active: true
        },
        {
          id: 2,
          name: "INVOICE",
          active: false
        },
        {
          id: 3,
          name: "PAID",
          active: false
        },
        {
          id: 4,
          name: "ALL",
          active: false
        }
        // {
        //   id: 5,
        //   name: "HISTORY",
        //   active: false
        // }
      ],
      sortValue: {
        "_order-by": "PREVIOUS",
        "_sort-by": "created_at"
      },
      currentNum: 50,
      isAllActive: false,
      brandOrders: [],
      checkList: [],
      value: 50,
      paginationValue: 1,
      searchValue: {},
      isShowLoading: false,
      // about sort
      isReferenceIdSort: false,
      isRetailerName: false,
      isSubtotalSort: false,
      isTotalSort: false,
      isOrderDateSort: false,
      isPaymentSort: false,
      isPaymentDateSort: false,
      isShipByDateSort: false,
      isCancelDateSort: false,
      isActualShipDateSort: false,
      isPONumberSort: false,
      isTrackingNumberSort: false,
      isInvoiceSort: false,
      isStatusSort: false,
      setRetailersInfo: [],
      downloadMaxNumber: 2000,
      percentNumber: 0,
      isShowPercent: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    // brandOrders() {
    //   return this.$store.getters["order/orders"].filter(item => item.status.id !== "deleted")
    // },
    activeRetailers() {
      return this.retailers.filter(item => item.checked)
    },
    activeStatuses() {
      return this.statuses.filter(item => item.checked)
    },
    activeFilterItems() {
      return [...this.activeStatuses, ...this.activeRetailers].filter(item => item.checked)
    },
    total() {
      return this.$store.getters["order/pagination"].total
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    }
    // setRetailersInfo() {
    //   const uniqueRetailersIds = [...new Set(this.brandOrders.map(order => order.retailer.id))]
    //   return uniqueRetailersIds.map(retailerId => {
    //     return {
    //       checked: false,
    //       id: retailerId,
    //       name: this.retailerNameById(retailerId)
    //     }
    //   })
    // }
    // routeLinkToDetail() {
    //   if (Number(this.$route.query.tabId) === 5) {
    //     return this.admin || this.$route.name === "adminBrandOrders"
    //       ? "adminBrandOldOrderDetails"
    //       : "OldOrderDetails"
    //   } else {
    //     return this.admin || this.$route.name === "adminBrandOrders"
    //       ? "adminBrandOrder"
    //       : "brandOrder"
    //   }
    // }
  },
  async mounted() {
    // if (this.brandType === "basic" && this.isBrand) {
    //   this.$refs["membershipExpiringDialog"].openDialog()
    //   return
    // }
    this.tabId = Number(this.$route.query.tabId)
    this.tabText = this.tabText.map(item => {
      if (item.id === this.tabId) {
        item.active = true
      } else {
        item.active = false
      }
      return item
    })
    this.value = Number(this.$route.query["_limit"])
    let info = {
      ...JSON.parse(JSON.stringify(this.$route.query))
    }
    delete info.tabId
    if (window.name === "") {
      await this.fetchBrandOrder(info)
      window.name = "isReload"
    } else if (window.name === "isReload") {
      info = {
        ...info,
        _index: 1
      }
      await this.fetchBrandOrder(info)
    }
    // this.paginationValue = this.$route.query["_index"] ? Number(this.$route.query["_index"]) : 1
    if (!this.brandOrders.length) {
      this.isEmptyCatalog = true
    }
    this.setRetailersInfo = await this.$store.dispatch("retailer/fetchAllRetailers", {
      params: { fields: "name,id" }
    })
    this.setRetailers()
    this.setStatuses()
  },
  methods: {
    // openAddMoreCardDialog() {
    //   const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
    //   this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    // },
    // openAddPaymentDialog() {
    //   this.$refs["addNewCardDialog"].openAddCardDialog()
    // },
    retailerNameById(retailerId) {
      const retailer = this.$store.getters["retailer/retailerById"](retailerId)
      let name = retailer && retailer.name
      return name ? name.toString().replace(/,/g, "，") : ""
    },
    statusNameById(statusId) {
      return this.brandOrders.find(order => order.status.id === statusId).status.name
    },

    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    checkOrderStatus(order) {
      let statusText
      if (order.declined && order.status.id === this.orderStatus.pending) {
        statusText = "Canceled"
      } else if (
        order.status.id === this.orderStatus.supplyShipped ||
        order.status.id === this.orderStatus.warehouse ||
        order.status.id === this.orderStatus.deliverShipped ||
        order.status.id === this.orderStatus.delivered
      ) {
        statusText = "Delivered"
      } else if (order.status.id === this.orderStatus.paidToBrand) {
        statusText = "Paid"
      } else {
        statusText = order.status.name
      }
      return statusText
    },
    priceFormatForCsv(price) {
      let priceStr = this.priceFormat(price)
      return priceStr.replace(/,/g, "，")
    },
    setRetailers() {
      const uniqueRetailersIds = [...new Set(this.brandOrders.map(order => order.retailer.id))]
      this.retailers = uniqueRetailersIds.map(retailerId => {
        return {
          checked: false,
          id: retailerId,
          name: this.retailerNameById(retailerId)
        }
      })
    },

    setStatuses() {
      const uniqueStatuses = [...new Set(this.brandOrders.map(order => order.status.id))]
      this.statuses = uniqueStatuses.map(statusId => {
        return {
          checked: false,
          id: statusId,
          name: this.statusNameById(statusId)
        }
      })
    },
    async sortOrderList(sortValue, otherValue) {
      this.formatSortValue(sortValue, otherValue)
      const status = this.checkStatusValue()
      let params = {
        _index: 1,
        _limit: this.currentNum,
        status,
        ...this.sortValue,
        ...this.searchValue
      }
      await this.fetchBrandOrder(params)
    },
    formatSortValue(value, otherValue) {
      if (value === "landing_reference_id") {
        this.isReferenceIdSort = !this.isReferenceIdSort
        this.sortValue = {
          "_order-by": this.isReferenceIdSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "retailer_name") {
        this.isRetailerName = !this.isRetailerName
        this.sortValue = {
          "_order-by": this.isRetailerName ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "calculated_wholesale_cost_usd") {
        this.isSubtotalSort = !this.isSubtotalSort
        this.sortValue = {
          "_order-by": this.isSubtotalSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "calculated_retailer_cost_usd") {
        this.isTotalSort = !this.isTotalSort
        this.sortValue = {
          "_order-by": this.isTotalSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "order_date") {
        this.isOrderDateSort = !this.isOrderDateSort
        this.sortValue = {
          "_order-by": this.isOrderDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "ship_date") {
        this.isShipByDateSort = !this.isShipByDateSort
        this.sortValue = {
          "_order-by": this.isShipByDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "cancel_date") {
        this.isCancelDateSort = !this.isCancelDateSort
        this.sortValue = {
          "_order-by": this.isCancelDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (otherValue === "actual-ship-date") {
        this.isActualShipDateSort = !this.isActualShipDateSort
        this.sortValue = {
          "_order-by": this.isActualShipDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": otherValue
        }
      }
      if (value === "retailer_reference_id") {
        this.isPONumberSort = !this.isPONumberSort
        this.sortValue = {
          "_order-by": this.isPONumberSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "tracking_id") {
        this.isTrackingNumberSort = !this.isTrackingNumberSort
        this.sortValue = {
          "_order-by": this.isTrackingNumberSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "invoice_id") {
        this.isInvoiceSort = !this.isInvoiceSort
        this.sortValue = {
          "_order-by": this.isInvoiceSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "status_private_id") {
        this.isStatusSort = !this.isStatusSort
        this.sortValue = {
          "_order-by": this.isStatusSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
    },
    checkStatusValue() {
      let status
      // OPEN
      if (this.tabId === 1) {
        status = "pending,confirmed"
      }
      // INVOICE
      if (this.tabId === 2) {
        status = "shipped-supply,warehouse,shipped-delivery,delivered,invoiced,paid-by-retailer"
      }
      // PAID
      if (this.tabId === 3) {
        status = "paid-to-brand"
      }
      if (this.tabId === 4) {
        status = null
      }
      return status
    },
    async handleTabs(tabInfo) {
      this.tabText = this.tabText.map(item => {
        if (item.id === tabInfo.id) {
          item.active = true
        } else {
          item.active = false
        }
        return item
      })
      this.tabId = tabInfo.id
      this.searchValue = {}
      const status = this.checkStatusValue()
      //paid-to-brand
      let params = {
        status,
        _index: 1,
        _limit: this.currentNum,
        "_order-by": "PREVIOUS",
        "_sort-by": "created_at"
      }
      await this.fetchBrandOrder(params)
    },
    openSearchOrders() {
      this.$refs["searchOrder"].openDialog()
    },
    async getOrderParams(obj, params) {
      this.searchValue = params
      await this.fetchBrandOrder(obj)
    },
    async clearSearchOrder() {
      const status = this.checkStatusValue()
      const params = {
        _limit: this.currentNum,
        _index: 1,
        status,
        ...this.sortValue
      }
      await this.fetchBrandOrder(params)
    },
    // clickToOrderDetail(order) {
    //   this.$router.push({
    //     name: this.isAdmin ? "adminBrandOrder" : "brandOrder",
    //     params: { id: order.id }
    //   })
    // },
    async selectPagination(currentInfo) {
      this.currentNum = currentInfo
      const status = this.checkStatusValue()
      const params = {
        _limit: this.currentNum,
        _index: 1,
        status,
        ...this.sortValue,
        ...this.searchValue
      }
      await this.fetchBrandOrder(params)
    },
    async handlePagination(page) {
      this.paginationValue = page
      const status = this.checkStatusValue()
      let params = {
        status,
        _index: page,
        _limit: this.currentNum,
        ...this.sortValue,
        ...this.searchValue
      }
      await this.fetchBrandOrder(params)
    },
    async fetchBrandOrder(data) {
      this.brandOrders = []
      this.isShowLoading = true
      let param = { _limit: this.currentNum, status: "pending,confirmed", ...this.sortValue }
      const params = data ? data : param
      if (this.tabId === 4) {
        data["osv-tag"] = "1,2"
      }
      const routerBrandId = this.$route.params.brandId
      if (routerBrandId) {
        params["_jwt-c_id"] = routerBrandId
        params["_jwt-c_type"] = "brand"
      }
      try {
        await this.$store.dispatch("order/featOrderListWithPage", { params })
        this.brandOrders = this.$store.getters["order/orders"].filter(
          item => item.status.id !== "deleted"
        )
        this.isShowLoading = false
        this.paginationValue = params["_index"]
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...params,
            tabId: this.tabId
          }
        })
      } catch (e) {
        this.brandOrders = []
      }
    },
    formatPaymentName(item) {
      const total = getOrderRetailerPayAmount(item) - getOrderBrandPayAmount(item)
      return total.toFixed(2)
    },
    async downloadOrder(arr) {
      await downloadCsvData(
        this.downloadStrForOrder(arr),
        `${this.brand.shortName}_order_list_${new Date().getTime()}.csv`
      )
    },
    getLimitInfo(total) {
      let result = []
      for (let i = 0; i < Math.ceil(total / this.downloadMaxNumber); i++) {
        const info = {
          _index: i + 1,
          _limit: this.downloadMaxNumber
        }
        result.push(info)
      }
      return result
    },
    async downloadStrForOrder(arr) {
      let list = arr && arr.length !== 0 ? arr : this.brandOrders
      let result
      if (this.total > this.downloadMaxNumber) {
        this.isShowPercent = true
        const status = this.checkStatusValue()
        const arr = this.getLimitInfo(this.total)
        const number = Math.ceil(this.total / this.downloadMaxNumber)
        let price = 100 / number
        this.percentNumber = 10
        for (let i = 0; i < arr.length; i++) {
          const params = {
            ...arr[i],
            status,
            ...this.sortValue
          }
          if (this.tabId === 4) {
            params["osv-tag"] = "1,2"
          }
          await this.$store.dispatch("order/downloadOrderList", { params }).then(async res => {
            result = this.getDownloadData(res)
            await downloadCsvData(
              result,
              `${this.brand.shortName}_order_list_${new Date().getTime()}_${i}.csv`
            )
          })
          price = (i + 1) * price
          if (price >= 95) {
            price = 90
          }
          this.percentNumber = price
        }
        this.percentNumber = 100
        setTimeout(() => {
          this.isShowPercent = false
        }, 1000)
        return
      }
      return this.getDownloadData(list)
    },
    getDownloadData(list) {
      if (list && list.length > 0) {
        if (this.tabId === 1) {
          return this.getOpenedOrderDownloadData(list)
        } else if (this.tabId === 2) {
          return this.getInvoicedOrderDownloadData(list)
        } else if (this.tabId === 3) {
          return this.getPaidOrderDownloadData(list)
        } else if (this.tabId === 5) {
          return this.getHistoryOrderDownloadData(list)
        } else {
          return this.getAllOrderDownloadData(list)
        }
      } else {
        return []
      }
    },
    getOpenedOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Total, Order Date , Ship by Date, Cancel Date, PO Number, Status"
      ]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate ? item.orderDate : "-"
        }, ${item.shipDate ? item.shipDate : "-"}, ${item.cancelDate ? item.cancelDate : "-"}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.status && item.status.name ? item.status.name : "-"}`
        csvData.push(str)
      }
      return csvData
    },
    getInvoicedOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Total, Order Date , Invoice Date, PO Number, Invoice #, Tracking Number"
      ]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate ? item.orderDate : "-"
        }, ${item.shipDate ? item.shipDate : "-"}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.invoiceId ? item.invoiceId : "-"}, ${this.formatTackingNumber(item)}`
        csvData.push(str)
      }
      return csvData
    },
    getPaidOrderDownloadData(list) {
      let csvData = ["Reference ID, Retailer, Total, Payment , Payment Date, PO Number, Invoice #"]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate ? item.orderDate : "-"
        }, ${this.formatPaymentDate(item)}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.invoiceId ? item.invoiceId : "-"}`
        csvData.push(str)
      }
      return csvData
    },
    getHistoryOrderDownloadData(list) {
      let csvData = ["Reference ID, Retailer, Total, order Date , PO Number, status"]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate ? item.orderDate : "-"
        }, ${item.retailerReferenceId ? item.retailerReferenceId : "-"}, ${
          item.status ? item.status.name : "-"
        }`
        csvData.push(str)
      }
      return csvData
    },
    getAllOrderDownloadData(list) {
      let csvData = ["Reference ID, Retailer, Total, Order Date , PO Number, Invoice #, Status"]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate ? item.orderDate : "-"
        }, ${item.retailerReferenceId ? item.retailerReferenceId : "-"}, ${
          item.invoiceId ? item.invoiceId : "-"
        }, ${item.status && item.status.name ? item.status.name : "-"}`
        csvData.push(str)
      }
      return csvData
    },
    async downloadOrderItems() {
      let list
      if (this.isAllActive) {
        list = this.brandOrders
      } else {
        list = this.checkList
      }
      // this.downloadOrder(list)
      await downloadCsvData(
        this.getDownloadData(list),
        `${this.brand.shortName}_order_list_${new Date().getTime()}.csv`
      )
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    onChangeAll() {
      this.isAllActive = !this.isAllActive
      this.brandOrders = this.brandOrders.map(item => {
        item.checkedActive = this.isAllActive
        return item
      })
    },
    onChangeItem(order) {
      this.brandOrders = this.brandOrders.map(item => {
        if (item.id === order.id) {
          item.checkedActive = order.checkedActive
        }
        return item
      })
      this.checkList = this.brandOrders.filter(item => item.checkedActive === true)
    },
    // Choose the tracking number
    formatTackingNumber(item) {
      let num
      if (item.airShipments) {
        num = item.airShipments[0].trackingId
      }
      // else if (item.oceanShipments) {
      //   num = item.oceanShipments[0].trackingId
      // }
      else {
        num = "-"
      }
      return num
    },
    formatPaymentDate(item) {
      const finalArr = item.lifecycle.slice(-1)
      if (finalArr[0].complete) {
        return formatLifecycleDate(finalArr[0].updatedAt)
      } else if (item.lifecycle && item.lifecycle.length >= 2) {
        const list = item.lifecycle.filter(item => item.complete).slice(-1)
        return list[0].transitionedAt
          ? formatLifecycleDate(list[0].transitionedAt)
          : formatLifecycleDate(list[0].updateAt)
      } else {
        return "-"
      }
    },
    routeLinkToDetail(orderItem) {
      if (orderItem.osvTag === 1) {
        return this.admin || this.$route.name === "adminBrandOrders"
          ? "adminBrandOldOrderDetails"
          : "OldOrderDetails"
      } else {
        return this.admin || this.$route.name === "adminBrandOrders"
          ? "adminBrandOrder"
          : "brandOrder"
      }
    }
  }
}
</script>

<style lang="sass">
.brand-orders
  padding: 50px 122px 80px 122px
  color: #000000
  .progress-download-tips
    width: 400px
    height: 100px
    padding: 20px 25px
    text-align: center
    position: fixed
    top: 60px
    left: 35%
    background-color: white
    p
      margin: 0 auto

  &__no-orders
    color: #9E9E9E
    white-space: pre-wrap

  //&__filter
  //  padding-right: 71px
  //  min-height: 90vh
  .order-title-button
    display: flex
    justify-content: space-between
  &__table-name
    color: #4a8a5d
    font-size: 14px
    width: 250px
    display: flex
    justify-content: space-between
    align-items: center
    .search-brand-order
      padding: 5px 16px
      border: 1px solid #4a8a5d
      border-radius: 2px
      background-color: #E2EEEC
      cursor: pointer
      .search-icon-style
        margin-left: 5px
    .clear-brand-order
      text-decoration: underline
      cursor: pointer

  .order-processing-tabs
    margin-bottom: 3px
    margin-top: 100px
    span
      padding: 4px 18px
      border: 1px solid white
      cursor: pointer
    .tab-active
      border: 1px solid gray
      border-bottom: none
  &__table-head
    color: #9E9E9E !important
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important

  &__table-cell
    color: #333333 !important
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important
    margin-top: 10px
    position: relative
    a
      color: #333333
  .tacking-number-style
    display: inline-block
    width: 200px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

  &__table-row
    cursor: pointer

  &__filter-column-name
    color: #292929
    font-size: 16px
    font-weight: 300
    line-height: 22px
    margin-bottom: 15px

  &__table-header
    border-top: 1px solid #E0E0E0

  &__sort-options
    margin-top: -15px

  &__orders-count
    font-size: 12px
    padding-bottom: 10px
  .select-text-right
    float: right
    margin-top: 90px
  .csv-style
    width: 150px
    height: 30px
    line-height: 30px
    text-align: center
    border: 1px solid #4a8a5d
    background-color: #E2EEEC
    color: #4a8a5d
    cursor: pointer
    position: relative
    .csv-select
      position: absolute
      top: 30px
      left: -1px
      border: 1px solid #D9D9D9
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
      .download-csv-select-one
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-one:hover
        background-color: #E2EEEC
      .download-csv-select-two
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-two:hover
        background-color: #E2EEEC
.spin-style-brand
  text-align: center
  margin: 40px 0
  //display: flex
  //justify-content: center
  //align-items: center
  .ant-spin
    color: #4a8a5d
  .ant-spin-dot-item
    background-color: #4a8a5d !important
.old-order-table-brand
  position: relative
  .old-order-icon
    position: absolute
    left: -4px
    top: 19px
    color: #1c7430
.brand-pagination-style
  text-align: right
</style>
