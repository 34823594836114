<template lang="pug">
.admin-retailer-margin
  .columns
    .column
      p.admin-retailer-margin__table-name {{$t('Admins.AdminRetailerMargin.retailerMargin')}}
      p.admin-retailer-margin__table-sub-name {{$t('Admins.AdminRetailerMargin.desc')}}
    .column
      button.button.admin-retailer-margin__add-retailer(@click="addRetailer") {{$t('Admins.AdminRetailerMargin.addRetailer')}}
  RetailerMarginItem(:retailers="adminRetailerMargins" :allBrands="activeBrands" @dataChange="cancelAddRetailer" :allProducts="allProducts")
  v-container(v-if="isAddNew===true" id="newRetailer")
    v-row
      v-col(cols="8").admin-retailer-margin__no-padding-bottom.admin-retailer-margin__no-padding-top
        Multiselect.admin-retailer-margin__compobox-cell.admin-retailer-margin__new-retailer-select(
          label="name"
          v-model="selectRetailer"
          :options="activeRetailers"
          placeholder="Retailer"
          open-direction="bottom"
          track-by="name"
          :allow-empty="false"
          @select="chooseRetailer"
          :class="{ 'is-danger': $v.selectRetailer.$error }"
          )
        p.help.is-danger(v-if="!$v.selectRetailer.required") Field is required
      v-col(cols="4").admin-retailer-margin__no-padding-top-bottom
        button.button.admin-retailer-margin__btn_border-black.admin-retailer-margin__margin-top(@click="cancelAddRetailer") {{$t('Admins.AdminRetailerMargin.cancel')}}
        button.button.admin-retailer-margin__btn_black-white.admin-retailer-margin__margin-top(@click="saveNewRetailer" :class="{'disable-save': isSavingNew}" :disable="isSavingNew") {{$t('Admins.AdminRetailerMargin.save')}}
    v-row
      v-col
        v-divider
    v-row
      v-col.admin-retailer-margin__no-padding-bottom.admin-retailer-margin__no-padding-top
        Multiselect.admin-retailer-margin__compobox-cell(v-model="selectBrand" :options="activeBrands" placeholder="Brand" open-direction="bottom" label="name" track-by="name" :allow-empty="false" @select="chooseBrand" :disabled="selectRetailer===null")
      v-col.admin-retailer-margin__no-padding-bottom.admin-retailer-margin__no-padding-top
        Multiselect.admin-retailer-margin__compobox-cell(v-model="selectProduct" :options="activeProducts" placeholder="Product" open-direction="bottom" label="name" track-by="name" :allow-empty="false" :disabled="selectRetailer===null || activeProducts.length===0" :loading="activeProducts.length===0")
      v-col.admin-retailer-margin__no-padding-bottom.admin-retailer-margin__no-padding-top.admin-retailer-margin__col-cell
        v-text-field.admin-retailer-margin__compobox-cell(
              v-model="marginValue"
              value="marginValue"
              placeholder="0.00"
              outlined dense
              suffix="%"
              :class="{ 'is-danger': $v.marginValue.$error }"
              @input="setMargin($event.target.value)"
              )
        p.help.is-danger(v-if="!$v.marginValue.between")  Should between {{$v.marginValue.$params.between.min}} - {{$v.marginValue.$params.between.max}}
        span.admin-retailer-margin__ctn-width {{(selectProduct && selectProduct.id && selectProduct.id !== '*' ) ? priceFormat(selectProduct.msrpUsd * (1-marginValue/100)):''}}
</template>

<script>
import Multiselect from "vue-multiselect"
import ProductsFilter from "@/components/filters/ProductsFilter"
import RetailerMarginItem from "@/components/admin/RetailerMarginItem"
import { required, between } from "vuelidate/lib/validators"

export default {
  components: {
    ProductsFilter,
    RetailerMarginItem,
    Multiselect
  },

  validations: {
    selectRetailer: { required },
    marginValue: { required, between: between(0, 100) }
  },

  data() {
    return {
      isAddNew: false,
      selectBrand: { id: "*", name: "All Brand" },
      selectProduct: { id: "*", name: "All Product" },
      selectRetailer: null,
      marginValue: 0.0,
      isSavingNew: false,
      activeProducts: []
    }
  },
  computed: {
    adminRetailerMargins() {
      return this.$store.getters["retailerMargin/retailers"]
    },
    activeRetailers() {
      return this.$store.getters["retailer/retailers"]
    },
    allProducts() {
      return this.$store.getters["product/allProducts"]
    },
    activeBrands() {
      let data = this.$store.getters["brand/brands"]
      data.unshift({ id: "*", name: "All Brand" })
      return data
    }
  },

  async created() {
    await this.$store.dispatch("brand/fetchAllBrands", { params: { fields: "name,id" } })
    await this.$store.dispatch("product/getProducts", {
      params: { fields: "name,id,msrpUsd", _limit: 9000 }
    })
    await this.$store.dispatch("retailer/fetchAllRetailers", {
      params: { status: "approved", fields: "name,id" }
    })
    this.activeProducts = [{ id: "*", name: "All Product" }]
    await this.$store.dispatch("retailerMargin/fetchRetailersMargins")
  },
  updated() {
    console.log("updated")
    this.scrollToNew()
  },
  methods: {
    chooseRetailer(retailer) {
      this.selectRetailer = retailer
      this.$v.selectRetailer.$touch()
    },
    setMargin(value) {
      this.marginValue = value
      this.$v.marginValue.$touch()
    },
    addRetailer() {
      console.log("add retailer before")
      this.isAddNew = true
      console.log("add retailer after")
    },
    scrollToNew() {
      if (this.isAddNew) {
        let eb = document.getElementById("newRetailer")
        if (eb) {
          eb.scrollIntoView()
        }
      }
    },
    cancelAddRetailer() {
      this.isAddNew = false
      this.isSavingNew = false
      this.selectBrand = null
      this.selectProduct = null
      this.selectRetailer = null
      this.marginValue = null
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    async chooseBrand(selectOption) {
      console.log("choose brand")
      console.log(selectOption)
      if (selectOption) {
        this.activeProducts = []
        if (selectOption.id === "*") {
          this.activeProducts = [{ id: "*", name: "All Product" }]
        } else {
          await this.$store.dispatch("product/getBrandProducts", {
            params: {
              _limit: 9000,
              fields: "name,id,msrpUsd",
              brand: selectOption.id
            }
          })
          this.activeProducts = this.$store.getters["product/brandProducts"]
          this.activeProducts.unshift({ id: "*", name: "All Product" })
        }
      }
      console.log(this.activeProducts)
    },
    async saveNewRetailer() {
      this.$v.$touch()
      if (this.$v.marginValue.$anyError || this.$v.selectRetailer.$anyError) {
        return
      }
      let param = []
      let id = this.selectRetailer.id

      if (!this.selectBrand || !this.selectBrand.id) {
        this.selectBrand = { id: "*" }
      }
      if (!this.selectProduct || !this.selectProduct.id) {
        this.selectProduct = { id: "*" }
      }

      param.push({
        margin: this.marginValue,
        brand: this.selectBrand,
        product: this.selectProduct,
        retailer: this.selectRetailer
      })
      try {
        await this.$store.dispatch("retailerMargin/updateRetailerMargins", {
          params: param,
          retailerId: id
        })
        this.cancelAddRetailer()
        await this.$store.dispatch("retailerMargin/fetchRetailersMargins")
      } catch (error) {
        console.log(`error${error}`)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.v-expansion-panel--active>.v-expansion-panel-header
    min-height: 50px !important
.v-expansion-panel-content__wrap
    padding: 0 0px 10px !important
.row
    margin-left: 0px !important
    margin-right: 0px !important
.admin-retailer-margin
    padding: 0 10%
    margin-top: 5%
    margin-left: 10%
    margin-right: 10%
    margin-bottom: 100px
    &__margin-top
        margin: 4px 0px !important

    &__table-name
        color: #000000
        font-size: 20px
        font-weight: 700
        letter-spacing: 0.25px
        padding-left: 15px

    &__table-sub-name
        color: #9E9E9E !important
        font-size: 12px
        font-weight: 500
        padding-top: 10px
        padding-left: 15px

    &__add-retailer
        width: 130px
        height: 30px
        float: right
        text-align: center
        background: #716B68
        font-size: 14px
        font-weight: bold
        color: #eeeeee

    &__expansion-head
        font-weight: bold
        font-size: 12px

    &__expansion-head>div
        margin-left: auto
    &__text-percent
        text-align: center
        font-size: 14px !important
        width: 100%
    &__text-center
        text-align: left
        font-size: 14px !important
    &__text-right
        text-align: right
        font-size: 14px !important
        margin-bottom: 0px !important
        width: 100%
        padding-right: 4px

    &__col-cell
        display: flex
        text-align: left
        color: #333333 !important
        padding: 4px !important
        align-items: center
    &__col-col-cell
        display: flex
        align-items: center
    &__compobox-cell
        font-size: 14px !important

    &__filter-column-name
        color: #292929
        font-size: 16px
        font-weight: 300
        margin-bottom: 15px

    &__border
        border-bottom: 1px solid #E0E0E0

    &__no-margin-bottom
        margin: 0px !important


    &__compbox-left-block
        float: left
        display: block
        padding: 0.4em 1em
        vertical-align: middle
        margin: 10px !important


    &__btn-right
        float: right
        display: flex
        justify-content: end
        justify-content: flex-end
        font-size: 12px
        padding: 10px
        margin: 10px

    &__no-padding-bottom
        padding-bottom: 0px !important

    &__no-padding-top
        padding-top: 0px !important

    &__no-padding-top-bottom
        padding: 0px 0px !important

    &__margin-right
        margin-right: 10px

    &__btn_border-grad
        margin-right: 10px
        width: 70px
        height: 30px
        border: 1px solid #D9D9D9
        box-sizing: border-box
        border-radius: 2px
        font-size: 12px
        font-weight: bold
        color: #595959

    &__btn_border-black
        margin-right: 10px
        width: 70px
        height: 30px
        border: 1px solid #D9D9D9
        box-sizing: border-box
        border-radius: 2px
        font-size: 12px
        font-weight: bold
        color: #000000
        align-items: center

    &__btn_black-white
        color: #ffffff
        margin-right: 10px
        width: 70px
        height: 30px
        background: #000000
        align-items: center
        font-size: 12px
        font-weight: bold
    &__btn_black-white:hover
        color: #ffffff !important

    &__btn_black-white:focus
        background: #000000
        color: #ffffff
    &__padding
        padding: 0px 0px !important

    &__margin-left
        margin-left: 16px !important
    &__margin-left-eight
        margin-left: 8px !important
    &__ctn-width
        width: 40%
        text-align: right
    &__new-retailer-select
        width: 60% !important

    &__padding-left
        padding-left: 42px !important
.v-messages__message
    color: #ff3860 !important
    font-size: 0.75rem !important
    line-height: 14px !important
    padding: 0 !important
    margin: 0 !important
.v-text-field__details
    padding: 4px 0px 0px 0px !important
    margin-bottom: 0px !important
.v-input__slot
    margin: 10px 0px 0px 0px!important
.v-btn--fab.v-size--small.v-btn--absolute.v-btn--bottom
    bottom: -15px !important
.v-btn--fab.v-size--small
    height: 25px !important
    width: 25px !important
.v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot
    min-height: 20px !important
.v-icon
    height: 20px !important
    width: 20px !important
    font-size: 20px !important
hr
    margin: 0 0 !important
@media (min-width: 1264px)
.col-1
    -webkit-box-flex: 0
    -ms-flex: 0 0 10.3333333333% !important
    flex: 0 0 10.3333333333% !important
    max-width: 10.3333333333% !important
.ant-btn-primary
    color: #ffffff !important
    background-color: #4a8a5d !important
    border-color: #4a8a5d !important
</style>
