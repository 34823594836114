import SimpleDescriptionEntity from "@/models/SimpleDescriptionEntity"
export default class ProductTraining extends SimpleDescriptionEntity {
  constructor(model) {
    super(model)
    this.brandId = null
    this.demoTips = null
    this.howToDemo = null
    this.proTip1 = null
    this.proTip2 = null
    this.proTip3 = null
    this.isHero = 0
    this.states = 0
    this.isPublished = 0
    this.sort = 0
    this.product = null
    if (model) {
      this.brandId = model.brandId || ""
      this.demoTips = model.demoTips || ""
      this.howToDemo = model.howToDemo || ""
      this.proTip1 = model.proTip1 || ""
      this.proTip2 = model.proTip2 || ""
      this.proTip3 = model.proTip3 || ""
      this.isHero = model.isHero || 0 
      this.states = model.states || 0
      this.isPublished = model.isPublished || 0
      this.sort = model.sort || 0
      this.product = model.product || null
    }
  }
  toModel() {
    const data = super.toModel()
    data.brandId = this.brandId
    data.demoTips = this.demoTips
    data.howToDemo = this.howToDemo
    data.proTip1 = this.proTip1
    data.proTip2 = this.proTip2
    data.proTip3 = this.proTip3
    data.isHero = this.isHero
    data.states = this.states
    data.isPublished = this.isPublished
    data.sort = this.sort
    data.product = this.product
    return data
  }
}