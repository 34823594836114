<template>
  <div>
    <a-spin :spinning="isLoading" :delay="delayTime" :indicator="indicator">
      <clean-list
        class="brand-clean-pre-check"
        :data-list="cleanPreCheckList"
        :is-retailer="role === 'retailer'"
        @changePage="getData"
      />
    </a-spin>
    <a-modal
      v-model="sureVisible"
      :centered="true"
      :footer="null"
      :width="464"
      :title="null"
      :body-style="noPadding"
    >
      <span slot="closeIcon">
        <a-icon type="close-circle" style="font-size: 21px" />
      </span>
      <div class="brand-clean-pre-check__dialog-info-before-submit">
        <p class="brand-clean-pre-check__dialog-info-title">Welcome to Clean PreCheck</p>
        <p class="dialog-content-style">
          We’ve made some changes to how you import product ingredients.<br />
          Please update your product ingredients in your product catalog to<br />
          use the Clean PreCheck tool and help retailers find your products.
        </p>
        <p class="brand-clean-pre-check__dialog-close-btn" @click="SubmitDialog">OK</p>
      </div>
    </a-modal>
    <BrandTableDialog ref="brandTableDialog"></BrandTableDialog>
    <membershipExpiringDialog
      ref="membershipExpiringDialog"
      @addCardList="openAddMoreCardDialog"
    ></membershipExpiringDialog>
    <addNewCardDialog
      ref="addNewCardDialog"
      @openAddMoreCardDialog="openAddMoreCardDialog"
    ></addNewCardDialog>
    <cardListDialog
      ref="cardListDialog"
      @openAddPaymentDialog="openAddPaymentDialog"
    ></cardListDialog>
  </div>
</template>

<script>
import CleanList from "@/components/clean/CleanList"
import BrandTableDialog from "@/components/brands/mainPage/components/BrandTableDialog"
import membershipExpiringDialog from "@/components/webflowComponent/MembershipExpiringDialog"
import addNewCardDialog from "@/components/brands/settings/addPaymentCardDialog"
import cardListDialog from "@/components/brands/settings/addMoreCardDialog"
import constants from "@/config"
export default {
  components: {
    "clean-list": CleanList,
    BrandTableDialog,
    membershipExpiringDialog,
    addNewCardDialog,
    cardListDialog
  },
  data() {
    return {
      sureVisible: false,
      noPadding: {
        padding: "4px 16px 16px 16px"
      },
      indicator: <a-icon type="loading" style="font-size: 24px; color: #4a8a5d" spin />,
      isLoading: false,
      delayTime: 500
    }
  },
  computed: {
    cleanPreCheckList() {
      let data = this.$store.getters["brand/preCheckList"]
      console.log(data)
      return data
    },
    role() {
      return this.$store.getters["user/role"]
    },
    brandId() {
      if (this.$route.params.brandId) {
        return this.$route.params.brandId
      }
      return this.$store.getters["user/businessId"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    }
  },
  async mounted() {
    if (this.brandType === "basic" && this.isBrand) {
      this.$refs["membershipExpiringDialog"].openDialog()
      return
    } else if (this.$route.query.cpcmain) {
      // load data
      this.sureVisible = true
    }
    this.$store
      .dispatch("product/getBrandProducts", {
        params: {
          _limit: 9000,
          fields: "name,id,msrpUsd,sku",
          brand: this.brandId
        }
      })
      .then(() => {
        this.getData(1)
      })
  },
  methods: {
    openAddMoreCardDialog() {
      const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
      this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    },
    openAddPaymentDialog() {
      this.$refs["addNewCardDialog"].openAddCardDialog()
    },
    getData(page) {
      this.isLoading = true
      this.$store
        .dispatch("brand/getCleanPreCheckList", {
          brandId: this.brandId,
          currentPage: page
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    SubmitDialog() {
      this.sureVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.brand-clean-pre-check {
  width: 952px;
  margin: 0 auto;
  &__dialog-close-btn {
    width: 200px;
    height: 33px;
    line-height: 33px;
    border-radius: 2px;
    font-size: 14px;
    margin-top: 27px;
    background-color: #12433d;
    color: white;
    text-align: center;
    margin-left: 110px;
    cursor: pointer;
  }
  &__dialog-close-btn:hover {
    background-color: #4a8a5d;
    color: #ffffff;
  }
  &__dialog-info-before-submit {
    //width: 236px;
    text-align: center;
    color: #12433d;
    font-size: 13px;
    //margin-left: 77px;
    margin-top: 13px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  &__dialog-info-title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 23px;
  }
}
</style>
