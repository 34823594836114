<template>
  <div class="upload-video-dialog__main">
    <a-modal
      v-model="visible"
      width="756px"
      :footer="null"
      :mask="false"
      :mask-closable="false"
      class="modal-upload-content"
    >
      <span slot="closeIcon" @click="cancelDialog">
        <a-icon type="close-circle" style="font-size: 21px" />
      </span>
      <div class="dialog-main">
        <div class="dialog-title-icon">
          <a-progress type="circle" stroke-color="#4A8A5D" :percent="percent" :width="136">
            <template #format="percent">
              <a-icon type="upload" class="upload-icon-style" />
            </template>
          </a-progress>
        </div>
        <p class="dialog-video-title">Drag and drop a video file here</p>
        <a-upload
          name="file"
          :multiple="true"
          accept="video/*"
          :custom-request="handleChange"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          @change="handleChange"
        >
          <a-button class="dialog-video-btn">
            <a-icon type="upload" class="upload-icon-btn-style" />
            {{ UploadText }}
          </a-button>
        </a-upload>
        <p class="dialog-video-content-format">
          <span>Video Format:</span>
          MPEG-2 (.mpeg), Windows Media (.wmv), QuickTime (.mov), FLV (.flv)
        </p>
        <p class="dialog-video-content-format">
          <span>Minimum Quality:</span>
          872 kbps, 720px wide
        </p>
        <p class="dialog-video-content-format">
          <span>Maximum Size:</span>
          150MB (Best Results under 100MB)
        </p>
        <p v-if="errorText" class="error-text-style">
          <a-icon type="close-circle" theme="filled" class="error-text-icon" />
          {{ errorText }}
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
// import assetClient from "@/api/asset-client"
import trainingClient from "@/api/training-client"
import AWS from "aws-sdk"

//上传资源事件重写
// eslint-disable-next-line no-unused-vars
const beforeUploads = file => {
  getBase64(file, imageUrl => {
    /** */
    console.log("url====", imageUrl)
  })
  return false
}
//取图片base64
const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  name: "UpLoadVideoDialog",
  props: {
    product: {
      type: Object
    }
  },
  data() {
    return {
      visible: false,
      percent: 0,
      errorText: "",
      fileList: [],
      UploadText: "Upload",
      isShowReplaceBtn: false,
      descriptor: null,
      brandId: null
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  mounted() {
    // if (this.$route.query.id) {
    //
    // }
  },
  methods: {
    openUploadVideo(id) {
      this.brandId = id
      this.visible = true
      this.percent = 0
    },
    closeUploadVideo() {
      this.visible = false
    },
    hideModal() {},
    beforeUpload(file) {
      if (!file.type.includes("video")) {
        this.errorText = "Video Format is not supported, please try again."
        return false
      }
      if (file.size / (1024 * 1024) > 150) {
        this.errorText = "Video file exceeds Maximum Size, please try again."
        return false
      }
    },
    async handleChange(files) {
      this.UploadText = "Uploading"
      const file = files.file
      if (file.status === "uploading") {
        this.percent = file.percent > 90 ? 90 : file.percent
        await this.getAWSKey(file)
        return
      }
      if (file.status === "done" || file.status === "error") {
        this.percent = file.percent > 90 ? 90 : file.percent
        await this.getAWSKey(file)
        // Before: Backend upload files
        // const { data } = await assetClient.createAsset(file.originFileObj, this.product.brand.id)
        // if (data && data.length !== 0) {
        //   this.percent = 100
        //   this.isShowReplaceBtn = true
        //   this.UploadText = "Upload Successful"
        //   this.$emit("handleUploadInfo", data[0], true, this.product.id)
        // }
      }
      // this.fileList = [...file]
    },
    cancelDialog() {
      this.visible = false
      if (this.brandId) {
        return
      }
    },
    async getAWSKey(fileInfo) {
      const uploadInfo = {
        originalFileName: fileInfo.name,
        characterSet: "UTF-8",
        contentType: fileInfo.type,
        size: fileInfo.size
      }
      const { data } = this.isAdmin
        ? await trainingClient.getAWSKeyInfoInAdmin(
            uploadInfo,
            this.brandId ? this.brandId : this.product.brand.id
          )
        : await trainingClient.getAWSKeyInfo(uploadInfo)
      this.descriptor = data.descriptor
      let s3 = new AWS.S3({
        // region: data.credentialsState.region,
        region: "us-west-2",
        apiVersion: "2006-03-01",
        // authority: data.credentialsState.authority,
        accessKeyId: data.credentialsState.accessKeyId,
        secretAccessKey: data.credentialsState.secretAccessKey,
        sessionToken: data.credentialsState.sessionToken
      })
      let params = {
        Body: fileInfo.originFileObj,
        Bucket: data.credentialsState.bucketName,
        Key: data.descriptor.storageKey
      }
      s3.putObject(params, async (err, data) => {
        if (err) {
          this.$message.error("Upload failed")
        } else {
          this.percent = 100
          this.isShowReplaceBtn = true
          // this.UploadText = "Upload Successful"
          this.UploadText = "Upload"
          const successInfo = await trainingClient.sendUploadInfo(this.descriptor)
          const info = {
            file: {
              url: successInfo.data.url,
              id: successInfo.data.id,
              contentType: successInfo.data.contentType
            }
          }
          const params = {
            introductionAsset: {
              url: successInfo.data.url,
              id: successInfo.data.id,
              contentType: successInfo.data.contentType
            }
          }
          if (this.brandId) {
            await trainingClient.addBrandClientVideo(this.brandId, params)
            this.visible = false
            this.$emit("sendSuccess")
          } else {
            // successful response
            console.log(data)
            this.$emit("handleUploadInfo", info, true, this.product.id)
            this.closeUploadVideo()
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.upload-video-dialog__main {
}
.error-text-style {
  font-size: 10px;
  width: 100%;
  height: 20px;
  color: #ff6132;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-text-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #ff6132;
  margin-right: 24px;
}
.dialog-main {
  margin: 0 auto;
  text-align: center;
  padding: 125px 183px;
}
.dialog-video-title {
  font-size: 16px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  color: #000000;
  margin: 14px 0 22px 0;
  font-weight: bold;
}
.dialog-video-btn {
  //width: 103px;
  height: 40px;
  padding: 0 16px;
  line-height: 37px;
  color: #4a8a5d;
  font-size: 16px;
  margin: 0 auto;
  background-color: #e2eeec;
  border: 1px solid #4a8a5d;
  margin-bottom: 28px;
  cursor: pointer;
}
.dialog-video-content-format {
  width: 480px;
  font-size: 10px;
  text-align: left;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  span {
    margin-right: 18px;
    display: inline-block;
    width: 95px;
    text-align: right;
  }
}
.ant-modal-content {
  background-color: #fafafa;
}
.upload-icon-style {
  //font-size: 60px;
  color: #4a8a5d;
}
.ant-progress-circle .ant-progress-text .anticon {
  width: 121px;
  height: 121px;
  border-radius: 50%;
  font-size: 55px;
  line-height: 124px;
  background-color: #e2eeec;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  display: none;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #4a8a5d;
  background-color: #e2eeec;
  border: 1px solid #4a8a5d;
}
</style>
