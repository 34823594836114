import axios from "@/api/axios"

export default {
  update(retailerId, addresses) {
    return axios.put(`/retailers/${retailerId}/addresses`, JSON.stringify(addresses))
  },

  fetch(retailerId) {
    return axios.get(`/retailers/${retailerId}/addresses`)
  }
}
