<template>
  <div>
    <a-modal
      v-model="isShowUpdatePlanModal"
      :footer="null"
      :destroy-on-close="true"
      class="is-active brand-settings-modal"
      width="601px"
      :body-style="noPadding"
    >
      <template #closeIcon>
        <img
          class="request-terms-modal__close-button"
          src="@/assets/img/icon/close-icon.svg"
          @click="closeDialog"
        />
      </template>
      <p class="request-terms-modal__header no-margin">
        Edit
      </p>
      <div class="section modal-card-body subscription__form-block padding-small">
        <div class="modal-content">
          <a-row class="row-margin-bottom">
            <p class="change-plan-title">{{ $t("Brands.BrandSettings.changePlanType") }}</p>
            <a-radio-group v-model="initPlanType" @change="changePlanType">
              <template v-for="item in subscriptionTypes">
                <a-radio :key="item.id" class="modal-radio" :value="item.id">
                  {{ item.name }}
                </a-radio>
              </template>
            </a-radio-group>
          </a-row>
          <div v-if="showSubscriberExpired">
            <a-row>
              <a-col class="align-center" :span="8">
                <span class="change-plan-title">Renewal Date</span>
              </a-col>
            </a-row>
            <a-row>
              <a-col class="align-center" :span="8">
                <a-date-picker
                  name="getPremiumStartDate"
                  format="YYYY-MM-DD"
                  :disabled-date="disabledExpiredDate"
                  @change="onChangePlanExpiredDate"
                />
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="modal-card-foot modal-actions">
          <a class="button is-white" @click="closeUpdatePlanModal">
            {{ $t("Brands.BrandSettings.cancel") }}
          </a>
          <a class="button is-black" @click="savePlanUpdate">
            {{ $t("Brands.BrandSettings.save") }}
          </a>
        </div>
      </div>
    </a-modal>
    <confirm-cancel-subscription-dialog
      ref="cancelConfirmAdmin"
      @close="clickDialogCancel"
      @doCancel="clickCancelConfirm"
    />
  </div>
</template>

<script>
import { Radio, DatePicker, Row, Col, Modal } from "ant-design-vue"
import { required } from "vuelidate/lib/validators"
import moment from "moment"
import ConfirmCancelSubscriptionDialog from "@/components/brands/settings/CancelSubscriptionDialogNew"

export default {
  validations: {
    selectPlanStartDate: { required },
    selectPlanEndDate: { required },
    selectBasicStartDate: { required }
  },
  components: {
    "a-radio": Radio,
    "a-radio-group": Radio.Group,
    "a-date-picker": DatePicker,
    "a-modal": Modal,
    "a-row": Row,
    "a-col": Col,
    ConfirmCancelSubscriptionDialog
  },
  props: {
    brandType: { type: String, default: () => undefined }
  },
  data() {
    return {
      isShowUpdatePlanModal: false,
      showSubscriberExpired: false,
      selectedPlanType: undefined,
      expiredDate: undefined,
      initPlanType: undefined,
      noPadding: {
        padding: "0px"
      },
      subscriptionTypes: [],
      retailerData: undefined,
      selectedCancelData: undefined,
      brandId: undefined
    }
  },
  // updated() {
  //   if (this.initPlanType === undefined && this.brandType && this.brandType.length > 0) {
  //     this.initPlanType = this.brandType
  //   }
  // },
  methods: {
    closeDialog() {
      this.isShowUpdatePlanModal = false
      this.initPlanType = undefined
      this.selectedPlanType = undefined
      this.retailerData = undefined
      this.subscriptionTypes = []
      this.selectedCancelData = undefined
      this.brandId = undefined
    },
    openDialog(data, id) {
      this.retailerData = data
      this.brandId = id
      console.log(data)
      console.log(id)
      if (data.isCancel) {
        this.subscriptionTypes = [
          {
            id: "resubscribe",
            name: "Resubscribe"
          },
          {
            id: "expired",
            name: "Expired"
          }
        ]
      } else {
        this.subscriptionTypes = [
          {
            id: "cancel",
            name: "Cancel"
          },
          {
            id: "expired",
            name: "Expired"
          }
        ]
      }
      this.isShowUpdatePlanModal = true
      this.showSubscriberExpired = false
    },
    changePlanType() {
      console.log("....change plan type....")
      console.log(this.initPlanType)
      let plan = this.subscriptionTypes.find(item => item.id === this.initPlanType)
      this.selectedPlanType = plan.id
      if (this.selectedPlanType) {
        if (this.selectedPlanType === "expired") {
          this.showSubscriberExpired = true
        }
      }
    },
    disabledExpiredDate(currentDate) {
      const time = this.retailerData && this.retailerData.data && this.retailerData.data.startAt
      return (
        currentDate > moment(new Date()).endOf("day") ||
        currentDate <
          moment(time)
            .add(-1, "days")
            .endOf("day")
      )
    },
    onChangePlanExpiredDate(date, dateString) {
      this.expiredDate = dateString
    },
    closeUpdatePlanModal() {
      this.isShowUpdatePlanModal = false
    },
    getDateString(date) {
      if (date) {
        let dates = date.split("/")
        return `${dates[2]}-${dates[0]}-${dates[1]}`
      }
      return ""
    },
    savePlanUpdate() {
      console.log(this.selectedPlanType)
      if (this.selectedPlanType === "expired") {
        let param = {
          id: this.brandId,
          brandTrainingSubscriptions: [
            {
              retailerId: this.retailerData.retailerId,
              anniversaryDate: this.expiredDate,
              status: {
                id: "expired",
                name: "Expired"
              }
            }
          ]
        }
        console.log(param)
        this.updateBrandData(param, "Expired retailer")
      } else if (this.selectedPlanType === "cancel" || this.selectedPlanType === "resubscribe") {
        this.clickCancelAction()
      }
    },
    clickCancelAction() {
      this.selectedCancelData = this.retailerData.data
      if (this.retailerData.isCancel) {
        console.log("resubscribe this retailer...")
        this.selectedCancelData.status = {
          id: "activated",
          name: "Activated"
        }
        this.selectedCancelData.isAutoRenewal = true
        this.clickCancelConfirm()
      } else {
        this.selectedCancelData.status = {
          id: "cancelled",
          name: "Cancelled"
        }
        this.selectedCancelData.isAutoRenewal = false
        this.$refs["cancelConfirmAdmin"].openDialogBF()
      }
    },
    clickDialogCancel() {
      console.log("...click first btn....")
      this.selectedCancelData = undefined
    },
    clickCancelConfirm() {
      console.log("....click confirm cancel....")
      if (this.selectedCancelData) {
        let param = {
          id: this.brandId,
          brandTrainingSubscriptions: [this.selectedCancelData]
        }
        this.updateBrandData(param, this.retailerData.isCancel ? "Resubscribe" : "Cancel")
      }
    },
    updateBrandData(param, actionName) {
      this.$store
        .dispatch("brand/updateBrandPublic", param)
        .then(() => {
          this.closeDialog()
        })
        .catch(() => {
          this.$message.error(`${actionName} failed.`)
          this.closeDialog()
        })
    }
  }
}
</script>

<style lang="sass"></style>
