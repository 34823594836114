import httpclient from "@/api/http-client";
import User from "@/models/User";

export default {
  async getUser(providerId) {
    return await httpclient
      .get(`/api/users/${providerId}/details`)
      .then((res) => new User(res.data));
  },
  async getUserByEmail(email) {
    return await httpclient
      .get(`/api/users/by-email/${email}`)
      .then((res) => new User(res.data));
  },
};
