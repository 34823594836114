import axios from "axios";
import store from "@/store";
import { notification } from "ant-design-vue";

const env = process.env.VUE_APP_ENV;
const baseUrl = process.env.VUE_APP_NET_CORE_API_BASE_URL;
const httpClient = axios.create({
  baseURL: baseUrl,
});
httpClient.defaults.headers["Content-Type"] = "application/json";

// Request interceptor
httpClient.interceptors.request.use(
  async (config) => {
    if (!store.getters["user/role"]) {
      return config;
    }

    await store.dispatch("user/refreshAccessTokenLazily");
    const accessToken = store.getters["user/accessToken"];

    config.headers = {
      ...(config.headers || {}),
      ...{ Authorization: `Bearer ${accessToken}` },
    };
    //return config;
    // config.headers.common["Authorization"] = `Bearer ${accessToken}`
    // if (config && config.data && typeof config.data === "string") {
    //   config.data = config.data.replace(/""/g, null);
    // }
    return config;
  },
  (error) => Promise.reject(error)
);

httpClient.interceptors.response.use(handleSuccess, handleFail);

function handleSuccess(response) {
  return response;
}
function handleFail(error) {
  const errorMessage =
    error.response.data.title || error.response.data || error.response;

  if (
    env === "DEV" &&
    !(error.response.status == 204 || error.response.status == 404)
  ) {
    notification.open({
      message: "Error",
      description: errorMessage,
      type: "error",
      duration: 3,
    });
  }
  //Vue.event.$emit("show-error-dialog", errorMessage);

  return Promise.reject(error.response || error);
}

// Response interceptor
// httpClient.interceptors.response.use(handleResponseSuccess, handleResponseFail);

// /**
//  * 对成功返回的请求回包进行数据预处理,存在有错误的情况
//  * @param response
//  * @returns {*}
//  */
// function handleResponseSuccess(response) {
//   // status为200/201，但是实际是返回的错误信息的情况
//   if (response.data.reports && response.data.reports.length !== 0) {
//     let errors = fillHasReportsErrorMessage(response.data.reports);
//     const err = {
//       status: response.status,
//       message: errors.errorMessage,
//       downloadMessage: errors.downloadErrorMessage,
//     };
//     return Promise.reject(err);
//   }
//   if (!response.data.reports && response.data.error) {
//     let errors = fillErrorMessage(response.data.error);
//     const err = {
//       status: response.status,
//       message: errors.errorMessage,
//       downloadMessage: errors.downloadErrorMessage,
//     };
//     return Promise.reject(err);
//   }
//   return response;
// }

// /**
//  *
//  * @param error 后端返回的错误对象
//  * @returns {Promise<never>}
//  */
// function handleResponseFail(error) {
//   // console.log(3333, error.response)
//   let errorInfo = {
//     errorMessage: "",
//     downloadErrorMessage: "",
//   };
//   if (error.response.data.reports && error.response.data.reports.length !== 0) {
//     const info = fillHasReportsErrorMessage(error.response.data.reports);
//     errorInfo.errorMessage = info.errorMessage;
//     errorInfo.downloadErrorMessage = info.downloadErrorMessage;
//   }
//   if (error.response.data.developerMessage && !error.response.data.underlying) {
//     errorInfo.errorMessage = error.response.data.developerMessage;
//     errorInfo.downloadErrorMessage = error.response.data.developerMessage;
//   }
//   if (!error.response.data.reports) {
//     if (error.response.data.underlying) {
//       const info = fillErrorMessage(error.response.data);
//       errorInfo.errorMessage = info.errorMessage;
//       errorInfo.downloadErrorMessage = info.downloadErrorMessage;
//     } else if (
//       error.response.data.error &&
//       Array.isArray(error.response.data.error)
//     ) {
//       const info = fillErrorMessage(error.response.data.error);
//       errorInfo.errorMessage = info.errorMessage;
//       errorInfo.downloadErrorMessage = info.downloadErrorMessage;
//     }
//   }
//   if (
//     error.response.data.error &&
//     !error.response.data.developerMessage &&
//     !error.response.data.underlying &&
//     !error.response.data.reports
//   ) {
//     errorInfo.errorMessage = error.response.data.error;
//     errorInfo.downloadErrorMessage = error.response.data.error;
//   }
//   if (!error.response.data && !error.response.data.developerMessage) {
//     switch (error.response.status) {
//       case 400:
//         errorInfo.errorMessage = "Parameter error";
//         errorInfo.downloadErrorMessage = "Parameter error";
//         break;
//       case 304:
//         errorInfo.errorMessage = "Request error";
//         errorInfo.downloadErrorMessage = "Request error";
//         break;
//       case 401:
//         errorInfo.errorMessage =
//           "No login authentication, please confirm and try again.";
//         errorInfo.downloadErrorMessage =
//           "No login authentication, please confirm and try again.";
//         break;
//       case 404:
//         errorInfo.errorMessage = "Request Not found";
//         errorInfo.downloadErrorMessage = "Request Not found";
//         break;
//       case 405:
//         errorInfo.errorMessage = "The request is not allowed";
//         errorInfo.downloadErrorMessage = "The request is not allowed";
//         break;
//     }
//   }
//   const errors = {
//     status: error.response.status,
//     message: errorInfo.errorMessage,
//     downloadMessage: errorInfo.downloadErrorMessage,
//   };
//   return Promise.reject(errors);
// }

// /**
//  * 重新封装返回数据里面有reports字段的错误信息
//  */
// function fillHasReportsErrorMessage(errorInfo) {
//   let errorMessage = "";
//   let downloadErrorMessage = "";
//   // 对错误数据进行封装返回统一的错误格式
//   errorInfo.forEach((item) => {
//     let index = item.indexOf("cause:");
//     if (index > 0) {
//       errorMessage += `${item.substring(0, index)}<br/>`;
//       downloadErrorMessage += `${item.substring(0, index)}\n`;
//     } else {
//       errorMessage += `${item}<br/>`;
//       downloadErrorMessage += `${item}\n`;
//     }
//   });
//   return { errorMessage, downloadErrorMessage };
// }

// /**
//  * 重新封装返回数据里面含有underlying字段的错误数据
//  * @param errorInfo
//  * @returns {*}
//  */
// function fillErrorMessage(errorInfo) {
//   let errorMessage = "";
//   let downloadErrorMessage = "";
//   if (errorInfo.underlying && errorInfo.underlying.length !== 0) {
//     for (let i = 0; i < errorInfo.underlying.length; i++) {
//       if (errorInfo.underlying[i].userMessage) {
//         errorMessage += errorInfo.underlying[i].userMessage;
//         downloadErrorMessage += errorInfo.underlying[i].userMessage;
//         if (i < errorInfo.underlying.length - 1) {
//           errorMessage += ",<br/>";
//           downloadErrorMessage += ",\n";
//         }
//       }
//       if (errorInfo.underlying[i].developerMessage) {
//         errorMessage += errorInfo.underlying[i].developerMessage;
//         downloadErrorMessage += errorInfo.underlying[i].developerMessage;
//         if (i < errorInfo.underlying.length - 1) {
//           errorMessage += ",<br/>";
//           downloadErrorMessage += ",\n";
//         }
//       }
//     }
//   }
//   return { errorMessage, downloadErrorMessage };
// }

export default httpClient;
