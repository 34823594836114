<template>
  <div>
    <add-header
      :title="title"
      :is-show-edit-btn="!(selectDeleteData && selectDeleteData.size > 0)"
      :items="datas"
      :is-focus="isFocus"
      @clickDeleteAll="removeItem"
      @addMultiple="addMultiple"
      @editAll="showEditAllDialog"
      @saveDelete="showDeleteConfirm"
    />
    <div class="admin-console-add__divider"></div>
    <div style="padding-left: 30px;">
      <div
        v-for="(item, index) in datas"
        :key="`${title}_${index}`"
        style="margin-right: 12px; margin-bottom: 14px; display: inline-block;"
      >
        <div
          v-if="item && item.isEdit"
          class="admin-console-add__edit-input"
          contentEditable="true"
          @input="inputChange"
          @blur="showConfirm"
          @keyup.enter="showConfirm"
        >
          {{ item.name }}
        </div>
        <a-button
          v-else
          :disabled="item.isSelected"
          class="admin-console-add__item-btn"
          @click="editItem(item)"
        >
          {{ item.name }}
          <a-icon
            type="edit"
            class="admin-console-add__item-edit-icon"
            :style="item.isSelected ? 'color: #E8E8E8;' : 'color: #4A8A5D;'"
          ></a-icon>
        </a-button>
        <!-- <span>{{ isShowDeleteIcon }}, {{item.isSelected}}</span> -->
        <a-icon
          v-if="isShowDeleteIcon && !item.isSelected"
          type="close-circle"
          class="admin-console-add__close-icon"
          theme="filled"
          @click="chooseDelete(item)"
        />
        <div
          v-if="item.isSelected"
          style="width: 21px; height: 21px; border-radius: 50%; background: #8C8C8C;"
          class="admin-console-add__undo-icon"
        >
          <a-icon
            type="undo"
            style="color: white; font-size: 16px; position: absolute;top: 2px; right: 2px;"
            @click="unDoChoose(item)"
          />
        </div>
      </div>
    </div>
    <ConfirmDialog
      ref="confirmAdd"
      @doConfirm="handleInputConfirm"
      @cancelDialog="cancelAddConfirm"
    />
    <ConfirmDialog ref="confirmEdit" @doConfirm="handleEidtItem" @cancelDialog="cancelEdit" />
    <ConfirmDialog ref="confirmDelete" @doConfirm="saveDelete" />
    <edit-all-dialog ref="editAllDialog" @save="doEditAll" />
  </div>
</template>

<script>
import ConsoleAddHeader from "@/components/admin/ConsoleAddHeader"
import ConfirmDialog from "@/components/admin/ConfirmDialog"
import EditReferenceDialog from "@/components/admin/EditReferenceDialog"
import { checkHasSameNameData } from "@/utils/validate"
export default {
  components: {
    "add-header": ConsoleAddHeader,
    ConfirmDialog,
    "edit-all-dialog": EditReferenceDialog
  },
  props: {
    title: { type: String, default: () => "title" },
    isFocus: { type: Boolean, default: false },
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      newValue: "",
      showNew: false,
      datas: [],
      isShowDeleteIcon: false,
      selectDeleteData: new Set(),
      editedItem: undefined,
      isEditing: false,
      editedValue: "",
      deletedData: undefined
    }
  },
  computed: {
    isModifySuccess() {
      return this.$store.getters["reference/isModifySuccess"]
    }
  },
  watch: {
    items() {
      this.datas = this.items
      if (this.datas && this.datas.length > 0) {
        this.datas.forEach(data => {
          data.isSelected = false
        })
      }
    },
    isModifySuccess(value) {
      if (value) {
        this.doModifySuccess()
      }
    }
  },
  methods: {
    //all this operation is for all the items
    removeItem() {
      this.isShowDeleteIcon = true
    },
    addMultiple(newValues) {
      this.$emit("addMultiple", newValues)
    },
    showEditAllDialog() {
      this.$refs["editAllDialog"].openDialog(this.datas)
    },
    doEditAll() {
      this.$emit("editAll", this.datas)
    },
    editItem(item) {
      item.isEdit = true
      this.editedItem = item
      this.isEditing = false
      this.editedValue = item.name
      this.$forceUpdate()
    },
    handleEidtItem() {
      if (checkHasSameNameData(this.editedValue, this.datas)) {
        this.$message.error("Has same name, can't save the data !")
        this.cancelEdit()
      } else {
        this.editedItem.name = this.editedValue
        this.editedValue = ""
        this.$emit("editItem", this.editedItem)
      }
    },
    createNewItem() {
      this.showNew = true
    },
    handleInputConfirm() {
      let param = {
        name: this.newValue,
        isActive: true
      }
      this.$emit("addNewItem", param)
      this.showNew = false
    },
    cancelAddConfirm() {
      this.showNew = false
    },
    chooseDelete(item) {
      this.selectDeleteData.add(item)
      item.isSelected = true
      item.isActive = false
      this.$forceUpdate()
    },
    unDoChoose(item) {
      this.selectDeleteData.delete(item)
      item.isSelected = false
      item.isActive = true
      this.$forceUpdate()
    },
    saveDelete() {
      this.$emit("removeItem", this.deletedData)
    },
    doModifySuccess() {
      this.selectDeleteData.clear()
      this.isShowDeleteIcon = false
      this.showNew = false
      this.newValue = ""
      if (this.editedItem) {
        this.editedItem.isEdit = false
        this.editedItem = undefined
      }
      this.$forceUpdate()
    },
    inputChange(event) {
      console.log(event)
      let type = event.inputType
      let value = event.target.innerText
      if (type !== "insertParagraph") {
        if (
          value !== undefined &&
          value.replace(/^\s*|\s*$/g, "").length > 0 &&
          value.indexOf("\n") < 0
        ) {
          this.editedValue = value.replace(/^\s*|\s*$/g, "")
        }
      }
    },
    cancelEdit() {
      this.isEditing = false
      this.editedItem.isEdit = false
      this.$forceUpdate()
    },
    showConfirm(event) {
      let value = event.target.outerText
      this.showConfirmEditDialog(value)
    },
    showConfirmEditDialog(value) {
      if (value === undefined || value.replace(/^\s*|\s*$/g, "").length === 0) {
        this.$message.info("Can't Set empty value!")
        this.editedItem.isEdit = false
        this.$forceUpdate()
      } else {
        if (!this.isEditing) {
          this.$refs["confirmEdit"].openDialogMsg("Are you sure you want to save changes?")
          this.isEditing = true
        }
      }
    },
    showAddConfirm() {
      this.$refs["confirmAdd"].openDialogMsg("Are you sure you want to save changes?")
    },
    showDeleteConfirm(data) {
      this.deletedData = data
      this.$refs["confirmDelete"].openDialogMsg(
        `Are you sure you want to delete these ${this.title}?`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-console-add {
  &__btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    background: #e2eeec;
    color: #4a8a5d;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
  }
  &__item-btn {
    background: #fff !important;
    border: 1px solid #4a8a5d !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 0px 8px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #4a8a5d !important;
  }
  &__item-edit-icon {
    color: #4a8a5d;
    font-size: 14px;
    margin-bottom: 4px;
  }
  &__add-new-input {
    background: #ffffff;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 2px;
    width: 100px;
    height: 32px;
  }
  &__close-icon {
    color: #ff4d4f;
    float: right;
    margin-top: -10px;
    margin-left: -10px;
    font-size: 18px;
    z-index: 199;
    position: relative;
  }
  &__undo-icon {
    float: right;
    margin-top: -10px;
    margin-left: -10px;
    font-size: 18px;
    z-index: 199;
    text-align: center;
    position: relative;
  }
  &__edit-input {
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #bfbfbf;
    border: 1px solid #ff6132;
    box-sizing: border-box;
    border-radius: 2px;
  }
  &__divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 50px;
  }
  &__confirm-btn {
    background: #4a8a5d;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
  }
}
.ant-btn-admin-console-add__confirm-btn {
  background: #4a8a5d;
  border: 1px solid #4a8a5d;
  box-sizing: border-box;
  border-radius: 2px;
}
.admin-console-add__item-btn[disabled] {
  background: #f5f5f5 !important;
  border: 1px solid #bfbfbf !important;
  box-sizing: border-box;
  border-radius: 2px !important;
  color: #bfbfbf !important;
}
.admin-console-add__item-edit-icon[disabled] {
  color: #e8e8e8;
}
</style>
