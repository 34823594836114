<template>
  <div>
    <a-spin :spinning="isLoading" :delay="delayTime" :indicator="indicator">
      <clean-list
        class="retailer-clean-pre-check"
        :data-list="cleanPreCheckList"
        @changePage="getData"
      />
    </a-spin>
    <a-modal
      v-model="sureVisible"
      :centered="true"
      :footer="null"
      :width="464"
      :title="null"
      :body-style="noPadding"
    >
      <span slot="closeIcon">
        <a-icon type="close-circle" style="font-size: 21px" />
      </span>
      <div class="retailer-clean-pre-check__dialog-info-before-submit">
        <p class="retailer-clean-pre-check__dialog-info-title">Welcome to Clean PreCheck</p>
        <p class="dialog-content-style">
          Please edit your Clean Standards in
          <a @click="clickToRetailerSetting">Retailer Settings</a><br />
          so we can match you with products that meet your requirements.
        </p>
        <p class="retailer-clean-pre-check__dialog-close-btn" @click="SubmitDialog">OK</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import CleanList from "@/components/clean/CleanList"
export default {
  components: {
    "clean-list": CleanList
  },
  data() {
    return {
      sureVisible: false,
      noPadding: {
        padding: "4px 16px 16px 16px"
      },
      indicator: <a-icon type="loading" style="font-size: 24px; color: #4a8a5d" spin />,
      isLoading: false,
      delayTime: 500
    }
  },
  computed: {
    cleanPreCheckList() {
      let data = this.$store.getters["retailer/preCheckList"]
      return data
    },
    retailerId() {
      if (this.$route.name === "adminRetailerCleanCheck") {
        return this.$route.params.retailerId
      }
      return this.$store.getters["user/businessId"]
    }
  },
  async created() {
    if (this.$route.query.cpcmain) {
      this.sureVisible = true
    }
    // load data
    this.getData(1)
  },
  methods: {
    getData(page) {
      this.isLoading = true
      this.$store
        .dispatch("retailer/getCleanPreCheckList", {
          retailerId: this.retailerId,
          currentPage: page
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    clickToRetailerSetting() {
      this.$router.push({
        name: "retailerSettings"
      })
    },
    SubmitDialog() {
      this.sureVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.retailer-clean-pre-check {
  width: 952px;
  margin: 0 auto;
  &__dialog-close-btn {
    width: 200px;
    height: 33px;
    line-height: 33px;
    border-radius: 2px;
    font-size: 14px;
    margin-top: 27px;
    background-color: #12433d;
    color: white;
    text-align: center;
    margin-left: 110px;
    cursor: pointer;
  }
  &__dialog-close-btn:hover {
    background-color: #4a8a5d;
    color: #ffffff;
  }
  &__dialog-info-before-submit {
    //width: 236px;
    text-align: center;
    color: #12433d;
    font-size: 13px;
    //margin-left: 77px;
    margin-top: 13px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  &__dialog-info-title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 23px;
  }
}
</style>
