import trainingClient from "@/api/training-client";
import productClient from "@/api/product-client";
import {
  formatTime,
  setTrainingTag,
  getTrainingTag,
  deleteTrainingTag,
} from "@/utils/validate";

export default {
  namespaced: true,
  state: {
    list: [],
    productList: [],
    adminProductList: [],
    crossSellers: [],
    adminTrainingPagination: {},
    trainingRetailer: [],
  },
  mutations: {
    SET_PRODUCT_LIST(state, product) {
      state.productList = product;
    },
    SET_ADMIN_PRODUCT_LIST(state, list) {
      state.adminProductList = list;
    },
    SET_CROSS_SELLERS(state, list) {
      state.crossSellers = list;
    },
    SET_ADMIN_TRAINING_PAGINATION(state, pageInfo) {
      state.adminTrainingPagination = pageInfo;
    },
    SET_LIST(state, list) {
      state.list = list;
    },
    SET_TRAINING_RETAILER(state, data) {
      state.trainingRetailer = data.map((item) => {
        if (item.retailerId === "*") {
          item.retailerName =
            item.retailerName.substring(0, 1).toUpperCase() +
            item.retailerName.substring(1);
        }
        item.status = item.gradeType
          ? item.gradeType.substring(0, 1).toUpperCase() +
            item.gradeType.substring(1)
          : "General";
        return item;
      });
    },
  },
  getters: {
    getTrainingProducts(state) {
      // const saveDraftList = JSON.parse(getSesStorage("SAVE_DRAFT_ARR_LIST")) || []
      const saveDraftList = [];
      return saveDraftList.concat(state.productList).map((item) => {
        // item.updatedAt = formatTime(item.updatedAt)
        item.modified = item.updatedAt ? item.updatedAt : item.createdAt;
        item.isDetele = false;
        if (item.training.states === 2) {
          item.public = true;
        } else {
          item.public = false;
        }
        return item;
      });
    },
    searchProductList(state) {
      if (state.adminProductList && state.adminProductList.length === 0) {
        return [];
      }
      return state.adminProductList.map((item) => {
        let isPaid = false;
        if (
          item.trainingSubscriptions &&
          item.trainingSubscriptions.length > 0
        ) {
          for (let i = 0; i < item.trainingSubscriptions.length; i++) {
            if (item.trainingSubscriptions[i].statusId === "activated") {
              isPaid = true;
              break;
            }
          }
        }
        return {
          brandId: item.brand.id,
          brandLink: item.brand.website,
          name: item.brand.name,
          shortName: item.brand.shortName,
          productCount: item.productCount.count,
          ...item.productTrainingCount,
          modified: formatTime(item.productTrainingCount.modified),
          isCopyTrainingLink: false,
          isDownloadQR: false,
          isHasBeautyfluent:
            item.trainingSubscriptions && item.trainingSubscriptions.length > 0,
          isBeautyfluentPaid: isPaid,
        };
      });
    },
    getCrossSellers(state) {
      if (state.crossSellers && state.crossSellers.length === 0) {
        return [];
      }
      return state.crossSellers.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },
    getTrainingRetailer(state) {
      return state.trainingRetailer;
    },
  },
  actions: {
    async getProductList({ commit }, brandId) {
      const { data } = await trainingClient.getTrainingProducts(brandId);
      commit("SET_PRODUCT_LIST", data);
    },
    async getSearchTrainingPro({ commit }, { name, params }) {
      const { data } = await trainingClient.searchTrainingProduct(name, params);
      commit("SET_ADMIN_PRODUCT_LIST", data.brands);
      commit("SET_ADMIN_TRAINING_PAGINATION", data);
      return data;
    },
    async getSearchTrainingBrand({ commit }, params) {
      try {
        const { data } = await trainingClient.searchTrainingBrand(params);
        commit("SET_ADMIN_PRODUCT_LIST", data.brands);
        commit("SET_ADMIN_TRAINING_PAGINATION", data);
      } catch (error) {
        if (
          (error.status && error.status === 404) ||
          (error.response && error.response.status === 404)
        ) {
          commit("SET_ADMIN_PRODUCT_LIST", []);
          commit("SET_ADMIN_TRAINING_PAGINATION", {});
        } else {
          return Promise.reject(error);
        }
      }
    },
    async getProductTraining({ commit }, params) {
      const { data } = await productClient.getProductsByParams(params);
      commit("SET_CROSS_SELLERS", data);
    },
    async getProducts({ commit }, id) {
      const { data } = await trainingClient.getProductList(id);
      commit("SET_LIST", data);
      return data;
    },
    async getRetailersTableList() {
      // 假数据
      return [
        {
          name: "retailer1",
          products: 3,
          published: 5,
          modified: "2022-1-4",
          public: false,
          publicNumber: 23,
        },
        {
          name: "retailer2",
          products: 4,
          published: 2,
          modified: "2022-2-17",
          public: true,
          publicNumber: 55,
        },
      ];
    },
    // eslint-disable-next-line no-empty-pattern
    getSearchBrandTags({}, tagName) {
      return getTrainingTag(tagName);
    },
    // eslint-disable-next-line no-empty-pattern
    updateBrandTags({}, { tagName, tagItem, type }) {
      const params = {
        name: tagItem,
      };
      if (type && type === "delete") {
        const list = getTrainingTag(tagName);
        const result = list.filter((item) => item.name !== tagItem);
        deleteTrainingTag(tagName, result);
        return result;
      }
      return setTrainingTag(tagName, params);
    },
    async getUsersInfo() {
      return [
        {
          created: "2020-2-3",
          first: "AAA",
          last: "BBB",
          email: "www.bbb.com",
          retailer: "Ulta",
          locationId: "32342s",
          lastLogin: "2022-2-16",
          sessions: 125,
        },
      ];
    },
    // eslint-disable-next-line no-empty-pattern
    async getBrandProduct({}, id) {
      const { data } = await trainingClient.getProductTrainingList(id);
      return data;
    },
    // eslint-disable-next-line no-empty-pattern
    async updateTrainingRetailer({}, { brandId, params }) {
      const { data } = await trainingClient.updateBrandTrainingSubscription(
        brandId,
        params
      );
      return data;
    },
    // eslint-disable-next-line no-empty-pattern
    async updatePaymentTrainingSubscription({}, { brandId, payment }) {
      const { data } = await trainingClient.paymentTrainingSubscription(
        brandId,
        payment
      );
      return data;
    },
    // eslint-disable-next-line no-empty-pattern
    async brandPaymentForSub({}, { brandId, param }) {
      const { data } = await trainingClient.brandPayment(brandId, param);
      return data;
    },
    async getTrainingRetailer({ commit }) {
      try {
        const { data } = await trainingClient.getTrainingRetailer();
        commit("SET_TRAINING_RETAILER", data);
      } catch (error) {
        if (
          (error && error.status === 404) ||
          (error.response && error.response.status === 404)
        ) {
          commit("SET_TRAINING_RETAILER", []);
        } else {
          return Promise.reject(error);
        }
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async getBrandTraining({}, brandId) {
      try {
        const { data } = await trainingClient.getBrandTrainingStatics(brandId);
        return data;
      } catch (error) {
        if (
          (error && error.status === 404) ||
          (error.response && error.response.status === 404)
        ) {
          return [];
        } else {
          return Promise.reject(error);
        }
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async updateTrainingProductRetailer({}, { productId, params }) {
      const protips = await trainingClient.getBrandTrainingProTips(productId);
      const { data } = await trainingClient.updateProductTrainingRetailer(
        productId,
        params
      );
      if (protips) {
        await trainingClient.updateBrandTrainingProTips(productId, protips);
      }
      return data;
    },
    // eslint-disable-next-line no-empty-pattern
    async uploadRetailerLocation({}, { retailerId, file }) {
      const response = await trainingClient.uploadRetailerLocationList(
        retailerId,
        file
      );
      return response;
    },
  },
};
