var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"sign-up-title"},[_vm._v(_vm._s(_vm.title))]),_c('a-form',{staticClass:"sign-up",attrs:{"layout":"horizontal","form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    'name',
    {
      rules: [
        {
          required: true,
          message: `${_vm.pName} is required`,
        },
        {
          validator: _vm.validateBrandName,
        },
      ],
    },
  ]),expression:"[\n    'name',\n    {\n      rules: [\n        {\n          required: true,\n          message: `${pName} is required`,\n        },\n        {\n          validator: validateBrandName,\n        },\n      ],\n    },\n  ]"}],attrs:{"placeholder":_vm.pName}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    'url',
    {
      rules: [
        {
          required: true,
          message: `${_vm.pUrl} is required`,
        },
        {
          validator: _vm.validateBrandWebsite,
        },
      ],
    },
  ]),expression:"[\n    'url',\n    {\n      rules: [\n        {\n          required: true,\n          message: `${pUrl} is required`,\n        },\n        {\n          validator: validateBrandWebsite,\n        },\n      ],\n    },\n  ]"}],attrs:{"placeholder":_vm.pUrl}})],1),_c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    'firstName',
    {
      rules: [
        {
          required: true,
          message: 'First Name is required',
        },
      ],
    },
  ]),expression:"[\n    'firstName',\n    {\n      rules: [\n        {\n          required: true,\n          message: 'First Name is required',\n        },\n      ],\n    },\n  ]"}],attrs:{"placeholder":"First Name"}})],1)],1),_c('div',{staticClass:"column"},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    'lastName',
    {
      rules: [
        {
          required: true,
          message: 'Last Name is required',
        },
      ],
    },
  ]),expression:"[\n    'lastName',\n    {\n      rules: [\n        {\n          required: true,\n          message: 'Last Name is required',\n        },\n      ],\n    },\n  ]"}],attrs:{"placeholder":"Last Name"}})],1)],1)])]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    'email',
    {
      rules: [
        {
          validator: _vm.validateEmail,
        },
        {
          required: true,
          message: 'E-mail is required',
        },
      ],
    },
  ]),expression:"[\n    'email',\n    {\n      rules: [\n        {\n          validator: validateEmail,\n        },\n        {\n          required: true,\n          message: 'E-mail is required',\n        },\n      ],\n    },\n  ]"}],attrs:{"placeholder":"Email"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    'password',
    {
      rules: [
        {
          required: true,
          message: 'Password is required',
        },
        {
          min: 8,
          message: 'Password must be at least 8 characters',
        },
        {
          validator: _vm.validateToNextPassword,
        },
      ],
    },
  ]),expression:"[\n    'password',\n    {\n      rules: [\n        {\n          required: true,\n          message: 'Password is required',\n        },\n        {\n          min: 8,\n          message: 'Password must be at least 8 characters',\n        },\n        {\n          validator: validateToNextPassword,\n        },\n      ],\n    },\n  ]"}],attrs:{"placeholder":"Password","type":"password"},on:{"blur":_vm.handleConfirmPasswordBlur}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    'confirm',
    {
      rules: [
        {
          required: true,
          message: 'Confirm Password is required',
        },
        {
          validator: _vm.compareToFirstPassword,
        },
      ],
    },
  ]),expression:"[\n    'confirm',\n    {\n      rules: [\n        {\n          required: true,\n          message: 'Confirm Password is required',\n        },\n        {\n          validator: compareToFirstPassword,\n        },\n      ],\n    },\n  ]"}],attrs:{"placeholder":"Confirm Password","type":"password"}})],1),(_vm.type === '1')?_c('a-form-item',{staticClass:"agreement"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    'agreement',
    {
      valuePropName: 'checked',
      rules: [
        // {
        //   required: true,
        //   message:
        //     'Please accept the terms and privcy policy to proceed',
        // },
        {
          validator: _vm.validateAgreement,
        },
      ],
    },
  ]),expression:"[\n    'agreement',\n    {\n      valuePropName: 'checked',\n      rules: [\n        // {\n        //   required: true,\n        //   message:\n        //     'Please accept the terms and privcy policy to proceed',\n        // },\n        {\n          validator: validateAgreement,\n        },\n      ],\n    },\n  ]"}]},[_vm._v(" By registering for a free trial, you agree to the "),_c('span',{staticClass:"sign-up-link",on:{"click":_vm.jumpToTerms}},[_vm._v("Landing Terms of Use")]),_vm._v(" and "),_c('span',{staticClass:"sign-up-link",on:{"click":_vm.jumpToPrivacy}},[_vm._v("Privacy Policy")])])],1):_vm._e(),_c('a-form-item',{staticClass:"center"},[_c('a-button',{on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.submitLabel))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }