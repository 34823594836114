<template>
  <section class="height-for-sticky-footer">
    <div
      v-if="isResetPasswordTokenValid === false"
      class="verify-email__container height-for-sticky-footer verify-email-style"
    >
      <div class="columns is-centered is-flex verify">
        <div class="column verify-email__box">
          <div class="verify-email__form">
            <div class="title">
              {{ $t("Auth.Verify.notValidLink") }}
            </div>
            <p>
              {{ $t("Auth.AddMember.invitationExpired") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="action == 'decline' && isResetPasswordTokenValid"
      class="login__container height-for-sticky-footer"
    >
      <div class="columns is-centered is-flex verify">
        <div class="column add-member__box">
          <div class="add-member__form">
            <div class="title">
              {{ $t("Auth.AddMember.invitationDeclined") }}
            </div>
            <p>
              {{ $t("Auth.AddMember.declinedMessage") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="action == 'accept' && isResetPasswordTokenValid"
      class="login__container height-for-sticky-footer"
    >
      <div class="columns is-centered is-flex">
        <div class="column add-member__signup-box">
          <div class="add-member__form">
            <h2 class="add-member__title">
              {{ $t("Auth.AddMember.getStarted") }}
            </h2>
            <div class="columns">
              <div class="column reset-form">
                <div class="control">
                  <label class="signup-label">
                    {{ $t("Auth.AddMember.emailAddress") }}
                  </label>
                  <input
                    v-model="$v.email.$model"
                    class="input signup-member__input"
                    :readonly="true"
                  />
                </div>
                <div class="control">
                  <label class="signup-label">
                    {{ $t("Auth.AddMember.createPassword") }}
                  </label>
                  <input
                    v-model="$v.password.$model"
                    class="input signup-member__input"
                    :type="createPasswordType"
                    :placeholder="$t('Auth.AddMember.createPassword')"
                    :class="{ 'is-danger': $v.password.$error }"
                    @input="upperCaseAndNumberValidation = false"
                  />
                  <span class="retailer-settings__eyeIcon" @click="createPasswordVisibility">
                    <img
                      v-if="createPasswordType == 'password'"
                      src="@/assets/img/icon/passwordEye.svg"
                    />
                    <img
                      v-if="createPasswordType == 'text'"
                      src="@/assets/img/icon/preview-icon.svg"
                    />
                  </span>
                  <p
                    v-if="$v.password.$error && !$v.password.required"
                    class="validation-message help is-danger"
                  >
                    {{ $t("Auth.AddMember.required") }}
                  </p>
                  <p
                    v-if="$v.password.$error && !$v.password.minLength"
                    class="validation-message help is-danger"
                  >
                    {{ $t("Auth.ResetPassword.Validations.mustBe10Characters") }}
                  </p>
                  <p v-if="upperCaseAndNumberValidation" class="validation-message help is-danger">
                    {{ $t("Retailers.RetailerSettings.Validations.mustContainUpperCaseAndNumber") }}
                  </p>
                </div>
                <div class="control">
                  <label class="signup-label">
                    {{ $t("Auth.AddMember.confirmPassword") }}
                  </label>
                  <input
                    v-model="$v.passwordConfirmation.$model"
                    class="input signup-member__input"
                    :type="confirmPasswordType"
                    placeholder="Confirm New Password"
                    :class="{ 'is-danger': $v.passwordConfirmation.$error }"
                  />
                  <span class="retailer-settings__eyeIcon" @click="confirmPasswordVisibility">
                    <img
                      v-if="confirmPasswordType == 'password'"
                      src="@/assets/img/icon/passwordEye.svg"
                    />
                    <img
                      v-if="confirmPasswordType == 'text'"
                      src="@/assets/img/icon/preview-icon.svg"
                    />
                  </span>
                  <p
                    v-if="$v.passwordConfirmation.$error && !$v.passwordConfirmation.required"
                    class="validation-message help is-danger"
                  >
                    >
                  </p>
                  <p
                    v-if="!$v.passwordConfirmation.sameAsPassword && passwordConfirmation.length"
                    class="validation-message help is-danger"
                  >
                    {{ $t("Auth.ResetPassword.Validations.passwordConfirmation") }}
                  </p>
                </div>
                <div v-show="isAuthInProgress" class="field is-marginless">
                  <div class="control has-text-centered">
                    <img src="@/assets/img/spinner.gif" alt="spinner" />
                  </div>
                </div>
                <button class="button signup-button is-black" @click="createPasswordAndSignUp">
                  {{ $t("Auth.AddMember.signUp") }}
                </button>
              </div>
            </div>
            <div class="add-member__question">
              <p class="login__register-question">
                {{ $t("Auth.AddMember.alreadyMember") }}
              </p>
              <button class="login__register-sign-up" @click="goToLogin">
                {{ $t("Auth.Login.signIn") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators"

export default {
  validations: {
    email: {},
    password: { required, minLength: minLength(10) },
    passwordConfirmation: { required, sameAsPassword: sameAs("password") }
  },
  data() {
    return {
      email: this.$route.query.email,
      userId: this.$route.query.userId,
      token: this.$route.query.token,
      action: this.$route.query.action,
      brandId: this.$route.query.brandId,
      retailerId: this.$route.query.retailerId,
      password: "",
      passwordConfirmation: "",
      createPasswordType: "password",
      confirmPasswordType: "password",
      showValidations: false,
      isResetPasswordTokenValid: null,
      isAuthInProgress: false,
      upperCaseAndNumberValidation: false,
      isCanSignUp: true
    }
  },
  async created() {
    if (!this.action || !this.token) {
      return
    }
    if (this.brandId) {
      await this.$store.dispatch("user/checkMember", this.brandId)
      this.isCanSignUp = this.$store.getters["user/checkMemberResult"]
    }
    if (this.action === "accept" || this.action === "decline") {
      try {
        await this.$store.dispatch("user/verifyResetToken", this.token)
        this.isResetPasswordTokenValid = true
      } catch (error) {
        this.isResetPasswordTokenValid = false
      }
    }
    if (this.action === "decline" && this.brandId) {
      try {
        let params = {}
        let member = this.declineMemberObject()
        let brandid = this.brandId
        params = {
          _authenticated: true,
          "_jwt-u_id": this.userId,
          "_jwt-c_id": this.brandId,
          "_jwt-c_type": "brand"
        }
        await this.$store.dispatch("brand/updateMemberStatusAfterInvite", {
          member,
          brandid,
          params
        })
      } catch (error) {
        console.error(error)
      }
    } else if (this.action === "decline" && this.retailerId) {
      try {
        let params = {}
        let member = this.declineMemberObject()
        let retailerid = this.retailerId
        params = {
          _authenticated: true,
          "_jwt-u_id": this.userId,
          "_jwt-c_id": this.retailerId,
          "_jwt-c_type": "retailer"
        }
        await this.$store.dispatch("retailer/updateMemberStatusAfterInvite", {
          member,
          retailerid,
          params
        })
      } catch (error) {
        console.error(error)
      }
    }
  },
  methods: {
    declineMemberObject() {
      return {
        membershipStatus: {
          id: "declined",
          name: "Declined"
        },
        user: {
          id: this.userId
        }
      }
    },
    accpectMemberObject() {
      return {
        membershipStatus: {
          id: "added",
          name: "Added"
        },
        user: {
          id: this.userId
        }
      }
    },
    passwordCheck() {
      if (this.password.search(/[A-Z]/) == -1 || this.password.search(/[0-9]/) == -1) {
        return true
      }
    },
    createPasswordVisibility() {
      this.createPasswordType = this.createPasswordType === "password" ? "text" : "password"
    },
    confirmPasswordVisibility() {
      this.confirmPasswordType = this.confirmPasswordType === "password" ? "text" : "password"
    },
    async createPasswordAndSignUp() {
      if (this.isCanSignUp === "false") {
        this.$message.warn(this.$t("Auth.AddMember.memberEnough"))
        return
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.showValidations = true
        return
      }
      if (this.passwordCheck()) {
        this.upperCaseAndNumberValidation = true
        return
      }
      this.isAuthInProgress = true
      try {
        await this.$store.dispatch("user/resetPassword", {
          token: this.token,
          password: this.password
        })
        this.isResetSuccessful = true
      } catch (error) {
        console.error(error)
      }
      if (this.isResetSuccessful && this.brandId) {
        try {
          this.acceptBrandMember()
        } catch (error) {
          console.error(error)
        }
      } else if (this.isResetSuccessful && this.retailerId) {
        try {
          this.acceptRetailerMember()
        } catch (error) {
          console.error(error)
        }
      }
      this.isAuthInProgress = false
      if (this.isResetSuccessful) {
        this.$router.push({ name: "login", query: { email: this.email } })
      }
      this.upperCaseAndNumberValidation = false
      this.isResetSuccessful = false
    },
    acceptBrandMember() {
      let params = {}
      let member = this.accpectMemberObject()
      let brandid = this.brandId
      params = {
        _authenticated: true,
        "_jwt-u_id": this.userId,
        "_jwt-c_id": this.brandId,
        "_jwt-c_type": "brand"
      }
      this.$store.dispatch("brand/updateMemberStatusAfterInvite", { member, brandid, params })
    },
    acceptRetailerMember() {
      let params = {}
      let member = this.accpectMemberObject()
      let retailerid = this.retailerId
      params = {
        _authenticated: true,
        "_jwt-u_id": this.userId,
        "_jwt-c_id": this.retailerId,
        "_jwt-c_type": "retailer"
      }
      this.$store.dispatch("retailer/updateMemberStatusAfterInvite", { member, retailerid, params })
    },
    goToLogin() {
      this.$router.push({ name: "login", query: { email: this.email } })
      if (this.action === "accept" && this.brandId) {
        try {
          this.acceptBrandMember()
        } catch (error) {
          console.error(error)
        }
      } else if (this.action === "accept" && this.retailerId) {
        try {
          this.acceptRetailerMember()
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.add-member__question
  display: flex
  justify-content: space-between

.add-member-style
  margin-top: -107px

.signup-label
  font-size: 14px

.signup-button
  display: flex
  margin-top: 20px
  width: 100%
  height: 46px
  width: 312px
  border-radius: 2px

.signup-member__input
  height: 40px
  border: 1px solid #E5E5E5
  border-radius: 2px
  margin-top: 4px
  margin-bottom: 23px

.add-member
  &__container
    display: flex
    justify-content: center
    align-items: center

  &__box
    width: 360px
    padding: 25px
    height: 31px
    width: 360px
    border-radius: 2px
    background-color: rgba(255,255,255,0.5)
    color: #212B36
    font-size: 14px
  &__form
    width: 350px
    margin: 0 auto

  &__signup-box
    padding: 25px
    padding-top: 58px
    height: 510px
    width: 360px
    border: 1px solid #E5E5E5
    border-radius: 6px
    background-color: rgba(255, 255, 255, 0.5)
    color: #212B36
    font-size: 14px

  &__title
    color: #000000
    font-size: 16px
    letter-spacing: 0.44px
    line-height: 24px
    margin-bottom: 16px
    text-align: center

  &__subtitle
    color: #8D8D8D
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px
    padding-bottom: 22px
    text-align: center

  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 120px 0px 25px 0px

  &__verified-divider
    border-bottom: 1px solid #e5e5e5
    margin: 165px 0px 25px 0px

  &__links
    display: flex
    justify-content: space-between

  &__link-title
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px

  &__link-resend
    color: #008489
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 17px
    cursor: pointer

.height-for-sticky-footer
  height: calc(101vh - 72px)
</style>
