<template>
  <div class="dropdown-list">
    <div
      ref="tags"
      class="dropdown-list__tags"
      :class="{ 'dropdown-list__active': isOpen, 'dropdown-list__disabled': disabled }"
      @click.stop="activate()"
    >
      <div
        class="dropdown-list__placeholder-text"
        :style="isOpen || (selected && selected.size > 0) ? 'color: #4A8A5D;' : 'color: #BFBFBF;'"
      >
        {{ tagValues ? tagValues : placeholder }}
      </div>
      <a-icon
        :type="isOpen ? 'caret-up' : 'caret-down'"
        :style="isOpen ? 'color: #4A8A5D;' : 'color: #8C8C8C;'"
        class="dropdown-list__right-arrow"
      />
    </div>
    <transition>
      <div v-if="isOpen" ref="list" tabindex="-1" class="dropdown-list__content-wrapper">
        <div v-if="!isDateList" class="dropdown-list__search-area">
          <a-input
            v-model="searchValue"
            class="dropdown-list__search-input"
            @change="onSearch"
            @click.stop
          >
            <a-icon slot="prefix" type="search" style="color: #4A8A5D" />
          </a-input>
        </div>
        <div
          :style="{ maxHeight: optimizedHeight + 'px' }"
          style="overflow-y: auto; overflow-x: hidden;"
          @mousedown.prevent
        >
          <a-row
            v-for="(item, index) in dataList"
            :key="`checkbox_filter_${index}`"
            class="dropdown-list__check-box-area"
          >
            <a-checkbox
              v-model="item.isChecked"
              @change="selectItem(item)"
              @click.stop="clickCheckBox"
              >{{ item.name }}</a-checkbox
            >
            <div
              v-if="isDateList && item.id === 7"
              style="margin-left: 10px; margin-top: 6px; margin-right: 10px;"
              @click.stop
            >
              <a-range-picker
                v-model="dateRangeData"
                :format="dateFormat"
                @change="dateChange"
                @click.stop
              />
            </div>
          </a-row>
        </div>
        <div>
          <a-button class="dropdown-list__btn" @click="clickToApply">Apply</a-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment"
import { getBeforeDate, getBeforeMonth } from "@/utils/helpers"
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: { type: String, default: () => "" },
    selectedData: { type: Array, default: () => [] },
    isDateList: { type: Boolean, default: () => false },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isOpen: false,
      optimizedHeight: 228,
      selected: new Set(),
      searchValue: "",
      dateFormat: "MM/DD/YYYY",
      dateRangeData: [],
      dataList: [],
      originList: [],
      tagValues: undefined,
      isClickApply: true
    }
  },
  computed: {
    contentStyle() {
      // return this.options.length
      //     ? { display: 'inline-block' }
      //     : { display: 'block' }
      return { display: "inline-block" }
    }
  },
  watch: {
    list() {
      console.log("......watch list.....")
      this.initDataList()
    },
    selectedData(newValue) {
      console.log(".....dropdown list.... selected data watch")
      if (newValue && newValue.length > 0) {
        if (!this.dataList || this.dataList.length === 0) {
          this.initDataList()
        }
        this.initSelectedData()
      }
    }
  },
  created() {
    if (this.list) {
      this.initDataList()
      this.originList = this.dataList.slice(0)
    }
    this.initSelectedData()
  },
  methods: {
    onSearch() {
      console.log("....search ....")
      console.log(this.searchValue)
      if (!this.searchValue || this.searchValue.length === 0) {
        this.dataList = this.originList.slice(0)
      } else {
        this.dataList = this.dataList.filter(item => {
          return item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0
        })
      }
    },
    initDataList() {
      this.dataList = []
      if (this.list && this.list.length > 0) {
        for (let i = 0; i < this.list.length; i++) {
          let data = Object.assign({}, this.list[i])
          data.isChecked = false
          this.dataList.push(data)
        }
      }
      this.originList = this.dataList.slice(0)
    },
    activate() {
      /* istanbul ignore else */
      if (this.disabled) return
      if (this.isOpen) {
        this.isOpen = false
        if (!this.isClickApply) {
          this.$message.warning(
            "You need to click Apply button, then can choose the selected data."
          )
        }
        this.$emit("close")
      } else {
        this.isOpen = true
        this.$emit("open")
      }

      // this.adjustPosition()
      console.log("....before set isOpen....")
    },
    deActivate() {
      this.isOpen = false
    },
    initSelectedData() {
      if (this.selectedData && this.selectedData.length > 0) {
        this.selectedData.forEach(item => {
          this.selected.add(item)
          if (item.date && item.date.length === 2) {
            this.dateRangeData = [moment(item.date[0]), moment(item.date[1])]
          } else {
            if (this.dataList && this.dataList.length > 0) {
              let data = this.dataList.filter(dl => dl.id === item.id)
              if (data && data.length > 0) {
                data[0].isChecked = true
              }
            }
          }
        })
      }
      this.tagValues = this.getTags()
    },
    clearSelectedData() {
      this.selected.clear()
      this.tagValues = undefined
      this.$forceUpdate()
    },
    clickCheckBox() {
      console.log(".....click check box.....")
      this.$emit("isClickCheckbox", true)
    },
    selectItem(item) {
      console.log("....select item.....")
      console.log(item)
      this.isClickApply = false
      if (this.isDateList) {
        //only can choose one item
        if (this.selected.size > 0) {
          for (var data of this.selected) {
            data.isChecked = false
          }
        }
        this.selected.clear()
        if (item.isChecked) {
          this.selected.add(item)
        }
      } else {
        // can multi select
        if (item.isChecked) {
          this.selected.add(item)
        } else {
          let selectArray = []
          for (var select of this.selected) {
            if (select.id === item.id) {
              select.isChecked = false
            } else {
              selectArray.push(select)
            }
          }
          this.selected = new Set(selectArray)
          console.log(this.selected)
        }
        console.log("...after check one item....")
        console.log(this.list)
        console.log(this.dataList)
      }
      this.tagValues = this.getTags()
      this.$forceUpdate()
      console.log(this.selected)
    },
    dateChange() {
      console.log("....date range...")
      console.log(this.dateRangeData)
      this.tagValues = this.getTags()
    },
    getTags() {
      console.log(this.selected)
      let str = ""
      if (this.isDateList) {
        if (this.selected && this.selected.size > 0) {
          for (var data of this.selected) {
            if (data.id === 1) {
              //all time
              return data.name
            }
            if (data.id === 2) {
              //Month to Date
              str = getBeforeDate(30)
            } else if (data.id === 3) {
              // last 7 days
              str = getBeforeDate(7)
            } else if (data.id === 4) {
              // last 3 months
              str = getBeforeMonth(3)
            } else if (data.id === 5) {
              // last 6 months
              str = getBeforeMonth(6)
            } else if (data.id === 6) {
              // last 12 months
              str = getBeforeMonth(12)
            } else {
              if (!this.dateRangeData || this.dateRangeData.length === 0) {
                str = data.name
              } else {
                console.log(this.dateRangeData)
                str = `${this.dateRangeData[0].format(
                  this.dateFormat
                )} ~ ${this.dateRangeData[1].format(this.dateFormat)}`
              }
            }
          }
        } else {
          str = ""
        }
      } else {
        if (this.selected && this.selected.size > 0) {
          this.selected.forEach(item => {
            console.log(item)
            str += item.name + ", "
          })
          str = str.substring(0, str.length - 2)
        }
      }
      return str
    },
    clickToApply() {
      this.isClickApply = true
      if (this.selected && this.selected.size > 0) {
        if (!this.isDateList) {
          let filterId = ""
          let filterName = ""
          for (var data of this.selected) {
            filterId += data.id + ","
            filterName += data.name + ","
          }
          filterId = filterId.substring(0, filterId.length - 1)
          filterName = filterName.substring(0, filterName.length - 1)
          this.$emit("apply", {
            filterId: filterId,
            filterName: filterName,
            data: [...this.selected]
          })
          this.isOpen = false
        } else {
          let data = [...this.selected]
          let dateTimes = this.tagValues.split(" ~ ")
          // if (this.tagValues.indexOf("All") >= 0) {
          //   dateTimes = ["01/01/1779", "12/31/2299"]
          // }
          console.log(`tag values: ${this.tagValues}`)
          console.log(`startTime: ${dateTimes[0]}, endTimes: ${dateTimes[1]}`)
          let param = { option: data[0], startTime: dateTimes[0], endTime: dateTimes[1] }
          this.$emit("apply", param)
          this.isOpen = false
        }
      } else {
        if (!this.isDateList) {
          this.$emit("apply", {
            filterId: "",
            filterName: "",
            data: []
          })
          this.isOpen = false
        } else {
          this.$emit("apply", undefined)
          this.isOpen = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-list {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 35px;
  text-align: left;
  color: #35495e;
  z-index: 1000;
  &__tags {
    height: 35px;
    display: block;
    // padding: 8px 40px 8px 8px;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
    background: #fff;
    font-size: 14px;
    line-height: 22px;
    color: #bfbfbf;
  }
  &__placeholder-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 35px;
    padding: 0px 9px;
    width: 80%;
    display: inline-block;
    word-wrap: break-word;
    word-break: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__active {
    border: 1px solid #4a8a5d;
  }
  &__disabled {
    border: 1px solid #bfbfbf;
  }
  &__right-arrow {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 20px;
  }
  &__content-wrapper {
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-top: none;
    border-radius: 0px 0px 2px 2px;
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
  }
  &__search-area {
    width: 100%;
    height: 48px;
    background: #e2eeec;
    border-radius: 2px;
    padding: 12px 20px;
    margin-bottom: 7px;
  }
  &__search-input {
    background: #ffffff;
    border-radius: 30px;
    height: 24px;
    font-size: 12px;
    padding-left: 13px;
    padding-right: 18px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    color: #262626 !important;
    border: none;
  }
  &__check-box-area {
    margin-left: 20px;
    margin-bottom: 4px;
  }
  &__btn {
    margin: 10px 20px;
    padding: 4px 20px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    background: #4a8a5d;
    border: none;
    box-sizing: border-box;
    border-radius: 2px;
  }
}
</style>
<style lang="scss">
.dropdown-list__search-input .ant-input {
  height: 24px;
  border-radius: 30px;
  border: none;
}
.dropdown-list__search-input .ant-input:hover {
  border: none;
}
.dropdown-list__search-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
}
.dropdown-list__search-input.ant-input-affix-wrapper {
  border: none;
}
.dropdown-list__search-input .ant-input {
  border-radius: 0px;
}
.dropdown-list .ant-btn:hover,
.dropdown-list .ant-btn:focus,
.dropdown-list .ant-btn:active {
  color: #fff;
  background: #4a8a5d;
  border-radius: 2px;
  border-color: #4a8a5d;
}
</style>
