<template lang="pug">
layout
  .signup--section--heroping
    .signup--section--hero--inner
      .columns
        .column.signup--section--hero--content__wrapper
          p.signup--section--hero__title Welcome
          p.signup--section--hero__title.retailer Retailers!
          p.signup--section--hero__content
            | Landing is <b>always free for retailers</b> with no commission fees on any orders you place.
        .column.signup--section--hero--image__wrapper
          .image--right--group
            img.ellipse(src='@/assets/webflowAssets/Signup/ellipse.svg' alt='Dots')
            img.dots--pattern(src='@/assets/webflowAssets/Signup/dots_pattern.svg' alt='Dots')
            img.dots--pattern--2(src='@/assets/webflowAssets/Signup/dots_pattern_2.svg' alt='Dots')
            img.clean--precheck.clean--precheck--1(src='@/assets/webflowAssets/Home/clean_precheck_1.svg' alt='Precheck 1')
            img.clean--precheck.clean--precheck--2(src='@/assets/webflowAssets/Home/clean_precheck_2.svg' alt='Precheck 2')
            img.clean--precheck.clean--precheck--3(src='@/assets/webflowAssets/Home/clean_precheck_3.svg' alt='Precheck 3')
            img.clean--precheck.clean--precheck--4(src='@/assets/webflowAssets/Home/clean_precheck_4.svg' alt='Precheck 4')
  .signup--section--main
    .columns
      .column
        .signup--section--main--benefits
          div
            h2.heading--4(style="font-size: 40px;font-weight: 400") Platform Benefits Include:
            ul
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Discover game-changing brands that are prepared to succeed at retail
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Be the first to find exciting new brands from across the globe
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Request samples and connect directly with brands
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Save time sourcing with brands that are pre-qualified for your clean standard
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Boost sell-through by giving associates instant access to training materials
              li
                img.circle--check(src='@/assets/webflowAssets/Signup/circle_check_solid.svg' alt='check')
                p
                  | Be the first to know about our new rewards-based training app
      .column
        .signup--section--main--form
          div(v-if="!isRegistered")
            sign-up(formTitle="Sign up" isBrand="false" @submitAction="handleSignUp")
          div(v-else)
            thankyou
</template>

<script>
import SignUp from "@/components/webflowComponent/SignUp"
import Thankyou from "@/components/webflowComponent/Thankyou"

export default {
  name: "RetailerSignUp",
  components: {
    SignUp,
    Thankyou
  },
  data() {
    return {
      isRegistered: false
    }
  },
  methods: {
    // handleSignUp() {
    //   this.isRegistered = true
    // },
    handleSignUp(params) {
      this.$store
        .dispatch("retailer/retailerRegistration", params)
        .then(res => {
          console.log(window)
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "registrationComplete",
            userId: res.user.id,
            firstName: res.user.givenName,
            lastName: res.user.familyName,
            emailAddress: res.user.email,
            userType: "Retailer"
          })
          this.isNotRegistered = false
          this.isRegistered = true
        })
        .catch(err => {
          this.isNotRegistered = true
          // if (err.response && err.response.data) {
          //   let errArrays = err.response.data.underlying
          //   let msg = ""
          //   for (let i = 0; i < errArrays.length; i++) {
          //     msg += errArrays[i].userMessage
          //     if (i < errArrays.length - 1) {
          //       msg += ","
          //     }
          //   }
          //   this.$message.error(msg)
          // } else {
          this.$message.error(err.message)
          // }
          this.isRegistered = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0;
}
.signup--section--heroping {
  background-color: rgba(33, 120, 174, 0.2);
  height: 480px;
  padding: 40px;
  overflow-y: hidden;
}

.signup--section--hero--inner {
  max-width: 1440px;
  margin: 0 auto;
}

.signup--section--hero--content__wrapper {
  padding-left: 80px;
  padding-top: 80px;
}

.signup--section--hero__title {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 60px;
  font-feature-settings: "liga" off;
  color: #1a1818;
}

.signup--section--hero__title.retailer {
  color: #2178ae;
}

.signup--section--hero__title:nth-child(2) {
  margin-bottom: 40px;
}

.signup--section--hero__content {
  max-width: 560px;
  font-family: Mr Eaves Mod OT;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 33px;
  color: #000000;
}

.signup--section--hero--image__wrapper {
  position: relative;
}

.signup--section--hero--image__wrapper .ellipse {
  position: absolute;
  top: 60px;
  right: 20px;
}

.signup--section--hero--image__wrapper .dots--pattern {
  position: absolute;
  top: 40px;
  right: 60px;
}

.signup--section--hero--image__wrapper .dots--pattern--2 {
  position: absolute;
  right: 420px;
  top: 230px;
}

.signup--section--hero--image__wrapper .clean--precheck {
  width: 330px;
  height: 100px;
  position: absolute;
}

.signup--section--hero--image__wrapper .clean--precheck--1 {
  right: 100px;
  top: 40px;
}

.signup--section--hero--image__wrapper .clean--precheck--2 {
  right: 255px;
  top: 172px;
}

.signup--section--hero--image__wrapper .clean--precheck--3 {
  right: 100px;
  top: 304px;
}

.signup--section--hero--image__wrapper .clean--precheck--4 {
  right: 255px;
  top: 436px;
}

.signup--section--main--benefits {
  height: 100%;
  background-color: rgba(241, 90, 66, 0.2);
  font-family: Mr Eaves Mod OT;
}

.signup--section--main--benefits > div {
  padding: 80px 80px;
  max-width: 720px;
  margin-left: auto;
}

.signup--section--main--benefits h2 {
  margin-bottom: 40px;
}

.signup--section--main--benefits li {
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 400;
  color: #1a1818;
}

.signup--section--main--benefits li:not(:last-child) {
  margin-bottom: 32px;
}

.signup--section--main--benefits li img {
  margin-right: 16px;
}

.signup--section--main--form > div {
  max-width: 720px;
  margin-right: auto;
  padding-top: 48px;
  padding-bottom: 80px;
  padding-left: 20px;
}

.signup--section--main--form > div section {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.signup--section--main--form > div section .form--title {
  text-align: left;
}

@media screen and (max-width: 1439px) {
  .signup--section--hero--content__wrapper {
    padding-left: 12px;
  }

  .signup--section--main--benefits > div {
    padding: 80px 40px;
  }

  .signup--section--main--form {
    padding-right: 24px;
  }

  .signup--section--main--form > div {
    padding-left: 0;
  }
}

@media screen and (max-width: 1199px) {
  .signup--section--hero--content__wrapper .signup--section--hero__title {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 55px;
  }

  .signup--section--hero--image__wrapper .ellipse {
    position: absolute;
    top: 60px;
    right: 20px;
    width: 126.5px;
    height: 126.5px;
  }

  .signup--section--hero--image__wrapper .dots--pattern {
    position: absolute;
    top: 40px;
    right: 60px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero--image__wrapper .dots--pattern--2 {
    position: absolute;
    right: 250px;
    top: 230px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero--image__wrapper .clean--precheck {
    height: 66px;
    width: 222px;
  }

  .signup--section--hero--image__wrapper .clean--precheck--1 {
    right: 100px;
    top: 50px;
  }

  .signup--section--hero--image__wrapper .clean--precheck--2 {
    right: 203px;
    top: 135px;
  }

  .signup--section--hero--image__wrapper .clean--precheck--3 {
    right: 100px;
    top: 220px;
  }

  .signup--section--hero--image__wrapper .clean--precheck--4 {
    right: 255px;
    top: 305px;
  }
}

@media screen and (max-width: 1023px) {
  .signup--section--main > .columns {
    display: block;
    margin: 0;
  }

  .signup--section--main > .columns > .column {
    padding: 0;
  }

  .signup--section--main--benefits > div {
    padding: 40px 20px;
    max-width: none;
    max-width: 600px;
    margin: 0 auto;
  }

  .signup--section--main--benefits .heading--4 {
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
  }

  .signup--section--main--form {
    padding-right: 0;
  }

  .signup--section--main--form > div {
    max-width: none;
    padding: 0;
  }

  .signup--section--main--form section {
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 991px) {
  .signup--section--hero {
    height: auto;
    padding: 40px 20px;
  }

  .signup--section--hero--inner > .columns {
    display: block;
    margin: 0;
  }

  .signup--section--hero--content__wrapper {
    padding: 0;
    text-align: center;
  }

  .signup--section--hero__content {
    max-width: 400px;
    margin: 0 auto;
    font-size: 25px;
    line-height: 28px;
  }

  .signup--section--hero--image__wrapper {
    height: 400px;
    max-width: 400px;
    margin: 0 auto;
  }

  .signup--section--hero--image__wrapper .dots--pattern {
    position: absolute;
    top: 40px;
    right: 60px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero--image__wrapper .dots--pattern--2 {
    position: absolute;
    right: 250px;
    top: 285px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero--image__wrapper .clean--precheck--1 {
    right: 32px;
    top: 50px;
  }

  .signup--section--hero--image__wrapper .clean--precheck--2 {
    right: 145px;
    top: 135px;
  }

  .signup--section--hero--image__wrapper .clean--precheck--3 {
    right: 32px;
    top: 220px;
  }

  .signup--section--hero--image__wrapper .clean--precheck--4 {
    right: 145px;
    top: 305px;
  }
}
</style>
