import CoreEntity from "@/models/CoreEntity";
export default class SimpleDescriptionEntity extends CoreEntity {
  constructor(model) {
    super(model);
    //this.privateId = 0
    this.name = "";
    this.id = "";
    //this.isActive = 1
    //this.createdAt = new Date()
    if (model) {
      //this.privateId = model.privateId || 0
      this.id = model.id || "";
      this.name = model.name || "";
      //this.isActive = model.isActive
      this.description = model.description || null;
      this.createdAt = model.createdAt ? new Date(model.createdAt) : null;
      //this.createdBy = model.createdBy || null
      //this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
      //this.updatedBy = model.updatedBy || null
    }
  }

  toModel() {
    const data = super.toModel();
    data.name = this.name;
    data.description = this.description;
    data.id = this.id;
    return data;
  }

  // clone() {
  //   //throw new Error("clone not implement")
  //   return new this.constructor(this.toModel())
  // }

  // toDateString(datetime) {
  //   return datetime ? datetime.toISOString() : null
  // }
}
