<template>
  <div class="modal added-member-modal" :class="{ 'is-active': showMemberInvited }">
    <div class="modal-background"></div>
    <div class="modal-card added-member-modal__content">
      <header class="added-member-modal__header">
        {{$t('Brands.BrandSettings.requestSent')}}
        <img class="added-member-modal__close-button" src="@/assets/img/icon/close-icon.svg" @click="onClickClose"/>
      </header>
      <section class="added-member-modal__body">
        <div class="infoText">
          {{$t('Brands.BrandSettings.requestInviteInfo')}}
        </div>
        <div class="columns margin-label">
          <div class="column">
            <label class="label brand-new-product__label">
              {{$t('Brands.BrandSettings.emailAddress')}}
            </label>
          </div>
          <div class="column">
            <label class="label brand-new-product__label">
              {{$t('Brands.BrandSettings.name')}}
            </label>
          </div>
        </div>
        <p class="border-line" />
        <div class="columns margin-value">
          <div class="column">
            <p class="member-added">
              {{memberAdded.user.email}}
            </p>
          </div>
          <div class="column">
            <p class="member-added">
              {{`${memberAdded.user.givenName} ${memberAdded.user.familyName}`}}
            </p>
          </div>
        </div>
      </section>
      <div class="modal-card-foot has-text-right">
        <div class="column">
          <div class="button cancel-button invite-more" @click="inviteMore">
            {{$t('Brands.BrandSettings.inviteMore')}}
          </div>
        </div>
        <div class="column is-2">
          <div class="button is-black save-button done" @click="onClickClose">
            {{$t('Brands.BrandSettings.done')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showMemberInvited: { type: Boolean, default: false },
    memberAdded: { type: Object, default: () => null }
  },
  methods: {
    onClickClose() {
      this.$emit("memberInvitedModalClose")
    },
    inviteMore() {
      this.$emit("inviteMorePeople")
    }
  }
}
</script>
<style lang="sass">
.border-line
    border-bottom: 1px solid #ddd
    width: 352px
    margin-bottom: 7px

.member-added
    color: #333
    font-size: 14px
    white-space: normal
    word-break: break-all

.margin-label
    margin-bottom: 0px !important

.margin-value
    margin-bottom: 20px

.done
    font-size: 14px
    width: 82px
    height: 38px

.invite-more
    height: 38px
    font-size: 14px
    width: 144px
    margin-right: 7px

.brand-invite-modal__input--fullwidth
  width: 327px

.added-member-modal
  .modal-card-body
    display: flex

  .request-term-details-info
    color: #000
    font-size: 14px
    font-weight: 400
    margin: 0px !important

  .infoText
    margin-bottom: 20px

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .added-member-modal__close-button
      top: 16px
      width: 14px
      height: 14px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 510px

  &__body
    padding: 45px 38px 60px 72px

  &__buttons
    padding: 10px 20px
    display: flex
    justify-content: flex-end

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__details-container
    font-size: 14px
    padding-bottom: 60px

  &__details
    margin: 18px
</style>
