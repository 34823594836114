<template>
  <a-row class="admin-retailer-edit">
    <a-col :span="4" style="padding-top: 190px;">
      <a-anchor @change="linkChange">
        <a-anchor-link href="#productValue" title="Product Value" />
        <a-anchor-link href="#categories" title="Category" />
        <a-anchor-link href="#curations" title="Curation" />
        <a-anchor-link href="#productType" title="Product Type" />
      </a-anchor>
    </a-col>
    <a-col :span="20" style="padding-left: 64px; padding-top: 71px;">
      <div class="admin-retailer-edit__title">
        Edit/Add Product Categories
      </div>
      <div id="productValue" style="width: 100%; margin-bottom: 43px;">
        <ConsoleAdd
          title="Product Value"
          :items="productValueList"
          :is-focus="isProductValueFocus"
          @removeItem="removeAllProductValue"
          @editAll="editAllProductValue"
          @addMultiple="addMultipleProductValue"
          @editItem="editItemProductValue"
          @addNewItem="addNewItemProductValue"
        />
      </div>
      <div id="categories" style="width: 100%; margin-bottom: 43px;">
        <ConsoleCascadeAdd
          title="Category"
          :items="categoryList"
          @editAll="editAllCategory"
          @editSecondAll="editAllSubCategory"
          @editThirdAll="editAllMicroCategory"
          @addMultiple="addMultipleCategory"
          @addMultipleSecond="addMultipleSubCategory"
          @addMultipleThird="addMultipleMicroCategory"
          @removeItem="removeAllCategory"
          @removeSecondItem="removeAllSubCategory"
          @removeThirdItem="removeAllMicroCategory"
          @addNewItem="addNewItemCategory"
          @addNewSecondItem="addNewItemSubCategory"
          @addNewThirdItem="addNewItemMicroCategory"
          @editItem="editItemCategory"
          @editSecondItem="editItemSubCategory"
          @editThirdItem="editItemMicroCategory"
        />
      </div>
      <div id="curations" style="width: 100%; margin-bottom: 43px;">
        <ConsoleAdd
          title="Curation"
          :items="curationList"
          :is-focus="isCurationFocus"
          @removeItem="removeAllChannelType"
          @editAll="editAllChannelType"
          @addMultiple="addMultipleChannelType"
          @editItem="editItemChannelType"
          @addNewItem="addNewItemChannelType"
        />
      </div>
      <div id="productType" style="width: 100%; margin-bottom: 43px;">
        <ConsoleAdd
          title="Product Type"
          :items="productTypeList"
          :is-focus="isProductTypeFocus"
          @removeItem="removeAllProductType"
          @editAll="editAllProductType"
          @addMultiple="addMultipleProductType"
          @editItem="editItemProductType"
          @addNewItem="addNewItemProductType"
        />
      </div>
    </a-col>
  </a-row>
</template>

<script>
import ConsoleAdd from "@/components/admin/ConsoleAdd"
import ConsoleCascadeAdd from "@/components/admin/ConsoleCascadeAdd"
export default {
  components: {
    ConsoleAdd,
    ConsoleCascadeAdd
  },
  data() {
    return {
      isLoading: false,
      isProductValueFocus: false,
      isCategoryFocus: false,
      isCurationFocus: false,
      isProductTypeFocus: false,
      isAdd: false,
      productValueList: undefined,
      categoryList: undefined,
      curationList: undefined,
      productTypeList: undefined,
      type: -1
    }
  },
  created() {
    this.getReference()
  },
  methods: {
    sortData(data, modifyData) {
      if (!this.isAdd) {
        modifyData = data.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        for (let i = modifyData.length; i < data.length; i++) {
          modifyData.push(data[i])
        }
        this.isAdd = false
      }
      return modifyData
    },
    async getReference() {
      await this.$store.dispatch("reference/getProductConsole")
      if (this.type === -1) {
        this.getCurationData()
        this.getProductValueList()
        this.getProductTypeList()
        this.getCategoryList()
      } else if (this.type === 1) {
        this.getCurationData()
      } else if (this.type === 2) {
        this.getProductValueList()
      } else if (this.type === 3) {
        this.getProductTypeList()
      } else if (this.type === 4) {
        this.getCategoryList()
      }
    },
    getCurationData() {
      let data = this.$store.getters["reference/productConsole"].curations
      this.curationList = this.sortData(data, this.curationList)
    },
    getProductValueList() {
      let data = this.$store.getters["reference/productConsole"].productValues
      this.productValueList = this.sortData(data, this.productValueList)
    },
    getCategoryList() {
      let data = this.$store.getters["reference/productConsole"].productCategorys
      this.categoryList = this.sortData(data, this.categoryList)
    },
    getProductTypeList() {
      let data = this.$store.getters["reference/productConsole"].productTypes
      this.productTypeList = this.sortData(data, this.productTypeList)
    },
    addMultipleChannelType(datas) {
      this.isAdd = true
      this.updateChannelType(this.getChannelTypeParam(datas))
    },
    editAllChannelType(datas) {
      this.updateChannelType(this.getChannelTypeParam(datas))
    },
    removeAllChannelType(datas) {
      this.updateChannelType(this.getChannelTypeParam(datas))
    },
    editItemChannelType(item) {
      this.updateChannelType(this.getChannelTypeParam([item]))
    },
    addNewItemChannelType(item) {
      this.isAdd = true
      this.updateChannelType(this.getChannelTypeParam([item]))
    },
    async updateChannelType(param) {
      this.type = 1
      await this.$store.dispatch("reference/updateCurationType", param)
      this.getReference()
    },
    getChannelTypeParam(data) {
      return { curations: data }
    },
    addMultipleProductValue(datas) {
      this.isAdd = true
      this.updateProductValue(this.getProductValueParam(datas))
    },
    editAllProductValue(datas) {
      this.updateProductValue(this.getProductValueParam(datas))
    },
    removeAllProductValue(datas) {
      this.updateProductValue(this.getProductValueParam(datas))
    },
    editItemProductValue(item) {
      this.updateProductValue(this.getProductValueParam([item]))
    },
    addNewItemProductValue(item) {
      this.isAdd = true
      this.updateProductValue(this.getProductValueParam([item]))
    },
    async updateProductValue(param) {
      this.type = 2
      await this.$store.dispatch("reference/updateProductValue", param)
      this.getReference()
    },
    getProductValueParam(data) {
      return { productValues: data }
    },
    addMultipleProductType(datas) {
      this.isAdd = true
      this.updateProductType(this.getProductTypeParam(datas))
    },
    editAllProductType(datas) {
      this.updateProductType(this.getProductTypeParam(datas))
    },
    removeAllProductType(datas) {
      this.updateProductType(this.getProductTypeParam(datas))
    },
    editItemProductType(item) {
      this.updateProductType(this.getProductTypeParam([item]))
    },
    addNewItemProductType(item) {
      this.isAdd = true
      this.updateProductType(this.getProductTypeParam([item]))
    },
    async updateProductType(param) {
      this.type = 3
      await this.$store.dispatch("reference/updateProductType", param)
      this.getReference()
    },
    getProductTypeParam(data) {
      return { productTypes: data }
    },
    // modify category
    addMultipleCategory(datas) {
      this.isAdd = true
      this.updateCategory(this.getCategoryParam(datas))
    },
    editAllCategory(datas) {
      this.updateCategory(this.getCategoryParam(datas))
    },
    removeAllCategory(datas) {
      this.updateCategory(this.getCategoryParam([...datas]))
    },
    editItemCategory(item) {
      this.updateCategory(this.getCategoryParam([item]))
    },
    addNewItemCategory(item) {
      this.isAdd = true
      this.updateCategory(this.getCategoryParam([item]))
    },
    async updateCategory(param) {
      this.type = 4
      await this.$store.dispatch("reference/updateProductCategory", param)
      this.getReference()
    },
    getCategoryParam(data) {
      return { productCategorys: data }
    },
    // subcategory
    addMultipleSubCategory(datas) {
      this.isAdd = true
      this.updateSubCategory(this.getSubCategoryParam(datas))
    },
    editAllSubCategory(datas) {
      this.updateSubCategory(this.getSubCategoryParam(datas))
    },
    removeAllSubCategory(datas) {
      this.updateSubCategory(this.getSubCategoryParam([...datas]))
    },
    editItemSubCategory(item) {
      this.updateSubCategory(this.getSubCategoryParam([item]))
    },
    addNewItemSubCategory(item) {
      this.isAdd = true
      this.updateSubCategory(this.getSubCategoryParam([item]))
    },
    async updateSubCategory(param) {
      await this.$store.dispatch("reference/updateProductSubCategory", param)
    },
    getSubCategoryParam(data) {
      return { productSubcategorys: data }
    },
    // micro category
    addMultipleMicroCategory(datas) {
      this.isAdd = true
      this.updateMicroCategory(this.getMicroCategoryParam(datas))
    },
    editAllMicroCategory(datas) {
      this.updateMicroCategory(this.getMicroCategoryParam(datas))
    },
    removeAllMicroCategory(datas) {
      this.updateMicroCategory(this.getMicroCategoryParam([...datas]))
    },
    editItemMicroCategory(item) {
      this.updateMicroCategory(this.getMicroCategoryParam([item]))
    },
    addNewItemMicroCategory(item) {
      this.isAdd = true
      this.updateMicroCategory(this.getMicroCategoryParam([item]))
    },
    async updateMicroCategory(param) {
      await this.$store.dispatch("reference/updateProductMicroCategory", param)
    },
    getMicroCategoryParam(data) {
      return { productMicroCategorys: data }
    },
    linkChange(value) {
      if (value === "#productValue") {
        this.isProductValueFocus = true
        this.isCategoryFocus = false
        this.isCurationFocus = false
        this.isProductTypeFocus = false
      } else if (value === "#categories") {
        this.isCategoryFocus = true
        this.isProductValueFocus = false
        this.isCurationFocus = false
        this.isProductTypeFocus = false
      } else if (value === "#curations") {
        this.isCurationFocus = true
        this.isCategoryFocus = false
        this.isProductValueFocus = false
        this.isProductTypeFocus = false
      } else {
        this.isProductTypeFocus = true
        this.isCategoryFocus = false
        this.isProductValueFocus = false
        this.isCurationFocus = false
      }
    }
  }
}
</script>
