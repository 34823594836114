<template>
    <div class="payment-transfer">
        <p>
            Please complete this contact form. A member of our team will be in touch to confirm details
            for your wire transfer.
        </p>

        <a-form layout="horizontal" :form="form">
            <div>
                <div class="columns">
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="First Name" v-decorator="[
            'firstName',
            {
                rules: [
                    {
                        required: true,
                        message: 'First Name is required',
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="Last Name" v-decorator="[
            'lastName',
            {
                rules: [
                    {
                        required: true,
                        message: 'Last Name is required',
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </div>
            <div>
                <a-form-item>
                    <a-input placeholder="Email" v-decorator="[
            'email',
            {
                rules: [
                    // {
                    //     validator: validateEmail,
                    // },
                    {
                        required: true,
                        message: 'E-mail is required',
                    },
                ],
            },
        ]"></a-input>
                </a-form-item>
            </div>
        </a-form>
    </div>

</template>
<script>
import SignUpService from "@/services/sign-up-services";
export default {
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: "paymentTransfer" });
    },
    methods: {
        async validateEmail(rule, value, callback) {
            if (value) {
                if (SignUpService.validateEmail(value)) {
                    const isExists = await SignUpService.isEmailExists(value);
                    if (isExists) {
                        callback(`${value} is already exists`);
                    } else {
                        callback();
                    }
                } else {
                    callback("Email is invalid");
                }
            } else {
                callback();
            }
        },
    }
}
</script>
<style lang="scss" scoped>
input {

    padding: 6px 24px 10px 24px;
    border-radius: 50px;
    font-size: 20px;
    color: #000;
    height: fit-content;
}
</style>