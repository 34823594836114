<template>
  <div class="add-retailer-terms-modal__main">
    <a-modal
      v-model="editProductVisible"
      width="990px"
      :footer="null"
      :title="'Retailer Terms'"
      :closable="true"
      :mask-closable="false"
    >
      <table class="table is-fullwidth">
        <thead>
          <th class="retailer-order-table__head">Retailer Terms</th>
          <th class="retailer-order-table__head">Type</th>
          <th class="retailer-order-table__head">Description</th>
          <th class="retailer-order-table__head">Amount</th>
        </thead>
        <tbody v-for="(item, index) in chargeList" :key="index">
          <tr>
            <td v-if="!item.isAddNewValue" class="retailer-order-table__cell">
              {{ item.note || "-" }}
            </td>
            <td v-else class="retailer-order-table__cell">
              <a-input v-model="item.note" @change="modifyName(item)"></a-input>
            </td>
            <td class="retailer-order-table__cell">{{ item.paymentType.name }}</td>
            <td v-if="!item.isAddNewValue" class="retailer-order-table__cell">
              {{ item.recordedValue }}%
            </td>
            <td v-else class="retailer-order-table__cell">
              <a-input-search v-model="item.recordedValue" @change="modifyRecorded">
                <a-button slot="enterButton" :disabled="true">
                  %
                </a-button>
              </a-input-search>
            </td>
            <td
              v-if="!item.isAddNewValue && item.paidBy && item.paidBy.id == 'brand'"
              class="retailer-order-table__cell"
            >
              ({{
                item.paidBy.id === "brand"
                  ? priceFormat(item.calculatedCostUsd)
                  : priceFormat(item.calculatedCostUsd)
              }})
            </td>
            <td v-else class="retailer-order-table__cell">
              <a-input v-model="item.calculatedCostUsd"></a-input>
            </td>
          </tr>
        </tbody>
        <span v-if="!isShowAdd" class="add-new-terms" :style="{ color: color }" @click="addNewTerms"
          >+ADD</span
        >
      </table>
      <p class="apply-button-add-terms">
        <span :style="{ background: color }" @click="AddRetailerTerms">Apply</span>
      </p>
    </a-modal>
  </div>
</template>

<script>
import { priceFormat } from "@/utils/validate"
export default {
  name: "CartAddRetailerTermsModal",
  data() {
    return {
      priceFormat,
      editProductVisible: false,
      chargeList: [],
      color: "",
      isShowAdd: false,
      calculatedWholesaleCostUsd: 0,
      charges: []
    }
  },
  computed: {},
  methods: {
    async openDialog(charges, buttonColor, total) {
      this.isShowAdd = false
      this.chargeList = charges.filter(item => item.seenByRetailer)
      this.charges = charges
      this.editProductVisible = true
      this.color = buttonColor
      this.calculatedWholesaleCostUsd = total
    },
    // priceFormat(price) {
    //   const numberFormat = new Intl.NumberFormat("en-US", {
    //     style: "currency",
    //     currency: "USD"
    //   })
    //   return numberFormat.format(price)
    // },
    addNewTerms() {
      const newObj = {
        isAddNewValue: true,
        calculatedCostUsd: "",
        ownedBy: {
          id: "retailer"
        },
        paidBy: {
          id: "brand"
        },
        addedBy: {
          id: this.isAdmin ? "landing" : "retailer"
        },
        paymentType: {
          id: "credit",
          name: "Credit"
        },
        chargeType: {
          id: "custom"
        },
        note: "",
        percentage: true,
        seenByBrand: true,
        seenByRetailer: true,
        seenByLanding: true
      }
      this.chargeList = this.chargeList
        .map(item => {
          item.isAddNewValue = false
          return item
        })
        .filter(
          item =>
            !(
              item.chargeType.id === "custom" &&
              item.ownedBy.id === "retailer" &&
              item.paidBy.id === "brand"
            )
        )
      this.chargeList.push(newObj)
      this.isShowAdd = true
    },
    AddRetailerTerms() {
      this.editProductVisible = false
      const otherList = this.charges.filter(item => !item.seenByRetailer)
      const list = this.chargeList.concat(otherList)
      this.isShowAdd = false
      this.$emit("updateRetailerTerms", list)
    },
    closeAddTerms() {
      this.editProductVisible = false
      this.isShowAdd = false
      this.chargeList = this.chargeList.filter(item => !item.isAddNewValue)
    },
    modifyRecorded() {
      this.chargeList = this.chargeList.map(item => {
        if (item.isAddNewValue) {
          item.calculatedCostUsd = (
            (item.recordedValue / 100) *
            this.calculatedWholesaleCostUsd
          ).toFixed(2)
        }
        return item
      })
    },
    modifyName(value) {
      this.chargeList = this.chargeList.map(item => {
        if (item.isAddNewValue) {
          item = value
        }
        return item
      })
    }
  }
}
</script>

<style lang="scss">
.add-retailer-terms-modal__main {
}
.add-new-terms {
  text-decoration: underline;
  cursor: pointer;
}
.apply-button-add-terms {
  text-align: right;
  span {
    padding: 5px 16px;
    color: white;
    cursor: pointer;
  }
}
</style>
