import cleanPreCheck from "@/api/clean-precheck.js"

export default {
  namespaced: true,

  state: {
    ingredientsByName: {},
    categoryIngredients: {},
    allCategoryIngredients: {},
    updatedCategory: {},
    ingredients: {},
    updatedDirtyIngredient: "",
    deleteIngredient: {}
  },

  getters: {
    ingredientsByName(state) {
      return state.ingredientsByName
    },
    categoryIngredients(state) {
      let data = state.categoryIngredients
      if (data.list && data.list.length) {
        data.list = data.list.filter(item => {
          if (item.categoryIngredients && item.categoryIngredients.length) {
            return true
          } else {
            return false
          }
        })
        data.list.forEach(item => {
          item.categoryIngredients.forEach(ingredient => (ingredient.isUnderCategory = true))
        })
      }
      return data
    },
    allCategoryIngredients(state) {
      return state.allCategoryIngredients
    },
    updatedCategory(state) {
      return state.updatedCategory
    },
    updatedDirtyIngredient(state) {
      return state.updatedDirtyIngredient
    },
    ingredients(state) {
      return state.ingredients
    }
  },

  mutations: {
    SET_INGREDIENTS_BY_NAME(state, list) {
      state.ingredientsByName = list ? list : {}
    },
    SET_CATEGORY_INGREDIENTS(state, data) {
      state.categoryIngredients = data ? data : {}
    },
    SET_DIRTY_CATEGORY(state, data) {
      state.updatedCategory = data
    },
    SET_DIRTY_INGREDIENT(state, data) {
      state.updatedDirtyIngredient = data
    },
    SET_ALL_CATEGORY(state, data) {
      state.allCategoryIngredients = data
    },
    SET_INGREDIENTS(state, data) {
      state.ingredients = data ? data.list : []
    }
  },

  actions: {
    async fetchIngredientsByName({ commit }, params) {
      try {
        const { data } = await cleanPreCheck.fetchIngredientsByName(params)
        commit("SET_INGREDIENTS_BY_NAME", data)
      } catch (error) {
        let index = error.toString().indexOf("404")
        if (index >= 0) {
          commit("SET_INGREDIENTS_BY_NAME", {})
        } else {
          return Promise.reject(error)
        }
      }
    },
    async fetchIngredients({ commit }, params) {
      try {
        params = Object.assign({}, params, { _limit: 9999 })
        const { data } = await cleanPreCheck.fetchIngredientsByName(params)
        commit("SET_INGREDIENTS", data)
      } catch (error) {
        let index = error.toString().indexOf("404")
        if (index >= 0) {
          commit("SET_INGREDIENTS", [])
        } else {
          return Promise.reject(error)
        }
      }
    },
    async fetchCategoryIngredientsByName({ commit }, params) {
      try {
        const { data } = await cleanPreCheck.fetchCategoryIngredientsByName(params)
        commit("SET_CATEGORY_INGREDIENTS", data)
      } catch (error) {
        let index = error.toString().indexOf("404")
        if (index >= 0) {
          commit("SET_CATEGORY_INGREDIENTS", {})
        } else {
          return Promise.reject(error)
        }
      }
    },
    async fetchAllCategoryIngredientsByName({ commit }, params) {
      try {
        params = Object.assign({}, params, { _limit: 9999 })
        const { data } = await cleanPreCheck.fetchCategoryIngredientsByName(params)
        commit("SET_ALL_CATEGORY", data)
      } catch (error) {
        let index = error.toString().indexOf("404")
        if (index >= 0) {
          commit("SET_ALL_CATEGORY", {})
        } else {
          return Promise.reject(error)
        }
      }
    },
    async updateDirtyIngreidentCategory({ commit }, params) {
      try {
        const { data } = await cleanPreCheck.createOrUpdateDirtyCategory(params)
        commit("SET_DIRTY_CATEGORY", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateDirtyIngreident({ commit }, params) {
      try {
        const { data } = await cleanPreCheck.createOrUpdateDirtyIngredients(params)
        commit("SET_DIRTY_INGREDIENT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteDirtyIngredient({ commit }, id) {
      try {
        const { data } = await cleanPreCheck.deleteIngredientFromCategory(id)
        commit("SET_DIRTY_INGREDIENT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteCategory({ commit }, id) {
      try {
        const { data } = await cleanPreCheck.deleteCategory(id)
        commit("SET_DIRTY_CATEGORY", data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
