<template>
  <div class="brand-pitch__main">
    <div class="brand-pitch-title">
      <div class="top-title">
        <span>{{ this.$t("brandPitch.title") }}</span>
      </div>
      <div class="text-info">
        <p>{{ this.$t("brandPitch.content1") }}</p>
        <p>{{ this.$t("brandPitch.content2") }}</p>
        <p>{{ this.$t("brandPitch.content3") }}</p>
      </div>
    </div>
    <div class="content-title">{{ this.$t("brandPitch.cardTitle") }}</div>
    <div class="pitch-content">
      <div v-for="(item, index) in cardList" :key="index" class="card-info">
        <div class="pitch-card-img">
          <div class="pitch-content-position">
            <img :src="item.img" alt="" />
          </div>
        </div>
        <div class="card-title-pitch">{{ item.title }}</div>
        <div class="card-link">
          <p @click="clickToDetail(item, dialogType.LINKONE)">
            <span>{{ item.linkOne }}</span>
          </p>
          <p v-if="item.linkTwo" @click="clickToDetail(item, dialogType.LINKTWO)">
            <span>{{ item.linkTwo }}</span>
          </p>
        </div>
      </div>
      <div class="card-info">
        <Footer style="background-color: #E2EEEC" :show-border="false"/>
      </div>
    </div>
    <div class="pitch-content-bg"></div>
    <div class="pitch-content-footer"></div>
    <a-modal
      :visible="visible"
      :width="825"
      :footer="null"
      :bodyStyle="bodyStyle"
      class="modal-dialog-main"
      @cancel="handleCancel"
    >
      <div slot="title" class="dialog-title-top"></div>
      <div class="dialog-content">
        <div class="dialog-left">
          <div class="dialog-left-content">
            <img :src="modalInfo.img" alt="" />
            <p>{{ modalInfo.title }}</p>
          </div>
        </div>
        <div class="dialog-right">
          <span class="dialog-right-title">{{ modalInfo.rightTitle }}</span>
          <p class="dialog-right-content">{{ modalInfo.content }}</p>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import January from "@/assets/img/brand/pitch/January.png"
import February from "@/assets/img/brand/pitch/February.png"
import March from "@/assets/img/brand/pitch/March.png"
import April from "@/assets/img/brand/pitch/April.png"
import May from "@/assets/img/brand/pitch/May.png"
import June from "@/assets/img/brand/pitch/June.png"
import July from "@/assets/img/brand/pitch/July.png"
import August from "@/assets/img/brand/pitch/August.png"
import September from "@/assets/img/brand/pitch/Seplember.png"
import October from "@/assets/img/brand/pitch/October.png"
import November from "@/assets/img/brand/pitch/November.png"
import December from "@/assets/img/brand/pitch/December.png"
import Footer from "@/layouts/retailer/Footer"

const dialogType = {
  LINKONE: 1,
  LINKTWO: 2
}
export default {
  name: "BrandPitch",
  data() {
    return {
      visible: false,
      dialogType,
      modalInfo: {
        img: "",
        title: "",
        rightTitle: "",
        content: ""
      },
      bodyStyle: {
        padding: '0px'
      }
    }
  },
  components: {
    Footer
  },
  computed: {
    // textContentOne() {
    //   return this.$t("brandPitch.content")
    // },
    guest() {
      return this.$t("GuestLayout")
    },
    cardList() {
      const list = [
        { img: January },
        { img: February },
        { img: March },
        { img: April },
        { img: May },
        { img: June },
        { img: July },
        { img: August },
        { img: September },
        { img: October },
        { img: November },
        { img: December }
      ]
      const cardText = this.$t("brandPitch.cardList")
      return cardText.map((item, index) => {
        return { ...item, ...list[index] }
      })
    }
  },
  methods: {
    handleCancel() {
      this.visible = false
      document.documentElement.style.overflow = "visible"
    },
    clickToDetail(item, type) {
      this.visible = true
      this.modalInfo = {
        ...item,
        content: type === dialogType.LINKONE ? item.modalTextOne : item.modalText2,
        rightTitle: type === dialogType.LINKONE ? item.linkOne : item.linkTwo
      }
      document.documentElement.style.overflow = "visible"
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: "Proxima Nova";
  src: url("~@/assets/fonts/ProximaNova-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Playfair Display";
  src: url("~@/assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}

.brand-pitch__main {
  padding-bottom: 0px !important;
  .brand-pitch-title {
    position: relative;
    margin-bottom: 396px;
  }

  .top-title {
    width: 100%;
    height: 477px;
    font-family: "Playfair Display";
    text-align: center;
    font-size: 65px;
    font-weight: bold;
    color: white;
    background-color: #12433d;
    padding-top: 120px;

    span {
      margin-top: 10px;
    }
  }

  .text-info {
    width: 1098px;
    height: 380px;
    padding: 56px 75px;
    background-color: white;
    font-size: 26px;
    font-family: "Proxima Nova";
    line-height: 36px;
    color: #000000;
    text-align: center;
    position: absolute;
    top: 234px;
    left: calc(50% - 549px);
    z-index: 3;
    p {
      margin-bottom: 48px;
    }
  }
  .content-title {
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 65px;
    font-weight: bold;
    font-family: "Playfair Display";
    color: #12433d;
    text-align: center;
    background-color: #fef2e2;
  }
  .pitch-content {
    width: 100%;
    height: 2409px;
    @media screen and (max-width: 1440px) {
      background: #fef2e2 url("~@/assets/img/brand/ptich-bg.png") no-repeat 100% center;
    }
    @media screen and (min-width: 1441px) {
      background: #fef2e2 url("~@/assets/img/brand/pitch-bg-large.png") no-repeat 100% center;
    }
    //overflow: hidden;
    position: relative;
    .card-title-pitch {
      font-size: 48px;
      font-weight: bold;
      color: #12433d;
      margin-bottom: 26px;
      font-family: "Playfair Display";
    }
    .card-link {
      //text-decoration-line: underline;
      font-size: 23px;
      color: #000000;
      font-family: "Proxima Nova";
      p {
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        span {
          border-bottom: 1px solid #000000;
        }
      }
      p:hover {
        color: #12433d;
      }
      p:nth-child(1) {
        margin-bottom: 16px;
      }
    }
    .card-info {
      position: absolute;
      text-align: center;
    }
    .card-info:nth-child(1) {
      @media screen and (max-width: 1440px){
        right: 102px;
        top: 15px;
      }
      @media screen and (min-width: 1441px){
        right: 102px;
        top: 3px;
      }
    }
    .card-info:nth-child(2) {
      @media screen and (max-width: 1440px) {
        right: 550px;
        top: 105px;
        .card-title-pitch {
          margin-top: 26px;
        }
      }
      @media screen and (min-width: 1441px){
        right: 660px;
        top: 94px;
        .card-title-pitch {
          margin-top: 26px;
        }
      }
    }
    .card-info:nth-child(3) {
      @media screen and (max-width: 1440px){
        right: 740px;
        top: 405px;
        .pitch-card-img {
          margin-right: 360px;
          margin-bottom: -60px;
        }
      }
      @media screen and (min-width: 1441px){
        right: 1070px;
        top: 445px;
        .pitch-card-img {
          margin-right: 360px;
          margin-bottom: -60px;
        }
      }
    }
    .card-info:nth-child(4) {
      @media screen and (max-width: 1440px){
        right: 540px;
        top: 764px;
        .pitch-card-img {
          margin-left: 320px;
          margin-bottom: -50px;
        }
      }
      @media screen and (min-width: 1441px){
        right: 680px;
        top: 768px;
        .pitch-card-img {
          margin-left: 320px;
          margin-bottom: -40px;
        }
      }
    }
    .card-info:nth-child(5) {
      @media screen and (max-width: 1440px){
        right: 102px;
        top: 700px;
        .pitch-content-position {
          margin-right: 40px;
        }
      }
      @media screen and (min-width: 1441px){
        right: 102px;
        top: 716px;
        .pitch-content-position {
          margin-right: 40px;
        }
      }
    }
    .card-info:nth-child(6) {
      @media screen and (max-width: 1440px){
        right: 92px;
        top: 1228px;
        .pitch-card-img {
          margin-bottom: 40px;
        }
      }
      @media screen and (min-width: 1441px){
        right: 92px;
        top: 1218px;
        .pitch-card-img {
          margin-bottom: 40px;
        }
      }
    }
    .card-info:nth-child(7) {
      @media screen and (max-width: 1440px) {
        right: 544px;
        top: 1300px;
      }
      @media screen and (min-width: 1441px) {
        right: 544px;
        top: 1340px;
      }

    }
    .card-info:nth-child(8) {
      @media screen and (max-width: 1440px) {
        right: 945px;
        top: 1165px;
        .pitch-card-img {
          margin-right: 200px;
        }
      }
      @media screen and (min-width: 1441px) {
        right: 1195px;
        top: 1135px;
        .pitch-card-img {
          margin-right: 200px;
        }
      }
    }
    .card-info:nth-child(9) {
      @media screen and (max-width: 1440px) {
        right: 1024px;
        bottom: 240px;
        .pitch-card-img {
          margin-bottom: -100px;
        }
        .card-title-pitch {
          margin-right: 100px;
        }
        .card-link {
          margin-right: 100px;
        }
      }
      @media screen and (min-width: 1441px) {
        right: 1334px;
        bottom: 194px;
        .pitch-card-img {
          margin-bottom: -100px;
        }
        .card-title-pitch {
          margin-right: 100px;
        }
        .card-link {
          margin-right: 100px;
        }
      }
    }
    .card-info:nth-child(10) {
      @media screen and (max-width: 1440px) {
        right: 623px;
        bottom: 77px;
      }
      @media screen and (min-width: 1441px) {
        right: 793px;
        bottom: 62px;
      }
    }
    .card-info:nth-child(11) {
      @media screen and (max-width: 1440px) {
        right: 137px;
        bottom: 10px;
      }
      @media screen and (min-width: 1441px) {
        right: 137px;
        bottom: -18px;
      }
    }
    .card-info:nth-child(12) {
      @media screen and (max-width: 1440px) {
        right: 311px;
        bottom: -560px;
      }
      @media screen and (min-width: 1441px) {
        right: 311px;
        bottom: -530px;
      }
    }
    .card-info:nth-child(13) {
      bottom: -740px;
      left: 0;
      right: 0;
      .footer-banner{
        margin: auto;
        width: 100%;
        @media screen and (max-width: 1440px) {
          padding-left: 34px;
          padding-right: 50px;
          height: 68px;
        }
        @media screen and (min-width: 1441px) {
          height: 80px;
          padding-left: 44px;
          padding-right: 70px;
        }
      }
      .right-footer {
        float: right;
      }
      .nav-link {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: normal;
        color: #000000;
        font-size: 20px;
        line-height: 18px;
        white-space: nowrap;
        @media screen and (max-width: 1440px) {
          margin-right: 62px;
        }
        @media screen and (min-width: 1441px) {
          margin-right: 82px;
        }
      }
      .nav-link:last-child {
        margin-right: 0px;
      }
      .nav-img {
        margin-right: 16px
      }
    }
  }
  .pitch-content-position:nth-child(3) {
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .pitch-content-bg {
    width: 100%;
    height: 120px;
    background: #fef2e2;
  }
  .pitch-content-footer {
    width: 100%;
    height: 694px;
    @media screen and (max-width: 1440px) {
      background: #fef2e2 url("~@/assets/img/brand/pitch/bg-footer.png") no-repeat 100% 1000%;
    }
    @media screen and (min-width: 1441px) {
      background: #fef2e2 url("~@/assets/img/brand/pitch/bg-footer-large.png") no-repeat 100% 1000%;
    }
  }
}
.dialog-title-top {
  height: 28px;
}
.dialog-content {
  padding: 10px 0 153px 0px;
  display: flex;
  width: 825px;
  height: 436px;
  line-height: 50px;
  .dialog-left {
    width: 373px;
    text-align: center;
    position: relative;
    .dialog-left-content {
      position: absolute;
      bottom: 0px;
      margin: auto;
      left: 0;
      right: 0;
    }
    p {
      font-size: 48px;
      font-weight: bold;
      color: #12433d;
      font-family: "Playfair Display";
      margin-bottom: 0px !important;
      margin-top: 6px;
    }
    img {
      margin-right: 0px !important;
    }
  }
  .dialog-right {
    width: 375px;
    font-family: "Proxima Nova";
    margin-top: 80.95px;
    margin-right: 77px;
    p {
      line-height: 24px;
    }
    .dialog-right-title {
      font-size: 23px;
      font-weight: bold;
      line-height: 24px;
      color: #12433d;
      font-family: "Playfair Display";
    }
    .dialog-right-content {
      font-size: 14px;
      line-height: 23px;
      color: #464646;
      margin-top: -12px;
    }
  }
}
</style>
