<template lang="pug">
.price-range-slider
  .price-range-slider__header-name {{$t('Components.Filters.PriceRangeFilter.priceRange')}}
  .columns.is-marginless
    .column.has-text-centered.current-values {{ priceFormat(minValue) }} - {{ priceFormat(maxValue) }}
  .columns
    .column
      .price-range-slider__scale-wrapper
        .price-range-slider__scale
          .price-range-slider__scale-active(:style="{ width: activeScaleWidth, left: activeScaleLeftOffset }")
        input.price-range-slider__min(
          :value="minValue" @input="setMinValue" @change="emitPriceChange" :min="totalMin" :max="totalMax" step="1" type="range"
        )
        input.price-range-slider__max(
          :value="maxValue"  @input="setMaxValue" @change="emitPriceChange" :min="totalMin" :max="totalMax" step="1" type="range"
        )
  .columns.is-mobile.price-range-slider__range
    .column.is-narrow {{ priceFormat(totalMin) }}
    .column
    .column.is-narrow {{ priceFormat(totalMax) }}
</template>

<script>
export default {
  props: {
    products: { type: Array, default: () => [] },
    totalMin: { type: String, default: "" },
    totalMax: { type: String, default: "" }
  },
  data() {
    return {
      isOpen: false,
      min: 0,
      max: 100
    }
  },
  computed: {
    activeScaleWidth() {
      const widthPercentage = parseInt(
        ((this.maxValue - this.minValue) / (this.totalMax - this.totalMin)) * 100
      )
      return `${widthPercentage}%`
    },
    activeScaleLeftOffset() {
      const widthPercentage = parseInt(
        ((this.minValue - this.totalMin) / (this.totalMax - this.totalMin)) * 100
      )
      return `${widthPercentage}%`
    },
    minValue() {
      return this.min < this.totalMin ? this.totalMin : this.min
    },
    maxValue() {
      return this.max > this.totalMax ? this.totalMax : this.max
    }
  },
  created() {
    this.min = this.totalMin
    this.max = this.totalMax
  },
  methods: {
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    setMinValue(event) {
      const value = parseInt(event.target.value, 10)
      const minValue = value > this.max ? this.max - 1 : value

      this.min = minValue
    },
    setMaxValue(event) {
      const value = parseInt(event.target.value, 10)
      this.max = value < this.min ? this.min + 1 : value
    },
    emitPriceChange() {
      this.$emit("filterSwitch", this.minValue, this.maxValue)
    }
  }
}
</script>

<style lang="sass">
.price-range-slider__scale-wrapper
  position: relative
  height: 26px

.price-range-slider__range
  margin-top: 20px
  font-size: 12px
  color: #4A4A4A

.price-range-slider

  &__header-name
    position: relative
    display: block
    cursor: pointer
    font-size: 16px
    color: #4A4A4A
    padding: 16px 0px 10px 0px

  .current-values
    color: #000000
    font-size: 12px
    font-weight: 600

  &__scale
    height: 6px
    border-radius: 2px
    width: 100%
    background: #E5E5E5
    position: absolute
    left: 0
    top: calc(50% - 3px)

  &__scale-active
    height: 6px
    border-radius: 2px
    width: 100%
    background: #000
    left: 0
    top: 0
    position: absolute

  &__min, &__max
    position: absolute
    left: 0
    width: 100%
    pointer-events: none
    background: inherit
    -webkit-appearance: none
    height: 26px

    &:focus
      outline: none

    &::-webkit-slider-thumb
      pointer-events: auto
      background: #292929
      width: 12px
      height: 12px
      border: none
      border-radius: 50%
      -webkit-appearance: none

    &::-moz-range-thumb
      pointer-events: auto
      background: #292929
      width: 12px
      height: 12px
      border: none
      border-radius: 50%

    &::-ms-thumb
      pointer-events: auto
      background: #292929
      width: 12px
      height: 12px
      border: none
      border-radius: 50%
</style>
