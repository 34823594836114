export default class CoreEntity {
  constructor(model) {
    this.privateId = 0;
    //this.name = "";
    this.id = "";
    //this.isActive = 1
    this.createdAt = new Date();
    if (model) {
      this.privateId = model.privateId || 0;
      this.id = model.id || "";
      //this.name = model.name || ""
      this.isActive = model.isActive;
      //this.description = model.description || null
      this.createdAt = model.createdAt ? new Date(model.createdAt) : null;
      this.createdBy = model.createdBy || null;
      this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null;
      this.updatedBy = model.updatedBy || null;
    }
  }

  toModel() {
    return {
      privateId: this.privateId,
      id: this.id,
      //name: this.name,
      //description: this.description,
      isActive: this.isActive,
      createdAt: this.toDateString(this.createdAt),
      createdBy: this.createdBy,
      updatedAt: this.toDateString(this.updatedAt),
      updatedBy: this.updatedBy,
    };
  }

  clone() {
    //throw new Error("clone not implement")
    return new this.constructor(this.toModel());
  }

  toDateString(datetime) {
    return datetime ? datetime.toISOString() : null;
  }
}
