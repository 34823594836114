<template lang="pug">
.retailer-cards
  .retailer-card(v-for="retailer in filteredRetailers" :key="retailer.id")
    RetailerCard(
      :retailerId="getRetailerId(retailer)"
      :retailerLogoUrl="logoImage(retailer)"
      :retailerName="getRetailerName(retailer)"
      :retailerWebsite="getRetailerWebsite(retailer)"
      :retailerChannel="getRetailerChannel(retailer)"
      :retailerDesc="getRetailerDesc(retailer)"
      :retailerAddresses="getRetailerLocations(retailer)"
      )
  a-empty(v-if="!filteredRetailers || filteredRetailers.length === 0" description="No Retailers")

</template>

<script>
import RetailerCard from "@/components/brands/retailers/RetailerCard"
import { mapState } from "vuex"
// import constants from "@/config"

export default {
  components: {
    RetailerCard
  },

  props: {
    retailers: {
      type: Array,
      default: () => []
    },
    searchedRetailers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      searchTerm: state => state.retailerSearch.searchTerm
    }),
    filteredRetailers() {
      if (
        this.$store.state.retailerSearch.isCustomSearch &&
        this.$store.state.retailerSearch.tempLocationVariable.length
      ) {
        console.log("this is custom search now... with location")
        return (
          // get retailers from state
          this.$store.state.retailerSearch.returnedCustomRetailers
            .filter(retailer => retailer.name.toLowerCase().includes(this.searchTerm))
            .filter(retailer => {
              console.log("does this retailer have a channel type?")
              console.log(retailer)
              let countries = []
              let addresses = retailer.addresses
              for (let address in addresses) {
                countries.push(addresses[address].country.id)
              }
              console.log("countries here!")
              console.log(countries)
              console.log("templocationvariables")
              console.log(this.$store.state.retailerSearch.tempLocationVariable)
              //use some...
              return countries.some(r => {
                if (this.$store.state.retailerSearch.tempLocationVariable.includes(r)) {
                  console.log(r)
                  return r
                }
              })
            })
        )
      } else if (
        this.$store.state.retailerSearch.isCustomSearch &&
        this.$store.state.retailerSearch.tempChannelTypeVariable.length
      ) {
        console.log("this is custom search now...without location bro")
        return (
          // get retailers from state
          this.$store.state.retailerSearch.returnedCustomRetailers.filter(retailer =>
            retailer.name.toLowerCase().includes(this.searchTerm)
          )
        )
      } else {
        return this.retailers.filter(retailer =>
          retailer.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        )
      }
    },
    isAdvancedSearch() {
      return this.$router.currentRoute.query.s == "adv"
    }
  },
  async created() {
    console.log("card view creted")
    this.$store.watch(
      () => {
        return this.$store.state.retailerSearch.isCustomSearch // could also put a Getter here
      },
      // eslint-disable-next-line no-unused-vars
      (newValue, oldValue) => {
        //something changed do something
        this.$store.dispatch(
          "retailerSearch/fetchCustomRetailers",
          this.$store.state.retailerSearch.newWindowSearchURL
        )
      },
      //Optional Deep if you need it
      {
        deep: true
      }
    )
  },
  methods: {
    async fetchCustomRetailers() {
      await this.getCustomRetailersAsync()
      console.log("fetchCustomRetailers")
    },
    async getCustomRetailersAsync() {
      console.log("getCustomRetailersAsync")
    },

    doRetailerAddressesContainState(retailer) {
      if (retailer.addresses) {
        let addresses = retailer.addresses
        addresses.forEach(address => {
          if (address.country) {
            if (address.country.id == "us") {
              console.log("yap")
              console.log(address)
              return retailer
            }
            return retailer
          }
        })
      } else {
        return retailer
      }
    },
    countryName(id) {
      return this.$store.getters["reference/countryName"](id)
    },
    async headquarters() {
      // await this.$store.dispatch("reference/setReference")
      await this.$store.dispatch("retailer/fetchRetailer", this.retailerId)
      return this.$store.getters["retailerAddress/headquarters"]
    },

    getRetailerId(retailer) {
      return retailer.id
    },
    getRetailerName(retailer) {
      return retailer.name
    },
    getRetailerWebsite(retailer) {
      return retailer.website
    },
    getRetailerChannel(retailer) {
      console.log("retailer coming. Get Channel Type!!:")
      if (retailer.channelType) {
        return retailer.channelType.name
      } else {
        return "No Type"
      }
    },
    getRetailerDesc(retailer) {
      console.log("retailer comgin!:")
      console.log(retailer)
      return retailer.about
    },
    getRetailerLocations(retailer) {
      let countries = []
      if (retailer.addresses) {
        let addresses = retailer.addresses

        for (let address in addresses) {
          console.log("Country::::")
          console.log(this.$store.getters["reference/countryName"](addresses[address].country.id))
          countries.push(
            this.$store.getters["reference/countryName"](addresses[address].country.id)
          )
        }
        return countries.join(",")
      } else {
        return ""
      }
    },
    logoImage(retailer) {
      return retailer.logoAsset && retailer.logoAsset.url
    }
  }
}
</script>

<style lang="sass">
//.retailer-cards
//  position: relative
//  // background-color: red
//  height: auto
//  width: 100%
//  padding-top: 30px


.retailer-card
    height: 350px
    width: 240px
    min-width: 200px
    float: left
    margin: 0 13px 32px
    text-align: center
    border: 1px solid #e8e8e8
    border-radius: 27px
    box-shadow: 1px 1px 4px 1px #c9c9c9
    font-size: 14px
    font-family: SF Pro Display
</style>
