import adminMessage from "@/api/admin-message"
import { messageSenderAndReceiverType, messageSearchType, messageItemizeType } from "@/utils/const"
export default {
  namespaced: true,

  state: {
    messages: {},
    sendMessages: {},
    brandSendMessage: {},
    retailerSendMessage: {},
    interMessage: {},
    archiveMessage: {},
    draftMessage: {},
    categorys: [],
    searchGroups: [],
    groupClient: [],
    messageDetail: {},
    isSuccess: false,
    retailers: [],
    brands: [],
    messageType: [],
    firebaseToken: null,
    hasConnection: true,
    unReadCount: 0,
    searchMessage: {}
  },

  getters: {
    messages(state) {
      return state.messages
    },
    sendMessages(state) {
      return state.sendMessages
    },
    brandSendAdminMessage(state) {
      return state.brandSendMessage
    },
    retailerSendAdminMessage(state) {
      return state.retailerSendMessage
    },
    interMessage(state) {
      return state.interMessage
    },
    archiveMessage(state) {
      return state.archiveMessage
    },
    draftMessages(state) {
      return state.draftMessage
    },
    categorys(state) {
      return state.categorys
    },
    searchGroups(state) {
      return state.searchGroups
    },
    groupClient(state) {
      return state.groupClient
    },
    messageDetail(state) {
      return state.messageDetail
    },
    isSuccess(state) {
      return state.isSuccess
    },
    messageType(state) {
      return state.messageType
    },
    brands(state) {
      return state.brands
    },
    retailers(state) {
      return state.retailers
    },
    hasConnection(state) {
      return state.hasConnection
    },
    unReadMessageCount(state) {
      return state.unReadCount
    },
    searchMessage(state) {
      return state.searchMessage
    },
    draftInfo(state) {
      if (state.messageDetail.messages && state.messageDetail.messages.length !== 0) {
        return state.messageDetail.messages.filter(item => item.drafts)
      }
    }
  },

  mutations: {
    SET_MESSAGES(state, clients) {
      state.messages = clients
    },
    SET_SEND_MESSAGES(state, clients) {
      state.sendMessages = clients
    },
    SET_BRAND_ADMIN_MESSAGES(state, clients) {
      state.brandSendMessage = clients
    },
    SET_RETAILER_ADMIN_MESSAGES(state, clients) {
      state.retailerSendMessage = clients
    },
    SET_INTER_MESSAGES(state, clients) {
      state.interMessage = clients
    },
    SET_ARCHIVE_MESSAGES(state, clients) {
      state.archiveMessage = clients
    },
    SET_DRAFT_MESSAGES(state, clients) {
      state.draftMessage = clients
    },
    SET_CATEGORYS(state, clients) {
      state.categorys = clients
    },
    UPDATE_CATEGORY(state, data) {
      if (!state.categorys) {
        state.categorys = []
      }
      state.categorys.push(data)
    },
    SET_SEARCH_GROUPS(state, clients) {
      state.searchGroups = clients
    },
    SET_GROUP_CLIENT(state, clients) {
      if (!clients || !clients.clientInformation) {
        state.groupClient = []
      } else {
        state.groupClient = clients.clientInformation
      }
    },
    SET_MESSAGE_DETAIL(state, data) {
      state.messageDetail = data
    },
    SET_IS_SUCCESS(state, data) {
      if (data) {
        state.isSuccess = true
      } else {
        state.isSuccess = false
      }
    },
    SET_MESSAGE_TYPE(state, data) {
      state.messageType = data
    },
    SET_BRANDS(state, brands) {
      state.brands = brands
    },
    SET_RETAILERS(state, retailers) {
      state.retailers = retailers
    },
    GET_SEARCH_MESSAGE(state, message) {
      state.searchMessage = message
    },
    SET_FIRBASE_TOKEN(state, token) {
      state.firebaseToken = token
    },
    SET_HAS_CONNECTION(state, data) {
      state.hasConnection = data
    },
    SET_UN_READ_COUNT(state, data) {
      state.unReadCount = data
    }
  },

  actions: {
    async fetchAdminInboxMessages({ commit }, params) {
      try {
        let param = {
          "client-id": "*",
          "sender-type": `${messageSenderAndReceiverType.BRAND_TYPE},${messageSenderAndReceiverType.RETAILER_TYPE}`,
          itemize: messageItemizeType.INBOX_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchBrandInboxMessages({ commit }, { id, params }) {
      try {
        let param = {
          "client-id": id,
          "sender-type": `${messageSenderAndReceiverType.ADMIN_TYPE},${messageSenderAndReceiverType.RETAILER_TYPE}`,
          itemize: messageItemizeType.INBOX_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchRetailerInboxMessages({ commit }, { id, params }) {
      try {
        let param = {
          "client-id": id,
          "sender-type": `${messageSenderAndReceiverType.ADMIN_TYPE},${messageSenderAndReceiverType.BRAND_TYPE}`,
          itemize: messageItemizeType.INBOX_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAdminBrandInboxMessages({ commit }, params) {
      try {
        let param = {
          "client-id": "*",
          "sender-type": messageSenderAndReceiverType.BRAND_TYPE,
          itemize: messageItemizeType.INBOX_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_BRAND_ADMIN_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAdminRetailerInboxMessages({ commit }, params) {
      try {
        let param = {
          "client-id": "*",
          "sender-type": messageSenderAndReceiverType.RETAILER_TYPE,
          itemize: messageItemizeType.INBOX_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_RETAILER_ADMIN_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAdminInterInboxMessages({ commit }, params) {
      try {
        let param = {
          "client-id": "*",
          "sender-type": `${messageSenderAndReceiverType.RETAILER_TYPE},${messageSenderAndReceiverType.BRAND_TYPE}`,
          itemize: messageItemizeType.INTER_MESSAGE_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_INTER_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAdminSendMessages({ commit }, params) {
      try {
        let param = {
          "client-id": "*",
          itemize: messageItemizeType.SENT_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_SEND_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchBrandSendMessages({ commit }, { id, params }) {
      try {
        let param = {
          "client-id": id,
          itemize: messageItemizeType.SENT_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_SEND_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchRetailerSendMessages({ commit }, { id, params }) {
      try {
        let param = {
          "client-id": id,
          itemize: messageItemizeType.SENT_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_SEND_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAdminArchivedMessages({ commit }, params) {
      try {
        let param = {
          "client-id": "*",
          itemize: messageItemizeType.ARCHIVE_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_ARCHIVE_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchBrandArchivedMessages({ commit }, { id, params }) {
      try {
        let param = {
          "client-id": id,
          itemize: messageItemizeType.ARCHIVE_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_ARCHIVE_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchRetailerArchivedMessages({ commit }, { id, params }) {
      try {
        let param = {
          "client-id": id,
          itemize: messageItemizeType.ARCHIVE_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.searchMessage(param)
        commit("SET_ARCHIVE_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAdminDraftMessages({ commit }, params) {
      try {
        let param = {
          "client-id": "*",
          itemize: messageItemizeType.DRAFTS_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.getDraftMessageList(param)
        commit("SET_DRAFT_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchBrandDraftMessages({ commit }, { id, params }) {
      try {
        let param = {
          "client-id": id,
          itemize: messageItemizeType.DRAFTS_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.getDraftMessageList(param)
        commit("SET_DRAFT_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchRetailerDraftMessages({ commit }, { id, params }) {
      try {
        let param = {
          "client-id": id,
          itemize: messageItemizeType.DRAFTS_TYPE,
          "search-type": messageSearchType.LIST_TYPE,
          ...params
        }
        const { data } = await adminMessage.getDraftMessageList(param)
        commit("SET_DRAFT_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchCategorys({ commit }) {
      try {
        const { data } = await adminMessage.getMessageCategory()
        commit("SET_CATEGORYS", data)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          commit("SET_CATEGORYS", [])
        } else {
          return Promise.reject(error)
        }
      }
    },
    async fetchSearchGroup({ commit }, params) {
      try {
        const { data } = await adminMessage.getSearchGroup(params)
        commit("SET_SEARCH_GROUPS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createMessage({ commit }, params) {
      try {
        const { data } = await adminMessage.createMessageInfo(params)
        commit("SET_MESSAGES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createMessageCategory({ commit }, params) {
      try {
        const { data } = await adminMessage.createMessageCategory(params)
        // if (data && data.state) {
        //   let category = {privateId: data.state, name: params.name, isActive: true}
        //   commit("UPDATE_CATEGORY", category)
        //   return category
        // }
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createSearchGroup({ commit }, params) {
      try {
        if (params.startDate && params.startDate === "All Time") {
          params.startDate = "1779-01-01"
          params.endDate = "2299-12-31"
        }
        const { data } = await adminMessage.createSearchGroup(params)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateSearchGroup({ commit }, params) {
      try {
        if (params.startDate && params.startDate === "All Time") {
          params.startDate = "1779-01-01"
          params.endDate = "2299-12-31"
        }
        const { data } = await adminMessage.updateSearchGroup(params)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateMessage({ commit }, { id, params }) {
      try {
        const { data } = await adminMessage.updateMessage(id, params)
        commit("SET_MESSAGES", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteMessageList({ commit }, params) {
      try {
        const { data } = await adminMessage.deleteMessageList(params)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteSearchGroup({ commit }, id) {
      try {
        const { data } = await adminMessage.deleteSearchGroup(id)
        //commit("SET_SEARCH_GROUPS", data)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getGroupClient({ commit }, param) {
      try {
        const { data } = await adminMessage.getGroupClient(param)
        commit("SET_GROUP_CLIENT", data)
        if (data) {
          return data
        }
        return []
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteMessageDetailsReceiver({ commit }, params) {
      try {
        const { data } = await adminMessage.deleteMessageDetailsReceiver(params)
        console.log(commit)
        commit("SET_MESSAGES", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async hasConnection({ commit }, { brandId, retailerId }) {
      try {
        const { data } = await adminMessage.hasConnection(brandId, retailerId)
        console.log(commit)
        commit("SET_HAS_CONNECTION", data)
        return data
      } catch (error) {
        commit("SET_HAS_CONNECTION", false)
        return Promise.reject(error)
      }
    },
    async getMessageDetail({ commit }, params) {
      try {
        const { data } = await adminMessage.getMessageDetail(params)
        commit("SET_MESSAGE_DETAIL", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getMessageType({ commit }) {
      try {
        const { data } = await adminMessage.getMessageType()
        commit("SET_MESSAGE_TYPE", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async archiveMessages({ commit }, params) {
      try {
        const { data } = await adminMessage.archiveMessage(params)
        commit("SET_IS_SUCCESS", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async updateBatchMessages({}, params) {
      try {
        const { data } = await adminMessage.updateBatchMessages(params)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAllBrands({ commit }, { params }) {
      try {
        const { data } = await adminMessage.getAllBrands(params)
        commit("SET_BRANDS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAllRetailers({ commit }, { params }) {
      try {
        const { data } = await adminMessage.getAllRetailers(params)
        commit("SET_RETAILERS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async fetchSearchMessage({ commit }, { params }) {
      try {
        const { data } = await adminMessage.getSearchMessage(params)
        commit("GET_SEARCH_MESSAGE", data)
        return data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async updateSearchMessage({}, { id, params }) {
      try {
        const { data } = await adminMessage.updateSearchMessageList(id, params)
        return data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async batchOperationMessage({}, { params }) {
      try {
        const { data } = await adminMessage.batchUpdateMessage(params)
        return data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async getUnReadMessage({ commit }) {
      try {
        const { data } = await adminMessage.getUnReadMessage()
        console.log(data)
        commit("SET_UN_READ_COUNT", data.count)
        return data.count
      } catch (err) {
        return Promise.reject(err)
      }
    }
  }
}
