import moment from "moment";
export default {
  parseDateOnly(dateOnly) {
    if (dateOnly) {
      return moment(dateOnly, "YYYY-MM-DD").toDate();
    }
    return null;
  },
  parseDateFromUTC(datetime) {
    if (datetime) {
      if (datetime.slice(-1) != "Z") {
        return new Date(`${datetime}Z`);
      }
    }
    return null;
  },
};
