<template>
  <div class="hero-product-form__main">
    <div class="hero-product-form-first">
      <div>
        <span class="icon-from-tips special-tips">
          <a-tooltip
            :title="this.$t('Training.brandTraining.trainingFormTips.categoryTip')"
            placement="left"
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
          {{ this.$t("Training.brandTraining.trainingFormTips.categoryLabel") }}
        </span>
        <span class="category-text">{{ product.subcategory.name }}</span>
      </div>
      <div>
        <span slot="label" class="icon-from-tips">
          <a-tooltip
            :title="this.$t('Training.brandTraining.trainingFormTips.productTip')"
            placement="left"
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
          {{ this.$t("Training.brandTraining.trainingFormTips.productLabel") }}
        </span>
        <a-switch v-model="isHero" @change="huandleChangeValue" />
      </div>
    </div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :colon="false">
      <a-form-model-item>
        <span slot="label" class="icon-from-tips">
          <a-tooltip
            :title="this.$t('Training.brandTraining.trainingFormTips.stepTip')"
            placement="left"
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
          {{ this.$t("Training.brandTraining.trainingFormTips.stepLabel") }}
        </span>
        <a-select
          v-model="form.trainingSteps"
          mode="multiple"
          :show-arrow="true"
          :label-in-value="true"
          placeholder="Select up to 2"
          @deselect="huandleChangeValue"
          @blur="huandleChangeValue"
          @change="handleChange($event, 2)"
        >
          <a-select-option v-for="(steps, index) in step" :key="index" :value="steps.id">
            {{ steps.description }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <span slot="label" class="icon-from-tips">
          <a-tooltip
            :title="this.$t('Training.brandTraining.trainingFormTips.useForTip')"
            placement="left"
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
          {{ this.$t("Training.brandTraining.trainingFormTips.useForLabel") }}
        </span>
        <a-select
          v-model="form.trainingBestUsedFors"
          mode="multiple"
          :show-arrow="true"
          :label-in-value="true"
          placeholder="Select up to 2"
          @deselect="huandleChangeValue"
          @blur="huandleChangeValue"
          @change="handleChange($event, 2)"
        >
          <a-select-option v-for="(item, index) in bestUsedFor" :key="index" :value="item.id">
            {{ item.description }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <span slot="label" class="icon-from-tips">
          <a-tooltip
            :title="this.$t('Training.brandTraining.trainingFormTips.crossSellersTip')"
            placement="left"
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
          {{ this.$t("Training.brandTraining.trainingFormTips.crossSellersLabel") }}
        </span>
        <a-select
          v-model="form.trainingCrossSellers"
          mode="multiple"
          :show-arrow="true"
          :label-in-value="true"
          :max-tag-count="3"
          placeholder="Select up to 3 (Optional)"
          @deselect="huandleChangeValue"
          @blur="huandleChangeValue"
          @change="handleChange($event, 3)"
        >
          <a-select-option v-for="(item, index) in getCrossSellers" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <uploadVideo ref="uploadVideo"></uploadVideo>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import uploadVideo from "./upLoadVideoDialog"

export default {
  name: "HeroProductForm",
  components: {
    uploadVideo
  },
  props: {
    product: {
      type: Object
    }
  },
  data() {
    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 19
      },
      isHero: false,
      form: {
        trainingSteps: [],
        trainingBestUsedFors: [],
        trainingCrossSellers: []
      }
    }
  },
  computed: {
    ...mapState({
      bestUsedFor: state => state.reference.productBestUsedFor,
      step: state => state.reference.productStep
    }),
    ...mapGetters({
      getCrossSellers: "training/getCrossSellers"
    }),
    tipsText() {
      return this.$t("Training.brandTraining.textareaTipsInfo")
    },
    canChooseBestUsedFors() {
      // return true
      return this.form.trainingBestUsedFors.length >= 2
    }
  },
  async mounted() {
    await this.getRefData()
    if (
      this.$route.query.id ||
      this.$route.query.isSaveDraft ||
      this.$route.name === "adminBrandTrainingProduct"
    ) {
      this.form.trainingSteps = this.checkValue(this.product.trainingSteps)
      this.form.trainingBestUsedFors = this.checkValue(this.product.trainingBestUsedFors)
      this.form.trainingCrossSellers = this.checkValue(this.product.trainingCrossSellers)
      this.isHero = this.product.training.hero
    }
  },
  methods: {
    ...mapActions({
      getRefData: "reference/setReference"
    }),
    openDialog() {
      this.$refs["uploadVideo"].openUploadVideo()
    },
    huandleChangeValue() {
      const params = {
        trainingSteps: this.formatValue(this.form.trainingSteps),
        trainingBestUsedFors: this.formatValue(this.form.trainingBestUsedFors),
        trainingCrossSellers: this.formatValue(this.form.trainingCrossSellers),
        training: {
          isHero: this.isHero
        }
      }
      this.$emit("getFormInfo", params)
    },
    formatValue(arr) {
      return arr.map(item => {
        return {
          id: item.key,
          name: item.label
        }
      })
    },
    checkValue(arr) {
      if (arr && arr.length !== 0) {
        return arr.map(item => {
          return {
            key: item.id,
            label: item.name
          }
        })
      }
    },
    handleChange(value, maxSize) {
      if (value.length > maxSize) {
        let tem = value.slice(-1)[0]
        value.splice(maxSize - 1, value.length - 1, tem)
      }
      Object.assign(this, {
        value,
        data: {}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.hero-product-form__main {
  font-family: "Proxima Nova", "Roboto", sans-serif;

  .ant-form-item {
    margin-bottom: 0;
  }
  .hero-product-form-first {
    display: flex;
    justify-content: space-between;
    .item-category {
      width: 100px;
    }
  }
  .icon-from-tips {
    display: inline-block;
    width: 125px;
    padding-right: 16px;
    font-size: 15px;
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .special-tips {
    width: 146px;
    text-align: right;
    color: #000000;
    padding-right: 25px;
    margin-bottom: 5px;
  }
  .category-text {
    display: inline-block;
    width: 379px;
    height: 24px;
    line-height: 24px;
    padding-left: 8px;
    background-color: #fafafa;
  }
  .upload-icon-tip {
    padding-right: 6px;
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .ant-switch-checked {
    background-color: #4a8a5d;
  }

  .upload-content {
    width: 100%;
    text-align: right;
    margin: 10px 0;
  }

  .upload-video-style {
    width: 215px;
    border: 1px solid #4a8a5d;
    color: #4a8a5d;
    background-color: #e2eeec;
    text-align: center;
  }

  .ant-form-horizontal .ant-form-item .ant-form-item label {
    //padding-right: 16px !important;
  }

  .ant-select-selection:hover {
    border-color: #4a8a5d;
  }

  .ant-select-open .ant-select-selection {
    border-color: #4a8a5d;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #4a8a5d;
    border-color: #4a8a5d;
  }
}
</style>
