<template>
  <a-modal
    v-model="show"
    width="500px"
    :footer="null"
    title="Enter Tracking Info"
    :closable="true"
    :mask-closable="false"
    @cancel="printVisible = false"
  >
    <div style="margin: 0 auto; width: 300px;">
      <div v-if="role == 'admin' && !isEditing">
        <label class="label tracking-information-modal__label">Shipping Option *</label>
        <a-select
          v-model="$v.trackingOrderStatus.$model"
          class="tracking-content-width"
          placeholder="Select shipping method"
          :class="{ 'is-danger': $v.trackingOrderStatus.$error }"
        >
          <a-select-option :value="orderStatus.deliverShipped">
            Domestic
          </a-select-option>
          <a-select-option :value="orderStatus.supplyShipped">
            International
          </a-select-option>
        </a-select>
        <p v-if="$v.trackingOrderStatus.$error" class="help is-danger">Required</p>
      </div>
      <div>
        <label class="label tracking-information-modal__label"> Shipping Method *</label>
        <a-select
          v-model="$v.trackingInformation.type.id.$model"
          class="tracking-content-width"
          placeholder="Select shipping method"
          :class="{ 'is-danger': $v.trackingInformation.type.id.$error }"
        >
          <a-select-option v-for="item in shipmentType" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <p v-if="$v.trackingInformation.type.id.$error" class="help is-danger">Required</p>
      </div>
      <div v-if="trackingInformation.type.id === 'air'">
        <div>
          <label class="label tracking-information-modal__label">Shipping Carrier *</label>
          <!-- <input class="input" placeholder="Enter shipping carrier" type = "text" 
                v-model.trim="$v.trackingInformation.shippingCarrier.$model"
                :class="{'is-danger': $v.trackingInformation.shippingCarrier.$error }" /> -->
          <a-select
            v-model="$v.airShipment.provider.id.$model"
            class="tracking-content-width"
            placeholder="Select shipping carrier"
            :class="{ 'is-danger': $v.airShipment.provider.id.$error }"
          >
            <a-select-option v-for="item in airProviders" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <p v-if="$v.airShipment.provider.$error" class="help is-danger">Required</p>
        </div>
        <div>
          <label class="label tracking-information-modal__label"> Tracking Number *</label>
          <input
            v-model.trim="$v.airShipment.trackingId.$model"
            class="input tracking-content-width"
            placeholder="Enter tracking number"
            type="text"
            :class="{ 'is-danger': $v.airShipment.trackingId.$error }"
          />
          <p v-if="$v.airShipment.trackingId.$error" class="help is-danger">Required</p>
        </div>
      </div>
      <div v-if="trackingInformation.type.id === 'ocean'">
        <div>
          <label class="label tracking-information-modal__label">Forwarder Name *</label>
          <input
            v-model.trim="$v.oceanShipment.forwarderName.$model"
            class="input tracking-content-width"
            placeholder="Enter forwarder name"
            type="text"
            :class="{ 'is-danger': $v.oceanShipment.forwarderName.$error }"
          />
          <p v-if="$v.oceanShipment.forwarderName.$error" class="help is-danger">Required</p>
        </div>
        <div>
          <label class="label tracking-information-modal__label">
            Forwarder Contact Number (optional)</label
          >
          <input
            v-model.trim="$v.oceanShipment.forwarderContactPhone.$model"
            class="input tracking-content-width"
            placeholder="Enter forwarder contact number"
            type="text"
          />
        </div>
        <div>
          <label class="label tracking-information-modal__label"> Vessel Company *</label>
          <a-select
            v-model="$v.oceanShipment.provider.id.$model"
            class="is-fullwidth tracking-content-width"
            placeholder="Select vessel company"
            :class="{ 'is-danger': $v.oceanShipment.provider.id.$error }"
          >
            <a-select-option v-for="item in oceanProviders" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <p v-if="$v.oceanShipment.provider.$error" class="help is-danger">Required</p>
        </div>
        <div>
          <label class="label tracking-information-modal__label"> Vessel Name (optional)</label>
          <input
            v-model.trim="$v.oceanShipment.vesselName.$model"
            class="input tracking-content-width"
            placeholder="Enter vessel name (optional)"
            type="text"
          />
        </div>
        <div>
          <label class="label tracking-information-modal__label"> House B/L Number *</label>
          <input
            v-model.trim="$v.oceanShipment.houseBlNumber.$model"
            class="input tracking-content-width"
            placeholder="Enter house B/L number"
            type="text"
            :class="{ 'is-danger': $v.oceanShipment.houseBlNumber.$error }"
          />
          <p v-if="$v.oceanShipment.houseBlNumber.$error" class="help is-danger">Required</p>
        </div>
        <div>
          <label class="label tracking-information-modal__label">
            Master B/L Number (optional)</label
          >
          <input
            v-model.trim="$v.oceanShipment.masterBlNumber.$model"
            class="input tracking-content-width"
            placeholder="Enter master B/L number (optional)"
            type="text"
          />
        </div>
      </div>
    </div>
    <a-row style="margin-top: 16px;">
      <a-col :span="16"></a-col>
      <a-col :span="8" style="text-align: right;">
        <a-button class="action-btn" @click="skip">Skip</a-button>
        <a-button
          type="primary"
          style="margin-left: 10px; background-color: #4A8A5D; border-color: #D9D9D9;"
          :style="`background-color: ${btnColor}; border-color: ${btnColor}; `"
          class="action-btn"
          :disabled="!$v.$anyDirty"
          @click="save"
        >
          Save
        </a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { required } from "vuelidate/lib/validators"
import constants from "@/config.js"

export default {
  // props: {
  //   show: { type: Boolean, default: false },
  //   airTrackingInfo: { type: Array, default: () => [] },
  //   oceanTrackingInfo: { type: Array, default: () => [] },
  //   isEditing: { type: Boolean, default: false },
  //   trackingStatus: { type: String, default: "" }
  // },
  data() {
    return {
      show: false,
      airTrackingInfo: [],
      oceanTrackingInfo: [],
      isEditing: false,
      trackingStatus: "",
      initialType: "",
      airShipments: [],
      oceanShipments: [],
      btnColor: "#4A8A5D",
      orderStatus: constants.orderStatus,
      showPlaceholder: true,
      trackingInformation: {
        type: {
          id: "",
          name: ""
        },
        options: {
          id: "",
          name: ""
        }
      },
      airShipment: {
        provider: {
          id: "",
          name: ""
        },
        orderStatus: {
          id: ""
        },
        trackingId: ""
      },
      oceanShipment: {
        forwarderName: "",
        forwarderContactPhone: "",
        provider: {
          id: "",
          name: ""
        },
        orderStatus: {
          id: ""
        },
        vesselName: "",
        houseBlNumber: "",
        masterBlNumber: ""
      },
      trackingOrderStatus: ""
    }
  },
  validations() {
    if (this.trackingInformation.type.id == "air") {
      return {
        trackingOrderStatus: this.role == "admin" ? { required } : {},
        trackingInformation: {
          type: {
            id: { required }
          }
        },
        airShipment: {
          provider: {
            id: { required }
          },
          trackingId: { required }
        }
      }
    } else {
      return {
        trackingOrderStatus: this.role == "admin" ? { required } : {},
        trackingInformation: {
          type: {
            id: { required }
          }
        },
        oceanShipment: {
          provider: {
            id: { required }
          },
          forwarderName: { required },
          forwarderContactPhone: {},
          vesselName: {},
          houseBlNumber: { required },
          masterBlNumber: {}
        }
      }
    }
  },
  computed: {
    order() {
      return this.$store.getters["order/order"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || this.$route.name === "adminRetailerOrder"
    },
    isBrand() {
      return this.role === "brand" || this.$route.name === "adminBrandOrder"
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    shipmentType() {
      return this.$store.getters["reference/shipmentType"]
    },
    shippingProvider() {
      return this.$store.getters["reference/shippingProvider"]
    },
    airProviders() {
      return this.shippingProvider && this.shippingProvider.filter(item => item.air)
    },
    oceanProviders() {
      return this.shippingProvider && this.shippingProvider.filter(item => item.ocean)
    }
  },
  created() {
    this.trackingOrderStatus = this.trackingStatus
    let airShipment
    let oceanShipment
    if (this.airTrackingInfo.length > 0 && this.isEditing) {
      if (this.trackingOrderStatus === this.orderStatus.supplyShipped) {
        airShipment = this.airTrackingInfo.filter(
          item => item.orderStatus.id == this.orderStatus.supplyShipped
        )[0]
      } else {
        airShipment = this.airTrackingInfo.filter(
          item => item.orderStatus.id == this.orderStatus.deliverShipped
        )[0]
      }
      if (airShipment) {
        this.trackingInformation.type.id = "air"
        this.airShipment = airShipment
        this.initialType = "air"
      }
    }
    if (this.oceanTrackingInfo.length > 0 && this.isEditing) {
      if (this.trackingOrderStatus === this.orderStatus.supplyShipped) {
        oceanShipment = this.oceanTrackingInfo.filter(
          item => item.orderStatus.id == this.orderStatus.supplyShipped
        )[0]
      } else {
        oceanShipment = this.oceanTrackingInfo.filter(
          item => item.orderStatus.id == this.orderStatus.deliverShipped
        )[0]
      }
      if (oceanShipment) {
        this.trackingInformation.type.id = "ocean"
        this.oceanShipment = oceanShipment
        this.initialType = "ocean"
      }
    }
    this.airShipments = this.airTrackingInfo
    this.oceanShipments = this.oceanTrackingInfo
  },
  methods: {
    openDialog(airTrackingInfo, ocean, isEdit, status, buttonColor) {
      this.airTrackingInfo = airTrackingInfo
      this.oceanTrackingInfo = ocean
      this.isEditing = isEdit
      this.trackingStatus = status
      this.show = true
      this.btnColor = buttonColor
    },
    resetValues() {
      this.showPlaceholder = false
      this.resetForm()
    },
    hide() {
      this.show = false
    },
    skip() {
      this.hide()
    },
    trackingInfoObject() {
      let status
      if (this.trackingOrderStatus === this.orderStatus.supplyShipped) {
        status = this.orderStatus.supplyShipped
      } else {
        status = this.orderStatus.deliverShipped
      }
      if (this.trackingInformation.type.id == "air") {
        this.airShipment.orderStatus.id = status
        this.airShipments.push(this.airShipment)
      } else {
        this.oceanShipment.orderStatus.id = status
        this.oceanShipments.push(this.oceanShipment)
      }
    },
    formatUsersAccepted() {
      let obj = {
        isBrandAccepted: false,
        isRetailerAccepted: false,
        isLandingAccepted: false
      }
      if (this.isAdmin) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = true
      }
      if (this.isBrand) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = false
      }
      if (this.isRetailer) {
        obj.isBrandAccepted = false
        obj.isRetailerAccepted = true
        obj.isLandingAccepted = false
      }
      return obj
    },
    async save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        if (!this.isEditing) {
          this.trackingInfoObject()
        } else {
          this.updateTrackingInfo()
        }
        const acceptInfo = this.formatUsersAccepted()
        try {
          let order = {
            ...acceptInfo,
            id: this.order.id,
            version: Number(this.order.version) + 1,
            airShipments: this.airShipments,
            oceanShipments: this.oceanShipments,
            status: {
              id: this.orderStatus.delivered
            }
          }
          let params = {}
          if (this.$route.name == "adminBrandOrder") {
            params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
          }
          if (this.$route.name == "adminRetailerOrder") {
            params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
          }
          await this.$store.dispatch("order/updateStatus", { order, params })
          await this.$store.dispatch("order/getOrder", this.$route.params.id)
          this.hide()
        } catch (error) {
          this.hide()
          console.error(error)
        }
      }
    },
    resetForm() {
      if (this.trackingInformation.type.id == "air") {
        this.$v.airShipment.$reset()
      } else {
        this.$v.oceanShipment.$reset()
      }
      this.airShipment.provider.id = ""
      this.airShipment.trackingId = ""
      this.oceanShipment.forwarderName = ""
      this.oceanShipment.forwarderContactPhone = ""
      this.oceanShipment.provider.id = ""
      this.oceanShipment.vesselName = ""
      this.oceanShipment.houseBlNumber = ""
      this.oceanShipment.masterBlNumber = ""
      this.$v.trackingInformation.type.id.$touch()
    },
    updateTrackingInfo() {
      if (this.initialType != this.trackingInformation.type.id) {
        if (this.trackingOrderStatus == this.orderStatus.supplyShipped) {
          this.airShipments = this.airShipments.filter(
            item => item.orderStatus.id != this.orderStatus.supplyShipped
          )
          this.oceanShipments = this.oceanShipments.filter(
            item => item.orderStatus.id != this.orderStatus.supplyShipped
          )
        } else {
          this.airShipments = this.airShipments.filter(
            item => item.orderStatus.id != this.orderStatus.deliverShipped
          )
          this.oceanShipments = this.oceanShipments.filter(
            item => item.orderStatus.id != this.orderStatus.deliverShipped
          )
        }
        this.trackingInfoObject()
      }
    }
  }
}
</script>

<style lang="sass">
.tracking-content-width
  width: 100%
.tracking-information-modal
  .modal-card-body
    display: flex

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .tracking-information-modal__close-button
      top: 20px
      width: 14px
      height: 14px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 525px

  .button
      height: 30px
      font-size: 14px
      padding: 16px 20px

  &__body
    padding: 30px
    width: 525px
    display: flex
    justify-content: center

  &__buttons
    padding: 15px 20px
    display: flex
    justify-content: flex-end

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 29px
    color: #b4b4b4
    font-size: 14px
    width: 165px

  &__label
    font-size: 14px
    font-weight: 500

  &__form-container
    width: 300px
    min-height: 220px
</style>
