import productClient from "@/api/product-client"
import { formatTime } from "@/utils/validate"

export default {
  namespaced: true,

  state: {
    product: {},
    formDocuments: [],
    productsForCart: [],
    products: [],
    productPageData: {},
    productsSearchHeaders: { "x-page-count": null },
    brandProducts: [],
    ingredientsList: {},
    trainingProducts: [],
    noTrainingProducts: []
  },

  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products
    },
    SET_PRODUCT_PAGE_DATA(state, data) {
      state.productPageData = data
    },
    SET_TRAINING_PAGE_DATA(state, data) {
      state.trainingProducts = data
    },
    SET_NO_TRAINING_PAGE_DATA(state, data) {
      state.noTrainingProducts = data
    },
    SET_BRAND_PRODUCTS(state, products) {
      state.brandProducts = products
    },
    ADD_PRODUCT(state, product) {
      state.products.push(product)
    },
    REMOVE_PRODUCT(state, productId) {
      state.products = state.products.filter(product => product.id == productId)
      state.product = {}
    },
    UPDATE_PRODUCT(state, updatedProduct) {
      state.products = state.products.map(product =>
        product.id == updatedProduct.id ? updatedProduct : product
      )
      state.product = updatedProduct
    },
    ADD_DOCUMENT(state, doc) {
      state.formDocuments.push(doc)
    },
    SET_PRODUCT(state, currentProduct) {
      state.product = currentProduct
    },
    SET_PRODUCTS_FOR_CART(state, productsForCart) {
      state.productsForCart = productsForCart
    },
    SET_PRODUCTS_SEARCH_HEADERS(state, headers) {
      state.productsSearchHeaders = headers
    },
    SET_INGREDIENTS_LIST(state, list) {
      state.ingredientsList = list
    }
  },

  getters: {
    produtsSearchTotalCount(state) {
      return state.productsSearchHeaders["x-total-count"]
    },
    productsSearchPageCount(state) {
      return state.productsSearchHeaders["x-page-count"]
    },
    productsSearchMinPrice(state) {
      return state.productsSearchHeaders["x-min-msrp-usd"]
    },
    productsSearchMaxPrice(state) {
      return state.productsSearchHeaders["x-max-msrp-usd"]
    },

    getProductPageData(state) {
      console.log("....get admin product page .....")
      console.log(state.productPageData)
      // if (state.productPageData.products != null && state.productPageData.products.length > 0) {
      //   state.productPageData.products.map(item => {
      //     // item.updatedAt = formatTime(item.updatedAt)
      //     item.modified = formatTime(item.updatedAt ? item.updatedAt : item.createdAt)
      //     item.isDetele = false
      //     item.public = item.training == null ? -1: item.training.states

      //     if (item.training != null && item.training.states === 0) {
      //       item.isDraft = true
      //     } else {
      //       item.isDraft = false
      //     }
      //     return item
      //   })
      //   state.productPageData.products.sort((a, b) => {
      //     if (a.public > b.public) {
      //       return -1
      //     } else if (a.public < b.public) {
      //       return 1
      //     } else {
      //       return 0
      //     }
      //   })
      //   console.log(state.productPageData.products)
      // }
      return state.productPageData
    },
    brandTrainingProducts(state) {
      return state.trainingProducts
    },
    brandNoTrainingProducts(state) {
      return state.noTrainingProducts
    },
    allProducts(state) {
      return state.products
    },
    brandProducts(state) {
      return state.brandProducts
    },
    products(state) {
      return state.products.filter(product => product.active)
    },
    productsForCart(state) {
      return state.productsForCart
    },

    mainImageIds(state) {
      return state.products.map(
        product => product.images && product.images.find(image => image.main).assetId
      )
    },
    product(state) {
      const formSafeProduct = {
        barcodes: state.product.barcodes || [{ code: "" }],
        benifits: state.product.benifits || [{ name: "" }],
        documents: state.product.documents || []
      }

      return Object.assign(formSafeProduct, state.product)
    },
    find: state => id => {
      return state.products.find(product => product.id === id)
    },
    findBrandProduct: state => id => {
      return state.brandProducts.find(product => product.id === id)
    },
    findInCartProducts: state => id => {
      return state.productsForCart.find(product => product.id === id)
    },

    documents(state) {
      return state.formDocuments
    },
    productFormDocuments(state) {
      return state.formDocuments.map(file => {
        return { assetId: file.id, label: "fake-label" }
      })
    },
    ingredientsTag(state) {
      return state.ingredientsList
    }
  },

  actions: {
    async getProducts({ commit }, { params }) {
      try {
        const response = await productClient.getProductsByParams(params)
        if (response.data && response.data.length) {
          response.data.map(item => {
            if (!item.brandSku) {
              item.brandSku = ""
            }
          })
        }
        commit("SET_PRODUCTS", response.data)
        commit("SET_PRODUCTS_SEARCH_HEADERS", response.headers)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getNewProducts({ commit }, { params }) {
      try {
        const response = await productClient.getProductsPageDataByParams(params)
        if (response.data && response.data.length) {
          response.data.map(item => {
            if (!item.brandSku) {
              item.brandSku = ""
            }
          })
        }
        commit("SET_PRODUCTS", response.data.products)
        commit("SET_PRODUCTS_SEARCH_HEADERS", response.headers)
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getProductsByPage({ commit }, { params }) {
      try {
        const publicList = []
        const privateList = []
        const draftList = []
        const noTrainingList = []
        const trainingList = []
        const response = await productClient.getProductsPageDataByParams(params)
        if (response.data && response.data.total > 0) {
          console.log(`map start: ${new Date().getTime()}`)
          response.data.products.map(item => {
            if (!item.brandSku) {
              item.brandSku = ""
            }
            item.modified = formatTime(item.createdAt)
            item.isDetele = false
            item.public = item.training == null ? -1 : item.training.states
            item.upc =
              item.barcodes != null && item.barcodes.length > 0 ? item.barcodes[0].code : ""
            if (item.training != null && item.training.states === 0) {
              item.isDraft = true
            } else {
              item.isDraft = false
            }
            item.heroValue = 0
            if (item.training != null && item.training.hero) {
              item.heroValue = 1
            }
            if (item.public == 2) {
              publicList.push(item)
            } else if (item.public == 1) {
              privateList.push(item)
            } else if (item.public == 0) {
              draftList.push(item)
            } else {
              noTrainingList.push(item)
            }
            return item
          })
          response.data.products = []
          response.data.products.push(...publicList)
          response.data.products.push(...privateList)
          response.data.products.push(...draftList)
          response.data.products.push(...noTrainingList)
          trainingList.push(...publicList)
          trainingList.push(...privateList)
          trainingList.push(...draftList)
          console.log(trainingList.length)
          console.log(noTrainingList.length)
          console.log(`map end: ${new Date().getTime()}`)
          commit("SET_TRAINING_PAGE_DATA", trainingList)
          commit("SET_NO_TRAINING_PAGE_DATA", noTrainingList)
        }
        commit("SET_PRODUCT_PAGE_DATA", response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getBrandProducts({ commit }, { params }) {
      try {
        const response = await productClient.getProductsByParams(params)
        commit("SET_BRAND_PRODUCTS", response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getProduct({ commit }, id) {
      // TODO: implement after fields projection
      // let product = getters.find(id)
      // if (!product) {
      //   commit("ADD_PRODUCT", data)
      // }
      const { data } = await productClient.getProduct(id)
      if (!data.brandSku) {
        data.brandSku = ""
      }

      commit("SET_PRODUCT", data)
    },
    async getGuestProduct({ commit }, id) {
      const { data } = await productClient.getGuestProduct(id)
      if (!data.brandSku) {
        data.brandSku = ""
      }

      commit("SET_PRODUCT", data)
    },
    async createProduct({ commit }, productForm) {
      try {
        let benefits = productForm.newBenefits
        delete productForm.newBenefits
        if (
          productForm.newIngredients &&
          productForm.newIngredients.length === 1 &&
          productForm.newIngredients[0].id === "-1"
        ) {
          productForm.newIngredients = []
        }
        const { data } = await productClient.createProduct(productForm)
        await productClient.updateBenefits(data.id, benefits)
        commit("ADD_PRODUCT", data)
        commit("SET_PRODUCT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateProduct({ commit }, productForm) {
      try {
        if (
          productForm.newIngredients &&
          productForm.newIngredients.length === 1 &&
          productForm.newIngredients[0].id === "-1"
        ) {
          productForm.newIngredients = []
        }
        const { data } = await productClient.updateProduct(productForm)
        commit("UPDATE_PRODUCT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateBenefits({ commit }, { productId, benefits }) {
      try {
        const { data } = await productClient.updateBenefits(productId, benefits)
        commit("UPDATE_PRODUCT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateProductIngredients({ commit }, { ingredients, productId }) {
      try {
        const { data } = await productClient.updateProductIngredients(ingredients, productId)
        commit("UPDATE_PRODUCT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async removeProduct({ commit }, productId) {
      try {
        await productClient.removeProduct(productId)
        commit("REMOVE_PRODUCT", productId)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getProductsByIds({ commit }, { productIds, params = {} }) {
      try {
        let id = productIds.join(",")
        const { data } = await productClient.getProductsByParams({
          ...params,
          _limit: productIds.length,
          id
        })
        commit("SET_PRODUCTS_FOR_CART", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    loadMainImages({ getters, dispatch }) {
      return Promise.all(
        getters.mainImageIds.map(async id => {
          try {
            return await dispatch("asset/getAsset", id, { root: true })
          } catch (error) {
            return Promise.reject(error)
          }
        })
      )
    },
    async getIngredientsTag({ commit }, { ingredientName, page }) {
      const { data } = await productClient.getIngredients(ingredientName, page)
      commit("SET_INGREDIENTS_LIST", data)
    }
  }
}
