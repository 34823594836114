import brandAddressClient from "@/api/brand-address-client.js"

export default {
  namespaced: true,

  state: {
    addresses: [],
    nullAddress: {
      label: "",
      addressee: "",
      streetAddressLine_1: "",
      streetAddressLine_2: "",
      city: "",
      region: "",
      postalCode: "",
      country: {
        id: "us"
      },
      defaultShipping: false,
      hq: false
    }
  },

  getters: {
    addresses(state, getters) {
      if (getters.headquarters || getters.warehouse)
        return [getters.headquarters, getters.warehouse]
      else return []
    },
    defaultAddress(state, getters) {
      const defaultAddress = getters.addresses.find(address => address.default)
      const fallbackAddress = getters.addresses.length && getters.addresses[0]
      return defaultAddress || fallbackAddress || state.nullAddress
    },
    headquarters(state) {
      const hqAddress = state.addresses.find(address => address.hq)
      return hqAddress || Object.assign({}, state.nullAddress, { hq: true })
    },
    warehouse(state) {
      const warehouse = state.addresses.find(address => address.defaultShipping && !address.hq)
      return warehouse || Object.assign({}, state.nullAddress, { defaultShipping: true })
    }
  },

  mutations: {
    SET_ADDRESSES(state, addresses) {
      state.addresses = addresses
    }
  },

  actions: {
    async updateAll({ commit }, { addresses, brandId }) {
      try {
        const { data } = await brandAddressClient.update(brandId, addresses)
        commit("SET_ADDRESSES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAddresses({ commit }, brandId) {
      try {
        const { data } = await brandAddressClient.fetch(brandId)
        commit("SET_ADDRESSES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
