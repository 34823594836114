<template>
  <div class="admin-orders">
    <div v-if="isShowPercent" class="progress-download-tips">
      <p>Downloading</p>
      <a-progress
        :percent="percentNumber"
        :stroke-color="{ '0%': '#87d068', '100%': '#1c7430' }"
      ></a-progress>
    </div>
    <div class="modal-container">
      <a-modal v-model="visible" width="600px" @cancel="handleCancle">
        <div slot="title" class="modal-title"></div>
        <div class="modal-body">
          <div class="modal-date-select">
            <div class="modal-filter">
              <span class="modal-date-font" style="margin-right:65px;">
                Date
              </span>
              <a-range-picker
                v-model="rangepicker"
                format="MM-DD-YYYY"
                :disabled-date="disabledDate"
                @change="orderChange"
              />
            </div>
          </div>
          <div class="modal-header">
            <span class="modal-date-font">
              Status
            </span>
            <a-radio-group
              v-model="checkedList"
              :options="StatusOptions"
              @change="handleChange"
            ></a-radio-group>
          </div>
        </div>
        <div slot="footer" class="modal-footer">
          <a-button @click="handleclear">
            {{ $t("Admins.AdminOrders.clearAllFilters") }}
          </a-button>
          <a-button key="back" @click="handleCancle">
            {{ $t("Admins.AdminOrders.cancel") }}
          </a-button>
          <a-button key="submit" type="primary" @click="exportOrderExcel">
            {{ $t("Admins.AdminOrders.apply") }}
          </a-button>
        </div>
      </a-modal>
      <a-modal
        v-model="showUpload"
        width="600px"
        :footer="null"
        @cancel="handleCancelUpload"
      >
        <div class="upload-modal__body">
          <a-upload-dragger
            name="file"
            :multiple="false"
            :file-list="fileList"
            :show-upload-list="false"
            :before-upload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="cloud-upload" />
            </p>
            <p class="ant-upload-text">
              {{ $t("Admins.AdminOrders.dragUpload") }}
            </p>
            <p class="ant-upload-text">
              {{ $t("Admins.AdminOrders.or") }}
            </p>
            <a-button class="upload-modal__btn">
              {{ $t("Admins.AdminOrders.chooseFile") }}
            </a-button>
          </a-upload-dragger>
          <div v-if="fileList.length > 0" class="upload-modal__body">
            <a-row v-for="(item, index) in fileList" :key="index">
              <a-col :span="1">
                <a-icon type="paper-clip" />
              </a-col>
              <a-col :span="19">
                {{ item.name }}
              </a-col>
              <a-col :span="4">
                <a-icon type="delete" @click="handleRemove(item)" />
              </a-col>
            </a-row>
          </div>
          <div class="upload-modal__action">
            <a-button
              class="upload-modal__btn"
              :disabled="fileList.length === 0"
              :loading="uploading"
              @click="handleUpload"
            >
              {{ $t("Admins.AdminOrders.upload") }}
            </a-button>
          </div>
        </div>
      </a-modal>
      <a-modal
        v-model="uploadSuccess"
        width="400px"
        :footer="null"
        @cancel="handleCancelSuccess"
      >
        <a-result
          status="success"
          :title="$t('Admins.AdminOrders.uploadSuccess')"
        />
      </a-modal>
      <a-modal
        v-model="uploadFail"
        width="400px"
        :footer="null"
        @cancel="handleCancelFail"
      >
        <a-result status="warning" :title="$t('Admins.AdminOrders.uploadFail')">
          <template #extra>
            <a-button
              type="primary"
              class="upload-modal__body"
              @click="showErrorReport"
              >{{ $t("Admins.AdminOrders.viewReports") }}</a-button
            >
          </template>
        </a-result>
      </a-modal>
      <a-modal
        v-model="showReport"
        width="600px"
        :footer="null"
        @cancel="handleCancelReport"
      >
        <div class="upload-modal__body">
          <p class="ant-upload-text">
            <a-icon
              type="exclamation-circle"
              class="upload-modal__error-icon"
            />
            {{ $t("Admins.AdminOrders.errorTitle") }}
          </p>
          <p class="ant-upload-text" v-html="errors"></p>
        </div>
        <div style="display: flex">
          <a-button
            class="upload-modal__btn"
            :loading="isLoading"
            @click="downloadReports"
            >{{ $t("Admins.AdminOrders.orderDownload") }}</a-button
          >
        </div>
      </a-modal>
    </div>
    <div class="column">
      <!--      <div class="admin-orders-title">-->
      <!--        <p class="admin-orders__table-name">-->
      <!--          {{ $t("Admins.AdminOrders.orders") }}-->
      <!--        </p>-->
      <!--        <p>-->
      <!--          <span class="upload-modal__action-btn" @click="showUploadDialog">-->
      <!--            <a-icon type="cloud-upload" />-->
      <!--          </span>-->
      <!--          <span class="upload-modal__action-btn" @click="showModal">-->
      <!--            <a-icon type="cloud-download" />-->
      <!--          </span>-->
      <!--        </p>-->
      <!--      </div>-->
      <div class="admin-order-top-button">
        <div class="top-left-button">
          <p class="order-upload-admin" @click="showUploadDialog">
            Order Upload
          </p>
          <p
            class="order-upload-admin shipping-upload-style"
            @click="showUploadDialog('ship')"
          >
            Order Shipping Upload
          </p>
          <p
            v-if="tabId === 5"
            class="order-upload-admin search-order button-icon-style"
            @click="openSearchOrders"
          >
            <span>Search Orders</span>
            <a-icon class="search-icon-style" type="search" />
          </p>
          <p
            v-if="tabId === 5"
            class="clear-search-admin button-icon-style"
            @click="clearSearchOrders"
          >
            <span class="clear-brand-order">Clear Search</span>
          </p>
        </div>
        <div class="top-right-button">
          <div class="csv-style">
            <div class="button-icon-style" @click="isClickCSV = !isClickCSV">
              CSV<a-icon type="download" />
            </div>
            <p v-if="isClickCSV" class="csv-select">
              <span class="download-csv-select-one" @click="downloadOrderItems"
                >Download Selected</span
              >
              <span class="download-csv-select-two" @click="downloadOrder()"
                >Download All</span
              >
            </p>
          </div>
          <div v-if="tabId === 5" class="csv-style">
            <div
              class="button-icon-style"
              @click="changeStatus('deleted', true)"
            >
              Delete<a-icon type="delete" />
            </div>
          </div>
          <div v-if="tabId === 5" class="csv-style">
            <div
              :class="[canClickChangeStatus ? '' : 'can-not-click']"
              @click="clickOpenStatus"
            >
              Change Status
            </div>
            <p v-if="isChangeStatus" class="csv-select">
              <span
                v-for="item in statusText"
                :key="item.id"
                class="download-csv-select-one"
                :style="{
                  cursor: item.canClick ? 'pointer' : 'not-allowed',
                  color: item.canClick ? '#000000' : '#d9d9d9',
                }"
                @click="changeStatus(item.id, item.canClick)"
                >{{ item.name }}</span
              >
            </p>
          </div>
        </div>
      </div>

      <div class="order-processing-admin-tabs">
        <div class="admin-tabs-style">
          <span
            v-for="tab in tabText"
            :key="tab.id"
            :class="[tab.active ? 'tab-active' : '']"
            @click="handleTabs(tab)"
          >
            {{ tab.name }}
          </span>
        </div>
        <div class="field is-grouped select-text-right">
          <div class="control">
            <div class="select">
              <select v-model="value" @change="selectPagination(value)">
                <option
                  v-for="visibleItemsCount in visibleItems"
                  :key="visibleItemsCount.id"
                  :value="visibleItemsCount.number"
                  >{{ visibleItemsCount.name }}</option
                >
              </select>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="adminOrders && adminOrders.length === 0 && isShowLoading"
        class="spin-style-admin"
      >
        <a-spin tip="Loading..." />
      </div>
      <table v-else class="table is-fullwidth">
        <tbody class="admin-orders__table-header">
          <tr>
            <td class="admin-orders__table-head">
              <a-checkbox @change="onChangeAll"></a-checkbox>
            </td>
            <td class="admin-orders__table-head" min-width="150px">
              {{ tabId === 6 ? "Landing Ref ID" : "Reference ID" }}
              <!--              Reference ID-->
              <a-icon
                style="cursor: pointer"
                :type="isReferenceIdSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('landing_reference_id', '')"
              />
            </td>
            <td class="admin-orders__table-head">
              {{ $t("Admins.AdminOrders.buyer") }}
              <a-icon
                style="cursor: pointer"
                :type="isRetailerName ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('retailer_name', '')"
              />
            </td>
            <td class="admin-orders__table-head">
              {{ $t("Admins.AdminOrders.brand") }}
              <a-icon
                style="cursor: pointer"
                :type="isBrandName ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('brand_name', '')"
              />
            </td>
            <!--            Subtotal-->
            <td
              v-if="tabId === 1 || tabId === 5"
              class="admin-orders__table-head"
            >
              Subtotal
              <a-icon
                style="cursor: pointer"
                :type="isSubtotalSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('calculated_wholesale_cost_usd', '')"
              />
            </td>
            <!--            Total-->
            <td
              v-if="tabId === 2 || tabId === 3 || tabId === 4 || tabId === 6"
              class="admin-orders__table-head"
            >
              Total
              <a-icon
                style="cursor: pointer"
                :type="isTotalSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('calculated_retailer_cost_usd', '')"
              />
            </td>
            <!--            orderDate-->
            <td
              v-if="tabId === 1 || tabId === 2 || tabId === 5 || tabId === 6"
              class="admin-orders__table-head"
              width="120px"
            >
              {{ $t("Admins.AdminOrders.orderDate") }}
              <a-icon
                style="cursor: pointer"
                :type="isOrderDateSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('order_date', '')"
              />
            </td>
            <!--            Payment-->
            <td v-if="tabId === 4" class="admin-orders__table-head">
              Payment
              <!--              <a-icon-->
              <!--                style="cursor: pointer"-->
              <!--                type="caret-down"-->
              <!--                @click="sortOrderList('landing_reference_id', false)"-->
              <!--              />-->
            </td>
            <!--            Payment Date-->
            <td
              v-if="tabId === 3 || tabId === 4"
              class="admin-orders__table-head"
              width="120px"
            >
              Payment Date
              <!--              <a-icon-->
              <!--                style="cursor: pointer"-->
              <!--                type="caret-down"-->
              <!--                @click="sortOrderList('landing_reference_id', false)"-->
              <!--              />-->
            </td>
            <!--            Ship by Date-->
            <td
              v-if="tabId === 1 || tabId === 6"
              class="admin-orders__table-head"
              width="120px"
            >
              Ship by Date
              <a-icon
                style="cursor: pointer"
                :type="isShipByDateSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('ship_date', '')"
              />
            </td>
            <!--            cancelDate-->
            <td
              v-if="tabId === 1 || tabId === 6"
              class="admin-orders__table-head"
              width="120px"
            >
              {{ $t("Admins.AdminOrders.cancelDate") }}
              <a-icon
                style="cursor: pointer"
                :type="isCancelDateSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('cancel_date', '')"
              />
            </td>
            <!--            Actual Ship Date-->
            <td
              v-if="tabId === 2 || tabId === 3 || tabId === 4"
              class="admin-orders__table-head"
              width="140px"
            >
              Actual Ship Date
              <a-icon
                style="cursor: pointer"
                :type="isActualShipDateSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('ship_date', 'actual-ship-date')"
              />
            </td>
            <!--            PO Number-->
            <td class="admin-orders__table-head">
              {{ tabId === 6 ? "Reference ID" : "PO Number" }}
              <!--              PO Number-->
              <a-icon
                style="cursor: pointer"
                :type="isPONumberSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('retailer_reference_id', '')"
              />
            </td>
            <!--            Tracking Number-->
            <td v-if="tabId === 2" class="admin-orders__table-head">
              Tracking Number
              <a-icon
                style="cursor: pointer"
                :type="isTrackingNumberSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('tracking_id', '')"
              />
            </td>
            <!--            Invoice #-->
            <td
              v-if="tabId === 3 || tabId === 4 || tabId === 5"
              class="admin-orders__table-head"
            >
              Invoice #
              <a-icon
                style="cursor: pointer"
                :type="isInvoiceSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('invoice_id', '')"
              />
            </td>
            <!--            status-->
            <td
              v-if="tabId === 1 || tabId === 5 || tabId === 6"
              class="admin-orders__table-head"
            >
              {{ $t("Admins.AdminOrders.status") }}
              <a-icon
                style="cursor: pointer"
                :type="isStatusSort ? 'caret-down' : 'caret-up'"
                @click="sortOrderList('status_private_id', '')"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-for="order in adminOrders" :key="order.id">
          <td class="admin-orders__table-cell">
            <a-checkbox
              v-model="order.checkedActive"
              @change="onChangeItem(order)"
            ></a-checkbox>
          </td>
          <td class="admin-orders__table-cell old-order-table">
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ order.landingReferenceId ? order.landingReferenceId : "-" }}
            </router-link>
            <a-tooltip placement="topLeft" title="Inactive Order">
              <a-icon
                v-if="order.osvTag === 1"
                class="old-order-icon"
                type="double-left"
              />
            </a-tooltip>
          </td>
          <td class="admin-orders__table-cell">
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ retailerNameById(order.retailer.id) }}
            </router-link>
          </td>
          <td class="admin-orders__table-cell">
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ brandNameById(order.brand.id) }}
            </router-link>
          </td>
          <!--          Subtotal-->
          <td
            v-if="tabId === 1 || tabId === 5"
            class="admin-orders__table-cell"
          >
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ priceFormat(order.calculatedWholesaleCostUsd) }}
            </router-link>
          </td>
          <!--          total-->
          <td
            v-if="tabId === 2 || tabId === 3 || tabId === 4 || tabId === 6"
            class="admin-orders__table-cell"
          >
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ priceFormat(order.calculatedRetailCostUsd) }}
            </router-link>
          </td>
          <!--          order date-->
          <td
            v-if="tabId === 1 || tabId === 2 || tabId === 5 || tabId === 6"
            class="admin-orders__table-cell"
          >
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ order.orderDate }}
            </router-link>
          </td>
          <!--          Payment-->
          <td v-if="tabId === 4" class="admin-orders__table-cell">
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              ${{ formatPaymentName(order) }}
            </router-link>
          </td>
          <!--          payment date-->
          <td
            v-if="tabId === 4 || tabId === 3"
            class="admin-orders__table-cell"
          >
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ formatPaymentDate(order) }}
            </router-link>
          </td>
          <td
            v-if="tabId === 1 || tabId === 6"
            class="admin-orders__table-cell"
          >
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ order.shipDate }}
            </router-link>
          </td>
          <td
            v-if="tabId === 1 || tabId === 6"
            class="admin-orders__table-cell"
          >
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ order.cancelDate }}
            </router-link>
          </td>
          <td
            v-if="tabId === 2 || tabId === 3 || tabId === 4"
            class="admin-orders__table-cell"
          >
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ formatActualShipDate(order) }}
            </router-link>
          </td>
          <td class="admin-orders__table-cell">
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ order.retailerReferenceId ? order.retailerReferenceId : "-" }}
            </router-link>
          </td>
          <td v-if="tabId === 2" class="admin-orders__table-cell">
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              <span class="tacking-number-style">
                {{ formatTackingNumber(order) }}
              </span>
            </router-link>
          </td>
          <td
            v-if="tabId === 3 || tabId === 4 || tabId === 5"
            class="admin-orders__table-cell"
          >
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ order.invoiceId ? order.invoiceId : "-" }}
            </router-link>
          </td>
          <td
            v-if="tabId === 1 || tabId === 5"
            class="admin-orders__table-cell"
          >
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ checkOrderStatus(order) }}
            </router-link>
          </td>
          <td v-if="tabId === 6" class="admin-orders__table-cell">
            <router-link
              :to="{
                name: order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder',
                params: { id: order.id },
              }"
            >
              {{ order.status.name }}
            </router-link>
          </td>
          <!--          </router-link>-->
        </tbody>
      </table>
      <div class="table-pagination-style">
        <a-pagination
          v-model="paginationValue"
          class="inventoryPagination"
          :default-current="1"
          :default-page-size="currentNum"
          :total="total"
          size="small"
          :show-quick-jumper="true"
          @change="handlePagination"
        ></a-pagination>
      </div>
    </div>
    <ModalSearchOrder
      ref="searchOrder"
      :brand-or-retailer="setRetailersInfo"
      :admin-to-brand-info="setBrandsInfo"
      :user="3"
      @getOrderParams="getOrderParams"
    ></ModalSearchOrder>
    <TrackingInformationModal
      v-if="showTrackingInfo"
      :show="showTrackingInfo"
      :air-tracking-info="airShipments"
      :ocean-tracking-info="oceanShipments"
      :is-editing="isEditTrackingInfo"
      :tracking-status="trackingStatus"
      @hide="hideTrackingInfo"
    ></TrackingInformationModal>
  </div>
</template>

<script>
// import ProductsFilter from "@/components/filters/ProductsFilter"
import {
  exportOrderExcel,
  getOrderBrandPayAmount,
  getOrderRetailerPayAmount,
  formatLifecycleDate,
} from "@/utils/validate";
import moment from "moment";
import ModalSearchOrder from "@/components/brands/orders/modalSearchOrder";
import { downloadCsvData } from "@/utils/validate";
import { newOrderStatus } from "@/utils/const";
import constants from "@/config.js";
import TrackingInformationModal from "@/components/orders/TrackingInformationModal";

const StatusOptions = ["Order Date", "Ship Date", "Cancel Date"];
const rangepicker = [];
const checkedList = [];
export default {
  components: {
    // ProductsFilter
    ModalSearchOrder,
    TrackingInformationModal,
  },
  data() {
    return {
      statusText: newOrderStatus.slice(0, 5),
      orderStatus: constants.orderStatus,
      retailers: [],
      statuses: [],
      brands: [],
      query: {},
      isClick: false,
      checked: false,
      isChangeStatus: false,
      orderdate: "",
      type: "",
      visible: false,
      showUpload: false,
      isShiping: false,
      uploadSuccess: false,
      uploadFail: false,
      showReport: false,
      errorReports: "",
      errors: "",
      isLoading: false,
      fileList: [],
      uploading: false,
      StatusOptions,
      checkedList,
      rangepicker,
      tabText: [
        {
          id: 1,
          name: "OPEN",
          active: true,
        },
        {
          id: 2,
          name: "INVOICE",
          active: false,
        },
        {
          id: 3,
          name: "PAYMENT",
          active: false,
        },
        {
          id: 4,
          name: "COMPLETE",
          active: false,
        },
        {
          id: 5,
          name: "ALL",
          active: false,
        },
        // {
        //   id: 6,
        //   name: "HISTORY",
        //   active: false
        // }
      ],
      visibleItems: [
        { id: 1, name: "View 50", number: 50 },
        { id: 2, name: "View 100", number: 100 },
      ],
      tabId: 1,
      value: 50,
      isClickCSV: false,
      currentNum: 50,
      adminOrders: [],
      checkList: [],
      canClickChangeStatus: true,
      firstStatus: "",
      trackingStatus: "",
      showTrackingInfo: false,
      isEditTrackingInfo: false,
      airShipments: [],
      oceanShipments: [],
      isSort: true,
      sortValue: {
        "_order-by": "PREVIOUS",
        "_sort-by": "created_at",
      },
      searchInfo: {},
      paginationValue: 1,
      isShowLoading: true,
      // about sort
      isReferenceIdSort: false,
      isRetailerName: false,
      isBrandName: false,
      isSubtotalSort: false,
      isTotalSort: false,
      isOrderDateSort: false,
      isPaymentSort: false,
      isPaymentDateSort: false,
      isShipByDateSort: false,
      isCancelDateSort: false,
      isActualShipDateSort: false,
      isPONumberSort: false,
      isTrackingNumberSort: false,
      isInvoiceSort: false,
      isStatusSort: false,
      searchBrandList: [],
      searchRetailerList: [],
      setRetailersInfo: [],
      setBrandsInfo: [],
      downloadMaxNumber: 2000,
      percentNumber: 0,
      isShowPercent: false,
    };
  },
  computed: {
    // adminOrders() {
    //   return this.$store.getters["order/orders"]
    // },
    activeRetailers() {
      return this.retailers.filter((item) => item.checked);
    },
    activeStatuses() {
      return this.statuses.filter((item) => item.checked);
    },
    activeBrands() {
      return this.brands.filter((item) => item.checked);
    },
    total() {
      return this.$store.getters["order/pagination"].total;
    },
    supplyAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          (item) => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      );
    },
    supplyOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          (item) => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      );
    },
    deliveryAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          (item) => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      );
    },
    deliveryOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          (item) => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      );
    },
    // toOrderDetailLink() {
    //   return order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder'
    // }
  },
  async created() {
    this.tabId = Number(this.$route.query.tabId);
    this.tabText = this.tabText.map((item) => {
      if (item.id === this.tabId) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    this.value = Number(this.$route.query["_limit"]);
    let info = {
      ...JSON.parse(JSON.stringify(this.$route.query)),
    };
    delete info.tabId;
    if (window.name === "") {
      await this.fetchOrders(info);
      window.name = "isReload";
    } else if (window.name === "isReload") {
      info = {
        ...info,
        _index: 1,
      };
      await this.fetchOrders(info);
    }
    // this.paginationValue = this.$route.query["_index"] ? Number(this.$route.query["_index"]) : 1
    this.setRetailersInfo = await this.$store.dispatch(
      "retailer/fetchAllRetailers",
      {
        params: { fields: "name,id" },
      }
    );
    this.setBrandsInfo = await this.$store.dispatch("brand/fetchAllBrands", {
      params: { fields: "name,id" },
    });
    this.setRetailers();
    this.setBrands();
    this.setStatuses();
  },
  methods: {
    setRetailers() {
      const uniqueRetailersIds = [
        ...new Set(this.adminOrders.map((order) => order.retailer.id)),
      ];
      this.retailers = uniqueRetailersIds.map((retailerId) => {
        return {
          checked: false,
          id: retailerId,
          name: this.retailerNameById(retailerId),
        };
      });
    },

    async clearSearchOrders() {
      const status = this.checkStatusValue();
      const params = {
        _limit: this.currentNum,
        _index: 1,
        status,
        ...this.sortValue,
      };
      await this.fetchOrders(params);
    },
    showModal() {
      this.visible = true;
      this.isClick = true;
    },
    orderChange(date, dateString) {
      this.orderdate = dateString;
      this.rangepicker = [moment(this.orderdate[0]), moment(this.orderdate[1])];
    },
    setBrands() {
      const uniqueBrandsIds = [
        ...new Set(this.adminOrders.map((order) => order.brand.id)),
      ];
      this.brands = uniqueBrandsIds.map((brandId) => {
        return {
          checked: false,
          id: brandId,
          name: this.brandNameById(brandId),
        };
      });
    },
    setStatuses() {
      const uniqueStatuses = [
        ...new Set(this.adminOrders.map((order) => order.status.id)),
      ];
      this.statuses = uniqueStatuses.map((statusId) => {
        return {
          checked: false,
          id: statusId,
          name: this.statusNameById(statusId),
        };
      });
    },
    retailerNameById(retailerId) {
      const retailer = this.$store.getters["retailer/retailerById"](retailerId);
      let name = retailer && retailer.name;
      return name ? name.toString().replace(/,/g, "，") : "";
    },
    brandNameById(brandId) {
      const brand = this.$store.getters["brand/brandById"](brandId);
      let name = brand && brand.name;
      return name ? name.toString().replace(/,/g, "，") : "";
    },
    statusNameById(statusId) {
      return this.adminOrders.find((order) => order.status.id === statusId)
        .status.name;
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return numberFormat.format(price);
    },
    checkOrderStatus(order) {
      let statusText;
      if (order.declined && order.status.id === this.orderStatus.pending) {
        statusText = "Canceled";
      } else if (
        order.status.id === this.orderStatus.supplyShipped ||
        order.status.id === this.orderStatus.warehouse ||
        order.status.id === this.orderStatus.deliverShipped
      ) {
        statusText = "Delivered";
      } else {
        statusText = order.status.name;
      }
      return statusText;
    },
    priceFormatForCsv(price) {
      let priceStr = this.priceFormat(price);
      return priceStr.replace(/,/g, "，");
    },
    async fetchOrders(info) {
      this.adminOrders = [];
      this.isShowLoading = true;
      const params = this.syncQueryWithFilters();
      const data = info ? info : params;
      if (this.tabId === 5) {
        data["osv-tag"] = "1,2";
      }
      try {
        await this.$store.dispatch("order/featOrderListWithPage", {
          params: data,
        });
        this.adminOrders = this.$store.getters["order/orders"];
        this.paginationValue = data["_index"];
        this.isShowLoading = false;
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...data,
            tabId: this.tabId,
          },
        });
      } catch (error) {
        this.adminOrders = [];
      }
    },
    async sortOrderList(sortValue, otherValue) {
      this.formatSortValue(sortValue, otherValue);
      const status = this.checkStatusValue();
      let params = {
        _index: 1,
        _limit: this.currentNum,
        status,
        ...this.sortValue,
        ...this.searchInfo,
      };
      await this.fetchOrders(params);
    },
    formatSortValue(value, otherValue) {
      if (value === "landing_reference_id") {
        this.isReferenceIdSort = !this.isReferenceIdSort;
        this.sortValue = {
          "_order-by": this.isReferenceIdSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "retailer_name") {
        this.isRetailerName = !this.isRetailerName;
        this.sortValue = {
          "_order-by": this.isRetailerName ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "brand_name") {
        this.isBrandName = !this.isBrandName;
        this.sortValue = {
          "_order-by": this.isBrandName ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "calculated_wholesale_cost_usd") {
        this.isSubtotalSort = !this.isSubtotalSort;
        this.sortValue = {
          "_order-by": this.isSubtotalSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "calculated_retailer_cost_usd") {
        this.isTotalSort = !this.isTotalSort;
        this.sortValue = {
          "_order-by": this.isTotalSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "order_date") {
        this.isOrderDateSort = !this.isOrderDateSort;
        this.sortValue = {
          "_order-by": this.isOrderDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "ship_date" && otherValue === "") {
        this.isShipByDateSort = !this.isShipByDateSort;
        this.sortValue = {
          "_order-by": this.isShipByDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "cancel_date") {
        this.isCancelDateSort = !this.isCancelDateSort;
        this.sortValue = {
          "_order-by": this.isCancelDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "ship_date" && otherValue === "actual-ship-date") {
        this.isActualShipDateSort = !this.isActualShipDateSort;
        this.sortValue = {
          "_order-by": this.isActualShipDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": otherValue,
        };
      }
      if (value === "retailer_reference_id") {
        this.isPONumberSort = !this.isPONumberSort;
        this.sortValue = {
          "_order-by": this.isPONumberSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "tracking_id") {
        this.isTrackingNumberSort = !this.isTrackingNumberSort;
        this.sortValue = {
          "_order-by": this.isTrackingNumberSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "invoice_id") {
        this.isInvoiceSort = !this.isInvoiceSort;
        this.sortValue = {
          "_order-by": this.isInvoiceSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
      if (value === "status_private_id") {
        this.isStatusSort = !this.isStatusSort;
        this.sortValue = {
          "_order-by": this.isStatusSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
        };
      }
    },
    syncQueryWithFilters(value, isSort) {
      this.query["_limit"] = this.currentNum;
      // when tab is OPEN status
      this.query.status = "pending,confirmed";
      this.query["_order-by"] = "PREVIOUS";
      this.query["_sort-by"] = "created_at";
      if (value) {
        this.query["_order-by"] = isSort ? "NEXT" : "PREVIOUS";
        this.query["_sort-by"] = value;
      }
      return this.query;
    },
    handleCancle() {
      this.visible = false;
      this.checkedList = [];
      this.orderdate = "";
      this.isClick = false;
      this.rangepicker = [];
    },
    showUploadDialog(value) {
      this.showUpload = true;
      this.isShiping = value === "ship";
      this.fileList = [];
    },
    openSearchOrders() {
      this.$refs["searchOrder"].openDialog();
    },
    handleCancelUpload() {
      this.showUpload = false;
      this.isShiping = false;
      this.fileList = [];
    },
    handleCancelSuccess() {
      this.uploadSuccess = false;
    },
    handleCancelFail() {
      this.uploadFail = false;
    },
    handleCancelReport() {
      this.showReport = false;
    },
    showErrorReport() {
      this.handleCancelFail();
      this.showReport = true;
    },
    isCsv(name) {
      if (name) {
        let index = name.lastIndexOf(".");
        let ext = name.substr(index + 1);
        if (ext.toLowerCase() === "csv") {
          return true;
        }
      }
      return false;
    },
    handleUpload() {
      if (this.fileList && this.fileList.length > 0) {
        let isFormatRight = true;
        for (let i = 0; i < this.fileList.length; i++) {
          let name = this.fileList[i].name;
          isFormatRight = this.isCsv(name);
          if (!isFormatRight) {
            let info = this.$t("Admins.AdminOrders.uploadFailInfo");
            this.$message.error(info);
            return;
          }
        }
        this.doUpload(this.fileList[0]);
      }
    },
    doUpload(file) {
      let resUrl;
      if (this.isShiping) {
        resUrl = "order/uploadShipOrder";
      } else {
        resUrl = "order/uploadOrder";
      }
      let fileReader = new FileReader();
      const formData = new FormData();
      fileReader.readAsText(file);
      fileReader.onloadend = (e) => {
        let data = new Blob([e.target.result], { type: "text/csv" });
        formData.append("file", data, file.name);
        this.uploading = true;
        this.$store
          .dispatch(`${resUrl}`, formData)
          .then((res) => {
            if (res.states) {
              this.uploadSuccess = true;
            } else if (res.reports) {
              this.errorReports = "";
              this.errors = "";
              res.reports.forEach((item) => {
                let index = item.indexOf("cause:");
                if (index > 0) {
                  this.errorReports += `${item.substring(0, index)}\n`;
                  this.errors += `${item.substring(0, index)}<br/>`;
                } else {
                  this.errorReports += `${item}\n`;
                  this.errors += `${item}<br/>`;
                }
              });
              this.showErrorReport();
            }
            this.handleCancelUpload();
          })
          .catch((error) => {
            this.errors = "";
            if (error.message) {
              this.errors = error.message;
              this.errorReports = error.downloadMessage;
              this.showErrorReport();
              this.handleCancelUpload();
            } else {
              let info = this.$t("Admins.AdminOrders.uploadFailInfo");
              this.$message.error(info);
            }
            console.log(error);
          })
          .finally(() => {
            this.uploading = false;
            this.isShiping = false;
          });
      };
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [];
      this.fileList.push(file);
      return false;
    },
    handleclear() {
      this.checkedList = [];
      this.rangepicker = [];
      this.orderdate = "";
    },
    handleChange(e) {
      switch (e.target.value) {
        case "Cancel Date":
          this.type = "cancel-date";
          break;
        case "Ship Date":
          this.type = "ship-date";
          break;
        case "Order Date":
          this.type = "order-date";
          break;
      }
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    moment,
    // Export order list to excel
    async exportOrderExcel(params) {
      this.visible = false;
      this.isShowPercent = true;
      this.percentNumber = 50;
      // const range =
      //   moment(this.orderdate[0]).format("YYYY-MM-DD") +
      //   "," +
      //   moment(this.orderdate[1]).format("YYYY-MM-DD")
      const blob = await this.$store.dispatch("order/downloadOrderExcel", {
        params: params,
      });
      this.percentNumber = 100;
      setTimeout(() => {
        let fileName = `brand_order_list_${new Date().getTime()}.xls`;
        let aLink = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //Create a link to download
        aLink.href = href;
        aLink.download = fileName; //fileName+time
        document.body.appendChild(aLink);
        aLink.click(); //click to download
        document.body.removeChild(aLink); //Remove the element after downloading
        window.URL.revokeObjectURL(blob); //Release the blob object
        this.isClick = false;
        this.isShowPercent = false;
      }, 1000);
      clearTimeout();
    },
    downloadReports() {
      let data = new Blob([this.errorReports]);
      let fileName = `upload_order_error_reports_${moment().format(
        "YYYY-MM-DD HH23:mm:ss"
      )}`;
      exportOrderExcel(data, fileName).finally(() => {
        this.isLoading = false;
        this.handleCancelReport();
      });
    },
    async handleTabs(tabInfo) {
      this.tabText = this.tabText.map((item) => {
        if (item.id === tabInfo.id) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });
      this.tabId = tabInfo.id;
      this.searchInfo = null;
      const status = this.checkStatusValue();
      //paid-to-brand
      let params = {
        _index: 1,
        _limit: this.currentNum,
        status,
        "_order-by": "PREVIOUS",
        "_sort-by": "created_at",
      };
      await this.fetchOrders(params);
    },
    async selectPagination(currentInfo) {
      this.currentNum = currentInfo;
      const status = this.checkStatusValue();
      const params = {
        _limit: this.currentNum,
        _index: 1,
        status,
        ...this.sortValue,
        ...this.searchInfo,
      };
      await this.fetchOrders(params);
      // await this.$store.dispatch("order/featOrderListWithPage", {params})
      // this.brandOrders = this.$store.getters["order/orders"].filter(item => item.status.id !== "deleted")
    },
    async downloadOrder() {
      // let list = arr && arr.length !== 0 ? arr : this.adminOrders
      // await downloadCsvData(
      //   this.downloadStrForOrder(arr),
      //   `brand_order_list_${new Date().getTime()}.csv`
      // )
      const state = this.checkStatusValue();
      let params = {
        _limit: this.total,
        status: state,
        ...this.sortValue,
        ...this.searchInfo,
      };
      if (this.tabId === 5) {
        params["osv-tag"] = "1,2";
      }
      await this.exportOrderExcel(params);
    },
    getLimitInfo(total) {
      let result = [];
      for (let i = 0; i < Math.ceil(total / this.downloadMaxNumber); i++) {
        const info = {
          _index: i + 1,
          _limit: this.downloadMaxNumber,
        };
        result.push(info);
      }
      return result;
    },
    async downloadStrForOrder(arr) {
      let list = arr && arr.length !== 0 ? arr : this.adminOrders;
      let result;
      if (this.total > this.downloadMaxNumber) {
        this.isShowPercent = true;
        const status = this.checkStatusValue();
        const arr = this.getLimitInfo(this.total);
        const number = Math.ceil(this.total / this.downloadMaxNumber);
        let price = 100 / number;
        this.percentNumber = 10;
        for (let i = 0; i < arr.length; i++) {
          const params = {
            ...arr[i],
            status,
            ...this.sortValue,
          };
          if (this.tabId === 5) {
            params["osv-tag"] = "1,2";
          }
          await this.$store
            .dispatch("order/downloadOrderList", { params })
            .then(async (res) => {
              result = this.downloadItemList(res);
              await downloadCsvData(
                result,
                `brand_order_list_${new Date().getTime()}_${i}.csv`
              );
            });
          price = (i + 1) * price;
          if (price >= 95) {
            price = 90;
          }
          this.percentNumber = price;
        }
        this.percentNumber = 100;
        setTimeout(() => {
          this.isShowPercent = false;
        }, 1000);
        return;
      }
      return this.downloadItemList(list);
    },
    downloadItemList(list) {
      if (list && list.length > 0) {
        if (this.tabId === 1) {
          return this.getOpenedOrderDownloadData(list);
        } else if (this.tabId === 2) {
          return this.getInvoicedOrderDownloadData(list);
        } else if (this.tabId === 3) {
          return this.getPaidOrderDownloadData(list);
        } else if (this.tabId === 4) {
          return this.getCompleteOrderDownloadData(list);
          // } else if (this.tabId === 6) {
          //   return this.getHistoryOrderDownloadData(list)
        } else {
          return this.getAllOrderDownloadData(list);
        }
      }
    },
    getOpenedOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Subtotal, Order Date , Ship by Date, Cancel Date, PO Number, Status",
      ];
      for (let item of list) {
        let str = `${
          item.landingReferenceId ? item.landingReferenceId : "-"
        }, ${this.retailerNameById(item.retailer.id)}, ${this.brandNameById(
          item.brand.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate
        }, ${item.shipDate}, ${item.cancelDate}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.status.name}`;
        csvData.push(str);
      }
      return csvData;
    },
    getInvoicedOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Total, Order Date , Actural Ship Date, PO Number, Tracking Number",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedRetailCostUsd
        )}, ${item.orderDate ? item.orderDate : "-"}, ${
          item.shipDate ? item.shipDate : "-"
        }, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${this.formatTackingNumber(item)}`;
        csvData.push(str);
      }
      return csvData;
    },
    getPaidOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Total, Payment Date, Actural Ship Date , PO Number, Invoice #",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedRetailCostUsd
        )},${this.formatPaymentDate(item)}, ${
          item.shipDate ? item.shipDate : "-"
        },  ${item.retailerReferenceId ? item.retailerReferenceId : "-"}, ${
          item.invoiceId ? item.invoiceId : "-"
        }`;
        csvData.push(str);
      }
      console.log(csvData);
      return csvData;
    },
    getCompleteOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Total, Payment, Payment Date, Actural Ship Date, PO Number, Invoice #",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedRetailCostUsd
        )}, $${this.formatPaymentName(item)}, ${this.formatPaymentDate(
          item
        )}, ${item.shipDate ? item.shipDate : "-"},${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.invoiceId ? item.invoiceId : "-"}`;
        csvData.push(str);
      }
      return csvData;
    },
    getHistoryOrderDownloadData(list) {
      let csvData = [
        " Landing Ref ID, Retailer, Brand, Total, order Date, Ship Date, Cancel Date, Reference ID, status",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedRetailCostUsd
        )}, ${item.orderDate ? item.orderDate : "-"}, ${
          item.shipDate ? item.shipDate : "-"
        }, ${item.cancelDate}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.status && item.status.name ? item.status.name : "-"}`;
        csvData.push(str);
      }
      return csvData;
    },
    getAllOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Subtotal, Order Date , PO Number, Invoice #, Status",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedWholesaleCostUsd
        )}, ${item.orderDate ? item.orderDate : "-"}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.invoiceId ? item.invoiceId : "-"}, ${
          item.status && item.status.name ? item.status.name : "-"
        }`;
        csvData.push(str);
      }
      return csvData;
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value);
        return new Intl.DateTimeFormat("en-US").format(date);
      }
      return " ";
    },
    async downloadOrderItems() {
      const state = this.checkStatusValue();
      let list;
      if (this.isAllActive) {
        list = this.adminOrders;
      } else {
        list = this.checkList;
      }
      const listText = list.map((item) => item.id).join(",");
      let params = {
        _limit: this.total,
        status: state,
        ...this.sortValue,
        ...this.searchInfo,
        id: listText,
      };
      await this.exportOrderExcel(params);
      // let list
      // if (this.isAllActive) {
      //   list = this.adminOrders
      // } else {
      //   list = this.checkList
      // }
      // await downloadCsvData(
      //   this.downloadItemList(list),
      //   `brand_order_list_${new Date().getTime()}.csv`
      // )
    },
    checkStatusValue() {
      let status;
      // OPEN
      if (this.tabId === 1) {
        status = "pending,confirmed";
      }
      // INVOICE
      if (this.tabId === 2) {
        status = "shipped-supply,warehouse,shipped-delivery,delivered,invoiced";
      }
      // PAID
      if (this.tabId === 3) {
        status = "paid-by-retailer";
      }
      // PAID
      if (this.tabId === 4) {
        status = "paid-to-brand";
      }
      if (this.tabId === 5) {
        status = null;
      }
      return status;
    },
    async handlePagination(page) {
      const state = this.checkStatusValue();
      this.paginationValue = page;
      let params = {
        _index: page,
        _limit: this.currentNum,
        status: state,
        ...this.sortValue,
        ...this.searchInfo,
      };
      await this.fetchOrders(params);
    },
    async getOrderParams(obj, searchInfo) {
      const params = obj;
      this.searchInfo = searchInfo;
      await this.fetchOrders(params);
    },
    clickToOrderDetail(order) {
      this.$router.push({
        name: "adminOrder",
        params: { id: order.id },
      });
    },
    onChangeItem(order) {
      this.canClickChangeStatus = true;
      this.adminOrders = this.adminOrders.map((item) => {
        if (item.id === order.id) {
          item.checkedActive = order.checkedActive;
        }
        return item;
      });
      this.checkList = this.adminOrders.filter(
        (item) => item.checkedActive === true
      );
      if (this.checkList && this.checkList.length === 0) {
        return;
      }
      this.firstStatus = this.checkList[0].status.id;
      if (this.checkList && this.checkList.length >= 2) {
        const otherStatusList = this.checkList.filter(
          (item) => item.status.id !== this.firstStatus
        );
        if (otherStatusList && otherStatusList.length === 0) {
          this.canClickChangeStatus = true;
        } else {
          this.canClickChangeStatus = false;
          this.isChangeStatus = false;
        }
      }
      const nextClickStatus =
        (this.statusText &&
          this.statusText.filter((item) => item.id === this.firstStatus)[0]
            .nextStatus) ||
        "";
      this.statusText =
        this.statusText &&
        this.statusText.map((item) => {
          if (item.id === nextClickStatus) {
            item.canClick = true;
          } else {
            item.canClick = false;
          }
          return item;
        });
    },
    clickOpenStatus() {
      if (this.canClickChangeStatus) {
        this.isChangeStatus = !this.isChangeStatus;
      }
    },
    onChangeAll() {
      this.isAllActive = !this.isAllActive;
      this.adminOrders = this.adminOrders.map((item) => {
        item.checkedActive = this.isAllActive;
        return item;
      });
    },
    deleteOrder() {},
    async changeStatus(orderStatus, canClick) {
      if (!canClick) {
        return;
      }
      if (this.checkList && this.checkList.length === 0) {
        this.$notification.open({
          message: "Prompt information",
          description: "Please select the Order that you want to modify",
        });
        return;
      }
      if (orderStatus === "delivered") {
        this.showUploadDialog("ship");
        return;
      }
      let list;
      if (this.isAllActive) {
        list = this.adminOrders;
      } else {
        list = this.checkList;
      }
      if (orderStatus === this.orderStatus.deleted) {
        for (let i = 0; i < list.length; i++) {
          let order = {
            id: list[i].id,
            status: {
              id: orderStatus,
            },
            version: Number(list[i].version) + 1,
            isBrandAccepted: true,
            isRetailerAccepted: false,
          };
          let params = {};
          await this.$store.dispatch("order/updateStatus", { order, params });
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          let order = {
            id: list[i].id,
            status: {
              id: orderStatus,
            },
            version: Number(list[i].version) + 1,
            isBrandAccepted: true,
            isRetailerAccepted: false,
          };
          let params = {};
          await this.$store.dispatch("order/updateStatus", { order, params });
        }
      }
      await this.fetchOrders();
    },
    // Choose the tracking number
    formatTackingNumber(item) {
      let num;
      if (item.airShipments) {
        num = item.airShipments[0].trackingId;
      }
      // else if (item.oceanShipments) {
      //   num = item.oceanShipments[0].trackingId
      // }
      else {
        num = "-";
      }
      return num;
    },
    formatPaymentDate(item) {
      const finalArr = item.lifecycle.slice(-1);
      if (finalArr[0].complete) {
        return formatLifecycleDate(finalArr[0].updatedAt);
      } else if (item.lifecycle && item.lifecycle.length >= 2) {
        const list = item.lifecycle.filter((item) => item.complete).slice(-1);
        return list[0].transitionedAt
          ? formatLifecycleDate(list[0].transitionedAt)
          : formatLifecycleDate(list[0].updateAt);
      } else {
        return "-";
      }
    },
    formatPaymentName(item) {
      const total =
        getOrderRetailerPayAmount(item) - getOrderBrandPayAmount(item);
      return total.toFixed(2);
    },
    formatActualShipDate(orderItem) {
      if (
        orderItem &&
        orderItem.airShipments &&
        orderItem.airShipments.length !== 0
      ) {
        return orderItem.airShipments[0].actualShipDate;
      } else {
        return "--";
      }
    },
    async showTrackingInfoModal() {
      if (this.role == "brand") {
        this.trackingStatus = this.orderStatus.supplyShipped;
      } else if (
        this.role == "admin" &&
        (this.supplyAirTrackingInfo || this.supplyOceanTrackingInfo)
      ) {
        this.trackingStatus = this.orderStatus.deliverShipped;
      } else if (
        this.role == "admin" &&
        (this.deliveryAirTrackingInfo || this.deliveryOceanTrackingInfo)
      ) {
        this.trackingStatus = this.orderStatus.deliverShipped;
      } else {
        this.trackingStatus = "";
      }
      this.airShipments = this.checkList[0].airShipments && [
        ...this.order.airShipments.map((item) => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            trackingId: item.trackingId,
          };
        }),
      ];
      this.oceanShipments = this.checkList[0].oceanShipments && [
        ...this.order.oceanShipments.map((item) => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            ...item,
          };
        }),
      ];
      await this.$store.dispatch("order/getOrder", this.checkList[0].id);
      this.isEditTrackingInfo = false;
      this.showTrackingInfo = true;
    },
    hideTrackingInfo() {
      this.showTrackingInfo = false;
      this.isEditTrackingInfo = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.ant-btn:last-child
  background: #4A8A5D
  border: 1px solid transparent

.modal-body
  width: 100%
  height: 105px
  padding: 0 24px

  .modal-header
    padding: 24px 0 0 0

  .modal-filter
    margin-bottom: 20px

  .modal-date-select
    border-bottom: 1px solid #e8e8e8

  .modal-body-footer
    padding-top: 24px

  .modal-date-font
    margin-right: 60px
    font-family: 'Poppins-Bold'
    font-size: 15px
    font-weight: 500

.modal-footer
  position: relative

  button:first-child
    position: absolute
    left: 0


.modal-title
  height: 27px

.admin-orders
  padding: 20px 20px 0 30px
  font-family: "Proxima"
  .progress-download-tips
    width: 400px
    height: 100px
    padding: 20px 25px
    text-align: center
    position: fixed
    top: 60px
    left: 35%
    background-color: white
    p
      margin: 0 auto
  .upload-csv-progress-style
    width: 300px
    position: fixed
    top: 100px
    left: 40%

  &__filter
    padding-right: 70px
    padding-top: 10px

  &__table-name
    color: #000000
    font-size: 20px
    font-weight: 700
    letter-spacing: 0.25px
    padding-left: 15px
    margin: 0px !important

  &__table-head
    color: #9E9E9E !important
    font-size: 12px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important
    cursor: pointer

  &__table-cell
    color: #333333 !important
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important
    margin-top: 10px
    position: relative
    a
      color: #333333
  .tacking-number-style
    display: inline-block
    width: 200px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

  &__table-row
    cursor: pointer

  &__filter-column-name
    color: #292929
    font-size: 16px
    font-weight: 300
    margin-bottom: 15px

  &__border
    border-bottom: 1px solid #E0E0E0

  .admin-orders-title
    display: flex
    justify-content: space-between
    align-items: center

    .admin-orders__download
      width: 142px
      height: 42px
      border-radius: 5px
      background: #4A8A5D
      text-align: center
      font-family: Proxima Nova
      font-size: 18px
      font-weight: 400
      line-height: 42px
      color: #ffffff
      cursor: pointer

    .click-btn
      background: rgba(0, 0, 0, .3)
      width: 142px
      height: 42px
      border-radius: 5px
      text-align: center
      font-family: Proxima Nova
      font-size: 18px
      font-weight: 400
      line-height: 42px
      color: #000000
      cursor: not-allowed

.upload-modal
  &__body
    margin-top: 16px

  &__action
    margin-top: 16px
    display: flex

  &__btn
    font-family: "Proxima Nova"
    font-weight: normal
    font-size: 14px
    margin: 0 auto
    padding: 4px 20px
    color: white !important

  &__error-icon
    color: red
    margin-right: 10px

  &__action-btn
    padding: 4px 20px
    border-radius: 2px
    background-color: #4A8A5D
    font-size: 24px
    color: white
    cursor: pointer
    margin-right: 20px
.order-processing-admin-tabs
  margin-bottom: 3px
  margin-top: 100px
  display: flex
  justify-content: space-between
  .admin-tabs-style
    padding-top: 22px
  span
    padding: 4px 18px
    border: 1px solid white
    cursor: pointer
  .tab-active
    border: 1px solid gray
    border-bottom: none

.ant-upload-text
  font-family: "Proxima Nova"

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon
  color: #4A8A5D

//.ant-upload-list-item-name
  margin-left: 10px !important
  width: 70% !important
.admin-order-top-button
  display: flex
  justify-content: space-between
  text-align: center
  p
    margin-bottom: 0
  .order-upload-admin
    width: 140px
    height: 30px
    cursor: pointer
    line-height: 30px
    background: #FFFAF9
    border: 1px solid #FF6132
    color: #FF6132
    span
      margin-right: 5px
  .shipping-upload-style
    width: 180px
    margin-left: 10px
  .search-order
    margin-left: 10px
  .top-right-button
    display: flex
    justify-content: flex-start
  .csv-style
    width: 150px
    height: 30px
    line-height: 30px
    text-align: center
    border: 1px solid #FF6132
    background-color: #FFFAF9
    color: #FF6132
    cursor: pointer
    position: relative
    margin-right: 10px
    .csv-select
      position: absolute
      top: 30px
      left: -1px
      z-index: 1000
      border: 1px solid #D9D9D9
      background-color: white
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
      .download-csv-select-one
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-one:hover
        background-color: #FFFAF9
      .download-csv-select-two
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-two:hover
        background-color: #FFFAF9
  .top-left-button
    display: flex
    justify-content: flex-start
  .button-icon-style
    display: flex
    align-items: center
    justify-content: center
  .clear-search-admin
    color: #FF6132
    margin-left: 18px
  //.admin-orders__table-name
  //  color: #FF6132
  //  font-size: 14px
  //  width: 172px
  //  height: 30px
  //  line-height: 30px
  //  text-align: center
  //  cursor: pointer
  //  .search-brand-order
  //    border: 1px solid #FF6132
  //    border-radius: 2px
  //    background-color: #FFFAF9
  .can-not-click
    cursor: not-allowed
    background-color: #D9D9D9
    color: white
    border-color: #d9d9d9
  .do-not-click
    cursor: not-allowed
.spin-style-admin
  text-align: center
  margin: 40px 0
//display: flex
//justify-content: center
//align-items: center
//.ant-spin
//  color: #ff6132 !important
//.ant-spin-dot-item
//  background-color: #ff6132 !important
.old-order-table
  position: relative
  .old-order-icon
    position: absolute
    left: -4px
    top: 19px
    color: #1c7430
.table-pagination-style
  text-align: right
  margin-bottom: 30px

.clear-brand-order
  text-decoration: underline
  cursor: pointer
</style>
