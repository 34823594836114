<template>
  <div>
    <a-modal
      v-model="isShow"
      :footer="null"
      :destroy-on-close="true"
      class="is-active admin-retailer-type-dialog"
      width="601px"
      :body-style="noPadding"
    >
      <template #closeIcon>
        <img
          class="request-terms-modal__close-button"
          src="@/assets/img/icon/close-icon.svg"
          @click="closeDialog"
        />
      </template>
      <p class="request-terms-modal__header no-margin">
        {{ $t("Retailers.RetailerSettings.editType") }}
      </p>
      <div class="section modal-card-body subscription__form-block padding-small">
        <div class="modal-content">
          <a-row class="row-margin-bottom">
            <p class="change-plan-title">{{ $t("Retailers.RetailerSettings.changeTypeTitle") }}</p>
            <a-radio-group v-model="initType" @change="onChangeType">
              <template v-for="item in retailerTypes">
                <a-radio :key="item.name" class="modal-radio" :value="item.name">
                  {{ item.description }}
                </a-radio>
              </template>
            </a-radio-group>
          </a-row>
        </div>
        <div class="modal-card-foot modal-actions">
          <a class="button is-white" @click="closeDialog">
            {{ $t("Brands.BrandSettings.cancel") }}
          </a>
          <a-button
            class="admin-retailer-type-dialog__save-btn"
            :loading="isDoAction"
            @click="saveAction"
          >
            {{ $t("Brands.BrandSettings.save") }}
          </a-button>
        </div>
      </div>
    </a-modal>
    <training-info-dialog ref="retailerChangeTypeWarningInfo" />
  </div>
</template>
<script>
import TrainingInfoDialog from "@/components/training/TrainingInfoDialog"
export default {
  components: {
    TrainingInfoDialog
  },
  data() {
    return {
      isShow: false,
      isDoAction: false,
      initType: undefined,
      newRetailerType: undefined,
      noPadding: {
        padding: "0px"
      }
    }
  },
  computed: {
    retailerTypes() {
      return this.$store.getters["reference/retailerTypes"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    }
  },
  methods: {
    openDialog(type) {
      this.initType = type
      this.isShow = true
    },
    onChangeType(e) {
      console.log(`.....onChange type : ${e}`)
      console.log(e)
      if (e.target.value === "preferred") {
        this.$refs["retailerChangeTypeWarningInfo"].openDialog(
          "",
          "Retailers cannot be downgraded to General.",
          "Close"
        )
      }
    },
    saveAction() {
      console.log("....change type....")
      if (
        this.retailer.grades &&
        this.retailer.grades.length > 0 &&
        this.retailer.grades[0].type.id === this.initType
      ) {
        this.closeDialog()
      } else {
        this.isDoAction = true
        let type = []
        if (!this.retailer.grades || (this.retailer.grades && this.retailer.grades.length === 0)) {
          type.push({ type: { id: this.initType }, active: true })
        } else {
          type.push({
            privateId: this.retailer.grades[0].privateId,
            retailerPrivateId: this.retailer.grades[0].retailerPrivateId,
            type: { id: this.initType },
            active: true
          })
        }

        let data = {
          id: this.retailer.id,
          name: this.retailer.name,
          shortName: this.retailer.shortName,
          status: this.retailer.status,
          grades: type
        }
        this.$store
          .dispatch("retailer/updateRetailerPartially", data)
          .then(() => {
            this.$message.success("Update retailer type success")
            this.closeDialog()
          })
          .catch(err => {
            let msg = err.message
            this.isDoAction = false
            this.$message.error("Update retailer type failed. Error:" + msg ? msg : "")
          })
      }
    },
    closeDialog() {
      this.isShow = false
      this.isDoAction = false
    }
  }
}
</script>
<style lang="scss">
.admin-retailer-type-dialog {
  &__save-btn {
    background-color: #000 !important;
    border-color: transparent;
    color: #fff !important;
    justify-content: center;
    padding-bottom: calc(0.375em - 1px);
    padding-left: 0.75em;
    padding-right: 0.75em;
    padding-top: calc(0.375em - 1px);
    text-align: center;
    white-space: nowrap;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-box-align: center;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
  }
}
.admin-retailer-type-dialog__save-btn.ant-btn:hover,
.admin-retailer-type-dialog__save-btn.ant-btn:active,
.admin-retailer-type-dialog__save-btn.ant-btn:focus {
  background: #000;
  color: #fff;
  border-color: transparent;
}
</style>
