<template lang="pug">
.modal.old-tracking-information-modal(:class="{ 'is-active': show }")
  .modal-background
  .modal-card.old-tracking-information-modal__content
    header.old-tracking-information-modal__header Enter Tracking Info
      img.old-tracking-information-modal__close-button(src="@/assets/img/icon/close-icon.svg" @click="hide")
    section.modal-card-body
      .old-tracking-information-modal__body
        .old-tracking-information-modal__form-container
          .field(v-if="role=='admin' && !isEditing")
            label.label.old-tracking-information-modal__label Shipping Option *
            .control
              .select.is-fullwidth(:class="{'is-danger': $v.trackingOrderStatus.$error }")
                select(v-model="$v.trackingOrderStatus.$model" :disabled="trackingStatus != ''")
                  option(:value="orderStatus.deliverShipped") Domestic
                  option(:value="orderStatus.supplyShipped") International
                .old-tracking-information-modal__select-placeholder(v-if="!trackingOrderStatus") Select shipping method
              p.help.is-danger(v-if="$v.trackingOrderStatus.$error") Required
          .field
            label.label.old-tracking-information-modal__label Shipping Method *
            .control
              .select.is-fullwidth(:class="{'is-danger': $v.trackingInformation.type.id.$error }")
                select(v-model="$v.trackingInformation.type.id.$model" @change="resetValues()")
                  option(v-for="item in shipmentType" :key="item.id" :value="item.id") {{item.name}}
                .old-tracking-information-modal__select-placeholder(v-if="!trackingInformation.type.id") Select shipping method
              p.help.is-danger(v-if="$v.trackingInformation.type.id.$error") Required
          div(v-if="trackingInformation.type.id==='air'")
            .field
              label.label.old-tracking-information-modal__label Shipping Carrier *
              //- .control
                input.input( placeholder="Enter shipping carrier" type = "text"
                      v-model.trim="$v.trackingInformation.shippingCarrier.$model"
              //:class="{'is-danger': $v.trackingInformation.shippingCarrier.$error }")
  .control
  .select.is-fullwidth(:class="{'is-danger': $v.airShipment.provider.id.$error }")
  select(v-model="$v.airShipment.provider.id.$model")
  option(v-for="item in airProviders" :key="item.id" :value="item.id") {{item.name}}
  .old-tracking-information-modal__select-placeholder(v-if="!airShipment.provider.id") Select shipping carrier
  p.help.is-danger(v-if="$v.airShipment.provider.$error") Required
  .field
    label.label.old-tracking-information-modal__label Tracking Number *
    .control
      input.input( placeholder="Enter tracking number" type = "text"
        v-model.trim="$v.airShipment.trackingId.$model"
        :class="{'is-danger': $v.airShipment.trackingId.$error }")
    p.help.is-danger(v-if="$v.airShipment.trackingId.$error") Required
  div(v-if="trackingInformation.type.id==='ocean'")
    .field
      label.label.old-tracking-information-modal__label Forwarder Name *
      .control
        input.input( placeholder="Enter forwarder name" type = "text"
          v-model.trim="$v.oceanShipment.forwarderName.$model"
          :class="{'is-danger': $v.oceanShipment.forwarderName.$error }")
      p.help.is-danger(v-if="$v.oceanShipment.forwarderName.$error") Required
    .field
      label.label.old-tracking-information-modal__label Forwarder Contact Number (optional)
      .control
        input.input( placeholder="Enter forwarder name" type = "text" v-model.trim="$v.oceanShipment.forwarderContactPhone.$model")
    .field
      label.label.old-tracking-information-modal__label Vessel Company *
      .control
        .select.is-fullwidth(:class="{'is-danger': $v.oceanShipment.provider.id.$error }")
          select(v-model="$v.oceanShipment.provider.id.$model")
            option(v-for="item in oceanProviders" :key="item.id" :value="item.id") {{item.name}}
          .old-tracking-information-modal__select-placeholder(v-if="!oceanShipment.provider.id") Select vessel company
        p.help.is-danger(v-if="$v.oceanShipment.provider.$error") Required
    .field
      label.label.old-tracking-information-modal__label Vessel Name (optional)
      .control
        input.input( placeholder="Enter vessel name (optional)" type = "text" v-model.trim="$v.oceanShipment.vesselName.$model")
    .field
      label.label.old-tracking-information-modal__label House B/L Number *
      .control
        input.input( placeholder="Enter house B/L number" type = "text"
          v-model.trim="$v.oceanShipment.houseBlNumber.$model"
          :class="{'is-danger': $v.oceanShipment.houseBlNumber.$error }")
      p.help.is-danger(v-if="$v.oceanShipment.houseBlNumber.$error") Required
    .field
      label.label.old-tracking-information-modal__label Master B/L Number (optional)
      .control
        input.input( placeholder="Enter master B/L number (optional)" type = "text"
          v-model.trim="$v.oceanShipment.masterBlNumber.$model")
  .modal-card-foot.old-tracking-information-modal__buttons
    button.button(@click="skip") Skip
    button.button.is-black(@click="save" :disabled="!$v.$anyDirty")  Save
</template>

<script>
import { required } from "vuelidate/lib/validators"
import constants from "@/config.js"
export default {
  props: {
    show: { type: Boolean, default: false },
    airTrackingInfo: { type: Array, default: () => [] },
    oceanTrackingInfo: { type: Array, default: () => [] },
    isEditing: { type: Boolean, default: false },
    trackingStatus: { type: String, default: "" }
  },
  data() {
    return {
      initialType: "",
      airShipments: [],
      oceanShipments: [],
      orderStatus: constants.orderStatus,
      showPlaceholder: true,
      trackingInformation: {
        type: {
          id: "",
          name: ""
        },
        options: {
          id: "",
          name: ""
        }
      },
      airShipment: {
        provider: {
          id: "",
          name: ""
        },
        orderStatus: {
          id: ""
        },
        trackingId: ""
      },
      oceanShipment: {
        forwarderName: "",
        forwarderContactPhone: "",
        provider: {
          id: "",
          name: ""
        },
        orderStatus: {
          id: ""
        },
        vesselName: "",
        houseBlNumber: "",
        masterBlNumber: ""
      },
      trackingOrderStatus: ""
    }
  },
  validations() {
    if (this.trackingInformation.type.id == "air") {
      return {
        trackingOrderStatus: this.role == "admin" ? { required } : {},
        trackingInformation: {
          type: {
            id: { required }
          }
        },
        airShipment: {
          provider: {
            id: { required }
          },
          trackingId: { required }
        }
      }
    } else {
      return {
        trackingOrderStatus: this.role == "admin" ? { required } : {},
        trackingInformation: {
          type: {
            id: { required }
          }
        },
        oceanShipment: {
          provider: {
            id: { required }
          },
          forwarderName: { required },
          forwarderContactPhone: {},
          vesselName: {},
          houseBlNumber: { required },
          masterBlNumber: {}
        }
      }
    }
  },
  computed: {
    order() {
      return this.$store.getters["order/order"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    shipmentType() {
      return this.$store.getters["reference/shipmentType"]
    },
    shippingProvider() {
      return this.$store.getters["reference/shippingProvider"]
    },
    airProviders() {
      return this.shippingProvider && this.shippingProvider.filter(item => item.air)
    },
    oceanProviders() {
      return this.shippingProvider && this.shippingProvider.filter(item => item.ocean)
    }
  },
  created() {
    this.trackingOrderStatus = this.trackingStatus
    let airShipment
    let oceanShipment
    if (this.airTrackingInfo.length > 0 && this.isEditing) {
      if (this.trackingOrderStatus === this.orderStatus.supplyShipped) {
        airShipment = this.airTrackingInfo.filter(
          item => item.orderStatus.id == this.orderStatus.supplyShipped
        )[0]
      } else {
        airShipment = this.airTrackingInfo.filter(
          item => item.orderStatus.id == this.orderStatus.deliverShipped
        )[0]
      }
      if (airShipment) {
        this.trackingInformation.type.id = "air"
        this.airShipment = airShipment
        this.initialType = "air"
      }
    }
    if (this.oceanTrackingInfo.length > 0 && this.isEditing) {
      if (this.trackingOrderStatus === this.orderStatus.supplyShipped) {
        oceanShipment = this.oceanTrackingInfo.filter(
          item => item.orderStatus.id == this.orderStatus.supplyShipped
        )[0]
      } else {
        oceanShipment = this.oceanTrackingInfo.filter(
          item => item.orderStatus.id == this.orderStatus.deliverShipped
        )[0]
      }
      if (oceanShipment) {
        this.trackingInformation.type.id = "ocean"
        this.oceanShipment = oceanShipment
        this.initialType = "ocean"
      }
    }
    this.airShipments = this.airTrackingInfo
    this.oceanShipments = this.oceanTrackingInfo
  },
  methods: {
    resetValues() {
      this.showPlaceholder = false
      this.resetForm()
    },
    hide() {
      this.$emit("hide")
    },
    skip() {
      this.$emit("skip")
      this.hide()
    },
    trackingInfoObject() {
      let status
      if (this.trackingOrderStatus === this.orderStatus.supplyShipped) {
        status = this.orderStatus.supplyShipped
      } else {
        status = this.orderStatus.deliverShipped
      }
      if (this.trackingInformation.type.id == "air") {
        this.airShipment.orderStatus.id = status
        this.airShipments.push(this.airShipment)
      } else {
        this.oceanShipment.orderStatus.id = status
        this.oceanShipments.push(this.oceanShipment)
      }
    },
    async save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        if (!this.isEditing) {
          this.trackingInfoObject()
        } else {
          this.updateTrackingInfo()
        }
        try {
          let order = {
            id: this.order.id,
            version: Number(this.order.version) + 1,
            airShipments: this.airShipments,
            oceanShipments: this.oceanShipments
          }
          let params = {}
          if (this.$route.name == "adminBrandOrder") {
            params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
          }
          if (this.$route.name == "adminRetailerOrder") {
            params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
          }
          await this.$store.dispatch("order/updateStatus", { order, params })
          this.hide()
        } catch (error) {
          this.hide()
          console.error(error)
        }
      }
    },
    resetForm() {
      if (this.trackingInformation.type.id == "air") {
        this.$v.airShipment.$reset()
      } else {
        this.$v.oceanShipment.$reset()
      }
      this.airShipment.provider.id = ""
      this.airShipment.trackingId = ""
      this.oceanShipment.forwarderName = ""
      this.oceanShipment.forwarderContactPhone = ""
      this.oceanShipment.provider.id = ""
      this.oceanShipment.vesselName = ""
      this.oceanShipment.houseBlNumber = ""
      this.oceanShipment.masterBlNumber = ""
      this.$v.trackingInformation.type.id.$touch()
    },
    updateTrackingInfo() {
      if (this.initialType != this.trackingInformation.type.id) {
        if (this.trackingOrderStatus == this.orderStatus.supplyShipped) {
          this.airShipments = this.airShipments.filter(
            item => item.orderStatus.id != this.orderStatus.supplyShipped
          )
          this.oceanShipments = this.oceanShipments.filter(
            item => item.orderStatus.id != this.orderStatus.supplyShipped
          )
        } else {
          this.airShipments = this.airShipments.filter(
            item => item.orderStatus.id != this.orderStatus.deliverShipped
          )
          this.oceanShipments = this.oceanShipments.filter(
            item => item.orderStatus.id != this.orderStatus.deliverShipped
          )
        }
        this.trackingInfoObject()
      }
    }
  }
}
</script>

<style lang="sass">
.old-tracking-information-modal
  .modal-card-body
    display: flex
  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .old-tracking-information-modal__close-button
      top: 20px
      width: 14px
      height: 14px
  &__content
    position: relative
    background-color: #FFFFFF
    width: 525px
  .button
    height: 30px
    font-size: 14px
    padding: 16px 20px
  &__body
    padding: 30px
    width: 525px
    display: flex
    justify-content: center
  &__buttons
    padding: 15px 20px
    display: flex
    justify-content: flex-end
  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px
  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 29px
    color: #b4b4b4
    font-size: 14px
    width: 165px
  &__label
    font-size: 14px
    font-weight: 500
  &__form-container
    width: 300px
    min-height: 220px
</style>
