<template>
  <div class="agency-wrap">
    <carousel :data-list="agency.bannerData" :carousel-attrs="carouselAttrs"></carousel>
    <div class="agency-wrap__wrap-box">
      <div class="agency-wrap__wrap-box__page-description">
        <p>{{ agency.description }}</p>
        <p class="agency-wrap-bold-style">{{ agency.agencys }}</p>
      </div>
      <agency-section
        v-for="(item, index) in agency.dataList"
        :key="'box' + index"
        :data="item"
        :attr="contentSectionAttrs[index]"
        :section-index="index"
      >
      </agency-section>
    </div>
  </div>
</template>

<script>
import AgencySection from "./components/AgencySection.vue"
import carousel from "./components/Carousel.vue"

export default {
  name: "AgencyHomePage",
  components: {
    "agency-section": AgencySection,
    carousel
  },
  data() {
    return {
      contentSectionAttrs: [
        {
          imageSrc: "Rectangle_41.svg",
          imagePosition: "left"
        },
        {
          imageSrc: "Rectangle_37.svg",
          imagePosition: "right"
        },
        {
          imageSrc: "Rectangle_40.svg",
          imagePosition: "left"
        },
        {
          imageSrc: "Rectangle_39.svg",
          imagePosition: "right"
        }
      ],
      carouselAttrs: [
        {
          fontPosition: "right",
          imageSrc: "agency_banner_2.png"
        },
        {
          fontPosition: "left",
          imageSrc: "agency_banner_3.png",
          params: ["GeneralAgency"]
        },
        // {
        //   fontPosition: "left",
        //   imageSrc: "agency_banner_4.png"
        // },
        {
          fontPosition: "left",
          imageSrc: "agency_banner_5.png"
        },
        {
          fontPosition: "left",
          imageSrc: "agency_banner_1.png"
        }
      ]
    }
  },
  computed: {
    agency() {
      return this.$t("Agency")
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/shared/_fonts";

// this is font and style variable
$contentWidth: 1106px;
$titleFont: "Poppins-Bold";
$titleColor: #12433d;
$contentFont: "Proxima Nova";
$fontSize: 18px;
$lineHeight: 22px;

.agency-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  &__wrap-box {
    width: $contentWidth;
    line-height: $lineHeight;
    margin: 0 auto;

    &__page-description {
      font-family: $contentFont;
      color: black;
      max-width: 984px;
      width: 100%;
      padding-top: 94px;
      padding-bottom: 75px;
      font-size: 18px;
      line-height: 22px;

      p {
        margin-bottom: 0;
      }

      p:first-child {
        margin-bottom: 37px;
      }

      p:first-child {
        margin-bottom: 37px;
      }
    }
  }
  .agency-wrap-bold-style {
    font-weight: bold;
  }
}
</style>
