<template lang="pug">
.retailer-order-table.table-border-t-none(v-click-outside="hideDropdown")
  .retailer-order-table__header-container
    p.retailer-order-table__name Retailer Terms
    p.add-charge-button(@click="showAddCharges" v-if="!isBrand && (order.status && order.status.id === orderStatus.pending && !order.declined && !(isRetailer && this.order.version === '2'))" :disabled="isEditing") Add Terms
  table.table.is-fullwidth
    thead
      tr
        th.retailer-order-table__head Retailer Terms
        th.retailer-order-table__head Type
        th.retailer-order-table__head {{$t('Retailers.RetailerOrder.description')}}
        th.retailer-order-table__head(width="95px") {{$t('Retailers.RetailerOrder.amount')}}
    tbody
      tr(v-for="item in retailerLists")
        td.retailer-order-table__cell(:style="`color: ${item.textColor}`") {{item.note || '-'}}
          //span(v-if="item.percentage") ({{item.recordedValue}}%)
        td.retailer-order-table__cell(:style="`color: ${item.textColor}`") {{item.paymentType.name}}
        td.retailer-order-table__cell(v-if="item.percentage" :style="`color: ${item.textColor}`") {{ Number(item.recordedValue).toFixed(1) }}%
        td.retailer-order-table__cell(v-else :style="`color: ${item.textColor}`") {{ item.recordedValue }}
        td.retailer-order-table__cell(v-if="item.paidBy && item.paidBy.id == 'brand'" :style="`color: ${item.textColor}`") ({{priceFormat(item.calculatedCostUsd)}})
        td.retailer-order-table__cell(v-else :style="`color: ${item.textColor}`") ({{priceFormat(item.calculatedCostUsd)}})

      //tr(v-if="retailerTermsTotal === 0")
        td.brand-order-table__cell.table-border-b-none
        td.brand-order-table__cell.table-border-b-none
        td.brand-order-table__cell.table-border-b-none.subtotal.text-right Total
        td.retailer-order-table__cell.table-border-b-none(v-if="totalAdditionalCharge<0") ({{priceFormat(totalAdditionalCharge * -1)}})
        td.retailer-order-table__cell.table-border-b-none(v-else) {{priceFormat(totalAdditionalCharge)}}
      tr
        td.brand-order-table__cell.table-border-b-none
        td.brand-order-table__cell.table-border-b-none
        td.brand-order-table__cell.table-border-b-none.subtotal.text-right Total
        td.retailer-order-table__cell.table-border-b-none(v-if="retailerTermsTotal<0") ({{priceFormat(retailerTermsTotal * -1)}})
        td.retailer-order-table__cell.table-border-b-none(v-else) {{priceFormat(retailerTermsTotal)}}
</template>

<script>
import constants from "@/config.js"
import { required } from "vuelidate/lib/validators"
// import { getSesStorage } from "@/utils/storageUtil"
import { priceFormat } from "@/utils/validate"

export default {
  props: {
    order: { type: Object, default: () => {} },
    retailerLists: { type: Array, default: () => [] },
    buttonColor: { type: String },
    retailerTermsTotal: { type: Number }
  },
  data() {
    return {
      priceFormat,
      orderStatus: constants.orderStatus,
      showAddChargeFileds: false,
      additionalCharge: {
        chargeType: {
          id: "",
          name: ""
        },
        ownedBy: {
          id: ""
        },
        paidBy: {
          id: ""
        },
        percentage: false,
        seenByBrand: false,
        seenByLanding: false,
        seenByRetailer: false,
        recordedValue: null,
        calculatedCostUsd: 0.0,
        note: ""
      },
      purchaseDetails: [
        {
          calculatedRetailCostUsd: "",
          calculatedWholesaleCostUsd: "",
          calculatedWholesalePriceUsd: "",
          createdAt: "",
          createdBy: "",
          product: {
            id: "",
            name: "",
            sku: ""
          },
          quantity: "",
          quantityType: {
            id: "",
            name: ""
          },
          testersquantity: "",
          recordedMargin: "",
          recordedMsrpUsd: ""
        }
      ],
      itemIndex: -1,
      editingIndex: -1,
      isEditing: false,
      productIndex: -1,
      productEditIndex: -1,
      isSaveDisabled: true
    }
  },
  validations() {
    if (this.additionalCharge.chargeType.id === "other") {
      return {
        additionalCharge: {
          chargeType: {
            id: { required },
            name: {}
          },
          recordedValue: {
            required,
            positiveNumber(msrpUsd) {
              return /^\d+(\.\d{1,2})?$/.test(msrpUsd)
            }
          },
          note: { required },
          percentage: {}
        }
      }
    } else {
      return {
        additionalCharge: {
          chargeType: {
            id: { required },
            name: {}
          },
          recordedValue: {
            required,
            positiveNumber(recordedValue) {
              return /^\d+(\.\d{1,2})?$/.test(recordedValue)
            }
          },
          note: {},
          percentage: {}
        }
      }
    }
  },
  computed: {
    charges() {
      return (
        this.$store.getters["reference/orderChargeType"] &&
        this.$store.getters["reference/orderChargeType"].filter(item => item.id == "custom")
      )
    },
    isRetailer() {
      return this.role === "retailer" || this.$route.name === "adminRetailerOrder"
    },
    isBrand() {
      return this.role === "brand" || this.$route.name === "adminBrandOrder"
    },
    additionalCharges() {
      if (this.isBrand) {
        return this.order.charges && this.order.charges.filter(item => item.seenByBrand)
      } else if (this.isRetailer) {
        return this.order.charges && this.order.charges.filter(item => item.seenByRetailer)
      } else {
        return this.order.charges
      }
    },
    termsCharges() {
      if (this.isRetailer) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              (item.chargeType.id === "damages" || item.chargeType.id === "marketing") &&
              item.seenByRetailer
          )
        )
      } else if (this.isBrand) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              (item.chargeType.id === "damages" || item.chargeType.id === "marketing") &&
              item.seenByBrand
          )
        )
      } else {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item => item.chargeType.id === "damages" || item.chargeType.id === "marketing"
          )
        )
      }
    },
    otherCharges() {
      if (this.isRetailer) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.chargeType.id !== "damages" &&
              item.chargeType.id !== "marketing" &&
              item.seenByRetailer
          )
        )
      } else if (this.isBrand) {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.chargeType.id !== "damages" &&
              item.chargeType.id !== "marketing" &&
              item.seenByBrand
          )
        )
      } else {
        return (
          this.order.charges &&
          this.order.charges.filter(
            item => item.chargeType.id !== "damages" && item.chargeType.id !== "marketing"
          )
        )
      }
    },
    role() {
      return this.$store.getters["user/role"]
    },
    totalAdditionalCharge() {
      let total
      if (this.additionalCharges) {
        total = this.additionalCharges.reduce((total, charge) => {
          if (charge.paidBy.id == "brand") {
            return total - parseFloat(charge.calculatedCostUsd)
          } else {
            return total + parseFloat(charge.calculatedCostUsd)
          }
        }, 0)
      } else {
        total = 0
      }
      this.$emit("getRetailerTermsTotal", total < 0 ? total * -1 : total)
      return total
    },
    totalAmount() {
      return this.totalAdditionalCharge + parseFloat(this.order.calculatedWholesaleCostUsd)
    }
  },
  methods: {
    saveEditedCharges(index) {
      this.otherCharges[index].chargeType = this.additionalCharge.chargeType
      this.otherCharges[index].note = this.additionalCharge.note
      this.otherCharges[index].recordedValue = this.additionalCharge.recordedValue
      this.otherCharges[index].percentage = this.additionalCharge.percentage
      this.updateCharges()
      this.editingIndex = -1
      this.isEditing = false
    },
    showDropdown(index) {
      this.itemIndex = index
    },
    enableEditing(index, charge) {
      this.editingIndex = index
      this.additionalCharge = { ...charge }
      this.isEditing = true
    },
    removeCharges(charge) {
      this.order.charges = this.order.charges.filter(item => item !== charge)
      this.updateCharges()
      this.hideDropdown()
    },
    hideDropdown() {
      this.itemIndex = -1
    },
    onSelectingCharge(event) {
      const selectedCharge = this.charges.filter(item => item.id === event.target.value)[0]
      this.additionalCharge.note = selectedCharge.note
      this.additionalCharge.chargeType.name = selectedCharge.name
      this.additionalCharge.recordedValue = selectedCharge.amount
      this.additionalCharge.seenByBrand = true
      this.additionalCharge.seenByLanding = true
      this.additionalCharge.seenByRetailer = true
      this.additionalCharge.ownedBy.id = this.role === "retailer" ? "retailer" : "brand"
      this.additionalCharge.paidBy.id = this.role === "retailer" ? "brand" : "retailer"
      if (this.$route.name == "adminBrandOrder") {
        this.additionalCharge.ownedBy.id = "brand"
        this.additionalCharge.paidBy.id = "retailer"
      }
      if (this.$route.name == "adminRetailerOrder") {
        this.additionalCharge.ownedBy.id = "retailer"
        this.additionalCharge.paidBy.id = "brand"
      }
    },
    saveCharges() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        this.order.charges.push(this.additionalCharge)
        this.updateCharges()
        this.showAddChargeFileds = false
      }
    },
    cancelCharges() {
      this.editingIndex = -1
      this.showAddChargeFileds = false
      this.isEditing = false
      this.resetForm()
    },
    showAddCharges() {
      this.isEditing = true
      this.showAddChargeFileds = !this.showAddChargeFileds
      this.$emit("openAddTermsModal")
    },
    resetForm() {
      this.isEditing = false
      this.additionalCharge = {
        chargeType: {
          id: "",
          name: ""
        },
        ownedBy: {
          id: ""
        },
        paidBy: {
          id: ""
        },
        percentage: false,
        seenByBrand: false,
        seenByLanding: false,
        seenByRetailer: false,
        recordedValue: null,
        calculatedCostUsd: 0.0,
        note: ""
      }
      this.$v.additionalCharge.$reset()
    },
    formatUsersAccepted() {
      let obj = {
        isBrandAccepted: false,
        isRetailerAccepted: false,
        isLandingAccepted: false
      }
      if (this.isAdmin) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = true
      }
      if (this.isBrand) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = false
      }
      if (this.isRetailer) {
        obj.isBrandAccepted = false
        obj.isRetailerAccepted = true
        obj.isLandingAccepted = false
      }
      return obj
    },
    updateAdditionalChargeObject() {
      const scceptInfo = this.formatUsersAccepted()
      return {
        ...scceptInfo,
        id: this.order.id,
        version: Number(this.order.version) + 1,
        charges: this.order.charges
      }
    },
    async updateCharges() {
      let order = this.updateAdditionalChargeObject()
      let params = {}
      if (this.$route.name == "adminBrandOrder") {
        params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      }
      if (this.$route.name == "adminRetailerOrder") {
        params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      }
      try {
        await this.$store.dispatch("order/updateStatus", { order, params })
        this.resetForm()
      } catch (error) {
        console.error()
      }
    }
  }
}
</script>

<style lang="sass">
.retailer-order-table__header-container
  display: flex
  justify-content: flex-start
  align-items: center
  p
    margin-bottom: 10px
  .add-charge-button
    margin-left: 30px
    text-decoration: underline
    cursor: pointer

.additional-charge
  width: 100%
  select
    width: 100%
    height: 36px
.amount-input-container
  display: flex
  margin-bottom: 7px
  .input
    width: 70px
    margin-right: 4px
  .additional-charge-dropdown
    .select
      height: 36px
      select
        height: 100%
.additional-charge-buttons
  width: 129px
  display: flex
  justify-content: flex-end
  padding: 5px 0
  .button[disabled]
    cursor: default
  .save-additional-charge
    margin-right: 10px
    font-size: 14px
    height: 25px
    width: 50px
  .cancel-additional-charge
    font-size: 14px
    height: 25px
    width: 50px
    text-decoration: underline
    color: #4250C7
    border: none

.adjust-width
  width: 120px
.total-amount-style
  background-color: #595959
</style>
