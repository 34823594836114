<template lang="pug">
.microcategories-filter(v-show="activeMicroCategories.length")
  .microcategories-filter__header(v-for="activeSubcategory in activeSubcategories") {{ activeSubcategory.name }}
  ul.microcategories-filter__visible
    li.dropdown-item.li-height(
      v-for="microCategory in activeMicroCategories"
      :key="microCategory.id"
      @click="makeMicroCategoryActive(microCategory.id)"
    )
      span.microcategories-filter__checkbox
        img(src="@/assets/img/checkbox.png")
        img.products-filter__item--checked(v-show="microCategory.checked" src="@/assets/img/inputs/checked.svg")
      span.microcategories-filter__name {{ microCategory.name }}
</template>

<script>
export default {
  props: {
    microCategories: { type: Array, default: () => [] },
    activeSubcategories: { type: Array, default: () => [] }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    activeSubcategoryIds() {
      return this.activeSubcategories.map(category => category.id)
    },
    activeMicroCategories() {
      return this.microCategories.filter(microCategory => {
        const subcategoryId = microCategory.parent.id
        return this.activeSubcategoryIds.includes(subcategoryId)
      })
    }
  },
  methods: {
    makeMicroCategoryActive(microCategoryId) {
      const selectedMicroCategories = this.microCategories.filter(
        microCategory => microCategory.id === microCategoryId
      )
      selectedMicroCategories.forEach(
        microCategory => (microCategory.checked = !microCategory.checked)
      )
      this.$emit("filterSwitch")
    }
  }
}
</script>

<style lang="sass">
.microcategories-filter
  .li-height
    height: 27px
    padding: 0px
    font-weight: 300
  .dropdown-item
    cursor: pointer

  &__checkbox
    position: absolute
    left: 0
    margin-right: 10px

  &__checked
    margin-left: -17px
    margin-top: -16px

  &__name
    font-size: 14px
    color: rgba(0, 0, 0, 0.65)
    margin-left: 24px

  &__visible
    display: block
    padding-bottom: 10px

  &__header
    position: relative
    display: block
    cursor: pointer
    font-family: Poppins
    font-size: 14px
    color: #000000
    font-weight: 600
    padding-top: 10px
</style>
