import brandClient from "@/api/brand-client"
import retailerClient from "@/api/retailer-client"
import { formatTime } from "@/utils/validate"

export default {
  namespaced: true,

  state: {
    brand: {},
    brands: [],
    brandsSearch: [],
    topBrands: [],
    brandsSearchHeaders: { "x-page-count": null },
    memberStatus: "",
    subscriptionList: [],
    historyList: [],
    cleanPreCheckList: {},
    changeOwner: {}
  },

  getters: {
    brands(state) {
      return state.brands.sort((a, b) => a.name.localeCompare(b.name))
    },
    brandsSearch(state) {
      return state.brandsSearch.map(item => {
        item.isDownload = false
        item.isCopyTrainingLink = false
        if (item.brandTrainingSubscriptions && item.brandTrainingSubscriptions.length > 0) {
          for (let i = 0; i < item.brandTrainingSubscriptions.length; i++) {
            let data = item.brandTrainingSubscriptions[i]
            let key = data.retailerId
            item[key] = {
              termName: data.subscriptionTerms ? data.subscriptionTerms.name : "",
              endDate: formatTime(data.endAt),
              beauty: data.status.id === "activated" ? "Member" : ""
            }
          }
        }
        return item
      })
    },
    brandsSearchTotalCount(state) {
      return state.brandsSearchHeaders["x-total-count"]
    },
    brandsSearchPageCount(state) {
      return state.brandsSearchHeaders["x-page-count"]
    },
    brandsByNavigationalTag: state => navigationalTagSlug => {
      return state.brandsByNavigationalTags[navigationalTagSlug] || []
    },

    brandsByCategories: state => categoryIds => {
      if (!categoryIds.length) {
        return state.brands
      }

      return state.brands.filter(brand => {
        if (!brand.productCategories) {
          return false
        }

        return brand.productCategories.ids.some(categoryId => categoryIds.includes(categoryId))
      })
    },
    brandById: state => id => {
      return state.brands.find(brand => brand.id === id)
    },
    isTermsPending(state) {
      return (
        state.brand && state.brand.termsPending && state.brand.termsPending.status.id === "pending"
      )
    },
    brand(state) {
      return {
        website: "",
        about: "",
        introduction: "",
        size: {},
        foundingDate: null,
        keyRetailers: [],
        heroProducts: [],
        socialAccounts: [],
        bannerAsset: {},
        logoAsset: {},
        terms: {},
        tags: [],
        status: { id: "pending" },
        inviteMemberError: undefined,
        ...state.brand
      }
    },
    isBrandPending(state) {
      return (
        state.brand && state.brand.status && ["pending", "rejected"].includes(state.brand.status.id)
      )
    },
    topBrands(state) {
      return state.topBrands
    },
    memberStatus(state) {
      return state.memberStatus
    },
    subscriptionList(state) {
      return state.historyList
    },
    preCheckList(state) {
      return state.cleanPreCheckList
    },
    changeOwner(state) {
      return state.changeOwner
    },
    brandMember(state) {
      return state.brand.members
    },
    getUpdateMemberInviteError(state) {
      return state.inviteMemberError
    }
  },

  mutations: {
    SET_BRANDS(state, brands) {
      state.brands = brands
    },
    SET_BRANDS_SEARCH(state, brands) {
      state.brandsSearch = brands
    },
    SET_TOP_BRANDS(state, brands) {
      state.topBrands = brands
    },
    SET_BRANDS_SEARCH_HEADERS(state, headers) {
      state.brandsSearchHeaders = headers
    },

    SET_BRAND(state, currentBrand) {
      state.brand = currentBrand
    },
    UPDATE_BRAND(state, updatedBrand) {
      state.brand = updatedBrand
    },
    UPDATE_BRAND_TERMS(state, updatedTerms) {
      state.brand.terms = updatedTerms
    },
    UPDATE_CURATIONS(state, curations) {
      state.brand.curations = curations
    },
    UPDATE_BRAND_SOCIAL_ACCOUNTS(state, socialAccounts) {
      state.brand.socialAccounts = socialAccounts
    },
    UPDATE_BRAND_KEY_RETAILERS(state, keyRetailers) {
      state.brand.keyRetailers = keyRetailers
    },
    UPDATE_HERO_PRODUCTS(state, heroProducts) {
      state.brand.heroProducts = heroProducts
    },
    UPDATE_TAGS(state, tags) {
      state.brand.tags = tags
    },
    UPDATE_WIRE_INSTRUCTIONS(state, wireInstructions) {
      state.brand.wireInstructions = wireInstructions
    },
    UPDATE_BRAND_PENDING_TERMS(state, pendingTerms) {
      state.brand.termsPending = pendingTerms
    },
    UPDATE_SUBSCRIPTIONS(state, scopes) {
      state.brand.scopes = scopes
    },
    ADD_MEMBER(state, member) {
      state.brand.members.push(member)
    },
    UPDATE_MEMBER(state, updatedMember) {
      state.memberStatus = updatedMember.membershipStatus
      for (var index in state.brand.members) {
        if (state.brand.members[index].user.id === updatedMember.user.id) {
          state.brand.members[index].membershipStatus = updatedMember.membershipStatus
        }
      }
    },
    UPDATE_SUBSCRIPTION_PLAN(state, planType) {
      state.brand.subscriptions = planType
    },
    UPDATE_SUBSCRIPTION_COMMISSION(state, commission) {
      state.brand.commissions = commission
    },
    SET_PRE_CHECK_LIST(state, list) {
      state.cleanPreCheckList = list
    },
    UPDATE_CHANGE_OWNER(state, data) {
      state.changeOwner = data
    },
    UPDATE_SUBSCRIPTION_HISTORY(state, history) {
      state.historyList = history
    },
    UPDATE_MEMEBER_ERROR(state, data) {
      state.inviteMemberError = data
    }
  },

  actions: {
    async searchBrands({ commit }, { params }) {
      try {
        const response = await brandClient.getBrands(params)
        commit("SET_BRANDS_SEARCH", response.data)
        commit("SET_BRANDS_SEARCH_HEADERS", response.headers)
        return response
      } catch (error) {
        if ((error && error.status === 404) || (error.response && error.response.status === 404)) {
          let headersInfo =
            error && error.response && error.response.headers ? error.response.headers : ""
          commit("SET_BRANDS_SEARCH", [])
          commit("SET_BRANDS_SEARCH_HEADERS", headersInfo)
          return { data: [], headers: headersInfo }
        } else {
          return Promise.reject(error)
        }
      }
    },

    async fetchBrands({ commit }, { params }) {
      try {
        const { data } = await brandClient.getBrands(params)
        commit("SET_BRANDS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetchAllBrands({ commit }, { params }) {
      try {
        const { data } = await brandClient.getAllBrands(params)
        commit("SET_BRANDS", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async fetchBrandsByIds({ commit }, { brandIds, params = {} }) {
      try {
        let id = brandIds.join(",")
        const { data } = await brandClient.getBrands({
          ...params,
          id
        })
        commit("SET_TOP_BRANDS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getBrand({ commit }, id) {
      const { data } = await brandClient.getBrand(id)
      commit("SET_BRAND", data)
      return data
    },
    async updateBrand({ commit }, brand) {
      try {
        const { data } = await brandClient.updateBrand(brand, brand.id)
        commit("UPDATE_BRAND", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateBrandPartially({ commit }, brand) {
      try {
        const { data } = await brandClient.updateBrandPartially(brand)
        commit("UPDATE_BRAND", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateBrandPublic({ commit }, brand) {
      try {
        const { data } = await brandClient.updateBrandPublic(brand)
        commit("UPDATE_BRAND", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateTerms({ commit, state }, terms) {
      try {
        const { data } = await brandClient.updateTerms(terms, state.brand.id)
        commit("UPDATE_BRAND_TERMS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateCurations({ commit, state }, curations) {
      try {
        const { data } = await brandClient.updateCurations(curations, state.brand.id)
        commit("UPDATE_CURATIONS", data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async updateSocialAccounts({ commit, state }, socialAccounts) {
      try {
        const { data } = await brandClient.updateSocialAccounts(socialAccounts, state.brand.id)
        commit("UPDATE_BRAND_SOCIAL_ACCOUNTS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateKeyRetailers({ commit, state }, keyRetailers) {
      try {
        const { data } = await brandClient.updateKeyRetailers(keyRetailers, state.brand.id)
        commit("UPDATE_BRAND_KEY_RETAILERS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateHeroProducts({ commit, state }, heroProducts) {
      try {
        const { data } = await brandClient.updateHeroProducts(heroProducts, state.brand.id)
        commit("UPDATE_HERO_PRODUCTS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateTags({ commit, state }, tags) {
      try {
        const { data } = await brandClient.updateTags(tags, state.brand.id)
        commit("UPDATE_TAGS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateCategories({ commit, state }, categories) {
      try {
        const { data } = await retailerClient.updateCategories(categories, state.retailer.id)
        commit("UPDATE_RETAILER_CATEGORIES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateWireInstructions({ commit, state }, wireInstructions) {
      try {
        const { data } = await brandClient.updateWireInstructions(wireInstructions, state.brand.id)
        commit("UPDATE_WIRE_INSTRUCTIONS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updatePendingTerms({ commit, state }, pendingTerms) {
      try {
        const { data } = await brandClient.updatePendingTerms(pendingTerms, state.brand.id)
        commit("UPDATE_BRAND_PENDING_TERMS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateSubscriptions({ commit, state }, scopes) {
      try {
        const { data } = await brandClient.updateSubscriptions(scopes, state.brand.id)
        commit("UPDATE_SUBSCRIPTIONS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async addMember({ commit, state }, member) {
      try {
        const { data } = await brandClient.addMember(member, state.brand.id)
        if (!data.developerMessage) {
          commit("ADD_MEMBER", data)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async adminAddMember({ commit, state }, { member, params }) {
      try {
        const { data } = await brandClient.adminAddMember(member, state.brand.id, params)
        if (!data.developerMessage) {
          commit("ADD_MEMBER", data)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateMemberStatus({ commit, state }, { member, params }) {
      try {
        const { data } = await brandClient.updateMemberStatus(member, state.brand.id, params)
        commit("UPDATE_MEMBER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateMemberStatusAfterInvite({ commit }, { member, brandid, params }) {
      try {
        const { data } = await brandClient.updateMemberStatus(member, brandid, params)
        commit("UPDATE_MEMBER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateSubscriptionPlan({ commit, state }, planType) {
      try {
        const { data } = await brandClient.updateBrandSubscriptionPlan(state.brand.id, planType)
        commit("UPDATE_SUBSCRIPTION_PLAN", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateBrandSubscriptionNew({ state }, param) {
      console.log("--->", param)
      try {
        const { data } = await brandClient.updateBrandSubscriptionNew(state.brand.id, param.mode)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateSubscriptionCommission({ commit, state }, commission) {
      try {
        const { data } = await brandClient.updateBrandCommission(state.brand.id, commission)
        commit("UPDATE_SUBSCRIPTION_COMMISSION", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getCleanPreCheckList({ commit, rootGetters }, { brandId, currentPage }) {
      try {
        const { data } = await brandClient.getBrandCleanPreCheck(brandId, currentPage)
        if (data.list) {
          data.list.map(item => {
            let obj = Object.assign(item, {
              percent: (item.clearedProductsSize / item.totalProductsSize) * 100
            })
            if (obj.products) {
              obj.products.map(product => {
                const data = rootGetters["product/findBrandProduct"](product.id)
                product.name = data.name
                product.sku = data.sku
                product.msrpUsd = data.msrpUsd
              })
            }
            return obj
          })
        }
        commit("SET_PRE_CHECK_LIST", data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async changeOwner({ commit, state }, param) {
      try {
        const { data } = await brandClient.changeOwner(state.brand.id, param)
        commit("UPDATE_CHANGE_OWNER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async brandRegister({ commit }, param) {
      try {
        const response = await brandClient.brandRegistration(param)
        console.log(commit)
        console.log(response)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async uploadCatalogProducts({}, { adminInfo, file }) {
      const response = await brandClient.uploadProducts(adminInfo, file)
      return response
    }
  }
}
