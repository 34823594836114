<template>
  <a-modal
    v-model="editFilter"
    width="520px"
    :title="type === '1' ? 'Brand' : type === '2' ? 'Retailer' : 'Custom'"
    :closable="true"
    :footer="null"
    :body-style="dialogBodyStyle"
    :destroy-on-close="true"
    dialog-class="admin-message-edit-filter"
  >
    <div class="admin-message-edit-filter__custom-area">
      <DropdownList
        v-if="type === '1' || type === '2'"
        :placeholder="type === '1' ? 'Select Brand' : 'Select Retailer'"
        :list="dropDownListData"
        :selected-data="selectedBrandRetailerData"
        @apply="applyRetailerBrandData"
      />
      <CustomFilterSelect
        v-else
        :is-edit="true"
        :condition="filterData"
        :brand-list="dropDownListData && dropDownListData[0].brand"
        :retailer-list="dropDownListData && dropDownListData[0].retailer"
        :date-list="dropDownListData && dropDownListData[0].dateList"
        @apply="doApplyCustomData"
      />
      <a-row v-if="type !== '1' && type !== '2' && isShowDateRange">
        <a-col
          :span="4"
          class="admin-message-custom-filters__desc admin-message-custom-filters__marginTop"
        >
          Date Range
        </a-col>
        <a-col :span="20" class="admin-message-custom-filters__marginTop">
          <a-row>
            <a-col :span="11">
              <a-date-picker
                v-model="startDate"
                placeholder="Select start date "
                style="width: 100%;"
                :format="dateFormat"
                @change="onChooseStartDate"
              />
            </a-col>
            <a-col :span="2" style="text-align: center; ">
              <span class="admin-message-custom-filters__date-range-icon">~</span>
            </a-col>
            <a-col :span="11" style="text-align: right;">
              <a-date-picker
                v-model="endDate"
                placeholder="Select end date "
                style="width: 100%;"
                :format="dateFormat"
                @change="onChooseEndDate"
              />
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-row style="margin-top: 32px;">
        <a-col :span="4"></a-col>
        <a-col :span="20"
          ><a-button class="admin-message-edit-filter__save-btn" @click="saveEditSearchGrouop"
            >Save</a-button
          ></a-col
        >
      </a-row>
    </div>
  </a-modal>
</template>

<script>
import DropdownList from "@/components/message/DropdownList"
import CustomFilterSelect from "@/components/message/CustomFilterSelect"
import moment from "moment"
export default {
  components: { CustomFilterSelect, DropdownList },
  data() {
    return {
      type: 0,
      editFilter: false,
      filterData: undefined,
      dropDownListData: undefined,
      dialogBodyStyle: {
        padding: "0px"
      },
      selectedBrandRetailerData: [],
      param: {
        filterClientId: undefined,
        filterClientName: undefined,
        privateId: undefined,
        id: undefined,
        filterClientType: undefined,
        startDate: undefined,
        endDate: undefined
      },
      startDate: undefined,
      endDate: undefined,
      dateFormat: "MM/DD/YYYY",
      applyRetailerData: undefined,
      applyCustomData: undefined,
      isShowDateRange: true
    }
  },
  methods: {
    openDialog(dialogType, data, dropDownList) {
      this.type = dialogType + ""
      this.filterData = data
      this.dropDownListData = dropDownList
      this.editFilter = true
      this.param = { privateId: data.privateId, id: data.id }
      if (dialogType === 3) {
        this.initSelectedData()
      } else {
        this.initBrandRetailerSelectData()
      }
    },
    initBrandRetailerSelectData() {
      let ids = this.filterData.filterClientId.split(",")
      this.selectedBrandRetailerData = []
      for (let i = 0; i < ids.length; i++) {
        let data = this.dropDownListData.filter(item => item.id === ids[i])
        if (data && data.length > 0) {
          this.selectedBrandRetailerData.push(data[0])
        }
      }
    },
    initSelectedData() {
      if (this.filterData) {
        let selectRecipient = this.filterData.filterClientType
        if (this.filterData.filterClientId && this.filterData.filterClientId.length > 0) {
          let selectedFilter = []
          let ids = this.filterData.filterClientId.split(",")
          if (ids && ids.length > 0) {
            let filter = []
            if (this.filterData.filterClientType.id === "brand") {
              filter = this.dropDownListData[0].brand
            } else {
              filter = this.dropDownListData[0].retailer
            }
            ids.forEach(item => {
              if (filter && filter.length > 0) {
                for (let i = 0; i < filter.length; i++) {
                  if (filter[i].id === item) {
                    let obj = Object.assign({}, filter[i])
                    obj.isChecked = true
                    selectedFilter.push(obj)
                    break
                  }
                }
              }
            })
          }
          this.filterData["selectRecipient"] = {
            key: selectRecipient.name,
            label: selectRecipient.name
          }
          this.filterData["selectFilter"] = selectedFilter
        }
        if (this.filterData.startDate) {
          this.param.startDate = this.filterData.startDate
          this.param.endDate = this.filterData.endDate
          if (this.filterData.startDate.indexOf("1779") >= 0) {
            this.filterData["isAllTime"] = true
            this.isShowDateRange = false
          }
          this.startDate = moment(this.filterData.startDate)
          this.endDate = moment(this.filterData.endDate)
        }
      }
    },
    applyRetailerBrandData(data) {
      console.log("......applyRetailerBrandData.......")
      console.log(data)
      console.log(this.filterData)
      this.applyRetailerData = data
      this.param.filterClientType = this.filterData.filterClientType
      this.param.filterClientId = data.filterId
      this.param.filterClientName = data.filterName
    },
    doApplyCustomData(data) {
      console.log("...doApplyCustomData.....")
      console.log(data)
      this.applyCustomData = data
      this.param.filterClientType = { id: data.recipient }
      this.param.filterClientId = data.filterId
      this.param.filterClientName = data.filterName
      if (!this.isShowDateRange) {
        let startTime = undefined
        let endTime = undefined
        if (data.filterStartTime === "All Time") {
          startTime = "1779-01-01"
          endTime = "2299-12-31"
        } else if (data.filterStartTime && data.filterEndTime) {
          startTime = moment(data.filterStartTime, "MM/DD/YYYY").format("YYYY-MM-DD")
          endTime = moment(data.filterEndTime, "MM/DD/YYYY").format("YYYY-MM-DD")
        }
        if (startTime) {
          this.param.startDate = startTime
        }
        if (endTime) {
          this.param.endDate = endTime
        }
      }
      console.log(this.param)
    },
    onChooseStartDate() {
      console.log(this.startDate)
      this.param.startDate = this.startDate.format("YYYY-MM-DD")
    },
    onChooseEndDate(item) {
      console.log(item)
      console.log(this.endDate)
      this.param.endDate = this.endDate.format("YYYY-MM-DD")
    },
    saveEditSearchGrouop() {
      console.log(".....save edit search group....")
      console.log(this.param)
      this.$store
        .dispatch("adminMessage/updateSearchGroup", this.param)
        .then(() => {
          this.$message.success("Edit Success")
          this.$emit("update")
          this.hideDialog()
        })
        .catch(err => {
          this.$message.error(err.toString())
        })
    },
    hideDialog() {
      this.filterData = undefined
      this.dropDownListData = undefined
      this.editFilter = false
      this.type = 0
      this.selectedBrandRetailerData = []
      this.isShowDateRange = true
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-message-edit-filter {
  top: 200px;
  &__custom-area {
    padding: 30px;
  }
  &__save-btn {
    background: #4a8a5d;
    border-radius: 2px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #ffffff;
    width: 89px;
    height: 30px;
  }
}
</style>
<style lang="scss">
.admin-message-edit-filter .ant-modal-close-x {
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
}
.admin-message-edit-filter .ant-modal-title {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #000;
}
.admin-message-edit-filter .ant-modal-header {
  padding: 14px 24px 7px 26px;
}
.admin-message-edit-filter__save-btn.ant-btn:active,
.admin-message-edit-filter__save-btn.ant-btn.active,
.admin-message-edit-filter__save-btn.ant-btn:hover,
.admin-message-edit-filter__save-btn.ant-btn:focus {
  color: #fff;
  border: none;
  background-color: #4a8a5d;
}
//.admin-message-edit-filter__custom-area .ant-select-selection--single {
//  height: 36px;
//}
//.admin-message-edit-filter__custom-area .ant-select-selection {
//  border: 1px solid #4A8A5D;
//  box-sizing: border-box;
//  border-radius: 2px;
//}
</style>
