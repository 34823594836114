export default class User {
  constructor(model) {
    this.privateId = 0;
    this.id = null;
    this.providerId = null;
    this.email = null;
    this.isEmailVerified = 0;
    this.givenName = null;
    this.familyName = null;
    this.jobTitle = null;
    this.phoneNumber = null;
    this.createdAt = new Date();
    this.createdBy = null;
    this.updatedAt = null;
    this.updatedBy = null;
    this.userType = 0 /* UserType.Regular */;
    if (model) {
      this.privateId = model.privateId;
      this.id = model.id;
      this.providerId = model.providerId;
      this.email = model.email;
      this.isEmailVerified = model.isEmailVerified;
      this.givenName = model.givenName;
      this.familyName = model.familyName;
      this.jobTitle = model.jobTitle;
      this.phoneNumber = model.phoneNumber;
      this.createdAt = new Date(model.createdAt);
      this.createdBy = model.createdBy;
      this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null;
      this.updatedBy = model.updatedBy;
      this.userType = model.userType;
    }
  }
}
