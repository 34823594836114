<template lang="pug">
.order-activity
  div
    .order-activity__title {{$t('Components.Orders.OrderActivity.comments')}}
    .columns.is-mobile.is-centered.order-activity__tabs
      .column.is-narrow.is-paddingless.order-activity__tab(@click="displayComments()" :class="{ 'order-activity__tab--active': showComments }")
        img(src="@/assets/img/orders/comment-icon.svg")
      .column
      .column
    v-app(id="inspire",v-if="massageList && massageList.length !== 0")
      comment-item(v-for='item in massageList', :key="item.createdAt"
        :comment="item"
        :brand="brand"
        :retailer="retailer")
    .order-activity__comment-input-group
      input.input.order-activity__comment-input(
        placeholder="Write a comment" v-model.trim="newCommentText"
        @keyup.enter="addComment()"
      )
      a.button.order-activity__coment-input-button(
        v-if="!isShow"
        @click="addComment()"
        :style="{background: buttonColor}"
      ) {{$t('Components.Orders.OrderActivity.post')}}
</template>

<script>
import CommentItem from "@/components/common/CommentItem"
import constants from "@/config"
export default {
  components: {
    "comment-item": CommentItem
  },
  props: {
    brand: { type: Object, default: () => {} },
    // comment: { type: Object, default: () => {} },
    retailer: { type: Object, default: () => {} },
    order: { type: Object, default: () => {} },
    buttonColor: { type: String, default: () => "" },
    isShow: { type: Boolean, default: false }
  },
  data() {
    return {
      showComments: true,
      showTimeline: true,
      newCommentText: "",
      alignTop: false,
      avatar: false,
      dense: true,
      fillDot: false,
      hideDot: false,
      icon: false,
      iconColor: false,
      left: false,
      reverse: false,
      right: true,
      small: false,
      // comment: {},
      loading: false,
      massageList: []
    }
  },
  computed: {
    userId() {
      return this.$store.getters["user/id"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    comment() {
      return this.$store.getters["adminMessage/searchMessage"]
    }
  },
  // watch: {
  //   comment(a, b) {
  //     console.log(44444, a, b)
  //   }
  // },
  async mounted() {
    // this.comment = {}
    // const params = {
    //   itemize: "send",
    //   _limit: 50,
    //   _index: 1,
    //   "order-id": this.$route.params.id
    //   // "client-id": this.brand.id,
    //   // "message-type-id": "Order",
    //   // category: "Orders"
    // }
    // // const textObj = `Landing Reference ID: ${this.order.landingReferenceId}`
    // this.comment = await this.$store.dispatch("adminMessage/fetchSearchMessage", { params })
    this.getOrderMessageDetails()
    // this.comment = {
    //   ...list
    //   // messages: list.messages.filter(item => item.subject === textObj)
    // }
  },
  methods: {
    addComment() {
      if (!this.newCommentText) {
        return
      }
      let sendParam = {
        // senderClientId: this.brand.id,
        // messageType: {
        //   id: "Order"
        // },
        order: {
          id: this.order.id
        },
        subject: `Landing Reference ID: ${this.order.landingReferenceId}`,
        message: this.newCommentText,
        // archive: false,
        // drafts: false,
        // isActive: true,
        messageReceivers: [
          {
            privateId: 1,
            receiverClientId: this.isBrand ? this.retailer.id : this.brand.id,
            isRead: false,
            isActive: true,
            archive: false
          }
        ]
      }
      if (this.role === "admin") {
        sendParam.senderClientId = "*"
      } else {
        sendParam.senderClientId = this.isBrand ? this.brand.id : this.retailer.id
      }
      if (this.comment.messages && this.comment.messages.length !== 0) {
        sendParam.parentPrivateId = this.comment.messages[0].privateId
        if (this.comment.messages[0].category) {
          sendParam["category"] = { id: this.comment.messages[0].category.description }
        }
        if (this.comment.messages[0].messageType) {
          sendParam["messageType"] = { id: this.comment.messages[0].messageType.description }
        }
      } else {
        if (this.isBrand) {
          sendParam.messageType = {
            id: "Order"
          }
        } else {
          sendParam.category = {
            id: "Orders"
          }
        }
      }
      // if (this.massageList && this.massageList.length !== 0) {
      //   const obj = this.massageList[this.massageList.length - 1]
      //   sendParam.messageReceivers = [
      //     {
      //       privateId: 1,
      //       receiverClientId: obj.senderClientId,
      //       isRead: false,
      //       isActive: true,
      //       archive: false
      //     }
      //   ]
      // } else {
      //   sendParam.messageReceivers = [
      //     {
      //       privateId: 1,
      //       receiverClientId: this.isBrand ? this.retailer.id : this.brand.id,
      //       isRead: false,
      //       isActive: true,
      //       archive: false
      //     }
      //   ]
      // }
      this.$store
        .dispatch("adminMessage/createMessage", sendParam)
        .then(async () => {
          this.newCommentText = ""
          const params = {
            itemize: "send",
            _limit: 50,
            _index: 1,
            "order-id": this.$route.params.id
          }
          await this.$store.dispatch("adminMessage/fetchSearchMessage", { params })
          // this.$emit("fetchSearchMessage", params)
          await this.getOrderMessageDetails()
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    },
    getOrderMessageDetails() {
      let id
      if (this.comment.messages && this.comment.messages.length !== 0) {
        id = this.comment.messages[0].privateId
      }
      if (!id) {
        this.massageList = []
        return
      }
      const params = {
        "private-id": id,
        "is-forward": false
      }
      this.$store
        .dispatch("adminMessage/getMessageDetail", params)
        .then(res => {
          if (res && res.messages && res.messages.length > 0) {
            this.massageList = res.messages
            // this.comment.messages.concat(massageList)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="sass">
.v-avatar
  background: white !important
  border-radius: 10%
.order-activity
  padding-top: 40px

  &__logo
    width: 36px
    height: auto

  &__tabs
    padding-bottom: 54px
    padding-top: 0
    border-top: 1px solid #DFE3E8
    height: 18px
    box-sizing: border-box

  &__tab
    cursor: pointer
    width: 30px
    height: 26px
    text-align: center
    margin-right: 5px

  &__tab--active
    border-top: 2px solid black

  &__time-tab
    position: absolute
    left: 35px
    padding-right: 8px
    padding-left: 8px
    cursor: pointer

  &__title
    font-size: 16px
    color: #212B36
    padding-bottom: 20px
    padding-left: 20px

  &__timeline-container
    border-left: 2px solid #DFE3E8
    margin-left: 20px

  &__timeline-logo-icon
    margin-left: -16px
    padding: 1px
    border: 1px solid #aaaaaa
    height: 37px
    width: 37px
    background: white

  &__timeline-clock-icon
    margin-left: -9px
    z-index: 999
    img
      background: white

  &__account-name
    margin-left: 10px
    color: #4A90E2
    font-size: 14px
    font-weight: bold

  &__date
    margin-left: 10px
    color: #AAAAAA
    font-size: 14px
    font-weight: 500
    text-transform: lowercase

  &__comment
    margin-left: 10px
    font-size: 14px
    color: #333333

  &__comment-input-group
    margin-top: 20px
    position: relative
    margin-bottom: 20px

  &__comment-input
    height: 44px
    font-size: 16px

  &__comment-input::placeholder
    font-size: 16px !important

  &__coment-input-button
    width: 89px
    position: absolute
    right: 10px
    top: 70px
    //margin-left: -60px
    margin-top: 5px
    color: #ffffff
    border: none
    font-size: 15px
    border-radius: 2px

  &__event:last-child
    background: white
    height: 100%

.order-activity__timeline-container
  .time-line__wrap
    height: 100%
    margin-top: 0px
    display: flex

    .time-line-list
      margin: 0
      padding: 0
      list-style: none
      width: 100%
      position: relative
      overflow: auto
      display: flex
      flex-direction: column

      .time-line
        position: relative

      .time-line:after
        display: inline-block
        width: 2px
        height: 100%
        background-color: #D7D9DE
        position: absolute
        top: 27px
        left: 17px
        z-index: 1

        .img-block
          position: absolute
          top: 0
          left: 0

        .order-activity__block
          padding: 14px 0px 11px 36px
.commentDate
  color: #AAAAAA
  font-size: 14px
  margin-right: 6px
.v-application--wrap
  min-height: 0px!important
</style>
