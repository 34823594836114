<template>
  <div class="type-sign-up">
    <SignUp
      v-show="step == 1"
      :type="type"
      :submit-label="submtLabel"
      :title="title"
      @submitAction="submitSignupAction"
    ></SignUp>
    <Payment
      v-show="step == 2"
      @submitAction="submitPaymentAction"
      @cancelAction="cancelPaymentAction"
    ></Payment>
    <div v-if="errorMsg" class="has-error">
      <div class=" ant-form-explain">{{ errorMsg }}</div>
    </div>
  </div>
</template>
<script>
import SignUp from "@/components/registration/PlatformSignUp";
import Payment from "@/components/registration/PlatformPayment";
import SubscriptionService from "@/services/subscriptions-service";
export default {
  components: {
    SignUp,
    Payment,
  },
  props: {
    type: { type: String, default: "1" },
  },
  data() {
    return {
      step: 1,
      submtLabel: { type: String },
      title: { type: String },
      signUpData: null,
      errorMsg: "",
    };
  },
  created() {
    if (this.type === "1") {
      this.title = "Sign up for a free trial";
      this.submtLabel = "Start Your Free Trial";
    } else {
      this.title = "Sign up";
      this.submtLabel = "Sign Up";
    }
  },
  methods: {
    submitSignupAction(data) {
      this.step = 2;
      this.signUpData = data;
      //console.log(data);
      //console.log(`step: ${this.step}`);
    },
    cancelPaymentAction() {
      this.step = 1;
    },
    async submitPaymentAction(data) {
      this.step = 2;
      //console.log(data);
      //console.log(`step: ${this.step}`);
      const subscriptionPayment = {
        signUp: this.signUpData,
        subscriptionPayment: data,
      };

      console.log(subscriptionPayment);
      // send http post for the registration
      this.errorMsg = "";
      await SubscriptionService.setup(subscriptionPayment)
        .then((data) => {
          this.$emit("confirm", data);
          return data;
        })
        .catch((err) => {
          if (err) {
            if (err.message) {
              this.errorMsg = err.message;
            } else {
              const errors = err.data.errors;
              if (errors) {
                for (const p in errors) {
                  this.errorMsg += errors[p];
                }
              } else {
                this.errorMsg = err.data;
              }
            }
          }
        });
    },
  },
};
</script>
<style></style>
