<template lang="pug">
.retailer-settings__title Password
    form.retailer-settings__password_form-section(@submit.prevent="setPassword")
        .columns
          .column.is-6
            .control.has-icons-right
              label.label.retailer-settings__account-information_label {{$t('Retailers.RetailerSettings.oldPassword')}}
              input.input.retailer-settings__input-password(
              v-model="$v.password.oldPassword.$model"
              :type="password.oldPasswordType"
              :class="{ 'is-danger': $v.password.oldPassword.$error && password.isInvalidCredentials}"
              @input="resetValidationFlags")
              span.retailer-settings__eyeIcon(@click="oldPasswordVisibility")
                img(src="@/assets/img/icon/passwordEye.svg" v-if="password.oldPasswordType=='password'")
                img(src="@/assets/img/icon/preview-icon.svg" v-if="password.oldPasswordType=='text'")
              p.help.is-danger(v-if="!$v.password.oldPassword.required && password.showValidations") {{$t('Retailers.RetailerSettings.Validations.required')}}
              p.help.is-danger(v-show="password.isInvalidCredentials") {{$t('Retailers.RetailerSettings.Validations.IncorrectOldPassword')}}

        .columns
          .column.is-6
            .control.has-icons-right
              label.label.retailer-settings__account-information_label {{$t('Retailers.RetailerSettings.newPassword')}}
              input.input.retailer-settings__input-password(
                v-model="$v.password.newPassword.$model"
                :type="password.newPasswordType"
                :class="{ 'is-danger': ($v.password.newPassword.$error || $v.password.passwordConfirmation.$error) && password.showValidations}"
                @input="resetValidationFlags"
              )
              span.retailer-settings__eyeIcon(@click="newPasswordVisibility")
                img(src="@/assets/img/icon/passwordEye.svg" v-if="password.newPasswordType=='password'")
                img(src="@/assets/img/icon/preview-icon.svg" v-if="password.newPasswordType=='text'")
              p.help.is-danger(v-if="!$v.password.newPassword.required && password.showValidations") {{$t('Retailers.RetailerSettings.Validations.required')}}
              p.help.is-danger(v-if="!$v.password.newPassword.minLength && password.showValidations") {{$t('Retailers.RetailerSettings.Validations.mustBe10Characters')}}
              p.help.is-danger(v-if="!$v.password.passwordConfirmation.sameAsPassword && password.showValidations") {{$t('Retailers.RetailerSettings.Validations.passwordsMustMatch')}}
              p.help.is-danger(v-if="password.upperCaseAndNumberValidation") {{$t('Retailers.RetailerSettings.Validations.mustContainUpperCaseAndNumber')}}
        .columns
          .column.is-6
            .control.has-icons-right
              label.label.retailer-settings__account-information_label {{$t('Retailers.RetailerSettings.confirmNewPassword')}}
              input.input.retailer-settings__input-password(
                v-model="$v.password.passwordConfirmation.$model"
                :type="password.confirmNewPasswordType"
                :class="{ 'is-danger': $v.password.passwordConfirmation.$error && password.showValidations}"
                @input="resetValidationFlags")
              span.retailer-settings__eyeIcon(@click="confirmNewPasswordVisibility")
                img(src="@/assets/img/icon/passwordEye.svg" v-if="password.confirmNewPasswordType=='password'")
                img(src="@/assets/img/icon/preview-icon.svg" v-if="password.confirmNewPasswordType=='text'")
              p.help.is-danger(v-if="!$v.password.passwordConfirmation.required && password.showValidations") {{$t('Retailers.RetailerSettings.Validations.required')}}

        .columns.is-marginless(v-show="password.isRestInProgress")
          .column
            .control
              img(src="@/assets/img/spinner.gif" alt="spinner")

        .columns
          .column
            .control
            button.button.is-black.retailer-settings__password_button(:disabled="isDisabled") {{$t('Retailers.RetailerSettings.changePassword')}}
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators"

export default {
  validations: {
    password: {
      oldPassword: { required },
      newPassword: { required, minLength: minLength(10) },
      passwordConfirmation: { required, sameAsPassword: sameAs("newPassword") }
    }
  },
  data() {
    return {
      password: {
        oldPasswordType: "password",
        newPasswordType: "password",
        confirmNewPasswordType: "password",
        oldPassword: "",
        newPassword: "",
        passwordConfirmation: "",
        showValidations: false,
        isInvalidCredentials: false,
        isResetSuccessful: false,
        isRestInProgress: false,
        upperCaseAndNumberValidation: false,
        email: ""
      }
    }
  },
  computed: {
    isDisabled() {
      if (this.password.isResetSuccessful) {
        return true
      } else
        return !(
          this.$v.password.oldPassword.$dirty &&
          this.$v.password.newPassword.$dirty &&
          this.$v.password.passwordConfirmation.$dirty
        )
    }
  },
  methods: {
    oldPasswordVisibility() {
      this.password.oldPasswordType =
        this.password.oldPasswordType === "password" ? "text" : "password"
    },
    newPasswordVisibility() {
      this.password.newPasswordType =
        this.password.newPasswordType === "password" ? "text" : "password"
    },
    confirmNewPasswordVisibility() {
      this.password.confirmNewPasswordType =
        this.password.confirmNewPasswordType === "password" ? "text" : "password"
    },
    passwordCheck() {
      if (
        this.password.newPassword.search(/[A-Z]/) == -1 ||
        this.password.newPassword.search(/[0-9]/) == -1
      ) {
        return true
      }
    },
    async setPassword() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.password.showValidations = true
        return
      }
      if (this.passwordCheck()) {
        this.password.upperCaseAndNumberValidation = true
        return
      }
      this.password.isRestInProgress = true
      this.password.email = this.$store.getters["user/email"]
      const email = this.password.email
      const password = this.password.oldPassword
      try {
        await this.$store.dispatch("user/signIn", { email, password })
        await this.$store.dispatch("user/updatePassword", {
          password: this.password.passwordConfirmation
        })
        this.password.isResetSuccessful = true
        this.$vs.notify({
          title: "Notification",
          text: "Password has been changed successfully",
          color: "green"
        })
      } catch (error) {
        this.password.isInvalidCredentials = true
        this.password.isResetSuccessful = false
        console.error(error)
      }
      this.password.isRestInProgress = false
    },
    resetValidationFlags() {
      this.password.showValidations = false
      this.password.isInvalidCredentials = false
      this.password.isResetSuccessful = false
      this.password.upperCaseAndNumberValidation = false
    }
  }
}
</script>

<style lang="sass">

.retailer-settings
  padding-top: 50px
  padding-bottom: 300px

  &__eyeIcon
    cursor: pointer
    position: absolute
    right: 14px
    top: 35px

  &__title
    color: #000000
    font-size: 20px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 30px
    margin-top: 19px

  &__input-password
    box-sizing: border-box
    height: 40px
    width: 100%
    border: 1px solid #E5E5E5
    border-radius: 2px
    position: relative

  &__account-information

    &_label
      color: #363636
      display: block
      font-size: 1rem
      font-weight: 400
      position: relative

  &__password

    &_form-section
      margin-top: 34px

    &_button
      height: 36px
      width: 135px
      font-size: 14px
      top: 6px
</style>
