<template lang="pug">
.modal.old-edit-contact-information-modal(:class="{ 'is-active': show }")
  .modal-background
  .modal-card.old-edit-contact-information-modal__content
    header.old-edit-contact-information-modal__header {{$t('Components.Orders.EditContactInformation.editContactPerson')}}
      img.old-edit-contact-information-modal__close-button(src="@/assets/img/close-button.png" @click="hide")
    section.modal-card-body
      .old-edit-contact-information-modal__body
        .columns
          .column
            .old-edit-contact-information-modal__input-label {{$t('Components.Orders.EditContactInformation.firstName')}}
            input.input(v-model.trim="$v.contactInformation.firstName.$model" :class="{'is-danger': $v.contactInformation.firstName.$error }")
            p.help.is-danger(v-if="$v.contactInformation.firstName.$error") {{$t('Components.Orders.EditContactInformation.Validations.required')}}
          .column
            .old-edit-contact-information-modal__input-label {{$t('Components.Orders.EditContactInformation.lastName')}}
            input.input(v-model.trim="$v.contactInformation.lastName.$model" :class="{'is-danger': $v.contactInformation.lastName.$error }")
        .old-edit-contact-information-modal__input-label {{$t('Components.Orders.EditContactInformation.phoneNumber')}}
        input.input(v-model="$v.contactInformation.phone.$model" :class="{'is-danger': $v.contactInformation.phone.$error }")
        p.help.is-danger(v-if="$v.contactInformation.phone.$error") {{$t('Components.Orders.EditContactInformation.Validations.required')}}
      .has-text-right.old-edit-contact-information-modal__buttons
        a.button(@click="hide") {{$t('Components.Orders.EditContactInformation.cancel')}}
        a.button.is-black.old-edit-contact-information-modal__save-button(@click="updateContactInfo" :disabled="isDisabled" :class="{ 'is-disabled': isDisabled }") {{$t('Components.Orders.EditContactInformation.save')}}
</template>

<script>
import { required } from "vuelidate/lib/validators"
export default {
  props: {
    show: { type: Boolean, default: false },
    contact: {
      type: Object,
      default() {
        return {
          firstName: "",
          lastName: "",
          phone: ""
        }
      }
    }
  },
  data() {
    return {
      contactInformation: {
        firstName: "",
        lastName: "",
        phone: ""
      }
    }
  },
  validations() {
    if (this.contactInformation.lastName || this.contactInformation.firstName) {
      return {
        contactInformation: {
          firstName: {},
          lastName: {},
          phone: { required }
        }
      }
    } else {
      return {
        contactInformation: {
          firstName: { required },
          lastName: { required },
          phone: { required }
        }
      }
    }
  },
  computed: {
    isDisabled() {
      if (this.$v.contactInformation.$anyDirty) {
        return false
      }
      return true
    }
  },
  created() {
    this.contactInformation = { ...this.contact }
  },
  methods: {
    updateContactInfo() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.hide()
      let name = []
      if (this.contactInformation.firstName && this.contactInformation.lastName) {
        name = [this.contactInformation.firstName, this.contactInformation.lastName].join(" ")
      } else {
        name = [this.contactInformation.firstName, this.contactInformation.lastName].join("")
      }
      let updatedContact = {
        name: name,
        phone: this.contactInformation.phone
      }
      this.$emit("contactInfoChange", updatedContact)
    },
    hide() {
      this.$emit("hideContactInfo")
    }
  }
}
</script>

<style lang="sass">
.edit-contact-information
  &__edit-form
    text-decoration: underline
    color: #161616
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.16px
    line-height: 18px
    margin-left: 11px
  &__paragraph
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 26px
  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.25px
    line-height: 24px
    margin-bottom: 24px
  a
    color: #161616
.old-edit-contact-information-modal
  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
  &__content
    position: relative
    background-color: #FFFFFF
    width: 635px
  &__body
    padding: 10px 20px 45px 20px
    border-bottom: 1px solid #E1E4E9
  &__input-label
    padding-bottom: 5px
  &__buttons
    padding: 10px 20px
  &__close-button
    position: absolute
    top: 24px
    right: 24px
    cursor: pointer
  &__save-button
    margin-left: 10px
</style>
