import adminClient from "@/api/admin-client"
//import brandClient from "@/api/brand-client"

export default {
  namespaced: true,

  state: {
    clients: []
  },

  getters: {
    clients(state) {
      return state.clients
    }
  },

  mutations: {
    SET_CLIENTS(state, clients) {
      state.clients = clients
    }
  },

  actions: {
    async fetchClients({ commit }, params) {
      try {
        const { data } = await adminClient.fetchClients(params)
        data.sort((a, b) => a.name.localeCompare(b.name))
        commit("SET_CLIENTS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
