var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('search-component',{attrs:{"search-recent":_vm.searchRecent,"search-hint":"Search by Brand"},on:{"doSearch":_vm.onSearch,"deleteTag":_vm.closeTagItem}}),_c('a-table',{staticClass:"admin-training-table",staticStyle:{"margin-top":"40px"},attrs:{"columns":_vm.tableColumn,"data-source":_vm.tableDate,"size":"middle","row-class-name":(_recode, index) =>
        index % 2 === 1 ? 'admin-training-table-striped' : null},scopedSlots:_vm._u([{key:"beautyfluentValue",fn:function(text){return _c('span',{style:(text ? 'color: #000;' : 'color: #E33716;')},[_vm._v(" "+_vm._s(text ? "Member" : " ")+" ")])}},{key:"modifyDate",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(_vm.dateFormat( record.productTrainingCount.updatedAt ? record.productTrainingCount.updatedAt : record.productTrainingCount.createdAt ))+" ")])}},{key:"link",fn:function(text){return _c('span',{staticClass:"admin-brand-url",on:{"click":function($event){return _vm.clickTo(text)}}},[_vm._v(" "+_vm._s(text)+" ")])}},{key:"trainLink",fn:function(text, record){return _c('span',{staticClass:"tab-link-style",staticStyle:{"color":"#4A8A5D","cursor":"pointer"},on:{"click":function($event){return _vm.copyLink($event, text, record)}}},[_vm._v(" "+_vm._s(record.isCopyTrainingLink ? "Link Copied" : "Copy Link")+" ")])}},{key:"qrCode",fn:function(text, record){return _c('span',{on:{"click":function($event){return _vm.clickToDownload(record)}}},[_c('span',{staticClass:"admin-training-brand__click-oper",staticStyle:{"color":"#4A8A5D"}},[_vm._v(_vm._s(record.isDownload ? "Downloaded" : "Download"))])])}},{key:"publicOper",fn:function(text, record){return _c('span',{},[_c('a-switch',{staticClass:"admin-training-switch",staticStyle:{"background-image":"none"},attrs:{"checked":text},on:{"change":function($event){return _vm.onChangePublic(record)}}})],1)}},{key:"actionOper",fn:function(text, record){return _c('span',{staticClass:"admin-training-brand__click-oper",on:{"click":function($event){return _vm.clickToView(record)}}},[_vm._v(" View ")])}}])},[_c('span',{attrs:{"slot":"nameTitle"},slot:"nameTitle"},[_vm._v(" Brand "),_c('a-icon',{class:_vm.sortBrand.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortBrand.toString().length > 0 && _vm.sortBrand === 'descend' ? 180 : 0},on:{"click":function($event){return _vm.sortByField('name')}}})],1),_c('span',{attrs:{"slot":"productTitle"},slot:"productTitle"},[_vm._v(" Products "),_c('a-icon',{class:_vm.sortProductCount.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortProductCount.toString().length > 0 &&
          _vm.sortProductCount === 'descend'
            ? 180
            : 0},on:{"click":function($event){return _vm.sortByField('productCount')}}})],1),_c('span',{attrs:{"slot":"publicTitle"},slot:"publicTitle"},[_vm._v(" Public "),_c('a-icon',{class:_vm.sortPublicCount.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortPublicCount.toString().length > 0 &&
          _vm.sortPublicCount === 'descend'
            ? 180
            : 0},on:{"click":function($event){return _vm.sortByField('publicCount')}}})],1),_c('span',{attrs:{"slot":"publishTitle"},slot:"publishTitle"},[_vm._v(" Published "),_c('a-icon',{class:_vm.sortPublishCount.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortPublishCount.toString().length > 0 &&
          _vm.sortPublishCount === 'descend'
            ? 180
            : 0},on:{"click":function($event){return _vm.sortByField('publishedCount')}}})],1),_c('span',{attrs:{"slot":"modifyTitle"},slot:"modifyTitle"},[_vm._v(" Modified "),_c('a-icon',{class:_vm.sortModified.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortModified.toString().length > 0 && _vm.sortModified === 'descend'
            ? 180
            : 0},on:{"click":function($event){return _vm.sortByField('modified')}}})],1),_c('span',{attrs:{"slot":"beautyfluentTitle"},slot:"beautyfluentTitle"},[_vm._v(" BeautyFluent "),_c('a-icon',{class:_vm.sortBeauty.toString().length > 0
            ? 'admin-training-sort-active'
            : 'admin-training-sort-gray',attrs:{"type":"caret-up","rotate":_vm.sortBeauty.toString().length > 0 && _vm.sortBeauty === 'descend'
            ? 180
            : 0},on:{"click":function($event){return _vm.sortByField('beautyfluent')}}})],1)]),_c('QRCode',{ref:"brandQrcode",attrs:{"url":_vm.qrText}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }