<template lang="pug">
section.retailer-directory-page-placeholder
  .hero-body
    .container
      //router-link.title(class="has-cursor-pointer" to="/brand/retailers" tag="li") {{$t('Brands.BrandMainPage.newRetailers')}}
      //li.title Retailer Directory
      .all-users-view__wrap
        .h4.all-brand All Retailers
        .user-index__block
          .index-box(@click="retailersStartsWith='' , fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == '' }") All
          .index-box(@click="retailersStartsWith='A', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'A' }") A
          .index-box(@click="retailersStartsWith='B', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'B' }") B
          .index-box(@click="retailersStartsWith='C', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'C' }") C
          .index-box(@click="retailersStartsWith='D', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'D' }") D
          .index-box(@click="retailersStartsWith='E', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'E' }") E
          .index-box(@click="retailersStartsWith='F', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'F' }") F
          .index-box(@click="retailersStartsWith='G', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'G' }") G 
          .index-box(@click="retailersStartsWith='H', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'H' }") H 
          .index-box(@click="retailersStartsWith='I', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'I' }") I 
          .index-box(@click="retailersStartsWith='J', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'J' }") J
          .index-box(@click="retailersStartsWith='K', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'K' }") K
          .index-box(@click="retailersStartsWith='L', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'L' }") L 
          .index-box(@click="retailersStartsWith='M', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'M' }") M 
          .index-box(@click="retailersStartsWith='N', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'N' }") N 
          .index-box(@click="retailersStartsWith='O', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'O' }") O 
          .index-box(@click="retailersStartsWith='P', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'P' }") P 
          .index-box(@click="retailersStartsWith='Q', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'Q' }") Q 
          .index-box(@click="retailersStartsWith='R', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'R' }") R 
          .index-box(@click="retailersStartsWith='S', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'S' }") S 
          .index-box(@click="retailersStartsWith='T', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'T' }") T 
          .index-box(@click="retailersStartsWith='U', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'U' }") U 
          .index-box(@click="retailersStartsWith='V', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'V' }") V 
          .index-box(@click="retailersStartsWith='W', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'W' }") W 
          .index-box(@click="retailersStartsWith='X', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'X' }") X 
          .index-box(@click="retailersStartsWith='Y', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'Y' }") Y 
          .index-box(@click="retailersStartsWith='Z', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == 'Z' }") Z 
          .index-box(@click="retailersStartsWith='#', fetchRetailers()")
            a(:class="{ 'dot': retailersStartsWith == '#' }") #

        .all-details__wrap
          ul(v-if="retailers.filter(retailer => retailer.name.charAt(0).match(/^[0-9]+$/)).length")
            li #
            li(v-for="retailer in retailers.filter(retailer => retailer.name.charAt(0).match(/^[0-9]+$/))")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailers.filter(retailer => retailer.name.charAt(0).match(/^[0-9]+$/)).length==0 && retailersStartsWith=='#' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('A').length")
            li A
            li(v-for="retailer in retailersGroup('A')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('A').length==0 && retailersStartsWith=='A' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('B').length")
            li B
            li(v-for="retailer in retailersGroup('B')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('B').length==0 && retailersStartsWith=='B' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('C').length")
            li C
            li(v-for="retailer in retailersGroup('C')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('C').length==0 && retailersStartsWith=='C' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('D').length")
            li D
            li(v-for="retailer in retailersGroup('D')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('D').length==0 && retailersStartsWith=='D' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('E').length") 
            li E
            li(v-for="retailer in retailersGroup('E')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('E').length==0 && retailersStartsWith=='E' && !fetchInProgress") No Retailers 
          ul(v-if="retailersGroup('F').length") 
            li F
            li(v-for="retailer in retailersGroup('F')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('F').length==0 && retailersStartsWith=='F' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('G').length") 
            li G
            li(v-for="retailer in retailersGroup('G')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('G').length==0 && retailersStartsWith=='G' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('H').length") 
            li H
            li(v-for="retailer in retailersGroup('H')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('H').length==0 && retailersStartsWith=='H' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('I').length") 
            li I
            li(v-for="retailer in retailersGroup('I')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('I').length==0 && retailersStartsWith=='I' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('J').length") 
            li J
            li(v-for="retailer in retailersGroup('J')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('J').length==0 && retailersStartsWith=='J' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('K').length") 
            li K
            li(v-for="retailer in retailersGroup('K')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('K').length==0 && retailersStartsWith=='K' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('L').length") 
            li L
            li(v-for="retailer in retailersGroup('L')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('L').length==0 && retailersStartsWith=='L' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('M').length") 
            li M
            li(v-for="retailer in retailersGroup('M')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('M').length==0 && retailersStartsWith=='M' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('N').length") 
            li N
            li(v-for="retailer in retailersGroup('N')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('N').length==0 && retailersStartsWith=='N' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('O').length") 
            li O
            li(v-for="retailer in retailersGroup('O')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('O').length==0 && retailersStartsWith=='O' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('P').length") 
            li P
            li(v-for="retailer in retailersGroup('P')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('P').length==0 && retailersStartsWith=='P' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('Q').length") 
            li Q
            li(v-for="retailer in retailersGroup('Q')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('Q').length==0 && retailersStartsWith=='Q' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('R').length") 
            li R
            li(v-for="retailer in retailersGroup('R')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('R').length==0 && retailersStartsWith=='R' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('S').length") 
            li S
            li(v-for="retailer in retailersGroup('S')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('S').length==0 && retailersStartsWith=='S' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('T').length") 
            li T
            li(v-for="retailer in retailersGroup('T')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('T').length==0 && retailersStartsWith=='T' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('U').length") 
            li U
            li(v-for="retailer in retailersGroup('U')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('U').length==0 && retailersStartsWith=='U' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('V').length") 
            li V
            li(v-for="retailer in retailersGroup('V')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('V').length==0 && retailersStartsWith=='V' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('W').length") 
            li W
            li(v-for="retailer in retailersGroup('W')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('W').length==0 && retailersStartsWith=='W' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('X').length") 
            li X
            li(v-for="retailer in retailersGroup('X')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('X').length==0 && retailersStartsWith=='X' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('Y').length") 
            li Y
            li(v-for="retailer in retailersGroup('Y')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('Y').length==0 && retailersStartsWith=='Y' && !fetchInProgress") No Retailers
          ul(v-if="retailersGroup('Z').length") 
            li Z
            li(v-for="retailer in retailersGroup('Z')")
              .retailer-item(v-if="isAdmin")
                router-link(:to="{ name: isAdminBrandrRoute?'adminBrandRetailerProfile':'retailerProfileAdmin', params: { retailerId: retailer.id }}") {{ retailer.name }}
              .retailer-item(v-else)
                router-link(:to="{ name: 'brandRetailerProfile', params: { retailerId: retailer.id }}") {{ retailer.name }}
          a.no-retailers(v-if="retailersGroup('Z').length==0 && retailersStartsWith=='Z' && !fetchInProgress") No Retailers
</template>

<script>
import constants from "@/config"
export default {
  data() {
    return {
      query: { _limit: "1" },
      fetchInProgress: false,
      retailersStartsWith: ""
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isAdminBrandrRoute() {
      return constants.adminBrandRoutes.includes(this.$route.name)
    },
    retailers() {
      let retailerArray = this.$store.getters["retailer/retailers"]
      return retailerArray.filter(retailer => this.selectedRetailersGroup(retailer) === true)
    }
  },
  async created() {
    if (this.isAdmin) {
      this.query["_jwt-c_id"] = this.$route.params.retailerId
      this.query["_jwt-c_type"] = "retailer"
    }
    this.fetchRetailers()
  },
  methods: {
    async fetchRetailers() {
      this.fetchInProgress = true
      await this.$store.dispatch("retailer/fetchAllRetailers", {
        params: { status: "approved" }
      })
      this.fetchInProgress = false
    },
    selectedRetailersGroup(retailer) {
      if (this.retailersStartsWith == "#") {
        if (retailer.name.charAt(0).match(/^[0-9]+$/)) {
          return true
        }
      }
      if (
        retailer.name.startsWith(this.retailersStartsWith.toUpperCase()) ||
        retailer.name.startsWith(this.retailersStartsWith.toLowerCase())
      ) {
        return true
      }
      return false
    },
    retailersGroup(alphabet) {
      return this.retailers.filter(
        retailer =>
          retailer.name.startsWith(alphabet.toUpperCase()) ||
          retailer.name.startsWith(alphabet.toLowerCase())
      )
    }
  }
}
</script>

<style lang="sass">
.no-retailers
  font-size: 14px
  color: #000
  text-align: center
  margin-left: 274px
.dot
  height: 19px
  padding: 3px
  background-color: #bbb
  border-radius: 29%

.retailer-directory-page-placeholder
  min-height: calc(100vh - 160px)

  .hero-body
    padding-top: 0px !important
    padding-bottom: 0px !important
    justify-content: center
    align-items: flex-start !important
    display: flex

    .container
      .all-users-view__wrap
        padding-top: 20px
        max-width: 684px
        margin: 0 auto
        .all-brand
          font-size: 30px
          color: #000
          margin-bottom: 20px
          text-align: center

        .user-index__block
          padding-bottom: 18px
          min-width: 684px
          border-bottom: 1px solid #ddd
          display: flex
          align-items: center
          justify-content: center
          flex-wrap: wrap

          .index-box
            cursor: pointer
            // margin: 0px 8px
            a
              font-size: 12px
              margin: 8px
              color: #000
              position: relative
              font-weight: 600
              text-transform: uppercase
              &:hover
                text-decoration: underline

        .all-details__wrap
          padding: 20px 0px
          min-width: 860px
          flex-wrap: wrap
          display: flex
          align-items: flex-start

          ul
            list-style: none
            padding: 0
            margin: 0 30px 30px
            flex-basis: 15%
            li
              a
                font-size: 12px
                color: #000
                font-weight: 300
                &:hover
                  text-decoration: underline
</style>
