import { SubscriptionTypeEnum } from "@/models/SubscriptionTypeEnum";
import { SubscriptionTermEnum } from "@/models/SubscriptionTermEnum";
import { SubscriptionStatusEnum } from "@/models/SubscriptionStatusEnum";
import { ClientStatus } from "@/models/ClientStatusEnum";

function GetStatusName(brand, subscriptionStatusPrivateId) {
  if (
    brand.statusPrivateId == ClientStatus.Rejected &&
    subscriptionStatusPrivateId == SubscriptionStatusEnum.Pending
  ) {
    return "Brand Rejected";
  }
  return SubscriptionStatusEnum[subscriptionStatusPrivateId];
}
export class BrandSubscription {
  brandPrivateId = 0;
  typePrivateId = null;
  statusPrivateId = null;
  subscriptionTerm = null;
  subscriptionId = "";
  isActive = false;
  isAutoRenewal = false;
  hasBeautyFluentSubscription = 0;
  subscriptionId = null;
  paidAmountUsd = 0;
  startDate = null;
  endDate = null;
  anniversaryDate = null;
  unsubscribeTime = null;
  brand = null;

  constructor(model) {
    if (model) {
      this.brandPrivateId = model.brandPrivateId;
      this.typePrivateId = model.typePrivateId;
      this.statusPrivateId = model.statusPrivateId;
      this.subscriptionTerm = model.subscriptionTerm;
      this.isActive = model.isActive;
      this.isAutoRenewal = model.isAutoRenewal;
      this.hasBeautyFluentSubscription = model.hasBeautyFluentSubscription;
      this.subscriptionId = ("" + (model.subscriptionId || "")).trim();
      this.paidAmountUsd = model.paidAmountUsd;
      this.startDate =
        model.startDate != void 0
          ? new Date(model.startDate + "T00:00:00")
          : null;
      this.endDate =
        model.endDate != void 0 ? new Date(model.endDate + "T00:00:00") : null;
      this.anniversaryDate =
        model.anniversaryDate != void 0
          ? new Date(model.anniversaryDate + "T00:00:00")
          : null;
      this.unsubscribeTime = model.unsubscribeTime;
      this.brand = model.brand;
    }
  }

  toSubscriptionPlan() {
    return new SubscriptionPlan({
      name: "Basic", //SubscriptionTypeEnum[this.typePrivateId],
      brandPrivateId: this.brandPrivateId,
      typePrivateId: this.typePrivateId,
      subscriptionTerm: this.subscriptionTerm,
      statusPrivateId: this.statusPrivateId,
      subscriptionId: this.subscriptionId,
      retailerId: null,
      retailer: null,
      anniversaryDate: this.anniversaryDate,
      startDate: this.startDate,
      endDate: this.endDate,
      subscriptionTermName: SubscriptionTermEnum[this.subscriptionTerm],
      statusName: GetStatusName(this.brand, this.statusPrivateId),
    });
  }
}

export class BrandTrainingSubscription {
  privateId = 0;
  brandPrivateId = 0;
  subscriptionTerm = null;
  subscriptionId = "";
  statusPrivateId = null;
  retailerId = null;
  startAt = null;
  endAt = null;
  amount = null;
  couponAmount = null;
  originalPrice = null;
  discountPercent = null;
  isPublic = 0;
  couponCodePrivateId = null;
  couponCode = null;
  isAutoRenewal = null;
  anniversaryDate = null;
  paidCount = null;
  subscriptionId = null;
  retailer = null;

  subscriptionType = null;
  brand = null;

  constructor(model) {
    if (model) {
      this.privateId = model.privateId;
      this.brandPrivateId = model.brandPrivateId;
      this.subscriptionTerm = model.subscriptionTerms;
      this.statusPrivateId = model.statusPrivateId;
      this.retailerId = model.retailerId;
      this.startAt = model.startAt != void 0 ? new Date(model.startAt) : null;
      this.endAt = model.endAt != void 0 ? new Date(model.endAt) : null;
      this.amount = model.amount;
      this.couponAmount = model.couponAmount;
      this.originalPrice = model.originalPrice;
      this.discountPercent = model.discountPercent;
      this.isPublic = model.isPublic;
      this.couponCodePrivateId = model.couponCodePrivateId;
      this.couponCode = model.couponCode;
      this.isAutoRenewal = model.isAutoRenewal;
      this.anniversaryDate =
        model.anniversaryDate != void 0
          ? new Date(model.anniversaryDate + "T00:00:00")
          : null;
      this.paidCount = model.paidCount;
      this.subscriptionId = ("" + (model.subscriptionId || "")).trim();
      this.retailer = model.retailer;
      this.brand = model.brand;

      if (this.subscriptionType == void 0) {
        if (this.retailerId != null) {
          // premium
          this.subscriptionType = SubscriptionTypeEnum.Premium;
        } else {
          this.subscriptionType = SubscriptionTypeEnum.Plus;
        }
      }
    }
  }

  toSubscriptionPlan() {
    let name = `${SubscriptionTypeEnum[this.subscriptionType]}`;
    if (this.retailer) {
      name = `${name} ${this.retailer.name}`;
    }
    return new SubscriptionPlan({
      brandPrivateId: this.brandPrivateId,
      typePrivateId: this.typePrivateId,
      subscriptionTerm: this.subscriptionTerm,
      statusPrivateId: this.statusPrivateId,
      subscriptionId: this.subscriptionId,
      retailerId: this.retailerId,
      retailer: this.retailer,
      anniversaryDate: this.anniversaryDate,
      name: name,
      subscriptionTermName: SubscriptionTermEnum[this.subscriptionTerm],
      statusName: GetStatusName(this.brand, this.statusPrivateId), //SubscriptionStatusEnum[this.statusPrivateId],
      startDate: this.startAt,
      endDate: this.endAt,
    });
  }
}

export class SubscriptionPlan {
  brandPrivateId = null;
  typePrivateId = null;
  subscriptionTerm = null;
  subscriptionId = "";
  statusPrivateId = null;
  retailerId = null;
  retailer = null;
  startDate = null;
  endDate = null;
  anniversaryDate = null;
  name = "";
  subscriptionTermName = null;
  statusName = null;

  constructor(model) {
    if (model) {
      this.brandPrivateId = model.brandPrivateId;
      this.typePrivateId = model.typePrivateId;
      this.subscriptionTerm = model.subscriptionTerm;
      this.subscriptionId = model.subscriptionId;
      this.statusPrivateId = model.statusPrivateId;
      this.retailerId = model.retailerId;
      this.retailer = model.retailer;
      this.anniversaryDate = model.anniversaryDate;
      this.name = model.name;
      this.startDate = model.startDate;
      this.endDate = model.endDate;
      this.subscriptionTermName = model.subscriptionTermName;
      this.statusName = model.statusName;
    }
  }
}

export class AllSubscription {
  brandSubscription = null;
  brandTrainingSubscriptions = [];
  allSubscriptions = [];

  constructor(model) {
    if (model) {
      this.brandSubscription =
        model.brandSubscription != void 0
          ? new BrandSubscription(model.brandSubscription)
          : null;

      const subscriptionIdHash = {};
      let hasBF = false;

      this.brandTrainingSubscriptions =
        model.brandTrainingSubscriptions != void 0
          ? model.brandTrainingSubscriptions.map((s) => {
              const ps = new BrandTrainingSubscription(s);
              this.allSubscriptions.push(ps.toSubscriptionPlan());
              let key = ps.subscriptionId;
              if (key != "") {
                subscriptionIdHash[key] = true;
              }
              hasBF = true;
              return ps;
            })
          : [];

      if (this.brandSubscription != null) {
        let key = this.brandSubscription.subscriptionId;
        // if have BF remove the subscriptionId
        const platformSubscription = this.brandSubscription.toSubscriptionPlan();
        if (hasBF || subscriptionIdHash[key]) {
          platformSubscription.subscriptionId = "";
        }
        this.allSubscriptions.unshift(platformSubscription);
      }
    }
  }
}
