<template lang="pug">
div
  .top-title
    section.hero
      .hero-inner
        .plat-font(style='color:white') Landing Platform 101
      .guide
        .guide-text
          | Step-by-step instructions on how to set up and make the most of your Landing International profile
  .video-main-style(v-for='(item, index) in plat_text' :key='index')
    section.hero(style='height:778px;background-color:white;')
      .plat-title
        .plat-font {{item.platTitle}}
      .plat-subtitle
        h2.plat-sub-font {{item.platContent}}
      .plat-video
        iframe(style='width:100%;height:100%;' :src='item.platLink' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen='')
</template>

<script>
export default {
  computed: {
    plat_text() {
      return this.$t("Brands.BrandPlatform.PlatformItemList")
    }
  }
}
</script>

<style>
@font-face {
  font-family: "Proxima Nova";
  src: url("~@/assets/fonts/ProximaNova-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Playfair Display";
  src: url("~@/assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}
.video-main-style {
  border-bottom: 1px solid #c8c8c8;
  /*margin: 0 auto;*/
  text-align: center;
}
.video-main-style:nth-last-child(1) {
  border: none;
}
.hero {
  position: relative;
  background-color: #12433d;
  background-position: center;
  background-size: cover;
  height: 367px;
  padding: 0 20px;
  text-align: center;
  width: 100%;
  justify-content: center;
}
.hero-inner {
  position: absolute;
  top: 32.7%;
  width: 100%;
  margin: 0 auto;
}
.guide {
  margin: auto;
  position: absolute;
  bottom: 0px;
  left: 17.7%;
  right: 16.66%;
  width: 945px;
  height: 134px;
  background: #fff;
  color: #262626;
}
.guide-text {
  position: absolute;
  width: 86.24%;
  height: 50.75%;
  left: 6.14%;
  right: 7.62%;
  top: 23.88%;
  bottom: 25.37%;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
}
.plat-title {
  width: 100%;
  height: 64px;
  margin-bottom: 29px;
}
.plat-font {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  align-items: center;
  text-align: center;
  color: #12433d;
}
.plat-subtitle {
  width: 100%;
  height: 29px;
  margin-bottom: 88px;
}
.plat-sub-font {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}
.plat-video {
  width: 640px;
  height: 360px;
  margin: 0 auto;
}
</style>
