<template>
  <div class="print-order-modal__main">
    <a-modal
      v-model="printVisible"
      width="595px"
      :footer="null"
      :title="null"
      :closable="false"
      :mask-closable="false"
      @cancel="printVisible = false"
    >
      <div id="printContent">
        <div class="modal-main-print-order" style="padding: 11px;">
          <div class="print-order-title">
            <div>
              <p>LANDING INTERNATIONAL</p>
              <p>1330 W. 12th Street, Suite A</p>
              <p>Los Angeles, CA 90015</p>
              <!--            <p>{{ userInfo.addresses[0].addressee }}</p>-->
              <!--            <p>{{ `${userInfo.addresses[0].region},${userInfo.addresses[0].city} ${userInfo.addresses[0].postalCode}` }}</p>-->
              <p>P: 310-924-2696</p>
              <p>Orders@landingintl.com</p>
            </div>
            <div>
              <img src="@/assets/img/orders/print-landing-voyagelanding.png" alt="printImg" />
              <p class="modal-title-right">{{ printTabTxt }}</p>
            </div>
          </div>
          <div style="margin-top: 20px;">
            <a-row v-if="tabId === 2">
              <a-col :span="10" style="padding-top: 85px;">
                <p class="print-black-txt" style="margin-bottom: 11px;">Bill To:</p>
                <p class="print-gray-txt" style="margin-bottom: 8px;">
                  {{ retailer.name }}
                </p>
                <p class="print-gray-txt" style="margin-bottom: 8px;">
                  {{ headquarters.streetAddressLine_1 }}
                </p>
                <p
                  v-if="headquarters.streetAddressLine_2"
                  class="print-gray-txt"
                  style="margin-bottom: 8px;"
                >
                  {{ headquarters.streetAddressLine_2 }}
                </p>
                <p v-if="headquarters.city" class="print-gray-txt" style="margin-bottom: 8px;">
                  {{ headquarters.city }}, {{ headquarters.region }} {{ headquarters.postalCode }}
                </p>
                <p class="print-gray-txt" style="margin-bottom: 8px;">
                  {{ countryName(headquarters.country.id) }}
                </p>
              </a-col>
              <a-col :span="14" style="text-align: right; ">
                <div style="text-align: right; float: right; margin-left: 18px;">
                  <div style="margin-bottom: 18px;">
                    <div class="print-black-txt">Landing Reference ID</div>
                    <div class="print-gray-txt">{{ orderItem.landingReferenceId }}</div>
                  </div>
                  <div style="margin-bottom: 18px;">
                    <div class="print-black-txt">Reference Number</div>
                    <div class="print-gray-txt">
                      {{
                        orderItem.brandReferenceId
                          ? orderItem.brandReferenceId
                          : orderItem.retailerReferenceId
                          ? orderItem.retailerReferenceId
                          : "--"
                      }}
                    </div>
                  </div>
                  <div style="margin-bottom: 18px;">
                    <div class="print-black-txt">Tracking Number</div>
                    <div class="print-gray-txt">
                      {{
                        orderItem.airShipments && orderItem.airShipments.length > 0
                          ? orderItem.airShipments[0].trackingId
                          : "--"
                      }}
                    </div>
                  </div>
                </div>
                <div style="text-align: right; float: right;">
                  <div style="margin-bottom: 18px;">
                    <div class="print-black-txt">Ship Method:</div>
                    <div class="print-gray-txt">
                      {{
                        orderItem.airShipments && orderItem.airShipments.length > 0
                          ? orderItem.airShipments[0].provider.name
                          : "--"
                      }}
                    </div>
                  </div>
                  <!-- <div style="margin-bottom: 18px;">
                    <div class="print-black-txt"># of Cartons:</div>
                    <div class="print-gray-txt">
                      {{
                        orderItem.airShipments && orderItem.airShipments.length > 0
                          ? orderItem.airShipments[0].totalCartonQuantity
                          : "--"
                      }}
                    </div>
                  </div> -->
                  <div style="margin-bottom: 18px;">
                    <div class="print-black-txt">Purchase Order Date:</div>
                    <div class="print-gray-txt">{{ orderItem.orderDate }}</div>
                  </div>
                  <div>
                    <div class="print-black-txt">Terms:</div>
                    <div class="print-gray-txt">
                      {{ orderItem.recordedRetailerPaymentTerm.name }}
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
            <a-row v-else-if="tabId === 3">
              <a-col :span="12">
                <div class="print-black-txt" style="margin-top: 20px; margin-bottom: 6px;">
                  Bill to:
                </div>
                <div class="print-gray-txt" style="margin-bottom: 8px;">
                  {{ retailer.name }}
                </div>
                <div class="print-gray-txt" style="margin-bottom: 8px;">
                  {{ headquarters.streetAddressLine_1 }}
                </div>
                <div
                  v-if="headquarters.streetAddressLine_2"
                  class="print-gray-txt"
                  style="margin-bottom: 8px;"
                >
                  {{ headquarters.streetAddressLine_2 }}
                </div>
                <div v-if="headquarters.city" class="print-gray-txt" style="margin-bottom: 8px;">
                  {{ headquarters.city }}, {{ headquarters.region }} {{ headquarters.postalCode }}
                </div>
                <div class="print-gray-txt" style="margin-bottom: 8px;">
                  {{ countryName(headquarters.country.id) }}
                </div>
              </a-col>
              <a-col :span="12" style="text-align: right;">
                <div style="margin-bottom: 18px;">
                  <div class="print-black-txt">Invoice #</div>
                  <div class="print-gray-txt">
                    {{ orderItem.invoiceId ? orderItem.invoiceId : "--" }}
                  </div>
                </div>
                <div style="margin-bottom: 18px;">
                  <div class="print-black-txt">Landing Reference ID</div>
                  <div class="print-gray-txt">
                    {{ orderItem.landingReferenceId }}
                  </div>
                </div>
                <div style="margin-bottom: 18px;">
                  <div class="print-black-txt">Reference Number</div>
                  <div class="print-gray-txt">
                    {{
                      orderItem.brandReferenceId
                        ? orderItem.brandReferenceId
                        : orderItem.retailerReferenceId
                        ? orderItem.retailerReferenceId
                        : "--"
                    }}
                  </div>
                </div>
                <div class="date-and-term-style">
                  <div style="margin-bottom: 18px;">
                    <div class="print-black-txt">Invoice Date</div>
                    <div class="print-gray-txt">{{ getInvoicedDate() }}</div>
                  </div>
                  <div style="margin-bottom: 18px;">
                    <div class="print-black-txt">Terms</div>
                    <div class="print-gray-txt">
                      {{ orderItem.recordedRetailerPaymentTerm.name }}
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
            <a-row v-else>
              <a-col :span="16">
                <div class="print-black-txt" style="margin-top: 50px; margin-bottom: 9px;">
                  Retailer:
                </div>
                <div class="print-gray-txt" style="margin-bottom: 6px;">
                  {{
                    orderItem && orderItem.shipToAddress ? orderItem.shipToAddress.addressee : "--"
                  }}
                </div>
                <div class="print-gray-txt" style="margin-bottom: 6px;">
                  {{
                    orderItem && orderItem.shipToAddress
                      ? orderItem.shipToAddress.streetAddressLine_1
                      : "--"
                  }}
                </div>
                <div class="print-gray-txt">
                  {{
                    orderItem &&
                    orderItem.shipToAddress &&
                    orderItem.shipToAddress.streetAddressLine_2
                      ? orderItem.shipToAddress.streetAddressLine_2
                      : ""
                  }}
                </div>
                <div class="print-gray-txt" style="margin-bottom: 8px;">
                  {{
                    orderItem && orderItem.shipToAddress && orderItem.shipToAddress.city
                      ? `${orderItem.shipToAddress.city}, ${orderItem.shipToAddress.region}  ${orderItem.shipToAddress.postalCode}`
                      : ""
                  }}
                </div>
                <div class="print-gray-txt">
                  {{
                    orderItem && orderItem.shipToAddress && orderItem.shipToAddress.country
                      ? countryNameById(orderItem.shipToAddress.country.id)
                      : ""
                  }}
                </div>
                <div style="float: right">
                  <div style="float: right; display: inline-block; margin-left: 25px;">
                    <div class="print-black-txt">Cancel Date</div>
                    <div class="print-gray-txt" style="margin-bottom: 6px;">
                      {{ orderItem.cancelDate }}
                    </div>
                  </div>
                  <div style="float: right; display: inline-block;">
                    <div class="print-black-txt">Ship Date</div>
                    <div class="print-gray-txt" style="margin-bottom: 6px;">
                      {{ formatActualShipDate }}
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="8" style="text-align: right;">
                <div style="margin-bottom: 12px;">
                  <div class="print-black-txt">Invoice #</div>
                  <div class="print-gray-txt">
                    {{ orderItem.invoiceId ? orderItem.invoiceId : "--" }}
                  </div>
                </div>
                <div style="margin-bottom: 12px;">
                  <div class="print-black-txt">Landing Ref ID</div>
                  <div class="print-gray-txt">{{ orderItem.landingReferenceId }}</div>
                </div>
                <div style="margin-bottom: 12px;">
                  <div class="print-black-txt">Reference ID</div>
                  <div class="print-gray-txt">
                    {{ orderItem.retailerReferenceId ? orderItem.retailerReferenceId : "--" }}
                  </div>
                </div>
                <div style="margin-bottom: 12px;">
                  <div class="print-black-txt">Payment Date</div>
                  <div class="print-gray-txt">{{ getPayDate }}</div>
                </div>
                <div>
                  <div class="print-black-txt">Order Date</div>
                  <div class="print-gray-txt">{{ orderItem.orderDate }}</div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="modal-main-print-order">
          <div class="modal-print-order-table" style="margin-left: -24px; margin-right: -24px;">
            <table v-if="tabId === 2" class="table is-fullwidth">
              <thead>
                <tr class="table-tr-color-style">
                  <th class="print-order-table-td-style" width="17%">SKU</th>
                  <th class="print-order-table-td-style" width="34%">Name</th>
                  <th class="print-order-table-td-style" width="16%">Quantity</th>
                  <th class="print-order-table-td-style" width="17%">MSRP</th>
                  <th class="print-order-table-td-style" width="16%">Notes</th>
                </tr>
              </thead>
              <tbody v-for="item in orderItem.items" :key="item.id">
                <td class="print-orders__table-cell">{{ item.product.sku }}</td>
                <td class="print-orders__table-cell">{{ item.product.name }}</td>
                <td class="print-orders__table-cell">
                  {{
                    item.adjustedQuantity || item.adjustedQuantity === 0
                      ? item.adjustedQuantity
                      : item.quantity
                  }}
                </td>
                <td class="print-orders__table-cell">
                  {{
                    item.quantityType.id === "purchase" && item.recordedMsrpUsd
                      ? `${priceFormat(item.recordedMsrpUsd)}`
                      : "$0.00"
                  }}
                </td>
                <td class="print-orders__table-cell">
                  <a-input
                    v-model="item.notes"
                    class="table-notes"
                    placeholder="Notes"
                    style="width: 98px"
                  />
                  <div class="table-notes-print">{{ item.notes }}</div>
                </td>
              </tbody>
              <tfoot style="display: none;">
                <tr>
                  <td colspan="5" class="table-foot-txt">
                    <p style="margin-bottom: 15px;">
                      Shipping: <span style="margin-left: 12px;">5%</span>
                    </p>
                    <p>
                      Other: <span style="margin-left: 25px;">$120.00</span
                      ><span style="margin-left: 41px; ">Credit</span>
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
            <table v-else class="table is-fullwidth">
              <thead>
                <tr class="table-tr-color-style">
                  <th class="print-order-table-td-style" width="17%">SKU</th>
                  <th class="print-order-table-td-style" width="34%">Name</th>
                  <th class="print-order-table-td-style" width="16%">Quantity</th>
                  <th class="print-order-table-td-style" width="17%">Unit price</th>
                  <th class="print-order-table-td-style" width="16%">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in orderItem.items" :key="item.id">
                  <td class="print-orders__table-cell">{{ item.product.sku }}</td>
                  <td class="print-orders__table-cell">{{ item.product.name }}</td>
                  <td class="print-orders__table-cell">
                    {{
                      item.adjustedQuantity || item.adjustedQuantity === 0
                        ? item.adjustedQuantity
                        : item.quantity
                    }}
                  </td>
                  <td class="print-orders__table-cell">
                    {{
                      item.quantityType.id === "purchase" && item.calculatedWholesalePriceUsd
                        ? `${priceFormat(item.calculatedWholesalePriceUsd)}`
                        : "$0.00"
                    }}
                  </td>
                  <td class="print-orders__table-cell">
                    {{
                      item.quantityType.id === "purchase" && item.calculatedWholesaleCostUsd
                        ? `${priceFormat(item.calculatedWholesaleCostUsd)}`
                        : "$0.00"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!--          router-link.retailer-orders__table-row(:to="{ name: isAdmin?'adminRetailerOrder':'retailerOrder', params: { id: order.id }}" v-for="order in retailerOrders" tag="tr")-->
          </div>
          <div v-if="tabId === 2" class="bottom-content" style="margin-bottom: 10px;">
            <div class="bottom-note">
              <a-textarea
                id="textarea-notes"
                v-model="orderItem.notes"
                placeholder="Notes"
                wrap="wrap"
                style="height: 123px; width: 100%;"
                :auto-size="{ minRows: 3 }"
              />
              <div id="print-textarea-text"></div>
            </div>
          </div>
          <!--          <a-row v-else style="margin-top: 30px; padding-left: 11px; padding-right: 11px;">-->
          <div v-else class="print-count-and-input-content">
            <div style="width: 240px">
              <PaymentCount :order="orderItem" :has-retailer="true" :tab-id="tabId"></PaymentCount>
            </div>
            <div class="bottom-invoice-note">
              <a-textarea
                v-if="tabId === 3"
                id="textarea-notes-tabThr"
                v-model="orderItem.notes"
                wrap="wrap"
                placeholder="Notes"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
              <a-textarea
                v-else
                id="textarea-notes-other"
                v-model="orderItem.paymentAdviceNote"
                wrap="wrap"
                placeholder="Notes"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
              <div id="print-textarea-notes-tabThr"></div>
              <div id="print-textarea-notes-other"></div>
            </div>
          </div>
          <!--            <a-col :span="12">-->
          <!--              <div style="margin-bottom: 8px; height: 14px;">-->
          <!--                <div class="bottom-txt-gray">-->
          <!--                  SUBTOTAL-->
          <!--                </div>-->
          <!--                <div class="bottom-txt-price">-->
          <!--                  {{ priceFormat(orderItem.calculatedWholesaleCostUsd) }}-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <template v-for="item in orderItem.charges">-->
          <!--                <div-->
          <!--                  v-if="item.ownedBy.id === 'retailer' || item.ownedBy.id === 'brand'"-->
          <!--                  :key="`retailer_${item.privateId}`"-->
          <!--                  style="margin-bottom: 13px; height: 14px;"-->
          <!--                >-->
          <!--                  <div class="bottom-txt-gray">-->
          <!--                    {{-->
          <!--                      item.chargeType.id === "custom"-->
          <!--                        ? item.ownedBy.id === "brand"-->
          <!--                          ? "ADDITIONAL CHARGE"-->
          <!--                          : "OTHER TERMS"-->
          <!--                        : item.chargeType.name.toString().toUpperCase()-->
          <!--                    }}-->
          <!--                  </div>-->
          <!--                  <div class="bottom-txt-price">-->
          <!--                    {{-->
          <!--                      item.paidBy.id === "brand"-->
          <!--                        ? `(${priceFormat(item.calculatedCostUsd)})`-->
          <!--                        : priceFormat(item.calculatedCostUsd)-->
          <!--                    }}-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </template>-->
          <!--              <div style="margin-bottom: 24px; height: 14px;">-->
          <!--                <div class="bottom-txt-gray" style="font-weight: bold; color: #000">-->
          <!--                  TOTAL-->
          <!--                </div>-->
          <!--                <div class="bottom-txt-price" style="font-weight: bold;">-->
          <!--                  {{ getRetailerTotal() }}-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <template v-for="item in orderItem.charges">-->
          <!--                <div-->
          <!--                  v-if="item.ownedBy.id === 'landing'"-->
          <!--                  :key="`brand_${item.privateId}`"-->
          <!--                  style="margin-bottom: 8px; height: 14px;"-->
          <!--                >-->
          <!--                  <div class="bottom-txt-gray">-->
          <!--                    {{-->
          <!--                      item.chargeType.id === "custom"-->
          <!--                        ? "OTHER TERMS"-->
          <!--                        : item.chargeType.id === "commission"-->
          <!--                        ? "TRANSACTION FEE"-->
          <!--                        : item.chargeType.name.toString().toUpperCase()-->
          <!--                    }}-->
          <!--                  </div>-->
          <!--                  <div class="bottom-txt-price">-->
          <!--                    {{-->
          <!--                      item.paidBy.id === "retailer"-->
          <!--                        ? `(${priceFormat(item.calculatedCostUsd)})`-->
          <!--                        : priceFormat(item.calculatedCostUsd)-->
          <!--                    }}-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </template>-->
          <!--              <div style="margin-bottom: 8px; height: 16px;">-->
          <!--                <div class="bottom-txt-payment" style="float: left;">-->
          <!--                  PAYMENT-->
          <!--                </div>-->
          <!--                <div class="bottom-txt-payment" style="float: right;">-->
          <!--                  {{ getBrandPayment() }}-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </a-col>-->
          <!--          </a-row>-->
        </div>
      </div>
      <a-row>
        <a-col :span="16"></a-col>
        <a-col :span="8" style="text-align: right;">
          <a-button
            type="primary"
            style="margin-right: 10px; background-color: #4A8A5D; border-color: #D9D9D9;"
            class="action-btn"
            @click="clickToPrint"
          >
            Print
          </a-button>
          <a-button class="action-btn" @click="clickToClose">Close</a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import {
  getOrderBrandPayAmount,
  getOrderRetailerPayAmount,
  priceFormat,
  formatLifecycleDate
} from "@/utils/validate"
import PaymentCount from "@/components/orders/orderComponent/orderPaymentCount"

export default {
  name: "PrintOrderModal",
  components: {
    PaymentCount
  },
  props: {
    orderItem: {
      type: Object,
      default: () => {}
    },
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      priceFormat,
      printVisible: false,
      tabId: 0,
      printTabTxt: "",
      retailerPaymentAmount: 0
    }
  },
  computed: {
    countries() {
      return this.$store.getters["reference/countries"]
    },
    getPayDate() {
      if (this.orderItem && this.orderItem.lifecycle) {
        const result = this.orderItem.lifecycle.filter(item => item.status.id === "paid-to-brand")
        if (result[0].transitionedAt) {
          return formatLifecycleDate(result[0].transitionedAt)
        }
      }
      return "--"
    },
    formatActualShipDate() {
      if (
        this.orderItem &&
        this.orderItem.airShipments &&
        this.orderItem.airShipments.length !== 0
      ) {
        return this.orderItem.airShipments[0].actualShipDate
      } else {
        return "--"
      }
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    headquarters() {
      // console.log(2222, this.$store.getters["brandAddress/headquarters"])
      return this.retailer.addresses.filter(item => item.hq)[0]
    }
  },
  methods: {
    openDialog(tabId) {
      this.printVisible = true
      this.tabId = tabId
      if (tabId === 2) {
        this.printTabTxt = "Packing List"
      }
      if (tabId === 3) {
        this.printTabTxt = "Invoice"
      }
      if (tabId === 4) {
        this.printTabTxt = "Payment Advice"
      }
    },
    countryName(id) {
      return this.$store.getters["reference/countryName"](id)
    },
    getRetailerTotal() {
      this.retailerPaymentAmount = getOrderRetailerPayAmount(this.orderItem)
      return priceFormat(this.retailerPaymentAmount)
    },
    getBrandPayment() {
      let brandPayment = this.retailerPaymentAmount - getOrderBrandPayAmount(this.orderItem)
      return priceFormat(brandPayment)
    },
    getInvoicedDate() {
      if (this.orderItem && this.orderItem.airShipments) {
        for (let i = 0; i < this.orderItem.airShipments.length; i++) {
          let lifeStatus = this.orderItem.airShipments[i]
          if (lifeStatus.orderStatus.id === "delivered" && lifeStatus.actualShipDate) {
            return lifeStatus.actualShipDate
            // return formatLifecycleDate(lifeStatus.transitionedAt)
          }
        }
      }
      return "--"
    },
    clickToPrint() {
      const btns = document.getElementsByClassName("action-btn")
      if (this.tabId === 2) {
        const textareaNotes = document.getElementById("textarea-notes")
        document.getElementById("print-textarea-text").innerHTML = textareaNotes.value
      } else if (this.tabId === 3) {
        const textareaNotes1 = document.getElementById("textarea-notes-tabThr")
        document.getElementById("print-textarea-notes-tabThr").innerHTML = textareaNotes1.value
      } else if (this.tabId === 4) {
        const textareaNotes2 = document.getElementById("textarea-notes-other")
        document.getElementById("print-textarea-notes-other").innerHTML = textareaNotes2.value
      }
      if (btns && btns.length > 0) {
        for (let i = 0; i < btns.length; i++) {
          btns[i].style.display = "none"
        }
      }
      window.print()
      if (btns && btns.length > 0) {
        for (let i = 0; i < btns.length; i++) {
          btns[i].style.display = "inline-block"
        }
      }
      this.updateOrder()
    },
    updateOrder() {
      let params = {
        id: this.orderItem.id,
        version: Number(this.orderItem.version) + 1,
        isBrandAccepted: this.orderItem.brandAccepted,
        isRetailerAccepted: this.orderItem.retailerAccepted,
        paymentAdviceNote: this.orderItem.paymentAdviceNote
        // status: {
        //   id: constants.orderStatus.paidToBrand
        // },
      }
      this.fetchUploadOrder(params)
    },
    async fetchUploadOrder(order) {
      let params = {}
      if (this.$route.name == "adminBrandOrder") {
        params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      }
      if (this.$route.name == "adminRetailerOrder") {
        params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      }
      await this.$store.dispatch("order/updateStatus", { order, params })
      await this.$store.dispatch("order/getOrder", this.$route.params.id)
    },
    clickToClose() {
      this.printVisible = false
      if (this.tabId !== 4) {
        this.orderItem.notes = ""
        this.orderItem.paymentAdviceNote = ""
      }
    },
    countryNameById(countryId) {
      const country = this.countries.find(country => country.id === countryId)
      return country && country.name
    }
  }
}
</script>

<style lang="scss">
.print-order-modal__main {
  padding: 0px;
}

.modal-main-print-order {
  p {
    margin-bottom: 0;
  }

  .print-order-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    p {
      color: #000000;
      font-size: 10px;
      transform: scale(0.83, 0.83);
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
    .modal-title-right {
      font-size: 14px;
      color: #000000;
      margin-top: 17px;
      text-align: right;
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
  }
  .print-gray-txt {
    font-family: Proxima Nova Alt;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #8c8c8c;
  }
  .print-black-txt {
    font-family: Proxima Nova Alt;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000;
    margin-bottom: 5px;
  }
  .table-foot-txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    padding-top: 63px;
    padding-left: 35px;
  }
  .modal-print-order-table {
    margin-top: 45px;
  }

  .table-tr-color-style {
    background-color: #e2eeec;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-size: 10px;

    .print-order-table-td-style {
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .print-orders__table-cell {
    font-size: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  .bottom-content {
    padding-left: 11px;
    padding-right: 0px;
  }
  .bottom-note {
    width: 100%;
    min-height: 123px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .bottom-invoice-note {
    width: 260px;
    //display: inline-block;
    min-height: 123px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    //position: absolute;
    margin-left: 44px;
  }
  .date-and-term-style {
    display: flex;
    justify-content: space-between;
  }
  #print-textarea-text,
  .table-notes-print,
  #print-textarea-notes-tabThr,
  #print-textarea-notes-other {
    display: none;
  }
  .print-count-and-input-content {
    width: 100%;
    padding: 0 11px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  @media print {
    #textarea-notes,
    .table-notes,
    #textarea-notes-tabThr,
    #textarea-notes-other {
      display: none;
    }
    #print-textarea-text,
    .table-notes-print,
    #print-textarea-notes-tabThr,
    #print-textarea-notes-other {
      display: block;
      overflow: visible;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      //white-space: pre;
      white-space: pre-wrap;
    }
  }
}
</style>
