export var ClientStatus;
(function(ClientStatus) {
  ClientStatus[(ClientStatus["Floating"] = 1)] = "Floating";
  ClientStatus[(ClientStatus["Pending"] = 2)] = "Pending";
  ClientStatus[(ClientStatus["Approved"] = 3)] = "Approved";
  ClientStatus[(ClientStatus["Disabled"] = 4)] = "Disabled";
  ClientStatus[(ClientStatus["Rejected"] = 5)] = "Rejected";
  ClientStatus[(ClientStatus["Prospective"] = 6)] = "Prospective";
  ClientStatus[(ClientStatus["Preloaded"] = 7)] = "Preloaded";
})(ClientStatus || (ClientStatus = {}));
