var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-carousel',{ref:"imgList",staticClass:"carousel",attrs:{"arrows":"","after-change":_vm.onChange,"dot-position":_vm.dotPosition,"dots":false}},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,class:`carousel-item ${
          _vm.carouselAttrs[index].params
            ? _vm.carouselAttrs[index].params.map(item => {
                return item
              })
            : ' '
        }`},[_c('div',{staticClass:"arrow-area"},[_c('button',{staticClass:"arrow-prev",on:{"click":function($event){return _vm.carouselPage('prev')}}},[_c('img',{attrs:{"src":require("@/assets/img/icon/arrow-left.svg")}})]),_c('button',{staticClass:"arrow-next",on:{"click":function($event){return _vm.carouselPage('next')}}},[_c('img',{attrs:{"src":require("@/assets/img/icon/arrow-right.svg")}})])]),_c('div',{staticClass:"img-box",style:(`background-image: url(${require(`@/assets/img/agency/${_vm.carouselAttrs[index].imageSrc}`)})`)}),_c('div',{class:_vm.carouselAttrs[index].fontPosition == 'left' ? 'text-wrap left' : 'text-wrap right'},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"subhead"},[_vm._v(_vm._s(item.subhead))])]),(item.content)?_c('div',[_c('agency-detail',{attrs:{"data":item}})],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }