<template lang="pug">
.retailer-orders
  .progress-download-tips(v-if="isShowPercent")
    p Downloading
    a-progress(:percent="percentNumber" :stroke-color="{'0%': '#87d068','100%': '#1c7430',}")
  .retailer-order-content
    //.column.is-3
      .retailer-orders__filter
        p.retailer-orders__filter-column-name {{$t('Retailers.RetailerOrders.filter')}}
        OrdersFilter(:title="$t('Retailers.RetailerOrders.brand')" :items="brands" @filterSwitch="fetchOrders")
        //- PriceFilter(:title="$t('Retailers.RetailerOrders.orderAmount')" )
        OrdersFilter(:title="$t('Retailers.RetailerOrders.status')" :items="statuses" @filterSwitch="fetchOrders")
        //- CompletedOrdersFilter(:title="$t('Retailers.RetailerOrders.showCompleted')")
        //- DatesFilter(:title="$t('Retailers.RetailerOrders.orderDate')")
        //- DatesFilter(:title="$t('Retailers.RetailerOrders.shipDate')")
        //- DatesFilter(:title="$t('Retailers.RetailerOrders.cancelDate')")
    .column
      .columns.is-marginless
        .column.order-title-button
          p.retailer-orders__table-name
            span.search-brand-order(v-if="tabId === 4" @click="openSearchOrders") Search Orders
              a-icon.search-icon-style(type="search")
            span.clear-brand-order(v-if="tabId === 4" @click="clearSearchOrder") Clear Search
          div.csv-style(@click="isClickCSV=!isClickCSV") CSV
            a-icon(type="download")
            p.csv-select(v-if="isClickCSV")
              span.download-csv-select-one(@click="downloadOrderItems") Download Selected
              span.download-csv-select-two(@click="downloadOrder([])") Download All
      .column.is-narrow.brand-orders__sort-options
        .field.is-grouped.select-text-right
          .control
            .select
              select(v-model="value" @change="selectPagination(value)")
                option(v-for="visibleItemsCount in visibleItems" :key="visibleItemsCount.id" :value="visibleItemsCount.number") {{ visibleItemsCount.name }}
          //.has-text-right
            .brand-orders__orders-count {{ retailerOrders.length }} {{$t('Retailers.RetailerOrders.ordersSmall')}}
    .order-processing-tabs
      span(v-for="tab in tabText" :key="tab.id" :class="[tab.active ? 'tab-active' : '']" @click="handleTabs(tab)") {{ tab.name }}

    div(v-if="retailerOrders && retailerOrders.length === 0 && isShowLoading" class="spin-style-retailer")
      a-spin(tip="Loading...")
    table.table(v-else style="width: 100%")
      tbody.retailer-orders__table-header
        tr
          td.retailer-orders__table-head
            a-checkbox(@change="onChangeAll")
          td.retailer-orders__table-head Reference ID
            a-icon(style="cursor: pointer" :type="isReferenceIdSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('landing_reference_id','')")
          td.retailer-orders__table-head(v-if="tabId === 3") Retailer
            a-icon(style="cursor: pointer" :type="isRetailerName ? 'caret-down' : 'caret-up'" @click="sortOrderList('retailer_name','')")
          td.retailer-orders__table-head(v-if="tabId === 1 || tabId === 2 || tabId === 4 || tabId === 3 || tabId === 5") {{$t('Retailers.RetailerOrders.brand')}}
            a-icon(style="cursor: pointer" :type="isBrandName ? 'caret-down' : 'caret-up'" @click="sortOrderList('brand_name','')")
          td.retailer-orders__table-head {{$t('Retailers.RetailerOrders.total')}}
            a-icon(style="cursor: pointer" :type="isSubtotalSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('calculated_wholesale_cost_usd','')")
          td.retailer-orders__table-head(v-if="tabId === 1 || tabId === 2 || tabId === 4 || tabId === 5") Order Date
            a-icon(style="cursor: pointer" :type="isOrderDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('order_date','')")
          td.retailer-orders__table-head(v-if="tabId === 1") Ship by Date
            a-icon(style="cursor: pointer" :type="isShipByDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('ship_date','')")
          td.retailer-orders__table-head(v-if="tabId === 1") Cancel Date
            a-icon(style="cursor: pointer" :type="isCancelDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('cancel_date','')")
          td.retailer-orders__table-head(v-if="tabId === 3") Actural Ship Date
            a-icon(style="cursor: pointer" :type="isActualShipDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('ship_date', 'actual-ship-date')")
          td.retailer-orders__table-head(v-if="tabId === 3") Payment Date
            //a-icon(style="cursor: pointer" :type="isShipByDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('ship_date','')")
          td.retailer-orders__table-head(v-if="tabId === 2") Invoice Date
            a-icon(style="cursor: pointer" :type="isShipByDateSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('ship_date','')")
          td.retailer-orders__table-head PO Number
            a-icon(style="cursor: pointer" :type="isPONumberSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('retailer_reference_id','')")
          td.retailer-orders__table-head(v-if="tabId === 2 || tabId === 3 || tabId === 4") Invoice #
            a-icon(style="cursor: pointer" :type="isInvoiceSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('invoice_id','')")
          td.retailer-orders__table-head(v-if="tabId === 1 || tabId === 4 || tabId === 5") {{$t('Retailers.RetailerOrders.status')}}
            a-icon(style="cursor: pointer" :type="isStatusSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('status_private_id','')")
          td.retailer-orders__table-head(v-if="tabId === 2") Tracking Number
            a-icon(style="cursor: pointer" :type="isTrackingNumberSort ? 'caret-down' : 'caret-up'" @click="sortOrderList('tracking_id','')")
      tbody(v-for="order in retailerOrders")
        tr.retailer-orders__table-row
          td.retailer-orders__table-cell
            a-checkbox(v-model="order.checkedActive" @change="onChangeItem(order)")
          td.retailer-orders__table-cell.old-order-table-retailer
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ order.landingReferenceId }}
            a-tooltip(placement="topLeft" title="Inactive Order")
              a-icon(v-if="order.osvTag === 1" class="old-order-icon" type="double-left")
          //retailer
          td.retailer-orders__table-cell(v-if="tabId === 3")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ retailerNameById(order.retailer.id) }}
          //brand
          td.retailer-orders__table-cell(v-if="tabId === 1 || tabId === 2 || tabId === 4 || tabId === 3 || tabId === 5")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ brandNameById(order.brand.id) }}
          td.retailer-orders__table-cell
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ priceFormat(order.calculatedWholesaleCostUsd) }}
          //Order Date
          td.retailer-orders__table-cell(v-if="tabId === 1 || tabId === 2 || tabId === 4 || tabId === 5")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ order.orderDate }}
          //Ship by Date
          td.retailer-orders__table-cell(v-if="tabId === 1")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ order.shipDate }}
          td.retailer-orders__table-cell(v-if="tabId === 1")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ order.cancelDate }}
          //Actural Ship Date
          td.retailer-orders__table-cell(v-if="tabId === 3")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ formatActualShipDate(order) }}
          //Payment Date
          td.retailer-orders__table-cell(v-if="tabId === 3")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ formatPaymentDate(order) }}
          //Invoice Date
          td.retailer-orders__table-cell(v-if="tabId === 2")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ order.airShipments && order.airShipments.length > 0 ? order.airShipments[0].actualShipDate : '-' }}
          // PO Number
          td.retailer-orders__table-cell
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ order.retailerReferenceId ? order.retailerReferenceId : "-" }}
          // Invoice #
          td.retailer-orders__table-cell(v-if="tabId === 2 || tabId === 3 || tabId === 4")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ order.invoiceId ? order.invoiceId : "-"}}
          //status
          td.retailer-orders__table-cell(v-if="tabId === 1 || tabId === 4 || tabId === 5")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}") {{ checkOrderStatus(order) }}
          //Tracking Number
          td.retailer-orders__table-cell(v-if="tabId === 2")
            router-link(:to="{name: routeLinkToRetailerDetail(order),params: { id: order.id }}")
              span.tacking-number-style {{ formatTackingNumber(order)}}
    //.retailer-orders__no-orders(v-show="retailerOrders && retailerOrders.length === 0 && !isShowLoading") {{$t('Retailers.RetailerOrders.noOrdersYet')}}
    .retailer-pagination-style
      a-pagination.inventoryPagination(v-if="retailerOrders && retailerOrders.length !== 0" v-model="paginationValue" :default-current="1" :total="total" :default-page-size="currentNum" @change="handlePagination" size="small"
        :show-quick-jumper="true")
  ModalSearchOrder(ref="searchOrder" @getOrderParams="getOrderParams" :brandOrRetailer="setBrands" :user="2")
</template>

<script>
import OrdersFilter from "@/components/orders/OrdersFilter"
import PriceFilter from "@/components/orders/PriceFilter"
import DatesFilter from "@/components/orders/DatesFilter"
import CompletedOrdersFilter from "@/components/orders/CompletedOrdersFilter"
import Pagination from "@/components/Pagination"
import ModalSearchOrder from "@/components/brands/orders/modalSearchOrder"
import { downloadCsvData, formatLifecycleDate } from "@/utils/validate"
import constants from "@/config.js"

export default {
  components: {
    OrdersFilter,
    PriceFilter,
    DatesFilter,
    CompletedOrdersFilter,
    Pagination,
    ModalSearchOrder
  },
  data() {
    return {
      orderStatus: constants.orderStatus,
      visibleItems: [
        { id: 1, name: "View 50", number: 50 },
        { id: 2, name: "View 100", number: 100 }
      ],
      query: {},
      batchId: this.$route.query["batch-id"],
      brands: [],
      isEmptyCatalog: false,
      statuses: [],
      isClickCSV: false,
      tabId: 1,
      tabText: [
        {
          id: 1,
          name: "OPEN",
          active: true
        },
        {
          id: 2,
          name: "INVOICE",
          active: false
        },
        {
          id: 3,
          name: "PAID",
          active: false
        },
        {
          id: 4,
          name: "ALL",
          active: false
        }
        // {
        //   id: 5,
        //   name: "HISTORY",
        //   active: false
        // }
      ],
      currentNum: 50,
      isAllActive: false,
      retailerOrders: [],
      checkList: [],
      value: 50,
      sortValue: {
        "_order-by": "PREVIOUS",
        "_sort-by": "created_at"
      },
      paginationValue: 1,
      searchInfo: {},
      isShowLoading: false,
      // about sort
      isReferenceIdSort: false,
      isRetailerName: false,
      isBrandName: false,
      isSubtotalSort: false,
      isTotalSort: false,
      isOrderDateSort: false,
      isPaymentSort: false,
      isPaymentDateSort: false,
      isShipByDateSort: false,
      isCancelDateSort: false,
      isActualShipDateSort: false,
      isPONumberSort: false,
      isTrackingNumberSort: false,
      isInvoiceSort: false,
      isStatusSort: false,
      setBrands: [],
      downloadMaxNumber: 2000,
      percentNumber: 0,
      isShowPercent: false
    }
  },
  computed: {
    activeBrands() {
      return this.brands.filter(item => item.checked)
    },
    activeStatuses() {
      return this.statuses.filter(item => item.checked)
    },
    activeFilterItems() {
      return [...this.activeStatuses, ...this.activeBrands].filter(item => item.checked)
    },
    // retailerOrders() {
    //   return this.$store.getters["order/orders"].filter(item => item.status.id !== "deleted")
    // },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    total() {
      return this.$store.getters["order/pagination"].total
    }
    // setBrands() {
    //   const uniqueBrandsIds = [...new Set(this.retailerOrders.map(order => order.brand.id))]
    //   return uniqueBrandsIds.map(brandId => {
    //     return {
    //       checked: false,
    //       id: brandId,
    //       name: this.brandNameById(brandId)
    //     }
    //   })
    // }
    // routeLinkToRetailerDetail() {
    //   if (this.tabId === 5) {
    //     return this.admin || this.$route.name === "adminRetailerOrders"
    //       ? "adminRetailerOldOrderDetails"
    //       : "OldRetailerOrderDetails"
    //   } else {
    //     return this.admin || this.$route.name === "adminRetailerOrders"
    //       ? "adminRetailerOrder"
    //       : "retailerOrder"
    //   }
    // }
  },
  async created() {
    this.tabId = Number(this.$route.query.tabId)
    this.tabText = this.tabText.map(item => {
      if (item.id === this.tabId) {
        item.active = true
      } else {
        item.active = false
      }
      return item
    })
    this.value = Number(this.$route.query["_limit"])
    let info = {
      ...JSON.parse(JSON.stringify(this.$route.query))
    }
    delete info.tabId
    if (window.name === "") {
      await this.fetchOrders(info)
      window.name = "isReload"
    } else if (window.name === "isReload") {
      info = {
        ...info,
        _index: 1
      }
      await this.fetchOrders(info)
    }
    // this.paginationValue = this.$route.query["_index"] ? Number(this.$route.query["_index"]) : 1
    this.setBrands = await this.$store.dispatch("brand/fetchAllBrands", {
      params: { fields: "name,id" }
    })
    await this.$store.dispatch("retailer/fetchAllRetailers", { params: { fields: "name,id" } })
    await this.$store.dispatch("reference/setReference")
    if (!this.retailerOrders.length) {
      this.isEmptyCatalog = true
    }

    this.setBuyers()
    this.setStatuses()
  },
  methods: {
    brandNameById(brandId) {
      const brand = this.$store.getters["brand/brandById"](brandId)
      let name = brand && brand.name
      return name ? name.toString().replace(/,/g, "，") : ""
    },
    retailerNameById(retailerId) {
      const retailer = this.$store.getters["retailer/retailerById"](retailerId)
      let name = retailer && retailer.name
      return name ? name.toString().replaceAll(/,/g, "，") : ""
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    checkOrderStatus(order) {
      let statusText
      if (order.declined && order.status.id === this.orderStatus.pending) {
        statusText = "Canceled"
      } else if (
        order.status.id === this.orderStatus.supplyShipped ||
        order.status.id === this.orderStatus.warehouse ||
        order.status.id === this.orderStatus.deliverShipped ||
        order.status.id === this.orderStatus.delivered
      ) {
        statusText = "Delivered"
      } else if (order.status.id === this.orderStatus.paidByRetailer) {
        statusText = "Paid"
      } else {
        statusText = order.status.name
      }
      return statusText
    },
    priceFormatForCsv(price) {
      let priceStr = this.priceFormat(price)
      return priceStr.replace(/,/g, "，")
    },
    setBuyers() {
      const uniqueBrandsIds = [...new Set(this.retailerOrders.map(order => order.brand.id))]
      this.brands = uniqueBrandsIds.map(brandId => {
        return {
          checked: false,
          id: brandId,
          name: this.brandNameById(brandId)
        }
      })
    },
    setStatuses() {
      const uniqueStatuses = [...new Set(this.retailerOrders.map(order => order.status.id))]
      this.statuses = uniqueStatuses.map(statusId => {
        return {
          checked: false,
          id: statusId,
          name: this.statusNameById(statusId)
        }
      })
    },
    statusNameById(statusId) {
      return this.retailerOrders.find(order => order.status.id === statusId).status.name
    },
    async fetchOrders(info) {
      this.retailerOrders = []
      this.isShowLoading = true
      const params = this.syncQueryWithFilters()
      const data = info ? info : params
      if (this.tabId === 4) {
        data["osv-tag"] = "1,2"
      }
      const routerRetailerId = this.$route.params.retailerId
      if (routerRetailerId) {
        data["_jwt-c_id"] = routerRetailerId
        data["_jwt-c_type"] = "retailer"
      }
      try {
        await this.$store.dispatch("order/featOrderListWithPage", {
          params: data
        })
        this.isShowLoading = false
        this.retailerOrders = this.$store.getters["order/orders"].filter(
          item => item.status.id !== "deleted"
        )
        this.paginationValue = data["_index"]
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...data,
            tabId: this.tabId
          }
        })
      } catch (error) {
        console.error(error)
        this.retailerOrders = []
      }
    },
    syncQueryWithFilters() {
      this.query["_limit"] = this.currentNum
      this.query["_order-by"] = "PREVIOUS"
      this.query["_sort-by"] = "created_at"
      // when tab is OPEN status
      this.query.status = "pending,confirmed"
      return this.query
    },
    checkStatusValue() {
      let status
      // OPEN
      if (this.tabId === 1) {
        status = "pending,confirmed"
      }
      // INVOICE
      if (this.tabId === 2) {
        status = "shipped-supply,warehouse,shipped-delivery,delivered,invoiced"
      }
      // PAID
      if (this.tabId === 3) {
        status = "paid-by-retailer,paid-to-brand"
      }
      // PAID
      if (this.tabId === 4) {
        status = null
      }
      return status
    },
    async handleTabs(tabInfo) {
      this.tabText = this.tabText.map(item => {
        if (item.id === tabInfo.id) {
          item.active = true
        } else {
          item.active = false
        }
        return item
      })
      this.tabId = tabInfo.id
      this.searchInfo = {}
      const status = this.checkStatusValue()
      //paid-to-brand
      let params = {
        status,
        _index: 1,
        _limit: this.currentNum,
        "_order-by": "PREVIOUS",
        "_sort-by": "created_at"
      }
      await this.fetchOrders(params)
    },
    openSearchOrders() {
      this.$refs["searchOrder"].openDialog()
    },
    async getOrderParams(obj, info) {
      const params = obj
      this.searchInfo = info
      await this.fetchOrders(params)
    },
    async clearSearchOrder() {
      const status = this.checkStatusValue()
      const params = {
        status,
        _limit: this.currentNum,
        _index: 1,
        ...this.sortValue
      }
      await this.fetchOrders(params)
    },
    async selectPagination(currentInfo) {
      this.currentNum = currentInfo
      const status = this.checkStatusValue()
      const params = {
        status,
        _limit: this.currentNum,
        _index: 1,
        ...this.sortValue,
        ...this.searchInfo
      }
      await this.fetchOrders(params)
    },
    async handlePagination(page) {
      this.paginationValue = page
      const status = this.checkStatusValue()
      let params = {
        status,
        _index: page,
        _limit: this.currentNum,
        ...this.sortValue,
        ...this.searchInfo
      }
      await this.fetchOrders(params)
    },
    async sortOrderList(sortValue, otherValue) {
      this.formatSortValue(sortValue, otherValue)
      const status = this.checkStatusValue()
      let params = {
        _index: 1,
        _limit: this.currentNum,
        status,
        ...this.sortValue,
        ...this.searchInfo
      }
      await this.fetchOrders(params)
    },
    formatSortValue(value, otherValue) {
      if (value === "landing_reference_id") {
        this.isReferenceIdSort = !this.isReferenceIdSort
        this.sortValue = {
          "_order-by": this.isReferenceIdSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "retailer_name") {
        this.isRetailerName = !this.isRetailerName
        this.sortValue = {
          "_order-by": this.isRetailerName ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "brand_name") {
        this.isBrandName = !this.isBrandName
        this.sortValue = {
          "_order-by": this.isBrandName ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "calculated_wholesale_cost_usd") {
        this.isSubtotalSort = !this.isSubtotalSort
        this.sortValue = {
          "_order-by": this.isSubtotalSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "calculated_retailer_cost_usd") {
        this.isTotalSort = !this.isTotalSort
        this.sortValue = {
          "_order-by": this.isTotalSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "order_date") {
        this.isOrderDateSort = !this.isOrderDateSort
        this.sortValue = {
          "_order-by": this.isOrderDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "ship_date" && otherValue === "") {
        this.isShipByDateSort = !this.isShipByDateSort
        this.sortValue = {
          "_order-by": this.isShipByDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "cancel_date") {
        this.isCancelDateSort = !this.isCancelDateSort
        this.sortValue = {
          "_order-by": this.isCancelDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "ship_date" && otherValue === "actual-ship-date") {
        this.isActualShipDateSort = !this.isActualShipDateSort
        this.sortValue = {
          "_order-by": this.isActualShipDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": otherValue
        }
      }
      if (value === "retailer_reference_id") {
        this.isPONumberSort = !this.isPONumberSort
        this.sortValue = {
          "_order-by": this.isPONumberSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "tracking_id") {
        this.isTrackingNumberSort = !this.isTrackingNumberSort
        this.sortValue = {
          "_order-by": this.isTrackingNumberSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "invoice_id") {
        this.isInvoiceSort = !this.isInvoiceSort
        this.sortValue = {
          "_order-by": this.isInvoiceSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
      if (value === "status_private_id") {
        this.isStatusSort = !this.isStatusSort
        this.sortValue = {
          "_order-by": this.isStatusSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value
        }
      }
    },
    async downloadOrder(arr) {
      await downloadCsvData(
        this.downloadStrForOrder(arr),
        `retailer_order_list_${new Date().getTime()}.csv`
      )
    },
    getLimitInfo(total) {
      let result = []
      for (let i = 0; i < Math.ceil(total / this.downloadMaxNumber); i++) {
        const info = {
          _index: i + 1,
          _limit: this.downloadMaxNumber
        }
        result.push(info)
      }
      return result
    },
    async downloadStrForOrder(arr) {
      let list = arr && arr.length !== 0 ? arr : this.retailerOrders
      let result
      if (this.total > this.downloadMaxNumber) {
        this.isShowPercent = true
        const status = this.checkStatusValue()
        const arr = this.getLimitInfo(this.total)
        const number = Math.ceil(this.total / this.downloadMaxNumber)
        let price = 100 / number
        this.percentNumber = 10
        for (let i = 0; i < arr.length; i++) {
          const params = {
            ...arr[i],
            status,
            ...this.sortValue
          }
          if (this.tabId === 4) {
            params["osv-tag"] = "1,2"
          }
          await this.$store.dispatch("order/downloadOrderList", { params }).then(async res => {
            result = this.getParamsInfo(res)
            await downloadCsvData(result, `retailer_order_list_${new Date().getTime()}_${i}.csv`)
          })
          price = (i + 1) * price
          if (price >= 95) {
            price = 90
          }
          this.percentNumber = price
        }
        this.percentNumber = 100
        setTimeout(() => {
          this.isShowPercent = false
        }, 1000)
        return
      }

      return this.getParamsInfo(list)
    },
    getParamsInfo(list) {
      if (list && list.length > 0) {
        if (this.tabId === 1) {
          return this.getOpenedOrderDownloadData(list)
        } else if (this.tabId === 2) {
          return this.getInvoicedOrderDownloadData(list)
        } else if (this.tabId === 3) {
          return this.getPaidOrderDownloadData(list)
          // } else if (this.tabId === 5) {
          //   return this.getHistoryOrderDownloadData(list)
        } else {
          return this.getAllOrderDownloadData(list)
        }
      } else {
        return []
      }
    },
    getOpenedOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Brand, Total, Order Date , Ship by Date, Cancel Date, PO Number, Status"
      ]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.brandNameById(
          item.brand.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate ? item.orderDate : "-"
        }, ${item.shipDate ? item.shipDate : "-"}, ${item.cancelDate ? item.cancelDate : "-"}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.status && item.status.name ? item.status.name : "-"}`
        csvData.push(str)
      }
      return csvData
    },
    getInvoicedOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Brand, Total, Order Date , Invoice Date, PO Number, Invoice #, Tracking Number"
      ]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.brandNameById(
          item.brand.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate ? item.orderDate : "-"
        }, ${item.shipDate ? item.shipDate : "-"}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.invoiceId ? item.invoiceId : "-"}, ${this.formatTackingNumber(item)}`
        csvData.push(str)
      }
      return csvData
    },
    getPaidOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Brand, Retailer, Total, Actural Ship Date , Payment Date, PO Number, Invoice #"
      ]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.brandNameById(
          item.brand.id
        )}, ${this.retailerNameById(item.retailer.id)}, ${this.priceFormatForCsv(
          item.calculatedWholesaleCostUsd
        )}, ${item.shipDate ? item.shipDate : "-"}, ${this.formatPaymentDate(item)}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.invoiceId ? item.invoiceId : "-"}`
        csvData.push(str)
      }
      return csvData
    },
    getHistoryOrderDownloadData(list) {
      let csvData = ["Reference ID, Brand, Total, order Date , PO Number, status"]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.brandNameById(
          item.brand.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate ? item.orderDate : "-"
        }, ${item.retailerReferenceId ? item.retailerReferenceId : "-"}, ${
          item.status ? item.status.name : "-"
        }`
        csvData.push(str)
      }
      return csvData
    },
    getAllOrderDownloadData(list) {
      let csvData = ["Reference ID, Brand, Total, Order Date , PO Number, Invoice #, Status"]
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.brandNameById(
          item.brand.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate ? item.orderDate : "-"
        }, ${item.retailerReferenceId ? item.retailerReferenceId : "-"}, ${
          item.invoiceId ? item.invoiceId : "-"
        }, ${item.status && item.status.name ? item.status.name : "-"}`
        csvData.push(str)
      }
      return csvData
    },
    async downloadOrderItems() {
      let list
      if (this.isAllActive) {
        list = this.retailerOrders
      } else {
        list = this.checkList
      }
      // this.downloadOrder(list)
      await downloadCsvData(
        this.getParamsInfo(list),
        `retailer_order_list_${new Date().getTime()}.csv`
      )
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    onChangeAll() {
      this.isAllActive = !this.isAllActive
      this.retailerOrders = this.retailerOrders.map(item => {
        item.checkedActive = this.isAllActive
        return item
      })
    },
    onChangeItem(order) {
      this.retailerOrders = this.retailerOrders.map(item => {
        if (item.id === order.id) {
          item.checkedActive = order.checkedActive
        }
        return item
      })
      this.checkList = this.retailerOrders.filter(item => item.checkedActive === true)
    },
    // clickToOrderDetail(order) {
    //   console.log(45454545, this.routeLinkToRetailerDetail)
    //   this.$router.push({
    //     name: this.routeLinkToRetailerDetail,
    //     params: { id: order.id }
    //   })
    // },
    // Choose the tracking number
    formatTackingNumber(item) {
      let num
      if (item.airShipments) {
        num = item.airShipments[0].trackingId
      }
      // else if (item.oceanShipments) {
      //   num = item.oceanShipments[0].trackingId
      // }
      else {
        num = "-"
      }
      return num
    },
    formatPaymentDate(item) {
      const finalArr = item.lifecycle.slice(-1)
      if (finalArr[0].complete) {
        return formatLifecycleDate(finalArr[0].updatedAt)
      } else if (item.lifecycle && item.lifecycle.length >= 2) {
        const list = item.lifecycle.filter(item => item.complete).slice(-1)
        return list[0].transitionedAt
          ? formatLifecycleDate(list[0].transitionedAt)
          : formatLifecycleDate(list[0].updatedAt)
      } else {
        return "-"
      }
    },
    formatActualShipDate(orderItem) {
      if (orderItem && orderItem.airShipments && orderItem.airShipments.length !== 0) {
        return orderItem.airShipments[0].actualShipDate
      } else {
        return "--"
      }
    },
    routeLinkToRetailerDetail(orderItem) {
      if (orderItem.osvTag === 1) {
        return this.admin || this.$route.name === "adminRetailerOrders"
          ? "adminRetailerOldOrderDetails"
          : "OldRetailerOrderDetails"
      } else {
        return this.admin || this.$route.name === "adminRetailerOrders"
          ? "adminRetailerOrder"
          : "retailerOrder"
      }
    }
  }
}
</script>

<style lang="sass">
.retailer-orders
  //padding-top: 50px
  //padding-bottom: 80px
  padding: 50px 122px 80px 122px
  .progress-download-tips
    width: 400px
    height: 100px
    padding: 20px 25px
    text-align: center
    position: fixed
    top: 60px
    left: 35%
    background-color: white
    p
      margin: 0 auto

  &__no-background
    background: none !important

  &__no-orders
    color: #9E9E9E
    white-space: pre-wrap

  //&__filter
  //  padding-right: 71px
  //  min-height: 90vh
  .order-title-button
    display: flex
    justify-content: space-between
  &__table-name
    color: #FEC206
    font-size: 14px
    width: 250px
    display: flex
    justify-content: space-between
    align-items: center
    .search-brand-order
      padding: 5px 16px
      border: 1px solid #FEC206
      border-radius: 2px
      background-color: #FEF2E2
      cursor: pointer
      .search-icon-style
        margin-left: 5px
    .clear-brand-order
      text-decoration: underline
      cursor: pointer

  .order-processing-tabs
    margin-bottom: 3px
    margin-top: 100px
    span
      padding: 4px 18px
      border: 1px solid white
      cursor: pointer
    .tab-active
      border: 1px solid gray
      border-bottom: none
  &__table-head
    color: #9E9E9E !important
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important

  &__table-cell
    color: #333333 !important
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important
    margin-top: 10px
    position: relative
    a
      color: #333333
  .tacking-number-style
    display: inline-block
    width: 200px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

  &__filter-column-name
    color: #292929
    font-size: 16px
    font-weight: 300
    line-height: 22px
    margin-bottom: 15px

  &__table-header
    border-top: 1px solid #E0E0E0

  &__sort-options
    margin-top: -15px
  &__table-row
    cursor: pointer

  &__orders-count
    font-size: 12px
    padding-bottom: 10px
  .select-text-right
    float: right
    margin-top: 90px
  .csv-style
    width: 150px
    height: 30px
    line-height: 30px
    text-align: center
    border: 1px solid #FEC206
    background-color: #FEF2E2
    color: #FEC206
    cursor: pointer
    position: relative
    .csv-select
      position: absolute
      top: 30px
      left: -1px
      border: 1px solid #D9D9D9
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
      .download-csv-select-one
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-one:hover
        background-color: #FEF2E2
      .download-csv-select-two
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-two:hover
        background-color: #FEF2E2
.spin-style-retailer
  text-align: center
  margin: 40px 0
  //display: flex
  //justify-content: center
  //align-items: center
  .ant-spin
    color: #FEC206
  .ant-spin-dot-item
    background-color: #FEC206 !important
.old-order-table-retailer
  position: relative
  .old-order-icon
    position: absolute
    left: -4px
    top: 19px
    color: #1c7430
.retailer-pagination-style
  text-align: right
</style>
