<template>
  <div>
    <p class="brand-setting-subscription__title">{{ $t("Brands.BrandSettings.subscriptionBf") }}</p>
    <span class="brand-line"></span>
    <p v-if="!canShowSubscription(status)" class="brand-not-approved">
      {{ $t("Brands.BrandSettings.brandNotApprovedMsg") }}
    </p>
    <div v-else>
      <a-row class="brand-line__main-block brand-settings__first-row">
        <a-col :span="6" class="brand-line__left-block">
          <p class="brand-setting-subscription__left-text">
            {{ $t("Brands.BrandSettings.planType") }}
          </p>
        </a-col>
        <a-col :span="10" class="brand-line__right-block">
          <p class="brand-setting-subscription__right-text">{{ planType }}</p>
        </a-col>
        <a-col :span="8" class="subscribeActionCntnr">
          <a
            class="upgrade-button button brand-settings__account-modal-form brand-setting-subscription__btn-txt"
            @click="changePlanType"
          >
            {{
              planType === "None"
                ? $t("Brands.BrandSettings.joinBF")
                : $t("Brands.BrandSettings.subscriptionAddRetailer")
            }}
          </a>
        </a-col>
      </a-row>
      <a-row class="brand-line__main-block brand-subscription-bf__table-title">
        <a-col :span="7">
          {{ $t("Brands.BrandSettings.subscriptionRetailer") }}
        </a-col>
        <a-col :span="5">
          {{ $t("Brands.BrandSettings.subscriptionRenewalDate") }}
        </a-col>
        <a-col :span="4">
          {{ $t("Brands.BrandSettings.subscriptionBilling") }}
        </a-col>
        <a-col :span="8"> </a-col>
      </a-row>
      <template v-if="subscribeRetailers && subscribeRetailers.length > 0">
        <a-row
          v-for="(item, index) in subscribeRetailers"
          :key="`subscribe_reatiler_${index}`"
          class="brand-line__main-block brand-subscription-bf__table-content"
        >
          <a-col :span="7">
            {{ getRetailerName(item) }}
          </a-col>
          <a-col :span="5">
            {{ item.renewalDate }}
          </a-col>
          <a-col :span="4">
            {{ item.billingCycle }}
          </a-col>
          <a-col :span="8">
            <button
              v-if="item.status === 'Expired' || item.status === 'Suspended'"
              class="brand-subscription-bf__btn"
              @click="clickToResubscribe(item)"
            >
              Resubscribe
            </button>
            <button
              v-else-if="isAdmin"
              class="brand-subscription-bf__btn"
              @click="clickToEditPlan(item)"
            >
              Edit
            </button>
            <button v-else class="brand-subscription-bf__btn" @click="clickCancelAction(item)">
              {{ item.isCancel ? "Resubscribe" : "Cancel" }}
            </button>
          </a-col>
        </a-row>
      </template>
    </div>

    <confirm-cancel-subscription-dialog
      ref="cancelConfirm"
      @close="clickDialogCancel"
      @doCancel="clickCancelConfirm"
    />
    <EditBFRetailerPlanDialog ref="editRetailerStatusAdmin" />
    <brand-subscribe ref="resubscribeRetailer" @submitSuccess="getBrand" />
  </div>
</template>
<script>
import ConfirmCancelSubscriptionDialog from "@/components/brands/settings/CancelSubscriptionDialogNew"
import EditBFRetailerPlanDialog from "@/components/shared/EditBFRetailerPlanDialog"
import BrandSubscribe from "@/views/Brands/BrandSubscribe"
export default {
  components: {
    ConfirmCancelSubscriptionDialog,
    EditBFRetailerPlanDialog,
    BrandSubscribe
  },
  data() {
    return {
      selectedCancelData: undefined
      //isGetCard: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    planType() {
      if (
        this.brand &&
        this.brand.brandTrainingSubscriptions &&
        this.brand.brandTrainingSubscriptions.length > 0
      ) {
        if (this.brand.brandTrainingSubscriptions[0].retailerId === "*") {
          return "BeautyFluent General"
        }
        return "BeautyFluent Preferred"
      }
      return "None"
    },
    status() {
      let brand = this.$store.getters["brand/brand"]
      return brand.status.name
    },
    subscribeRetailers() {
      let data = []
      if (
        this.brand &&
        this.brand.brandTrainingSubscriptions &&
        this.brand.brandTrainingSubscriptions.length > 0
      ) {
        for (let i = 0; i < this.brand.brandTrainingSubscriptions.length; i++) {
          if (
            this.brand.brandTrainingSubscriptions[i].status.id === "activated" ||
            this.brand.brandTrainingSubscriptions[i].status.id === "cancelled" ||
            this.brand.brandTrainingSubscriptions[i].status.id === "expired" ||
            this.brand.brandTrainingSubscriptions[i].status.id === "suspended"
          ) {
            data.push({
              retailerId: this.brand.brandTrainingSubscriptions[i].retailerId,
              renewalDate: this.brand.brandTrainingSubscriptions[i].anniversaryDate || "-",
              billingCycle: this.brand.brandTrainingSubscriptions[i].subscriptionTerms
                ? this.brand.brandTrainingSubscriptions[i].subscriptionTerms.name
                : "-",
              status: this.brand.brandTrainingSubscriptions[i].status
                ? this.brand.brandTrainingSubscriptions[i].status.name
                : "",
              isCancel: this.brand.brandTrainingSubscriptions[i].status.id === "cancelled",
              discountPercent: this.brand.brandTrainingSubscriptions[i].discountPercent,
              data: Object.assign({}, this.brand.brandTrainingSubscriptions[i])
            })
          }
        }
      }
      return data
    }
  },
  //   watch: {
  //     brand(old, newValue) {
  //       console.log(old)
  //       console.log(newValue)
  //     }
  //   },
  methods: {
    changePlanType() {
      console.log(".... add retailer...")
      console.log(this.brand.brandTrainingSubscriptions)
      // let hasRetailer =
      //   this.brand &&
      //   this.brand.brandTrainingSubscriptions &&
      //   this.brand.brandTrainingSubscriptions.length > 0
      this.$router.push({
        path: this.isAdmin
          ? `/admin/brand/${this.$route.params.brandId}/training?tab=retailers&isAddRetailer=true`
          : `/brand/training?tab=retailers&isAddRetailer=true`
      })
    },
    canShowSubscription(brandStatus) {
      console.log(brandStatus)
      if (brandStatus == "Pending" || brandStatus == "Rejected") {
        return false
      } else if (brandStatus == "Approved") {
        return true
      }
    },
    async clickToResubscribe(item) {
      //   if (!this.isGetCard) {

      //     await this.$store.dispatch("reference/setReference")
      //     this.isGetCard = true
      //   }
      this.$refs["resubscribeRetailer"].openDialog(item, this.brand.id)
    },
    clickToEditPlan(item) {
      console.log(item)
      this.$refs["editRetailerStatusAdmin"].openDialog(item, this.brand.id)
    },
    clickCancelAction(item) {
      this.selectedCancelData = item.data
      if (item.isCancel) {
        console.log("resubscribe this retailer...")
        this.selectedCancelData.status = {
          id: "activated",
          name: "Activated"
        }
        this.selectedCancelData.isAutoRenewal = true
        this.clickCancelConfirm()
      } else {
        this.selectedCancelData.status = {
          id: "cancelled",
          name: "Cancelled"
        }
        this.selectedCancelData.isAutoRenewal = false
        this.$refs["cancelConfirm"].openDialogBF()
      }
    },
    clickDialogCancel() {
      console.log("...click first btn....")
      this.selectedCancelData = undefined
    },
    clickCancelConfirm() {
      console.log("....click confirm cancel....")
      if (this.selectedCancelData) {
        let param = {
          id: this.brand.id,
          brandTrainingSubscriptions: [this.selectedCancelData]
        }
        this.$store.dispatch("brand/updateBrandPublic", param)
      }
    },
    getRetailerName(item) {
      let name = this.$store.getters["retailer/getPreferredRetailerName"](item.retailerId)
      if (item.status && item.status.length > 0) {
        if (
          item.status === "Cancelled" ||
          item.status === "Suspended" ||
          item.status === "Expired"
        ) {
          name = `${name}(${item.status})`
        }
      }
      return name
    },
    getBrand() {
      this.$store.dispatch("brand/getBrand", this.brand.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.brand-subscription-bf {
  &__table-title {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  &__table-content {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  &__btn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202020;
    padding: 3px 25px;
    background: #fff;
    border: 1px solid #202020;
    border-radius: 4px;
  }
}
</style>
<style lang="scss">
.brand-setting-subscription {
  &__title {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
  &__left-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }
  &__right-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }
  &__btn-txt {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px;
    border-radius: 4px;
  }
}
</style>
