<template>
  <a-row class="admin-system-promocode">
    <a-col :span="24" style="text-align: right;">
      <a-button class="admin-system-promocode__btn" @click="showHistory">
        <a-icon type="history" class="admin-system-promocode__btn-icon" />
        {{ $t("Admins.AdminSystem.history") }}
      </a-button>
      <a-button class="admin-system-promocode__btn" @click="showAddDialog">
        <a-icon type="plus" class="admin-system-promocode__btn-icon" />
        {{ $t("Admins.AdminSystem.addCode") }}
      </a-button>
    </a-col>
    <a-col :span="24" class="admin-system-promocode__table-content">
      <a-table
        :columns="columns"
        :data-source="couponList.list"
        class="admin-system-promocode"
        :pagination="pagination"
      >
        <span slot="promoCodeName" slot-scope="text">
          <a-tooltip
            v-if="text !== undefined && text.length > 18"
            placement="bottomLeft"
            :title="text"
          >
            <span>{{ text }}</span>
          </a-tooltip>
          <span v-else>{{ text }}</span>
        </span>
        <span slot="promoCode" slot-scope="text">
          <a-tooltip
            v-if="text !== undefined && text.length > 9"
            placement="bottomLeft"
            :title="text"
          >
            <span>{{ text }}</span>
          </a-tooltip>
          <span v-else>{{ text }}</span>
        </span>
        <span slot="startDate" slot-scope="text">
          {{ formatDate(text) }}
        </span>
        <span slot="expirationDate" slot-scope="text">
          {{ formatDate(text) }}
        </span>
        <span slot="promoCodeValue" slot-scope="text, record">
          {{ record.isPercentage ? `-${text}%` : `-${priceFormat(text)}` }}
        </span>
        <span slot="isActive" slot-scope="text, record">
          <a-switch :checked="text" @change="checked => clickToActiveCode(checked, record)" />
        </span>
        <span slot="action" slot-scope="text, record">
          <a-icon
            type="edit"
            class="admin-system-promocode__action-icon"
            style="marign-right: 8px;"
            @click="clickToEdit(record)"
          />
          <a-icon
            type="delete"
            class="admin-system-promocode__action-icon"
            @click="clickToRemove(record)"
          />
        </span>
      </a-table>
    </a-col>
    <add-edit-coupon-dialog ref="addDialog" @saveNew="saveAction" />
    <confirm-dialog ref="confirmDelete" @doConfirm="saveDelete" />
  </a-row>
</template>

<script>
import AddEditCouponDialog from "@/components/admin/AddEditCouponDialog.vue"
import ConfirmDialog from "@/components/admin/ConfirmDialog.vue"
export default {
  components: { AddEditCouponDialog, ConfirmDialog },
  data() {
    return {
      columns: [
        {
          title: this.$t("Admins.AdminSystem.promoCodeName"),
          dataIndex: "name",
          key: "name",
          ellipsis: true,
          scopedSlots: { customRender: "promoCodeName" },
          width: "18%"
        },
        {
          title: this.$t("Admins.AdminSystem.promoCode"),
          dataIndex: "code",
          key: "code",
          ellipsis: true,
          scopedSlots: { customRender: "promoCode" },
          width: "12%"
        },
        {
          title: this.$t("Admins.AdminSystem.promoCodeValue"),
          dataIndex: "amount",
          key: "amount",
          scopedSlots: { customRender: "promoCodeValue" },
          width: "13%"
        },
        {
          title: this.$t("Admins.AdminSystem.startDate"),
          dataIndex: "startTime",
          key: "startTime",
          scopedSlots: { customRender: "startDate" },
          width: "13%"
        },
        {
          title: this.$t("Admins.AdminSystem.expirationDate"),
          dataIndex: "endTime",
          key: "endTime",
          scopedSlots: { customRender: "expirationDate" },
          width: "15%"
        },
        {
          title: this.$t("Admins.AdminSystem.numOfUse"),
          dataIndex: "usedCount",
          key: "usedCount",
          width: "10%"
        },
        {
          title: this.$t("Admins.AdminSystem.isActive"),
          dataIndex: "isEnable",
          key: "isEnable",
          width: "10%",
          scopedSlots: { customRender: "isActive" }
        },
        {
          title: this.$t("Admins.AdminSystem.action"),
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      deleteItem: undefined,
      isDoActive: false,
      page: 1,
      pageSize: 50,
      total: 0,
      pagination: {
        onChange: page => {
          console.log(page)
          this.getCouponList(page)
        },
        size: "small",
        pageSize: 50,
        total: 0
      }
    }
  },
  computed: {
    couponList() {
      let data = this.$store.getters["adminConsole/coupons"]
      return data
    }
  },
  watch: {
    couponList() {
      this.pagination.total = this.couponList.total
    }
  },
  created() {
    this.getCouponList(1)
  },
  methods: {
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    clickToActiveCode(checked, record) {
      // will invoke the api
      this.isDoActive = true
      record.isEnable = checked
      this.saveAction(record)
      this.$forceUpdate()
    },
    showHistory() {
      this.$router.push({
        name: "adminSystemPromoCodeHistory"
      })
    },
    showAddDialog() {
      this.isDoActive = false
      this.$refs["addDialog"].openAddDialog()
    },
    clickToEdit(record) {
      this.isDoActive = false
      this.$refs["addDialog"].openEditDialog(record)
    },
    clickToRemove(record) {
      this.deleteItem = record
      this.deleteItem.isActive = false
      this.showDeleteConfirm()
    },
    getCouponList(page) {
      this.page = page
      let param = {
        _limit: this.pageSize,
        "page-index": page
      }
      this.$store.dispatch("adminConsole/getCoupons", param)
    },
    saveAction(data) {
      this.$store
        .dispatch("adminConsole/addCoupon", data)
        .then(res => {
          let msg = ""
          if (data.privateId) {
            if (!data.isActive) {
              msg = "Delete promo code"
            } else if (this.isDoActive) {
              if (data.isEnable) {
                msg = "Active promo code"
              } else {
                msg = "Deactive promo code"
              }
            } else {
              msg = "Modify promo code"
            }
          } else {
            msg = "Add promo code"
          }
          if (res) {
            this.$message.success(`${msg} success.`)
            this.getCouponList(this.page)
          } else {
            this.$message.warn(`${msg} failed.`)
          }
        })
        .catch(err => {
          if (err.message) {
            this.$message.error(err.message)
          } else {
            this.$message.error(err.toString())
          }
        })
    },
    showDeleteConfirm() {
      let msg = this.$t("Admins.AdminSystem.confirmDelete")
      this.$refs["confirmDelete"].openDialogMsg(msg)
    },
    saveDelete() {
      this.saveAction(this.deleteItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-system-promocode {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    background: #4a8a5d;
    color: #fff;
    padding: 0px 8px !important;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
    height: auto !important;
  }
  &__btn-icon {
    vertical-align: middle;
    // line-height: normal;
    //margin-bottom: 4px;
  }
  &__table-content {
    margin-top: 20px;
  }
  &__action-icon {
    font-size: 14px;
    color: #4a8a5d;
  }
}
</style>
<style lang="scss">
.admin-system-promocode__table-content .ant-table-thead > tr > th,
.admin-system-promocode__table-content .ant-table-tbody > tr > td {
  padding: 5px 5px;
  text-align: center;
}
.admin-system-promocode__table-content .ant-switch-checked {
  background: #4a8a5d;
}
.admin-system-promocode .ant-btn:hover,
.admin-system-promocode .ant-btn:focus {
  background: #4a8a5d;
  color: white;
}
</style>
