import httpclient from "@/api/http-client"
import ProductTrainingStep from "@/models/ProductTrainingStep"

export default {
  async getSteps(categoryPrivateId) {
    return await httpclient
      .get(`/api/steps/by-category/${categoryPrivateId}`)
      .then((res) => res.data.map((m) => new ProductTrainingStep(m)))
  },
  async addStep(step) {
    return await httpclient
      .post("/api/steps", step.toModel())
      .then((res) => res.data.map((m) => new ProductTrainingStep(m)))
  },
  async addSteps(steps) {
    return await httpclient
      .post(
        "/api/steps/many",
        steps.map((s) => s.toModel())
      )
      .then((res) => res.data.map((m) => new ProductTrainingStep(m)))
  },
  async deleteStep(stepPrivateId) {
    return await httpclient.delete(`/api/steps/${stepPrivateId}`)
  },
}
