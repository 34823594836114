<template>
  <div ref="retailerLocationUploadFile" class="retailer-location-tab__main">
    <div class="search-title-content">
      <div class="retailer-training-search">
        <div class="search-style search-input">
          <p>Search</p>
          <div>
            <a-input-search
              v-model="inputLocationTag"
              placeholder=" Search by City or Zip"
              :loading="isLoadingSearch"
              @search="searchLocationInfo"
              @change="changeValue"
            />
          </div>
        </div>
        <div class="search-style search-resent">
          <!--          <p>Recent</p>-->
          <!--          <div>-->
          <!--            <a-tag-->
          <!--              v-for="(tag, index) in tagList"-->
          <!--              :key="index"-->
          <!--              closable-->
          <!--              @close="closeBrandLocationTag(tag)"-->
          <!--            >-->
          <!--              {{ tag.name }}-->
          <!--            </a-tag>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="create-new-location">
        <p class="upload-bulk-csv-style">
          <!--          <span @click="showUploadDialog">Upload Bulk CSV</span>-->
          <span @click="downloadCsv">Download CSV</span>
          <!--          <span class="template-csv-style" @click="downloadTemplateCSV">Download template</span>-->
        </p>
        <p class="add-location-style" @click="createNewLocation">Add Location</p>
      </div>
    </div>
    <div class="retailer-brand-training-table">
      <table class="training-table-style">
        <thead>
          <tr class="table-tr-header">
            <th>
              <a-checkbox v-model="isCheckAll" @change="handleSelectAllReport"></a-checkbox>
            </th>
            <th>
              Modified
              <a-icon
                style="cursor: pointer"
                :type="isModifiedSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('modified')"
              ></a-icon>
            </th>
            <th>
              Store #
              <a-icon
                style="cursor: pointer"
                :type="isStoreSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('storeNumber')"
              ></a-icon>
            </th>
            <th>
              Address
              <a-icon
                style="cursor: pointer"
                :type="isAddressSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('address')"
              ></a-icon>
            </th>
            <th>
              City
              <a-icon
                style="cursor: pointer"
                :type="isCitySort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('city')"
              ></a-icon>
            </th>
            <th>
              State
              <a-icon
                style="cursor: pointer"
                :type="isStateSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('state')"
              ></a-icon>
            </th>
            <th>
              Zip
              <a-icon
                style="cursor: pointer"
                :type="isZipSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('postalCode')"
              ></a-icon>
            </th>
            <th style="width: 150px">
              Telephone
              <a-icon
                style="cursor: pointer"
                :type="isPhoneSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('phone')"
              ></a-icon>
            </th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <div v-if="tableData && tableData.length === 0" class="no-table-data">
          <a-empty />
        </div>
        <tr v-for="(item, index) in tableData" :key="index">
          <td class="brand-orders__table-cell">
            <a-checkbox v-model="item.isChecked" @change="chooseData(item)"></a-checkbox>
          </td>
          <td style="width:123px;" class="name-tab-style">
            {{ item.updatedAt ? item.updatedAt.slice(0, 10) : item.createdAt.slice(0, 10) }}
          </td>
          <td style="width:123px;" class="">{{ item.storeNumber ? item.storeNumber : "--" }}</td>
          <td style="width:115px;" class="data-tab-style">{{ item.address }}</td>
          <td class="data-tab-style">{{ item.city }}</td>
          <td style="width:109px;" class="data-tab-style">
            {{ item.stateText }}
          </td>
          <td style="width:109px;" class="data-tab-style">{{ item.postalCode || "--" }}</td>
          <td style="width:109px;" class="data-tab-style">{{ item.phone || "--" }}</td>
          <td class="data-tab-style">
            <!--            <a-switch v-model="item.active" @change="handleViewItem(item)" />-->
            <span
              class="location-circular-active"
              :class="item.active ? 'green-circular' : 'red-circular'"
            ></span>
          </td>
          <td style="width:136px" class="click-pro-action">
            <div class="editable-row-operations">
              <span @click="editLocationInfo(item)">
                Edit
              </span>
            </div>
          </td>
        </tr>
      </table>

      <div v-if="showPagination" class="table-pagination-style">
        <a-pagination
          v-model="paginationValue"
          class="inventoryPagination"
          :default-current="1"
          :default-page-size="currentNum"
          :total="total"
          size="small"
          :show-quick-jumper="true"
          @change="handlePagination"
        ></a-pagination>
      </div>
    </div>
    <createLocation
      ref="createLocation"
      @createOrUpdate="createOrUpdate"
      @fetchData="fetchData"
    ></createLocation>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { downloadCsvData, replaceCommaForCsvStr } from "@/utils/validate"
import { retailerLocationTag } from "@/utils/const"
import createLocation from "@/components/training/__components/createNewLocationDialog"

export default {
  name: "RetailerLocation",
  components: {
    createLocation
  },
  // props: {
  //   tagList: { type: Array, default: () => [] },
  //   tableData: { type: Array, default: () => [] }
  // },
  data() {
    return {
      isLoadingSearch: false,
      showUpload: false,
      uploading: false,
      isCheckAll: false,
      inputLocationTag: "",
      errorReports: "",
      errors: "",
      tagList: [],
      tableData: [],
      allRetailerInfo: {},
      fileList: [],
      current: 1,
      selectedData: [],
      isModifiedSort: false,
      isStoreSort: false,
      isAddressSort: false,
      isCitySort: false,
      isStateSort: false,
      isZipSort: false,
      isPhoneSort: false,
      total: 0,
      currentNum: 50,
      paginationValue: 1,
      showPagination: true,
      locationList: [],
      sortValueText: "modified",
      sortValueBool: false
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    ...mapActions({
      getTagList: "training/getSearchBrandTags",
      getLocationsInfo: "retailer/fetchRetailer",
      updateTagList: "training/updateBrandTags",
      updateLocationInfo: "retailer/updateRetailer"
    }),
    async fetchData() {
      const routerRetailerId = this.retailer.id ? this.retailer.id : this.$route.params.retailerId
      this.allRetailerInfo = await this.getLocationsInfo(routerRetailerId)
      this.locationList = this.allRetailerInfo.locations.map(item => {
        item.stateText =
          Object.keys(item.state).length !== 0 ? item.state.id.toLocaleUpperCase() : ""
        return item
      })
      this.handleName(this.sortValueText, this.sortValueBool)
      // this.tableData = this.locationList.slice(index, limit)
      this.total = this.locationList.length
      this.tagList = await this.getTagList(retailerLocationTag)
    },
    createNewLocation() {
      this.$refs["createLocation"].openCreateLocation(true, true)
    },
    editLocationInfo(item) {
      this.$refs["createLocation"].openCreateLocation(true, false, item)
    },
    async handleViewItem(value) {
      const retailer = {
        ...this.allRetailerInfo,
        locations: this.locationList.filter(item => item.locationId === value.locationId)
      }
      await this.updateLocationInfo(retailer)
      await this.fetchData()
    },
    async closeBrandLocationTag(item) {
      await this.updateTagList({ tagName: retailerLocationTag, tagItem: item.name, type: "delete" })
    },
    async changeValue() {
      if (!this.inputLocationTag) {
        await this.fetchData()
        this.showPagination = true
      }
    },
    async searchLocationInfo() {
      this.isLoadingSearch = true
      if (!this.inputLocationTag) {
        this.isLoadingSearch = false
        this.tableData = this.locationList.map(item => {
          item.isChecked = false
        })
        return
      }
      const value = this.inputLocationTag.toUpperCase()
      const list = this.locationList.filter(
        item =>
          item.postalCode
            .toString()
            .toUpperCase()
            .includes(value) ||
          item.city.toUpperCase().includes(value) ||
          item.city.toUpperCase() === value ||
          value === item.postalCode.toUpperCase()
      )
      if (list && list.length !== 0) {
        this.tableData = list.map(item => {
          item.isChecked = false
          return item
        })
      } else {
        this.tableData = list
      }
      this.showPagination = false
      this.tagList = await this.updateTagList({
        tagName: retailerLocationTag,
        tagItem: this.inputLocationTag
      })
      this.isLoadingSearch = false
    },
    handleSelectAllReport() {
      if (this.isCheckAll) {
        this.tableData = this.tableData.map(item => {
          item.isChecked = true
          return item
        })
        this.selectedData = this.tableData
      } else {
        this.tableData = this.tableData.map(item => {
          item.isChecked = false
          return item
        })
        this.selectedData = []
      }
    },
    async handlePagination(page) {
      this.paginationValue = page
      // const index = (page - 1) * this.currentNum
      // const limit = index + this.currentNum
      await this.fetchData()
    },
    chooseData(value) {
      this.tableData = this.tableData.map(item => {
        if (item.locationId === value.locationId) {
          item.isChecked = value.isChecked
        }
        return item
      })
      this.selectedData = this.tableData.filter(item => item.isChecked)
    },
    async downloadCsv() {
      const brandName = "retailer"
      await downloadCsvData(
        this.generateDownloadStr(),
        `${brandName}_location_${new Date().getTime()}.csv`
      )
    },
    generateDownloadStr() {
      let list = []
      if (this.selectedData.length === 0) {
        list = this.tableData
      } else {
        list = this.selectedData
      }
      let csvData = ["Modified, Store #, Address, City, State, Zip, Telephone, Active"]
      for (let item of list) {
        let str = `${this.formatDate(item.updatedAt ? item.updatedAt : item.createdAt)}, ${
          item.storeNumber
        }, ${item.address != null ? replaceCommaForCsvStr(item.address) : ""}, ${
          item.city != null ? replaceCommaForCsvStr(item.city) : ""
        }, ${item.state != null ? item.state.id : ""},${
          item.postalCode != null ? replaceCommaForCsvStr(item.postalCode) : item.postalCode
        }, ${item.phone != null ? replaceCommaForCsvStr(item.phone) : ""}, ${item.active}`
        csvData.push(str)
      }
      return csvData
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    // create or update location
    async createOrUpdate(param, isAdd) {
      let retailer
      let locationInfo = this.tableData.filter(item => item.locationId !== param.locationId)
      const data = JSON.parse(JSON.stringify(param))
      if (isAdd) {
        delete data.locationId
      }
      locationInfo.push(data)
      retailer = {
        ...this.allRetailerInfo,
        // locations: locationInfo
        locations: [data]
      }
      this.updateLocationInfo(retailer)
        .then(async res => {
          if (res.states || (res.status >= 200 && res.status < 300)) {
            if (res.data && res.data.error) {
              if (res.data.error.underlying && res.data.error.underlying.length > 0) {
                let errorMsg = ""
                for (let i = 0; i < res.data.error.underlying.length; i++) {
                  errorMsg += res.data.error.underlying[i].developerMessage
                  if (i < res.data.error.underlying.length - 1) {
                    errorMsg += ","
                  }
                }
                this.$message.error(errorMsg)
              }
            } else {
              await this.fetchData()
              this.$message.success("Location has been updated")
            }
          } else if (res.reports) {
            this.errorReports = ""
            this.errors = ""
            res.reports.forEach(item => {
              let index = item.indexOf("cause:")
              if (index > 0) {
                this.errorReports += `${item.substring(0, index)}\n`
                this.errors += `${item.substring(0, index)}<br/>`
              } else {
                this.errorReports += `${item}\n`
                this.errors += `${item}<br/>`
              }
            })
            // this.showErrorReport()
          }
        })
        .catch(error => {
          // if (!error.response) {
          //   return
          // }
          // let info = ""
          // if (error.response) {
          //   let messages = error.response.data.underlying
          //   if (messages && messages.length > 0) {
          //     info = ""
          //     for (let i = 0; i < messages.length; i++) {
          //       info += messages[i].developerMessage + ","
          //     }
          //     info = info.substring(0, info.length - 1)
          //   }
          // }
          this.$message.error(error.message)
        })
        .finally(async () => {
          await this.fetchData()
        })
    },
    // upload csv
    showUploadDialog() {
      this.showUpload = true
      this.fileList = []
    },
    handleCancelUpload() {
      this.showUpload = false
      this.fileList = []
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = []
      this.fileList.push(file)
      return false
    },
    async sortList(sortValue) {
      const info = this.formatSortValue(sortValue)
      this.handleName(info["_sort-by"], info.isAscend)
    },
    formatSortValue(value) {
      // this.initSort()
      if (value === "modified") {
        this.isModifiedSort = !this.isModifiedSort
        this.isStoreSort = false
        this.isAddressSort = false
        this.isCitySort = false
        this.isStateSort = false
        this.isZipSort = false
        this.isPhoneSort = false
        return {
          "_order-by": this.isModifiedSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isModifiedSort
        }
      }
      if (value === "storeNumber") {
        this.isStoreSort = !this.isStoreSort
        this.isModifiedSort = false
        this.isAddressSort = false
        this.isCitySort = false
        this.isStateSort = false
        this.isZipSort = false
        this.isPhoneSort = false
        return {
          "_order-by": this.isStoreSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isStoreSort
        }
      }
      if (value === "address") {
        this.isAddressSort = !this.isAddressSort
        this.isModifiedSort = false
        this.isStoreSort = false
        this.isCitySort = false
        this.isStateSort = false
        this.isZipSort = false
        this.isPhoneSort = false
        return {
          "_order-by": this.isAddressSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isAddressSort
        }
      }
      if (value === "city") {
        this.isCitySort = !this.isCitySort
        this.isModifiedSort = false
        this.isStoreSort = false
        this.isAddressSort = false
        this.isStateSort = false
        this.isZipSort = false
        this.isPhoneSort = false
        return {
          "_order-by": this.isCitySort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isCitySort
        }
      }
      if (value === "state") {
        this.isStateSort = !this.isStateSort
        this.isModifiedSort = false
        this.isStoreSort = false
        this.isAddressSort = false
        this.isCitySort = false
        this.isZipSort = false
        this.isPhoneSort = false
        return {
          "_order-by": this.isStateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isStateSort
        }
      }
      if (value === "postalCode") {
        this.isZipSort = !this.isZipSort
        this.isModifiedSort = false
        this.isStoreSort = false
        this.isAddressSort = false
        this.isCitySort = false
        this.isStateSort = false
        this.isPhoneSort = false
        return {
          "_order-by": this.isZipSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isZipSort
        }
      }
      if (value === "phone") {
        this.isPhoneSort = !this.isPhoneSort
        this.isModifiedSort = false
        this.isStoreSort = false
        this.isAddressSort = false
        this.isCitySort = false
        this.isStateSort = false
        this.isZipSort = false
        return {
          "_order-by": this.isPhoneSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isPhoneSort
        }
      }
    },
    handleName(sorter, isAscend) {
      this.sortValueText = sorter
      this.sortValueBool = isAscend
      let index
      if (sorter === "modified") {
        index = 1
      } else if (sorter === "address") {
        index = 4
      } else if (sorter === "storeNumber") {
        index = 2
      } else if (sorter === "city") {
        index = 5
      } else if (sorter === "state") {
        index = 6
      } else if (sorter === "postalCode") {
        index = 7
      } else if (sorter === "phone") {
        index = 8
      }
      this.sortData(index, isAscend)
      const first = (this.paginationValue - 1) * this.currentNum
      const limit = first + this.currentNum
      this.tableData = this.locationList.slice(first, limit)
    },
    sortData(index, ascend) {
      if (index == 1) {
        this.locationList.sort((a, b) => {
          const aDate = a.updatedAt ? a.updatedAt : a.createdAt
          const bDate = b.updatedAt ? b.updatedAt : b.createdAt
          if (ascend) {
            return aDate.localeCompare(bDate)
          } else {
            return bDate.localeCompare(aDate)
          }
        })
      } else if (index == 2) {
        this.locationList.sort((a, b) => {
          if (ascend) {
            return a.storeNumber - b.storeNumber
          } else {
            return b.storeNumber - a.storeNumber
          }
        })
      } else if (index == 5) {
        this.locationList.sort((a, b) => {
          if (ascend) {
            return a.city.localeCompare(b.city)
          } else {
            return b.city.localeCompare(a.city)
          }
        })
      } else if (index == 4) {
        this.locationList.sort((a, b) => {
          if (ascend) {
            return a.address.localeCompare(b.address)
          } else {
            return b.address.localeCompare(a.address)
          }
        })
      } else if (index == 6) {
        this.locationList.sort((a, b) => {
          if (ascend) {
            if (a.state && a.state.id && b.state && b.state.id) {
              return a.state.id.localeCompare(b.state.id)
            }
            if (a.state && a.state.id && (!b.state || !b.state.id)) {
              return a.state.id.localeCompare("")
            }
            if ((!a.state || !a.state.id) && b.state && b.state.id) {
              return "".localeCompare(b.state.id)
            }
          } else {
            if (a.state && a.state.id && b.state && b.state.id) {
              return b.state.id.localeCompare(a.state.id)
            }
            if (b.state && b.state.id && (!a.state || !a.state.id)) {
              return b.state.id.localeCompare("")
            }
            if ((!b.state || !b.state.id) && a.state && a.state.id) {
              return "".localeCompare(a.state.id)
            }
          }
        })
      } else if (index === 7) {
        this.locationList.sort((a, b) => {
          if (ascend) {
            return a.postalCode.localeCompare(b.postalCode)
          } else {
            return b.postalCode.localeCompare(a.postalCode)
          }
        })
      } else if (index === 8) {
        this.locationList.sort((a, b) => {
          const aPhone = a.phone ? a.phone : ""
          const bPhone = b.phone ? b.phone : ""
          if (ascend) {
            return aPhone.localeCompare(bPhone)
          } else {
            return bPhone.localeCompare(aPhone)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.retailer-location-tab__main {
  p {
    margin-bottom: 0;
  }
  .search-title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .create-new-location {
      width: 350px;
      display: flex;
      p {
        width: 150px;
        height: 30px;
        line-height: 30px;
        background-color: #ffffff;
        border-radius: 5px;
        border: 1px solid #e19387;
        color: #e19387;
        text-align: center;
        cursor: pointer;
      }
      p:nth-child(1) {
        margin-right: 20px;
      }
      .add-location-style {
        background: #e19387;
        color: #ffffff;
      }
    }
  }
  .retailer-training-search {
    display: flex;
    .search-style {
      display: flex;
      flex-direction: column;
    }
    .search-input {
      width: 350px;
      margin-right: 15px;
      p {
        margin-bottom: 10px;
      }
    }
    .search-resent {
      width: 270px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .retailer-brand-training-table {
    //width: 952px;
    margin-top: 30px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      width: 100%;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }
    .training-table-style tr {
      width: 100%;
      position: relative;
    }
    .training-table-style th {
      border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 16px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
    }
    .training-table-style tr td {
      max-width: 250px;
      height: 56px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 16px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training-table-style tr,
    .training-table-style td.move:hover {
      //cursor: pointer;
    }
    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }
    .name-tab-style {
      position: relative;
      span {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
    .editable-row-operations-share {
      text-align: center;
      padding: 3px 4px;
      background-color: #4a8a5d;
      color: white;
      border-radius: 5px;
    }
    .editable-row-operations {
      span:hover {
        color: #4a8a5d;
        text-decoration: underline;
      }
    }
  }
  .upload-bulk-csv-style {
    position: relative;
    .template-csv-style {
      position: absolute;
      bottom: -25px;
      right: 0;
      color: rgba(0, 0, 0, 0.45);
      font-size: 10px;
    }
    .template-csv-style:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .ant-tag {
    margin-bottom: 3px;
  }
  .upload-modal__body {
    margin-top: 20px;
  }
  .ant-upload-drag-icon {
    i {
      color: #4a8a5d !important;
    }
  }
  .retailer-location-upload-modal__action {
    padding: 20px 0;
    text-align: center;
  }
  .data-tab-style {
    //position: relative;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
  }
  .location-circular-active {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
  .green-circular {
    background: #9fd19a;
  }
  .red-circular {
    background: #f27c59;
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
  .table-pagination-style {
    text-align: right;
    margin-bottom: 30px;
  }
  .brand-orders__table-cell {
    .ant-checkbox-wrapper::after {
      contain: none;
      display: none;
    }
  }
}
</style>
