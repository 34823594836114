<template>
  <div class="landing-terms-main">
    <p class="logistic-style">Logistics by Landing</p>
    <a-tooltip v-if="!isLogisticsActive" placement="top">
      <template slot="title">
        <span
          >Clicking “Activate” will guide you through accepting the basic terms and conditions
          required before Landing can manage your fulfillment and shipping needs.</span
        >
      </template>
      <span class="active-button" @click="openLogisticModal">
        Activate
      </span>
    </a-tooltip>
    <span v-else class="activated-button" @click="openLogisticModal">
      <a-icon type="check" :style="{ 'margin-right': '5px' }" />ACTIVATED
    </span>
    <div class="logistic-active">
      <a-modal
        :mask-closable="false"
        :centered="true"
        width="623px"
        :visible="logisticModal"
        :footer="null"
        @cancel="handleCancel"
      >
        <p slot="title" class="logistic-modal-title">Logistics by Landing Terms & Conditions</p>
        <p class="logistic-text-content">
          Your use of Landing’s Services is at all times subject to the Terms of Use, which
          incorporates these Logistics by Landing Terms and Conditions. Any terms we use in these
          Logistics by Landing Terms and Conditions without defining them have the definitions given
          to them in the Terms of Use (the “Terms”). Any term capitalized, but not defined herein,
          shall have the meaning set forth in the Terms.
        </p>
        <p class="logistic-text-content">
          In connection with a sale between a Brand and Retailer pursuant to the Services provided
          by the Company, the Brand desires to store consigned inventory of certain Products (the
          “Consigned Product”) at the Company’s third-party logistics premises (hereinafter referred
          to as the “Premises”). Further, the Brand desires the Company to provide fulfillment and
          shipping services related to Offers; provided that, the Company has the ability to change
          the third-party logistics partner at its sole discretion. Brand is willing to provide such
          Consigned Products to the Company at the Premises from time to time upon the following
          terms and conditions:
        </p>
        <p class="logistic-text-content-child">
          From time to time, in order to fulfill Offers, Company and Brand shall mutually determine
          and confirm in writing the amount of Consigned Product to be stored at the Premises. Brand
          shall safely deliver the Consigned Product according to the guidelines provided by Landing
          at Brand’s expense, except as mutually determined by the parties.
        </p>
        <p class="logistic-text-content-child">
          Landing will manage fulfillment and shipment of all Offers or Offers from specific
          Retailers as expressly approved by you on website.
        </p>
        <p class="logistic-text-content-child">
          Except as withdrawn to fulfill Offers, Company shall not remove the Consigned Product from
          the Premises. Brand may remove any Consigned Products from the Premises from time to time
          upon reasonable advance notice to the Company.
        </p>
        <p class="logistic-text-content-child">
          Charges for these Services will be as accepted by you on our website from time to time and
          deduced prior to any remittances issued to you pursuant to Orders.
        </p>
        <p class="logistic-text-content-child">
          Unless caused by employees of Brand, Landing shall be responsible for all loss, damage or
          shortages to the Consigned Product that is stored at the Premises; provided that the we
          will be allowed an annual allowance for inventory shrinkage of 1.5% of the inventory
          stored at the Premises.
        </p>
        <p class="logistic-text-content-child">
          Upon the termination of the Logistics by Landing Services by the Brand, Landing shall
          determine whether we will either promptly return any of the goods remaining in the
          Consigned Product at our sole cost and expense or shall promptly pay for the balance of
          goods in the Consigned Product at the Brand’s actual cost for such goods in effect at the
          time of termination.
        </p>
        <p class="logistic-text-content-child">
          It is acknowledged that Consigned Product is the property of Brand, until such time as the
          Landing removes the item to fulfill Offers in the ordinary course of its business.
        </p>
        <div class="logistic-modal-checkInfo">
          <a-checkbox v-model="checkValue" />
          <p>
            I acknowledge that I have read, and do hereby accept the terms and conditions contained
            in this Inventory Consignment Agreement.
          </p>
        </div>
        <p class="logistic-modal-button">
          <span class="decline-text" @click="handleCancel">Decline</span>
          <span class="accept-text" @click="handleAccept">Accept</span>
        </p>
      </a-modal>
    </div>
  </div>
</template>

<script>
import OrderClient from "@/api/order-client"
export default {
  name: "LandingTermsMain",
  data() {
    return {
      logisticModal: false,
      checkValue: null
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    isLogisticsActive() {
      return this.isActive || this.brand.terms.usesLandingLogistic
    }
  },
  methods: {
    openLogisticModal() {
      if (this.isLogisticsActive) {
        return
      }
      this.logisticModal = true
    },
    handleCancel() {
      this.logisticModal = false
    },
    async handleAccept() {
      if (this.checkValue) {
        // const params = {
        //   ...this.brand.termsPending,
        //   status: {
        //     id: "pending"
        //   },
        //   usesLandingLogisticAfter: true
        // }
        // await OrderClint.brandTermsPending(this.brand.id)
        const result = await OrderClient.updateLandingTermStatus(this.brand.id)
        if (result.usesLandingLogistic) {
          this.isActive = true
        }
        this.$notification.open({
          message: "Thank you!",
          description:
            "Logistics by Landing is now active. Landing will contact you via email on next steps. "
        })
        this.logisticModal = false
        this.$router.push({
          name: this.isAdmin ? "adminLandingTerms" : "brandLandingLogistics"
        })
      }
    }
    // async updatePendingTermStatus(params) {
    //   try {
    //     await this.$store.dispatch("brand/updatePendingTerms", params)
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
  }
}
</script>

<style scoped lang="scss">
.landing-terms-main {
  .logistic-style {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .active-button {
    float: right;
    margin-top: -63px;
    padding: 5px 16px;
    border: 1px solid #4a8a5d;
    background-color: #e2eeec;
    color: #4a8a5d;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .active-button:hover {
    background-color: #4a8a5d;
    color: white;
  }
  .activated-button {
    float: right;
    margin-top: -62px;
    color: #4a8a5d;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}
.logistic-modal-title {
  text-align: center;
  margin-bottom: 0;
}
.logistic-text-content {
  width: 525px;
  font-size: 14px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  //text-align: center;
  margin-left: 30px;
}
.logistic-text-content-child {
  width: 425px;
  font-size: 14px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  //text-align: center;
  margin-left: 70px;
}
.logistic-modal-checkInfo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  color: #000000;
  margin-top: 70px;
  p {
    width: 488px;
    margin-left: 14px;
  }
}
.logistic-modal-button {
  text-align: center;
  margin-top: 20px;
  span {
    padding: 5px 16px;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .decline-text {
    border: 1px solid #d9d9d9;
    color: #595959;
  }
  .accept-text {
    color: white;
    border: 1px solid #4a8a5d;
    background-color: #4a8a5d;
    margin-left: 8px;
  }
}
</style>
