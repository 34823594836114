export default {
    namespaced: true,
    state: {
        maskVisible: false
    },
    mutations: {
        handleMask(state, { visible }) {
            state.maskVisible = visible
        }
    }
}
