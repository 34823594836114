<template>
  <a-row class="admin-retailer-edit">
    <a-col :span="4" style="padding-top: 190px;">
      <a-anchor @change="linkChange">
        <a-anchor-link href="#curations" title="Curations" />
      </a-anchor>
    </a-col>
    <a-col :span="20" style="padding-left: 94px; padding-top: 71px;">
      <div class="admin-retailer-edit__title">
        Edit/Add Brand Curations
      </div>
      <div id="curations" style="width: 100%; margin-bottom: 43px;">
        <ConsoleAdd
          title="Curations"
          :items="curationList"
          :is-focus="isCurationFocus"
          @removeItem="removeAllChannelType"
          @editAll="editAllChannelType"
          @addMultiple="addMultipleChannelType"
          @editItem="editItemChannelType"
          @addNewItem="addNewItemChannelType"
        />
      </div>
    </a-col>
  </a-row>
</template>

<script>
import ConsoleAdd from "@/components/admin/ConsoleAdd"

export default {
  components: {
    ConsoleAdd
  },
  data() {
    return {
      isLoading: false,
      isCurationFocus: false,
      curationList: undefined,
      isAdd: false
    }
  },
  created() {
    this.getReference()
  },
  methods: {
    async getReference() {
      await this.$store.dispatch("reference/getBrandConsole")
      let data = this.$store.getters["reference/brandConsole"].curations
      if (!this.isAdd) {
        this.curationList = this.sortData(data)
      } else {
        for (let i = this.curationList.length; i < data.length; i++) {
          this.curationList.push(data[i])
        }
        this.isAdd = false
      }
    },
    sortData(data) {
      if (data && data.length > 0 && !this.isAdd) {
        data = data.sort((a, b) => a.name.localeCompare(b.name))
      }
      return data
    },
    addMultipleChannelType(datas) {
      this.isAdd = true
      this.updateChannelType(this.getChannelTypeParam(datas))
    },
    editAllChannelType(datas) {
      this.updateChannelType(this.getChannelTypeParam(datas))
    },
    removeAllChannelType(datas) {
      this.updateChannelType(this.getChannelTypeParam(datas))
    },
    editItemChannelType(item) {
      this.updateChannelType(this.getChannelTypeParam([item]))
    },
    addNewItemChannelType(item) {
      this.isAdd = true
      this.updateChannelType(this.getChannelTypeParam([item]))
    },
    async updateChannelType(param) {
      this.$store.dispatch("reference/updateCurationType", param).then(() => {
        this.getReference()
      })
    },
    getChannelTypeParam(data) {
      return { curations: data }
    },
    linkChange(link) {
      if (link === "#curations") {
        this.isCurationFocus = true
      } else {
        this.isCurationFocus = false
      }
    }
  }
}
</script>
