<template>
  <a-row class="admin-retailer-search">
    <a-col :span="24">
      <a-button
        class="admin-console-product-search-result__download-btn"
        style="float: right;"
        @click="clickToDownloadData"
      >
        {{ $t("Admins.AdminInventory.csv") }}
        <a-icon type="download" />
      </a-button>
      <!-- <div style="margin-bottom: 8px;">
        <div style="float: right;" class="admin-product-search__right-view">
          
        </div>
      </div> -->
    </a-col>
    <a-col :span="24" class="admin-console-brand-retailer-product-table">
      <a-table
        bordered
        :data-source="searchResult.products"
        :columns="columns"
        :pagination="false"
        :row-key="record => record.privateId"
      >
        <template slot="msrp" slot-scope="text">
          <span>{{ priceFormat(text) }}</span>
        </template>
        <template slot="brandName">
          <span> Brand Name </span>
          <a-icon type="caret-up" :rotate="isSortAsc ? 0 : 180" @click="doSortByBrandName" />
        </template>
        <template slot="productName">
          <span>Product Name </span>
          <a-icon
            type="caret-up"
            :rotate="isBestProductSortAsc ? 0 : 180"
            @click="doSortByProductBest"
          />
        </template>
        <template slot="productNameValue" slot-scope="text, record">
          <a-tooltip v-if="text && text.length > 20" placement="bottom" :title="text">
            <span class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
              text
            }}</span>
          </a-tooltip>
          <span v-else class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
            text
          }}</span>
        </template>
        <template slot="sku">
          <span>SKU </span>
          <a-icon type="caret-up" :rotate="isBestSkuSortAsc ? 0 : 180" @click="doSortBySkuBest" />
        </template>
        <template slot="barCode">
          <span>UPC </span>
          <a-icon type="caret-up" :rotate="isBestUpcSortAsc ? 0 : 180" @click="doSortByUpcBest" />
        </template>
        <template slot="size">
          <span>Size </span>
          <a-icon type="caret-up" :rotate="isBestSizeSortAsc ? 0 : 180" @click="doSortBySizeBest" />
        </template>
        <template slot="category">
          <span>Category </span>
          <a-icon
            type="caret-up"
            :rotate="isBestCategorySortAsc ? 0 : 180"
            @click="doSortByCategoryBest"
          />
        </template>
        <template slot="msrpSort">
          <span>MSRP</span>
          <a-icon type="caret-up" :rotate="isBestMsrpSortAsc ? 0 : 180" @click="doSortByMsrpBest" />
        </template>
        <template slot="skuColumn" slot-scope="text, record">
          <span class="admin-console-brand-link-click" @click="clickToProductPage(record)">
            {{ text }}
          </span>
        </template>
        <template slot="sizeColumn" slot-scope="text, record">
          <span> {{ `${text} ${record.sizeUnits.id}` }}</span>
        </template>
        <template slot="name" slot-scope="text, record">
          <span> {{ record.brand.name }}</span>
        </template>
        <template slot="barCodeValue" slot-scope="text, record">
          <span> {{ text ? text : record.barcodes[0].code }} </span>
        </template>
        <template slot="categoryValue" slot-scope="text">
          <span> {{ text.name }} </span>
        </template>
      </a-table>
      <a-pagination
        class="inventoryPagination"
        style="margin-top: 10px; float: right;"
        :default-page-size="pageSize"
        :default-current="1"
        :total="searchResult.total"
        size="small"
        :show-quick-jumper="true"
        @change="getNextPageData"
      />
    </a-col>
  </a-row>
</template>

<script>
import { priceFormat, downloadCsvDataStr } from "@/utils/validate"
export default {
  data() {
    return {
      priceFormat,
      pageSize: 30,
      sortBestParam: {},
      isSortAsc: false,
      isBestMsrpSortAsc: false,
      isBestCategorySortAsc: false,
      isBestSizeSortAsc: false,
      isBestUpcSortAsc: false,
      isBestProductSortAsc: false,
      isBestSkuSortAsc: false,
      columns: [
        {
          dataIndex: "rank",
          title: "#",
          width: "6%"
        },
        {
          dataIndex: "brandName",
          slots: { title: "brandName" },
          width: "15%",
          scopedSlots: { customRender: "name" }
        },
        {
          //title: "Product Name",
          dataIndex: "name",
          width: "24%",
          ellipsis: true,
          slots: { title: "productName" },
          scopedSlots: { customRender: "productNameValue" }
        },
        {
          //title: "SKU",
          dataIndex: "sku",
          slots: { title: "sku" },
          width: "10%"
        },
        {
          //title: "UPC",
          dataIndex: "barCode",
          slots: { title: "barCode" },
          width: "15%",
          scopedSlots: { customRender: "barCodeValue" }
        },
        {
          //title: "Size",
          dataIndex: "size",
          slots: { title: "size" },
          scopedSlots: { customRender: "sizeColumn" },
          width: "10%"
        },
        {
          //title: "Category",
          dataIndex: "category",
          slots: { title: "category" },
          scopedSlots: { customRender: "categoryValue" },
          width: "14%"
        },
        {
          //title: "MSRP",
          dataIndex: "msrpUsd",
          width: "10%",
          slots: { title: "msrpSort" },
          scopedSlots: { customRender: "msrp" }
        }
      ],
      selectedRecords: new Set()
    }
  },
  computed: {
    searchResult() {
      return this.$store.getters["adminConsole/products"]
    },
    searchParam() {
      return this.$store.getters["adminConsole/productSearchParam"]
    },
    searchKeyWords() {
      return this.$store.getters["adminConsole/productSearchKeyWords"]
    },
    bestSellersSelect() {
      return this.$store.getters["adminConsole/bestSellersSelect"]
    }
  },
  watch: {
    searchParam() {
      this.getNextPageData(1)
    },
    searchKeyWords() {
      this.getNextPageData(1)
    }
  },
  created() {
    this.getNextPageData(1)
  },
  methods: {
    doSortByBrandName() {
      this.isSortAsc = !this.isSortAsc
      this.sortBestParam = {
        "_sort-by": "brand_name",
        "_order-by": this.isSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNextPageData(1)
    },
    doSortByProductBest() {
      this.isBestProductSortAsc = !this.isBestProductSortAsc
      this.sortBestParam = {
        "_sort-by": "name",
        "_order-by": this.isBestProductSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNextPageData(1)
    },
    doSortBySkuBest() {
      this.isBestSkuSortAsc = !this.isBestSkuSortAsc
      this.sortBestParam = {
        "_sort-by": "sku",
        "_order-by": this.isBestSkuSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNextPageData(1)
    },
    doSortByUpcBest() {
      this.isBestUpcSortAsc = !this.isBestUpcSortAsc
      this.sortBestParam = {
        "_sort-by": "bar_code",
        "_order-by": this.isBestUpcSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNextPageData(1)
    },
    doSortBySizeBest() {
      this.isBestSizeSortAsc = !this.isBestSizeSortAsc
      this.sortBestParam = {
        "_sort-by": "size",
        "_order-by": this.isBestSizeSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNextPageData(1)
    },
    doSortByCategoryBest() {
      this.isBestCategorySortAsc = !this.isBestCategorySortAsc
      this.sortBestParam = {
        "_sort-by": "category_name",
        "_order-by": this.isBestCategorySortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNextPageData(1)
    },
    doSortByMsrpBest() {
      this.isBestMsrpSortAsc = !this.isBestMsrpSortAsc
      this.sortBestParam = {
        "_sort-by": "msrp_usd",
        "_order-by": this.isBestMsrpSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNextPageData(1)
    },
    getNextPageData(page) {
      let param = this.getBaseParam(page)
      if (this.searchParam) {
        param = Object.assign({}, param, this.searchParam)
      }
      if (this.searchKeyWords) {
        param = Object.assign({}, param, this.searchKeyWords)
      }
      this.searchProductsData(param)
    },
    getBaseParam(page) {
      return {
        ...this.sortBestParam,
        _limit: this.pageSize,
        _index: page
      }
    },
    async searchProductsData(param) {
      if (!param) {
        await this.$store.dispatch("adminConsole/fetchProducts", this.getBaseParam(1))
      } else {
        // param = Object.assign({}, param, this.sortBestParam)
        await this.$store.dispatch("adminConsole/fetchProducts", param)
      }
    },
    async clickToDownloadData() {
      let param = Object.assign({}, this.searchParam)
      param["_limit"] = this.searchResult.total
      param["_index"] = 1
      param = Object.assign({}, param, this.sortBestParam)
      await this.$store
        .dispatch("adminConsole/downloadProduct", param)
        .then(result => {
          let fileName = `product_search_result.csv`
          this.download(result, fileName)
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    async download(data, fileName) {
      await downloadCsvDataStr(data, fileName)
        .then(() => {
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    clickToProductPage(item) {
      this.$router.push({
        name: "adminProduct",
        params: {
          id: item.id
        }
      })
    }
  }
}
</script>
<style lang="scss">
.admin-console-product-search-result {
  &__download-btn {
    background: #4a8a5d !important;
    border: 1px solid #4a8a5d !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #fff !important;
    margin: 32px 0px 8px 0px;
  }
}
</style>
