<template>
  <div class="team_page">
    <div>
      <a-modal v-model="visible" width="825px" footer="">
        <div slot="title" class="modal-title"></div>
        <div class="modal-body">
          <div class="modal_left">
            <p class="team_role" style="left: 45px;">{{ modalInfo.Role }}</p>
            <div>
              <img :src="modalInfo.img" style="width: 249px; height: 249px;" />
              <h3 class="team_head" style="padding-left: 5px;">{{ modalInfo.Name }}</h3>
            </div>
          </div>
          <div class="modal_right">
            <p class="detail">{{ modalInfo.Detail }}</p>
          </div>
        </div>
      </a-modal>
    </div>
    <div class="team_contain">
      <div class="team_title">
        Meet the Team
      </div>
      <div class="team_container">
        <div class="team_list">
          <div v-for="(item, index) in item_list" :key="index" class="team_content">
            <span class="team_role">{{ item.Role }}<br /></span>
            <div @click="showModal(item)">
              <img :src="item.img" />
              <h3 class="team_head">{{ item.Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sarah from "@/assets/img/team/sarah_chung.png"
import Thak from "@/assets/img/team/lisa_thak.png"
import Adriana from "@/assets/img/team/Adriana_Lipzstein.png"
import Annie from "@/assets/img/team/Annie_Moberg.png"
import Nadia from "@/assets/img/team/Nadia_Suttle.png"
import SarahKim from "@/assets/img/team/Sarah_Kim.png"
import Sangeun from "@/assets/img/team/Sangeun_Cha.png"
import Vichida from "@/assets/img/team/Vichida_Vongvanij.png"
// import Brenda from "@/assets/img/team/Brenda_Chia.png"
import Gavin from "@/assets/img/team/Gavin_Husni.png"
import Alec from "@/assets/img/team/Alec_Bayot.png"
import Lisa from "@/assets/img/team/Lisa_Song.png"
import connieChing from "@/assets/img/team/connieChing.png"
import JohnDezes from "@/assets/img/team/John_Dezes.png"

export default {
  data() {
    return {
      visible: false,
      modalInfo: {
        Name: "",
        Role: "",
        Detail: "",
        img: ""
      }
    }
  },
  computed: {
    item_list() {
      const imageList = [
        { img: Sarah },
        { img: Thak },
        { img: Adriana },
        { img: SarahKim },
        { img: Annie },
        { img: Nadia },
        { img: Sangeun },
        { img: Vichida },
        { img: JohnDezes },
        { img: Gavin },
        { img: Alec },
        { img: Lisa },
        { img: "" },
        { img: connieChing },
        { img: "" }
      ]
      const textlist = this.$t("Auth.Team")
      return textlist.map((item, index) => {
        return { ...item, ...imageList[index] }
      })
    }
  },
  methods: {
    showModal(item) {
      this.visible = true
      this.modalInfo = item
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Roboto";
  src: url("~@/assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("~@/assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("~@/assets/fonts/Poppins-Medium.ttf") format("truetype");
}

.team_container {
  max-width: 1140px;
  margin: auto;
}

.team_page {
  margin: 0 auto;
}

.team_title {
  font-family: Playfair Display, sans-serif;
  font-style: normal;
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  color: #12433d;
  margin-bottom: 100px;
}

.team_list {
  display: flex;
  flex-wrap: wrap;
}

.modal-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 55px;
  margin-bottom: 30px;
}

.team_role {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  position: absolute;
  left: 33px;
  letter-spacing: 3px;
  color: #1e252c;
}

.team_content {
  padding: 30px 49px;
  position: relative;
  flex: 33.3%;
}

.team_contain {
  margin-top: 119px;
  margin-bottom: 647px;
  text-align: center;
}

.team_head {
  font-family: "Poppins-Bold", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  margin-top: 8px;
  letter-spacing: 0.5px;
  color: #1e252c;
  text-align: left;
  padding-left: 16px;
}

.modal_left {
  flex: 0 0 41.666667%;
  padding: 8px 28px 8px 45px;
}

.modal_right {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 54px;
  flex: 0 0 58.33333%;
}

.detail {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-title {
  height: 27px;
}
</style>
