<template>
  <a-modal v-model="showpendingchanges" title="Terms Change Request" width="640px">
    <template slot="footer">
      <a class="button is-black" @click="hide" v-if="role == 'brand'">{{$t('Components.Retailers.Profile.ChangeRequestModal.ok')}}</a>
      <a class="button" @click="rejectBrandTerms" v-if="role == 'admin'" style="margin-right: 10px;">{{$t('Components.Brands.Settings.ViewPendingChanges.reject')}}</a>
      <a class="button is-black" @click="approveBrandTerms" v-if="role == 'admin'">{{$t('Components.Brands.Settings.ViewPendingChanges.approve')}}</a>
    </template>
    <div class="request-terms-modal">
      <div class="modal-card request-terms-modal__content">
        <div class="modal-card-body">
          <div class="request-terms-modal__body">
            <div class="view-pending-request__wrap">
              <div class="request-terms-modal__details-container"
                   v-if="viewPendingChangeRequest.usesLandingFulfillmentBefore !=viewPendingChangeRequest.usesLandingFulfillmentAfter || brand.terms.usesLandingFulfillment!=true && brand.terms.usesLandingFulfillment!=false">
                <div class="payment-block column is-5">
                  <div class="request-terms-modal__subtitles">
                    {{$t('Components.Brands.Settings.ViewPendingChanges.currentLandingFulfillment')}}
                  </div>
                  <div class="request-terms-modal__details" v-if ="viewPendingChangeRequest.usesLandingFulfillmentBefore===true">
                    {{$t('Brands.BrandSettings.yes')}}
                  </div>
                  <div class="request-terms-modal__details" v-else-if="viewPendingChangeRequest.usesLandingFulfillmentBefore===false && brand.terms.usesLandingFulfillment ===false">
                    {{$t('Brands.BrandSettings.no')}}
                  </div>
                  <div class="request-terms-modal__details" v-else-if="brand.terms.usesLandingFulfillment!=true && brand.terms.usesLandingFulfillment!=false">
                    {{$t('Brands.BrandSettings.notYet')}}
                  </div>
                </div>
                <div class="payment-block column is-5">
                  <div class="request-terms-modal__subtitles">
                    {{$t('Components.Brands.Settings.ViewPendingChanges.newLandingFulfillment')}}
                  </div>
                  <div class="request-terms-modal__details" v-if ="viewPendingChangeRequest.usesLandingFulfillmentAfter===true">
                    {{$t('Brands.BrandSettings.yes')}}
                  </div>
                  <div class="request-terms-modal__details" v-else>
                    {{$t('Brands.BrandSettings.no')}}
                  </div>
                </div>
              </div>
              <div class="request-terms-modal__details-container"
                   v-if="viewPendingChangeRequest.usesLandingShippingBefore !=viewPendingChangeRequest.usesLandingShippingAfter || brand.terms.usesLandingShipping!=true && brand.terms.usesLandingShipping!=false">
                <div class="payment-block column is-5">
                  <div class="request-terms-modal__subtitles">
                    {{$t('Components.Brands.Settings.ViewPendingChanges.currentLandingShipping')}}
                  </div>
                  <div class="request-terms-modal__details" v-if ="viewPendingChangeRequest.usesLandingShippingBefore===true">
                    {{$t('Brands.BrandSettings.yes')}}
                  </div>
                  <div class="request-terms-modal__details" v-else-if="viewPendingChangeRequest.usesLandingShippingBefore===false && brand.terms.usesLandingShipping ===false">
                    {{$t('Brands.BrandSettings.no')}}
                  </div>
                  <div class="request-terms-modal__details" v-else-if="brand.terms.usesLandingShipping!=true && brand.terms.usesLandingShipping!=false">
                    {{$t('Brands.BrandSettings.notYet')}}
                  </div>
                </div>
                <div class="payment-block column is-5">
                  <div class="request-terms-modal__subtitles">
                    {{$t('Components.Brands.Settings.ViewPendingChanges.newLandingShipping')}}
                  </div>
                  <div class="request-terms-modal__details" v-if ="viewPendingChangeRequest.usesLandingShippingAfter===true">
                    {{$t('Brands.BrandSettings.yes')}}
                  </div>
                  <div class="request-terms-modal__details" v-else>
                    {{$t('Brands.BrandSettings.no')}}
                  </div>
                </div>
              </div>
              <div class="request-terms-modal__details-container"
                   v-if="viewPendingChangeRequest.shippingTermsBefore && viewPendingChangeRequest.shippingTermsBefore.name !=viewPendingChangeRequest.shippingTermsAfter.name || !brand.terms.shippingTerms">
                <div class="payment-block column is-5">
                  <div class="request-terms-modal__subtitles">
                    {{$t('Components.Brands.Settings.ViewPendingChanges.currentshippingTerms')}}
                  </div>
                  <div class="request-terms-modal__details">
                    {{viewPendingShippingTerms}}
                  </div>
                </div>
                <div class="payment-block column is-5">
                  <div class="request-terms-modal__subtitles">
                    {{$t('Components.Brands.Settings.ViewPendingChanges.newshippingTerms')}}
                  </div>
                  <div class="request-terms-modal__details">
                    {{viewPendingChangeRequest.shippingTermsAfter.name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns request-term-details" v-if="role == 'brand'">
              <div>
                {{$t('Components.Brands.Settings.ViewPendingChanges.content')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    viewPendingChangeRequest: {
      type: Object,
      default() {
        return {
          usesLandingFulfillmentBefore: null,
          usesLandingFulfillmentAfter: null,
          usesLandingShippingBefore: null,
          usesLandingShippingAfter: null,
          shippingTermsBefore: {
            id: null,
            name: null
          },
          shippingTermsAfter: {
            id: null,
            name: null
          }
        }
      }
    }
  },
  data() {
    return {
      showpendingchanges: false
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    viewPendingShippingTerms() {
      if (
        this.brand.termsPending &&
        this.brand.termsPending.shippingTermsBefore &&
        this.brand.termsPending.shippingTermsBefore.name
      ) {
        return this.brand.termsPending.shippingTermsBefore.name
      } else if (
        this.brand.terms &&
        this.brand.terms.shippingTerms &&
        this.brand.terms.shippingTerms.name
      ) {
        return this.this.brand.terms.shippingTerms.name
      } else return "(Not set)"
    }
  },

  methods: {
    hide() {
      this.showpendingchanges = false
    },
    openDialog() {
      this.showpendingchanges = true
    },
    changeNeedReview() {
      this.$emit("changeTermsPending")
    },
    approveBrandTerms() {
      this.brand.termsPending.status.id = "approved"
      this.brand.termsPending.status.name = "Approved"
      this.updatePendingTermStatus()
    },
    rejectBrandTerms() {
      this.brand.termsPending.status.id = "rejected"
      this.brand.termsPending.status.name = "Rejected"
      this.updatePendingTermStatus()
    },
    async updatePendingTermStatus() {
      this.hide()
      try {
        await this.$store.dispatch("brand/updatePendingTerms", this.brand.termsPending)
        await this.$store.dispatch("brand/getBrand", this.brand.id)
        this.changeNeedReview()
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style lang="sass">
.request-terms-modal
  .modal-card-body
    display: flex
    .view-pending-request__wrap
      margin: 0px 0px 60px 0px
      .request-terms-modal__details-container
        padding-bottom: 60px
        display: flex
        .payment-block
          .request-terms-modal__subtitles
            color: #000
            font-weight: 500
            padding-bottom: 30px
          .request-terms-modal__details
            color: #000
            margin: 0px
        .payment-block + .payment-block
            margin-left: 30px
      :last-child
        padding-bottom: 0px

  .request-term-details-info
  color: #000
  font-size: 14px
  font-weight: 400
  margin: 0px !important

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .request-terms-modal__close-button
      top: 16px
      width: 14px
      height: 14px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 601px

  .request-terms-modal__buttons
  .button
      height: 30px
      font-size: 14px
      padding: 16px 20px

  &__body
    padding: 30px 20px
    width: 601px

  &__buttons
    padding: 10px 20px
    display: flex
    justify-content: flex-end

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__details-container
    font-size: 14px
    padding-bottom: 60px

  &__details
    margin: 18px
</style>
