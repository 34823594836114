<template>
  <div id="inputOuter">
    <!--    <a-row-->
    <!--      v-if="!isForwordList"-->
    <!--      id="text-container"-->
    <!--      :class="-->
    <!--        role === 'admin'-->
    <!--          ? 'message-input-content__text-contailer-admin'-->
    <!--          : 'message-input-content__text-contailer'-->
    <!--      "-->
    <!--    >-->
    <!--    </a-row>-->
    <a-row v-if="!isForwordList">
      <editorCom
        :is-admin="isAdmin"
        :generate-content="generateContent"
        :is-forword-list="isForwordList"
        :from="from"
        :temp-text="tempText"
        :is-small-status="isSmallStatus"
        :is-has-connection="isHasConnection"
        :to-data="toData"
        :disable="disable"
        :draft-info="draftInfo"
        @send="sendMessage"
        @sendDraft="sendDraft"
        @hideDialog="hideDialog"
      ></editorCom>
    </a-row>
    <!--    <a-row style="padding: 7px 5px;">-->
    <!--      <a-col v-if="!isForwordList" :span="2" style="text-align: center; margin-top: 10px;">-->
    <!--        <a-icon type="delete" @click="deleteAction" />-->
    <!--      </a-col>-->
    <!--    </a-row>-->
    <!--    <div id="introBrand" style="display: none;">-->
    <!--      <BrandIntroTemplate :from="from" :to-data="toData" />-->
    <!--    </div>-->
  </div>
</template>
<script>
// import E from "wangeditor"
// import E from "@wangeditor/editor-for-vue"
// import i18next from "i18next"
// import BrandIntroTemplate from "@/components/message/components/BrandIntroTemplate"
import { checkBrandRetailerConnection } from "@/utils/validate"
import editorCom from "@/components/message/components/editor_com"
export default {
  components: {
    // BrandIntroTemplate,
    editorCom
  },
  props: {
    isForwordList: { type: Boolean, default: () => false },
    content: { type: String, default: () => "" },
    generateContent: { type: Number, default: () => -1 },
    from: { type: Object, default: () => undefined },
    toData: { type: Object, default: () => undefined },
    draftInfo: { type: Object, default: () => undefined },
    isSmallStatus: { type: Boolean, default: () => true },
    isHasConnection: { type: Boolean, default: () => true },
    receiversData: { type: Array, default: () => [] },
    disable: { type: Boolean, default: () => undefined }
  },
  data() {
    return {
      editor: null,
      editorData: "",
      htmlContent: "",
      disableSend: this.disable === undefined ? true : this.disable,
      addressedIds: [],
      fileList: [],
      tempText: null,
      toAddress: []
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  watch: {
    receiversData() {
      this.toAddress = [{ id: "*", name: "Landing" }]
      if (this.receiversData && this.receiversData.length > 0) {
        this.toAddress.push(...this.receiversData)
      }
    }
  },
  // updated() {
  //   console.log("......input updated......")
  //   console.log(this.isHasConnection)
  // },
  created() {
    this.toAddress = [{ id: "*", name: "Landing" }]
    if (this.receiversData && this.receiversData.length > 0) {
      this.toAddress.push(...this.receiversData)
    }
  },
  methods: {
    getEditorData() {
      let data = this.editor.txt.html()
      alert(data)
    },
    hideDialog() {
      this.$emit("hideDialog")
    },
    generateBrandCustomMessage() {
      return `<div class="ant-row" style="${
        this.isSmallStatus ? "padding: 14px 20px;" : "padding: 40px 30px;"
      }">
              <div class="message-input-content">
                <div class="message-input-content__content">
                  <div>
                   please input the content here
                  </div>  
                  <div>
                      Sincerely,<br/>
                      ${this.from.name}
                  </div>  
                </div>
              </div>
        </div>`
    },
    generateCommonMessage() {
      return `<div class="ant-row" style="padding: 40px;">
              <div class="message-input-content">
                <p class="message-input-content__from">${this.from.name}</p> 
                <p class="message-input-content__to">To: </p>
                <div class="message-input-content__divider"></div>
                <div class="message-input-content__content">
                  <div>
                   please input the content here
                  </div>  
                  <div>
                      Sincerely,<br/>
                      ${this.from.name}
                  </div>  
                </div>
              </div>
        </div>`
    },
    checkSendIds(idsStr) {
      this.addressedIds = []
      let obj = document.getElementsByClassName("message-input-content__to")
      let str = "To:"
      let hasErr = false
      if (idsStr.indexOf(",") > 0) {
        let ids = idsStr.split(",")
        if (ids && ids.length > 0) {
          ids.forEach(item => {
            if (item && item.length > 0 && item.replace(/^\s*|\s*$/g, "").length > 0) {
              let res = this.addAddresId(item)
              hasErr = res.hasErr
              str += res.addressStr
            }
          })
        }
      } else {
        if (idsStr && idsStr.length > 0 && idsStr.replace(/^\s*|\s*$/g, "").length > 0) {
          let res = this.addAddresId(idsStr)
          hasErr = res.hasErr
          str += res.addressStr
        }
      }
      console.log(hasErr)
      //if (hasErr) {
      //str = str.substring(0, str.length -1)
      obj[0].innerHTML = str
      // }
    },
    addAddresId(item) {
      let obj = this.getAddressObj(item)
      let hasErr = false
      let str = ""
      if (obj) {
        if (this.role === "brand") {
          let isOk = this.checkConnection(obj)
          if (isOk) {
            this.addressedIds.push(Object.assign({}, obj))
            str = item + ","
          } else {
            hasErr = true
            str = `<font color="red">${item}</font>,`
          }
        } else {
          this.addressedIds.push(Object.assign({}, obj))
          str = item + ","
        }
      } else {
        hasErr = true
        str = `<font color="red">${item}</font>,`
      }
      return { hasErr: hasErr, addressStr: str }
    },
    getAddressObj(itemName) {
      let name = itemName.replace(/^\s*|\s*$/g, "")
      if (itemName.indexOf("<font") >= 0) {
        //remove the <font></font>
        let data = itemName.split(">")
        name = data[1]
      }
      if (this.toAddress && this.toAddress.length > 0) {
        let result = this.toAddress.filter(item => item.name === name)
        if (result && result.length > 0) {
          return result[0]
        }
      }
    },
    checkConnection(obj) {
      return checkBrandRetailerConnection(this.from, obj)
      //   this.$store
      //     .dispatch("adminMessage/hasConnection", { retailerId: obj.id, brandId: this.fromUserId })
      //     .then(res => {
      //       return res
      //     })
      //     .catch(err => {
      //       console.log(err)
      //       return false
      //     })
    },
    checkToAddress() {
      let obj = document.getElementsByClassName("message-input-content__to")
      if (obj && obj.length > 0) {
        let toValue = obj[0].outerText
        let toIdStr = toValue.split("To:")
        let idsStr = toIdStr[1]
        this.checkSendIds(idsStr)
      }
    },
    sendMessage(message, fileList, addressedIds) {
      this.checkToAddress()
      if (!message && this.generateContent !== 10) {
        this.$message.error("The content is empty, please enter and submit")
      }
      this.$emit("send", message, fileList, addressedIds)
    },
    sendDraft(message, fileList, addressedIds) {
      // this.checkToAddress()
      if (!message) {
        this.$message.error("The content is empty, please enter and submit")
      }
      this.$emit("sendDraft", message, fileList, addressedIds)
    }
    // getNewMsgContentInfo() {
    //   let contentObj = document.getElementsByClassName("message-input-content__content")
    //   if (contentObj && contentObj.length > 0) {
    //     console.log(contentObj[0])
    //     console.log(contentObj[0].innerHTML)
    //     return contentObj[0].innerHTML
    //   }
    //   return ""
    // },
  }
}
</script>
<style lang="scss" scoped>
.message-input-component {
  &__toolbar-area {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    display: inline-block;
  }
}
</style>
<style lang="scss">
@font-face {
  font-family: "icomoon";
  src: url("../../assets/fonts/icomoon.ttf") format("truetype");
}
.message-input-content {
  background: #f5f5f5;
  border-radius: 16px;
  padding: 20px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  &__from {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 4px;
  }
  &__to {
    color: #000000;
    margin-bottom: 10px;
  }
  &__divider {
    border-bottom: 1px solid #d9d9d9;
    margin-right: 60px;
    margin-bottom: 20px;
  }
  &__content {
    color: #262626;
  }
  &__text-container {
    border-top: 1px solid rgba(74, 138, 93, 0.46);
    border-bottom: 1px solid rgba(74, 138, 93, 0.46);
    margin-top: -1px;
    min-height: 111px;
  }
  &__text-contailer-admin {
    border: 1px solid rgba(74, 138, 93, 0.46);
    margin-top: -1px;
    min-height: 217px;
  }
}
.message-input-component__toolbar-area .w-e-icon-image:before {
  font-family: "icomoon";
  font-size: 20px;
  content: "\e900";
}
</style>
