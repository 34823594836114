<template>
  <a-modal
    v-model="isShow"
    width="403px"
    dialog-class="adminProductTrainingDialog"
    :footer="null"
    :destroy-on-close="true"
  >
    <a-row class="admin-modal-title"> Are Your Sure? </a-row>
    <div class="admin-modal-content" style="text-align: center;">
      <div style="width: 250px; margin-top: 90px; margin-left: 36px; margin-bottom: 140px;">
        <!-- Deleting this User(s) will permanently remove him/her/they from everything. It cannot be
        undone! -->
        {{ message }}
      </div>
    </div>
    <a-row style="margin-top: 20px;">
      <a-col :span="12">
        <a-button
          class="admin-training-landing-deep-red-btn"
          style="width: 90%;"
          @click="handleCancel"
          >Cancel</a-button
        >
      </a-col>
      <a-col :span="12" style="text-algin: right;">
        <a-button
          class="admin-training-landing-light-green-btn"
          style="width: 90%;"
          :loading="isDoAction"
          @click="handleSave"
          >I’m Sure</a-button
        >
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
export default {
  props: {
    isDeleteUser: { type: Boolean, default: () => true },
    message: {
      type: String,
      default: () =>
        "Deleting this User(s) will permanently remove him/her/they from everything. It cannot be undone!"
    }
  },
  data() {
    return {
      isShow: false,
      isDoAction: false,
      record: undefined
    }
  },
  methods: {
    openDialog(data) {
      this.record = data
      this.isShow = true
    },
    handleCancel() {
      this.isShow = false
      this.isDoAction = false
      this.record = undefined
    },
    handleSave() {
      this.isDoAction = true
      console.log(this.record)
      if (this.isDeleteUser) {
        for (let i = 0; i < this.record.length; i++) {
          let param = { id: this.record[i].id, isDeleted: true }
          this.$store
            .dispatch("seller/updateASeller", { sellerId: this.record[i].id, params: param })
            .then(() => {
              if (i == this.record.length - 1) {
                this.$message.success("Delete user success")
                this.handleCancel()
                this.$emit("success")
              }
            })
            .catch(err => {
              console.log(err)
              this.isDoAction = false
              this.$message.error(err.message)
            })
        }
      } else {
        let ids = this.record.map(item => item.id).join(",")
        this.$store
          .dispatch("seller/deleteLoginMessage", ids)
          .then(() => {
            this.$message.success("Delete Success")
            this.handleCancel()
            this.$emit("success")
          })
          .catch(err => {
            if (err && err.response && err.response.data) {
              this.$message.error(err.response.data.developerMessage)
            } else {
              this.$message.error(err.message)
            }
            this.isDoAction = false
          })
      }
    }
  }
}
</script>
