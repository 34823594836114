<template>
  <a-modal
    v-model="isShow"
    width="900px"
    dialog-class="adminProductTrainingDialog admin-training-retailer-brand-product"
    :footer="null"
    :destroy-on-close="true"
  >
    <a-row class="admin-modal-title">
      Product Training Details1
    </a-row>
    <a-table
      :columns="tableColumn"
      :data-source="tableData"
      size="middle"
      class="admin-training-table"
      :loading="isLoading"
      :pagination="false"
    >
      <span slot="hero" slot-scope="text, record">
        <span
          v-if="record.training != null && record.training.hero"
          class="admin-training-retailer-brand-product__hero-product-position"
          >Hero</span
        >
        <span v-else></span>
      </span>
      <span slot="upc" slot-scope="text, record">
        {{ record.barcodes && record.barcodes.length > 0 ? record.barcodes[0].code : "" }}
      </span>
      <span slot="skuOper" slot-scope="text">
        {{ text }}
      </span>
      <span slot="publicOper" slot-scope="text">
        <!-- <a-switch :checked="text" :disabled="true" class="admin-training-switch" /> -->
        <div
          :class="
            text == 2
              ? 'admin-training-active-green'
              : text == 1
              ? 'admin-training-deactive-red'
              : 'admin-training-deactive-gray'
          "
        ></div>
      </span>
    </a-table>
    <a-row style="margin-top: 20px; text-align: right;">
      <a-button
        class="admin-training-landing-deep-red-btn"
        style="width: 150px;"
        @click="hideDialog"
        >Close</a-button
      >
    </a-row>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      tableColumn: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name"
          //   sorter: true
        },
        {
          title: "Hero",
          dataIndex: "hero",
          key: "hero",
          //   sorter: true,
          scopedSlots: { customRender: "hero" }
        },
        {
          title: "SKU",
          dataIndex: "sku",
          key: "sku",
          //   sorter: true,
          scopedSlots: { customRender: "skuOper" }
        },
        {
          title: "UPC",
          dataIndex: "upc",
          key: "upc",
          //   sorter: true,
          scopedSlots: { customRender: "upc" }
        },
        {
          title: "Modified",
          dataIndex: "modified",
          key: "modified"
          //   sorter: true
        },
        {
          title: "Public",
          dataIndex: "public",
          key: "public",
          scopedSlots: { customRender: "publicOper" }
        }
      ],
      brandId: undefined,
      isShow: false,
      tableData: undefined,
      isLoading: false,
      pagination: {
        onChange: page => {
          this.getProduct(this.getInitParam(page))
        },
        size: "small",
        showQuickJumper: true,
        pageSize: 9999
      }
    }
  },
  methods: {
    openDialog(brandId) {
      this.brandId = brandId
      this.isShow = true
      this.isLoading = true
      this.getProduct(this.getInitParam(1))
    },
    hideDialog() {
      this.isShow = false
    },
    getInitParam(page) {
      let param = this.getBaseParam(page)
      param.params = Object.assign({}, param.params, {
        "_sort-by": "name",
        "_order-by": "PREVIOUS"
      })
      return param
    },
    getBaseParam(page) {
      const param = {
        _index: page,
        _limit: this.pagination.pageSize,
        brand: this.brandId,
        "is-download-csv": false
      }
      console.log(param)
      return { params: param }
    },
    getProduct(param) {
      this.$store
        .dispatch("product/getProductsByPage", param)
        .then(() => {
          this.tableData = this.$store.getters["product/getProductPageData"].products
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.$message.error(err.message)
        })

      // let param = {
      //     "brand": this.brandId,
      //     "_limit": 50,
      //     "_sort-by": "created",
      //     "_order-by": "next",
      //     "_index": page
      // }
      //  this.$store.dispatch("product/getProductsByPage", { params: param })
      //    .then(() => {
      //       this.tableData = this.$store.getters["training/getTrainingProducts"]
      //       this.isLoading = false
      //     })
      //     .catch(err => {
      //       this.isLoading = false
      //       this.$message.error(err)
      //     })
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-training-retailer-brand-product {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  &__hero-product-position {
    padding: 3px 8px;
    color: #ffffff;
    font-size: 12px;
    background-color: #f3ca50;
    border-radius: 20px;
  }
}
</style>
