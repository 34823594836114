<template>
  <div class="update-payment-details">
    <a-row>
      <span class="update-payment-details__title">
        {{ $t("Components.Brands.Payment.paymentDetails") }}
      </span>
    </a-row>
    <a-row>
      <span class="update-payment-details__subtitle-desc">
        {{ $t("Components.Brands.Settings.PaymentDetails.paymentMethods") }}
      </span>
    </a-row>
    <a-row>
      <a-col :span="15">
        <payment-card-list :card-info-list="paymentMethods" :brand-id="brandId" style="margin-bottom: 20px;" />
        <a-row>
          <a-col :span="20">
            <button class="update-payment-details__add-new-text update-payment-details__add-new-border"
              @click="showAddDialog">
              {{
          $t("Components.Brands.Settings.PaymentDetails.addNewPayment")
        }}
              <span class="update-payment-details__add-new-icon" />
            </button>
          </a-col>
          <a-col v-if="subscriptionId && subscriptionId === 'Manual'" :span="4" style="text-align: right;">
            <a-button class="update-payment-details__pay-btn" @click="clickToPay">Pay</a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-modal v-model="isShowUpdateMethod" class="update-payment-dialog" :footer="null" width="501px"
      :destroy-on-close="true">
      <template #closeIcon>
        <img src="@/assets/img/icon/close-circle.svg" />
      </template>
      <a-row>
        <span class="update-payment-dialog__title ">{{
          $t("Components.Brands.Payment.updatePayment")
        }}</span>
      </a-row>
      <a-row v-if="this.isUseAuthorizeNet">
        <new-payment-info :brand-id="brandId" @addPayment="afterAddedNewPayment"></new-payment-info>
      </a-row>
      <div v-else>
        <a-row>
          <payment-info @cardInfo="receiveCardData" />
        </a-row>

        <a-row class="update-payment-dialog__button-row">
          <a-button class="update-payment-dialog__button" @click="doUpdateMethod">
            {{ $t("Components.Brands.Payment.updatePayment") }}
            <span class="update-payment-details__add-new-icon" />
          </a-button>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import NewCardList from "./NewCardList";
import PaymentInfo from "../payment/PaymentInfo";
import { Row, Col, Modal, Button } from "ant-design-vue";
import NewPaymentInfo from "../payment/NewPaymentInfo";
import SubscriptionService from "@/services/subscriptions-service";
import Utils from "@/common/Utils";
export default {
  components: {
    "a-row": Row,
    "a-col": Col,
    "a-modal": Modal,
    "a-button": Button,
    "payment-card-list": NewCardList,
    "payment-info": PaymentInfo,
    "new-payment-info": NewPaymentInfo,
  },
  data() {
    return {
      isUseAuthorizeNet: true,
      paymentMethods: [],
      isShowDeleteDialog: false,
      isShowUpdateMethod: false,
      selectedIndex: "",
      selectedProfileId: null,
      selectedId: null,
      cardData: undefined,
    };
  },
  computed: {
    paymentMethodsData() {
      //this.getPayments(this.brandId);
      return this.$store.getters["brandPayment/cardInfoList"];
    },
    brandId() {
      return this.isAdmin
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"];
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"];
    },
    subscriptionId() {
      let brand = this.$store.getters["brand/brand"];
      if (brand && brand.subscriptions && brand.subscriptions.length > 0) {
        return brand.subscriptions[0].subscriptionId;
      }
      return undefined;
    },
  },
  async created() {
    let brandId =
      this.$route.name == "adminBrandUpdatePaymentDetails"
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"];
    await this.$store.dispatch("brandPayment/fetchCards", brandId);
    this.refreshPaymentMethods();
  },
  methods: {
    async refreshPaymentMethods() {
      this.paymentMethods = await this.getPayments(this.brandId);
    },
    async getPayments(brandId) {
      var cardInfoList = [];
      var paymentMethods = null;

      try {
        paymentMethods = await SubscriptionService.getPaymentMethods(brandId);
        this.isUseAuthorizeNet = true;
        cardInfoList = paymentMethods.map((p) => this.toCardList(p));
      } catch (ex) {
        this.isUseAuthorizeNet = false;

        console.log(ex);
      }

      if (!this.isUseAuthorizeNet) {
        cardInfoList = this.$store.getters["brandPayment/cardInfoList"];
        //console.log(this.$store.getters["brandPayment/cardInfoList"]);
      }

      return cardInfoList;
    },
    toCardList(payment) {
      var cc = payment.creditCard;
      var r = {
        brandPrivateId: this.brandId,
        cardBrand: cc.cardType,
        cardCode: cc.cardCode,
        cardHolderName: `${payment.firstName || ""} ${payment.lastName || ""}`,
        cardNumber: cc.cardNumber,
        expirationDate: cc.expirationDate,
        isExpired: false,
        default: cc.isDefaultPaymentMethod,
        customerPaymentProfileId: payment.customerPaymentProfileId,
        customerProfileId: 0,
        isAuthorizeNet: true,
      };

      console.log(r);

      return r;
    },
    /**
     *  show add new payment method dialog
     *  which named update payment
     */
    showAddDialog() {
      this.isShowUpdateMethod = true;
    },
    hideAddDialog() {
      this.isShowUpdateMethod = false;
    },
    receiveCardData(data) {
      this.cardData = data;
    },
    isCreditCard() {
      if (this.cardData.payType !== "creditCard") {
        return false;
      }
      return true;
    },
    checkCardInfo() {
      if (!this.cardData.cardNumber) {
        let info = this.$t("Components.Brands.Payment.cardNumberEmpty");
        this.$message.error(info);
        return false;
      }
      if (!this.cardData.expirationDate) {
        let info = this.$t("Components.Brands.Payment.expirationDateEmpty");
        this.$message.error(info);
        return false;
      }
      if (!this.cardData.cardCode) {
        let info = this.$t("Components.Brands.Payment.cvvEmpty");
        this.$message.error(info);
        return false;
      }
      if (!Utils.checkCardNumber(this.cardData.cardNumber)) {
        // check number
        let info = this.$t("Components.Brands.Payment.cardNumberError");
        this.$message.error(info);
        return false;
      }
      if (!Utils.checkExpiredDate(this.cardData.expirationDate)) {
        let info = this.$t("Components.Brands.Payment.expiredDateFormatError");
        this.$message.error(info);
        return false;
      }
      if (Utils.isDateExpired(this.cardData.expirationDate)) {
        let info = this.$t("Components.Brands.Payment.expiredDateExpiredError");
        this.$message.error(info);
        return false;
      }
      if (!Utils.checkCardCode(this.cardData.cardCode)) {
        let info = this.$t("Components.Brands.Payment.cardCodeError");
        this.$message.error(info);
        return false;
      }
      return true;
    },
    async afterAddedNewPayment() {
      this.refreshPaymentMethods();
      this.hideAddDialog();
    },
    async doUpdateMethod() {
      //invoke api to save data
      if (!this.cardData) {
        let info = this.$t("Components.Brands.Payment.cardNumberError");
        this.$message.error(info);
        return;
      }
      if (this.isCreditCard()) {
        if (this.checkCardInfo()) {
          this.addCardInfo();
        }
      } else {
        this.hideAddDialog();
      }
    },
    async addCardInfo() {
      await this.$store
        .dispatch("brandPayment/updateCardInfo", {
          brandId: this.brandId,
          cardInfo: this.cardData,
        })

        .then((response) => {
          if (response.error) {
            this.$message.error(response.developerMessage);
          } else {
            let info = this.$t("Components.Brands.Payment.addSuccess");
            this.$message.success(info);
            this.cardData = undefined;
            this.hideAddDialog();
            this.refreshPaymentMethods();
          }
        });
    },
    clickToPay() {
      if (this.isAdmin) {
        this.$router.push({
          name: "adminBrandSubscription",
          params: {
            isOnlyPay: true,
          },
        });
      } else {
        this.$router.push({
          name: "brandSubscription",
          params: {
            isOnlyPay: true,
          },
        });
      }
    },
  },
};
</script>

<style lang="sass">
.update-payment-details
    margin-bottom: 200px
    &__title
      font-family: Playfair Display
      font-style: normal
      font-weight: bold
      font-size: 48px
      line-height: 38px
      display: flex
      align-items: center
      color: #000000
      margin-bottom: 30px
    &__subtitle-desc
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 24px
      display: flex
      align-items: center
      color: #595959
      margin-bottom: 26px
    &__method-desc
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 22px
      display: flex
      align-items: center
      color: #000000
    &__content-padding
      padding: 5px 16px 10px 12px !important
    &__method-card-num
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 22px
      display: flex
      align-items: center
      text-align: center
      color: #000000
    &__other-text
      font-family: SF Pro Display
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 20px
      display: flex
      align-items: center
    &__black
      text-align: center
      color: #000000
    &__green
      color: #4A8A5D
    &__red
      color: #FF6132
    &__green-border-bg
      display: flex
      flex-direction: row
      align-items: center
      padding: 1px 8px
      background: #F6FFED
      border: 1px solid #4A8A5D
      box-sizing: border-box
      border-radius: 2px
      text-align: center
    &__pay-btn
      border: 1px solid #4A8A5D !important
      border-radius: 2px !important
      color: #4A8A5D !important
      font-size: 16px !important
      line-height: 24px !important
      height: 30px !important
    &__add-new-text
      font-family: SF Pro Display
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 24px
      display: flex
      align-items: center
      text-align: center
      color: #4A8A5D
    &__add-new-border
      padding: 2px 10px
      border: 1px solid #4A8A5D
      box-sizing: border-box
      border-radius: 2px
      align-items: center
    &__add-new-icon:before
      content: "\00a0"
    &__add-new-icon
      background-size: contain
      background-position: 50%
      background-repeat: no-repeat
      position: relative
      display: inline-block
      width: 1.33333333em
      line-height: 1em
      margin-left: 4px
      background-image: url("../../../assets/img/icon/add_new_payment_btn_card.svg")
.update-payment-dialog
  width: 501px
  &__title
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 18px
    line-height: 24px
    display: flex
    align-items: center
    color: #262626
    margin-bottom: 17px
  &__button-row
    align-items: center
    margin-top: 20px
    padding: 0px 25%
  &__button
    font-family: Proxima Nova !important
    font-style: normal !important
    font-weight: normal !important
    font-size: 16px !important
    line-height: 24px !important
    border-radius: 2px !important
    border-color: #4A8A5D !important
    color: #4A8A5D !important
.no-padding-left
  padding: 12px 0px 12px 0px!important
.no-padding
  padding: 13px 0px !important
.direction-right
  direction: rtl
.margin-bottom-seven
  margin-bottom: 7px
.card-margin-bottom
  margin-bottom: 20px
.disable-action
  pointer-events: none
</style>
