<template>
  <div class="memberss__wrap">
    <div class="members-details-table">
      <a-row class="members-details-table-header">
        <a-col :span="18">
          <p v-if="memberCount > 1" class="members__title">
            {{ memberCount }} {{ $t("Components.Brands.Settings.Members.members") }}
          </p>
          <p v-else class="members__title">
            {{ $t("Components.Brands.Settings.Members.memberCount") }}
          </p>
        </a-col>
        <a-col v-if="brandType !== 'basic' && isOwner" :span="6" class="invite_member">
          <a-button class="button-send-invitations" @click="onClickRequestInvite()">
            {{ $t("Retailers.RetailerSettings.requestInvitations") }}
          </a-button>
        </a-col>
      </a-row>
      <div class="members-details-table-body">
        <a-row class="table-header">
          <a-col :span="5">
            <p class="brand-settings__account-information_text">
              {{ $t("Components.Brands.Settings.Members.name") }}
            </p>
          </a-col>
          <a-col :span="5">
            <p class="brand-settings__account-information_text">
              {{ $t("Components.Brands.Settings.Members.email") }}
            </p>
          </a-col>
          <a-col :span="4">
            <p class="brand-settings__account-information_text">
              {{ $t("Components.Brands.Settings.Members.title") }}
            </p>
          </a-col>
          <a-col :span="5">
            <p class="brand-settings__account-information_text">
              {{ $t("Components.Brands.Settings.Members.phoneNumber") }}
            </p>
          </a-col>
          <a-col :span="3">
            <p class="brand-settings__account-information_text">
              {{ $t("Retailers.RetailerSettings.status") }}
            </p>
          </a-col>
          <a-col :span="3"></a-col>
        </a-row>
      </div>
      <div v-if="members.length" class="table-body">
        <MemberItem
          :is-owner="isOwner"
          :member="members"
          @addedMember="addedMember"
          @refreshData="updateMemberOwner"
        >
        </MemberItem>
      </div>
      <div
        v-if="(brandType === 'basic' && !isAdmin) || isAdmin"
        class="members-details-table-footer"
      >
        <div class="span banner-status">
          <div class="title-block" style="text-align: center;">
            <p v-if="brandType === 'basic'" class="member-upgrade-title">
              {{ $t("Components.Brands.Settings.Members.upgradeToAdd") }}
            </p>
            <p v-if="brandType === 'basic'" class="member-upgrade-subtitle">
              {{ $t("Components.Brands.Settings.Members.premiumText") }}
            </p>
            <div class="button-block">
              <!--              <a class="box-button button member-upgrade-button" @click="clickToShowUpgradePlan">-->
              <!--                {{-->
              <!--                  brandType === "basic"-->
              <!--                    ? $t("Components.Brands.Settings.Members.upgradePlan")-->
              <!--                    : $t("Brands.BrandSettings.downgradePlan")-->
              <!--                }}-->
              <!--              </a>-->
            </div>
          </div>
        </div>
      </div>
      <div>
        <InviteMemberModal
          v-if="showRequestInvite"
          :show-invite-member="showRequestInvite"
          @inviteMemberModalClose="closeInviteMemberModal"
          @addedMember="addedMember"
          @onClickClose="showRequestInvite = false"
        >
        </InviteMemberModal>
        <MemberInvitedModal
          v-if="successfullyAddedMember"
          :show-member-invited="successfullyAddedMember"
          :member-added="memberAdded"
          @memberInvitedModalClose="memberInvitedModalClose"
          @inviteMorePeople="inviteMorePeople"
        >
        </MemberInvitedModal>
        <BrandTableDialog ref="memberUpgradePlanDialog" />
        <UpgradePlanDialog ref="upgradePlanDialog" :brand-type="brandType" />
      </div>
    </div>
  </div>
</template>

<script>
import MemberItem from "@/components/brands/settings/MemberItem"
import InviteMemberModal from "@/components/shared/InviteMemberModal"
import MemberInvitedModal from "@/components/shared/MemberInvitedModal"
import BrandTableDialog from "@/components/brands/mainPage/components/BrandTableDialog"
import UpgradePlanDialog from "@/components/shared/UpgradePlanDialog"
import { Row, Col, Button } from "ant-design-vue"
import { required, numeric } from "vuelidate/lib/validators"

export default {
  components: {
    MemberItem,
    InviteMemberModal,
    MemberInvitedModal,
    BrandTableDialog,
    UpgradePlanDialog,
    "a-button": Button,
    "a-row": Row,
    "a-col": Col
  },
  validations: {
    paidAmount: { required, numeric },
    selectPlanStartDate: { required },
    selectPlanEndDate: { required },
    selectBasicStartDate: { required }
  },
  data() {
    return {
      showPlan: false,
      showRequestInvite: false,
      showMemberActions: false,
      successfullyAddedMember: false,
      brandMembers: [],
      memberAdded: {},
      isOwner: false
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandType() {
      return (
        this.brand &&
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    },
    members() {
      let data = (this.brand && this.brand.members) || []
      console.log(data)
      this.$forceUpdate()
      return data
    },
    memberCount() {
      if (this.brand && this.brand.members) {
        let count = 0
        for (let i = 0; i < this.brand.members.length; i++) {
          if (
            this.brand.members[i] &&
            this.brand.members[i].membershipStatus &&
            this.brand.members[i].membershipStatus.id &&
            this.brand.members[i].membershipStatus.id !== "delete"
          ) {
            count++
          }
        }
        return count
      }
      return 0
    },
    userId() {
      return this.$store.getters["user/id"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  async created() {
    const brandId =
      this.$route.name == "adminBrandMember"
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"]
    await this.$store.dispatch("brand/getBrand", brandId)
    this.brandMembers = this.brand.members
    this.brandMembers.sort(function(a, b) {
      var c = new Date(a.createdAt)
      var d = new Date(b.createdAt)
      return c - d
    })
    let owner = this.brand.members.find(member => member.role.id == "owner")
    if (this.userId == owner.user.id || this.isAdmin) {
      this.isOwner = true
    }
  },
  methods: {
    hide() {
      this.showPlan = false
    },
    onClickRequestInvite() {
      this.showRequestInvite = true
    },
    onClickOutsideMember() {
      this.showMemberActions = false
    },
    profileIcon(firstName, lastName) {
      if (firstName && lastName) {
        let result = `${firstName.charAt(0)} ${lastName.charAt(0)}`
        return result
      }
      return " "
    },
    addedMember(member) {
      this.fetchBrand()
      this.successfullyAddedMember = true
      this.memberAdded = member
    },
    async fetchBrand() {
      const brandId =
        this.$route.name == "adminBrandMember"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      await this.$store.dispatch("brand/getBrand", brandId)
      this.brandMembers = this.brand.members
      this.brandMembers.sort(function(a, b) {
        var c = new Date(a.createdAt)
        var d = new Date(b.createdAt)
        return c - d
      })
    },
    updateMemberOwner(data) {
      for (let index in this.members) {
        if (this.members[index].role.id === "owner") {
          this.$set(this.members[index], "role", { id: "member", name: "Member" })
        }
        if (this.members[index].user.id === data.user.id) {
          this.$set(this.members[index], "role", data.role)
        }
      }
      let owner = this.brand.members.find(member => member.role.id == "owner")
      if (this.userId == owner.user.id || this.isAdmin) {
        this.isOwner = true
      }
      this.$forceUpdate()
    },
    closeInviteMemberModal() {
      this.showRequestInvite = false
    },
    memberInvitedModalClose() {
      this.successfullyAddedMember = false
    },
    inviteMorePeople() {
      this.successfullyAddedMember = false
      this.showRequestInvite = true
    },
    // upgrade plan button action
    clickToShowUpgradePlan() {
      if (this.isAdmin) {
        // display admin dialog
        this.$refs["upgradePlanDialog"].openDialog()
      } else {
        this.$refs["memberUpgradePlanDialog"].openDialog()
      }
    }
  }
}
</script>

<style lang="sass">
.brand-settings
 &__title
    color: #000000
    font-size: 20px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 30px

.memberss__wrap
  .members-details-table
    border: 1px solid #ddd

    .members-details-table-header
      padding: 11px 0px
      border-bottom: 1px solid #ddd
      display: flex

      .members__title
        margin-top: 6px
        padding-left: 18px
        margin-bottom: 0px !important
      .invite_member
        justify-content: flex-end
        display: flex
        padding-right: 20px
        .button-send-invitations
          background-color: black
          color: white
          font-size: 14px
          width: 125px
          height: 31px
          line-height: 31px
          text-align: center
          border-radius: 0
    .members-details-table-body
      .table-header
        padding: 11px 35px
        display: flex
        border-bottom: 1px solid #ddd
        .membersList
          flex: 1!important
          &__table-cell

        .brand-settings__account-information_text
          color: #888888

      .table-body
        padding: 6px 35px
        border-bottom: 1px solid #ddd
        display: block !important
        align-items: center
        .membersList
          flex: 1!important

        .user-name__block
          display: flex
          align-items: center

          .user-name-status
            width: 31px
            height: 31px
            background-color: #e3d7d7
            border: 1px solid #979797
            color: #535050
            padding: 2px
            border-radius: 100%
            font-size: 16px
            font-weight: 300
            margin-right: 5px
            /*display: flex*/
            align-items: center
            justify-content: center

        .brand-settings__account-information_name
          color: #333
          font-size: 14px
          white-space: normal
          word-break: break-all

    .members-details-table-footer
      .banner-status
        width: 100%
        margin-top: 0px
        margin-left: 0px
        border: none
        display: flex
        flex-direction: column
        justify-content: center

        .title-block
          .title-boxbanners
            margin-top: 0px
            .title-style
              font-weight: bold
          .title-boxbanners + .title-boxbanners
            margin-top: 25px

.button-block
  margin-top: 25px
  text-align: center
.box-button
  min-width: 124px
  width: auto
  min-height: 27px
  font-size: 14px
.member-upgrade-title
  font-family: Proxima
  margin-top: 30px
  margin-left: 30px
  line-height: 2px
  font-weight: 700
  font-size: 16px
  letter-spacing: 0
  color: #000000
  margin-bottom: 0px !important
.member-upgrade-subtitle
  font-family: Proxima
  margin-top: 25px
  margin-left: 30px
  line-height: 2px
  font-size: 16px
  letter-spacing: 0
  color: #000000
  margin-bottom: 0px !important
.member-upgrade-button
  background-color: #000
  color: #fff
  font-size: 14px
  margin-bottom: 30px
.member-upgrade-button:hover
  color: #fff
</style>
