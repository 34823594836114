<template lang="pug">
.documents-form(:class="{ 'wait': this.wait }")
  .documents-form__header {{$t('Components.Brands.Products.ProductDocumentsForm.safetyDataSheet')}}
  .is-multiline.is-mobile
    .column
      .product-documents__new.has-text-centered(@click="$refs.documentUpload.click()" :class="{ 'disabled': this.documents.length == 1}")
        //img.product-documents__icon(src="@/assets/img/icon/document-upload-icon.png")
        .upload-block
          a.product-documents__new-button {{$t('Components.Brands.Products.ProductDocumentsForm.browseToUploadFiles')}}
        input.file-input(
          type='file'
          ref="documentUpload"
          multiple
          :disabled='isSaving || documents.length == 1'
          @input='saveFiles($event.target.files)'
          accept=".pdf"
        )
  .upload-file__area-info
    p {{$t('Components.Brands.Products.ProductDocumentsForm.onlyPDF')}}
  .upload-file__block(v-for="document in documents")
    .left-block
      .image-block
        <svg width="31px" height="40px" viewBox="0 0 31 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: sketchtool 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
            <title>EF7617DE-E829-460E-8E5F-42C2F9158FC8</title>
            <desc>Created with sketchtool.</desc>
            <defs></defs>
            <g id="Asset-Artboard-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Files/Attachments/PDF-Files/Attachments/Small/PDF">
                    <g id="PDF">
                        <g id="Group">
                            <g id="Group-2">
                                <g id="Group-3">
                                    <path d="M2.5,0 L19.7412,0 L30.9999,11.215 L30.9999,37.5 C30.9999,38.8812 29.88,40 28.4999,40 L2.5,40 C1.11996,40 2.07424e-05,38.8812 2.07424e-05,37.5 L2.07424e-05,2.49998 C2.07424e-05,1.11877 1.12009,0 2.5,0 Z" id="Path-1" fill="#D1E1DD"></path>
                                    <path d="M30.9638,11.25 L22.25,11.25 C20.8699,11.25 19.75,10.1301 19.75,8.75004 L19.75,0.0250244 L30.9638,11.25 Z" id="Path-2" fill="#B4C3BF"></path>
                                </g>
                            </g>
                        </g>
                        <text font-family="ProximaNova-Regular, Proxima Nova" font-size="10" font-weight="normal" fill="#737781">
                            <tspan x="6.31" y="24">PDF</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </svg>
      .file-details
        .top-block
          p.upload-file-name {{$t('Components.Brands.Products.ProductDocumentsForm.uploadedBy')}} {{ getName(document.createdBy) }} • 
          span {{ formattedDate(document.createdAt) }}
        .bottom-block
          span {{ sizeInKB(document.id) }} KB

    .right-block
      a.download-link(@click="wait=true, downloadDocument(document.id)") {{$t('Components.Brands.Products.ProductDocumentsForm.download')}}
      a.delete-link(@click.prevent.stop="deleteDocument(document.id)") {{$t('Components.Brands.Products.ProductDocumentsForm.delete')}}

</template>

<script>
import axios from "@/api/axios"
import assetClient from "@/api/asset-client"

export default {
  props: {
    initialDocuments: { type: Array, default: () => [] },
    clientId: { type: String, default: null }
  },
  data() {
    return {
      documents: this.initialDocuments,
      isSaving: false,
      wait: false
    }
  },
  async mounted() {
    if (!this.initialDocuments.length) {
      return
    }
    this.updateFormDocuments()
  },
  methods: {
    productFormDocuments() {
      return this.documents.map(document => {
        return { id: document.id, label: "fake-document-label", ...document }
      })
    },
    updateFormDocuments() {
      this.$emit("updateDocuments", this.productFormDocuments())
    },
    emitProductSaving(status) {
      this.$emit("productSaving", status)
    },
    sizeInKB(documentId) {
      this.$store.dispatch("asset/getAsset", documentId)
      var asset = this.$store.getters["asset/find"](documentId)
      if (asset) {
        return asset.size / 1000
      }
    },
    getName(createdBy) {
      this.$store.dispatch("user/fetchUser", createdBy)
      return this.$store.getters["user/memberName"]
    },
    formattedDate(createdAt) {
      const date = new Date(createdAt)
      const time = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false
      })
      const month = date.toLocaleString("en-US", { month: "short" })
      return `${date.getDate()} ${month} ${date.getFullYear()} ${time}`
    },
    async saveFile(file) {
      const { data } = await assetClient.createAsset(file, this.clientId)
      this.documents.push(data[0].file)
    },
    async saveFiles(files) {
      this.isSaving = true
      if (files[0]["type"] != "application/pdf") {
        this.isSaving = false
        this.emitProductSaving("error")
        return
      }
      this.emitProductSaving("saving")
      const vm = this

      try {
        await Promise.all(Array.from(files).map(file => vm.saveFile(file)))
        this.updateFormDocuments()
        this.emitProductSaving("saved")
      } catch (error) {
        console.error(error)
        this.emitProductSaving("error")
      } finally {
        this.isSaving = false
      }
    },
    async downloadDocument(documentId) {
      await this.$store.dispatch("asset/getAsset", documentId)
      const asset = this.$store.getters["asset/find"](documentId)
      axios({
        url: asset.url,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", asset.originalFileName)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
        this.wait = false
      })
    },
    async deleteDocument(id) {
      this.$refs.documentUpload.value = ""
      this.documents = this.documents.filter(document => ![document.id].includes(id))
      this.updateFormDocuments()
    }
  }
}
</script>

<style lang="sass">
.disabled
  pointer-events: none
  background-color: white !important
  color: black !important
  border-color: #8D8D8D !important
  opacity: 0.6

.documents-form
  width: 600px

  &__header
    font-size: 16px
    padding: 20px 0px 5px 0px

  .upload-file__area-info
    margin-top: 5px
    p
      color: #333
      font-size:10px
      font-weight: 300

  .upload-file__block
    margin-top: 26px
    display: flex
    align-items: center
    justify-content: space-between

    .left-block
      display: flex
      align-items: center

      .image-block
        width: 30px

      .file-details
        margin-left: 15px

        .top-block
          display: flex
          p
            font-size: 10px
            color: #737781
          span
            font-size: 10px
            color: #737781
            margin-left: 5px

        .bottom-block
          span
            font-size: 13px
            color: #737781

    .right-block
      display: flex
      align-items: center
      flex-direction: column

      .download-link
        color: #000
        font-size: 12px

      .delete-link
        color: #D0021B
        font-size: 12px
        margin-top: 12px


.file-input
  height: 1px
  opacity: 0

.wait
  cursor: wait

.product-documents
  &__card
    height: 250px
    width: 250px
    display: flex
    align-items: center
    justify-content: center
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06)
    border-radius: 4px
    background-color: white

  &__icon
    margin-top: 45px

  &__image
    max-width: 250px
    max-height: 250px
    font-size: 12px

  &__info
    text-align: center
    padding-top: 10px

    &-name
      font-weight: 500
      font-size: 13px
      letter-spacing: 0.01em
      color: #60ceb3 !important

  &__new
     width: 580px !important
     height: 222px
     font-size: 20px
     border-radius: 5px
     cursor: pointer
     border: 2px dashed #DFE3E8
     display: flex
     align-items: center
     justify-content: center

     .upload-block
      a
        color: #007ACE

     &-button
       color: #333333
       height: 36px
       width: 136px
       font-size: 12px

     &-recommendations
       color: #637381
       font-size: 14px
       padding-top: 10px
</style>
