<template>
  <div class="beauty-fluent-step-three-payment__main">
    <div class="billing-title">
      <p>
        <a-avatar
          style="color: #F15A42; background-color: rgba(237, 142, 131, .2);margin-right: 18px"
          >{{ isBrand ? 2 : 3 }}</a-avatar
        >
        <span style="font-size: 18px;font-weight: bold;color: #1a1818">SELECT PAYMENT METHOD</span>
      </p>
    </div>
    <div
      class="choose-card-payment"
      style="font-size: 16px;font-family: 'Proxima Nova', 'Roboto', sans-serif"
    >
      <a-radio-group v-model="value" @change="radioChange">
        <a-radio v-if="isBrand" :value="radioInfo.selectCard" style="margin-right: 24px"
          >Credit Card</a-radio
        >
        <a-radio v-else :value="radioInfo.creditCard" style="margin-right: 24px"
          >Credit Card</a-radio
        >
        <a-radio v-if="isBrand" :value="radioInfo.newCard" style="margin-right: 24px"
          >New Card</a-radio
        >
        <a-radio :value="radioInfo.transfer">Wire Transfer</a-radio>
      </a-radio-group>
    </div>
    <div v-if="value !== radioInfo.selectCard" class="payment-form-style">
      <p v-if="value === radioInfo.transfer" style="margin: 20px 0">
        Please complete this contact form. A member of our team will be in touch to confirm details
        for your wire transfer.
      </p>
      <div style="margin-top: 20px">
        <a-form-model
          ref="bfStep3PaymentForm"
          :model="paymentForm"
          name="nest-messages"
          :rules="rules"
        >
          <div class="form-item-name">
            <a-form-model-item prop="firstName">
              <a-input
                v-model="paymentForm.firstName"
                placeholder="First Name"
                style="width: 300px"
                @change="inputValue"
              />
            </a-form-model-item>
            <a-form-model-item prop="lastName">
              <a-input
                v-model="paymentForm.lastName"
                placeholder="Last Name"
                style="width: 300px"
                @change="inputValue"
              />
            </a-form-model-item>
          </div>
          <!-- credit card form -->
          <a-form-model-item
            v-if="value === radioInfo.creditCard || value === radioInfo.newCard"
            prop="creditCardNumber"
          >
            <a-input
              v-model="paymentForm.creditCardNumber"
              placeholder="Credit Card #"
              @change="inputValue"
            />
          </a-form-model-item>
          <div
            v-if="value === radioInfo.creditCard || value === radioInfo.newCard"
            class="form-item-name"
          >
            <a-form-model-item prop="expiration">
              <!--              <a-input-->
              <!--                v-model="paymentForm.expiration"-->
              <!--                placeholder="Expiration"-->
              <!--                @change="inputValue"-->
              <!--              />-->
              <a-date-picker
                :picker="'month'"
                style="width: 300px"
                placeholder="MM/YY"
                format="MM/YY"
                @change="changeDate"
              />
            </a-form-model-item>
            <a-form-model-item prop="cvv">
              <a-input
                v-model="paymentForm.cvv"
                style="width: 300px"
                placeholder="CVV"
                @change="inputValue"
              />
            </a-form-model-item>
          </div>
          <!-- transfer -->
          <a-form-model-item v-if="value === radioInfo.transfer" prop="newEmail">
            <a-input v-model="paymentForm.newEmail" placeholder="Email" @change="inputValue" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div v-else>
      <p style="margin: 20px 0;font-size: 16px;font-family: 'Proxima Nova', 'Roboto', sans-serif">
        Your credit and debit cards
      </p>
      <div v-for="(item, index) in cardList" :key="index" class="payment-form-style">
        <div class="card-box-content" @click="chooseCard(item)">
          <p style="width: 80%;display: flex;justify-content: flex-start;align-items: center">
            <a-radio v-if="isBrand" v-model="item.isClick" style="margin-right: 24px"></a-radio>
            <CardNumber :card-info="item"></CardNumber>
            <span v-if="item.default" class="default-card-style">Default</span>
          </p>
          <p>
            <span style="display: block">{{ item.expirationDate }}</span>
            <span style="display: block;color: #F15A42">{{
              item.isExpired ? "(Expired)" : ""
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AES from "@/common/AES"
import CardNumber from "@/components/brands/settings/CardNumber"

const radioInfo = {
  creditCard: 1,
  transfer: 2,
  newCard: 3,
  selectCard: 4
}
export default {
  name: "BFStep3Payment",
  components: {
    CardNumber
  },
  props: {
    isBrand: {
      type: Boolean,
      default: false
    },
    defaultCard: {
      type: Object,
      default: () => {}
    },
    createEmail: {
      type: String,
      default: ""
    },
    cardLists: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      radioInfo,
      value: this.isBrand ? radioInfo.selectCard : radioInfo.creditCard,
      time: null,
      paymentForm: {
        // credit card form
        firstName:
          this.value === radioInfo.creditCard
            ? this.defaultCard && this.defaultCard.cardHolderName.split(" ")[0]
            : undefined,
        lastName:
          this.value === radioInfo.creditCard
            ? this.defaultCard && this.defaultCard.cardHolderName.split(" ")[1]
            : undefined,
        creditCardNumber: undefined,
        expiration: null,
        cvv: undefined,
        // transfer
        newEmail: undefined
      },
      cardList: this.cardLists.map(item => {
        item.isClick = !!item.default
        return item
      }),
      rules: {
        creditCardNumber: [
          { required: true, message: "Please input credit Card #", trigger: "blur" },
          { min: 13, message: "Length should be more than 13 ", trigger: "change" }
        ],
        expiration: [{ required: true, message: "Please input expiration", trigger: "blur" }],
        firstName: [{ required: true, message: "Please input first name", trigger: "blur" }],
        lastName: [{ required: true, message: "Please input last name", trigger: "blur" }],
        newEmail: [{ required: true, message: "Please input email", trigger: "blur" }],
        cvv: [
          { required: true, message: "Please input cvv", trigger: "blur" },
          { min: 3, max: 4, message: "Length should be 3 or 4 ", trigger: "change" }
        ]
      }
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    }
    // paymentInfo() {
    //   if (this.brand && this.value === radioInfo.creditCard) {
    //     return {
    //       ...this.paymentForm,
    //       firstName: this.defaultCard.cardHolderName.split(" ")[0],
    //       lastName: this.defaultCard.cardHolderName.split(" ")[1]
    //     }
    //   }
    //   return this.paymentForm
    // }
  },
  methods: {
    changeDate(value, dateStr) {
      console.log("date", value, dateStr)
      // this.time = dateStr
      this.paymentForm = {
        ...this.paymentForm,
        expiration: dateStr.split("/").join("")
      }
      this.inputValue()
    },
    radioChange() {
      this.$emit("radioChange", this.value)
    },
    inputValue() {
      let data
      let isFinal = false
      if (this.value === radioInfo.creditCard) {
        data = {
          brandCreditCardInformation: [
            {
              firstName: this.paymentForm.firstName,
              lastName: this.paymentForm.lastName,
              cardNumber: AES.encrypt(this.paymentForm.creditCardNumber),
              cardCode: AES.encrypt(this.paymentForm.cvv),
              email: this.createEmail,
              cardHolderName: `${this.paymentForm.firstName} ${this.paymentForm.lastName}`,
              expirationDate: AES.encrypt(this.paymentForm.expiration),
              default: true
            }
          ]
        }
        const obj = JSON.parse(JSON.stringify(this.paymentForm))
        delete obj.newEmail
        const nodataList = Object.values(obj)
        if (nodataList.filter(item => !item).length === 0) {
          isFinal = true
        }
      }
      if (this.value === radioInfo.newCard) {
        data = {
          cardNumber: this.paymentForm.creditCardNumber,
          cardCode: this.paymentForm.cvv,
          expirationDate: this.paymentForm.expiration,
          cardHolderName: `${this.paymentForm.firstName} ${this.paymentForm.lastName}`,
          default: true
        }
        const nodata = Object.values(data)
        if (nodata.filter(item => !item).length === 0) {
          isFinal = true
        }
      }
      if (this.value === radioInfo.transfer) {
        data = {
          brandWireTransferContacts: [
            {
              firstName: this.paymentForm.firstName,
              lastName: this.paymentForm.lastName,
              email: this.paymentForm.newEmail
            }
          ]
        }
        if (this.paymentForm.firstName && this.paymentForm.lastName && this.paymentForm.newEmail) {
          isFinal = true
        }
      }
      this.$emit("getPaymentType", this.value, isFinal)
      this.$emit("paymentSend", data, this.paymentForm.cvv, this.paymentForm.creditCardNumber)
    },
    chooseCard(item) {
      this.cardList = this.cardList.map(value => {
        value.isClick = value.cardNumber === item.cardNumber
        return value
      })
      this.$emit("getPaymentType", this.value, true)
      this.$emit("chooseCreditCard", item)
    },
    checkValue(validFun) {
      this.$refs.bfStep3PaymentForm.validate(valid => {
        if (validFun) {
          validFun(valid)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.beauty-fluent-step-three-payment__main {
  p {
    margin: 0;
  }
  .billing-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    font-family: "Niveau Grotesk";
  }
  .form-item-name {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ant-form-item {
    margin-bottom: 24px;
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input-password,
    .ant-input-affix-wrapper .ant-input {
      height: 55px;
      border-radius: 100px;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #f15a42 !important;
  }
  .ant-radio-checked::after {
    border: 1px solid #f15a42;
  }
  .ant-radio-inner::after {
    background-color: #f15a42 !important;
  }
  .ant-radio-wrapper {
    margin-top: -4px;
  }
  .card-box-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    height: 60px;
    padding: 10px 20px;
    border: 1px solid rgba(26, 24, 24, 0.2);
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .card-box-content:hover {
    background-color: rgba(237, 142, 131, 0.2);
    border-color: #f15a42;
  }
  .default-card-style {
    font-size: 12px;
    color: #f15a42;
    border-radius: 5px;
    border: 1px solid #f15a42;
    text-align: center;
    padding: 3px 5px;
    margin-left: 5px;
  }
}
</style>
