var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"width":_vm.modalWidth,"footer":null,"mask":!_vm.isSmallStatus,"closable":false,"body-style":_vm.bodyStyle,"mask-closable":false,"dialog-style":_vm.isSmallStatus ? { bottom: '100px', right: '-364px' } : { top: '10px' }},on:{"cancel":_vm.hideDialog},model:{value:(_vm.showMessageList),callback:function ($$v) {_vm.showMessageList=$$v},expression:"showMessageList"}},[_c('div',{staticStyle:{"margin":"0 auto"},style:(_vm.isSmallStatus ? 'width: 561px;' : 'width: 952px; padding-bottom: 48px;')},[(!_vm.isSmallStatus)?_c('a-row',{staticClass:"message-list-modal__title-outer"},[_c('a-col',{attrs:{"span":6}},[_c('span',{staticClass:"message-list-modal__title"},[_vm._v("Messaging")])]),_c('a-col',{staticStyle:{"text-align":"right"},attrs:{"span":18}},[_c('a-icon',{staticStyle:{"margin-right":"8px","font-size":"14px","font-weight":"bold"},attrs:{"type":_vm.isSmallStatus ? 'arrows-alt' : 'shrink'},on:{"click":_vm.clickToSwitch}}),_c('a-icon',{attrs:{"type":"close"},on:{"click":_vm.hideDialog}})],1)],1):_vm._e(),_c('div',{staticClass:"message-list-modal"},[_c('a-row',{staticStyle:{"padding":"5px 8px","border-bottom":"1px  solid rgba(74, 138, 93, 0.46)"}},[_c('a-col',{attrs:{"span":18}},[(!_vm.isForwordList && !_vm.forwardMessage)?_c('span',{staticStyle:{"margin-left":"19px"}},[(
                _vm.messageData &&
                  _vm.messageData.messageForwards &&
                  _vm.messageData.messageForwards.length > 0
              )?_c('span',[_vm._v("Fwd:")]):_vm._e(),_vm._v("Subject:"+_vm._s(_vm.messageData ? _vm.messageData.subject : "")+" ")]):_c('span',[_vm._v(" To: "),_c('a-select',{class:_vm.isToError
                  ? 'admin-message-brand-create-new-modal__toTarget admin-message-brand-to-error'
                  : 'admin-message-brand-create-new-modal__toTarget',staticStyle:{"width":"200px"},attrs:{"show-search":"","filter-option":false},on:{"search":_vm.handleSearch,"change":_vm.onSelectToTarget},model:{value:(_vm.forwardTo),callback:function ($$v) {_vm.forwardTo=$$v},expression:"forwardTo"}},_vm._l((_vm.dataSource),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]),(_vm.isSmallStatus)?_c('a-col',{staticStyle:{"text-align":"right"},attrs:{"span":6}},[_c('a-icon',{staticStyle:{"margin-right":"8px","font-size":"14px","font-weight":"bold"},attrs:{"type":_vm.isSmallStatus ? 'arrows-alt' : 'shrink'},on:{"click":_vm.clickToSwitch}}),_c('a-icon',{attrs:{"type":"close"},on:{"click":_vm.hideDialog}})],1):_vm._e()],1),(_vm.isForwordList)?_c('a-row',{staticStyle:{"padding":"9px 8px 9px 27px","border-bottom":"1px  solid rgba(74, 138, 93, 0.46)"}},[_c('span',[_vm._v("Fwd: Subject:"+_vm._s(_vm.messageData ? _vm.messageData.subject : "")+" ")])]):_vm._e(),(_vm.messageDetails)?_c('div',{staticStyle:{"height":"343px","overflow-y":"auto","padding":"4px 25px","border-bottom":"1px  solid rgba(74, 138, 93, 0.46)"},attrs:{"id":"messageReplyList"}},[_vm._l((_vm.messageDetails.messages),function(message){return [(!message.isDelete)?_c('MessageItem',{key:`message_${message.id}`,attrs:{"is-small-status":_vm.isSmallStatus,"message":message,"no-forward":_vm.isForwordList,"no-operation":_vm.isItemNoOperation},on:{"uHeight":_vm.updateHeight,"deleteMessage":_vm.deleteMessageItem,"forwardMessage":_vm.forwardMessageItem}}):(
              _vm.isShowDeleteMessageId &&
                _vm.isShowDeleteMessageId.length > 0 &&
                _vm.isShowDeleteMessageId.includes(message.id)
            )?_c('div',{key:`delete-message_${message.id}`,staticStyle:{"width":"100%","margin-bottom":"29px"}},[_c('div',{staticClass:"message-list-modal__delete-msg-area"},[_vm._v(" This message has been deleted. "),_c('span',{staticClass:"message-list-modal__undo",on:{"click":function($event){return _vm.undoDelete(message)}}},[_vm._v("Undo")])])]):_vm._e()]}),(_vm.isShowWaiting && !_vm.isRetailer)?_c('div',{staticClass:"message-list-modal__waiting-replay"},[_c('a-spin',{staticStyle:{"margin-right":"8px"}}),_vm._v(" Waiting for Reply...... "),_c('div',{staticClass:"message-list-modal__waiting-replay-info-layer"},[_vm._v(" Message was sent successfully!"),_c('br'),_vm._v("Please wait until the retailer responds to send a new message. ")])],1):_vm._e()],2):_vm._e(),(_vm.forwardMessage)?_c('a-row',{staticStyle:{"border-bottom":"1px solid rgba(74, 138, 93, 0.46)","padding":"12px 10px 9px 30px"}},[_c('MessageItem',{attrs:{"is-forward":true,"message":_vm.forwardMessage}})],1):_vm._e(),(_vm.isForwordList || _vm.forwardMessage !== undefined)?_c('a-row',{staticStyle:{"padding":"9px 0px 11px 30px"}},[_c('a-button',{staticClass:"message-list-modal__send-btn",attrs:{"type":"primary","disabled":_vm.forwardTo === undefined || _vm.forwardTo.length === 0 || _vm.isToError},on:{"click":_vm.sendForwardMessage}},[_vm._v("Send")])],1):_c('message-input-component',{attrs:{"is-small-status":_vm.isSmallStatus,"draft-info":_vm.draftInfo,"generate-content":_vm.isShowWaiting && !_vm.isRetailer ? 1 : -1},on:{"send":_vm.sendMessage,"sendDraft":_vm.sendDraft,"hideDialog":_vm.hideDialog}})],1),_c('confirm-dialog',{ref:"confirmClose",on:{"doConfirm":_vm.doCloseDialog}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }