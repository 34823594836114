import axios from "@/api/axios"

export default {
  fetch(retailerId) {
    return axios.get(`/retailers/${retailerId}`)
  },
  fetchRetailers(params = {}) {
    return axios.get("/retailers", { params })
  },
  getAllRetailers(params = {}) {
    return axios.get("/retailers", { params: { _limit: 9000, ...params } })
  },
  updateRetailer(retailer, retailerId) {
    return axios.post(`/retailers/${retailerId}`, JSON.stringify(retailer))
  },
  updateRetailerPartially(retailer) {
    return axios.post(`/retailers/${retailer.id}?_skipNulls=true`, JSON.stringify(retailer))
  },
  updateCategories(categories, retailerId) {
    return axios.put(`/retailers/${retailerId}/product-categories`, JSON.stringify(categories))
  },
  updateTerms(terms, retailerId) {
    return axios.post(`/retailers/${retailerId}/terms`, JSON.stringify(terms))
  },
  updateLocations(locations, retailerId) {
    return axios.put(`/retailers/${retailerId}/location-counts`, JSON.stringify(locations))
  },
  updateSocialAccounts(socialAccounts, retailerId) {
    return axios.put(`/retailers/${retailerId}/social-accounts`, JSON.stringify(socialAccounts))
  },
  updateAddress(addresses, retailerId) {
    return axios.put(`/retailers/${retailerId}/addresses`, JSON.stringify(addresses))
  },
  updatePendingTerms(pendingTerms, retailerId) {
    return axios.post(`/retailers/${retailerId}/terms-pending`, JSON.stringify(pendingTerms))
  },
  addMember(member, retailerId) {
    return axios.post(`/retailers/${retailerId}/add-members`, JSON.stringify(member))
  },
  adminAddMember(member, retailerId, params) {
    return axios.post(`/retailers/${retailerId}/add-members`, JSON.stringify(member), { params })
  },
  updateMemberStatus(member, retailerId, params) {
    return axios.post(`/retailers/${retailerId}/update-members`, JSON.stringify(member), { params })
  },
  updateOtherTypes(member, retailerId) {
    return axios.put(`/retailers/${retailerId}/other-types`, JSON.stringify(member))
  },
  updateForbiddenIngredients(ingredients, retailerId) {
    return axios.put(`/retailers/${retailerId}/forbidden-ingredients`, JSON.stringify(ingredients))
  },
  removeForbiddenIngredients(ingredients, retailerId) {
    return axios.post(`/retailers/${retailerId}/forbidden-ingredients`, JSON.stringify(ingredients))
  },
  getRetailerCleanPreCheck(retailerId, currentPage) {
    return axios.get(`/retailers/${retailerId}/clean-pre-check?_limit=25&page-index=${currentPage}`)
  },
  getRetailerForbiddenList(retailerId) {
    return axios.get(`/retailers/${retailerId}/forbidden-ingredients`, {
      params: { _limit: 9999, "page-index": 1 }
    })
  },
  retailerSearchToBrand() {
    return axios.get(`/brands?status=approved&_limit=99999&fields=id,name,shortName`)
  },
  retailerRegistration(params) {
    return axios.post("/retailers/registration", JSON.stringify(params))
  },
  getRetailerAdminTrainingList(params = {}) {
    return axios.get("/retailers", { params })
  },
  getBeautyfluentRetailer() {
    return axios.get("/bf-retailers")
  }
}
