export default class StoreUtil {
  static getStateProperty(state, stateProperties) {
    let data = state
    if (stateProperties != void 0) {
      stateProperties.forEach(p => {
        data = data[p]
      })
    }
    return data
  }
}
