<template>
  <div style="width: 100%;">
    <div v-if="showEditor" class="editor-style">
      <Editor
        v-model="htmlText"
        style="min-height: 144px;"
        :default-config="editorConfig"
        :mode="mode"
        :style="
          isAdmin ? 'border: 1px solid #BFBFBF; border-radius: 2px; border-bottom: none;' : ''
        "
        @onCreated="onCreated"
        @onChange="onChange"
      />
      <div v-if="draftTime" class="draft-save-info-style">
        <p>Draft Saved at {{ draftTime }}</p>
      </div>
      <span v-if="isLoading"><a-spin /></span>
      <div v-if="fileList && fileList.length !== 0" class="file-list-editor">
        <div
          v-for="(item, index) in fileList"
          :key="index"
          style="margin-right: 10px;background: #f5f5f5;"
        >
          <p>
            <span style="color: #1D4BA1">
              {{ item.name ? item.name : item.url }}({{ item.size ? item.size : 0 }}k)
            </span>
            <span class="delete-file-info" @click="deleteFil(item)">x</span>
          </p>
        </div>
      </div>
    </div>
    <div
      style="display: flex;justify-content: space-between;align-items: center"
      :style="
        isAdmin ? 'border-top: 1px solid #BFBFBF' : 'border-top: 1px solid rgba(74, 138, 93, 0.46)'
      "
    >
      <div class="toolbar-button-style">
        <a-button
          type="primary"
          style="margin-right: 18px; margin-top: 10px;background: #4a8a5d;width: 89px;color: white"
          :disabled="disableSend"
          @click="sendMessage"
          >Send</a-button
        >
        <a-button
          v-if="!isForwordList && role === 'admin'"
          style="margin-right: 18px; margin-top: 10px; background: #4a8a5d; border: 1px solid #4A8A5D; border-radius: 2px; color: #ffffff;width: 89px"
          @click="saveDraft"
        >
          Save</a-button
        >
        <div class="toolbart-style">
          <Toolbar :editor="editor" :default-config="toolbarConfig" :mode="mode" />
        </div>
      </div>
      <a-icon v-if="isAdmin" type="delete" style="margin-right: 10px" @click="deleteAction" />
    </div>
    <div id="introBrand" style="display: none;">
      <BrandIntroTemplate :from="from" :to-data="toData" />
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue"
import "@wangeditor/editor/dist/css/style.css"
import trainingClient from "@/api/training-client"
import AWS from "aws-sdk"
import { i18nChangeLanguage } from "@wangeditor/editor"
import { message } from "ant-design-vue"
import BrandIntroTemplate from "@/components/message/components/BrandIntroTemplate"
// import BrandIntroTemplate from "@/components/message/components/BrandIntroTemplate"
import { Boot } from "@wangeditor/editor"
import attachmentModule from "@wangeditor/plugin-upload-attachment"
// import { IEditorConfig } from '@wangeditor/editor'

// 注册。要在创建编辑器之前注册，且只能注册一次，不可重复注册。
Boot.registerModule(attachmentModule)

i18nChangeLanguage("en")

// 自定义校验链接
const customCheckLinkFn = (text, url) => {
  if (!url) {
    message.warning("Link cannot be empty")
    return
  }
  if (url.indexOf("http") !== 0) {
    message.warning("Links must start with http/https")
    return
  }
  return true

  // 返回值有三种选择：
  // 1. 返回 true ，说明检查通过，编辑器将正常插入链接
  // 2. 返回一个字符串，说明检查未通过，编辑器会阻止插入。会 alert 出错误信息（即返回的字符串）
  // 3. 返回 undefined（即没有任何返回），说明检查未通过，编辑器会阻止插入。但不会提示任何信息
}
// 自定义转换链接 url
const customParseLinkUrl = url => {
  if (url.indexOf("http") !== 0) {
    return `http://${url}`
  }
  return url
}
// 自定义校验图片
// const customCheckImageFn = (src, alt, url) => {
//   if (!src) {
//     message.warning("Image URL cannot be empty")
//     return
//   }
//
//   if (src.indexOf("http") !== 0) {
//     message.warning("The picture address must start with http/https")
//     return "The picture address must start with http/https"
//   }
//   if (url.indexOf("http") !== 0) {
//     message.warning("The picture link must start with http/https")
//     return "The picture link must start with http/https"
//   }
//   return true
//
//   // 返回值有三种选择：
//   // 1. 返回 true ，说明检查通过，编辑器将正常插入图片
//   // 2. 返回一个字符串，说明检查未通过，编辑器会阻止插入。会 alert 出错误信息（即返回的字符串）
//   // 3. 返回 undefined（即没有任何返回），说明检查未通过，编辑器会阻止插入。但不会提示任何信息
// }
// 转换图片链接
// const customParseImageSrc = src => {
//   if (src.indexOf("http") !== 0) {
//     return `http://${src}`
//   }
//   return src
// }
let fileList = []

export default {
  name: "EditorCom",
  components: {
    Editor,
    Toolbar,
    BrandIntroTemplate
    // BrandIntroTemplate
  },
  props: {
    isForwordList: { type: Boolean, default: () => false },
    isAdmin: { type: Boolean, default: () => false },
    from: { type: Object, default: () => undefined },
    generateContent: { type: Number, default: () => -1 },
    toData: { type: Object, default: () => undefined },
    // draftInfo: { type: Object, default: () => undefined },
    isHasConnection: { type: Boolean, default: () => true },
    isSmallStatus: { type: Boolean, default: () => true },
    disable: { type: Boolean, default: () => undefined }
  },
  data() {
    const isadmin = this.isAdmin
    const from = this.from
    return {
      // fileList: [],
      fileList,
      isLoading: false,
      draftTime: undefined,
      editor: null,
      sendContent: null,
      showEditor: true,
      disableSend: this.disable === undefined ? true : this.disable,
      htmlText: "",
      toolbarConfig: {
        // 显示指定的菜单项
        // toolbarKeys: ["bold", "italic", "underline", "justify", "list", "image", "link"],
        // 隐藏指定的菜单项
        excludeKeys: [
          // 隐藏全屏按钮等按钮
          "fullScreen",
          "header1",
          "header2",
          "header3",
          "bgColor",
          "color",
          "codeSelectLang",
          "blockquote",
          "codeBlock",
          "uploadVideo",
          "clearStyle",
          "insertTable",
          "insertVideo",
          "redo",
          "undo",
          "todo",
          "delIndent",
          "sub",
          "through",
          "image",
          "groupImage"
        ],
        insertKeys: {
          index: 20, // 自定义插入的位置
          keys: ["uploadAttachment"] // “上传附件”菜单
        }
      },
      editorConfig: {
        placeholder: "Please Enter Content...",
        // 在编辑器中，点击选中“附件”节点时，要弹出的菜单
        hoverbarKeys: {
          attachment: {
            menuKeys: ["downloadAttachment"] // “下载附件”菜单
          }
        },

        MENU_CONF: {
          // 插入链接的校验
          insertLink: {
            checkLink: customCheckLinkFn, // 也支持 async 函数
            parseLinkUrl: customParseLinkUrl // 也支持 async 函数
          },

          // 修改链接的校验
          editLink: {
            checkLink: customCheckLinkFn, // 也支持 async 函数
            parseLinkUrl: customParseLinkUrl // 也支持 async 函数
          },

          // 插入网络图片的校验
          // insertImage: {
          //   //  插入图片之后的回调
          //   onInsertedImage(imageNode) {
          //     if (imageNode == null) return
          //
          //     const { src, alt, url, href } = imageNode
          //     console.log("inserted image", src, alt, url, href)
          //   },
          //
          //   // 校验图片链接
          //   checkImage: customCheckImageFn, // 也支持 async 函数
          //
          //   // 转换图片链接
          //   parseImageSrc: customParseImageSrc // 也支持 async 函数
          // },

          // 编辑网络图片的校验
          // editImage: {
          //   // 更新图片之后的回调
          //   onUpdatedImage(imageNode = null) {
          //     if (imageNode == null) return
          //
          //     const { src, alt, url } = imageNode
          //     console.log("updated image", src, alt, url)
          //   },
          //
          //   // 校验图片链接
          //   checkImage: customCheckImageFn, // 也支持 async 函数
          //
          //   // 转换图片链接
          //   parseImageSrc: customParseImageSrc // 也支持 async 函数
          // },

          // 图片的上传
          // uploadImage: {
          //   async customUpload(file, insertFn) {
          //     let descriptor
          //     let formData = new FormData()
          //     formData.append("file", file)
          //     // const configs = {
          //     //   // 上传请求头
          //     //   headers: {"Content-Type": "multipart/form-data"},
          //     //
          //     //   // 上传进度
          //     //   onUploadProgress: progressEvent => {
          //     //     let percent = (progressEvent.loaded / progressEvent.total * 100 | 0)
          //     //   }
          //     // }
          //     // const res = await that.$axios.post("/api/uploadFile", formData, configs)
          //     // this.getAWSKey(file)
          //     const uploadInfo = {
          //       originalFileName: file.name,
          //       characterSet: "UTF-8",
          //       contentType: file.type,
          //       size: file.size
          //     }
          //     const { data } = isadmin
          //       ? await trainingClient.getAWSKeyInfoInAdmin(
          //           uploadInfo,
          //           this.brandId ? this.brandId : from.id
          //         )
          //       : await trainingClient.getAWSKeyInfo(uploadInfo)
          //     descriptor = data.descriptor
          //     let s3 = new AWS.S3({
          //       // region: data.credentialsState.region,
          //       region: "us-west-2",
          //       apiVersion: "2006-03-01",
          //       // authority: data.credentialsState.authority,
          //       accessKeyId: data.credentialsState.accessKeyId,
          //       secretAccessKey: data.credentialsState.secretAccessKey,
          //       sessionToken: data.credentialsState.sessionToken
          //     })
          //     let params = {
          //       Body: file,
          //       Bucket: data.credentialsState.bucketName,
          //       Key: data.descriptor.storageKey
          //     }
          //     s3.putObject(params, async (err, data) => {
          //       console.log(data)
          //       if (err) {
          //         this.$message.error("Upload failed")
          //       } else {
          //         const successInfo = await trainingClient.sendUploadInfo(descriptor)
          //         const info = {
          //           file: {
          //             url: successInfo.data.url,
          //             id: successInfo.data.id,
          //             contentType: successInfo.data.contentType
          //           }
          //         }
          //         // console.log(this.htmlText)
          //         // const text = info.file.url
          //         const fileShow = `<p style="border: 1px solid #e2e2e2">${info.file.name}-${info.file.url}</p>`
          //         // insertFn(info.file.url, info.file.name, info.file.url)
          //         insertFn(fileShow)
          //         const obj = {
          //           ...info.file,
          //           name: file.name,
          //           size: file.size
          //         }
          //         fileList.push(obj)
          //       }
          //     })
          //   }
          // },
          // uploadUnderline: {
          //   customUpload(fff) {
          //     console.log(9999, fff)
          //   }
          // },
          uploadAttachment: {
            // server: '/api/upload', // 服务端地址
            timeout: 5 * 1000, // 5s

            // fieldName: 'custom-fileName',
            // meta: { token: 'xxx', a: 100 }, // 请求时附加的数据
            // metaWithUrl: true, // meta 拼接到 url 上
            headers: { Accept: "text/x-json" },

            maxFileSize: 10 * 1024 * 1024, // 10M

            // onBeforeUpload(file) {
            //   console.log('onBeforeUpload', file)
            //   this.getAWSKey(file)
            //   return file // 上传 file 文件
            //   // return false // 会阻止上传
            // },
            // onProgress(progress) {
            //   console.log('onProgress', progress)
            // },
            // onSuccess(file, res) {
            //   console.log('onSuccess', file, res)
            // },
            // onFailed(file, res) {
            //   alert(res.message)
            //   console.log('onFailed', file, res)
            // },
            // onError(file, err, res) {
            //   alert(err.message)
            //   console.error('onError', file, err, res)
            // },

            // // 上传成功后，用户自定义插入文件
            // customInsert(res: any, file: File, insertFn: Function) {
            //   console.log('customInsert', res)
            //   const { url } = res.data || {}
            //   if (!url) throw new Error(`url is empty`)

            //   // 插入附件到编辑器
            //   insertFn(`customInsert-${file.name}`, url)
            // },

            // 用户自定义上传
            async customUpload(file) {
              let descriptor
              let formData = new FormData()
              formData.append("file", file)
              const uploadInfo = {
                originalFileName: file.name,
                characterSet: "UTF-8",
                contentType: file.type,
                size: file.size
              }
              const { data } = isadmin
                ? await trainingClient.getAWSKeyInfoInAdmin(
                    uploadInfo,
                    this.brandId ? this.brandId : from.id
                  )
                : await trainingClient.getAWSKeyInfo(uploadInfo)
              descriptor = data.descriptor
              let s3 = new AWS.S3({
                // region: data.credentialsState.region,
                region: "us-west-2",
                apiVersion: "2006-03-01",
                // authority: data.credentialsState.authority,
                accessKeyId: data.credentialsState.accessKeyId,
                secretAccessKey: data.credentialsState.secretAccessKey,
                sessionToken: data.credentialsState.sessionToken
              })
              let params = {
                Body: file,
                Bucket: data.credentialsState.bucketName,
                Key: data.descriptor.storageKey
              }
              s3.putObject(params, async (err, data) => {
                console.log(data)
                if (err) {
                  this.$message.error("Upload failed")
                } else {
                  const successInfo = await trainingClient.sendUploadInfo(descriptor)
                  const info = {
                    file: {
                      url: successInfo.data.url,
                      id: successInfo.data.id,
                      contentType: successInfo.data.contentType
                    }
                  }
                  // console.log(this.htmlText)
                  // const text = info.file.url
                  // const fileShow = `<p style="border: 1px solid #e2e2e2">${info.file.name}-${info.file.url}</p>`
                  // insertFn(`customUpload-${info.file.url}`, info.file.url)
                  //       resolve('ok')
                  // insertFn(fileShow)
                  const obj = {
                    ...info.file,
                    name: file.name,
                    size: file.size
                  }
                  fileList.push(obj)
                  // console.log("99999", fileList)
                }
              })
            }

            // // 自定义选择
            // customBrowseAndUpload(insertFn: Function) {
            //   alert('自定义选择文件，如弹出图床')
            //   // 自己上传文件
            //   // 上传之后用 insertFn(fileName, link) 插入到编辑器
            // },

            // 插入到编辑器后的回调
            // onInsertedAttachment(elem) {
            //   console.log('inserted attachment', elem)
            // },
          }
        }
      },
      mode: "simple"
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    draftInfo() {
      return this.$store.getters["adminMessage/draftInfo"]
    }
  },
  watch: {
    draftInfo() {
      if (this.draftInfo && this.draftInfo.length !== 0) {
        this.htmlText = this.draftInfo[0].message
        this.draftTime = `${
          this.draftInfo[0].createdAt.split("T")[0]
        }  ${this.draftInfo[0].createdAt.split("T")[1].slice(0, 8)}`
        // this.editor.setHtml(this.draftInfo.message)
      }
    },
    generateContent() {
      console.log("===generateContent===", this.generateContent)
      // if (this.draftInfo) {
      //   // this.htmlText = this.draftInfo.message
      //   this.editor.setHtml(this.draftInfo.message)
      // }
      if (this.generateContent === -1 || this.generateContent === 10) {
        this.htmlText = ""
        this.editor.enable()
        if (this.generateContent === 10) {
          //intro brand products

          this.showEditor = false
          this.disableSend = !this.isHasConnection
          this.sendContent = ""
          //   this.disableSend = false
        }
      } else if (this.generateContent === 1) {
        // nothing , no template, user will input the message himself.
        this.htmlText = ""
        this.disableSend = true
        this.editor.disable()
      } else if (this.generateContent === 9) {
        // brand send to retailer, choose the custom template
        const text = this.generateBrandCustomMessage()
        this.showEditor = true
        this.sendContent = text
        // this.editor.dangerouslyInsertHtml(text)
        this.editor.setHtml(text)
        this.editor.enable()
        this.disableSend = false
      } else if (this.generateContent === 8) {
        this.htmlText = ""
        this.showEditor = false
        // brand intro himself to retailer to build connection
        let brandIntro = document.getElementById("introBrand")
        const text = brandIntro.innerHTML
        this.sendContent = text
        // this.editor.dangerouslyInsertHtml(text)
        // this.editor.setHtml(text)
        this.editor.enable()
        this.disableSend = false
      } else {
        // others
        const text = this.generateCommonMessage()
        // this.editor.dangerouslyInsertHtml(text)
        this.editor.setHtml(text)
        this.editor.enable()
        this.disableSend = false
      }
      // this.editor.txt.html(this.htmlContent)
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  // mounted() {
  //   console.log('组件获取====》', this.draftInfo)
  //   this.editor.setHtml('<p>ssss</p>p>')
  //   if (this.draftInfo) {
  //     this.editor.enable()
  //     // this.htmlText = this.draftInfo.message
  //     this.editor.dangerouslyInsertHtml(this.draftInfo.message)
  //   }
  // },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },

    result() {
      console.log(this.htmlText)
    },

    onChange(val) {
      this.sendContent = val.getHtml()
      if (this.generateContent === -1 && this.sendContent.length > 0) {
        this.disableSend = false
      }
      if (this.generateContent === 10) {
        this.disableSend = false
      }
    },
    checkToAddress() {
      let obj = document.getElementsByClassName("message-input-content__to")
      if (obj && obj.length > 0) {
        let toValue = obj[0].outerText
        let toIdStr = toValue.split("To:")
        let idsStr = toIdStr[1]
        this.checkSendIds(idsStr)
      }
    },
    sendMessage() {
      // this.checkToAddress()
      // console.log("发送消息====》", this.sendContent, this.fileList, this.addressedIds)
      //   let param = { message: this.editor.txt.html() }
      if (this.draftInfo && this.draftInfo.length !== 0) {
        const sendParam = {
          ...this.draftInfo[0],
          drafts: false,
          message: this.sendContent
        }
        const id = this.draftInfo[0].id
        this.$store
          .dispatch("adminMessage/updateMessage", { id, params: sendParam })
          .then(() => {
            this.draftTime = undefined
            this.htmlText = ""
            this.fileList = []
            fileList = []
            this.$emit("hideDialog")
          })
          .catch(err => {
            console.log(err)
          })
        return
      }
      this.$emit("send", this.sendContent, fileList, this.addressedIds)
      // this.htmlText = ""
      this.fileList = []
      fileList = []
    },
    saveDraft() {
      // this.checkToAddress()
      this.$emit("sendDraft", this.sendContent, fileList, this.addressedIds)
      this.htmlText = ""
      this.fileList = []
      fileList = []
    },
    deleteAction() {
      // this.htmlText = ""
      this.editor.clear()
    },
    async getAWSKey(fileInfo) {
      const uploadInfo = {
        originalFileName: fileInfo.name,
        characterSet: "UTF-8",
        contentType: fileInfo.type,
        size: fileInfo.size
      }
      const { data } = this.isAdmin
        ? await trainingClient.getAWSKeyInfoInAdmin(
            uploadInfo,
            this.brandId ? this.brandId : this.product.brand.id
          )
        : await trainingClient.getAWSKeyInfo(uploadInfo)
      this.descriptor = data.descriptor
      let s3 = new AWS.S3({
        // region: data.credentialsState.region,
        region: "us-west-2",
        apiVersion: "2006-03-01",
        // authority: data.credentialsState.authority,
        accessKeyId: data.credentialsState.accessKeyId,
        secretAccessKey: data.credentialsState.secretAccessKey,
        sessionToken: data.credentialsState.sessionToken
      })
      let params = {
        Body: fileInfo.originFileObj,
        Bucket: data.credentialsState.bucketName,
        Key: data.descriptor.storageKey
      }
      s3.putObject(params, async (err, data) => {
        console.log(data)
        if (err) {
          this.$message.error("Upload failed")
        } else {
          const successInfo = await trainingClient.sendUploadInfo(this.descriptor)
          const info = {
            file: {
              url: successInfo.data.url,
              id: successInfo.data.id,
              contentType: successInfo.data.contentType
            }
          }
          console.log(info)
        }
      })
    },
    generateCommonMessage() {
      // return `<div style="padding: 40px;">
      //         <div class="message-input-content">
      //           <p class="message-input-content__from">${this.from.name}</p>
      //           <p class="message-input-content__to">To: </p>
      //           <div class="message-input-content__divider"></div>
      //           <div class="message-input-content__content">
      //             <div>
      //              please input the content here
      //             </div>
      //             <div>
      //                 Sincerely,<br/>
      //                 ${this.from.name}
      //             </div>
      //           </div>
      //         </div>
      //   </div>`
      return `<p style="padding: 0;margin: 0"></p>
<p style="padding: 0;margin: 0">Sincerely,</p><p style="padding: 0;margin: 0">${this.from.name}</p>`
    },
    generateBrandCustomMessage() {
      // return `<div class="ant-row" style="${
      //   this.isSmallStatus ? "padding: 14px 20px;" : "padding: 40px 30px;"
      // }">
      //         <div class="message-input-content">
      //           <div class="message-input-content__content">
      //             <div>
      //              please input the content here
      //             </div>
      //             <div>
      //                 Sincerely,<br/>
      //                 ${this.from.name}
      //             </div>
      //           </div>
      //         </div>
      //   </div>`
      return `<p style="padding: 0;margin: 0"></p>
<p style="padding: 0;margin: 0">Sincerely,</p><p style="padding: 0;margin: 0">${this.from.name}</p>`
    },
    deleteFil(item) {
      if (fileList && fileList.length === 0) {
        return
      }
      fileList = fileList.filter(value => value.id !== item.id)
      this.fileList = fileList
    }
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss">
u {
  text-decoration: underline;
}
.toolbar-button-style {
  display: flex;
  justify-content: flex-start;
  //border-top: 1px solid #4a8a5d;
  padding-top: 8px;
  padding-left: 5px;
  padding-bottom: 8px;
  //border-bottom: 1px solid #4a8a5d;
}
.toolbart-style {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
.w-e-bar-item button {
  padding: 0 3px;
}
.w-e-bar-divider {
  display: none;
}
.w-e-bar-item-group {
  display: none;
}
.w-e-bar-item-group .w-e-bar-item-menus-container .w-e-bar-item:nth-child(1) {
  display: none;
}
.w-e-bar-item-group .w-e-bar-item-menus-container .w-e-bar-item button {
  font-size: 14px;
}
.w-e-bar-item-group .w-e-bar-item-menus-container .w-e-bar-item span {
  font-size: 14px;
}
.w-e-modal {
  z-index: 666;
}
.message-input-content {
  background: #f5f5f5;
  border-radius: 16px;
  padding: 20px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  &__from {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 4px;
  }
  &__to {
    color: #000000;
    margin-bottom: 10px;
  }
  &__divider {
    border-bottom: 1px solid #d9d9d9;
    margin-right: 60px;
    margin-bottom: 20px;
  }
  &__content {
    color: #262626;
  }
  &__text-container {
    border-top: 1px solid rgba(74, 138, 93, 0.46);
    border-bottom: 1px solid rgba(74, 138, 93, 0.46);
    margin-top: -1px;
    min-height: 111px;
  }
  &__text-contailer-admin {
    border: 1px solid rgba(74, 138, 93, 0.46);
    margin-top: -1px;
    min-height: 217px;
  }
}
.editor-style {
  position: relative;
  .draft-save-info-style {
    padding: 2px 10px;
    height: 26px;
    line-height: 26px;
    background: #e8e8e8;
    font-family: ProximaNova;
    border-radius: 2px;
    color: #000000;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 300;
  }
  .file-list-editor {
    position: absolute;
    left: 12px;
    bottom: 8px;
    color: #262626;
    padding: 2px 4px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 300;
  }
  .delete-file-info {
    padding: 0 5px;
    cursor: pointer;
  }
  p {
    padding: 0;
    margin: 0;
  }
}
</style>
