<template>
  <div class="brand-settings-all">
    <a-row>
      <img src="~@/assets/img/icon/settings.svg" />
      <span class="brand-settings-all__top-title">{{ $t("Admins.AdminSystem.system") }}</span>
    </a-row>
    <a-row class="brand-settings-all__row">
      <a-col :span="4">
        <div class="admin-system__left-menu">
          <div class="admin-system__left-menu__menu">
            <a-menu
              v-model="defaultSelecttList"
              mode="inline"
              theme="light"
              style="width: 100%;"
              @click="handleClick"
            >
              <template v-for="menu in typeButton">
                <a-menu-item :key="menu.id">
                  <router-link :to="{ name: menu.route }">{{ menu.title }}</router-link>
                </a-menu-item>
              </template>
            </a-menu>
          </div>
        </div>
      </a-col>
      <a-col :span="20">
        <div style="margin-left: 10px;">
          <router-view></router-view>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultSelecttList: [4]
    }
  },
  computed: {
    typeButton() {
      return [
        {
          title: this.$t("Admins.AdminSystem.users"),
          ref: "users",
          id: 0,
          route: "adminSystemUsers"
        },
        {
          title: this.$t("Admins.AdminSystem.group"),
          ref: "group",
          id: 1,
          route: "adminSystemGroup"
        },
        {
          title: this.$t("Admins.AdminSystem.access"),
          ref: "access",
          id: 2,
          route: "adminSystemAccess"
        },
        {
          title: this.$t("Admins.AdminSystem.logs"),
          ref: "logs",
          id: 3,
          route: "adminSystemLogs"
        },
        {
          title: this.$t("Admins.AdminSystem.promoCode"),
          ref: "promoCode",
          id: 4,
          route: "adminSystemPromoCode"
        }
      ]
    }
  },
  created() {
    if (this.$route.name === "adminSystemUsers") {
      this.defaultSelecttList = [0]
    } else if (this.$route.name === "adminSystemGroup") {
      this.defaultSelecttList = [1]
    } else if (this.$route.name === "adminSystemAccess") {
      this.defaultSelecttList = [2]
    } else if (this.$route.name === "adminSystemLogs") {
      this.defaultSelecttList = [3]
    } else {
      this.defaultSelecttList = [4]
    }
  },
  methods: {
    handleClick(key) {
      console.log(key)
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-system {
  &__left-menu {
    float: left;
    width: 100%;
    margin-right: 25px;
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    &__menu {
      border-top: 1px solid #d9d9d9;
    }
  }
}
</style>
