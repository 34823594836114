<template>
  <div class="brand-training-create__main">
    <div class="training-create-title">
      <div class="training-page-title">
        <div class="training-logo">{{ this.$t("Training.brandTraining.title") }}</div>
        <div class="step-title-content">
          <div class="step-title">
            <p class="step-title-one">
              {{ this.$t("Training.brandTraining.createTraining.stepOne") }}
            </p>
            <p class="step-title-two">
              {{ this.$t("Training.brandTraining.createTraining.stepTwo") }}
            </p>
            <p class="step-title-three">
              {{ this.$t("Training.brandTraining.createTraining.stepThree") }}
            </p>
          </div>
          <div class="step-line"></div>
        </div>
        <div class="title-button">
          <a-button class="btn-style" @click="jumpToTraining">
            {{ this.$t("Training.brandTraining.back") }}
          </a-button>
          <a-button
            :disabled="isClickContinue"
            :class="isClickContinue ? 'can-not-click' : 'btn-style'"
            @click="jumpToTrainingDetails"
          >
            {{ this.$t("Training.brandTraining.continue") }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="training-create-content">
      <div v-for="(item, index) in productList" :key="index" class="products-card">
        <createTraining :item="item" @getItemProduct="getItemProduct"></createTraining>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import createTraining from "@/components/training/productCard"
import { setSesStorage } from "@/utils/storageUtil"

export default {
  name: "BrandCreateTraining",
  components: {
    createTraining
  },
  props: {
    retailerId: { type: String, default: "" }
  },
  data() {
    return {
      editProductList: [],
      userId: undefined
    }
  },
  computed: {
    ...mapState({
      products: state => state.training.list
    }),
    isClickContinue() {
      return this.editProductList && this.editProductList.length === 0
    },
    productList() {
      return this.products.map(item => {
        item.msrpUsd = parseInt(item.msrpUsd, 10).toFixed(2)
        return item
      })
    }
  },
  async created() {
    if (this.$route.params.brandId) {
      this.userId = this.$route.params.brandId
    } else {
      this.userId = this.$store.getters["user/businessId"]
    }
    await this.getProduct(this.userId)
  },
  methods: {
    ...mapActions({
      getProduct: "training/getProducts"
    }),
    jumpToTraining() {
      if (this.$route.params.brandId) {
        this.$router.push({
          name: "adminBrandProfile",
          params: {
            brandId: this.$route.params.brandId
          }
        })
      } else {
        this.$router.push({
          name: "BrandTraining",
          query: {
            // main: true,
            tab: "product"
          }
        })
      }
    },
    jumpToTrainingDetails() {
      if (this.$route.params.brandId) {
        this.$router.push({
          name: "adminBrandTrainingDetails",
          params: {
            brandId: this.$route.params.brandId
          }
        })
      } else {
        this.$router.push({
          name: "BrandTrainingDetails"
        })
      }
    },
    getItemProduct(info, isClick) {
      // If the return isClick is true, the current item has been selected, and if it is false, it is unselected
      if (!isClick) {
        this.editProductList = this.editProductList.filter(item => item.id !== info.id)
      } else {
        this.editProductList.push(info)
      }
      setSesStorage("CREATE_PRODUCT_LIST_VALUE", this.editProductList)
    }
  }
}
</script>

<style scoped lang="scss">
.brand-training-create__main {
  min-height: 520px;
  width: 952px;
  margin: 0 auto;
  .training-page-title {
    width: 100%;
    height: 94px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }
  .title-button {
    width: 205px;
    text-align: right;
    //display: flex;
    //justify-content: space-between;
    .btn-style {
      width: 88px;
      height: 30px;
      font-family: "Proxima Nova";
      //line-height: 20px;
      //padding: 5px 16px;
      font-size: 14px;
      border: 2px solid #4a8a5d;
      color: #4a8a5d;
      background: #e2eeec;
    }
    .btn-style:nth-child(2) {
      margin-left: 26px;
    }
    .btn-style:hover {
      color: white;
      background: #4a8a5d;
    }
    .can-not-click {
      width: 88px;
      height: 30px;
      font-family: "Proxima Nova";
      font-size: 14px;
      border: 2px solid #bfbfbf;
      color: #bfbfbf;
      background: #fafafa;
      margin-left: 26px;
    }
    .can-not-click:hover {
      //color: white;
      //background: #4a8a5d;
    }
  }
  .step-title {
    width: 488px;
    font-size: 13px;
    line-height: 15.83px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .step-title-one {
      width: 109px;
      height: 60px;
    }
    .step-title-two {
      width: 126px;
      height: 60px;
    }
    .step-title-three {
      width: 186px;
      height: 60px;
    }
    p {
      margin-bottom: 0;
    }
  }
  .step-line {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: #e2eeec;
    margin-top: 8px;
    //margin-top: 4px;
  }
  .training-create-content {
    margin-top: 24px;
    display: flex;
    //justify-content: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .products-card {
      width: 220px;
      margin-right: 24px;
      margin-bottom: 24px;
    }
    .products-card:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>
