var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.member),function(memberItem,index){return _c('div',{key:index},[(
        memberItem && memberItem.membershipStatus && memberItem.membershipStatus.name !== 'Delete'
      )?_c('a-row',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutsideMember),expression:"onClickOutsideMember"}],key:index,staticClass:"membersList"},[_c('a-col',{staticClass:"user-name__block",attrs:{"span":5}},[_c('div',{staticClass:"user-name-status"},[_vm._v(" "+_vm._s(_vm.profileIcon(memberItem.user.givenName, memberItem.user.familyName))+" ")]),_c('p',{staticClass:"table-item-information-name"},[_vm._v(" "+_vm._s(`${memberItem.user.givenName} ${memberItem.user.familyName}`)+" ")])]),_c('a-col',{attrs:{"span":5}},[_c('p',{staticClass:"table-item-information-name"},[_vm._v(" "+_vm._s(memberItem.user.email)+" ")])]),_c('a-col',{attrs:{"span":4}},[_c('p',{staticClass:"table-item-information-name text-padding-left"},[_vm._v(" "+_vm._s(memberItem.user.jobTitle)+" ")])]),_c('a-col',{attrs:{"span":5}},[_c('p',{staticClass:"table-item-information-name col-phone"},[_vm._v(" "+_vm._s(memberItem.user.phoneNumber)+" ")])]),_c('a-col',{attrs:{"span":3}},[_c('p',{staticClass:"table-item-information-name"},[_vm._v(" "+_vm._s(memberItem.membershipStatus.name)+" ")])]),(
          (_vm.brandType !== 'basic' && _vm.isOwner && memberItem.role.id !== 'owner') ||
            (_vm.isOwner && _vm.isRetailer && memberItem.role.id !== 'owner')
        )?_c('a-col',{staticClass:"membersList__table-cell",attrs:{"span":2}},[_c('a-dropdown',[_c('a-icon',{attrs:{"type":"more"},on:{"click":e => e.preventDefault()}}),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[(
                memberItem.membershipStatus.name === 'Removed' ||
                  memberItem.membershipStatus.name === 'Declined'
              )?_c('a-menu-item',{on:{"click":function($event){return _vm.inviteMemberAgain(memberItem)}}},[_c('span',{staticClass:"admin-clients__dropdown-button"},[_vm._v(" "+_vm._s(_vm.$t("Components.Brands.Settings.MemberItem.inviteAgain"))+" ")])]):_vm._e(),(memberItem.membershipStatus.name === 'Added')?_c('a-menu-item',{on:{"click":function($event){return _vm.changeOwner(memberItem)}}},[_c('span',{staticClass:"admin-clients__dropdown-button"},[_vm._v(" "+_vm._s(_vm.$t("Components.Brands.Settings.MemberItem.changeOwner"))+" ")])]):_vm._e(),(memberItem.membershipStatus.name !== 'Removed')?_c('a-menu-item',{on:{"click":function($event){return _vm.doRemoveAction(memberItem)}}},[_c('span',{staticClass:"admin-clients__dropdown-button"},[_vm._v(" "+_vm._s(_vm.$t("Components.Brands.Settings.MemberItem.remove"))+" ")])]):_vm._e(),(memberItem.membershipStatus.name !== 'Added')?_c('a-menu-item',{on:{"click":function($event){return _vm.doDeleteAction(memberItem)}}},[_c('span',{staticClass:"admin-clients__dropdown-button"},[_vm._v(" "+_vm._s(_vm.$t("Components.Brands.Settings.MemberItem.delete"))+" ")])]):_vm._e()],1)],1)],1):_vm._e(),(memberItem.role.id === 'owner')?_c('a-col',{staticClass:"membersList__table-cell",attrs:{"span":2}},[_c('a',{staticClass:"owner"},[_vm._v(" "+_vm._s(_vm.$t("Components.Brands.Settings.MemberItem.owner"))+" ")])]):_vm._e(),(!_vm.isOwner && _vm.brandType !== 'basic' && memberItem.role.id != 'owner')?_c('a-col',{staticClass:"membersList__table-cell",attrs:{"span":2}}):_vm._e(),(
          (_vm.brandType === 'basic' || (_vm.isRetailer && !_vm.isOwner)) && memberItem.role.id != 'owner'
        )?_c('a-col',{staticClass:"membersList__table-cell",attrs:{"span":2}}):_vm._e()],1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }