<template>
  <div id="tags" class="admin-message-recipeint-box">
    <template v-for="(item, index) in list">
      <a-tag
        v-if="!item.isDelete"
        :key="`recipient_${index}`"
        style="margin-bottom: 2px;"
        :class="{ 'admin-message-recipeint-box__deleted-item': item.isDelete }"
      >
        {{ item.name }}
        <a-icon :type="!item.isDelete ? 'close' : 'check'" @click="deleteItem(item)" />
      </a-tag>
    </template>
    <a-icon
      v-if="isShowArrow"
      :type="isShowAll ? 'caret-up' : 'caret-down'"
      class="admin-message-recipeint-box__right-arrow"
      @click="clickToShowAll"
    />
  </div>
</template>
<script>
export default {
  props: {
    recipientAddressList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      list: this.recipientAddressList,
      isShowAll: false,
      isShowArrow: false
    }
  },
  watch: {
    recipientAddressList(newValue) {
      console.log(".....watch.....")
      this.list = newValue
      if (this.list && this.list.length > 0) {
        this.list.forEach(item => {
          item.isDelete = false
        })
      }
      if (!this.list || this.list.length === 0) {
        this.isShowArrow = false
        var o = document.getElementById("tags")
        this.resetTagsHeight(o)
      } else {
        this.initShowArrowState()
      }
    }
  },
  updated() {
    if (!this.isShowAll && !this.isShowArrow) {
      this.initShowArrowState()
    }
  },
  mounted() {
    this.initShowArrowState()
  },
  methods: {
    deleteItem(item) {
      if (item.isDelete) {
        // reset it
        item.isDelete = false
      } else {
        item.isDelete = true
      }
      this.$forceUpdate()
    },
    initShowArrowState() {
      var o = document.getElementById("tags")
      let childrends = o.childNodes
      let childCount = this.list.length
      //   o.style.height = "auto"
      var contentWidth = o.offsetWidth - 35 - 10
      if (childCount > 0) {
        let countWidth = 0
        for (let i = 0; i < childCount; i++) {
          if (countWidth > contentWidth) {
            this.isShowArrow = true
            break
          }
          console.log(childrends[i])
          countWidth += childrends[i] ? childrends[i].offsetWidth + 4 : 0
        }
        if (countWidth <= contentWidth) {
          this.isShowArrow = false
        }
      } else {
        this.isShowArrow = false
      }
      //   var h = o.offsetHeight
      //   console.log(childrends)
      //   console.log(`width: ${o.offsetWidth}, height: ${h}, left: ${o.offsetLeft}, top: ${o.offsetTop}, count: ${o.childElementCount}`)
      //   console.log("....init show arrow state....")
      //   console.log(h)
      //   console.log(h > 44)
      //   if (h > 44) {
      //     this.isShowArrow = true
      //   } else {
      //     this.isShowArrow = false
      //   }
      this.resetTagsHeight(o)
    },
    resetTagsHeight(obj) {
      obj.style.height = "40px"
      obj.style["overflow"] = "hidden"
      obj.style["z-index"] = "auto"
    },
    clickToShowAll() {
      this.isShowAll = !this.isShowAll
      let obj = document.getElementById("tags")
      if (obj) {
        if (this.isShowAll) {
          obj.style.height = "auto"
          obj.style["overflow-y"] = "auto"
          obj.style["overflow-x"] = "hidden"
          obj.style["z-index"] = "50"
        } else {
          this.resetTagsHeight(obj)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-message-recipeint-box {
  position: relative;
  min-height: 40px;
  display: block;
  background: #f5f5f5;
  border-radius: 2px;
  padding: 10px 35px 10px 10px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  z-index: 50;
  &__right-arrow {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 20px;
    color: #8c8c8c;
  }
}
</style>
<style lang="scss">
.admin-message-recipeint-box .ant-tag {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  background: #e2eeec;
  border: 1px solid #12433d;
  box-sizing: border-box;
  border-radius: 2px;
  color: #12433d;
  font-size: 14px;
  margin-right: 4px;
}
.admin-message-recipeint-box .admin-message-recipeint-box__deleted-item.ant-tag {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  background: #d9d9d9;
  border: 1px solid #12433d;
  box-sizing: border-box;
  border-radius: 2px;
  color: #12433d;
  font-size: 14px;
  margin-right: 4px;
}
.admin-message-recipeint-box .ant-tag .anticon {
  color: #12433d;
  font-size: 10px;
}
</style>
