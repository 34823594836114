<template>
  <div>
    <a-modal
      v-model="editDialog"
      width="952px"
      title="Edit All"
      ok-text="Save"
      :closable="true"
      class="admin-console-edit-dialog"
      :destroy-on-close="true"
      @cancel="cancelEdit"
      @ok="doSaveEditValue"
    >
      <div style="min-height: 270px; width: 100%; margin: 0 auto;">
        <template v-if="referenceDatas && referenceDatas.length > 0">
          <!-- <a-input
            v-for="(item, index) in referenceDatas"
            :key="`${item.name}_${index}_item`"
            v-model="item.name"
            type="text"
            size="small"
            class="edit-reference-dialog__edit-text"
            @change="handleInputChange"
            @blur="handleInputConfirm"
            @keyup.enter="handleInputConfirm"
          /> -->
          <div
            v-for="(item, index) in referenceDatas"
            :key="`${item.name}_${index}_item`"
            class="edit-reference-dialog__edit-text"
            contentEditable="true"
            @blur="event => handleInputConfirm(event, item)"
            @keyup.enter="event => handleInputConfirm(event, item)"
          >
            {{ item.name }}
          </div>
        </template>
      </div>
    </a-modal>
  </div>
</template>

<script>
//import { getCamelCase } from "@/utils/validate"
export default {
  data() {
    return {
      editDialog: false,
      referenceDatas: []
    }
  },
  methods: {
    doSaveEditValue() {
      let canSave = true
      if (this.referenceDatas === undefined || this.referenceDatas.length === 0) {
        canSave = false
      } else {
        let count = 0
        this.referenceDatas.forEach(item => {
          if (item.name === undefined || item.name.replace(/^\s*|\s*$/g, "").length === 0) {
            count += 1
          }
        })
        if (count === 0) {
          canSave = true
        } else {
          canSave = false
        }
      }
      if (canSave) {
        this.$emit("save", this.referenceDatas)
        this.editDialog = false
      } else {
        this.$message.info("Can't Set empty value!")
      }
    },
    cancelEdit() {
      this.editDialog = false
      this.$emit("cancel")
    },
    openDialog(items) {
      this.editDialog = true
      this.referenceDatas = items
    },
    handleInputConfirm(event, item) {
      let value = event.target.outerText
      if (value === undefined || value.replace(/^\s*|\s*$/g, "").length === 0) {
        this.$message.info("Can't Set empty value!")
      } else {
        //item.name = getCamelCase(value)
        item.name = value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-reference-dialog {
  &__edit-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #bfbfbf;
    padding: 6px 8px;
    border: 1px solid #4a8a5d;
    background: #ffffff;
    border-radius: 2px;
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
  }
}
.edit-reference-dialog__edit-text:focus {
  border: 1px solid #ff6132;
}
</style>
