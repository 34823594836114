<template lang="pug">
.modal(:class="{'is-active': show}")
  .old-edit-address-information
    .modal-background
    .modal-card.old-edit-address-information-modal
      header.modal-card-head.old-edit-address-information-modal__header
        p.modal-card-title {{$t('Components.Orders.EditAddressModal.editAddress')}}
        img.old-edit-address-information-modal__header-close-icon(src="@/assets/img/icon/close-icon.png" @click="emitModalClose")
      section.modal-card-body.old-edit-address-information-modal__body
        .columns.is-marginless.old-edit-address-information-modal__container
          .column.old-edit-address-information-modal__form
            .old-edit-address-information-base-form
              .field
                label.label.old-edit-address-information__label {{$t('Components.Orders.EditAddressModal.addressee')}}
                .control
                  input.input.old-edit-address-information__input--fullwidth.field-width(
                    v-model.trim="$v.addressModal.addressee.$model"
                    :class="{ 'is-danger': $v.addressModal.addressee.$error }"
                    v-bind:placeholder="$t('Components.Orders.EditAddressModal.enterAddressee')"
                  )
                p.help.is-danger(v-if="$v.addressModal.addressee.$error") {{$t('Components.Orders.EditAddressModal.Validations.required')}}

              .columns
                .column
                  .field
                    label.label.old-edit-address-information__label {{$t('Components.Orders.EditAddressModal.streetAddress')}}
                    .control
                      input.input.old-edit-address-information__input--fullwidth(
                        v-model.trim="$v.addressModal.streetAddressLine_1.$model"
                        :class="{ 'is-danger': $v.addressModal.streetAddressLine_1.$error }"
                        v-bind:placeholder="$t('Components.Orders.EditAddressModal.enterStreetAddress')"
                      )
                    p.help.is-danger(v-if="$v.addressModal.streetAddressLine_1.$error") {{$t('Components.Orders.EditAddressModal.Validations.required')}}


              .columns
                .column
                  .field
                    label.label.old-edit-address-information__label {{$t('Components.Orders.EditAddressModal.aptSuiteBldg')}}
                    .control
                      .control
                        input.input.old-edit-address-information__input--fullwidth(
                          v-model="$v.addressModal.streetAddressLine_2.$model"
                          v-bind:placeholder="$t('Components.Orders.EditAddressModal.enterAptSuiteBldg')"
                        )


              .columns
                .column
                  .field
                    label.label.old-edit-address-information__label {{$t('Components.Orders.EditAddressModal.city')}}
                    .control
                      .control
                        input.input.old-edit-address-information__input--fullwidth(
                          v-model.trim="$v.addressModal.city.$model"
                          :class="{ 'is-danger': $v.addressModal.city.$error }"
                          v-bind:placeholder="$t('Components.Orders.EditAddressModal.enterCity')"
                        )
                    p.help.is-danger(v-if="$v.addressModal.city.$error") {{$t('Components.Orders.EditAddressModal.Validations.required')}}


              .columns
                .column
                  .field
                    label.label.old-edit-address-information__label {{$t('Components.Orders.EditAddressModal.stateOrRegion')}}
                    .control
                      .control
                        input.input.old-edit-address-information__input--fullwidth(
                          v-model.trim="$v.addressModal.region.$model"
                          :class="{ 'is-danger': $v.addressModal.region.$error }"
                          v-bind:placeholder="$t('Components.Orders.EditAddressModal.enterStateOrRegion')"
                        )
                    p.help.is-danger(v-if="$v.addressModal.region.$error") {{$t('Components.Orders.EditAddressModal.Validations.required')}}

                .column
                  .field
                    label.label.old-edit-address-information__label {{$t('Components.Orders.EditAddressModal.postalCode')}}
                    .control
                      .control
                          input.input.old-edit-address-information__input--fullwidth(
                          v-model.trim="$v.addressModal.postalCode.$model"
                          :class="{'is-danger': $v.addressModal.postalCode.$error }"
                          v-bind:placeholder="$t('Components.Orders.EditAddressModal.enterPostalCode')"
                        )
                    p.help.is-danger(v-if="$v.addressModal.postalCode.$error") {{$t('Components.Orders.EditAddressModal.Validations.required')}}

              .columns
                .column
                  .field
                    label.label.old-edit-address-information__label {{$t('Components.Orders.EditAddressModal.country')}}
                      .control
                        .select.is-fullwidth.brand-settings-wireinstruction__select(
                          :class="{'is-danger': $v.addressModal.country.id.$error }"
                          v-bind:placeholder="$t('Components.Orders.EditAddressModal.enterCountry')")
                          select(v-model="$v.addressModal.country.id.$model")
                            option(v-for="country in countries" :key="country.id" :value="country.id") {{ country.name }}
                        p.help.is-danger(v-if="$v.addressModal.country.id.$error") {{$t('Components.Orders.EditAddressModal.Validations.required')}}

      footer.modal-card-foot.old-edit-address-information-modal__form-buttons
        .columns
          .column
            .has-text-right
              a.button.old-edit-address-information-modal__cancel-button(@click="cancelChanges") {{$t('Components.Orders.EditAddressModal.cancel')}}
              a.button.is-black.old-edit-address-information-modal__save-button(
                :class="{ 'is-disabled': isDisabled }"
                @click="saveAddress" :disabled="isDisabled") {{$t('Components.Orders.EditAddressModal.save')}}
</template>

<script>
import { required } from "vuelidate/lib/validators"
export default {
  validations: {
    addressModal: {
      addressee: { required },
      streetAddressLine_1: { required },
      streetAddressLine_2: {},
      city: { required },
      region: { required },
      postalCode: { required },
      country: { id: { required } }
    }
  },
  props: {
    show: { type: Boolean, default: false },
    address: {
      type: Object,
      default() {
        return {
          addressee: null,
          city: null,
          country: {
            id: null
          },
          postalCode: null,
          region: null,
          streetAddressLine_1: null,
          streetAddressLine_2: null
        }
      }
    }
  },
  data() {
    return {
      addressModal: { ...this.address, country: { ...this.address.country } }
    }
  },
  computed: {
    countries() {
      return this.$store.getters["reference/countries"]
    },
    isDisabled() {
      if (this.$v.addressModal.$anyDirty) {
        return false
      }
      return true
    }
  },
  created() {
    this.addressModal = { ...this.address, country: { ...this.address.country } }
  },
  methods: {
    async saveAddress() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.$emit("updateAddress", this.addressModal)
      this.emitModalClose()
    },
    cancelChanges() {
      this.addressModal = this.address
      this.emitModalClose()
    },
    emitModalClose() {
      this.$emit("hideAddressModal")
    }
  }
}
</script>

<style lang="sass">
.is-disabled
  pointer-events: none
.old-edit-address-information-base-form
  width: 100%
.old-edit-address-information-modal
  background-color: #ffffff
  width: 100%
  &__body
    overflow-x: hidden
  &__container
    min-height: 0px
  &__container-images
    min-height: 600px !important
  &__container-documents
    min-height: 500px !important
  &__form
    padding: 20px 40px 20px 40px
  &__form-buttons
    padding-right: 20px
    height: 75px
    border-top: 1px solid #E0E4E9
    display: flow-root
  &__header
    font-size: 18px !important
    font-weight: 500
    letter-spacing: 0.25px
    color: #212B36
    padding-left: 20px
    border-bottom: 1px solid #E0E4E9
  &__header-close-icon
    cursor: pointer
  &__menu
    padding-left: 40px
    padding-top: 30px
    background-color: #F4F4F4
    font-size: 18px
    width: 240px
    li
      margin-top: 20px
    a
      color: #000000 !important
  &__menu--sticky
    position: sticky
    top: 30px
  &__save-button, &__cancel-button
    height: 36px
    width: 69px
    font-size: 12px
    font-weight: bold
    margin-left: 10px
  &__error-message
    color: #BF0711
    font-size: 18px
  &__form-messages
    display: flex
    align-items: center
.old-edit-address-information
  &__label
    font-size: 14px
    font-weight: 500
  &__msrp-input
    width: 260px
  &__size-select
    width: 90px
  &__size-input
    width: 205px
  &__select, &__input
    width: 295px
    &--fullwidth
      width: 100%
</style>
