<template>
  <div ref="adminTrainingUser" class="admin-training-user__main">
    <a-row>
      <a-col :span="16">
        <search-component
          :search-recent="searchRecent"
          search-hint="Search by First or Last Name"
          @doSearch="onSearch"
          @deleteTag="closeTagItem"
        />
      </a-col>
      <a-col :span="8" style="text-align: right;">
        <a-button
          class="admin-training-landing-red-border-btn"
          style="margin-right: 6px;"
          @click="clickToDelete"
          >Delete</a-button
        >
        <a-button class="admin-training-landing-red-border-btn" @click="clickToDownload"
          >Download CSV</a-button
        >
      </a-col>
    </a-row>

    <a-table
      :columns="tableColumn"
      :data-source="tableData"
      size="middle"
      class="admin-training-table"
      :row-class-name="
        (_recode, index) => (index % 2 === 1 ? 'admin-training-table-striped' : null)
      "
      style="margin-top: 40px;"
      :pagination="false"
      :loading="isLoading"
      :get-popup-container="() => $refs.adminTrainingUser"
    >
      <span slot="createTitle">
        Created
        <a-icon
          type="caret-up"
          :rotate="sortCreate.toString().length > 0 && sortCreate === 'descend' ? 180 : 0"
          :class="
            sortCreate.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('createdAt')"
        />
      </span>
      <span slot="firstTitle">
        First
        <a-icon
          type="caret-up"
          :rotate="sortFirst.toString().length > 0 && sortFirst === 'descend' ? 180 : 0"
          :class="
            sortFirst.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('first')"
        />
      </span>
      <span slot="lastTitle">
        Last
        <a-icon
          type="caret-up"
          :rotate="sortLast.toString().length > 0 && sortLast === 'descend' ? 180 : 0"
          :class="
            sortLast.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('last')"
        />
      </span>
      <span slot="emailTitle">
        Email
        <a-icon
          type="caret-up"
          :rotate="sortEmail.toString().length > 0 && sortEmail === 'descend' ? 180 : 0"
          :class="
            sortEmail.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('email')"
        />
      </span>
      <span slot="retailerTitle">
        Retailer
        <a-icon
          type="caret-up"
          :rotate="sortRetailer.toString().length > 0 && sortRetailer === 'descend' ? 180 : 0"
          :class="
            sortRetailer.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('retailer')"
        />
      </span>
      <span slot="storeTitle">
        Store #
        <a-icon
          type="caret-up"
          :rotate="sortStoreNumber.toString().length > 0 && sortStoreNumber === 'descend' ? 180 : 0"
          :class="
            sortStoreNumber.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('storeNumber')"
        />
      </span>
      <span slot="regionTitle">
        Region
        <a-icon
          type="caret-up"
          :rotate="sortRegion.toString().length > 0 && sortRegion === 'descend' ? 180 : 0"
          :class="
            sortRegion.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('region')"
        />
      </span>
      <span slot="loginTitle">
        Last Login
        <a-icon
          type="caret-up"
          :rotate="sortLogin.toString().length > 0 && sortLogin === 'descend' ? 180 : 0"
          :class="
            sortLogin.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('lastLoginStr')"
        />
      </span>
      <span slot="sessionTitle">
        Sessions
        <a-icon
          type="caret-up"
          :rotate="sortSession.toString().length > 0 && sortSession === 'descend' ? 180 : 0"
          :class="
            sortSession.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('sessionCount')"
        />
      </span>
      <span slot="customCheckTitle" style="padding-bottom: 6px;">
        <a-checkbox v-model="isCheckAll" @change="handleSelectAllReport" />
      </span>
      <span slot="checkAction" slot-scope="text, record">
        <a-checkbox :checked="text" @change="checkedValue => chooseData(checkedValue, record)" />
      </span>
      <span slot="createdAt" slot-scope="text">
        {{ formatDate(text) }}
      </span>
      <span slot="retailer" slot-scope="text, record">
        {{ record.retailer && record.retailer.name ? record.retailer.name : "" }}
      </span>
      <span slot="storeNumber" slot-scope="text, record">
        {{ record.location ? record.location.storeNumber : "" }}
      </span>
      <span slot="region" slot-scope="text, record" style="text-transform:uppercase;">
        {{ record.location && record.location.state ? record.location.state.id : "" }}
      </span>
      <span slot="active" slot-scope="text">
        <!-- <a-switch
          :checked="text"
          class="admin-training-switch"
          @change="deactionLocation(record)"
        /> -->
        <div :class="text ? 'admin-training-active-green' : 'admin-training-deactive-red'"></div>
      </span>
      <span
        slot="actionOper"
        slot-scope="text, record"
        class="admin-training-user__click-oper"
        @click="clickToEdit(record)"
      >
        Edit
      </span>
    </a-table>
    <admin-training-user-edit-dialog
      ref="editAdminTrainingUserProfile"
      @success="getSearchTrainingPro"
    />
    <admin-training-delete-dialog ref="deleteAdminTrainingUser" @success="getSearchTrainingPro" />
  </div>
</template>
<script>
import SearchComponent from "@/components/training/SearchComponent"
import AdminTrainingUserEditDialog from "@/components/admin/AdminTrainingUserEditDialog"
import AdminTrainingDeleteDialog from "@/components/admin/AdminTrainingDeleteDialog"
import { downloadCsvData, getNowDateStr, replaceCommaForCsvStr } from "@/utils/validate"
import { adminUserTag } from "@/utils/const"
export default {
  components: {
    "search-component": SearchComponent,
    AdminTrainingUserEditDialog,
    AdminTrainingDeleteDialog
  },
  data() {
    return {
      isCheckAll: false,
      selectedData: new Set(),
      searchRecent: [],
      searchValue: "",
      isLoading: false,
      tableColumn: [
        {
          key: "isCheck",
          dataIndex: "isChecked",
          slots: { title: "customCheckTitle" },
          scopedSlots: { customRender: "checkAction" },
          width: "30px"
        },
        {
          //title: "Created",
          dataIndex: "createdAt",
          key: "createdAt",
          scopedSlots: { customRender: "createdAt" },
          slots: { title: "createTitle" },
          //sorter: true,
          width: "7%"
        },
        {
          //title: "First",
          dataIndex: "first",
          key: "first",
          slots: { title: "firstTitle" },
          //sorter: true,
          width: "9%"
        },
        {
          //title: "Last",
          dataIndex: "last",
          key: "last",
          slots: { title: "lastTitle" },
          //sorter: true,
          width: "9%"
        },
        {
          //title: "Email",
          dataIndex: "email",
          key: "email",
          slots: { title: "emailTitle" },
          //sorter: true,
          width: "15%"
        },
        // {
        //   title: "State",
        //   dataIndex: "state",
        //   key: "state",
        //   sorter: true
        // },
        {
          //title: "Retailer",
          dataIndex: "retailer",
          key: "retailer",
          scopedSlots: { customRender: "retailer" },
          slots: { title: "retailerTitle" },
          //sorter: true,
          width: "13%"
        },
        {
          //title: "Store #",
          dataIndex: "storeNumber",
          key: "storeNumber",
          slots: { title: "storeTitle" },
          scopedSlots: { customRender: "storeNumber" },
          //sorter: true,
          width: "8%"
        },
        {
          //title: "Region",
          dataIndex: "region",
          key: "region",
          scopedSlots: { customRender: "region" },
          slots: { title: "regionTitle" },
          //sorter: true,
          width: "8%"
        },
        {
          //title: "Last Login",
          dataIndex: "lastLoginStr",
          key: "lastLoginStr",
          slots: { title: "loginTitle" },
          //sorter: true,
          width: "12%"
        },
        {
          //title: "Sessions",
          dataIndex: "sessionCount",
          key: "sessionCount",
          slots: { title: "sessionTitle" },
          //sorter: true,
          width: "7%"
        },
        {
          title: "Active",
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "active" },
          width: "6%"
        },
        {
          title: "",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "actionOper" },
          width: "6%"
        }
      ],
      sortValue: { index: 0, order: "" },
      sortCreate: "",
      sortFirst: "",
      sortLast: "",
      sortEmail: "",
      sortRetailer: "",
      sortStoreNumber: "",
      sortRegion: "",
      sortLogin: "",
      sortSession: ""
    }
  },
  computed: {
    tableData() {
      let data = this.$store.getters["seller/sellerList"]
      console.log(data)
      return data
    }
  },
  async created() {
    this.getSortCreateData()
    this.searchRecent = await this.$store.dispatch("training/getSearchBrandTags", adminUserTag)
    this.$store.dispatch("retailer/fetchAllRetailers", {
      params: { fields: "name,id,locations" }
    })
  },
  methods: {
    getSortCreateData() {
      let param = this.getBaseParam()
      this.isLoading = true
      this.$store
        .dispatch("seller/getSellerList", param)
        .then(() => {
          this.isLoading = false
          this.sortCreate = "ascend"
          this.sortByField("createdAt")
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
    // get user list
    getSearchTrainingPro() {
      let param = this.getBaseParam()
      this.isLoading = true
      this.$store
        .dispatch("seller/getSellerList", param)
        .then(() => {
          this.isLoading = false
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
    getBaseParam() {
      let param = { _limit: 9999 }
      if (this.searchValue && this.searchValue.length > 0) {
        param["name"] = this.searchValue
      }
      return param
    },
    async onSearch(value) {
      this.searchValue = value
      this.searchRecent = await this.$store.dispatch("training/updateBrandTags", {
        tagName: adminUserTag,
        tagItem: this.searchValue
      })
      this.getSearchTrainingPro()
    },
    async closeTagItem(item) {
      await this.$store.dispatch("training/updateBrandTags", {
        tagName: adminUserTag,
        tagItem: item.name,
        type: "delete"
      })
    },
    sortByField(field) {
      let index = 0
      let order = ""
      if (field === "createdAt") {
        index = 1
        if (this.sortCreate.length === 0) {
          this.sortCreate = "ascend"
        } else if (this.sortCreate === "ascend") {
          this.sortCreate = "descend"
        } else {
          this.sortCreate = "ascend"
        }
        this.sortFirst = ""
        this.sortLast = ""
        this.sortEmail = ""
        this.sortRetailer = ""
        this.sortStoreNumber = ""
        this.sortRegion = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortCreate
      } else if (field === "first") {
        index = 2
        if (this.sortFirst.length === 0) {
          this.sortFirst = "ascend"
        } else if (this.sortFirst === "ascend") {
          this.sortFirst = "descend"
        } else {
          this.sortFirst = "ascend"
        }
        this.sortCreate = ""
        this.sortLast = ""
        this.sortEmail = ""
        this.sortRetailer = ""
        this.sortStoreNumber = ""
        this.sortRegion = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortFirst
      } else if (field === "email") {
        index = 4
        if (this.sortEmail.length === 0) {
          this.sortEmail = "ascend"
        } else if (this.sortEmail === "ascend") {
          this.sortEmail = "descend"
        } else {
          this.sortEmail = "ascend"
        }
        this.sortFirst = ""
        this.sortLast = ""
        this.sortCreate = ""
        this.sortRetailer = ""
        this.sortStoreNumber = ""
        this.sortRegion = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortEmail
      } else if (field === "storeNumber") {
        index = 6
        if (this.sortStoreNumber.length === 0) {
          this.sortStoreNumber = "ascend"
        } else if (this.sortStoreNumber === "ascend") {
          this.sortStoreNumber = "descend"
        } else {
          this.sortStoreNumber = "ascend"
        }
        this.sortFirst = ""
        this.sortLast = ""
        this.sortEmail = ""
        this.sortRetailer = ""
        this.sortCreate = ""
        this.sortRegion = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortStoreNumber
      } else if (field === "last") {
        index = 3
        if (this.sortLast.length === 0) {
          this.sortLast = "ascend"
        } else if (this.sortLast === "ascend") {
          this.sortLast = "descend"
        } else {
          this.sortLast = "ascend"
        }
        this.sortFirst = ""
        this.sortCreate = ""
        this.sortEmail = ""
        this.sortRetailer = ""
        this.sortStoreNumber = ""
        this.sortRegion = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortLast
      } else if (field === "region") {
        index = 7
        if (this.sortRegion.length === 0) {
          this.sortRegion = "ascend"
        } else if (this.sortRegion === "ascend") {
          this.sortRegion = "descend"
        } else {
          this.sortRegion = "ascend"
        }
        this.sortFirst = ""
        this.sortLast = ""
        this.sortEmail = ""
        this.sortRetailer = ""
        this.sortStoreNumber = ""
        this.sortCreate = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortRegion
      } else if (field === "retailer") {
        index = 5
        if (this.sortRetailer.length === 0) {
          this.sortRetailer = "ascend"
        } else if (this.sortRetailer === "ascend") {
          this.sortRetailer = "descend"
        } else {
          this.sortRetailer = "ascend"
        }
        this.sortFirst = ""
        this.sortLast = ""
        this.sortEmail = ""
        this.sortCreate = ""
        this.sortStoreNumber = ""
        this.sortRegion = ""
        this.sortLogin = ""
        this.sortSession = ""
        order = this.sortRetailer
      } else if (field === "lastLoginStr") {
        index = 8
        if (this.sortLogin.length === 0) {
          this.sortLogin = "ascend"
        } else if (this.sortLogin === "ascend") {
          this.sortLogin = "descend"
        } else {
          this.sortLogin = "ascend"
        }
        this.sortFirst = ""
        this.sortLast = ""
        this.sortEmail = ""
        this.sortRetailer = ""
        this.sortStoreNumber = ""
        this.sortRegion = ""
        this.sortCreate = ""
        this.sortSession = ""
        order = this.sortLogin
      } else if (field === "sessionCount") {
        index = 9
        if (this.sortSession.length === 0) {
          this.sortSession = "ascend"
        } else if (this.sortSession === "ascend") {
          this.sortSession = "descend"
        } else {
          this.sortSession = "ascend"
        }
        this.sortFirst = ""
        this.sortLast = ""
        this.sortEmail = ""
        this.sortRetailer = ""
        this.sortStoreNumber = ""
        this.sortRegion = ""
        this.sortLogin = ""
        this.sortCreate = ""
        order = this.sortSession
      }
      this.sortData(index - 1, order === "ascend")
    },
    handleName(pagination, filters, sorter) {
      console.log(sorter)
      let index = 0
      if (sorter.field === "createdAt") {
        index = 1
      } else if (sorter.field === "first") {
        index = 2
      } else if (sorter.field === "email") {
        index = 4
      } else if (sorter.field === "storeNumber") {
        index = 6
      } else if (sorter.field === "last") {
        index = 3
      } else if (sorter.field === "region") {
        index = 7
      } else if (sorter.field === "retailer") {
        index = 5
      } else if (sorter.field === "lastLoginStr") {
        index = 8
      } else if (sorter.field === "sessionCount") {
        index = 9
      }
      if (!sorter.order) {
        sorter.order = "ascend"
      }
      console.log(index)
      if (index === this.sortValue.index) {
        if (this.sortValue.order === "ascend") {
          sorter.order = "descend"
        } else {
          sorter.order = "ascend"
        }
        this.sortValue.order = sorter.order
      } else {
        this.sortValue.index = index
        this.sortValue.order = "ascend"
        sorter.order = "ascend"
      }
      console.log(this.sortValue)
      console.log(sorter)

      for (let i = 1; i < 10; i++) {
        if (i !== index) {
          this.$set(this.tableColumn[i], "sortOrder", false)
          //this.tableColumn[i].sortOrder = false
        } else {
          this.$set(this.tableColumn[i], "sortOrder", sorter.order)
          //this.tableColumn[index].sortOrder = sorter.order
        }
      }
      console.log(this.tableColumn)
      //this.$forceUpdate()
      this.sortData(index - 1, sorter.order === "ascend")
    },
    sortData(index, ascend) {
      if (index == 0) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.createdAt.localeCompare(b.createdAt)
          } else {
            return b.createdAt.localeCompare(a.createdAt)
          }
        })
      } else if (index == 1) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.first.localeCompare(b.first)
          } else {
            return b.first.localeCompare(a.first)
          }
        })
      } else if (index == 2) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.last.localeCompare(b.last)
          } else {
            return b.last.localeCompare(a.last)
          }
        })
      } else if (index == 3) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.email.localeCompare(b.email)
          } else {
            return b.email.localeCompare(a.email)
          }
        })
      } else if (index == 5) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.location.storeNumber && b.location.storeNumber) {
              return a.location.storeNumber - b.location.storeNumber
            } else if (a.location.storeNumber && !b.location.storeNumber) {
              return a.location.storeNumber - 0
            } else if (!a.location.storeNumber && b.location.storeNumber) {
              return 0 - b.location.storeNumber
            }
          } else {
            if (a.location.storeNumber && b.location.storeNumber) {
              return b.location.storeNumber - a.location.storeNumber
            } else if (a.location.storeNumber && !b.location.storeNumber) {
              return b.location.storeNumber - 0
            } else if (!a.location.storeNumber && b.location.storeNumber) {
              return 0 - a.location.storeNumber
            }
          }
        })
      } else if (index == 6) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (
              a.location.state &&
              a.location.state.id &&
              b.location.state &&
              b.location.state.id
            ) {
              return a.location.state.id.localeCompare(b.location.state.id)
            }
            if (
              a.location.state &&
              a.location.state.id &&
              (!b.location.state || !b.location.state.id)
            ) {
              return a.location.state.id.localeCompare("")
            }
            if (
              (!a.location.state || !a.location.state.id) &&
              b.location.state &&
              b.location.state.id
            ) {
              return "".localeCompare(b.location.state.id)
            }
          } else {
            if (
              a.location.state &&
              a.location.state.id &&
              b.location.state &&
              b.location.state.id
            ) {
              return b.location.state.id.localeCompare(a.location.state.id)
            }
            if (
              b.location.state &&
              b.location.state.id &&
              (!a.location.state || !a.location.state.id)
            ) {
              return b.location.state.id.localeCompare("")
            }
            if (
              (!b.location.state || !b.location.state.id) &&
              a.location.state &&
              a.location.state.id
            ) {
              return "".localeCompare(a.location.state.id)
            }
          }
        })
      } else if (index == 4) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.retailer.name && b.retailer.name) {
              return a.retailer.name.localeCompare(b.retailer.name)
            }
            if (a.retailer.name && !b.retailer.name) {
              return a.retailer.name.localeCompare("")
            }
            if (a.retailer.name && b.retailer.name) {
              return "".localeCompare(b.location.city)
            }
          } else {
            if (a.retailer.name && b.retailer.name) {
              return b.retailer.name.localeCompare(a.retailer.name)
            }
            if (b.retailer.name && !a.retailer.name) {
              return b.retailer.name.localeCompare("")
            }
            if (!b.retailer.name && a.retailer.name) {
              return "".localeCompare(a.retailer.name)
            }
          }
        })
      } else if (index === 7) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.lastLoginStr && b.lastLoginStr) {
              return a.lastLoginStr.localeCompare(b.lastLoginStr)
            } else if (!a.lastLoginStr && b.lastLoginStr) {
              return "".localeCompare(b.lastLoginStr)
            } else if (a.lastLoginStr && !b.lastLoginStr) {
              return a.lastLoginStr.localeCompare("")
            }
          } else {
            if (a.lastLoginStr && b.lastLoginStr) {
              return b.lastLoginStr.localeCompare(a.lastLoginStr)
            } else if (!b.lastLoginStr && a.lastLoginStr) {
              return "".localeCompare(a.lastLoginStr)
            } else if (b.lastLoginStr && !a.lastLoginStr) {
              return b.lastLoginStr.localeCompare("")
            }
          }
        })
      } else {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.sessionCount - b.sessionCount
          } else {
            return b.sessionCount - a.sessionCount
          }
        })
      }
    },
    handleSelectAllReport() {
      this.selectedData.clear()
      this.tableData.forEach(item => {
        item.isChecked = this.isCheckAll
        if (this.isCheckAll) {
          this.selectedData.add(item)
        }
      })
    },
    chooseData(checkedValue, record) {
      record.isChecked = checkedValue.target.checked
      if (checkedValue.target.checked) {
        // add record into the list
        this.selectedData.add(record)
      } else {
        // remove it form list
        this.selectedData.delete(record)
        if (this.isCheckAll) {
          this.isCheckAll = false
        }
      }
      this.$forceUpdate()
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    clickToDelete() {
      if (this.selectedData && this.selectedData.size > 0) {
        this.showDeleteDialog([...this.selectedData])
      } else {
        this.$message.warning("Please choose records")
      }
    },
    showDeleteDialog(data) {
      console.log("....show delete dialog....")
      this.$refs["deleteAdminTrainingUser"].openDialog(data)
    },
    async clickToDownload() {
      console.log("....download data.....")
      if (this.selectedData && this.selectedData.size > 0) {
        const brandName = this.retailer ? this.retailer.name : "retailer"
        await downloadCsvData(
          this.generateDownloadStr(),
          `${brandName}_user_${getNowDateStr()}.csv`
        )
      } else {
        this.$message.warning(this.$t("Admins.AdminInventory.downloadTooltip"), 5)
      }
    },
    generateDownloadStr() {
      if (this.selectedData && this.selectedData.size > 0) {
        let csvData = [
          "Created, First, Last, Email, Retailer, Store #, Region, Last Login, Sessions, active"
        ]
        for (let item of this.selectedData) {
          let str = `${this.formatDate(item.createdAt)}, ${replaceCommaForCsvStr(
            item.user.givenName
          )}, ${replaceCommaForCsvStr(item.user.familyName)}, ${replaceCommaForCsvStr(
            item.user.email
          )}, ${replaceCommaForCsvStr(item.retailer.name)}, ${
            item.location.storeNumber ? item.location.storeNumber : ""
          }, ${item.state && item.state.id ? item.state.id : "-"},${
            item.lastLoginStr ? item.lastLoginStr : "-"
          }, ${item.sessionCount}, ${item.active}`
          csvData.push(str)
        }
        return csvData
      }
      return []
    },
    clickToEdit(data) {
      console.log(".....click to edit....")
      console.log(data)
      this.$refs["editAdminTrainingUserProfile"].openDialog(data)
    },
    deactionLocation(data) {
      console.log("....de active user.....")
      let param = { id: data.id, active: !data.active }
      this.$store
        .dispatch("seller/updateASeller", { sellerId: data.id, params: param })
        .then(() => {
          this.$message.success(data.active ? "Deactive user success" : "Active user success")
          data.active = !data.active
        })
        .catch(err => {
          console.log(err)
          this.$message.error(err.message)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-training-user {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__click-oper {
    cursor: pointer;
    color: #4a8a5d;
  }
}
.admin-training-user__main {
  .ant-checkbox-wrapper::after {
    contain: none;
    display: none;
  }
}
</style>
