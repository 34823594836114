<template lang="pug">
.brand-registraion
  .brand-personal-info(v-if="index==1")
    .brand-personal-info__header
      .brand-personal-info__dot-container
        .brand-personal-info__dot-container--dot-filled
        .brand-personal-info__dot-container--dot-unfilled
      span.brand-personal-info__header--title {{$t('Components.Brands.Unfiltered.BrandRegistration.helloThere')}}
      span.brand-personal-info__header--subtitle {{$t('Components.Brands.Unfiltered.BrandRegistration.letsStartMessage')}}

    .brand-personal-info__form
      .columns
        .column.is-6.brand-personal-info__form-field
          .field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.firstName')}}
              input.input.brand-personal-info__form--input(
                  v-model.trim="$v.user.givenName.$model" :class="{ 'is-danger': $v.user.givenName.$error}"
                type="text" :placeholder="$t('Components.Brands.Unfiltered.BrandRegistration.firstName')")
              span.help.is-danger(v-if="$v.user.givenName.$error") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.firstNameRequired')}}
        .column.is-6.brand-personal-info__form-field
          .field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.lastName')}}
              input.input.brand-personal-info__form--input(
                  v-model.trim="$v.user.familyName.$model" :class="{ 'is-danger': $v.user.familyName.$error}"
                type="text" :placeholder="$t('Components.Brands.Unfiltered.BrandRegistration.lastName')")
              span.help.is-danger(v-if="$v.user.familyName.$error") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.lastNameRequired')}}
      .columns
        .column.is-6.brand-personal-info__form-field
          .field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.createPassword')}}
              input.input.brand-personal-info__form--input(
                  v-model.trim="$v.user.password.$model" :class="{ 'is-danger': $v.user.password.$error || user.upperCaseAndNumberValidation}"
                type="password" placeholder="Password"
                @input="user.upperCaseAndNumberValidation=false")
              span.help.is-danger(v-if="$v.user.password.$error && !$v.user.password.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.passwordRequired')}}
              span.help.is-danger(v-if="$v.user.password.$error && !$v.user.password.minLength") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.passwordMinLength10')}}
              span.help.is-danger(v-if="user.upperCaseAndNumberValidation") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.passwordPattern')}}
        .column.is-6.brand-personal-info__form-field
          .field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.confirmPassword')}}
              input.input.brand-personal-info__form--input(
                  v-model.trim="$v.user.confirmPassword.$model" :class="{ 'is-danger': $v.user.confirmPassword.$error}"
                type="password" placeholder="Password")
              span.help.is-danger(v-if="$v.user.confirmPassword.$error && !$v.user.confirmPassword.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.confirmPasswordRequired')}}
              span.help.is-danger(v-if="!$v.user.confirmPassword.sameAsPassword && user.confirmPassword.length") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.passwordsMustMatch')}}
      .columns
        .column.is-6.brand-personal-info__form-field
          .field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.registrationCode')}}
              input.input.brand-personal-info__form--input(@input="promocodeInvalid=false"
                v-model.trim="$v.user.promocode.$model" :class="{ 'is-danger': $v.user.promocode.$error || promocodeInvalid}"
                type="text" placeholder="Registration Code *" :disabled="disablePromocode")
              span.help.is-danger(v-if="$v.user.promocode.$error") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.registrationCodeRequired')}}
              span.help.is-danger(v-if="promocodeInvalid") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.invalidPromocode')}}
          p.registraion-code-message {{$t('Components.Brands.Unfiltered.BrandRegistration.registrationCodeMessage')}}
        .column.is-6.brand-personal-info__form-field
          .field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.emailAddress')}}
              input.input.brand-personal-info__form--input(@input="duplicatedEmail=false"
                v-model.trim="$v.user.email.$model" :class="{ 'is-danger': $v.user.email.$error || duplicatedEmail}"
                type="text" :placeholder="$t('Components.Brands.Unfiltered.BrandRegistration.emailAddress')")
              span.help.is-danger(v-if="$v.user.email.$error && !$v.user.email.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.emailRequired')}}
              span.help.is-danger(v-if="$v.user.email.$error && !$v.user.email.email") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.invalidEmail')}}
              span.help.is-danger(v-if="duplicatedEmail") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.duplicateEmail')}}
  .brand-personal-info(v-if="index==2")
    .brand-personal-info__header
      .brand-personal-info__dot-container
        .brand-personal-info__dot-container--dot-filled
        .brand-personal-info__dot-container--dot-filled
      span.brand-personal-info__header--title {{$t('Components.Brands.Unfiltered.BrandRegistration.tellUsAboutBrand')}}
      span.brand-personal-info__header--subtitle {{$t('Components.Brands.Unfiltered.BrandRegistration.completeProfileMessage')}}
    .brand-personal-info__form
      .columns
        .column.is-6.brand-personal-info__form-field
          .field.brand-personal-info__field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.brandName')}}
              input.input.brand-personal-info__form--input(@input="duplicatedName=false"
                  v-model.trim="$v.organization.name.$model" :class="{ 'is-danger': $v.organization.name.$error || duplicatedName}"
                type="text" :placeholder="$t('Components.Brands.Unfiltered.BrandRegistration.brandName')")
              span.help.is-danger(v-if="$v.organization.name.$error && !$v.organization.name.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.organizationNameRequired')}}
              span.help.is-danger(v-show="duplicatedName") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.duplicatename')}}
          .field.brand-personal-info__field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.brandWebsite')}}
              input.input.brand-personal-info__form--input(
                  v-model.trim="$v.organization.website.$model" :class="{ 'is-danger': $v.organization.website.$error}"
                type="text" placeholder="Brand Website")
              span.help.is-danger(v-if="$v.organization.website.$error && !$v.organization.website.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.websiteRequired')}}
          .field.brand-personal-info__field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.describeYourBrand')}}
              textarea.textarea.brand-personal-info__form--text-area(
                  v-model.trim="$v.organization.about.$model" :class="{ 'is-danger': $v.organization.about.$error}"
                type="text"
                  :placeholder="$t('Components.Brands.Unfiltered.BrandRegistration.describeBrandPlaceholder')"
              )
              span.help.is-danger(v-if="$v.organization.about.$error && !$v.organization.about.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.descriptionRequired')}}
        .column.is-6.brand-personal-info__form-field
          .field.brand-personal-info__field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.streetAddress')}}
              input.input.brand-personal-info__form--input(
                  v-model.trim="$v.headquarters.streetAddressLine_1.$model" :class="{ 'is-danger': $v.headquarters.streetAddressLine_1.$error}"
                type="text" placeholder="Street Address")
              span.help.is-danger(v-if="$v.headquarters.streetAddressLine_1.$error && !$v.headquarters.streetAddressLine_1.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.streetAddressRequired')}}
          .field.brand-personal-info__field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.suiteBldgFloor')}}
              input.input.brand-personal-info__form--input(v-model.trim="headquarters.streetAddressLine_2" type="text" :placeholder="$t('Components.Brands.Unfiltered.BrandRegistration.suiteBldgFloor')")
          .field.brand-personal-info__field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.city')}}
              input.input.brand-personal-info__form--input(
                  v-model.trim="$v.headquarters.city.$model" :class="{ 'is-danger': $v.headquarters.city.$error}"
                type="text" :placeholder="$t('Components.Brands.Unfiltered.BrandRegistration.city')")
              span.help.is-danger(v-if="$v.headquarters.city.$error && !$v.headquarters.city.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.cityRequired')}}
          .columns
            .column.is-6
              .field
                .control
                  label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.stateRegion')}}
                  input.input.brand-personal-info__form--input(
                      v-model.trim="$v.headquarters.region.$model" :class="{ 'is-danger': $v.headquarters.region.$error}"
                    type="text" :placeholder="$t('Components.Brands.Unfiltered.BrandRegistration.stateRegion')")
                  span.help.is-danger(v-if="$v.headquarters.region.$error && !$v.headquarters.region.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.stateOrRegionRequired')}}
            .column.is-6
              .field
                .control
                  label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.postalCode')}}
                  input.input.brand-personal-info__form--input(
                      v-model.trim="$v.headquarters.postalCode.$model" :class="{ 'is-danger': $v.headquarters.postalCode.$error}"
                    type="text" :placeholder="$t('Components.Brands.Unfiltered.BrandRegistration.postalCode')")
                  span.help.is-danger(v-if="$v.headquarters.postalCode.$error && !$v.headquarters.postalCode.required") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.postalCodeRequired')}}
          .field.brand-personal-info__field
            .control
              label.brand-personal-info__form--label {{$t('Components.Brands.Unfiltered.BrandRegistration.country')}}
              .select.is-fullwidth
                select(v-model.trim="$v.headquarters.country.id.$model" :class="{ 'is-danger': $v.headquarters.country.$error}")
                  option(v-for="country in countries" :key="country.id" :value="country.id") {{ country.name }}
                .brand-personal-info__select-placeholder(v-if="!headquarters.country.id") {{$t('Components.Brands.Unfiltered.BrandRegistration.country')}}
              span.help.is-danger(v-if="$v.headquarters.country.id.$error") {{$t('Components.Brands.Unfiltered.BrandRegistration.Validations.countryRequired')}}


  .brand-registraion__buttons
    .agree-terms(v-if="index==2")
      img.agree-terms__icon(v-show="!isAgreeTerms" src="@/assets/img/checkbox.png" @click="isAgreeTerms=true")
      img.agree-terms__icon-checked(v-show="isAgreeTerms" src="@/assets/img/inputs/checked.png" @click="isAgreeTerms=false")
      .agree-terms__text
      span I agree to the
      span.agree-terms__text-link(@click="navigateToTerms") Terms & Conditions
      span and
      span.agree-terms__text-link(@click="navigateToPrivacy") Privacy Policy
    button.next-button(v-if="index<2" @click="next") {{$t('Components.Brands.Unfiltered.BrandRegistration.next')}}
    button.next-button(
      v-if="index==2"
      @click="submit"
      :disabled="!isAgreeTerms"
      :class="{'disabled-button' : !isAgreeTerms}"
    ) {{$t('Components.Brands.Unfiltered.BrandRegistration.submitApplication')}}
    a.back-button(v-if="index>1" @click="back") {{$t('Components.Brands.Unfiltered.BrandRegistration.back')}}
            

    
</template>

<script>
import { mapActions } from "vuex";
import { required, sameAs, email, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      promocodeInvalid: false,
      disablePromocode: false,
      role: "brand",
      user: {
        givenName: "",
        familyName: "",
        email: "",
        password: "",
        confirmPassword: "",
        promocode: "",
        upperCaseAndNumberValidation: false,
      },
      organization: {
        name: "",
        website: "",
        about: "",
        promocode: "",
      },
      headquarters: {
        addressee: "",
        streetAddressLine_1: "",
        streetAddressLine_2: "",
        city: "",
        region: "",
        postalCode: "",
        country: {
          id: "",
        },
        hq: true,
        defaultShipping: false,
      },
      isAgreeTerms: false,
      index: 1,
      duplicatedEmail: false,
      duplicatedName: false,
    };
  },
  validations: {
    user: {
      givenName: { required },
      familyName: { required },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(10),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      promocode: { required },
    },
    organization: {
      name: { required },
      website: { required },
      about: { required },
    },
    headquarters: {
      streetAddressLine_1: { required },
      city: { required },
      region: { required },
      postalCode: { required },
      country: {
        id: { required },
      },
    },
  },
  computed: {
    countries() {
      return this.$store.getters["reference/countries"];
    },
  },
  created() {
    if (this.$route.query.token) {
      this.user.promocode = this.$route.query.token;
      this.disablePromocode = true;
    }
  },
  methods: {
    ...mapActions("user", ["signUp"]),
    passwordCheck() {
      if (
        this.user.password.search(/[A-Z]/) == -1 ||
        this.user.password.search(/[0-9]/) == -1
      ) {
        return true;
      }
    },
    next() {
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }
      if (this.passwordCheck()) {
        this.user.upperCaseAndNumberValidation = true;
        return;
      }
      this.index = this.index + 1;
    },
    back() {
      this.index = this.index - 1;
    },
    async submit() {
      this.$v.organization.$touch();
      this.$v.headquarters.$touch();
      if (this.$v.organization.$anyError || this.$v.headquarters.$anyError) {
        return;
      }
      this.headquarters.addressee = this.organization.name;
      this.organization.promocode = this.user.promocode;
      const { user, organization, role, headquarters } = this;
      const payload = { user };
      payload[role] = organization;
      payload[role]["addresses"] = [headquarters];
      this.duplicatedName = false;
      this.duplicatedEmail = false;
      this.promocodeInvalid = false;
      try {
        await this.signUp(payload);
        this.$router.push({ name: "brandPending" });
      } catch (error) {
        if (error.response.data.code === 400) {
          this.promocodeInvalid = error.response.data.error.includes(
            "client/invalid_promo_code"
          );
          const errors =
            error.response.data.underlying &&
            error.response.data.underlying.map((error) => error.error);
          this.duplicatedEmail = errors && errors.includes("user_exists");
          this.duplicatedName = errors && errors.includes("client_exists");
          if (this.duplicatedEmail || this.promocodeInvalid) {
            this.index = 1;
          }
        }
      }
    },
    // navigateToTerms() {
    //   this.$router.push({ name: "terms" })
    // },
    // navigateToPrivacy() {
    //   this.$router.push({ name: "privacyPolicy" })
    // },
    navigateToTerms() {
      const url = "http://www.landinginternational.com/terms-of-use";
      // this.$router.push({ name: "terms" })
      window.open(url, "_target");
    },
    navigateToPrivacy() {
      //this.$router.push({ name: "privacyPolicy" })
      const url = "https://www.landinginternational.com/privacy-policy";
      // this.$router.push({ name: "terms" })
      window.open(url, "_target");
    },
  },
};
</script>

<style lang="sass">
.brand-registraion
  padding: 50px
  width: 100%

  &__buttons
    display: flex
    margin-top: 25px
    flex-direction: column
    align-items: center

.brand-personal-info
  &__header
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 25px
    color: #212B36

    &--title
      font-size: 28px
      color: #333333

    &--subtitle
      font-size: 16px
      color: #333333

  &__form-field
    padding-right: 16px
    padding-left: 16px

  &__field
    margin-bottom: 24px !important

  &__form
    &--label
      font-size: 14px
      color: #212B36
    &--text-area
      height: 204px

    .registraion-code-message
      font-size: 10px

  &__terms-checkbox
    position: relative

  &__terms-checkbox-icon
    position: absolute
    left: 0
    cursor: pointer

  &__terms-checkbox-icon--checked
    position: absolute
    right: 3px
    top: 3px
    width: 18px
    height: 18px

  &__terms-checkbox-text
    margin-left: 25px
    font-size: 14px
    color: #212B36

  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 30px
    color: #b4b4b4
    width: 0px
    font-size: 14px

  &__dot-container
    display: flex
    margin-bottom: 12px

    &--dot-filled
      height: 8px
      width: 8px
      background-color: #000000
      margin: 0 4px
      border-radius: 50px

    &--dot-unfilled
      height: 8px
      width: 8px
      margin: 0 4px
      background-color: #DBDBDB
      border-radius: 50px

.next-button
  background-color: #FF6132
  width: 335px
  height: 55px
  border-radius: 30px
  color: #FFFFFF
  font-size: 16px

.back-button
  margin-top: 20px
  color: #161616
  font-size: 14px
  font-weight: 300
  text-decoration: underline

.percentage-icon
  color: #000000 !important

.agree-terms
  display: flex
  align-items: center
  font-size: 10px
  color: #000000
  margin-bottom: 5px

  &__icon
    margin-right: 10px
    height: 16px
    width: 16px
    cursor: pointer

  &__icon-checked
    margin-right: 10px
    height: 16px
    width: 16px
    cursor: pointer
  &__text-link
    text-decoration: underline
    cursor: pointer
    margin: 0 3px
.disabled-button
  opacity: 0.5
</style>
