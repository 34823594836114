<template>
  <div class="beauty-fluent-step-three-billing__main">
    <div class="billing-title">
      <p>
        <a-avatar
          style="color: #F15A42; background-color: rgba(237, 142, 131, .2);margin-right: 18px"
          >{{ isBrand ? 1 : 2 }}</a-avatar
        >
        <span style="font-size: 18px;font-weight: bold;color: #1a1818"
          >SELECT BILLING FREQUENCY</span
        >
      </p>
      <!--      <p>-->
      <!--        <span>Already have an account?</span>-->
      <!--        <span style="color: #F15A42;font-weight: bold;margin-left: 10px;cursor: pointer">LOG IN HERE</span>-->
      <!--      </p>-->
    </div>
    <div v-for="item in billingList" :key="item.id" class="billing-card-content">
      <div
        class="plan-card year-plan-card"
        :class="item.isClick ? 'plan-card-click' : ''"
        @click="choosePlan(item)"
      >
        <div>
          <p>
            <a-radio v-model="item.isClick" @change="choosePlan(item)"></a-radio>
            <span style="font-weight: bold;font-family: 'Proxima Nova', 'Roboto', sans-serif">{{
              item.titleName
            }}</span>
          </p>
          <p
            class="save-up-style"
            :class="item.hasSav ? '' : 'no-save-up-style'"
            style="font-family: 'Proxima Nova', 'Roboto', sans-serif"
          >
            {{ item.hasSav ? item.savings : "0%" }} SAVINGS
          </p>
        </div>
        <div style="text-align: right">
          <div class="price-style">
            <p style="font-family: 'Proxima Nova', 'Roboto', sans-serif">
              <span v-if="item.hasSav" class="save-price-style"
                >save up to {{ priceFormat(item.savingNumber) }}</span
              >
            </p>
            <p>
              <span style="font-size: 25px;font-family: DMSans">{{
                priceFormat(item.sumTotal)
              }}</span>
              <span style="font-weight: bold;font-family: 'Proxima Nova', 'Roboto', sans-serif"
                >/ {{ item.unitText }}</span
              >
            </p>
          </div>
          <p style="font-family: 'Proxima Nova', 'Roboto', sans-serif">{{ item.number }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { priceFormat } from "@/utils/validate"
import _ from "lodash"

export default {
  name: "BFStep3BillingCard",
  props: {
    billingInfo: {
      type: Array,
      default: () => []
    },
    isBrand: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      priceFormat
      // billingList: JSON.parse(JSON.stringify(this.billingInfo))
    }
  },
  computed: {
    billingList() {
      const list = JSON.parse(JSON.stringify(this.billingInfo))
      return _.sortBy(list, item => item.id)
    }
  },
  methods: {
    // sortBilling() {}
    choosePlan(planItem) {
      const list = this.billingInfo.map(item => {
        if (item.id == planItem.id) {
          item.isClick = true
        } else {
          item.isClick = false
        }
        return item
      })
      const sendBilling = this.billingInfo.filter(item => item.isClick)[0]
      this.$emit("sendBilling", sendBilling, list)
    }
  }
}
</script>

<style lang="scss">
.beauty-fluent-step-three-billing__main {
  margin-bottom: 48px;
  p {
    margin-bottom: 0;
  }
  .billing-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    font-family: "Niveau Grotesk";
  }
  .billing-card-content {
    .plan-card {
      width: 100%;
      height: 96px;
      padding: 20px;
      margin-bottom: 16px;
      border: 1px solid rgba(26, 24, 24, 0.2);
      border-radius: 6px;
      color: #1a1818;
      cursor: pointer;
    }
    .plan-card-click {
      border-color: #f15a42;
    }
    .year-plan-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .save-up-style {
    display: inline-block;
    //width: 142px;
    //height: 24px;
    text-align: center;
    padding: 3px 10px;
    margin-top: 10px;
    margin-left: 20px;
    border: 1px solid #f15a42;
    border-radius: 30px;
    color: #f15a42;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .no-save-up-style {
    color: #1a1818;
    border: 1px solid #1a1818;
  }
  .save-price-style {
    display: inline-block;
    width: 134px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    background-color: rgba(250, 201, 44, 0.3);
    color: #f15a42;
    margin-right: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .price-style {
    //width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #f15a42 !important;
  }
  .ant-radio-checked::after {
    border: 1px solid #f15a42;
  }
  .ant-radio-inner::after {
    background-color: #f15a42 !important;
  }
}
</style>
