<template>
  <a-modal v-model="isShowUpdatePlanModal" :footer="null" :destroy-on-close="true"
    class="is-active brand-settings-modal" width="601px" :body-style="noPadding">
    <template #closeIcon>
      <img class="request-terms-modal__close-button" src="@/assets/img/icon/close-icon.svg" @click="closeDialog" />
    </template>
    <p class="request-terms-modal__header no-margin">
      {{ $t("Brands.BrandSettings.editPlanType") }}
    </p>
    <div class="section modal-card-body subscription__form-block padding-small">
      <div class="modal-content">
        <a-row class="row-margin-bottom">
          <p class="change-plan-title">
            {{ $t("Brands.BrandSettings.changePlanType") }}
          </p>
          <a-radio-group v-model="initPlanType" @change="changePlanType">

            <template v-for="item in subscriptionTypes">
              <a-radio v-if="item.id !== 'classic'" :key="item.id" class="modal-radio" :value="item.id">
                {{ item.name }}
              </a-radio>
            </template>
          </a-radio-group>
        </a-row>
        <div v-if="showSubscriberExpired">
          <a-row>
            <a-col class="align-center" :span="8">
              <span class="change-plan-title">Renewal Date</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col class="align-center" :span="8">
              <a-date-picker name="getPremiumStartDate" format="YYYY-MM-DD" :disabled-date="disabledExpiredDate"
                @change="onChangePlanExpiredDate" />
            </a-col>
          </a-row>
        </div>
        <div v-if="showPremiumDate">
          <a-row>
            <a-col class="align-center" :span="8">
              <span class="change-plan-title">{{
    $t("Brands.BrandSettings.startDate")
  }}</span>
            </a-col>
            <a-col class="align-center" :span="8">
              <span class="change-plan-title">{{
    $t("Brands.BrandSettings.endDate")
  }}</span>
            </a-col>
            <a-col class="align-center" :span="8">
              <span class="change-plan-title">{{
    $t("Brands.BrandSettings.amount")
  }}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col class="align-center" :span="8">
              <a-date-picker name="getPremiumStartDate" :format="dateFormat" :disabled-date="disabledStartDate"
                @change="onChangePlanStartDate" />
            </a-col>
            <a-col class="align-center" :span="8">
              <a-date-picker name="getPremiumEndDate" :format="dateFormat" :disabled-date="disabledDate"
                @change="onChangePlanEndDate" />
            </a-col>
            <a-col class="align-center" :span="8">
              <a-input-number v-model="paidAmount" :placeholder="$t('Brands.BrandSettings.paidAmount')"
                :class="{ 'is-danger': $v.paidAmount.$error }" @change="resetValidationFlags" />
              <p v-if="!$v.paidAmount.required" class="help is-danger">
                {{ $t("Brands.BrandSettings.paidAmountCannotBeEmpty") }}
              </p>
              <p v-if="!$v.paidAmount.numeric" class="help is-danger">
                {{ $t("Brands.BrandSettings.enterNumericValue") }}
              </p>
            </a-col>
          </a-row>
        </div>
        <div v-if="showBasicStartDate">
          <p class="change-plan-title">
            {{ $t("Brands.BrandSettings.startDate") }}
          </p>
          <a-date-picker name="getBasicDate" :format="dateFormat" :disabled-date="disabledBasicStartDate"
            @change="onChangeBasicDate" />
        </div>
      </div>
      <div class="modal-card-foot modal-actions">
        <a class="button is-white" @click="closeUpdatePlanModal">
          {{ $t("Brands.BrandSettings.cancel") }}
        </a>
        <a class="button is-black" @click="savePlanUpdate">
          {{ $t("Brands.BrandSettings.save") }}
        </a>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {
  Radio,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Modal,
} from "ant-design-vue";
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";
import brandClient from "@/api/brand-client";
import SubscriptionsService from "@/services/subscriptions-service";
export default {
  validations: {
    paidAmount: { required, numeric },
    selectPlanStartDate: { required },
    selectPlanEndDate: { required },
    selectBasicStartDate: { required },
  },
  components: {
    "a-radio": Radio,
    "a-radio-group": Radio.Group,
    "a-input-number": InputNumber,
    "a-date-picker": DatePicker,
    "a-modal": Modal,
    "a-row": Row,
    "a-col": Col,
  },
  props: {
    brandType: { type: String, default: () => undefined },
    isAuthorizeNet: { type: Boolean, default: () => false },
  },
  data() {
    return {
      isShowUpdatePlanModal: false,
      showBasicStartDate: false,
      showPremiumDate: false,
      showSubscriberExpired: false,
      showValidation: false,
      selectedPlanType: undefined,
      expiredDate: undefined,
      selectPlanStartDate: "",
      selectPlanEndDate: "",
      initPlanType: undefined,
      paidAmount: 2000,
      dateFormat: "MM/DD/YYYY",
      noPadding: {
        padding: "0px",
      },
    };
  },
  computed: {
    subscriptionTypes() {
      let result;
      let data = this.$store.getters["reference/subscriptionTypes"];
      let brand = this.$store.getters["brand/brand"];
      const expiredValue =
        brand.subscriptions &&
        brand.subscriptions.length !== 0 &&
        brand.subscriptions[0].status.id;
      if (this.brandType === "premium" || this.brandType === "trial") {
        result = [
          {
            id: "cancel",
            name: "Cancel",
          },
          {
            id: "subscriber",
            name: "Subscriber",
          },
        ];
      } else if (
        this.brandType === "basic" ||
        (this.brandType === "subscriber" && expiredValue === "expired")
      ) {
        result = [
          {
            id: "trial",
            name: "Trial",
          },
          {
            id: "subscriber",
            name: "Subscriber",
          },
        ];
      } else if (
        this.brandType === "subscriber" &&
        expiredValue !== "expired"
      ) {
        result = [
          {
            id: "cancel",
            name: "Cancel",
          },
          {
            id: "expired",
            name: "Expired",
          },
        ];
      } else {
        result = data;
      }

      if (this.isAuthorizeNet) {
        result = result.filter((r) => r.id === "cancel" || r.id === "expired");
      }
      return result;
    },
  },
  watch: {
    brandType: {
      handler(newValue) {
        this.brandType = newValue;
        if (
          this.initPlanType === undefined &&
          newValue &&
          newValue.length > 0
        ) {
          this.initPlanType = newValue;
        }
      },
    },
  },
  // updated() {
  //   if (this.initPlanType === undefined && this.brandType && this.brandType.length > 0) {
  //     this.initPlanType = this.brandType
  //   }
  // },
  methods: {
    closeDialog() {
      this.isShowUpdatePlanModal = false;
      this.initPlanType = undefined;
      this.selectedPlanType = undefined;
    },
    openDialog() {
      this.isShowUpdatePlanModal = true;
      this.showSubscriberExpired = false;
      console.log(this.isAuthorizeNet);
    },
    changePlanType() {
      this.showPremiumDate = false;
      this.showBasicStartDate = false;
      this.showSubscriberExpired = false;
      if (
        this.brandType === this.initPlanType &&
        this.brandType !== "subscriber"
      ) {
        if (this.selectedPlanType) {
          this.selectedPlanType = undefined;
        }
        return;
      }
      let plan = this.subscriptionTypes.find(
        (item) => item.id === this.initPlanType
      );
      this.selectedPlanType = plan.id;
      if (this.selectedPlanType) {
        if (this.selectedPlanType === "subscriber") {
          this.showPremiumDate = true;
        }
        if (this.selectedPlanType === "expired") {
          // this.showSubscriberExpired = true;
        }
      }
    },
    onChangeBasicDate(date, dateString) {
      this.selectBasicStartDate = dateString;
    },
    disabledBasicStartDate(currentDate) {
      return (
        currentDate <
        moment(new Date())
          .add(-1, "days")
          .endOf("day") || currentDate > moment(new Date()).endOf("day")
      );
    },
    disabledStartDate(currentDate) {
      return (
        currentDate <
        moment(new Date())
          .add(-1, "days")
          .endOf("day") || currentDate > moment(new Date()).endOf("day")
      );
    },
    disabledDate(currentDate) {
      if (this.selectPlanStartDate) {
        return (
          currentDate <
          moment(this.selectPlanStartDate, this.dateFormat).endOf("day")
        );
      } else {
        return currentDate <= moment().endOf("day");
      }
    },
    disabledExpiredDate(currentDate) {
      let brand = this.$store.getters["brand/brand"];
      const time =
        brand.subscriptions &&
        brand.subscriptions.length !== 0 &&
        brand.subscriptions[0].startDate;
      return (
        currentDate > moment(new Date()).endOf("day") ||
        currentDate <
        moment(time)
          .add(-1, "days")
          .endOf("day")
      );
    },
    onChangePlanStartDate(date, dateString) {
      this.selectPlanStartDate = dateString;
    },
    onChangePlanEndDate(date, dateString) {
      this.selectPlanEndDate = dateString;
    },
    onChangePlanExpiredDate(date, dateString) {
      this.expiredDate = dateString;
    },
    closeUpdatePlanModal() {
      this.isShowUpdatePlanModal = false;
    },
    getDateString(date) {
      if (date) {
        let dates = date.split("/");
        return `${dates[2]}-${dates[0]}-${dates[1]}`;
      }
      return "";
    },
    resetValidationFlags() {
      this.showValidation = false;
    },
    async savePlanUpdate() {
      if (this.isAuthorizeNet) {
        console.log("update");
        let brand = this.$store.getters["brand/brand"];
        switch (this.selectedPlanType) {
          case "cancel":
            await SubscriptionsService.cancelSubscription(brand.id);
            break;
          case "expired":
            await SubscriptionsService.expiredBrandSubscription(brand.id);
            break;
        }

        await this.$store.dispatch("brand/getBrand", brand.id);
        this.closeUpdatePlanModal();
        this.selectedPlanType = undefined;
        this.showBasicStartDate = false;
        this.showPremiumDate = false;
        return;
      }
      // if (this.selectedPlanType === "basic") {
      //   this.$v.selectBasicStartDate.$touch()
      //   if (this.$v.selectBasicStartDate.$invalid) {
      //     return
      //   }
      if (
        this.selectedPlanType === "expired" &&
        this.brandType === "subscriber"
      ) {
        const params = {
          "expired-date": this.expiredDate,
          mode: "refund",
        };
        let brand = this.$store.getters["brand/brand"];
        await brandClient.updateSubscriberExpired(brand.id, params);
        await this.$store.dispatch("brand/getBrand", brand.id);
        this.closeUpdatePlanModal();
        this.selectedPlanType = undefined;
        this.initPlanType = undefined;
        this.showBasicStartDate = false;
        this.showPremiumDate = false;
        return;
      }
      if (this.selectedPlanType === "subscriber") {
        this.$v.paidAmount.$touch();
        this.$v.selectPlanStartDate.$touch();
        this.$v.selectPlanEndDate.$touch();
        if (
          this.$v.paidAmount.$invalid ||
          this.$v.selectPlanStartDate.$invalid ||
          this.$v.selectPlanEndDate.$invalid
        ) {
          return;
        }
      }
      try {
        let params = [];
        let param;
        // if (this.selectedPlanType === "basic") {
        //   params.push({
        //     type: { id: this.selectedPlanType },
        //     startDate: this.getDateString(this.selectBasicStartDate)
        //   })
        // } else
        if (this.selectedPlanType !== "subscriber") {
          param = {
            mode: this.selectedPlanType,
          };
        } else {
          params.push({
            type: { id: this.selectedPlanType },
            paidAmountUsd: this.paidAmount,
            startDate: this.getDateString(this.selectPlanStartDate),
            endDate: this.getDateString(this.selectPlanEndDate),
            status: {
              id: "activated",
            },
          });
        }
        if (this.selectedPlanType === "subscriber") {
          await this.$store.dispatch("brand/updateSubscriptionPlan", params);
        } else {
          //@@
          await this.$store.dispatch("brand/updateBrandSubscriptionNew", param);
        }
        // await this.$store.dispatch("brand/getBrandSubscriptionHistoryList")
        let brand = this.$store.getters["brand/brand"];
        await this.$store.dispatch("brand/getBrand", brand.id);
        this.closeUpdatePlanModal();
        this.selectedPlanType = undefined;
        this.showBasicStartDate = false;
        this.showPremiumDate = false;
      } catch (error) {
        this.showValidation = true;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="sass"></style>
