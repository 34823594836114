<template>
  <div class="admin-training-retailer-view-page">
    <a-breadcrumb :routes="routes" class="admin-training-breadcrumb">
      <template slot="itemRender" slot-scope="{ route, paths }">
        <span v-if="routes.indexOf(route) >= routes.length - 2">
          {{ route.breadcrumbName }}
        </span>
        <router-link v-else :to="`${basePath}/${paths.join('/')}`">
          {{ route.breadcrumbName }}
        </router-link>
      </template>
    </a-breadcrumb>
    <a-tabs class="admin-training-tabs1" size="small" @change="onChangeTab">
      <a-tab-pane key="0" tab="Retailer Locations">
        <AdminTrainingRetailerLocation :retailer="retailerData" :is-loading="isLoading" />
      </a-tab-pane>
      <a-tab-pane key="1" tab="Brands at Retailer">
        <admin-training-retailer-brand :retailer="retailerData" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="Users at Retailer">
        <admin-training-retailer-user :retailer="retailerData" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import AdminTrainingRetailerLocation from "@/components/admin/AdminTrainingRetailerLocation"
import AdminTrainingRetailerBrand from "@/components/admin/AdminTrainingRetailerBrand"
import AdminTrainingRetailerUser from "@/components/admin/AdminTrainingRetailerUser"
export default {
  components: {
    AdminTrainingRetailerLocation,
    AdminTrainingRetailerBrand,
    AdminTrainingRetailerUser
  },
  data() {
    return {
      basePath: "/admin",
      routes: [
        {
          path: "adminTraining",
          breadcrumbName: "Training"
        },
        {
          path: "?tab=1",
          breadcrumbName: "Retailers"
        }
      ],
      retailerId: undefined,
      retailerName: undefined,
      isLoading: false
      // retailerData: undefined
    }
  },
  computed: {
    retailerData() {
      let data = {}
      if (this.retailerId !== "*") {
        data = this.$store.getters["retailer/retailer"]
      }
      console.log(data)
      return data
    }
  },
  watch: {
    retailerData() {
      if (this.retailerId !== "*" && this.retailerData) {
        this.retailerName = this.retailerData.name
      }
      if (this.retailerId === "*") {
        this.retailerName = "General"
      }
      this.routes[2].breadcrumbName = this.retailerName
      this.isLoading = false
    }
  },
  created() {
    this.retailerId = this.$route.params.retailerId
    this.retailerName = this.$route.params.name
    this.routes.push({
      path: "",
      breadcrumbName: this.retailerName
    })
    this.routes.push({
      path: "",
      breadcrumbName: "Retailer Locations"
    })
    this.isLoading = true
    if (this.retailerId !== "*") {
      this.$store.dispatch("retailer/fetchRetailer", this.retailerId)
    }
    this.$store.dispatch("retailer/fetchAllRetailers", {
      params: { fields: "name,id,locations" }
    })
  },
  methods: {
    onChangeTab(key) {
      if (key === "0") {
        this.routes[this.routes.length - 1].breadcrumbName = "Retailer Locations"
      } else if (key === "1") {
        this.routes[this.routes.length - 1].breadcrumbName = "Brand at Retailers"
      } else {
        this.routes[this.routes.length - 1].breadcrumbName = "Retailer Users"
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-training-retailer-view-page {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 60px 40px;
}
</style>
