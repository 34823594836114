import httpclient from "@/api/http-client";
import RetailerTerritory from "../models/RetailerTerritory";

export default {
  endPoint: "/api/retailers",
  mockData: [
    new RetailerTerritory({
      privateId: 1,
      retailerPrivateId: 525,
      code: "08142",
      name: "08142",
    }),
    new RetailerTerritory({
      privateId: 2,
      retailerPrivateId: 525,
      code: "08147",
      name: "08147",
    }),
    new RetailerTerritory({
      privateId: 3,
      retailerPrivateId: 525,
      code: "08151",
      name: "08151",
    }),
    new RetailerTerritory({
      privateId: 4,
      retailerPrivateId: 525,
      code: "08254",
      name: "08254",
    }),
    new RetailerTerritory({
      privateId: 5,
      retailerPrivateId: 525,
      code: "08256",
      name: "08256",
    }),
  ],
  async getTerritories(retailerId, searchTerms) {
    let url = `${this.endPoint}/${retailerId}/territories`;
    if (searchTerms) {
      url += `?searchTerms=${searchTerms}`;
    }
    return await httpclient
      .get(url)
      .then((res) => (res.data || []).map((d) => new RetailerTerritory(d)))
      .catch(() => []);
  },

  async getMockTerritories(retailerId) {
    let privateId = 0;
    if (this.isJCPenny(retailerId)) {
      privateId = 525;
    }
    return Promise.resolve(
      this.mockData.filter((f) => f.retailerPrivateId == privateId)
    );
  },

  isJCPenny(retailerId) {
    return retailerId === "5QwjyvoVT1aOCxoCGCP1Ow";
  },
};
