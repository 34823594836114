<template>
  <a-row style="margin-bottom: 12px;">
    <a-col :span="10" style="padding-left: 28px;">
      <!-- <a-button
        class="admin-console-add-cascade-header__add-new-btn"
        icon="plus"
        @click="createNewItem"
      />
      <a-input
        v-if="showNew"
        ref="console-add-new"
        v-model="newValue"
        type="text"
        size="small"
        class="admin-console-add-cascade-header__add-new-input"
        autofocus="autofocus"
        @blur="showAddConfirm"
        @keyup.enter="showAddConfirm"
      /> -->
    </a-col>
    <a-col :span="14" style="text-align: right;">
      <a-button class="admin-console-add-cascade-header__btn" @click="showAddNewAll">
        <a-icon type="plus" class="admin-console-add-cascade-header__btn-icon" />
        Add
      </a-button>
      <a-dropdown v-if="isShowEditBtn">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="1"> <a-icon type="edit" />Edit All </a-menu-item>
          <a-menu-item key="2"> <a-icon type="delete" />Delete </a-menu-item>
        </a-menu>
        <a-button class="admin-console-add-cascade-header__drop-btn">
          <a-icon type="edit" class="admin-console-add-cascade-header__btn-icon" />
          Edit
        </a-button>
      </a-dropdown>
      <!-- <a-button v-else class="admin-console-add-cascade-header__btn" @click="saveDelete">
        <a-icon type="save" class="admin-console-add-cascade-header__btn-icon" />
        Save
      </a-button> -->
    </a-col>
    <AddNewDialog ref="addNewDialog" @saveNew="addMultiple" />
    <ConfirmDialog
      ref="confirmAdd"
      @doConfirm="handleInputConfirm"
      @cancelDialog="cancelAddConfirm"
    />
    <delete-reference-dialog ref="deleteDialog" @doDelete="saveDelete" />
  </a-row>
</template>
<script>
import AddNewDialog from "@/components/admin/AddNewDialog"
import ConfirmDialog from "@/components/admin/ConfirmDialog"
import DeleteReferenceDialog from "@/components/admin/DeleteReferenceDialog.vue"
export default {
  components: {
    AddNewDialog,
    ConfirmDialog,
    DeleteReferenceDialog
  },
  props: {
    item: { type: Object, default: () => {} },
    isShowEditBtn: { type: Boolean, default: true }
  },
  data() {
    return {
      showNew: false,
      newValue: ""
    }
  },
  methods: {
    //all this operation is for all the items
    addMultiple(newValues) {
      this.$emit("addMultiple", newValues, this.item)
    },
    handleMenuClick(e) {
      if (e.key === "1") {
        this.editAll()
      } else {
        this.showDeleteDialog()
      }
    },
    createNewItem() {
      this.showNew = true
    },
    editAll() {
      this.$emit("editAll")
    },
    showDeleteDialog() {
      this.$refs["deleteDialog"].openDialog(
        this.item.subcategories ? this.item.subcategories : this.item.microCategories
      )
    },
    showAddConfirm() {
      this.$refs["confirmAdd"].openDialogMsg("Are you sure you want to save changes?")
    },
    showAddNewAll() {
      this.$refs["addNewDialog"].openDialog(
        this.item.subcategories ? this.item.subcategories : this.item.microCategories,
        this.item.name
      )
    },
    showDeleteIcon() {
      this.$emit("clickDeleteAll")
    },
    saveDelete(data) {
      this.$emit("saveDelete", data)
    },
    handleInputConfirm() {
      this.$emit("addNewItem", this.newValue, this.item)
      this.showNew = false
      this.newValue = ""
    },
    cancelAddConfirm() {
      this.showNew = false
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-console-add-cascade-header {
  &__add-new-btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    background: #4a8a5d;
    color: #fff;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
  }
  &__btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    background: #4a8a5d;
    color: #fff;
    padding: 0px 8px !important;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
    height: auto !important;
  }
  &__drop-btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #fff;
    background: #4a8a5d;
    padding: 0px 20px !important;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
    height: auto !important;
  }
  &__btn-icon {
    vertical-align: middle;
    line-height: normal;
    margin-bottom: 4px;
  }
  &__add-new-input {
    background: #ffffff;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 2px;
    width: 100px;
    height: 32px;
  }
}
.admin-console-add-cascade-header__btn:hover,
.admin-console-add-cascade-header__btn:focus,
.admin-console-add-cascade-header__btn:active,
.admin-console-add-cascade-header__btn.active,
.admin-console-add-cascade-header__drop-btn:hover,
.admin-console-add-cascade-header__drop-btn:focus,
.admin-console-add-cascade-header__drop-btn:active,
.admin-console-add-cascade-header__drop-btn.active {
  background-color: #e2eeec;
}
.admin-console-add-cascade-header__btn:hover,
.admin-console-add-cascade-header__btn:focus,
.admin-console-add-cascade-header__drop-btn:hover,
.admin-console-add-cascade-header__drop-btn:focus {
  color: #4a8a5d;
}
</style>
