<template>
  <div ref="brandTrainingStepThree" class="brand-training-retailer-add-Step2-dialog__main">
    <a-modal
      v-model="showModal"
      width="806px"
      :footer="null"
      :get-container="() => $refs.brandTrainingStepThree"
      @cancel="closeDialog"
    >
      <p slot="title" class="modal-title-location">Step 3. Order Review</p>
      <p class="step-content-text">
        You have the option of being monthly, quarterly, or annually. Please select one and we'll be
        charging the card on file based on your selection.
      </p>
      <a-table
        :columns="columns"
        :data-source="retailerInfoList"
        :pagination="false"
        :get-popup-container="() => $refs.addNewRetailerPart1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>
      <div >
        <!-- <div class="terms-condition-title-style">
          <p class="terms-title">Terms & Conditions</p>
          <p>
            <span style="cursor: pointer" @click="downloadTermsPdf">Download & Print</span>
                       <a-icon type="download" style="margin-right: 7px" @click="downloadTermsPdf" />
                       <a-icon type="printer" theme="filled" />
          </p>
        </div>
        <div id="termsCondition" class="terms-condition-style">
          <Terms></Terms>
        </div> -->
        <p class="agree-terms-condition">
          <a-checkbox v-model="checkboxValue">
            I agree to the <a class="nav-link" href="https://www.landinginternational.com/terms-of-use/" target="_blank">Terms & Conditions</a> and <a class="nav-link" href="https://www.landinginternational.com/privacy-policy" target="_blank">Privacy Policy</a>
          </a-checkbox>
        </p>
      </div>
      <div class="button-step-style">
        <a-button class="cancel-button-style" @click="backToDialog">Back</a-button>
        <a-button
          class="next-button-style"
          style="margin-left: 15px"
          :disabled="!checkboxValue"
          @click="nextDialog"
          >Purchase</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
// import Terms from "@/views/Terms"
import html2Canvas from "html2canvas"
import JsPDF from "jspdf"
export default {
  name: "BrandTrainingRetailerAddStep3Dialog",
  // components: {
  //   Terms
  // },
  data() {
    return {
      showModal: false,
      checkboxValue: false,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "Locations",
          dataIndex: "locationNumber",
          key: "locationNumber"
        },
        {
          title: "Territory",
          dataIndex: "territory",
          key: "territory"
        },
        {
          title: "Payment",
          dataIndex: "payment",
          key: "payment"
        },
        {
          title: "Price",
          dataIndex: "amount",
          key: "amount"
        }
      ],
      retailerInfoList: []
    }
  },
  methods: {
    openDialog(data, type) {
      this.showModal = true
      if (type === "monthly") {
        this.retailerInfoList = data.map(item => {
          item.amount = `$${item.trainingPrices[0].monthlyPrice || 0}`
          item.priceNumber = `${item.trainingPrices[0].monthlyPrice || 0}`
          item.payment = type
          return item
        })
      } else if (type === "annually") {
        this.retailerInfoList = data.map(item => {
          item.amount = `$${item.trainingPrices[0].annuallyPrice || 0}`
          item.priceNumber = `${item.trainingPrices[0].annuallyPrice || 0}`
          item.payment = type
          return item
        })
      } else {
        this.retailerInfoList = data.map(item => {
          item.amount = `$${item.trainingPrices[0].quarterlyPrice || 0}`
          item.priceNumber = `${item.trainingPrices[0].quarterlyPrice || 0}`
          item.payment = type
          return item
        })
      }
    },
    closeDialog() {
      this.showModal = false
    },
    nextDialog() {
      if (this.checkboxValue) {
        this.$emit("updatePaymentTrainingSubscription", this.retailerInfoList)
      }
    },
    backToDialog() {
      this.$emit("backToDialog")
    },
    downloadTermsPdf() {
      const linkUrl =
        "https://s3.us-west-2.amazonaws.com/com.landingintl.assets-us-west-2/Landing+Mobile+App+Terms+%26+Conditions.pdf"
      window.open(linkUrl)
    },
    downloadTerms() {
      let elementId
      const title = "terms&conditions"
      // const btns = document.getElementsByClassName("print-download-order-detail-special")
      elementId = document.getElementById("termsCondition")
      // if (btns && btns.length > 0) {
      //   for (let i = 0; i < btns.length; i++) {
      //     btns[i].style.display = "none"
      //   }
      // }
      html2Canvas(elementId, {
        allowTaint: true,
        useCORS: true,
        logging: true,
        // height: document.getElementById("6orderDetailMain").scrollHeight,
        // width: document.getElementById("orderDetailMain").scrollWidth,
        background: "#FFFFFF"
      })
        .then(function(canvas) {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          let pageHeight = (contentWidth / 592.28) * 841.89 - 100
          let leftHeight = contentHeight
          let position = 0
          let imgWidth = 595.28
          let imgHeight = (592.28 / contentWidth) * contentHeight
          let pageData = canvas.toDataURL("image/jpeg", 1.0)
          let PDF = new JsPDF("", "pt", "a4")
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight)
          } else {
            while (leftHeight > 0) {
              PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight)
              leftHeight -= pageHeight
              position -= 840
              if (leftHeight > 0) {
                PDF.addPage()
              }
            }
          }
          PDF.save(title + ".pdf")
        })
        .finally(() => {
          // if (btns && btns.length > 0) {
          //   for (let i = 0; i < btns.length; i++) {
          //     btns[i].style.display = "block"
          //   }
          // }
        })
    }
  }
}
</script>

<style scoped lang="scss">
.brand-training-retailer-add-Step2-dialog__main {
  p {
    margin-bottom: 0;
  }
  .step-content-text {
    padding-bottom: 20px;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .terms-condition-style {
    padding: 20px 0;
  }
  .button-step-style {
    text-align: right;
    .cancel-button-style {
      width: 160px;
      color: white;
      background-color: rgba(242, 124, 89, 1);
    }
    .next-button-style {
      width: 160px;
      color: white;
      background-color: rgba(145, 211, 149, 1);
    }
  }
  .terms-condition-title-style {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .terms-title {
      width: 60%;
      font-weight: bold;
      text-align: right;
    }
    span:hover {
      text-decoration: underline;
      color: #4a8a5d;
    }
  }
  .terms-condition-style {
    width: 100%;
    height: 200px;
    overflow: auto;
    border: 1px solid #e2dfdf;
  }
  .agree-terms-condition {
    text-align: right;
    // padding: 15px 0;
  }
  .nav-link {
    color: rgba(0,0,0,0.6);
    text-decoration: underline;
  }
}
@media print {
  .terms-condition-style {
    width: 100%;
    height: auto;
  }
}
</style>
