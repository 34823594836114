import Vue from "vue";
import Vuex from "vuex";

import asset from "./asset";
import admin from "./admin";
import brand from "./brand";
import retailer from "./retailer";
import retailerAddress from "./retailer-address";
import brandAddress from "./brand-address";
import product from "./product";
import user from "./user";
import reference from "./reference";
import order from "./order";
import retailerMargin from "./retailer-margin";
import brandRetailer from "./brand-retailer";
import brandPayment from "./brand-payment";
import guest from "./guest";
import cleanPreCheck from "./clean-precheck";
import training from "./training";
import inventory from "./inventory";
import adminConsole from "./admin-console";
import clientUser from "./client-user";
import adminMessage from "./admin-message";
import seller from "./sellers";
import adminTrainingConsole from "./admin-training-console/module";
import orders from "./orders/module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin,
    asset,
    brand,
    retailer,
    product,
    user,
    reference,
    order,
    retailerAddress,
    brandAddress,
    retailerMargin,
    retailerSearch: brandRetailer,
    brandRetailer,
    brandPayment,
    guest,
    training,
    cleanPreCheck,
    inventory,
    adminConsole,
    clientUser,
    adminMessage,
    seller,
    adminTrainingConsole,
    orders,
  },
});
