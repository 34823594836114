<template>
  <div class="retailers-right-box">
    <div class="retailer-profile__card-shadowed">
      <div class="retailer-profile__title">
        {{ $t("Retailers.RetailerProfile.terms") }}
      </div>
      <div class="retailer-profile__border"></div>
      <div class="retailer-profile__details">
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.paymentTerms") }}
          </span>
          <span>
            <RetailerPopup
              :text="$t('Retailers.RetailerProfile.brandretailerToolTip.paymentterm')"
            />
          </span>
          <div class="retailer-profile__content">
            {{ paymentTermName(retailer.terms.paymentTerm.id) }}
          </div>
        </div>
        <div v-if="role === 'retailer'" class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.margin") }}
          </span>
          <span>
            <RetailerPopup :text="$t('Retailers.RetailerProfile.brandretailerToolTip.margin')" />
          </span>
          <div class="retailer-profile__content">
            {{ retailer.terms && retailer.terms.margin ? retailer.terms.margin + "%" : "" }}
          </div>
        </div>
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.marketingCoop") }}
          </span>
          <span>
            <RetailerPopup :text="$t('Retailers.RetailerProfile.brandretailerToolTip.marketing')" />
          </span>
          <div v-if="retailer.terms.marketingCoOp" class="retailer-profile__content">
            {{
              retailer.terms && retailer.terms.marketingCoOp
                ? retailer.terms.marketingCoOp + "%"
                : " "
            }}
          </div>
        </div>
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.damagesBudget") }}
          </span>
          <span>
            <RetailerPopup :text="$t('Retailers.RetailerProfile.brandretailerToolTip.damage')" />
          </span>
          <div v-if="retailer.terms.damagesBudget" class="retailer-profile__content">
            {{
              retailer.terms && retailer.terms.damagesBudget
                ? `${retailer.terms.damagesBudget}%`
                : " "
            }}
          </div>
        </div>
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            Testers
          </span>
          <!--          <span>-->
          <!--            <RetailerPopup :text="$t('Retailers.RetailerProfile.brandretailerToolTip.damage')" />-->
          <!--          </span>-->
          <div v-if="retailer.terms.testers" class="retailer-profile__content">
            {{ retailer.terms && retailer.terms.testers ? `${retailer.terms.testers}%` : " " }}
          </div>
        </div>
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.compliance") }}
          </span>
          <span>
            <RetailerPopup
              :text="$t('Retailers.RetailerProfile.brandretailerToolTip.compliance')"
            />
          </span>
          <div class="retailer-profile__content">{{ requirementsString }}</div>
        </div>
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.acceptsOverseasShipment") }}
          </span>
          <span>
            <RetailerPopup :text="$t('Retailers.RetailerProfile.brandretailerToolTip.oversea')" />
          </span>
          <div v-if="retailer.terms.acceptsOverseasFreight" class="retailer-profile__content">
            {{ $t("Brands.BrandSettings.yes") }}
          </div>
          <div v-else class="retailer-profile__content">{{ $t("Brands.BrandSettings.no") }}</div>
        </div>
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.usShipping") }}
          </span>
          <span>
            <RetailerPopup
              :text="$t('Retailers.RetailerProfile.brandretailerToolTip.usshipping')"
            />
          </span>
          <div class="retailer-profile__content">
            {{
              retailer.terms && retailer.terms.usDomesticShippingObligation
                ? retailer.terms.usDomesticShippingObligation
                : " "
            }}
          </div>
        </div>
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.testers") }}
          </span>
          <span>
            <RetailerPopup :text="$t('Retailers.RetailerProfile.brandretailerToolTip.testers')" />
          </span>
          <div class="retailer-profile__content">
            {{
              retailer.terms && retailer.terms.testersObligation
                ? retailer.terms.testersObligation
                : " "
            }}
          </div>
        </div>
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.fixtures") }}
          </span>
          <span>
            <RetailerPopup :text="$t('Retailers.RetailerProfile.brandretailerToolTip.fixtures')" />
          </span>
          <div class="retailer-profile__content">
            {{
              retailer.terms && retailer.terms.fixturesObligation
                ? retailer.terms.fixturesObligation
                : " "
            }}
          </div>
        </div>
        <div class="retailer-profile__details--container">
          <span class="retailer-profile__details--subtitle">
            {{ $t("Retailers.RetailerProfile.signage") }}
          </span>
          <span>
            <RetailerPopup :text="$t('Retailers.RetailerProfile.brandretailerToolTip.signage')" />
          </span>
          <div class="retailer-profile__content">
            {{
              retailer.terms && retailer.terms.signageObligation
                ? retailer.terms.signageObligation
                : " "
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RetailerPopup from "@/components/retailers/profile/RetailerPopup"

export default {
  components: {
    RetailerPopup
  },
  props: {
    retailer: {
      type: Object,
      default: () => {}
    },
    role: { type: String, default: () => "" }
  },
  computed: {
    requirementsString() {
      const ticketingRequiredString =
        this.retailer.terms.productTicketingRequired && "Product Ticketing"
      const ediRequired = this.retailer.terms.ediRequired && "Retailer EDI"
      const routingGuideRequired = this.retailer.terms.routingGuideRequired && "Routing Guide"
      if (ticketingRequiredString || ediRequired || routingGuideRequired)
        return [ticketingRequiredString, ediRequired, routingGuideRequired]
          .filter(requirement => requirement)
          .join(" ")
      else return " "
    }
  },
  methods: {
    paymentTermName(id) {
      return this.$store.getters["reference/paymentTermName"](id)
    }
  }
}
</script>

<style scoped lang="sass">
.retailer-profile
  color: #000000
  padding: 0 40px
  font-family: SF Pro Display
  font-style: normal
  font-weight: normal
  &__card-shadowed
    -webkit-box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
    border-radius: 2px
    padding: 20px 20px 38px 13px
  .container
    position: relative
    width: auto !important
    padding-left: 25px
    margin-top: 10px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
  &__border
    max-width: 201px !important
    border-bottom: 1px solid #E8E8E8
    margin-bottom: 1px
  &__title
    font-family: Proxima Nova
    font-size: 24px !important
    font-weight: normal !important
    line-height: 24px !important
    color: #262626 !important
    margin-bottom: 13px

  &__content
    color: #8C8C8C
    width: 120px
    white-space: pre-wrap

  &__details
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    line-height: 22px
    font-size: 14px

    &--container
      margin-top: 15px
      margin-left: 3px
    &--title
      font-size: 18px
      letter-spacing: 0.25px

      &_text
        margin-top: 20px
        font-weight: 300

    &--subtitle
      color: #262626

      &_link
        text-decoration: underline

    &--edit-icon
      float: right
      cursor: pointer

    &--info-container
      padding-top: 25px
      font-size: 14px
      letter-spacing: 0.13px
</style>
