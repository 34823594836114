<template>
  <a-row class="brand-user-profile-page">
    <a-row class="header-content" :span="24">
      <HeadCard
        :head-info="brand"
        :headquarters="headquarters"
        :tags="brand.curations"
        :role="role"
        @showEditModal="showEditModal"
      />
    </a-row>
    <a-row
      class="content-text-info"
      :span="24"
      :social-accounts-for-api="socialAccountsForApi"
    >
      <a-col class="left-right-item-padding" :span="16">
        <OverView
          :retailer="brand"
          :video-intro-assets="introVideoAssets"
          :headquarters="headquarters"
          :is-retailer="false"
        />
        <ProfileCategories
          class="top-margin"
          :is-retailer="false"
          :categories-for-api="brand.productCategories"
        />
      </a-col>
      <a-col :span="8">
        <BrandProfileRight
          :title="$t('Components.Brands.Profile.BrandProfile.keyRetailers')"
          :data="brand.keyRetailers"
        />
        <BrandProfileRight
          class="right-item-top-margin"
          :title="$t('Components.Brands.Profile.BrandProfile.heroProducts')"
          :data="brand.heroProducts"
        />
        <BrandProfileRight
          class="right-item-top-margin"
          :title="$t('Components.Brands.Profile.BrandProfile.brandValues')"
          :data="brand.brandValues"
        />
      </a-col>
    </a-row>
    <div
      v-if="isEditModalOpen"
      class="modal"
      :class="{ 'is-active': isEditModalOpen }"
    >
      <div class="modal-background"></div>
      <div v-if="isEditModalOpen" class="modal-card brand-profile__edit-modal">
        <header class="modal-card-head">
          <p class="modal-card-title brand-profile__edit-modal--header">
            {{ $t("Components.Brands.Profile.BrandProfile.editProfile") }}
          </p>
          <img
            class="brand-profile__edit-modal--header_close-icon"
            src="@/assets/img/icon/close-icon.png"
            @click="closeModal()"
          />
        </header>
        <section class="modal-card-body">
          <div class="columns brand-profile__edit-modal--container">
            <div class="column is-4 brand-profile__edit-modal--menu">
              <ul class="brand-profile__edit-modal-sticky-menu">
                <li>
                  <a
                    :class="{ 'is-selected': isMenuItem('overview') }"
                    @click.prevent="toggleMenuItem('overview')"
                  >
                    {{ $t("Components.Brands.Profile.BrandProfile.overview") }}
                  </a>
                </li>
                <li>
                  <a
                    :class="{ 'is-selected': isMenuItem('keyRetailers') }"
                    @click.prevent="toggleMenuItem('keyRetailers')"
                  >
                    {{
                      $t("Components.Brands.Profile.BrandProfile.keyRetailers")
                    }}
                  </a>
                </li>
                <li>
                  <a
                    :class="{ 'is-selected': isMenuItem('heroProducts') }"
                    @click.prevent="toggleMenuItem('heroProducts')"
                  >
                    {{
                      $t("Components.Brands.Profile.BrandProfile.heroProducts")
                    }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="column brand-profile__edit-modal--form">
              <div
                v-show="currentMenuItem === 'overview'"
                class="brand-profile__edit-modal--form_photos-verview"
              >
                <BannerImageCropper
                  :initial-banner-asset-url="brand.bannerAsset.url"
                  :client-id="brand.id"
                  @bannerAssetUpdated="updateBannerAsset"
                  @imageSaving="imageSaving"
                />
                <LogoImageCropper
                  :initial-logo-asset-url="brand.logoAsset.url"
                  :client-id="brand.id"
                  @logoAssetUpdated="updateLogoAsset"
                  @imageSaving="imageSaving"
                />
                <div class="field">
                  <div class="control">
                    <label class="brand-profile__edit-modal--form_label">
                      {{
                        $t(
                          "Components.Brands.Profile.BrandProfile.introduction"
                        )
                      }}
                    </label>
                    <span>
                      <BaseFormPopup
                        :text="
                          $t(
                            'Components.Brands.Profile.BrandProfile.introductionToolTip'
                          )
                        "
                      />
                    </span>
                    <input
                      v-model="$v.brandInitial.introduction.$model"
                      class="input"
                      :class="{
                        'is-danger':
                          $v.brandInitial.introduction.$error ||
                          introductionLengthExceeds,
                      }"
                      :placeholder="
                        $t(
                          'Components.Brands.Profile.BrandProfile.introductionPlaceholder1'
                        ) +
                          ' ' +
                          brandInitial.name +
                          ' ' +
                          $t(
                            'Components.Brands.Profile.BrandProfile.introductionPlaceholder2'
                          )
                      "
                      @input="introductionLengthExceeds = false"
                      @keyup="charCount()"
                    />
                    <span
                      v-if="!introductionLengthExceeds"
                      class="remaining-characters"
                    >
                      {{ remaincharactersText }}
                    </span>
                    <p
                      v-if="$v.brandInitial.introduction.$error"
                      class="help is-danger"
                    >
                      {{
                        $t(
                          "Components.Brands.Profile.BrandProfile.Validations.introductionIsRequired"
                        )
                      }}
                    </p>
                    <p v-if="introductionLengthExceeds" class="help is-danger">
                      {{
                        $t(
                          "Components.Brands.Profile.BrandProfile.Validations.characterExceeds100"
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="brand-profile__edit-modal--form_label">
                      {{ $t("Components.Brands.Profile.BrandProfile.aboutUs") }}
                    </label>
                    <span>
                      <BaseFormPopup
                        :text="
                          $t(
                            'Components.Brands.Profile.BrandProfile.aboutUsToolTip'
                          )
                        "
                      />
                    </span>
                    <textarea
                      v-model="$v.brandInitial.about.$model"
                      class="textarea brand-profile__edit-modal--form_textarea"
                      :class="{ 'is-danger': $v.brandInitial.about.$error }"
                      :placeholder="
                        $t(
                          'Components.Brands.Profile.BrandProfile.aboutUsPlaceHolder1'
                        ) +
                          ' ' +
                          brandInitial.name +
                          ' ' +
                          $t(
                            'Components.Brands.Profile.BrandProfile.aboutUsPlaceHolder2'
                          )
                      "
                    />
                    <p
                      v-if="$v.brandInitial.about.$error"
                      class="help is-danger"
                    >
                      {{
                        $t(
                          "Components.Brands.Profile.BrandProfile.Validations.aboutIsRequired"
                        )
                      }}
                    </p>
                  </div>
                </div>
                <InputWithPrefix
                  :initial-value="brandInitial.website"
                  :is-social-account="false"
                  :placeholder="
                    $t('Components.Brands.Profile.BrandProfile.domain')
                  "
                  prefix="http://www."
                  prefix-offset="80px"
                  label="Website"
                  @input="brandInitial.website = $event"
                />
                <InputWithPrefix
                  v-for="(socialAccount, index) in socialAccounts"
                  :key="index"
                  :initial-value="socialAccount.address"
                  :is-social-account="true"
                  :placeholder="
                    $t('Components.Brands.Profile.BrandProfile.yourUsername')
                  "
                  :prefix="`http://www.${socialAccount.platform.id}.com/`"
                  :prefix-offset="offset(socialAccount.platform.id)"
                  :label="socialAccount.platform.name"
                  @input="socialAccountAddress($event, socialAccount)"
                />
                <div class="field">
                  <div class="control">
                    <label class="brand-profile__edit-modal--form_label">
                      {{
                        $t("Components.Brands.Profile.BrandProfile.companySize")
                      }}
                    </label>
                    <span>
                      <BaseFormPopup
                        :text="
                          $t(
                            'Components.Brands.Profile.BrandProfile.companySizeToolTip'
                          )
                        "
                      />
                    </span>
                    <div class="select is-fullwidth">
                      <select
                        v-if="brandInitial.size"
                        v-model="brandInitial.size.id"
                      >
                        <option value="">N/A</option>
                        <option
                          v-for="clientSize in clientSizes"
                          :key="clientSize.id"
                          :value="clientSize.id"
                        >
                          {{ clientSize.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="brand-profile__edit-modal--form_label">
                      {{
                        $t("Components.Brands.Profile.BrandProfile.yearFounded")
                      }}
                    </label>
                    <div class="select is-fullwidth">
                      <select v-model="brandInitial.foundingDate">
                        <option
                          v-for="(year, index) in years"
                          :key="index"
                          :value="year"
                        >
                          {{ year }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="field form-section-field">
                  <label class="brand-profile__edit-modal--form_label">
                    {{ $t("Components.Brands.Profile.BrandProfile.curation") }}
                  </label>
                  <div class="control">
                    <multi-select
                      v-model="brandInitial.curations"
                      :options="sellSideCuration"
                      :multiple="true"
                      label="id"
                      track-by="id"
                      :close-on-select="true"
                      :custom-label="tagNameById"
                      open-direction="bottom"
                      @remove="removeCuration"
                    />
                  </div>
                </div>
                <div class="field form-section-field">
                  <label class="brand-profile__edit-modal--form_label">
                    {{ $t("Components.Brands.Profile.BrandProfile.uploadPdf") }}
                  </label>
                  <div class="control">
                    <a-upload-dragger
                      :file-list="fileList"
                      :custom-request="doUpload"
                      :before-upload="beforeUploadFile"
                      :multiple="false"
                      :remove="removePdf"
                      @change="handleChange"
                    >
                      <div class="ant-upload-drag-icon">
                        <span class="upload-pdf-btn"
                          ><a-icon type="upload" /> Upload File
                        </span>
                      </div>
                      <p class="ant-upload-text" style="margin-top: 10px;">
                        Click or drag file to this area to upload
                      </p>
                    </a-upload-dragger>
                  </div>
                </div>
                <div class="field form-section-field">
                  <label class="brand-profile__edit-modal--form_label">
                    {{
                      $t(
                        "Components.Brands.Profile.BrandProfile.uploadBrandVideoImg"
                      )
                    }}
                  </label>
                  <div class="control">
                    <a-upload-dragger
                      :file-list="VideoImgFileList"
                      :custom-request="handleVideoImgChange"
                      :before-upload="beforeUploadVideoImgFile"
                      :multiple="false"
                      :remove="removeVideoImg"
                      @change="handleVideoImgChange"
                    >
                      <div class="ant-upload-drag-icon">
                        <span class="upload-pdf-btn"
                          ><a-icon type="upload" /> Upload File
                        </span>
                      </div>
                      <p class="ant-upload-text" style="margin-top: 10px;">
                        Click or drag file to this area to upload
                      </p>
                      <p v-if="isLoading">
                        <a-progress :percent="progress" status="active" />
                      </p>
                    </a-upload-dragger>
                  </div>
                </div>
                <div class="brand-profile__edit-modal--form_section">
                  <div class="brand-profile__edit-modal--form_section-label">
                    {{
                      $t("Components.Brands.Profile.BrandProfile.headquarters")
                    }}
                  </div>
                  <div
                    v-for="hq in $v.headquarterAddresses.$each.$iter"
                    :key="hq.createdAt"
                    class="hq"
                  >
                    <div class="field">
                      <div class="control">
                        <label class="brand-profile__edit-modal--form_label">
                          {{
                            $t(
                              "Components.Brands.Profile.BrandProfile.streetAddress"
                            )
                          }}
                        </label>
                        <input
                          v-model="hq.streetAddressLine_1.$model"
                          class="input"
                          :class="{
                            'is-danger': hq.streetAddressLine_1.$error,
                          }"
                        />
                        <p
                          v-if="hq.streetAddressLine_1.$error"
                          class="help is-danger"
                        >
                          {{
                            $t(
                              "Components.Brands.Profile.BrandProfile.Validations.streetAddressRequired"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="field">
                      <div class="control">
                        <label class="brand-profile__edit-modal--form_label">
                          {{
                            $t(
                              "Components.Brands.Profile.BrandProfile.aptSuiteBldg"
                            )
                          }}
                        </label>
                        <input
                          v-model="hq.streetAddressLine_2.$model"
                          class="input"
                        />
                      </div>
                    </div>
                    <div
                      class="columns is-paddingless brand-profile__edit-modal--form_address"
                    >
                      <div class="column">
                        <div class="control">
                          <label class="brand-profile__edit-modal--form_label">
                            {{
                              $t("Components.Brands.Profile.BrandProfile.city")
                            }}
                          </label>
                          <input
                            v-model="hq.city.$model"
                            class="input"
                            :class="{ 'is-danger': hq.city.$error }"
                          />
                          <p v-if="hq.city.$error" class="help is-danger">
                            {{
                              $t(
                                "Components.Brands.Profile.BrandProfile.Validations.cityRequired"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="column">
                        <div class="control">
                          <label class="brand-profile__edit-modal--form_label">
                            {{
                              $t(
                                "Components.Brands.Profile.BrandProfile.region"
                              )
                            }}
                          </label>
                          <input
                            v-model="hq.region.$model"
                            class="input"
                            :class="{ 'is-danger': hq.region.$error }"
                          />
                          <p v-if="hq.region.$error" class="help is-danger">
                            {{
                              $t(
                                "Components.Brands.Profile.BrandProfile.Validations.regionRequired"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="column">
                        <div class="control">
                          <label class="brand-profile__edit-modal--form_label">
                            {{
                              $t(
                                "Components.Brands.Profile.BrandProfile.zipCode"
                              )
                            }}
                          </label>
                          <input
                            v-model="hq.postalCode.$model"
                            class="input"
                            :class="{ 'is-danger': hq.postalCode.$error }"
                          />
                          <p v-if="hq.postalCode.$error" class="help is-danger">
                            {{
                              $t(
                                "Components.Brands.Profile.BrandProfile.Validations.zipCodeRequired"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="field form-section-field">
                      <div class="control">
                        <label class="brand-profile__edit-modal--form_label">
                          {{
                            $t("Components.Brands.Profile.BrandProfile.country")
                          }}
                        </label>
                        <div class="select is-fullwidth">
                          <select v-model="hq.country.id.$model">
                            <option
                              v-for="country in countries"
                              :key="country.id"
                              :value="country.id"
                            >
                              {{ country.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="brand-profile__edit-modal--form_section">
                  <div class="brand-profile__edit-modal--form_section-label">
                    {{
                      $t(
                        "Components.Brands.Profile.BrandProfile.domesticWarehouseLocation"
                      )
                    }}
                  </div>
                  <div
                    v-for="(warehouse, index) in addressEdit.filter(
                      (address) => address.defaultShipping && !address.hq
                    )"
                    :key="index"
                    class="hq"
                  >
                    <div class="field">
                      <div class="control">
                        <label class="brand-profile__edit-modal--form_label">
                          {{
                            $t(
                              "Components.Brands.Profile.BrandProfile.streetAddress"
                            )
                          }}
                        </label>
                        <input
                          v-model="warehouse.streetAddressLine_1"
                          class="input"
                        />
                      </div>
                    </div>
                    <div class="field">
                      <div class="control">
                        <label class="brand-profile__edit-modal--form_label">
                          {{
                            $t(
                              "Components.Brands.Profile.BrandProfile.aptSuiteBldg"
                            )
                          }}
                        </label>
                        <input
                          v-model="warehouse.streetAddressLine_2"
                          class="input"
                        />
                      </div>
                    </div>
                    <div
                      class="columns is-paddingless brand-profile__edit-modal--form_address"
                    >
                      <div class="column">
                        <div class="control">
                          <label class="brand-profile__edit-modal--form_label">
                            {{
                              $t("Components.Brands.Profile.BrandProfile.city")
                            }}
                          </label>
                          <input v-model="warehouse.city" class="input" />
                        </div>
                      </div>
                      <div class="column">
                        <div class="control">
                          <label class="brand-profile__edit-modal--form_label">
                            {{
                              $t(
                                "Components.Brands.Profile.BrandProfile.region"
                              )
                            }}
                          </label>
                          <input v-model="warehouse.region" class="input" />
                        </div>
                      </div>
                      <div class="column">
                        <div class="control">
                          <label class="brand-profile__edit-modal--form_label">
                            {{
                              $t(
                                "Components.Brands.Profile.BrandProfile.zipCode"
                              )
                            }}
                          </label>
                          <input v-model="warehouse.postalCode" class="input" />
                        </div>
                      </div>
                    </div>
                    <div class="field form-section-field">
                      <div class="control">
                        <label class="brand-profile__edit-modal--form_label">
                          {{
                            $t("Components.Brands.Profile.BrandProfile.country")
                          }}
                        </label>
                        <div class="select is-fullwidth">
                          <select v-model="warehouse.country.id">
                            <option
                              v-for="country in countries"
                              :key="country.id"
                              :value="country.id"
                            >
                              {{ country.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="brand-profile__edit-modal--form_label">
                      {{
                        $t(
                          "Components.Brands.Profile.BrandProfile.minimumOrderQuantity"
                        )
                      }}
                    </label>
                    <input
                      v-model="brandInitial.terms.minimumOrderQuantity"
                      v-mask="'#######'"
                      class="input"
                      :placeholder="
                        $t('Components.Brands.Profile.BrandProfile.value100')
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                v-show="currentMenuItem === 'keyRetailers'"
                class="brand-profile__edit-modal--form_terms"
              >
                <div
                  v-for="(keyRetailer, index) in brandInitial.keyRetailers"
                  :key="index"
                  class="field"
                >
                  <div class="control">
                    <label class="brand-profile__edit-modal--form_label">
                      {{
                        $t("Components.Brands.Profile.BrandProfile.keyRetailer")
                      }}
                      {{ index + 1 }}
                    </label>
                    <span>
                      <BaseFormPopup
                        :text="
                          $t(
                            'Components.Brands.Profile.BrandProfile.keyRetailersToolTip'
                          )
                        "
                      />
                    </span>
                    <span v-if="index !== 0">
                      <a
                        class="brand-profile__edit-modal--form_link"
                        @click="removeKeyRetailer(index)"
                      >
                        {{
                          $t(
                            "Components.Brands.Profile.BrandProfile.deleteRetailer"
                          )
                        }}
                      </a>
                    </span>
                    <input
                      v-model.trim="keyRetailer.name"
                      class="input"
                      :placeholder="
                        $t(
                          'Components.Brands.Profile.BrandProfile.keyRetialerPlaceHolder'
                        )
                      "
                    />
                  </div>
                </div>
                <div class="has-text-right">
                  <a class="button" @click="addKeyRetailer()">
                    {{
                      $t(
                        "Components.Brands.Profile.BrandProfile.addAnotherRetailer"
                      )
                    }}
                  </a>
                </div>
              </div>
              <div
                v-show="currentMenuItem === 'heroProducts'"
                class="brand-profile__edit-modal--form_terms"
              >
                <div
                  v-for="(heroProduct, index) in brandInitial.heroProducts"
                  :key="index"
                  class="field"
                >
                  <div class="control">
                    <label class="brand-profile__edit-modal--form_label">
                      {{
                        $t("Components.Brands.Profile.BrandProfile.heroProduct")
                      }}
                      {{ index + 1 }}
                    </label>
                    <span>
                      <BaseFormPopup
                        :text="
                          $t(
                            'Components.Brands.Profile.BrandProfile.heroProductsToolTip'
                          )
                        "
                      />
                    </span>
                    <span v-if="index !== 0">
                      <a
                        class="brand-profile__edit-modal--form_link"
                        @click="removeHeroProduct(index)"
                      >
                        {{
                          $t(
                            "Components.Brands.Profile.BrandProfile.deleteProduct"
                          )
                        }}
                      </a>
                    </span>
                    <input
                      v-model.trim="heroProduct.name"
                      class="input"
                      :placeholder="
                        $t(
                          'Components.Brands.Profile.BrandProfile.enterProductName'
                        )
                      "
                    />
                  </div>
                </div>
                <div class="has-text-right">
                  <a class="button" @click="addHeroProduct()">
                    {{
                      $t(
                        "Components.Brands.Profile.BrandProfile.addAnotherProduct"
                      )
                    }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot brand-profile__edit-modal-form-buttons">
          <div class="columns">
            <div class="column is-8 brand-profile__edit-modal-form-messages">
              <div
                v-show="isSaving && currentMenuItem === 'overview'"
                class="has-text-left"
              >
                {{
                  $t(
                    "Components.Brands.Profile.BrandProfile.Validations.uploadingImage"
                  )
                }}
              </div>
              <div
                v-show="isSaved && currentMenuItem === 'overview'"
                class="has-text-left"
              >
                {{
                  $t(
                    "Components.Brands.Profile.BrandProfile.Validations.successfullyUploaded"
                  )
                }}
              </div>
              <div
                v-show="savingError && currentMenuItem === 'overview'"
                class="has-text-left brand-profile__edit-modal-error-message"
              >
                {{
                  $t(
                    "Components.Brands.Profile.BrandProfile.Validations.imageSizeValidation"
                  )
                }}
              </div>
              <div
                v-if="
                  currentMenuItem == 'overview' &&
                    ($v.brandInitial.$anyError ||
                      $v.headquarterAddresses.$anyError)
                "
                class="has-text-left error-message"
              >
                {{
                  $t(
                    "Components.Brands.Profile.BrandProfile.Validations.required"
                  )
                }}
              </div>
              <div
                v-if="
                  currentMenuItem !== 'overview' &&
                    ($v.brandInitial.$anyError ||
                      $v.headquarterAddresses.$anyError)
                "
                class="has-text-left error-message"
              >
                {{
                  $t(
                    "Components.Brands.Profile.BrandProfile.Validations.overviewTabIncomplete"
                  )
                }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-right">
                <a class="button" @click="closeModal()">
                  {{ $t("Components.Brands.Profile.BrandProfile.cancel") }}
                </a>
                <a
                  class="button is-black brand-profile__edit-modal-form-save-button"
                  :class="{ 'disable-save': isSaving }"
                  :disabled="isSaving"
                  @click="update()"
                >
                  {{ $t("Components.Brands.Profile.BrandProfile.save") }}
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </a-row>
</template>

<script>
import Multiselect from "vue-multiselect";
import { Row, Col } from "ant-design-vue";
import HeadCard from "@/components/common/IdentityCard";
import OverView from "@/components/common/OverView";
import ProfileCategories from "@/components/common/ProfileCategories";
import BrandProfileRight from "@/components/brands/profile/BrandProfileRightItem";
import firebase from "@/services/firebase-service";
import BannerImageCropper from "@/components/cropper/BannerImageCropper";
import LogoImageCropper from "@/components/cropper/LogoImageCropper";
import InputWithPrefix from "@/components/InputWithPrefix";
import BaseFormPopup from "@/components/brands/products/BaseFormPopup";
import { required } from "vuelidate/lib/validators";
import assetClient from "@/api/asset-client";
import trainingClient from "@/api/training-client";
import AWS from "aws-sdk";
export default {
  validations: {
    brandInitial: {
      introduction: { required },
      about: { required },
    },
    headquarterAddresses: {
      $each: {
        streetAddressLine_1: { required },
        streetAddressLine_2: {},
        city: { required },
        region: { required },
        postalCode: { required },
        country: {
          id: { required },
        },
      },
    },
  },
  components: {
    "multi-select": Multiselect,
    "a-row": Row,
    "a-col": Col,
    HeadCard,
    OverView,
    ProfileCategories,
    BrandProfileRight,
    BannerImageCropper,
    LogoImageCropper,
    BaseFormPopup,
    InputWithPrefix,
  },
  data() {
    return {
      isRetailer: false,
      socialAccounts: [],
      brandInitial: {},
      isEditModalOpen: false,
      currentMenuItem: "overview",
      isSaving: false,
      isSaved: false,
      savingError: false,
      isApproved: false,
      heroProductsArray: [],
      keyRetailersArray: [],
      addressEdit: [],
      introductionLengthExceeds: false,
      maxcharacter: 100,
      remaincharactersText: "Remaining 100 characters.",
      logoAsset: undefined,
      bannerAsset: undefined,
      fileList: [],
      VideoImgFileList: [],
      pdfFileName: "IntroDeck.pdf",
      isVideoImg: false,
      isLoading: false,
      progress: 0,
      introVideoAssets: [],
    };
  },
  computed: {
    headquarterAddresses() {
      return (
        this.addressEdit && this.addressEdit.filter((address) => address.hq)
      );
    },
    brand() {
      return this.$store.getters["brand/brand"];
    },
    introPdf() {
      let data = this.$store.getters["brand/brand"];
      return data.introDeckAsset;
    },
    brandId() {
      console.log(this.$route.params.brandId);
      return this.$route.params.brandId
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"];
    },
    brandStatus() {
      return this.brand && this.brand.status && this.brand.status.id;
    },
    isEmailVerified() {
      return this.$store.getters["user/isEmailVerified"];
    },
    userEmail() {
      return this.$store.getters["user/email"];
    },
    headquarters() {
      return this.$store.getters["brandAddress/headquarters"];
    },
    keyRetailersForApi() {
      return (
        (this.brandInitial.keyRetailers &&
          this.brandInitial.keyRetailers.filter(
            (keyRetailer) => keyRetailer.name
          )) ||
        []
      );
    },
    heroProductsForApi() {
      return (
        (this.brandInitial.heroProducts &&
          this.brandInitial.heroProducts.filter(
            (heroProduct) => heroProduct.name
          )) ||
        []
      );
    },
    role() {
      return this.$store.getters["user/role"];
    },
    countries() {
      return this.$store.getters["reference/countries"];
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"];
    },
    addresses() {
      return this.$store.getters["brandAddress/addresses"];
    },
    socialAccountsForApi() {
      return this.socialAccounts.filter(
        (socialAccount) => socialAccount.address
      );
    },
    sellSideTags() {
      return (
        (
          this.$store.getters["reference/sellSideTags"] &&
          this.$store.getters["reference/sellSideTags"].filter(
            (tag) => tag.brandApplicable
          )
        ).map((item) => {
          if (!item.editable) {
            item.$isDisabled = true;
          }
          return item;
        }) || []
      );
    },
    sellSideCuration() {
      let data = this.$store.getters["reference/curations"];
      if (!data) {
        return [];
      }
      if (this.isAdmin) {
        return data;
      } else {
        let result = data.filter((item) => item.name !== "unfiltered");
        return result;
      }
    },
    clientSizes() {
      return this.$store.getters["reference/clientSizes"];
    },
    years() {
      const year = new Date().getFullYear();
      var arr = Array.from(
        { length: year - 1899 },
        (value, index) => 1900 + index
      );
      return arr.reverse();
    },
  },
  watch: {
    async brand() {
      //await this.afterCreate();
    },
    async brandStatus() {
      this.checkBrandStatus();
    },
    introPdf() {
      if (this.introPdf && this.introPdf.id) {
        this.getPdfName(this.introPdf.id);
      }
    },
  },
  async created() {
    const brandId =
      this.$route.name == "adminBrandProfile" ||
      this.$route.name == "retailerBrandProfile" ||
      this.$route.name == "brandProfileAdmin" ||
      this.$route.name == "adminRetailerBrandProfile" ||
      this.$route.name == "retailerBrandOnlyProfile"
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"];
    await this.$store.dispatch("brand/getBrand", brandId);
    await this.$store.dispatch("brandAddress/fetchAddresses", brandId);
    await this.initVideoImgAsset();
    await this.afterCreate();
    this.initSocialAccounts();
  },
  methods: {
    getPdfName(id) {
      this.$store
        .dispatch("asset/getAsset", id)
        .then(() => {
          let data = this.$store.getters["asset/find"](id);
          if (data) {
            this.pdfFileName = data.originalFileName;
          }
        })
        .catch((err) => {
          console.info(err);
        });
    },
    handleChange(info) {
      const status = info.file.status;
      if (status !== "uploading") {
        if (status === "removed") {
          this.fileList = info.fileList;
        }
      }
      if (status === "done") {
        this.$message.success(
          `${info.fileList[0].name} file uploaded successfully.`
        );
      } else if (status === "error") {
        this.$message.error(`${info.fileList[0].name} file upload failed.`);
      }
    },
    async handleVideoImgChange(files) {
      // if (this.VideoImgFileList.length >= 4 || this.formatVideoImg(files.file)) {
      //   this.$message.warning(
      //     "Please remove existed intro deck, then can upload two video and two image."
      //   )
      //   return
      // }
      // const status = info.file.status
      // if (status !== "uploading") {
      //   if (status === "removed") {
      //     this.VideoImgFileList = info.fileList
      //   }
      // }
      // if (status === "done") {
      //   this.$message.success(`${info.fileList[0].name} file uploaded successfully.`)
      // } else if (status === "error") {
      //   this.$message.error(`${info.fileList[0].name} file upload failed.`)
      // }
      const file = files.file;
      if (file.status === "uploading") {
        await this.getAWSKey(file);
        return;
      }
      if (file.status === "done" || file.status === "error") {
        this.$message.success(
          `${file.fileList[0].name} file uploaded successfully.`
        );
      }
    },
    beforeUploadFile(file, fileList) {
      if (!this.fileList || this.fileList.length === 0) {
        this.fileList = fileList;
        return true;
      } else {
        this.$message.warning(
          "Please remove existed intro deck, then can upload a new one."
        );
        return false;
      }
    },
    beforeUploadVideoImgFile(file) {
      if (!this.VideoImgFileList || this.VideoImgFileList.length === 0) {
        // this.VideoImgFileList = fileList
        return true;
      } else if (
        this.VideoImgFileList.length >= 4 ||
        this.formatVideoImg(file)
      ) {
        this.$message.warning(
          "Please remove existed intro deck, then can upload two video and two image."
        );
        return false;
      }
    },
    formatVideoImg(file) {
      const imgList = this.VideoImgFileList.filter((item) =>
        item.contentType.includes("image")
      );
      const videoList = this.VideoImgFileList.filter((item) =>
        item.contentType.includes("video")
      );
      if (imgList.length >= 2 && file.type.includes("image")) {
        return true;
      }
      if (videoList.length >= 2 && file.type.includes("video")) {
        return true;
      }
      return false;
    },
    removePdf(file) {
      this.introVideoAssets.splice(
        this.introVideoAssets.findIndex((i) => i.uid === file.uid),
        1
      );
      let param = {
        introDeckAsset: {
          id: "",
          url: "",
        },
      };
      this.updateBrandIntro(param);
    },
    async removeVideoImg(file) {
      this.VideoImgFileList = this.VideoImgFileList.filter(
        (item) => item.uid !== file.uid
      );
      this.introVideoAssets.splice(
        this.introVideoAssets.findIndex((i) => i.uid === file.uid),
        1
      );
      const params = {
        id: this.brand.id,
        assets: this.sendBrandAsset(this.VideoImgFileList),
      };
      if (this.brand.id) {
        await this.$store.dispatch("brand/updateBrandPublic", params);
        // this.$emit("updateBrandInfo", this.brand.shortName)
      }
    },
    updateUploadProgress(options) {
      options.onProgress({ percent: 90 });
    },
    doUpload(data) {
      this.updateUploadProgress(data);
      assetClient
        .createAsset(data.file, this.brandId)
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            let uploadedFile = response.data[0].file;
            data.onSuccess(response, data.file);
            let param = {
              introDeckAsset: {
                id: uploadedFile.id,
                contentType: uploadedFile.contentType,
                url: uploadedFile.url,
              },
            };
            this.updateBrandIntro(param);
          }
        })
        .catch((err) => {
          // data.onError(err, err.response)
          console.info(err);
        });
    },
    doUploadVideoImg(data) {
      this.updateUploadProgress(data);
      assetClient
        .createAsset(data.file, this.brandId)
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            let uploadedFile = response.data[0].file;
            data.onSuccess(response, data.file);
            // const param = {
            //   asset: {
            //     id: uploadedFile.id,
            //     contentType: uploadedFile.contentType,
            //     url: uploadedFile.url
            //   }
            // }
            const param = {
              ...uploadedFile,
              uid: uploadedFile.id,
              name: uploadedFile.originalFileName,
              status: "done",
            };
            this.VideoImgFileList.push(param);
            this.isVideoImg = true;
          }
        })
        .catch((err) => {
          // data.onError(err, err.response)
          console.info(err);
        });
    },
    async getAWSKey(fileInfo) {
      this.isLoading = true;
      this.progress = 10;
      const uploadInfo = {
        originalFileName: fileInfo.name,
        characterSet: "UTF-8",
        contentType: fileInfo.type,
        size: fileInfo.size,
      };
      const { data } = this.isAdmin
        ? await trainingClient.getAWSKeyInfoInAdmin(
            uploadInfo,
            this.brandId ? this.brandId : this.brand.id
          )
        : await trainingClient.getAWSKeyInfo(uploadInfo);
      this.descriptor = data.descriptor;
      this.progress = 50;
      let s3 = new AWS.S3({
        // region: data.credentialsState.region,
        region: "us-west-2",
        apiVersion: "2006-03-01",
        // authority: data.credentialsState.authority,
        accessKeyId: data.credentialsState.accessKeyId,
        secretAccessKey: data.credentialsState.secretAccessKey,
        sessionToken: data.credentialsState.sessionToken,
      });
      this.progress = 80;
      let params = {
        Body: fileInfo.originFileObj,
        Bucket: data.credentialsState.bucketName,
        Key: data.descriptor.storageKey,
      };
      s3.putObject(params, async (err, data) => {
        if (err) {
          this.$message.error("Upload failed");
        } else {
          this.isShowReplaceBtn = true;
          // this.UploadText = "Upload Successful"
          this.UploadText = "Upload";
          const successInfo = await trainingClient.sendUploadInfo(
            this.descriptor
          );
          const param = [
            {
              asset: {
                url: successInfo.data.url,
                id: successInfo.data.id,
                contentType: successInfo.data.contentType,
              },
              type: {
                id: "intro",
              },
            },
          ];
          const list = this.sendBrandAsset(this.VideoImgFileList);
          const sendList = list.concat(param);
          const params = {
            id: this.brandId,
            assets: sendList,
          };
          if (this.brand.id) {
            await this.$store.dispatch("brand/updateBrandPublic", params);
            // this.$emit("updateBrandInfo", this.brand.shortName)
            this.$message.success(
              `${fileInfo.name} file uploaded successfully.`
            );
            await this.initVideoImgAsset();
            this.isLoading = false;
            this.progress = 100;
          } else {
            // successful response
            console.log("error", data);
          }
        }
      });
    },
    async updateBrandIntro(param) {
      await trainingClient.addBrandClientVideo(this.brandId, param);
    },
    removeTags(value) {
      let obj = this.sellSideTags.find((item) => {
        let isSame = item.id === value;
        return isSame;
      });
      if (!obj.editable) {
        return false;
      } else {
        this.brandInitial.tags = this.brandInitial.tags.filter(
          (item) => item.id != obj.id
        );
      }
    },
    offset(key) {
      return {
        website: "80px",
        facebook: "174px",
        instagram: "177px",
        linkedin: "165px",
        twitter: "153px",
      }[key];
    },
    formatDate(foundingDate) {
      if (foundingDate) {
        return foundingDate + "-" + "01" + "-" + "01";
      }
      return "";
    },
    selectTags(value) {
      this.brandInitial.tags = [];
      for (let i = 0; i < value.length; i++) {
        this.brandInitial.tags.push({ id: value[i] });
      }
    },
    removeCuration(value) {
      this.brandInitial.curations = this.brandInitial.curations.filter(
        (item) => item.id != value.id
      );
    },
    tagNameById({ id }) {
      const tag = this.sellSideCuration.find((tag) => tag.id === id);
      return tag && tag.description;
    },
    async isLegacyUser() {
      return (
        await firebase
          .database()
          .ref("legacy/users/" + (await this.sha256(this.userEmail)))
          .once("value", (snapshot) => {
            return snapshot.val();
          })
      ).toJSON();
    },
    initSocialAccounts() {
      let socialAccounts =
        this.$store.getters["reference/socialAccounts"] || [];
      this.socialAccounts = [
        ...socialAccounts.map((socialAccount) => {
          const existingAccount =
            this.brand.socialAccounts.find(
              (account) => account.platform.id === socialAccount.id
            ) || {};

          return { address: existingAccount.address, platform: socialAccount };
        }),
      ];
    },
    toggleMenuItem(item) {
      this.isEditModalOpen = true;
      this.currentMenuItem = item;
    },
    async showEditModal() {
      this.addressEdit = this.resetEditAddress();
      this.brandInitial = this.brandInitialObject();
      this.initSocialAccounts();
      this.initDeckPDF();
      await this.initVideoImgAsset();
      this.isEditModalOpen = true;
      this.isSaving = false;
    },
    initDeckPDF() {
      if (
        this.brand &&
        this.brand.introDeckAsset &&
        this.brand.introDeckAsset
      ) {
        this.fileList = [];
        let data = Object.assign({}, this.brand.introDeckAsset);
        data.uid = this.brand.introDeckAsset.id;
        data.name = this.pdfFileName;
        data.status = "done";
        this.fileList.push(data);
      }
    },
    async initVideoImgAsset() {
      if (this.brand && this.brand.assets && this.brand.assets.length !== 0) {
        this.VideoImgFileList = [];
        this.introVideoAssets.splice(0, this.introVideoAssets.length);
        await this.brand.assets.map(async (item) => {
          const asset = await this.$store.dispatch(
            "asset/getAsset",
            item.asset.id
          );
          //const name = await assetClient.getAsset(item.asset.id);
          const data = {};
          data.uid = item.asset.id;
          // data.name = item.asset.url.split("/").pop()
          data.name = asset.originalFileName;
          data.status = "done";
          data.url = item.asset.url;
          data.contentType = item.asset.contentType;
          this.VideoImgFileList.push(data);
          this.introVideoAssets.push(data);

          return data;
        });
      }
    },
    resetEditAddress() {
      return [
        ...(this.addresses &&
          this.addresses.map((item) => {
            return {
              addressee: item.addressee,
              city: item.city,
              country: { ...item.country },
              defaultShipping: item.defaultShipping,
              hq: item.hq,
              label: item.label,
              postalCode: item.postalCode,
              region: item.region,
              streetAddressLine_1: item.streetAddressLine_1,
              streetAddressLine_2: item.streetAddressLine_2,
            };
          })),
      ];
    },
    charCount() {
      if (this.brandInitial.introduction.length > this.maxcharacter) {
        this.introductionLengthExceeds = true;
      } else {
        var remainingCharacters =
          this.maxcharacter - this.brandInitial.introduction.length;
        this.remaincharactersText = `${remainingCharacters} characters remaining`;
      }
    },
    resetSavedStatus() {
      this.isSaved = false;
    },
    resetErrorStatus() {
      this.isSavingError = false;
    },
    imageSaving(status) {
      this.isSaving = false;
      this.isSaved = false;
      this.savingError = false;

      if (status === "saving") {
        this.isSaving = true;
      }
      if (status === "saved") {
        this.isSaved = true;
        setTimeout(this.resetSavedStatus, 3000);
      }
      if (status === "error") {
        this.savingError = true;
        setTimeout(this.resetErrorStatus, 3000);
      }
    },
    async update() {
      this.$v.$touch();
      if (
        this.$v.brandInitial.$anyError ||
        this.$v.headquarterAddresses.$anyError ||
        this.introductionLengthExceeds
      ) {
        return;
      }
      this.currentMenuItem = "overview";
      try {
        // this.updateCuration()
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "profileComplete",
          userId: this.$store.getters["user/id"],
          firstName: this.$store.getters["user/givenName"],
          lastName: this.$store.getters["user/familyName"],
          emailAddress: this.$store.getters["user/email"],
          userType: "Brand",
        });
        this.updateBrand();
        this.isEditModalOpen = false;
      } catch (error) {
        this.$vs.notify({
          title: "Notification",
          text:
            error.response &&
            error.response.data &&
            error.response.data.underlying[0]
              ? error.response.data.underlying[0].developerMessage
              : error.message
              ? error.message
              : "Something wrong here",
          color: "red",
        });
      }
    },
    async updateTags() {
      return await this.$store.dispatch(
        "brand/updateTags",
        this.brandInitial.tags,
        this.brand.id
      );
    },
    async updateCuration() {
      return await this.$store.dispatch(
        "brand/updateCurations",
        this.brandInitial.curations ? this.brandInitial.curations : [],
        this.brand.id
      );
    },
    async updateSocialAccounts() {
      await this.$store.dispatch(
        "brand/updateSocialAccounts",
        this.socialAccountsForApi,
        this.brand.id
      );
    },
    async updateKeyRetailers() {
      await this.$store.dispatch(
        "brand/updateKeyRetailers",
        this.keyRetailersForApi,
        this.brand.id
      );
    },
    async updateHeroProducts() {
      await this.$store.dispatch(
        "brand/updateHeroProducts",
        this.heroProductsForApi,
        this.brand.id
      );
    },
    async updateAddresses() {
      await this.$store.dispatch("brandAddress/updateAll", {
        addresses: this.addressEdit,
        brandId: this.brand.id,
      });
    },
    async updateBrand() {
      if (this.isVideoImg) {
        const param = {
          id: this.brand.id,
          assets: this.sendBrandAsset(this.VideoImgFileList),
        };
        await this.$store.dispatch("brand/updateBrandPublic", param);
      } else {
        await this.$store.dispatch("brand/updateBrand", this.brandRootObject());
      }
      await this.$store.dispatch("brandAddress/fetchAddresses", this.brand.id);
    },
    async updateBannerAsset(bannerAssetId, bannerAssetUrl) {
      this.brandInitial.bannerAsset.id = bannerAssetId;
      this.brandInitial.bannerAsset.url = bannerAssetUrl;
    },
    async updateLogoAsset(logoAssetId, logoAssetUrl) {
      this.brandInitial.logoAsset.id = logoAssetId;
      this.brandInitial.logoAsset.url = logoAssetUrl;
    },
    // removeTags(value) {
    //   if (!value.editable) {
    //     return false
    //   } else {
    //     this.brandInitial.tags = this.brandInitial.tags.filter(item => item.id != value.id)
    //   }
    // },
    async afterCreate() {
      await this.checkBrandStatus();
      var remainingCharacters =
        this.maxcharacter - this.brand.introduction.length;
      this.remaincharactersText = `${remainingCharacters} characters remaining`;
      console.log("before");
      await this.$store.dispatch("reference/setReference");
      console.log("after");
      if (
        this.brandInitial &&
        this.brandInitial.keyRetailers &&
        !this.brandInitial.keyRetailers.length
      ) {
        this.addKeyRetailer();
      }
      if (
        this.brandInitial &&
        this.brandInitial.heroProducts &&
        !this.brandInitial.heroProducts.length
      ) {
        this.addHeroProduct();
      }
    },
    brandInitialObject() {
      let data = {
        ...this.brand,
        logoAsset: { ...this.brand.logoAsset },
        bannerAsset: this.brand.bannerAsset
          ? { id: this.brand.bannerAsset.id, url: this.brand.bannerAsset.url }
          : {},
        size: { ...this.brand.size },
        terms: { ...this.brand.terms },
        foundingDate:
          this.brand.foundingDate && this.brand.foundingDate.split("-")[0],
      };
      if (data.keyRetailers) {
        for (let i = 0; i < data.keyRetailers.length; i++) {
          if (!data.keyRetailers[i].sort) {
            data.keyRetailers[i].sort = Number(i + 1);
          }
        }
      }
      return data;
    },
    async sha256(email) {
      const msgBuffer = new TextEncoder("utf-8").encode(email);
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((b) => ("00" + b.toString(16)).slice(-2))
        .join("");

      return hashHex;
    },
    async checkBrandStatus() {
      // if ((await this.isLegacyUser())) {
      //   this.isApproved = true;
      // } else
      if (
        this.brandStatus &&
        (this.brandStatus == "pending" ||
          this.brandStatus == "rejected" ||
          !this.isEmailVerified) &&
        !this.isAdmin
      ) {
        this.$router.push({ name: "brandPending" });
      } else if (this.brandStatus && this.brandStatus == "approved") {
        this.isApproved = true;
      }
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return numberFormat.format(price);
    },
    brandRootObject() {
      return {
        id: this.brandInitial.id,
        name: this.brandInitial.name,
        shortName: this.brandInitial.shortName,
        logoAsset: this.logoAsset
          ? {
              id: this.logoAsset.id,
              url: this.logoAsset.url,
            }
          : {
              id: this.brandInitial.logoAsset.id,
              url: this.brandInitial.logoAsset.url,
            },
        bannerAsset: this.bannerAsset
          ? {
              id: this.bannerAsset.id,
              url: this.bannerAsset.url,
            }
          : {
              id: this.brandInitial.bannerAsset.id,
              url: this.brandInitial.bannerAsset.url,
            },
        introduction: this.brandInitial.introduction,
        about: this.brandInitial.about,
        website: this.brandInitial.website,
        foundingDate: this.formatDate(this.brandInitial.foundingDate),
        size: this.brandInitial.size,
        status: this.brandInitial.status,
        tags: this.brandInitial.tags,
        curations: this.brandInitial.curations,
        socialAccounts: this.socialAccountsForApi,
        addresses: this.addressEdit,
        keyRetailers: this.keyRetailersForApi,
        heroProducts: this.heroProductsForApi,
        terms: this.brandInitial.terms,
        assets: this.sendBrandAsset(this.VideoImgFileList),
      };
    },
    sendBrandAsset(list) {
      return list.map((item) => {
        return {
          asset: {
            id: item.uid,
            url: item.url,
            contentType: item.contentType,
          },
          type: {
            id: "intro",
          },
        };
      });
    },
    isMenuItem(item) {
      return this.currentMenuItem === item;
    },
    displayMinimumAmount(brand) {
      if (brand.terms && brand.terms.minimumOrderAmountUsd) {
        return this.priceFormat(brand.terms.minimumOrderAmountUsd).replace(
          ".00",
          ""
        );
      }
      return "";
    },
    displayMinimumQuantity(brand) {
      if (brand.terms && brand.terms.minimumOrderQuantity) {
        return Number(brand.terms.minimumOrderQuantity).toLocaleString();
      }
      return "";
    },
    countryName(id) {
      return this.$store.getters["reference/countryName"](id);
    },
    clientSizeName(id) {
      return this.$store.getters["reference/clientSizeName"](id);
    },
    flattenAddress(address) {
      if (!address.city || !address.region) {
        return "N/A";
      }

      return [
        address.streetAddressLine_1,
        address.streetAddressLine_2,
        address.city,
        address.region,
        address.postalCode,
        this.countryName(address.country.id),
      ]
        .filter((item) => item)
        .join(", ");
    },
    addKeyRetailer() {
      this.keyRetailersArray = [...this.brandInitial.keyRetailers];
      let sortNumber = 1;
      if (this.keyRetailersArray.length > 0) {
        sortNumber =
          Number(
            this.keyRetailersArray[this.keyRetailersArray.length - 1].sort
          ) + 1;
      }
      this.keyRetailersArray.push({ name: "", sort: sortNumber });
      this.brandInitial.keyRetailers = this.keyRetailersArray;
    },
    removeKeyRetailer(index) {
      this.keyRetailersArray = [...this.brandInitial.keyRetailers];
      this.keyRetailersArray.splice(index, 1);
      return (this.brandInitial.keyRetailers = this.keyRetailersArray);
    },
    addHeroProduct() {
      this.heroProductsArray = [...this.brandInitial.heroProducts];
      this.heroProductsArray.push({ name: "" });
      this.brandInitial.heroProducts = this.heroProductsArray;
    },
    removeHeroProduct(index) {
      this.heroProductsArray = [...this.brandInitial.heroProducts];
      this.heroProductsArray.splice(index, 1);
      return (this.brandInitial.heroProducts = this.heroProductsArray);
    },
    async updateTerms() {
      try {
        const minimumOrderAmountUsd =
          this.brandInitial.terms.minimumOrderAmountUsd || null;
        const minimumOrderQuantity =
          this.brandInitial.terms.minimumOrderQuantity || null;
        const shippingTerms = this.brandInitial.terms.shippingTerms;
        const usesLandingFulfillment =
          this.brandInitial.terms.usesLandingFulfillment || false;
        await this.$store.dispatch("brand/updateTerms", {
          minimumOrderAmountUsd,
          minimumOrderQuantity,
          shippingTerms,
          usesLandingFulfillment,
        });
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      this.isEditModalOpen = false;
      this.currentMenuItem = "overview";
      this.brandInitial = {
        ...this.brand,
        size: { ...this.brand.size },
        terms: { ...this.brand.terms },
        foundingDate:
          this.brand.foundingDate && this.brand.foundingDate.split("-")[0],
      };
      this.initSocialAccounts();
      var remainingCharacters =
        this.maxcharacter - this.brandInitial.introduction.length;
      this.remaincharactersText = `${remainingCharacters} characters remaining`;
    },
    goToWebsite() {
      window.open(`"http://${this.brand.website}`);
    },
    goToSocialMedia(socialAccount) {
      window.open(`http://${socialAccount.address}`);
    },
    socialAccountAddress(address, socialAccount) {
      if (address) {
        socialAccount.address = `${socialAccount.platform.id}.com/${address}`;
      } else {
        socialAccount.address = address;
      }
    },
  },
};
</script>
<style lang="sass">
.brand-user-profile-page
  min-height: 80vh !important
  width: 953px
  margin: 0px auto !important
  padding: 0px
  align-content: center
  font-family: "Proxima Nova"
.header-content
  margin-bottom: 32px
.content-text-info
  display: flex
  margin-bottom: 100px
.top-margin
  margin-top: 20px
.right-item-top-margin
  margin-top: 26px
.left-right-item-padding
  padding-right: 16px !important
.upload-pdf-btn
  line-height: 1.499
  text-align: center
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%)
  height: 32px
  padding: 4px 15px
  font-size: 14px
  border-radius: 4px
  color: rgba(0, 0, 0, 0.65)
  background-color: #fff
  border: 1px solid #d9d9d9

//For edit modal
.remaining-characters
  font-size: 12px
  justify-content: flex-end
  display: flex

.pending-brand
 min-height: 80vh !important
.error-message
    color: #BF0711
    font-size: 18px
.brand-profile
  padding-bottom: 100px
  color: #000000
  font-family: "Proxima Nova"
  .save-button
    height: 36px
    width: 83px
    font-size: 12px
    font-weight: bold
    background-color: #323232

  .modal-card-body
    overflow-y: auto
    overflow-x: hidden


  &__edit-modal
    background-color: #ffffff
    width: 1038px

    &-error-message
      color: #BF0711
      font-size: 18px

    &-form-messages
      display: flex
      align-items: center

    &-form-buttons
      display: flow-root

    &--container
      min-height: 1149px

    &--form
      padding: 0px 80px 130px 80px

    &--form_section
      font-weight: 500
      font-size: 16px
      padding-top: 20px
      letter-spacing: 0.23px
      border-top: 1px solid #E5E5E5

    &--form_section-label
      margin-bottom: 20px

    .form-section-field
      padding-bottom: 25px

    &--form_terms
      position: sticky
      top: 59px

    &--form_label
      color: #000000
      font-size: 14px
      font-weight: 500
      letter-spacing: 0.2px
      padding-bottom: 5px
      padding-right: 5px

    &--form_textarea
      height: 175px

    &--form_link
      float: right
      text-decoration: underline
      color: #000000

    &--form_address
      padding-bottom: 10px

    &--form_save-button
      padding-top: 30px

    &--header
      font-size: 18px
      font-weight: 500
      letter-spacing: 0.25px
      color: #000000
      padding-left: 15px

    &--header_close-icon
      cursor: pointer

    &--menu
      padding-left: 35px
      padding-top: 30px
      background-color: #F4F4F4
      font-size: 18px

      li
        margin-top: 30px

      a
        color: #000000

  &__banner
    height: 280px
    margin-bottom: -35px
    background-color: #ffffff

  &__logo
    height: 140px
    width: 140px
    border: 1px solid #E5E5E5
    background-color: #ffffff

    img
      max-height: 140px
      max-width: 140px

  &__about
    padding: 55px 100px 0px 100px

  &__gallery-container
    padding-top: 55px

  &__gallery
    max-width: 920px
    margin: 0 auto

  &__overview
    margin-right: 40px
    font-size: 14px

  &__details-value
    font-size: 16px

  &__details
    border: 1px solid rgba(151,151,151,0.23)
    padding: 25px
    margin-bottom: 25px

    &--container
      margin-top: 30px

    &--title
      font-size: 18px
      letter-spacing: 0.25px
      padding-bottom: 20px
      padding-right: 5px

      &_text
        font-weight: 400

    &--subtitle
       font-size: 14px
       font-weight: bold

       &_link
         text-decoration: underline

  &__logo
    display: flex
    justify-content: center
    margin-inline-end: 10px
    margin-left: 30px

  &__name
    font-size: 18px
    font-weight: bold
    letter-spacing: 0.25px
    padding-top: 50px

  &__description
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.25px

  &__website
    padding-top: 30px
    text-decoration: underline

    a
      color: #161616

  &__edit
    color: #161616
    font-size: 14px
    letter-spacing: 0.16px
    text-decoration: underline
    border-bottom: 1px solid #D8D8D8
    display: flex
    justify-content: flex-end

  &__edit--link
    cursor: pointer

  &__country
    font-size: 14px

  &__icons
    margin-top: 50px

.brand-profile__edit-modal-sticky-menu
  position: sticky
  top: 60px

.is-selected
  font-weight: bold
  color: #000
.enable-line-break

  white-space: pre-wrap

.disable-save
  pointer-events: none

.brand-profile__edit-modal--form_photos-verview .ant-upload-list-text .ant-upload-list-item-card-actions
  position:absolute
.brand-profile__edit-modal--form_photos-verview .ant-upload-list-text .ant-upload-list-item-name,
.brand-profile__edit-modal--form_photos-verview .ant-upload-list-picture .ant-upload-list-item-name
  padding-left:22px
.brand-profile__edit-modal--form_photos-verview .ant-upload-list-item-name
  width: 90%
.brand-profile__edit-modal--form_photos-verview .ant-upload-list-item-card-actions
  position: absolute !important
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
