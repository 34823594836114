<template>
  <a-modal
    v-model="isShowCancelSubscription"
    :footer="null"
    :destroy-on-close="true"
    width="403px"
    :body-style="noPadding"
  >
    <template #closeIcon>
      <img src="@/assets/img/icon/close-circle.svg" />
    </template>
    <div>
      <span class="cancel-subscription__title">{{
        $t("Brands.BrandSettings.cancelSubscriptionTitle")
      }}</span>
      <span class="cancel-subscription__content">{{
        $t("Brands.BrandSettings.cancelSubscriptionContent", {
          date: data && data.endDate ? formatDate(data.endDate) : "-"
        })
      }}</span>
    </div>
    <a-row class="cancel-subscription__action">
      <a-col :span="8"></a-col>
      <a-col :span="16">
        <span class="cancel-subscription__confirm_action" @click="closeModal">
          {{ $t("Brands.BrandSettings.cancelSubscriptionKeep") }}
        </span>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12"></a-col>
      <a-col :span="12">
        <span class="cancel-subscription__cancel_action" @click="doCancelSubscription">
          {{ $t("Brands.BrandSettings.cancelSubscriptionCancel") }}
        </span>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { Modal, Row, Col } from "ant-design-vue"
// import moment from "moment"
export default {
  components: {
    "a-modal": Modal,
    "a-row": Row,
    "a-col": Col
  },
  props: {
    data: { type: Object, default: () => {} }
  },
  data() {
    return {
      isShowCancelSubscription: false,
      noPadding: {
        padding: "18px 20px 22px 30px"
      }
    }
  },
  updated() {
    console.log(this.data)
  },
  methods: {
    openDialog() {
      this.isShowCancelSubscription = true
    },
    closeModal() {
      this.isShowCancelSubscription = false
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    async fetchBrandInfo() {
      const brandId =
        this.$route.name == "adminBrandSettingsSubscription"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      await this.$store.dispatch("brand/getBrand", brandId)
    },
    async doCancelSubscription() {
      if (this.data) {
        // let params = []
        // params.push({
        //   "unsubscribeTime": moment().format('yyyy-MM-DD'),
        //   "isAutoRenewal":false,
        //   "type": this.data.type,
        //   "subscriptionId": this.data.subscriptionId,
        //   "paidAmountUsd": this.data.paidAmountUsd,
        //   "startDate": this.data.startDate,
        //   "endDate": this.data.endDate
        // })
        // await this.$store.dispatch("brand/updateSubscriptionPlan", params)
        const param = {
          mode: "cancel"
        }
        await this.$store.dispatch("brand/updateBrandSubscriptionNew", param)
        // await this.$store.dispatch("brand/getBrandSubscriptionHistoryList")
        await this.fetchBrandInfo()
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cancel-subscription {
  font-family: Proxima Nova;
  font-style: normal;
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #595959;
    margin-top: 27px;
    margin-bottom: 10px;
  }
  &__content {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #8c8c8c;
    margin-top: 20px;
    margin-bottom: 22px;
  }
  &__action {
    margin-bottom: 8px;
    margin-top: 10px;
  }
  &__confirm_action {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4a8a5d;
    padding: 6px 12px;
    border: 1px solid #4a8a5d;
    cursor: pointer;
  }
  &__cancel_action {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ff6132;
    text-align: right;
    cursor: pointer;
  }
}
</style>
