<template>
  <div class="brands">
    <div class="header-box">
      <a-row>
        <a-col class="banner-brand">
          <h1 class="playfair-h1">{{ $t("Homepage.brand.banner.bannerTitle") }}</h1>
          <p>{{ $t("Homepage.brand.banner.bannerContent") }}</p>
          <p>{{ $t("Homepage.brand.banner.bannerContentMore") }}</p>
          <a class="link-btn" href="/brand-registration">
            {{ $t("Homepage.brand.banner.bannerButton") }}
          </a>
        </a-col>
      </a-row>
      <img class="banner-computer" src="@/assets/img/homepage/banner/retailers.gif" />
    </div>
    <c-swiper :swiper-list="swiperList" :title="title"></c-swiper>
    <a-row v-for="(item, index) in cardList" :key="index" class="frame-box" type="flex">
      <a-col :span="12" :order="index === 0 ? '1' : '4'">
        <img :src="item.cardImg" />
      </a-col>
      <a-col :span="12" :order="2" :class="'card-body' + (index === 0 ? '' : '-two')">
        <div>
          <h1 class="playfair-h1">{{ item.cardTitle }}</h1>
          <ul>
            <li v-for="(site, idx) in item.cardContent" :key="idx">{{ site }}</li>
          </ul>
          <a class="link-btn" href="/brand-registration">
            {{ item.cardButton }}
          </a>
        </div>
      </a-col>
    </a-row>
    <div class="works-step">
      <h1 class="playfair-h1">{{ $t("Homepage.brand.worksStepTitle") }}</h1>
      <a-row class="brands-step" type="flex" justify="center">
        <a-col v-for="(item, index) in worksStepList" :key="index" :span="8" :class="item.classCol">
          <div :class="item.classDiv">
            <div></div>
            <h2>{{ item.title }}</h2>
            <p>{{ item.content }}</p>
          </div>
        </a-col>
      </a-row>
      <a class="link-btn" href="/brand-registration">{{ $t("Homepage.brand.worksStepButton") }}</a>
    </div>
    <div class="center-container">
      <h1 class="playfair-h1">{{ $t("Homepage.brand.profile") }}</h1>
      <router-link class="link-btn" to="/website-subscription">{{
        $t("Homepage.brand.profileButton")
      }}</router-link>
    </div>

    <div class="bottom-banner">
      <div class="banner-container">
        <a-row class="extra-box">
          <a-col :span="12" class="card-body">
            <h1 class="playfair-h1">{{ $t("Homepage.brand.bottomBannerTitle") }}<br /></h1>
            <p class="centerp">{{ $t("Homepage.brand.bottomBannerContert") }}</p>
            <ul class="centerlist">
              <li v-for="(item, idx) in centerlist" :key="idx">{{ item }}</li>
            </ul>
          </a-col>
          <a-col :span="12" class="card-body-right">
            <div class="get-list">
              <img src="@/assets/img/homepage/banner/Subscribe to our ret.svg" />
            </div>
            <div class="email-box">
              <form class="validate">
                <div :class="isEmailInputStyle ? 'email-element active' : 'email-element'">
                  <input
                    ref="inputVal"
                    type="email"
                    name="EMAIL"
                    class="required email"
                    placeholder="Enter your email"
                    onfocus="this.placeholder=''"
                    onblur="this.placeholder='Enter your email'"
                  />
                  <a
                    href="https://landinginternational.us14.list-manage.com/subscribe/post?u=e3f5833e4e09791a8c1d76bc1&id=38b0f801fc"
                    class="link-btn"
                    @click="setInputStyle"
                  >
                    {{ $t("Homepage.brand.bottomBannerButton") }}
                  </a>
                </div>
              </form>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { Row, Col } from "ant-design-vue"
import SwiperSlide from "@/components/common/SwiperSlide"
import ordersCard from "@/assets/img/homepage/banner/orders.gif"
import businessCard from "@/assets/img/homepage/banner/business.gif"
import ultaLogo from "@/assets/img/homepage/banner/ulta_logo.svg"
import revolveLogo from "@/assets/img/homepage/banner/revolve_logo.svg"
import UrbanOutfittersLogo from "@/assets/img/homepage/banner/Urban_Outfitters_logo.svg"
import dreamstoreLogo from "@/assets/img/homepage/banner/dreamstore_logo.svg"
import AnthropologieLogo from "@/assets/img/homepage/banner/Anthropologie_logo.svg"
import JcpLogo from "@/assets/img/homepage/banner/jcp-logo.svg"
export default {
  components: {
    "a-row": Row,
    "a-col": Col,
    "c-swiper": SwiperSlide
  },
  data() {
    return {
      showBigImg: false,
      isEmailInputStyle: false,
      title: this.$t("Homepage.brand.swiperBanner"),
      swiperList: [
        ultaLogo,
        revolveLogo,
        UrbanOutfittersLogo,
        dreamstoreLogo,
        AnthropologieLogo,
        JcpLogo,
        ultaLogo,
        revolveLogo,
        UrbanOutfittersLogo,
        dreamstoreLogo,
        AnthropologieLogo,
        JcpLogo
      ]
    }
  },
  computed: {
    cardList() {
      const list = [{ cardImg: ordersCard }, { cardImg: businessCard }]
      const cardList = this.$t("Homepage.brand.cardList")
      return cardList.map((item, index) => {
        return { ...item, ...list[index] }
      })
    },
    worksStepList() {
      return this.$t("Homepage.brand.worksStepList")
    },
    centerlist() {
      return this.$t("Homepage.brand.bottomBannerContentList")
    }
  },
  methods: {
    setInputStyle() {
      this.$refs.inputVal.focus()
      this.isEmailInputStyle = true
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Playfair Display";
  src: url("../assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../assets/fonts/Proxima-Nova-Light.otf") format("truetype");
}
@font-face {
  font-family: "ProximaNova-Regular";
  src: url("~@/assets/fonts/ProximaNova-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: url("~@/assets/fonts/Roboto-Medium.ttf") format("truetype");
}

a.link-btn {
  padding: 12px;
  color: #000;
  border-radius: 27.5px;
  border: 2px solid #ff6132;
  font-family: "ProximaNova-Regular", "Roboto", sans-serif;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  filter: sepia(0%);
  display: inline-block;
  width: 268px;
  margin-top: 6px;
  transition: 0.3s;
}
a.link-btn:hover {
  text-decoration: none;
  background: #ff6132;
  transition: 0.3s;
  color: #fff;
}
.playfair-h1 {
  color: #12433d;
  line-height: 52px;
  font-size: 48px;
  font-weight: bold;
  font-family: "Playfair Display";
}
p,
a,
ul,
li {
  font-family: "ProximaNova-Regular", "Proxima Nova", "Roboto", sans-serif;
}

.header-box {
  position: relative;
  width: 100%;
  height: 820px;
  background: url("../assets/img/homepage/banner/banner-deskto.png") center/auto no-repeat;
  margin-top: -42px;
  .banner-brand {
    position: absolute;
    top: 118px;
    left: 52%;
    transform: translateX(-838px);
    padding: 75px 0 0 36px;
    max-width: 670px;
    z-index: 5;
    text-align: right;
    .playfair-h1 {
      line-height: 1.2;
      font-family: "Playfair Display Bold";
      margin-bottom: 20px;
      white-space: pre-wrap;
    }
    p {
      color: #000000;
      line-height: 18px;
      font-size: 15px;
      margin-bottom: 10px;
      font-family: "ProximaNova-Regular", "Proxima Nova", "Roboto", sans-serif;
    }
    .link-btn {
      margin-top: 20px;
    }
  }
  .banner-computer {
    position: absolute;
    top: 358px;
    left: 50%;
    width: 400px;
    height: 250px;
    transform: translate(-10%);
  }
  .banner-phone {
    position: absolute;
    top: 446px;
    right: 51%;
    width: 106px;
    height: 180px;
    transform: translate(540%);
    transition: 0.5s;
  }
}
.frame-box {
  margin: 0px auto;
  padding: 20px;
  width: 75vw;
  max-width: 1140px;
  margin-bottom: 106px;
  margin-top: 62px;

  img {
    width: 100%;
  }
  .card-body,
  .card-body-two {
    padding: 49px 0 0 37px;
    div {
      padding: 1.25rem;
      .playfair-h1 {
        font-family: "Playfair Display Bold";
        margin: 0 10px 10px 20px;
      }
      ul {
        list-style-type: disc;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
        margin-bottom: 24px;
        padding-left: 40px;
        text-align: left;
        li {
          margin-bottom: 16px;
        }
      }
      .link-btn {
        margin-left: 22px;
      }
    }
  }
  .card-body-two {
    padding: 0 0 0 24px;
    div .playfair-h1 {
      margin: 0 0 24px 0;
      padding: 0 20px;
    }
  }
}
.works-step {
  text-align: center;
  .playfair-h1 {
    margin-bottom: 26px !important;
    text-align: center !important;
  }
  .brands-step {
    max-width: 1140px;
    margin: 0 auto;
    .one {
      text-align: center;
      border-style: none;
      background: url("../assets/img/homepage/banner/Line.png") right/auto no-repeat;
    }
    .two {
      text-align: center;
      background: url("../assets/img/homepage/banner/Line.png") center repeat-x;
    }
    .three {
      text-align: center;
      border-style: none;
      background: url("../assets/img/homepage/banner/Line.png") left/auto no-repeat;
    }
    .step-box {
      width: 304px;
      height: 304px;
      margin: 0 auto;
      background-color: #f1f7f6;
      border-radius: 50%;
      position: relative;
      padding-top: 50px;
      transition: 0.5s;
      text-align: center;
      div {
        display: inline-block;
        width: 100%;
        height: 95px;
        text-align: center;
        margin-bottom: 10px;
      }
      h2 {
        font-family: "Proxima Nova";
        font-weight: 600;
        font-size: 48px;
        color: #12433d;
        line-height: 48px;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 24px;
        font-family: "Proxima Nova";
        font-weight: 600;
        color: #12433d;
        line-height: 24px;
      }
    }
    .step-box.step1 div {
      background: url("../assets/img/homepage/banner/step1.svg") bottom/auto 70px no-repeat;
      margin-bottom: 10px;
    }
    .step-box.step2 div {
      background: url("../assets/img/homepage/banner/step2.svg") bottom/auto 70px no-repeat;
      margin-bottom: 10px;
    }
    .step-box.step3 div {
      background: url("../assets/img/homepage/banner/step3.svg") bottom/auto 70px no-repeat;
      margin-bottom: 10px;
    }
    .step-box:hover {
      background-color: #12433d;
      color: #fff;
      transition: 0.5s;
      h2,
      p {
        display: none;
        color: #fff;
      }
    }
    .step-box:hover.step1:hover div {
      background: url("../assets/img/homepage/banner/step1a.svg") bottom/auto 70px no-repeat;
      margin-bottom: 5px;
    }
    .step-box:hover.step2:hover div {
      background: url("../assets/img/homepage/banner/step2a.svg") bottom/auto 70px no-repeat;
      margin-bottom: 5px;
    }
    .step-box:hover.step3:hover div {
      background: url("../assets/img/homepage/banner/step3a.svg") bottom/auto 70px no-repeat;
      margin-bottom: 5px;
    }
    .step-box:hover::after {
      display: inline-block;
      margin-top: 5px;
      width: 200px;
      line-height: 26px;
      font-size: 18px;
    }
    .step-box.step1:hover::after,
    .step-box.step1:focus::after {
      content: "Tell us about your \A brand & products";
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      white-space: pre-line;
    }
    .step-box.step2:hover::after,
    .step-box.step2:focus::after {
      content: "To get your products in \A front of buyers";
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      white-space: pre-line;
    }
    .step-box.step3:hover::after,
    .step-box.step3:focus::after {
      content: "Plus access tools & insights \A to help you win at retail.";
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      white-space: pre-line;
    }
  }
  .link-btn {
    margin-top: 40px;
  }
}
.center-container {
  text-align: center;
  margin-top: 134px;
  .link-btn {
    margin-top: 56px;
  }
}
.center-container > .playfair-h1 {
  text-align: center;
}
.bottom-banner {
  background-color: #e2eeec;
  padding: 50px 0;
  margin-top: 112px;
  .banner-container {
    max-width: 1140px;
    padding: 0 15px;
    margin: 0 auto;
    .extra-box {
      .card-body {
        .playfair-h1 {
          padding: 15px;
          padding-left: 0;
          margin-bottom: 10px;
        }
        .centerp {
          margin-bottom: 10px;
          font-size: 18px;
          color: #000000;
          font-family: "ProximaNova-Regular", "Proxima Nova", "Roboto", sans-serif;
        }
        .centerlist {
          list-style-type: disc;
          font-size: 18px;
          line-height: 21px;
          color: #000000;
          margin-bottom: 24px;
          text-align: left;
          li {
            list-style-position: inside;
            margin-bottom: 10px;
          }
        }
      }
      .card-body-right {
        padding-top: 30px;
        .get-list {
          margin: 35px 0;
        }
        .email-box {
          .email-element {
            display: flex;
            justify-content: space-between;
            input {
              border: none;
              border-bottom: 2px solid #333;
              width: 100%;
              background: none;
              margin-top: 25px;
              outline: none;
              width: 260px;
            }
            input::placeholder {
              line-height: 21px;
              padding-left: 8px;
              color: #757575;
            }
          }
          .email-element.active > input {
            border: 2px solid #e85c41;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1650px) {
  .brands .header-box .banner-brand {
    transform: translateX(-700px);
    max-width: 550px;
    top: 80px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1440px) {
  .brands {
    .header-box .banner-brand > p {
      max-width: 400px;
      float: right;
    }
    .bottom-banner .banner-container {
      max-width: 1140px;
      .email-box {
        transform: translateX(18px);
      }
    }
  }
}
@media screen and (min-width: 1199px) and (max-width: 1339px) {
  .brands {
    .header-box .banner-brand {
      padding: 75px 0 0 170px;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  a.link-btn {
    width: 196px;
  }
  .brands {
    .header-box .banner-brand {
      max-width: 430px;
      transform: translateX(-530px);
      top: 78px;
    }
    .works-step .brands-step {
      max-width: 960px;
    }
    .bottom-banner .banner-container {
      max-width: 960px;
      .extra-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .card-body,
        .card-body .centerlist {
          text-align: center;
        }
        .card-body-right {
          min-width: 540px;
          .get-list {
            text-align: center;
          }
          .link-btn {
            width: 268px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  a.link-btn {
    width: 196px;
  }
  .playfair-h1 {
    font-size: 42px;
  }
  .brands {
    .header-box {
      .banner-brand {
        transform: translateX(-440px);
        max-width: 350px;
      }
    }
    .frame-box {
      .card-body,
      .card-body-two {
        text-align: center;
        .playfair-h1 {
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          transform: translateX(-10px);
        }
      }
    }
    .works-step .brands-step {
      max-width: 720px;
      .step-box {
        width: 165px;
        height: 165px;
        padding: 8px;
        div {
          height: 60px;
        }
        h2 {
          font-size: 22px;
          line-height: 22px;
        }
        p {
          font-size: 16px;
        }
      }
      .step-box.step1 div,
      .step-box:hover.step1:hover div,
      .step-box.step2 div,
      .step-box:hover.step2:hover div,
      .step-box.step3 div,
      .step-box:hover.step3:hover div {
        background-size: 38px 50px;
      }
    }
    .bottom-banner .banner-container {
      max-width: 720px;
      text-align: center;
      .extra-box {
        .card-body .ant-col .ant-col-12,
        .card-body-right .ant-col .ant-col-12 {
          margin: 0 auto;
          float: none;
          .playfair-h1,
          ul {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1339px) {
  .frame-box {
    width: 100%;
    .card-body {
      padding: 0px 0 0 37px;
    }
  }
}
@media screen and (max-width: 992px) {
  .brands {
    .works-step .brands-step {
      .step-box.step1:hover::after,
      .step-box.step2:hover::after,
      .step-box.step3:hover::after {
        font-size: 13px;
        line-height: 14px;
      }
    }
    .banner-container {
      max-width: 960px;
      .extra-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .card-body .playfair-h1 {
          white-space: nowrap;
        }
        .card-body,
        .card-body .centerlist {
          text-align: center;
        }
        .card-body-right {
          min-width: 540px;
          .get-list {
            text-align: center;
          }
          .link-btn {
            width: 268px;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (max-width: 767px) {
  a.link-btn {
    width: 199px;
  }
  .playfair-h1 {
    font-size: 32px;
  }
  .brands {
    overflow: hidden;
    .header-box {
      background: url("../assets/img/homepage/banner/banner-deskto3-mobie.png") bottom/auto
        no-repeat;
      background-color: #f2e6f2;
      .banner-brand {
        padding: 102px 45px 0 45px;
        transform: translateX(-50%);
        text-align: center;
        top: 20px;
        z-index: 5;
        .playfair-h1 {
          font-size: 32px;
          padding: 0 20px;
          margin-bottom: 20px;
          line-height: 50px;
          text-align: center;
        }
        p {
          width: 300px;
          margin: 10px auto;
          font-size: 13px;
        }
        .link-btn {
          background-color: #ff6132;
          color: white;
        }
      }
      .banner-computer {
        transform: translate(-44%);
        width: 334px;
        height: 211px;
        top: 495px;
      }
    }
    .frame-box {
      width: 100vw;
      margin-bottom: 0;
      .ant-col-12 {
        width: 75%;
        max-width: 75%;
        padding: 0;
        text-align: center;
      }
    }
    .works-step {
      .brands-step {
        .one,
        .two,
        .three {
          background: none;
        }
        .step-box,
        .step-box:hover {
          width: 165px;
          height: 165px;
          padding: 8px;
          margin-bottom: 24px;
          div {
            height: 60px;
          }
          h2 {
            font-size: 22px;
            line-height: 22px;
          }
          p {
            font-size: 16px;
          }
        }
        .step-box.step1 div,
        .step-box:hover.step1:hover div,
        .step-box.step2 div,
        .step-box:hover.step2:hover div,
        .step-box.step3 div,
        .step-box:hover.step3:hover div {
          background-size: 38px 50px;
        }
      }
    }
  }
  .bottom-banner .banner-container {
    max-width: 720px;
    .extra-box {
      display: block;
      .card-body {
        margin-top: -20px;
        text-align: center;
        .playfair-h1 {
          max-width: 800px;
          padding: 15px 0;
          text-align: center;
          font-weight: bold;
          font-size: 32px;
        }
        .centerlist {
          width: 75%;
          margin: 20px auto;
          text-align: center;
        }
      }
      .card-body-right {
        .get-list {
          text-align: center;
          img {
            height: 48px;
          }
        }
        .email-element {
          float: none !important;
          margin: 0 auto 30px auto 0 !important;
          padding: 0;
          width: 275px !important;
          text-align: center;
          input {
            width: 75%;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  a.link-btn {
    width: 200px;
    max-width: 100%;
  }
  .card-body > ul {
    width: 100%;
    margin: 20px auto;
    .centerlist {
      text-align: center;
      list-style: none;
      margin: 8px auto;
      li::before {
        content: " ";
        display: inline-block;
        background-color: #000000;
        width: 5px;
        height: 5px;
        vertical-align: middle;
        margin-right: 8px;
        border-radius: 50%;
      }
    }
  }
}
@media (max-width: 450px) {
  .banner-brand {
    background-size: 400px;
  }
  .banner-computer {
    top: 577px;
    width: 214px;
    height: 133px;
    transform: translateX(-95px) !important;
  }
}
@media (max-width: 400px) {
  .brands {
    .banner-brand > p {
      width: auto;
    }
    .banner-computer {
      position: absolute;
      left: 50%;
      transform: translateX(-46%);
      max-width: 95%;
    }
    .step-box {
      width: 165px;
      height: 165px;
      margin-bottom: 20px;
      div {
        height: 60px;
      }
    }
  }
}
</style>
