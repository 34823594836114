<template>
  <div class="retailer_az">
    <div class="loading_wrap">
      <a-spin :spinning="loading"></a-spin>
      <RetailersAZ></RetailersAZ>
    </div>
  </div>
</template>
<script>
import RetailersAZ from "../retailers/RetailersAZ"
export default {
  name: "RetailerAZ",
  components: { RetailersAZ },
  data() {
    return {
      loading: false
    }
  }
}
</script>
<style lang="scss">
.retailer_az {
  &_title {
    font-family: Playfair Display;
    font-weight: bold;
    font-size: 48px;
    color: #262626;
  }
}
</style>
