import CryptoJS from "crypto-js"

export default {
  generateKey(num) {
    let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let key = ""
    for (let i = 0; i < num; i++) {
      let randomPoz = Math.floor(Math.random() * library.length)
      key += library.substring(randomPoz, randomPoz + 1)
    }
    return key
  },
  encrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : "fMA0GCSqGSIb3DQEBAQUAA4GNADCBiQK" //判断是否存在ksy，不存在就用定义好的key
    // console.log(`encrypt word: ${word}`)
    let key = CryptoJS.enc.Utf8.parse(keyStr)
    let srcs = CryptoJS.enc.Utf8.parse(word)
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.ciphertext.toString()
  },
  decrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : "fMA0GCSqGSIb3DQEBAQUAA4GNADCBiQK"
    let key = CryptoJS.enc.Utf8.parse(keyStr)
    const wordArray = CryptoJS.enc.Hex.parse(word)
    const base64Word = CryptoJS.enc.Base64.stringify(wordArray)
    let decrypt = CryptoJS.AES.decrypt(base64Word, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  }
}
