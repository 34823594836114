<template>
  <div class="setting-terms-new__main">
    <p class="transaction-style">Retailer Terms</p>
    <div v-if="isShowLoading" class="spin-style-admin">
      <a-spin tip="Loading..." />
    </div>
    <table v-else class="table is-fullwidth">
      <tbody class="admin-orders__table-header">
        <tr>
          <th class="admin-orders__table-head" min-width="150px">Retailer</th>
          <th class="admin-orders__table-head">SKU</th>
          <th class="admin-orders__table-head">Retailer Margin</th>
          <th class="admin-orders__table-head">Damages</th>
          <th class="admin-orders__table-head">Testers</th>
          <th class="admin-orders__table-head">Marketing Co-op</th>
        </tr>
      </tbody>
      <tbody v-for="(item, index) in tableList" :key="index" class="admin-orders__table-header">
        <tr>
          <td class="admin-orders__table-cell">{{ item.retailerName }}</td>
          <td class="admin-orders__table-cell">{{ formatSKU(item.sku) }}</td>
          <td class="admin-orders__table-cell">{{ item.margin }}%</td>
          <td class="admin-orders__table-cell">{{ item.damagesBudget }}%</td>
          <td class="admin-orders__table-cell">{{ item.testers }}%</td>
          <td class="admin-orders__table-cell">{{ item.marketingCoOp }}%</td>
        </tr>
      </tbody>
    </table>
    <a-pagination
      v-if="!isShowLoading"
      v-model="paginationValue"
      :default-current="1"
      :default-page-size="30"
      :total="total"
      @change="handlePagination"
    ></a-pagination>
  </div>
</template>

<script>
import BrandClient from "@/api/brand-client"
export default {
  name: "TermsNew",
  data() {
    return {
      isShowLoading: false,
      retailerList: [],
      paginationValue: 1,
      tableList: [],
      total: 0
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    }
  },
  async created() {
    this.isShowLoading = true
    try {
      const { data } = await BrandClient.getRetailerTerms(this.brand.id)
      this.retailerList = data
      this.total = this.retailerList.length
      this.isShowLoading = false
      this.handlePagination()
    } catch (e) {
      this.isShowLoading = false
      this.retailerList = []
    }
  },
  methods: {
    formatSKU(value) {
      if (value.includes("*") && value.length === 1) {
        return "All Product"
      }
      return value
    },
    async handlePagination(page) {
      this.paginationValue = page
      const value = page ? page : 1
      this.tableList = this.retailerList.slice((value - 1) * 30, value * 30)
    }
  }
}
</script>

<style scoped lang="scss">
.setting-terms-new__main {
  .transaction-style {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .admin-orders__table-head {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    //border-bottom: 1px solid #8c8c8c;
    //cursor: pointer;
  }
  .admin-orders__table-header {
    tr {
      border-bottom: 1px solid #8c8c8c;
    }
  }
  .admin-orders__table-cell {
    color: #262626;
    font-size: 14px;
    font-weight: 400;
    //border-bottom: 1px solid #8c8c8c;
    margin-top: 10px;
    position: relative;
    a {
      color: #333333;
    }
  }
}

.spin-style-admin {
  text-align: center;
  margin: 40px 0;
}
</style>
