var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-transfer"},[_c('p',[_vm._v(" Please complete this contact form. A member of our team will be in touch to confirm details for your wire transfer. ")]),_c('a-form',{attrs:{"layout":"horizontal","form":_vm.form}},[_c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'firstName',
        {
            rules: [
                {
                    required: true,
                    message: 'First Name is required',
                },
            ],
        },
    ]),expression:"[\n        'firstName',\n        {\n            rules: [\n                {\n                    required: true,\n                    message: 'First Name is required',\n                },\n            ],\n        },\n    ]"}],attrs:{"placeholder":"First Name"}})],1)],1),_c('div',{staticClass:"column"},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'lastName',
        {
            rules: [
                {
                    required: true,
                    message: 'Last Name is required',
                },
            ],
        },
    ]),expression:"[\n        'lastName',\n        {\n            rules: [\n                {\n                    required: true,\n                    message: 'Last Name is required',\n                },\n            ],\n        },\n    ]"}],attrs:{"placeholder":"Last Name"}})],1)],1)])]),_c('div',[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'email',
        {
            rules: [
                // {
                //     validator: validateEmail,
                // },
                {
                    required: true,
                    message: 'E-mail is required',
                },
            ],
        },
    ]),expression:"[\n        'email',\n        {\n            rules: [\n                // {\n                //     validator: validateEmail,\n                // },\n                {\n                    required: true,\n                    message: 'E-mail is required',\n                },\n            ],\n        },\n    ]"}],attrs:{"placeholder":"Email"}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }