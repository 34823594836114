export const nullSafeProduct = {
  methods: {
    nullSafeProduct(product) {
      const barcodes =
        product.barcodes && product.barcodes.length ? product.barcodes : [{ code: "" }]
      const microCategory = product.microCategory ? product.microCategory : { id: null }
      const subcategory = product.subcategory ? product.subcategory : { id: null }
      const category = product.category ? product.category : { id: null }
      const type = product.type ? product.type : { id: null }
      const size = product.size ? product.size : null
      const sizeUnits = product.sizeUnits ? product.sizeUnits : { id: null }
      const msrpUsd = product.msrpUsd ? product.msrpUsd : null
      const description = product.description ? product.description : null
      const newBenefits = product.newBenefits
        ? product.newBenefits
        : [{ name: "" }, { name: "" }, { name: "" }]
      const benefits = product.benefits ? product.benefits : null
      const newIngredients = product.newIngredients
        ? product.newIngredients.length === 0
          ? []
          : product.newIngredients
        : []
      const ingredients = product.ingredients ? product.ingredients : null
      const values = product.values ? product.values : []
      const countryOfOrigin = product.countryOfOrigin ? product.countryOfOrigin : { id: null }
      if (newBenefits.length < 3) {
        let count = 3 - newBenefits.length
        for (let i = 0; i < count; i++) {
          newBenefits.push({ name: "" })
        }
      }

      return {
        ...product,
        barcodes,
        microCategory,
        type,
        subcategory,
        category,
        size,
        sizeUnits,
        msrpUsd,
        countryOfOrigin,
        description,
        benefits,
        newBenefits,
        newIngredients,
        values,
        ingredients
      }
    }
  }
}
