<template>
  <div ref="adminLandingTerms" class="admin-landing-terms__main">
    <div class="landing-terms-title">
      <p>Logistics by Landing</p>
      <p v-if="!checkStandard" class="add-new-terms-button" @click="termsVisible = !termsVisible">
        ADD MULTIPLE LANDING TERMS
      </p>
    </div>
    <div v-if="isShowLoading" class="spin-style-admin">
      <a-spin tip="Loading..." />
    </div>
    <div v-else class="landing-terms-content">
      <div class="check-standard-style">
        <div v-if="checkStandard">
          <div class="standard-title-info">
            <div>
              <span class="title-second-style">Standard</span>
              <a-switch
                v-model="logisticsStandard.enabled"
                :default-checked="false"
                @change="changeStandard"
              />
            </div>
            <span
              v-if="
                logisticsStandard.enabled && brand.logisticTerms && brand.logisticTerms.length !== 0
              "
              class="standard-edit"
              @click="openEditDialog(logisticsStandard, true)"
              ><a-icon type="edit"></a-icon
            ></span>
          </div>
          <div v-if="isShowStandardLoading" class="spin-style-admin">
            <a-spin tip="Loading..." />
          </div>
          <div v-else>
            <div
              v-if="
                logisticsStandard.enabled && brand.logisticTerms && brand.logisticTerms.length !== 0
              "
            >
              <p class="table-terms-standard">
                <span>Fulfillment</span>
                <span>
                  {{ logisticsStandard.fulfillmentValue }}%
                  <a-icon
                    v-if="logisticsStandard.accepted"
                    type="check"
                    :style="{ color: '#ff6132', marginLeft: '10px' }"
                  />
                </span>
              </p>
              <p class="table-terms-standard">
                <span>Shipping</span>
                <span>
                  {{ logisticsStandard.shippingValue }}%
                  <a-icon
                    v-if="logisticsStandard.accepted"
                    type="check"
                    :style="{ color: '#ff6132', marginLeft: '10px' }"
                  />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <span class="title-second-style">Other</span>
        <div v-if="isShowOtherLoading" class="spin-style-admin">
          <a-spin tip="Loading..." />
        </div>
        <div v-else>
          <div v-if="logisticsList && logisticsList.length !== 0">
            <div
              v-for="value in logisticsList"
              :key="value.privateId"
              class="admin-other-terms-content"
            >
              <div class="admin-other-name">
                <p class="name-left">
                  <span>{{ value.name }}</span>
                  <a-switch
                    v-model="value.enabled"
                    :default-checked="false"
                    @change="changeOtherValue(value)"
                  />
                </p>
                <p class="name-right">
                  <a-popconfirm
                    title="Are you sure you want to delete this?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="confirmDelete(value)"
                  >
                    <a-icon slot="icon" type="exclamation-circle" style="color: red" />
                    <a-icon type="delete" :style="{ marginRight: '12px' }" />
                  </a-popconfirm>
                  <a-icon
                    type="edit"
                    :style="{ marginRight: '12px' }"
                    @click="openEditDialog(value, false)"
                  ></a-icon>
                  <a-icon :type="value.isOpenInfo ? 'up' : 'down'" @click="modifyIcon(value)" />
                </p>
              </div>
              <div v-if="value.isOpenInfo" class="admin-other-number">
                <div class="other-number-item">
                  <p class="number-name">Fulfillment</p>
                  <p>
                    {{ value.fulfillmentValue }}%
                    <a-icon
                      v-if="value.accepted"
                      type="check"
                      :style="{ color: '#ff6132', marginLeft: '10px' }"
                    />
                  </p>
                </div>
                <div class="other-number-item">
                  <p class="number-name">Shipping</p>
                  <p>
                    {{ value.shippingValue }}%
                    <a-icon
                      v-if="value.accepted"
                      type="check"
                      :style="{ color: '#ff6132', marginLeft: '10px' }"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="add-button-style" @click="addOtherTerms">+Add</p>
      </div>
    </div>
    <div>
      <a-modal
        :mask-closable="false"
        :centered="true"
        width="990px"
        :visible="termsVisible"
        :footer="null"
        :get-container="() => $refs.adminLandingTerms"
        @cancel="handleCancel"
      >
        <p slot="title" class="logistic-modal-title">Add multiple Landing Terms</p>
        <div class="modal-terms-content__main">
          <div class="other-terms-item">
            <a-checkbox v-model="standard" :indeterminate="standard">
              Standard
            </a-checkbox>
            <div class="add-landing-terms-number">
              <div>
                <p class="dialog-text-style">Fulfillment</p>
                <a-input-search v-model="standardFulfillment" style="width: 294px">
                  <a-button slot="enterButton" :disabled="true">
                    %
                  </a-button>
                </a-input-search>
              </div>
              <div class="add-landing-terms-two">
                <p class="dialog-text-style">Shipping</p>
                <a-input-search v-model="standardShipping" style="width: 294px">
                  <a-button slot="enterButton" :disabled="true">
                    %
                  </a-button>
                </a-input-search>
              </div>
            </div>
          </div>
          <div class="other-terms-item">
            <a-checkbox v-model="other" :indeterminate="other">
              Other
            </a-checkbox>
            <div class="dialog-text-style">Retailer</div>
            <a-select v-model="retailerValue" style="width: 294px" placeholder="value">
              <a-select-option v-for="item in retailer" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <div class="add-landing-terms-number">
              <div>
                <p class="dialog-text-style">Fulfillment</p>
                <a-input-search v-model="otherFulfillment" style="width: 294px">
                  <a-button slot="enterButton" :disabled="true">
                    %
                  </a-button>
                </a-input-search>
              </div>
              <div class="add-landing-terms-two">
                <p class="dialog-text-style">Shipping</p>
                <a-input-search v-model="otherAddShipping" style="width: 294px">
                  <a-button slot="enterButton" :disabled="true">
                    %
                  </a-button>
                </a-input-search>
              </div>
            </div>
          </div>
        </div>
        <p class="logistic-modal-button">
          <span class="accept-text-terms" @click="handleAccept">Accept</span>
        </p>
      </a-modal>
    </div>
    <a-modal
      :mask-closable="false"
      :centered="true"
      width="342px"
      :visible="otherAddVisible"
      :footer="null"
      @cancel="handleCancelAdd"
    >
      <p slot="title" class="logistic-modal-title">Logistics Terms</p>
      <div v-if="showRetailerSelect && !isStandard" class="add-other-info">
        <div>Retailer</div>
        <a-select
          v-if="isAdd"
          show-search
          style="width: 294px"
          :filter-option="filterOption"
          :get-popup-container="triggerNode => triggerNode.parentNode"
          placeholder="value"
          @change="handleSelectValue"
        >
          <a-select-option v-for="item in retailerList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-else
          v-model="retailerName"
          :disabled="true"
          style="width: 294px"
          :get-popup-container="triggerNode => triggerNode.parentNode"
          placeholder="value"
          @change="handleSelectValue"
        >
          <a-select-option v-for="item in retailerList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="add-other-info">
        <div>Fulfillment</div>
        <a-input-search v-model="otherFulfill" style="width: 294px">
          <a-button slot="enterButton" :disabled="true">
            %
          </a-button>
        </a-input-search>
      </div>
      <div class="add-other-info">
        <div>Shipping</div>
        <a-input-search v-model="otherShipping" style="width: 294px">
          <a-button slot="enterButton" :disabled="true">
            %
          </a-button>
        </a-input-search>
      </div>
      <p class="logistic-modal-button">
        <span class="accept-text-terms" @click="handleAddOther">ADD</span>
      </p>
    </a-modal>
  </div>
</template>

<script>
import OrderClient from "@/api/order-client"

export default {
  name: "AdminLandingTerms",
  data() {
    return {
      showInfo: false,
      standard: true,
      other: true,
      termsVisible: false,
      otherAddVisible: false,
      standardFulfillment: "12.5",
      standardShipping: "5",
      retailerValue: null,
      otherFulfillment: "12.5",
      otherShipping: "5",
      otherAddShipping: "5",
      otherFulfill: "12.5",
      retailerCheckValue: null,
      retailers: [],
      logisticsStandard: {
        enabled: false,
        accepted: false,
        fulfillmentValue: 12.5,
        shippingValue: 5
      },
      logisticsList: [],
      showAddStandard: false,
      showRetailerSelect: true,
      isAdd: false,
      editPriv: null,
      isStandard: false,
      isShowLoading: false,
      isShowStandardLoading: false,
      isShowOtherLoading: false,
      retailerList: [],
      retailerName: null
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    retailer() {
      return this.$store.getters["retailer/retailers"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    checkStandard() {
      // Data redundancy caused by old data
      // const list = this.brand.logisticTerms.filter(
      //   item => item.brandId === "*" && item.standard && item.retailerId === "*"
      // )
      const list2 = this.brand.logisticTerms.filter(
        item => item.brandId !== "*" && item.standard && item.retailerId === "*"
      )
      // Make standard display judgments based on this location
      // const isLogisticStandard = list2 && list2.length !== 0 ? !list2[0].enabled : false
      // return (
      //   (list2 && list2.length !== 0 && isLogisticStandard) ||
      //   (this.brand.logisticTerms.length === 1 && list.length === 1)
      // )
      return list2 && list2.length !== 0
    }
  },
  async mounted() {
    this.isShowLoading = true
    await this.$store.dispatch("brand/getBrand", this.brandId)
    await this.$store.dispatch("retailer/fetchAllRetailers", { params: { fields: "name,id" } })
    this.retailerList = this.retailer
    await this.formatParams()
  },
  methods: {
    async formatParams() {
      this.logisticsList = []
      if (this.brand.logisticTerms && this.brand.logisticTerms.length !== 0) {
        // this.logisticsStandard = this.brand.logisticTerms.filter(item => item.standard)[0]
        const standardList = this.brand.logisticTerms.filter(
          item => item.brandId.length > 1 && item.standard && item.retailerId === "*"
        )
        if (standardList && standardList.length === 0) {
          this.logisticsStandard = this.brand.logisticTerms.filter(
            item => item.brandId === "*" && item.standard && item.retailerId === "*"
          )[0]
        } else {
          this.logisticsStandard = standardList[0]
        }
        const list = this.brand.logisticTerms.filter(
          value => !value.standard && value.retailerId !== "*"
        )
        if (list && list.length !== 0) {
          list.forEach(async item => {
            const obj = await this.getRetailer(item.retailerId)
            item.name = obj.name
            item.isOpenInfo = false
            this.logisticsList.push(item)
          })
        }
      }
      this.isShowLoading = false
      this.isShowOtherLoading = false
      this.isShowStandardLoading = false
    },
    handleCancel() {
      this.termsVisible = false
    },
    handleCancelAdd() {
      this.otherAddVisible = false
    },
    addOtherTerms() {
      this.otherAddVisible = true
      this.showRetailerSelect = true
      this.isAdd = true
      this.retailerName = null
      this.isStandard = false
      this.retailerList = this.retailer
      this.otherFulfill = "12.5"
      this.otherShipping = "5"
    },
    openEditDialog(obj, isStandard) {
      this.otherAddVisible = true
      this.isAdd = false
      // this.retailerValue = obj.retailerId
      this.retailerName = obj.retailerId
      this.otherFulfill = obj.fulfillmentValue
      this.otherShipping = obj.shippingValue
      this.editPriv = obj.privateId
      this.isStandard = isStandard
    },
    confirmDelete(item) {
      const action = "remove"
      const params = [item]
      this.fetchLogisticTerms(action, params)
    },
    modifyIcon(obj) {
      this.logisticsList = this.logisticsList.map(item => {
        if (item.privateId === obj.privateId) {
          item.isOpenInfo = !item.isOpenInfo
        }
        return item
      })
    },
    async fetchLogisticTerms(action, params) {
      await OrderClient.updateLogisticTerms(this.brandId, action, params)
      await this.$store.dispatch("brand/getBrand", this.brandId)
      this.formatParams()
    },
    async handleAccept() {
      this.showInfo = true
      this.isShowLoading = true
      this.termsVisible = false
      let data = []
      if (this.standard) {
        const standardInfo = {
          retailerId: null,
          fulfillmentValue: this.standardFulfillment,
          shippingValue: this.standardShipping,
          standard: true,
          enabled: this.standard,
          accepted: false
        }
        data.push(standardInfo)
      }
      if (this.other) {
        const otherInfo = [
          {
            retailerId: this.retailerValue,
            fulfillmentValue: this.otherFulfillment,
            shippingValue: this.otherAddShipping,
            standard: false,
            enabled: this.other,
            accepted: false
          }
        ]
        data = data.concat(otherInfo)
      }
      const action = "add"
      await this.fetchLogisticTerms(action, data)
    },
    async handleAddOther() {
      this.otherAddVisible = false
      this.isShowOtherLoading = true
      let otherInfo
      let data
      let action = "update"
      // If operating on Standard:
      // 1. Only initial data is available. Use add for the first operation
      // 2. If there is independent data, update data directly
      if (this.isStandard) {
        data = [
          {
            retailerId: this.retailerName,
            fulfillmentValue: Number(this.otherFulfill),
            shippingValue: Number(this.otherShipping),
            standard: true,
            enabled: this.logisticsStandard.enabled,
            accepted: false,
            privateId: this.editPriv
          }
        ]
        if (!this.checkDefaultLogisticValue()) {
          action = "add"
          data[0].retailerId = "*"
          delete data[0].privateId
        }
      } else {
        // If operating on Standard:update data directly
        otherInfo = [
          {
            retailerId: this.retailerName,
            fulfillmentValue: Number(this.otherFulfill),
            shippingValue: Number(this.otherShipping),
            standard: false,
            enabled: this.other,
            accepted: false,
            privateId: this.editPriv
          }
        ]
        if (this.isAdd) {
          action = "add"
          delete otherInfo[0].privateId
        }
      }
      const fanalValue = this.isStandard ? data : otherInfo
      await this.fetchLogisticTerms(action, fanalValue)
      this.editPriv = null
      this.retailerName = null
      this.isStandard = false
    },
    async changeStandard() {
      if (this.brand.logisticTerms && this.brand.logisticTerms.length === 0) {
        this.otherAddVisible = true
        this.showRetailerSelect = false
        this.isAdd = true
        return
      }
      let params = [
        {
          ...this.logisticsStandard
          // enabled: this.logisticsStandard.enable,
        }
      ]
      let action = "update"
      const standardList = this.brand.logisticTerms.filter(
        item => item.brandId.length > 1 && item.standard && item.retailerId === "*"
      )
      if (standardList && standardList.length === 0) {
        action = "add"
        delete params[0].privateId
      } else {
        action = "update"
      }
      // const list = this.brand.logisticTerms.filter(item => item.privateId !== data[0].privateId)
      // const params = list.concat(data)
      await this.fetchLogisticTerms(action, params)
    },
    async changeOtherValue(value) {
      this.isShowOtherLoading = true
      const params = [
        {
          ...value
        }
      ]
      // const list = this.brand.logisticTerms.filter(item => item.privateId !== data[0].privateId)
      // const params = list.concat(data)
      const action = "update"
      await this.fetchLogisticTerms(action, params)
    },
    async getRetailer(retailerId) {
      try {
        return this.$store.dispatch("retailer/fetchRetailer", retailerId)
      } catch (e) {
        console.log(e)
      }
    },
    handleSelectValue(value) {
      this.retailerName = value
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    checkDefaultLogisticValue() {
      const list = this.brand.logisticTerms.filter(
        item => item.brandId.length > 1 && item.standard && item.retailerId === "*"
      )
      return list && list.length !== 0
    }
  }
}
</script>

<style lang="scss">
.admin-landing-terms__main {
  p {
    margin-bottom: 0;
  }
  .landing-terms-title {
    font-size: 28px;
    color: #000000;
    font-weight: 700;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add-new-terms-button {
      width: 212px;
      height: 32px;
      color: white;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      border-radius: 2px;
      cursor: pointer;
      background-color: #ff6132;
    }
  }
  .landing-terms-content {
    .check-standard-style {
      margin-bottom: 80px;
      min-height: 40px;
      span {
        margin-right: 20px;
      }
    }
  }
  .title-second-style {
    font-size: 24px;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .table-terms-standard {
    height: 46px;
    line-height: 46px;
    margin-bottom: 0;
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    span {
      display: inline-block;
      width: 280px;
    }
  }
  .table-terms-standard:nth-child(2) {
    border-top: none;
  }
  //.no-border {
  //  border: none;
  //  height: 25px;
  //}
  .special-span-style {
    display: block;
  }
  //.header-collapse-title__name {
  //  margin-top: 10px;
  //  margin-bottom: 10px;
  //  span {
  //    margin-right: 20px;
  //  }
  //}
  .add-button-style {
    width: 50px;
    cursor: pointer;
    text-decoration: underline;
    color: #ff6132;
  }
  .admin-other-terms-content {
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    padding: 12px 10px;
    .admin-other-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name-left {
        span {
          margin-right: 20px;
        }
      }
      .name-right {
        span {
          display: inline-block;
          width: 60px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #595959;
          background-color: #f5f5f5;
          border: 1px solid #595959;
          border-radius: 2px;
          cursor: pointer;
          margin-right: 20px;
        }
      }
    }
    .admin-other-number {
      padding: 0 20px;
      .other-number-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 13px;
        .number-name {
          width: 280px;
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .admin-other-terms-content:nth-child(n + 1) {
    border-top: none;
  }
  .admin-other-terms-content:nth-child(1) {
    border-top: 1px solid #595959;
  }
  .ant-switch-checked {
    background-color: #ff6132;
  }
  .standard-title-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .standard-edit {
    //display: inline-block;
    //width: 60px;
    //height: 24px;
    //line-height: 20px;
    //text-align: center;
    color: #595959;
    //background-color: #f5f5f5;
    //border: 1px solid #595959;
    //border-radius: 2px;
    cursor: pointer;
    margin-right: 20px;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #ff6132 !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white !important;
    border-color: #ff6132 !important;
  }
  .ant-checkbox-wrapper {
    margin-left: -27px !important;
    color: #262626 !important;
    font-weight: 600 !important;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
}
.modal-terms-content__main {
  padding-left: 200px;
}
.accept-text-terms {
  background-color: #ff6132;
  color: white;
}
.add-landing-terms-number {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    margin-bottom: 0;
  }
}
.add-landing-terms-two {
  margin-left: 80px;
}
.other-terms-item {
  margin-bottom: 20px;
}
.add-other-info {
  margin-bottom: 15px;
  font-size: 14px;
  color: #000000;
}
.spin-style-admin {
  text-align: center;
  margin: 40px 0;
}
//.ant-checkbox-wrapper {
//  margin-left: -27px !important;
//  color: #262626 !important;
//  font-weight: 600 !important;
//  font-family: "Proxima Nova", "Roboto", sans-serif;
//}
.dialog-text-style {
  font-size: 14px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 14px;
}
</style>
