<template>
  <div class="agency-retailer">
    <div class="agency-retailer__carousel">
      <div class="agency-retailer__arrows">
        <div @click="next(-1)">
          <img src="../../assets/img/agency/retailer/arrow-left.png" alt="" />
        </div>
        <div @click="next()">
          <img src="../../assets/img/agency/retailer/arrow-right.png" alt="" />
        </div>
      </div>
      <a-carousel ref="carousel" :dot-position="`bottom`" arrows autoplay>
        <div
          v-for="(item, index) in retailer.carousels"
          :key="index"
          :style="
            `background-image: url(${require(`../../assets/img/agency/retailer/${carouselImageNames[index]}`)})`
          "
          class="agency-retailer__carousel-item"
        >
          <div
            :class="[
              `agency-retailer__carousel-item__content-wrapper`,
              index === 1 || index === 2
                ? 'agency-retailer__carousel-item__content-wrapper-left'
                : '',
              !item.buttonFillText ? 'agency-retailer__carousel-item__another-position' : ''
            ]"
          >
            <div class="agency-retailer__carousel-item__content-wrapper__title">
              {{ item.title }}
            </div>
            <div class="agency-retailer__carousel-item__content-wrapper__sub-title">
              {{ item.subTitle }}
            </div>
            <a
              v-if="item.buttonFillText"
              @click="clickToRetailerRegistration"
              class="agency-retailer__carousel-item__content-wrapper__button agency-retailer__button"
            >
              {{ item.buttonFillText }}
            </a>
          </div>
        </div>
      </a-carousel>
    </div>
    <div class="agency-retailer__wrapper">
      <div
        v-for="(item, index) in retailer.content"
        :key="index"
        class="agency-retailer__content-section"
      >
        <template v-if="(index + 1) % 2 === 1">
          <div
            class="agency-retailer__content-section__content agency-retailer__content-section__content-left"
          >
            <div class="agency-retailer__content-section__content__title">{{ item.title }}</div>
            <div
              v-for="(secItem, secIndex) in item.descriptions"
              :key="secIndex"
              class="agency-retailer__content-section__content__description"
            >
              <div>•</div>
              <div>{{ secItem }}</div>
            </div>
            <a
              @click="clickToRetailerRegistration"
              class="agency-retailer__content-section__content__button agency-retailer__button"
              >{{ item.buttonFillText }}</a
            >
          </div>
          <div
            class="agency-retailer__content-section__image agency-retailer__content-section__image-right"
          >
            <img
              :src="require(`../../assets/img/agency/retailer/${contentImageNames[index]}`)"
              alt=""
            />
          </div>
        </template>
        <template v-else>
          <div
            class="agency-retailer__content-section__image agency-retailer__content-section__image-left"
          >
            <img
              :src="require(`../../assets/img/agency/retailer/${contentImageNames[index]}`)"
              alt=""
            />
          </div>
          <div
            class="agency-retailer__content-section__content agency-retailer__content-section__content-right"
          >
            <div class="agency-retailer__content-section__content__title">{{ item.title }}</div>
            <div
              v-for="(secItem, secIndex) in item.descriptions"
              :key="secIndex"
              class="agency-retailer__content-section__content__description"
            >
              <div>•</div>
              <div>{{ secItem }}</div>
            </div>
            <a
              @click="clickToRetailerRegistration"
              class="agency-retailer__content-section__content__button agency-retailer__button"
              >{{ item.buttonFillText }}</a
            >
          </div>
        </template>
      </div>
      <div class="agency-retailer__how-it-works">
        <div class="agency-retailer__how-it-works__title">{{ retailer.howItWorks.title }}</div>
        <div class="agency-retailer__how-it-works__works">
          <div
            v-for="(item, index) in retailer.howItWorks.works"
            :key="index"
            :class="[
              activeStepIndex === index ? 'agency-retailer__how-it-works__works__step-active' : ''
            ]"
            @mouseover="activeStepIndex = index"
            @mouseout="activeStepIndex = NaN"
          >
            <div
              :class="[
                `agency-retailer__how-it-works__works__step`,
                `agency-retailer__how-it-works__works__step-${index + 1}`,
                activeStepIndex === index
                  ? `agency-retailer__how-it-works__works__step-${index + 1}-active`
                  : ''
              ]"
            ></div>
            <div
              v-show="activeStepIndex !== index"
              class="agency-retailer__how-it-works__works__section-1"
            >
              {{ item.section1 }}
            </div>
            <div
              v-show="activeStepIndex !== index"
              class="agency-retailer__how-it-works__works__section-2"
            >
              {{ item.section2 }}
            </div>
            <div
              v-show="activeStepIndex === index"
              class="agency-retailer__how-it-works__works__description"
            >
              {{ item.description }}
            </div>
          </div>
        </div>
        <a
          @click="clickToRetailerRegistration"
          class="agency-retailer__how-it-works__button agency-retailer__button"
          >{{ retailer.howItWorks.buttonFillText }}</a
        >
      </div>
    </div>
    <div class="agency-retailer__sign-up-wrapper">
      <div class="agency-retailer__sign-up">
        <div class="agency-retailer__sign-up__features">
          <div class="agency-retailer__sign-up__features__title">{{ retailer.features.title }}</div>
          <ul>
            <li v-for="(item, index) in retailer.features.list" :key="index">{{ item }}</li>
          </ul>
        </div>
        <div class="agency-retailer__sign-up__form">
          <div class="agency-retailer__sign-up__form__title">{{ retailer.formText.title }}</div>
          <div class="agency-retailer__sign-up__form__sign-up-box">
            <input
              type="email"
              :placeholder="retailer.formText.inputPlaceholder"
              onfocus="this.placeholder=''"
              autocomplete="on"
              @blur="handleBlur"
            />
            <a
              href="https://landinginternational.us14.list-manage.com/subscribe/post?u=e3f5833e4e09791a8c1d76bc1&id=38b0f801fc"
              class="agency-retailer__sign-up__form__sign-up-box__submit agency-retailer__button"
              >{{ retailer.formText.buttonFillText }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeStepIndex: NaN,
      carouselImageNames: [
        "banner_bg_1.png",
        "banner_bg_2.jpg",
        "banner_bg_3.jpg",
        "banner_bg_4.png"
      ],
      contentImageNames: ["retailers.gif", "eliminate.gif"]
    }
  },
  computed: {
    retailer() {
      return this.$t("Retailer")
    }
  },
  methods: {
    next(isPrev) {
      if (isPrev) {
        return this.$refs.carousel.prev()
      }
      this.$refs.carousel.next()
    },
    handleBlur(e) {
      e.target.placeholder = this.retailer.formText.inputPlaceholder
    },
    clickToRetailerRegistration() {
      this.$router.push({
        name: "retailerRegistration"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/shared/_fonts";

.agency-retailer {
  width: 100%;

  &__button {
    cursor: pointer;
    user-select: none;
    line-height: 41px !important;
    border: 2px solid #ff6132;

    &:hover {
      background-color: #ff6132;
      color: #fff;
      transition: all 0.1s linear;
    }

    @media screen and (max-width: 899px) {
      margin-top: 20px;
      align-self: center;
    }
  }

  &__wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
  }

  &__carousel {
    position: relative;
    margin: 0 auto 145px auto;
    width: 100%;
    height: 443px;
  }

  &__arrows {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 104px;
    height: 40px;
    right: 29px;
    bottom: 23px;
    z-index: 9;

    @media screen and (max-width: 899px) {
      right: calc(50% - 52px);
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: #fff;
      background-size: 100% 100%;
      // box-shadow: 0px 2px 4px rgba(220, 220, 220, 0.5);
      box-shadow: 1px 1px 4px #aaa;
      cursor: pointer;

      &:hover {
        box-shadow: 1px 1px 6px #aaa;

        img {
          opacity: 0.5;
        }
      }

      img {
        display: block;
        margin: 0;
        width: 8px;
        height: 12px;
        opacity: 0.3;
        user-select: none;
      }
    }
  }

  &__carousel-item {
    height: 443px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;

    &__another-position {
      top: 133px !important;
    }

    @media screen and (max-width: 1440px) {
      background-size: auto 100%;
    }

    &__content-wrapper-left {
      left: 157px;
      text-align: left !important;

      @media screen and (max-width: 899px) {
        text-align: center !important;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 91px;
      right: 195px;
      width: 669px;
      height: 251px;
      text-align: right;

      @media screen and (max-width: 1300px) and (min-width: 900px) {
        right: 66px;
      }

      @media screen and (max-width: 899px) {
        width: 378px;
        top: 51px;
        right: calc(50% - 378px / 2);
        text-align: center;
      }

      &__title {
        font-family: "Playfair Display Bold";
        font-weight: bold;
        font-size: 48px;
        line-height: 52px;
        color: #12433d;
        white-space: pre-line;

        @media screen and (max-width: 900px) {
          font-size: 40px;
        }

        @media screen and (max-width: 899px) {
          font-size: 32px;
          line-height: 43px;
          text-align: center;
        }
      }

      &__sub-title {
        margin-top: 20px;
        font-size: 25px;
        line-height: 28px;
        font-family: "Proxima";
        color: #2c2e30;
        white-space: pre-line;

        @media screen and (max-width: 899px) {
          font-size: 16px;
          line-height: 28px;
          text-align: center;
        }
      }

      &__button {
        display: block;
        margin-top: 24px;
        width: 268px;
        height: 43px;
        box-sizing: border-box;
        border-radius: 27.5px;
        text-align: center;
        font-size: 16px;
        line-height: 43px;
        font-family: "Proxima";
        color: #000;
        align-self: flex-end;
        user-select: none;
        cursor: pointer;
        background-color: #fff;
        transition: background-color 0.1s linear;

        @media screen and (max-width: 899px) {
          align-self: center;
        }
      }
    }
  }

  &__content-section {
    margin: 0 auto 150px auto;
    display: flex;
    justify-content: space-between;
    width: 952px;

    @media screen and (max-width: 999px) and (min-width: 800px) {
      width: 100%;
      justify-content: center;
    }

    @media screen and (max-width: 799px) {
      margin: 0 auto 100px auto;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    &__content {
      @media screen and (max-width: 799px) {
        margin: 0 auto;
        text-align: center;
        width: 100%;
      }

      @media screen and (max-width: 799px) {
        display: block !important;
      }

      &__title {
        margin-bottom: 24px;
        font-family: "Playfair Display";
        font-weight: bold;
        font-size: 48px;
        line-height: 52px;
        color: #12433d;
        white-space: pre-line;

        @media screen and (max-width: 999px) and (min-width: 900px) {
          font-size: 40px;
        }

        @media screen and (max-width: 799px) {
          font-size: 32px;
          line-height: 43px;
          margin-bottom: 20px;
        }
      }

      &__description {
        display: flex;
        margin-bottom: 18px;
        font-size: 18px;
        line-height: 21px;
        font-family: "Proxima Nova";
        color: #000000;
        overflow: hidden;

        @media screen and (max-width: 799px) {
          display: flex;
          justify-content: center;
        }

        > div:first-child {
          margin-right: 7px;
        }

        > div:last-child {
          @media screen and (max-width: 799px) {
            overflow: initial;
          }
        }
      }

      &__button {
        display: block;
        box-sizing: border-box;
        margin-top: 24px;
        width: 268px;
        height: 43px;
        line-height: 43px;
        border-radius: 21.5px;
        text-align: center;
        font-size: 16px;
        font-family: "Proxima";
        color: #000;
        background-color: #fff;
        transition: background-color 0.1s linear;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: #ff6132;
          color: #fff;
          transition: all 0.1s linear;
        }

        @media screen and (max-width: 799px) {
          margin: 20px auto;
        }
      }
    }

    &__image {
      img {
        width: 100%;
        height: 100%;
      }
    }

    &__content-left {
      .agency-retailer__content-section__content__title {
        padding: 15px 0px;
      }
    }

    @media screen and (max-width: 999px) and (min-width: 800px) {
      &__content-left,
      &__image-left {
        margin-right: 29px;
      }
    }

    @media screen and (min-width: 1000px) {
      &__content-left {
        width: 454px;
      }

      &__image-right {
        width: 475px;
        height: 331px;
      }

      &__content-right {
        width: 475px;
      }

      &__image-left {
        width: 445px;
        height: 371px;
      }
    }

    @media screen and (max-width: 999px) {
      &__content-left {
        width: 375px;
      }

      &__image-right {
        width: 416px;
        height: 290px;
      }

      &__content-right {
        width: 375px;
      }

      &__image-left {
        width: 416px;
        height: 310px;
      }
    }

    @media screen and (max-width: 799px) {
      &__content-left {
        width: 383px;
      }

      &__image-right {
        width: 300px;
        height: 209px;
      }

      &__content-right {
        order: 0;
        width: 520px;

        .agency-retailer__content-section__content__title {
          white-space: normal !important;
        }
      }

      &__image-left {
        order: 1;
        width: 300px;
        height: 223px;
      }
    }
  }

  &__how-it-works {
    &__title {
      margin: 150px auto 0 auto;
      font-weight: bold;
      font-size: 48px;
      line-height: 52px;
      font-family: "Playfair Display";
      text-align: center;
      color: #12433d;

      @media screen and (max-width: 900px) and (min-width: 601px) {
        font-size: 42px;
        margin: 130px auto 0 auto;
      }

      @media screen and (max-width: 600px) {
        font-size: 32px;
        margin: 100px auto 0 auto;
      }
    }

    &__works::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      width: 100%;
      height: 0px;
      border-bottom: 3px dashed #12433d;
      z-index: -1;
      // white-space: pre-line;

      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    &__works {
      position: relative;
      margin: 47px auto 0 auto;
      display: flex;
      justify-content: space-between;
      width: 1100px;
      height: 304px;
      transition: background-color 0.3s linear;

      @media screen and (max-width: 1050px) and (min-width: 900px) {
        margin: 54px auto 0 auto;
        width: 820px !important;
        height: 254px;
      }

      @media screen and (max-width: 899px) {
        margin: 32px auto 0 auto;
        width: 520px;
        height: 152px;
      }

      @media screen and (max-width: 600px) {
        margin: 32px auto 0 auto;
        width: 520px;
        height: 152px;
      }

      @media screen and (max-width: 500px) {
        width: 100%;
        height: 520px;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
      }

      > div {
        position: relative;
        width: 304px;
        height: 304px;
        border-radius: 152px;
        user-select: none;
        cursor: default;
        background-color: #f1f7f6;
        transition: background-color 0.3s linear;
        z-index: 9;

        @media screen and (max-width: 1050px) and (min-width: 900px) {
          width: 254px;
          height: 254px;
          border-radius: 127px;
        }

        @media screen and (max-width: 899px) {
          width: 152px;
          height: 152px;
          border-radius: 76px;
        }
      }

      &__step-active {
        background-color: #12433d !important;
        transition: background-color 0.3s linear;
      }

      &__step {
        margin-top: 55px;
        height: 67px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;

        @media screen and (max-width: 899px) {
          height: 31px;
          margin-top: 30px;
        }
      }

      &__step-1 {
        background-image: url(../../assets/img/agency/retailer/step1.svg);
      }

      &__step-1-active {
        background-image: url(../../assets/img/agency/retailer/step1a.svg);
      }

      &__step-2 {
        background-image: url(../../assets/img/agency/retailer/step2.svg);
      }

      &__step-2-active {
        background-image: url(../../assets/img/agency/retailer/step2a.svg);
      }

      &__step-3 {
        background-image: url(../../assets/img/agency/retailer/step3.svg);
      }

      &__step-3-active {
        background-image: url(../../assets/img/agency/retailer/step3a.svg);
      }

      &__section-1 {
        margin-top: 19px;
        font-size: 48px;
        line-height: 56px;
        color: #12433d;
        font-family: "Proxima-Bold";
        text-align: center;

        @media screen and (max-width: 1050px) and (min-width: 900px) {
          font-size: 38px;
          line-height: 45px;
        }

        @media screen and (max-width: 899px) {
          font-size: 20px;
          line-height: 23px;
        }
      }

      &__section-2 {
        margin-top: 3px;
        font-family: "Proxima-Nova-Semibold";
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #12433d;

        @media screen and (max-width: 1050px) and (min-width: 900px) {
          margin-top: 0;
        }

        @media screen and (max-width: 899px) {
          margin-top: 2px;
          font-size: 14px;
          line-height: 16px;
        }
      }

      &__description {
        margin-top: 19px;
        font-family: "Proxima";
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        font-weight: 400;
        color: #fff;

        @media screen and (max-width: 1050px) {
          margin: 19px auto 0 auto;
          width: 85%;
        }

        @media screen and (max-width: 899px) {
          width: 90%;
          margin: 13px auto 0 auto;
          font-size: 13px;
          line-height: 14px;
        }
      }
    }

    &__button {
      display: block;
      margin: 58px auto 0 auto;
      width: 268px;
      height: 43px;
      font-family: "Proxima Nova";
      font-size: 16px;
      line-height: 43px;
      border-radius: 21.5px;
      text-align: center;
      color: #000;

      @media screen and (max-width: 1050px) and (min-width: 900px) {
        margin: 62px auto 0 auto;
      }

      @media screen and (max-width: 600px) {
        margin: 43px auto 0 auto;
      }
    }
  }

  &__sign-up-wrapper {
    margin: 132px auto 83px auto;
    width: 100%;
    height: 383px;
    overflow: hidden;
    background-color: rgb(226, 238, 236);

    @media screen and (max-width: 1200px) and (min-width: 900px) {
      margin: 130px auto 74px auto;
      height: 534px;
    }

    @media screen and (max-width: 899px) {
      margin: 100px auto;
      height: 668px;
    }
  }

  &__sign-up {
    margin: 85px auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1186px;
    height: 174px;

    @media screen and (max-width: 1200px) {
      display: block;
    }

    @media screen and (max-width: 1200px) and (min-width: 900px) {
      width: 608px;
      height: 338px;
    }

    @media screen and (max-width: 899px) {
      margin: 50px auto;
      width: 553px;
      height: 348px;
    }

    &__features {
      width: 491px;

      @media screen and (max-width: 1200px) {
        margin: 0 auto;
      }

      &__title {
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 52px;
        color: #12433d;

        @media screen and (max-width: 1200px) {
          text-align: center;
        }

        @media screen and (max-width: 899px) {
          font-size: 32px;
        }
      }

      ul {
        margin-top: 64px;

        li {
          margin-bottom: 10px;
          font-family: "Proxima Nova";
          font-size: 18px;
          line-height: 21px;
          color: #000000;

          @media screen and (max-width: 1200px) {
            text-align: center;
          }
        }
      }
    }

    &__form {
      margin-top: 23px;
      width: 608px;
      height: 152px;

      @media screen and (max-width: 1200px) {
        margin: 40px auto 0 auto;
      }

      @media screen and (max-width: 899px) {
        // width: 270px;
        width: 400px;
        height: 162px;
        margin-top: 100px;
      }

      &__title {
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 80px;
        text-align: left;
        color: rgb(226, 238, 236);
        text-shadow: 0 0 1.5px #12433d, 0 0 1.5px #12433d, 0 0 1.5px #12433d, 0 0 1.5px #12433d,
          0 0 1.5px #12433d;

        @media screen and (min-width: 1200px) {
          transform: translateX(-20px);
        }

        @media screen and (max-width: 1200px) {
          text-align: center;
        }

        @media screen and (max-width: 899px) {
          // font-size: 36px;
          line-height: 48px;
        }
      }

      &__sign-up-box {
        margin-top: 55px;
        display: flex;
        // justify-content: space-between;
        align-items: flex-end;

        @media screen and (max-width: 899px) {
          display: block;
          margin: 55px auto 0 auto;
          width: 270px;
        }

        input {
          width: 260px;
          height: 33px;
          margin-right: 15px;
          border-bottom: 2px solid #12433d;
          border-radius: 0 0 3px 3px;
          font-family: "Proxima Nova";
          font-size: 18px;
          color: #000000;

          @media screen and (max-width: 899px) {
            margin-top: 18px;
            width: 100%;
          }
        }

        input::-webkit-input-placeholder {
          font-family: "Proxima Nova";
          font-size: 16px;
          color: #757575;
        }

        input:-moz-placeholder {
          font-family: "Proxima Nova";
          font-size: 18px;
          color: #757575;
        }

        input::-moz-placeholder {
          font-family: "Proxima Nova";
          font-size: 18px;
          color: #757575;
        }

        input:-ms-input-placeholder {
          font-family: "Proxima Nova";
          font-size: 18px;
          color: #757575;
        }

        &__submit {
          display: block;
          width: 268px;
          height: 43px;
          border-radius: 21.5px;
          line-height: 43px;
          text-align: center;
          font-family: "Proxima Nova";
          font-size: 16px;
          color: #000;

          @media screen and (max-width: 899px) {
            margin-top: 16px;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.agency-retailer {
  .ant-carousel {
    width: 100%;
    height: 443px;

    .slick-list {
      height: 443px;
    }

    .slick-dots {
      bottom: 20px !important;
    }

    .slick-slide {
      height: 443px;
      overflow: hidden;
    }
  }
}
</style>
