<template>
  <div>
    <a-modal
      :title="title"
      :visible="isOpen"
      ref="modal"
      width="952px"
      class="save-all-items-dialog"
      ok-text="Save"
      @ok="save"
      @cancel="cancel"
    >
      <div class="content">
        <div v-show="type === 1" class="add-panel">
          <a-input-search
            ref="addInput"
            v-model="addItemValue"
            enter-button="Add"
            @search="addItem"
          ></a-input-search>
          <!-- <a-input v-model="addItemValue">
            <a-button
              slot="suffix"
              class="save-all-items-dialog"
              @click="addItem"
            >
              Add
            </a-button>
          </a-input> -->
        </div>

        <div class="f-col">
          <div class="f-row f-wrap pad">
            <template v-if="type === 2">
              <ld-chip
                v-for="(item, itemIndex) in updateItems"
                ref="editchip"
                :key="getKeyFn(item)"
                :index="itemIndex"
                :item="item"
                :items="updateItems"
                :getDisplayFn="getDisplayFn"
                edit-only
                is-editable
                :display-value="getDisplayFn(item)"
                @on-change="updateSingleItem"
              ></ld-chip>
            </template>
            <template v-else>
              <div class="f-col">
                <!-- delete chip panel  -->
                <div class="chip-panel f-row f-wrap pad">
                  <div
                    v-for="(item, itemIndex) in updateItems"
                    :key="getUpdateItemsKey(item)"
                    class="chip"
                  >
                    <span>{{ getDisplayFn(item) }}</span>
                    <a-icon
                      type="close"
                      @click="deleteItem(item, itemIndex)"
                    ></a-icon>
                  </div>
                </div>

                <!-- deleted chip panel -->
                <div v-if="type === 3" class="deleted-panel f-col">
                  <div class="header">Deleted</div>
                  <div class="chip-panel f-row f-wrap pad">
                    <div
                      v-for="(item, itemIndex) in deletedItems"
                      :key="getKeyFn(item, itemIndex)"
                      class="chip red"
                    >
                      <span>{{ getDisplayFn(item) }}</span>
                      <a-icon
                        type="close"
                        @click="undoDeletedItem(item, itemIndex)"
                      ></a-icon>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <template slot="footer">
        <a-button key="cancel" @click="cancel">
          Cancel
        </a-button>
        <a-button key="save" type="primary" :loading="isLoading" @click="save">
          Save
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { nextTick } from "vue";
import ConsoleChip from "@/components/admin/ConsoleChip";
export default {
  components: { "ld-chip": ConsoleChip },
  props: {
    getKeyFn: { type: Function, required: true },
    getDisplayFn: { type: Function, required: true },
    setItemFn: { type: Function },
  },
  data() {
    const typeItem = [
      { id: 1, name: "Add" },
      { id: 2, name: "Edit" },
      { id: 3, name: "Delete" },
    ];
    const typeMap = new Map();
    typeItem.forEach((i) => typeMap.set(i.id, i));
    return {
      type: 0,
      parentItem: null,
      parentData: null,
      addItems: [],
      updateItems: [],
      deletedItems: [],
      title: "",
      typeMap: typeMap,
      isOpen: false,
      isEditOnly: false,
      isEditable: false,
      isDelete: false,
      deleteMap: new Map(),
      addItemValue: "",
      isLoading: false,
    };
  },
  methods: {
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    getUpdateItemsKey(item, index) {
      return this.type === 1 ? index : this.getKeyFn(item);
    },
    getParentKey() {
      return (
        (this.parentItem && this.parentData.getKeyFn(this.parentItem)) || null
      );
    },
    openDialog(model) {
      this.type = model.type;
      this.parentItem = model.parentItem;
      this.parentData = model.parentData;
      this.title = model.title;
      this.items = model.items;
      this.updateItems = [];
      this.deletedItems = [];
      this.deleteMap.clear();
      if (this.type !== 1) {
        //this.updateItems = JSON.parse(JSON.stringify(model.items))
        this.updateItems = model.items.map((i) => i.clone());
      }

      this.isOpen = true;
      this.setTitle();
    },
    setTitle() {
      const typeItem = this.typeMap.get(this.type);
      if (typeItem.id == 1) {
        this.title = `${typeItem.name} New ${this.title}`;
        this.addInputFocus();
      } else {
        this.title = `${typeItem.name} All`;
      }
      const isDelete = typeItem.id === 3;
      this.isEditOnly = !isDelete;
      this.isEditable = !isDelete;
      this.isDelete = isDelete;
      this.deleteMap.clear();
    },
    save() {
      let data = [];
      const valueCheckMap = new Map();
      switch (this.type) {
        case 1:
          data = this.updateItems;
          break;
        case 2:
          this.items.forEach((i) =>
            valueCheckMap.set(this.getKeyFn(i), this.getDisplayFn(i))
          );
          data = this.updateItems.filter(
            (i) => valueCheckMap.get(this.getKeyFn(i)) !== this.getDisplayFn(i)
          );
          break;
        case 3:
          data = this.deletedItems;
          break;
      }
      //this.isloading = true;
      this.$emit("save-all-items", {
        type: this.type,
        data: data,
        parentKey: this.getParentKey(),
        //getKeyFn: this.getKeyFn,
      });
    },
    close() {
      this.isLoading = false;
      this.isOpen = false;
    },
    cancel() {
      this.isOpen = false;
      this.updateItems = [];
      this.deletedItems = [];
      this.$emit("cancel-save-all-items");
    },
    showItem(index) {
      return !this.deleteMap.get(index);
    },
    deleteItem(item, index) {
      if (this.type === 3) {
        this.deleteMap.set(this.getKeyFn(item), item);
        this.deletedItems.push(item);
      }
      this.updateItems.splice(index, 1);
    },
    undoDeletedItem(item, index) {
      this.deleteMap.delete(this.getKeyFn(item));
      this.updateItems.push(item);
      this.deletedItems.splice(index, 1);
    },
    showDeleteItem(index) {
      return this.deleteMap.get(index) == void 0;
    },
    addInputFocus() {
      nextTick(() => {
        console.log(this.$refs.addInput);
        this.$refs.addInput.focus();
      });
    },
    addItem(value) {
      value = value.trim() || "";
      if (!value) {
        this.$message.error("Value cannot be empty");
        return false;
      } else {
        if (
          this.items.some(
            (i) => this.getDisplayFn(i).toLowerCase() === value.toLowerCase()
          )
        ) {
          this.$message.error("Duplicate values are not allowed");
          return false;
        }
      }

      const model = this.setItemFn(value, this.getParentKey());
      this.addItemValue = "";
      this.updateItems.push(model);
    },
    updateSingleItem(value) {
      //this.updateItems[value.index].name = value
      //this.setItemFn(value, this.getParentKey(), this.updateItems[value.index])
      this.setItemFn(value.newValue, this.getParentKey(), value.item);
      this.updateItems[value.index] = value.item;
      this.updateItems = [...this.updateItems];
    },
  },
  create() {},
};
</script>
<style lang="scss">
.save-all-items-dialog {
  .add-panel {
    margin-bottom: 12px;
  }
  .content {
    min-height: 270px;

    .add-panel {
      .ant-input-search-button {
        border-color: #4a8a5d;
        background: #4a8a5d;
      }
    }
    .deleted-panel {
      .header {
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
