// 导出页面为PDF格式
import html2Canvas from "html2canvas"
import JsPDF from "jspdf"

export default {
  install(Vue) {
    Vue.prototype.getPdf = function(id) {
      let elementId
      const title = "order"
      const btns = document.getElementsByClassName("print-download-order-detail-special")
      if (this.$route.name === "adminBrandOrder" || this.$route.name === "adminRetailerOrder") {
        elementId = document.getElementById(id)
      } else {
        elementId = document.getElementById("orderDetailMain")
      }
      if (btns && btns.length > 0) {
        for (let i = 0; i < btns.length; i++) {
          btns[i].style.display = "none"
        }
      }
      html2Canvas(elementId, {
        allowTaint: true,
        useCORS: true,
        logging: true,
        // height: document.getElementById("6orderDetailMain").scrollHeight,
        // width: document.getElementById("orderDetailMain").scrollWidth,
        background: "#FFFFFF"
      })
        .then(function(canvas) {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          let pageHeight = (contentWidth / 592.28) * 841.89 - 100
          let leftHeight = contentHeight
          let position = 0
          let imgWidth = 595.28
          let imgHeight = (592.28 / contentWidth) * contentHeight
          let pageData = canvas.toDataURL("image/jpeg", 1.0)
          let PDF = new JsPDF("", "pt", "a4")
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight)
          } else {
            while (leftHeight > 0) {
              PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight)
              leftHeight -= pageHeight
              position -= 840
              if (leftHeight > 0) {
                PDF.addPage()
              }
            }
          }
          PDF.save(title + ".pdf")
        })
        .finally(() => {
          if (btns && btns.length > 0) {
            for (let i = 0; i < btns.length; i++) {
              btns[i].style.display = "block"
            }
          }
        })
    }
  }
}
