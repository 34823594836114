<template>
  <div class="modal request-invite-modal" :class="{ 'is-active': showInviteMember }">
    <div class="modal-background"></div>
    <div class="modal-card request-invite-modal__content">
      <div class="header request-invite-modal__header">
        <span>{{
          role === "admin"
            ? $t("Retailers.RetailerSettings.adminRequestInvitations")
            : $t("Retailers.RetailerSettings.requestInvitations")
        }}</span>
        <img
          class="request-invite-modal__close-button"
          src="@/assets/img/icon/close-icon.svg"
          @click="onClickClose"
        />
      </div>
      <section class="request-invite-modal__body">
        <div class="infoText">
          {{ $t("Retailers.RetailerSettings.Members.inviteText") }}
        </div>
        <div class="column is-full">
          <div class="field">
            <label class="label brand-new-product__label">
              {{ $t("Retailers.RetailerSettings.Members.email") }}
            </label>
            <div class="control">
              <input
                v-model="$v.inviteMember.email.$model"
                class="input brand-new-product__input--fullwidth field-width max-width"
                :class="{
                  'is-danger':
                    $v.inviteMember.email.$error || duplicateMember || apiError !== undefined
                }"
                :placeholder="$t('Retailers.RetailerSettings.Members.emailPlaceholder')"
                @input="emialInputChange"
              />
              <p
                v-if="$v.inviteMember.email.$error && !$v.inviteMember.email.required"
                class="help is-danger"
              >
                {{ $t("Retailers.RetailerSettings.Validations.required") }}
              </p>
              <p
                v-if="$v.inviteMember.email.$error && !$v.inviteMember.email.email"
                class="help is-danger"
              >
                {{ $t("Auth.Register.Validations.invalidEmail") }}
              </p>
              <p v-if="duplicateMember" class="help is-danger">
                User already exists
              </p>
              <p v-if="apiError !== undefined" class="help is-danger">
                {{ apiError }}
              </p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label brand-new-product__label">
                {{ $t("Retailers.RetailerSettings.Members.firstName") }}
              </label>
              <div class="control">
                <input
                  v-model="$v.inviteMember.firstName.$model"
                  class="input brand-new-product__input--fullwidth field-width"
                  :class="{ 'is-danger': $v.inviteMember.firstName.$error }"
                  :placeholder="$t('Retailers.RetailerSettings.Members.firstNamePlaceholder')"
                />
                <p v-if="$v.inviteMember.firstName.$error" class="help is-danger">
                  {{ $t("Retailers.RetailerSettings.Validations.required") }}
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label brand-new-product__label">
                {{ $t("Retailers.RetailerSettings.Members.lastName") }}
              </label>
              <div class="control">
                <input
                  v-model="$v.inviteMember.lastName.$model"
                  class="input brand-new-product__input--fullwidth field-width"
                  :class="{ 'is-danger': $v.inviteMember.lastName.$error }"
                  :placeholder="$t('Retailers.RetailerSettings.Members.lastNamePlaceholder')"
                />
                <p v-if="$v.inviteMember.lastName.$error" class="help is-danger">
                  {{ $t("Retailers.RetailerSettings.Validations.required") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-8">
          <div class="field">
            <label class="label brand-new-product__label">
              {{ $t("Retailers.RetailerSettings.Members.jobTitle") }}
            </label>
            <div class="control">
              <input
                v-model="$v.inviteMember.jobTitle.$model"
                class="input brand-new-product__input--fullwidth field-width"
                :class="{ 'is-danger': $v.inviteMember.jobTitle.$error }"
                :placeholder="$t('Retailers.RetailerSettings.Members.jobTitlePlaceholder')"
              />
              <p v-if="$v.inviteMember.jobTitle.$error" class="help is-danger">
                {{ $t("Retailers.RetailerSettings.Validations.required") }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div class="modal-card-foot request-invite-modal__buttons">
        <div class="column">
          <div class="button" :disabled="isLoading" @click="onClickSave">
            {{ $t("Brands.BrandSettings.sendInvitation") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators"
import constants from "@/config.js"

export default {
  validations: {
    inviteMember: {
      email: { email, required },
      firstName: { required },
      lastName: { required },
      jobTitle: {}
    }
  },
  props: {
    showInviteMember: { type: Boolean, default: false },
    selectedMember: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      inviteMember: {
        email: "",
        firstName: "",
        lastName: "",
        jobTitle: ""
      },
      duplicateMember: false,
      isLoading: false,
      apiError: undefined
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    brand() {
      if (this.$route.name == "brandMember" || this.$route.name == "adminBrandMember") {
        return this.$store.getters["brand/brand"]
      }
      return {}
    },
    retailer() {
      if (this.$route.name == "retailerSettings" || this.$route.name == "adminRetailerSettings") {
        return this.$store.getters["retailer/retailer"]
      }
      return {}
    }
  },
  async created() {},
  methods: {
    onClickClose() {
      this.inviteMember = this.clearInviteMember()
      this.$emit("onClickClose")
    },
    emialInputChange() {
      this.duplicateMember = false
      this.apiError = undefined
    },
    async onClickSave() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      try {
        this.duplicateEmail(this.inviteMember.email)
        if (this.isRetailer && !this.duplicateMember) {
          this.isLoading = true
          this.inviteRetailerMember(this.inviteMemberRootObject())
        } else if (this.isBrand && !this.duplicateMember) {
          this.isLoading = true
          this.inviteBrandMember(this.inviteMemberRootObject())
        } else {
          return
        }
      } catch (error) {
        console.error(error)
      }
    },
    async inviteRetailerMember(member) {
      try {
        if (this.role === "admin") {
          let jwt_userId = ""
          if (this.retailer && this.retailer.members && this.retailer.members.length > 0) {
            for (let i = 0; i < this.retailer.members.length; i++) {
              if (this.retailer.members[i].role.id === "owner") {
                jwt_userId = this.retailer.members[i].user.id
                break
              }
            }
          }
          let params = {
            _authenticated: true,
            "_jwt-u_id": jwt_userId,
            "_jwt-c_id": this.$route.params.retailerId,
            "_jwt-c_type": "retailer"
          }
          await this.$store
            .dispatch("retailer/adminAddMember", { member, params })
            .then(res => {
              this.afterAddMemeber(res)
            })
            .catch(e => {
              if (e.message) {
                let data = e.message
                this.$message.error(`Failed: ${data}`)
              } else {
                this.$message.error("Failed")
              }
            })
        } else {
          await this.$store.dispatch("retailer/addMember", member).then(res => {
            this.afterAddMemeber(res)
          })
        }
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
    },
    async inviteBrandMember(member) {
      try {
        if (this.role === "admin") {
          let jwt_userId = ""
          if (this.brand && this.brand.members && this.brand.members.length > 0) {
            for (let i = 0; i < this.brand.members.length; i++) {
              if (this.brand.members[i].role.id === "owner") {
                jwt_userId = this.brand.members[i].user.id
                break
              }
            }
          }
          let params = {
            _authenticated: true,
            "_jwt-u_id": jwt_userId,
            "_jwt-c_id": this.$route.params.brandId,
            "_jwt-c_type": "brand"
          }
          this.$store
            .dispatch("brand/adminAddMember", { member, params })
            .then(res => {
              this.afterAddMemeber(res)
            })
            .catch(e => {
              if (e.response) {
                let data = e.response.data
                this.$message.error(`Failed: ${data.developerMessage}`)
              } else {
                this.$message.error("Failed")
              }
            })
        } else {
          await this.$store.dispatch("brand/addMember", member).then(res => {
            this.afterAddMemeber(res)
          })
        }
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
    },
    afterAddMemeber(res) {
      if (res.developerMessage) {
        this.apiError = res.developerMessage
        this.$forceUpdate()
      } else {
        this.$emit("addedMember", this.addedMemberRootObject())
        this.emitModalClose()
      }
      this.isLoading = false
    },
    inviteMemberRootObject() {
      return {
        membershipStatus: {
          id: "invited",
          name: "Invited"
        },
        role: {},
        user: {
          givenName: this.inviteMember.firstName,
          familyName: this.inviteMember.lastName,
          email: this.inviteMember.email,
          jobTitle: this.inviteMember.jobTitle
        }
      }
    },
    addedMemberRootObject() {
      return {
        user: {
          givenName: this.inviteMember.firstName,
          familyName: this.inviteMember.lastName,
          email: this.inviteMember.email
        }
      }
    },
    duplicateEmail(memberEmail) {
      if (this.isBrand) {
        if (
          this.brand.members.filter(member => {
            if (member.membershipStatus.id !== "delete") {
              return member.user.email === memberEmail
            }
            return false
          }).length > 0
        ) {
          this.duplicateMember = true
        }
      }
      if (this.isRetailer) {
        if (
          this.retailer.members.filter(member => {
            if (member.membershipStatus.id !== "delete") {
              return member.user.email === memberEmail
            }
            return false
          }).length > 0
        ) {
          this.duplicateMember = true
        }
      }
    },
    emitModalClose() {
      this.inviteMember = this.clearInviteMember()
      this.$emit("inviteMemberModalClose")
    },
    clearInviteMember() {
      return {
        email: "",
        firstName: "",
        lastName: "",
        jobTitle: ""
      }
    }
  }
}
</script>
<style lang="sass">

.max-width
  width: 334px !important

.request-invite-modal
  .modal-card-body
    display: flex

  .request-term-details-info
    color: #000
    font-size: 14px
    font-weight: 400
    margin: 0px !important

  .infoText
    margin-bottom: 20px

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .request-invite-modal__close-button
      top: 16px
      width: 14px
      height: 14px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 510px

  .request-invite-modal__buttons
    padding: 10px 91px 10px 90px
    .button
      height: 30px
      font-size: 14px
      padding: 16px 20px
      width: 100%
      background: black
      color: white

  &__body
    padding: 30px 90px 30px 91px

  &__buttons
    padding: 10px 20px
    display: flex
    justify-content: flex-end

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__details-container
    font-size: 14px
    padding-bottom: 60px

  &__details
    margin: 18px
</style>
