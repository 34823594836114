<template lang="pug">
.logo-or-placeholder
  img(v-if="url" :src="url")
  img(v-if="!url && imageUrl" :src="imageUrl")
</template>

<script>
export default {
  props: {
    url: { type: String, default: null }
  },
  data() {
    return {
      imageUrl: ""
    }
  },
  created() {
    if (!this.url) {
      this.imageUrl = require("@/assets/img/default-logo-image.png")
    }
  }
}
</script>

<style lang="sass" scoped>
.logo-or-placeholder
  width: 48px
  height: 48px
  display: flex
  position: relative
  justify-content: center
  align-items: center
  overflow: hidden
  border-radius: 50%

img
  max-width: 100%
  max-height: 100%
</style>
