<template>
  <div>
    <BrandHeader />
    <router-view />
    <BrandFooter />
  </div>
</template>

<script>
import BrandHeader from "./admin/Header"
import BrandFooter from "./retailer/Footer"

export default {
  components: {
    BrandHeader,
    BrandFooter
  }
}
</script>

<style lang="sass" scoped>
@import "bulma"

.nav-link
  cursor: pointer
</style>
