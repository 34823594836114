<template>
  <div ref="editShippedDateModal" class="edit-ship-or-cancel-modal__main">
    <a-modal
      v-model="editShipOrCancelVisible"
      width="790px"
      :title="modalTitle"
      :footer="null"
      :get-container="() => $refs.editShippedDateModal"
    >
      <div class="ship-cancel-content">
        <div class="date-content-style">
          <p class="date-Flexible">
            <a-checkbox v-model="checkFlexible" :style="{ marginRight: '15px' }" @change="changeFlexible" />
            <a-input v-model="flexibleValue" :disabled="true" style="width: 256px" />
          </p>
          <div class="date-Flexible">
            <a-checkbox v-model="checkDate" style="margin-right: 15px" @change="changeCheckDate"/>
            <a-date-picker
              v-model="chooseDate"
              format="YYYY-MM-DD"
              style="width: 256px"
              @change="onChangeDate"
            />
          </div>
        </div>
      </div>
      <div class="ship-cancel-button-style">
        <span class="button-style" :style="{ backgroundColor: btnColor }" @click="applyEdit">
          Apply
        </span>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classifyValue: "",
      modalTitle: "",
      editShipOrCancelVisible: false,
      chooseDate: null,
      checkDate: false,
      checkFlexible: true,
      flexibleValue: "Flexible",
      btnColor: ""
    }
  },
  methods: {
    openDialog(value, color) {
      this.classifyValue = value
      this.modalTitle = value === "ship" ? "Ship Date" : "Cancel Date"
      this.editShipOrCancelVisible = true
      this.btnColor = color
    },
    onChangeDate(value, dateStr) {
      console.log("shipDate", value, dateStr)
      this.chooseDate = dateStr
    },
    changeFlexible() {
      this.checkDate = !this.checkFlexible
    },
    changeCheckDate() {
      this.checkFlexible = !this.checkDate
    },
    applyEdit() {
      const shipDate = this.classifyValue === "ship" && this.checkDate ? this.chooseDate : null
      const cancelDate = this.classifyValue === "cancel" && this.checkDate ? this.chooseDate : null
      this.$emit("updateShippingWindow", shipDate, cancelDate)
      this.editShipOrCancelVisible = false
    }
  }
}
</script>

<style lang="scss">
.edit-ship-or-cancel-modal__main {
  .ship-cancel-content {
    margin: 0 auto;
    width: 300px;
    margin-bottom: 100px;
    margin-top: 30px;
  }
  .ship-cancel-button-style {
    text-align: right;
    .button-style {
      padding: 5px 16px;
      color: white;
      cursor: pointer;
      border-radius: 2px;
    }
  }
}
</style>
