<template>
  <div class="contact-us-page__main">
    <a-row type="flex" justify="center" class="contact-us-content">
      <a-col :xs="{ span: 20 }" :lg="{ span: 10 }" class="left-main">
        <div class="left-main-content">
          <p class="left-content-title">Contact Us</p>
          <p class="content-email-content">contact@landingintl.com</p>
          <img src="../assets/img/components/Lifestyle_1-print 1.png" alt="" />
        </div>
      </a-col>
      <a-col v-if="isShowSuccess" :xs="{ span: 20 }" :lg="{ span: 10 }" class="right-main-success">
        <p>Thanks for reaching out!</p>
        <p>Someone will get back to you soon</p>
        <p @click="closeText">Back</p>
      </a-col>
      <a-col v-else :xs="{ span: 20 }" :lg="{ span: 10 }" class="right-main">
        <div class="form-title-top">
          <div>
            <p class="label-style">First name</p>
            <a-input v-model="form.firstName" placeholder="First name" />
          </div>
          <div>
            <p class="label-style">Last name</p>
            <a-input v-model="form.lastName" placeholder="Last name" />
          </div>
        </div>
        <a-form-model :model="form" layout="vertical">
          <a-form-model-item label="Email">
            <a-input v-model="form.email" placeholder="Email" />
          </a-form-model-item>
          <a-form-model-item label="Company Name">
            <a-input v-model="form.companyName" placeholder="Company name" />
          </a-form-model-item>
          <a-form-model-item label="Subject">
            <a-select v-model="form.subject" placeholder="Brand Inquiries">
              <a-select-option v-for="item in SubjectList" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Message">
            <a-input v-model="form.message" :rows="5" type="textarea" placeholder="Message" />
          </a-form-model-item>
<!--          <a-form-model-item :wrapper-col="{ span: 18, offset: 4 }">-->
<!--          </a-form-model-item>-->
        </a-form-model>
        <p class="form-submit-style" @click="onSubmit">
          <span>SUBMIT</span>
        </p>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      isShowSuccess: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        // subject:"1",
        message: ""
      },
      SubjectList: [
        {
          label: "Brand Inquiries",
          value: "brand"
        },
        {
          label: "Retailer Inquiries",
          value: "retailer"
        },
        {
          label: "Marketing",
          value: "marketing"
        },
        {
          label: "Press",
          value: "press"
        },
        {
          label: "General",
          value: "general"
        }
      ]
    }
  },
  methods: {
    onSubmit() {
      this.isShowSuccess = true
    },
    closeText() {
      this.isShowSuccess = false
    }
  }
}
</script>

<style scoped lang="scss">
.contact-us-page__main {
  max-width: 1400px;
  //min-height: 600px;
  margin: 0 auto;
  .contact-us-content {
    //height: 200px;
    //padding-top: 20px;
  }
  .left-main {
    //height: 200px;
    text-align: center;
    //background-color: #00e7eb;
    .left-main-content {
      .left-content-title {
        font-size: 48px;
        color: #12433d;
        margin-bottom: 26px;
        font-family: 'Playfair Display Bold';
      }
      .content-email-content {
        font-size: 14px;
        margin-bottom: 34px;
      }
      //img {
      //  width: ;
      //}
    }
  }
  .right-main-success {
    padding-top: 270px;
    text-align: center;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    p:nth-child(1) {
      font-size: 28px;
      margin-bottom: 4px;
    }
    p:nth-child(2) {
      font-size: 16px;
    }
    p:nth-child(3) {
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .right-main {
    //height: 100px;
    width: 540px;
    text-align: center;
    margin-top: 40px;
    //background-color: #FF6132;
  }
  .form-title-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .label-style {
      width: 260px;
      text-align: left;
      font-size: 15px;
      margin-bottom: 7px;
      color: #000000;
    }
  }
  .form-submit-style {
    //padding: 12px 34px;
    text-align: center;
    height: 40px;
    font-size: 16px;
    margin-top: 50px;
    span {
      padding: 12px 34px;
      border-radius: 27px;
      background-color: #FF6132;
      border-color: #FF6132;
      color: white;
      cursor: pointer;
    }
  }
}
.ant-form-item {
  margin-bottom: 0;
  padding-bottom: 15px !important;
  font-family: "ProximaNova-Regular", sans-serif;
}
.ant-row .ant-form-item {
  width: 260px;
}
.ant-form label {
  font-size: 16px;
  font-family: "ProximaNova-Regular", "Roboto", sans-serif;
}
</style>
