<template>
  <div @click="closeSearchBrandDropdown">
    <RetailerHeader :showBrandDropdown="showBrandDropdown" />
    <router-view />
    <RetailerFooter />
  </div>
</template>

<script>
import RetailerHeader from "./retailer/Header"
import RetailerFooter from "./retailer/Footer"

export default {
  components: {
    RetailerHeader,
    RetailerFooter
  },
  data() {
    return {
      showBrandDropdown: null,
      index: 0
    }
  },
  methods: {
    closeSearchBrandDropdown(event) {
      const divClass = document.getElementById("searchBrandShow")
      if (divClass) {
        if (!divClass.contains(event.target)) {
          this.showBrandDropdown = this.index++
        }
      }
    }
  }
}
</script>
