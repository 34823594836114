<template>
  <a-modal
    v-model="showProductList"
    width="800px"
    :footer="null"
    dialog-class="admin-message-choose-product"
    :body-style="noPadding"
    :destroy-on-close="true"
    :mask-closable="false"
    @cancel="hideDialog"
  >
    <template slot="title">
      <a-input
        v-model="searchProName"
        class="admin-message-choose-product__title"
        placeholder="Type the Products Name"
        @blur="searchProduct"
        @change="searchProduct"
      >
        <a-icon slot="prefix" type="search" @change="searchProduct" />
      </a-input>
    </template>
    <div style="background: #fff;">
      <div class="admin-message-choose-product__txt">Please select up to six proucts to share</div>
      <a-row style="height: 442px; overflow-y: auto; overflow-x: hidden; padding-left: 40px;">
        <a-col
          v-for="(item, index) in products"
          :key="`product_${index}`"
          :span="6"
          style="width: 170px;height: 221px;border: 1px solid #e2e2e2;margin: 0 6px 6px 0;"
          :class="{ 'admin-message-choose-product__mask': item.isAdded }"
        >
          <img :src="item.images[0].asset.url" alt="" width="auto" height="95" />
          <a-checkbox
            v-model="item.isChecked"
            :disabled="item.isAdded"
            class="admin-message-choose-product__check-box"
            @change="selectItem(item)"
          />
          <div class="admin-message-choose-product__product-name">{{ item.name }}</div>
          <div class="admin-message-choose-product__product-info">
            <div style="color: #595959; margin-bottom: 2px;">{{ item.sku }}</div>
            <div>{{ priceFormat(item.msrpUsd) }}</div>
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" justify="end">
        <a-col>
          <a-pagination
            v-if="products && products.length !== 0"
            v-model="paginationValue"
            :default-current="1"
            :default-page-size="currentNum"
            :total="total"
            size="small"
            :show-quick-jumper="true"
            @change="handlePagination"
          ></a-pagination>
        </a-col>
      </a-row>
      <a-row style="padding-top: 5px; padding-bottom: 20px; width: 100%; padding-right: 20px;">
        <a-button class="admin-message-choose-product__btn" @click="applyProducts">Apply</a-button>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      showProductList: false,
      brandId: undefined,
      selectedData: new Set(),
      searchProName: undefined,
      addedProducts: [],
      noPadding: {
        padding: "0px"
      },
      paginationValue: 1,
      currentNum: 12,
      total: 0
    }
  },
  computed: {
    products() {
      let data = this.$store.getters["product/products"]
      if (data && data.length > 0) {
        data.forEach(item => {
          if (item.isChecked === undefined) {
            item.isChecked = false
          }
          item.isAdded = false
        })
        if (this.selectedData && this.selectedData.size > 0) {
          for (let product of this.selectedData) {
            let filterResult = data.filter(item => item.id === product.id)
            if (filterResult && filterResult.length > 0) {
              filterResult[0].isChecked = true
            }
          }
        }
        if (this.addedProducts && this.addedProducts.length > 0) {
          this.addedProducts.forEach(item => {
            let filterResult = data.filter(product => item.id === product.id)
            if (filterResult && filterResult.length > 0) {
              filterResult[0].isAdded = true
            }
          })
        }
      }
      return data
    }
  },

  methods: {
    async getProduct(param) {
      if (!param) {
        param = {}
      }
      if (this.brandId) {
        param.brand = this.brandId
        const info = await this.$store.dispatch("product/getNewProducts", { params: param })
        this.total = info.total
      }
    },
    async handlePagination(page) {
      this.paginationValue = page
      let params = {
        _index: page,
        _limit: this.currentNum
      }
      await this.getProduct(params)
    },
    openDialog(id, added) {
      console.log("....openDialog....")
      this.brandId = id
      this.showProductList = true
      this.addedProducts = added
      let params = {
        _index: 1,
        _limit: this.currentNum
      }
      this.getProduct(params)
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price) + " MSRP"
    },
    hideDialog() {
      this.showProductList = false
    },
    selectItem(item) {
      if (item.isChecked) {
        if (this.selectedData.size >= 6) {
          item.isChecked = false
          this.$message.warning("Only can select 6 product a time!")
        } else {
          this.selectedData.add(item)
        }
      } else {
        this.selectedData.delete(item)
      }
      this.$forceUpdate()
    },
    searchProduct() {
      console.log("search product by value")
      console.log(this.searchProName)
      if (this.searchProName.length < 2) {
        return
      }
      let params = {
        _index: 1,
        _limit: this.currentNum,
        product_name: this.searchProName
      }
      this.getProduct(params)
    },
    applyProducts() {
      console.log("...applyProducts....")
      console.log(this.selectedData.size)
      this.$emit("apply", [...this.selectedData])
      this.hideDialog()
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-message-choose-product {
  &__title {
    height: 28px;
    width: 400px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    border-radius: 30px;
  }
  &__txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    color: #4a8a5d;
    margin: 14px 20px 20px 20px;
  }
  &__mask {
    opacity: 0.5;
    width: 100%;
    background: #fff;
    z-index: 998;
  }
  img {
    width: 100%;
    min-height: 75px;
    max-height: 122px;
    object-fit: cover;
  }
  &__product-name {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    width: 122px;
    text-align: center;
    margin: 0 auto;
    padding-top: 8px;
  }
  &__product-info {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    color: #262626;
  }
  &__check-box {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  &__btn {
    float: right;
    background: #4a8a5d;
    border-radius: 2px;
    padding: 5px 8px;
    width: 90px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
  }
}
</style>
<style lang="scss">
.admin-message-choose-product .ant-modal-header {
  background: #e2eeec;
  padding: 13px 20px;
  border-bottom: 1px solid #4a8a5d;
}
.admin-message-choose-product__title .ant-input {
  height: 28px;
  border-radius: 30px;
  border: none;
}
.admin-message-choose-product__title .ant-input:hover {
  border: none;
}
.admin-message-choose-product__title.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
}
.admin-message-choose-product__title.ant-input-affix-wrapper {
  border: none;
}
.admin-message-choose-product__title .anticon {
  color: #4a8a5d;
}
.admin-message-choose-product .ant-btn:hover {
  color: #fff;
  background: #4a8a5d;
  border-radius: 2px;
  border-color: #4a8a5d;
}
.admin-message-choose-product .ant-modal-close-x {
  color: #595959;
}
</style>
