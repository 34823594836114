import firebase from "@/services/firebase-service"
import userClient from "@/api/user-client"

export default {
  namespaced: true,

  state: {
    role: null,
    id: null,
    businessId: null,
    name: "",
    givenName: null,
    familyName: null,
    phoneNumber: null,
    jobTitle: null,
    email: null,
    accessToken: null,
    emailVerified: null,
    isUserReady: false,
    memberEmail: null,
    memberName: "",
    memberPhone: null,
    memberTitle: null,
    mailPreference: {},
    checkMember: false
  },

  mutations: {
    SET_USER(state, payload) {
      state.name = payload.name
      state.email = payload.email
      state.emailVerified = payload.emailVerified
      state.id = payload.id
      state.businessId = payload.businessId
    },
    SET_TOKEN(state, accessToken) {
      state.accessToken = accessToken
    },
    SET_USER_READY(state, value) {
      state.isUserReady = value
    },
    SET_ACCOUNT(state, data) {
      state.givenName = data.givenName
      state.familyName = data.familyName
      state.phoneNumber = data.phoneNumber
      state.jobTitle = data.jobTitle
      state.memberEmail = data.email
      state.memberName = data.givenName + " " + data.familyName
      state.memberTitle = data.jobTitle
      state.memberPhone = data.phoneNumber
      state.mailPreference = data.mailPreference
    },
    UPDATE_ACCOUNT(state, updatedUser) {
      state.givenName = updatedUser.givenName
      state.familyName = updatedUser.familyName
      state.phoneNumber = updatedUser.phoneNumber
      state.email = updatedUser.email
      state.jobTitle = updatedUser.jobTitle
      state.mailPreference = updatedUser.mailPreference
    },
    UPDATE_CHECK_RESULT(state, checkMember) {
      state.checkMember = checkMember
    }
  },

  actions: {
    async fetchUser({ commit }, userId) {
      try {
        const { data } = await userClient.fetch(userId)
        commit("SET_ACCOUNT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateUser({ commit }, user) {
      try {
        const { data } = await userClient.updateUser(user, user.id)
        commit("UPDATE_ACCOUNT", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async signIn({ commit }, { email, password }) {
      commit("SET_USER_READY", false)
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async signOutWithoutReload() {
      try {
        await firebase.auth().signOut()
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async signOut() {
      try {
        await firebase.auth().signOut()
        location.reload("/")
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async sendPasswordRecoveryLink(_, email) {
      try {
        await userClient.sendPasswordRecoveryLink(email)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async sendPasswordRecoveryLinkForLegacyUser(_, emailHash) {
      try {
        await userClient.sendPasswordRecoveryLinkForLegacyUser(emailHash)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async sendEmailVerifyLink(_, email) {
      try {
        await userClient.sendEmailVerifyLink(email)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async verifyEmail(_, token) {
      try {
        await userClient.verifyEmail(token)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async verifyResetToken(_, token) {
      try {
        await userClient.verifyResetToken(token)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async resetPassword(_, { token, password }) {
      try {
        await userClient.resetPassword(token, password)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async updatePassword(_, { password }) {
      try {
        var user = await firebase.auth().currentUser
        user.updatePassword(password)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async checkMember({ commit }, brandId) {
      try {
        let { data } = await userClient.checkMember(brandId)
        commit("UPDATE_CHECK_RESULT", data.success)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  },

  getters: {
    ready: state => state.isUserReady,
    name(state) {
      return state.name
    },
    givenName(state) {
      return state.givenName
    },
    familyName(state) {
      return state.familyName
    },
    phoneNumber(state) {
      return state.phoneNumber
    },
    jobTitle(state) {
      return state.jobTitle
    },
    email(state) {
      return state.email
    },
    memberEmail(state) {
      return state.memberEmail
    },
    memberName(state) {
      return state.memberName
    },
    memberPhone(state) {
      return state.memberPhone
    },
    memberTitle(state) {
      return state.memberTitle
    },
    isEmailVerified(state) {
      return state.emailVerified
    },
    id(state) {
      return state.id
    },
    businessId: state => state.businessId,
    accessToken(state) {
      return state.accessToken
    },
    postSignUpPath(state) {
      const postSignUpPaths = {
        brand: "brandPending",
        retailer: "retailerVerifyEmail",
        admin: "adminMain"
      }
      return postSignUpPaths[state.role]
    },
    mailPreference(state) {
      return state.mailPreference
    },
    checkMemberResult(state) {
      return state.checkMember
    }
  }
}
