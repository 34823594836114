function removeFromState(state, payload, type) {
  if (payload.type == type) {
    const index = state.indexOf(payload.id)
    if (index >= 0) {
      state.splice(index, 1)
      console.log(state)
    } else {
      console.log("it wasnt th index")
    }
    console.log(state)
  } else {
    console.log("payload type", payload.type)
  }
}

function addToState(state, payload, type) {
  if (payload.type == type) {
    console.log("add temp var to channel")
    state.push(payload.id)
    console.log(state)
  } else {
    console.log("payload type", payload.type)
  }
}

function getBeforeDate(days) {
  let now = new Date()
  let monthBefore = new Date(now - 1000 * 60 * 60 * 24 * parseInt(days))
  // return [now, monthBefore]
  let startTime = new Intl.DateTimeFormat("en-US").format(monthBefore)
  let endTime = new Intl.DateTimeFormat("en-US").format(now)
  return `${startTime} ~ ${endTime}`
}

function getBeforeMonth(months) {
  let now = new Date()
  let year = now.getFullYear()
  let month = now.getMonth()
  let beforeMonth = month - parseInt(months)
  if (beforeMonth <= 0) {
    year = year - 1
    beforeMonth += 12
  }
  let beforeTime = new Date(
    year,
    beforeMonth,
    0,
    now.getHours(),
    now.getMinutes(),
    now.getSeconds()
  )
  let day = beforeTime.getDate()
  if (now.getDate() < day) {
    day = now.getDate()
  }
  beforeTime.setDate(day)
  let startTime = new Intl.DateTimeFormat("en-US").format(beforeTime)
  let endTime = new Intl.DateTimeFormat("en-US").format(now)
  return `${startTime} ~ ${endTime}`
}

module.exports = {
  removeFromState,
  addToState,
  getBeforeDate,
  getBeforeMonth
}
