<template>
  <a-row class="admin-retailer-search">
    <a-col :span="24">
      <a-button
        class="admin-retailer-search__download-btn"
        style="float: right;"
        @click="clickToDownloadData"
      >
        {{ $t("Admins.AdminInventory.csv") }}
        <a-icon type="download" />
      </a-button>
    </a-col>
    <a-col :span="4" style="padding-right: 12px;">
      <SearchItems :items="searchItems" @doSearch="doSearch" />
    </a-col>
    <a-col :span="20" class="admin-console-brand-retailer-product-table">
      <a-table
        bordered
        :data-source="searchResult.list"
        :columns="columns"
        :pagination="false"
        :row-key="record => record.id"
      >
        <template slot="name">
          <span> Retailer Name </span>
          <a-icon type="caret-up" :rotate="isSortAsc ? 0 : 180" @click="doSortByRetailerName" />
        </template>
        <template slot="brandColumn" slot-scope="text, record">
          <span class="admin-console-brand-link-click" @click="clickToRetailerProfile(record)">
            {{ text }}
          </span>
        </template>
        <template slot="changeRequiredColumn" slot-scope="text, record">
          <span v-if="text" style="cursor: pointer; " @click="showChangeRequestModal(record)">
            {{ $t("Components.Admin.ClientItem.needsReview") }}
          </span>
          <span v-else></span>
        </template>
        <template slot="status" slot-scope="text, record">
          <div style="width: 100%;">
            <span>{{ text ? text : "status" }}</span>
            <a-dropdown style="float: right;">
              <a-icon
                type="more"
                style="margin-top: 4px;"
                @click="e => e.preventDefault()"
              ></a-icon>
              <a-menu slot="overlay" @click="event => handleMenuClick(event, record)">
                <a-menu-item v-for="item in status" :key="item.id">
                  {{ item.name }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </template>
        <template slot="marginValue" slot-scope="text">
          <span>{{ text ? text : "Margin" }}</span>
        </template>
        <template slot="location">
          <span>Location</span>
          <a-icon type="caret-up" :rotate="isSortLocationAsc ? 0 : 180" @click="doSortByLocation" />
        </template>
        <template slot="shortName">
          <span>Short Name</span>
          <a-icon
            type="caret-up"
            :rotate="isSortShortNameAsc ? 0 : 180"
            @click="doSortByShortName"
          />
        </template>
        <template slot="marginTitle">
          <span>Margin</span>
          <a-icon type="caret-up" :rotate="isSortMarginAsc ? 0 : 180" @click="doSortByMargin" />
        </template>
        <template slot="date">
          <span>Date Registered</span>
          <a-icon type="caret-up" :rotate="isSortDateAsc ? 0 : 180" @click="doSortByDate" />
        </template>
        <template slot="paymentTitle">
          <span>Payment Terms</span>
          <a-icon type="caret-up" :rotate="isSortPaymentAsc ? 0 : 180" @click="doSortByPayment" />
        </template>
        <template slot="shipping">
          <span>Shipping</span>
          <a-icon type="caret-up" :rotate="isSortShippingAsc ? 0 : 180" @click="doSortByShipping" />
        </template>
        <template slot="changeRequired">
          <span>Change Required</span>
          <a-icon
            type="caret-up"
            :rotate="isSortChangeRequired ? 0 : 180"
            @click="doSortByChangeRequired"
          />
        </template>
        <template slot="statusTitle">
          <span>Status</span>
          <a-icon type="caret-up" :rotate="isSortStatusAsc ? 0 : 180" @click="doSortByStatus" />
        </template>

        <template slot="regiserDate" slot-scope="text">
          <span>{{ formatDate(text) }}</span>
        </template>
        <template slot="payment" slot-scope="text">
          <span>{{ text }} </span>
        </template>
      </a-table>
      <a-pagination
        class="inventoryPagination"
        style="margin-top: 10px; float: right;"
        :default-page-size="pageSize"
        :default-current="1"
        :total="searchResult.total"
        size="small"
        :show-quick-jumper="true"
        @change="getNextPageData"
      />
    </a-col>
    <ApproveModal
      v-if="client"
      :approve-error="approveError"
      :show-error="showError"
      :show="approveModal"
      :initial-short-name="client.shortName"
      @hideApproveModal="hideApproveModal"
      @subscribe="subscribe"
    />
    <ChangeRequestModal
      :show="showChanges"
      :pending-data="retailer.termsPending"
      @hideChangeRequestModal="hideChangeRequestModal"
      @approveChanges="approve"
      @rejectChanges="reject"
    />
  </a-row>
</template>

<script>
import SearchItems from "@/components/admin/SearchItems"
import ApproveModal from "@/components/admin/ApproveModal"
import ChangeRequestModal from "@/components/retailers/profile/ChangeRequestModal"
import { downloadCsvDataStr } from "@/utils/validate"
export default {
  components: {
    SearchItems,
    ApproveModal,
    ChangeRequestModal
  },
  data() {
    return {
      approveModal: false,
      approveError: null,
      showError: false,
      showChanges: false,
      client: undefined,
      curPage: 1,
      status: [
        {
          id: "approved",
          name: "Approved",
          isChecked: false
        },
        {
          id: "pending",
          name: "Pending",
          isChecked: false
        },
        {
          id: "rejected",
          name: "Rejected",
          isChecked: false
        }
      ],
      isCheckAllRecord: false,
      retailerName: [],
      retailerShortName: [],
      channelTypes: [],
      locationTypes: [],
      otherTypes: [],
      locations: [],
      searchItems: [],
      searchParam: undefined,
      sortParam: {},
      isSortAsc: true,
      isSortLocationAsc: true,
      isSortShortNameAsc: true,
      isSortDateAsc: true,
      isSortMarginAsc: true,
      isSortPaymentAsc: true,
      isSortShippingAsc: true,
      isSortChangeRequired: true,
      isSortStatusAsc: true,
      columns: [
        {
          dataIndex: "rank",
          title: "#",
          width: "50px"
        },
        {
          dataIndex: "retailerName",
          width: "16%",
          scopedSlots: { customRender: "brandColumn" },
          slots: { title: "name" }
        },
        {
          // title: "Location",
          dataIndex: "location",
          width: "10%",
          slots: { title: "location" }
        },
        {
          // title: "Short Name",
          dataIndex: "shortName",
          width: "11%",
          slots: { title: "shortName" }
        },
        {
          // title: "Date Registered",
          dataIndex: "createdAt",
          width: "11%",
          scopedSlots: { customRender: "regiserDate" },
          slots: { title: "date" }
        },
        {
          // title: "Margin",
          dataIndex: "margin",
          width: "8%",
          scopedSlots: { customRender: "marginValue" },
          slots: { title: "marginTitle" }
        },
        {
          // title: "Payment Terms",
          dataIndex: "paymentTerm",
          width: "12%",
          scopedSlots: { customRender: "payment" },
          slots: { title: "paymentTitle" }
        },
        {
          //title: "Shipping",
          dataIndex: "shipping",
          width: "10%",
          slots: { title: "shipping" }
        },
        {
          //title: "Change Required",
          dataIndex: "termsUpdatePending",
          scopedSlots: { customRender: "changeRequiredColumn" },
          slots: { title: "changeRequired" },
          width: "10%"
        },
        {
          //title: "Status",
          dataIndex: "status",
          width: "10%",
          slots: { title: "statusTitle" },
          scopedSlots: { customRender: "status" }
        }
      ],
      selectedRecords: new Set(),
      pageSize: 30
    }
  },
  computed: {
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    searchResult() {
      return this.$store.getters["adminConsole/retailers"]
    }
  },
  created() {
    this.getReferenceData()
    this.getRetailerName()
    this.searchRetailerData()
  },
  methods: {
    getReferenceData() {
      this.$store.dispatch("reference/setReference").then(() => {
        this.channelTypes = this.$store.getters["reference/channelType"]
        this.locationTypes = this.$store.getters["reference/locationType"]
        this.locations = this.$store.getters["reference/countries"]
        this.otherTypes = this.$store.getters["reference/otherType"]
        this.initData(this.channelTypes)
        this.initData(this.locationTypes)
        this.initData(this.locations)
        this.initData(this.otherTypes)
        this.initSearchItems()
      })
    },
    getRetailerName() {
      this.$store
        .dispatch("retailer/fetchAllRetailers", { params: { fields: "name, shortName, id" } })
        .then(() => {
          let retailers = this.$store.getters["retailer/retailers"]
          if (retailers && retailers.length > 0) {
            this.retailerName = []
            this.retailerShortName = []
            retailers.forEach(element => {
              this.retailerName.push({ name: element.name, id: element.id })
              if (element.shortName) {
                this.retailerShortName.push({ name: element.shortName, id: element.id })
              }
            })
            this.initData(this.retailerName)
            this.initData(this.retailerShortName)
            this.initSearchItems()
            this.searchRetailerData()
          }
        })
    },
    initSearchItems() {
      this.searchItems = []
      this.searchItems.push({
        name: "Retailer Name",
        paramName: "retailerId",
        canSearch: true,
        isUseId: true,
        child: this.retailerName
      })
      this.searchItems.push({
        name: "Short Name",
        paramName: "retailerId",
        isUseId: true,
        canSearch: false,
        child: this.retailerShortName
      })
      this.searchItems.push({
        name: "Channel Type",
        paramName: "channeyType",
        canSearch: false,
        child: this.channelTypes
      })
      this.searchItems.push({
        name: "Location Type",
        paramName: "locationType",
        canSearch: false,
        child: this.locationTypes
      })
      this.searchItems.push({
        name: "Other Type",
        paramName: "otherType",
        canSearch: false,
        child: this.otherTypes
      })
      this.searchItems.push({
        name: "Location",
        paramName: "location",
        canSearch: false,
        child: this.locations
      })
      this.searchItems.push({
        name: "Status",
        paramName: "status",
        canSearch: false,
        child: this.status
      })
    },
    initData(data) {
      if (data && data.length > 0) {
        data.forEach(item => {
          item.isChecked = false
          item.hasChild = false
        })
      }
    },
    getBaseParam(page) {
      return {
        _limit: this.pageSize,
        // sortByRetailerName: this.isSortAsc,
        "page-index": page
        // sortByShortName: this.isSortShortNameAsc,
        // sortByLocation: this.isSortLocationAsc,
        // sortByCreatedAt: this.isSortDateAsc,
        // sortByMargin: this.isSortMarginAsc,
        // sortByPaymentTerms: this.isSortPaymentAsc,
        // sortByStatus: this.isSortStatusAsc
      }
    },
    doSearch(param) {
      let params = Object.assign({}, param, this.getBaseParam(1))
      this.searchParam = params
      params = Object.assign({}, params, this.sortParam)
      this.searchRetailerData(params)
    },
    getNextPageData(page) {
      this.curPage = page
      let param = undefined
      if (this.searchParam) {
        param = Object.assign({}, this.searchParam, this.getBaseParam(page))
      } else {
        param = this.getBaseParam(page)
      }
      param = Object.assign({}, param, this.sortParam)
      this.searchRetailerData(param)
    },
    async searchRetailerData(param) {
      if (!param) {
        await this.$store.dispatch("adminConsole/fetchRetailers", this.getBaseParam(1))
      } else {
        await this.$store.dispatch("adminConsole/fetchRetailers", param)
      }
    },
    doSortByRetailerName() {
      this.isSortAsc = !this.isSortAsc
      this.sortParam = {}
      this.sortParam.sortByRetailerName = this.isSortAsc
      this.getNextPageData(1)
    },
    doSortByShortName() {
      this.isSortShortNameAsc = !this.isSortShortNameAsc
      this.sortParam = {}
      this.sortParam.sortByShortName = this.isSortShortNameAsc
      this.getNextPageData(1)
    },
    doSortByLocation() {
      this.isSortLocationAsc = !this.isSortLocationAsc
      this.sortParam = {}
      this.sortParam.sortByLocation = this.isSortLocationAsc
      this.getNextPageData(1)
    },
    doSortByMargin() {
      this.isSortMarginAsc = !this.isSortMarginAsc
      this.sortParam = {}
      this.sortParam.sortByMargin = this.isSortMarginAsc
      this.getNextPageData(1)
    },
    doSortByDate() {
      this.isSortDateAsc = !this.isSortDateAsc
      this.sortParam = {}
      this.sortParam.sortByCreatedAt = this.isSortDateAsc
      this.getNextPageData(1)
    },
    doSortByPayment() {
      this.isSortPaymentAsc = !this.isSortPaymentAsc
      this.sortParam = {}
      this.sortParam.sortByPaymentTerms = this.isSortPaymentAsc
      this.getNextPageData(1)
    },
    doSortByShipping() {
      this.isSortShippingAsc = !this.isSortShippingAsc
      this.sortParam = {}
      this.sortParam.sortByShipping = this.isSortShippingAsc
      this.getNextPageData(1)
    },
    doSortByChangeRequired() {
      this.isSortChangeRequired = !this.isSortChangeRequired
      this.sortParam = {}
      this.sortParam.sortByChangeRequired = this.isSortChangeRequired
      this.getNextPageData(1)
    },
    doSortByStatus() {
      this.isSortStatusAsc = !this.isSortStatusAsc
      this.sortParam = {}
      this.sortParam.sortByStatus = this.isSortStatusAsc
      this.getNextPageData(1)
    },

    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },

    handleMenuClick(e, item) {
      this.client = item
      if (e.key === "approved") {
        this.approveModal = true
      } else {
        let payload = { status: { id: e.key }, id: item.id }
        this.updateRetailer(payload)
        this.client = undefined
      }
    },
    async updateRetailer(payload) {
      try {
        await this.$store.dispatch("retailer/updateRetailerPartially", payload)
        this.approveModal = false
        this.approveError = ""
        this.showError = false
        this.getNextPageData(this.curPage)
      } catch (error) {
        this.handleApproveError(error)
      }
    },
    handleApproveError(error) {
      if (error.status === 400) {
        this.approveError = error.message
        this.showError = true
        return
      }
    },
    hideApproveModal() {
      this.approveModal = false
    },
    subscribe(shortName) {
      this.updateRetailer({ status: { id: "approved" }, shortName, id: this.client.id })
    },
    hideChangeRequestModal() {
      this.showChanges = false
    },
    approve() {
      this.retailer.termsPending.status.id = "approved"
      this.retailer.termsPending.status.name = "Approved"
      this.updatePendingTermStatus()
    },
    reject() {
      this.retailer.termsPending.status.id = "rejected"
      this.retailer.termsPending.status.name = "Rejected"
      this.updatePendingTermStatus()
    },
    async updatePendingTermStatus() {
      try {
        await this.$store.dispatch("retailer/updatePendingTerms", this.retailer.termsPending)
        this.client.termsUpdatePending = false
      } catch (error) {
        console.error(error)
      }

      try {
        await this.$store.dispatch("retailer/fetchRetailer", this.retailer.id)
      } catch (error) {
        console.error(error)
      }
    },
    clickToRetailerProfile(item) {
      this.$router.push({
        name: "adminRetailerProfile",
        params: {
          retailerId: item.id
        }
      })
    },
    async showChangeRequestModal(record) {
      this.client = record
      await this.$store.dispatch("retailer/fetchRetailer", this.client.id)
      this.showChanges = true
    },
    async clickToDownloadData() {
      let param = Object.assign({}, this.searchParam)
      param["_limit"] = this.searchResult.total
      param["page-index"] = 1
      param = Object.assign({}, param, this.sortParam)
      await this.$store
        .dispatch("adminConsole/downloadRetailer", param)
        .then(result => {
          let fileName = `retailer_search_result.csv`
          this.download(result, fileName)
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    async download(data, fileName) {
      await downloadCsvDataStr(data, fileName)
        .then(() => {
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    }
  }
}
</script>

<style lang="scss">
.admin-retailer-search {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 10px;
  &__download-btn {
    background: #4a8a5d !important;
    border: 1px solid #4a8a5d !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #fff !important;
    margin: 42px 0px 10px 0px;
  }
}
.admin-console-brand-retailer-product-table
  .ant-table-bordered
  > .ant-table-content
  .ant-table-thead
  > tr
  > th {
  padding: 7px 12px;
  color: #595959;
  background: #fafafa;
}
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-tbody > tr > td {
  padding: 7px 12px;
  color: #595959;
}
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-tbody > tr > td,
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-thead > tr > th {
  border-right: none;
}
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-thead > tr > th i {
  display: none;
  position: absolute;
  top: 2px;
  margin-left: 8px;
  // right: 0px;
}
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-thead > tr > th:hover i {
  display: inline-block;
}
.admin-console-brand-retailer-product-table
  .ant-table-bordered
  .ant-table-tbody
  > tr
  > td:last-child,
.admin-console-brand-retailer-product-table
  .ant-table-bordered
  .ant-table-thead
  > tr
  > th:last-child {
  border-right: 1px solid #e8e8e8;
}
.admin-console-brand-retailer-product-table .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0px;
}
.admin-console-brand-retailer-product-table .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0px;
}
.admin-console-brand-link-click:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
