<template lang="pug">
.retailer-settings__title {{$t('Retailers.RetailerSettings.myAccount')}}
      p.retailer-settings__account-information_text {{$t('Retailers.RetailerSettings.personalInfo')}}
      form.retailer-settings__form-section(@submit.prevent="updateAccount")
        .columns
          .column
            label.label.retailer-settings__account-information_label {{$t('Retailers.RetailerSettings.firstName')}} *
            .control
              input.input.retailer-settings__input(
                placeholder="Enter First Name" v-model="$v.myAccount.firstName.$model"
                :class="{ 'is-danger': $v.myAccount.firstName.$error }"
                @input="resetValidationFlags"
              )
              p.help.is-danger(v-if="$v.myAccount.firstName.$error") {{$t('Retailers.RetailerSettings.Validations.required')}}
          .column.margin-style
            label.label.retailer-settings__account-information_label-right {{$t('Retailers.RetailerSettings.lastName')}} *
            .control
              input.input.retailer-settings__input-right(
                placeholder="Enter Last Name" v-model="$v.myAccount.lastName.$model"
                :class="{ 'is-danger': $v.myAccount.lastName.$error }"
                @input="resetValidationFlags"
              )
              p.help.is-danger(v-if="$v.myAccount.lastName.$error") {{$t('Retailers.RetailerSettings.Validations.required')}}
        .columns
          .column
            label.label.retailer-settings__account-information_label {{$t('Retailers.RetailerSettings.phoneNumber')}}
            .control
              input.input.retailer-settings__input(
                placeholder="Phone Number" v-model="$v.myAccount.phoneNumber.$model"
                @input="resetValidationFlags"
              )
          .column.margin-style
            .control
              label.label.retailer-settings__account-information_label-right {{$t('Retailers.RetailerSettings.email')}}
              p.retailer-settings__account-information_text-right(v-show="email") {{ email }}
        .columns
          .column.is-6.retailer-settings__account-information_job-title
            label.label.retailer-settings__account-information_label {{$t('Retailers.RetailerSettings.jobTitle')}}
            .control
              input.input.retailer-settings__input(
                placeholder="Job Title" v-model="$v.myAccount.jobTitle.$model"
                @input="resetValidationFlags"
              )

        .columns.is-marginless(v-show="myAccount.isSaveInProgress")
          .column
           .control
              img(src="@/assets/img/spinner.gif" alt="spinner")

        .columns
          .column
            .control
              button.button.is-black.retailer-settings__account-information_save-button(:disabled="isDisabled") {{$t('Retailers.RetailerSettings.save')}}
</template>

<script>
import { required } from "vuelidate/lib/validators"

export default {
  validations: {
    myAccount: {
      firstName: { required },
      lastName: { required },
      phoneNumber: {},
      jobTitle: {}
    }
  },
  data() {
    return {
      myAccount: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        jobTitle: undefined,
        email: "",
        isSaveInProgress: false,
        isSaveSuccessfull: false
      }
    }
  },
  computed: {
    email() {
      return this.$store.getters["user/email"]
    },
    isDisabled() {
      if (this.myAccount.isSaveSuccessfull) {
        return true
      } else {
        return !this.$v.myAccount.$anyDirty
      }
    }
  },
  async created() {
    this.myAccount.userId = this.$store.getters["user/id"]
    await this.$store.dispatch("user/fetchUser", this.myAccount.userId)
    this.myAccount.firstName = this.$store.getters["user/givenName"]
    this.myAccount.lastName = this.$store.getters["user/familyName"]
    this.myAccount.phoneNumber = this.$store.getters["user/phoneNumber"]
    this.myAccount.jobTitle = this.$store.getters["user/jobTitle"]
    this.myAccount.email = this.$store.getters["user/email"]
  },
  methods: {
    userRootObject() {
      return {
        id: this.myAccount.userId,
        givenName: this.myAccount.firstName,
        familyName: this.myAccount.lastName,
        phoneNumber: this.myAccount.phoneNumber,
        email: this.myAccount.email,
        jobTitle: this.myAccount.jobTitle
      }
    },
    async updateAccount() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.myAccount.isSaveInProgress = true
      try {
        await this.$store.dispatch("user/updateUser", this.userRootObject())
        this.$vs.notify({
          title: "Notification",
          text: "My Account saved successfully",
          color: "green"
        })
      } catch (error) {
        console.error(error)
      }
      try {
        await this.$store.dispatch("user/fetchUser", this.myAccount.userId)
        this.myAccount.isSaveSuccessfull = true
      } catch (error) {
        this.myAccount.isSaveSuccessfull = false
        console.error(error)
      }
      this.myAccount.isSaveInProgress = false
    },
    resetValidationFlags() {
      this.myAccount.isSaveSuccessfull = false
    }
  }
}
</script>

<style lang="sass">
.retailer-settings
  padding-left: 80px !important
  padding-bottom: 300px
  padding-top: 0px
  &__title-style
    font-weight: bold
    font-size: 14px
    color: #333333

  &__sub-menuItem-link
    margin-left :21px
    color: #7F7F7F
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 24px

  &__input
    box-sizing: border-box
    height: 40px
    width: 320px
    border: 1px solid #E5E5E5
    border-radius: 2px

  &__input-right
    box-sizing: border-box
    height: 40px
    width: 320px
    border: 1px solid #E5E5E5
    border-radius: 2px

  &__form-section

  &__account-information
    margin-top: 43px
    .margin-style
      margin-right: 97px

    &_edit-column
      display: grid

    &_label
      color: black
      display: block
      font-size: 14px
      font-weight: 400

    &_label-right
      color: #363636
      display: block
      font-size: 14px
      font-weight: 400

    &_job-title
      margin-top: -28px

    &_text
      margin-bottom: 32px
      color: #000000
      font-size: 16px
      letter-spacing: 0.25px
      line-height: 18px
      margin-top: 45px

    &_text-right
      margin-bottom: 32px
      color: #000000
      font-size: 14px
      letter-spacing: 0.25px
      line-height: 18px
      margin-left: 22px
      margin-top: 22px

    &_edit-button
      color: #161616
      font-size: 16px
      font-weight: 300
      letter-spacing: 0.16px
      line-height: 18px
      text-decoration: underline
      cursor: pointer

    &_save-button
      height: 36px
      width: 83px
      color: #292929
      font-weight: 600
      font-size: 14px
      margin-top: 21px
    .is-black
      background-color: #292929

  &__link-my-account
    color: #000000
    font-size: 16px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__link-retailer-account
    color: #7F7F7F
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px
    margin-top: 26px

  &__retailer-account
    padding: 18px
    margin-left: 20px
</style>
