<template>
  <div ref="productViewDialog" class="product-view-dialog-new__main">
    <a-modal
      v-model="isShow"
      width="960px"
      :get-container="() => $refs.productViewDialog"
      :footer="null"
      @cancel="closeModal"
    >
      <div
        slot="title"
        style="text-align: center;font-size: 20px;color: #1a1818"
      >
        {{ userInfo.name }} Training Details
      </div>
      <div class="brand-info-trining">
        <brandInfoProductDetail
          :user-info="userInfo"
          :brand-img-and-video-info="brandImgAndVideoInfo"
          @updateBrandInfo="updateBrandInfo"
        ></brandInfoProductDetail>
      </div>
      <div
        :class="productList.length > 1 ? 'read-product-training-details' : ''"
      >
        <div
          v-for="(product, index) in productList"
          :key="index"
          class="item-product-training-details"
        >
          <div class="read-product-top">
            <div class="product-info-left">
              <p>
                <span class="test-title-name">Brand:</span>
                <span>{{ brandName }}</span>
              </p>
              <p>
                <span class="test-title-name">Name:</span>
                <span class="product-name-info">{{ product.name }}</span>
              </p>
              <p>
                <span class="test-title-name">SKU:</span>
                <span>{{ product.sku }}</span>
              </p>
              <p>
                <span class="test-title-name">UPC:</span>
                <span>{{ product.barcodes[0].code }}</span>
              </p>
              <p>
                <span class="test-title-name">MSRP:</span>
                <span>${{ product.msrpUsd }}</span>
              </p>
              <p>
                <span class="test-title-name">Category:</span>
                <span>{{ getCategoryPath(product) }}</span>
              </p>
              <p>
                <span class="test-title-name">Hero:</span>
                <a-switch
                  v-model="product.training.hero"
                  disabled="true"
                ></a-switch>
              </p>
            </div>
            <div class="product-img-video-right">
              <div v-if="product.hasVideo" class="img-video-content">
                <p>Videos</p>
                <div class="video-add-style">
                  <div
                    v-for="(item, index) in product.imgAssetsList.filter(
                      (video) =>
                        video.asset && video.asset.contentType.includes('video')
                    )"
                    :key="index"
                    class="videos-imgs-style"
                  >
                    <div
                      v-if="item.imgAsset"
                      class="video-style"
                      @click="clickToVideo(item.asset.url)"
                    >
                      <img
                        class="public-start"
                        src="../../../assets/training_public_video.jpg"
                        alt=""
                      />
                      <video
                        width="auto"
                        height="65"
                        style="height: 65px !important;"
                      >
                        <source :src="item.asset.url" type="video/mp4" />
                        Your browser does not support the HTML5 video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="product.hasImg" class="img-video-content">
                <p>Images</p>
                <div class="img-add-style">
                  <div
                    v-for="(item, index) in product.imgAssetsList.filter(
                      (video) =>
                        video.asset && video.asset.contentType.includes('image')
                    )"
                    :key="index"
                    class="videos-imgs-style"
                  >
                    <div
                      class="video-style img-style-add"
                      @click="clickToViewImages(item, index)"
                    >
                      <img
                        class="public-start-img"
                        :src="item.asset.url"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="other-info-center-content">
            <div class="form-content">
              <div>
                <span class="form-item-name">
                  Step:
                </span>
                <p class="form-item-content">
                  <span v-for="item in product.trainingSteps" :key="item.id">{{
                    item.name
                  }}</span>
                </p>
              </div>
              <div>
                <span class="form-item-name">
                  Best Used For:
                </span>
                <p class="form-item-content">
                  <span
                    v-for="item in product.trainingBestUsedFors"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </span>
                </p>
              </div>
              <div class="cross-seller-box">
                <span class="form-item-name">
                  Cross-Sellers:
                </span>
                <div class="form-item-content">
                  <span
                    v-for="item in product.trainingCrossSellers"
                    :key="item.id"
                    class="span-cross-seller-text"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </div>
            </div>
            <div class="center-key-benefits">
              <div class="key-title">
                Product Key Benefits:
              </div>
              <ul>
                <li
                  v-for="item in product.newBenefits"
                  :key="item.id"
                  class="line-clamp"
                >
                  <i></i>
                  <p>{{ item.name }}</p>
                </li>
              </ul>
            </div>
          </div>
          <lazyProductTraining
            v-if="isShow"
            :data="product"
          ></lazyProductTraining>
          <!-- <div class="demo-info-tips-bottom">
            <div class="textarea-bottom">
              <div class="center-demo-textarea">
                <p class="demo-tip-style">How To Demo:</p>
                <p class="tip-content-style">
                  {{ product.training.howToDemo }}
                </p>
              </div>
              <div class="demo-tip-content">
                <p class="demo-tip-style">
                  Demo Tips:
                </p>
                <p class="tip-content-style">{{ product.training.demoTips }}</p>
              </div>
            </div>
          </div> -->
        </div>
        <div class="close-dialog">
          <p @click="closeModal">Close</p>
        </div>
      </div>
    </a-modal>
    <a-modal
      :visible="modalVisible"
      :footer="null"
      :body-style="{ padding: 0 }"
      @cancel="handleCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div v-if="modalVideo" class="video-main">
        <video id="video1" ref="videoOpen" controls>
          <source :src="modalVideo" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
    </a-modal>
    <a-modal
      :visible="showImageVisible"
      :footer="null"
      :destroy-on-close="true"
      :body-style="{ padding: 0 }"
      @cancel="handleImgCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div
        style="width: 300px; min-height: 300px; line-height: 300px; margin: 0 auto"
      >
        <img :src="modalImage" alt="" style="width: 100%; height: auto" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import trainingClient from "@/api/training-client";
import brandInfoProductDetail from "@/components/training/__components/brandInfoProductDetailDialog";
import lazyProductTraining from "@/components/shared/lazy-product-training";

export default {
  name: "ProductViewDialogNew",
  components: {
    brandInfoProductDetail,
    lazyProductTraining,
  },
  data() {
    return {
      isShow: false,
      brandName: "",
      shortName: "",
      productList: [],
      modalVisible: false,
      modalVideo: "",
      userInfo: {},
      showImageVisible: false,
      modalImage: "",
      brandImgAndVideoInfo: [],
    };
  },
  computed: {},
  watch: {
    modalVisible() {
      if (this.modalVisible === false) {
        this.$refs["videoOpen"].pause();
      }
    },
  },
  methods: {
    getCategoryPath(product) {
      var catPath = product && product.category ? product.category.name : "";
      catPath +=
        product && product.subcategory ? " > " + product.subcategory.name : "";
      catPath +=
        product && product.microCategory
          ? " > " + product.microCategory.name
          : "";
      return catPath;
    },
    async openProductDialog(list, brandName, userInfo) {
      this.brandImgAndVideoInfo = [];
      this.productList = [];
      this.isShow = true;
      this.userInfo = userInfo;
      this.brandImgAndVideoInfo = this.formatBrandImgAndVideoInfo();
      this.productList = list
        .map((item) => {
          item.imgAssetsList = this.formatTrainingVideos(
            item,
            item.trainingAssets
          );
          if (
            item.imgAssetsList.filter((item) =>
              item.asset.contentType.includes("image")
            ).length !== 0
          ) {
            item.hasImg = true;
          }
          if (
            item.imgAssetsList.filter((item) =>
              item.asset.contentType.includes("video")
            ).length !== 0
          ) {
            item.hasVideo = true;
          }
          return item;
        })
        .filter((item) => item.training.states === 2);
      this.brandName = brandName;
    },
    async getProTips(productId) {
      return await trainingClient
        .getBrandTrainingProTips(productId)
        .catch((error) => {
          if (error.status == 404) {
            return null;
          } else {
            throw error;
          }
        });
    },
    formatBrandImgAndVideoInfo() {
      const logoImg = [
        {
          asset: {
            ...this.userInfo.logoAsset,
            contentType: "image/jpeg",
            canEdit: false,
            isImg: true,
          },
        },
      ];
      let videoImgList =
        this.userInfo.assets && this.userInfo.assets.length !== 0
          ? this.userInfo.assets
          : [];
      if (videoImgList.length !== 0) {
        videoImgList = videoImgList.map((item) => {
          item.canEdit = true;
          item.uid = item.id;
          return item;
        });
      }
      const imgList = videoImgList.filter((item) =>
        item.asset.contentType.includes("image")
      );
      const videoList = videoImgList.filter((item) =>
        item.asset.contentType.includes("video")
      );
      const list = logoImg.concat(imgList).concat(videoList);
      return list.map((item) => {
        if (item.asset.contentType.includes("video")) {
          item.isImg = false;
        } else {
          item.isImg = true;
        }
        return item;
      });
    },
    async updateBrandInfo(shortName) {
      const brand = await trainingClient.getGuestBrandInfo(shortName);
      this.userInfo = brand.data;
      this.brandImgAndVideoInfo = this.formatBrandImgAndVideoInfo();
    },
    closeModal() {
      this.isShow = false;
    },
    clickToVideo(url) {
      this.modalVideo = url;
      this.modalVisible = true;
      this.$refs["videoOpen"].play();
    },
    handleCancel() {
      this.modalVisible = false;
      this.modalVideo = "";
      this.$refs["videoOpen"].pause();
    },
    handleImgCancel() {
      this.showImageVisible = false;
      this.modalImage = "";
    },
    formatTrainingAssets(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].imgAsset && Object.values(list[i].imgAsset).length !== 0) {
          const obj = {
            asset: {
              ...list[i].imgAsset,
            },
            title: list[i].title,
          };
          list[i].imgAsset = {};
          list.push(obj);
        }
      }
      return list;
    },
    formatTrainingVideos(item, list) {
      if (!list || list.length === 0) {
        if (item.images && item.images.length === 1) {
          return item.images;
        }
        return item.images.slice(0, 2);
      }
      if (list.length === 4) {
        const img = list.filter((item) =>
          item.asset.contentType.includes("image")
        );
        const video = list.filter((item) =>
          item.asset.contentType.includes("video")
        );
        return img.concat(video);
      }
      let trainingAssetList = this.formatTrainingAssets(list);
      const trainingAssetImg = trainingAssetList.filter((item) =>
        item.asset.contentType.includes("image")
      );
      const trainingAssetVideo = trainingAssetList.filter((item) =>
        item.asset.contentType.includes("video")
      );
      return trainingAssetImg.concat(trainingAssetVideo);
    },
    clickToViewImages(item, index) {
      console.log(index);
      this.showImageVisible = true;
      this.modalImage = item.asset.url;
    },
  },
};
</script>

<style scoped lang="scss">
.product-view-dialog-new__main {
  p {
    margin-bottom: 0;
  }
  .brand-info-trining {
    margin: 20px 0;
    border-bottom: 1px solid #d9d9d9;
  }
  .read-product-training-details {
    padding: 0 30px 30px 30px;
  }
  .item-product-training-details {
    border-bottom: 1px solid #d9d9d9;
    margin: 20px 0;
  }
  .read-product-top {
    display: flex;
    justify-content: start;
    color: #000000;
    font-size: 14px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    .product-info-left {
      width: 400px;
      margin-right: 40px;
      p {
        width: 100%;
        line-height: 22px;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
      }
      .test-title-name {
        display: inline-block;
        width: 65px;
      }
      .product-name-info {
        width: 300px;
      }
    }
    .product-img-video-right {
      display: flex;
      justify-content: start;
      align-items: center;
      .video-add-style {
        margin-right: 35px;
        width: 200px;
        display: flex;
      }
      .img-add-style {
        width: 200px;
        display: flex;
      }
      .videos-imgs-style {
        min-width: 90px;
        display: flex;
        justify-content: start;
      }
    }
    .video-style {
      width: 75px;
      height: 69px;
      border: 1px solid #d9d9d9;
      border-radius: 7px;
      position: relative;
      margin-right: 16px;
      text-align: center;
    }
    .img-style-add {
      border-radius: 0;
    }
    .public-start {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 24px;
      left: 24px;
    }
    .public-start-img {
      width: auto;
      height: 100%;
    }
  }
  .other-info-center-content {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: start;
    .form-content {
      width: 400px;
      margin-right: 40px;
      color: #262626;
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
    .form-item-name {
      display: inline-block;
      width: 101px;
      text-align: left;
      margin-right: 24px;
    }
    .form-item-content {
      display: inline-block;
      width: 400px;
      min-height: 24px;
      background-color: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 7px;
      padding: 4px 5px;
      margin-bottom: 12px;
      margin-right: 20px;
      span,
      .span-cross-seller-text {
        margin-right: 4px;
        padding: 1px 8px;
        border: 1px solid #e2eeec;
        border-radius: 2px;
        background-color: white;
      }
      .span-cross-seller-text {
        margin-bottom: 2px;
        display: block;
      }
    }
    .center-key-benefits {
      //height: 221px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      .key-title {
        font-size: 14px;
        margin-bottom: 8px;
        color: #262626;
        i {
          font-size: 12px;
          color: #bfbfbf;
        }
      }
      ul li {
        display: flex;
        justify-content: space-between;
        width: 100%;
        //height: 80px;
        //line-height: 16px;
        //list-style-type: disc;
        word-break: break-word;
        margin-bottom: 40px;
        -webkit-line-clamp: 5;
        i {
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #000000;
          margin-right: 4px;
          margin-top: 5px;
        }
        p {
          //  display: inline-block;
          width: 381px;
          font-size: 14px;
          color: #262626;
          font-family: "Proxima Nova", "Roboto", sans-serif;
          margin-bottom: 0;
          //  color: #000000;
          //  font-family: "Proxima Nova", "Roboto", sans-serif;
        }
      }
    }
  }
  .demo-info-tips-bottom {
    .textarea-bottom {
      //width: 392px;
      display: flex;
      justify-content: start;
    }
    .center-demo-textarea {
      width: 400px;
      margin-right: 40px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      position: relative;
    }
    .tip-content-style {
      width: 392px;
      min-height: 96px;
      font-size: 14px;
      color: #262626;
      border: 1px solid #d9d9d9;
      padding: 8px 9px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      margin-bottom: 16px;
      //word-break: break-word;
      //-webkit-line-clamp: 4;
    }
    .demo-tip-style {
      font-size: 14px;
      margin-bottom: 8px;
      color: #000000;
    }
    .demo-tip-content {
      width: 400px;
      position: relative;
    }
  }
  .close-dialog {
    text-align: right;
    margin-top: 20px;
    cursor: pointer;
    p {
      display: inline-block;
      width: 160px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      background: #f27c59;
      border-radius: 7px;
    }
  }
}
.ant-switch-checked {
  background-color: rgba(243, 202, 80, 1);
}
</style>
