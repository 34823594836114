<template>
  <div ref="brandAndRetailerHeaderSearch" class="header-search__main">
    <div class="search-container">
      <div class="search-icon" @click="goSearch(searchValue)">
        <!--        <img src="@/assets/img/icon/search-icon.svg" alt="" />-->
        <a-icon type="search" />
      </div>
      <a-select
        id="searchBox"
        v-model="searchValue"
        show-search
        type="text"
        class="header-search-box"
        :default-active-first-option="false"
        :show-arrow="false"
        :disabled="isBrandExpired"
        :filter-option="false"
        :not-found-content="null"
        :placeholder="searchText"
        :style="{ width: '220px', 'margin-top': '25px', 'border-radius': '40px' }"
        @getPopupContainer="() => $refs.brandAndRetailerHeaderSearch"
        @change="searchSure"
        @search="searchSure"
        @blur="searchToBrandProfile"
      >
        <a-select-option v-for="search in searchList" :key="search.id" :value="search.id">
          {{ search.name }}
        </a-select-option>
      </a-select>
    </div>
    <BrandTableDialog ref="SearchUpgradePlanDialog" :brand-type="brandType" />
    <!--    <div v-show="showDropdown" id="searchBrandShow" class="search-brand-list" @click="closeSel">-->
    <!--      <div v-if="searchList && searchList.length === 0" class="no-brand-data">no data</div>-->
    <!--      <div class="brand-list-content">-->
    <!--        <div-->
    <!--          v-for="search in searchList"-->
    <!--          :key="search.id"-->
    <!--          class="brand-item"-->
    <!--          @click="searchToBrandProfile(search.id)"-->
    <!--        >-->
    <!--          {{ search.name }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    .search-dropdown(v-if="showDropdown")-->
    <!--    RetailerQuickSearchDropdown(@disableDropdown="disableDropdown")-->
  </div>
</template>
<script>
// import RetailerQuickSearchDropdown from "@/components/brands/retailers/RetailerQuickSearchDropdown"
import retailerClient from "@/api/retailer-client"
import brandClient from "@/api/brand-client"
import BrandTableDialog from "@/components/brands/mainPage/components/BrandTableDialog"

export default {
  components: {
    // RetailerQuickSearchDropdown
    BrandTableDialog
  },
  props: {
    isRetailer: { type: Boolean },
    isBrandExpired: { type: Boolean },
    showBrandDropdown: { type: Number }
  },
  data() {
    return {
      searchValue: "",
      showDropdown: false,
      searchBrandList: [],
      searchList: []
    }
  },
  computed: {
    searchTerm: {
      get() {
        return this.$store.state.retailerSearch.searchTerm
      },
      set(value) {
        this.$store.dispatch("retailerSearch/updateSearchTerm", value)
      }
    },
    searchText() {
      return this.isRetailer ? "Search for Brands" : "Search for Retailers"
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions.length !== 0 &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    }
  },
  watch: {
    searchValue() {
      if (this.searchValue.length >= 2) {
        this.searchSure(this.searchValue)
      }
    },
    showBrandDropdown(value) {
      if (value > 0) {
        this.showDropdown = false
      }
    }
  },
  async created() {
    await this.getSearchBrandList()
  },
  methods: {
    async getSearchBrandList() {
      let list
      if (this.isRetailer) {
        list = await brandClient.getAllBrands({ fields: "id,name,shortName" })
      } else {
        list = await retailerClient.getAllRetailers({ fields: "id,name,shortName" })
      }
      this.searchBrandList = list.data
    },
    showUpdatePlanModal() {
      this.$refs["SearchUpgradePlanDialog"].openDialog(true)
    },
    searchToBrandProfile(value) {
      if (value && value.length > 0) {
        if (this.brandType === "basic" && !this.isRetailer) {
          this.showUpdatePlanModal()
          this.showDropdown = false
          this.searchValue = ""
          return
        }
        if (this.isRetailer) {
          this.$router.push({
            name: "retailerBrandProfile",
            params: {
              brandId: value
            }
          })
        } else {
          this.$router.push({
            name: "brandRetailerProfile",
            params: {
              retailerId: value
            }
          })
        }
      }
      this.showDropdown = false
      this.searchValue = ""
    },
    /**
     * sure search
     */
    searchSure(value) {
      this.searchList = this.searchBrandList.filter(item => {
        const name = item.name.toLocaleUpperCase()
        const text = value.toLocaleUpperCase()
        return name.indexOf(text) !== -1
      })
      if (this.searchList && this.searchList.length !== 0) {
        this.showDropdown = true
      }
      this.$store.commit("reference/RESET_FILTERS")
      if (this.isRetailer) {
        return
      }
      if (this.brandType === "basic") {
        // this.showUpdatePlanModal()
        return
      }
      // The search type is 0-5, and this search is independent of the others, so it is 6
      this.$router.push({
        name: "BrandSearch",
        query: {
          id: "0",
          "channel-type": ""
          // searchValue: value
        }
      })
    },
    goSearch() {
      if (this.isRetailer) {
        return
      }
      // The default jumps to the first type
      this.$router.push({
        name: "BrandSearch",
        query: {
          id: "0",
          "channel-type": ""
        }
      })
    },
    async disableDropdown() {
      this.showDropdown = false
    }
  }
}
</script>

<style lang="sass">
//.header-search__main
  //position: relative
//.search-container
//  display: flex
//  align-items: center
//.search-box
//  padding: 20px
//  //border: 1px solid #d9d9d9
//  border-radius: 40px
//  margin-right: 10px
//  height: 25px
//  width: 220px
//  margin-top: 20px
.header-search-box
  margin-top: 25px
  //border-radius: 40px
  //.search-dropdown
  //    align: center
  .ant-select-selection
    border-radius: 25px
    height: 35px
  .ant-select-search--inline
    input
      //border-radius: 40px
      padding-left: 14px !important
.search-icon
  cursor: pointer
  display: inline-flex
  position: absolute
  margin-left: 7px
  margin-top: 34px
  color: #8c8c8c
  font-size: 17px
  z-index: 12
</style>
