<template>
  <a-modal
    v-model="isShowUpdateCommissionModal"
    :footer="null"
    :destroy-on-close="true"
    class="is-active brand-settings-modal"
    width="601px"
    :body-style="noPadding"
  >
    <template #closeIcon>
      <img
        class="request-terms-modal__close-button"
        src="@/assets/img/icon/close-icon.svg"
        @click="closeCommissionModal"
      />
    </template>
    <p class="request-terms-modal__header no-margin">
      {{ $t("Brands.BrandSettings.changeCommission") }}
    </p>
    <div class="section modal-card-body subscription__form-block padding-small">
      <div class="column-section">
        <label class="label brand-settings-wireinstruction">
          {{ $t("Brands.BrandSettings.commission") }}
        </label>
        <a-input-number
          v-model="defaultCommission"
          class="input brand-settings-wireinstruction"
          :min="0"
          :max="100"
          :class="{ 'is-danger': $v.modifyCommission.$error }"
          @change="changeCommission"
        />
        <p v-if="!$v.modifyCommission.numeric" class="help is-danger">
          {{ $t("Brands.BrandSettings.enterNumericValue") }}
        </p>
      </div>
      <div class="modal-card-foot modal-actions">
        <a class="button is-white" @click="closeCommissionModal">
          {{ $t("Brands.BrandSettings.cancel") }}
        </a>
        <a class="button is-black" @click="saveCommissionUpdate">
          {{ $t("Brands.BrandSettings.save") }}
        </a>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { Modal, InputNumber } from "ant-design-vue"
import { numeric } from "vuelidate/lib/validators"
import OrderClient from "@/api/order-client"

export default {
  components: {
    "a-modal": Modal,
    "a-input-number": InputNumber
  },
  props: {
    commission: { type: Number, default: () => -1 }
  },
  validations: {
    modifyCommission: { numeric }
  },
  data() {
    return {
      modifyCommission: undefined,
      defaultCommission: undefined,
      isShowUpdateCommissionModal: false,
      showValidation: false,
      noPadding: {
        padding: "0px"
      }
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    }
  },
  updated() {
    if (this.defaultCommission === undefined && this.commission && this.commission >= 0) {
      this.defaultCommission = this.commission
    }
  },
  methods: {
    closeCommissionModal() {
      this.isShowUpdateCommissionModal = false
    },
    openDialog() {
      this.isShowUpdateCommissionModal = true
    },
    resetValidationFlags() {
      this.showValidation = false
    },
    changeCommission(value) {
      this.modifyCommission = value
      this.resetValidationFlags()
    },
    async saveCommissionUpdate() {
      this.$v.modifyCommission.$touch()
      if (this.$v.modifyCommission.$invalid) {
        return
      }
      if (this.modifyCommission === undefined) {
        this.closeCommissionModal()
        return
      }
      let data
      let action = "add"
      let commissionOverrides = this.brand.transactionFeeTerms.filter(
        item => item.brandId.length > 1 && item.standard && item.retailerId === "*"
      )
      if (commissionOverrides.length !== 0) {
        data = [
          {
            ...commissionOverrides[0],
            transactionFeeValue: this.modifyCommission
          }
        ]
        action = "update"
      } else {
        data = [
          {
            retailerId: "*",
            transactionFeeValue: this.modifyCommission,
            standard: true,
            enabled: true,
            accepted: true
          }
        ]
      }
      try {
        await OrderClient.updateTransaction(this.brand.id, action, data)
        await this.$store.dispatch("brand/getBrand", this.brand.id)
        // let params = []
        // params.push({ brand: { id: this.brandId }, commission: this.modifyCommission })
        // await this.$store.dispatch("brand/updateSubscriptionCommission", params)
        this.closeCommissionModal()
        this.modifyCommission = undefined
      } catch (error) {
        this.showValidation = true
        console.error(error)
      }
    }
  }
}
</script>

<style lang="sass"></style>
