<template>
  <a-modal v-model="isShowMaximumOrdersModal"
           :footer="null"
           :destroyOnClose="true"
           class="is-active brand-settings-modal"
           width="601px"
           :bodyStyle="noPadding">
    <template #closeIcon>
      <img class="request-terms-modal__close-button" src="@/assets/img/icon/close-icon.svg" @click="closeOrderModal" />
    </template>
    <p class="request-terms-modal__header no-margin">
      {{$t('Brands.BrandSettings.editMaxOrders')}}
    </p>
    <div class="section modal-card-body subscription__form-block padding-small">
      <div class="column-section">
        <label class="label brand-settings-wireinstruction">
          {{$t('Brands.BrandSettings.maxNumberOfOrders')}}
        </label>
        <input class="input brand-settings-wireinstruction"
               v-model="maxOrders"
               :class="{'is-danger': $v.maxOrders.$error}"
               @input="resetValidationFlags"/>
        <p class="help is-danger" v-if="!$v.maxOrders.required">
          {{$t('Brands.BrandSettings.maxOrdersCannotBeEmpty')}}
        </p>
        <p class="help is-danger" v-if="!$v.maxOrders.numeric">
          {{$t('Brands.BrandSettings.enterNumericValue')}}
        </p>
      </div>
      <div class="modal-card-foot modal-actions">
        <a class="button is-white" @click ="closeOrderModal">
          {{$t('Brands.BrandSettings.cancel')}}
        </a>
        <a class="button is-black" @click ="saveMaxOrders">
          {{$t('Brands.BrandSettings.save')}}
        </a>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import { required, numeric } from "vuelidate/lib/validators"
  import { Modal } from "ant-design-vue"
  export default {
    props: {
      brand: { type: Object, default: () => undefined }
    },
    components: {
      "a-modal": Modal
    },
    validations: {
      maxOrders: { required, numeric }
    },
    updated() {
      if ( this.brandInfo === undefined && this.brand !== undefined ) {
        this.brandInfo = this.brand
      }
      if (this.maxOrders === undefined && (this.brand !== undefined)) {
        this.maxOrders = this.brand.scopes.maxOrders
      }
    },
    data() {
      return {
        isShowMaximumOrdersModal: false,
        showValidation: false,
        brandInfo: undefined,
        maxOrders: undefined,
        noPadding: {
          padding: "0px"
        }
      }
    },
    methods: {
      closeOrderModal() {
        this.isShowMaximumOrdersModal = false
        this.maxOrders = this.brand.scopes.maxOrders
      },
      resetValidationFlags() {
        this.showValidation = false
      },
      async saveMaxOrders() {
        this.$v.maxOrders.$touch()
        if (this.$v.maxOrders.$invalid) {
          return
        }
        try {
          this.brandInfo.scopes.maxOrders = this.maxOrders
          await this.$store.dispatch("brand/updateSubscriptions", this.brandInfo.scopes)
          this.closeOrderModal()
        } catch (error) {
          this.showValidation = true
          console.error(error)
        }
      },
      openDialog() {
        this.isShowMaximumOrdersModal = true
      }
    }
  }
</script>

<style lang="sass">

</style>
