<template>
  <div>
    <a-row>
      <a-col :span="18" class="admin-training-search__text">
        Search
      </a-col>
      <!--      <a-col v-if="searchRecent && searchRecent.length > 0" :span="14">-->
      <!--        Recent-->
      <!--      </a-col>-->
    </a-row>
    <a-row>
      <a-col :span="18">
        <a-input-search
          v-model="searchValue"
          :placeholder="searchHint"
          style="width: 90%; padding-left: 10px; max-width: 400px;"
          class="admin-training-search__input-outline"
          @change="onChange"
          @search="onSearch"
        />
      </a-col>
      <a-col :span="6">
        <!--        <a-tag-->
        <!--          v-for="(item, index) in searchRecent"-->
        <!--          :key="`search_recent_${index}`"-->
        <!--          closable-->
        <!--          @click="clickToSearch(item)"-->
        <!--          @close.stop="removeSearchRecent"-->
        <!--        >-->
        <!--          {{ item.name }}-->
        <!--        </a-tag>-->
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  props: {
    searchRecent: { type: Array, default: () => [] },
    searchHint: { type: String, defalut: () => "" }
  },
  data() {
    return {
      searchValue: ""
    }
  },
  methods: {
    onChange() {
      if (this.searchValue.length == 0) {
        this.onSearch()
      }
    },
    onSearch() {
      this.$emit("doSearch", this.searchValue)
    },
    removeSearchRecent(value) {
      this.$emit("deleteTag", value)
    },
    clickToSearch(data) {
      if (data) {
        this.searchValue = data.name
        this.onSearch()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-training-search {
  &__text {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #000000;
  }
  &__input-outline {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    letter-spacing: 0.04em;
    line-height: 20px;
  }
}
</style>
