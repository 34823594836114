<template lang="pug">
.field
  label.brand-profile__edit-modal--form_label(v-if="label") {{ label }}
  .control.input-with-prefix
    input.input-with-prefix__input.input(
      :value="inputValue" @input="updateValue" :placeholder="placeholder"
      :style="{ 'padding-left': prefixOffset }"
    )
    .input-with-prefix__prefix {{ prefix }}
</template>

<script>
export default {
  props: {
    initialValue: { type: String, default: "" },
    label: { type: String, default: "" },
    prefix: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    prefixOffset: { type: String, default: "" },
    isSocialAccount: { type: Boolean, default: false }
  },
  data() {
    return { value: this.initialValue.replace(this.prefix, "") }
  },
  computed: {
    inputValue() {
      if (this.isSocialAccount) {
        return this.initialValue.split("com/")[1]
      } else {
        return this.initialValue
      }
    }
  },
  methods: {
    updateValue(event) {
      const value = event.target.value ? event.target.value : ""
      this.$emit("input", value)
    }
  }
}
</script>

<style lang="sass">
.input-with-prefix
  position: relative

  &__input
    font-size: 14px
    height: 40px
    line-height: 40px
    &::placeholder
      font-size: 500
      line-height: 28px
      padding-left: 0

  &__prefix
    position: absolute
    left: 10px
    z-index: 1
    display: inline
    height: 40px
    line-height: 40px
    color: #000000
    font-family: "Proxima"
    font-size: 14px
    letter-spacing: 0.2px
    pointer-events: none
</style>
