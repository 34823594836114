<template>
  <div>
    <add-header
      :title="title"
      :is-show-edit-btn="!(selectDeleteData && selectDeleteData.size > 0)"
      :items="items"
      @clickDeleteAll="removeItem"
      @addMultiple="addMultiple"
      @editAll="showEditAllDialog"
      @saveDelete="showDeleteConfirm"
    />
    <div class="admin-console-cascade-add__divider"></div>
    <div class="admin-console-cascade-add__collapse">
      <a-collapse v-model="secondActiveKey" :bordered="false" accordion>
        <a-collapse-panel
          v-for="(item, index) in items"
          :key="`${title[0]}_${index}`"
          :show-arrow="false"
          :disabled="item.isSelected"
        >
          <template #header="props">
            <div v-if="item.name && item.name.replace(/^\s*|\s*$/g, '').length > 0">
              <div
                v-if="item && item.isEdit"
                class="admin-console-cascade-add__edit-input"
                contentEditable="true"
                @input="inputChange"
                @click.stop
                @blur="showConfirm"
                @keyup.enter="showConfirm"
              >
                {{ item.name }}
              </div>
              <span
                v-else
                style="margin-right: 12px; margin-top: 4px; margin-bottom: 10px; display: inline-block;"
                @click="getSubcategories(item)"
              >
                <span
                  class="admin-console-cascade-add__item-btn"
                  :class="{ 'admin-console-cascade-add__disabled-btn': item.isSelected }"
                >
                  {{ item.name }}
                  <a-icon
                    type="edit"
                    style="font-size: 16px; margin-right: 4px;"
                    :style="item.isSelected ? 'color: #bfbfbf; ' : 'color: #4A8A5D; '"
                    @click.stop="editItem(item, 0)"
                  ></a-icon>
                  <a-icon
                    type="down"
                    style="font-size: 14px;"
                    :style="item.isSelected ? 'color: #bfbfbf; ' : 'color: #4A8A5D; '"
                    :rotate="props.isActive ? 180 : 0"
                  ></a-icon>
                </span>
                <a-icon
                  v-if="isShowDeleteIcon && !item.isSelected"
                  type="close-circle"
                  class="admin-console-cascade-add__close-icon"
                  theme="filled"
                  @click.stop="chooseDelete(item)"
                />
                <div
                  v-if="item.isSelected"
                  style="width: 21px; height: 21px; border-radius: 50%; background: #8C8C8C;"
                  class="admin-console-cascade-add__undo-icon"
                >
                  <a-icon
                    type="undo"
                    style="color: white; font-size: 16px; position: absolute;top: 2px; right: 2px;"
                    @click.stop="unDoChoose(item)"
                  />
                </div>
              </span>
            </div>
          </template>
          <div class="admin-console-cascade-add__second-div">
            <!-- <img
              src="@/assets/img/icon/admin-console-second-arrow.svg"
              style="position: relative; top: -20px;"
            /> -->
            <ConsoleCascadeHeader
              :is-show-edit-btn="!(selectDeleteDataSecond && selectDeleteDataSecond.size > 0)"
              :item="item"
              @addNewItem="addNewSecond"
              @clickDeleteAll="removeSecondItem"
              @saveDelete="showDeleteSecondConfirm"
              @addMultiple="addMultipleSecond"
              @editAll="showEditSecondAllDialog(item)"
            />
            <div>
              <a-spin v-if="isLoading && loadingItem.privateId === item.privateId" />
              <a-row v-else-if="item.subcategories && item.subcategories.length > 0">
                <a-collapse v-model="thirdActiveKey" :bordered="false" accordion>
                  <a-collapse-panel
                    v-for="(citem, cindex) in item.subcategories"
                    :key="`${title[1]}_${item.name}_${cindex}`"
                    :show-arrow="false"
                    style="margin-top: 10px;"
                  >
                    <template #header="props">
                      <div
                        v-if="citem && citem.isEdit"
                        class="admin-console-cascade-add__edit-input"
                        contentEditable="true"
                        @input="inputChange"
                        @click.stop
                        @blur="showConfirm"
                        @keyup.enter="showConfirm"
                      >
                        {{ citem.name }}
                      </div>
                      <span
                        v-else
                        style="margin-right: 12px; margin-bottom: 4px; display: inline-block;"
                        @click="getMicrocategories(citem)"
                      >
                        <span
                          class="admin-console-cascade-add__item-btn"
                          :class="{ 'admin-console-cascade-add__disabled-btn': citem.isSelected }"
                        >
                          {{ citem.name }}
                          <a-icon
                            type="edit"
                            style="font-size: 14px; margin-right: 4px;"
                            :style="citem.isSelected ? 'color: #bfbfbf; ' : 'color: #4A8A5D; '"
                            @click.stop="editItem(citem, 1)"
                          ></a-icon>
                          <a-icon
                            type="down"
                            style="font-size: 14px;"
                            :style="citem.isSelected ? 'color: #bfbfbf; ' : 'color: #4A8A5D; '"
                            :rotate="props.isActive ? 180 : 0"
                          ></a-icon>
                        </span>
                        <a-icon
                          v-if="isShowDeleteIconSecond && !citem.isSelected"
                          type="close-circle"
                          class="admin-console-cascade-add__close-icon"
                          theme="filled"
                          @click.stop="chooseDeleteSecond(citem)"
                        />
                        <div
                          v-if="citem.isSelected"
                          style="width: 21px; height: 21px; border-radius: 50%; background: #8C8C8C;"
                          class="admin-console-cascade-add__undo-icon"
                        >
                          <a-icon
                            type="undo"
                            style="color: white; font-size: 16px; position: absolute;top: 2px; right: 2px;"
                            @click.stop="unDoChooseSecond(citem)"
                          />
                        </div>
                      </span>
                    </template>
                    <div class="admin-console-cascade-add__third-div">
                      <!-- <img
                        src="@/assets/img/icon/admin_console_add_third_arrow.svg"
                        style="position: relative; top: -16px; left: 24px;"
                      /> -->
                      <ConsoleCascadeHeader
                        :is-show-edit-btn="
                          !(selectDeleteDataThird && selectDeleteDataThird.size > 0)
                        "
                        :item="citem"
                        style="padding-left: 26px;"
                        @addNewItem="addNewThirdItem"
                        @clickDeleteAll="removeThirdItem"
                        @saveDelete="showDeleteThirdConfirm"
                        @addMultiple="addMultipleThird"
                        @editAll="showEditThirdAllDialog(citem)"
                      />
                      <div>
                        <a-spin v-if="isLoading && loadingItem.privateId === citem.privateId" />
                        <div v-else style="padding-left: 54px;">
                          <div
                            v-for="(ccitem, ccindex) in citem.microCategories"
                            :key="`${title[2]}_${citem.name}_${ccindex}`"
                            style="margin-right: 14px; margin-bottom: 14px; display: inline-block;"
                          >
                            <div
                              v-if="ccitem && ccitem.isEdit"
                              class="admin-console-cascade-add__edit-input"
                              contentEditable="true"
                              @input="inputChange"
                              @blur="showConfirm"
                              @keyup.enter="showConfirm"
                            >
                              {{ ccitem.name }}
                            </div>
                            <span
                              v-else
                              style="margin-right: 12px; margin-top: 4px; margin-bottom: 10px; display: inline-block;"
                            >
                              <span
                                class="admin-console-cascade-add__item-btn"
                                :class="{
                                  'admin-console-cascade-add__disabled-btn': ccitem.isSelected
                                }"
                                @click.stop="editItem(ccitem, 2)"
                              >
                                {{ ccitem.name }}
                                <a-icon
                                  type="edit"
                                  style="font-size: 14px; cursor: pointer;"
                                  :style="
                                    ccitem.isSelected ? 'color: #bfbfbf; ' : 'color: #4A8A5D; '
                                  "
                                  @click.stop="editItem(ccitem, 2)"
                                ></a-icon>
                              </span>
                              <a-icon
                                v-if="isShowDeleteIconThird && !ccitem.isSelected"
                                type="close-circle"
                                class="admin-console-cascade-add__close-icon"
                                theme="filled"
                                @click.stop="chooseDeleteThird(ccitem)"
                              />
                              <div
                                v-if="ccitem.isSelected"
                                style="width: 21px; height: 21px; border-radius: 50%; background: #8C8C8C;"
                                class="admin-console-cascade-add__undo-icon"
                              >
                                <a-icon
                                  type="undo"
                                  style="color: white; font-size: 16px; position: absolute;top: 2px; right: 2px;"
                                  @click.stop="unDoChooseThird(ccitem)"
                                />
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </a-row>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <ConfirmDialog
      ref="confirmAdd"
      @doConfirm="handleInputConfirm"
      @cancelDialog="cancelAddConfirm"
    />
    <ConfirmDialog ref="confirmEdit" @doConfirm="handleEidtItem" @cancelDialog="cancelEditItem" />
    <ConfirmDialog ref="confirmDelete" @doConfirm="saveDelete" @cancelDialog="cancelDeleteFirst" />
    <ConfirmDialog
      ref="confirmDeleteSecond"
      @doConfirm="saveDeleteSecond"
      @cancelDialog="cancelDeleteSecond"
    />
    <ConfirmDialog
      ref="confirmDeleteThird"
      @doConfirm="saveDeleteThird"
      @cancelDialog="cancelDeleteThird"
    />
    <edit-all-dialog ref="editAllDialog" @save="doEditAll" @cancel="cancelEditAll" />
  </div>
</template>

<script>
import ConsoleAddHeader from "@/components/admin/ConsoleAddHeader"
import ConfirmDialog from "@/components/admin/ConfirmDialog"
import EditReferenceDialog from "@/components/admin/EditReferenceDialog"
import ConsoleCascadeHeader from "@/components/admin/ConsoleCascadeHeader"
import { checkHasSameNameData } from "@/utils/validate"
export default {
  components: {
    "add-header": ConsoleAddHeader,
    ConfirmDialog,
    ConsoleCascadeHeader,
    "edit-all-dialog": EditReferenceDialog
  },
  props: {
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      title: "Category",
      newValue: "",
      showNew: false,
      datas: [],
      isShowDeleteIcon: false,
      selectDeleteData: new Set(),
      editedItem: undefined,
      editedValue: "",
      isEditing: false,
      showNewSecond: false,
      isShowDeleteIconSecond: false,
      selectDeleteDataSecond: new Set(),
      showNewThird: false,
      isShowDeleteIconThird: false,
      selectDeleteDataThird: new Set(),
      level: -1,
      editedItemId: undefined,
      isLoading: false,
      loadingItem: undefined,
      secondActiveKey: [],
      thirdActiveKey: []
    }
  },
  computed: {
    isModifySubSuccess() {
      let data = this.$store.getters["reference/updateSubSuccess"]
      return data
    },
    isModifyMicrSuccess() {
      return this.$store.getters["reference/updateMicroSuccess"]
    },
    isModifySuccess() {
      return this.$store.getters["reference/isModifySuccess"]
    }
  },
  watch: {
    items() {
      this.datas = this.items
      if (this.datas && this.datas.length > 0) {
        this.datas.forEach(data => {
          data.isSelected = false
          data.isEdit = false
        })
      }
    },
    secondActiveKey(key) {
      if (key && key.length > 0) {
        if (this.thirdActiveKey && this.thirdActiveKey.length > 0) {
          this.title = "Category / Subcategory / microcategory"
        } else {
          this.title = "Category / Subcategory"
        }
      } else {
        this.title = "Category"
      }
    },
    thirdActiveKey(key) {
      if (key && key.length > 0) {
        this.title = "Category / Subcategory / microcategory"
      } else {
        this.title = "Category / Subcategory"
      }
    },
    isModifySubSuccess(value) {
      if (value.isSuccess) {
        this.clearSelected(this.selectDeleteDataSecond)
        this.selectDeleteDataSecond.clear()
        this.isShowDeleteIconSecond = false
        this.doModifySubSuccess(value)
      }
    },
    isModifyMicrSuccess(value) {
      if (value.isSuccess) {
        this.clearSelected(this.selectDeleteDataThird)
        this.selectDeleteDataThird.clear()
        this.isShowDeleteIconThird = false
        this.doModifyMicroSuccess(value)
      }
    },
    isModifySuccess(value) {
      if (value) {
        this.clearSelected(this.selectDeleteData)
        this.selectDeleteData.clear()
        this.isShowDeleteIcon = false
      }
    }
  },
  created() {
    this.datas = this.items
  },
  methods: {
    //all this operation is for all the items
    removeItem() {
      this.isShowDeleteIcon = true
      this.selectDeleteData.clear()
    },
    removeSecondItem() {
      this.isShowDeleteIconSecond = true
      this.selectDeleteDataSecond.clear()
    },
    removeThirdItem() {
      this.isShowDeleteIconThird = true
      this.selectDeleteDataThird.clear()
    },
    showEditAllDialog() {
      this.level = 0
      this.$refs["editAllDialog"].openDialog(this.datas)
      this.cancelDelete()
    },
    doEditAll(editData) {
      if (this.level === 0) {
        this.$emit("editAll", editData)
      } else if (this.level === 1) {
        this.$emit("editSecondAll", editData)
      } else if (this.level === 2) {
        this.$emit("editThirdAll", editData)
      }
      this.level = -1
    },
    cancelEditAll() {
      this.level = -1
    },
    showEditSecondAllDialog(data) {
      this.level = 1
      this.$refs["editAllDialog"].openDialog(data.subcategories)
      this.cancelDelete()
    },
    showEditThirdAllDialog(data) {
      this.level = 2
      this.$refs["editAllDialog"].openDialog(data.microCategories)
      this.cancelDelete()
    },
    addMultiple(newValues) {
      this.$emit("addMultiple", newValues)
    },
    createNewItem() {
      this.showNew = true
    },
    cancelAddConfirm() {
      this.showNew = false
      this.showNewSecond = false
      this.showNewThird = false
    },
    editItem(item, type) {
      if (!item.isSelected) {
        item.isEdit = true
        this.editedItem = item
        this.level = type
        this.editedItemId = item.id
        this.isEditing = false
        this.$forceUpdate()
      }
    },
    chooseDelete(item) {
      this.selectDeleteData.add(item)
      item.isSelected = true
      item.isActive = false
      this.$forceUpdate()
    },
    chooseDeleteSecond(item) {
      this.selectDeleteDataSecond.add(item)
      item.isSelected = true
      item.isActive = false
      this.$forceUpdate()
    },
    chooseDeleteThird(item) {
      this.selectDeleteDataThird.add(item)
      item.isSelected = true
      item.isActive = false
      this.$forceUpdate()
    },
    unDoChoose(item) {
      this.selectDeleteData.delete(item)
      item.isSelected = false
      item.isActive = true
      this.$forceUpdate()
    },
    unDoChooseSecond(item) {
      this.selectDeleteDataSecond.delete(item)
      item.isSelected = false
      item.isActive = true
      this.$forceUpdate()
    },
    unDoChooseThird(item) {
      this.selectDeleteDataThird.delete(item)
      item.isSelected = false
      item.isActive = true
      this.$forceUpdate()
    },
    saveDelete() {
      this.$emit("removeItem", this.selectDeleteData)
    },
    cancelDeleteSecond() {
      this.isShowDeleteIconSecond = false
      this.clearSelected(this.selectDeleteDataSecond)
      this.$forceUpdate()
    },
    cancelDeleteFirst() {
      this.isShowDeleteIcon = false
      this.clearSelected(this.selectDeleteData)
      this.$forceUpdate()
    },
    cancelDeleteThird() {
      this.isShowDeleteIconThird = false
      this.clearSelected(this.selectDeleteDataThird)
      this.$forceUpdate()
    },
    clearSelected(data) {
      if (data && data.size > 0) {
        for (let item of data) {
          item.isSelected = false
        }
        data.clear()
      }
    },
    cancelDelete() {
      this.isShowDeleteIconThird = false
      this.isShowDeleteIconSecond = false
      this.isShowDeleteIcon = false
      this.clearSelected(this.selectDeleteDataSecond)
      this.clearSelected(this.selectDeleteData)
      this.clearSelected(this.selectDeleteDataThird)
      this.selectDeleteDataThird.clear()
      this.selectDeleteData.clear()
      this.selectDeleteDataSecond.clear()
      this.$forceUpdate()
    },
    saveDeleteSecond() {
      this.$emit("removeSecondItem", this.selectDeleteDataSecond)
    },
    addMultipleSecond(newValues, item) {
      newValues.forEach(data => {
        data.productCategoryPrivateId = item.privateId
        data["id"] = data.name
        delete data.isDiff
      })
      this.$emit("addMultipleSecond", newValues)
    },
    saveDeleteThird() {
      this.$emit("removeThirdItem", this.selectDeleteDataThird)
    },
    addMultipleThird(newValues, item) {
      newValues.forEach(data => {
        data.productSubcategoryPrivateId = item.privateId
        data["id"] = data.name
      })
      this.$emit("addMultipleThird", newValues)
    },
    handleInputConfirm() {
      if (this.showNew) {
        this.$emit("addNewItem", { name: this.newValue, isActive: true })
        this.showNew = false
        this.newValue = undefined
      }
    },
    addNewSecond(value, item) {
      this.$emit("addNewSecondItem", {
        name: value,
        isActive: true,
        productCategoryPrivateId: item.privateId
      })
    },
    addNewThirdItem(value, item) {
      this.$emit("addNewThirdItem", {
        name: value,
        isActive: true,
        productSubcategoryPrivateId: item.privateId
      })
    },
    handleEidtItem() {
      if (checkHasSameNameData(this.editedValue, this.datas)) {
        this.$message.error("Has same name, can't save the data !")
      } else {
        this.editedItem.name = this.editedValue
        this.editedValue = ""
        if (this.level === 0) {
          this.$emit("editItem", this.editedItem)
        } else if (this.level === 1) {
          this.$emit("editSecondItem", this.editedItem)
        } else if (this.level === 2) {
          this.$emit("editThirdItem", this.editedItem)
        }
      }
      this.cancelEditItem()
    },
    cancelEditItem() {
      this.editedItem.isEdit = false
      this.editedItem = undefined
      this.level = -1
      this.isEditing = false
      this.$forceUpdate()
    },
    inputChange(event) {
      let type = event.inputType
      let value = event.target.innerText
      if (type !== "insertParagraph") {
        if (
          value !== undefined &&
          value.replace(/^\s*|\s*$/g, "").length > 0 &&
          value.indexOf("\n") < 0
        ) {
          this.editedValue = value.replace(/^\s*|\s*$/g, "")
        }
      }
    },
    showConfirm(event) {
      let value = event.target.outerText
      if (value === undefined || value.replace(/^\s*|\s*$/g, "").length === 0) {
        this.$message.info("Can't Set empty value!")
        this.editedItem.isEdit = false
        this.level = -1
        this.editedItemId = undefined
        this.$forceUpdate()
      } else {
        if (!this.isEditing) {
          this.isEditing = true
          this.$refs["confirmEdit"].openDialogMsg("Are you sure you want to save changes?")
        }
      }
    },
    showAddConfirm() {
      this.$refs["confirmAdd"].openDialogMsg("Are you sure you want to save changes?")
    },
    showDeleteConfirm(data) {
      this.selectDeleteData.clear()
      if (data) {
        data.forEach(item => this.selectDeleteData.add(item))
      }
      this.$refs["confirmDelete"].openDialogMsg(`Are you sure you want to delete these Category?`)
    },
    showDeleteSecondConfirm(data) {
      this.selectDeleteDataSecond.clear()
      if (data) {
        data.forEach(item => this.selectDeleteDataSecond.add(item))
      }
      this.$refs["confirmDeleteSecond"].openDialogMsg(
        `Are you sure you want to delete these Subcategories?`
      )
    },
    showDeleteThirdConfirm(data) {
      this.selectDeleteDataThird.clear()
      if (data) {
        data.forEach(item => this.selectDeleteDataThird.add(item))
      }
      this.$refs["confirmDeleteThird"].openDialogMsg(
        `Are you sure you want to delete these MicroCategories?`
      )
    },
    doModifySubSuccess(data) {
      let item = this.datas.filter(category => category.privateId === data.privateId)
      if (item && item.length > 0) {
        this.getSubcategories(item[0])
      }
    },
    doModifyMicroSuccess(data) {
      let item = undefined
      for (let i = 0; i < this.datas.length; i++) {
        let sub = this.datas[i].subcategories
        if (sub) {
          for (let j = 0; j < sub.length; j++) {
            if (sub[j].privateId === data.privateId) {
              item = sub[j]
              break
            }
          }
        }
      }
      if (item) {
        this.getMicrocategories(item)
      }
    },
    getSubcategories(item) {
      this.isLoading = true
      this.loadingItem = item
      this.$store
        .dispatch("reference/getSubCategory", item.privateId)
        .then(response => {
          item["subcategories"] = response.productSubcategorys
          if (item.subcategories && item.subcategories.length > 0) {
            item.subcategories.forEach(data => {
              data.isSelected = false
              data.isEdit = false
            })
          }
        })
        .finally(() => {
          this.isLoading = false
          this.loadingItem = undefined
        })
    },
    getMicrocategories(item) {
      this.isLoading = true
      this.loadingItem = item
      this.$store
        .dispatch("reference/getMicroCategory", item.privateId)
        .then(response => {
          item["microCategories"] = response.productMicroCategorys
          if (item.microCategories && item.microCategories.length > 0) {
            item.microCategories.forEach(micro => {
              micro.isSelected = false
              micro.isEdit = false
            })
          }
        })
        .finally(() => {
          this.isLoading = false
          this.loadingItem = undefined
        })
    }
  }
}
</script>

<style lang="scss">
.admin-console-cascade-add {
  &__btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    background: #e2eeec !important;
    color: #4a8a5d !important;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
  }
  &__item-btn {
    background: #fff;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #4a8a5d;
    display: inline-block;
  }
  &__disabled-btn {
    background: #f5f5f5 !important;
    border: 1px solid #bfbfbf !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    color: #bfbfbf !important;
  }
  &__second-item-btn {
    background: #fff1f0 !important;
    border: 1px solid #ffa39e !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 6px 8px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #f5222d;
    display: inline-block;
    margin-bottom: 8px;
  }
  &__third-item-btn {
    background: #fff7e6 !important;
    border: 1px solid #ffd591 !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 6px 8px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #fa8c16 !important;
    display: inline-block;
    margin-right: 14px;
    margin-bottom: 14px;
  }
  &__divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 50px;
  }
  &__second-div {
    background: #fff;
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border: 1px solid #4a8a5d;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: 12px;
    padding-left: 26px;
    // border-radius: 2px;
    padding-bottom: 8px;
  }
  &__third-div {
    background: #e2eeec;
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    // border-radius: 2px;
    padding: 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 10px;
    // margin-top: 11px;
  }
  &__add-new-input {
    background: #ffffff;
    border: 1px solid #4a8a5d !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 6px 8px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 2px;
    width: 100px !important;
    height: 32px;
  }
  &__edit-input {
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #bfbfbf;
    border: 1px solid #ff6132;
    box-sizing: border-box;
    border-radius: 2px;
    display: inline-block;
  }
  &__close-icon {
    color: #ff4d4f !important;
    float: right;
    margin-top: -10px;
    margin-left: -10px;
    font-size: 18px;
    z-index: 199;
    position: relative;
  }
  &__undo-icon {
    float: right;
    margin-top: -10px;
    margin-left: -10px;
    font-size: 18px;
    z-index: 199;
    text-align: center;
    position: relative;
  }
}
.admin-console-cascade-add__collapse .ant-collapse-borderless {
  background-color: transparent;
}
.admin-console-cascade-add__collapse .ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.admin-console-cascade-add__collapse
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 12px;
}
.admin-console-cascade-add__collapse
  .ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding-left: 30px;
}
.admin-console-cascade-add__collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
}
.admin-console-cascade-add__item-btn[disabled] {
  background: #f5f5f5 !important;
  border: 1px solid #bfbfbf !important;
  box-sizing: border-box;
  border-radius: 2px !important;
  color: #bfbfbf !important;
}
</style>
