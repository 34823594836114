<template>
  <div class="landing-terms-logistics__main">
    <p class="logistic-style">Logistics by Landing</p>
    <span v-if="brand.logisticTerms && brand.logisticTerms.length >= 2" class="activated-button">
      <!--    <span v-if="!checkStandard" class="activated-button">-->
      <a-icon type="check" />
      ACTIVATED
    </span>
    <div class="landing-terms-content">
      <div class="check-standard-style">
        <div v-if="checkStandard">
          <span class="title-second-style">Standard</span>
          <a-checkbox
            v-if="logisticsStandard && !logisticsStandard.accepted"
            v-model="logisticsStandard.accepted"
            :style="{ marginLeft: '180px' }"
            @change="changeStandard"
            >Accept</a-checkbox
          >
          <div v-if="logisticsStandard">
            <p class="table-terms-standard">
              <span>Fulfillment</span>
              <span>
                {{ logisticsStandard.fulfillmentValue }}%
                <a-icon
                  v-if="logisticsStandard.accepted"
                  type="check"
                  :style="{ color: '#4a8a5d', marginLeft: '10px' }"
                />
              </span>
            </p>
            <p class="table-terms-standard">
              <span>Shipping</span>
              <span>
                {{ logisticsStandard.shippingValue }}%
                <a-icon
                  v-if="logisticsStandard.accepted"
                  type="check"
                  :style="{ color: '#4a8a5d', marginLeft: '10px' }"
                />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <span v-if="logisticsList && logisticsList.length !== 0" class="title-second-style"
          >Other</span
        >
        <div v-if="logisticsList && logisticsList.length !== 0">
          <!--          <p class="table-terms-standard">-->
          <div
            v-for="value in logisticsList"
            :key="value.privateId"
            class="admin-other-terms-content"
          >
            <div class="admin-other-name">
              <p class="name-left">
                <span class="name-left-title">{{ value.name }}</span>
                <a-checkbox
                  v-if="!value.accepted"
                  v-model="value.accepted"
                  @change="changeRetailerValue(value)"
                >
                  Accept
                  <a-tooltip placement="right">
                    <template slot="title">
                      <span>You Accept terms to begin relationship with Retailer. </span>
                    </template>
                    <a-icon type="info-circle" :style="{ fontSize: '12px', color: '#bfbfbf' }" />
                  </a-tooltip>
                </a-checkbox>
              </p>
              <p class="name-right">
                <a-icon :type="value.isOpenInfo ? 'up' : 'down'" @click="modifyIcon(value)" />
              </p>
            </div>
            <div v-if="value.isOpenInfo" class="admin-other-number">
              <div class="other-number-item">
                <p class="number-name">Fulfillment</p>
                <p>
                  {{ value.fulfillmentValue }}%
                  <a-icon
                    v-if="value.accepted"
                    type="check"
                    :style="{ color: '#4a8a5d', marginLeft: '10px' }"
                  />
                </p>
              </div>
              <div class="other-number-item">
                <p class="number-name">Shipping</p>
                <p>
                  {{ value.shippingValue }}%
                  <a-icon
                    v-if="value.accepted"
                    type="check"
                    :style="{ color: '#4a8a5d', marginLeft: '10px' }"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="logistic-active">-->
    <!--      <a-modal-->
    <!--          :mask-closable="false"-->
    <!--          :centered="true"-->
    <!--          width="423px"-->
    <!--          :visible="logisticModal"-->
    <!--          :footer="null"-->
    <!--          @cancel="handleCancel"-->
    <!--      >-->
    <!--        <p slot="title" class="logistic-modal-title">Inventory Consignment Agreement</p>-->
    <!--        <p class="logistic-text-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>-->
    <!--        <div class="logistic-modal-checkInfo">-->
    <!--          <a-checkbox v-model="checkValue"/>-->
    <!--          <p>I acknowledge that I have read, and do hereby accept the terms and conditions contained in this Inventory Consignment Agreement.</p>-->
    <!--        </div>-->
    <!--        <p class="logistic-modal-button">-->
    <!--          <span class="decline-text" @click="handleCancel">Decline</span>-->
    <!--          <span class="accept-text" @click="handleAccept">Accept</span>-->
    <!--        </p>-->
    <!--      </a-modal>-->
    <!--    </div>-->
  </div>
</template>

<script>
import OrderClient from "@/api/order-client"

export default {
  name: "LandingTermsLogistics",
  data() {
    return {
      logisticModal: false,
      checkValue: null,
      isActive: false,
      logisticsStandard: null,
      logisticsList: []
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    isLogisticsActive() {
      return this.isActive || this.brand.termsPending.usesLandingLogisticAfter
    },
    checkStandard() {
      const list = this.brand.logisticTerms.filter(
        item => item.brandId === "*" && item.standard && item.retailerId === "*"
      )
      const list2 = this.brand.logisticTerms.filter(
        item => item.brandId !== "*" && item.standard && item.retailerId === "*"
      )
      const isLogisticStandard = list2 && list2.length !== 0 ? list2[0].enabled : false
      return (list2 && list2.length !== 0 && isLogisticStandard) || !(list && list.length === 1)
    }
  },
  async created() {
    await this.$store.dispatch("brand/getBrand", this.brandId)
    this.formatParams()
  },
  methods: {
    formatParams() {
      const standardList = this.brand.logisticTerms.filter(
        item => item.brandId.length > 1 && item.standard && item.retailerId === "*"
      )
      if (standardList && standardList.length === 0) {
        this.logisticsStandard = this.brand.logisticTerms.filter(
          item => item.brandId === "*" && item.standard && item.retailerId === "*"
        )[0]
      } else {
        this.logisticsStandard = standardList[0]
      }
      this.logisticsList = []
      this.brand.logisticTerms
        .filter(value => !value.standard && value.enabled)
        .forEach(async item => {
          const obj = await this.getRetailer(item.retailerId)
          item.name = obj.name
          item.isOpenInfo = false
          this.logisticsList.push(item)
        })
    },
    modifyIcon(obj) {
      this.logisticsList = this.logisticsList.map(item => {
        if (item.privateId === obj.privateId) {
          item.isOpenInfo = !item.isOpenInfo
        }
        return item
      })
    },
    openLogisticModal() {
      if (this.isLogisticsActive) {
        return
      }
      this.logisticModal = true
    },
    handleCancel() {
      this.logisticModal = false
    },
    async handleAccept() {
      if (this.checkValue) {
        const params = {
          ...this.brand.termsPending,
          status: {
            id: "pending"
          },
          usesLandingLogisticAfter: true
        }
        // await OrderClint.brandTermsPending(this.brand.id)
        await this.updatePendingTermStatus(params)
        this.$notification.open({
          message: "Thank you!",
          description:
            "Logistics by Landing is now active. Landing will contact you via email on next steps. "
        })
        this.logisticModal = false
        this.isActive = true
      }
    },
    async updatePendingTermStatus(params) {
      try {
        await this.$store.dispatch("brand/updatePendingTerms", params)
      } catch (error) {
        console.error(error)
      }
    },
    async changeStandard() {
      const params = [
        {
          ...this.logisticsStandard
          // accepted: this.logisticsStandard.enable,
        }
      ]
      // const list = this.brand.logisticTerms.filter(item => item.privateId !== data[0].privateId)
      // const params = list.concat(data)
      const action = "update"
      await OrderClient.updateLogisticTerms(this.brandId, action, params)
      await this.$store.dispatch("brand/getBrand", this.brandId)
      this.formatParams()
    },
    async changeRetailerValue(value) {
      const params = [
        {
          ...value
        }
      ]
      // const list = this.brand.logisticTerms.filter(item => item.privateId !== data[0].privateId)
      // const params = list.concat(data)
      const action = "update"
      await OrderClient.updateLogisticTerms(this.brandId, action, params)
      await this.$store.dispatch("brand/getBrand", this.brandId)
      this.formatParams()
    },
    async getRetailer(retailerId) {
      let info
      try {
        info = await this.$store.dispatch("retailer/fetchRetailer", retailerId)
      } catch (e) {
        console.log(e)
      }
      return info
    }
  }
}
</script>

<style lang="scss">
.landing-terms-logistics__main {
  .logistic-style {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .active-button {
    float: right;
    margin-top: -33px;
    padding: 5px 16px;
    border: 1px solid #4a8a5d;
    background-color: #e2eeec;
    color: #4a8a5d;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .active-button:hover {
    background-color: #4a8a5d;
    color: white;
  }
  .activated-button {
    float: right;
    margin-top: -62px;
    color: #4a8a5d;
    font-size: 14px;
  }
  .title-second-style {
    font-size: 24px;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .table-terms-standard {
    height: 46px;
    line-height: 46px;
    margin-bottom: 0;
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    span {
      display: inline-block;
      width: 280px;
    }
  }
  .table-terms-standard:nth-child(2) {
    border-top: none;
  }
  .no-border {
    border: none;
    height: 25px;
  }
  .special-span-style {
    display: block;
  }
  .header-collapse-title__name {
    margin-top: 10px;
    margin-bottom: 10px;
    span {
      margin-right: 20px;
    }
  }
  .add-button-style {
    cursor: pointer;
    text-decoration: underline;
    color: #ff6132;
  }
  .check-standard-style {
    margin-bottom: 80px;
    min-height: 40px;
    span {
      //margin-right: 20px;
    }
  }
  .admin-other-terms-content {
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    padding: 12px 10px;
    .admin-other-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name-left {
        .name-left-title {
          margin-right: 207px;
        }
      }
      .name-right {
        span {
          display: inline-block;
          width: 60px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #595959;
          background-color: #f5f5f5;
          border: 1px solid #595959;
          border-radius: 2px;
          cursor: pointer;
          margin-right: 20px;
        }
      }
    }
    .admin-other-number {
      padding: 0 20px;
      .other-number-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 13px;
        .number-name {
          width: 280px;
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .admin-other-terms-content:nth-child(n + 1) {
    border-top: none;
  }
  .admin-other-terms-content:nth-child(1) {
    border-top: 1px solid #595959;
  }
}
.logistic-modal-title {
  text-align: center;
  margin-bottom: 0;
}
.logistic-text-content {
  width: 325px;
  font-size: 14px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  //text-align: center;
  margin-left: 30px;
}
.logistic-modal-checkInfo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  color: #000000;
  margin-top: 70px;
  p {
    width: 288px;
    margin-left: 14px;
  }
}
.logistic-modal-button {
  text-align: center;
  margin-top: 20px;
  span {
    padding: 5px 16px;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .decline-text {
    border: 1px solid #d9d9d9;
    color: #595959;
  }
  .accept-text {
    color: white;
    border: 1px solid #4a8a5d;
    background-color: #4a8a5d;
    margin-left: 8px;
  }
}
.ant-checkbox {
  //margin-left: 185px !important;
}
.ant-checkbox-wrapper {
  font-weight: 400 !important;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
</style>
