import axios from "@/api/axios"

export default {
  getMarginSetRetailers(params = {}) {
    return axios.get("/retailers", { params: { _limit: 10000, ...params } })
  },
  getRetailerMargins(retailerId) {
    return axios.get(`/retailer/${retailerId}/margins`)
  },
  updateRetailerMargins(margins, retailerId) {
    return axios.put(`/retailers/${retailerId}/margins`, JSON.stringify(margins))
  },
  removeRetailerMargins(params, retailerId) {
    return axios.post(`/retailers/${retailerId}/margins`, JSON.stringify(params))
  }
}
