import httpclient from "@/api/http-client";
import { FragranceNote } from "../models/Fragrance";

export default {
  async getFragrances() {
    return await httpclient
      .get(`/api/Fragrances`)
      .then((res) => res.data.map(d => new FragranceNote(d)));
  },
  async getProductFragrances(id) {
    return await httpclient
    .get(`/api/Fragrances/by-product/${id}`)
    .then((res) => res.data.map(d => new FragranceNote(d)));
  },
  async updateProductFragrances(productId, notes) {
    return await httpclient
    .post(`/api/Fragrances/by-product/${productId}`, notes);
  }
};

