export default class OrderItem {
  constructor(model) {
    this.privateId = 0;
    this.orderPrivateId = 0;
    this.productId = "";
    this.quantityTypePrivateId = 0;
    this.quantity = 0;
    this.adjustedQuantity = null;
    this.recordedMsrpUsd = 0;
    this.recordedMargin = 0;
    this.recordedCommission = 0;
    this.calculatedRetailCostUsd = 0;
    this.calculatedWholesalePriceUsd = 0;
    this.calculatedWholesaleCostUsd = 0;
    this.calculatedCommission = 0;
    this.adjusterByClientTypePrivateId = null;
    this.noteTypePrivateId = null;
    this.createdAt = new Date();
    this.createdBy = null;
    this.updatedAt = null;
    this.updatedBy = null;
    if (model) {
      this.privateId = model.privateId;
      this.orderPrivateId = model.orderPrivateId;
      this.productId = model.productId;
      this.quantityTypePrivateId = model.quantityTypePrivateId;
      this.quantity = model.quantity;
      this.adjustedQuantity = model.adjustedQuantity;
      this.recordedMsrpUsd = model.recordedMsrpUsd;
      this.recordedMargin = model.recordedMargin;
      this.recordedCommission = model.recordedCommission;
      this.calculatedRetailCostUsd = model.calculatedRetailCostUsd;
      this.calculatedWholesalePriceUsd = model.calculatedWholesalePriceUsd;
      this.calculatedWholesaleCostUsd = model.calculatedWholesaleCostUsd;
      this.calculatedCommission = model.calculatedCommission;
      this.adjusterByClientTypePrivateId = model.adjusterByClientTypePrivateId;
      this.noteTypePrivateId = model.noteTypePrivateId;
      this.createdAt = new Date(model.createdAt);
      this.createdBy = model.createdBy;
      this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null;
      this.updatedBy = model.updatedBy;
    }
  }
}
