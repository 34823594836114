<template>
  <div class="edit-video-dialog__main">
    <a-modal
      v-model="visible"
      width="756px"
      :footer="null"
      :mask="false"
      :mask-closable="false"
      :destroy-on-close="true"
    >
      <span slot="closeIcon" @click="cancelDialog">
        <a-icon type="close-circle" style="font-size: 21px" />
      </span>
      <div class="edit-video-content">
        <!--        <p v-if="status" class="upload-status">-->
        <!--          <a-icon type="check-circle" class="status-icon-style" />-->
        <!--          Upload Successful-->
        <!--        </p>-->
        <div class="upload-box-style">
          <span class="video-title-style">Title:</span>
          <a-input
            v-model="videoTitle"
            width="243"
            placeholder="Type here..."
            @blur="changeVideoTitle"
          />
        </div>
        <div class="upload-box-style">
          <span class="video-title-style">Image:</span>
          <div class="upload-button">
            <a-upload
              class="upload-video-info-style"
              name="avatar"
              list-type="picture-card"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              accept="image/*"
              :custom-request="handleChange"
              @change="handleChange"
            >
              <div v-if="imageUrl" class="upload-img-style-box">
                <img :src="imageUrl" alt="avatar" />
              </div>
              <div v-else class="upload-style-box">
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  Thumbnail
                </div>
              </div>
            </a-upload>
            <span @click="closeUploadImg"
              ><a-icon class="close-icon-btn" type="close-circle"
            /></span>
          </div>
        </div>
        <div class="text-content">
          <!--          <p class="upload-message">-->
          <!--            <span class="message-title-style" :style="{ fontWeight: 'bold', color: '#000000' }"-->
          <!--              >Thumbnail Specs</span-->
          <!--            >-->
          <!--            <span class="message-content-style">-->
          <!--              <a-tooltip title="Replace video to replace thumbnail." placement="top">-->
          <!--                <a-icon type="info-circle" />-->
          <!--              </a-tooltip>-->
          <!--            </span>-->
          <!--          </p>-->
          <p v-for="item in dataList" :key="item.label" class="upload-message">
            <span class="message-title-style">{{ item.label }}</span>
            <span class="message-content-style">{{ item.value }}</span>
          </p>
        </div>
        <div class="upload-btn-bottom">
          <!--          <p class="replace-btn" @click="remove">-->
          <!--            Replace Video-->
          <!--          </p>-->
          <p class="save-btn" @click="saveUpload">
            Save
          </p>
        </div>
        <p v-if="errorText" class="error-text-style">
          <a-icon type="close-circle" theme="filled" class="error-text-icon" />
          {{ errorText }}
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import assetClient from "@/api/asset-client"

export default {
  name: "EditVideoDialog",
  props: {
    product: {
      type: Object
    }
  },
  data() {
    return {
      status: true,
      imageUrl: "",
      loading: false,
      visible: false,
      isShowReplaceBtn: false,
      errorText: "",
      videoTitle: "",
      sendInfo: {},
      dataList: [
        { label: "Image Format:", value: "JPEG，PNG，GIF，TIFF" },
        { label: "Minimum Quality", value: "At least 1000 pixels in either width or height" },
        { label: "Maximum Size:", value: "20Mb" },
        { label: "Background Color:", value: "Pure white background" },
        { label: "Image Frame:", value: "The product should fill 85% of the frame" },
        { label: "Color Mode:", value: "RGB，CMYK" }
      ]
    }
  },
  methods: {
    openEditVideo(info, value) {
      this.visible = true
      this.status = true
      if (value === 1) {
        this.imageUrl = info.trainingVideos[0].imgAsset.url
        this.videoTitle = info.trainingVideos[0].title
      }
      if (!info) {
        this.imageUrl = this.product.images[0].asset.url
        this.sendInfo = {
          ...this.product.images[0].asset
        }
      }
      if (info && value === 2) {
        this.imageUrl = info.imgAsset.url
        this.videoTitle = info.title
      }
    },
    closeEditVideo() {
      this.visible = false
    },
    changeVideoTitle() {
      if (!this.videoTitle) {
        return
      }
      // this.$emit("getVideoTitle", this.videoTitle)
    },
    async handleChange(info) {
      const file = info.fileList
      this.percent = file[0].percent > 90 ? 80 : file[0].percent
      const { data } = await assetClient.createAsset(file[0].originFileObj, this.product.brand.id)
      if (data && data.length !== 0) {
        this.isShowReplaceBtn = true
        this.percent = 100
        this.imageUrl = data[0].file.url
        this.sendInfo = {
          id: data[0].file.id,
          url: data[0].file.url,
          contentType: data[0].file.contentType
        }
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
      if (!isJpgOrPng) {
        this.errorText = "Image Format is not supported, please try again."
      }
      const isLt20M = file.size / 1024 / 1024 > 20
      if (isLt20M) {
        this.errorText = "Video file exceeds Maximum Size, please try again."
      }
      return isJpgOrPng && isLt20M
    },
    // remove() {
    //   this.visible = false
    //   this.$emit("replaceVideo")
    // },
    saveUpload() {
      // if (!this.imageUrl || !this.videoTitle) {
      //   this.errorText = "Some fields are unfilled, please check and try again"
      //   return
      // }
      this.visible = false
      this.$emit("handleEditVideoInfo", this.sendInfo, this.videoTitle, this.product.id)
    },
    cancelDialog() {
      this.visible = false
    },
    closeUploadImg() {
      this.imageUrl = ""
      this.sendInfo = {}
    }
  }
}
</script>

<style scoped lang="scss">
.ant-modal-body {
  .edit-video-content {
    text-align: center;
    margin: 44px 166px 24px 193px;
    .video-title-style {
      display: inline-block;
      width: 80px;
      font-size: 16px;
      font-weight: bold;
      color: #262626;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      margin-right: 24px;
    }
    .upload-status {
      width: 182px;
      height: 40px;
      line-height: 37px;
      border: 1px solid #4a8a5d;
      border-radius: 4px;
      color: #4a8a5d;
      background: #e2eeec;
      //margin: 44px 228px 24px 298px;
      margin: 0 0 24px 105px;
      .status-icon-style {
        color: #4a8a5d;
      }
    }
    .text-content {
      margin-left: -29px;
      p {
        width: 100%;
        margin-bottom: 0;
      }
      //margin: 24px 218px 24px 194px;
    }
    .upload-message {
      display: flex;
      //justify-content: space-between;
      font-size: 10px;
      //transition: .625;
      transform: scale(0.83);
      .message-title-style {
        display: inline-block;
        width: 100px;
        margin-right: 24px;
        text-align: right;
      }
      .message-content-style {
        display: inline-block;
        width: 233px;
        text-align: left;
      }
    }
    .upload-btn-bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 18px;
    }
    .replace-btn {
      width: 106px;
      text-align: center;
      height: 22px;
      line-height: 18px;
      color: #4a8a5d;
      border: 1px solid #4a8a5d;
      border-radius: 2px;
      margin-left: 105px;
      font-size: 14px;
    }
    .replace-btn:hover {
      background-color: #4a8a5d;
      color: white;
      cursor: pointer;
    }
    .save-btn {
      width: 47px;
      text-align: center;
      height: 22px;
      line-height: 22px;
      background-color: #4a8a5d;
      color: white;
      border-radius: 2px;
      margin-left: 86px;
      font-size: 14px;
    }
    .save-btn:hover {
      color: #4a8a5d;
      border: 1px solid #4a8a5d;
      background-color: #ffffff;
      cursor: pointer;
    }
    input {
      width: 244px;
    }
  }
  .upload-button {
    width: 243px;
    margin-left: 4px;
    height: 156px;
    span {
      position: relative;
      color: #b21f2d;
      cursor: pointer;
      background-color: white;
      .close-icon-btn {
        position: absolute;
        right: -126px;
        top: -164px;
        font-size: 16px;
      }
    }
  }
  .upload-box-style {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5px;
  }
  .upload-style-box {
    width: 227px;
    height: 130px;
    text-align: center;
    padding-top: 42px;
  }
  .upload-img-style-box {
    width: 227px;
    height: 130px;
    text-align: center;
    img {
      //width: 100%;
      height: 100%;
    }
  }
  .ant-upload-picture-card-wrapper {
    width: auto;
  }
  .ant-upload .ant-upload-select-picture-card {
    width: 244px;
    height: 138px;
    margin: 0;
    padding-top: 42px;
    display: none;
  }
  .error-text-style {
    font-size: 10px;
    width: 100%;
    height: 20px;
    color: #ff6132;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .error-text-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #ff6132;
    margin-right: 24px;
  }
}
</style>
