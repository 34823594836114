export const productFallbackImage = {
  methods: {
    fallbackImage() {
      if (process.env.NODE_ENV === "development") {
        return "https://assets-d.landingintl.com/no-image-available.png"
      }

      if (process.env.NODE_ENV === "qa") {
        return "https://assets-q.landingintl.com/no-image-available.png"
      }

      if (process.env.NODE_ENV === "production") {
        return "https://assets.landingintl.com/no-image-available.png"
      }
    }
  }
}
