export default class LoginMessageDistrict {
  constructor(model) {
    this.privateId = 0;
    this.loginMessagePrivateId = 0;
    this.retailerTerritoryPrivateId = 0;
    this.createdAt = new Date();
    if (model) {
      this.privateId = model.privateId || 0;
      this.loginMessagePrivateId = model.loginMessagePrivateId || 0;
      this.retailerTerritoryPrivateId = model.retailerTerritoryPrivateId || 0;

      this.createdAt = model.createdAt ? new Date(model.createdAt) : null;
      this.createdBy = model.createdBy || null;
      this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null;
      this.updatedBy = model.updatedBy || null;
    }
  }

  toModel() {
    const data = {};
    data.privateId = this.privateId;
    data.loginMessagePrivateId = this.loginMessagePrivateId;
    data.retailerTerritoryPrivateId = this.retailerTerritoryPrivateId;
    data.createdAt = this.toDateString(this.createdAt);
    data.createdBy = this.createdBy;
    data.updatedAt = this.toDateString(this.updatedAt);
    this.updatedBy = this.updatedBy;
    return data;
  }

  toDateString(datetime) {
    return datetime ? datetime.toISOString() : null;
  }
}
