<template>
  <a-row>
    <span class="payment-detail__title"> {{ $t('Components.Brands.Payment.paymentDetails') }} </span>
    <a-row>
      <span class="payment-detail__subtitle" @click="checkPaymentList">
        {{ $t('Components.Brands.Payment.updatePaymentDetails') }}
      </span>
    </a-row>
    <a-row>
      <span class="payment-detail__subtitle" @click="checkHistory">
        {{ $t('Components.Brands.Payment.viewPaymentHistory') }}
      </span>
    </a-row>
  </a-row>
</template>

<script>
import { Row } from "ant-design-vue"

export default {
  components: {
    "a-row": Row
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  methods: {
    checkPaymentList() {
      if (this.isAdmin) {
        return this.$router.push({
          name: "adminBrandUpdatePaymentDetails"
        })
      } else {
        return this.$router.push({
          name: "brandUpdatePaymentDetails"
        })
      }
    },
    checkHistory() {
      if (this.isAdmin) {
        return this.$router.push({
          name: "adminBrandPaymentHistory"
        })
      } else {
        return this.$router.push({
          name: "brandPaymentHistory"
        })
      }
    }
  }
}
</script>

<style lang="sass">
.payment-detail
  &__title
    font-family: Playfair Display
    font-style: normal
    font-weight: bold
    font-size: 48px
    line-height: 38px
    display: flex
    align-items: center
    color: #000000
    margin-bottom: 35px
  &__subtitle
    font-family: SF Pro Display
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
    display: flex
    align-items: center
    text-align: center
    color: #4A8A5D
    margin-bottom: 6px
    cursor: pointer
    width: fit-content
    width: -moz-fit-content
</style>
