<template>
  <div class="register">
    <div class="columns is-centered is-flex">
      <div class="column register__box is-paddingless">
        <form @submit.prevent="signUp">
          <div class="register__header">
            {{ $t("Auth.Register.signUpAs") }}
          </div>
          <div class="control register__account-type">
            <div class="columns">
              <div class="column has-text-centered">
                <label class="register__radio register__radio-container">
                  <input v-model="role" type="radio" name="role" value="brand" />
                  <span class="register__radio-checkmark"></span>
                  <span class="register__radio-label">
                    {{ $t("Auth.Register.brand") }}
                  </span>
                </label>
              </div>
              <div class="column has-text-centered">
                <label class="register__radio register__radio-container">
                  <input v-model="role" type="radio" name="accounType" value="retailer" />
                  <span class="register__radio-checkmark"></span>
                  <span class="register__radio-label">
                    {{ $t("Auth.Register.retailer") }}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="register__header">
              {{ $t("Auth.Register.personalInfo") }}
            </div>
            <div class="columns">
              <div class="column">
                <div class="control">
                  <input
                    v-model.trim="$v.user.givenName.$model"
                    class="input register__input"
                    :class="{ 'is-danger': $v.user.givenName.$error }"
                    :placeholder="$t('Auth.Register.firstName')"
                  />
                  <span v-if="$v.user.givenName.$error" class="help is-danger">
                    {{ $t("Auth.Register.Validations.firstNameRequired") }}
                  </span>
                </div>
              </div>
              <div class="column">
                <div class="control">
                  <input
                    v-model.trim="$v.user.familyName.$model"
                    class="input register__input"
                    :class="{ 'is-danger': $v.user.familyName.$error }"
                    :placeholder="$t('Auth.Register.lastName')"
                  />
                  <span v-if="$v.user.familyName.$error" class="help is-danger">
                    {{ $t("Auth.Register.Validations.lastNameRequired") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                v-model.trim="$v.user.email.$model"
                class="input register__input"
                type="email"
                :class="{ 'is-danger': $v.user.email.$error || duplicatedEmail }"
                :placeholder="$t('Auth.Register.emailAddress')"
              />
              <div
                v-if="$v.user.email.$error && !$v.user.email.required"
                class="error help is-danger"
              >
                {{ $t("Auth.Register.Validations.emailRequired") }}
              </div>
              <div v-if="$v.user.email.$error && !$v.user.email.email" class="error help is-danger">
                {{ $t("Auth.Register.Validations.invalidEmail") }}
              </div>
              <p v-if="duplicatedEmail" class="help is-danger">
                {{ $t("Auth.Register.Validations.duplicateEmail") }}
              </p>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                v-model.trim="$v.user.password.$model"
                class="input register__input"
                type="password"
                :class="{ 'is-danger': $v.user.password.$error }"
                :placeholder="$t('Auth.Register.password')"
              />
              <p
                v-if="$v.user.password.$error && !$v.user.password.required"
                class="help is-danger"
              >
                {{ $t("Auth.Register.Validations.passwordRequired") }}
              </p>
              <p
                v-if="$v.user.password.$error && !$v.user.password.minLength"
                class="help is-danger"
              >
                {{ $t("Auth.Register.Validations.passwordMinLength10") }}
              </p>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                v-model.trim="$v.user.confirmPassword.$model"
                class="input register__input"
                type="password"
                :class="{ 'is-danger': $v.user.confirmPassword.$error }"
                :placeholder="$t('Auth.Register.confirmPassword')"
              />
              <p
                v-if="$v.user.confirmPassword.$error && !$v.user.confirmPassword.required"
                class="help is-danger"
              >
                {{ $t("Auth.Register.Validations.confirmPasswordRequired") }}
              </p>
              <p
                v-if="!$v.user.confirmPassword.sameAsPassword && user.confirmPassword.length"
                class="help is-danger"
              >
                {{ $t("Auth.Register.Validations.passwordsMustMatch") }}
              </p>
            </div>
          </div>
        </form>
        <div v-if="role" class="account-type-info">
          <div class="register__role">{{ role }} {{ $t("Auth.Register.info") }}</div>
          <form>
            <div class="field">
              <div class="control">
                <input
                  v-model="$v.organization.name.$model"
                  class="input register__input"
                  type="text"
                  autocomplete="off"
                  name="organization.name"
                  :class="{ 'is-danger': $v.organization.name.$error || duplicatedName }"
                  :placeholder="role.charAt(0).toUpperCase() + role.substr(1) + ' Name'"
                />
                <p v-if="$v.organization.name.$error" class="help is-danger">
                  {{ $t("Auth.Register.Validations.organizationNameRequired") }}
                </p>
                <p v-show="duplicatedName" class="help is-danger">
                  {{ $t("Auth.Register.Validations.duplicatename") }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="$v.organization.website.$model"
                  class="input register__input"
                  type="text"
                  autocomplete="off"
                  name="organization.website"
                  :class="{ 'is-danger': $v.organization.website.$error }"
                  :placeholder="$t('Auth.Register.website')"
                />
                <p v-if="$v.organization.website.$error" class="help is-danger">
                  {{ $t("Auth.Register.Validations.websiteRequired") }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <textarea
                  v-model.trim="$v.organization.about.$model"
                  class="textarea register__textarea"
                  type="text"
                  autocomplete="off"
                  name="organization.about"
                  :class="{ 'is-danger': $v.organization.about.$error }"
                  :placeholder="$t('Auth.Register.aboutUs')"
                />

                <p v-if="$v.organization.about.$error" class="help is-danger">
                  {{ $t("Auth.Register.Validations.aboutUsRequired") }}
                </p>
              </div>
            </div>
          </form>
        </div>
        <div class="account-headquarters">
          <div class="register__header">
            {{ $t("Auth.Register.headquarters") }}
          </div>
          <div class="field">
            <div class="control">
              <input
                v-model.trim="$v.headquarters.streetAddressLine_1.$model"
                class="input register__input"
                :class="{ 'is-danger': $v.headquarters.streetAddressLine_1.$error }"
                type="text"
                :placeholder="$t('Auth.Register.streetAddress')"
              />
              <p v-if="$v.headquarters.streetAddressLine_1.$error" class="help is-danger">
                {{ $t("Auth.Register.Validations.streetAddressRequired") }}
              </p>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                v-model="headquarters.streetAddressLine_2"
                class="input register__input"
                type="text"
                :placeholder="$t('Auth.Register.aptSuiteBldg')"
              />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                v-model.trim="$v.headquarters.city.$model"
                class="input register__input"
                :class="{ 'is-danger': $v.headquarters.city.$error }"
                type="text"
                :placeholder="$t('Auth.Register.city')"
              />
              <p v-if="$v.headquarters.city.$error" class="help is-danger">
                {{ $t("Auth.Register.Validations.cityRequired") }}
              </p>
            </div>
          </div>
          <div class="columns account-headquarters__columns">
            <div class="column">
              <div class="field">
                <div class="control">
                  <input
                    v-model.trim="$v.headquarters.region.$model"
                    class="input register__input"
                    :class="{ 'is-danger': $v.headquarters.region.$error }"
                    type="text"
                    :placeholder="$t('Auth.Register.stateRegion')"
                  />
                  <p v-if="$v.headquarters.region.$error" class="help is-danger">
                    {{ $t("Auth.Register.Validations.stateRegionRequired") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <div class="control">
                  <input
                    v-model.trim="$v.headquarters.postalCode.$model"
                    class="input register__input"
                    :class="{ 'is-danger': $v.headquarters.postalCode.$error }"
                    type="text"
                    :placeholder="$t('Auth.Register.postalCode')"
                  />
                  <p v-if="$v.headquarters.postalCode.$error" class="help is-danger">
                    {{ $t("Auth.Register.Validations.postalCodeRequired") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div
                class="select is-fullwidth register__select"
                :class="{ 'is-danger': $v.headquarters.country.id.$error }"
                @change="showPlaceholder = false"
              >
                <select v-model="$v.headquarters.country.id.$model">
                  <option v-for="country in countries" :key="country.id" :value="country.id">
                    {{ country.name }}
                  </option>
                </select>
                <div v-show="showPlaceholder" class="register__select-placeholder">
                  {{ $t("Auth.Register.country") }}
                </div>
              </div>
            </div>
            <p v-if="$v.headquarters.country.id.$error" class="help is-danger">
              {{ $t("Auth.Register.Validations.countryRequired") }}
            </p>
            <div class="register__parent-company-checkbox">
              <span class="register__parent-company-checkbox-icon" @click="hasParent = !hasParent">
                <img src="@/assets/img/checkbox.png" />
                <img
                  v-show="hasParent"
                  class="register__parent-company-checkbox-icon--checked"
                  src="@/assets/img/inputs/checked.png"
                />
              </span>
              <span class="register__parent-company-checkbox-text">
                {{ $t("Auth.Register.haveParentCompanyQn") }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="hasParent" class="has-parent-organization">
          <form>
            <div class="field">
              <div class="control">
                <input
                  v-model="parentOrganization.name"
                  class="input register__input"
                  type="text"
                  autocomplete="off"
                  name="parentOrganization.name"
                  :placeholder="$t('Auth.Register.parentName')"
                />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="parentOrganization.website"
                  class="input register__input"
                  type="text"
                  autocomplete="off"
                  name="parentOrganization.website"
                  :placeholder="$t('Auth.Register.websiteUrl')"
                />
              </div>
            </div>
          </form>
        </div>
        <div v-show="role === 'retailer'" class="field">
          <div class="register__header">
            {{ $t("Auth.Register.locations") }}
          </div>
          <div
            v-for="(location, index) in $v.locations.$each.$iter"
            :key="index"
            class="columns is-multiline"
          >
            <div v-show="index > 0" class="column is-12">
              <a class="register__add-location-button" @click="removeLocation(index)">
                {{ $t("Auth.Register.deleteLocation") }}
              </a>
            </div>
            <div class="column is-8">
              <div class="control">
                <div
                  class="select is-fullwidth"
                  :class="{ 'is-danger': location.country.id.$error }"
                >
                  <select v-model="location.country.id.$model">
                    <option v-for="country in countries" :key="country.id" :value="country.id">
                      {{ country.name }}
                    </option>
                  </select>
                  <p v-if="location.country.id.$error" class="help is-danger">
                    {{ $t("Auth.Register.Validations.locationRequired") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="control">
                <input
                  v-model.number.trim="location.count.$model"
                  class="input"
                  type="number"
                  :placeholder="$t('Auth.Register.numberOfLocations')"
                  :class="{ 'is-danger': location.count.$error }"
                />
                <p v-if="location.count.$error && !location.count.required" class="help is-danger">
                  {{ $t("Auth.Register.Validations.numberRequired") }}
                </p>
                <p
                  v-if="location.count.$error && !location.count.positiveNumber"
                  class="help is-danger"
                >
                  {{ $t("Auth.Register.Validations.numberMustPositive") }}
                </p>
              </div>
            </div>
          </div>
          <div class="register__add-location-button" @click="addLocation()">
            {{ $t("Auth.Register.addAnotherLocation") }}
          </div>
        </div>
        <div v-show="role === 'retailer'" class="account-terms">
          <div class="register__header">
            {{ $t("Auth.Register.terms") }}
          </div>
          <div>
            <span class="register__field-header">
              {{ $t("Auth.Register.paymentTerms") }}
            </span>
            <span>
              <RegisterPopup :text="$t('Auth.Register.netTermsQn')" />
            </span>
          </div>
          <div class="field">
            <div class="control">
              <div
                class="select is-fullwidth"
                :class="{ 'is-danger': $v.retailerTerms.paymentTerm.id.$error }"
              >
                <select v-model="$v.retailerTerms.paymentTerm.id.$model">
                  <option
                    v-for="paymentTerm in paymentTerms"
                    :key="paymentTerm.id"
                    :value="paymentTerm.id"
                  >
                    {{ paymentTerm.name }}
                  </option>
                </select>
                <p v-if="$v.retailerTerms.paymentTerm.id.$error" class="help is-danger">
                  {{ $t("Auth.Register.Validations.paymentTermsRequired") }}
                </p>
              </div>
            </div>
          </div>
          <div class="columns account-headquarters__columns">
            <div class="column">
              <div>
                <span class="register__field-header">
                  {{ $t("Auth.Register.marketingCoop") }}
                </span>
                <span>
                  <RegisterPopup :text="$t('Auth.Register.marketingCoopInvoiceQn')" />
                </span>
              </div>
              <div class="field">
                <div class="control has-icons-right retailer__edit-modal--form_control">
                  <input
                    v-model.number.trim="$v.retailerTerms.marketingCoOp.$model"
                    class="input register__input"
                    :class="{ 'is-danger': $v.retailerTerms.marketingCoOp.$error }"
                    type="number"
                  />
                  <span class="icon is-right percent-icon">%</span>
                  <p v-if="$v.retailerTerms.marketingCoOp.$error" class="help is-danger">
                    {{ $t("Auth.Register.Validations.marketingCoopRequired") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="columns account-headquarters__columns">
            <div class="column">
              <div>
                <span class="register__field-header">
                  {{ $t("Auth.Register.damagesBudget") }}
                </span>
                <span>
                  <RegisterPopup :text="$t('Auth.Register.damagesBudgetInvoiceQn')" />
                </span>
              </div>
              <div class="field">
                <div class="control has-icons-right retailer__edit-modal--form_control">
                  <input
                    v-model.number.trim="$v.retailerTerms.damagesBudget.$model"
                    class="input register__input"
                    :class="{ 'is-danger': $v.retailerTerms.damagesBudget.$error }"
                    type="number"
                  />
                  <span class="icon is-right percent-icon">%</span>
                  <p v-if="$v.retailerTerms.damagesBudget.$error" class="help is-danger">
                    {{ $t("Auth.Register.Validations.damagesBudgetRequired") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <span class="register__field-header">
              {{ $t("Auth.Register.compliance") }}
            </span>
            <span>
              <RegisterPopup :text="$t('Auth.Register.complianceReqQn')" />
            </span>
          </div>
          <div class="register__terms-checkbox">
            <span
              class="register__terms-checkbox-icon"
              @click="retailerTerms.ediRequired = !retailerTerms.ediRequired"
            >
              <img src="@/assets/img/checkbox.png" />
              <img
                v-show="retailerTerms.ediRequired"
                class="register__terms-checkbox-icon--checked"
                src="@/assets/img/inputs/checked.png"
              />
            </span>
            <span class="register__terms-checkbox-text">
              {{ $t("Auth.Register.edi") }}
            </span>
          </div>
          <div class="register__terms-checkbox">
            <span
              class="register__terms-checkbox-icon"
              @click="retailerTerms.routingGuideRequired = !retailerTerms.routingGuideRequired"
            >
              <img src="@/assets/img/checkbox.png" />
              <img
                v-show="retailerTerms.routingGuideRequired"
                class="register__terms-checkbox-icon--checked"
                src="@/assets/img/inputs/checked.png"
              />
            </span>
            <span class="register__terms-checkbox-text">
              {{ $t("Auth.Register.vendorGuide") }}
            </span>
          </div>
          <div class="register__terms-checkbox">
            <span
              class="register__terms-checkbox-icon"
              @click="
                retailerTerms.productTicketingRequired = !retailerTerms.productTicketingRequired
              "
            >
              <img src="@/assets/img/checkbox.png" />
              <img
                v-show="retailerTerms.productTicketingRequired"
                class="register__terms-checkbox-icon--checked"
                src="@/assets/img/inputs/checked.png"
              />
            </span>
            <span class="register__terms-checkbox-text">
              {{ $t("Auth.Register.productTicketing") }}
            </span>
          </div>
          <div class="retailer-terms">
            <div>
              <span class="register__field-header">
                {{ $t("Auth.Register.acceptOverseasShipment") }}
              </span>
              <span>
                <RegisterPopup :text="$t('Auth.Register.acceptShipmentQn')" />
              </span>
            </div>
            <div class="control register__radio-field">
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.acceptsOverseasFreight"
                  type="radio"
                  name="seaShipment"
                  value="true"
                />
                <span class="register__radio-checkmark"></span>
                <span class="register__radio-label">
                  {{ $t("Auth.Register.yes") }}
                </span>
              </label>
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.acceptsOverseasFreight"
                  type="radio"
                  name="seaShipment"
                  value="false"
                />
                <span class="register__radio-checkmark" />
                <span class="register__radio-label">
                  {{ $t("Auth.Register.no") }}
                </span>
              </label>
            </div>
            <div>
              <span class="register__field-header">
                {{ $t("Auth.Register.usShipping") }}
              </span>
              <span>
                <RegisterPopup :text="$t('Auth.Register.partyPaysShipmentQn')" />
              </span>
            </div>
            <div class="control register__radio-field">
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.usDomesticShippingObligation"
                  type="radio"
                  name="usRetailerShipping"
                  value="brand"
                />
                <span class="register__radio-checkmark"></span>
                <span class="register__radio-label">
                  {{ $t("Auth.Register.brand") }}
                </span>
              </label>
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.usDomesticShippingObligation"
                  type="radio"
                  name="usRetailerShipping"
                  value="retailer"
                />
                <span class="register__radio-checkmark"></span>
                <span class="register__radio-label">
                  {{ $t("Auth.Register.retailer") }}
                </span>
              </label>
            </div>
            <div>
              <span class="register__field-header">
                {{ $t("Auth.Register.testers") }}
              </span>
              <span>
                <RegisterPopup :text="$t('Auth.Register.partyResponsibleForCostTestersQn')" />
              </span>
            </div>
            <div class="control register__radio-field">
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.testersObligation"
                  type="radio"
                  name="testers"
                  value="brand"
                />
                <span class="register__radio-checkmark"></span>
                <span class="register__radio-label">
                  {{ $t("Auth.Register.brand") }}
                </span>
              </label>
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.testersObligation"
                  type="radio"
                  name="testers"
                  value="retailer"
                />
                <span class="register__radio-checkmark"></span>
                <span class="register__radio-label">
                  {{ $t("Auth.Register.retailer") }}
                </span>
              </label>
            </div>
            <div>
              <span class="register__field-header">
                {{ $t("Auth.Register.fixtures") }}
              </span>
              <span>
                <RegisterPopup :text="$t('Auth.Register.partyResponsibleForCostFixturesQn')" />
              </span>
            </div>
            <div class="control register__radio-field">
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.fixturesObligation"
                  type="radio"
                  name="fixtures"
                  value="brand"
                />
                <span class="register__radio-checkmark"></span>
                <span class="register__radio-label">
                  {{ $t("Auth.Register.brand") }}
                </span>
              </label>
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.fixturesObligation"
                  type="radio"
                  name="fixtures"
                  value="retailer"
                />
                <span class="register__radio-checkmark"></span>
                <span class="register__radio-label">
                  {{ $t("Auth.Register.retailer") }}
                </span>
              </label>
            </div>
            <div>
              <span class="register__field-header">
                {{ $t("Auth.Register.signage") }}
              </span>
              <span>
                <RegisterPopup :text="$t('Auth.Register.partyResponsibleForCostSignage')" />
              </span>
            </div>
            <div class="control register__radio-field">
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.signageObligation"
                  type="radio"
                  name="signage"
                  value="brand"
                />
                <span class="register__radio-checkmark"></span>
                <span class="register__radio-label">
                  {{ $t("Auth.Register.brand") }}
                </span>
              </label>
              <label class="register__radio register__radio-container">
                <input
                  v-model="retailerTerms.signageObligation"
                  type="radio"
                  name="signage"
                  value="retailer"
                />
                <span class="register__radio-checkmark"></span>
                <span class="register__radio-label">
                  {{ $t("Auth.Register.retailer") }}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div v-show="registerInProgress" class="field">
          <div class="control has-text-centered">
            <img src="@/assets/img/spinner.gif" alt="spinner" />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <button
              class="button is-black is-fullwidth register__button"
              type="submit"
              @click.prevent="createUser"
            >
              {{ $t("Auth.Register.register") }}
            </button>
          </div>
        </div>
        <div class="register__border"></div>
        <div class="register__login">
          <p class="register__login-question">
            {{ $t("Auth.Register.alreadyMemberQn") }}
          </p>
          <router-link class="register__login-sign-in" to="/login">
            {{ $t("Auth.Register.signIn") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { required, sameAs, email, minLength } from "vuelidate/lib/validators"
import RegisterPopup from "@/components/registration/RegisterPopup"

export default {
  components: {
    RegisterPopup
  },
  data() {
    return {
      registerInProgress: false,
      role: this.$route.params.role || "brand",
      user: {
        givenName: "",
        familyName: "",
        email: "",
        password: "",
        confirmPassword: ""
      },
      organization: {
        name: "",
        website: "",
        about: ""
      },
      parentOrganization: {
        name: "",
        website: ""
      },
      headquarters: {
        addressee: "Test",
        label: "hq",
        streetAddressLine_1: "",
        streetAddressLine_2: "",
        city: "",
        region: "",
        postalCode: "",
        country: {
          id: ""
        },
        hq: true,
        defaultShipping: false
      },
      locations: [{ country: { id: "" }, count: "" }],
      // brandTerms: {
      //   paymentTerm: { id: "" },
      //   landingCommission: "",
      //   usesLandingFulfillment: true
      // },
      retailerTerms: {
        usDomesticShippingObligation: "brand",
        testersObligation: "brand",
        fixturesObligation: "brand",
        signageObligation: "brand",
        paymentTerm: { id: "" },
        marketingCoOp: "",
        damagesBudget: "",
        ediRequired: false,
        acceptsOverseasFreight: true,
        routingGuideRequired: false,
        productTicketingRequired: false
      },
      formInvalid: false,
      hasParent: false,
      showPlaceholder: true,
      duplicatedEmail: false,
      duplicatedName: false
    }
  },
  validations: {
    user: {
      givenName: {
        required
      },
      familyName: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(10)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    },
    organization: {
      name: {
        required
      },
      website: {
        required
      },
      about: {
        required
      }
    },
    headquarters: {
      streetAddressLine_1: { required },
      city: { required },
      region: { required },
      postalCode: { required },
      country: {
        id: { required }
      }
    },
    locations: {
      $each: {
        country: { id: { required } },
        count: {
          required,
          positiveInteger(count) {
            return /^[1-9]\d*$/gm.test(count)
          }
        }
      }
    },
    // brandTerms: {
    //   paymentTerm: { id: { required } },
    //   landingCommission: {
    //     required: requiredIf(function(brandTerms) {
    //       return brandTerms.paymentTerm.id !== "prepay"
    //     })
    //   }
    // },
    retailerTerms: {
      paymentTerm: { id: { required } },
      marketingCoOp: { required },
      damagesBudget: { required }
    }
  },
  computed: {
    countries() {
      return this.$store.getters["reference/countries"]
    },
    paymentTerms() {
      return this.$store.getters["reference/paymentTerms"]
    }
  },
  async created() {
    await this.setReference()
  },
  methods: {
    ...mapActions("user", ["signUp"]),
    ...mapActions("reference", ["setReference"]),

    async createUser() {
      this.registerInProgress = true
      this.formTouched = !this.$v.$anyDirty
      this.errors = this.$v.$anyErrors

      this.touchForm()
      if (this.$v.user.confirmPassword.$invalid) {
        this.formInvalid = true
        this.registerInProgress = false
        return
      }
      if (this.$v.headquarters.$invalid) {
        this.formInvalid = true
        this.registerInProgress = false
        return
      }

      if (
        (this.role === "retailer" && this.$v.retailerTerms.$invalid) ||
        (this.role === "retailer" && this.$v.locations.$invalid)
      ) {
        this.formInvalid = true
        this.registerInProgress = false
        return
      }

      const { user, organization, role, headquarters, retailerTerms, locations } = this

      const payload = { user }
      payload[role] = organization
      payload[role]["addresses"] = [headquarters]

      if (role === "retailer") {
        payload[role]["terms"] = retailerTerms
        payload[role]["locationCounts"] = locations
      }

      this.duplicatedName = false
      this.duplicatedEmail = false

      try {
        await this.signUp(payload)
        this.$router.push({ name: this.$store.getters["user/postSignUpPath"] })
      } catch (error) {
        this.registerInProgress = false
        if (error.status === 400) {
          const errors = error.message

          this.duplicatedEmail = errors.includes("user_exists")
          this.duplicatedName = errors.includes("client_exists")
        }
      }
    },
    addLocation() {
      this.locations.push({ country: { id: "" }, count: "" })
    },
    removeLocation(index) {
      return this.locations.splice(index, 1)
    },
    touchForm() {
      this.$v.user.$touch()
      this.$v.organization.$touch()
      this.$v.headquarters.$touch()

      if (this.role === "retailer") {
        this.$v.retailerTerms.$touch()
        this.$v.locations.$touch()
      }
    },
    checkIfPrepayBrand() {
      if (this.brandTerms.paymentTerm.id === "prepay") {
        this.brandTerms.landingCommission = null
      }
    }
  }
}
</script>

<style lang="sass" scoped>
input[type=number]
  width: auto !important
.verify
  &__box
    width: 360px
    border: 1px solid #E5E5E5
    border-radius: 2px
    padding: 25px
  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 120px 0px 25px 0px

.register
  display: flex
  justify-content: center
  align-items: center
  min-height: 80vh
  font-family: Proxima
  &__box
    max-width: 410px
    min-height: 637px
    border: 1px solid #E5E5E5
    border-radius: 2px
    margin: 40px 0px 100px 0px
    padding: 30px 45px 45px 45px !important

  &__form
    text-align: center
    margin-bottom: 28px

  &__name
    color: #000000
    font-size: 14px
    font-weight: 500
    letter-spacing: 0.25px
    line-height: 24px

  &__input
    height: 40px
    border-radius: 2px
    margin-bottom: 5px

  &__select
    posiition: absolute

  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 30px
    color: #b4b4b4
    width: 0px
    font-size: 14px

  &__role
    text-transform: capitalize
    font-size: 14px
    font-weight: bold
    letter-spacing: 0.25px
    color: #000000
    text-align: center
    padding-top: 30px
    padding-bottom: 25px

  &__button
    height: 46px
    margin: 24px 0px 24px 0px
    border-radius: 2px

  &__add-location-button
    color: #161616
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.16px
    text-decoration: underline
    cursor: pointer
    margin-top: -10px

  &__border
    border-bottom: 1px solid #e5e5e5
    margin: 0px 0px 25px 0px

  &__login
    display: flex
    justify-content: space-between

    &-question
      font-size: 14px
      font-weight: 600
      letter-spacing: 0.25px
      line-height: 21px

    &-sign-in
      font-size: 14px
      font-weight: 600
      letter-spacing: 0.25px
      color: #008489

  &__terms-checkbox
    position: relative

  &__terms-checkbox:last-child
    margin-bottom: 15px

  &__parent-company-checkbox
    position: relative
    margin-top: 20px
    margin-bottom: 20px

  &__parent-company-checkbox-icon, &__terms-checkbox-icon
    position: absolute
    left: 0
    cursor: pointer

  &__parent-company-checkbox-icon--checked, &__terms-checkbox-icon--checked
    position: absolute
    right: 3px
    top: 3px
    width: 18px
    height: 18px

  &__terms-checkbox-text
    margin-left: 25px
    font-size: 14px

  &__parent-company-checkbox-text
    margin-left: 35px

  &__account-type
    padding-bottom: 20px

  .retailer-terms
    margin-top: 20px

  &__header
    font-family: Proxima
    text-align: center !important
    font-size: 14px
    font-weight: bold
    letter-spacing: 0.25px
    padding-bottom: 25px
    padding-top: 15px
    color: #000000

  &__field-header
    font-size: 14px
    color: #000000
    padding-top: 10px
    padding-right: 5px

  &__radio-field
    padding-bottom: 15px

  &__radio + &__radio
    margin-left: 3em !important

  &__radio-container
    position: relative
    padding-left: 25px
    margin-top: 10px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    input
      position: absolute
      opacity: 0
      cursor: pointer

  &__radio-label
    font-size: 14px
    color: #000000

  .checkmark-box
    position: absolute
    top: 3px
    left: 0
    height: 14px
    width: 14px
    background: #FFFFFF
    border-radius: 2px
    border: 1px solid #E5E5E5

  &__radio-checkmark
    position: absolute
    top: 3px
    left: 0
    height: 14px
    width: 14px
    background: #FFFFFF
    border-radius: 50%
    border: 1px solid #E5E5E5

  &__radio-container input:checked ~ &__radio-checkmark, .checkmark-box
    background-color: #FFFFFF

  &__radio-checkmark:after
    content: ""
    position: absolute
    display: none

  .checkmark-box:after
    content: ""
    position: absolute
    display: none

  &__radio-container input:checked ~ &__radio-checkmark:after
    display: block

  &__radio-container input:checked ~ .checkmark-box:after
    display: block

  &__radio-container &__radio-checkmark:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 50%
    background: #000000

  &__radio-container .checkmark-box:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 2px
    background: #000000
    padding-left: 20px

  &__textarea
    min-height: 84px !important

  &__textarea::placeholder
    padding-left: 10px

  &__input::placeholder
    font-weight: 500
    letter-spacing: 0.25px
    color: #B6B6B6
    text-transform: capitalize
    padding-left: 10px

  .percent-icon
    color: #000000 !important
    position: relative!important
    right:35px!important

  .account-headquarters
    &__columns
      margin-bottom: 10px !important
</style>
