<template>
  <div class="message-inbox-operation">
    <a-button class="message-inbox-operation__btn" @click="composeMessage">
      {{ $t("Components.Admin.message.Compose") }}
      <a-icon type="edit" />
    </a-button>
    <a-button class="message-inbox-operation__btn" @click="deleteMessage">
      {{ $t("Components.Admin.message.Delete") }}
      <a-icon type="delete" />
    </a-button>
    <a-button v-if="isAdmin" class="message-inbox-operation__btn" @click="arChieveMessage">
      {{ $t("Components.Admin.message.Archieve") }}
      <!-- <a-icon type="edit" /> -->
      <img src="@/assets/img/archive.svg" style="margin-left:2px;" />
    </a-button>
    <a-button class="message-inbox-operation__btn" @click="searchMessage">
      {{ $t("Components.Admin.message.Search") }}
      <a-icon :type="isShowSearch ? 'left-circle' : 'right-circle'" />
    </a-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShowSearch: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  methods: {
    composeMessage() {
      this.$emit("compose")
    },
    deleteMessage() {
      this.$emit("delete")
    },
    arChieveMessage() {
      this.$emit("archive")
    },
    searchMessage() {
      this.isShowSearch = !this.isShowSearch
      this.$emit("search", this.isShowSearch)
    }
  }
}
</script>
<style lang="scss" scoped>
.message-inbox-operation {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  &__btn {
    background-color: #4a8a5d !important;
    border-radius: 2px !important;
    border: 1px solid #4a8a5d;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
    padding: 0px 9px;
    margin-left: 10px;
  }
}
</style>
<style lang="scss">
.message-inbox-operation .ant-btn:hover,
.message-inbox-operation .ant-btn:focus,
.message-inbox-operation .ant-btn:active {
  color: #fff;
  background: #4a8a5d;
  border-radius: 2px;
  border-color: #4a8a5d;
}
</style>
