<template>
  <div>
    <p class="brand-settings__title ">{{ $t("Brands.BrandSettings.subscriptionPlatform") }}</p>
    <span class="brand-line"></span>
    <div>
      <a-row class="brand-line__main-block brand-settings__first-row">
        <a-col :span="6" class="brand-line__left-block">
          <p class="brand-settings__account-information_text">
            {{ $t("Brands.BrandSettings.planType") }}
          </p>
        </a-col>
        <a-col :span="10" class="brand-line__right-block">
          <p class="brand-settings__account-information_text textfont">{{ planType }}</p>
        </a-col>
        <a-col :span="8"></a-col>
      </a-row>
      <a-row class="brand-line__main-block">
        <a-col :span="6" class="brand-line__left-block">
          <p class="brand-settings__account-information_text">
            {{ $t("Brands.BrandSettings.products") }}
          </p>
        </a-col>
        <a-col :span="10" class="brand-line__right-block">
          <p class="brand-settings__account-information_text textfont">
            {{ productCount }} {{ $t("Brands.BrandSettings.outOf") }} Unlimited
            {{ $t("Brands.BrandSettings.productUploaded") }}
          </p>
        </a-col>
        <a-col :span="8"></a-col>
      </a-row>
      <a-row class="brand-line__main-block">
        <a-col :span="6" class="brand-line__left-block">
          <p class="brand-settings__account-information_text">Transaction Fee</p>
        </a-col>
        <a-col :span="10" class="brand-line__right-block">
          <p class="brand-settings__account-information_text textfont">{{ commissions }}%</p>
        </a-col>
        <a-col :span="8"></a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    planType() {
      if (this.brand && this.brand.subscriptions && this.brand.subscriptions.length === 0) {
        if (
          this.brand.brandTrainingSubscriptions &&
          this.brand.brandTrainingSubscriptions.length > 0
        ) {
          return "Subscriber"
        }
        return ""
      }
      if (
        this.brand.subscriptions[0].status &&
        this.brand.subscriptions[0].status.id === "cancelled" &&
        this.brand.subscriptions[0].type.name === "Trial"
      ) {
        return `${this.brand.subscriptions[0].type.name} (${this.brand.subscriptions[0].status.name})`
      }
      if (
        this.brand.subscriptions[0].status &&
        this.brand.subscriptions[0].status.id === "cancelled" &&
        this.brand.subscriptions[0].type.name === "Subscriber"
      ) {
        return `${this.brand.subscriptions[0].type.name} (${this.brand.subscriptions[0].status.name})`
      }
      if (
        this.brand.subscriptions[0].status &&
        this.brand.subscriptions[0].status.id === "expired"
      ) {
        return `${this.brand.subscriptions[0].type.name} (${this.brand.subscriptions[0].status.name})`
      }
      return (
        this.brand &&
        this.brand.subscriptions &&
        this.brand.subscriptions[0] &&
        this.brand.subscriptions[0].type.name
      )
    },
    productCount() {
      return (this.brand && this.brand.productCount && this.brand.productCount.count) || 0
    },
    commissions() {
      let result = -1
      let brandId = this.isAdmin
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"]
      if (this.brand && this.brand.transactionFeeTerms) {
        let commissionOverrides = this.brand.transactionFeeTerms.filter(
          item => item.brandId === brandId && item.standard && item.retailerId === "*"
        )
        if (commissionOverrides.length) {
          result = commissionOverrides[0].transactionFeeValue
        } else {
          let basicCommision = this.brand.transactionFeeTerms.filter(
            item => item.brandId === "*" && item.standard && item.retailerId === "*"
          )
          if (basicCommision.length) {
            result = basicCommision[0].transactionFeeValue
          }
        }
      }
      if (result >= 0) {
        result = +((result / 100) * 100).toFixed(2)
      } else {
        result = 0
      }
      return result
    }
  }
}
</script>
