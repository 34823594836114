export default class ConsoleCascadeContentItem {
  constructor(model) {
    if (model) {
      (this.parent = model.parent), (this.title = model.title)
      this.addTitle = model.addTitle
      this.isEditable = model.isEditable
      //this.getDataFn = model.getDataFn
      this.computed = model.computed
      this.getKeyFn = model.getKeyFn
      this.getDisplayFn = model.getDisplayFn
      this.setItemFn = model.setItemFn
      this.next = model.next
    }
  }
}
