import retailerAddressClient from "@/api/retailer-address-client.js"

export default {
  namespaced: true,

  state: {
    addresses: [],
    nullAddress: {
      label: "",
      addressee: "",
      streetAddressLine_1: "",
      streetAddressLine_2: "",
      city: "",
      region: "",
      postalCode: "",
      country: {
        id: "us"
      },
      hq: false
    }
  },

  getters: {
    addresses(state) {
      return state.addresses
    },
    defaultAddress(state, getters) {
      const defaultAddress = getters.addresses.find(address => address.defaultShipping)
      return defaultAddress || state.nullAddress
    },
    headquarters(state) {
      const hqAddress = state.addresses.find(address => address.hq)
      return hqAddress || Object.assign({}, state.nullAddress, { hq: true })
    }
  },

  mutations: {
    SET_ADDRESSES(state, addresses) {
      state.addresses = addresses
    }
  },

  actions: {
    async updateAll({ commit }, { retailerId, addresses }) {
      try {
        const { data } = await retailerAddressClient.update(retailerId, addresses)
        commit("SET_ADDRESSES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchAddresses({ commit }, retailerId) {
      try {
        const { data } = await retailerAddressClient.fetch(retailerId)
        commit("SET_ADDRESSES", data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
