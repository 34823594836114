<template>
  <div v-if="retailer" class="retailer-profile-container">
    <a-row class="header-content" :span="24">
      <IdentityCard
        :head-info="retailer"
        :is-brand="false"
        :headquarters="headquarters"
        :tags="tags"
        :role="role"
        @showEditModal="showEditModal"
      />
    </a-row>
    <a-row v-if="role == 'retailer'" class="card-bottom-action" :span="24">
      <a class="link-left" target="_blank" @click="goToWebsite">
        {{ $t("Components.Brands.Profile.BrandProfile.visitButton") }}
      </a>
    </a-row>
    <a-row class="card-content">
      <a-col :span="16">
        <div class="card-left-content">
          <OverView :retailer="retailer" :headquarters="headquarters" />
          <ProfileCategories
            class="retailer-profile__margin-top"
            :categories-for-api="categoriesForApi"
          />
        </div>
      </a-col>
      <a-col :span="8">
        <RetailersProfileRightCard :retailer="retailer" :role="role" />
      </a-col>
    </a-row>
    <div class="retailer-profile__description">
      <div
        v-if="isTermsPending"
        class="pending-changes-approval"
        :class="{ 'admin-view': role == 'admin' }"
      >
        <TermsChangeRequest
          @undoChanges="undoChanges"
          @viewPendingChanges="showChangeRequestModal"
        />
      </div>
    </div>
    <div v-if="isEditModalOpen" class="modal" :class="{ 'is-active': isEditModalOpen }">
      <div class="modal-background"></div>
      <div class="modal-card retailer-profile__edit-modal">
        <header class="modal-card-head">
          <p class="modal-card-title retailer-profile__edit-modal--header">
            {{ $t("Retailers.RetailerProfile.editProfile") }}
          </p>
          <img
            class="retailer-profile__edit-modal--header_close-icon"
            src="@/assets/img/icon/close-icon.png"
            @click="hideEditModal"
          />
        </header>
        <section class="modal-card-body">
          <div class="columns retailer-profile__edit-modal--container">
            <div class="column is-4 retailer-profile__edit-modal--menu">
              <ul class="retailer-profile__edit-modal-sticky-menu">
                <li>
                  <a
                    :class="{ 'is-selected': isMenuItem('overview') }"
                    @click.prevent="toggleMenuItem('overview')"
                  >
                    {{ $t("Retailers.RetailerProfile.overview") }}
                  </a>
                </li>
                <li>
                  <a
                    :class="{ 'is-selected': isMenuItem('terms') }"
                    @click.prevent="toggleMenuItem('terms')"
                  >
                    {{ $t("Retailers.RetailerProfile.terms") }}
                  </a>
                </li>
                <li>
                  <a
                    :class="{ 'is-selected': isMenuItem('categories') }"
                    @click.prevent="toggleMenuItem('categories')"
                  >
                    {{ $t("Retailers.RetailerProfile.categories") }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="column retailer-profile__edit-modal--form">
              <div
                v-show="currentMenuItem === 'overview'"
                class="retailer-profile__edit-modal--form_photos"
              >
                <BannerImageCropper
                  :initial-banner-asset-url="retailer.bannerAsset.url"
                  :client-id="retailer.id"
                  @bannerAssetUpdated="updateBannerAsset"
                  @imageSaving="imageSaving"
                />
                <LogoImageCropper
                  :initial-logo-asset-url="retailer.logoAsset.url"
                  :client-id="retailer.id"
                  @logoAssetUpdated="updateLogoAsset"
                  @imageSaving="imageSaving"
                />
                <div
                  class="field retailer-profile__edit-modal--form_container retailer-profile__edit-modal--form_photos-overview"
                >
                  <label class="retailer-profile__edit-modal--form_label">
                    {{ $t("Retailers.RetailerProfile.introduction") }}
                  </label>
                  <span>
                    <BaseFormPopup :text="$t('Retailers.RetailerProfile.introductionToolTip')" />
                  </span>
                  <div class="control retailer-profile__edit-modal--form_control">
                    <input
                      v-model="$v.retailerEdit.introduction.$model"
                      class="input retailer-profile__edit-modal--form_input"
                      :class="{
                        'is-danger':
                          $v.retailerEdit.introduction.$error || introductionLengthExceeds
                      }"
                      :placeholder="
                        retailerEdit.name +
                          ' ' +
                          $t('Retailers.RetailerProfile.introductionPlaceholder')
                      "
                      @input="introductionLengthExceeds = false"
                      @keyup="charCount()"
                    />
                    <span v-if="!introductionLengthExceeds" class="remaining-characters">
                      {{ remaincharactersText }}
                    </span>
                    <p v-if="$v.retailerEdit.introduction.$error" class="help is-danger">
                      {{ $t("Retailers.RetailerProfile.Validations.introductionIsRequired") }}
                    </p>
                    <p v-if="introductionLengthExceeds" class="help is-danger">
                      {{ $t("Retailers.RetailerProfile.Validations.characterExceeds100") }}
                    </p>
                  </div>
                </div>
                <div class="field retailer-profile__edit-modal--form_container">
                  <label class="retailer-profile__edit-modal--form_label">
                    {{ $t("Retailers.RetailerProfile.aboutUs") }}
                  </label>
                  <span>
                    <BaseFormPopup :text="$t('Retailers.RetailerProfile.aboutUsToolTip')" />
                  </span>
                  <div class="control retailer-profile__edit-modal--form_control">
                    <textarea
                      v-model="$v.retailerEdit.about.$model"
                      class="textarea retailer-profile__edit-modal--form_input retailer-profile__edit-modal--form_textarea"
                      :class="{ 'is-danger': $v.retailerEdit.about.$error }"
                      :placeholder="
                        retailerEdit.name + ' ' + $t('Retailers.RetailerProfile.aboutUsPlaceholder')
                      "
                    />
                    <p v-if="$v.retailerEdit.about.$error" class="help is-danger">
                      {{ $t("Retailers.RetailerProfile.Validations.aboutIsRequired") }}
                    </p>
                  </div>
                </div>
                <InputWithPrefix
                  :initial-value="retailerEdit.website"
                  :is-social-account="false"
                  :placeholder="$t('Retailers.RetailerProfile.domain')"
                  prefix="http://www."
                  prefix-offset="80px"
                  :label="$t('Retailers.RetailerProfile.website')"
                  @input="retailerEdit.website = $event"
                />
                <InputWithPrefix
                  v-for="(socialAccount, index) in socialAccounts"
                  :key="`socialAccount_${index}`"
                  :initial-value="socialAccount.address"
                  :is-social-account="true"
                  :placeholder="$t('Retailers.RetailerProfile.yourUsername')"
                  :prefix="`http://www.${socialAccount.platform.id}.com/`"
                  :prefix-offset="offset(socialAccount.platform.id)"
                  :label="socialAccount.platform.name"
                  @input="socialAccountAddress($event, socialAccount)"
                />
                <div class="field">
                  <div class="control">
                    <label class="brand-profile__edit-modal--form_label">
                      {{ $t("Retailers.RetailerProfile.companySize") }}
                    </label>
                    <div class="select is-fullwidth">
                      <select v-model="retailerEdit.size.id">
                        <option value="">N/A</option>
                        <option
                          v-for="clientSize in clientSizes"
                          :key="`clientSize_${clientSize.id}`"
                          :value="clientSize.id"
                        >
                          {{ clientSize.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="field retailer-profile__edit-modal--form_container">
                  <label class="retailer-profile__edit-modal--form_label">
                    {{ $t("Retailers.RetailerProfile.yearFounded") }}
                  </label>
                  <div class="control retailer-profile__edit-modal--form_control">
                    <div class="select is-fullwidth">
                      <select v-model="retailerEdit.foundingDate">
                        <option v-for="year in years" :key="`foundingDate_${year}`" :value="year">
                          {{ year }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="retailer-profile__edit-modal--form_subtitle">
                  {{ $t("Retailers.RetailerProfile.headQuarters") }}
                </div>
                <div class="hq">
                  <div class="field retailer-profile__edit-modal--form_container">
                    <label class="retailer-profile__edit-modal--form_label">
                      {{ $t("Retailers.RetailerProfile.streetAddress") }}
                    </label>
                    <div class="control retailer-profile__edit-modal--form_control">
                      <input
                        v-model="$v.headQuartersEdit.streetAddressLine_1.$model"
                        class="input retailer-profile__edit-modal--form_input"
                        :class="{ 'is-danger': $v.headQuartersEdit.streetAddressLine_1.$error }"
                      />
                      <p
                        v-if="$v.headQuartersEdit.streetAddressLine_1.$error"
                        class="help is-danger"
                      >
                        {{ $t("Retailers.RetailerProfile.Validations.streetAddressRequired") }}
                      </p>
                    </div>
                  </div>
                  <div class="field retailer-profile__edit-modal--form_container">
                    <label class="retailer-profile__edit-modal--form_label">
                      {{ $t("Retailers.RetailerProfile.aptSuiteBldg") }}
                    </label>
                    <div class="control retailer-profile__edit-modal--form_control">
                      <input
                        v-model="headQuartersEdit.streetAddressLine_2"
                        class="input retailer-profile__edit-modal--form_input"
                      />
                    </div>
                  </div>
                  <div class="columns is-marginless retailer-profile__edit-modal--form_address">
                    <div class="column">
                      <div class="field retailer-profile__edit-modal--form_container">
                        <label class="retailer-profile__edit-modal--form_label">
                          {{ $t("Retailers.RetailerProfile.city") }}
                        </label>
                        <div class="control retailer-profile__edit-modal--form_control">
                          <input
                            v-model="$v.headQuartersEdit.city.$model"
                            class="input retailer-profile__edit-modal--form_input"
                            :class="{ 'is-danger': $v.headQuartersEdit.city.$error }"
                          />
                          <p v-if="$v.headQuartersEdit.city.$error" class="help is-danger">
                            {{ $t("Retailers.RetailerProfile.Validations.cityRequired") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field retailer-profile__edit-modal--form_container">
                        <label class="retailer-profile__edit-modal--form_label">
                          {{ $t("Retailers.RetailerProfile.regionState") }}
                        </label>
                        <div class="control retailer-profile__edit-modal--form_control">
                          <input
                            v-model="$v.headQuartersEdit.region.$model"
                            class="input retailer-profile__edit-modal--form_input"
                            :class="{ 'is-danger': $v.headQuartersEdit.region.$error }"
                          />
                          <p v-if="$v.headQuartersEdit.region.$error" class="help is-danger">
                            {{ $t("Retailers.RetailerProfile.Validations.regionRequired") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field retailer-profile__edit-modal--form_container">
                        <label class="retailer-profile__edit-modal--form_label">
                          {{ $t("Retailers.RetailerProfile.zipCode") }}
                        </label>
                        <div class="control retailer-profile__edit-modal--form_control">
                          <input
                            v-model="$v.headQuartersEdit.postalCode.$model"
                            class="input retailer-profile__edit-modal--form_input"
                            :class="{ 'is-danger': $v.headQuartersEdit.postalCode.$error }"
                          />
                          <p v-if="$v.headQuartersEdit.postalCode.$error" class="help is-danger">
                            {{ $t("Retailers.RetailerProfile.Validations.zipCodeRequired") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field retailer-profile__edit-modal--form_container">
                    <div class="control">
                      <label class="retailer-profile__edit-modal--form_label">
                        {{ $t("Retailers.RetailerProfile.country") }}
                      </label>
                      <div class="select is-fullwidth">
                        <select v-model="headQuartersEdit.country.id">
                          <option
                            v-for="country in countries"
                            :key="`countryId_${country.id}`"
                            :value="country.id"
                          >
                            {{ country.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="retailer-profile__edit-modal--form_subtitle">
                  {{ $t("Retailers.RetailerProfile.types") }}
                </div>
                <div class="hq">
                  <div class="field retailer-profile__edit-modal--form_container">
                    <div class="control">
                      <label class="retailer-profile__edit-modal--form_label">
                        {{ $t("Retailers.RetailerProfile.channelType") }}
                      </label>
                      <div class="select is-fullwidth">
                        <select v-model="retailerEdit.channelType.id">
                          <option
                            v-for="item in channelTypes"
                            :key="`channelType_${item.id}`"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="field retailer-profile__edit-modal--form_container">
                    <div class="control">
                      <label class="retailer-profile__edit-modal--form_label">
                        {{ $t("Retailers.RetailerProfile.locationType") }}
                      </label>
                      <div class="select is-fullwidth">
                        <select v-model="retailerEdit.locationType.id">
                          <option
                            v-for="item in locationTypes"
                            :key="`locationType_${item.id}`"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="field retailer-profile__edit-modal--form_container">
                    <div class="control">
                      <label class="retailer-profile__edit-modal--form_label">
                        {{ $t("Retailers.RetailerProfile.otherType") }}
                      </label>
                      <div class="select is-fullwidth">
                        <a-select
                          v-model="retailerEdit.otherTypeValue"
                          mode="multiple"
                          size="large"
                          style="width: 100%"
                          placeholder="Please select type"
                          :get-popup-container="triggerNode => triggerNode.parentNode"
                          @change="handleOtherTypeChange"
                        >
                          <a-select-option v-for="item in otherTypes" :key="item.id">
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="retailer-profile__edit-modal--form-locations-title">
                  {{ $t("Retailers.RetailerProfile.locations") }}
                </div>
                <div
                  v-for="(location, index) in $v.retailerEdit.locationCounts.$each.$iter"
                  :key="`location_${index}`"
                  class="columns is-multiline is-marginless"
                >
                  <div class="column is-8">
                    <div class="field retailer-profile__edit-modal--form_container">
                      <div class="control">
                        <div
                          class="select is-fullwidth"
                          :class="{ 'is-danger': location.country.id.$error }"
                        >
                          <select
                            v-model="location.country.id.$model"
                            @change="setLocationChanged()"
                          >
                            <option
                              v-for="country in countries"
                              :key="`country_${country.id}`"
                              :value="country.id"
                            >
                              {{ country.name }}
                            </option>
                          </select>
                          <p v-if="location.country.id.$error" class="help is-danger">
                            {{ $t("Retailers.RetailerProfile.Validations.required") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field retailer-profile__edit-modal--form_container">
                      <div class="control retailer-profile__edit-modal--form_control">
                        <input
                          v-model.trim="location.count.$model"
                          class="input retailer-profile__edit-modal--form_input"
                          :placeholder="$t('Retailers.RetailerProfile.numberOfLocations')"
                          :class="{ 'is-danger': location.count.$error }"
                          @input="setLocationChanged()"
                        />
                        <p
                          v-if="
                            location.count.$error && !location.count.required && isLocationChanged
                          "
                          class="help is-danger"
                        >
                          {{ $t("Retailers.RetailerProfile.Validations.required") }}
                        </p>
                        <p
                          v-if="location.count.$error && !location.count.positiveNumber"
                          class="help is-danger"
                        >
                          {{ $t("Retailers.RetailerProfile.Validations.numberMustPositive") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="column is-12">
                    <a
                      class="retailer-profile__edit-modal--form_link"
                      @click.prevent="removeLocation(index)"
                    >
                      {{ $t("Retailers.RetailerProfile.deleteLocation") }}
                    </a>
                  </div>
                </div>
                <a class="retailer-profile__edit-modal--form_add-location" @click="addLocation()">
                  {{ $t("Retailers.RetailerProfile.addAnotherLocation") }}
                </a>
              </div>
              <div
                v-show="currentMenuItem === 'terms'"
                class="retailer-profile__edit-modal--form_terms"
              >
                <p class="retailer-profile__edit-modal--change-warning">
                  * {{ $t("Retailers.RetailerProfile.changeWarning") }}
                </p>
                <div class="columns is-marginless retailer-profile__edit-modal--form_address">
                  <div class="column">
                    <div class="field retailer-profile__edit-modal--form_container">
                      <label class="retailer-profile__edit-modal--form_label">
                        * {{ $t("Retailers.RetailerProfile.paymentTerms") }}
                      </label>
                      <RegisterPopup
                        :text="$t('Retailers.RetailerProfile.paymentTermsDescription')"
                      />
                      <div class="select is-fullwidth">
                        <select v-model="retailerEdit.terms.paymentTerm.id">
                          <option
                            v-for="paymentTerm in paymentTerms"
                            :key="`paymentTerm_${paymentTerm.id}`"
                            :value="paymentTerm.id"
                          >
                            {{ paymentTerm.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field retailer-profile__edit-modal--form_container">
                      <label class="retailer-profile__edit-modal--form_label">
                        * {{ $t("Retailers.RetailerProfile.marketingCoop") }}
                      </label>
                      <RegisterPopup
                        :text="$t('Retailers.RetailerProfile.marketingCoopDescription')"
                      />
                      <div
                        class="control has-icons-right retailer-profile__edit-modal--form_control"
                      >
                        <input
                          v-model="retailerEdit.terms.marketingCoOp"
                          class="input retailer-profile__edit-modal--form_input"
                          :class="{ 'is-danger': !$v.retailerEdit.terms.marketingCoOp.required }"
                          @input="invalidMarketingCoop = false"
                        />
                        <p
                          v-if="!$v.retailerEdit.terms.marketingCoOp.required"
                          class="help is-danger"
                        >
                          {{ $t("Retailers.RetailerProfile.Validations.required") }}
                        </p>
                        <p v-if="this.invalidMarketingCoop" class="help is-danger">
                          {{
                            $t("Retailers.RetailerProfile.Validations.pleaseEnterValueLessThan100")
                          }}
                        </p>
                        <span class="icon is-right percent-icon">
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns is-marginless retailer-profile__edit-modal--form_address">
                  <div class="column">
                    <div class="field retailer-profile__edit-modal--form_container">
                      <label class="retailer-profile__edit-modal--form_label">
                        * {{ $t("Retailers.RetailerProfile.damageBudget") }}
                      </label>
                      <RegisterPopup
                        :text="$t('Retailers.RetailerProfile.damageBudgetDesciption')"
                      />
                      <div
                        class="control has-icons-right retailer-profile__edit-modal--form_control"
                      >
                        <input
                          v-model="retailerEdit.terms.damagesBudget"
                          class="input retailer-profile__edit-modal--form_input"
                          :class="{ 'is-danger': !$v.retailerEdit.terms.damagesBudget.required }"
                          @input="invalidDamagesBudget = false"
                        />
                        <p
                          v-if="!$v.retailerEdit.terms.damagesBudget.required"
                          class="help is-danger"
                        >
                          {{ $t("Retailers.RetailerProfile.Validations.required") }}
                        </p>
                        <p v-if="this.invalidDamagesBudget" class="help is-danger">
                          {{
                            $t("Retailers.RetailerProfile.Validations.pleaseEnterValueLessThan100")
                          }}
                        </p>
                        <span class="icon is-right percent-icon">
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field retailer-profile__edit-modal--form_container">
                      <label class="retailer-profile__edit-modal--form_label">
                        * {{ $t("Retailers.RetailerProfile.tester") }}
                      </label>
                      <!-- <RegisterPopup :text="$t('Retailers.RetailerProfile.testerDescription')" /> -->
                      <div
                        class="control has-icons-right retailer-profile__edit-modal--form_control"
                      >
                        <input
                          v-model="retailerEdit.terms.testers"
                          class="input retailer-profile__edit-modal--form_input"
                          :class="{ 'is-danger': !$v.retailerEdit.terms.testers.required }"
                          @input="invalidTester = false"
                        />
                        <p v-if="!$v.retailerEdit.terms.testers.required" class="help is-danger">
                          {{ $t("Retailers.RetailerProfile.Validations.required") }}
                        </p>
                        <p v-if="this.invalidTester" class="help is-danger">
                          {{
                            $t("Retailers.RetailerProfile.Validations.pleaseEnterValueLessThan100")
                          }}
                        </p>
                        <span class="icon is-right percent-icon">
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <!--                    <div class="field retailer-profile__edit-modal&#45;&#45;form_container">-->
                    <!--                      <label class="retailer-profile__edit-modal&#45;&#45;form_label">-->
                    <!--                        * {{ $t("Retailers.RetailerProfile.tester") }}-->
                    <!--                      </label>-->
                    <!--                      <RegisterPopup :text="$t('Retailers.RetailerProfile.testerDescription')" />-->
                    <!--                      <div-->
                    <!--                          class="control has-icons-right retailer-profile__edit-modal&#45;&#45;form_control"-->
                    <!--                      >-->
                    <!--                        <input-->
                    <!--                            v-model="retailerEdit.terms.testers"-->
                    <!--                            class="input retailer-profile__edit-modal&#45;&#45;form_input"-->
                    <!--                            :class="{ 'is-danger': !$v.retailerEdit.terms.testers.required }"-->
                    <!--                            @input="invalidTester = false"-->
                    <!--                        />-->
                    <!--                        <p v-if="!$v.retailerEdit.terms.testers.required" class="help is-danger">-->
                    <!--                          {{ $t("Retailers.RetailerProfile.Validations.required") }}-->
                    <!--                        </p>-->
                    <!--                        <p v-if="this.invalidTester" class="help is-danger">-->
                    <!--                          {{-->
                    <!--                            $t("Retailers.RetailerProfile.Validations.pleaseEnterValueLessThan100")-->
                    <!--                          }}-->
                    <!--                        </p>-->
                    <!--                        <span class="icon is-right percent-icon">-->
                    <!--                          %-->
                    <!--                        </span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                </div>
                <div class="field retailer-profile__edit-modal--form_container">
                  <div class="retailer-profile__edit-modal--form-compliance">
                    {{ $t("Retailers.RetailerProfile.compliance") }}
                  </div>
                  <div class="columns">
                    <div class="column is-4">
                      <div class="control">
                        <label class="checkbox retailer-profile__edit-modal--form_radio container">
                          <input
                            v-model="retailerEdit.terms.productTicketingRequired"
                            type="checkbox"
                            name="testers"
                          />
                          <span class="checkmark-box"> </span>
                          <span class="retailer-profile__edit-modal--form_radio-label">
                            {{ $t("Retailers.RetailerProfile.productTicketing") }}
                          </span>
                        </label>
                        <label class="checkbox retailer-profile__edit-modal--form_radio container">
                          <input
                            v-model="retailerEdit.terms.ediRequired"
                            type="checkbox"
                            name="testers"
                          />
                          <span class="checkmark-box"> </span>
                          <span class="retailer-profile__edit-modal--form_radio-label">
                            {{ $t("Retailers.RetailerProfile.retailerEDI") }}
                          </span>
                        </label>
                        <label class="checkbox retailer-profile__edit-modal--form_radio container">
                          <input
                            v-model="retailerEdit.terms.routingGuideRequired"
                            type="checkbox"
                            name="testers"
                          />
                          <span class="checkmark-box"> </span>
                          <span class="retailer-profile__edit-modal--form_radio-label">
                            {{ $t("Retailers.RetailerProfile.vendorGuide") }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="retailer-profile__edit-modal--form_label">
                  {{ $t("Retailers.RetailerProfile.acceptsOverseasShipment") }}
                  <div class="control">
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.acceptsOverseasFreight"
                        type="radio"
                        name="shipment"
                        value="true"
                      />
                      <span class="checkmark-radio"></span>
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.yes") }}
                      </span>
                    </label>
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.acceptsOverseasFreight"
                        type="radio"
                        name="shipment"
                        value="false"
                      />
                      <span class="checkmark-radio"></span>
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.no") }}
                      </span>
                    </label>
                  </div>
                </div>
                <label class="retailer-profile__edit-modal--form_label">
                  {{ $t("Retailers.RetailerProfile.usShipping") }}
                </label>
                <span>
                  <BaseFormPopup :text="$t('Retailers.RetailerProfile.shippingUSToolTip')" />
                  <div class="control terms-edit">
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.usDomesticShippingObligation"
                        type="radio"
                        name="shipping"
                        value="Brand"
                      />
                      <span class="checkmark-radio" />
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.brand") }}
                      </span>
                    </label>
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.usDomesticShippingObligation"
                        type="radio"
                        name="shipping"
                        value="Retailer"
                      />
                      <span class="checkmark-radio" />
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.retailer") }}
                      </span>
                    </label>
                  </div>
                </span>
                <label class="retailer-profile__edit-modal--form_label">
                  {{ $t("Retailers.RetailerProfile.testers") }}
                </label>
                <span>
                  <BaseFormPopup :text="$t('Retailers.RetailerProfile.testersToolTip')" />
                  <div class="control terms-edit">
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.testersObligation"
                        type="radio"
                        name="testers"
                        value="Brand"
                      />
                      <span class="checkmark-radio" />
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.brand") }}
                      </span>
                    </label>
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.testersObligation"
                        type="radio"
                        name="testers"
                        value="Retailer"
                      />
                      <span class="checkmark-radio" />
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.retailer") }}
                      </span>
                    </label>
                  </div>
                </span>
                <label class="retailer-profile__edit-modal--form_label">
                  {{ $t("Retailers.RetailerProfile.fixtures") }}
                </label>
                <span>
                  <BaseFormPopup :text="$t('Retailers.RetailerProfile.fixturesToolTip')" />
                  <div class="control terms-edit">
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.fixturesObligation"
                        type="radio"
                        name="fixtures"
                        value="Brand"
                      />
                      <span class="checkmark-radio" />
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.brand") }}
                      </span>
                    </label>
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.fixturesObligation"
                        type="radio"
                        name="fixtures"
                        value="Retailer"
                      />
                      <span class="checkmark-radio" />
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.retailer") }}
                      </span>
                    </label>
                  </div>
                </span>
                <label class="retailer-profile__edit-modal--form_label">
                  {{ $t("Retailers.RetailerProfile.signage") }}
                </label>
                <span>
                  <BaseFormPopup :text="$t('Retailers.RetailerProfile.signageToolTip')" />
                  <div class="control terms-edit">
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.signageObligation"
                        type="radio"
                        name="signage"
                        value="Brand"
                      />
                      <span class="checkmark-radio" />
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.brand") }}
                      </span>
                    </label>
                    <label class="radio retailer-profile__edit-modal--form_radio container">
                      <input
                        v-model="retailerEdit.terms.signageObligation"
                        type="radio"
                        name="signage"
                        value="Retailer"
                      />
                      <span class="checkmark-radio" />
                      <span class="retailer-profile__edit-modal--form_radio-label">
                        {{ $t("Retailers.RetailerProfile.retailer") }}
                      </span>
                    </label>
                  </div>
                </span>
              </div>
              <div
                v-show="currentMenuItem === 'categories'"
                class="retailer-profile__edit-modal--form_terms"
              >
                <p class="retailer-profile__edit-modal--add-interested-product-categories">
                  * {{ $t("Retailers.RetailerProfile.pleaseAddInterestedProductCategories") }}
                </p>
                <div
                  v-for="(category, index) in retailerEdit.productCategories"
                  :key="`category_${index}`"
                  class="field retailer-profile__edit-modal--form_container"
                >
                  <label class="retailer-profile__edit-modal--form_label">
                    {{ $t("Retailers.RetailerProfile.category") }} {{ index + 1 }}
                  </label>
                  <span v-if="index !== 0">
                    <a
                      class="retailer-profile__edit-modal--form_link"
                      @click="removeCategory(index)"
                    >
                      {{ $t("Retailers.RetailerProfile.deleteCategory") }}
                    </a>
                  </span>
                  <div class="control retailer-profile__edit-modal--form_control">
                    <div class="select is-fullwidth">
                      <select v-model="category.id">
                        <option
                          v-for="category in categories"
                          :key="`categoryId_${category.id}`"
                          :value="category.id"
                        >
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="has-text-right">
                  <a class="button retailer-profile__add-category-button" @click="addCategory()">
                    {{ $t("Retailers.RetailerProfile.addAnotherCategory") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot brand-profile__edit-modal-form-buttons">
          <div class="columns">
            <div class="column is-8 brand-profile__edit-modal-form-messages">
              <div v-show="isSaving && currentMenuItem === 'overview'" class="has-text-left">
                {{ $t("Retailers.RetailerProfile.uploadingImage") }}
              </div>
              <div v-show="isSaved && currentMenuItem === 'overview'" class="has-text-left">
                {{ $t("Retailers.RetailerProfile.successfullyUploaded") }}
              </div>
              <div
                v-show="savingError && currentMenuItem === 'overview'"
                class="has-text-left brand-profile__edit-modal-error-message"
              >
                {{ $t("Retailers.RetailerProfile.imageTooLarge") }}
              </div>
              <div
                v-if="$v.retailerEdit.$invalid || $v.headQuartersEdit.$invalid"
                class="has-text-left error-message"
              >
                {{ $t("Retailers.RetailerProfile.Validations.missingFields") }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-right">
                <a class="button cancel" @click="hideEditModal">
                  {{ $t("Retailers.RetailerProfile.cancel") }}
                </a>
                <a
                  class="button is-black brand-profile__edit-modal-form-save-button"
                  :class="{ 'disable-save': isSaving }"
                  :disabled="isSaving"
                  @click="update()"
                >
                  {{ $t("Retailers.RetailerProfile.save") }}
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <ChangeRequestModal
      :show="showChanges"
      :pending-data="retailer.termsPending"
      @hideChangeRequestModal="hideChangeRequestModal"
      @approveChanges="approve"
      @rejectChanges="reject"
    />
  </div>
</template>

<script>
import BannerImageCropper from "@/components/cropper/BannerImageCropper"
import LogoImageCropper from "@/components/cropper/LogoImageCropper"
import InputWithPrefix from "@/components/InputWithPrefix"
import RegisterPopup from "@/components/registration/RegisterPopup"
import ChangeRequestModal from "@/components/retailers/profile/ChangeRequestModal"
import TermsChangeRequest from "@/components/shared/TermsChangeRequest"
import BaseFormPopup from "@/components/brands/products/BaseFormPopup"
import OverView from "@/components/common/OverView"
import IdentityCard from "@/components/common/IdentityCard"
import ProfileCategories from "@/components/common/ProfileCategories"
import { required } from "vuelidate/lib/validators"
import RetailersProfileRightCard from "@/components/retailers/profile/RetailersProfileRightCard"
import { Select } from "ant-design-vue"
import infiniteScroll from "@/utils/InfiniteScroll"

export default {
  directives: { infiniteScroll },
  validations() {
    if (this.isLocationChanged) {
      return {
        headQuartersEdit: {
          streetAddressLine_1: { required },
          streetAddressLine_2: {},
          city: { required },
          region: { required },
          postalCode: { required },
          country: {
            id: { required }
          }
        },
        retailerEdit: {
          about: { required },
          introduction: { required },
          locationCounts: {
            $each: {
              country: { id: { required } },
              count: {
                required,
                positiveInteger(count) {
                  return /^[1-9]\d*$/gm.test(count)
                }
              }
            }
          },
          terms: {
            paymentTerm: { id: { required } },
            marketingCoOp: { required },
            damagesBudget: { required },
            testers: { required }
          }
        }
      }
    } else {
      return {
        headQuartersEdit: {
          streetAddressLine_1: { required },
          streetAddressLine_2: {},
          city: { required },
          region: { required },
          postalCode: { required },
          country: {
            id: { required }
          }
        },
        retailerEdit: {
          introduction: { required },
          about: { required },
          locationCounts: {
            $each: {
              country: { id: {} },
              count: {
                positiveInteger(count) {
                  return /^(\s*|[1-9]\d*)$/gm.test(count)
                }
              }
            }
          },
          terms: {
            paymentTerm: { id: { required } },
            marketingCoOp: { required },
            damagesBudget: { required },
            testers: { required }
          }
        }
      }
    }
  },
  components: {
    BannerImageCropper,
    LogoImageCropper,
    InputWithPrefix,
    RegisterPopup,
    ChangeRequestModal,
    TermsChangeRequest,
    BaseFormPopup,
    OverView,
    IdentityCard,
    ProfileCategories,
    RetailersProfileRightCard,
    "a-select": Select,
    "a-select-option": Select.Option
  },
  data() {
    return {
      isLocationChanged: false,
      changeRequest: false,
      isEditModalOpen: false,
      currentMenuItem: "overview",
      retailerTermsInitial: null,
      retailerEdit: {
        website: "",
        about: "",
        introduction: "",
        foundingDate: null,
        size: {},
        channelType: {},
        locationType: {},
        otherTypeValue: [],
        otherType: [],
        socialAccounts: [],
        locationCounts: [],
        bannerAsset: {},
        logoAsset: {},
        terms: { paymentTerm: { id: null } },
        productCategories: [],
        ingredientName: [],
        forbiddenIngredients: []
      },
      headQuartersEdit: {
        streetAddressLine_1: "",
        streetAddressLine_2: "",
        city: "",
        region: "",
        postalCode: "",
        country: {
          id: "us"
        },
        hq: true
      },
      socialAccounts: [],
      isSaving: false,
      isSaved: false,
      savingError: false,
      showChanges: false,
      invalidMarketingCoop: false,
      invalidDamagesBudget: false,
      invalidTester: false,
      introductionLengthExceeds: false,
      maxcharacter: 100,
      remaincharactersText: "Remaining 100 characters.",
      addIngredientsFilter: "",
      logoAsset: undefined,
      bannerAsset: undefined
    }
  },
  computed: {
    retailerAddressess() {
      return this.$store.getters["retailerAddress/addresses"].filter(item => !item.hq)
    },
    headquarters() {
      return this.$store.getters["retailerAddress/headquarters"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    tags() {
      let data = this.$store.getters["retailer/retailer"]
      let res = []
      if (data && data.channelType) {
        res.push(data.channelType)
      }
      return res
    },
    role() {
      return this.$store.getters["user/role"]
    },
    categories() {
      return this.$store.getters["reference/categories"]
    },
    countries() {
      return this.$store.getters["reference/countries"]
    },
    paymentTerms() {
      return this.$store.getters["reference/paymentTerms"]
    },
    retailerId() {
      if (
        this.$route.name == "adminRetailerProfile" ||
        this.$route.name == "retailerProfileAdmin" ||
        this.$route.name == "adminBrandRetailerProfile" ||
        this.$route.name == "brandRetailerProfile"
      ) {
        return this.$route.params.retailerId
      } else {
        return this.$store.getters["user/businessId"]
      }
    },
    clientSizes() {
      return this.$store.getters["reference/clientSizes"]
    },
    channelTypes() {
      return this.$store.getters["reference/channelType"]
    },
    locationTypes() {
      return this.$store.getters["reference/locationType"]
    },
    otherTypes() {
      return this.$store.getters["reference/otherType"]
    },
    years() {
      const year = new Date().getFullYear()
      var arr = Array.from({ length: year - 1899 }, (value, index) => 1900 + index)
      return arr.reverse()
    },
    locationsForApi() {
      return this.retailerEdit.locationCounts.filter(
        location => location.country.id && location.count
      )
    },
    categoriesForApi() {
      if (this.retailerEdit.productCategories) {
        const categories = this.retailerEdit.productCategories.filter(category => category.id)
        const uniqCategoriesIds = [...new Set(categories.map(item => item.id))]
        return uniqCategoriesIds.map(id => categories.find(x => x.id === id))
      }
      return []
    },
    requirementsString() {
      const ticketingRequiredString =
        this.retailer.terms.productTicketingRequired && "Product Ticketing"
      const ediRequired = this.retailer.terms.ediRequired && "Retailer EDI"
      const routingGuideRequired = this.retailer.terms.routingGuideRequired && "Routing Guide"
      if (ticketingRequiredString || ediRequired || routingGuideRequired)
        return [ticketingRequiredString, ediRequired, routingGuideRequired]
          .filter(requirement => requirement)
          .join(", ")
      else return "(None)"
    },
    socialAccountsForApi() {
      return this.socialAccounts.filter(socialAccount => socialAccount.address)
    },
    isTermsPending() {
      return this.$store.getters["retailer/isTermsPending"]
    }
  },
  watch: {
    async retailer() {
      this.afterCreate()
    }
  },
  async created() {
    await this.$store.dispatch("reference/setReference")
    await this.$store.dispatch("retailer/fetchRetailer", this.retailerId)
    this.afterCreate()
  },
  updated() {
    let multi_input = document.getElementById("addDirtyIngreidentSelect")
    if (multi_input) {
      multi_input.setAttribute("placeholder", "+ Add Banned Ingredients")
    }
  },
  methods: {
    updated() {
      let multi_input = document.getElementById("addDirtyIngreidentSelect")
      if (multi_input) {
        multi_input.setAttribute("placeholder", "+ Add Banned Ingredients")
      }
    },
    // .......add clean standards function start..........
    async afterCreate() {
      this.retailerTermsInitial = {
        ...this.retailer.terms,
        paymentTerm: { ...this.retailer.terms.paymentTerm }
      }
      await this.$store.dispatch("retailerAddress/fetchAddresses", this.retailer.id)
    },
    initSocialAccounts() {
      this.socialAccounts = this.$store.getters["reference/socialAccounts"].map(socialAccount => {
        const existingAccount =
          this.retailer.socialAccounts.find(account => account.platform.id === socialAccount.id) ||
          {}
        return { address: existingAccount.address, platform: socialAccount }
      })
    },
    charCount() {
      if (this.retailerEdit.introduction.length > this.maxcharacter) {
        this.introductionLengthExceeds = true
      } else {
        var remainingCharacters = this.maxcharacter - this.retailerEdit.introduction.length
        this.remaincharactersText = remainingCharacters + " characters " + "remaining"
      }
    },
    showEditModal() {
      this.retailerEdit = this.retailerEditObject()
      var remainingCharacters = this.maxcharacter - this.retailerEdit.introduction.length
      this.remaincharactersText = remainingCharacters + " characters " + "remaining"
      this.initSocialAccounts()
      if (!this.retailerEdit.locationCounts.length) {
        this.addLocation()
      }
      if (!this.retailer.productCategories.length) {
        this.addCategory()
      }
      this.$v.retailerEdit.locationCounts.$each.$reset()
      this.isLocationChanged = false
      this.headQuartersEdit = { ...this.headquarters, country: { ...this.headquarters.country } }
      this.isEditModalOpen = true
      this.isSaving = false
    },
    handleOtherTypeChange(value) {
      this.retailerEdit.otherType = []
      for (let i = 0; i < value.length; i++) {
        this.retailerEdit.otherType.push({ id: value[i] })
      }
    },
    retailerEditObject() {
      return {
        ...this.retailer,
        bannerAsset: { ...this.retailer.bannerAsset },
        logoAsset: { ...this.retailer.logoAsset },
        terms: {
          ...this.retailer.terms,
          paymentTerm: { ...this.retailer.terms.paymentTerm }
        },
        productCategories: [
          ...this.retailer.productCategories.map(item => {
            return {
              id: item.id,
              name: item.name
            }
          })
        ],
        size: {
          id: this.retailer.size.id ? this.retailer.size.id : "",
          name: this.retailer.size.name
        },
        channelType: {
          id:
            this.retailer.channelType && this.retailer.channelType.id
              ? this.retailer.channelType.id
              : ""
        },
        locationType: {
          id:
            this.retailer.locationType && this.retailer.locationType.id
              ? this.retailer.locationType.id
              : ""
        },
        otherTypeValue: this.retailer.otherTypes
          ? [...this.retailer.otherTypes.map(item => item.id)]
          : [],
        otherType: this.retailer.otherTypes
          ? [
              ...this.retailer.otherTypes.map(item => {
                return {
                  id: item.id
                }
              })
            ]
          : [],
        ingredientName: this.retailer.forbiddenIngredients
          ? [...this.retailer.forbiddenIngredients.map(item => parseInt(item.id))]
          : [],
        forbiddenIngredients: [],
        locationCounts: [
          ...this.retailer.locationCounts.map(item => {
            return {
              count: item.count,
              country: { ...item.country }
            }
          })
        ],
        foundingDate: this.retailer.foundingDate && this.retailer.foundingDate.split("-")[0]
      }
    },
    offset(key) {
      return {
        website: "80px",
        facebook: "174px",
        instagram: "177px",
        linkedin: "165px",
        twitter: "153px"
      }[key]
    },
    countryName(id) {
      return this.$store.getters["reference/countryName"](id)
    },
    clientSizeName(id) {
      return this.$store.getters["reference/clientSizeName"](id)
    },
    paymentTermName(id) {
      return this.$store.getters["reference/paymentTermName"](id)
    },
    categoryName(id) {
      return this.$store.getters["reference/categoryName"](id)
    },

    retailerRootObject() {
      this.retailerAddressess.push(this.headQuartersEdit)
      return {
        id: this.retailerEdit.id,
        name: this.retailerEdit.name,
        logoAsset: this.logoAsset
          ? {
              id: this.logoAsset.id,
              url: this.logoAsset.url
            }
          : {
              id: this.retailerEdit.logoAsset.id,
              url: this.retailerEdit.logoAsset.url
            },
        bannerAsset: this.bannerAsset
          ? {
              id: this.bannerAsset.id,
              url: this.bannerAsset.url
            }
          : {
              id: this.retailerEdit.bannerAsset.id,
              url: this.retailerEdit.bannerAsset.url
            },
        shortName: this.retailerEdit.shortName,
        introduction: this.retailerEdit.introduction,
        about: this.retailerEdit.about,
        website: this.retailerEdit.website,
        foundingDate: this.formatDate(this.retailerEdit.foundingDate),
        size: this.retailerEdit.size,
        channelType: this.retailerEdit.channelType,
        locationType: this.retailerEdit.locationType,
        status: this.retailerEdit.status,
        productCategories: this.categoriesForApi,
        otherTypes: this.retailerEdit.otherType,
        socialAccounts: this.socialAccountsForApi,
        addresses: this.retailerAddressess,
        locationCounts: this.locationsForApi,
        terms: this.retailerEdit.terms
      }
    },
    formatDate(foundingDate) {
      if (foundingDate) {
        return foundingDate + "-" + "01" + "-" + "01"
      }
      return ""
    },
    isActiveTab(tab) {
      return this.currentTab === tab
    },

    toggleTab(tab) {
      this.currentTab = tab
    },

    isMenuItem(item) {
      return this.currentMenuItem === item
    },

    toggleMenuItem(item) {
      this.isEditModalOpen = true
      this.currentMenuItem = item
    },
    addCategory() {
      this.retailerEdit.productCategories.push({ id: "" })
    },
    removeCategory(index) {
      return this.retailerEdit.productCategories.splice(index, 1)
    },
    removeLocation(index) {
      return this.retailerEdit.locationCounts.splice(index, 1)
    },

    flattenAddress(address) {
      if (!address.city || !address.region) {
        return "N/A"
      }

      return [
        address.streetAddressLine_1,
        address.streetAddressLine_2,
        address.city,
        address.region,
        address.postalCode,
        this.countryName(address.country.id)
      ]
        .filter(item => item)
        .join(", ")
    },
    addLocation() {
      this.retailerEdit.locationCounts.push({ country: { id: "" }, count: "" })
    },
    marketingCoopAndDamageBudgeCheck() {
      if (this.retailerEdit.terms.marketingCoOp > 100) {
        this.invalidMarketingCoop = true
      }
      if (this.retailerEdit.terms.damagesBudget > 100) {
        this.invalidDamagesBudget = true
      }
      if (this.retailerEdit.terms.tester > 100) {
        this.invalidTester = true
      }
    },
    async update() {
      this.$v.$touch()
      this.marketingCoopAndDamageBudgeCheck()
      if (
        this.$v.retailerEdit.$invalid ||
        this.invalidMarketingCoop ||
        this.invalidDamagesBudget ||
        this.invalidTester ||
        this.$v.headQuartersEdit.$invalid ||
        this.introductionLengthExceeds
      ) {
        return
      }
      this.isEditModalOpen = false
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "profileComplete",
        userId: this.$store.getters["user/id"],
        firstName: this.$store.getters["user/givenName"],
        lastName: this.$store.getters["user/familyName"],
        emailAddress: this.$store.getters["user/email"],
        userType: "Retailer"
      })
      // this.updateLocations()
      this.updateTerms()
      this.updateRetailer()
    },
    async updateRetailer() {
      try {
        await this.$store.dispatch("retailer/updateRetailer", this.retailerRootObject())
      } catch (error) {
        console.error(error)
      }
    },
    updateBannerAsset(bannerAssetId, bannerAssetUrl) {
      this.bannerAsset = {
        id: bannerAssetId,
        url: bannerAssetUrl
      }
    },
    updateLogoAsset(logoAssetId, logoAssetUrl) {
      this.logoAsset = {
        id: logoAssetId,
        url: logoAssetUrl
      }
    },
    async updateCategories() {
      try {
        await this.$store.dispatch("retailer/updateCategories", this.categoriesForApi)
        this.retailer.productCategories = this.categoriesForApi
      } catch (error) {
        console.error(error)
      }
    },
    async updateTerms() {
      if (
        (Number(this.retailerTermsInitial.damagesBudget) !==
          Number(this.retailerEdit.terms.damagesBudget) ||
          Number(this.retailerTermsInitial.marketingCoOp) !==
            Number(this.retailerEdit.terms.marketingCoOp) ||
          Number(this.retailerTermsInitial.testers) !== Number(this.retailerEdit.terms.testers) ||
          this.retailerTermsInitial.paymentTerm.id !== this.retailerEdit.terms.paymentTerm.id) &&
        this.role === "retailer"
      ) {
        this.updatePendingTerms()
      }
    },
    async updateOtherTypes() {
      try {
        await this.$store.dispatch("retailer/updateOtherTypes", this.retailerEdit.otherType)
      } catch (error) {
        console.error(error)
      }
    },

    async getRetailerInfo() {
      await this.$store.dispatch("retailer/fetchRetailer", this.retailerId)
    },

    async updateLocations() {
      try {
        await this.$store.dispatch("retailer/updateLocations", this.locationsForApi)
      } catch (error) {
        console.error(error)
      }
    },
    async updateAddresses() {
      this.retailerAddressess.push(this.headQuartersEdit)
      try {
        await this.$store.dispatch("retailerAddress/updateAll", {
          addresses: this.retailerAddressess,
          retailerId: this.retailer.id
        })
      } catch (error) {
        console.error(error)
      }
    },
    async updateSocialAccounts() {
      try {
        await this.$store.dispatch(
          "retailer/updateSocialAccounts",
          this.socialAccountsForApi,
          this.retailer.id
        )
      } catch (error) {
        console.error(error)
      }
    },
    resetSavedStatus() {
      this.isSaved = false
    },
    resetErrorStatus() {
      this.isSavingError = false
    },
    imageSaving(status) {
      this.isSaving = false
      this.isSaved = false
      this.savingError = false

      if (status === "saving") {
        this.isSaving = true
      }
      if (status === "saved") {
        this.isSaved = true
        setTimeout(this.resetSavedStatus, 3000)
      }
      if (status === "error") {
        this.savingError = true
        setTimeout(this.resetErrorStatus, 3000)
      }
    },
    retailerPendingTermsObject() {
      return {
        status: {
          id: "pending",
          name: "Pending"
        },
        paymentTermBefore: {
          id: this.retailerTermsInitial.paymentTerm.id,
          name: this.retailerTermsInitial.paymentTerm.name
        },
        paymentTermAfter: {
          id: this.retailerEdit.terms.paymentTerm.id,
          name: this.retailerEdit.terms.paymentTerm.name
        },
        marketingCoOpBefore: this.retailerTermsInitial.marketingCoOp,
        marketingCoOpAfter: this.retailerEdit.terms.marketingCoOp,
        damagesBudgetBefore: this.retailerTermsInitial.damagesBudget,
        damagesBudgetAfter: this.retailerEdit.terms.damagesBudget,
        testersBefore: this.retailerTermsInitial.testers,
        testersAfter: this.retailerEdit.terms.testers
      }
    },
    showChangeRequestModal() {
      this.showChanges = true
    },
    hideChangeRequestModal() {
      this.showChanges = false
    },
    async updatePendingTerms() {
      try {
        await this.$store.dispatch("retailer/updatePendingTerms", this.retailerPendingTermsObject())
      } catch (error) {
        console.error(error)
      }
    },
    undoChanges() {
      this.retailer.termsPending.status.id = "canceled"
      this.retailer.termsPending.status.name = "Canceled"
      this.updatePendingTermStatus()
    },
    approve() {
      this.retailer.termsPending.status.id = "approved"
      this.retailer.termsPending.status.name = "Approved"
      this.updatePendingTermStatus()
    },
    reject() {
      this.retailer.termsPending.status.id = "rejected"
      this.retailer.termsPending.status.name = "Rejected"
      this.updatePendingTermStatus()
    },
    async updatePendingTermStatus() {
      try {
        await this.$store.dispatch("retailer/updatePendingTerms", this.retailer.termsPending)
      } catch (error) {
        console.error(error)
      }

      try {
        await this.$store.dispatch("retailer/fetchRetailer", this.retailer.id)
        this.retailerEdit = this.retailerEditObject()
      } catch (error) {
        console.error(error)
      }
    },
    hideEditModal() {
      this.isEditModalOpen = false
      this.invalidMarketingCoop = false
      this.invalidDamagesBudget = false
      this.invalidTester = false
      this.currentMenuItem = "overview"
      this.retailerEdit = this.retailerEditObject()
      var remainingCharacters = this.maxcharacter - this.retailerEdit.introduction.length
      this.remaincharactersText = remainingCharacters + " characters " + "remaining"
      this.initSocialAccounts()
    },
    goToWebsite() {
      window.open("http://" + this.retailer.website)
    },
    goToSocialMedia(socialAccount) {
      window.open("http://" + socialAccount.address)
    },
    socialAccountAddress(address, socialAccount) {
      if (address) {
        socialAccount.address = socialAccount.platform.id + ".com/" + address
      } else {
        socialAccount.address = address
      }
    },
    setLocationChanged() {
      let locationLength = this.retailerEdit.locationCounts.filter(
        item => item.country.id || item.count
      ).length
      if (locationLength != this.locationsForApi.length) {
        this.isLocationChanged = true
      } else {
        this.isLocationChanged = false
      }
    }
  }
}
</script>

<style lang="sass">
.terms-edit
  padding-bottom: 10px

.pending-changes-approval
  position: absolute
  top: 415px
  right: 20px

.remaining-characters
  font-size: 12px
  justify-content: flex-end
  display: flex
.retailer-profile-container
  color: #000000
  width: 953px
  margin: 0px auto !important

.header-content
  margin-bottom: 26px
.retailer-profile
  .modal-card-body
    overflow-y: auto
    overflow-x: hidden

  &__breadcrumb
    margin-bottom: 1rem !important

  &__edit-modal
    background-color: #ffffff
    width: 875px

    &-error-message
      color: #BF0711
      font-size: 18px

    &-form-messages
      display: flex
      align-items: center

    input::placeholder
      text-transform: none

    .radio + .radio
      margin-left: 3em !important

    .container
      position: relative
      width: auto !important
      padding: 0px
      padding-left: 25px
      margin-top: 10px
      cursor: pointer
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

      input
        position: absolute
        opacity: 0
        font-size: 14px
        cursor: pointer

    .modal-card-head
      border-bottom: 1px solid #DFE3E8
    .checkmark-box
      position: absolute
      top: 3px
      left: 0
      height: 14px
      width: 14px
      background: #FFFFFF
      border-radius: 2px
      border: 1px solid #E5E5E5

    .checkmark-radio
      position: absolute
      top: 3px
      left: 0
      height: 14px
      width: 14px
      background: #FFFFFF
      border-radius: 50%
      border: 1px solid #E5E5E5

    .container input:checked ~ .checkmark-radio, .checkmark-box
      background-color: #FFFFFF

    .checkmark-radio:after
      content: ""
      position: absolute
      display: none

    .checkmark-box:after
      content: ""
      position: absolute
      display: none

    .container input:checked ~ .checkmark-radio:after
      display: block

    .container input:checked ~ .checkmark-box:after
      display: block

    .container .checkmark-radio:after
      top: 3px
      left: 3px
      width: 6px
      height: 6px
      border-radius: 50%
      background: #000000

    .container .checkmark-box:after
      top: 3px
      left: 3px
      width: 6px
      height: 6px
      border-radius: 2px
      background: #000000

    .percent-icon
      color: #000000 !important

    select
      font-size: 14px
      height: 40px
      margin-top: 8px
      padding-left: 18px

    .select
      display: block
      margin-top: 8px

    &--container
      min-height: 1149px

    &--change-warning
      padding-bottom: 5px
      margin-bottom: 24px
      font-size: 14px
      height: 47px
      overflow: auto
    &--clean-title
      padding-bottom: 5px
      margin-bottom: 24px
      font-size: 14px
      height: 47px
      font-weight: 600
      overflow: auto

    &--add-interested-product-categories
      padding-bottom: 5px
      font-size: 14px
      height: 47px
      overflow: auto

    &--form
      padding: 0px 66px 44px 44px

    &--form_label
      color: #000000
      font-size: 14px
      font-weight: 500
      letter-spacing: 0.2px
      padding-bottom: 20px
      padding-right: 5px

    &--clean_label
      color: #000000
      font-size: 14px
      font-weight: 400
      letter-spacing: 0.2px
      padding-bottom: 20px
      padding-right: 5px

    &--clean_label
      color: #000000
      font-size: 14px
      font-weight: 400
      letter-spacing: 0.2px
      padding-bottom: 20px
      padding-right: 5px

    &--form-compliance
      padding-bottom: 5px
      font-size: 14px
      font-weight: 500

    &--form_input
      height: 40px
      font-size: 14px
      padding-left: 18px
      border-radius: 2px

    &--form_link
      float: right
      font-size: 14px
      text-decoration: underline
      color: #000000

    &--form_textarea
      height: 175px

    &--form_control
      margin-top: 8px

    &--form_radio-label
      font-size: 14px

    &--form_prefix
      padding-left: 15px
      padding-top: 12px
      display: flex
      align-items: center

    &--form_subtitle
      font-size: 16px
      font-weight: bold
      padding: 15px 0px 20px 0px

    &--form-locations-title
      margin-top: 40px
      font-size: 16px
      font-weight: bold

    &--form_container
      padding-bottom: 5px

    &--form_address
      padding-bottom: 10px

      .column:first-child
        padding: 0px 4px 0px 0px

      .column
        padding: 0px 5px 0px 5px

      .column:last-child
        padding: 0px 0px 0px 4px

    &--form_save-button
      padding-top: 30px

    &--form_add-location
      color: #161616
      font-size: 14px
      font-weight: 300
      letter-spacing: 0.16px
      text-decoration: underline
      margin-top: 17px
      cursor: pointer

    &--form_terms
      position: sticky
      top: 33px

    &--form_button
      color: #FFFFFF
      font-size: 12px
      font-weight: bold
      height: 36px
      width: 83px
      background-color: #323232
      box-shadow: inset 0 1px 0 1px rgba(255,255,255,0.06), 0 1px 0 0 rgba(22,29,37,0.1)

    .retailer-profile__edit-modal--form_photos-overview
      margin-top: 30px

    &--header
      font-size: 20px
      font-weight: 500
      letter-spacing: 0.25px
      color: #212B36
      padding-left: 15px

    &--header_close-icon
      cursor: pointer

    &--menu
      padding-left: 35px
      padding-top: 50px
      background-color: #F4F4F4
      font-size: 18px
      width: 241px !important

      li
        margin-top: 15px

      a
        color: #000000

  &__categories
    padding-top: 60px
    padding-bottom: 10px

    &--header
      font-size: 14px
      font-weight: 600

    &--back-to
      cursor: pointer
      font-size: 14px
      font-weight: 300
      padding-top: 10px

  &__filter-header
    font-size: 14px
    font-weight: 600
    text-transform: uppercase

  &__filter
    cursor: pointer
    margin: 10px 48px 0px 0px

  &__about
    padding: 55px 100px 0px 100px

  &__gallery-container
    padding-top: 55px

  &__gallery
    max-width: 920px
    margin: 0 auto

  &__overview
    margin-right: 40px
    font-size: 14px

  &__details


    &--container
      margin-top: 30px

    &--terms-title
      font-size: 18px
      letter-spacing: 0.25px
      margin-bottom: -15px

    &--title
      font-size: 18px
      letter-spacing: 0.25px

      &_text
        margin-top: 20px
        font-weight: 300

    &--subtitle


      &_link
        text-decoration: underline

    &--edit-icon
      float: right
      cursor: pointer

    &--info-container
      padding-top: 25px
      font-size: 14px
      letter-spacing: 0.13px

  &__banner
    height: 280px
    margin-bottom: -35px
    background-color: #ffffff

  &__logo
    display: flex
    justify-content: center
    margin-inline-end: 10px
    margin-left: 30px
    height: 140px
    width: 140px
    border: 1px solid #E5E5E5
    background-color: #ffffff

  &__name
    font-size: 18px
    font-weight: bold
    letter-spacing: 0.25px
    padding-top: 50px

  &__description
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.25px
    padding-top: 10px

    &--info
      padding-top: 15px

  &__website
    text-decoration: underline

    a
      color: #161616

  &__edit
    cursor: pointer
    color: #161616
    font-size: 14px
    letter-spacing: 0.16px
    text-decoration: underline
    float: right

  &__country
    font-size: 14px

  &__tabs
    padding-top: 40px

  &__tab
    font-size: 14px
    color: #9B9B9B
    font-weight: 500
    width: 115px
    letter-spacing: 0.1px

  &__product
    max-width: 250px
    margin: 0 auto

  &__products
    padding-top: 30px

  &__icons
    margin-top: 50px

  &__add-category-button
    margin-top: 10px

.retailer-profile__edit-modal-sticky-menu
  position: sticky
  top: 40px
  font-size: 18px
  color: #212B36
  padding-left: 30px

.is-selected
  font-weight: bold
  color: #000
.enable-line-break
  white-space: pre-wrap

.admin-view
  top: 375px
  right: 32px

.disable-save
  pointer-events: none
.card-content
  width: 100%
  display: flex
  padding: 0px !important
  margin-top: 20px
.card-left-content
  width: 600px
.card-right-content
  width: 327px
  margin-left: 25px
.card-bottom-action
  width: 100%
  float: left
  margin-bottom: 20px
  .link-left
    float: left
    text-decoration: underline
    font-size: 14px
    color: #595959
  .link-right
    float: right
    text-decoration: underline
    font-size: 14px
    color: #595959
</style>
