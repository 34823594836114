<template lang="pug">
.container
  .checkout-success
    .title.checkout-success__title {{$t('Retailers.RetailerCheckoutSuccess.thankYouText')}}
    .checkout-success__subtitle
      p.checkout-success__subtitle__text(v-if="role=='admin'") {{$t('Retailers.RetailerCheckoutSuccess.orderId')}}: {{ orderBatchId }}
      p.checkout-success__subtitle__text {{$t('Retailers.RetailerCheckoutSuccess.orderProcessedText')}}

    .checkout-success__buttons
      div
        router-link(to="/",v-if="!isAdmin")
          button.button.checkout-success__buttons__button.is-black.margin-rights-tyle {{$t('Retailers.RetailerCheckoutSuccess.continueShopping')}}
        router-link(:to="{name:'adminRetailerProductsAll'}",v-if="isAdmin")
          button.button.checkout-success__buttons__button.is-black {{$t('Retailers.RetailerCheckoutSuccess.continueShopping')}}
      div
        router-link(:to="{ name: 'retailerOrders', query: { 'batch-id' : orderBatchId } }",v-if="!isAdmin")
          button.button.checkout-success__buttons__button.is-black.margin-rights-tyle {{$t('Retailers.RetailerCheckoutSuccess.viewOrderDetails')}}
        router-link(:to="{ name: 'adminRetailerOrders', query: { 'batch-id' : orderBatchId } }",v-if="isAdmin")
          button.button.checkout-success__buttons__button.is-black {{$t('Retailers.RetailerCheckoutSuccess.viewOrderDetails')}}
</template>

<script>
export default {
  props: { orderBatchId: { type: String, default: "" } },
  data() {
    return {
      currentRoute: this.$route
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    role() {
      return this.$store.getters["user/role"]
    }
  }
}
</script>

<style lang="sass">
.checkout-success
  padding-top: 70px
  padding-bottom: 200px

  &__title
    height: 60px
    color: #292929
    font-size: 40px
    font-weight: 700
    letter-spacing: 0.08px
    line-height: 60px
    margin-top: 41px

  &__tab
    font-weight: 500
    width: 115px !important
    letter-spacing: 0.1px

  &__subtitle
    margin-top: 80px

  &__subtitle__text
    width: 678px
    font-size: 16px
    font-weight: 600
    letter-spacing: 0.5px
    line-height: 22px
    margin-top: 20px
    color: #292929

  &__buttons
    margin-top: 40px
    display: flex
    justify-content: flex-start
    align-items: center
    div
      display: flex
      justify-content: flex-start
      margin-right: 15px
    .margin-rights-tyle
      margin-right: 15px

  &__buttons__button
    margin-top: 11px
    height: 46px
    width: 310px
    font-size: 14px
</style>
