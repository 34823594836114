<template>
    <div class="content">
        <div class="top">
            <a-button class="upgrade-button" @click="addOrUpgradePlan">Add / Upgrade Plan</a-button>
        </div>

        <a-table :columns="columns" :data-source="subscriptions.allSubscriptions" :customRow="customRow">
            <span slot="action" slot-scope="text, record">
                <div v-if="showAction(record)">
                    <div v-if="isAdmin">
                        <a @click="adminCancel(record)">Cancel</a>
                        <a-divider type="vertical" />

                        <a @click="adminExpired(record)">Expired</a>
                    </div>
                    <div v-else>
                        <a @click="cancel(record)">Cancel</a>
                        <a-divider type="vertical" />
                        <a v-if="showReactivate" @click="resume(record)">Reactivate</a>
                    </div>
                </div>

            </span>
            <span slot="date" slot-scope="text">
                {{ formatDate(text) }}
            </span>

        </a-table>

        <a-card v-if="selectRow" :title="cardTitle" :loading="loadingDetail" class="card">
            <!-- <a slot="extra" href="#">more</a> -->
            <div class="content" v-if="subscriptionDetail">
                <div>
                    <label>Original Subscription Amount</label>
                    <div>{{ subscriptionDetail.originalAmount | currencyFormat }}</div>
                </div>
                <div>
                    <label>Promo Code - {{ subscriptionDetail.couponCode }}</label>
                    <div>({{ subscriptionDetail.couponAmount | currencyFormat }})</div>
                </div>
                <div>
                    <label v-if="subscriptionDetail.termDiscountPercentage == 0">Billing Credit - {{
                        subscriptionDetail.termName }}</label>
                    <label v-else>Billing Credit - {{ subscriptionDetail.termName }} - {{
                        subscriptionDetail.termDiscountPercentage }}%</label>

                    <div>({{ subscriptionDetail.termCredit | currencyFormat }})</div>
                </div>
                <div>
                    <label>Subscription Amount</label>
                    <div>{{ subscriptionDetail.totalPaymentAmount | currencyFormat }}</div>
                </div>
                <div>
                    <label>{{ subscriptionDetail.termName }} Payment</label>
                    <div>{{ subscriptionDetail.paymentAmount | currencyFormat }}</div>
                </div>
                <div>
                    <label>Money Paid</label>
                    <div>{{ subscriptionDetail.totalPaidAmount | currencyFormat }}</div>
                </div>
                <div>
                    <label>Balance Due</label>
                    <div>{{ subscriptionDetail.balance | currencyFormat }}</div>
                </div>
            </div>
        </a-card>

        <a-card style="margin-top:40px">
            <div class="subscription-footer">
                For Subscription Assistance - Please Contact Us At support@landingintl.com
            </div>

        </a-card>
        <confirm-cancel-subscription-dialog ref="cancelConfirm" />
        <confirm-dialog ref="confirmDialog" />
        <error-dialog ref="errorDialog" />

    </div>
</template>
<script>

import subscriptionService from "@/services/subscriptions-service";
import ConfirmCancelSubscriptionDialog from "@/components/brands/settings/CancelSubscriptionDialogNew"
import ConfirmDialog from "@/components/admin/ConfirmDialog"
const columns = [
    {
        title: 'Plan',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Billing Cycle',
        dataIndex: 'subscriptionTermName',
        key: 'subscriptionTermName',
    },
    {
        title: 'Renewal Date',
        dataIndex: 'anniversaryDate',
        key: 'anniversaryDate',
        scopedSlots: { customRender: 'date' },
    },

    {
        title: 'Payment Status',
        key: 'statusName',
        dataIndex: 'statusName',

    },
    {
        title: 'Start Date',
        key: 'startDate',
        dataIndex: 'startDate',
        scopedSlots: { customRender: 'date' },
    },
    // {
    //     title: 'End',
    //     key: 'endDate',
    //     dataIndex: 'endDate'
    // },
    {
        title: 'Action',
        key: 'action',
        scopedSlots: { customRender: 'action' },
    },
];
export default {
    components: {
        ConfirmCancelSubscriptionDialog,
        ConfirmDialog,
    },
    filters: {
        currencyFormat: function (value) {
            function formatNumber(number) {
                return value ? number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
            }

            return '$' + formatNumber(value);
        }
    },
    data() {
        return {
            subscriptions: [],
            columns: columns,
            showReactivate: false,
            brandId: '',
            selectRow: null,
            cardTitle: '',
            subscriptionDetail: null,
            loadingDetail: false,
        };
    },
    async created() {
        this.brandId = this.$route.params && this.$route.params.brandId || this.$store.getters["user/businessId"];
        await this.refreshData();
    },
    computed: {
        isAdmin() {
            return this.$store.getters["user/isAdmin"]
        },

    },
    methods: {
        async refreshData() {
            if (this.brandId) {
                this.subscriptions = await subscriptionService.getAllSubscriptions(this.brandId);
            }
        },
        addOrUpgradePlan() {
            this.$router.push({
                path: this.isAdmin
                    ? `/admin/brand/${this.$route.params.brandId}/training?tab=retailers&isAddRetailer=true`
                    : `/brand/training?tab=retailers&isAddRetailer=true`
            });
        },
        formatDate(date) {
            if (!date) return '';
            const month = date.getMonth() + 1; // Months are zero-indexed
            const day = date.getDate();
            const year = date.getFullYear();
            return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
        },
        showAction(item) {
            return item.subscriptionId != '';
        },
        adminCancel(item) {
            this.cancel(item);
        },
        adminExpired(item) {
            this.$refs.confirmDialog.openDialog$('Confirm Expiration', 'Cancel', 'Confirm').then(value => {
                if (value) {
                    this.expiredSubscription(item);
                }
            });
        },
        cancel(item) {
            this.$refs.cancelConfirm.openDialogBF$().then(value => {
                if (value) {
                    this.cancelSubscription(item);

                }
            });
        },
        resume(item) {
            console.log(item);
        },
        cancelSubscription(item) {
            return this.reqestHandler(subscriptionService.cancelSubscription(this.brandId, item.subscriptionId));
        },
        expiredSubscription(item) {
            return this.reqestHandler(subscriptionService.expiredBrandSubscription(this.brandId, item.subscriptionId));
        },
        reqestHandler(promise) {
            return promise
                .then(async () => await this.refreshData())
            //.catch(e => this.$refs.errorDialog.openModel$(e.data || e));
        },
        handleRowClick(record) {
            console.log(record);
        },
        customRow(record, index) {
            return {
                on: {
                    click: () => {
                        if (this.showAction(record)) {
                            this.onRowClick(record, index);
                        }
                    }
                }
            };
        },
        onRowClick(record) {
            this.selectRow = record;
            this.cardTitle = `${record.name} ${record.subscriptionTermName} (${this.formatDate(record.startDate)}-${this.formatDate(record.endDate)})`

            this.subscriptionDetail = null;
            this.loadingDetail = true;
            subscriptionService.getSubscriptionDetail(this.brandId, record.subscriptionId, this.formatDate(record.startDate), record.retailerId)
                .then(subscriptionDetail => {
                    this.subscriptionDetail = subscriptionDetail;
                }).finally(() => this.loadingDetail = false);
        }
    }
}
</script>
<style lang="scss">
.content {
    display: flex;
    flex-direction: column;

    .top {
        display: flex;
        justify-content: right;
        padding-bottom: 10px;
    }
}

.card {
    display: flex;
    flex-direction: column;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        div {
            display: flex;
            flex-direction: row;
            width: 100%;

            label {
                padding-left: 40px;
                font-weight: bold;
                flex: 1;
            }

            div {
                flex: 1;
            }
        }
    }

    .subscription-footer {
        text-align: center;
    }
}
</style>