<template>
  <a-modal
    v-model="isShow"
    width="600px"
    dialog-class="requestDemo"
    :footer="null"
    :closable="true"
  >
    <div class="guest_request_demo">
      <a-row class="guest_request_demo_header__title" style="margin-top: 40px"
        >Request A Demo</a-row
      >
      <a-row class="guest_request_demo_header__desc"
        >Complete the following details to get your demo!</a-row
      >
      <form
        id="email-form"
        name="email-form"
        data-name="Email Form"
        redirect="/thank-you-page"
        data-redirect="/thank-you-page"
        class="form-2"
        aria-label="Email Form"
        data-hs-cf-bound="true"
        _lpchecked="1"
        @submit.prevent="clickStartDemo"
      >
        <input
          id="email-3"
          v-model="email"
          type="email"
          class="text-field---rd w-input"
          maxlength="256"
          name="email-3"
          data-name="Email 3"
          placeholder="Email"
          required=""
        />
        <input
          id="name-2"
          v-model="name2"
          type="text"
          class="text-field---rd w-input"
          maxlength="256"
          name="name-2"
          data-name="Name 2"
          placeholder="Name"
        />
        <input
          id="Company-Title-2"
          v-model="companyTitle"
          type="text"
          class="text-field---rd w-input"
          maxlength="256"
          name="Company-Title-2"
          data-name="Company Title 2"
          placeholder="Company Title"
        />
        <input
          id="Company-2"
          v-model="company"
          type="text"
          class="text-field---rd w-input"
          maxlength="256"
          name="Company"
          data-name="Company"
          placeholder="Company"
        />
        <input
          id="Phone-2"
          v-model="phoneValue"
          type="tel"
          class="text-field---rd w-input"
          maxlength="256"
          name="Phone-2"
          data-name="Phone 2"
          placeholder="Phone"
        />
        <div class="flex-center">
          <input
            type="submit"
            value="start demo"
            data-wait="Please wait..."
            class="button rd-btn w-button"
          />
        </div>
      </form>
    </div>
  </a-modal>
</template>
<script>
import axios from "axios"
export default {
  data() {
    return {
      isShow: false,
      requestClient: undefined,
      email: undefined,
      name2: undefined,
      companyTitle: undefined,
      company: undefined,
      phoneValue: undefined
    }
  },
  created() {
    this.requestClient = axios.create({
      baseURL: "https://forms.hubspot.com",
      headers: { "Content-Type": "application/json" }
    })
  },
  methods: {
    openDialog() {
      this.isShow = true
    },
    clickStartDemo() {
      let param = {
        contactFields: {
          email: this.email,
          firstName: this.name2,
          phone: this.phoneValue
        },
        formSelectorClasses: ".form-2",
        formSelectorId: "#email-form",
        formValues: {
          "Company Title": this.companyTitle,
          Company: this.company
        },
        labelToNameMap: {
          "Company Title": "Company-Title-2",
          Company: "Company"
        },
        pageTitle: "Landing International | Helping Brands Launch and Scale at Retail",
        pageUrl: "https://www.landinginternational.com/",
        portalId: 21436754,
        type: "SCRAPED",
        utk: "3bfe1693f12886aef1a2d1192d380268",
        uuid: "f9b4d3b7-83e0-41b6-9b1e-7eecb138d82c",
        version: "collected-forms-embed-js-static-1.278",
        collectedFormId: "email-form",
        collectedFormClasses: "form-2"
      }
      console.log(param)
      this.requestClient.post("/collected-forms/submit/form", JSON.stringify(param)).then(() => {
        this.isShow = false
        this.$router.push({
          name: "thankyoupage"
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.guest_request_demo {
  font-family: niveau-grotesk, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  padding: 54px 24px;
  .guest_request_demo_header {
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    &__title {
      margin-bottom: 20px;
      opacity: 1;
      color: #000;
      font-size: 25px;
      line-height: 1.2;
      font-weight: 400;
      letter-spacing: 0.02em;
      text-align: center;
    }
    &__desc {
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.text-field---rd {
  height: 55px;
  padding-left: 25px !important;
  border-style: solid;
  border-width: 1px;
  border-color: #f15a42;
  border-radius: 50px;
  color: #000;
}
.w-input,
.w-select {
  display: block;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #ffffff;
}
.flex-center {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.button.rd-btn {
  min-height: 48px;
  min-width: 153px;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  padding-right: 35px;
  padding-left: 35px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #f15a42;
  border-radius: 50px;
  background-color: #f15a42;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0em;
  color: #fff;
}
input.w-button {
  -webkit-appearance: button;
}
</style>
