var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"width":_vm.modalWidth,"footer":null,"title":null,"mask":!_vm.isSmallStatus,"closable":false,"body-style":_vm.bodyStyle,"mask-closable":false,"dialog-style":_vm.isSmallStatus ? { bottom: '100px', right: '-364px' } : { top: '10px' },"dialog-class":"admin-message-brand-create-new-modal"},on:{"cancel":_vm.hideDialog},model:{value:(_vm.showCreateMessage),callback:function ($$v) {_vm.showCreateMessage=$$v},expression:"showCreateMessage"}},[_c('div',{class:{ 'admin-message-brand-create-new-modal__outer': !_vm.isSmallStatus }},[_c('div',{staticStyle:{"margin":"0 auto"},style:(!_vm.isSmallStatus ? 'width: 952px;' : 'width: 100%;')},[(!_vm.isSmallStatus)?_c('a-row',{staticStyle:{"margin-bottom":"20px"}},[_c('a-col',{staticClass:"admin-message-brand-create-new-modal__title",attrs:{"span":12}},[_vm._v(" Messaging ")]),_c('a-col',{staticStyle:{"text-align":"right"},attrs:{"span":12}},[_c('a-icon',{staticClass:"admin-message-brand-create-new-modal__large-icon",staticStyle:{"margin-right":"12px"},attrs:{"type":"shrink"},on:{"click":_vm.clickToSwitch}}),_c('a-icon',{staticClass:"admin-message-brand-create-new-modal__large-icon",attrs:{"type":"close"},on:{"click":_vm.hideDialog}})],1)],1):_vm._e(),_c('div',{staticClass:"admin-message-brand-create-new-modal__box-content"},[_c('a-row',{staticClass:"admin-message-brand-create-new-modal__choose-model-layer",style:(_vm.isSmallStatus
              ? 'padding-left: 14px; padding-right: 10px; height: 42px; padding-top: 6px; padding-bottom: 6px;'
              : 'padding-left: 30px; padding-left: 20px; height: 40px; padding-top: 6px; padding-bottom: 6px;')},[_c('a-col',{staticClass:"admin-message-brand-create-new-modal__subject",style:(_vm.isSmallStatus ? 'width: 255px;' : 'width: 702px;'),attrs:{"span":1}},[_vm._v(" To: ")]),_c('a-col',{attrs:{"span":18}},[_c('a-select',{class:_vm.isToError
                  ? 'admin-message-brand-create-new-modal__toTarget admin-message-brand-to-error'
                  : 'admin-message-brand-create-new-modal__toTarget',staticStyle:{"width":"200px"},attrs:{"show-search":"","filter-option":false},on:{"search":_vm.handleSearch,"change":_vm.onSelectToTarget},model:{value:(_vm.toValue),callback:function ($$v) {_vm.toValue=$$v},expression:"toValue"}},_vm._l((_vm.dataSource),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),(_vm.isSmallStatus)?_c('div',{staticStyle:{"float":"right","display":"inline-block","margin-top":"4px"}},[_c('a-icon',{staticClass:"admin-message-brand-create-new-modal__large-icon",staticStyle:{"margin-right":"8px"},attrs:{"type":"arrows-alt"},on:{"click":_vm.clickToSwitch}}),_c('a-icon',{staticClass:"admin-message-brand-create-new-modal__large-icon",attrs:{"type":"close"},on:{"click":_vm.hideDialog}})],1):_vm._e()],1),_c('a-row',{staticClass:"admin-message-brand-create-new-modal__choose-model-layer",style:(_vm.isSmallStatus
              ? 'padding-left: 14px; padding-right: 10px; height: 42px; padding-top: 6px; padding-bottom: 6px;'
              : 'padding-left: 30px; padding-left: 20px; height: 40px; padding-top: 6px; padding-bottom: 6px;')},[_c('span',{staticClass:"admin-message-brand-create-new-modal__subject",style:(_vm.isSmallStatus ? 'width: 317px;' : 'width: 702px;')},[_vm._v(" Subject: "),_c('a-input',{staticStyle:{"width":"160px"},style:(_vm.inputError ? 'border: 1px solid red' : ''),attrs:{"size":"small"},model:{value:(_vm.subjectText),callback:function ($$v) {_vm.subjectText=$$v},expression:"subjectText"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.inputError)+" ")])],1),_c('a-select',{staticStyle:{"width":"190px","right":"20px"},attrs:{"dropdown-class-name":"admin-message-brand-create-new-modal__select-dropdown"},on:{"change":_vm.handleChange},model:{value:(_vm.selectCategory),callback:function ($$v) {_vm.selectCategory=$$v},expression:"selectCategory"}},_vm._l((_vm.selectOption),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.description)+" ")])}),1)],1),_c('a-row',{staticClass:"admin-message-brand-create-new-modal__choose-model-layer",style:(_vm.isSmallStatus ? 'height: 270px;' : 'height: 83px;')}),_c('a-row',{staticClass:"admin-message-brand-create-new-modal__choose-model-layer",style:(_vm.isSmallStatus ? 'min-height: 138px;' : 'min-height: 165px;')},[_c('message-input-component',{attrs:{"receivers-data":_vm.brands,"from":_vm.retailer,"generate-content":_vm.contentType},on:{"send":_vm.sendMessage,"sendDraft":_vm.sendDraft,"hideDialog":_vm.hideDialog}})],1)],1)],1),_c('warn-message-dialog',{ref:"warningDialog"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }