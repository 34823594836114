<template>
  <div>
    <div class="console-cascade-content">
      <ld-header
        :is-loading="isLoading"
        :title="data.title"
        :is-editable="data.isEditable"
        :is-focus="isFocus"
        :class="{ 'last-level-panel': !data.next }"
        @open-all-items-dialog="openAllItemsDialog"
        @save-all-items="saveAllItems"
        @cancel-save-all-items="cancelSaveAllItems"
      >
        <a-spin v-if="isLoading" />
        <div v-if="data.next" class="row">
          <a-collapse :bordered="false" :accordion="true">
            <a-collapse-panel
              v-for="(item, itemIndex) in items"
              :key="getKey(item)"
              :show-arrow="false"
            >
              <template #header="props">
                <ld-chip
                  ref="items"
                  :is-editable="props.isEditable"
                  :index="itemIndex"
                  :item="item"
                  :items="items"
                  :get-display-fn="data.getDisplayFn"
                  :show-expansion-icon="true"
                  :is-expanded="props.isActive"
                  :display-value="getItemText(item)"
                  @on-change="onUpdateItem"
                  @open-confirm-dialog="confirmEditOpen"
                ></ld-chip>
              </template>
              <div :class="[getContentStyle()]">
                <ld-cascade-content
                  :data="data.next"
                  :parent-item="item"
                  :parent-data="data"
                ></ld-cascade-content>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div v-else class="f-col">
          <div class="f-row f-wrap pad">
            <div v-for="(item, itemIndex) in items" :key="getKey(item)">
              <ld-chip
                ref="items"
                :is-editable="data.isEditable"
                :index="itemIndex"
                :item="item"
                :items="items"
                :get-display-fn="data.getDisplayFn"
                :display-value="data.getDisplayFn(item)"
                @on-change="onUpdateItem"
                @open-confirm-dialog="confirmEditOpen"
              ></ld-chip>
            </div>
          </div>
        </div>
      </ld-header>
    </div>
    <ConfirmDialog
      ref="confirmEdit"
      @doConfirm="confirmEdit"
      @cancelDialog="confirmEditClose"
    />

    <save-all-item-dialog
      ref="save-all-dialog"
      :get-key-fn="data.getKeyFn"
      :get-display-fn="data.getDisplayFn"
      :set-item-fn="data.setItemFn"
      @save-all-items="saveAllItems"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/admin/ConfirmDialog";
import ConsoleHeader from "@/components/admin/ConsoleHeader";
import ConsoleChip from "@/components/admin/ConsoleChip";
import ConsoleCascadeContentItem from "@/models/admin/ConsoleCascadeContentItem";
import SaveAllItemDialog from "@/components/admin/SaveAllItemDialog";
export default {
  name: "LdCascadeContent",
  components: {
    ConfirmDialog,
    "ld-header": ConsoleHeader,
    "ld-chip": ConsoleChip,
    "save-all-item-dialog": SaveAllItemDialog,
  },
  props: {
    parentItem: { default: null },
    parentData: { type: ConsoleCascadeContentItem, default: null },
    isFocus: { type: Boolean, default: false },

    data: { type: ConsoleCascadeContentItem, required: true },
  },
  data() {
    return {};
  },
  computed: {
    items() {
      return this.data.computed.items(this.$store, this.getParentKey());
    },
    isLoading() {
      return this.data.computed.isLoading(this.$store, this.getParentKey());
    },
  },
  async created() {
    if (this.data.computed.fetchData != void 0) {
      await this.data.computed.fetchData(this.$store, this.getParentKey());
    }
  },
  methods: {
    getKey(item) {
      return this.data.getKeyFn(item);
    },
    getParentKey() {
      return (
        (this.parentItem && this.parentData.getKeyFn(this.parentItem)) || null
      );
    },
    getItemText(item) {
      return this.data.getDisplayFn(item);
    },
    confirmEditOpen(data) {
      // this.$refs["confirmEdit"].openDialogMsg(
      //   "Are you sure you want to save changes?",
      //   editItem
      // )
      this.confirmOpen("Are you sure you want to save changes?", {
        type: "edit-item",
        data,
      });
    },
    confirmDeleteOpen(data) {
      this.confirmOpen("Are you sure you want to delete these?", {
        type: "delete-all",
        data,
      });
    },
    confirmOpen(message, data) {
      this.$refs["confirmEdit"].openDialogMsg(message, data);
    },
    getConfirmEditItem(param) {
      return this.$refs.items.find((i) => i._props.index == param.data);
    },
    confirmEdit(param) {
      if (param.type === "edit-item") {
        const refItem = this.getConfirmEditItem(param);
        refItem.confirmEdit();
      } else if (param.type === "delete-all") {
        //this.data.computed.updateItems(this.$store, param.data);
        this.updateAllItems(param.data);
      }
    },
    confirmEditClose(param) {
      if (param.type === "edit-item") {
        const refItem = this.getConfirmEditItem(param);
        refItem.confirmCancel();
      }
    },
    openAllItemsDialog(value) {
      this.$refs["save-all-dialog"].openDialog({
        type: value,
        parentItem: this.parentItem,
        parentData: this.parentData,
        title:
          (this.parentItem && this.parentData.getDisplayFn(this.parentItem)) ||
          this.data.addTitle,
        items: this.items,
      });
    },
    async saveAllItems(value) {
      if (value.type === 3) {
        this.confirmDeleteOpen(value);
      } else {
        await this.updateAllItems(value);
      }
    },
    async updateAllItems(value) {
      const saveAllDialog = this.$refs["save-all-dialog"];
      saveAllDialog.setLoading(true);
      return await this.data.computed
        .updateItems(this.$store, value)
        .then(() => {
          saveAllDialog.close();
        })
        .catch((err) => {
          saveAllDialog.setLoading(false);
          this.$message.error(err.message || err);
        });
    },
    onUpdateItem(editItem) {
      // const data = [...this.items]
      const data = [];
      const parentKey = this.getParentKey();
      // this.data.setItemFn(editItem.newValue, parentKey, data[editItem.index])
      const compareItem = this.items[editItem.index];
      if (this.data.getDisplayFn(compareItem) !== editItem.newValue) {
        const cloneItem = compareItem.clone();
        this.data.setItemFn(editItem.newValue, parentKey, cloneItem);
        data.push(cloneItem);
      }

      this.saveAllItems({
        type: 2,
        data,
        parentKey,
      });
    },
    cancelSaveAllItems() {},
    getContentStyle() {
      return "";
    },
  },
};
</script>
<style lang="scss">
.console-cascade-content {
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  .ant-collapse {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;

    > .ant-collapse-item {
      border-bottom: none;
    }
  }

  .content {
    padding-top: 12px;
    padding-left: 26px;
    padding-bottom: 8px;
  }
  .content-border {
    border: 1px solid #4a8a5d;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: 12px;
    padding-left: 26px;
    padding-bottom: 8px;
  }
  .last-level-panel {
    background: #e2eeec;
    padding: 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 10px;
  }
}
.item-btn {
  background: #fff;
  border: 1px solid #4a8a5d;
  border-radius: 2px;
  padding: 6px 8px;
  color: #4a8a5d;
  > *:not(:last-child) {
    padding-right: 4px;
  }
}
.f-col {
  display: flex;
  flex-direction: column;
}
.f-row {
  display: flex;
  flex-direction: row;
}
.single-row {
  width: 100%;
}
.f-wrap {
  flex-wrap: wrap;
}
.pad {
  .chip-panel {
    > * {
      margin-bottom: 14px;
      margin-right: 14px;
    }
  }
}
.save-all-items-dialog .ant-modal-footer .ant-btn-primary {
  border-color: #4a8a5d;
  background: #4a8a5d;
}
</style>
