import orderClient from "@/api/order-client"
import constants from "@/config.js"

export default {
  namespaced: true,

  state: {
    order: { comments: [] },
    orders: [],
    orderBatchId: null,
    uploadResult: {},
    paginationInfo: {}
  },

  mutations: {
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    SET_ORDER_BATCH_ID(state, id) {
      state.orderBatchId = id
    },
    SET_ORDER(state, order) {
      state.order = order
    },
    SET_ORDER_COMMENTS(state, orderComments) {
      state.order.comments = orderComments
    },
    PUSH_ORDER_COMMENT(state, comment) {
      state.order.comments = state.order.comments || []
      state.order.comments.push(comment)
    },
    SET_ORDERS_BATCH(state, data) {
      state.orders = data.orders
    },
    SET_UPLOAD_RESULT(state, data) {
      state.uploadResult = data
    },
    SET_ORDER_PAGINATION(state, data) {
      const info = JSON.parse(JSON.stringify(data))
      delete info.orders
      state.paginationInfo = info
    }
  },

  getters: {
    find: state => id => {
      return state.orders.find(order => order.id === id)
    },
    orderBatchId(state) {
      return state.orderBatchId
    },
    orders(state) {
      state.orders = state.orders.map(item => {
        item.checkedActive = false
        return item
      })
      return state.orders
    },
    order(state) {
      if (state.order && state.order.lifecycle) {
        state.order.lifecycle.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
        const list = state.order.lifecycle.map(item => {
          let newObj = {}
          if (
            item.status.id === constants.orderStatus.pending ||
            item.status.id === constants.orderStatus.confirmed
          ) {
            newObj = { ...item }
          } else if (
            item.status.id === constants.orderStatus.supplyShipped ||
            item.status.id === constants.orderStatus.warehouse ||
            item.status.id === constants.orderStatus.deliverShipped
          ) {
            newObj.complete = item.complete ? true : false
            newObj.sequence = 3
            newObj.status = {
              id: constants.orderStatus.shipped,
              name: "Shipped"
            }
          } else if (item.status.id === constants.orderStatus.delivered) {
            newObj.complete = item.complete
            newObj.sequence = 4
            newObj.status = {
              id: constants.orderStatus.invoiced,
              name: "Invoiced"
            }
          } else {
            newObj = { ...item }
            newObj.sequence = item.status.id === constants.orderStatus.paidByRetailer ? 5 : 6
          }
          return newObj
        })
        const shippedList = list.filter(item => item.sequence === 3).slice(0, 1)
        const otherStatusList = list.filter(item => item.sequence !== 3)
        state.order.newFeLifecycle = shippedList
          .concat(otherStatusList)
          .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      }
      return state.order
    },
    pagination(state) {
      return state.paginationInfo
    }
  },

  actions: {
    async create({ commit, dispatch }, { order, params = {}, brandId }) {
      try {
        const { data } = await orderClient.createOrder(order, params, brandId)
        dispatch("retailer/fetchCart", params, { root: true })

        commit("SET_ORDER_BATCH_ID", data.id)
        commit("SET_ORDERS", data.orders)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateStatus({ commit }, { order, params }) {
      try {
        const { data } = await orderClient.updateOrderStatus(order, params)
        commit("SET_ORDER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async pushOrderComment({ commit }, { orderId, comment, params }) {
      try {
        commit("PUSH_ORDER_COMMENT", comment)
        await orderClient.pushOrderComment(orderId, comment, params)
      } catch (error) {
        commit("SET_ORDER_COMMENTS", [])
        return Promise.reject(error)
      }
    },
    async fetchOrdersByParams({ commit }, { params }) {
      try {
        const { data } = await orderClient.getOrdersByParams(params)
        commit("SET_ORDERS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async featOrderListWithPage({ commit }, { params }) {
      let list
      try {
        const { data } = await orderClient.getOrderListWithPage(params)
        if (params.status && params.status.includes("pending")) {
          list = data.orders.filter(item => !item.declined)
        } else {
          list = data.orders
        }
        commit("SET_ORDERS", list)
        commit("SET_ORDER_PAGINATION", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async downloadOrderList({}, { params }) {
      let list
      try {
        const { data } = await orderClient.getOrderListWithPage(params)
        if (params.status && params.status.includes("pending")) {
          list = data.orders.filter(item => !item.declined)
        } else {
          list = data.orders
        }
        return list
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getOrders({ commit }, params = {}) {
      try {
        const { data } = await orderClient.getOrders(params)
        commit("SET_ORDERS", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getOrder({ commit }, orderId) {
      try {
        const { data } = await orderClient.getOrder(orderId)
        commit("SET_ORDER", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getOrdersByBatch({ commit }, { batchId, params }) {
      try {
        const { data } = await orderClient.getOrderByBatch(batchId, params)
        commit("SET_ORDERS_BATCH", data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async uploadOrder({ commit }, params) {
      try {
        const { data } = await orderClient.uploadOrder(params)
        commit("SET_UPLOAD_RESULT", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async uploadShipOrder({ commit }, params) {
      try {
        const { data } = await orderClient.updateShippingDrder(params)
        commit("SET_UPLOAD_RESULT", data)
        return data
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async downloadOrderExcel({ commit }, { params }) {
      try {
        console.log(commit)
        const { data } = await orderClient.orderDownload(params)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async downloadOrderPacklist({ commit }, orderId) {
      try {
        console.log(commit)
        const data = await orderClient.dowloadPacklistPdf(orderId)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async downloadOrderInvoice({ commit }, orderId) {
      try {
        console.log(commit)
        const data = await orderClient.dowloadInvoicePdf(orderId)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async downloadOrderPayAdvice({ commit }, orderId) {
      try {
        console.log(commit)
        const data = await orderClient.dowloadPayAdvicePdf(orderId)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async orderToPdf({ commit }, orderId) {
      try {
        console.log(commit)
        const data = await orderClient.dowloadOrderToPdf(orderId)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async downloadOrderEstimatePayment({ commit }, orderId) {
      try {
        console.log(commit)
        const data = await orderClient.downloadEstimatePaymentPdf(orderId)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }
}
