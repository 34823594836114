<template>
  <a-row class="admin-retailer-edit">
    <a-col :span="4" style="padding-top: 190px;">
      <a-anchor @change="linkChange">
        <a-anchor-link href="#channelType" title="Channel Type" />
        <a-anchor-link href="#locationType" title="Location Type" />
        <a-anchor-link href="#otherType" title="Other Type" />
      </a-anchor>
    </a-col>
    <a-col :span="20" style="padding-left: 94px; padding-top: 71px;">
      <div class="admin-retailer-edit__title">
        Edit/Add Retailer Categories
      </div>
      <div id="channelType" style="width: 100%; margin-bottom: 43px;">
        <ConsoleAdd
          title="Channel Type"
          :items="channelTypeList"
          :is-focus="isChannelFocus"
          @removeItem="removeAllChannelType"
          @editAll="editAllChannelType"
          @addMultiple="addMultipleChannelType"
          @editItem="editItemChannelType"
          @addNewItem="addNewItemChannelType"
        />
      </div>
      <div id="locationType" style="width: 100%; margin-bottom: 43px;">
        <ConsoleAdd
          title="Location Type"
          :items="locationTypeList"
          :is-focus="isLocationFocus"
          @removeItem="removeAllLocationType"
          @editAll="editAllLocationType"
          @addMultiple="addMultipleLocationType"
          @editItem="editItemLocationType"
          @addNewItem="addNewItemLocationType"
        />
      </div>
      <div id="otherType" style="width: 100%; margin-bottom: 43px;">
        <ConsoleAdd
          title="Other Type"
          :items="otherTypeList"
          :is-focus="isOtherFocus"
          @removeItem="removeAllOtherType"
          @editAll="editAllOtherType"
          @addMultiple="addMultipleOtherType"
          @editItem="editItemOtherType"
          @addNewItem="addNewItemOtherType"
        />
      </div>
    </a-col>
  </a-row>
</template>

<script>
import ConsoleAdd from "@/components/admin/ConsoleAdd"

export default {
  components: {
    ConsoleAdd
  },
  data() {
    return {
      isLoading: false,
      isChannelFocus: false,
      isLocationFocus: false,
      isOtherFocus: false,
      type: -1,
      isAdd: false,
      channelTypeList: undefined,
      otherTypeList: undefined,
      locationTypeList: undefined
    }
  },
  created() {
    this.getReference()
  },
  methods: {
    sortData(data, modifyData) {
      if (!this.isAdd) {
        modifyData = data.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        for (let i = modifyData.length; i < data.length; i++) {
          modifyData.push(data[i])
        }
        this.isAdd = false
      }
      return modifyData
    },
    async getReference() {
      await this.$store.dispatch("reference/getRetailerConsole")
      if (this.type === -1) {
        this.getChannelTypeList()
        this.getOtherTypeList()
        this.getLocationTypeList()
      } else if (this.type === 1) {
        this.getChannelTypeList()
      } else if (this.type === 2) {
        this.getOtherTypeList()
      } else if (this.type === 3) {
        this.getLocationTypeList()
      }
    },
    getChannelTypeList() {
      let data = this.$store.getters["reference/retailerConsole"].channelTypes
      this.channelTypeList = this.sortData(data, this.channelTypeList)
    },
    getOtherTypeList() {
      let data = this.$store.getters["reference/retailerConsole"].otherTypes
      this.otherTypeList = this.sortData(data, this.otherTypeList)
    },
    getLocationTypeList() {
      let data = this.$store.getters["reference/retailerConsole"].locationTypes
      this.locationTypeList = this.sortData(data, this.locationTypeList)
    },
    addMultipleChannelType(datas) {
      this.isAdd = true
      this.updateChannelType(this.getChannelTypeParam(datas))
    },
    editAllChannelType(datas) {
      this.updateChannelType(this.getChannelTypeParam(datas))
    },
    removeAllChannelType(datas) {
      this.updateChannelType(this.getChannelTypeParam(datas))
    },
    editItemChannelType(item) {
      this.updateChannelType(this.getChannelTypeParam([item]))
    },
    addNewItemChannelType(item) {
      this.isAdd = true
      this.updateChannelType(this.getChannelTypeParam([item]))
    },
    addMultipleOtherType(datas) {
      this.isAdd = true
      this.updateOtherType(this.getOtherTypeParam(datas))
    },
    editAllOtherType(datas) {
      this.updateOtherType(this.getOtherTypeParam(datas))
    },
    removeAllOtherType(datas) {
      this.updateOtherType(this.getOtherTypeParam(datas))
    },
    editItemOtherType(item) {
      this.updateOtherType(this.getOtherTypeParam([item]))
    },
    addNewItemOtherType(item) {
      this.isAdd = true
      this.updateOtherType(this.getOtherTypeParam([item]))
    },

    addMultipleLocationType(datas) {
      this.isAdd = true
      this.updateLocationType(this.getLocationTypeParam(datas))
    },
    editAllLocationType(datas) {
      this.updateLocationType(this.getLocationTypeParam(datas))
    },
    removeAllLocationType(datas) {
      this.updateLocationType(this.getLocationTypeParam(datas))
    },
    editItemLocationType(item) {
      this.updateLocationType(this.getLocationTypeParam([item]))
    },
    addNewItemLocationType(item) {
      this.isAdd = true
      this.updateLocationType(this.getLocationTypeParam([item]))
    },

    updateChannelType(param) {
      this.type = 1
      this.$store.dispatch("reference/updateChannelType", param).then(() => {
        this.getReference()
      })
    },
    getChannelTypeParam(data) {
      return { channelTypes: data }
    },
    async updateOtherType(param) {
      this.type = 2
      await this.$store.dispatch("reference/updateOtherType", param)
      this.getReference()
    },
    getOtherTypeParam(data) {
      return { otherTypes: data }
    },
    async updateLocationType(param) {
      this.type = 3
      await this.$store.dispatch("reference/updateLocationType", param)
      this.getReference()
    },
    getLocationTypeParam(data) {
      return { locationTypes: data }
    },
    linkChange(link) {
      if (link === "#channelType") {
        this.isChannelFocus = true
        this.isLocationFocus = false
        this.isOtherFocus = false
      } else if (link === "#locationType") {
        this.isChannelFocus = false
        this.isLocationFocus = true
        this.isOtherFocus = false
      } else if (link === "#otherType") {
        this.isChannelFocus = false
        this.isLocationFocus = false
        this.isOtherFocus = true
      }
    }
  }
}
</script>

<style lang="scss">
.admin-retailer-edit {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  &__title {
    font-family: Playfair Display;
    font-size: 23px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 83px;
  }
}
.admin-retailer-edit .ant-anchor-ink {
  height: 0%;
}
.admin-retailer-edit .ant-anchor-ink-ball.visible {
  display: none;
}
.admin-retailer-edit .ant-anchor-link {
  padding: 12px 24px;
  font-size: 18px;
  line-height: 22px;
  // border: 1px solid #d9d9d9;
  letter-spacing: 0.13125px;
  color: #262626;
  border-bottom: none;
  text-transform: uppercase;
}
.admin-retailer-edit .ant-anchor-link-title {
  text-transform: uppercase;
}
.admin-retailer-edit .ant-anchor-link-active > .ant-anchor-link-title {
  color: #ff6132;
  text-transform: uppercase;
}
</style>
