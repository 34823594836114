import CoreEntity from "@/models/CoreEntity";
export default class Ingredient extends CoreEntity {
  constructor(model) {
    super(model);
    this.name = "";
    this.sort = 0;
    if (model) {
      this.name = model.name || "";
    }
  }

  toModel() {
    const data = super.toModel();
    data.name = this.name;
    data.sort = this.sort;
    return data;
  }
}
