import CoreEntity from "@/models/CoreEntity";
export class Client extends CoreEntity {
  constructor(model) {
    super(model);
    this.typePrivateId = null;
    this.name = "";
    this.shortName = "";
    this.statusPrivateId = null;
    if (model) {
      this.typePrivateId = model.typePrivateId;
      this.name = model.name;
      this.shortName = model.shortName;
      this.statusPrivateId = model.statusPrivateId;
    }
  }
}

export class Retailer extends Client {
  constructor(model) {
    super(model);
  }
}

export var ClientType;
(function(ClientType) {
  ClientType[(ClientType["Landing"] = 1)] = "Landing";
  ClientType[(ClientType["Parent"] = 2)] = "Parent";
  ClientType[(ClientType["Brand"] = 3)] = "Brand";
  ClientType[(ClientType["Retailer"] = 4)] = "Retailer";
  ClientType[(ClientType["Seller"] = 5)] = "Seller";
})(ClientType || (ClientType = {}));

export var ClientStatusEnum;
(function(ClientStatusEnum) {
  ClientStatusEnum[(ClientStatusEnum["Floating"] = 1)] = "Floating";
  ClientStatusEnum[(ClientStatusEnum["Pending"] = 2)] = "Pending";
  ClientStatusEnum[(ClientStatusEnum["Approved"] = 3)] = "Approved";
  ClientStatusEnum[(ClientStatusEnum["Disabled"] = 4)] = "Disabled";
  ClientStatusEnum[(ClientStatusEnum["Rejected"] = 5)] = "Rejected";
  ClientStatusEnum[(ClientStatusEnum["Prospective"] = 6)] = "Prospective";
  ClientStatusEnum[(ClientStatusEnum["Preloaded"] = 7)] = "Preloaded";
})(ClientStatusEnum || (ClientStatusEnum = {}));
