<template lang="pug">
.brand-main-page.pending-retailer
  BrandBanners
  BrandMiddleBox(:textInfo="exploreList" :typeNumber="1")
  //BrandNewRetailers
  BrandMiddleBox(:textInfo="learningList" :typeNumber="2")
  BrandBottomBanner
  TrainingInfoDialog(ref="joinBFDialogRef" @clickBtn="toRegisterPage")
  //TrialDayDialog(ref="TrialDayDialog")
  //membershipExpiringDialog(ref="membershipExpiringDialog" @addCardList="openAddMoreCardDialog")
  //addNewCardDialog(ref="addNewCardDialog" @openAddMoreCardDialog="openAddMoreCardDialog")
  //cardListDialog(ref="cardListDialog" @openAddPaymentDialog="openAddPaymentDialog")
    
</template>

<script>
import BrandBanners from "@/components/brands/mainPage/BrandBanner"
import BrandMiddleBox from "@/components/brands/mainPage/BrandMiddleBox"
import BrandNewRetailers from "@/components/brands/mainPage/BrandNewRetailers"
import BrandBottomBanner from "@/components/brands/mainPage/BrandBottomBanner"
import constants from "@/config.js"
// import BRBannerButtom from "@/assets/img/brand_and_retailer_new_homepage/B&R_banner_buttom.png"
import BRExploreOne from "@/assets/img/brand_and_retailer_new_homepage/B&R_explore_one.png"
import BRExploreTwo from "@/assets/img/brand_and_retailer_new_homepage/B&R_explore_two.png"
import BExploreThree from "@/assets/img/brand_and_retailer_new_homepage/Brand_explore_three.png"
import BLearningOne from "@/assets/img/brand_and_retailer_new_homepage/Brand_learning_one.png"
import BLearningTwo from "@/assets/img/brand_and_retailer_new_homepage/Brand_learning_two.png"
import BLearningThree from "@/assets/img/brand_and_retailer_new_homepage/Brand_learning_three.png"
import RExploreThree from "@/assets/img/brand_and_retailer_new_homepage/Retailer_explore_three.png"
import RLearningOne from "@/assets/img/brand_and_retailer_new_homepage/Retailer_learning_one.png"
import RLearningTwo from "@/assets/img/brand_and_retailer_new_homepage/Retailer_learning_two.png"
import RLearningThree from "@/assets/img/brand_and_retailer_new_homepage/Retailer_learning_three.png"
// import TrialDayDialog from "@/components/webflowComponent/TrialDayDialog"
import membershipExpiringDialog from "@/components/webflowComponent/MembershipExpiringDialog"
// import addNewCardDialog from "@/components/brands/settings/addPaymentCardDialog"
// import cardListDialog from "@/components/brands/settings/addMoreCardDialog"
import TrainingInfoDialog from "@/components/training/TrainingInfoDialog"

export default {
  components: {
    BrandBanners,
    BrandMiddleBox,
    BrandNewRetailers,
    BrandBottomBanner,
    TrainingInfoDialog,
    // TrialDayDialog,
    membershipExpiringDialog
    // addNewCardDialog,
    // cardListDialog
  },
  data() {
    return {}
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    },
    exploreList() {
      let list
      if (this.isBrand) {
        const textList = this.$t("Components.Brands.mainPage.brandMiddleBoxCard")
        const imgList = [{ img: BRExploreOne }, { img: BRExploreTwo }, { img: BExploreThree }]
        list = textList.map((item, index) => {
          return { ...item, ...imgList[index] }
        })
      }
      if (this.isRetailer) {
        const textList = this.$t("Components.Brands.mainPage.retailerMiddleBoxCard")
        const imgList = [{ img: BRExploreOne }, { img: BRExploreTwo }, { img: RExploreThree }]
        list = textList.map((item, index) => {
          return { ...item, ...imgList[index] }
        })
      }
      return list
    },
    learningList() {
      let list
      if (this.isBrand) {
        const textList = this.$t("Components.Brands.mainPage.brandMiddleBoxCardList")
        const imgList = [{ img: BLearningOne }, { img: BLearningTwo }, { img: BLearningThree }]
        list = textList.map((item, index) => {
          return { ...item, ...imgList[index] }
        })
      }
      if (this.isRetailer) {
        const textList = this.$t("Components.Brands.mainPage.retailerMiddleBoxCardList")
        const imgList = [{ img: RLearningOne }, { img: RLearningTwo }, { img: RLearningThree }]
        list = textList.map((item, index) => {
          return { ...item, ...imgList[index] }
        })
      }
      return list
    }
  },
  async mounted() {
    // const brandId = this.$store.getters["user/businessId"]
    // await this.$store.dispatch("brand/getBrand", brandId)
    // this.isMemberRemoved()
    // this.checkBrandPlanType()
    // let retailerIds = constants.retailerIds
    // this.$store.dispatch("retailer/fetchretailersByIds", { retailerIds })
    // if (
    //   this.brand &&
    //   this.brand.subscriptions &&
    //   this.brand.subscriptions.length !== 0 &&
    //   this.checkTrialIsEnd(this.brand) &&
    //   this.$route.query.isLogin
    // ) {
    //   const day = this.checkTrialDay(this.brand)
    //   this.$refs["TrialDayDialog"].openTrialDialog(day)
    // } else if (this.brandType === "basic" && this.isBrand) {
    //   this.$refs["membershipExpiringDialog"].openDialog()
    // }
    let isShowJoinBF = this.$route.params ? this.$route.params.isShowJoinBF : false
    if (isShowJoinBF) {
      this.showJoinBFDialog()
    }
  },
  methods: {
    // openAddMoreCardDialog() {
    //   const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
    //   this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    // },
    // openAddPaymentDialog() {
    //   this.$refs["addNewCardDialog"].openAddCardDialog()
    // },
    checkTrialDay(brandInfo) {
      let time
      if (brandInfo && brandInfo.subscriptions && this.role === "brand") {
        const myDate = new Date(new Date().getTime() + 8 * 60 * 60 * 1000)
        const list = myDate
          .toJSON()
          .split("T")
          .slice(0, 10)
        time = list[0]
        return this.getDaysBetween(time, brandInfo.subscriptions[0].endDate)
      }
    },
    checkTrialIsEnd(brandInfo) {
      let time
      if (brandInfo && brandInfo.subscriptions && this.role === "brand") {
        const myDate = new Date(new Date().getTime() + 8 * 60 * 60 * 1000)
        if (!brandInfo.subscriptions[0].startDate) {
          time = myDate
            .toJSON()
            .split("T")
            .join(" ")
        } else {
          time = brandInfo.subscriptions[0].startDate
        }
        const timeValue = this.getDaysBetween(time, brandInfo.subscriptions[0].endDate)
        return brandInfo.subscriptions[0].type.id === "trial" && timeValue !== 0
      } else {
        return false
      }
    },
    getDaysBetween(date1, date2) {
      const startDate = Date.parse(date1)
      const endDate = Date.parse(date2)
      if (startDate > endDate) {
        return 0
      }
      if (startDate === endDate) {
        return 1
      }
      return Math.floor((endDate - startDate) / (1 * 24 * 60 * 60 * 1000))
    },
    async checkBrandPlanType() {
      if (this.brandPlanType && this.brandPlanType == "basic") {
        if (this.$route.params.brandId) {
          this.$router.push({
            name: "adminBrandProfile",
            params: {
              brandId: this.$route.params.brandId
            }
          })
        } else {
          this.$router.push({ name: "brandProfile" })
        }
      } else {
        this.showPromoPage = true
      }
    },
    async isMemberRemoved() {
      for (var a in this.brand.members) {
        if (this.brand.members[a].user.id == this.userId) {
          if (this.brand.members[a].membershipStatus.id == "removed") {
            this.$router.push({ name: "memberProfile" })
          }
        }
      }
    },
    logoImage(retailer) {
      return retailer.logoAsset && retailer.logoAsset.url
    },
    toRegisterPage() {
      this.$router.push({
        name: "BrandBeautyFluentRegistration"
      })
    },
    showJoinBFDialog() {
      this.$refs["joinBFDialogRef"].openDialog(
        "",
        "Thank you for already being a Landing International subscriber! If you’d like to sign up for a BeautyFluent plan please use the button below.",
        "Join Beautyfluent"
      )
    }
  }
}
</script>

<style lang="sass">
.carousael-description-brand
  width: 100%
.ant-carousel .slick-dots li
  margin: 0 7px !important
</style>
