<template lang="pug">
.retailers-filter-button(@click="clickedItem", :class="{'selected': isSelected == true, 'pleaseHide': id == 4 }" )
    .icon(v-if="!isSelected")
      img(:src="require(`@/assets/img/icon/${iconName}`)")
    .icon(v-else)
      img(:src="require(`@/assets/img/icon/${iconOnName}`)")
    .name {{ title }}
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    id: { type: String, default: "" },
    iconName: { type: String, defualt: "clear-icon.svg" },
    iconOnName: { type: String, defualt: "clear-icon.svg" }
    // items: { type: Array, default: () => [{name: "null", id: 1234, checked: false}] }
  },
  data() {
    return {
      isOpen: false,
      isExtended: false,
      option: "All",
      isDropDownOpen: false,
      hasCheckedItems: false,
      titleAllRetailers: this.title,
      isSelected: false
    }
  },
  computed: {
    countryAndTagFilters() {
      return ["Country of Origin", "Tags"].includes(this.title) && this.isExtended
    }
    // ,
    // isAnyItemsChecked() {
    //   return this.items.filter(item => item.checked).length
    // }
  },
  methods: {
    async clickedItem() {
      this.isSelected = !this.isSelected

      if (this.isSelected == true) {
        this.$emit("filterClick", this.id, "on")
      } else {
        this.resetAllCheckedItems()
        this.$emit("filterClick", this.id, "off")
      }
    },
    async resetAllCheckedItems() {
      this.isSelected = false
    }
  }
}
</script>

<style lang="sass" scoped>
.retailers-filter-button
  width: 100%
  border-radius: 3px
  display: flex
  border-top: 1px solid #D9D9D9
  fill: black
  cursor: pointer
.icon
  position: relative
  display: inline-block
  float: left
  width: 25px
  height: 25px
  margin-left: 25px
  margin-right: 10px
  padding-top: 10px
  fill: red

.name
  text-align: left
  display: inline-block
  position: relative
  float: left
  width: 100%
  font-weight: 620
  font-size: 16px
  padding-top: 10px
  padding-bottom: 10px
  margin-left: 10px

.selected
  width: 100%
  height: auto
  background-color: #4a8a5d
  color: white
  font-weight: 500

.pleaseHide
  display: none
</style>
