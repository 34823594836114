<template>
  <div>
    <img
      :class="
        cardInfo
          ? cardInfo.cardBrand === 'Discover'
            ? 'card-number__icon-discovery'
            : `${cardInfo.cardBrand}`.replace(' ', '') === 'AmericanExpress'
            ? 'card-number__icon-american'
            : cardInfo.cardBrand === 'MasterCard'
            ? 'card-number__icon-master'
            : 'card-number__icon-visa'
          : 'card-number__icon-visa'
      "
    />
    <span class="card-number__method-card-num">
      {{
        cardInfo
          ? $t("Components.Brands.Payment.cardInfo", {
              cardBrand: cardInfo.cardBrand,
              cardNumber: cardInfo.cardNumber.substr(
                cardInfo.cardNumber.length - 4
              ),
            })
          : ""
      }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    cardInfo: { type: Object, default: () => undefined },
  },
};
</script>

<style lang="sass">
.card-number
  &__icon-visa
    background: url("../../../assets/img/card_type.png") no-repeat
    height: 26px
    width: 40px
  &__icon-master
    background: url("../../../assets/img/card_type.png") no-repeat -42px 0px
    height: 26px
    width: 40px
  &__icon-american
    background: url("../../../assets/img/card_type.png") no-repeat -82px 0px
    height: 26px
    width: 40px
  &__icon-discovery
    background: url("../../../assets/img/card_type.png") no-repeat -118px 0px
    height: 26px
    width: 41px
  &__method-card-num
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 22px
    color: #000000
</style>
