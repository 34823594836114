<template>
  <div class="fixed-footer">
    <div v-if="showBorder" class="fixed-footer__border"></div>
    <div class="fixed-footer-container">
      <div class="columns footer__paddings is-hidden-mobile">
        <div class="column is-6 fixed-footer__column">
          <router-link class="nav-link" to="/about-us">
            {{ $t("Components.Brands.Footer.aboutUs") }}
          </router-link>
          <!-- <router-link class="nav-link" to="/terms">
            {{ $t("Components.Brands.Footer.terms") }}
          </router-link>
          <router-link class="nav-link" to="/privacy-policy">
            {{ $t("Components.Brands.Footer.privacy") }}
          </router-link> -->
          <a
            class="nav-link"
            href="https://www.landinginternational.com/terms-of-use/"
            >{{ $t("Components.Brands.Footer.terms") }}</a
          >
          <a
            class="nav-link"
            href="https://www.landinginternational.com/privacy-policy/"
            >{{ $t("Components.Brands.Footer.privacy") }}</a
          >
          <a class="nav-link" href="/contact-us">
            {{ $t("Components.Brands.Footer.contactUs") }}
          </a>
        </div>
        <div class="column justify-elements-end is-5 is-flex is-offset-1">
          <a class="nav-link" href="#">
            &copy;{{ $t("Components.Brands.Footer.copyRight") }}
          </a>
          <a
            class="nav-link-image"
            href="https://facebook.com/landinginternational"
            target="_blank"
          >
            <img
              src="@/assets/img/icon/facebook-icon.svg"
              width="23"
              alt="icon-facebook"
            />
          </a>
          <a
            class="nav-link-image"
            href="https://instagram.com/landinginternational"
            target="_blank"
          >
            <img
              src="@/assets/img/icon/instagram-icon.svg"
              width="23"
              alt="icon-instagram"
            />
          </a>
        </div>
      </div>
      <div
        class="columns is-mobile is-multiline footer__paddings is-hidden-tablet flexy-column is-centered"
      >
        <div class="column is-full has-text-centered">
          <router-link class="nav-link" to="/about-us">
            {{ $t("Components.Brands.Footer.aboutUs") }}
          </router-link>
        </div>
        <div class="column is-full has-text-centered">
          <router-link class="nav-link" to="/terms">
            {{ $t("Components.Brands.Footer.terms") }}
          </router-link>
        </div>
        <div class="column is-full has-text-centered">
          <router-link class="nav-link" to="/privacy-policy">
            {{ $t("Components.Brands.Footer.privacy") }}
          </router-link>
        </div>
        <div class="column is-full has-text-centered">
          <a class="nav-link" href="/contact-us">
            {{ $t("Components.Brands.Footer.contactUs") }}
          </a>
        </div>
        <div class="column is-full has-text-centered">
          <a
            class="nav-link is-inline-block"
            target="_blank"
            href="https://facebook.com/landinginternational"
          >
            <img
              src="@/assets/img/icon/facebook-icon.svg"
              width="23"
              alt="icon-facebook"
            />
          </a>
          <a
            class="nav-link is-inline-block"
            target="_blank"
            href="https://instagram.com/landinginternational"
          >
            <img
              src="@/assets/img/icon/instagram-icon.svg"
              width="23"
              alt="icon-instagram"
            />
          </a>
        </div>
        <div class="column is-full has-text-centered">
          <a class="nav-link" href="#">
            &copy;{{ $t("Components.Brands.Footer.copyRight") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showBorder: { type: Boolean, default: true },
  },
};
</script>
<style lang="sass" scoped>
@font-face
  font-family: "SF Pro Display"
  src: url("~@/assets/fonts/SFProDisplay-Regular.ttf") format("truetype")

.nav-link
  color: #000000
  font-size: 14px
  font-weight: 500
  line-height: 17px
  margin-right: 55px
  white-space: nowrap
  font-family: "SF Pro Display"
  @media screen and (max-width: 600px)
    margin-right: 5px

.nav-link-image
  margin-top: 11px
  margin-right: 10px

.footer__paddings
  padding: 0px 10px
  width: 100%
  margin-top: 0px !important
.flexy-column
  display: flex
  justify-content: space-between

.columns:not(:last-child)
  margin-bottom: 0px

.justify-elements-end
  justify-content: flex-end
  align-items: center

.fixed-footer-container
  width: 100%
  height: 100%
  display: flex
  padding-left: 18px !important
  padding-right: 18px !important
.fixed-footer
  left: 0
  position: absolute
  width: 100%
  height: 10vh
  background-color: #fff
  z-index: 10
  font-family: SF Pro Display

  &__border
    border-top: 1px solid #e5e5e5

  &__column
    display: flex
    align-items: center
  img
    margin-left: 0px !important
</style>
