import Vue from "vue";
import StoreUtil from "@/store/store-util";
import initState from "./state";
export default {
  SET_STATE_RESET(state) {
    Object.assign(state, initState);
  },
  SET_VALUE(state, { stateProperties, propertyName, data }) {
    const stateProperty = StoreUtil.getStateProperty(state, stateProperties);
    Vue.set(stateProperty, propertyName, data);
  },
  SET_LOADING(state, { stateProperties, propertyName, status }) {
    let stateProperty = StoreUtil.getStateProperty(
      state.status.isLoading,
      stateProperties
    );
    Vue.set(stateProperty, propertyName, status);
  },
  SET_ERROR(state, dataName, status) {
    Vue.set(state.status.error, dataName, status);
  },
  SET_SAVING(state, isSaving) {
    state.status.isSaving = isSaving;
  },
  UPDATE_TRAINING_STEPS_BY_CATEGORY_PRIVATE_ID(state, param) {
    const type = param.type;
    const categoryPrivateId = param.parentKey;
    const oldList = StoreUtil.getStateProperty(state, [
      "categoryTrainingSteps",
      `${categoryPrivateId}`,
    ]);
    let j = oldList.length;
    const itemMap = new Map();
    let newList = [];
    let key = 999;
    switch (type) {
      case 1: //add
        param.data.forEach((i) => (i.privateId = key++));
        newList = oldList.concat(param.data);
        break;
      case 2: //update
        oldList.forEach((l) => itemMap.set(l.privateId, l));
        param.data.forEach((l) => (itemMap.get(l.privateId).name = l.name));
        newList = oldList;
        break;
      case 3: //delete
        param.data.forEach((l) => itemMap.set(l.privateId, l));

        while (j--) {
          if (itemMap.has(oldList[j].privateId)) {
            oldList.splice(j, 1);
          }
        }
        newList = oldList;
        break;
    }
    newList.sort((a, b) => a.name - b.name);
    Vue.set(state.categoryTrainingSteps, `${categoryPrivateId}`, newList);
  },
};
