<template lang="pug">
.guest-sign-up__main-content
  form(:id="isBrand ? 'brand-free-trial' : 'retailer-registration'")
    p.form--title {{ formTitle }}
    b-field
      b-input(v-model="name" :placeholder="namePlaceholder")
    b-field
      b-input(v-model="website" :placeholder="websitePlaceholder")
    b-field
      .columns
        .column
          b-input(v-model="firstName" placeholder="First Name")
        .column
          b-input(v-model="lastName" placeholder="Last Name")
    b-field
      b-input(type="email" v-model="email" placeholder="Email")
    b-field
      b-input(:type="passwordType" v-model="password" placeholder="Password" :password-reveal="false" class="password-input-style")
      //span(class="eyeIcon-style" @click="Passwordvisibility")
        img(v-if="passwordType === 'password'" src="@/assets/img/icon/passwordEye.svg")
        img(v-if="passwordType === 'text'" src="@/assets/img/icon/preview-icon.svg" )
    b-field
      b-input(type="password" v-model="confirmPassword" placeholder="Confirm Password" :password-reveal="false")
    b-field(v-if="isCheckbox")
      b-checkbox(v-model="isCheck")
        p By registering for a free trial, you agree to the
          span(@click="jumpToTerms" style="text-decoration: underline;cursor: pointer;")   Landing Terms of Use
          |   and
          span(@click="jumpToPrivacy"  style="text-decoration: underline;cursor: pointer;")   Privacy Policy
    .form--footer
      my-button(variant="is-primary" :label="submitLabel" @onClick="submitAction")
</template>

<script>
import MyButton from "@/components/webflowComponent/Button"

export default {
  name: "SignUp",

  components: {
    MyButton
  },

  props: {
    formTitle: {
      type: String
    },
    isBrand: {
      type: Boolean,
      default: () => false
    },
    brandStorageInfo: {
      type: Object,
      default: () => {}
    },
    // name: {
    //   type: String
    // },
    namePlaceholder: {
      type: String,
      default: "Retailer Name"
    },
    // website: {
    //   type: String
    // },
    websitePlaceholder: {
      type: String,
      default: "Retailer Website"
    },
    // firstName: {
    //   type: String
    // },
    // lastName: {
    //   type: String
    // },
    // email: {
    //   type: String
    // },
    // password: {
    //   type: String
    // },
    // confirmPassword: {
    //   type: String
    // },
    submitLabel: {
      type: String,
      default: "Sign Up"
    },
    text: {
      type: String,
      default:
        "By registering for a free trial, you agree that your subscription will automatically renew and you will be charged unless you cancel before the end of your trial. Cancel at any time through your account page before your trial ends to avoid charges."
    },
    isCheckbox: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isCheck: false,
      canClick: false,
      name: "",
      website: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      passwordType: "password"
    }
  },

  computed: {},
  mounted() {
    if (Object.keys(this.brandStorageInfo).length !== 0) {
      this.email = this.brandStorageInfo.user.email || ""
      this.password = this.brandStorageInfo.user.password || ""
      this.confirmPassword = this.brandStorageInfo.user.confirmPassword || ""
      this.firstName = this.brandStorageInfo.user.givenName || ""
      this.lastName = this.brandStorageInfo.user.familyName || ""
      this.name = this.brandStorageInfo.brand.name || ""
      this.website = this.brandStorageInfo.brand.website || ""
    }
  },

  methods: {
    Passwordvisibility() {
      this.passwordType = this.passwordType === "password" ? "text" : "password"
    },
    submitAction() {
      if (this.isBrand && this.isCheckbox && !this.isCheck) {
        this.$message.info("Please check whether it is selected.")
        return
      }
      if (
        !this.email ||
        !this.password ||
        !this.confirmPassword ||
        !this.firstName ||
        !this.lastName ||
        !this.name ||
        !this.website
      ) {
        this.$message.info("Please fill in the required fields.")
        return
      }
      let params
      if (this.isBrand && this.isCheckbox) {
        params = {
          user: {
            email: this.email,
            password: this.password,
            confirmPassword: this.confirmPassword,
            givenName: this.firstName,
            familyName: this.lastName
            // phoneNumber: ""
          },
          brand: {
            name: this.name,
            website: this.website
          }
        }
      } else {
        params = {
          user: {
            email: this.email,
            password: this.password,
            confirmPassword: this.confirmPassword,
            givenName: this.firstName,
            familyName: this.lastName
            // phoneNumber: ""
          },
          retailer: {
            name: this.name,
            website: this.website
          }
        }
      }
      this.$emit("submitAction", params)
    },
    jumpToTerms() {
      window.open("https://www.landinginternational.com/terms-of-use", "_blank")
    },
    jumpToPrivacy() {
      window.open("https://www.landinginternational.com/privacy-policy", "_blank")
    }
  }
}
</script>
<style lang="scss">
.guest-sign-up__main-content {
  .input,
  .textarea,
  .input:hover,
  .textarea:hover,
  .input:focus,
  .textarea:focus {
    border: 1px solid #f15a42;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 16px 24px;

    font-size: 20px;
    line-height: 22px;
    color: #000000;

    box-shadow: none;
    height: fit-content;
  }

  .b-checkbox.checkbox {
    display: flex;
  }

  .b-checkbox.checkbox input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    background: #f15a42;
    border: 1px solid #f15a42;
    box-sizing: border-box;
    border-radius: 0px;
  }

  .b-checkbox.checkbox input[type="checkbox"]:before {
    border: 1px solid #f15a42;
    box-sizing: border-box;
    content: "";
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    //background: #F15A42;
  }

  .b-checkbox.checkbox input[type="checkbox"]:after {
    z-index: 1;
  }

  .b-checkbox.checkbox span.control-label {
    flex: 1;
  }

  .field:not(:last-child) {
    margin-bottom: 20px;
  }

  .form--title {
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #1a1818;
    margin-bottom: 30px;
    margin-top: 0;
    font-family: "Niveau Grotesk";
  }

  .form--footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  .control.has-icons-right .icon.is-right {
    top: 8px;
    cursor: pointer;
  }
  .password-input-style {
    position: relative;
  }
  .eyeIcon-style {
    top: 28px !important;
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    right: 32px !important;
  }
}
</style>
