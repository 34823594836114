import SimpleDescriptionEntity from "@/models/SimpleDescriptionEntity"
export default class ProductTrainingStep extends SimpleDescriptionEntity {
  constructor(model) {
    super(model)
    this.categoryPrivateId = 0
    this.category = null
    this.products = null
  }

  toModel() {
    const data = super.toModel()
    data.categoryPrivateId = this.categoryPrivateId
    data.category = this.category
    data.products = this.products
    return data
  }
}
