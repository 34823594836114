<template>
  <div class="flex-c">
    <div class="header" :class="{ 'header-border': hasTitle }">
      <div class="flex-r">
        <div :class="{ bold: isFocus }" class="title">
          {{ title }}
        </div>
        <div class="f-spacer"></div>
        <div v-if="isEditable">
          <a-button class="btn" @click="() => openAllItemDialog(1)">
            <a-icon type="plus"></a-icon>
            Add
          </a-button>
          <a-dropdown :disabled="isLoading">
            <a-menu slot="overlay" @click="e => openAllItemDialog(+e.key)">
              <a-menu-item key="2">
                <div><a-icon type="edit" /> Edit All</div>
              </a-menu-item>
              <a-menu-item key="3">
                <div><a-icon type="delete" /> Delete</div>
              </a-menu-item>
            </a-menu>
            <a-button class="btn"
              ><a-icon type="edit"></a-icon> Edit <a-icon type="down" />
            </a-button>
          </a-dropdown>
        </div>
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
//import AddNewDialog from "@/components/admin/AddNewDialog";
export default {
  components: {
    //AddNewDialog,
  },
  props: {
    title: { type: String, default: "" },
    isFocus: { type: Boolean, default: false },
    isEditable: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },
  data() {
    return {
      hasTitle: false
    };
  },
  created() {
    this.hasTitle = this.title.length > 0;
  },
  methods: {
    openAllItemDialog(type) {
      this.$emit("open-all-items-dialog", type);
    }
  }
};
</script>
<style lang="scss">
.f-spacer {
  flex: 1 1 auto;
}
.flex-r {
  display: flex;
  flex-direction: row;
}
.flex-c {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.root {
  margin-bottom: 43px;
}
.header-border {
  border-bottom: 1px solid #d9d9d9;
}
.header {
  margin-bottom: 12px;
  .title {
    font-weight: normal;
    font-family: Proxima Nova;
    font-style: normal;
    font-size: 22px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #262626;
    text-transform: uppercase;
  }
  .btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    background: #4a8a5d;
    color: #fff;
    padding: 0px 8px;
    border: 1px solid #4a8a5d;
    border-radius: 2px;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  .bold {
    font-weight: bold;
  }
  .ant-dropdown-menu-item {
    > div {
      > * {
        margin-right: 4px;
      }
    }
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    color: #4a8a5d;
    background-color: #e2eeec;
  }
}
</style>
