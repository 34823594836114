<template>
  <div>
    <a-modal
      v-model="addDialog"
      width="952px"
      :title="`Add New ${title}`"
      :closable="true"
      ok-text="Save"
      class="admin-console-edit-dialog"
      :destroy-on-close="true"
      @cancel="cancelDialog"
      @ok="doSaveNewValue"
    >
      <div style="min-height: 270px;">
        <p v-if="countSameItem > 0" class="add-new-dialog__error-txt">
          *Categories in red already exist. Please remove in order to proceed.
        </p>
        <a-input
          v-model="inputValue"
          class="add-new-dialog__input-tag"
          @blur="createTag"
          @keyup.enter="createTag"
        ></a-input>
        <div style="margin-top: 12px;">
          <template v-if="newValues && newValues.length > 0">
            <a-tag
              v-for="(item, index) in newValues"
              :key="`${item}_${index}_add`"
              closable
              :class="item.isSame ? 'add-new-dialog__error-tag' : 'add-new-dialog__tags'"
              @close="removeTag(item)"
            >
              {{ item.name }}
            </a-tag>
          </template>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { checkHasSameNameData } from "@/utils/validate"
export default {
  data() {
    return {
      addDialog: false,
      inputValue: "",
      newValues: [],
      countSameItem: 0,
      title: "",
      existedDatas: []
    }
  },
  methods: {
    doSaveNewValue() {
      let data = this.newValues.filter(item => !item.isSame)
      console.log(data)
      if (!data && data.length === 0) {
        this.$message.error("Nothing can be saved!")
      } else {
        this.$emit("saveNew", data)
      }
      this.newValues = []
      this.countSameItem = 0
      this.addDialog = false
    },
    cancelDialog() {
      // this.newValues = []
      this.addDialog = false
    },
    openDialog(exitedData, title) {
      this.addDialog = true
      this.title = title
      this.existedDatas = exitedData
    },
    createTag(event) {
      console.log(event)
      if (this.inputValue === undefined || this.inputValue.replace(/^\s*|\s*$/g, "").length === 0) {
        if (event.type !== "blur") {
          this.$message.info("Can't Set empty value!")
        }
      } else {
        let item = { name: this.inputValue, isActive: true }
        this.newValues.push(item)
        item["isSame"] = checkHasSameNameData(item.name, this.existedDatas)
        this.inputValue = ""
        this.countSame()
      }
    },
    removeTag(item) {
      if (this.newValues && this.newValues.length > 0) {
        let position = -1
        for (let index = 0; index < this.newValues.length; index++) {
          if (this.newValues[index].name.toLowerCase() === item.name.toLowerCase()) {
            position = index
            break
          }
        }
        if (position > -1) {
          this.newValues.splice(position, 1)
        }
      } else {
        this.newValues = []
      }
      this.countSame()
    },
    doAfterClose() {
      this.newValues = []
    },
    countSame() {
      if (this.newValues && this.newValues.length > 0) {
        this.countSameItem = 0
        this.newValues.forEach(item => {
          if (item.isSame) {
            this.countSameItem++
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.add-new-dialog {
  &__error-txt {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #f5222d;
  }
  &__input-tag {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #262626;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
  }
  &__tags {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px !important;
    padding: 6px 8px !important;
    background: #ffffff !important;
    border: 1px solid #4a8a5d !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px !important;
    margin-bottom: 12px !important;
    color: #4a8a5d !important;
  }
  &__error-tag {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px !important;
    padding: 6px 8px !important;
    background: #ffffff !important;
    box-sizing: border-box;
    color: #f5222d !important;
    border: 1px solid #f5222d !important;
    border-radius: 2px !important;
    margin-bottom: 12px !important;
  }
}
.add-new-dialog__tags.ant-tag .anticon-close {
  color: inherit;
  vertical-align: middle;
}
.add-new-dialog__error-tag.ant-tag .anticon-close {
  color: inherit;
  vertical-align: middle;
}
.admin-console-edit-dialog .ant-modal-footer .ant-btn-primary {
  border-color: #4a8a5d;
  background: #4a8a5d;
}
</style>
