<template>
  <div class="message-search-panel">
    <div class="message-search-panel__search-area">
      <a-input v-model="searchValue" class="message-search-panel__search-input">
        <a-icon slot="prefix" type="search" />
      </a-input>
    </div>
    <div style="padding: 20px 10px;">
      <span class="message-search-panel__date-title">{{
        $t("Components.Admin.message.Date")
      }}</span>
      <div class="message-search-panel__divider"></div>
      <span class="message-search-panel__date-subtitle">{{
        $t("Components.Admin.message.SelectDateRange")
      }}</span>
      <a-date-picker
        v-model="startDate"
        :format="dateFormatString"
        :placeholder="$t('Components.Admin.message.SelectStart')"
        class="message-search-panel__date-input"
        dropdown-class-name="message-search-panel__date-picker"
        :show-today="false"
        @change="onChangeStart"
      />
      <a-date-picker
        v-model="endDate"
        :format="dateFormatString"
        :placeholder="$t('Components.Admin.message.SelectEnd')"
        class="message-search-panel__date-input"
        dropdown-class-name="message-search-panel__date-picker"
        :show-today="false"
        @change="onChangeEnd"
      />
      <a-collapse :bordered="false" style="margin-top: 20px;" :active-key="[1, 2]">
        <a-collapse-panel key="1" :show-arrow="false">
          <template #header="props">
            <a-row style="padding-bottom: 15px; border-bottom: 1px solid #e3e3e3;">
              <a-col :span="20">
                <span class="message-search-panel__date-title">{{
                  $t("Components.Admin.message.Category")
                }}</span>
              </a-col>
              <a-col :span="4" style="text-align: right;">
                <a-icon
                  type="down"
                  style="font-size: 16px; color: #4A8A5D; "
                  :rotate="props.isActive ? 180 : 0"
                ></a-icon>
              </a-col>
            </a-row>
          </template>
          <div>
            <a-row v-for="item in categoryDatas" :key="`category_data_${item.id}`">
              <a-checkbox
                class="message-search-panel__check-box-txt"
                @change="checkedValued => chooseCategory(checkedValued, item)"
              >
                {{ item.description }}
              </a-checkbox>
            </a-row>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="2" :disabled="false" :show-arrow="false" style="margin-top: 15px;">
          <template #header="props">
            <a-row style="padding-bottom: 15px; border-bottom: 1px solid #e3e3e3;">
              <a-col :span="20">
                <span class="message-search-panel__date-title">{{
                  $t("Components.Admin.message.MessageType")
                }}</span>
              </a-col>
              <a-col :span="4" style="text-align: right;">
                <a-icon
                  type="down"
                  style="font-size: 16px; color: #4A8A5D; "
                  :rotate="props.isActive ? 180 : 0"
                ></a-icon>
              </a-col>
            </a-row>
          </template>
          <div>
            <a-row v-for="messageType in messageTypes" :key="`message_type_${messageType.id}`">
              <a-checkbox
                class="message-search-panel__check-box-txt"
                @change="checkedValue => chooseMessageType(checkedValue, messageType)"
              >
                {{ messageType.description }}
              </a-checkbox>
            </a-row>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-button class="message-search-panel__btn" @click="doApplyAction">Apply</a-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateFormatString: "MM/DD/YYYY",
      searchValue: undefined,
      startDate: undefined,
      endDate: undefined,
      selectedCategory: new Set(),
      selectedMessageType: new Set()
    }
  },
  computed: {
    categoryDatas() {
      let data = this.$store.getters["reference/messageCategory"]
      return data
    },
    messageTypes() {
      let data = this.$store.getters["reference/messageType"]
      return data
    }
  },
  methods: {
    onChangeStart(value, dateStr) {
      console.log("value", value)
      if (!this.endDate) {
        this.$message.warning("Please choose the end date!")
      }
      this.startDate = dateStr
    },
    onChangeEnd(value, dateStr) {
      console.log("value", value)
      if (!this.startDate) {
        this.$message.warning("Please choose the start date!")
      }
      this.endDate = dateStr
    },
    chooseCategory(isChecked, item) {
      if (isChecked) {
        this.selectedCategory.add(item)
      } else {
        this.selectedCategory.delete(item)
      }
    },
    chooseMessageType(isChecked, item) {
      if (isChecked) {
        this.selectedMessageType.add(item)
      } else {
        this.selectedMessageType.delete(item)
      }
    },
    doApplyAction() {
      let param = {}
      if (this.searchValue && this.searchValue.length > 0) {
        param["keyword"] = this.searchValue
      }
      if (this.startDate) {
        param["start-date"] = this.startDate
      }
      if (this.endDate) {
        param["end-date"] = this.endDate
      }
      if (this.selectedCategory && this.selectedCategory.size > 0) {
        let data = [...this.selectedCategory]
        let categoryParamStr = data.map(item => item.description).join(",")
        param["message-category-id"] = categoryParamStr
      }
      if (this.selectedMessageType && this.selectedMessageType.size > 0) {
        let data = [...this.selectedMessageType]
        let typeStr = data.map(item => item.description).join(",")
        param["message-type-id"] = typeStr
      }
      this.$emit("applySearch", param)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-search-panel {
  width: 220px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  &__search-area {
    width: 100%;
    height: 48px;
    background: #e2eeec;
    border-radius: 2px;
    padding: 12px 20px;
    border-bottom: 1px solid #4a8a5d;
  }
  &__search-input {
    background: #ffffff;
    border-radius: 30px;
    height: 24px;
    font-size: 12px;
  }
  &__date-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #4a8a5d;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  &__divider {
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    height: 1px;
    margin: 3px 0px;
  }
  &__date-subtitle {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #8c8c8c;
    margin-bottom: 10px;
  }
  &__date-input {
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    width: 100%;
  }
  &__btn {
    width: 89px;
    color: white !important;
    background-color: #4a8a5d !important;
    border-color: #4a8a5d !important;
    border-radius: 2px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
  }
  &__check-box-txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #262626;
    margin-bottom: 5px;
    display: block;
  }
}
</style>
<style lang="scss">
.message-search-panel .ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.message-search-panel .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
}
.message-search-panel
  .ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding-left: 0px;
  display: block;
}
.message-search-panel .ant-collapse-borderless {
  background-color: #fff;
}
.message-search-panel
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0px;
}
.message-search-panel .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}
.message-search-panel__search-input .ant-input {
  height: 24px;
  border-radius: 30px;
  border: none;
}
.message-search-panel__search-input .ant-input:hover {
  border: none;
}
.message-search-panel__search-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
}
.message-search-panel__search-input.ant-input-affix-wrapper {
  border: none;
}
.message-search-panel__date-input .ant-input {
  border-radius: 0px;
}
.message-search-panel__date-picker .ant-calendar-selected-day .ant-calendar-date {
  background: #e2eeec;
}
.message-search-panel__date-picker .ant-calendar-today .ant-calendar-date {
  color: #4a8a5d;
  font-weight: bold;
  border-color: #4a8a5d;
}
.message-search-panel__date-picker .ant-calendar-date {
  color: #595959;
}
</style>
