<template>
    <div class="bf-payment">
        <a-form layout="horizontal" :form="form">
            <!-- <div class="payment-header">
                <div>
                    Add Your Payment Details
                </div>
                <div>
                    To activate your subscription at the end of your 21-day trial.
                </div>

                <div>
                    <img src="@/assets/webflowAssets/Payment/method.svg" alt="Methods" />
                </div>
            </div> -->

            <div class="payment-section">Payment Details</div>
            <div>
                <div class="columns">
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="First Name" v-decorator="[
            'firstName',
            {
                rules: [
                    {
                        required: true,
                        message: 'First Name is required',
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="Last Name" v-decorator="[
            'lastName',
            {
                rules: [
                    {
                        required: true,
                        message: 'Last Name is required',
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </div>
            <a-form-item>
                <a-input placeholder="Card Number" v-decorator="[
            'cardNumber',
            {
                rules: [
                    {
                        required: true,
                        message: 'Card Number is required',
                    },
                    {
                        validator: validateCardNumber,
                    },
                ],
            },
        ]"></a-input>
            </a-form-item>
            <div>
                <div class="columns">
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="CVV" v-decorator="[
            'cvvNumber',
            {
                rules: [
                    {
                        required: true,
                        message: 'CVV Code is required',
                    },
                    {
                        validator: validateCVV,
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="MM/YY" v-decorator="[
            'expirationDate',
            {
                rules: [
                    {
                        required: true,
                        message: 'Expiration Date is required',
                    },
                    {
                        validator: validateExpirationDate,
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </div>
            <div class="payment-section">Billing Details</div>
            <a-form-item>
                <a-input placeholder="Address 1" v-decorator="[
            'address1',
            {
                rules: [
                    {
                        required: true,
                        message: 'Address 1 is required',
                    },
                ],
            },
        ]"></a-input>
            </a-form-item>
            <a-form-item>
                <a-input placeholder="Address 2" v-decorator="[
            'address2',
            {
                rules: [],
            },
        ]"></a-input>
            </a-form-item>
            <div>
                <div class="columns">
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="City" v-decorator="[
            'city',
            {
                rules: [
                    {
                        required: true,
                        message: 'City is required',
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="State" v-decorator="[
            'state',
            {
                rules: [
                    {
                        required: true,
                        message: 'State is required',
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </div>
            <div>
                <div class="columns">
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="Zip Code" v-decorator="[
            'zipCode',
            {
                rules: [
                    {
                        required: true,
                        message: 'Zip Code is required',
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                    <div class="column"></div>
                </div>
            </div>
        </a-form>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: "payment" });
    },
    methods: {
        removeCode() {
            this.form.setFieldsValue({ promoCode: "" });
            this.promoValidateStatus = "";
            this.showApply = true;
        },
        validateExpirationDate(rules, value, callback) {
            const v = (value || "").trim();
            if (v) {
                const expirationDateRegex = /^[0-9]{2}[/][0-9]{2}$/;
                if (expirationDateRegex.test(v)) {
                    callback();
                } else {
                    callback("Expiration Date is invalid");
                }
            } else {
                callback();
            }
        },
        validateCVV(rules, value, callback) {
            if (value) {
                const cvvRegex = /^[0-9]{3,4}$/;
                if (cvvRegex.test(value)) {
                    callback();
                } else {
                    callback("CVV number is invalid");
                }
            } else {
                callback();
            }
        },
        validateCardNumber(rules, value, callback) {
            if (value) {
                var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
                const creditCardRegex = /\d{12,16}/g;
                if (!creditCardRegex.test(v)) {
                    callback("Card Number is invalid");
                    // } else if (!this.luhn(v)) {
                    //   callback("Card Number is invalid");
                } else {
                    callback();
                }
            } else {
                callback();
            }
        },
        luhn(cardNumber) {
            var sum = 0;
            var parity = cardNumber.length % 2;

            for (var i = cardNumber.length - 1; i >= 0; i--) {
                var digit = cardNumber[i];

                if (parity === 0) {
                    digit = digit * 2;
                }

                sum += Math.floor(digit / 10) + (digit % 10);

                parity = 1 - parity;
            }

            return sum % 10 === 0;
        },
        handleCancel(e) {
            e.preventDefault();
            this.$emit("cancelAction");
        },
        handleSubmit(e) {
            e.preventDefault();
            const emptyString = "";
            this.form.validateFields((err, values) => {
                if (!err) {
                    //console.log("Received values of form: ", values);
                    const billingAddress = {
                        addressLine1: values.address1,
                        addressLine2: values.address2 || emptyString,
                        city: values.city,
                        state: values.state,
                        zipCode: values.zipCode,
                        country: emptyString,
                    };
                    const paymentDetail = {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        cardNumber: values.cardNumber,
                        cvvCode: values.cvvNumber,
                        expirationDate: values.expirationDate,
                        billingAddress,
                    };
                    const subscriptionPayment = {
                        paymentDetail,

                        promoCode: this.appliedPromoCode || emptyString,

                        discountAmount: this.discountAmount,

                    };
                    //console.log("subscriptionPayment: ", subscriptionPayment);
                    this.$emit("submitAction", subscriptionPayment);
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.bf-payment {
    .payment-header {
        font-family: Proxima;

        div:first-child {
            color: #1a1818;
            font-size: 25px;
        }

        div:nth-child(2) {
            font-size: 20px;
            color: #4a4a4a;
            //   margin-bottom: 34px;
        }

        // div:nth-child(3) {
        //   color: #363636;
        //   font-size: 1rem;
        //   font-weight: 700;
        // }
    }

    .payment-section {
        font-family: Niveau Grotesk;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .promo-top {
        display: flex;
        flex-direction: row;
        font-family: Niveau Grotesk;
        font-size: 14px;
        color: #000;

        .promo-code {
            font-weight: 700;
        }
    }

    .promo-item {
        margin-bottom: 40px !important;
    }

    .apply-coupon-code-check {
        position: absolute;
        right: 23px;
        top: 2px;
        font-size: 20px;
        color: #f15a42;
        cursor: pointer;
    }

    .fx-spacer {
        flex: 1 1 auto;
    }

    .ant-form-item {
        margin-bottom: 22px;

        &.center {
            display: flex;
            justify-content: center;
        }

        .ant-form-explain {
            position: absolute;
            bottom: -20px;
            left: 24px;
        }

        input {

            padding: 6px 24px 10px 24px;
            border-radius: 50px;
            font-size: 20px;
            color: #000;
            height: fit-content;
        }

        .ant-checkbox {
            border: 1px solid #f15a42;
        }
    }


}
</style>