<template>
  <div class="payment-info">
    <a-radio-group
      id="payWay"
      v-model="cardInfo.payType"
      button-style="outline"
      @change="changePayWay"
    >
      <a-radio-button id="credit" class="payment-info__tab" value="creditCard">
        {{ $t("Components.Brands.Payment.creditCardTitle") }}
      </a-radio-button>
      <a-radio-button class="payment-info__tab" value="wireTrans">
        {{ $t("Components.Brands.Payment.wireTransferTitle") }}
      </a-radio-button>
    </a-radio-group>
    <div v-if="cardInfo.payType == 'creditCard'" class="payment-info__tab-content">
      <a-row>
        <a-input
          v-model="cardInfo.cardHolderName"
          class="payment-info__text"
          :placeholder="$t('Components.Brands.Payment.nameOnCard')"
          @change="sendData"
        >
          <template #prefix>
            <span class="payment-info__card-name-icon" />
          </template>
        </a-input>
      </a-row>
      <a-row class="payment-info__row">
        <a-input
          v-model="cardInfo.cardNumber"
          class="payment-info__text"
          :placeholder="$t('Components.Brands.Payment.cardNumber')"
          @change="sendData"
        >
          <template #prefix>
            <span class="payment-info__card-number-icon" />
          </template>
          <template #suffix>
            <span class="payment-info__card-number-suffix" />
          </template>
        </a-input>
      </a-row>
      <a-row class="payment-info__row">
        <a-col class="payment-info__last-input" :span="10">
          <a-input
            v-model="cardInfo.expirationDate"
            class="payment-info__text"
            placeholder="MM/YY"
            @change="sendData"
          >
            <template #prefix>
              <span class="payment-info__date-icon" />
            </template>
          </a-input>
        </a-col>
        <a-col class="payment-info__last-input" :span="10">
          <a-input
            v-model="cardInfo.cardCode"
            class="payment-info__text"
            :placeholder="$t('Components.Brands.Payment.cardCode')"
            @change="sendData"
          >
          </a-input>
        </a-col>
      </a-row>
      <a-row v-if="showCheck" class="payment-info__row">
        <a-checkbox
          id="saveAsDefault"
          v-model="cardInfo.default"
          class="payment-info__text"
          @change="saveAsDefault"
        >
          {{ $t("Components.Brands.Payment.saveCardAsDefault") }}
        </a-checkbox>
      </a-row>
    </div>
    <div v-else class="payment-info__tab-wire-transfer-content">
      <span v-html="$t('Components.Brands.Payment.wireTransferInfo')" />
    </div>
  </div>
</template>

<script>
import { Radio, Input, Row, Col, Checkbox } from "ant-design-vue"
export default {
  components: {
    "a-radio-group": Radio.Group,
    "a-radio-button": Radio.Button,
    "a-input": Input,
    "a-checkbox": Checkbox,
    "a-row": Row,
    "a-col": Col
  },
  props: {
    creditCard: { type: Object, default: () => {} },
    showCheck: { type: Boolean, default: true }
  },
  data() {
    return {
      cardInfo: {
        payType: "creditCard",
        cardNumber: "",
        cardHolderName: "",
        cardBrand: "",
        expirationDate: "",
        cardCode: "",
        privateId: undefined,
        brandPrivateId: undefined,
        email: undefined,
        customerProfileId: undefined,
        customerPaymentProfileId: undefined,
        createdAt: undefined,
        createdBy: undefined,
        default: false
      }
    }
  },
  watch: {
    creditCard: {
      handler(newValue) {
        this.creditCard = newValue
        this.initCardInfo()
      }
    }
  },
  async created() {
    this.initCardInfo()
  },
  methods: {
    saveAsDefault(e) {
      if (this.creditCard && this.creditCard.default && !e.target.checked) {
        this.cardInfo.default = this.creditCard.default
        this.$message.warn(this.$t("Components.Brands.Payment.canNotCancelDefault"))
      } else {
        this.cardInfo.default = e.target.checked
        this.$emit("cardInfo", this.cardInfo)
      }
    },
    initCardInfo() {
      if (this.creditCard) {
        this.cardInfo = Object.assign({}, this.cardInfo, this.creditCard)
      }
    },
    changePayWay() {
      this.$emit("cardInfo", this.cardInfo)
    },
    sendData() {
      if (this.creditCard) {
        if (this.cardInfo.cardNumber !== this.creditCard.cardNumber) {
          this.cardInfo.privateId = undefined
        }
      }
      this.$emit("cardInfo", this.cardInfo)
    }
  }
}
</script>

<style lang="sass">
.payment-info
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    &__tab
        font-size: 12px !important
        line-height: 22px !important
        display: flex !important
        width: 160px
        height: 50px !important
        margin-right: 24px !important
        align-items: center !important
        border: 1px solid #D9D9D9 !important
        border-radius: 2px !important
        float: left
        color: #000000 !important
        padding: 0px !important
    &__tab-content
        margin-top: 31px
        width: 375px
        font-size: 12px !important
        line-height: 22px !important
    &__tab-wire-transfer-content
        margin-top: 21px
        width: 302px
        font-size: 9px !important
        line-height: 20px !important
        color: #000000 !important
    &__card-name-icon
        display: -moz-box
        display: -webkit-box
        float: left
        width: 14px
        height: 14px
        background: url("../../../assets/img/icon/name_on_card.svg") no-repeat
        background-size: 14px 14px
    &__row
        margin-top: 23px
    &__card-number-icon
        display: -moz-box
        display: -webkit-box
        float: left
        width: 14px
        height: 14px
        background: url("../../../assets/img/icon/card_number.svg") no-repeat
        background-size: 14px 14px
    &__card-number-suffix
        display: -moz-box
        display: -webkit-box
        width: 160px
        height: 28px
        background: url("../../../assets/img/icon/visa_master_card.svg") no-repeat
    &__last-input
        width: 160px
        margin-right: 24px
    &__date-icon
        display: -moz-box
        display: -webkit-box
        float: left
        width: 14px
        height: 14px
        background: url("../../../assets/img/icon/expire_date.svg") no-repeat
        background-size: 14px 14px
    &__cvv-icon
        display: -moz-box
        display: -webkit-box
        float: left
        width: 14px
        height: 14px
        background: url("../../../assets/img/icon/three_number.svg") no-repeat
        background-size: 14px 14px
    &__text >.ant-input
        font-style: normal !important
        font-weight: normal !important
        font-size: 12px !important
        line-height: 22px !important
        color: #595959 !important

.ant-radio-group>label span
    text-align: center
#payWay> label:first-child span:last-child
    width: 80%
    margin-left: 20px
    display: -moz-box
    display: -webkit-box
    float: left
    line-height: 20px
    text-indent: 30px
    background: url("../../../assets/img/icon/add_credit_tab.svg") no-repeat left top
    background-size: 20px 20px
#payWay> label:last-child span:last-child
    width: 100%
    margin-left: 20px
    display: -moz-box
    display: -webkit-box
    float: left
    text-indent: 26px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 22px
    background: url("../../../assets/img/icon/add_wiretrans_tab.svg") no-repeat left center
    background-size: 19px 18px
#payWay .ant-radio-button-wrapper-checked
    background: #E2EEEC !important
    color: #595959 !important
    border: 1px solid rgba(0, 0, 0, 0) !important
#payWay .ant-radio-button-wrapper-checked:hover
    border: 1px solid rgba(0, 0, 0, 0) !important
    color: #595959 !important
    box-shadow: none !important
#payWay .ant-radio-button-wrapper:hover
    color: #595959 !important
#payWay .ant-radio-button:hover
    color: #595959 !important
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before
    background-color: #ffffff !important
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
    box-shadow: none !important
    outline: none !important
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within
    box-shadow: none !important
    outline: none !important
.ant-radio-button-wrapper:focus-within
    box-shadow: none !important
    outline: none !important
.ant-radio-button-wrapper::before
    outline: none !important
    box-shadow: none !important
.ant-radio-button-wrapper:not(:first-child)::before
    width: 0px !important
.ant-checkbox-checked #saveAsDefault+.ant-checkbox-inner
    background-color: #4a8a5d !important
    border-color: #4a8a5d !important
</style>
