var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{class:_vm.cardInfo
        ? _vm.cardInfo.cardBrand === 'Discover'
          ? 'card-number__icon-discovery'
          : `${_vm.cardInfo.cardBrand}`.replace(' ', '') === 'AmericanExpress'
          ? 'card-number__icon-american'
          : _vm.cardInfo.cardBrand === 'MasterCard'
          ? 'card-number__icon-master'
          : 'card-number__icon-visa'
        : 'card-number__icon-visa'}),_c('span',{staticClass:"card-number__method-card-num"},[_vm._v(" "+_vm._s(_vm.cardInfo ? _vm.$t("Components.Brands.Payment.cardInfo", { cardBrand: _vm.cardInfo.cardBrand, cardNumber: _vm.cardInfo.cardNumber.substr( _vm.cardInfo.cardNumber.length - 4 ), }) : "")+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }