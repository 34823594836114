import Vue from "vue";
import Router from "vue-router";
import AuthGuard from "@/routes/auth-guard";

import Home from "@/views/Homepage.vue";
import About from "@/views/About.vue";
import Team from "@/views/Team_New.vue";
import AboutUs from "@/views/About-us.vue";
import Terms from "@/views/Terms.vue";
import PurchaseOrderTerms from "@/views/PurchaseOrderTerms.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import CopyrightDisputePolicy from "@/views/CopyrightDisputePolicy.vue";
import Unfiltered from "@/views/Unfiltered.vue";
import MemberProfile from "@/views/MemberProfile.vue";
import GuestSubscription from "@/views/Subscription.vue";
import BrandRegistration from "@/views/RegistrationBrand.vue";
import Press from "@/views/Press.vue";
import ContactUs from "@/views/ContactUs";
import SettingSubscription from "@/components/brands/settings/Subscription";
import BrandSubscriptionBF from "@/views/Brands/BrandSubscriptionBF";
import BrandSubscriptions from "@/views/Brands/Subscriptions";
import BrandSubscriptionPlatform from "@/views/Brands/BrandSubscriptionPlatform";
import WebsiteSubscription from "@/views/websiteSubscription";
// import BrandRegistrationMain from "@/views/BrandRegistration"
import TypeSignUp from "@/views/TypeSignUp";
//import BrandSignUp from "@/views/BrandSignUp";
//import BrandBFRegistrationStepOne from "@/views/BrandBFRegistration";
import RegistrationBrand from "@/views/Registrations/BrandRegistration";
import SubscribeSuccess from "@/views/SubscribeSuccess";
import BrandTrialEnd from "@/views/BrandTrialEnd";

import Auth from "@/views/Layout/ParentView.vue";
import Register from "@/views/Auth/Register.vue";
import Login from "@/views/Auth/Login.vue";
import ForgotPassword from "@/views/Auth/ForgotPassword.vue";
import ResetPassword from "@/views/Auth/ResetPassword.vue";
import welcomePage from "@/components/webflowComponent/ResetPassword";
import Verify from "@/views/Auth/Verify.vue";
import AddMember from "@/views/Auth/AddMember.vue";

// TODO: reuse components
import Brands from "@/views/Layout/ParentView.vue";
import BrandDashboard from "@/views/Brands/BrandDashboard.vue";
import BrandMainPage from "@/views/Brands/BrandMainPage.vue";
import BrandCatalog from "@/views/Brands/BrandCatalog.vue";
import BrandOrders from "@/views/Brands/BrandOrders.vue";
import BrandOrder from "@/views/Brands/BrandOrder.vue";
import BrandSettings from "@/views/Brands/BrandSettings.vue";
import BrandAnalytics from "@/views/Brands/BrandAnalytics.vue";
import BrandProduct from "@/views/Brands/BrandProduct.vue";
import BrandPending from "@/views/Brands/BrandPending.vue";
import TrialDayDialog from "../components/webflowComponent/TrialDayDialog";
import MembershipExpiringDialog from "../components/webflowComponent/MembershipExpiringDialog";
import Suspended from "../components/webflowComponent/suspendedDialog";
// import BrandRegistrationPage from "@/views/Brands/BrandRegistrationPage.vue"
import BrandRetailerSearch from "@/views/Brands/BrandRetailerSearch.vue";
import RetailerDirectory from "@/views/Brands/RetailerDirectory.vue";
import BrandMessaging from "@/views/Brands/BrandMessaging.vue";
import BrandPlatform from "@/views/Brands/BrandPlatform.vue";
import BrandSearch from "@/views/Brands/BrandSearch";
import BrandBuyerBox from "@/views/Brands/BrandBuyerBox";
import BrandPitch from "@/views/Brands/BrandPitch";
import BrandUserProfile from "@/views/Brands/BrandUserProfile";

import Retailers from "@/views/Layout/ParentView.vue";
import RetailerDashboard from "@/views/Retailers/RetailerMainPage.vue";
import RetailerVerifyEmail from "@/views/Retailers/RetailerVerifyEmail.vue";
import RetailerOrders from "@/views/Retailers/RetailerOrders.vue";
import RetailerOrder from "@/views/Retailers/RetailerOrder.vue";
import RetailerCart from "@/views/Retailers/RetailerCart.vue";
import RetailerCheckout from "@/views/Retailers/RetailerCheckout.vue";
import RetailerCheckoutSuccess from "@/views/Retailers/RetailerCheckoutSuccess.vue";
import OldRetailerBrandProfile from "@/views/Retailers/OldRetailerBrandProfile.vue";
import RetailerSettings from "@/views/Retailers/RetailerSettings.vue";
import RetailerProfile from "@/views/Retailers/RetailerProfile.vue";
import RetailerProducts from "@/views/Retailers/RetailerProducts.vue";
import RetailerBrandProduct from "@/views/Retailers/RetailerBrandProduct.vue";
import RetailerMainPage from "@/views/Retailers/RetailerMainPage.vue";
import RetailerCard from "@/views/Retailers/RetailerCard.vue";
import BrandDirectory from "@/views/Retailers/BrandDirectory.vue";
import RetailerMessaging from "@/views/Retailers/RetailerMessaging.vue";
import RetailerTraining from "@/views/Training/retailerTraining";
import RetailerTrainingBrand from "@/components/training/retailerTraining/retailerBrandsTab";
import retailerTrainingLocation from "@/components/training/retailerTraining/retailerLocationTab";
import retailerTrainingUsers from "@/components/training/retailerTraining/retailerUsersTab";
import retailerTrainingProduct from "@/components/training/retailerTraining/retailerProductTab";

import Admins from "@/views/Layout/ParentView.vue";
import AdminsMainPage from "@/views/Admins/AdminsMainPage.vue";
import AdminDashboard from "@/views/Admins/AdminDashboard.vue";
import AdminOrders from "@/views/Admins/AdminOrders.vue";
import AdminOrdersOld from "@/views/Admins/AdminOrdersOld.vue";
import AdminOrder from "@/views/Admins/AdminOrder.vue";
import AdminClients from "@/views/Admins/AdminClients.vue";
import AdminSettings from "@/views/Admins/AdminSettings.vue";
import AdminBrand from "@/views/Layout/AdminBrand.vue";
import AdminRetailer from "@/views/Layout/AdminRetailer.vue";
import AdminMessaging from "@/views/Admins/AdminMessaging.vue";
// import AdminTraining from "@/views/Training/adminTraining"
import AdminTrainingNew from "@/views/Admins/AdminTraining";
import AdminRetailerMargin from "@/views/Admins/AdminRetailerMargin.vue";
import AdminTrainingBrand from "@/components/admin/AdminTrainingBrand";
import AdminTrainingBrandProductList from "@/views/Admins/AdminTrainingBrandProductList";
import AdminTrainingRetailer from "@/views/Admins/AdminTrainingRetailer";
import AdminTrainingUser from "@/views/Admins/AdminTrainingUser";
import AdminTrainingLoginMessage from "@/views/Admins/AdminTrainingLoginMessage";
import AdminTrainingRetailerViewPage from "@/views/Admins/AdminTrainingRetailerViewPage";
import AdminInventory from "@/views/Admins/AdminInventory";
import AdminRetailerSearch from "@/views/Admins/AdminRetailerSearch";
import AdminRetailerEdit from "@/views/Admins/AdminRetailerEdit";
import AdminBrandSearch from "@/views/Admins/AdminBrandSearch";
import AdminBrandList from "@/views/Admins/AdminBrandList";
import AdminBrandEdit from "@/views/Admins/AdminBrandEdit";
import AdminProductEdit from "@/views/Admins/AdminProductEdit";
import AdminTrainingEdit from "@/views/Admins/AdminTrainingEdit";
import AdminProductSearch from "@/views/Admins/AdminProductSearch";
import AdminProductBestSeller from "@/views/Admins/AdminProductBestSeller";
import AdminProductSearchHome from "@/views/Admins/AdminProductSearchHome";
import AdminProductNewArrival from "@/views/Admins/AdminProductNewArrival";
import AdminProductSearchResult from "@/views/Admins/AdminProductSearchResult";
import AdminSystem from "@/views/Admins/AdminSystem";
import AdminSystemUsers from "@/views/Admins/AdminSystemUsers";
import AdminSystemGroup from "@/views/Admins/AdminSystemGroup";
import AdminSystemAccess from "@/views/Admins/AdminSystemAccess";
import AdminSystemLogs from "@/views/Admins/AdminSystemLogs";
import AdminSystemPromoCode from "@/views/Admins/AdminSystemPromoCode";
import AdminSystemPromoCodeHistory from "@/views/Admins/AdminSystemPromoCodeHistory";

import OldOrderDetails from "@/components/oldOrder/oldOrderDetails";
import AdminComposeMessage from "@/views/Admins/AdminComposeMessage";

import BrandTrainingDetails from "../views/Training/BrandTrainingDetails";
// import PublicFacingTraining from "../views/Training/PublicFacingTraining"
import BrandTraining from "../views/Training/BrandTraining";
import SelectPaymentPay from "../views/Brands/SelectPaymentPay";
import BrandTrainingCreate from "../views/Training/BrandCreateTraining";
// import PublicReadTraining from "../views/Training/PublicReadTraining"
import TrainingProductViewAll from "../views/Training/ViewAllProducts";

import UpdatePaymentDetails from "../components/brands/settings/UpdatePaymentDetails";
import PaymentHistory from "../components/brands/settings/PaymentHistory";
import PaymentDetails from "../components/brands/settings/PaymentDetails";
import LandingTermsMain from "../components/brands/settings/LandingTermsMain";

// TODO: Agency Page and component
import Agency from "@/views/Agency/AgencyHomePage";
import GuestRetailer from "@/views/Agency/Retailer.vue";
import AgencyDetail from "@/views/Agency/AgencyDetail";
import MyAccount from "@/components/shared/MyAccount";
import Members from "@/components/brands/settings/Members";
import adminLandingTermsTF from "@/components/brands/settings/adminLandingTermsTF";
import brandLandingLogistics from "@/components/brands/settings/LandingTermsLogistics";
import LandingTermsTransaction from "@/components/brands/settings/LandingTermsTransaction";
import AdminLandingTerms from "@/components/brands/settings/adminLandingTerms";
import BrandSettingTerms from "@/components/brands/settings/Terms_new";
import PaySubscriptionSuccess from "@/views/Brands/PaySubscriptionSuccess";
import RetailerCleanPreCheck from "@/views/Retailers/RetailerCleanPreCheck";
import BrandCleanPreCheck from "@/views/Brands/BrandCleanPreCheck";
// import RetailerRegistration from "@/views/RetailerRegistration"
import RetailerRegistration from "@/views/RetailerSignUp";
import AdminRetialerDirtyIngredient from "@/views/Admins/AdminRetialerDirtyIngredient";
import AdminBrandInventory from "@/views/Admins/AdminBrandInventory";
import AdminProductInventoryHistory from "@/views/Admins/AdminProductInventoryHistory";
import VerifyApp from "@/views/VerifyApp";

import ThankYouPage from "@/views/ThankYouPage";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
  mode: "history",
  scrollBehavior() {
    // document.getElementById("app").scrollIntoView()
  },
  base: process.env.BASE_URL,
  // TODO: prerender static pages
  routes: [
    { path: "/", name: "home", component: Home },
    { path: "/about", name: "about", component: About },
    { path: "/team", name: "team", component: Team },
    { path: "/terms", name: "terms", component: Terms },
    {
      path: "/purchase-order-terms",
      name: "purchaseOrderTerms",
      component: PurchaseOrderTerms,
    },
    {
      path: "/privacy-policy",
      name: "privacyPolicy",
      component: PrivacyPolicy,
    },
    { path: "/about-us", name: "About-Us", component: AboutUs },
    { path: "/press", name: "Press", component: Press },
    { path: "/contact-us", name: "ContactUs", component: ContactUs },
    {
      path: "/website-subscription",
      name: "WebsiteSubscription",
      component: WebsiteSubscription,
    },
    {
      path: "/subscription",
      component: GuestSubscription,
      name: "subscription",
    },
    { path: "/verify-app", component: VerifyApp, name: "verifyMobile" },
    { path: "/thank-you-page", component: ThankYouPage, name: "thankyoupage" },
    {
      path: "/brand-registration",
      redirect: "bf-registration/basic/yearly",
      // component: RegistrationBrand,
      //component: BrandSignUp,
      // component: BrandBFRegistrationStepOne,
      name: "BrandRegistrationMain",
    },
    {
      path: "/type-registration",
      component: TypeSignUp,
      // component: BrandBFRegistrationStepOne,
      name: "typeRegistration",
    },
    {
      path: "/platform-brand-registration",
      component: TypeSignUp,
      // component: BrandBFRegistrationStepOne,
      name: "platformBrandRegistration",
      params: { type: "1" },
    },
    {
      path: "/platform-retailer-registration",
      component: TypeSignUp,
      // component: BrandBFRegistrationStepOne,
      name: "platformRetailerRegistration",
      params: { type: "2" },
    },
    // {
    //   path: "/bf-registration-obsolete",
    //   component: BrandBFRegistrationStepOne,
    //   name: "BrandBeautyFluentRegistration",
    // },
    {
      path: "/bf-registration",
      component: RegistrationBrand,
      name: "RegistrationBrand",
    },
    {
      path: "/bf-registration/:plan",
      component: RegistrationBrand,
      name: "BrandBeautyFluentRegistrationPlan",
    },
    {
      path: "/bf-registration/:plan/:term",
      component: RegistrationBrand,
      name: "RegistrationBrand",
    },
    {
      path: "/subscription-success",
      component: SubscribeSuccess,
      name: "SubscribeSuccess",
    },
    {
      path: "/copyright-dispute-policy",
      name: "copyrightDisputePolicy",
      component: CopyrightDisputePolicy,
    },
    {
      path: "/unfiltered/:role?",
      name: "unfiltered",
      component: Unfiltered,
    },

    { path: "/memberProfile", name: "memberProfile", component: MemberProfile },
    { path: "/login", name: "login", component: Login },
    {
      path: "/register/:role?",
      name: "register",
      component: Register,
      props: true,
    },
    // { path: "/brand-registration", name: "brandRegistration", component: BrandRegistrationPage },
    {
      path: "/brand-register",
      name: "brandregister",
      component: BrandRegistration,
    },
    { path: "/trial-end", name: "BrandTrialEnd", component: BrandTrialEnd },
    {
      path: "/retailer-registration",
      name: "retailerRegistration",
      component: RetailerRegistration,
    },
    {
      path: "/training/:brandName/:retailerName",
      component: TrainingProductViewAll,
      name: "PublicReadTraining",
    },
    {
      path: "/training/:brandName",
      component: TrainingProductViewAll,
      name: "BrandPublicReadTraining",
    },
    {
      path: "/products/:id",
      component: BrandProduct,
      name: "guestProducts",
      // props: true
    },
    {
      path: "/TrainingProductViewAll",
      component: TrainingProductViewAll,
      name: "TrainingProductViewAll",
    },
    {
      path: "/auth",
      component: Auth,
      children: [
        {
          path: "forgot-password",
          component: ForgotPassword,
          name: "ForgotPassword",
        },
        {
          path: "reset-password",
          component: ResetPassword,
          name: "ResetPassword",
        },
        { path: "welcomePage", component: welcomePage, name: "welcomePage" },
      ],
    },
    { path: "/verify", name: "verify", component: Verify, props: true },
    {
      path: "/add-member",
      name: "AddMember",
      component: AddMember,
      props: true,
    },
    {
      path: "/brand",
      meta: { auth: "brand", layout: "Brand" },
      component: Brands,
      children: [
        {
          path: "membershipExpiringDialog",
          component: MembershipExpiringDialog,
          name: "membershipExpiringDialog",
        },
        {
          path: "/bf-payment",
          //component: BrandBFRegistrationStepOne,
          component: RegistrationBrand,
          name: "BrandBeautyFluentPayment",
        },
        {
          path: "suspended",
          component: Suspended,
          name: "Suspended",
        },
        {
          path: "TrialDayDialog",
          component: TrialDayDialog,
          name: "TrialDayDialog",
        },
        { path: "pending", component: BrandPending, name: "brandPending" },
        {
          path: "dashboard",
          component: BrandDashboard,
          name: "brandDashboard",
        },
        { path: "main", component: BrandMainPage, name: "brandMain" },
        { path: "retailer", component: RetailerCard },
        {
          path: "settings",
          component: BrandSettings,
          name: "brandSettings",
          children: [
            {
              path: "myAccount",
              component: MyAccount,
              name: "brandMyAccount",
            },
            {
              path: "subscription",
              component: SettingSubscription,
              name: "brandSettingsSubscription",
            },
            {
              path: "subscription-platform",
              component: BrandSubscriptionPlatform,
              name: "brandSettingsSubscriptionPlatform",
            },
            {
              path: "subscription-bf",
              component: BrandSubscriptionBF,
              name: "brandSettingsSubscriptionBF",
            },
            {
              path: "subscriptions",
              component: BrandSubscriptions,
              name: "brandSettingsSubscriptions",
            },
            {
              path: "member",
              component: Members,
              name: "brandMember",
            },
            // {
            //   path: "brandLandingTerms",
            //   component: LandingTerms,
            //   name: "brandLandingTerms"
            // },
            {
              path: "LandingTermsMain",
              component: LandingTermsMain,
              name: "LandingTermsMain",
            },
            {
              path: "brandLandingLogistics",
              component: brandLandingLogistics,
              name: "brandLandingLogistics",
            },
            {
              path: "brandLandingTransaction",
              component: LandingTermsTransaction,
              name: "brandLandingTransaction",
            },
            {
              path: "terms",
              component: BrandSettingTerms,
              name: "brandTerms",
            },
            {
              path: "paymentDetail",
              component: PaymentDetails,
              name: "brandPaymentDetails",
            },
            {
              path: "updatePayment",
              component: UpdatePaymentDetails,
              name: "brandUpdatePaymentDetails",
            },
            {
              path: "paymentHistory",
              component: PaymentHistory,
              name: "brandPaymentHistory",
            },
          ],
        },
        { path: "products", component: BrandCatalog, name: "brandCatalog" },
        { path: "products/:id", component: BrandProduct, name: "brandProduct" },
        { path: "profile", component: BrandUserProfile, name: "brandProfile" },
        {
          path: "clean",
          component: BrandCleanPreCheck,
          name: "brandCleanCheck",
          meta: {
            expiredPermissions: true,
          },
        },
        { path: "orders", component: BrandOrders, name: "brandOrders" },
        { path: "orders/:id", component: BrandOrder, name: "brandOrder" },
        {
          path: "oldOrders/:id",
          component: OldOrderDetails,
          name: "OldOrderDetails",
        },
        {
          path: "retailerDirectory",
          component: RetailerDirectory,
          name: "retailerDirectory",
        },
        {
          path: "analytics",
          component: BrandAnalytics,
          name: "brandAnalytics",
        },
        {
          path: "inventory",
          component: AdminBrandInventory,
          name: "brandInventory",
          meta: {
            expiredPermissions: true,
          },
        },
        {
          path: "inventory/:id",
          component: AdminProductInventoryHistory,
          name: "brandProductInventory",
          meta: {
            expiredPermissions: true,
          },
        },
        {
          path: "retailers",
          component: BrandRetailerSearch,
          name: "brandRetailerSearch",
          props: true,
        },
        {
          path: "retailersearch",
          component: BrandRetailerSearch,
          name: "retailerSearch",
          props: true,
        },
        {
          path: "retailer/:retailerId",
          component: RetailerProfile,
          name: "brandRetailerProfile",
          props: true,
          meta: {
            expiredPermissions: true,
          },
        },
        {
          path: "messaging",
          component: BrandMessaging,
          name: "brandMessaging",
        },
        { path: "platform", component: BrandPlatform, name: "brandplatform" },
        {
          path: "subscription",
          component: SelectPaymentPay,
          name: "brandSubscription",
        },
        {
          path: "subsuccess",
          component: PaySubscriptionSuccess,
          name: "brandPaySubscriptionSuccess",
        },
        {
          path: "search",
          component: BrandSearch,
          name: "BrandSearch",
          meta: {
            expiredPermissions: true,
          },
        },
        { path: "buyerBox", component: BrandBuyerBox, name: "BrandBuyerBox" },
        { path: "pitch", component: BrandPitch, name: "brandPitch" },
        {
          path: "training",
          name: "BrandTraining",
          component: BrandTraining,
          meta: {
            expiredPermissions: true,
          },
        },
        {
          path: "createTraining",
          component: BrandTrainingCreate,
          name: "BrandTrainingCreate",
        },
        {
          path: "trainingDetails",
          component: BrandTrainingDetails,
          name: "BrandTrainingDetails",
        },
      ],
    },

    // {
    //   path: "/brand_retailer_search",
    //   meta: { auth: "brand", layout: "Brand" },
    //   component: BrandRetailerSearch
    // },
    {
      path: "/retailer",
      meta: { auth: "retailer", layout: "Retailer" },
      component: Retailers,
      children: [
        {
          path: "verify-email",
          component: RetailerVerifyEmail,
          name: "retailerVerifyEmail",
        },
        {
          path: "dashboard",
          component: RetailerDashboard,
          name: "retailerDashboard",
        },
        { path: "orders", component: RetailerOrders, name: "retailerOrders" },
        { path: "orders/:id", component: RetailerOrder, name: "retailerOrder" },
        {
          path: "oldOrders/:id",
          component: OldOrderDetails,
          name: "OldRetailerOrderDetails",
        },
        { path: "cart", component: RetailerCart, name: "retailerShoppingCart" },
        {
          path: "messaging",
          component: RetailerMessaging,
          name: "retailerMessaging",
        },
        {
          path: "clean",
          component: RetailerCleanPreCheck,
          name: "retailerCleanCheck",
        },
        {
          path: "checkout/:orderBatchId",
          component: RetailerCheckout,
          name: "retailerCheckout",
        },
        {
          path: "brandDirectory",
          component: BrandDirectory,
          name: "brandDirectory",
        },
        {
          path: "checkout-success/:orderBatchId",
          component: RetailerCheckoutSuccess,
          name: "retailerCheckoutSuccess",
          props: true,
        },
        {
          path: "brand/:brandId",
          component: OldRetailerBrandProfile,
          name: "retailerBrandProfile",
          props: true,
        },
        {
          path: "brand/profile/:brandId",
          component: BrandUserProfile,
          name: "retailerBrandOnlyProfile",
          props: true,
        },
        {
          path: "products/:navigationalTagId?",
          component: RetailerProducts,
          name: "retailerProducts",
          props: true,
        },
        {
          path: "products/category/:categoryId",
          component: RetailerProducts,
          name: "retailerProductsByCategory",
          props: true,
        },
        {
          path: "products/new",
          component: RetailerProducts,
          name: "retailerProductsNew",
          props: true,
        },
        {
          path: "products/all",
          component: RetailerProducts,
          name: "retailerProductsAll",
          props: true,
        },
        {
          path: "product/:id",
          component: RetailerBrandProduct,
          name: "retailerProduct",
          props: true,
        },
        { path: "main", component: RetailerMainPage, name: "retailermain" },
        {
          path: "settings",
          component: RetailerSettings,
          name: "retailerSettings",
        },
        {
          path: "profile",
          component: RetailerProfile,
          name: "retailerProfile",
        },
        {
          path: "messaging",
          component: RetailerMessaging,
          name: "retailerMessaging",
        },
        {
          path: "RetailerTraining",
          component: RetailerTraining,
          name: RetailerTraining,
          children: [
            {
              path: "RetailerTrainingBrand",
              component: RetailerTrainingBrand,
              name: "RetailerTrainingBrand",
            },
            {
              path: "retailerTrainingLocation",
              component: retailerTrainingLocation,
              name: "retailerTrainingLocation",
            },
            {
              path: "retailerTrainingUsers",
              component: retailerTrainingUsers,
              name: "retailerTrainingUsers",
            },
            {
              path: "retailerTrainingProduct",
              component: retailerTrainingProduct,
              name: "retailerTrainingProduct",
            },
          ],
        },
      ],
    },
    // {
    //   path: "/training",
    //   meta: { auth: "brand", layout: "Brand" },
    //   component: BrandTraining
    // },
    // Agency Page
    {
      path: "/Agency",
      component: Agency,
    },
    {
      path: "/AgencyDetail",
      component: AgencyDetail,
    },
    {
      path: "/guestRetailer",
      component: GuestRetailer,
      name: "guestRetailer",
    },
    {
      path: "/admin",
      meta: { auth: "admin", layout: "Admin" },
      component: Admins,
      children: [
        { path: "main", component: AdminsMainPage, name: "adminMain" },
        {
          path: "dashboard",
          component: AdminDashboard,
          name: "adminDashboard",
        },
        { path: "orders", component: AdminOrders, name: "adminOrders" },
        { path: "orders/:id", component: AdminOrder, name: "adminOrder" },
        {
          path: "orders-old",
          component: AdminOrdersOld,
          name: "adminOrdersOld",
        },
        {
          path: "oldOrders/:id",
          component: OldOrderDetails,
          name: "OldOrderDetails",
        },
        { path: "clients", component: AdminClients, name: "adminClients" },
        { path: "settings", component: AdminSettings, name: "adminSettings" },
        {
          path: "marketplace",
          component: RetailerProducts,
          name: "adminMarketplace",
        },
        {
          path: "messaging",
          component: AdminMessaging,
          name: "adminMessaging",
        },
        // { path: "adminTraining", component: AdminTraining, name: "AdminTraining" },
        {
          path: "margin",
          component: AdminRetailerMargin,
          name: "adminRetailerMargin",
        },
        {
          path: "inventory",
          component: AdminInventory,
          name: "adminInventory",
        },
        {
          path: "inventory/:brandId",
          component: AdminBrandInventory,
          name: "adminBrandInventory",
        },
        {
          path: "composeMsg",
          component: AdminComposeMessage,
          name: "adminComposeMessage",
        },
        {
          path: "productInventory/:productId?",
          component: AdminProductInventoryHistory,
          name: "adminProductHistoryInventory",
        },
        {
          path: "dirtyIngredient",
          component: AdminRetialerDirtyIngredient,
          name: "adminRetailerDirty",
        },
        {
          path: "retailerSearch",
          component: AdminRetailerSearch,
          name: "adminRetailerSearch",
        },
        {
          path: "retailerEdit",
          component: AdminRetailerEdit,
          name: "adminRetailerEdit",
        },
        {
          path: "brandSearch",
          component: AdminBrandSearch,
          name: "adminBrandSearch",
        },
        {
          path: "brands",
          component: AdminBrandList,
          name: "adminBrandList",
        },
        {
          path: "brandEdit",
          component: AdminBrandEdit,
          name: "adminBrandEdit",
        },
        {
          path: "productEdit",
          component: AdminProductEdit,
          name: "adminProductEdit",
        },
        {
          path: "trainingEdit",
          component: AdminTrainingEdit,
          name: "adminTrainingEdit",
        },
        {
          path: "productSearch",
          component: AdminProductSearch,
          name: "adminProductSearch",
          children: [
            {
              path: "/",
              component: AdminProductSearchHome,
              name: "adminProductSearchHome",
            },
            {
              path: "best",
              component: AdminProductBestSeller,
              name: "adminProductSearchBest",
            },
            {
              path: "result",
              component: AdminProductSearchResult,
              name: "adminProductSearchResult",
            },
            {
              path: "arrival",
              component: AdminProductNewArrival,
              name: "adminProductSearchArrival",
            },
          ],
        },
        {
          path: "adminTraining",
          component: AdminTrainingNew,
          name: "AdminTraining",
          // components: {
          //   brand: "adminTrainingBrand",
          //   retailers: "adminTrainingRetailer",
          //   users: "adminTrainingUser",
          //   loginMessages: "adminTrainingLoginMessage"
          // },
          children: [
            {
              path: "/",
              component: AdminTrainingBrand,
              name: "adminTrainingBrand",
            },
            {
              path: "brand/:brandId",
              component: AdminTrainingBrandProductList,
              name: "adminBrandTrainingProduct",
            },
            {
              path: "retailer",
              component: AdminTrainingRetailer,
              name: "adminTrainingRetailer",
            },
            {
              path: "user",
              component: AdminTrainingUser,
              name: "adminTrainingUser",
            },
            {
              path: "loginMessage",
              component: AdminTrainingLoginMessage,
              name: "adminTrainingLoginMessage",
            },
          ],
        },
        // { path: "brand", component: AdminTrainingBrand, name: "adminTrainingBrand" },
        // {
        //   path: "brand/:brandId",
        //   component: AdminTrainingBrandProductList,
        //   name: "adminBrandTrainingProduct"
        // },
        // { path: "retailer", component: AdminTrainingRetailer, name: "adminTrainingRetailer" },
        // { path: "user", component: AdminTrainingUser, name: "adminTrainingUser" },
        // {
        //   path: "loginMessage",
        //   component: AdminTrainingLoginMessage,
        //   name: "adminTrainingLoginMessage"
        // },
        {
          path: "adminTraining/retailer/:retailerId",
          component: AdminTrainingRetailerViewPage,
          name: "adminTrainingRetailerView",
        },
        {
          path: "system",
          component: AdminSystem,
          name: "adminSystem",
          children: [
            {
              path: "user",
              component: AdminSystemUsers,
              name: "adminSystemUsers",
            },
            {
              path: "group",
              component: AdminSystemGroup,
              name: "adminSystemGroup",
            },
            {
              path: "access",
              component: AdminSystemAccess,
              name: "adminSystemAccess",
            },
            {
              path: "logs",
              component: AdminSystemLogs,
              name: "adminSystemLogs",
            },
            {
              path: "/",
              component: AdminSystemPromoCode,
              name: "adminSystemPromoCode",
            },
            {
              path: "history",
              component: AdminSystemPromoCodeHistory,
              name: "adminSystemPromoCodeHistory",
            },
          ],
        },
        {
          path: "brand/:brandId",
          component: AdminBrand,
          name: "adminBrand",
          props: true,
          children: [
            {
              path: "dashboard",
              component: BrandDashboard,
              name: "adminBrandDashboard",
            },
            { path: "main", component: BrandMainPage, name: "adminBrandMain" },
            {
              path: "profile",
              component: BrandUserProfile,
              name: "adminBrandProfile",
            },
            {
              path: "settings",
              component: BrandSettings,
              name: "adminBrandSettings",
              children: [
                {
                  path: "myAccount",
                  component: MyAccount,
                  name: "adminBrandMyAccount",
                },
                {
                  path: "subscription",
                  component: SettingSubscription,
                  name: "adminBrandSettingsSubscription",
                },
                {
                  path: "subscription-platform",
                  component: BrandSubscriptionPlatform,
                  name: "adminBrandSettingsSubscriptionPlatform",
                },
                {
                  path: "subscription-bf",
                  component: BrandSubscriptionBF,
                  name: "adminBrandSettingsSubscriptionBF",
                },
                {
                  path: "subscriptions",
                  component: BrandSubscriptions,
                  name: "adminBrandSettingsSubscriptions",
                },
                {
                  path: "LandingTermsMain",
                  component: LandingTermsMain,
                  name: "adminLandingTermsMain",
                },
                {
                  path: "adminLandingTerms",
                  component: AdminLandingTerms,
                  name: "adminLandingTerms",
                },
                {
                  path: "adminLandingTermsTF",
                  component: adminLandingTermsTF,
                  name: "adminLandingTermsTF",
                },
                {
                  path: "member",
                  component: Members,
                  name: "adminBrandMember",
                },
                {
                  path: "terms",
                  component: BrandSettingTerms,
                  name: "adminBrandTerms",
                },
                {
                  path: "paymentDetail",
                  component: PaymentDetails,
                  name: "adminBrandPaymentDetails",
                },
                {
                  path: "paymentDetail/updatePayment",
                  component: UpdatePaymentDetails,
                  name: "adminBrandUpdatePaymentDetails",
                },
                {
                  path: "paymentDetail/paymentHistory",
                  component: PaymentHistory,
                  name: "adminBrandPaymentHistory",
                },
              ],
            },
            {
              path: "orders",
              component: BrandOrders,
              name: "adminBrandOrders",
            },
            {
              path: "orders/:id",
              component: BrandOrder,
              name: "adminBrandOrder",
            },
            {
              path: "oldBrandOrders/:id",
              component: OldOrderDetails,
              name: "adminBrandOldOrderDetails",
            },
            {
              path: "products",
              component: BrandCatalog,
              name: "adminBrandCatalog",
            },
            {
              path: "products/:id",
              component: BrandProduct,
              name: "adminBrandProduct",
            },
            {
              path: "clean",
              component: BrandCleanPreCheck,
              name: "adminBrandCleanCheck",
            },
            {
              path: "subscription",
              component: SelectPaymentPay,
              name: "adminBrandSubscription",
            },
            {
              path: "inventory",
              component: AdminBrandInventory,
              name: "adminBrandBrandInventory",
            },
            {
              path: "subscription",
              component: SelectPaymentPay,
              name: "adminBrandSubscription",
            },
            {
              path: "inventory/:id",
              component: AdminProductInventoryHistory,
              name: "adminBrandProductInventory",
            },
            {
              path: "training",
              name: "adminBrandTraining",
              component: BrandTraining,
            },
            {
              path: "createTraining",
              component: BrandTrainingCreate,
              name: "AdminbrandTrainingCreate",
            },
            {
              path: "trainingDetails",
              component: BrandTrainingDetails,
              name: "adminBrandTrainingDetails",
            },
            {
              path: "retailer/:retailerId",
              component: RetailerProfile,
              name: "adminBrandRetailerProfile",
              props: true,
            },
            {
              path: "retailers",
              component: BrandSearch,
              name: "adminBrandRetailerSearch",
              props: true,
            },
            {
              path: "subsuccess",
              component: PaySubscriptionSuccess,
              name: "adminBrandPaySubscriptionSuccess",
            },
            {
              path: "retailerDirectory",
              component: RetailerDirectory,
              name: "adminRetailerDirectory",
            },
          ],
        },
        {
          path: "product/:id",
          component: RetailerBrandProduct,
          name: "adminProduct",
          props: true,
        },
        {
          path: "brandprofile/:brandId",
          component: OldRetailerBrandProfile,
          name: "brandProfileAdmin",
          props: true,
        },
        {
          path: "retailer/:retailerId",
          component: AdminRetailer,
          name: "adminRetailer",
          props: true,
          children: [
            {
              path: "main",
              component: RetailerMainPage,
              name: "adminRetailermain",
            },
            {
              path: "verify-email",
              component: RetailerVerifyEmail,
              name: "adminRetailerVerifyEmail",
            },
            {
              path: "dashboard",
              component: RetailerDashboard,
              name: "adminRetailerDashboard",
            },
            {
              path: "orders",
              component: RetailerOrders,
              name: "adminRetailerOrders",
            },
            {
              path: "orders/:id",
              component: AdminOrder,
              name: "adminRetailerOrder",
            },
            {
              path: "oldRetailsOrders/:id",
              component: OldOrderDetails,
              name: "adminRetailerOldOrderDetails",
            },
            {
              path: "settings",
              component: RetailerSettings,
              name: "adminRetailerSettings",
            },
            {
              path: "profile",
              component: RetailerProfile,
              name: "adminRetailerProfile",
            },
            {
              path: "clean",
              component: RetailerCleanPreCheck,
              name: "adminRetailerCleanCheck",
            },
            {
              path: "messaging",
              component: RetailerMessaging,
              name: "adminRetailerMessaging",
            },
            {
              path: "/TrainingProductViewAll",
              component: TrainingProductViewAll,
              name: "adminTrainingProductViewAll",
            },
            {
              path: "RetailerTraining",
              component: RetailerTraining,
              name: "AdminRetailerTraining",
              children: [
                {
                  path: "RetailerTrainingBrand",
                  component: RetailerTrainingBrand,
                  name: "AdminRetailerTrainingBrand",
                },
                {
                  path: "retailerTrainingLocation",
                  component: retailerTrainingLocation,
                  name: "AdminRetailerTrainingLocation",
                },
                {
                  path: "retailerTrainingUsers",
                  component: retailerTrainingUsers,
                  name: "AdminRetailerTrainingUsers",
                },
                {
                  path: "retailerTrainingProduct",
                  component: retailerTrainingProduct,
                  name: "AdminRetailerTrainingProduct",
                },
              ],
            },
            {
              path: "products/all",
              component: RetailerProducts,
              name: "adminRetailerProductsAll",
            },
            {
              path: "brand/:brandId",
              component: OldRetailerBrandProfile,
              name: "adminRetailerBrandProfile",
              props: true,
            },
            {
              path: "products/:navigationalTagId?",
              component: RetailerProducts,
              name: "adminRetailerProducts",
              props: true,
            },
            {
              path: "product/:id",
              component: RetailerBrandProduct,
              name: "adminRetailerProduct",
              props: true,
            },
            {
              path: "products/category/:categoryId",
              component: RetailerProducts,
              name: "adminRetailerProductsByCategory",
              props: true,
            },
            {
              path: "products/new",
              component: RetailerProducts,
              name: "adminRetailerProductsNew",
              props: true,
            },
            {
              path: "cart",
              component: RetailerCart,
              name: "adminRetailerCart",
            },
            {
              path: "checkout/:orderBatchId",
              component: RetailerCheckout,
              name: "adminRetailerCheckout",
            },
            {
              path: "checkout-success/:orderBatchId",
              component: RetailerCheckoutSuccess,
              name: "adminRetailerCheckoutSuccess",
              props: true,
            },
            {
              path: "brandDirectory",
              component: BrandDirectory,
              name: "adminBrandDirectory",
            },
          ],
        },
        {
          path: "retailerprofile/:retailerId",
          component: RetailerProfile,
          name: "retailerProfileAdmin",
          props: true,
        },
      ],
    },
    {
      path: "/test",
      component: () => import("@/views/Test/Index.vue"),
      name: "Test",
    },
    // NOTE: can damage SEO
    {
      path: "*",
      redirect: "/",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const guard = new AuthGuard(to, from);
  if (guard.userRole() === "brand") {
    const brandStatus = await guard.brandExpiredStatus(
      to,
      from,
      router.options.routes
    );
    if (brandStatus.isNext) {
      if (brandStatus.nextTo) return next({ path: brandStatus.nextTo });
    }
  }
  if (
    !guard.userRole() &&
    (to.name === "BrandTrialEnd" || from.name === "BrandTrialEnd")
  ) {
    return next({ path: "/login" });
  }
  if (guard.validate()) {
    if (
      to.name == "home" ||
      to.name == "login" ||
      to.name == "brandRegistration" ||
      to.name == "register" ||
      to.name == "ForgotPassword" ||
      to.name == "ResetPassword"
    ) {
      if (guard.userRole() == "admin") next({ path: "/admin/dashboard" });
      if (guard.userRole() == "brand") next({ path: "/brand/profile" });
      if (guard.userRole() == "retailer") next({ path: "/retailer/products" });
    }
    const status = await guard.routerLinkPermissions(to, from);
    if (!status) {
      if (from.name) {
        return next({ name: from.name });
      } else {
        if (guard.userRole() == "admin") next({ path: "/admin/dashboard" });
        if (guard.userRole() == "brand") next({ path: "/brand/profile" });
        if (guard.userRole() == "retailer")
          next({ path: "/retailer/products" });
      }
    }
    return next();
  }
  next({ path: "/login" });
});

export default router;
