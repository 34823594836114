var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"comment-item"},[_c('a-col',{staticClass:"comment-item__header",class:_vm.comment.createdBy === _vm.userId ? 'comment-item__float-left' : 'comment-item__float-right',attrs:{"span":2}},[_c('a-avatar',{staticClass:"comment-item__header-avarta",staticStyle:{"background":"#4a8a5d"},attrs:{"size":"large"}},[(_vm.userHead)?_c('img',{attrs:{"slot":"icon","src":_vm.userHead},slot:"icon"}):_vm._e()])],1),_c('a-col',{class:_vm.comment.createdBy === _vm.userId ? 'comment-item__float-right' : 'comment-item__float-left',staticStyle:{"padding-right":"10px","padding-left":"4px"},attrs:{"span":22}},[_c('a-col',{class:_vm.comment.createdBy === _vm.userId ? 'comment-item__float-left' : 'comment-item__float-right',attrs:{"span":20}},[_c('div',{staticClass:"comment-item__message",class:_vm.comment.createdBy === _vm.userId
            ? 'comment-item__message-right'
            : 'comment-item__message-left',style:(_vm.member && _vm.member.type && _vm.member.type.id
            ? _vm.member.type.id === 'brand'
              ? 'background: rgba(74, 138, 93, 0.11);border: 1px solid rgba(74, 138, 93, 0.11)'
              : 'background: #fef2e2;border: 1px solid #fef2e2'
            : 'background: #F5F5F5;border: 1px solid #F5F5F5'),domProps:{"innerHTML":_vm._s(_vm.comment.message)}}),_c('div',{staticClass:"comment-item__message-date",style:(_vm.comment.createdBy === _vm.userId ? 'text-align: left;' : 'text-align: right;')},[_vm._v(" "+_vm._s(_vm.humanReadableDate(_vm.comment.createdAt))+" ")])]),_c('a-col',{class:_vm.comment.createdBy === _vm.userId ? 'comment-item__float-right' : 'comment-item__float-left',attrs:{"span":4}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }