<template>
  <section class="forgot-password__container height-for-sticky-footer">
    <div class="columns is-centered is-flex form-style">
      <div class="column forgot-password__box">
        <div v-if="!isEmailSended" class="forgot-password__form">
          <h2 class="forgot-password__title">
            {{ $t("Auth.ForgotPassword.forgotPasswordQn") }}
          </h2>
          <p class="forgot-password__subtitle">
            {{ $t("Auth.ForgotPassword.forgotPasswordEnterEmail") }}
          </p>
          <form @submit.prevent="requestPasswordReset">
            <div class="field is-marginless">
              <div class="control">
                <input v-model="email" class="input forgot-password__input" type="email"
                  :placeholder="$t('Auth.ForgotPassword.emailAddress')" required />
              </div>
            </div>
            <div class="field has-text-centered">
              <button class="button forgot-password__button is-fullwidth is-black">
                {{ $t("Auth.ForgotPassword.resetYourPassword") }}
              </button>
            </div>
            <div class="forgot-password__divider"></div>
            <div class="forgot-password__links">
              <router-link class="forgot-password__link-login" to="/login">
                {{ $t("Auth.ForgotPassword.backToSignIn") }}
              </router-link>
            </div>
          </form>
        </div>
        <div v-else class="forgot-password__form">
          <h2 class="forgot-password__title">
            {{ $t("Auth.ForgotPassword.welcomeBack") }}
          </h2>
          <p class="forgot-password__subtitle">
            {{ $t("Auth.ForgotPassword.welcomeText2") }}
            <span class="forgot-password__divider--success"></span>
          </p>
          <div class="forgot-password__links">
            <div class="column is-narrow">
              <span>{{ $t("Auth.ForgotPassword.didntReceiveEmail") }}</span>
            </div>
            <div class="column">
              <a class="welcome-back__resend-email" @click="isEmailSended = false">
                {{ $t("Auth.ForgotPassword.tryAgain") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import firebase from "@/services/firebase-service"
export default {
  data() {
    return {
      email: "",
      isEmailSended: false
    }
  },
  methods: {
    async sendPasswordRecoveryLink() {
      try {
        await this.$store.dispatch("user/sendPasswordRecoveryLink", this.email)
        this.$router.push({
          name: "welcomePage",
          query: {
            email: this.email
          }
        })
        this.isEmailSended = true
      } catch (error) {
        console.error(error)
      }
    },
    async requestPasswordReset() {
      try {
        await firebase.auth().sendPasswordResetEmail(this.email);
        this.isEmailSended = true
      } catch (error) {
        console.error(error.message);
        this.isEmailSended = false;
      }
    }
  }
}
</script>

<style lang="sass">
button.button.forgot-password__button.is-fullwidth.is-black
  background-color: #FF6132 !important
  border-radius: 27.5px
  font-family: "ProximaNova-Regular"

.forgot-password__input
    font-family: "ProximaNova-Regular"

.form-style
   margin-top: 50px
   min-height: 70vh !important
.forgot-password
  &__container
    display: flex
    justify-content: center
    align-items: center
  &__box
    width: 360px
    border: 1px solid #E5E5E5
    border-radius: 2px
    height: 335px
    padding: 25px

  &__title
    color: #000000
    font-size: 16px
    letter-spacing: 0.44px
    line-height: 24px
    margin-bottom: 16px

  &__subtitle
    color: #8D8D8D
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px

  &__input
    height: 40px
    border: 1px solid #E5E5E5
    border-radius: 2px
    margin: 15px 0px

  &__button
    height: 46px
    width: 312px
    border-radius: 2px

  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 24px 0px 25px 0px

  &__divider--success
    border-bottom: 1px solid #e5e5e5
    margin: 140px 0px 25px 0px

  &__links
    display: flex
    justify-content: center
    align-items: center

  &__link-title
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px

  &__link-login
    color: #008489
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 17px

.forgot-password__link-login
    color: #12433D
    font-family: "ProximaNova-Regular"

.height-for-sticky-footer
  height: calc(93vh - 72px)
</style>
