<template lang="pug">
a-modal.add-paymentCard-Dialog__mian(
  v-model="isShowDialog"
  :footer="null"
  width="600px"
  @cancel="cancelPayment")
  .guest-payment-component__main-content
    section.payment--form
      p.form--title.heading--5 {{ formTitle }}
      p.form--content.body--normal {{ text }}
      .payment--methods
        .label Payment Methods
        img(src="@/assets/webflowAssets/Payment/method.svg" alt="Methods")
      b-field
        .columns
          .column
            b-input(v-model="firstName" placeholder="First Name")
          .column
            b-input(v-model="lastName" placeholder="Last Name")
      b-field
        b-input(v-model="cardNumber" placeholder="Card Number")
      b-field
        .columns
          .column
            b-input(v-model="cvvNumber" placeholder="CVV")
          .column
            a-date-picker(v-model="date" format="MM/YY" @change="onChangeShipDate" :picker="'month'" placeholder="MM/YY")
      .form--footer
        my-button(variant="is-primary" label="Save" size="is-large" @onClick="submitPayment")
</template>

<script>
import MyButton from "@/components/webflowComponent/Button"
// import AES from "@/common/AES"
// import Utils from "@/common/Utils"
export default {
  name: "AddPaymentCardDialog",
  components: {
    MyButton
  },
  data() {
    return {
      isShowDialog: false,
      isCheck: false,
      formTitle: "The payment method was declined",
      text: "Please enter new details to activate your subscription",
      activeTab: 0,
      firstName: "",
      lastName: "",
      cardNumber: "",
      cvvNumber: "",
      promoCode: "",
      date: null,
      price: 2000,
      showApply: true,
      codeText: [
        {
          type: "is-success",
          message: "This promo code is valid"
        },
        {
          type: "is-danger",
          message: "This promo code is invalid"
        }
      ],
      showCodeText: {},
      amount: 0,
      couponAmount: 0,
      expirationDate: null
    }
  },
  computed: {
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    }
  },
  methods: {
    openAddCardDialog() {
      this.isShowDialog = true
    },
    cancelPayment() {
      this.isShowDialog = false
      if (
        this.$route.name === "BrandTraining" ||
        this.$route.name === "brandCleanCheck" ||
        this.$route.name === "brandOrders" ||
        this.$route.name === "BrandSearch" ||
        this.$route.name === "membershipExpiringDialog" ||
        this.$route.name === "brandInventory"
      ) {
        this.$router.push({
          name: "brandMain"
        })
      }
    },
    removeCode() {
      this.showApply = true
      this.promoCode = ""
      this.showCodeText = {}
      this.price = 2000
    },
    onChangeShipDate(value, dateStr) {
      console.log("date", value, dateStr)
      this.expirationDate = dateStr
    },
    showErrorInfo(message) {
      if (message) {
        this.$message.error(message)
      }
    },
    async submitPayment() {
      const cardInfo = {
        cardNumber: this.cardNumber,
        cardCode: this.cvvNumber,
        expirationDate: this.expirationDate,
        cardHolderName: `${this.firstName} ${this.lastName}`,
        default: true
      }
      await this.$store
        .dispatch("brandPayment/updateCardInfo", {
          brandId: this.brandId,
          cardInfo
        })
        .then(response => {
          if (response.error) {
            // add failed
            this.showErrorInfo(response.developerMessage)
          } else {
            this.isShowDialog = false
            this.$emit("openAddMoreCardDialog")
          }
        })
        .catch(() => {
          this.showErrorInfo(this.$t("Components.Brands.Payment.addCardFailed"))
        })
    },
    jumpToTerms() {
      this.$router.push({
        name: "terms"
      })
    },
    jumpToPrivacy() {
      this.$router.push({
        name: "privacyPolicy"
      })
    }
  }
}
</script>

<style lang="scss">
.add-paymentCard-Dialog__mian {
  .guest-payment-component__main-content {
    p {
      margin-bottom: 0;
    }
    .input,
    .textarea,
    .input:hover,
    .textarea:hover,
    .input:focus,
    .textarea:focus {
      border: 1px solid #f15a42;
      box-sizing: border-box;
      border-radius: 50px;
      padding: 16px 24px;

      font-size: 20px;
      line-height: 22px;
      color: #000000;

      box-shadow: none;
      height: fit-content;
    }
    .payment--form .form--footer {
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;
      text-align: center;
    }

    .payment--form .form--footer button + button {
      margin-left: 25px;
    }

    .payment--form .form--title {
      text-align: left;
      color: #1a1818;
      font-size: 25px;
    }

    .payment--form .form--content {
      margin-bottom: 34px;
      font-size: 20px;
    }

    .payment--form .promo--code {
      position: relative;
    }
    .apply-coupon-code-check {
      position: absolute;
      right: 23px;
      top: 35px;
      font-size: 20px;
      color: #f15a42;
      cursor: pointer;
    }

    .payment--form .promo--code .field label {
      font-family: Niveau Grotesk;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #000000;
    }

    .payment--form .remove--code {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    .total--cost {
      padding: 17px;
      font-family: "Mr Eaves Mod OT";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      background-color: rgba(241, 90, 66, 0.1);
      border-radius: 100px;
      color: black;
    }

    .total--cost span:nth-child(2) {
      font-weight: 400;
      margin-left: 16px;
    }
    .ant-calendar-picker {
      width: 100%;
    }
    .ant-calendar-picker-input.ant-input {
      width: 100%;
      border: 1px solid #f15a42;
      box-sizing: border-box;
      border-radius: 50px;
      padding: 12px 24px;
      font-size: 20px;
    }
    .ant-input {
      height: auto;
    }
    .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: #f15a42;
    }
    .control.has-icons-right .icon.is-right {
      top: 8px;
    }
  }
  .text-info-checkout {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .payment--form .form--content {
    max-width: 300px;
  }
}
</style>
