<template lang="pug">
component(:is="layout" id="app")
  //- notifications(group="alerts" position="bottom right")
  //- router-view
</template>

<script>
import Unfiltered from "./layouts/Unfiltered.vue"
import Guest from "./layouts/Guest.vue"
import Brand from "./layouts/Brand.vue"
import Retailer from "./layouts/Retailer.vue"
import Admin from "./layouts/Admin.vue"
import Blank from "./layouts/Blank.vue"
import { mapActions } from "vuex"

export default {
  name: "App",
  components: { Guest, Unfiltered, Brand, Retailer, Admin, Blank },
  computed: {
    layout() {
      if (this.$route.name == "subscription" || this.$route.name == "verifyMobile") return "Blank"
      return this.$route.name == "unfiltered"
        ? "Unfiltered"
        : this.$store.getters["user/role"] || "Guest"
    }
  },
  async created() {
    // await this.getSwitch()
  },
  methods: {
    ...mapActions({
      getSwitch: "asset/getSwitchStatusInfo"
    })
  }
}
</script>

<style lang="sass">
html,body
  font-family: Proxima Nova
@import "./assets/styles/shared/variables"
@import "./assets/styles/shared/fonts"
@import "./assets/styles/common/common"

@import "~bulma/bulma.sass"
@import "~antd/dist/antd.css"
.nav-link, .has-cursor-pointer
  cursor: pointer

#app
  min-height: 90vh

input[type=range]::-moz-focus-outer
  border: 0
</style>
