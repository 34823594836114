<template lang="pug">
.product-item
  .product-item__thumb-container
    template(v-if="product.images")
      router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerProduct':'adminProduct', params: { id: product.id }}" v-if="isAdmin")
        .product-item__thumb
          img(:src="mainImageUrl(product)")
      router-link(:to="{ name: 'retailerProduct', params: { id: product.id }}" v-else)
        .product-item__thumb
          img(:src="mainImageUrl(product)")
    template(v-else)
      .product-item__thumb
        img(:src="fallbackImage()")
  .productStatus(v-if="product.status&&product.status.id=='discontinued'")
    p {{'DISCONTINUED'}}
  .productStatus(v-if="product.status&&product.status.id=='upcoming'")
    p {{'UPCOMING'}}
  .product-item__body
    .field
      .control.flexButton(:class="{ 'product-item__add-to-cart-button--not-allowed-cursor': product.sample }")
        a.button.is-black.is-outlined.product-item__add-to-cart-button(
          v-show="!productInCart && !productAdded && isShowForAdmin"
          @click='addProductToCart(product, "purchase")'
          :class="{ 'product-item__add-to-cart-button--disabled': product.sample || isRetailerNotApproved || product.status.id === 'discontinued' || product.status.id === 'upcoming' }"
        ) {{$t('Components.Retailers.Products.ProductItem.addToCart')}}
        a.button.is-black.product-item__add-to-cart-button(v-show="productAdded && !productInCart") {{$t('Components.Retailers.Products.ProductItem.addedToCart')}}
        a.button.is-black.is-outlined.product-item__add-to-cart-button.product-item__add-to-cart-button--disabled(v-show="productInCart") {{$t('Components.Retailers.Products.ProductItem.inYourCart')}}
    router-link.product-item__body-brand(v-if="isAdmin"
      :to="{ name:  isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: product.brand.id }}"
    ) {{ brandNameById(product.brand.id) }}
    router-link.product-item__body-brand(v-else
      :to="{ name:  'retailerBrandProfile', params: { brandId: product.brand.id }}"
    ) {{ brandNameById(product.brand.id) }}
    .product-item__body-title(v-if="isAdmin")
      router-link(:to="{ name: isAdminRetailerRoute? 'adminRetailerProduct':'adminProduct', params: { id: product.id }}") {{ product.name }}
    .product-item__body-title(v-else)
      router-link(:to="{ name: 'retailerProduct', params: { id: product.id }}") {{ product.name }}
    .columns.is-marginless
      .column.is-narrow.product-item__body-status(v-if="product.sizeUnits.id!='container'") {{$t('Components.Retailers.Products.ProductItem.size')}} {{ product.size.replace(".0", "") }}{{ product.sizeUnits.id }}
      .column.is-narrow.product-item__body-status(v-if="product.sizeUnits.id=='container'") {{$t('Components.Retailers.Products.ProductItem.size')}} {{ product.size.replace(".0", "") }}{{' ' + product.sizeUnits.id }}
      .column.is-narrow •
      .column.product-item__body-status {{$t('Components.Retailers.Products.ProductItem.sku')}} {{ product.sku }}
    .product-item__body-price
      strong {{ priceFormat(product.msrpUsd) }}
      |&nbsp;
      span {{$t('Components.Retailers.Products.ProductItem.msrp')}}
      //- .control
      //-   a.button.is-black.is-outlined.product-item__samples-request-button(@click='addProductToCart(product.id, "samples-sampling")') Request samples
</template>

<script>
import { productFallbackImage } from "@/mixins/product-fallback-image.js"
import constants from "@/config"

export default {
  mixins: [productFallbackImage],
  props: {
    product: { type: Object, default: () => {} }
  },
  data() {
    return {
      productInCart: false,
      productAdded: false
    }
  },
  computed: {
    isAdminRetailerRoute() {
      return constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isRetailerNotApproved() {
      if (this.isAdmin) {
        return
      }

      return this.$store.getters["retailer/isRetailerPending"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    isShowForAdmin() {
      if (!this.isAdmin) {
        return true
      }
      if (constants.adminRetailerRoutes.includes(this.$route.name)) {
        return true
      } else return false
    }
  },
  created() {
    this.isProductInCart(this.product.id)
  },
  methods: {
    brandNameById(brandId) {
      const brand = this.$store.getters["brand/brandById"](brandId)
      return brand && brand.name
    },
    isVideo(image) {
      const type = [...image.asset.url.split(".")].pop()
      return !["jpeg", "gif", "png", "jpg"].includes(type)
    },
    mainImageUrl(product) {
      return this.mainImage(product) && this.mainImage(product).asset.url
    },
    mainImage(product) {
      if (!product.images) {
        return ""
      }

      const mainImage = product.images.find(image => image.main)
      return mainImage
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    showErrorInfo(product, data) {
      if (data && data.developerMessage) {
        let array = JSON.parse(data.developerMessage)
        let errMsg = ""
        if (array && array.length > 0) {
          array.forEach(item => {
            let key = Object.keys(item)
            if (item[key[0]] === -1) {
              errMsg = `${product.name} inventory not enough!`
            } else {
              errMsg = `${product.name} not in inventory!`
            }
          })
        }
        this.$message.error(errMsg)
        this.productInCart = false
        this.productAdded = false
      } else {
        this.productInCart = true
      }
    },
    canCheckAddCart() {
      const boolList = this.$store.getters["asset/getRouterPermissions"]
      const shoppingCartPermissionsBool = boolList.filter(item => item.id === "shopping-cart")[0]
        .status
      if (!shoppingCartPermissionsBool && !this.isAdmin) {
        this.$message.error(
          "Shopping Cart is unavailable due to a system upgrade. Contact support@landingintl.com with any issues or concerns."
        )
        return false
      } else {
        return true
      }
    },
    async addProductToCart(product, orderTypeId) {
      if (!this.canCheckAddCart()) {
        this.productAdded = false
        return
      }
      this.productAdded = true
      let params
      if (this.isAdmin) {
        params = { "_jwt-c_id": this.retailer.id, "_jwt-c_type": "retailer" }
      }
      this.$store
        .dispatch("retailer/addProductToCart", {
          brand: product.brand,
          productId: product.id,
          orderTypeId: orderTypeId,
          params: params
        })
        .then(data => {
          this.showErrorInfo(product, data)
        })
        .catch(err => {
          this.showErrorInfo(product, err.response.data)
        })
      //setTimeout((this.productInCart = true), 2000)
      await this.$store.dispatch("retailer/fetchCart", {})
    },
    isProductInCart(id) {
      if (
        this.$store.getters["retailer/cartProducts"].find(
          cartProduct => cartProduct.product.id === id
        )
      ) {
        this.productInCart = true
      }
    }
  }
}
</script>

<style lang="sass">
.productStatus
  position: absolute
  top: 14px
  left: 12px
  border: 1px solid rgba(0,0,0,0.38)
  padding: 4px
  p
    color: rgba(0,0,0,0.38)
    font-size: 12px
    letter-spacing: 0.09px
    line-height: 14px
    text-align: center
.flexButton
  display: flex!important
.product-item
  position: relative
  margin-bottom: 50px

  &__thumb-container
    display: flex
    justify-content: center
    align-items: center

  &.best-seller:before
    content: "*BEST SELLER*"
    background: #71cfb3

  &.new:before
    content: "*NEW*"
    background: #fcc9c5

  a
    color: #787474
    text-decoration: none

  &__add-to-cart-button
    height: 33px
    width: 265px
    font-size: 12px
    line-height: 20px

    &--not-allowed-cursor
      cursor: not-allowed

    &--disabled
      color: #8D8D8D !important
      border-color: #8D8D8D !important
      cursor: not-allowed !important
      pointer-events: none

  &__samples-request-button
    height: 33px
    width: 147px
    font-size: 12px
    line-height: 20px

  .product-item__thumb
    background-color: #fff
    padding: 30px
    margin-bottom: 15px
    border-radius: 4px
    overflow: hidden
    height: 264px
    display: flex
    justify-content: center
    align-items: center

    img
      max-width: 100%
      max-height: 100%

  .product-item__video
    display: flex
    background-color: #fff
    padding: 30px
    margin-bottom: 15px
    border-radius: 4px
    overflow: hidden
    height: 264px

  .product-item__body
    color: #888888
    letter-spacing: 0.01em
    font-size: 13px

    &-title
      font-size: 14px
      letter-spacing: 0.2px
      min-height: 42px
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical

      a
        color: black

    &-price
      font-weight: 300
      color: #292929
      font-size: 16px
      letter-spacing: 0.5px
      margin-bottom: 9px

    &-brand
      font-weight: 600
      font-size: 14px
      letter-spacing: 0.2px
      text-transform: uppercase
      color: #292929

    &-status
      color: #757575
      font-size: 12px
      letter-spacing: 0.17px
</style>
