<template>
  <div class="content-area">
    <div class="title">{{ data.contentTitle ? data.contentTitle : data.title }}</div>
    <div class="headline">{{ data.headline }}</div>
    <div class="content">
      <div v-for="(item, index) in data.content.p" :key="'p' + index">{{ item }}</div>
      <li v-for="(item, index) in data.content.features" :key="index">{{ item }}</li>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
}
</script>
<style lang="scss" scope>
@import "@/assets/styles/shared/_fonts";
// this is font and style variable
$titleFont: "Poppins";
$titleColor: #12433d;
$contentFont: "Proxima Nova";
$fontSize: 18px;
$lineHeight: 22px;

.content-area {
  text-align: left;
  margin-top: 120px;
  margin-left: 90px;
  width: 100%;
  max-width: 647px;
  margin-bottom: 70px;

  .title {
    color: $titleColor;
    font-family: 'Poppins SemiBold';
    line-height: 52px;
    margin-bottom: 20px;
    font-size: 48px;
    font-weight: 600;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .headline {
    font-family: "Playfair Display";
    font-size: 31px;
    line-height: 41px;
    margin-bottom: 20px;
    color: black;
  }

  .content {
    font-family: 'Proxima Nova Regular';
    margin-bottom: 20px;
    color: black;
    font-size: $fontSize;
    line-height: $lineHeight;
	
	>div {
		margin-bottom: 20px;
	}

    li {
      margin-bottom: 29px;
    }
  }
}
</style>
