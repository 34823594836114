<template>
  <a-row class="admin-retailer-search" style="padding: 0px;">
    <a-col :span="24" class="admin-console-brand-retailer-product-table">
      <div style="margin-bottom: 4px;">
        <a-button icon="arrow-left" @click="goBack" />
        <a-button
          class="admin-console-product-search-result__download-btn"
          style="float: right; margin: 0px;"
          @click="clickToDownloadData"
        >
          {{ $t("Admins.AdminInventory.csv") }}
          <a-icon type="download" />
        </a-button>
      </div>
      <div style="margin-bottom: 8px;">
        <div class="admin-product-search__left-title">New Arrivals</div>
        <div style="float: right; margin-bottom: 6px;" class="admin-product-search__right-view">
          View
          <a-select
            v-model="newArrivalSelect"
            class="admin-product-search__dropdown-view"
            @change="handleChangeArrival"
          >
            <a-select-option value="30">
              Last 30 days
            </a-select-option>
            <a-select-option value="90">
              Last 90 days
            </a-select-option>
            <a-select-option value="365">
              Last 365 days
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        bordered
        :data-source="newArrivalResult.list"
        :columns="columns"
        :pagination="false"
        :row-key="record => record.privateId"
      >
        <template slot="productNameValue" slot-scope="text, record">
          <a-tooltip v-if="text && text.length > 20" placement="bottom" :title="text">
            <span class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
              text
            }}</span>
          </a-tooltip>
          <span v-else class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
            text
          }}</span>
        </template>
        <template slot="brandName">
          <span> Brand Name </span>
          <a-icon type="caret-up" :rotate="isSortAsc ? 0 : 180" @click="doSortByBrandName" />
        </template>
        <template slot="productName">
          <span>Product Name </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalProductSortAsc ? 0 : 180"
            @click="doSortByProductArrival"
          />
        </template>
        <template slot="sku">
          <span>SKU </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalSkuSortAsc ? 0 : 180"
            @click="doSortBySkuArrival"
          />
        </template>
        <template slot="barCode">
          <span>UPC </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalUpcSortAsc ? 0 : 180"
            @click="doSortByUpcArrival"
          />
        </template>
        <template slot="size">
          <span>Size </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalSizeSortAsc ? 0 : 180"
            @click="doSortBySizeArrival"
          />
        </template>
        <template slot="category">
          <span>Category </span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalCategorySortAsc ? 0 : 180"
            @click="doSortByCategoryArrival"
          />
        </template>
        <template slot="msrpSort">
          <span>MSRP</span>
          <a-icon
            type="caret-up"
            :rotate="isArrivalMsrpSortAsc ? 0 : 180"
            @click="doSortByMsrpArrival"
          />
        </template>
        <template slot="msrp" slot-scope="text">
          <span>{{ priceFormat(text) }}</span>
        </template>
        <template slot="sizeColumn" slot-scope="text, record">
          <span> {{ `${text} ${record.sizeUnitID}` }}</span>
        </template>
      </a-table>
      <a-pagination
        class="inventoryPagination"
        style="margin-top: 10px; float: right;"
        :default-page-size="pageSize"
        :default-current="1"
        :total="newArrivalResult.total"
        size="small"
        :show-quick-jumper="true"
        @change="getNewArrivalData"
      />
    </a-col>
  </a-row>
</template>

<script>
import { priceFormat, downloadCsvDataStr } from "@/utils/validate"
export default {
  data() {
    return {
      priceFormat,
      newArrivalSelect: "90",
      pageSize: 50,
      isArrivalSortAsc: false,
      isArrivalMsrpSortAsc: false,
      isArrivalCategorySortAsc: false,
      isArrivalSizeSortAsc: false,
      isArrivalUpcSortAsc: false,
      isArrivalProductSortAsc: false,
      isArrivalSkuSortAsc: false,
      sortParam: {},
      columns: [
        {
          dataIndex: "rank",
          title: "#",
          width: "50px"
        },
        {
          dataIndex: "brandName",
          slots: { title: "brandName" },
          width: "15%"
          // scopedSlots: { customRender: 'name' },
        },
        {
          // title: "Product Name",
          dataIndex: "name",
          width: "24%",
          ellipsis: true,
          slots: { title: "productName" },
          scopedSlots: { customRender: "productNameValue" }
        },
        {
          // title: "SKU",
          dataIndex: "sku",
          slots: { title: "sku" },
          width: "10%"
        },
        {
          // title: "UPC",
          dataIndex: "barCode",
          slots: { title: "barCode" },
          width: "15%"
        },
        {
          //title: "Size",
          dataIndex: "size",
          slots: { title: "size" },
          scopedSlots: { customRender: "sizeColumn" },
          width: "10%"
        },
        {
          //title: "Category",
          dataIndex: "category",
          slots: { title: "category" },
          width: "14%"
        },
        {
          //title: "MSRP",
          dataIndex: "msrpUsd",
          width: "10%",
          slots: { title: "msrpSort" },
          scopedSlots: { customRender: "msrp" }
        }
      ],
      selectedRecords: new Set()
    }
  },
  computed: {
    newArrivalResult() {
      return this.$store.getters["adminConsole/newArrivals"]
    }
  },
  created() {
    this.newArrivalSelect = this.$route.params.searchParam
    if (this.newArrivalSelect === undefined) {
      this.newArrivalSelect = this.$store.getters["adminConsole/newArrivalSelect"]
    }
    this.getNewArrivalData(1)
  },
  methods: {
    doSortByBrandName() {
      this.isArrivalSortAsc = !this.isArrivalSortAsc
      this.sortParam = {
        "_sort-by": "brand_name",
        "_order-by": this.isArrivalSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData(1)
    },
    doSortByProductArrival() {
      this.isArrivalProductSortAsc = !this.isArrivalProductSortAsc
      this.sortParam = {
        "_sort-by": "name",
        "_order-by": this.isArrivalProductSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData(1)
    },
    doSortBySkuArrival() {
      this.isArrivalSkuSortAsc = !this.isArrivalSkuSortAsc
      this.sortParam = {
        "_sort-by": "sku",
        "_order-by": this.isArrivalSkuSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData(1)
    },
    doSortByUpcArrival() {
      this.isArrivalUpcSortAsc = !this.isArrivalUpcSortAsc
      this.sortParam = {
        "_sort-by": "bar_code",
        "_order-by": this.isArrivalUpcSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData(1)
    },
    doSortBySizeArrival() {
      this.isArrivalSizeSortAsc = !this.isArrivalSizeSortAsc
      this.sortParam = {
        "_sort-by": "size",
        "_order-by": this.isArrivalSizeSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData(1)
    },
    doSortByCategoryArrival() {
      this.isArrivalCategorySortAsc = !this.isArrivalCategorySortAsc
      this.sortParam = {
        "_sort-by": "category_name",
        "_order-by": this.isArrivalCategorySortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData(1)
    },
    doSortByMsrpArrival() {
      this.isArrivalMsrpSortAsc = !this.isArrivalMsrpSortAsc
      this.sortParam = {
        "_sort-by": " msrp_usd",
        "_order-by": this.isArrivalMsrpSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getNewArrivalData(1)
    },
    getParam(page) {
      return {
        ...this.sortParam,
        _limit: this.pageSize,
        _index: page,
        "last-days": this.newArrivalSelect
      }
    },
    async getNewArrivalData(page) {
      this.$store.commit("adminConsole/SET_ARRIVAL_SELECT_PARAM", this.newArrivalSelect)
      await this.$store.dispatch("adminConsole/fetchNewArrival", this.getParam(page))
    },
    handleChangeArrival() {
      this.getNewArrivalData(1)
    },
    clickToProductPage(item) {
      this.$router.push({
        name: "adminProduct",
        params: {
          id: item.id
        }
      })
    },
    async clickToDownloadData() {
      let page = this.newArrivalResult ? this.newArrivalResult.index : 1
      await this.$store
        .dispatch("adminConsole/downloadNewArrival", this.getParam(page))
        .then(result => {
          let fileName = `product_new_arrival_result.csv`
          this.download(result, fileName)
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    async download(data, fileName) {
      await downloadCsvDataStr(data, fileName)
        .then(() => {
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
