<template>
  <div class="order-payment-count__main">
    <p v-if="!hasRetailer" class="retailer-payment-title">{{ retailerName }} Order</p>
    <!--    retailer to brand-->
    <div class="payment-count-content">
      <div class="payment-item" :style="{ fontSize: hasRetailer ? '12px' : '14px' }">
        <span :style="{ color: hasRetailer ? '#8c8c8c' : '#292929' }">Subtotal</span>
        <span>{{ subtotal }}</span>
      </div>
      <div
        v-for="item in retailerToBrandList"
        :key="`retailer_${item.privateId}`"
        class="payment-item"
        :style="{ fontSize: hasRetailer ? '12px' : '14px' }"
      >
        <span :style="{ color: hasRetailer ? '#8c8c8c' : '#292929' }">{{ item.textValue }}</span>
        <span>{{ item.payNumber }}</span>
      </div>
      <div class="payment-item" :style="{ marginBottom: hasRetailer ? '40px' : '0' }">
        <span class="special-total">Total</span>
        <span class="special-total">{{ retailerToBrandTotal }}</span>
      </div>
    </div>
    <a-divider v-if="!hasRetailer" />
    <p v-if="!hasRetailer" class="retailer-payment-title">Your Payment Calculation</p>
    <!--    brand to landing-->
    <div v-if="showBrandToLandingData" class="payment-count-content">
      <div v-if="!hasRetailer" class="payment-item">
        <span>Total</span>
        <span>{{ retailerToBrandTotal }}</span>
      </div>
      <div
        v-for="item in brandToLandingList"
        :key="`retailer_${item.privateId}`"
        class="payment-item"
        :style="{ fontSize: hasRetailer ? '12px' : '14px' }"
      >
        <span :style="{ color: hasRetailer ? '#8c8c8c' : '#292929' }">{{ item.textValue }}</span>
        <span v-if="item.isEdit && hasEdit">
          <a-select v-model="typeValue" style="width: 80px;" @change="sendTypes">
            <a-select-option v-for="value in selectType" :key="value.id" :value="value.name">
              {{ value.name }}
            </a-select-option>
          </a-select>
          <a-input v-model="chargeAmount" prefix="$" style="width: 80px;" @change="sendNumber()" />
        </span>
        <span v-else>{{ item.payNumber }}</span>
      </div>
      <div class="payment-item">
        <span class="special-total">Your Payment</span>
        <span class="special-total">{{ paymentTotal }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { priceFormat, getOrderRetailerPayAmount, getOrderBrandPayAmount } from "@/utils/validate"

export default {
  name: "OrderPaymentCount",
  props: {
    retailerName: { type: String, default: "--" },
    hasRetailer: { type: Boolean, default: false },
    hasEdit: { type: Boolean, default: false },
    order: { type: Object, default: () => {} },
    isPayment: { type: Boolean, default: false },
    tabId: { type: Number, default: 1 }
    // chargeList: { type: Array, default: () => [] }
  },
  data() {
    return {
      priceFormat,
      chargeAmount: null,
      typeValue: null,
      sendType: null,
      selectType: [
        {
          id: "credit",
          name: "+"
        },
        {
          id: "debit",
          name: "-"
        }
      ]
    }
  },
  computed: {
    subtotal() {
      return this.order && this.order.calculatedWholesaleCostUsd
        ? priceFormat(this.order.calculatedWholesaleCostUsd)
        : priceFormat(0)
    },
    retailerToBrandList() {
      if (!this.order.charges || this.order.charges.length === 0) {
        return []
      }
      let list = this.order.charges.filter(
        item =>
          (item.ownedBy.id === "retailer" && item.paidBy.id === "brand") ||
          (item.ownedBy.id === "brand" && item.paidBy.id === "retailer")
      )
      if (list && list.length === 0) {
        return []
      }
      list = list.map(value => {
        if (value.chargeType.id === "custom" && value.ownedBy.id === "retailer") {
          value.textValue = "Other Terms"
          value.payNumber = `(${priceFormat(Math.round(value.calculatedCostUsd * 100) / 100)})`
        } else if (value.chargeType.id === "custom" && value.ownedBy.id === "brand") {
          value.textValue = "Additional Charge"
          value.payNumber = `${priceFormat(Math.round(value.calculatedCostUsd * 100) / 100)}`
        } else {
          value.textValue = value.chargeType.name
          value.payNumber = `(${priceFormat(Math.round(value.calculatedCostUsd * 100) / 100)})`
        }
        return value
      })
      return list.filter(item => Number(item.calculatedCostUsd) !== 0)
    },
    retailerToBrandTotal() {
      const total = getOrderRetailerPayAmount(this.order)
      return priceFormat(total)
    },
    brandToLandingList() {
      if (this.order.charges && this.order.charges.length !== 0) {
        const list = this.order.charges.filter(
          item =>
            (item.ownedBy.id === "brand" && item.paidBy.id === "landing") ||
            (item.ownedBy.id === "landing" && item.paidBy.id === "brand")
        )
        let finallyList = list.map(value => {
          if (value.chargeType.id === "custom" && value.ownedBy.id === "brand") {
            value.textValue = "Other Charge"
            value.isEdit = true
            if (this.hasEdit) {
              value.payNumber = value.calculatedCostUsd
            } else if (value.paymentType.id === "credit") {
              value.payNumber = `${priceFormat(Math.round(value.calculatedCostUsd * 100) / 100)}`
            } else {
              value.payNumber = `(${priceFormat(Math.round(value.calculatedCostUsd * 100) / 100)})`
            }
          } else {
            value.textValue = value.chargeType.name
            value.isEdit = false
            value.payNumber = `(${priceFormat(Math.round(value.calculatedCostUsd * 100) / 100)})`
          }
          return value
        })
        const result = finallyList.filter(
          item => item.chargeType.id === "custom" && item.ownedBy.id === "brand"
        )
        if (result && result.length === 0 && this.isPayment) {
          const obj = {
            textValue: "Other Charge",
            isEdit: true,
            payNumber: 0
          }
          finallyList.push(obj)
        }
        return finallyList.filter(item => Number(item.calculatedCostUsd) !== 0)
      }
      return []
    },
    paymentTotal() {
      const type = this.typeValue === "+" ? "Credit" : "Debit"
      const retailerToBrandNumber = getOrderRetailerPayAmount(this.order)
      const value1 = getOrderBrandPayAmount(this.order)
      let total = (retailerToBrandNumber * 10000 - value1 * 10000) / 10000
      if (this.chargeAmount) {
        const value2 = getOrderBrandPayAmount(this.order, this.chargeAmount, type)
        total = (retailerToBrandNumber * 10000 - value2 * 10000) / 10000
      }
      total = Math.round(total * 100) / 100
      return priceFormat(total)
    },
    showBrandToLandingData() {
      if (
        this.tabId === 3
        // (this.$route.name === "adminBrandOrder" || this.$route.name === "brandOrder")
      ) {
        return false
      } else if (
        this.$route.name === "retailerOrder" ||
        this.$route.name === "adminRetailerOrder"
      ) {
        return false
      } else {
        return true
      }
    }
  },
  mounted() {
    this.getOtherChargeValue()
  },
  methods: {
    getOtherChargeValue() {
      if (this.order.charges && this.order.charges.length !== 0) {
        const list = this.order.charges.filter(
          item =>
            (item.ownedBy.id === "brand" && item.paidBy.id === "landing") ||
            (item.ownedBy.id === "landing" && item.paidBy.id === "brand")
        )
        if (list && list.length !== 0) {
          const otherValue = list.filter(
            item => item.chargeType.id === "custom" && item.ownedBy.id === "brand"
          )
          this.chargeAmount =
            otherValue && otherValue.length !== 0 ? otherValue[0].calculatedCostUsd : null
          this.typeValue =
            otherValue && otherValue.length !== 0 ? otherValue[0].paymentType.name : null
          if (otherValue && otherValue.length !== 0) {
            this.typeValue = otherValue[0].paymentType.id === "credit" ? "+" : "-"
          }
        }
      }
    },
    sendNumber() {
      this.sendType = this.typeValue === "+" ? "Credit" : "Debit"
      this.$emit("handlePaymentValue", this.sendType, this.chargeAmount)
    },
    sendTypes() {
      this.sendType = this.typeValue === "+" ? "Credit" : "Debit"
      this.$emit("handlePaymentValue", this.sendType, this.chargeAmount)
    }
  }
}
</script>

<style scoped lang="scss">
.order-payment-count__main {
  min-height: 100px;
  p {
    margin-bottom: 0;
  }
  .retailer-payment-title {
    color: #8c8c8c;
    margin-bottom: 10px;
  }
  .payment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    span {
      color: #262626;
      font-size: 14px;
    }
  }
  .special-total {
    font-weight: 700;
  }
}
</style>
