<template>
  <ProductCard v-if="product" :product="product" :is-from-inventory="isFromInvertory" />
</template>
<script>
import ProductCard from "@/components/retailers/products/ProductCard"
export default {
  components: {
    ProductCard
  },
  props: { id: { type: String, default: "" }, isFromInvertory: { type: Boolean, default: false} },
  data() {
    return {
      product: null
    }
  },
  async created() {
    await this.$store.dispatch("product/getProduct", this.id)
    this.product = this.$store.getters["product/product"]

    this.$store.dispatch("brand/getBrand", this.product.brand.id)
  }
}
</script>
