<template lang="pug">
.retailer-checkout
  .retailer-checkout__title {{$t('Retailers.RetailerCheckout.checkout')}}
  .columns
    .column.is-8
      .order-details
        .order-details__shipping-address
          .columns
            .column.is-6
              RetailerAddress(@selectedAddressChange="updateSelectedAddress")
              p.help.is-danger.login__input-error(v-show="showAddressValidation") {{showAddressValidation}}
            .column.is-6
              RetailerShippingWindow(:orderShipDate="order.shipDate" :orderCancelDate="order.cancelDate" @shippingWindowChange="updateOrderDates")

      .order-details.order-details__dates-container
        .columns
          .column.is-6
            RetailerContactInformation(@contactInfoChange="updateContactInfo" :name="order.contact.name" :phone="order.contact.phone")
            p.help.is-danger.login__input-error(v-show="showContactValidation") Contact info is required.
          .column.is-6
            .field
              .order-details__title  {{$t('Retailers.RetailerCheckout.referenceId')}}
              .control
                input.input.is-fullwidth(v-model="order.retailerReferenceId" :placeholder="$t('Retailers.RetailerCheckout.enterReferenceId')")
              .order-details__tip {{$t('Retailers.RetailerCheckout.addReferenceNumberText')}}

      .order-details.order-details__dates-container
        .order-details__terms-title {{$t('Retailers.RetailerCheckout.terms')}}
        .columns
          .column.is-3
            .order-details__terms-subtitle {{$t('Retailers.RetailerCheckout.paymentTerms')}}
            .order-details__terms-info {{ retailer.terms.paymentTerm.name }}
          .column
            .order-details__terms-subtitle {{$t('Retailers.RetailerCheckout.compliance')}}
            .order-details__terms-info(v-show="retailer.terms.usDomesticShippingObligation === 'retailer'") {{$t('Retailers.RetailerCheckout.uSDomesticShipping')}}
            .order-details__terms-info(v-show="retailer.terms.productTicketingRequired") {{$t('Retailers.RetailerCheckout.productTicketing')}}
            .order-details__terms-info(v-show="retailer.terms.ediRequired") {{$t('Retailers.RetailerCheckout.eDI')}}
            .order-details__terms-info(v-show="retailer.terms.routingGuideRequired") {{$t('Retailers.RetailerCheckout.vendorGuide')}}

    .column
      .order-summary
        .order-summary__title {{$t('Retailers.RetailerCheckout.orderSummary')}}
        .order-summary__brand-order(v-show="cartProductGroup.length" v-for="cartProductGroup in cartProductGroups")
          p {{ brandNameById(cartProductGroup[0].product.brand.id) }}
          p {{ priceFormat(cartProductGroupSubtotal(cartProductGroup)) }}
        .order-summary__brand-order
          p Retailer Charges
          p(v-if="additionalChargeTotal<0") ({{ priceFormat(additionalChargeTotal * -1) }})
          p(v-else) {{ priceFormat(additionalChargeTotal) }}
        .order-summary__brand-order
          p {{$t('Retailers.RetailerCheckout.estimatedTotal')}}
          p {{ priceFormat(cartProductsTotal() + additionalChargeTotal) }}
      .order-summary__submit-button-container
          button.button.is-black.is-fullwidth.order-summary__submit-button(@click.prevent="placeOrder" :disbaled="isOrderPlaced") {{$t('Retailers.RetailerCheckout.placeOrder')}}

  .columns
    .column.is-8
      .additional-charges
        .additional-charges__title Retailer Terms
          span.retailer-terms-edit(@click="addTerms") Add Terms
        .columns.is-multiline
          .column.is-12
            .columns.additional-charges__header
              .column.additional-charges__header-cell Retailer Terms
              .column.additional-charges__header-cell type
              .column.additional-charges__header-cell.has-text-right {{$t('Retailers.RetailerCheckout.description')}}
              .column.additional-charges__header-cell.has-text-right {{$t('Retailers.RetailerCheckout.amount')}}
          .column.is-12(v-for="charge in additionalCharges")
            .columns.additional-charges__row
              .column {{charge.note}} ({{Number(charge.recordedValue).toFixed(1)}}%)
              .column Credit
              //.column(v-if="!charge.note") -
              //.column(v-if="charge.note") {{charge.note}}
              .column.has-text-right ({{ priceFormat(charge.calculatedCostUsd) }})
              .column.has-text-right(v-if="charge.paidBy.id == 'brand'") ({{ priceFormat(charge.calculatedCostUsd) }})
              .column.has-text-right(v-if="charge.paidBy.id == 'retailer'") {{ priceFormat(charge.calculatedCostUsd) }}
          .column.is-12
            .columns.additional-charges__subtotal
              .column.is-10.retailer-terms-total-style.has-text-right Total
              .column.additional-charges__subtotal-item(v-if="additionalChargeTotal<0") {{ priceFormat(additionalChargeTotal * -1) }}
              .column.additional-charges__subtotal-item(v-else) {{ priceFormat(additionalChargeTotal) }}

  .columns
    .column.is-8
      .order-items
        .order-items__title {{$t('Retailers.RetailerCheckout.orderItems')}}
        .columns.is-multiline
          .column.is-12
            .order-items__container(v-show="cartProductGroup.length" v-for="cartProductGroup in cartProductGroups")
              .order-items__banner
                .columns
                  .column.is-3.order-items__brand-name {{ brandNameById(cartProductGroup[0].product.brand.id) }}
                  .column.order-items__brand-amount {{ priceFormat(cartProductGroupSubtotal(cartProductGroup)) }}
              .columns.order-items__header
                .column.is-8.has-text-right.order-items__header--margin {{$t('Retailers.RetailerCheckout.mSRP')}}
                .column.is-2.has-text-centered {{$t('Retailers.RetailerCheckout.unitPrice')}}
                .column {{$t('Retailers.RetailerCheckout.amount')}}
              .columns.is-marginless.order-items__body(v-for="cartProduct in cartProductGroup")
                .column.order-items__image
                  img(:src="mainImageUrl(cartProduct.product)")
                .column.is-3
                  .order-items__name {{ cartProduct.product.name }}
                  .order-items__details {{ cartProduct.product.size }} · {{ cartProduct.product.sku }}
                .column.is-3.has-text-left.order-items__quantity
                  div(v-for="orderDetail in cartProduct.orderDetails")
                    .order-items__quantity-type(v-if="orderDetail.orderType.id === 'purchase'") {{ orderDetail.quantity }} {{$t('Retailers.RetailerCheckout.units')}}
                    div(v-else) {{ orderDetail.quantity }} {{$t('Retailers.RetailerCheckout.testers')}}
                .column
                  .order-items__msrp {{ priceFormat(cartProduct.product.msrpUsd) }}
                .column
                  .order-items__unit-price {{ priceFormat(cartProduct.product.wholesalePriceUsd) }}
                .column
                  .order-items__amount {{ priceFormat(productAmount(cartProduct)) }}

  //.columns
    .column.is-8
      .additional-charges
        .additional-charges__title {{$t('Retailers.RetailerCheckout.additionalCharges')}}
        .columns.is-multiline
          .column.is-12
            .columns.additional-charges__header
              .column.additional-charges__header-cell {{$t('Retailers.RetailerCheckout.additionalCharge')}}
              .column.additional-charges__header-cell {{$t('Retailers.RetailerCheckout.description')}}
              .column.additional-charges__header-cell.has-text-right {{$t('Retailers.RetailerCheckout.amount')}}
          .column.is-12(v-for="charge in additionalCharges")
            .columns.additional-charges__row
              .column {{charge.chargeType.name}} ({{charge.recordedValue}}%)
              .column(v-if="!charge.note") -
              .column(v-if="charge.note") {{charge.note}}
              .column.has-text-right(v-if="charge.paidBy.id == 'brand'") ({{ priceFormat(charge.calculatedCostUsd) }})
              .column.has-text-right(v-if="charge.paidBy.id == 'retailer'") {{ priceFormat(charge.calculatedCostUsd) }}
          .column.is-12
            .columns.additional-charges__subtotal
              .column.is-10.additional-charges__subtotal-item--bold.has-text-right {{$t('Retailers.RetailerCheckout.additionalChargesSubtotal')}}
              .column.additional-charges__subtotal-item(v-if="additionalChargeTotal<0") ({{ priceFormat(additionalChargeTotal * -1) }})
              .column.additional-charges__subtotal-item(v-else) {{ priceFormat(additionalChargeTotal) }}
  RetailerTerms(ref="addRetailerTerms" @updateRetailerTerms="updateRetailerTerms")
</template>

<script>
import RetailerAddress from "@/components/retailers/checkout/RetailerAddress"
import RetailerShippingWindow from "@/components/retailers/checkout/RetailerShippingWindow"
import RetailerContactInformation from "@/components/retailers/checkout/RetailerContactInformation"
import RetailerTerms from "@/components/orders/cartAddRetailerTermsModal"

export default {
  components: {
    RetailerAddress,
    RetailerShippingWindow,
    RetailerContactInformation,
    RetailerTerms
  },
  data() {
    return {
      order: {
        isRetailerAccepted: true,
        retailerReferenceId: "",
        orderDate: new Date().toISOString().split("T")[0],
        shipDate: "",
        cancelDate: "",
        shipToAddress: {
          addressee: "",
          streetAddressLine_1: "",
          streetAddressLine_2: "",
          city: "",
          region: "",
          postalCode: "",
          country: { id: "" }
        },
        contact: {
          name: "",
          phone: ""
        }
      },
      isActive: false,
      isOrderPlaced: true,
      showContactValidation: false,
      showAddressValidation: null,
      totalAdditionalCharge: 0,
      charges: []
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    additionalChargeTotal() {
      return (
        this.additionalCharges &&
        this.additionalCharges.reduce((total, item) => {
          if (item.paidBy.id == "brand") {
            return parseFloat(total) - parseFloat(item.calculatedCostUsd)
          } else {
            return parseFloat(total) + parseFloat(item.calculatedCostUsd)
          }
        }, 0)
      )
    },
    additionalCharges() {
      if (this.role === "retailer") {
        return this.charges.length > 0 && this.charges.filter(item => item.seenByRetailer)
      } else {
        return this.charges.length > 0 && this.charges.filter(item => item.seenByLanding)
      }
    },
    orders() {
      return this.$store.getters["order/orders"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    orderBatchId() {
      return this.$route.params.orderBatchId
    },
    cartProductGroups() {
      return this.$store.getters["retailer/cartProductGroups"]
        .map(cartProductGroup => cartProductGroup.products)
        .map(cartProductGroup => cartProductGroup.filter(cartProduct => !cartProduct.isSaved))
    },
    retailerId() {
      return this.$route.name == "adminRetailerCheckout"
        ? this.$route.params.retailerId
        : this.$store.getters["user/businessId"]
    }
  },
  async created() {
    let params = {}
    if (this.$route.name == "adminRetailerCheckout") {
      params = { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
    }
    await this.getCartProducts()
    const productIds = this.$store.getters["retailer/cartProducts"]
      .filter(item => !item.saved)
      .map(cartProduct => cartProduct.product.id)
    await this.$store.dispatch("product/getProductsByIds", { productIds, params })
    this.$store.dispatch("retailer/setCartProductGroups")
    this.setInitialAddress()
    this.$store.dispatch("retailer/fetchRetailer", this.retailerId)
    this.$store.dispatch("brand/fetchAllBrands", { params: { fields: "name,id" } })
    this.additionalChargesSum()
  },
  methods: {
    async additionalChargesSum() {
      try {
        await this.$store.dispatch("order/getOrdersByBatch", {
          batchId: this.orderBatchId,
          params: { batch: true }
        })
        this.orders.forEach(order => {
          if (this.charges.length === 0) {
            this.charges = order.charges
          } else {
            order.charges.forEach(charge => {
              this.charges.forEach(element => {
                if (charge.chargeType.id === element.chargeType.id) {
                  element.calculatedCostUsd =
                    parseFloat(charge.calculatedCostUsd) + parseFloat(element.calculatedCostUsd)
                }
              })
            })
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    async getCartProducts() {
      let params = {}
      if (this.$route.name == "adminRetailerCheckout") {
        params = { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
      }
      await this.$store.dispatch("retailer/fetchCart", params)
    },
    updateSelectedAddress(address) {
      const { hq, label, createdAt, createdBy, ...addressForApi } = address
      this.order.shipToAddress = addressForApi
      this.showAddressValidation = null
    },
    updateContactInfo(name, phone) {
      this.order.contact.name = name
      this.order.contact.phone = phone
      this.showContactValidation = false
    },
    updateOrderDates(shipDate, cancelDate) {
      this.order.shipDate = shipDate
      this.order.cancelDate = cancelDate
    },
    async setInitialAddress() {
      await this.$store.dispatch("retailerAddress/fetchAddresses", this.retailerId)
      const defaultAddress = this.$store.getters["retailerAddress/defaultAddress"]
      if (defaultAddress) {
        this.updateSelectedAddress(defaultAddress)
      }
    },
    displayError(error) {
      const vm = this
      if (error.message) {
        return vm.$notify({
          group: "alerts",
          type: "error",
          title: error.status,
          text: error.message
        })
      }
    },
    isNotValidAddress() {
      this.showAddressValidation = null
      if (!this.order.shipToAddress) {
        this.showAddressValidation = "Shipment Address is required."
      }
      if (!this.order.shipToAddress.addressee) {
        this.showAddressValidation = "Addressee is required in the shipping address."
      }
      if (!this.order.shipToAddress.postalCode) {
        this.showAddressValidation = "Postal Code is required in the shipping address."
      }
      if (!this.order.shipToAddress.city) {
        this.showAddressValidation = "City is required in the shipping address."
      }
      return this.showAddressValidation
    },
    async placeOrder() {
      this.isOrderPlaced = true
      if (this.isNotValidAddress()) {
        return
      }

      if (!(this.order.contact.name && this.order.contact.phone)) {
        this.showContactValidation = true
      }
      if (this.showAddressValidation || this.showContactValidation) return
      this.order.retailer = { id: this.retailer.id }
      this.order.status = { id: "pending" }
      try {
        let order = this.order
        order.id = this.orderBatchId
        order.version = 2
        order.charges = this.charges
        let params =
          this.$route.name == "adminRetailerCheckout"
            ? { "_jwt-c_id": this.$route.params.retailerId, "_jwt-c_type": "retailer" }
            : {}
        params.batch = true
        await this.$store.dispatch("order/updateStatus", { order, params })
        this.getCartProducts()
        this.$route.name == "adminRetailerCheckout"
          ? this.$router.push({
              name: "adminRetailerCheckoutSuccess",
              params: { orderBatchId: this.orderBatchId }
            })
          : this.$router.push({
              name: "retailerCheckoutSuccess",
              params: { orderBatchId: this.orderBatchId }
            })
      } catch (error) {
        this.displayError(error)
      }
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },

    countryNameById(countryId) {
      const country = this.countries.find(country => country.id === countryId)
      return country && country.name
    },

    mainImageUrl(product) {
      const mainImage = product.images.find(image => image.main)
      return mainImage && mainImage.asset.url
    },
    brandNameById(brandId) {
      const brand = this.$store.getters["brand/brandById"](brandId)
      return brand && brand.name
    },
    productAmount(cartProduct) {
      return cartProduct.orderDetails
        .map(orderDetail =>
          orderDetail.orderType.id === "purchase"
            ? parseFloat(orderDetail.quantity) * parseFloat(cartProduct.product.wholesalePriceUsd)
            : 0
        )
        .reduce((a, b) => {
          return a + b
        }, 0)
    },
    cartProductGroupSubtotal(cartProductGroup) {
      const productsPrices = cartProductGroup.map(cartProduct =>
        cartProduct.orderDetails
          .map(orderDetail =>
            orderDetail.orderType.id === "purchase"
              ? parseFloat(orderDetail.quantity) * parseFloat(cartProduct.product.wholesalePriceUsd)
              : 0
          )
          .reduce((a, b) => {
            return a + b
          }, 0)
      )
      return productsPrices.reduce((a, b) => {
        return a + b
      }, 0)
    },
    cartProductsTotal() {
      return this.cartProductGroups
        .map(cartProductGroup => this.cartProductGroupSubtotal(cartProductGroup))
        .reduce((a, b) => {
          return a + b
        }, 0)
    },
    // add retailer terms
    addTerms() {
      const buttonColor = "#000000"
      const total = this.cartProductsTotal(this.cartProductGroups)
      this.$refs["addRetailerTerms"].openDialog(this.charges, buttonColor, total)
    },
    updateRetailerTerms(list) {
      this.charges = list
    }
  }
}
</script>

<style lang="sass">
.retailer-checkout
  padding-bottom: 40px
  padding-left: 122px
  padding-right: 122px

  &__title
    color: #212B36
    font-family: "Proxima"
    font-size: 28px
    font-weight: 300
    margin: 16px 0

  .order-details
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    padding: 25px
    border: 1px solid #E5E5E5
    border-radius: 4px

    &__dates-container
      margin-top: 10px

    &__title
      color: #000000
      font-size: 18px
      letter-spacing: 0.25px
      margin-bottom: 24px

    &__subtitle
      font-size: 18px
      font-weight: 500 !important

    &__tip
      color: #8D8D8D
      padding-top: 5px

    &__shipping-address
      color: #000000
      font-size: 14px
      letter-spacing: 0.25px

    &__key-dates
      &--cancel
        padding-top: 20px

    &__terms-title
      color: #000000
      font-size: 18px
      letter-spacing: 0.25px
      margin-bottom: 15px

    &__terms-subtitle
      font-size: 12px
      color: #888888
      text-transform: uppercase
      margin-bottom: 15px

    &__reference
      max-width: 250px

    &__square-box
      box-sizing: border-box
      height: 222px
      width: 224px
      border: 1px solid #9B9B9B
      border-radius: 4px
      margin-right: 16px
      margin-top: 25px
      margin-bottom: 23px
      display: flex
      justify-content: center
      align-items: center

    &_plus
      font-size: 70px
      text-align: center
      margin-bottom: -30px
      margin-top: -30px

    &__dates
      margin-right: 26px

.right-padding
  padding-right: 5px

.retailer-terms-edit
  margin-left: 52px
  font-size: 14px
  color: #262626
  text-decoration: underline
  cursor: pointer

.left-padding
  padding-left: 10px

.order-summary
  border: 1px solid #E5E5E5
  margin-left: 25px

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.25px
    line-height: 24px
    padding: 24px 23px

  &__brand-order
    display: flex
    justify-content: space-between
    height: 44px
    align-items: center
    margin: 0px 19px
    border-top: 1px solid #E5E5E5
    margin: 0px 16px

  &__submit-button-container
    margin-left: 25px
    margin-top: 15px

  &__submit-button
    height: 46px
    font-size: 12px

.order-items
  &__container
    border: 1px solid #E6E6E6

  &__banner
    height: 80px
    background-color: #F4F6F8
    position: relative
    border-top: 3px solid rgba(145,158,171,0.54)

  &__brand-name
    margin: 30px 10px 30px 25px
    color: #333333
    font-size: 16px

  &__brand-amount
    margin: 30px 0px 30px
    color: #AAAAAA
    font-size: 16px

  &__title
    font-size: 18px
    padding-left: 20px
    padding-bottom: 20px
    font-weight: 500
    color: #000000

  &__header
    padding-top: 20px

    &--margin
      margin-left: 30px

  &__body
    min-height: 110px
    padding-top: 20px
    padding-bottom: 20px
    border-top: 1px solid #E6E6E6

  &__image
    margin-left: 10px
    display: flex
    justify-content: center

    img
      max-width: 74px
      max-height: 74px

  &__name
    font-size: 14px

  &__details
    font-size: 14px
    color: #9B9B9B
    margin-top: 8px

  &__quantity
    margin-left: 10px

  &__quantity-type
    margin-bottom: 20px

.additional-charges
  margin-top: 10px
  border: 1px solid #E6E6E6
  font-size: 14px

  &__title
    padding: 20px
    font-size: 16px
    color: #333333

  &__row
    border-top: 1px solid #E6E6E6
    padding: 15px

  &__header
    padding: 15px

  &__header-cell
    color: #888888 !important

  &__subtotal
    border-top: 1px solid #E6E6E6
    padding: 60px 0px 10px

  &__subtotal-item
    margin-left: 55px

  &__subtotal-item--bold
    font-weight: bold
    color: #000000
.retailer-terms-total-style
  font-size: 16px
  color: #333333
</style>
