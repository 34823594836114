<template lang="pug">
section.modal-card-body
  .address-modal__form
    .field.address-modal__field-margin-bottom
      .control
        input.input(
          v-model.trim="$v.address.addressee.$model"
          v-bind:placeholder="$t('Components.Retailers.Checkout.RetailerAddressModal.name')"
          :class="{'is-danger': $v.address.addressee.$error}"
          @blur="$v.address.addressee.$touch()"
        )
      p.help.is-danger(v-show="$v.address.addressee.$error") {{$t('Components.Retailers.Checkout.RetailerAddressModal.Validations.nameRequired')}}

    .field.address-modal__field-margin-bottom
      .control
        input.input(
          v-model.trim="$v.address.streetAddressLine_1.$model"
          v-bind:placeholder="$t('Components.Retailers.Checkout.RetailerAddressModal.addressLine1')"
          :class="{'is-danger': $v.address.streetAddressLine_1.$error}"
          @blur="$v.address.streetAddressLine_1.$touch()"
          
        )
      p.help.is-danger(v-show="$v.address.streetAddressLine_1.$error") {{$t('Components.Retailers.Checkout.RetailerAddressModal.Validations.streetlineRequired')}}
    .field.address-modal__field-margin-bottom
      .control
        input.input(
          v-model="address.streetAddressLine_2"
          v-bind:placeholder="$t('Components.Retailers.Checkout.RetailerAddressModal.aptSuiteBldg')"
        )
    .field.address-modal__field-margin-bottom
      .control
        input.input(
          v-model.trim="$v.address.city.$model"
          v-bind:placeholder="$t('Components.Retailers.Checkout.RetailerAddressModal.city')"
          :class="{'is-danger': $v.address.city.$error}"
          @blur="$v.address.city.$touch()"
        )
      p.help.is-danger(v-show="$v.address.city.$error") {{$t('Components.Retailers.Checkout.RetailerAddressModal.Validations.cityRequired')}}
    .columns.address-modal__field-margin-bottom
      .column
        .field
          .control
            input.input(
              v-model.trim="$v.address.region.$model"
              v-bind:placeholder="$t('Components.Retailers.Checkout.RetailerAddressModal.stateOrRegion')"
              :class="{'is-danger': $v.address.region.$error}"
              @blur="$v.address.region.$touch()"
            )
          p.help.is-danger(v-show="$v.address.region.$error") {{$t('Components.Retailers.Checkout.RetailerAddressModal.Validations.stateOrRegionRequired')}}
      .column
        .field
          .control
            input.input(
              v-model.trim="$v.address.postalCode.$model"
               v-bind:placeholder="$t('Components.Retailers.Checkout.RetailerAddressModal.postalCode')"
              :class="{'is-danger': $v.address.postalCode.$error}"
              @blur="$v.address.postalCode.$touch()"
            )
          p.help.is-danger(v-show="$v.address.postalCode.$error") {{$t('Components.Retailers.Checkout.RetailerAddressModal.Validations.postalCodeRequired')}}
    .field.address-modal__field-margin-bottom
      .control
        .select.is-fullwidth
          select(
            v-model="$v.address.country.id.$model"
            :class="{ 'is-danger-select': $v.address.country.id.$error}"
            @input="$v.address.country.id.$touch()"
          )
            option(v-for="country in countries" :key="country.id" :value="country.id") {{ country.name }}
        p.help.is-danger(v-show="$v.address.country.id.$error")  {{$t('Components.Retailers.Checkout.RetailerAddressModal.Validations.countryRequired')}}
    .has-text-centered
      button.button.address-modal__save-button.is-black(@click="emitAddressChange()") {{$t('Components.Retailers.Checkout.RetailerAddressModal.saveAddress')}}
</template>

<script>
import { required } from "vuelidate/lib/validators"
export default {
  props: {
    address: {
      type: Object,
      default() {
        return {
          label: "",
          addressee: "",
          streetAddressLine_1: "",
          streetAddressLine_2: "",
          city: "",
          region: "",
          postalCode: "",
          country: {
            id: ""
          }
        }
      }
    },
    isEditing: { type: Boolean, default: false }
  },
  validations: {
    address: {
      addressee: { required },
      streetAddressLine_1: { required },
      city: { required },
      region: { required },
      postalCode: { required },
      country: {
        id: { required }
      }
    }
  },
  computed: {
    retailerId() {
      return this.$store.getters["user/businessId"]
    },
    countries() {
      return this.$store.getters["reference/countries"]
    }
  },

  methods: {
    isValid() {
      this.$v.$touch()
      return !this.$v.address.$anyError
    },
    emitAddressChange() {
      if (!this.isValid()) {
        return
      }
      if (this.isEditing) {
        this.$emit("addressEdited")
      } else {
        this.$emit("addressChange", this.address)
      }
    }
  }
}
</script>

<style lang="sass">
.address-modal
  &__form
    padding-left: 100px
    padding-right: 100px
    font-size: 20px

  &__field-margin-bottom
    margin-bottom: 16px !important

  &__save-button
    width: 147px
    height: 46px
    margin-top: 19px
    margin-bottom: 43px

  .is-danger-select
    border: 1px solid red
</style>
