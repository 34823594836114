<template lang="pug">
layout
  .landing--section--wrapper
    .landing--section--inner
      div
        p.landing--header Your {{titleText}} has ended
        p.landing--content
          | Please
          b subscribe to access
          |  our tools for a flat annual cost and pay no commission fees ever.
        my-button(variant='is-primary' size='is-large' label='SUBSCRIBER HERE' @onClick="openAddCard")
      .image--group
        .image--left--group
          img.left--ellipse(src='@/assets/webflowAssets/Home/left_ellipse.svg' alt='Ellipse')
          img.left--dots--group(src='@/assets/webflowAssets/Home/left_dots_pattern.svg' alt='Dots')
          img.left--training--1(src='@/assets/webflowAssets/Home/left_training_1.svg' alt='Training 1')
          img.left--training--2(src='@/assets/webflowAssets/Home/left_training_2.svg' alt='Training 2')
          img.left--training--3(src='@/assets/webflowAssets/Home/left_training_3.svg' alt='Training 3')
        .image--right--group
          img.right--dots--group(src='@/assets/webflowAssets/Home/right_dots_pattern.svg' alt='Dots')
          img.clean--precheck--1(src='@/assets/webflowAssets/Home/clean_precheck_1.svg' alt='Precheck 1')
          img.clean--precheck--2(src='@/assets/webflowAssets/Home/clean_precheck_2.svg' alt='Precheck 2')
          img.clean--precheck--3(src='@/assets/webflowAssets/Home/clean_precheck_3.svg' alt='Precheck 3')
          img.clean--precheck--4(src='@/assets/webflowAssets/Home/clean_precheck_4.svg' alt='Precheck 4')
  addNewCardDialog(ref="addNewCardDialog" @openAddMoreCardDialog="openAddMoreCardDialog")
  cardListDialog(ref="cardListDialog" @openAddPaymentDialog="openAddPaymentDialog")

</template>

<script>
import MyButton from "@/components/webflowComponent/Button"
import cardListDialog from "@/components/brands/settings/addMoreCardDialog"
import addNewCardDialog from "@/components/brands/settings/addPaymentCardDialog"

export default {
  name: "BrandTrialEnd",
  components: {
    MyButton,
    cardListDialog,
    addNewCardDialog
  },
  props: {},

  data() {
    return {}
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    },
    titleText() {
      if (
        this.brand &&
        this.brand.subscriptions &&
        (this.brand.subscriptions[0].type.id == "basic" ||
          this.brand.subscriptions[0].type.id == "subscriber")
      ) {
        return "Subscription"
      }
      if (
        this.brand &&
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type.id == "premium"
      ) {
        return "Premium"
      }
      return "free trial"
    }
  },
  created() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "SubscriptionExpired",
      userId: this.$store.getters["user/id"],
      firstName: this.$store.getters["user/givenName"],
      lastName: this.$store.getters["user/familyName"],
      emailAddress: this.$store.getters["user/email"],
      userType: "Brand"
    })
  },
  methods: {
    openAddCard() {
      const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
      this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    },
    openAddMoreCardDialog() {
      const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
      this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    },
    openAddPaymentDialog() {
      this.$refs["addNewCardDialog"].openAddCardDialog()
    }
  }
}
</script>

<style scoped lang="scss">
.landing--section--wrapper {
  padding-top: 150px;
  padding-bottom: 244px;
  position: relative;
  text-align: center;
}

.landing--section--inner {
  position: relative;
}

.landing--section--inner > div:nth-child(1) {
  padding: 0 20px;
}

.landing--header {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 60px;
  text-align: center;
  text-transform: capitalize;
  color: #1a1818;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.landing--content {
  font-family: Mr Eaves Mod OT;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  max-width: 560px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.left--ellipse {
  position: absolute;
  top: 70px;
  left: 0;
}

.left--dots--group {
  position: absolute;
  left: 0px;
  top: 40px;
}

.left--training--1 {
  position: absolute;
  left: 66px;
  top: -10px;
  z-index: 1;
}

.left--training--2 {
  position: absolute;
  left: 142px;
  top: 135px;
}

.left--training--3 {
  position: absolute;
  left: 32px;
  top: 212px;
}

.right--dots--group {
  position: absolute;
  top: 20px;
  right: 0;
}

.clean--precheck--1 {
  position: absolute;
  right: 70px;
  top: 60px;
}

.clean--precheck--2 {
  position: absolute;
  right: 148px;
  top: 110px;
}

.clean--precheck--3 {
  position: absolute;
  right: 70px;
  top: 160px;
}

.clean--precheck--4 {
  position: absolute;
  right: 148px;
  top: 210px;
}

@media screen and (max-width: 1199px) {
  .landing--header {
    font-size: 60px;
  }

  .landing--content {
    font-size: 25px;
    max-width: 380px;
  }
}

@media screen and (max-width: 1023px) {
  .landing--section--wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .landing--section--wrapper .image--group {
    display: flex;
    overflow: hidden;
  }

  .landing--section--wrapper .image--left--group,
  .landing--section--wrapper .image--right--group {
    position: relative;
    height: 300px;
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .landing--header {
    font-size: 40px;
    line-height: 47px;
    font-weight: 400;
  }

  .landing--content {
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
  }

  .landing--content b {
    font-weight: 400;
  }

  .landing--section--wrapper .image--left--group,
  .landing--section--wrapper .image--right--group {
    position: relative;
    height: 240px;
    flex: 1;
    margin-top: 40px;
  }

  .landing--section--wrapper .left--ellipse {
    height: 156px;
  }

  .landing--section--wrapper .left--dots--group {
    height: 107px;
    width: 142px;
    left: -33px;
    top: 27px;
  }

  .landing--section--wrapper .left--training--1 {
    width: 63px;
    height: 102px;
    top: 0;
    left: 57px;
  }

  .landing--section--wrapper .left--training--2 {
    width: 175px;
    height: 75px;
    left: 0px;
    top: 115px;
  }

  .landing--section--wrapper .left--training--3 {
    width: 123px;
    height: 61px;
    left: 23px;
    top: 185px;
  }

  .landing--section--wrapper .right--dots--group {
    right: -70px;
    top: 56px;
  }

  .landing--section--wrapper .clean--precheck--1 {
    right: 40px;
    top: 40px;
  }

  .landing--section--wrapper .clean--precheck--2 {
    right: 56px;
    top: 90px;
  }

  .landing--section--wrapper .clean--precheck--3 {
    right: 40px;
    top: 140px;
  }

  .landing--section--wrapper .clean--precheck--4 {
    right: 56px;
    top: 190px;
  }
}
</style>
