<template lang="pug">
.retailer-order-status-bar
  .download-box
    .order-status-print-and-download(id="statusPrintAndDownloadButton")
      p.download-content(v-if="!($route.name === 'adminBrandOrder' || $route.name === 'adminRetailerOrder' || $route.name === 'retailerOrder' || $route.name === 'brandOrder')")
        a-icon(type="check-square")
        span.retailer-order-status-bar__item(@click="isMarkAs = !isMarkAs") Mark As
          a-icon(type="down" :style="{margin: '0px 4px'}")
          span.print-tabs(v-show="isMarkAs")
            span(v-for="tab in list" :key="tab.id" @click="onClickStatus(tab.status, tab.canClick, tab.reStatus)" :class="[!tab.canClick ? '': 'can-not-click-mark-as']") {{tab.name}}
      p.download-content(v-if="this.isAdmin && $route.name !== 'adminBrandOrder' && $route.name !== 'adminRetailerOrder'" @click="openDeliveryModal")
        a-icon(type="check-circle")
        span.retailer-order-status-bar__item Delivery Details
        a-icon(type="down" :style="{margin: '0px 4px'}")

      p.download-content
        a-icon(type="printer")
        span.retailer-order-status-bar__item(@click="clickPrint") Print
          a-icon(type="down" :style="{margin: '0px 4px'}")
          span.print-tabs(v-show="isPrint")
            template(v-for="tab in printTabText")
              span(v-if="!(isRetailer && tab.id === 4)" :key="tab.id" @click="openPrintOrder(tab)" :style="tab.disabled ? 'color: gray;' : 'color: #000000;' ") {{tab.name}}
      p.download-content
        a-icon(type="download")
        span.retailer-order-status-bar__item.download-tab-style(@click="clickDownload") Download
          a-icon(type="down" :style="{margin: '0px 4px'}")
          span.print-tabs.print-download-order-detail-special(v-show="isShowDownloadDropDown")
            template(v-for="downloadTab in downloadTabText")
              span(v-if="!(isRetailer && downloadTab.id === 3) && downloadTab.id !== 0" :key="`download_tab_${downloadTab.id}`" @click="downloadPdf(downloadTab)" :style="downloadTab.disabled ? 'color: gray;' : 'color: #000000;' ") {{ downloadTab.name }}
              span(v-else-if="downloadTab.id === 0" :key="`download_tab_${downloadTab.id}`" @click="getPdf($route.params.id)" :style="downloadTab.disabled ? 'color: gray;' : 'color: #000000;' ") {{ downloadTab.name }}

  .retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.deleted") {{$t('Components.Retailers.Order.OrderStatus.deleteOrder')}}
  .retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.pending && updatedStatusDate") {{$t('Components.Retailers.Order.OrderStatus.orderCreatedOn')}} {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.confirmed && updatedStatusDate") Order confirmed on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.supplyShipped && updatedStatusDate") Order shipped for supply on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.warehouse && updatedStatusDate") Order in warehouse on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.deliverShipped && updatedStatusDate") Order shipped for delivery on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.delivered && updatedStatusDate") Order delivered on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.paidByRetailer && (role === 'retailer' || role === 'admin') && updatedStatusDate") Payment made on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .retailer-order-status-bar__creation-date(v-if="order.status && order.status.id ===orderStatus.paidToBrand && (role === 'brand'|| role === 'admin') && updatedStatusDate") Payment made on {{ $d(updatedStatusDate, 'long', 'en-US') }}
  .retailer-order-status-bar__block
    .order-status-bar.completed(v-for="item in feLifecycle")
      img(v-if="item.complete&&isRetailer" src="@/assets/img/orders/retailer-status-bar-lit.svg")
      img(v-else-if="item.complete&&isBrand" src="@/assets/img/orders/status-bar-lit.svg")
      img(v-else-if="item.complete&&isAdmin" src="@/assets/img/orders/admin-status-bar-lit.svg")
      img(v-else src="@/assets/img/orders/status-bar-unlit.svg")
      span(v-if="(item.status.id===orderStatus.paidByRetailer || item.status.id===orderStatus.paidToBrand) && role != 'admin'") Paid
      span(v-else-if="(item.status.id===orderStatus.paidByRetailer || item.status.id===orderStatus.paidToBrand) && ($route.name === 'adminBrandOrder' || $route.name === 'adminRetailerOrder') && role === 'admin' ") Paid
      span(v-else) {{item.status.name}}
  printOrderModal(ref="printOrder" :orderItem="order" :userInfo="userInfo")
  // printActiveOrderModal(ref="printActiveOrder" :orderItem="order" :userInfo="userInfo")
  printOrderDetail(ref="printOrderDetail")
  deliveryDetail(ref="deliveryDetails")

</template>

<script>
import constants from "@/config.js"
import printOrderModal from "@/components/retailers/order/printOrderModal"
// import printActiveOrderModal from "@/components/retailers/order/printOrderActiveModal"
import printOrderDetail from "@/components/retailers/order/printOrderDetailModal"
import deliveryDetail from "@/components/retailers/order/deliveryDetailModal"
import { exportOrderExcel } from "@/utils/validate"

export default {
  components: {
    printOrderModal,
    // printActiveOrderModal,
    deliveryDetail,
    printOrderDetail
  },
  props: {
    // order: {
    //   type: Object,
    //   default: () => {}
    // },
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      orderStatus: constants.orderStatus,
      isPrint: false,
      isShowDownloadDropDown: false,
      isMarkAs: false,
      printTabText: [
        { id: 1, name: "Order", disabled: false },
        { id: 2, name: "Packing List", disabled: true },
        { id: 3, name: "Invoice", disabled: true },
        { id: 4, name: "Payment Advice", disabled: true }
      ],
      downloadTabText: [
        { id: 0, name: "Order", disabled: false },
        { id: 1, name: "Packing List", disabled: true },
        { id: 2, name: "Invoice", disabled: true },
        { id: 3, name: "Payment Advice", disabled: true }
      ],
      markAsTabText: [
        {
          id: 0,
          name: "Delete",
          status: constants.orderStatus.deleted,
          nextStatus: constants.orderStatus.deleted,
          reStatus: null,
          canClick: true
        },
        {
          id: 1,
          name: "Pending",
          status: constants.orderStatus.pending,
          nextStatus: constants.orderStatus.confirmed,
          reStatus: null,
          canClick: true
        },
        {
          id: 2,
          name: "Confirmed",
          status: constants.orderStatus.confirmed,
          nextStatus: constants.orderStatus.delivered,
          reStatus: null,
          canClick: true
        },
        {
          id: 3,
          name: "Shipped",
          status: constants.orderStatus.delivered,
          nextStatus: constants.orderStatus.paidByRetailer,
          reStatus: null,
          canClick: true
        },
        {
          id: 4,
          name: "Invoice",
          status: constants.orderStatus.invoiced,
          reStatus: constants.orderStatus.delivered,
          nextStatus: constants.orderStatus.paidByRetailer,
          canClick: true
        },
        {
          id: 5,
          name: "Paid by Retailer",
          status: constants.orderStatus.paidByRetailer,
          reStatus: constants.orderStatus.invoiced,
          nextStatus: constants.orderStatus.paidToBrand,
          canClick: true
        },
        {
          id: 6,
          name: "Paid to Brand",
          status: constants.orderStatus.paidToBrand,
          reStatus: constants.orderStatus.paidByRetailer,
          nextStatus: "",
          canClick: true
        }
      ],
      isDownloadLoading: false,
      list: []
    }
  },
  computed: {
    order() {
      return this.$store.getters["order/order"]
    },
    showAddTrackingInfo() {
      if (this.order.airShipments && this.order.oceanShipments) {
        return this.order.airShipments.length + this.order.oceanShipments.length == 2
      } else {
        return (
          (this.order.airShipments && this.order.airShipments.length == 2) ||
          (this.order.oceanShipments && this.order.oceanShipments.length == 2)
        )
      }
    },
    role() {
      return this.$store.getters["user/role"]
    },
    pendingStatus() {
      return this.order.status && this.order.status.id === "pending"
    },
    statusItems() {
      return this.order.lifecycle && Object.values(this.order.lifecycle).map(item => item.status)
    },
    updatedStatusDate() {
      if (this.order.lifecycle) {
        const currentStatus = this.order.lifecycle.filter(
          item => item.status.id === this.order.status.id
        )
        if (currentStatus[0] && currentStatus[0].transitionedAt) {
          return new Date(currentStatus[0].transitionedAt)
        } else {
          return null
        }
      }
      return null
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    retailerOrBrandInfo() {
      return this.$store.getters["retailer/retailer"]
    },
    feLifecycle() {
      let list = JSON.parse(JSON.stringify(this.order.newFeLifecycle))
      if (
        (this.$route.name === "adminBrandOrder" || this.$route.name === "adminRetailerOrder") &&
        this.role === "admin"
      ) {
        list.pop()
      }
      return list
    }
  },
  watch: {
    order(newValue) {
      if (newValue) {
        this.initStatusTxt()
        this.markAsList()
      }
    }
  },
  async created() {
    this.initStatusTxt()
    await this.$store.dispatch("order/getOrder", this.$route.params.id)
    this.markAsList()
  },
  methods: {
    markAsList() {
      const status = this.order.status.id || "pending"
      const list = this.markAsTabText.filter(item => item.status === status)
      let result = this.markAsTabText.map(value => {
        if (
          list[0].nextStatus === value.status ||
          list[0].reStatus === value.status ||
          (value.status === value.nextStatus &&
            (status === this.orderStatus.pending || status === this.orderStatus.confirmed))
        ) {
          value.canClick = false
        } else {
          value.canClick = true
        }
        return value
      })
      // If it is not in these two states, it means that there is no need to roll back the order state, so invoiced is not displayed
      if (
        !(status === this.orderStatus.paidToBrand || status === this.orderStatus.paidByRetailer)
      ) {
        result = result.filter(item => item.id !== 4)
      }
      this.list = result
    },
    updateStatus() {
      this.$emit("statusUpdated")
    },
    formattedDate() {
      return this.order.createdAt && new Date(this.order.createdAt)
    },
    filledStatus(statusId) {
      const statusItemsNames = this.statusItems.map(item => item.id)
      let indexOfActiveStatus = statusItemsNames.indexOf(this.order.status.id)
      if (this.role === "brand" && this.order.status.id === this.orderStatus.paidByRetailer) {
        indexOfActiveStatus = statusItemsNames.indexOf(this.orderStatus.paidToBrand) - 1
      }
      return (
        statusItemsNames.indexOf(statusId) <= indexOfActiveStatus ||
        this.order.status.id == this.orderStatus.paidToBrand
      )
    },
    onClickStatus(status, isCanClick, reStatus) {
      if (isCanClick) {
        return
      }
      if (this.order.status.id === this.orderStatus.paidToBrand && reStatus) {
        this.$emit("reStatusTransition", status)
      } else if (
        this.order.status.id === this.orderStatus.paidByRetailer &&
        reStatus &&
        reStatus !== this.orderStatus.paidByRetailer
      ) {
        this.$emit("reStatusTransition", reStatus)
      } else {
        this.$emit("statusUpdated", status)
      }
    },
    initStatusTxt() {
      if (this.order && this.order.status) {
        if (this.order.status.id === this.orderStatus.confirmed) {
          this.downloadTabText[1].disabled = false
          this.downloadTabText[2].disabled = true
          this.downloadTabText[3].disabled = true
          this.printTabText[1].disabled = false
          this.printTabText[2].disabled = true
          this.printTabText[3].disabled = true
        } else if (
          this.order.status.id === this.orderStatus.supplyShipped ||
          this.order.status.id === this.orderStatus.warehouse ||
          this.order.status.id === this.orderStatus.deliverShipped ||
          this.order.status.id === this.orderStatus.delivered
        ) {
          this.downloadTabText[1].disabled = false
          this.downloadTabText[2].disabled = false
          this.downloadTabText[3].disabled = true
          this.printTabText[1].disabled = false
          this.printTabText[2].disabled = false
          this.printTabText[3].disabled = true
        } else if (
          this.order.status.id === this.orderStatus.paidToBrand ||
          this.order.status.id === this.orderStatus.paidByRetailer
        ) {
          this.downloadTabText[1].disabled = false
          this.downloadTabText[2].disabled = false
          this.printTabText[1].disabled = false
          this.printTabText[2].disabled = false
          if (this.order.status.id === this.orderStatus.paidToBrand) {
            this.printTabText[3].disabled = false
            this.downloadTabText[3].disabled = false
          } else {
            this.printTabText[3].disabled = !this.order.paymentAdviceStatus
            this.downloadTabText[3].disabled = !this.order.paymentAdviceStatus
          }
        } else {
          this.downloadTabText[1].disabled = true
          this.downloadTabText[2].disabled = true
          this.downloadTabText[3].disabled = true
          this.printTabText[1].disabled = true
          this.printTabText[2].disabled = true
          this.printTabText[3].disabled = true
        }
      }
    },
    showTrackingInfo() {
      this.$emit("showTrackingInfo")
    },
    openPrintOrder(tab) {
      if (
        (tab.id === 2 && !tab.disabled) ||
        (tab.id === 3 && !tab.disabled) ||
        (tab.id === 4 && !tab.disabled)
      ) {
        this.$refs["printOrder"].openDialog(tab.id)
      }
      if (tab.id === 1) {
        this.$refs["printOrderDetail"].openDialog()
        // const el = document.getElementById("orderDetailMain")
        // let iframe = document.createElement("IFRAME")
        // let doc = null
        // // iframe.setAttribute("id", "orderDetailMain")
        // iframe.setAttribute("style", "width: 100%; height: auto;")
        // document.body.appendChild(iframe)
        // doc = iframe.contentWindow.document
        // //Fixed the occurrence of header footer and path
        // // doc.write('<style media="print">@page {size: auto;margin: 0mm;}</style>')
        // doc.write(
        //   "<LINK rel='stylesheet' type='text/css' href='./src/assets/styles/print-order.css'/>"
        // )
        // doc.write("<div class='retailer-order'>" + el.innerHTML + "</div>")
        // doc.close()
        // iframe.contentWindow.focus()
        // // Fix a problem where the style didn't work the first time
        // setTimeout(function() {
        //   iframe.contentWindow.print()
        // }, 50)
        // if (navigator.userAgent.indexOf("MSIE") > 0) {
        //   document.body.removeChild(iframe)
        // }
      }
    },
    openDeliveryModal() {
      this.$refs["deliveryDetails"].openDialog(this.order)
    },
    downloadFile(apiPath, param, fileName, canDownload) {
      if (apiPath && param && canDownload) {
        this.isDownloadLoading = true
        this.$store
          .dispatch(apiPath, param)
          .then(result => {
            exportOrderExcel(
              new Blob([result.data], { type: `${result.headers["content-type"]};base64` }),
              fileName
            )
              .then(() => {
                this.isDownloadLoading = false
              })
              .catch(e => {
                console.log(e)
                this.isDownloadLoading = false
              })
          })
          .catch(e => {
            this.isDownloadLoading = false
            console.log(e)
          })
      }
    },
    downloadPackList(canDownload) {
      this.downloadFile(
        "order/downloadOrderPacklist",
        this.order.id,
        `order_${this.order.landingReferenceId}_packingList.pdf`,
        canDownload
      )
    },
    downLoadOrder(canDownload) {
      this.downloadFile(
        "order/orderToPdf",
        this.order.id,
        `order_${this.order.landingReferenceId}.pdf`,
        canDownload
      )
    },
    downloadInvoice(canDownload) {
      this.downloadFile(
        "order/downloadOrderInvoice",
        this.order.id,
        `order_${this.order.landingReferenceId}_invoice.pdf`,
        canDownload
      )
    },
    downloadPaymentAdvice(canDownload) {
      this.downloadFile(
        "order/downloadOrderPayAdvice",
        this.order.id,
        `order_${this.order.landingReferenceId}_paymentAdvice.pdf`,
        canDownload
      )
    },
    downloadPdf(tab) {
      if (tab.id === 0) {
        // this.downLoadOrder(!tab.disabled)
      } else if (tab.id === 1) {
        this.downloadPackList(!tab.disabled)
      } else if (tab.id === 2) {
        this.downloadInvoice(!tab.disabled)
      } else {
        this.downloadPaymentAdvice(!tab.disabled)
      }
    },
    clickPrint() {
      this.isPrint = !this.isPrint
      this.isShowDownloadDropDown = false
    },
    clickDownload() {
      this.isShowDownloadDropDown = !this.isShowDownloadDropDown
      this.isPrint = false
    }
  }
}
</script>

<style lang="sass">
.markAsIcon
    position: absolute !important

.retailer-order-status-bar
    .retailer-order-status-bar__block
        display: flex
        padding-bottom: 45px
        padding-top: 30px

        .order-status-bar
            display: flex
            flex-direction: column
            align-items: self-start
            img
              height: 32px

            span
                width: 100%
                font-size: 14px
                color: #ffffff
                //padding-top: 16px
                text-align: center
                margin-top: -27px

        .order-status-bar.completed
            svg
                .order-status-bar-order-status-bar-inactive
                    fill: #FFFBFA !important

    &__statuses
        max-width: 1180px
        padding-top: 5px
        padding-bottom: 40px
        margin: 0 auto

    &__icon
        img
            margin-bottom: -5px
            margin-right: 3px
            cursor: pointer

    &__creation-date
      min-height: 40px
      //padding-top: 20px
      margin-top: -38px
      color: #000000
      font-size: 20px
      font-weight: 300
      //margin-bottom: 30px
      font-family: "Proxima Nova", "Roboto", sans-serif

    &__line-container
        max-width: 176px

    .disabled
        opacity: 0.6
        pointer-events: none

.order-status-print-and-download
  min-width: 230px
  //float: right
  margin-top: 20px
  display: flex
  justify-content: space-between
  p
    margin-bottom: 0
  //align-items: center
  .retailer-order-status-bar__item
    color: #595959
    font-size: 14px
    padding-left: 4px
    padding-right: 4px
    line-height: 24px
    cursor: pointer
    position: relative
    vertical-align: text-top
    //display: flex
    //align-items: center
    .print-tabs
      background-color: #ffffff
      border: 1px solid #efefef
      display: block
      width: 116px
      position: absolute
      top: 25px
      left: -28px
      z-index: 4
      span
        display: block
        padding-left: 12px
        font-size: 13px
        color: #000000
        font-family: "Proxima Nova", "Roboto", sans-serif
      span:hover
        background-color: #E2EEEC
    .download-tabs-width
      width: 180px
.dropIcon
    position: absolute !important

.add-tracking
    margin-left: 100px
.download-box
  display: flex
  justify-content: flex-end
  .download-content
    margin: 0 8px
    line-height: 24px
    display: flex
    justify-content: flex-start
    align-items: center
    .retailer-order-status-bar__item
      display: flex
      align-items: center
  //.download-tab-style
  //  display: flex
  //  align-items: center
.mask-as-style
  width: 100px
.disalbe-action-text
  color: #e8e8e8
.can-not-click-mark-as
  cursor: not-allowed
  background-color: #D9D9D9
  color: white
.can-not-click-mark-as:hover
  background-color: #595959
</style>
