<template lang="pug">
.price-filter
  .price-filter__header-body
    .price-filter__header-name(@click="openList()") {{ title }}
      span.price-filter__header--icon(v-show="!isOpen")
        img(src="@/assets/img/inputs/filters-arrow-icon.svg")
      span.price-filter__header--icon(v-show="isOpen && !filterIsActive")
        img(src="@/assets/img/inputs/filters-arrow-icon-up.svg")
      span.price-filter__header--reset(v-show="isOpen && filterIsActive" @click="resetFilter()") {{$t('Components.Orders.PriceFilter.resetAll')}}
    .price-filter__items(:class="{ 'price-filter__items--visible': isOpen}")
      .field.is-horizontal
        input.input.price-filter__input(@change="filterIsActive = true" placeholder="$ Minimum" ref="inputMinimum")
        span.price-filter__input-divider -
        input.input.price-filter__input(@change="filterIsActive = true" placeholder="$ Maximum" ref="inputMaximum")
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" }
  },
  data() {
    return {
      isOpen: false,
      filterIsActive: false
    }
  },
  methods: {
    openList() {
      this.isOpen = !this.isOpen
    },
    resetFilter() {
      this.$refs.inputMinimum.value = ""
      this.$refs.inputMaximum.value = ""
    }
  }
}
</script>

<style lang="sass">
.price-filter
  &__items
    display: none
    overflow: hidden
    margin-top: 10px

  &__items--visible
    display: block

  &__icon
    position: absolute
    left: 0
    margin-right: 20px

  &__header-body
    position: relative
    display: block
    cursor: pointer
    padding: 16px 0px 16px 0px
    border-top: 1px solid #EBEFF2
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

  &__header--icon
    float: right
    padding-right: 10px

  &__header--reset
    float: right
    color: #B3B3B3

  &__input
    width: 110px

  &__input-divider
    margin: 8px
</style>
