<template lang="pug">
div.banner-or-placeholder(:style="cssProps")
</template>

<script>
export default {
  props: {
    url: { type: String, default: null }
  },
  computed: {
    cssProps() {
      if (!this.url) {
        return { "--background-color": "#E4D9D8", "background-image": `url(${this.imageUrl})` }
      } else {
        return { "--background-color": "#FFFFFF", "background-image": `url(${this.url})` }
      }
    }
  },
  created() {
    this.imageUrl = require("@/assets/img/default-banner-image.png")
  }
}
</script>

<style lang="sass" scoped>
.banner-or-placeholder
    width: 100%
    padding-bottom: 38%
    overflow: hidden
    height: 0px
    background-position: center center
    background-repeat: no-repeat
    background-size: 100% 100%
    background-color: var(--background-color)

    img
        width: 100%
        padding-bottom: 38%
        overflow: hidden
        border-radius: 0 !important

.brand-profile__logo img
    border-radius: 0 !important
    border: 1px #e5e5e5 solid
    webkit-box-shadow: none
    box-shadow: none
</style>
