<template>
    <div>
        <a-form layout="horizontal" class="bf-sign-up" :form="form">
            <a-form-item>
                <a-input :placeholder="pName" v-decorator="[
            'name',
            {
                rules: [
                    {
                        required: true,
                        message: `${pName} is required`,
                    },
                    {
                        validator: validateBrandName,
                    },
                ],
            },
        ]"></a-input>
            </a-form-item>
            <a-form-item>
                <a-input :placeholder="pUrl" v-decorator="[
            'url',
            {
                rules: [
                    {
                        required: true,
                        message: `${pUrl} is required`,
                    },
                    {
                        validator: validateBrandWebsite,
                    },
                ],
            },
        ]"></a-input>
            </a-form-item>
            <div>
                <div class="columns">
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="First Name" v-decorator="[
            'firstName',
            {
                rules: [
                    {
                        required: true,
                        message: 'First Name is required',
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                    <div class="column">
                        <a-form-item>
                            <a-input placeholder="Last Name" v-decorator="[
            'lastName',
            {
                rules: [
                    {
                        required: true,
                        message: 'Last Name is required',
                    },
                ],
            },
        ]"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </div>
            <a-form-item>
                <a-input placeholder="Email" v-decorator="[
            'email',
            {
                rules: [
                    {
                        validator: validateEmail,
                    },
                    {
                        required: true,
                        message: 'E-mail is required',
                    },
                ],
            },
        ]"></a-input>
            </a-form-item>
            <a-form-item>
                <a-input placeholder="Password" v-decorator="[
            'password',
            {
                rules: [
                    {
                        required: true,
                        message: 'Password is required',
                    },
                    {
                        min: 8,
                        message: 'Password must be at least 8 characters',
                    },
                    {
                        validator: validateToNextPassword,
                    },
                ],
            },
        ]" type="password" @blur="handleConfirmPasswordBlur"></a-input>
            </a-form-item>
            <a-form-item>
                <a-input placeholder="Confirm Password" type="password" v-decorator="[
            'confirm',
            {
                rules: [
                    {
                        required: true,
                        message: 'Confirm Password is required',
                    },
                    {
                        validator: compareToFirstPassword,
                    },
                ],
            },
        ]"></a-input>
            </a-form-item>
            <!-- <a-form-item class="agreement" v-if="type === '1'">
                <a-checkbox v-decorator="[
                    'agreement',
                    {
                        valuePropName: 'checked',
                        rules: [

                            {
                                validator: validateAgreement,
                            },
                        ],
                    },
                ]">
                    By registering for a free trial, you agree to the
                    <span class="sign-up-link" @click="jumpToTerms">Landing Terms of Use</span>
                    and
                    <span class="sign-up-link" @click="jumpToPrivacy">Privacy Policy</span>
                </a-checkbox>
            </a-form-item> -->
            <!-- <a-form-item class="center">
                <a-button @click="handleSubmit">{{ submitLabel }}</a-button>
            </a-form-item> -->
        </a-form>
    </div>
</template>

<script>
import SignUpService from "@/services/sign-up-services";
export default {
    data() {
        return {
            pName: "Brand Name",
            pUrl: "Brand Website",
            confirmDirty: false,
        }
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: "signUp" });
    },

    methods: {
        jumpToTerms() {
            window.open(
                "https://www.landinginternational.com/terms-of-use",
                "_blank"
            );
        },
        jumpToPrivacy() {
            window.open(
                "https://www.landinginternational.com/privacy-policy",
                "_blank"
            );
        },
        validateToNextPassword(rule, value, callback) {
            const form = this.form;
            if (value && this.confirmDirty) {
                form.validateFields(["confirm"], { force: true });
            }
            callback();
        },
        handleConfirmPasswordBlur(e) {
            const value = e.target.value;
            this.confirmDirty = this.confirmDirty || !!value;
        },
        compareToFirstPassword(rule, value, callback) {
            const form = this.form;
            if (value && value !== form.getFieldValue("password")) {
                callback("Password and Confirm Password does not match");
            } else {
                callback();
            }
        },
        validateAgreement(rule, value, callback) {
            if (!value) {
                callback("Please accept the terms and privcy policy to proceed");
            }
            callback();
        },
        async validateEmail(rule, value, callback) {
            if (value) {
                if (SignUpService.validateEmail(value)) {
                    const isExists = await SignUpService.isEmailExists(value);
                    if (isExists) {
                        callback(`${value} is already exists`);
                    } else {
                        callback();
                    }
                } else {
                    callback("Email is invalid");
                }
            } else {
                callback();
            }
        },
        async validateBrandName(rule, value, callback) {
            if (value) {
                const isExists = await SignUpService.isBrandNameExists(value);
                if (isExists) {
                    callback(`${this.pName} is already exists`);
                } else {
                    callback();
                }
            }
        },
        async validateBrandWebsite(rule, value, callback) {
            if (value) {
                const isExists = await SignUpService.isBrandWebsiteExists(value);
                if (isExists) {
                    callback(`${this.pUrl} is already exists`);
                } else {
                    callback();
                }
            }
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    //console.log("Received values of form: ", values);
                    const platformSignup = {
                        type: +this.type,
                        name: values.name,
                        website: values.url,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        password: values.password,
                    };
                    //console.log("platformSignup: ", platformSignup);
                    this.$emit("submitAction", platformSignup);
                }
            });
        },

    },
}
</script>

<style lang="scss" scoped>
.sign-up-title {
    font-family: "Niveau Grotesk";
    font-size: 25px;
    color: #1a1818;
}

.bf-sign-up {
    .sign-up-link {
        text-decoration: underline;
    }

    .ant-form-item {
        margin-bottom: 22px;

        &.center {
            display: flex;
            justify-content: center;
        }



        .ant-form-explain {
            position: absolute;
            bottom: -20px;
            left: 24px;
        }

        input {
            padding: 6px 24px 10px 24px;
            border-radius: 50px;
            font-size: 20px;
            color: #000;
            height: fit-content;
        }

    }
}
</style>