<template>
  <div class="admin-inventory" style="max-width: 1196px;">
    <a-row class="admin-inventory__header">
      <a-col :span="24">
        <a-row>
          <a-col :span="12">
            <span class="admin-inventory__header__title" style="float: left;">{{
              $t("Admins.AdminInventory.inventory")
            }}</span>
          </a-col>
          <a-col :span="12">
            <a-button
              v-if="isFromAdminInventory"
              class="admin-brand-inventory__back-btn admin-inventory__header__download"
              @click="backToParent"
            >
              {{ $t("Admins.AdminInventory.back") }}
            </a-button>
            <a-button
              class="admin-inventory__download-btn admin-inventory__header__download"
              style="float: right;"
              @click="clickToDownloadData"
            >
              {{ $t("Admins.AdminInventory.csv") }}
              <a-icon type="download" />
            </a-button>
          </a-col>
        </a-row>
        <a-row>
          <div class="admin-inventory__header__search">
            <a-select
              v-model="searchValue"
              mode="combobox"
              class="admin-brand-inventory__search-input"
              dropdown-class-name="admin-inventory__header__search-dropdown"
              :placeholder="$t('Admins.AdminInventory.searchBrand')"
              :allow-clear="true"
              option-label-prop="value"
              @inputKeydown="pressEnterToSearch"
              @select="onSearchChange"
              @change="clearSearchText"
            >
              <a-icon slot="suffixIcon" type="search" />
              <a-select-option v-for="item in searchedHistoryArray" :key="item" :value="item">
                <a-row>
                  <a-col :span="23">
                    <a-icon type="search" style="margin-right:  11px;" />
                    <span>{{ item }}</span>
                  </a-col>
                  <a-col :span="1">
                    <a-icon
                      type="close"
                      style="margin-right: 2px; font-size: 14px;"
                      @click.stop="removeCachedData(item)"
                    />
                  </a-col>
                </a-row>
              </a-select-option>
            </a-select>
            <a-icon
              type="search"
              style="font-size: 12px; z-index: 44; position: absolute; right: 47%; top: 8px;"
            />
          </div>
        </a-row>
      </a-col>
    </a-row>
    <a-row class="admin-brand-inventory__content">
      <a-table
        id="productInventoryTable"
        :columns="columns"
        :data-source="brandProductList"
        :row-key="record => record.privateId"
        bordered
        :pagination="false"
      >
        <span slot="customCheckTitle">
          <a-checkbox v-model="isCheckAllProduct" @change="handleSelectAllProductReport" />
        </span>
        <span slot="modifyDate" slot-scope="text">{{ formatDate(text) }}</span>
        <span slot="customModifiedTitle">
          {{ $t("Admins.AdminInventory.modified") }}
          <a-icon type="down" :rotate="productSortModifiedAes ? 180 : 0" @click="sortByModified" />
        </span>
        <span slot="customProductTitle">
          {{ $t("Admins.AdminInventory.product") }}
          <a-icon
            type="down"
            :rotate="productSortProductAes ? 180 : 0"
            @click="sortByProductName"
          />
        </span>
        <span slot="customSkuTitle">
          {{ $t("Admins.AdminInventory.sku") }}
          <a-icon type="down" :rotate="productSortSkuAes ? 180 : 0" @click="sortBySkuNumber" />
        </span>
        <span slot="customTotalTitle">
          {{ $t("Admins.AdminInventory.total") }}
          <a-icon type="down" :rotate="productSortTotalAes ? 180 : 0" @click="sortByTotal" />
        </span>
        <span slot="customAllocatedTitle">
          {{ $t("Admins.AdminInventory.allocated") }}
          <a-icon
            type="down"
            :rotate="productSortAllocatedAes ? 180 : 0"
            @click="sortByAllocated"
          />
        </span>
        <span slot="customAvailableTitle">
          {{ $t("Admins.AdminInventory.available") }}
          <a-icon
            type="down"
            :rotate="productSortAvailableAes ? 180 : 0"
            @click="sortByAvailable"
          />
        </span>
        <span slot="checkProductAction" slot-scope="text, record">
          <a-checkbox
            :checked="text"
            @change="checkedValue => chooseProduct(checkedValue, record)"
          />
        </span>
        <span
          slot="historyProductView"
          slot-scope="text, record"
          class="admin-inventory__content__product-view-action"
          @click="clickToProductHistory(record)"
        >
          {{ $t("Admins.AdminInventory.view") }}
        </span>
        <span
          slot="productNameValue"
          slot-scope="text, record"
          class="admin-brand-inventory__name-value"
          @click="clickToProductPage(record)"
        >
          <a-tooltip v-if="text && text.length > maxTextLen" placement="topLeft" :title="text">
            <span>{{ text }}</span>
          </a-tooltip>
          <span v-else>{{ text }}</span>
        </span>
        <span slot="brandNameValue" slot-scope="text">
          {{ text }}
        </span>
      </a-table>
      <a-pagination
        class="inventoryPagination"
        style="margin-top: 10px; float: right;"
        :default-page-size="pageSize"
        :default-current="1"
        :total="brandProductReport ? brandProductReport.total : 0"
        size="small"
        :show-quick-jumper="true"
        @change="getBrandProductPageData"
      />
    </a-row>
    <!--    <membershipExpiringDialog-->
    <!--      ref="membershipExpiringDialog"-->
    <!--      @addCardList="openAddMoreCardDialog"-->
    <!--    ></membershipExpiringDialog>-->
    <!--    <addNewCardDialog-->
    <!--      ref="addNewCardDialog"-->
    <!--      @openAddMoreCardDialog="openAddMoreCardDialog"-->
    <!--    ></addNewCardDialog>-->
    <!--    <cardListDialog-->
    <!--      ref="cardListDialog"-->
    <!--      @openAddPaymentDialog="openAddPaymentDialog"-->
    <!--    ></cardListDialog>-->
  </div>
</template>

<script>
import { downloadCsvData, getNowDateStr, replaceCommaForCsvStr } from "@/utils/validate"
import constants from "@/config"
// import membershipExpiringDialog from "@/components/webflowComponent/MembershipExpiringDialog"
// import addNewCardDialog from "@/components/brands/settings/addPaymentCardDialog"
// import cardListDialog from "@/components/brands/settings/addMoreCardDialog"

export default {
  // components: {
  //   membershipExpiringDialog,
  //   addNewCardDialog,
  //   cardListDialog
  // },
  data() {
    return {
      brandId: this.$route.params.brandId,
      isFromAdminInventory: this.$route.params.isFromAdminInventory,
      loadingBrands: false,
      searchValue: "",
      currentPage: 1,
      isCheckAllProduct: false,
      selectedProductsInProductView: new Set(),
      searchedHistory: new Set(),
      searchedHistoryArray: [],
      routerName: "",
      pageSize: 50,
      maxTextLen: 50,
      productSortModifiedAes: false,
      productSortProductAes: false,
      productSortSkuAes: false,
      productSortTotalAes: false,
      productSortAvailableAes: false,
      productSortAllocatedAes: false,
      brandSortAvailableAes: false,
      productSortParam: undefined,
      searchParam: {},
      columns: [
        {
          key: "isCheck",
          dataIndex: "isChecked",
          slots: { title: "customCheckTitle" },
          scopedSlots: { customRender: "checkProductAction" },
          width: "30px"
        },
        {
          slots: { title: "customModifiedTitle" },
          dataIndex: "createdAt",
          width: "8%",
          scopedSlots: { customRender: "modifyDate" }
        },
        {
          slots: { title: "customProductTitle" },
          dataIndex: "productName",
          scopedSlots: { customRender: "productNameValue" },
          ellipsis: true,
          width: "37%"
        },
        {
          slots: { title: "customSkuTitle" },
          dataIndex: "sku",
          width: "9%"
        },
        {
          slots: { title: "customTotalTitle" },
          dataIndex: "total",
          width: "9.4%"
        },
        {
          slots: { title: "customAllocatedTitle" },
          dataIndex: "allocated",
          width: "10%"
        },
        {
          slots: { title: "customAvailableTitle" },
          dataIndex: "available",
          width: "8%"
        },
        {
          title: this.$t("Admins.AdminInventory.location"),
          dataIndex: "locationName",
          width: "8%"
        },
        {
          title: this.$t("Admins.AdminInventory.history"),
          dataIndex: "history",
          scopedSlots: { customRender: "historyProductView" },
          width: "6.4%"
        }
      ]
    }
  },
  computed: {
    brandProductList() {
      return this.$store.getters["inventory/brandProductList"]
    },
    brandProductReport() {
      return this.$store.getters["inventory/brandProductReport"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    }
  },
  async beforeCreate() {
    await this.$store.dispatch("reference/setReference")
  },
  mounted() {
    // if (this.brandType === "basic" && this.isBrand) {
    //   this.$refs["membershipExpiringDialog"].openDialog()
    //   return
    // }
    this.routerName = this.$route.name
    this.isFromAdminInventory = this.$route.params.isFromAdminInventory
      ? this.$route.params.isFromAdminInventory
      : this.routerName && this.routerName === "adminBrandInventory"
      ? true
      : false
    this.brandId =
      this.routerName === "adminBrandInventory" || this.routerName === "adminBrandBrandInventory"
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"]
    this.searchParam = {}
    this.getBrandProductInventory(this.getParam(this.currentPage))
    let jsonStr = localStorage.getItem("brandInventorySearchHistory")
    if (jsonStr) {
      let searchHistoryArr = JSON.parse(jsonStr)
      if (searchHistoryArr && searchHistoryArr.length > 0) {
        this.searchedHistoryArray = searchHistoryArr
        searchHistoryArr.forEach(item => {
          if (item) {
            this.searchedHistory.add(item)
          }
        })
      }
    } else {
      this.searchedHistoryArray = []
    }
  },
  methods: {
    // openAddMoreCardDialog() {
    //   const hasCardInfo = this.brand.subscriptions && this.brand.subscriptions.length !== 0
    //   this.$refs["cardListDialog"].openAddCardDialog(hasCardInfo)
    // },
    // openAddPaymentDialog() {
    //   this.$refs["addNewCardDialog"].openAddCardDialog()
    // },
    sortByModified() {
      this.productSortModifiedAes = !this.productSortModifiedAes
      this.currentPage = 1
      this.productSortParam = {
        "_sort-by": "create_at",
        "_order-by": this.productSortModifiedAes ? "NEXT" : "PREVIOUS"
      }
      this.getBrandProductInventory(this.getParam(this.currentPage))
    },
    sortByProductName() {
      this.productSortProductAes = !this.productSortProductAes
      this.currentPage = 1
      this.productSortParam = {
        "_sort-by": "product_name",
        "_order-by": this.productSortProductAes ? "NEXT" : "PREVIOUS"
      }
      this.getBrandProductInventory(this.getParam(this.currentPage))
    },
    sortByAvailable() {
      this.productSortAvailableAes = !this.productSortAvailableAes
      this.currentPage = 1
      this.productSortParam = {
        "_sort-by": "available",
        "_order-by": this.productSortAvailableAes ? "NEXT" : "PREVIOUS"
      }
      this.getBrandProductInventory(this.getParam(this.currentPage))
    },
    sortByAllocated() {
      this.productSortAllocatedAes = !this.productSortAllocatedAes
      this.currentPage = 1
      this.productSortParam = {
        "_sort-by": "allocated",
        "_order-by": this.productSortAllocatedAes ? "NEXT" : "PREVIOUS"
      }
      this.getBrandProductInventory(this.getParam(this.currentPage))
    },
    sortByTotal() {
      this.productSortTotalAes = !this.productSortTotalAes
      this.currentPage = 1
      this.productSortParam = {
        "_sort-by": "total",
        "_order-by": this.productSortTotalAes ? "NEXT" : "PREVIOUS"
      }
      this.getBrandProductInventory(this.getParam(this.currentPage))
    },
    sortBySkuNumber() {
      this.productSortSkuAes = !this.productSortSkuAes
      this.currentPage = 1
      this.productSortParam = {
        "_sort-by": "sku",
        "_order-by": this.productSortSkuAes ? "NEXT" : "PREVIOUS"
      }
      this.getBrandProductInventory(this.getParam(this.currentPage))
    },
    pressEnterToSearch(event) {
      if (event.keyCode === 13) {
        this.doSearch(this.searchValue)
      }
    },
    clearSearchText(value) {
      if (!value || value.length === 0) {
        this.doSearch("")
      }
    },
    onSearchChange(value) {
      this.doSearch(value)
    },
    doSearch(value) {
      if (value && value.length > 0) {
        this.searchedHistory.add(value)
        let searchArray = [...this.searchedHistory]
        if (this.searchedHistory.size >= 10) {
          searchArray = searchArray.slice(this.searchedHistory.size - 10, this.searchedHistory.size)
        }
        this.searchedHistoryArray = searchArray
        this.searchedHistory.clear()
        this.searchedHistory = new Set(this.searchedHistoryArray)
        searchArray = []
        localStorage.setItem(
          "brandInventorySearchHistory",
          JSON.stringify(this.searchedHistoryArray)
        )
      }
      this.currentPage = 1
      this.searchParam = {
        keyword: value
      }
      this.getBrandProductInventory(this.getParam(this.currentPage))
    },
    getParam(page) {
      let param = {
        _limit: this.pageSize,
        _index: page,
        "brand-Id": this.brandId
      }
      if (this.productSortParam) {
        param = Object.assign({}, param, this.productSortParam)
      } else {
        param["_order-by"] = "PREVIOUS"
      }
      return Object.assign({}, param, this.searchParam)
    },
    removeCachedData(item) {
      for (let i = 0; i < this.searchedHistoryArray.length; i++) {
        if (this.searchedHistory.has(item)) {
          this.searchedHistory.delete(item)
        }
        if (this.searchedHistoryArray[i] === item) {
          this.searchedHistoryArray.splice(i, 1)
          localStorage.setItem(
            "brandInventorySearchHistory",
            JSON.stringify(this.searchedHistoryArray)
          )
          break
        }
      }
    },
    async clickToDownloadData() {
      if (this.selectedProductsInProductView && this.selectedProductsInProductView.size > 0) {
        const brandName =
          this.brandProductList &&
          this.brandProductList.length > 0 &&
          this.brandProductList[0].brandName
            ? this.brandProductList[0].brandName
            : "brand"
        await downloadCsvData(
          this.generateDownloadStrForProduct(),
          `${brandName}_product_${getNowDateStr()}.csv`
        )
      } else {
        this.$message.warning(this.$t("Admins.AdminInventory.downloadTooltip"), 5)
      }
    },
    generateDownloadStrForProduct() {
      if (this.selectedProductsInProductView && this.selectedProductsInProductView.size > 0) {
        let csvData = ["Modified, Product, SKU, Total, Allocated, Available, Location"]
        for (let item of this.selectedProductsInProductView) {
          let str = `${this.formatDate(item.createdAt)}, ${replaceCommaForCsvStr(
            item.productName
          )}, ${item.sku}, ${item.total}, ${item.allocated}, ${item.available}, ${
            item.locationName
          }`
          csvData.push(str)
        }
        return csvData
      }
      return []
    },
    getBrandProductPageData(page) {
      this.currentPage = page
      this.getBrandProductInventory(this.getParam(page))
    },
    // page, sort, search
    getBrandProductInventory(params) {
      this.loadingBrands = true
      this.$store
        .dispatch("inventory/fetchBrandProductReportInBrandView", params)
        .then(() => {
          this.loadingBrands = false
        })
        .catch(error => {
          console.error(error)
          this.loadingBrands = false
        })
    },
    backToParent() {
      this.$router.go(-1)
    },
    handleSelectAllProductReport() {
      this.selectedProductsInProductView.clear()
      this.brandProductList.forEach(item => {
        item.isChecked = this.isCheckAllProduct
        this.selectedProductsInProductView.add(item)
      })
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    chooseProduct(checkedValue, record) {
      record.isChecked = checkedValue.target.checked
      if (checkedValue.target.checked) {
        // add record into the list
        this.selectedProductsInProductView.add(record)
      } else {
        // remove it form list
        this.selectedProductsInProductView.delete(record)
      }
    },
    clickToProductHistory(item) {
      // need to save some param in the session, in order to back to this page.
      if (this.routerName == "adminBrandInventory") {
        this.$router.push({
          name: "adminProductHistoryInventory",
          params: {
            productId: item.productId,
            brandId: item.brandId
          }
        })
      } else if (this.routerName === "adminBrandBrandInventory") {
        this.$router.push({
          name: "adminBrandProductInventory",
          params: {
            productId: item.productId,
            brandId: item.brandId
          }
        })
      } else {
        this.$router.push({
          name: "brandProductInventory",
          params: {
            productId: item.productId,
            brandId: item.brandId
          }
        })
      }
    },
    clickToProductPage(item) {
      if (this.routerName == "adminBrandInventory") {
        this.$router.push({
          name: "adminProduct",
          params: {
            id: item.productId,
            isFromInvertory: true
          }
        })
      } else if (this.routerName === "adminBrandBrandInventory") {
        this.$router.push({
          name: "adminBrandProduct",
          params: {
            id: item.productId,
            isFromInvertory: true
          }
        })
      } else {
        this.$router.push({
          name: "brandProduct",
          params: {
            id: item.productId,
            isFromInvertory: true
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.admin-brand-inventory {
  &__content {
    margin-top: 10px;
  }
  &__search-input {
    background: #dee5ef !important;
    border-radius: 1px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #595959 !important;
    border: none !important;
    height: 27px !important;
    width: 100%;
  }
  &__back-btn {
    background: #dee5ef !important;
    border-radius: 2px !important;
    border: none !important;
    width: 88px;
    height: 30px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000 !important;
    float: right;
    margin-left: 34px;
  }
  &__back-img {
    width: 17px;
    height: 17px;
    margin-left: 6px;
  }
  &__name-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 98%;
    display: block;
  }
}
.admin-brand-inventory__search-input input.ant-input {
  background-color: #dee5ef;
  padding-left: 10px;
}
.admin-brand-inventory__search-input .ant-input {
  height: 27px;
}
.admin-brand-inventory__search-input .ant-select-selection--single {
  height: 28px;
  min-height: 28px;
  background-color: transparent;
  border: none;
}
.admin-brand-inventory__search-input
  .ant-select-selection--multiple
  .ant-select-selection__choice__remove {
  display: none;
}
.admin-brand-inventory__search-input .ant-select-selection__rendered {
  line-height: 27px;
}

.admin-brand-inventory__search-input input.ant-input {
  background-color: #dee5ef;
  padding-left: 10px;
}
.admin-brand-inventory__search-input .ant-input {
  height: 27px;
}
.admin-brand-inventory__search-input .ant-select-selection__clear {
  background-color: transparent;
  right: 24px;
}
.admin-brand-inventory__search-input.ant-select-focus {
  border: none;
}
.admin-brand-inventory__search-input .ant-select-selection--multiple .ant-select-selection__choice {
  padding: 2px 2px 0 2px;
  background-color: transparent;
  border: none;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #595959;
}
.admin-brand-inventory__search-input .ant-select-selection__placeholder {
  color: #595959 !important;
}
.admin-brand-inventory__content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff !important;
  border: 1px solid #bfbfbf !important;
}
.admin-brand-inventory__content .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #595959;
}
</style>
