<template>
  <div
    class="type_btn"
    :class="(currentValue === index) || (currentIndex === index) ? 'type_btn_selected' : ''"
    @click="itemClick(item)"
  >
    <a-icon class="location-style" :type="item.iconName" v-if="!item.iconName"/>
    <div class="name">{{ item.title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    currentIndex: {
      handler(newValue) {
        this.currentIndex = newValue
      }
    }
  },
  computed: {
    // The route ID is highlighted
    currentValue() {
      if (this.$route.name === "RetailerAZ") return 3
      return parseInt(this.$route.query.id, 10)
    }
  },
  methods: {
    itemClick(item) {
      if (this.index === this.currentIndex && !item.child) return
      this.$emit("update:currentIndex", this.index)
      this.$emit("itemClick", item)
    }
  }
}
</script>

<style lang="scss">
.type_btn {
  height: 48px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  padding-left: 18px;
  color: #262626;
  cursor: pointer;
  .name {
    font-family: Proxima Nova;
    font-size: 16px;
    margin-left: 16px;
  }
  &_selected {
    background-color: #4a8a5d !important;
    color: #fff !important;
  }
  .location-style {
    font-size: 20px;
    margin-right: 5px;
  }
}
</style>
