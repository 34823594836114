<template>
  <div ref="newRetailerPurchase" class="add-new-retailer-purchase__main">
    <a-modal
      v-model="showPurchase"
      width="806px"
      :footer="null"
      :get-container="() => $refs.newRetailerPurchase"
      @cancel="closeDialog"
    >
      <p slot="title" class="modal-title-location">Step 4. Make Payment</p>
      <a-table
        :columns="columns"
        :data-source="retailerInfoList"
        :pagination="false"
        :get-popup-container="() => $refs.addNewRetailerPart1"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>
      <a-row style="margin-top: 30px">
        <a-col :span="15">
          <payment-card-list
            :card-info-list="paymentMethodsData"
            :brand-id="brandId"
            style="margin-bottom: 20px;"
          />
          <a-row>
            <a-col :span="20">
              <button
                class="update-payment-details__add-new-text update-payment-details__add-new-border"
                @click="showAddDialog"
              >
                {{ $t("Components.Brands.Settings.PaymentDetails.addNewPayment") }}
                <span class="update-payment-details__add-new-icon" />
              </button>
            </a-col>
            <!--            <a-col-->
            <!--                v-if="subscriptionId && subscriptionId === 'Manual'"-->
            <!--                :span="4"-->
            <!--                style="text-align: right;"-->
            <!--            >-->
            <!--              <a-button class="update-payment-details__pay-btn" @click="clickToPay">Pay</a-button>-->
            <!--            </a-col>-->
          </a-row>
        </a-col>
      </a-row>
      <div class="payment-add-retailer-purchase">
        <!--          <a-row class="select-payment-pay__buttons">-->
        <a-button id="doPurchase" :loading="isLoading" @click="doPurchase">
          {{ $t("Components.Brands.Payment.purchaseNow") }}
        </a-button>
        <!--          </a-row>-->
      </div>
    </a-modal>
    <a-modal
      v-model="isShowUpdateMethod"
      class="update-payment-dialog"
      :footer="null"
      width="501px"
      :destroy-on-close="true"
    >
      <template #closeIcon>
        <img src="@/assets/img/icon/close-circle.svg" />
      </template>
      <a-row>
        <span class="update-payment-dialog__title ">{{
          $t("Components.Brands.Payment.updatePayment")
        }}</span>
      </a-row>
      <a-row>
        <payment-info @cardInfo="receiveCardData" />
      </a-row>
      <a-row class="update-payment-dialog__button-row">
        <a-button class="update-payment-dialog__button" @click="doUpdateMethod">
          {{ $t("Components.Brands.Payment.updatePayment") }}
          <span class="update-payment-details__add-new-icon" />
        </a-button>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import NewCardList from "@/components/brands/settings/NewCardList"
import PaymentInfo from "@/components/brands/payment/PaymentInfo"
import Utils from "@/common/Utils"
import AES from "@/common/AES.js"
// import PaymentInfo from "@/components/brands/payment/PaymentInfo"
export default {
  name: "AddNewRetailerPurchase",
  components: {
    // PaymentInfo
    "payment-card-list": NewCardList,
    "payment-info": PaymentInfo
  },
  data() {
    return {
      showPurchase: false,
      isLoading: false,
      isShowList: false,
      isShowMore: true,
      selectedPayment: undefined,
      isShowUpdateMethod: false,
      canPurchase: this.selectedPayment ? true : false,
      payment: {},
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "Locations",
          dataIndex: "locationNumber",
          key: "locationNumber"
        },
        {
          title: "Territory",
          dataIndex: "territory",
          key: "territory"
        },
        {
          title: "Pay ment",
          dataIndex: "payment",
          key: "payment"
        },
        {
          title: "Price",
          dataIndex: "amount",
          key: "amount"
        }
      ],
      retailerInfoList: [],
      cardData: undefined
    }
  },
  computed: {
    paymentMethodsData() {
      return this.$store.getters["brandPayment/cardInfoList"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    subscriptionId() {
      let brand = this.$store.getters["brand/brand"]
      if (brand && brand.subscriptions && brand.subscriptions.length > 0) {
        return brand.subscriptions[0].subscriptionId
      }
      return undefined
    }
  },
  methods: {
    ...mapActions({
      updatePaymentTrainingSub: "training/updatePaymentTrainingSubscription"
    }),
    async openCardDialog(payment, data, type) {
      this.showPurchase = true
      this.payment = payment
      this.selectedPayment = this.$store.getters["brandPayment/defaultCard"]
      if (type === "monthly") {
        this.retailerInfoList = data.map(item => {
          item.amount = `$${item.trainingPrices[0].monthlyPrice || 0}`
          item.priceNumber = `${item.trainingPrices[0].monthlyPrice || 0}`
          item.payment = type
          return item
        })
      } else if (type === "annually") {
        this.retailerInfoList = data.map(item => {
          item.amount = `$${item.trainingPrices[0].annuallyPrice || 0}`
          item.priceNumber = `${item.trainingPrices[0].annuallyPrice || 0}`
          item.payment = type
          return item
        })
      } else {
        this.retailerInfoList = data.map(item => {
          item.amount = `$${item.trainingPrices[0].quarterlyPrice || 0}`
          item.priceNumber = `${item.trainingPrices[0].quarterlyPrice || 0}`
          item.payment = type
          return item
        })
      }
    },
    closeDialog() {
      this.showPurchase = false
    },
    showAddDialog() {
      this.isShowUpdateMethod = true
    },
    hideAddDialog() {
      this.isShowUpdateMethod = false
    },
    isCreditCard() {
      if (this.cardData.payType !== "creditCard") {
        return false
      }
      return true
    },
    checkCardInfo() {
      if (!this.cardData.cardNumber) {
        let info = this.$t("Components.Brands.Payment.cardNumberEmpty")
        this.$message.error(info)
        return false
      }
      if (!this.cardData.expirationDate) {
        let info = this.$t("Components.Brands.Payment.expirationDateEmpty")
        this.$message.error(info)
        return false
      }
      if (!this.cardData.cardCode) {
        let info = this.$t("Components.Brands.Payment.cvvEmpty")
        this.$message.error(info)
        return false
      }
      if (!Utils.checkCardNumber(this.cardData.cardNumber)) {
        // check number
        let info = this.$t("Components.Brands.Payment.cardNumberError")
        this.$message.error(info)
        return false
      }
      if (!Utils.checkExpiredDate(this.cardData.expirationDate)) {
        let info = this.$t("Components.Brands.Payment.expiredDateFormatError")
        this.$message.error(info)
        return false
      }
      if (Utils.isDateExpired(this.cardData.expirationDate)) {
        let info = this.$t("Components.Brands.Payment.expiredDateExpiredError")
        this.$message.error(info)
        return false
      }
      if (!Utils.checkCardCode(this.cardData.cardCode)) {
        let info = this.$t("Components.Brands.Payment.cardCodeError")
        this.$message.error(info)
        return false
      }
      return true
    },
    async doUpdateMethod() {
      //invoke api to save data
      if (!this.cardData) {
        let info = this.$t("Components.Brands.Payment.cardNumberError")
        this.$message.error(info)
        return
      }
      if (this.isCreditCard()) {
        if (this.checkCardInfo()) {
          this.addCardInfo()
        }
      } else {
        this.hideAddDialog()
      }
    },
    async addCardInfo() {
      await this.$store
        .dispatch("brandPayment/updateCardInfo", {
          brandId: this.brandId,
          cardInfo: this.cardData
        })

        .then(response => {
          if (response.error) {
            this.$message.error(response.developerMessage)
          } else {
            let info = this.$t("Components.Brands.Payment.addSuccess")
            this.$message.success(info)
            this.cardData = undefined
            this.hideAddDialog()
          }
        })
    },
    receiveCardData(data) {
      this.cardData = data
    },
    showPaymentMethods() {},
    doPurchase() {
      const list = this.paymentMethodsData.filter(item => item.default)
      if (list && list.length === 0) {
        this.$message.error("Please add credit card")
        return
      }
      this.showPurchase = false
      const params = {
        ...this.payment,
        cardNumber: AES.encrypt(list[0].cardNumber)
        // cardNumber: this.selectedPayment.cardNumber,
      }
      this.$emit("doPurchase", params)
      // await this.updatePaymentTrainingSub({ brandId: this.brand.id, payment })
    },
    receiveSelectedData() {
      // if (data.payType && data.payType !== "creditCard") {
      //   this.isShowMore = false
      // } else {
      //   this.isShowMore = true
      // }
      // this.selectedPayment = data
      // if (this.isShowList) {
      //   this.selectedPayment.payType = "creditCard"
      //   this.isShowList = false
      // }
      // this.initCanPurchase()
    }
  }
}
</script>

<style scoped lang="scss">
.add-new-retailer-purchase__main {
  p {
    margin-bottom: 0;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .payment-add-retailer-amount {
    padding: 20px;
    font-size: 18px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .payment-add-retailer-purchase {
    text-align: center;
  }
}
</style>
