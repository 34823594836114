<template>
  <div ref="retailerLocationUploadFile" class="admin-training-retailer-location__main">
    <a-row>
      <a-col :span="14">
        <search-component
          :search-recent="searchRecent"
          search-hint="Search by City or Zip"
          @doSearch="onSearch"
          @deleteTag="closeTagItem"
        />
      </a-col>
      <a-col :span="10" style="text-align: right;">
        <!-- <a-button class="admin-training-landing-red-btn" @click="showUploadDialog"
          >Upload Location</a-button> -->
        <!-- <a-upload
            name="file"
            :multiple="false"
            :show-upload-list="false"
            :file-list="fileList"
            :custom-request="doUpload"
            :before-upload="beforeUpload"
            style="float: right;"
          >
            <a-button
              class="admin-training-landing-red-btn"
            >
              {{ $t("Admins.AdminInventory.upload") }}
            </a-button>
          </a-upload> -->
        <a-button
          class="admin-training-landing-red-border-btn"
          style="margin-right: 6px; margin-left: 6px;"
          @click="clickToDownload"
          >Download CSV</a-button
        >

        <a-button class="admin-training-landing-red-btn" @click="showCreateNewDialog"
          >Add Location</a-button
        >
      </a-col>
    </a-row>

    <a-table
      :columns="tableColumn"
      :data-source="tableData"
      :loading="isLoading"
      size="middle"
      class="admin-training-table"
      :row-class-name="
        (_recode, index) => (index % 2 === 1 ? 'admin-training-table-striped' : null)
      "
      style="margin-top: 40px;"
      :get-popup-container="() => $refs.retailerLocationUploadFile"
    >
      <span slot="modifyTitle">
        Modified
        <a-icon
          type="caret-up"
          :rotate="sortModify.toString().length > 0 && sortModify === 'descend' ? 180 : 0"
          :class="
            sortModify.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('modified')"
        />
      </span>
      <span slot="storeTitle">
        Store #
        <a-icon
          type="caret-up"
          :rotate="sortStoreNumber.toString().length > 0 && sortStoreNumber === 'descend' ? 180 : 0"
          :class="
            sortStoreNumber.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('storeNumber')"
        />
      </span>
      <span slot="storeNameTitle">
        Store Name
        <a-icon
          type="caret-up"
          :rotate="sortStoreName.toString().length > 0 && sortStoreName === 'descend' ? 180 : 0"
          :class="
            sortStoreName.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('storeName')"
        />
      </span>
      <span slot="addressTitle">
        Address
        <a-icon
          type="caret-up"
          :rotate="sortAddress.toString().length > 0 && sortAddress === 'descend' ? 180 : 0"
          :class="
            sortAddress.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('address')"
        />
      </span>
      <span slot="cityTitle">
        City
        <a-icon
          type="caret-up"
          :rotate="sortCity.toString().length > 0 && sortCity === 'descend' ? 180 : 0"
          :class="
            sortCity.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('city')"
        />
      </span>
      <span slot="stateTitle">
        State
        <a-icon
          type="caret-up"
          :rotate="sortState.toString().length > 0 && sortState === 'descend' ? 180 : 0"
          :class="
            sortState.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('state')"
        />
      </span>
      <span slot="ZipTitle">
        Zip
        <a-icon
          type="caret-up"
          :rotate="sortZip.toString().length > 0 && sortZip === 'descend' ? 180 : 0"
          :class="
            sortZip.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('postalCode')"
        />
      </span>
      <span slot="phoneTitle">
        TelePhone
        <a-icon
          type="caret-up"
          :rotate="sortPhone.toString().length > 0 && sortPhone === 'descend' ? 180 : 0"
          :class="
            sortPhone.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('phone')"
        />
      </span>
      <span slot="customCheckTitle" style="text-align: center;">
        <a-checkbox v-model="isCheckAll" @change="handleSelectAllReport" />
      </span>
      <span slot="checkAction" slot-scope="text, record">
        <a-checkbox :checked="text" @change="checkedValue => chooseData(checkedValue, record)" />
      </span>
      <span slot="stateData" slot-scope="text, record" style="text-transform:uppercase;">
        {{ record.state ? record.state.id : "" }}
      </span>
      <span slot="modifyTime" slot-scope="text, record">
        {{ record.updatedAt ? formatDate(record.updatedAt) : formatDate(record.createdAt) }}
      </span>
      <span slot="active" slot-scope="text">
        <!-- <a-switch
          :checked="text"
          class="admin-training-switch"
          @change="deactionLocation(record)"
        /> -->
        <div :class="text ? 'admin-training-active-green' : 'admin-training-deactive-red'"></div>
      </span>
      <span
        slot="actionOper"
        slot-scope="text, record"
        class="admin-training-retailer__click-oper"
        @click="clickToEdit(record)"
      >
        Edit
      </span>
    </a-table>
    <AdminTrainintRetailerLocationEditDialog ref="createNewLocationDialog" />
  </div>
</template>
<script>
import AdminTrainintRetailerLocationEditDialog from "@/components/admin/AdminTrainingRetailerLocationEditDialogNew"
import SearchComponent from "@/components/training/SearchComponent"
import { downloadCsvData, getNowDateStr, replaceCommaForCsvStr } from "@/utils/validate"
import { adminRetailerLocationTag } from "@/utils/const"

export default {
  components: {
    AdminTrainintRetailerLocationEditDialog,
    "search-component": SearchComponent
  },
  props: {
    retailer: { type: Object, default: () => undefined },
    isLoading: { type: Boolean, default: () => false }
  },
  data() {
    return {
      isCheckAll: false,
      showUpload: false,
      uploading: false,
      fileList: [],
      selectedData: new Set(),
      searchRecent: [],
      searchValue: "",
      tableColumn: [
        {
          key: "isCheck",
          dataIndex: "isChecked",
          slots: { title: "customCheckTitle" },
          scopedSlots: { customRender: "checkAction" },
          width: "30px"
        },
        {
          //title: "Modified",
          dataIndex: "modified",
          key: "modified",
          scopedSlots: { customRender: "modifyTime" },
          slots: { title: "modifyTitle" }
          //sorter: true
        },
        {
          //title: "Store #",
          dataIndex: "storeNumber",
          key: "storeNumber",
          //sorter: true
          slots: { title: "storeTitle" }
        },
        // {
        //   //title: "Store Name",
        //   dataIndex: "storeName",
        //   key: "storeName",
        //   slots: { title: "storeNameTitle" }
        //   // sorter: true
        // },
        {
          //title: "Address",
          dataIndex: "address",
          key: "address",
          slots: { title: "addressTitle" }
          // sorter: true
        },
        {
          //title: "City",
          dataIndex: "city",
          key: "city",
          slots: { title: "cityTitle" }
          //sorter: true
        },
        {
          //title: "State",
          dataIndex: "state",
          key: "state",
          // sorter: true,
          slots: { title: "stateTitle" },
          scopedSlots: { customRender: "stateData" }
        },
        {
          //title: "Zip",
          dataIndex: "postalCode",
          key: "postalCode",
          // sorter: true
          slots: { title: "ZipTitle" }
        },
        {
          //title: "Telephone",
          dataIndex: "phone",
          key: "phone",
          // sorter: true
          slots: { title: "phoneTitle" }
        },
        {
          title: "Active",
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "active" }
        },
        {
          title: "",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "actionOper" }
        }
      ],
      tableData: [],
      originalData: [],
      sortModify: "",
      sortStoreNumber: "",
      sortStoreName: "",
      sortAddress: "",
      sortCity: "",
      sortState: "",
      sortZip: "",
      sortPhone: ""
    }
  },
  watch: {
    retailer(old, newValue) {
      console.log("....retaielr change watch.....")
      console.log(old)
      console.log(newValue)
      console.log(this.retailer)
      if (this.retailer && this.retailer.locations) {
        this.initTableData(this.retailer.locations, true)
      }
    }
  },
  async created() {
    this.searchRecent = await this.$store.dispatch(
      "training/getSearchBrandTags",
      adminRetailerLocationTag
    )
  },
  methods: {
    async onSearch(value) {
      this.searchValue = value
      this.searchRecent = await this.$store.dispatch("training/updateBrandTags", {
        tagName: adminRetailerLocationTag,
        tagItem: this.searchValue
      })
      let result = this.originalData.filter(item => {
        if (
          // (item.address && item.address.toLowerCase().indexOf(value.toLowerCase()) >= 0) ||
          (item.city &&
            item.city
              .toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0) ||
          // (item.country &&
          //   item.country.id &&
          //   item.country.id
          //     .toString()
          //     .toLowerCase()
          //     .indexOf(value.toLowerCase()) >= 0) ||
          // (item.phone &&
          //   item.phone
          //     .toString()
          //     .toLowerCase()
          //     .indexOf(value.toLowerCase()) >= 0) ||
          (item.postalCode &&
            item.postalCode
              .toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0)
        ) {
          return true
        }
        return false
      })

      console.log(result)
      this.initTableData(result, false)
    },
    sortByField(field) {
      let index = 0
      let order = ""
      if (field === "modified") {
        index = 1
        if (this.sortModify.length === 0) {
          this.sortModify = "ascend"
        } else if (this.sortModify === "ascend") {
          this.sortModify = "descend"
        } else {
          this.sortModify = "ascend"
        }
        this.sortStoreNumber = ""
        this.sortStoreName = ""
        this.sortAddress = ""
        this.sortCity = ""
        this.sortState = ""
        this.sortZip = ""
        this.sortPhone = ""
        order = this.sortModify
      } else if (field === "storeName") {
        index = 3
        if (this.sortStoreName.length === 0) {
          this.sortStoreName = "ascend"
        } else if (this.sortStoreName === "ascend") {
          this.sortStoreName = "descend"
        } else {
          this.sortStoreName = "ascend"
        }
        this.sortStoreNumber = ""
        this.sortModify = ""
        this.sortAddress = ""
        this.sortCity = ""
        this.sortState = ""
        this.sortZip = ""
        this.sortPhone = ""
        order = this.sortStoreName
      } else if (field === "address") {
        index = 4
        if (this.sortAddress.length === 0) {
          this.sortAddress = "ascend"
        } else if (this.sortAddress === "ascend") {
          this.sortAddress = "descend"
        } else {
          this.sortAddress = "ascend"
        }
        this.sortStoreNumber = ""
        this.sortStoreName = ""
        this.sortModify = ""
        this.sortCity = ""
        this.sortState = ""
        this.sortZip = ""
        this.sortPhone = ""
        order = this.sortAddress
      } else if (field === "storeNumber") {
        index = 2
        if (this.sortStoreNumber.length === 0) {
          this.sortStoreNumber = "ascend"
        } else if (this.sortStoreNumber === "ascend") {
          this.sortStoreNumber = "descend"
        } else {
          this.sortStoreNumber = "ascend"
        }
        this.sortModify = ""
        this.sortStoreName = ""
        this.sortAddress = ""
        this.sortCity = ""
        this.sortState = ""
        this.sortZip = ""
        this.sortPhone = ""
        order = this.sortStoreNumber
      } else if (field === "city") {
        index = 5
        if (this.sortCity.length === 0) {
          this.sortCity = "ascend"
        } else if (this.sortCity === "ascend") {
          this.sortCity = "descend"
        } else {
          this.sortCity = "ascend"
        }
        this.sortStoreNumber = ""
        this.sortStoreName = ""
        this.sortAddress = ""
        this.sortModify = ""
        this.sortState = ""
        this.sortZip = ""
        this.sortPhone = ""
        order = this.sortCity
      } else if (field === "state") {
        index = 6
        if (this.sortState.length === 0) {
          this.sortState = "ascend"
        } else if (this.sortState === "ascend") {
          this.sortState = "descend"
        } else {
          this.sortState = "ascend"
        }
        this.sortStoreNumber = ""
        this.sortStoreName = ""
        this.sortAddress = ""
        this.sortCity = ""
        this.sortModify = ""
        this.sortZip = ""
        this.sortPhone = ""
        order = this.sortState
      } else if (field === "postalCode") {
        index = 7
        if (this.sortZip.length === 0) {
          this.sortZip = "ascend"
        } else if (this.sortZip === "ascend") {
          this.sortZip = "descend"
        } else {
          this.sortZip = "ascend"
        }
        this.sortStoreNumber = ""
        this.sortStoreName = ""
        this.sortAddress = ""
        this.sortCity = ""
        this.sortState = ""
        this.sortModify = ""
        this.sortPhone = ""
        order = this.sortZip
      } else if (field === "phone") {
        index = 8
        if (this.sortPhone.length === 0) {
          this.sortPhone = "ascend"
        } else if (this.sortPhone === "ascend") {
          this.sortPhone = "descend"
        } else {
          this.sortPhone = "ascend"
        }
        this.sortStoreNumber = ""
        this.sortStoreName = ""
        this.sortAddress = ""
        this.sortCity = ""
        this.sortState = ""
        this.sortZip = ""
        this.sortModify = ""
        order = this.sortPhone
      }
      this.sortData(index - 1, order == "ascend")
    },
    handleName(pagination, filters, sorter) {
      console.log(sorter)
      let index = 0
      if (sorter.field === "modified") {
        index = 1
      } else if (sorter.field === "storeName") {
        index = 3
      } else if (sorter.field === "address") {
        index = 4
      } else if (sorter.field === "storeNumber") {
        index = 2
      } else if (sorter.field === "city") {
        index = 5
      } else if (sorter.field === "state") {
        index = 6
      } else if (sorter.field === "postalCode") {
        index = 7
      } else if (sorter.field === "phone") {
        index = 8
      }
      if (!sorter.order) {
        sorter.order = "ascend"
      }
      this.tableColumn[index].sortOrder = sorter.order
      for (let i = 1; i < 9; i++) {
        if (i !== index) {
          this.tableColumn[i].sortOrder = false
        }
      }
      this.sortData(index - 1, sorter.order == "ascend")
    },
    sortData(index, ascend) {
      if (index == 0) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.updatedAt != null && b.updatedAt != null) {
              return a.updatedAt.localeCompare(b.updatedAt)
            }
            if (a.updatedAt != null && b.updatedAt == null) {
              return a.updatedAt.localeCompare(b.createdAt)
            }
            if (a.updatedAt == null && b.updatedAt != null) {
              return a.createdAt.localeCompare(b.updatedAt)
            }
          } else {
            if (b.updatedAt != null && a.updatedAt != null) {
              return b.updatedAt.localeCompare(a.updatedAt)
            }
            if (b.updatedAt != null && a.updatedAt == null) {
              return b.updatedAt.localeCompare(a.createdAt)
            }
            if (b.updatedAt == null && a.updatedAt != null) {
              return b.createdAt.localeCompare(a.updatedAt)
            }
          }
        })
      } else if (index == 1) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.storeNumber - b.storeNumber
          } else {
            return b.storeNumber - a.storeNumber
          }
        })
      } else if (index == 2) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.storeName.localeCompare(b.storeName)
          } else {
            return b.storeName.localeCompare(a.storeName)
          }
        })
      } else if (index == 3) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.address.localeCompare(b.address)
          } else {
            return b.address.localeCompare(a.address)
          }
        })
      } else if (index == 4) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.city.localeCompare(b.city)
          } else {
            return b.city.localeCompare(a.city)
          }
        })
      } else if (index == 5) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            if (a.state && a.state.id && b.state && b.state.id) {
              return a.state.id.localeCompare(b.state.id)
            }
            if (a.state && a.state.id && (!b.state || !b.state.id)) {
              return a.state.id.localeCompare("")
            }
            if ((!a.state || !a.state.id) && b.state && b.state.id) {
              return "".localeCompare(b.state.id)
            }
          } else {
            if (a.state && a.state.id && b.state && b.state.id) {
              return b.state.id.localeCompare(a.state.id)
            }
            if (b.state && b.state.id && (!a.state || !a.state.id)) {
              return b.state.id.localeCompare("")
            }
            if ((!b.state || !b.state.id) && a.state && a.state.id) {
              return "".localeCompare(a.state.id)
            }
          }
        })
      } else if (index === 6) {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.postalCode.localeCompare(b.postalCode)
          } else {
            return b.postalCode.localeCompare(a.postalCode)
          }
        })
      } else {
        this.tableData.sort((a, b) => {
          if (ascend) {
            return a.phone.localeCompare(b.phone)
          } else {
            return b.phone.localeCompare(a.phone)
          }
        })
      }
    },
    async closeTagItem(item) {
      await this.$store.dispatch("training/updateBrandTags", {
        tagName: adminRetailerLocationTag,
        tagItem: item.name,
        type: "delete"
      })
    },
    initTableData(data, needInitOrigin) {
      this.tableData = []
      if (needInitOrigin) {
        this.originalData = []
      }
      if (data && data.length > 0) {
        let len = data.length
        for (let i = 0; i < len; i++) {
          let aa = Object.assign({}, data[i])
          aa.isChecked = false
          if (aa.storeName == undefined) {
            aa.storeName = ""
          }
          if (aa.city == undefined) {
            aa.city = ""
          }
          this.tableData.push(aa)
          if (needInitOrigin) {
            this.originalData.push(aa)
          }
        }
      }
    },
    handleSelectAllReport() {
      this.selectedData.clear()
      this.tableData.forEach(item => {
        item.isChecked = this.isCheckAll
        if (this.isCheckAll) {
          this.selectedData.add(item)
        }
      })
    },
    chooseData(checkedValue, record) {
      record.isChecked = checkedValue.target.checked
      if (checkedValue.target.checked) {
        // add record into the list
        this.selectedData.add(record)
        if (this.selectedData.size === this.tableData.length) {
          this.isCheckAll = true
        }
      } else {
        // remove it form list
        this.selectedData.delete(record)
        if (this.isCheckAll) {
          this.isCheckAll = false
        }
      }
    },
    showCreateNewDialog() {
      this.$refs["createNewLocationDialog"].openDialog(
        false,
        "Add New Retail Location",
        this.retailer
      )
    },
    deactionLocation(data) {
      console.log("....de active location.....")
      let param = {
        id: this.retailer.id,
        name: this.retailer.name,
        shortName: this.retailer.shortName,
        status: this.retailer.status,
        locations: [
          { privateId: data.privateId, locationId: data.locationId, active: !data.active }
        ]
      }
      this.$store
        .dispatch("retailer/updateRetailer", param)
        .then(() => {
          this.$message.success(
            data.active ? "Deactive location success " : "Active location success "
          )
          this.isShow = false
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    },
    clickToEdit(data) {
      this.$refs["createNewLocationDialog"].openDialog(
        true,
        "Edit Retail Location",
        this.retailer,
        data
      )
    },
    async clickToDownload() {
      console.log("....download data.....")
      if (this.selectedData && this.selectedData.size > 0) {
        const brandName = this.retailer ? this.retailer.name : "retailer"
        await downloadCsvData(
          this.generateDownloadStr(),
          `${brandName}_location_${getNowDateStr()}.csv`
        )
      } else {
        this.$message.warning(this.$t("Admins.AdminInventory.downloadTooltip"), 5)
      }
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value)
        return new Intl.DateTimeFormat("en-US").format(date)
      }
      return " "
    },
    generateDownloadStr() {
      if (this.selectedData && this.selectedData.size > 0) {
        let csvData = ["Modified, Store #, Address, City, State, Zip, Telephone, Active"]
        for (let item of this.selectedData) {
          let str = `${this.formatDate(item.updatedAt ? item.updatedAt : item.createdAt)}, ${
            item.storeNumber
          }, ${item.address != null ? replaceCommaForCsvStr(item.address) : ""}, ${
            item.city != null ? replaceCommaForCsvStr(item.city) : ""
          }, ${item.state != null ? item.state.id : ""},${
            item.postalCode != null ? replaceCommaForCsvStr(item.postalCode) : item.postalCode
          }, ${item.phone != null ? replaceCommaForCsvStr(item.phone) : ""}, ${item.active}`
          csvData.push(str)
        }
        return csvData
      }
      return []
    },
    // upload csv
    showUploadDialog() {
      this.showUpload = true
      this.fileList = []
    },
    handleCancelUpload() {
      this.showUpload = false
      this.fileList = []
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = []
      this.fileList.push(file)
      return false
    },
    handleUpload() {
      if (this.fileList && this.fileList.length > 0) {
        let isFormatRight = true
        for (let i = 0; i < this.fileList.length; i++) {
          let name = this.fileList[i].name
          isFormatRight = this.isCsv(name)
          if (!isFormatRight) {
            let info = this.$t("Admins.AdminOrders.uploadFailInfo")
            this.$message.error(info)
            return
          }
        }
        this.doUpload(this.fileList[0])
      }
    },
    isCsv(name) {
      if (name) {
        let index = name.lastIndexOf(".")
        let ext = name.substr(index + 1)
        if (ext.toLowerCase() === "csv") {
          return true
        }
      }
      return false
    },
    // doUpload(data) {
    //   let file = data.file
    //   let fileReader = new FileReader()
    //   const formData = new FormData()
    //   fileReader.readAsText(file)
    //   let info = this.$t("Admins.AdminOrders.uploadFailInfo")
    //   fileReader.onloadend = e => {
    //     let data = new Blob([e.target.result], { type: "text/csv" })
    //     formData.append("file", data, file.name)
    //     this.uploading = true
    //     this.$store
    //       .dispatch("inventory/uploadInventory", formData)
    //       .then(res => {
    //         if (res.type === "success") {
    //           this.uploadSuccess = true
    //           this.$message.success("Upload success!", this.messageShowTime)
    //         } else {
    //           let msg = res.reports
    //           if (msg && msg.length > 0) {
    //             this.$message.error(msg[0], this.messageShowTime)
    //           } else {
    //             this.$message.error(info, this.messageShowTime)
    //           }
    //         }
    //       })
    //       .catch(error => {
    //         this.$message.error(info, this.messageShowTime)
    //         console.log(error)
    //       })
    //       .finally(() => {
    //         this.uploading = false
    //       })
    //   }
    // },
    doUpload(file) {
      let fileReader = new FileReader()
      const formData = new FormData()
      fileReader.readAsText(file)
      fileReader.onloadend = e => {
        let data = new Blob([e.target.result], { type: "text/csv" })
        formData.append("file", data, file.name)
        this.uploading = true
        this.$store
          .dispatch("training/uploadRetailerLocation", {
            retailerId: this.retailer.id,
            file: formData
          })
          .then(res => {
            if (res.states) {
              this.uploadSuccess = true
            } else if (res.reports) {
              this.errorReports = ""
              this.errors = ""
              res.reports.forEach(item => {
                let index = item.indexOf("cause:")
                if (index > 0) {
                  this.errorReports += `${item.substring(0, index)}\n`
                  this.errors += `${item.substring(0, index)}<br/>`
                } else {
                  this.errorReports += `${item}\n`
                  this.errors += `${item}<br/>`
                }
              })
              // this.showErrorReport()
            }
            this.handleCancelUpload()
          })
          .catch(error => {
            let info = this.$t("Admins.AdminOrders.uploadFailInfo")
            this.$message.error(info)
            console.log(error)
          })
          .finally(() => {
            this.uploading = false
            //this.isShiping = false
          })
      }
    }
  }
}
</script>
<style lang="scss">
.admin-training-retailer-location__main {
  //.ant-radio {
  //  top: 0;
  //}
  .ant-checkbox-wrapper::after {
    contain: none;
    display: none;
  }
}
.admin-training-retailer-location-upload .upload-modal__body {
  margin-top: 20px;
}
.admin-training-retailer-location-upload .ant-upload-drag-icon {
  i {
    color: #91d395 !important;
  }
}
.admin-training-retailer-location-upload .retailer-location-upload-modal__action {
  padding: 20px 0;
  text-align: center;
}

// .admin-training-retailer-location-upload .upload-modal__btn {
//   background: #ffffff;
//   border: 1px solid #e1644c ;
//   border-radius: 7px;
//   font-family: "Proxima Nova";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 20px;
//   letter-spacing: 0.04em;
//   color: #e1644c ;
// }
</style>
