<template>
  <div class="payment-method">
    <a-radio-group v-model="payType" size="large" button-style="outline">
      <a-radio-button class="credit-pay-type" value="credit-card">
        <a-icon type="credit-card" />
        {{ $t("Components.Brands.Payment.creditCardTitle") }}</a-radio-button
      >
      <a-radio-button value="wire-transfer">
        <a-icon type="bank" />
        {{ $t("Components.Brands.Payment.wireTransferTitle") }}</a-radio-button
      >
    </a-radio-group>
    <a-form
      layout="horizontal"
      v-if="payType == 'credit-card'"
      class="payment-card"
      :form="form"
    >
      <div class="payment-detail">
        <h3>Payment Details</h3>
        <!-- <a-form-item>
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Please input your name!' }],
              },
            ]"
            :placeholder="$t('Components.Brands.Payment.nameOnCard')"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-item> -->
        <div class="f-col">
          <a-form-item>
            <a-input
              v-decorator="[
                'firstName',
                {
                  rules: [
                    { required: true, message: 'First name is required' },
                  ],
                },
              ]"
              placeholder="First Name"
            >
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              v-decorator="[
                'lastName',
                {
                  rules: [{ required: true, message: 'Last name is required' }],
                },
              ]"
              placeholder="Last Name"
            >
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-item>
        </div>
        <a-form-item>
          <a-input
            v-decorator="[
              'cardNumber',
              {
                rules: [
                  { required: true, message: 'Name is required' },
                  {
                    validator: validateCardNumber,
                  },
                ],
              },
            ]"
            :placeholder="$t('Components.Brands.Payment.cardNumber')"
          >
            <a-icon slot="prefix" type="credit-card" />
            <template #suffix>
              <span class="payment-info__card-number-suffix" />
            </template>
          </a-input>
        </a-form-item>
        <div class="f-col">
          <div>
            <a-form-item>
              <a-input
                v-decorator="[
                  'expirationDate',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Expiration date is required',
                      },
                      {
                        validator: validateExpirationDate,
                      },
                    ],
                  },
                ]"
                placeholder="MM/YY"
              >
                <a-icon slot="prefix" type="calendar" />
              </a-input>
            </a-form-item>
          </div>
          <div>
            <a-form-item>
              <a-input
                v-decorator="[
                  'cvvCode',
                  {
                    rules: [
                      { required: true, message: 'CVV is required' },
                      {
                        validator: validateCVV,
                      },
                    ],
                  },
                ]"
                placeholder="CVV"
              >
              </a-input>
            </a-form-item>
          </div>
        </div>
      </div>
      <div class="billing-details">
        <h3>Billing Details</h3>
        <a-form-item>
          <a-input
            v-decorator="[
              'address1',
              {
                rules: [{ required: true, message: 'Address 1 is required' }],
              },
            ]"
            placeholder="Address 1"
          >
          </a-input>
        </a-form-item>
        <!-- <a-form-item>
          <a-input v-decorator="['address2', {}]" placeholder="Address 2">
          </a-input>
        </a-form-item> -->

        <div class="f-col">
          <div>
            <a-form-item>
              <a-input
                v-decorator="[
                  'city',
                  {
                    rules: [{ required: true, message: 'City is required' }],
                  },
                ]"
                placeholder="City"
              >
              </a-input>
            </a-form-item>
          </div>
          <div>
            <a-form-item>
              <a-input
                v-decorator="[
                  'state',
                  {
                    rules: [{ required: true, message: 'State is required' }],
                  },
                ]"
                placeholder="State"
              >
              </a-input>
            </a-form-item>
          </div>
        </div>
        <a-form-item>
          <a-input
            v-decorator="[
              'zipCode',
              {
                rules: [{ required: true, message: 'Zip Code is required' }],
              },
            ]"
            placeholder="Zip Code"
          >
          </a-input>
        </a-form-item>
        <div>
          <a-checkbox v-model="isDefaultPayment">
            {{ $t("Components.Brands.Payment.saveCardAsDefault") }}
          </a-checkbox>
        </div>
      </div>
      <div class="action">
        <a-button
          @click="saving"
          class="green-btn"
          size="large"
          :loading="isSaving"
        >
          {{ $t("Components.Brands.Payment.updatePayment") }}
          <a-icon type="credit-card" />
        </a-button>
      </div>
    </a-form>
    <div v-else class="wire-transfer-content">
      <span v-html="$t('Components.Brands.Payment.wireTransferInfo')" />
    </div>
  </div>
</template>
<script>
import { CustomerPayment } from "@/models/Payment";
import SubscriptionService from "@/services/subscriptions-service";
export default {
  props: {
    brandId: { type: String },
  },
  data() {
    return {
      payType: "credit-card",
      isDefaultPayment: false,
      isSaving: false,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "payment" });
  },
  methods: {
    validateCardNumber(rules, value, callback) {
      console.log("validateCardNumber");
      if (value) {
        var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
        const creditCardRegex = /\d{12,16}/g;
        if (!creditCardRegex.test(v)) {
          callback("Card Number is invalid");
          // } else if (!this.luhn(v)) {
          //   callback("Card Number is invalid");
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    validateExpirationDate(rules, value, callback) {
      const v = (value || "").trim();
      if (v) {
        const expirationDateRegex = /^[0-9]{2}[/][0-9]{2}$/;
        if (expirationDateRegex.test(v)) {
          callback();
        } else {
          callback("Expiration Date is invalid");
        }
      } else {
        callback();
      }
    },
    validateCVV(rules, value, callback) {
      if (value) {
        const cvvRegex = /^[0-9]{3,4}$/;
        if (cvvRegex.test(value)) {
          callback();
        } else {
          callback("CVV number is invalid");
        }
      } else {
        callback();
      }
    },
    async saving(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          var billingAddress = {
            addressLine1: values.address1,
            // addressLine2: values.address2,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
          };
          this.isSaving = true;
          var payment = {
            firstName: values.firstName,
            lastName: values.lastName,
            cardNumber: values.cardNumber,
            expirationDate: values.expirationDate,
            cvvCode: values.cvvCode,
            billingAddress: billingAddress,
          };
          var customerPayment = new CustomerPayment(payment);
          var brandId = this.brandId;
          var isDefaultPaymentMethod = this.isDefaultPayment;

          SubscriptionService.addPaymentMethod(
            brandId,
            isDefaultPaymentMethod,
            customerPayment
          ).finally(() => {
            this.isSaving = true;
            this.$emit("addPayment");
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.payment-method {
  font-family: Proxima Nova;
  .wire-transfer-content {
    font-size: 9px;
    line-height: 20px;
    color: #000000;
  }
  .action {
    display: flex;
    justify-content: center;
    padding-top: 24px;
    .ant-btn.green-btn {
      display: inline-flex;
      align-items: center;

      color: #4a8a5d;
      font-size: 16px;
      font-family: Proxima Nova;
      border: 1px solid #4a8a5d;
      .anticon {
        font-size: 20px;
        svg {
          color: #4a8a5d;
        }
      }
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-bottom: 24px;
  }
  .ant-radio-button-wrapper {
    padding: 0 24px;
    border-left: 1px solid #d9d9d9;
    span {
      font-family: Proxima Nova;
      font-size: 12px;
      color: #595959;
    }
    .anticon {
      font-size: 18px;
      padding-right: 5px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  label.ant-radio-button-wrapper-checked {
    background-color: #e2eeec;
    border: 1px solid transparent !important;
  }
}
.payment-card {
  h3 {
    padding-left: 8px;
  }
  width: 375px;
  .ant-form-item {
    margin-bottom: 18px;
  }

  .f-col {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .anticon {
    svg {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
