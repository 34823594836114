<template>
  <div class="guest-layout">
    <GuestHeader />
    <router-view class="guest-layout__router-view" />
    <GuestFooter />
  </div>
</template>

<script>
import GuestHeader from "./guest/Header"
import GuestFooter from "./guest/Footer"
export default {
  components: {
    GuestHeader,
    GuestFooter
  }
}
</script>

<style lang="scss" scoped>
.guest-layout {
  min-height: 100vh !important;
  &__router-view {
    margin-top: 31px !important;

    @media screen and (max-width: 800px) {
      margin-top: 80px !important;
    }
  }
}
</style>