<template>
  <div class="order-summary__bg">
    <p class="order-summary__title">{{ $t("Components.Brands.Payment.orderSummary") }}</p>
    <span
      class="order-summary__light-gray"
      v-html="$t('Components.Brands.Payment.premiumSubscription')"
    />
    <a-row class="order-summary__row">
      <img src="@/assets/img/icon/check-circle.svg" />
      <span class="order-summary__light-gray">{{
        $t("Components.Brands.Payment.trainingPage")
      }}</span>
    </a-row>
    <a-row class="order-summary__row">
      <img src="@/assets/img/icon/check-circle.svg" />
      <span class="order-summary__light-gray">{{
        $t("Components.Brands.Payment.cleanPreCheck")
      }}</span>
    </a-row>
    <a-row class="order-summary__row">
      <img src="@/assets/img/icon/check-circle.svg" />
      <span class="order-summary__light-gray">{{
        $t("Components.Brands.Payment.retailerProfile")
      }}</span>
    </a-row>
    <a-row class="order-summary__row-big order-summary__row-col">
      <a-col :span="18">
        <span class="order-summary__dark-gray">{{
          $t("Components.Brands.Payment.oneYearSubscription")
        }}</span>
      </a-col>
      <a-col :span="6">
        <span class="order-summary__dark-gray">$2000.00</span>
      </a-col>
    </a-row>
    <hr class="order-summary__border" />
    <a-row>
      <p class="order-summary__title">{{ $t("Components.Brands.Payment.addPromocode") }}</p>
      <a-col :span="16" style="margin-top: 10px; margin-bottom: 6px;">
        <a-input
          v-model="promocode"
          class="order-summary__dark-gray"
          :placeholder="$t('Components.Brands.Payment.enterCode')"
          style="width: 90%; border-radius: 2px;"
          @change="changePromoCode"
        />
      </a-col>
      <a-col :span="8" style="margin-top: 10px;  margin-bottom: 6px;">
        <a-button class="order-summary__button" @click="checkCode">{{
          $t("Components.Brands.Payment.apply")
        }}</a-button>
      </a-col>
      <p v-if="isCodeError" class="help is-danger">{{ $t("Components.Brands.Payment.errCode") }}</p>
      <p v-if="isCodeUnEnalbe" class="help is-danger">
        {{ $t("Components.Brands.Payment.errUnEanlbe") }}
      </p>
      <p v-if="isCodeExpired" class="help is-danger">
        {{
          $t("Components.Brands.Payment.errExpired", {
            date: `${startDate.format("MM/DD/YYYY")}-${endDate.format("MM/DD/YYYY")}`
          })
        }}
      </p>
    </a-row>
    <a-row class="order-summary__row-col">
      <a-col :span="18">
        <span class="order-summary__dark-gray">{{
          $t("Components.Brands.Payment.todayTotal")
        }}</span>
      </a-col>
      <a-col :span="6">
        <span class="order-summary__dark-gray">{{ totalAmount }}</span>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Row, Col } from "ant-design-vue"
import moment from "moment"
export default {
  components: {
    "a-row": Row,
    "a-col": Col
  },
  data() {
    return {
      isCodeError: false,
      isCodeUnEnalbe: false,
      isCodeExpired: false,
      promocode: undefined,
      totalAmount: "$2000.00",
      startDate: undefined,
      endDate: undefined
    }
  },
  methods: {
    changePromoCode() {
      if (!this.promocode || this.promocode.length === 0) {
        this.totalAmount = "$2000.00"
        this.isCodeError = false
        this.isCodeUnEnalbe = false
        this.isCodeExpired = false
      }
      this.$emit("changeCode", this.promocode)
    },
    checkCode() {
      if (this.promocode && this.promocode.length > 0) {
        //once check success, send emit to make the purchase button can purchase
        // compute the total
        let param = {
          _limit: 50,
          "page-index": 1,
          couponCode: this.promocode
        }
        this.$store
          .dispatch("adminConsole/getCoupons", param)
          .then(res => {
            if (res && res.list && res.list.length > 0) {
              this.checkCouponValid(res.list[0])
            } else {
              this.isCodeError = true
            }
          })
          .catch(err => {
            console.log(err)
            this.isCodeError = true
          })
      } else {
        this.isCodeError = false
      }
    },
    checkCouponValid(data) {
      console.log(!data.isEnable)
      this.isCodeError = false
      this.endDate = moment(data.endTime, "YYYY-MM-DD")
      this.startDate = moment(data.startTime, "YYYY-MM-DD")
      let now = moment()
      if (!data.isEnable) {
        // coupon now is deactive, can't be used
        this.isCodeUnEnalbe = true
        this.isCodeExpired = false
      } else if (now.diff(this.endDate) > 0 || now.diff(this.startDate) < 0) {
        this.isCodeExpired = true
        this.isCodeUnEnalbe = false
      } else {
        this.isCodeExpired = false
        this.isCodeUnEnalbe = false
        this.computeTotalAmount(data)
      }
    },
    computeTotalAmount(data) {
      let total = 2000
      if (data.isPercentage) {
        total = total * (1 - data.amount / 100)
      } else {
        total = total - data.amount
      }
      this.totalAmount = this.priceFormat(total)
      this.isCodeError = false
      this.$emit("checkSuccess", this.promocode)
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    }
  }
}
</script>

<style lang="sass" scoped>
.order-summary
    font-family: Proxima Nova
    font-style: normal
    &__bg
        width: 282px
        border: 1px solid #D9D9D9
        box-sizing: border-box
        padding: 24px 10px 10px 16px
    &__title
        font-weight: bold
        font-size: 18px
        line-height: 24px
        color: #262626
        padding: 0px !important
        margin-bottom: 2px !important
    &__row
        align-content: center
        margin-bottom: 10px !important
    &__row-big
        margin-top: 10px !important
    &__row-col
        width: 221px
        align-content: center
        margin-bottom: 6px !important
    &__light-gray
        font-weight: normal
        font-size: 12px
        line-height: 22px
        color: #8C8C8C
        margin-bottom: 6px !important
    &__dark-gray
        font-weight: normal
        font-size: 12px
        line-height: 22px
        color: #595959
    &__border
        width: 221px
        border-bottom: 0.5px solid #E8E8E8
        height: 1px
        margin-bottom: 17px !important
    &__button
        font-family: Proxima Nova !important
        font-style: normal !important
        font-weight: normal !important
        font-size: 16px !important
        line-height: 24px !important
        border-radius: 2px !important
        border-color: #4A8A5D !important
        color: #4A8A5D !important
img
    margin-right: 7px
.ant-col-6
    text-align: right
</style>
