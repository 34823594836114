<template>
  <div>
    <a-modal
      v-model="isShow"
      width="952px"
      title="Delete"
      ok-text="Save"
      :closable="true"
      class="admin-console-edit-dialog"
      :destroy-on-close="true"
      @cancel="cancelEdit"
      @ok="doSaveEditValue"
    >
      <div
        style="min-height: 270px; width: 100%; margin: 0 auto;"
        class="admin-console-delete-reference-dialog"
      >
        <div>
          <a-tag
            v-for="(item, index) in datas"
            :key="`${item}_${index}_delete`"
            closable
            class="add-new-dialog__tags"
            :style="item.isActive === true ? 'display: inline-block;' : 'display: none;'"
            @close="removeTag(item)"
          >
            {{ item.name }}
          </a-tag>
        </div>
        <a-row v-if="deletedDatas !== undefined && deletedDatas.length > 0">
          <div class="admin-console-delete-reference-dialog__delete-text">
            Delete
          </div>
          <div
            v-for="(item, index) in deletedDatas"
            :key="`${item.name}_${index}_item`"
            class="admin-console-delete-reference-dialog__deleted-tag"
            @click="undoDelete(item)"
          >
            {{ item.name }}
          </div>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
      datas: [],
      deletedDatas: []
    }
  },
  methods: {
    cancelEdit() {
      this.isShow = false
      this.deletedDatas = []
    },
    openDialog(data) {
      this.isShow = true
      if (data && data.length > 0) {
        this.datas = []
        for (let i = 0; i < data.length; i++) {
          this.datas.push(Object.assign({}, data[i]))
        }
      }
    },
    doSaveEditValue() {
      if (this.deletedDatas && this.deletedDatas.length > 0) {
        this.$emit("doDelete", this.deletedDatas)
      }
      this.deletedDatas = []
      this.isShow = false
    },
    removeTag(item) {
      //   let index = this.findItemIndex(item, this.datas)
      //   if (index >= 0) {
      //     this.deletedDatas.push(item)
      //     console.log('before delete, then len of the datas: '+ this.datas.length)
      //     this.datas.splice(index, 1)
      //     console.log('after delete, then len of the datas: '+ this.datas)
      //     this.$forceUpdate()
      //   }
      item.isActive = false
      this.deletedDatas.push(item)
      this.$forceUpdate()
    },
    undoDelete(item) {
      item.isActive = true
      let index = this.findItemIndex(item, this.deletedDatas)
      if (index >= 0) {
        // this.datas.push(item)
        this.deletedDatas.splice(index, 1)
        this.$forceUpdate()
      }
    },
    findItemIndex(item, items) {
      let index = -1
      if (item && items && items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          if (item.privateId === items[i].privateId) {
            index = i
            break
          }
        }
      }
      return index
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-console-delete-reference-dialog {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  &__deleted-tag {
    font-size: 18px;
    line-height: 22px;
    color: #f5222d;
    padding: 6px 8px;
    border: 1px solid #f5222d;
    background: #ffffff;
    border-radius: 2px;
    //display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
    float: left;
    cursor: pointer;
  }
  &__delete-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 14px;
    margin-top: 12px;
  }
}
</style>
