/**
 * create by Amanda
 * 2021-2-5
 */
// Training page submits parameter to modify type
export const trainingEditType = {
  onlyTextarea: 1,
  formInfo: 2
}

export const retailerBrandTag = "SESS_RETAILER_TRAINING_BRAND_TAG"
export const retailerLocationTag = "SESS_RETAILER_LOCATION_TAG"
export const retailerUsersTag = "SESS_RETAILER_USERS_TAG"

export const adminBrandTag = "SESS_ADMIN_TRAINING_BRAND_TAG"
export const adminRetailerTag = "SESS_ADMIN_TRAINING_RETAILER_TAG"
export const adminUserTag = "SESS_ADMIN_TRAINING_USERS_TAG"
export const adminLoginTag = "SESS_ADMIN_TRAINING_LOGIN_TAG"
export const adminRetailerLocationTag = "SESS_ADMIN_RETAILER_LOCATION_TAG"
export const adminRetailerUsersTag = "SESS_ADMIN_RETAILER_USERS_TAG"
export const adminRetailerBrandsTag = "SESS_ADMIN_RETAILER_BRANDS_TAG"

export const newOrderStatus = [
  {
    id: "pending",
    name: "Pending",
    nextStatus: "confirmed",
    canClick: true
  },
  {
    id: "confirmed",
    name: "Confirmed",
    nextStatus: "delivered",
    canClick: true
  },
  {
    id: "delivered",
    name: "Shipped",
    nextStatus: "paid-by-retailer",
    canClick: true
  },
  // {
  //   id: "invoiced",
  //   name: "Invoiced",
  //   nextStatus: "paid-by-retailer",
  //   canClick: true
  // },
  {
    id: "paid-by-retailer",
    name: "Paid By Retailer",
    nextStatus: "paid-to-brand",
    canClick: true
  },
  {
    id: "paid-to-brand",
    name: "Paid To Brand",
    canClick: false
  },
  {
    id: "canceled",
    name: "Canceled"
  },
  {
    id: "deleted",
    name: "Deleted"
  }
]

export const messageSenderAndReceiverType = {
  ADMIN_TYPE: "landing",
  BRAND_TYPE: "brand",
  RETAILER_TYPE: "retailer"
}
export const messageSearchType = {
  LIST_TYPE: "list",
  DETAILS_TYPE: "details"
}
export const messageItemizeType = {
  SENT_TYPE: "send",
  INBOX_TYPE: "received",
  DRAFTS_TYPE: "drafts",
  ARCHIVE_TYPE: "archive",
  INTER_MESSAGE_TYPE: "interMessaging"
}
