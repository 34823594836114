<template>
  <div class="img-video-carousel__main">
    <div class="img-and-video-list">
      <div v-for="(item, index) in imgList" :key="index">
        <div
          v-if="item.asset.contentType.includes('image')"
          class="item-img-info"
          @click="showBigImg(item)"
        >
          <img class="img-asset-style" :src="item.asset.url" alt="/" />
        </div>
        <div v-if="item.asset.contentType.includes('video')" class="item-img-info">
          <div class="video-style" @click="clickToVideo(item.asset.url)">
            <img class="public-start" src="../../../assets/training_public_video.jpg" alt="" />
            <video width="auto" height="35" style="height: 35px !important;">
              <source :src="item.asset.url" type="video/mp4" />
              Your browser does not support the HTML5 video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="show-big-img">
      <img :src="showImg" alt="" />
    </div>
    <a-modal
      :visible="modalVisible"
      :footer="null"
      :body-style="{ padding: 0 }"
      @cancel="handleCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div v-if="modalVideo" class="video-main">
        <video id="video1" ref="videoM" controls>
          <source :src="modalVideo" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "ImgVideoCarousel",
  props: {
    imgList: {
      type: Array
    }
  },
  data() {
    return {
      showImg: "",
      modalVisible: false,
      modalVideo: ""
    }
  },
  computed: {
    // firstShowImg() {
    //   if (this.imgList && this.imgList.length !== 0) {
    //     return this.imgList[0]
    //   }
    //   return {}
    // }
  },
  watch: {
    modalVisible() {
      if (this.modalVisible === false) {
        this.$refs["videoM"].pause()
      }
    }
  },
  mounted() {
    if (this.imgList && this.imgList.length !== 0) {
      this.showImg = this.imgList[0].asset.url
    }
  },
  methods: {
    clickToVideo(url) {
      this.modalVideo = url
      this.modalVisible = true
      this.$refs["videoM"].play()
    },
    showBigImg(item) {
      this.showImg = item.asset.url
    },
    handleCancel() {
      this.modalVisible = false
      this.$refs["videoM"].pause()
    }
  }
}
</script>

<style lang="scss">
.img-video-carousel__main {
  height: 221px;
  display: flex;
  justify-content: space-between;
  .img-and-video-list {
    width: 35px;
    height: 222px;
    .item-img-info {
      width: 35px;
      height: 35px;
      border: 0.5px solid #d9d9d9;
      border-radius: 2px;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;
      .img-asset-style {
        width: 100%;
        height: 100%;
      }
    }
  }
  .show-big-img {
    width: 340px;
    height: 261px;
    text-align: center;
    margin-right: 50px;
    img {
      height: 261px;
      width: auto;
    }
  }
  .public-start {
    width: 15px !important;
    position: absolute;
    top: 8px;
    left: 10px;
    //height: 15px;
  }
}
</style>
