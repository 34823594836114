<template lang="pug">
.columns.admin-retailer-margin__no-margin-bottom
  v-expansion-panels(accordion flat id='retailerMarginItem')
    v-expansion-panel(v-for="(retailer,i) in retailers" :key="retailer.id" @change="revertMode")
      v-divider.retailer-margin-item__margin
      v-expansion-panel-header.retailer-margin-item__expansion-head {{retailer.name}}
      v-expansion-panel-content
        v-fab-transition
                v-btn(dark small absolute bottom right fab @click="addNewBrandMargin(retailer.id)")
                  v-icon mdi-plus
        v-container.admin-retailer-margin__no-padding-top-bottom(v-for="(item,i) in retailer.margins" :key="i")
          v-row(v-if="item.isShow")
            v-divider.retailer-margin-item__margin
          v-row(v-if="item.isShow")
              v-col.admin-retailer-margin__col-cell.admin-retailer-margin__padding-left(cols="3")
                span.admin-retailer-margin__text-center {{item.brand.name ? (item.brand.name ==='0' ? 'All Brand' : item.brand.name) : 'All Brand'}}
              v-col.admin-retailer-margin__col-cell(cols="3")
                span.admin-retailer-margin__text-center {{item.product.name ? item.product.name : 'All Product'}}
              v-col.admin-retailer-margin__col-cell(cols="1")
                v-text-field.admin-retailer-margin__no-padding-top-bottom.admin-retailer-margin__text-percent(
                  v-if="editItemId===i && newMarginRetailerId=== retailer.id"
                  placeholder="0.00"
                  :value="numberFormat(item.margin)"
                  :rules="rules"
                  @change="modifyMargin"
                  outlined dense
                  hide-detail="auto"
                  suffix="%")
                span.admin-retailer-margin__text-percent(v-else) {{numberFormat(item.margin)}} %
              v-col.admin-retailer-margin__col-cell(v-if="item.product && item.product.id && item.product.id !== '*'" cols="2")
                span.admin-retailer-margin__text-percent(v-html="countPriceFormat(item.product, item.margin)")
              v-col.admin-retailer-margin__col-cell(v-else cols="2")
                span.admin-retailer-margin__text-center
              v-col.admin-retailer-margin__col-cell(cols="2")
                button.button.admin-retailer-margin__btn_black-white(v-if="editItemId===i && newMarginRetailerId=== retailer.id" @click="saveMargin"  :class="{'disable-save': isSavingItem}" :disable="isSavingItem") {{$t('Admins.AdminRetailerMargin.save')}}
                button.button.admin-retailer-margin__btn_border-black(v-else @click="clickToEdit(i, retailer.id)") {{$t('Admins.AdminRetailerMargin.edit')}}
                button.button.admin-retailer-margin__btn_border-black(v-if="editItemId===i && newMarginRetailerId=== retailer.id" @click="clickToCancel") {{$t('Admins.AdminRetailerMargin.cancel')}}
                button.button.admin-retailer-margin__btn_border-black(v-else @click="clickToRemove(item, retailer.id)" :class="{'disable-save': isSavingItem}" :disable="isSavingItem") {{$t('Admins.AdminRetailerMargin.remove')}}
        v-row(v-if="isAddNewItem===true")
          v-col.admin-retailer-margin__col-cell
            Multiselect.admin-retailer-margin__compobox-cell(v-model="newSelectBrand" :options="allBrands" placeholder="Brand" clearable hide-selected outlined dense open-direction="bottom" :allow-empty="false" label="name" track-by="name" @select="chooseBrand")
          v-col.admin-retailer-margin__col-cell
            Multiselect.admin-retailer-margin__compobox-cell(v-model="newSelectProduct" :options="productsOptions" placeholder="Product" clearable hide-selected outlined dense open-direction="bottom" :allow-empty="false" label="name" track-by="name" :disabled="productsOptions.length===0" :loading="productsOptions.length===0")
          v-col.admin-retailer-margin__col-cell
            v-text-field.admin-retailer-margin__compobox-cell.admin-retailer-margin__margin-right(
            outlined dense
            placeholder="0.00"
            suffix="%"
            v-model="newMarginValue"
            :class="{ 'is-danger': $v.newMarginValue.$error }"
            )
            p.help.is-danger(v-if="!$v.newMarginValue.between")  Should between {{$v.newMarginValue.$params.between.min}} - {{$v.newMarginValue.$params.between.max}}
            span.admin-retailer-margin__ctn-width {{(newSelectProduct && newSelectProduct.id && newSelectProduct.id !== '*' ) ? priceFormat(newSelectProduct.msrpUsd * (1-newMarginValue/100)):''}}
          v-col.admin-retailer-margin__col-cell
            button.button.admin-retailer-margin__btn_black-white(@click="saveMargin" :class="{'disable-save': isSavingItem}" :disable="isSavingItem") {{$t('Admins.AdminRetailerMargin.save')}}
            button.button.admin-retailer-margin__btn_border-black(@click="clickToCancel") {{$t('Admins.AdminRetailerMargin.cancel')}}

</template>

<script>
import Multiselect from "vue-multiselect"
import { required, between } from "vuelidate/lib/validators"
export default {
  components: {
    Multiselect
  },
  props: {
    retailers: { type: Array, default: () => [] },
    allBrands: { type: Array, default: () => [] },
    allProducts: { type: Array, default: () => [] }
  },
  validations: {
    newMarginValue: { required, between: between(0, 100) }
  },

  data() {
    return {
      editItemId: "",
      isAddNewItem: false,
      newMarginRetailerId: "",
      newSelectBrand: { id: "*", name: "All Brand" },
      newSelectProduct: { id: "*", name: "All Product" },
      newMarginValue: 0.0,
      disabled: false,
      isSavingItem: false,
      editMarginItem: undefined,
      productsOptions: [{ id: "*", name: "All Product" }],
      rules: [
        value => !!value || "Required.",
        value => (value >= 0 && value <= 100) || "Should between 0-100"
      ]
    }
  },
  methods: {
    clickToEdit(index, id) {
      this.revertMode()
      this.editItemId = index
      this.newMarginRetailerId = id
      this.disabled = true
    },
    numberFormat(margin) {
      if (margin) {
        return Number(margin).toFixed(2)
      }
      return ""
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    countPriceFormat(item, margin) {
      const pro = this.allProducts.find(product => product.id === item.id)
      let price = 0
      if (pro) {
        price = pro.msrpUsd * (1 - margin / 100)
      }
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      let priceStr = numberFormat.format(price)
      if (price < 10) {
        let aa = priceStr.slice(0, 1) + "&ensp;&ensp;&ensp;&ensp;" + priceStr.slice(1)
        return aa
      } else if (price < 100) {
        return priceStr.slice(0, 1) + "&ensp;&ensp;&ensp;" + priceStr.slice(1)
      } else if (price < 1000) {
        return priceStr.slice(0, 1) + "&ensp;&ensp;" + priceStr.slice(1)
      }
      return priceStr
    },
    sendAction() {
      this.$emit("dataChange")
    },
    async clickToRemove(item, id) {
      this.revertMode()
      if (id !== "") {
        this.isSavingItem = true
        this.sendAction()
        if (item.retailer.id === "*") {
          this.$warning({ title: "Can't remove the default margin value!" })
          this.revertMode()
        } else {
          let param = []
          let brand = { id: item.brand.id }
          let product = { id: item.product.id }
          let retailer = { id: item.retailer.id }
          param.push({ brand: brand, product: product, retailer: retailer })
          try {
            await this.$store.dispatch("retailerMargin/removeRetailerMargins", {
              params: param,
              retailerId: id
            })
            this.revertMode()
            await this.$store.dispatch("retailerMargin/fetchRetailersMargins")
          } catch (error) {
            console.log(`error${error}`)
          }
        }
      }
    },
    revertMode() {
      this.isAddNewItem = false
      this.editItemId = ""
      this.newMarginRetailerId = ""
      this.selectBrand = null
      this.selectProduct = null
      this.newSelectBrand = { id: "*", name: "All Brand" }
      this.newSelectProduct = { id: "*", name: "All Product" }
      this.newMarginValue = 0
      this.disabled = false
      this.isSavingItem = false
      this.editMarginItem = undefined
    },
    clickToCancel() {
      this.revertMode()
    },

    addNewBrandMargin(id) {
      this.revertMode()
      this.isAddNewItem = true
      this.newMarginRetailerId = id
      this.disabled = true
    },

    selectBrand({ id, name }) {
      if (this.isAddNewItem) {
        this.newSelectBrand = { id, name }
      } else {
        this.retailers.map(retailer => {
          if (retailer.id == this.newMarginRetailerId) {
            retailer.margins[this.editItemId].brand.id = id
            retailer.margins[this.editItemId].brand.name = name
          }
        })
      }
    },
    selectProduct(item) {
      if (this.isAddNewItem) {
        this.newSelectProduct = item
      } else {
        this.retailers.map(retailer => {
          if (retailer.id == this.newMarginRetailerId) {
            retailer.margins[this.editItemId].product.id = item.id
            retailer.margins[this.editItemId].product.name = item.name
          }
        })
      }
    },
    modifyMargin(marginValue) {
      if (marginValue >= 0 && marginValue <= 100) {
        if (!this.isAddNewItem) {
          this.retailers.map(retailer => {
            if (retailer.id == this.newMarginRetailerId) {
              retailer.margins[this.editItemId].margin = marginValue
              this.editMarginItem = retailer.margins[this.editItemId]
            }
          })
        }
      }
    },
    async chooseBrand(selectOption) {
      if (selectOption) {
        this.productsOptions = []
        if (selectOption.id === "*") {
          this.productsOptions = [{ id: "*", name: "All Product" }]
        } else {
          await this.$store.dispatch("product/getBrandProducts", {
            params: {
              _limit: 9000,
              fields: "name,id,msrpUsd",
              brand: selectOption.id
            }
          })
          this.productsOptions = this.$store.getters["product/brandProducts"]
          this.productsOptions.unshift({ id: "*", name: "All Product" })
        }
      }
    },
    async saveMargin() {
      let modifyRetailer = null
      this.$v.$touch()
      if (this.$v.newMarginValue.$anyError) {
        return
      }
      this.isSavingItem = true
      this.sendAction()
      if (this.isAddNewItem) {
        this.retailers.map(retailer => {
          if (retailer.id == this.newMarginRetailerId) {
            if (!this.newSelectProduct.id) {
              this.newSelectProduct = { id: "*" }
            }
            if (!this.newSelectBrand.id) {
              this.newSelectBrand = { id: "*" }
            }
            let marginObj = {
              margin: this.newMarginValue,
              brand: this.newSelectBrand,
              product: this.newSelectProduct,
              retailer: { id: retailer.id }
            }
            retailer.margins.push(marginObj)
            modifyRetailer = []
            modifyRetailer.push(marginObj)
          }
        })
      } else {
        if (this.editMarginItem) {
          modifyRetailer = []
          delete this.editMarginItem.isShow
          delete this.editMarginItem.createdAt
          delete this.editMarginItem.createdBy
          delete this.editMarginItem.brand.name
          if (this.editMarginItem.retailer.id === "*") {
            this.editMarginItem.retailer.id = this.newMarginRetailerId
          }
          modifyRetailer.push(this.editMarginItem)
        }
      }
      try {
        await this.$store.dispatch("retailerMargin/updateRetailerMargins", {
          params: modifyRetailer,
          retailerId: this.newMarginRetailerId
        })
        this.revertMode()
        await this.$store.dispatch("retailerMargin/fetchRetailersMargins")
      } catch (error) {
        console.log(`error${error}`)
      }
    }
  }
}
</script>

<style lang="sass">
.retailer-margin-item__margin
  margin: 0 !important
.admin-retailer-margin
  padding: 0 10%
  margin-bottom: 100px
  &__margin-top
    margin: 4px 0px !important

  &__table-name
    color: #000000
    font-size: 20px
    font-weight: 700
    letter-spacing: 0.25px
    padding-left: 15px

  &__table-sub-name
    color: #9E9E9E !important
    font-size: 12px
    font-weight: 500
    padding-top: 10px
    padding-left: 15px

  &__add-retailer
    width: 130px
    height: 30px
    float: right
    text-align: center
    background: #716B68
    font-size: 14px
    font-weight: bold
    color: #eeeeee

  &__expansion-head
    font-weight: bold
    font-size: 12px
    display: flex

  &__text-percent
    text-align: center
    font-size: 14px !important
    width: 100%
  &__text-center
    text-align: left
    font-size: 14px !important
  &__text-right
    text-align: right
    font-size: 14px !important
    margin-bottom: 0px !important
    width: 100%
    padding-right: 4px

  &__col-cell
    display: flex
    text-align: left
    color: #333333 !important
    padding: 4px !important
    align-items: center
  &__col-col-cell
    display: flex
    align-items: center
  &__compobox-cell
    font-size: 14px !important

  &__filter-column-name
    color: #292929
    font-size: 16px
    font-weight: 300
    margin-bottom: 15px

  &__border
    border-bottom: 1px solid #E0E0E0

  &__no-margin-bottom
    margin: 0px !important


  &__compbox-left-block
    float: left
    display: block
    padding: 0.4em 1em
    vertical-align: middle
    margin: 10px !important


  &__btn-right
    float: right
    display: flex
    justify-content: end
    justify-content: flex-end
    font-size: 12px
    padding: 10px
    margin: 10px

  &__no-padding-bottom
    padding-bottom: 0px !important

  &__no-padding-top
    padding-top: 0px !important

  &__no-padding-top-bottom
    padding: 0px 0px !important

  &__margin-right
    margin-right: 10px

  &__btn_border-grad
    margin-right: 10px
    width: 70px
    height: 30px
    border: 1px solid #D9D9D9
    box-sizing: border-box
    border-radius: 2px
    font-size: 12px
    font-weight: bold
    color: #595959

  &__btn_border-black
    margin-right: 10px
    width: 70px
    height: 30px
    border: 1px solid #D9D9D9
    box-sizing: border-box
    border-radius: 2px
    font-size: 12px
    font-weight: bold
    color: #000000
    align-items: center

  &__btn_black-white
    color: #ffffff
    margin-right: 10px
    width: 70px
    height: 30px
    background: #000000
    align-items: center
    font-size: 12px
    font-weight: bold
  &__btn_black-white:hover
    color: #ffffff !important

  &__btn_black-white:focus
    background: #000000
    color: #ffffff
  &__padding
    padding: 0px 0px !important

  &__margin-left
    margin-left: 16px !important
  &__margin-left-eight
    margin-left: 8px !important
  &__ctn-width
    width: 40%
    text-align: right
  &__new-retailer-select
    width: 60% !important

  &__padding-left
    padding-left: 42px !important
#retailerMarginItem .v-messages__message
  color: #ff3860 !important
  font-size: 0.75rem !important
  line-height: 14px !important
  padding: 0 !important
  margin: 0 !important
#retailerMarginItem .v-text-field__details
  padding: 4px 0px 0px 0px !important
  margin-bottom: 0px !important
#retailerMarginItem .v-input__slot
  margin: 10px 0px 0px 0px!important
#retailerMarginItem .v-btn--fab.v-size--small.v-btn--absolute.v-btn--bottom
  bottom: -15px !important
#retailerMarginItem .v-btn--fab.v-size--small
  height: 25px !important
  width: 25px !important
#retailerMarginItem .v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot
  min-height: 20px !important
#retailerMarginItem .v-icon
  height: 20px !important
  width: 20px !important
  font-size: 20px !important
#retailerMarginItem .v-expansion-panel-header__icon
  margin-left: auto
#retailerMarginItem .v-expansion-panel--active>.v-expansion-panel-header
  min-height: 10px
#retailerMarginItem .v-expansion-panel-header
  font-weight: bold
  font-size: 12px
  display: flex
.v-text-field__slot input
  border: none
</style>
