import axios from "@/api/axios";

export default {
  /**
   * {{server_url}}/refdata/dirty-category-ingredient?id&ingredient-name=&category-name=a&page-index=1&_limit=50
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  fetchIngredientsByName(params = {}) {
    return axios.get(`/refdata/dirty-category-ingredient`, { params });
  },
  /**
   * {{server_url}}/refdata/dirty-category?category-name=a&active=true&page-index=1&_limit=50
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  fetchCategoryIngredientsByName(params = {}) {
    return axios.get(`/refdata/dirty-category`, {
      params: { active: true, ...params },
    });
  },
  createOrUpdateDirtyCategory(params) {
    return axios.put("/refdata/dirty-category", JSON.stringify(params));
  },
  createOrUpdateDirtyIngredients(params) {
    return axios.put(
      "/refdata/dirty-category-ingredient",
      JSON.stringify(params)
    );
  },
  deleteIngredientFromCategory(id) {
    return axios.delete(`/refdata/dirty-category-ingredient/${id}/delete`);
  },
  deleteCategory(id) {
    return axios.delete(`/refdata/dirty-category/${id}/delete`);
  },
};
