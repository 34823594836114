<template>
  <div ref="dialogProductModal" class="product-card-main">
    <div
      ref="dialogProductModal"
      class="training-product-card__main"
      :class="isClick ? 'training-product-card-click__main' : ''"
      @click="clickProduct"
    >
      <div class="prod-img">
        <img :src="item.images[0].asset.url" alt="" />
      </div>
      <div class="prod-content">
        <div class="content-title">{{ item.name }}</div>
        <div class="content-sku">SKU: {{ item.sku }}</div>
        <div class="content-price">MSRP: ${{ item.msrpUsd }}</div>
      </div>
    </div>
    <a-modal
      v-model="isShowModal"
      :centered="true"
      :footer="null"
      :width="464"
      :get-container="() => $refs.dialogProductModal"
      :title="null"
      :closable="false"
    >
      <span slot="closeIcon">
        <a-icon type="close-circle" style="font-size: 21px" />
      </span>
      <div class="dialog-info-before-submit">
        <p class="dialog-info-title"></p>
        <p class="dialog-content-style">
          This product does not have updated key benefits or ingredients information. Please update
          these fields from your product catalog.
        </p>
        <p class="dialog-close-btn" @click="SubmitDialog">Update Your Product Catalog</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      isClick: false,
      isShowModal: false
    }
  },
  methods: {
    clickProduct() {
      if (
        (this.item.newBenefits && this.item.newBenefits.length !== 0) ||
        (this.item.newIngredients && this.item.newIngredients.length !== 0)
      ) {
        this.isClick = !this.isClick
        this.$emit("getItemProduct", this.item, this.isClick)
      } else {
        this.isShowModal = true
        this.isClick = false
      }
    },
    SubmitDialog() {
      this.$router.push({
        name: "brandProduct",
        params: {
          id: this.item.id
        }
      })
    }
    // closeDialog() {
    //   this.isShowModal = false
    //   this.isClick = false
    // }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: "Proxima Nova";
  src: url("../../assets/fonts/Proxima-Nova-Light.otf") format("truetype");
}
.training-product-card__main {
  width: 220px;
  height: 364px;
  border: 2px solid #ffffff;
  .prod-img {
    max-width: 220px;
    height: 264px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 90%;
      max-height: 90%;
    }
  }
  .prod-content {
    text-align: center;
    font-size: 13px;
    font-family: "Proxima Nova";
    .content-title {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      height: 44px;
      word-wrap: break-word;
      word-break: normal;
      //display: block;
      //white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      //line-height: 44px;
    }
  }
}
.training-product-card__main:hover {
  border: 2px solid #8c8c8c;
  cursor: pointer;
}
.training-product-card-click__main {
  border: 2px solid #8c8c8c;
}
.ant-btn-primary {
  background-color: #12433d;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center;
  float: none;
}
.dialog-close-btn {
  width: 200px;
  height: 33px;
  line-height: 33px;
  border-radius: 2px;
  font-size: 14px;
  margin-top: 27px;
  background-color: #12433d;
  color: white;
  text-align: center;
  margin-left: 110px;
  cursor: pointer;
}
.dialog-close-btn:hover {
  background-color: #4a8a5d;
  color: #ffffff;
}
.ant-modal-confirm-title {
  text-align: center;
}
.info-dialog-main {
  text-align: center;
  background-color: yellow;
}
.dialog-info-before-submit {
  //width: 236px;
  text-align: center;
  color: #12433d;
  font-size: 13px;
  //margin-left: 77px;
  margin-top: 23px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
.dialog-info-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 23px;
  //height: 16px;
}
.dialog-content-style {
  width: 70%;
  margin-left: 60px;
}
</style>
