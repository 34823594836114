<template>
  <div
    class="message-customer-condition"
    :style="isSelected ? 'background: #E2EEEC;' : 'background: #F5F5F5;'"
  >
    <a-row>
      <a-col :span="22" class="message-customer-condition__text">
        <span style="display:block;">Recipient: {{ getRecipientTypeName() }}</span>
        <a-tooltip
          v-if="condition.filterClientName !== undefined && condition.filterClientName.length > 21"
          placement="bottomLeft"
          :title="`Filter: ${condition.filterClientName}`"
          overlay-class-name="brand-new-product__tooltip"
        >
          <span class="message-customer-condition__filter-name">
            Filter: {{ condition.filterClientName }}
          </span>
        </a-tooltip>
        <span v-else class="message-customer-condition__filter-name">
          Filter: {{ condition.filterClientName }}
        </span>
      </a-col>
      <a-col :span="2" style="text-align: right;">
        <a-tooltip placement="rightTop" overlay-class-name="message-customer-condition__menu">
          <template slot="title">
            <a-row>
              <a-col
                :span="24"
                class="message-customer-condition__menu-item"
                @click="clickToShowEdit"
              >
                <a-icon type="edit" style="color: #8c8c8c; margin-right: 6px" />
                Edit
              </a-col>
              <a-col
                :span="24"
                class="message-customer-condition__menu-item"
                @click="clickToShowDelete"
              >
                <a-icon type="delete" style="color: #8c8c8c; margin-right: 6px" />
                Delete
              </a-col>
            </a-row>
          </template>
          <a-icon
            type="more"
            style="color: #8c8c8c; position: relative; top: -8px; font-weight: bold; font-size: 14px;"
          />
        </a-tooltip>
      </a-col>
    </a-row>
    <div v-if="condition.startDate" class="message-customer-condition__divider"></div>
    <a-row v-if="condition.startDate">
      <a-col :span="22" class="message-customer-condition__date">
        {{ getDateRange() }}
      </a-col>
      <a-col :span="2" style="text-align: right;">
        <a-icon type="calendar" style="color: #8c8c8c; position: relative; top: -4px;"></a-icon>
      </a-col>
    </a-row>
    <ConfirmDialog
      ref="confirmDelete"
      class="admin-message-compose__custom-group"
      @doConfirm="doDeleteMessage"
    />
    <EditFilterDialog
      ref="editFilterDialog"
      class="admin-message-compose__custom-group"
      @update="sendUpdate"
    />
  </div>
</template>
<script>
import ConfirmDialog from "@/components/admin/ConfirmDialog"
import EditFilterDialog from "@/components/message/EditFilterDialog"
export default {
  components: {
    EditFilterDialog,
    ConfirmDialog
  },
  props: {
    condition: { type: Object, default: () => undefined },
    isSelected: { type: Boolean, default: () => false },
    brandList: { type: Array, default: () => [] },
    retailerList: { type: Array, default: () => [] },
    dateList: { type: Array, default: () => [] }
  },
  // data() {},
  methods: {
    clickToShowEdit() {
      console.log(this.condition)
      console.log(this.condition.startDate)
      if (this.condition.startDate) {
        //custom group
        console.log("show custom group edit modal")
        this.$refs["editFilterDialog"].openDialog(3, this.condition, [
          {
            retailer: this.brandList,
            brand: this.retailerList,
            dateList: this.dateList
          }
        ])
      } else {
        this.$refs["editFilterDialog"].openDialog(
          this.condition.filterClientType.id === "brand" ? 1 : 2,
          this.condition,
          this.condition.filterClientType.id === "brand" ? this.brandList : this.retailerList
        )
      }
    },
    clickToShowDelete() {
      this.$refs["confirmDelete"].openDialog(
        "Are you sure you wish to delete this custom group? ",
        "No",
        "Yes"
      )
    },
    getRecipientTypeName() {
      console.log(this.condition)
      // if (this.condition.recipient === "1" || this.condition.recipient === 1) {
      //   return "Brand"
      // }
      // if (this.condition.recipient === "2" || this.condition.recipient === 2) {
      //   return "Retailer"
      // }
      return this.condition.filterClientType.name
    },
    getDateRange() {
      console.log(this.condition)
      if (this.condition.startDate && this.condition.endDate) {
        if (this.condition.startDate.indexOf("1779") >= 0) {
          return "All Time"
        }
        return `${this.formateDate(this.condition.startDate)} ~ ${this.formateDate(
          this.condition.endDate
        )}`
      }
    },
    formateDate(dateStr) {
      const dateArray = dateStr.split("-")
      return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`
    },
    sendUpdate() {
      this.$emit("update")
    },
    doDeleteMessage() {
      console.log("do delete action....")
      // let param = {
      //   privateId: this.condition.privateId
      // }
      console.log(this.condition)
      this.$store
        .dispatch("adminMessage/deleteSearchGroup", this.condition.id)
        .then(() => {
          this.$message.success("Delete Success")
          this.sendUpdate()
        })
        .catch(err => {
          console.log(err)
          this.$message.error(`Delete Fail:${err.toString()}`)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.message-customer-condition {
  border-radius: 2px;
  width: 231px;
  height: 85px;
  padding: 12px;
  &__text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #262626;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__filter-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  &__date {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #8c8c8c;
  }
  &__divider {
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 5px;
  }
  &__menu {
    width: 104px;
    height: 60px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
    color: #8c8c8c;
    background: #fafafa;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
  }
  &__menu-item {
    padding: 7px 14px;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.message-customer-condition__menu .ant-tooltip-arrow {
  display: none;
}
.message-customer-condition__menu .ant-tooltip-inner {
  padding: 0px;
  border-radius: 2px;
  background: #fafafa;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  color: #8c8c8c;
  width: 104px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.message-customer-condition__menu .message-customer-condition__menu-item:hover {
  background-color: rgba(74, 138, 93, 0.15);
}
.admin-message-compose__custom-group .ant-modal-mask,
.admin-message-compose__custom-group .ant-modal-wrap {
  z-index: 1050;
}
</style>
