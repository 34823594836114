<template lang="pug">
.brand-new-product
  .modal-background
  .modal-card.brand-new-product-modal
    header.modal-card-head.brand-new-product-modal__header
      p.modal-card-title(v-if="!addressModal.id") {{$t('Components.Retailers.Settings.RetailerAddressModal.addAddress')}}
      p.modal-card-title(v-else) {{$t('Components.Retailers.Settings.RetailerAddressModal.editAddress')}}
      img.brand-new-product-modal__header-close-icon(src="@/assets/img/icon/close-icon.png" @click="emitModalClose")
    section.modal-card-body.brand-new-product-modal__body
      .columns.is-marginless.brand-new-product-modal__container
        .column.brand-new-product-modal__form
          .brand-new-product-base-form
            .field
              label.label.brand-new-product__label {{$t('Components.Retailers.Settings.RetailerAddressModal.addressee')}}
              .control
                input.input.brand-new-product__input--fullwidth.field-width(
                  v-model.trim="$v.addressModal.addressee.$model"
                  :class="{ 'is-danger': $v.addressModal.addressee.$error }"
                  v-bind:placeholder="$t('Components.Retailers.Settings.RetailerAddressModal.enterAddressee')"
              )
              p.help.is-danger(v-if="$v.addressModal.addressee.$error") {{$t('Components.Retailers.Settings.RetailerAddressModal.Validations.required')}}
 
            .columns
              .column
                .field
                  label.label.brand-new-product__label {{$t('Components.Retailers.Settings.RetailerAddressModal.streetAddress')}}
                  .control
                    input.input.brand-new-product__input--fullwidth(
                      v-model.trim="$v.addressModal.streetAddressLine_1.$model"
                      :class="{ 'is-danger': $v.addressModal.streetAddressLine_1.$error }"
                      v-bind:placeholder="$t('Components.Retailers.Settings.RetailerAddressModal.enterStreetAddress')"
                  )
                  p.help.is-danger(v-if="$v.addressModal.streetAddressLine_1.$error") {{$t('Components.Retailers.Settings.RetailerAddressModal.Validations.required')}}
          
            
            .columns
              .column
                .field
                  label.label.brand-new-product__label {{$t('Components.Retailers.Settings.RetailerAddressModal.aptSuiteBldg')}}
                  .control
                    .control
                      input.input.brand-new-product__input--fullwidth(
                      v-model="$v.addressModal.streetAddressLine_2.$model"
                      v-bind:placeholder="$t('Components.Retailers.Settings.RetailerAddressModal.enterAptSuiteBldg')"
                  )
          
          
            .columns
              .column
                .field
                  label.label.brand-new-product__label {{$t('Components.Retailers.Settings.RetailerAddressModal.city')}}
                  .control
                    .control
                      input.input.brand-new-product__input--fullwidth(
                      v-model.trim="$v.addressModal.city.$model"
                      :class="{ 'is-danger': $v.addressModal.city.$error }"
                      v-bind:placeholder="$t('Components.Retailers.Settings.RetailerAddressModal.enterCity')"
                  )
                  p.help.is-danger(v-if="$v.addressModal.city.$error") {{$t('Components.Retailers.Settings.RetailerAddressModal.Validations.required')}}
          
          
            .columns
              .column
                .field
                  label.label.brand-new-product__label {{$t('Components.Retailers.Settings.RetailerAddressModal.stateOrRegion')}}
                  .control
                    .control
                      input.input.brand-new-product__input--fullwidth(
                      v-model.trim="$v.addressModal.region.$model"
                      :class="{ 'is-danger': $v.addressModal.region.$error }"
                      v-bind:placeholder="$t('Components.Retailers.Settings.RetailerAddressModal.enterStateOrRegion')"
                  )
                  p.help.is-danger(v-if="$v.addressModal.region.$error") {{$t('Components.Retailers.Settings.RetailerAddressModal.Validations.required')}}
          
              .column
                .field
                  label.label.brand-new-product__label {{$t('Components.Retailers.Settings.RetailerAddressModal.postalCode')}}
                  .control
                    .control
                      input.input.brand-new-product__input--fullwidth(
                      v-model.trim="$v.addressModal.postalCode.$model"
                      :class="{'is-danger': $v.addressModal.postalCode.$error }"
                      v-bind:placeholder="$t('Components.Retailers.Settings.RetailerAddressModal.enterPostalCode')"
                  )
                  p.help.is-danger(v-if="$v.addressModal.postalCode.$error") {{$t('Components.Retailers.Settings.RetailerAddressModal.Validations.required')}}
          
            .columns
              .column
                .field
                  label.label.brand-new-product__label {{$t('Components.Retailers.Settings.RetailerAddressModal.country')}}
                    .control
                      .select.is-fullwidth.brand-settings-wireinstruction__select(
                        :class="{'is-danger': $v.addressModal.country.id.$error }"
                        v-bind:placeholder="$t('Components.Retailers.Settings.RetailerAddressModal.enterCountry')")
                        select(v-model="$v.addressModal.country.id.$model")
                          option(v-for="country in countries" :key="country.id" :value="country.id") {{ country.name }}
                      p.help.is-danger(v-if="$v.addressModal.country.id.$error") {{$t('Components.Retailers.Settings.RetailerAddressModal.Validations.required')}}       
          
    footer.modal-card-foot.brand-new-product-modal__form-buttons
      .columns
        .column
          .has-text-right
            a.button.brand-new-product-modal__cancel-button(@click="cancelChanges") {{$t('Components.Brands.Products.BrandProductModal.cancel')}}
            a.button.is-black.brand-new-product-modal__save-button(
              :class="{ 'is-disabled': isDisabled }"
              @click="saveAddress" :disabled="isDisabled") {{$t('Components.Brands.Products.BrandProductModal.save')}}
</template>

<script>
import { required } from "vuelidate/lib/validators"
export default {
  validations: {
    addressModal: {
      addressee: { required },
      streetAddressLine_1: { required },
      streetAddressLine_2: {},
      city: { required },
      region: { required },
      postalCode: { required },
      country: { id: { required } }
    }
  },
  props: {
    retailerId: { type: String, default: "" },
    address: {
      type: Object,
      default() {
        return {
          addressee: null,
          city: null,
          country: {
            id: null
          },
          defaultShipping: null,
          hq: null,
          postalCode: null,
          region: null,
          streetAddressLine_1: null,
          streetAddressLine_2: null
        }
      }
    }
  },
  data() {
    return {
      addressModal: { ...this.address, country: { ...this.address.country } },
      id: this.retailerId
    }
  },
  computed: {
    countries() {
      return this.$store.getters["reference/countries"]
    },
    isDisabled() {
      if (this.$v.addressModal.$anyDirty) {
        return false
      }
      return true
    }
  },
  methods: {
    async saveAddress() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      try {
        if (!this.addressModal.id) {
          this.$emit("createAddress", this.addressModal)
          this.emitModalClose()
        } else {
          this.$emit("updateAddress", this.addressModal)
          this.emitModalClose()
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancelChanges() {
      this.addressModal = this.address
      this.emitModalClose()
    },
    emitModalClose() {
      this.$emit("modalClose")
    }
  }
}
</script>

<style lang="sass">
.is-disabled
  pointer-events: none
.brand-new-product-base-form
  width: 100%
.brand-new-product-modal
  background-color: #ffffff
  width: 100%

  &__body
    overflow-x: hidden

  &__container
    min-height: 0px

  &__container-images
    min-height: 600px !important

  &__container-documents
    min-height: 500px !important

  &__form
    padding: 20px 40px 20px 40px

  &__form-buttons
    padding-right: 20px
    height: 75px
    border-top: 1px solid #E0E4E9
    display: flow-root

  &__header
    font-size: 18px !important
    font-weight: 500
    letter-spacing: 0.25px
    color: #212B36
    padding-left: 20px
    border-bottom: 1px solid #E0E4E9

  &__header-close-icon
    cursor: pointer

  &__menu
    padding-left: 40px
    padding-top: 30px
    background-color: #F4F4F4
    font-size: 18px
    width: 240px

    li
      margin-top: 20px

    a
      color: #000000 !important

  &__menu--sticky
    position: sticky
    top: 30px

  &__save-button, &__cancel-button
    height: 36px
    width: 69px
    font-size: 12px
    font-weight: bold
    margin-left: 10px

  &__error-message
    color: #BF0711
    font-size: 18px

  &__form-messages
    display: flex
    align-items: center
.brand-new-product
  &__label
    font-size: 14px
    font-weight: 500

  &__msrp-input
    width: 260px

  &__size-select
    width: 90px

  &__size-input
    width: 205px

  &__select, &__input
    width: 295px

    &--fullwidth
      width: 100%
</style>
