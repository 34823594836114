import dateUtils from "@/utils/date-utils";
export default class OrderStateTransition {
  constructor(model) {
    this.orderPrivateId = 0;
    this.sequence = 0;
    this.statusPrivateId = 0;
    this.transitionedAt = null;
    this.isComplete = 0;
    this.createdAt = new Date();
    this.createdBy = null;
    this.updatedAt = null;
    this.updatedBy = null;
    if (model) {
      this.orderPrivateId = model.orderPrivateId;
      this.sequence = model.sequence;
      this.statusPrivateId = model.statusPrivateId;
      this.transitionedAt = dateUtils.parseDateFromUTC(model.transitionedAt);
      this.isComplete = model.isComplete;
      this.createdAt = dateUtils.parseDateFromUTC(model.createdAt);
      this.createdBy = model.createdBy;
      this.updatedAt = dateUtils.parseDateFromUTC(model.updatedAt);
      this.updatedBy = model.updatedBy;
    }
  }
}
