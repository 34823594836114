<template>
  <div class="admin-retailer-dirty">
    <!-- <a-row>
      <a-col :span="14">
        <p class="admin-retailer-dirty__table-name">
          {{ $t("Admins.AdminRetailerDirtyIngredient.dirtyIngredient") }}
        </p>
        <p class="admin-retailer-dirty__table-sub-name">
          {{ $t("Admins.AdminRetailerDirtyIngredient.desc") }}
        </p>
      </a-col>
      <a-col :span="10">
        <button
          class="button admin-retailer-dirty__add-retailer"
          :class="{ selected: activeCategoryPanel == void 0 }"
          @click="addRetailer"
        >
          {{ $t("Admins.AdminRetailerDirtyIngredient.addCategory") }}
        </button>
      </a-col>
    </a-row> -->
    <div class="fx-r">
      <div class="fx-c title-panel">
        <p class="admin-retailer-dirty__table-name">
          {{ $t("Admins.AdminRetailerDirtyIngredient.dirtyIngredient") }}
        </p>
        <p class="admin-retailer-dirty__table-sub-name">
          {{ $t("Admins.AdminRetailerDirtyIngredient.desc") }}
        </p>
      </div>
      <div class="spacer"></div>
      <div class="upload-csv-link">
        <a class="upload" href="#" @click="openUploadIngredients">Upload CSV</a>
      </div>
      <button
        class="button admin-retailer-dirty__add-retailer selected"
        @click="openNewCategory"
      >
        {{ $t("Admins.AdminRetailerDirtyIngredient.addCategory") }}
      </button>
      <button
        class="button admin-retailer-dirty__add-retailer selected"
        @click="openNewIngredient"
      >
        {{ $t("Admins.AdminRetailerDirtyIngredient.addIngredient") }}
      </button>
      <a-modal
        title="Upload .CSV file"
        :visible="uploadIngredientsVisible"
        :footer="null"
        @ok="uploadIngredientsOk"
        @cancel="uploadIngredientsCancel"
        class="upload"
      >
        <a-upload-dragger
          name="file"
          :multiple="false"
          :file-list="fileList"
          :show-upload-list="false"
          :before-upload="beforeUpload"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="cloud-upload" style="color: #4A8a5d" />
          </p>
          <p class="ant-upload-text">
            {{ $t("Admins.AdminOrders.dragUpload") }}
          </p>
          <p class="ant-upload-text">
            {{ $t("Admins.AdminOrders.or") }}
          </p>
          <a-button>
            {{ $t("Admins.AdminOrders.chooseFile") }}
          </a-button>
        </a-upload-dragger>
        <div v-if="fileList.length > 0" class="upload-modal__body">
          <a-row v-for="(item, index) in fileList" :key="index">
            <a-col :span="1">
              <a-icon type="paper-clip" />
            </a-col>
            <a-col :span="19">
              {{ item.name }}
            </a-col>
            <a-col :span="4">
              <a-icon type="delete" @click="handleRemove(item)" />
            </a-col>
          </a-row>
        </div>
        <div class="fx-r center pad-t">
          <a-button
            :disabled="fileList.length === 0"
            :loading="ingredientUploading"
            @click="uploadIngredientsOk"
          >
            {{ $t("Admins.AdminOrders.upload") }}
          </a-button>
        </div>
      </a-modal>
      <a-modal
        title="Add new ingredient"
        :visible="newIngredientVisible"
        :confirm-loading="newIngredientConfirmLoading"
        @ok="newIngredientOk"
        @cancel="newIngredientCancel"
      >
        <a-form :form="ingredientForm" layout="vertical">
          <a-form-item label="Ingredient Name">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input Ingredient Name',
                    },
                  ],
                },
              ]"
              placeholder="Please input Ingredient name"
            />
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal
        title="Add new category"
        :visible="newCategoryVisible"
        :confirm-loading="newCategoryConfirmLoading"
        @ok="newCategoryOk"
        @cancel="newCategoryCancel"
      >
        <a-form :form="categoryForm" layout="vertical">
          <a-form-item label="Category name">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input Category name',
                    },
                  ],
                },
              ]"
              placeholder="Please input Category name"
            />
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal
        v-model="showReport"
        title="Upload Error"
        width="600px"
        :footer="null"
        @cancel="handleCancelReport"
      >
        <div class="upload-modal__body">
          <p class="ant-upload-text">
            <a-icon
              type="exclamation-circle"
              class="upload-modal__error-icon"
            />
            {{ $t("Admins.AdminOrders.errorTitle") }}
          </p>
          <p class="ant-upload-text">
            Error found during products upload. Please click download to view
            the import status.
          </p>
        </div>
        <div style="display: flex">
          <a-button
            class="upload-modal__btn"
            :loading="isDowloadingReport"
            @click="downloadReports"
            >{{ $t("Admins.AdminOrders.orderDownload") }}</a-button
          >
        </div>
      </a-modal>
    </div>
    <!-- <div class="fx-r">
      <p class="admin-retailer-dirty__table-sub-name">
        {{ $t("Admins.AdminRetailerDirtyIngredient.desc") }}
      </p>
    </div> -->
    <a-row id="dirtyCategoryIngredientPanel">
      <a-collapse
        expand-icon-position="right"
        :bordered="false"
        accordion
        @change="changeActiveKey"
      >
        <template #expandIcon="props">
          <a-icon type="down" :rotate="props.isActive ? 180 : 0" />
        </template>
        <a-collapse-panel
          v-for="(item, index) in adminRetailerDirtyIngredient.list"
          :key="index"
          class="admin-retailer-dirty__col-panel"
          :class="{ hightlight: activeCategoryPanel === index }"
        >
          <template #header>
            <div class="panel_header">
              <a-row
                v-if="
                  !editCategoryId || editCategoryId !== item.category.privateId
                "
              >
                <a-col :span="8">
                  <span>{{ item.category.name }}</span>
                </a-col>
                <a-col :span="8">
                  <button
                    class="button admin-retailer-dirty__btn_border-black"
                    @click.stop="clickEditCategory(item.category)"
                  >
                    {{ $t("Admins.AdminRetailerMargin.edit") }}
                  </button>
                  <button
                    class="button admin-retailer-dirty__btn_border-black"
                    @click.stop="clickRemoveCategory(item)"
                  >
                    {{ $t("Admins.AdminRetailerMargin.remove") }}
                  </button>
                </a-col>
              </a-row>
              <a-row v-else>
                <a-col :span="8">
                  <input
                    v-model="editCategoryName"
                    class="input"
                    type="text"
                    :class="{ 'is-danger': $v.editCategoryName.$error }"
                  />
                  <p
                    v-if="!$v.editCategoryName.required"
                    class="help is-danger"
                  >
                    Field is required
                  </p>
                </a-col>
                <a-col :span="8" class="admin-retailer-dirty__btn_layer">
                  <button
                    class="button admin-retailer-dirty__btn_black-white"
                    :class="{ 'disable-save': isSavingCategory }"
                    :disable="isSavingCategory"
                    @click.stop="saveCategory(item.category)"
                  >
                    {{ $t("Admins.AdminRetailerMargin.save") }}
                  </button>
                  <button
                    class="button admin-retailer-margin__btn_border-black"
                    @click.stop="cancelEditCategory"
                  >
                    {{ $t("Admins.AdminRetailerMargin.cancel") }}
                  </button>
                </a-col>
              </a-row>
            </div>
          </template>
          <div>
            <a-row
              v-for="(dirtyIngredient, i) in item.categoryIngredients"
              :key="`dirtyIngredient_${i}`"
              class="admin-retailer-dirty__panel-content-row"
            >
              <!-- <a-col :span="8">
                Dirty Ingredient:
              </a-col> -->
              <a-col :span="1"></a-col>
              <a-col :span="8">
                <span
                  v-if="
                    !editDirtyIngredient ||
                      dirtyIngredient.privateId !==
                        editDirtyIngredient.privateId
                  "
                  >{{ dirtyIngredient.ingredientId }}</span
                >
                <a-select
                  v-else
                  v-model="modifyDirtyIngredient"
                  size="large"
                  show-search
                  style="width: 100%"
                  placeholder="Please select ingredient"
                  :not-found-content="isLoadingIngredients ? undefined : null"
                  :filter-option="false"
                  @getPopupContainer="getPopupNode"
                  @search="searchIngredients"
                  @change="selectIngredients"
                >
                  <a-spin
                    v-if="isLoadingIngredients"
                    slot="notFoundContent"
                    size="small"
                  />
                  <div slot="dropdownRender" slot-scope="menu">
                    <v-nodes :vnodes="menu" />
                    <!--                    <a-divider style="margin: 4px 0;" />-->
                    <div
                      v-if="
                        searchIngredientPage.curPage <
                          searchIngredientPage.pages
                      "
                      class="admin-retailer-dirty__load-more"
                      @mousedown="(e) => e.preventDefault()"
                      @click="loadMoreIngredients"
                    >
                      <a-divider style="margin: 4px 0;" />
                      <span> Load More</span>
                      <a-icon type="redo" />
                    </div>
                  </div>
                  <a-select-option
                    v-for="item in ingredientsTag"
                    :key="item.privateId"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="8" class="admin-retailer-dirty__btn_layer">
                <button
                  v-if="
                    !editDirtyIngredient ||
                      dirtyIngredient.privateId !==
                        editDirtyIngredient.privateId
                  "
                  class="button admin-retailer-dirty__btn_border-black"
                  @click="clickEditDirtyIngredient(dirtyIngredient)"
                >
                  {{ $t("Admins.AdminRetailerMargin.edit") }}
                </button>
                <div v-else>
                  <button
                    class="button admin-retailer-dirty__btn_black-white"
                    :class="{ 'disable-save': isSavingDirtyItem }"
                    :disable="isSavingDirtyItem"
                    @click="saveEditDirtyIngredient"
                  >
                    {{ $t("Admins.AdminRetailerMargin.save") }}
                  </button>
                  <button
                    class="button admin-retailer-margin__btn_border-black"
                    @click="cancelEditDirtyIngredient"
                  >
                    {{ $t("Admins.AdminRetailerMargin.cancel") }}
                  </button>
                </div>
                <button
                  v-if="
                    !editDirtyIngredient ||
                      dirtyIngredient.privateId !==
                        editDirtyIngredient.privateId
                  "
                  class="button admin-retailer-dirty__btn_border-black"
                  @click="clickRemoveDirtyIngredient(dirtyIngredient)"
                >
                  {{ $t("Admins.AdminRetailerMargin.remove") }}
                </button>
              </a-col>
            </a-row>
            <a-row
              v-if="
                isAddNewDirtyIngredient &&
                  newCategoryId === item.category.privateId
              "
              class="admin-retailer-dirty__panel-content-row"
            >
              <a-col :span="8">
                Add New Ingredient:
              </a-col>
              <a-col :span="8">
                <a-select
                  size="large"
                  show-search
                  style="width: 100%"
                  placeholder="Please select ingredient"
                  :filter-option="false"
                  :not-found-content="isLoadingIngredients ? undefined : null"
                  @getPopupContainer="getPopupNode"
                  @search="searchIngredients"
                  @change="selectNewIngredient"
                >
                  <a-spin
                    v-if="isLoadingIngredients"
                    slot="notFoundContent"
                    size="small"
                  />
                  <div slot="dropdownRender" slot-scope="menu">
                    <v-nodes :vnodes="menu" />
                    <div
                      v-if="
                        searchIngredientPage.curPage <
                          searchIngredientPage.pages
                      "
                      class="admin-retailer-dirty__load-more"
                      @mousedown="(e) => e.preventDefault()"
                      @click="loadMoreIngredients"
                    >
                      <a-divider style="margin: 4px 0;" />
                      <span> Load More </span>
                      <a-icon type="redo" />
                    </div>
                  </div>
                  <a-select-option
                    v-for="item in ingredientsTag"
                    :key="item.privateId"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="8">
                <div class="admin-retailer-dirty__btn_layer">
                  <button
                    class="button admin-retailer-dirty__btn_black-white"
                    :class="{ 'disable-save': isSavingDirtyItem }"
                    :disable="isSavingDirtyItem"
                    @click="saveNewDirtyIngredient"
                  >
                    {{ $t("Admins.AdminRetailerMargin.save") }}
                  </button>
                  <button
                    class="button admin-retailer-margin__btn_border-black"
                    @click="cancelNewDirtyIngredient"
                  >
                    {{ $t("Admins.AdminRetailerMargin.cancel") }}
                  </button>
                </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="20"></a-col>
              <a-col :span="4" style="text-align: right;">
                <a-tooltip placement="topRight">
                  <template slot="title">
                    <span>
                      Add Ingredients
                    </span>
                  </template>
                  <button
                    type="button"
                    class="admin-retailer-dirty__add-float-btn"
                    @click="addNewDirtyIngredient(item)"
                  >
                    <span class="admin-retailer-dirty__add-float-content">
                      <a-icon
                        type="plus"
                        style="width: 20px;height: 20px;font-size: 18px;margin-left: 2px;"
                      />
                    </span>
                  </button>
                </a-tooltip>
              </a-col>
            </a-row>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </a-row>
    <a-row v-if="isAddNew" id="newRetailer">
      <a-col
        :span="12"
        class="admin-retailer-dirty__no-padding-bottom admin-retailer-dirty__no-padding-top"
      >
        <input
          v-model="$v.newCategoryName.$model"
          class="input"
          type="text"
          :class="{ 'is-danger': $v.newCategoryName.$error }"
        />
        <p v-if="!$v.newCategoryName.required" class="help is-danger">
          Field is required
        </p>
      </a-col>
      <a-col :span="12" class="admin-retailer-dirty__btn_layer">
        <button
          class="button admin-retailer-dirty__btn_black-white admin-retailer-dirty__margin-top"
          :class="{ 'disable-save': isSavingNew }"
          :disable="isSavingNew"
          @click="saveNewRetailer"
        >
          {{ $t("Admins.AdminRetailerMargin.save") }}
        </button>
        <button
          class="button admin-retailer-dirty__btn_border-black admin-retailer-dirty__margin-top"
          @click="cancelAddRetailer"
        >
          {{ $t("Admins.AdminRetailerMargin.cancel") }}
        </button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from "moment";
import { exportOrderExcel } from "@/utils/validate";
import { required } from "vuelidate/lib/validators";
import ingredientsService from "@/services/ingredients-service";
import uploadsService from "@/services/upload-service";
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },

  validations: {
    newCategoryName: { required },
    newDirtyIngredient: { required },
    modifyDirtyIngredient: { required },
    editCategoryName: { required },
  },

  data() {
    return {
      newIngredientVisible: false,
      newIngredientConfirmLoading: false,
      uploadIngredientsVisible: false,
      newCategoryVisible: false,
      newCategoryConfirmLoading: false,
      showReport: false,
      isDowloadingReport: false,
      ingredientUploading: false,
      ingredientServerError: null,
      errorReports: undefined,
      fileList: [],
      isAddNew: false,
      isSavingNew: false,
      isSavingCategory: false,
      isSavingDirtyItem: false,
      newCategoryName: "",
      editCategoryId: undefined,
      editCategoryName: "",
      editDirtyIngredient: undefined,
      modifyDirtyIngredient: undefined,
      searchIngredientValue: "",
      isLoadingIngredients: false,
      newDirtyIngredient: undefined,
      newCategoryId: undefined,
      isAddNewDirtyIngredient: false,
      ingredientsMaxTextlen: 50,
      totalCount: 1,
      totalPage: 1,
      activeProducts: [],
      ingredientsTag: [],
      activeCategoryPanel: undefined,
      ingredientForm: this.$form.createForm(this, { name: "coordinated" }),
      categoryForm: this.$form.createForm(this, { name: "coordinated" }),
    };
  },
  computed: {
    adminRetailerDirtyIngredient() {
      return this.$store.getters["cleanPreCheck/allCategoryIngredients"];
    },
    searchIngredientPage() {
      let data = this.$store.getters["product/ingredientsTag"];
      return { total: data.total, pages: data.pages, curPage: data.index };
    },
  },
  async created() {
    this.getCategoryDirtyIngredient(1);
    this.searchIngredients("");
  },
  updated() {
    this.scrollToNew();
  },
  methods: {
    async getCategoryDirtyIngredient(page) {
      await this.$store.dispatch(
        "cleanPreCheck/fetchAllCategoryIngredientsByName",
        {
          "page-index": page,
        }
      );
    },
    changeActiveKey(key) {
      this.searchIngredientValue = "";
      this.modifyDirtyIngredient = undefined;
      this.searchIngredients("");
      this.activeCategoryPanel = key;
    },
    getPopupNode(triggerNode) {
      return triggerNode.parent;
    },
    searchIngredients(value) {
      this.searchIngredientValue = value;
      this.isLoadingIngredients = true;
      this.ingredientsTag = [];
      this.$store
        .dispatch("product/getIngredientsTag", {
          ingredientName: value,
          page: 1,
        })
        .then(() => {
          this.ingredientsTag = this.$store.getters[
            "product/ingredientsTag"
          ].list;
          this.$forceUpdate();
        })
        .finally(() => {
          this.isLoadingIngredients = false;
        });
    },
    selectIngredients(value) {
      this.modifyDirtyIngredient = value;
    },
    selectNewIngredient(value) {
      this.newDirtyIngredient = value;
    },
    addRetailer() {
      this.isAddNew = true;
    },
    addIngredient() {
      if (this.activeCategoryPanel !== void 0) {
        const item = this.adminRetailerDirtyIngredient.list[
          this.activeCategoryPanel
        ];
        this.addNewDirtyIngredient(item);
      }
    },
    scrollToNew() {
      if (this.isAddNew) {
        let eb = document.getElementById("newRetailer");
        if (eb) {
          eb.scrollIntoView();
        }
      }
    },
    cancelAddRetailer() {
      this.isAddNew = false;
      this.isSavingNew = false;
      this.newCategoryName = "";
    },
    async saveNewRetailer() {
      this.$v.$touch();
      if (this.$v.newCategoryName.$anyError) {
        return;
      }
      let param = {
        name: this.newCategoryName,
      };
      try {
        await this.$store.dispatch(
          "cleanPreCheck/updateDirtyIngreidentCategory",
          param
        );
        this.cancelAddRetailer();
        this.getCategoryDirtyIngredient(1);
      } catch (error) {
        console.log(`error${error}`);
      }
    },
    clickEditCategory(item) {
      this.editCategoryId = item.privateId;
      this.editCategoryName = item.name;
    },
    clickRemoveCategory(item) {
      if (!item.categoryIngredients || item.categoryIngredients.length === 0) {
        let _this = this;
        this.$confirm({
          title: "Do you want to delete this category?",
          onOk() {
            _this.doDeleteCategory(item);
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      } else {
        this.$message.warning(
          `There still have ingredients under the category ${item.category.name}`
        );
        return;
      }
    },
    doDeleteCategory(item) {
      this.$store
        .dispatch("cleanPreCheck/deleteCategory", item.category.privateId)
        .then(() => {
          this.$message.success("Delete category Success");
          this.getCategoryDirtyIngredient(1);
        })
        .catch((error) => {
          console.log(error);
          this.$message.error(error.message);
        });
    },
    saveCategory(item) {
      if (item.privateId === this.editCategoryId) {
        this.isSavingCategory = true;
        let param = {
          privateId: item.privateId,
          name: this.editCategoryName,
        };
        this.$store
          .dispatch("cleanPreCheck/updateDirtyIngreidentCategory", param)
          .then(() => {
            this.$message.info("Update category name success");
            this.cancelEditCategory();
            this.getCategoryDirtyIngredient(1);
          })
          .catch(() => {
            this.$message.error("Update category name failed");
          })
          .finally(() => {
            this.isSavingCategory = false;
          });
      }
    },
    cancelEditCategory() {
      this.editCategoryName = "";
      this.editCategoryId = undefined;
      this.isSavingCategory = false;
    },
    clickEditDirtyIngredient(item) {
      this.editDirtyIngredient = item;
      let data = this.ingredientsTag.filter(
        (ingre) => ingre.privateId === item.ingredientPrivateId
      );
      if (!data || data.length === 0) {
        this.ingredientsTag.push({
          privateId: item.ingredientPrivateId,
          name: item.ingredientId,
        });
      }

      this.modifyDirtyIngredient = item.ingredientPrivateId;
    },
    clickRemoveDirtyIngredient(item) {
      let _this = this;
      this.$confirm({
        title: "Do you want to delete this ingredient?",
        onOk() {
          _this.doDeleteIngredientFromCategory(item);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    doDeleteIngredientFromCategory(item) {
      this.$store
        .dispatch("cleanPreCheck/deleteDirtyIngredient", item.privateId)
        .then(() => {
          let data = this.$store.getters[
            "cleanPreCheck/updatedDirtyIngredient"
          ];
          if (!data) {
            this.$message.success("Delete ingredient Success");
            this.getCategoryDirtyIngredient(1);
          } else {
            this.$message.info(data.toString());
            this.getCategoryDirtyIngredient(1);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error(error.message);
        });
    },
    saveEditDirtyIngredient() {
      if (this.editDirtyIngredient && this.modifyDirtyIngredient) {
        this.isSavingDirtyItem = true;
        let param = {
          privateId: this.editDirtyIngredient.privateId,
          dirtyCategoryPrivateId: this.editDirtyIngredient
            .dirtyCategoryPrivateId,
          ingredientPrivateId: this.modifyDirtyIngredient,
        };
        this.$store
          .dispatch("cleanPreCheck/updateDirtyIngreident", param)
          .then(() => {
            this.$message.info("Update dirty ingredient success");
            this.cancelEditDirtyIngredient();
            this.getCategoryDirtyIngredient(1);
          })
          .catch(() => {
            this.$message.error("Update dirty ingredient failed");
          })
          .finally(() => {
            this.isSavingDirtyItem = false;
          });
      }
    },
    async addMasterIngredient(name) {
      var ingredient = await ingredientsService.addIngredient(name);
      return ingredient;
    },

    checkFileExtension() {
      if (this.fileList && this.fileList.length > 0) {
        let isFormatRight = true;
        for (let i = 0; i < this.fileList.length; i++) {
          let name = this.fileList[i].name;
          isFormatRight = this.isCsv(name);
          if (!isFormatRight) {
            let info = this.$t("Admins.AdminOrders.uploadFailInfo");
            this.$message.error(info);
            return;
          }
        }
        return true;
      }
    },
    async uploadMasterIngredients() {
      if (!this.checkFileExtension()) {
        return;
      }

      let fileReader = new FileReader();
      var formData = new FormData();
      const file = this.fileList[0];
      fileReader.readAsText(file);
      fileReader.onloadend = (e) => {
        let data = new Blob([e.target.result], { type: "text/csv" });
        formData.append("file", data, file.name);
        this.ingredientUploading = true;
        uploadsService
          .uploadIngredients(formData)
          .then((error) => {
            if (error) {
              this.uploadIngredientsCancel();
              this.showReport = true;
              this.errorReports = error;
            } else {
              this.uploadIngredientsVisible = false;
            }
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.ingredientUploading = false;
          });
      };
      // let fileReader = new FileReader();
      // fileReader.readAsText(file)
      // fileReader.onloadend = e => {
      //  let data = new Blob([e.target.result], { type: "text/csv" })
      // formData.append("file", data, file.name)
    },
    handleCancelReport() {
      this.showReport = false;
      this.errorReports = "";
    },
    downloadReports() {
      this.downloadingReport = true;
      let data = new Blob([this.errorReports]);
      let fileName = `upload_ingredients_error_reports_${moment().format(
        "YYYY-MM-DD HH23:mm:ss"
      )}.csv`;
      exportOrderExcel(data, fileName).finally(() => {
        this.isLoading = false;
        this.handleCancelReport();
      });
    },
    isCsv(name) {
      if (name) {
        let index = name.lastIndexOf(".");
        let ext = name.substr(index + 1);
        if (ext.toLowerCase() === "csv") {
          return true;
        }
      }
      return false;
    },
    cancelEditDirtyIngredient() {
      this.editDirtyIngredient = undefined;
      this.modifyDirtyIngredient = undefined;
      this.isSavingDirtyItem = false;
    },
    saveNewDirtyIngredient() {
      if (this.newDirtyIngredient) {
        let param = {
          dirtyCategoryPrivateId: this.newCategoryId,
          ingredientPrivateId: this.newDirtyIngredient,
        };
        this.isSavingDirtyItem = true;
        this.$store
          .dispatch("cleanPreCheck/updateDirtyIngreident", param)
          .then(() => {
            this.$message.info("Update dirty ingredient success");
            this.cancelNewDirtyIngredient();
            this.getCategoryDirtyIngredient(1);
          })
          .catch(() => {
            this.$message.error("Update dirty ingredient failed");
          })
          .finally(() => {
            this.isSavingDirtyItem = false;
          });
      }
    },
    cancelNewDirtyIngredient() {
      this.newDirtyIngredient = {};
      this.isAddNewDirtyIngredient = false;
      this.newCategoryId = undefined;
      this.isSavingDirtyItem = false;
    },
    addNewDirtyIngredient(item) {
      this.isAddNewDirtyIngredient = true;
      this.newCategoryId = item.category.privateId;
      this.newDirtyIngredient = {};
    },
    loadMoreIngredients() {
      this.isLoadingIngredients = true;
      this.$store
        .dispatch("product/getIngredientsTag", {
          ingredientName: this.searchIngredientValue,
          page: this.searchIngredientPage.curPage + 1,
        })
        .then(() => {
          this.ingredientsTag = this.$store.getters[
            "product/ingredientsTag"
          ].list;
        })
        .finally(() => {
          this.isLoadingIngredients = false;
        });
    },
    loadMoreCategory() {
      this.getCategoryDirtyIngredient(
        this.adminRetailerDirtyIngredient.index + 1
      );
    },
    newIngredientOk(e) {
      e.preventDefault();
      this.ingredientForm.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          // this.ingredientServerError = "error";
          // this.$nextTick(() => {
          //   this.ingredientForm.validateFields(["name"]);
          // });
          // this.$nextTick(() => {
          //   this.ingredientForm.setFields({
          //     name: {
          //       value: this.ingredientForm.getFieldValue("name"),
          //       errors: ["required"],
          //     },
          //   });
          // });
          const value = `${this.ingredientForm.getFieldValue("name")}`.trim();
          this.addMasterIngredient(value)
            .then(() => {
              this.newIngredientVisible = false;
              this.ingredientForm.resetFields();
            })
            .catch((err) => {
              if (err.status === 409) {
                this.$message.error(`Found existing ingredient name: ${value}`);
              } else {
                this.$message.error(err.message);
              }
            });
        }
      });
    },
    newIngredientCancel() {
      this.newIngredientVisible = false;
    },
    openNewIngredient() {
      this.newIngredientVisible = true;
    },
    uploadIngredientsOk() {
      this.uploadMasterIngredients();
    },
    uploadIngredientsCancel() {
      this.uploadIngredientsVisible = false;
    },
    newCategoryOk(e) {
      e.preventDefault();
      this.categoryForm.validateFields((err) => {
        if (!err) {
          const value = `${this.categoryForm.getFieldValue("name")}`.trim();
          let param = {
            name: value,
          };
          this.newCategoryConfirmLoading = true;
          this.$store
            .dispatch("cleanPreCheck/updateDirtyIngreidentCategory", param)
            .then(() => {
              this.newCategoryCancel();
              this.getCategoryDirtyIngredient(1);
              this.categoryForm.resetFields();
            })
            .catch((error) => {
              if (error.message.indexOf("Duplicate") !== -1) {
                this.$message.error(`Found duplicate Category: ${value}`);
              } else {
                this.$message.error(error.message);
              }
            })
            .finally(() => (this.newCategoryConfirmLoading = false));
        } else {
          this.$message.error(err);
        }
      });
    },
    newCategoryCancel() {
      this.newCategoryVisible = false;
    },
    openNewCategory() {
      this.newCategoryVisible = true;
    },
    openUploadIngredients() {
      this.uploadIngredientsVisible = true;
    },
    ingredientValidator(rule, value, callback) {
      if (this.ingredientServerError) {
        return [new Error("Found duplicate Ingredient Name")];
      }
      console.log(rule);
      console.log(value);
      console.log(callback);
      return [];
    },
    beforeUpload(file) {
      this.fileList = [];
      this.fileList.push(file);
      return false;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 50px !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 0px 10px !important;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.button {
  &.selected {
    background: #4a8a5d;
    border: 1px solid #4a8a5d;
  }
}
.admin-retailer-dirty {
  padding: 16px 10%;
  margin-bottom: 100px;
  &__margin-top {
    margin: 4px 0px !important;
  }
  &__table-name {
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    padding-left: 15px;
    margin-bottom: 0;
  }
  &__table-sub-name {
    color: #707070 !important;
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
    padding-left: 15px;
  }
  &__add-retailer {
    // width: 130px;
    // height: 30px;
    // float: right;
    text-align: center;
    background: #716b68;
    font-size: 14px;
    // font-weight: bold;
    color: #eeeeee;
    padding: 10px 14px;
  }
  &__expansion-head {
    font-weight: bold;
    font-size: 12px;
  }
  &__expansion-head > div {
    margin-left: auto;
  }
  &__text-percent {
    text-align: center;
    font-size: 14px !important;
    width: 100%;
  }
  &__text-center {
    text-align: left;
    font-size: 14px !important;
  }
  &__text-right {
    text-align: right;
    font-size: 14px !important;
    margin-bottom: 0px !important;
    width: 100%;
    padding-right: 4px;
  }
  &__col-cell {
    display: flex;
    text-align: left;
    color: #333333 !important;
    padding: 4px !important;
    align-items: center;
  }
  &__col-col-cell {
    display: flex;
    align-items: center;
  }
  &__compobox-cell {
    font-size: 14px !important;
  }
  &__filter-column-name {
    color: #292929;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
  }
  &__border {
    border-bottom: 1px solid #e0e0e0;
  }
  &__no-margin-bottom {
    margin: 0px !important;
  }

  &__compbox-left-block {
    float: left;
    display: block;
    padding: 0.4em 1em;
    vertical-align: middle;
    margin: 10px !important;
  }

  &__btn-right {
    float: right;
    display: flex;
    justify-content: end;
    justify-content: flex-end;
    font-size: 12px;
    padding: 10px;
    margin: 10px;
  }
  &__no-padding-bottom {
    padding-bottom: 0px !important;
  }
  &__no-padding-top {
    padding-top: 0px !important;
  }
  &__no-padding-top-bottom {
    padding: 0px 0px !important;
  }
  &__margin-right {
    margin-right: 10px;
  }
  &__btn_border-grad {
    margin-right: 10px;
    width: 70px;
    height: 30px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #595959;
  }
  &__btn_layer {
    text-align: right;
    padding-right: 30px;
  }
  &__btn_border-black {
    margin-right: 10px !important;
    width: 70px;
    height: 30px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    align-items: center;
  }
  &__btn_black-white {
    color: #ffffff;
    margin-right: 10px !important;
    width: 70px;
    height: 30px;
    background: #000000;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
  }
  &__btn_black-white:hover {
    color: #ffffff !important;
  }
  &__btn_black-white:focus {
    background: #000000;
    color: #ffffff;
  }
  &__padding {
    padding: 0px 0px !important;
  }
  &__margin-left {
    margin-left: 16px !important;
  }
  &__margin-left-eight {
    margin-left: 8px !important;
  }
  &__ctn-width {
    width: 40%;
    text-align: right;
  }
  &__new-retailer-select {
    width: 60% !important;
  }
  &__panel-top-line {
    border-top: 1px solid #d9d9d9;
  }
  &__panel-content-row:not(:first-child) {
    border-top: 1px solid #d9d9d9;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  &__padding-left {
    padding-left: 42px !important;
  }
  &__add-float-btn {
    font-size: 0.75rem;
    color: #ffffff;
    height: 25px;
    width: 25px;
    bottom: 0px;
    right: 16px;
    background: #272727;
    border-radius: 50%;
    z-index: 4;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
      0 1px 18px 0 rgb(0 0 0 / 12%);
  }
  &__add-float-content {
    align-items: center;
    position: relative;
    justify-content: inherit;
    display: flex;
    color: inherit;
  }
  &__load-more {
    padding: 4px 8px;
    cursor: pointer;
    text-align: center;
  }
}
#dirtyCategoryIngredientPanel .ant-collapse-borderless {
  background: #ffffff;
}
#dirtyCategoryIngredientPanel .ant-collapse-borderless .ant-collapse-item {
  border-top: 1px solid #d9d9d9 !important;
  border-bottom: none !important;
}
#dirtyCategoryIngredientPanel
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 4px 16px !important;
  display: block;
}
.admin-retailer-dirty__col-panel:before {
  z-index: -1 !important;
}
.upload {
  .ant-btn:not([disabled]) {
    background-color: #4a8a5d;
    color: white !important;
  }
}
</style>
<style lang="scss">
#dirtyCategoryIngredientPanel
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  display: block;
  padding-left: 30px;
}

.button {
  padding: 5px;
  text-transform: none !important;
}
.fx-r {
  display: flex;
  flex-direction: row;

  .button {
    margin-left: 10px;
  }

  > div {
    display: inline-flex;
  }

  &.center {
    justify-content: center;
  }

  &.pad-t {
    padding-top: 10px;
  }
}
.spacer {
  flex: 1 1 auto;
}
.fx-c {
  display: flex;
  flex-direction: column;
  width: 100%;

  .fx-r:not(:first-child) {
    &.label {
      padding-top: 12px;
    }
  }

  .label {
    font-size: 13px;
  }
}
.full {
  .ant-select-selection {
    width: 100%;
  }
}
a {
  &.upload {
    display: flex;
    align-items: center;
    font-family: "Niveau Grotesk";
    font-size: 14px;
    text-decoration: underline;
    color: #4a8a5d;
    margin-right: 16px;
    font-weight: bold;
  }
}
.hightlight {
  .ant-collapse-header {
    background: #e2eeec;
    border-bottom: 1px solid #d9d9d9;
  }
}
.ant-modal-content .ant-btn-primary,
.ant-modal-content .upload-modal__btn {
  background-color: #4a8a5d !important;
  color: white !important;
}

.title-panel {
  background-color: #ffff66;
  margin-right: 10%;
  padding: 20px;
}

.upload-csv-link {
  align-items: center;
  white-space: nowrap;
  height: 46px;
}
</style>
