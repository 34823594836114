<template>
  <div class="website-subscription__main">
    <div class="subscription">
      <div class="subscription__wrapper">
        <div class="subscription__title">
          {{ subscription.title1[0] }}
        </div>
        <div class="subscription__subtitle">
          <section
            v-for="(value, index) in subscription.subtitle1"
            :key="index"
            :class="[index === 1 || index === 2 ? 'subscription__subtitle__highlight' : '']"
          >
            {{ value }}
          </section>
        </div>
        <div
          class="subscription__description-flag"
          :data-before="subscription.description.category[0]"
          :data-after="subscription.description.category[1]"
        ></div>
        <div class="subscription__description">
          <section class="subscription__description__basic">
            <div v-for="(value, index) in subscription.description.basic" :key="index">
              <div class="subscription__description__title">{{ value.title }}</div>
              <div class="subscription__description__content">{{ value.content }}</div>
            </div>
          </section>
          <section class="subscription__description__premuim">
            <div v-for="(value, index) in subscription.description.premuim" :key="index">
              <div class="subscription__description__title">{{ value.title }}</div>
              <div class="subscription__description__content">{{ value.content }}</div>
            </div>
            <!--          <div class="feature-effective__date">{{ subscription.description.effectiveDate }}</div>-->
          </section>
        </div>
        <table class="subscription__comparison">
          <thead>
            <th v-for="(v, k) in subscription.comparison.thead" :key="k">{{ v }}</th>
          </thead>
          <tbody>
            <tr v-for="(v, k) in subscription.comparison.tbody" :key="k">
              <td v-for="(item, index) in v" :key="index" class="table-td-text">{{ item }}</td>
            </tr>
          </tbody>
        </table>
        <div class="subscription__copyright">
          <a class="button-link-apply" href="/brand-register">Apply Today</a>
          <!--          {{ subscription.copyright[0] }}<br />{{ subscription.copyright[1] }}-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebsitSubscriptionVue",
  computed: {
    subscription() {
      return this.$t("Subscription")
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../assets/styles/shared/_fonts"
.subscription
  margin: 0 auto
  padding-bottom: 74px
  width: 100%
  &__wrapper
  margin: 0 auto
    width: 100%
  //max-width: 1440px
  &__cover
    position: relative
    width: 100%
    height: 885px
    img
      width: 100%
      height: 100%
  //.subscription-logo
  //  position: absolute
  //  left: 66px
  //  top: 59px
  //  width: 173px
  //  height: 54px
  //  img
  //    width: 100%
  //    height: 100%
  &__title
    margin: 181px auto 0 auto
    width: 100%
    text-align: center
    font-family: 'Playfair Display'
    color: #000000
    font-weight: 600
    font-size: 35px
    line-height: 51px
  &__subtitle
    margin: 30px auto 0 auto
    text-align: center
    font-family: 'Proxima Nova'
    font-size: 20px
    line-height: 20px
    font-weight: 400
    color: #000000
    &__highlight
      color: #4a8a5d
  &__description-flag
    position: relative
    margin: 114px auto 0 auto
    height: 0
    width: 1028px
    &::before
      position: absolute
      top: -24px
      left: -38px
      width: 129px
      height: 48px
      line-height: 51px
      content: attr(data-before)
      font-size: 33px
      font-family: 'Playfair Display'
      font-weight: bold
      color: #659A71
    &::after
      position: absolute
      right: -78px
      top: -24px
      width: 209px
      height: 48px
      content: attr(data-after)
      text-align: right
      font-family: 'Playfair Display'
      font-weight: bold
      font-size: 33px
      line-height: 51px
      color: #FF6132
  &__description
    position: relative
    display: flex
    margin: 0 auto
    width: 1028px
    height: 1110px
    border-radius: 250px
    border: 5px solid #FF6132
    overflow: hidden
    &>section:first-child
      width: 533px
    &>section:last-child
      width: 485px
    &>section
      margin-top: -5px
      margin-bottom: -5px
      height: 1110px
      &>div
        margin-bottom: 50px
      &>div:first-child
        margin-top: 92px
      &>div:nth-child(2)
        margin-bottom: 33px
      .feature-effective__date
        margin: -30px 0 0 124px
        font-family: 'Proxima Nova'
        font-style: italic
        font-size: 14px
        line-height: 14px
        font-weight: 400
    &__basic
      border-right: 5px solid #4A8a5d
      border-top: 5px solid #4A8a5d
      border-bottom: 5px solid #4A8a5d
      border-radius: 250px
      transform: translateX(-3px)
    &__title
      position: relative
      margin-left: 124px
      height: 23px
      line-height: 23px
      font-family: 'Playfair Display'
      font-weight: bold
      font-size: 17px
      color: #12433D
      &::before
        position: absolute
        content: '.'
        top: -7px
        left: -20px
        font-size: 26px
        font-weight: bold
    &__content
      margin: 16px 0 0 124px
      width: 246px
      min-height: 51px
      font-family: 'Proxima Nova'
      font-size: 14px
      line-height: 17px
      color: #000000
  &__comparison
    margin: 76px auto 0 auto
    width: 720px
    font-family: 'Proxima Nova'
    thead
      line-height: 21px
      font-size: 17px
      font-weight: bold
      border-bottom: 1px solid rgba(51, 62, 76, .2)
      th
        color: #888888
      &>th:first-child
        width: 290px
        text-indent: 26px
      &>th:nth-child(2)
        width: 210px
        text-align: center
      &>th:nth-child(3)
        width: 220px
        text-align: center
    tbody
      tr
        height: 65px
        font-size: 17px
        line-height: 25px
        font-weight: bold
        color: #12433D
        border-bottom: 1px solid rgba(51, 62, 76, .2)
        td
          vertical-align: bottom
        &>td:first-child
          font-weight: 500
          text-indent: 34px
        &>td:not(:first-child)
          text-align: center
  &__copyright
    margin: 140px auto 0 auto
    font-family: 'Proxima'
    font-weight: 400
    font-size: 24px
    line-height: 37px
    text-align: center
    color: #000
    .button-link-apply
      width: 152px
      height: 55px
      line-height: 55px
      font-size: 16px
      border: 2px solid #FF6132
      border-radius: 27px
      margin: 0 auto
      padding: 18px 33px
      color: #000000
      cursor: pointer
      font-family: "Proxima Nova", sans-serif
    .button-link-apply:hover
      color: white
      background-color: #FF6132
</style>
