<template>
  <div class="terms-changes-message">
    <div class="terms-changes-message__icon-block">
      <div class="succss-icon-block">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
        </svg>
      </div>
    </div>
    <div class="terms-changes-message__right-block" v-if="role == 'retailer'">
      <div class="terms-changes-message__card-header">
        {{$t('Components.Shared.termsChangeRequest.requestTermsChange')}}
      </div>
      <div class="terms-changes-message__message">
        {{$t('Components.Shared.termsChangeRequest.requestUnderReview')}}
      </div>
      <div class="terms-changes-message__actions">
        <a class="terms-changes-message__link" @click="undoChanges">
          {{$t('Components.Shared.termsChangeRequest.undo')}}
        </a>
        <a class="terms-changes-message__link" @click="viewChanges">
          {{$t('Components.Shared.termsChangeRequest.viewPendingChanges')}}
        </a>
      </div>
    </div>
    <div class="terms-changes-message__right-block" v-if="role == 'admin'">
      <div class="terms-changes-message__card-header">
        {{$t('Components.Shared.termsChangeRequest.reviewTermsChange')}}
      </div>
      <div class="terms-changes-message__message">
        {{$t('Components.Shared.termsChangeRequest.approveOrDeclineMessage')}}
      </div>
      <div class="terms-changes-message__actions">
        <a class="terms-changes-message__link" @click="viewChanges">
          {{$t('Components.Shared.termsChangeRequest.reviewChangeRequest')}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    role() {
      return this.$store.getters["user/role"]
    }
  },
  methods: {
    viewChanges() {
      this.$emit("viewPendingChanges")
    },
    undoChanges() {
      this.$emit("undoChanges")
    }
  }
}
</script>

<style lang="sass">
.terms-changes-message
  background-color: white
  width: 540px
  border: 1px solid #EBEBED
  font-size: 14px
  padding: 17px 41px
  display: flex
  box-shadow: 0 -10px 10px -5px rgba(63,63,68,0.05), 0 1px 2px -1px rgba(63,63,68,0.15)

  .terms-changes-message__message
    max-height: 200px
    overflow: auto
  .terms-changes-message__icon-block
    margin-right: 16px
    .succss-icon-block
      width: 40px
      height: 40px
      background-color: #D1E2DD
      border-radius: 100%
      display: flex
      align-items: center
      justify-content: center
      svg
        width: 22px
        fill: #fff

  .terms-changes-message__right-block
    .terms-changes-message__card-header
      margin-bottom: 3px
    .terms-changes-message__actions
      margin-top: 6px
      margin-bottom: 10px
      .terms-changes-message__link
        text-decoration: underline

  &__card-header
    font-weight: bold

  &__message
    font-weight: 300
    color: #212B36

  &__link
    font-weight: 300
    color: #212B36
    margin-right: 16px

.admin-page
  height: 125px
</style>
