<template>
  <div ref="adminTrainingLoginMessage" class="admin-training-login-message__main">
    <a-row>
      <a-col :span="16">
        <search-component
          :search-recent="searchRecent"
          search-hint="Search by Category or Recipient"
          @doSearch="onSearch"
          @deleteTag="closeTagItem"
        />
      </a-col>
      <a-col :span="8" style="text-align: right;">
        <a-button
          class="admin-training-landing-red-border-btn"
          style="margin-right: 6px;"
          @click="showDeleteConfirm"
          >Delete</a-button
        >
        <a-button class="admin-training-landing-red-btn" @click="showCreateNew"
          >Create New</a-button
        >
      </a-col>
    </a-row>

    <a-table
      :columns="tableColumn"
      :data-source="tableData"
      size="middle"
      class="admin-training-table"
      :row-class-name="
        (_recode, index) => (index % 2 === 1 ? 'admin-training-table-striped' : null)
      "
      :pagination="pagination"
      style="margin-top: 40px;"
      :loading="isLoading"
      :get-popup-container="() => $refs.adminTrainingLoginMessage"
    >
      <span slot="customCheckTitle" style="padding-bottom: 6px;">
        <a-checkbox v-model="isCheckAll" @change="handleSelectAllReport" />
      </span>
      <span slot="checkAction" slot-scope="text, record">
        <a-checkbox :checked="text" @change="checkedValue => chooseData(checkedValue, record)" />
      </span>
      <span slot="createTitle">
        Created
        <a-icon
          type="caret-up"
          :rotate="sortCreate.toString().length > 0 && sortCreate === 'descend' ? 180 : 0"
          :class="
            sortCreate.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('created')"
        />
      </span>
      <span slot="paidTitle">
        Paid
        <a-icon
          type="caret-up"
          :rotate="sortPaid.toString().length > 0 && sortPaid === 'descend' ? 180 : 0"
          :class="
            sortPaid.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('is_paid')"
        />
      </span>
      <span slot="categoryTitle">
        Category
        <a-icon
          type="caret-up"
          :rotate="sortCategory.toString().length > 0 && sortCategory === 'descend' ? 180 : 0"
          :class="
            sortCategory.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('category')"
        />
      </span>
      <span slot="recipientTitle">
        Recipient
        <a-icon
          type="caret-up"
          :rotate="sortRecipient.toString().length > 0 && sortRecipient === 'descend' ? 180 : 0"
          :class="
            sortRecipient.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('retailer-name')"
        />
      </span>
      <span slot="startDateTitle">
        Start Date
        <a-icon
          type="caret-up"
          :rotate="sortStartDate.toString().length > 0 && sortStartDate === 'descend' ? 180 : 0"
          :class="
            sortStartDate.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('start_date')"
        />
      </span>
      <span slot="endDateTitle">
        End Date
        <a-icon
          type="caret-up"
          :rotate="sortEndDate.toString().length > 0 && sortEndDate === 'descend' ? 180 : 0"
          :class="
            sortEndDate.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('end_date')"
        />
      </span>
      <span slot="headerTitle">
        Header Title
        <a-icon
          type="caret-up"
          :rotate="sortHeader.toString().length > 0 && sortHeader === 'descend' ? 180 : 0"
          :class="
            sortHeader.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('title')"
        />
      </span>
      <span slot="invitedTitle">
        Invited
        <!-- <a-icon
          type="caret-up"
          :rotate="sortInvited.toString().length > 0 && sortInvited === 'descend' ? 180 : 0"
          :class="
            sortInvited.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('invited')"
        /> -->
      </span>
      <span slot="viewedTitle">
        Viewed
        <a-icon
          type="caret-up"
          :rotate="sortViewed.toString().length > 0 && sortViewed === 'descend' ? 180 : 0"
          :class="
            sortViewed.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('view_count')"
        />
      </span>
      <span slot="activeTitle">
        Active
        <a-icon
          type="caret-up"
          :rotate="sortActive.toString().length > 0 && sortActive === 'descend' ? 180 : 0"
          :class="
            sortActive.toString().length > 0
              ? 'admin-training-sort-active'
              : 'admin-training-sort-gray'
          "
          @click="sortByField('is_active')"
        />
      </span>
      <span slot="active" slot-scope="text">
        <div :class="text ? 'admin-training-active-green' : 'admin-training-deactive-red'"></div>
      </span>
      <div slot="actionOper" slot-scope="text, record" class="admin-training-retailer__click-oper">
        <span @click="showEditDialog(record)"> View</span>
      </div>
      <span slot="paidValue" slot-scope="text">
        {{ text ? "Yes" : "No" }}
      </span>
      <span slot="categoryValue" slot-scope="text, record">
        {{ record && record.messageCategory ? record.messageCategory.description : "" }}
      </span>
      <span slot="recipientValue" slot-scope="text, record">
        {{
          record.recipientRetailer && record.recipientRetailer.name
            ? record.recipientRetailer.name
            : record.recipientRetailer && record.recipientRetailer.id == "*"
            ? "All Retailers"
            : ""
        }}
      </span>
      <!-- <div slot="actionOper" slot-scope="text, record" class="admin-training-user__click-oper">
        <span @click="showEditDialog(record)"> Edit</span>
        <span style="margin-left: 5px;margin-right: 5px;">|</span>
        <span @click="showDeleteConfirm(record)">Delete</span>
      </div> -->
    </a-table>
    <admin-training-login-message-dialog
      ref="createAdminTrainingLoginMessage"
      @success="onUpdateSuccess"
    />
    <admin-training-delete-dialog
      ref="confirmDeleteLoginMessage"
      :message="dialogMessage"
      :is-delete-user="false"
      @success="onUpdateSuccess"
    />
  </div>
</template>
<script>
import SearchComponent from "@/components/training/SearchComponent"
import AdminTrainingLoginMessageDialog from "@/components/admin/AdminTrainingLoginMessageDialog"
import AdminTrainingDeleteDialog from "@/components/admin/AdminTrainingDeleteDialog"
import { adminLoginTag } from "@/utils/const"
export default {
  components: {
    "search-component": SearchComponent,
    AdminTrainingLoginMessageDialog,
    AdminTrainingDeleteDialog
  },
  data() {
    return {
      isCheckAll: false,
      selectedData: new Set(),
      searchRecent: [],
      searchValue: "",
      deletedData: undefined,
      dialogMessage:
        "Deleting this LogIn Messages will permanently remove the listing. It cannot be undone!",
      pagination: {
        onChange: page => {
          this.getNextPage(page)
        },
        size: "small",
        showQuickJumper: true,
        pageSize: 50
      },
      //   pageSize: 50,
      tableColumn: [
        {
          key: "isChecked",
          dataIndex: "isChecked",
          slots: { title: "customCheckTitle" },
          scopedSlots: { customRender: "checkAction" },
          width: "30px"
        },
        {
          //title: "Start Date",
          dataIndex: "createdAtStr",
          key: "createdAtStr",
          slots: { title: "createTitle" }
          //sorter: true
        },
        {
          //title: "End Date",
          dataIndex: "paid",
          key: "paid",
          slots: { title: "paidTitle" },
          scopedSlots: { customRender: "paidValue" }
          //sorter: true
        },
        {
          //title: "Retailer Name",
          dataIndex: "category",
          key: "category",
          slots: { title: "categoryTitle" },
          scopedSlots: { customRender: "categoryValue" }
        },
        {
          //title: "Title",
          dataIndex: "recipient",
          key: "recipient",
          slots: { title: "recipientTitle" },
          scopedSlots: { customRender: "recipientValue" }
        },
        {
          dataIndex: "startDateStr",
          key: "startDateStr",
          slots: { title: "startDateTitle" }
        },
        {
          dataIndex: "endDateStr",
          key: "endDateStr",
          slots: { title: "endDateTitle" }
        },
        {
          dataIndex: "title",
          key: "title",
          slots: { title: "headerTitle" }
        },
        {
          dataIndex: "invitedCount",
          key: "invitedCount",
          slots: { title: "invitedTitle" }
        },
        {
          dataIndex: "viewCount",
          key: "viewCount",
          slots: { title: "viewedTitle" }
        },
        {
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "active" },
          slots: { title: "activeTitle" }
        },
        {
          //title: "Actions",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "actionOper" }
        }
      ],
      tableData: [],
      isLoading: false,
      currentPage: 1,
      existedRetailerIds: [],
      sortCreate: "ascend",
      sortPaid: "",
      sortCategory: "",
      sortRecipient: "",
      sortStartDate: "",
      sortEndDate: "",
      sortHeader: "",
      sortInvited: "",
      sortViewed: "",
      sortActive: ""
    }
  },
  async created() {
    this.sortByField("created")
    this.searchRecent = await this.$store.dispatch("training/getSearchBrandTags", adminLoginTag)
  },
  methods: {
    getSearchTrainingPro(param) {
      this.isLoading = true
      this.$store
        .dispatch("seller/searchLoginMessage", param)
        .then(() => {
          this.tableData = this.$store.getters["seller/loginMessageList"]
          this.existedRetailerIds = []
          if (this.tableData && this.tableData.length > 0) {
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].recipientRetailer.id !== "*") {
                this.existedRetailerIds.push(this.tableData[i].recipientRetailer.id)
              }
            }
          }
          console.log(this.tableData)
          this.isLoading = false
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
    onUpdateSuccess() {
      this.currentPage = 1
      this.sortCreate = "ascend"
      this.sortByField("created")
    },
    getNextPage(page) {
      this.currentPage = page
      this.getSearchTrainingPro(this.getBaseParam())
    },
    getBaseParam() {
      let param = {
        _index: this.currentPage,
        _limit: this.pagination.pageSize
      }
      param["keyword"] = this.searchValue
      return param
    },
    async onSearch(value) {
      this.searchValue = value
      this.currentPage = 1
      this.searchRecent = await this.$store.dispatch("training/updateBrandTags", {
        tagName: adminLoginTag,
        tagItem: this.searchValue
      })
      this.getSearchTrainingPro(this.getBaseParam())
    },
    async closeTagItem(item) {
      await this.$store.dispatch("training/updateBrandTags", {
        tagName: adminLoginTag,
        tagItem: item.name,
        type: "delete"
      })
    },
    handleSelectAllReport() {
      this.selectedData.clear()
      this.tableData.forEach(item => {
        item.isChecked = this.isCheckAll
        if (this.isCheckAll) {
          this.selectedData.add(item)
        }
      })
    },
    chooseData(checkedValue, record) {
      console.log(checkedValue)
      console.log(record)
      record.isChecked = checkedValue.target.checked
      this.$forceUpdate()
      if (checkedValue.target.checked) {
        // add record into the list
        console.log("....checked value...., add it....")
        this.selectedData.add(record)
      } else {
        // remove it form list
        console.log(".....unchecked the value, remove it....")
        this.selectedData.delete(record)
        if (this.isCheckAll) {
          this.isCheckAll = false
        }
      }
    },
    resetSorter() {
      this.sortCreate = ""
      this.s
    },
    sortByField(field) {
      console.log(`sort by field: ${field}`)
      let order = "ascend"
      let param = this.getBaseParam()
      param = Object.assign({}, param, { "_sort-by": field })
      if (field === "start_date") {
        if (this.sortStartDate.length === 0) {
          this.sortStartDate = "ascend"
        } else if (this.sortStartDate === "ascend") {
          this.sortStartDate = "descend"
        } else {
          this.sortStartDate = "ascend"
        }
        this.sortCreate = ""
        this.sortPaid = ""
        this.sortCategory = ""
        this.sortRecipient = ""
        this.sortEndDate = ""
        this.sortHeader = ""
        this.sortInvited = ""
        this.sortViewed = ""
        this.sortActive = ""
        order = this.sortStartDate
      } else if (field === "end_date") {
        if (this.sortEndDate.length === 0) {
          this.sortEndDate = "ascend"
        } else if (this.sortEndDate === "ascend") {
          this.sortEndDate = "descend"
        } else {
          this.sortEndDate = "ascend"
        }
        this.sortCreate = ""
        this.sortPaid = ""
        this.sortCategory = ""
        this.sortRecipient = ""
        this.sortStartDate = ""
        this.sortHeader = ""
        this.sortInvited = ""
        this.sortViewed = ""
        this.sortActive = ""
        order = this.sortEndDate
      } else if (field === "created") {
        if (this.sortCreate.length === 0) {
          this.sortCreate = "ascend"
        } else if (this.sortCreate === "ascend") {
          this.sortCreate = "descend"
        } else {
          this.sortCreate = "ascend"
        }
        this.sortStartDate = ""
        this.sortPaid = ""
        this.sortCategory = ""
        this.sortRecipient = ""
        this.sortEndDate = ""
        this.sortHeader = ""
        this.sortInvited = ""
        this.sortViewed = ""
        this.sortActive = ""
        order = this.sortCreate
      } else if (field === "is_paid") {
        if (this.sortPaid.length === 0) {
          this.sortPaid = "ascend"
        } else if (this.sortPaid === "ascend") {
          this.sortPaid = "descend"
        } else {
          this.sortPaid = "ascend"
        }
        this.sortStartDate = ""
        this.sortCreate = ""
        this.sortCategory = ""
        this.sortRecipient = ""
        this.sortEndDate = ""
        this.sortHeader = ""
        this.sortInvited = ""
        this.sortViewed = ""
        this.sortActive = ""
        order = this.sortPaid
      } else if (field === "title") {
        if (this.sortHeader.length === 0) {
          this.sortHeader = "ascend"
        } else if (this.sortHeader === "ascend") {
          this.sortHeader = "descend"
        } else {
          this.sortHeader = "ascend"
        }
        this.sortStartDate = ""
        this.sortPaid = ""
        this.sortCategory = ""
        this.sortRecipient = ""
        this.sortEndDate = ""
        this.sortCreate = ""
        this.sortInvited = ""
        this.sortViewed = ""
        this.sortActive = ""
        order = this.sortHeader
      } else if (field === "view_count") {
        if (this.sortViewed.length === 0) {
          this.sortViewed = "ascend"
        } else if (this.sortViewed === "ascend") {
          this.sortViewed = "descend"
        } else {
          this.sortViewed = "ascend"
        }
        this.sortStartDate = ""
        this.sortPaid = ""
        this.sortCategory = ""
        this.sortRecipient = ""
        this.sortEndDate = ""
        this.sortHeader = ""
        this.sortInvited = ""
        this.sortCreate = ""
        this.sortActive = ""
        order = this.sortViewed
      } else if (field === "is_active") {
        if (this.sortActive.length === 0) {
          this.sortActive = "ascend"
        } else if (this.sortActive === "ascend") {
          this.sortActive = "descend"
        } else {
          this.sortActive = "ascend"
        }
        this.sortStartDate = ""
        this.sortPaid = ""
        this.sortCategory = ""
        this.sortRecipient = ""
        this.sortEndDate = ""
        this.sortHeader = ""
        this.sortInvited = ""
        this.sortCreate = ""
        this.sortViewed = ""
        order = this.sortActive
      } else if (field === "retailer-name") {
        if (this.sortRecipient.length === 0) {
          this.sortRecipient = "ascend"
        } else if (this.sortRecipient === "ascend") {
          this.sortRecipient = "descend"
        } else {
          this.sortRecipient = "ascend"
        }
        this.sortStartDate = ""
        this.sortPaid = ""
        this.sortCategory = ""
        this.sortActive = ""
        this.sortEndDate = ""
        this.sortHeader = ""
        this.sortInvited = ""
        this.sortCreate = ""
        this.sortViewed = ""
        order = this.sortRecipient
      } else if (field === "category") {
        if (this.sortCategory.length === 0) {
          this.sortCategory = "ascend"
        } else if (this.sortCategory === "ascend") {
          this.sortCategory = "descend"
        } else {
          this.sortCategory = "ascend"
        }
        this.sortStartDate = ""
        this.sortPaid = ""
        this.sortActive = ""
        this.sortRecipient = ""
        this.sortEndDate = ""
        this.sortHeader = ""
        this.sortInvited = ""
        this.sortCreate = ""
        this.sortViewed = ""
        order = this.sortCategory
      }
      param = Object.assign({}, param, {
        "_order-by": order === "ascend" ? "NEXT" : "PREVIOUS"
      })
      this.getSearchTrainingPro(param)
    },
    showCreateNew() {
      this.$refs["createAdminTrainingLoginMessage"].openDialog(
        false,
        "Create New Log in Message",
        this.existedRetailerIds
      )
    },
    showEditDialog(data) {
      if (data.recipientRetailer.id !== "*") {
        let retailers = this.existedRetailerIds.filter(id => id !== data.recipientRetailer.id)
        console.log(retailers)
        this.$refs["createAdminTrainingLoginMessage"].openDialog(
          true,
          "Edit Log in Message",
          retailers,
          data
        )
      } else {
        this.$refs["createAdminTrainingLoginMessage"].openDialog(
          true,
          "Edit Log in Message",
          this.existedRetailerIds,
          data
        )
      }
    },
    showDeleteConfirm() {
      //this.deletedData = data
      if (this.selectedData && this.selectedData.size > 0) {
        this.$refs["confirmDeleteLoginMessage"].openDialog([...this.selectedData])
      } else {
        this.$message.warning("Please choose at least one record.")
      }
    },
    handleName(pagination, filters, sorter) {
      this.currentPage = pagination.current
      let param = this.getBaseParam()
      if (sorter.field === "startDateStr") {
        param = Object.assign({}, param, { "_sort-by": "start_date" })
      } else if (sorter.field === "endDateStr") {
        param = Object.assign({}, param, { "_sort-by": "end_date" })
      } else if (sorter.field === "createdAtStr") {
        param = Object.assign({}, param, { "_sort-by": "created" })
      } else {
        param = Object.assign({}, param, { "_sort-by": sorter.field })
      }
      param = Object.assign({}, param, {
        "_order-by": sorter.order === "ascend" ? "NEXT" : "PREVIOUS"
      })
      this.getSearchTrainingPro(param)
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-training-login-message__main {
  .ant-checkbox-wrapper::after {
    contain: none;
    display: none;
  }
}
</style>
