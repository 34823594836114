<template>
  <div>
    <my-lazy-component :data="data"></my-lazy-component>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
const MyLazyComponent = defineAsyncComponent({
  loader: () => import("./product-training.vue"),
});

export default {
  components: {
    MyLazyComponent,
  },
  props: {
    data: Object,
  },
};
</script>
