<template>
  <div ref="addShipDetail" class="add-ship-detail-modal__main">
    <a-modal
      v-model="addShipVisible"
      width="342px"
      :footer="null"
      :get-container="() => $refs.addShipDetail"
    >
      <span slot="title" class="modal-title-text">Delivery Details</span>
      <div class="modal-add-ship-content">
        <div>
          <p class="item-text-title">Carrier*</p>
          <a-select
            v-model="carrierValue"
            :get-popup-container="triggerNode => triggerNode.parentNode"
            style="width: 100%"
            placeholder="Select shipping carrier"
          >
            <a-select-option v-for="item in carrierList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <p class="item-text-title">Tracking Information*</p>
        <a-input v-model="trackingNumber" placeholder="Enter tracking number" />
        <!--        <span v-if="!trackingNumber && showError" class="error-params"-->
        <!--          >Tracking Information is required</span-->
        <!--        >-->
        <p class="item-text-title">Actual Ship Date*</p>
        <a-date-picker
          v-model="shipDate"
          format="YYYY-MM-DD"
          placeholder="MM/DD/YYYY"
          :get-popup-container="triggerNode => triggerNode.parentNode"
          style="width: 294px"
          @change="onChangeShipDate"
        />
        <!--        <span v-if="!shipDate && showError" class="error-params">Actual Ship Date is required</span>-->
        <!--        <p class="item-text-title">Total Carton Quantity*</p>-->
        <!--        <a-input v-model="totalCarton" placeholder="Enter Caton Count" />-->
        <p class="modal-button-style">
          <span class="apply-button-save" :style="{ backgroundColor: color }" @click="saveData"
            >Save</span
          >
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
// import OrderClient from "@/api/order-client"
import constants from "@/config.js"
export default {
  name: "AddShipDetails",
  data() {
    return {
      addShipVisible: false,
      order: {},
      color: {},
      status: "",
      carrierValue: null,
      shipDate: null,
      totalCarton: null,
      trackingNumber: null,
      showError: false
    }
  },
  computed: {
    carrierList() {
      return this.$store.getters["reference/shippingProvider"].filter(item => item.air)
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  methods: {
    openDialog(order, status, color) {
      this.addShipVisible = true
      this.order = order
      this.status = status
      this.color = color
    },
    onChangeShipDate(value, dateStr) {
      console.log("end date", value, dateStr)
      this.shipDate = dateStr
    },
    formatUsersAccepted() {
      let obj = {
        isBrandAccepted: false,
        isRetailerAccepted: false,
        isLandingAccepted: false
      }
      if (this.isAdmin) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = true
      }
      if (this.isBrand) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = false
      }
      if (this.isRetailer) {
        obj.isBrandAccepted = false
        obj.isRetailerAccepted = true
        obj.isLandingAccepted = false
      }
      return obj
    },
    async saveData() {
      this.showError = true
      let order
      let params = {}
      const priv = this.carrierList.filter(item => item.id === this.carrierValue)
      const acceptInfo = this.formatUsersAccepted()
      // if (priv[0].air) {
      order = {
        ...acceptInfo,
        id: this.order.id,
        version: Number(this.order.version) + 1,
        status: {
          id: constants.orderStatus.delivered
        },
        airShipments: [
          {
            provider: {
              id: priv[0].id,
              name: priv[0].name
            },
            actualShipDate: this.shipDate,
            orderStatus: {
              id: constants.orderStatus.delivered
            },
            trackingId: this.trackingNumber
            // totalCartonQuantity: Number(this.totalCarton)
          }
        ]
      }
      if (this.$route.name == "adminBrandOrder") {
        params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      }
      if (this.$route.name == "adminRetailerOrder") {
        params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      }
      await this.$store.dispatch("order/updateStatus", { order, params })
      await this.$store.dispatch("order/getOrder", this.$route.params.id)

      this.addShipVisible = false
    }
  }
}
</script>

<style lang="scss">
.add-ship-detail-modal__main {
  .ant-modal-body {
    padding-top: 0;
  }
  .modal-title-text {
    text-align: center;
  }
  .item-text-title {
    text-align: left;
    margin-bottom: 0;
    margin-top: 15px;
  }
  .modal-button-style {
    margin-top: 20px;
    text-align: center;
  }
  .apply-button-save {
    padding: 5px 28px;
    color: white;
    border-radius: 2px;
    cursor: pointer;
  }
}
</style>
