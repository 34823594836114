<template>
  <div class="wrap-box">
    <div :class="attr.imagePosition == 'left' ? 'image-box left' : 'image-box'">
      <img :src="require('@/assets/img/agency/' + attr.imageSrc)" />
      <div class="description">{{ data.imageDescription }}</div>
    </div>
    <div :class="[attr.imagePosition == 'right' ? 'content-box right' : 'content-box', sectionIndex === 3 ? 'spical-right' : '']">
      <div class="title" :class="sectionIndex === 3 ? 'spical-title' : ''">{{ data.title }}</div>
      <div class="subhead">{{ data.subhead }}</div>
      <div
        v-for="(item, index) in data.content"
        :key="'p' + index"
        :class="['content', sectionIndex === 0 ? 'spical-margin' : '']"
      >
        <div>{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    attr: {
      type: Object
    },
    sectionIndex: {
      type: Number
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/shared/_fonts";

// this is font and style variable
$titleFont: "Poppins-Bold";
$titleColor: #12433d;
$titleSize: 48px;
$subheadFont: "Playfair Display Regular";
$subheadSize: 31px;
$subheadHeight: 52px;
$contentFont: "Proxima Nova";
$color: #000000;
$fontSize: 18px;
$lineHeightContent: 22px;
$lineHeight: 52px;

.wrap-box {
  display: flex;
  flex-wrap: nowrap;

  padding: 45px 0;
  border-top: 1px solid #979797;

  > .left,
  > .right {
    order: -1;
    padding: 0;
    padding-right: 100px;
  }

  .image-box {
    flex-grow: 1;

    img {
      width: 345px;
    }

    .description {
      width: 345px;
      margin-top: 10px;
      font-family: "Proxima Nova";
      color: black;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
    }

    .ant-carousel >>> .slick-slide {
      text-align: center;
      height: 160px;
      line-height: 160px;
      background: #364d79;
      overflow: hidden;
    }

    .ant-carousel >>> .slick-slide h3 {
      color: #fff;
    }
  }

  .content-box {
    display: block;
    flex-grow: 3;

    > div:last-child {
      margin-bottom: 0px !important;
    }

    .title {
      font-family: "Poppins SemiBold";
      font-weight: 600;
      font-size: $titleSize;
      color: $titleColor;
      font-style: SemiBold;
      line-height: $lineHeight;
      margin-bottom: 10px;
    }

    .subhead {
      margin: 20px 0;
      font-family: $subheadFont;
      font-size: $subheadSize;
      line-height: 41px;
      color: black;
      font-weight: 400;
    }

    .content {
      margin-bottom: 20px;
      width: 646px;
      font-family: "Proxima Nova Regular";
      color: black;
      font-size: $fontSize;
      word-spacing: 0;
      line-height: $lineHeightContent;
      text-align: left;
      font-weight: 400;
      white-space: pre-wrap;
    }

    .spical-margin {
      margin-bottom: 24px !important;
    }
  }
  .spical-title {
    width: 682px;
  }
  .spical-right {
    padding-right: 68px;
  }
}
</style>
