<template lang="pug">
.assets-form
  .assets-form__header {{$t('Components.Brands.Products.ProductAssetsForm.imagesAndVideos')}}
  .assets-form__subtitle {{$t('Components.Brands.Products.ProductAssetsForm.images')}}
  .image-assets-form__banner
    ul.image-assets-form__banner-list
      li {{$t('Components.Brands.Products.ProductAssetsForm.imageSizeConstraint')}}
      li {{$t('Components.Brands.Products.ProductAssetsForm.maxImageSizeConstraint')}}
      li {{$t('Components.Brands.Products.ProductAssetsForm.imageFrameConstraint')}}
      li {{$t('Components.Brands.Products.ProductAssetsForm.backgroundColorConstraint')}}
      li {{$t('Components.Brands.Products.ProductAssetsForm.fileFormatConstraint')}}
      li {{$t('Components.Brands.Products.ProductAssetsForm.colorModeConstraint')}}
      li {{$t('Components.Brands.Products.ProductAssetsForm.maxNumberOfFilesAccepted')}}
  .columns.is-multiline.is-mobile
    .column.is-narrow(v-show="images.length" v-for="image in productImages" :key="image.id")
      .product-asset__card(:class="{ 'product-asset__image--main': image.main }")
        img.product-asset__image(:src="image.asset.url")
      .columns
        .column.has-cursor-pointer.product-asset__set-main-button(v-show="!image.main" @click.prevent="setMainImage(image.id)") {{$t('Components.Brands.Products.ProductAssetsForm.setMain')}}
        .column.has-cursor-pointer.has-text-right.product-asset__delete-button(@click.prevent.stop="deleteImage(image.id, image.main)" :class="{ 'product-asset__delete-button--disabled': image.main && images.length > 1 && imageCount > 1}") {{$t('Components.Brands.Products.ProductAssetsForm.delete')}}
    .column(:class="{ 'product-asset__input--disabled': images.length >= 6 }")
      .product-asset__new(@click="$refs.imageFile.click(), imageUpload = true, videoUpload = false")
        .product-asset__new-plus-icon +
        .product-asset__new-link {{$t('Components.Brands.Products.ProductAssetsForm.clickToAddImage')}}
        input.file-input(
          type='file'
          ref="imageFile"
          multiple
          :disabled='isSaving || images.length >= 6'
          @change='saveFiles($event.target.files)'
          accept='image/*'
        )
      p.help.is-danger(v-show="$v.images.$error") {{$t('Components.Brands.Products.ProductAssetsForm.Validations.addAtleastOneImage')}}
      p.help.is-danger(v-if="imageSizeExceeds") {{$t('Components.Brands.Products.ProductAssetsForm.Validations.imageSizeTooLarge')}}

  .assets-form__subtitle {{$t('Components.Brands.Products.ProductAssetsForm.videos')}}
  .assets-form__banner
    ul.assets-form__banner-list
      li {{$t('Components.Brands.Products.ProductAssetsForm.numberOfFilesAcceptedConstraint')}}
      li {{$t('Components.Brands.Products.ProductAssetsForm.acceptableFormatConstraint')}}
      li {{$t('Components.Brands.Products.ProductAssetsForm.maxSizeAllowedConstraint')}}
      li {{$t('Components.Brands.Products.ProductAssetsForm.minVideoQualityConstraint')}}
  .columns.is-multiline.is-mobile
    .column.is-narrow(v-show="videos.length" v-for="video in videos" :key="video.id")
      .product-asset__card
        video.product-asset__image(loop autoplay controls muted)
          source(:src="video.asset.url" type="video/mp4")
      .columns
        .column.has-cursor-pointer.has-text-right.product-asset__delete-button(@click.prevent.stop="deleteVideo(video.id)") {{$t('Components.Brands.Products.ProductAssetsForm.delete')}}
    .column(:class="{ 'product-asset__input--disabled': videos.length >= 6 }")
      .product-asset__new(v-if="videos.length == 0", @click="$refs.file.click(), videoUpload = true, imageUpload = false")
        .product-asset__new-plus-icon +
        .product-asset__new-link {{$t('Components.Brands.Products.ProductAssetsForm.clickToAddVideo')}}
        input.file-input(
          type='file'
          ref="file"
          multiple
          :disabled='isSaving || videos.length == 1'
          @change='saveFiles($event.target.files)'
          accept='video/*'
        )
      p.help.is-danger(v-if="videoSizeExceeds") {{$t('Components.Brands.Products.ProductAssetsForm.Validations.videoSizeTooLarge')}}

</template>

<script>
import { required } from "vuelidate/lib/validators"
import assetClient from "@/api/asset-client"

export default {
  props: {
    initialImages: { type: Array, default: () => [] },
    clientId: { type: String, default: null }
  },
  data() {
    return {
      images: this.initialImages,
      isSaving: false,
      videoSizeExceeds: false,
      imageSizeExceeds: false,
      imageCount: 0,
      isAssetVideo: false,
      imageUpload: false,
      videoUpload: false
    }
  },
  validations: {
    images: { required }
  },
  computed: {
    imagesWithNormalizedIds() {
      return this.sortedImages.map(image => {
        return { ...image, id: image.id || image.asset.id, main: image.main || false }
      })
    },
    productImages() {
      return this.imagesWithNormalizedIds.filter(image => this.isVideo(image) === false)
    },
    videos() {
      return this.imagesWithNormalizedIds.filter(image => this.isVideo(image) === true)
    },
    sortedImages() {
      if (this.images.length > 1 && this.imageCount > 1) {
        const mainImage = this.images.find(image => image.main)
        const sortedImages = this.images.filter(image => !image.main)
        sortedImages.unshift(mainImage)
        return sortedImages
      } else {
        return this.images
      }
    }
  },
  async mounted() {
    if (!this.initialImages.length) {
      return
    }

    this.updateFormImages()
  },
  async created() {
    this.setAssetCount(this.images)
  },
  methods: {
    isVideo(image) {
      if (!image.asset.url) {
        return
      }
      // return image.contentType && image.contentType.split("/")[0] === "video"
      const type = [...image.asset.url.split(".")].pop()
      return !["jpeg", "gif", "png", "jpg"].includes(type)
    },
    productFormImages() {
      return this.imagesWithNormalizedIds.map(image => {
        return {
          asset: { id: image.id, url: image.url },
          //assetId: image.id,
          main: image.main,
          ...image
        }
      })
    },
    updateFormImages() {
      this.$emit("updateImages", this.productFormImages())
    },
    setAssetCount(images) {
      for (var a in images) {
        if (!this.isVideo(images[a])) {
          this.imageCount = this.imageCount + 1
        }
      }
    },
    setMainImage(id) {
      this.images = this.images.map(image => {
        image.main = id === image.id || id === image.asset.id
        return image
      })
      this.updateFormImages()
    },
    deleteImage(id, main) {
      if (this.$refs.imageFile && this.$refs.imageFile.value) {
        this.$refs.imageFile.value = ""
      }
      this.images = this.images.filter(image => ![image.id, image.asset.id].includes(id))
      if (this.images.length && main) {
        const firstImage = this.images[0]
        firstImage.main = true
      }
      this.updateFormImages()
      this.imageCount = this.imageCount - 1
    },
    deleteVideo(id) {
      if (this.$refs.file && this.$refs.file.value) {
        this.$refs.file.value = ""
      }
      this.images = this.images.filter(image => ![image.id, image.asset.id].includes(id))
      this.updateFormImages()
    },
    clearRefsValue() {
      if (this.$refs.file && this.$refs.file.value) {
        this.$refs.file.value = ""
      }
      if (this.$refs.imageFile && this.$refs.imageFile.value) {
        this.$refs.imageFile.value = ""
      }
    },
    async saveFile(file) {
      console.log('....save file....')
      console.log(file)
      const { data } = await assetClient.createAsset(file, this.clientId)

      this.images.push({
        asset: {
          id: data[0].file.id,
          url: data[0].file.url,
          contentType: data[0].file.contentType
        },
        ...data[0].file
      })
      if (this.productImages.length === 1 && !this.isAssetVideo) {
        this.setMainImage(data[0].file.id)
      }
    },
    emitProductSaving(status) {
      this.$emit("productSaving", status)
    },
    async saveFiles(files) {
      this.isSaving = true
      if (files.length > 0 && files[0].type.includes("video") && this.videoUpload) {
        this.videoSizeExceeds = false
        this.isAssetVideo = true
        if (files[0].size / (1024 * 1024) > 150) {
          this.videoSizeExceeds = true
          this.$refs.file.value = ""
          this.isSaving = false
          return
        }
        this.emitProductSaving("savingVideo")
      } else if (files.length > 0 && files[0].type.includes("image") && this.imageUpload) {
        this.imageSizeExceeds = false
        if (files[0].size / (1024 * 1024) > 20) {
          this.imageSizeExceeds = true
          this.$refs.imageFile.value = ""
          this.isSaving = false
          return
        }
        this.imageCount = this.imageCount + 1
        this.emitProductSaving("saving")
      } else {
        this.videoUpload = false
        this.imageUpload = false
        this.isSaving = false
        this.clearRefsValue()
        this.emitProductSaving("error")
        return
      }
      const vm = this

      try {
        await Promise.all(Array.from(files).map(file => vm.saveFile(file)))
        this.updateFormImages()
        this.emitProductSaving("saved")
      } catch (error) {
        console.error(error)
        this.clearRefsValue()
        this.emitProductSaving("error")
      } finally {
        this.clearRefsValue()
        this.imageUpload = false
        this.videoUpload = false
        this.isAssetVideo = false
        this.isSaving = false
        this.$v.images.$touch()
      }
    }
  }
}
</script>

<style lang="sass">
.assets-form
  width: 580px

  &__header
    font-size: 16px
    padding: 5px 0px 20px 0px

  &__banner
    border: 1px solid #FFDBCF
    border-left: 11px solid #FFDBCF
    height: 100px
    margin-bottom: 20px
    font-size: 10px
    padding-left: 20px
    display: flex
    align-items: center
    margin-top: 10px
    padding-top: 10px

  &__banner-list
    list-style-type: disc

.image-assets-form
  width: 580px

  &__header
    font-size: 16px
    padding: 5px 0px 20px 0px

  &__banner
    border: 1px solid #FFDBCF
    border-left: 11px solid #FFDBCF
    height: 125px
    margin-bottom: 20px
    font-size: 10px
    padding-left: 20px
    display: flex
    align-items: center
    margin-top: 10px

  &__banner-list
    list-style-type: disc

.file-input
  height: 1px
  opacity: 0

.product-asset__new:hover
  border-color: #4C4C4C

.product-asset__new:hover .product-asset__new-plus-icon
  color: #4C4C4C

.product-asset__new:hover .product-asset__new-link
  color: #4C4C4C !important

.product-asset
  &__card
    height: 173px
    width: 173px
    display: flex
    align-items: center
    justify-content: center
    background-color: white
    border: 1px solid #979797

  &__image
    max-width: 169px
    max-height: 169px

  &__image--main
    border: 2px solid #979797

  &__new
     width: 173px !important
     height: 173px
     font-size: 14px
     border-radius: 5px
     cursor: pointer
     border: 2px dashed #DFE3E8

     &-plus-icon
       font-size: 45px
       font-weight: 600
       color: rgba(0,0,0,0.38)
       display: flex
       justify-content: center
       align-items: center
       padding-top: 20px

     &-link
       color: rgba(0,0,0,0.38)
       display: flex
       justify-content: center
       align-items: center

  &__set-main-button
    color: #4A90E2
    font-size: 12px
    margin-top: 5px
    margin-left: 5px

  &__delete-button
    font-size: 12px
    margin-top: 5px
    margin-right: 5px
    color: #F9375D

    &--disabled
      display: none

  &__input--disabled
    display: none
</style>
