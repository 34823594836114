<template lang="pug">
.brand-product-card.prodt-info
  .columns(v-if="isFromInventory")
    .brand-product-card__btn-layer
      button.brand-product-card__back-btn(@click="backToPrevious") Back
  template(v-if="product.id")
    .columns.brand-product-card__body.is-multiline
      .column.is-12.brand-style
        .columns
          .column.is-1(v-if="product.images")
            p.brand-product-card__thumb-container(v-for="image in sortedImages()" :key="image && image.asset.id" :class="{ 'brand-product-card__thumb_active': selectedImage && image && selectedImage.asset.id === image.asset.id }")
              video.brand-product-card__thumb(
                poster
                v-if="isVideo(image)"
                @click="selectedImage = image"
              )
                source(:src="image.asset.url" type="video/mp4")
              img.brand-product-card__thumb(
                :src="image && image.asset.url"
                @click="selectedImage = image"
                v-else
              )
          .column.is-1

          .column.brand-product-card__image
            template(v-if="product.images")
              video.brand-product-card__selected-image(loop muted autoplay controls v-if="isVideo(selectedImage)")
                  source(:src="selectedImage && selectedImage.asset && selectedImage.asset.url" type="video/mp4")
              img.brand-product-card__selected-image(:src="selectedImage && selectedImage.asset && selectedImage.asset.url" v-else)
            template(v-else)
              img(:src="fallbackImage()")

          .column.is-1

          .column
            .brand-product-card__details(v-click-outside="hideDropdown")
              ul.brand-product-card__breadcrumb(v-if="product.category")
                li {{ product.category.name }} > &nbsp
                li {{ product.subcategory.name }} &nbsp
                li(v-if="product.microCategory && product.microCategory.length") > {{ product.microCategory.name }}
              p.brand-product-card__details-brand {{ brandName }}
              p.brand-product-card__details-name {{ product.name }}
              .columns
                .column.is-narrow.brand-product-card__details-sku(v-if="product.size") {{$t('Brands.BrandProduct.size')}} {{ product.size.replace(".0", "") }}{{ product.sizeUnits.id }}
                .column.is-narrow(v-if="product.size") •
                .column.brand-product-card__details-sku {{$t('Brands.BrandProduct.sku')}} {{ product.sku }}
              p.brand-product-card__details-color {{ product.color }}
              p.brand-product-card__details-msrp(v-if="product.msrpUsd") {{$t('Brands.BrandProduct.msrp')}}: {{ priceFormat(product.msrpUsd) }}
              .columns.is-vcentered(v-if="product.margin")
                .column.is-narrow
                  p.brand-product-card__details-margin {{ parseFloat(product.margin) }}%
                .column.is-narrow
                  span.brand-product-card__details-price {{ priceFormat(product.wholesalePriceUsd)}}
                .column.is-narrow
                  span.brand-product-card__details-price-info {{$t('Brands.BrandProduct.unitPrice')}}
              .columns.is-vcentered(v-if="product.countryOfOrigin")
                .column.is-narrow
                  p.brand-product-card__details-country {{$t('Brands.BrandProduct.countryOfOrigin')}}:
                .column.is-narrow
                  p.brand-product-card__details-country {{ countryNameById(product.countryOfOrigin.id) }}
              p.brand-product-card__details-sample(v-if="product.sample") {{$t('Brands.BrandProduct.sample')}}
              p.brand-product-card__details-upcoming(v-if="product.status.id === 'discontinued'") {{$t('Brands.BrandProduct.discontinuedProduct')}}
              p.brand-product-card__details-upcoming(v-if="product.status.id === 'upcoming'") {{$t('Brands.BrandProduct.upcomingProduct')}}
              .columns.is-multiline.is-marginless
                .column.is-narrow(v-for="tag in product.tags")
                  a.button.is-rounded.tag-button.is-static {{ tagNameById(tag.id) }}
              <!-- p.has&#45;text&#45;centered(v&#45;for="document in product.documents") -->
              <!--   a.product&#45;card__data&#45;sheet&#45;link(@click="downloadDocument(document.assetId)") Download Safety Data Sheet -->
              .brand-product-card__unpublished-warning-wrapper(v-if="!product.published")
                .columns
                  .column.is-narrow
                    img(src="@/assets/img/icon/unpublished-warning.svg")
                  .column
                    .brand-product-card__unpublished-warning
                      | {{$t('Brands.BrandProduct.requiredFieldsMissing')}}

              .brand-product-item__circles(v-if="(role && businessId === product.brand.id) || isAdmin" @click="isDropdownOpen = true")
                img(src="@/assets/img/icon/ellipses.svg")
              .brand-product-item__dropdown(v-show="isDropdownOpen")
                .brand-product-item__dropdown-item(@click="openEditModal")
                  span.brand-product-item__dropdown-icon
                    img(src="@/assets/img/icon/edit-dropdown-icon.svg")
                  span.brand-product-item__edit-button {{$t('Brands.BrandProduct.edit')}}
                .brand-product-item__dropdown-item(@click="removeProduct")
                  span.brand-product-item__dropdown-icon
                    img(src="@/assets/img/icon/remove-dropdown-icon.svg")
                  span.brand-product-item__remove-button {{$t('Brands.BrandProduct.remove')}}
                .modal(:class="{'is-active': isEditModalOpen}" v-if="isEditModalOpen")
                  BrandProductModal(
                    v-show="isEditModalOpen"
                    :initialProduct="nullSafeProduct(product)"
                    @modalClose="closeModal"
                    @productUpdate="updateProduct"
                  )
      .column
        .brand-product-card__details-header {{$t('Brands.BrandProduct.productDetails')}}
        .brand-product-card__body-info
          .brand-product-card__body-info-title {{$t('Brands.BrandProduct.description')}}
          .brand-product-card__body-description {{ product.description }}
        .brand-product-card__body-info
          .brand-product-card__body-info-title {{$t('Brands.BrandProduct.ingredients')}}
          .brand-product-card__body-description(v-if="product.newIngredients") {{ getIngredientsValue() }}
        .brand-product-card__body-info
          .brand-product-card__body-info-title {{$t('Brands.BrandProduct.keyBenefits')}}
          .brand-product-card__body-description {{ getBenefitsValue() }}
</template>

<script>
import BrandProductModal from "@/components/brands/products/BrandProductModal"
import axios from "@/api/axios"
import { productFallbackImage } from "@/mixins/product-fallback-image.js"
import { nullSafeProduct } from "@/mixins/null-safe-product.js"

export default {
  components: {
    BrandProductModal
  },
  mixins: [productFallbackImage, nullSafeProduct],
  data() {
    return {
      selectedImage: {},
      isFromInventory: false,
      product: {},
      isDropdownOpen: false,
      isEditModalOpen: false,
      brandName: ""
    }
  },
  computed: {
    // brandName() {
    //   const brand = this.$store.getters["brand/brand"]
    //   return brand && brand.name
    // },
    countries() {
      return this.$store.getters["reference/countries"]
    },
    tags() {
      return this.$store.getters["reference/sellSideTags"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    businessId() {
      return this.$store.getters["user/businessId"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  async created() {
    if (!this.role) {
      await this.$store.dispatch("product/getGuestProduct", this.$route.params.id)
    } else {
      await this.$store.dispatch("product/getProduct", this.$route.params.id)
    }
    this.isFromInventory = this.$route.params.isFromInvertoryc
      ? this.$route.params.isFromInvertory
      : false
    this.product = this.nullSafeProduct(this.$store.getters["product/product"])
    if (this.product.images) {
      this.selectedImage = this.product.images.find(image => image.main)
    }
    this.$store.dispatch("reference/setReference")
    this.brandName = this.product.brand.name
    // this.$store.dispatch("brand/getBrand", this.product.brand.id)
  },
  methods: {
    backToPrevious() {
      this.$router.go(-1)
    },
    async downloadDocument(documentId) {
      await this.$store.dispatch("asset/getAsset", documentId)
      const asset = this.$store.getters["asset/find"](documentId)

      axios({
        url: asset.url,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", asset.assignedFileName)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
      })
    },
    isVideo(image) {
      if (!image || !image.asset.url) {
        return
      }
      const type = [...image.asset.url.split(".")].pop()
      return !["jpeg", "gif", "png", "jpg"].includes(type)
    },
    countryNameById(countryId) {
      const country = this.countries.find(country => country.id === countryId)
      return country && country.name
    },
    tagNameById(id) {
      const tag = this.tags.find(tag => tag.id === id)
      return tag && tag.name
    },

    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      })
      return numberFormat.format(price)
    },
    async removeProduct() {
      await this.$store.dispatch("product/removeProduct", this.product.id)
      this.$router.push("/brand/products")
    },
    hideDropdown() {
      this.isDropdownOpen = false
    },
    openEditModal() {
      this.isEditModalOpen = true
    },
    closeModal() {
      this.isEditModalOpen = false
      this.isDropdownOpen = false
    },
    async updateProduct(updatedProduct) {
      this.product = this.nullSafeProduct(updatedProduct)
      this.selectedImage = this.product.images.find(image => image.main)
      if (!this.product.published) {
        await this.$store.dispatch("product/getProduct", this.$route.params.id)
        this.product = this.nullSafeProduct(this.$store.getters["product/product"])
      }
    },
    sortedImages() {
      const mainImage = this.product.images.find(image => image.main)
      const sortedImages = this.product.images.filter(image => !image.main)
      sortedImages.unshift(mainImage)
      return sortedImages
    },
    getBenefitsValue() {
      if (this.product.newBenefits && this.product.newBenefits.length > 0) {
        return this.product.newBenefits.map(item => item.name).join("\n")
      }
      return ""
    },
    getIngredientsValue() {
      if (this.product.newIngredients && this.product.newIngredients.length > 0) {
        return this.product.newIngredients.map(item => item.name).join("\n")
      }
      return ""
    }
  }
}
</script>

<style lang="sass">
.prodt-info
   min-height: 80vh
   padding: 0 20px 0 30px
.brand-product-card__btn-layer
  width: 100%
.brand-product-card__btn-img
  width: 17px
  height: 17px
  margin-left: 6px
.brand-product-card__back-btn
  background: #DEE5EF
  border-radius: 2px
  width: 88px
  height: 30px
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 22px
  color: #000000
  float: right
.brand-product-card
  .brand-style
  &__unpublished-warning-wrapper
    border-radius: 6px
    background-color: #fcf4f1
    margin-top: 25px
    margin-bottom: 10px
    padding: 5px

    img
      padding: 3px 10px

  &__unpublished-warning
    width: 228px
    color: #212B36
    font-size: 16px
    line-height: 24px

.brand-product-card__details-header
  margin-top: 24px
  font-size: 18px
  letter-spacing: 0.25px
  color: #000

.brand-product-card__body-info-title
  letter-spacing: 0.15px
  font-size: 16px
  color: #000
  margin-top: 27px

.brand-product-card__body-description::before
  display: block

.brand-product-card__body-description
  padding-left: 20px
  padding-bottom: 24px
  padding-top: 16px
  color: #4A4A4A
  max-width: 1100px
  white-space: pre-wrap
  overflow-wrap: break-word

.brand-product-card__body-info
  text-align: justify
  border-bottom: 1px solid #E5E5E5

.brand-product-card__body-info:nth-child(4)
  border-bottom: none

.brand-product-card
  padding-top: 40px
  padding-bottom: 60px

  .brand-product-card__thumb-container
    height: 64px
    width: 64px
    display: flex
    justify-content: center
    vertical-align: center
    margin-bottom: 10px

  &__thumb
    margin-bottom: 15px
    cursor: pointer
    height: 100%

    &_active
      border: 2px solid #000000
      border-radius: 2px

  &__breadcrumb
    display: inline

    li
      display: inline
      color: #000000
      font-size: 14px
      font-weight: 300
      letter-spacing: 0.16px

  &__details
    border: 1px solid #e5e5e5
    border-radius: 4px
    max-width: 384px
    padding: 25px 23px 10px 23px
    position: relative

    &-brand
      font-size: 16px
      letter-spacing: 0.15px
      color: #000
      padding-top: 10px

    &-size
      font-size: 18px
      letter-spacing: 0.25px
      color: #000

    &-name
      font-size: 18px
      letter-spacing: 0.25px
      color: #000
      margin-top: 8px

    &-sku
      font-size: 16px
      letter-spacing: 0.4px
      line-height: 22px
      color: #767676
      margin-top: 4px

    &-msrp
      color: #B3B3B3
      font-size: 16px
      letter-spacing: 0.15px
      line-height: 22px
      margin-top: 4px

    &-margin
      color: #AAE4D4
      font-size: 20px

    &-price
      color: #292929
      font-size: 20px
      line-height: 24px
      line-height: 24px
      margin-top: 7px

      &-info
        color: black
        font-size: 14px
        letter-spacing: 0.25px
        line-height: 18px

    &-country
      font-size: 14px
      letter-spacing: 0.25px
      margin-top: 30px

    &-sample
      color: #9B9B9B
      font-size: 14px
      letter-spacing: 0.25px
      line-height: 18px
      text-transform: uppercase
      margin-top: 23px

    &-upcoming
      color: #9B9B9B
      font-size: 14px
      letter-spacing: 0.25px
      margin-top: 23px

  &__buttons
    margin: 0 auto
    margin-top: 20px
    margin-bottom: 5px

  &__data-sheet-link
    text-decoration: underline
    color: #161616
    font-size: 14px
    display: inline-block

  &__image
    display: flex
    justify-content: center
    align-items: center
    width: 591px
    height: 591px
    margin-top: -30px

    video
      max-height: 100%
      max-width: 100%

    img
      max-height: 100%
      max-width: 100%

.tag-button
  cursor: default
  background-color: #dbdbdb !important
  color: #494949 !important
  height: 32px

.brand-product-item__dropdown
  position: absolute
  width: 128px
  height: 92px
  top: 10px
  right: 10px
  z-index: 100
  background-color: #ffffff
  border: 1px solid #DADADA

.brand-product-item__dropdown-item
  height: 45px
  display: flex
  align-items: center
  padding-left: 10px
  cursor: pointer

.brand-product-item__dropdown-icon
  padding-right: 15px

.brand-product-item__dropdown-item:hover
  background-color: #F5F6F8

.brand-product-item__remove-button
  color: #BF0711

.brand-product-item__circles
  position: absolute
  top: 15px
  right: 0px
  z-index: 1
  cursor: pointer
  height: 30px
  width: 30px
</style>
