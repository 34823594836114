<template lang="pug">
OrderDetails(:orderId="orderId")
</template>

<script>
import OrderDetails from "@/components/orders/OrderDetails"
export default {
  components: {
    OrderDetails
  },
  data() {
    return {
      orderId: this.$route.params.id
    }
  }
}
</script>
