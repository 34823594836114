<template>
  <div ref="suspendedRef" class="suspended-Dialog__main">
    <a-modal
      v-model="isModalActive"
      :footer="null"
      :destroy-on-close="true"
      :body-style="noPadding"
      :get-container="() => $refs.suspendedRef"
      width="520px"
    >
      <div class="cancel-subscription-new" style="overflow: hidden; padding: 0;">
        <img
          src="@/assets/webflowAssets/Modal/dots_pattern.svg"
          alt="dots_pattern"
          style="margin-top: -20px; "
        />
        <div style="text-align: center; margin-top: -20px;">
          <p style="font-size: 25px;">Hmm... Something Went Wrong</p>
        </div>
        <div style="text-align: center; padding: 0 30px;">
          <p>
            It seems we had an issue processing your payment. Please update your payment information
            to continue to access the Landing tools.
          </p>
        </div>
        <div class="cancel-dialog-btn-style">
          <span class="close-dialog-style" @click="doSettings">Go to Settings</span>
          <!--          <span class="close-dialog-style" @click="closeModal">No, go back</span>-->
        </div>
        <a-row style="margin-right: -24px; margin-bottom: -24px;">
          <a-col :span="24" style="text-align: right;">
            <img src="@/assets/webflowAssets/Modal/ellipse.svg" />
          </a-col>
        </a-row>
      </div>
    </a-modal>
    <a-modal
      v-model="settingVisible"
      :footer="null"
      title="Payment Method"
      width="700px"
      :get-container="() => $refs.suspendedRef"
    >
      <cardList
        :card-info-list="paymentMethodsData"
        :brand-id="brandId"
        style="margin-bottom: 20px;"
      ></cardList>
      <a-row>
        <a-col :span="20">
          <button
            class="update-payment-details__add-new-text update-payment-details__add-new-border"
            @click="showAddDialog"
          >
            {{ $t("Components.Brands.Settings.PaymentDetails.addNewPayment") }}
            <span class="update-payment-details__add-new-icon" />
          </button>
        </a-col>
        <a-col
          v-if="subscriptionId && subscriptionId === 'Manual'"
          :span="4"
          style="text-align: right;"
        >
          <a-button class="update-payment-details__pay-btn" @click="clickToPay">Pay</a-button>
        </a-col>
      </a-row>
    </a-modal>
    <a-modal
      v-model="isShowUpdateMethod"
      class="update-payment-dialog"
      :footer="null"
      width="501px"
      :destroy-on-close="true"
      :get-container="() => $refs.suspendedRef"
    >
      <template #closeIcon>
        <img src="@/assets/img/icon/close-circle.svg" />
      </template>
      <a-row>
        <span class="update-payment-dialog__title ">{{
          $t("Components.Brands.Payment.updatePayment")
        }}</span>
      </a-row>
      <a-row>
        <payment-info @cardInfo="receiveCardData" />
      </a-row>
      <a-row class="update-payment-dialog__button-row">
        <a-button class="update-payment-dialog__button" @click="doUpdateMethod">
          {{ $t("Components.Brands.Payment.updatePayment") }}
          <span class="update-payment-details__add-new-icon" />
        </a-button>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import cardList from "@/components/brands/settings/NewCardList"
import PaymentInfo from "@/components/brands/payment/PaymentInfo"
import Utils from "@/common/Utils"

export default {
  name: "SuspendedDialog",
  components: {
    cardList,
    PaymentInfo
  },
  data() {
    return {
      isModalActive: false,
      settingVisible: false,
      isShowUpdateMethod: false,
      noPadding: {
        padding: "0px"
      },
      cardData: undefined
    }
  },
  computed: {
    paymentMethodsData() {
      return this.$store.getters["brandPayment/cardInfoList"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    subscriptionId() {
      let brand = this.$store.getters["brand/brand"]
      if (brand && brand.subscriptions && brand.subscriptions.length > 0) {
        return brand.subscriptions[0].subscriptionId
      }
      return undefined
    }
  },
  async created() {
    if (this.$route.query.isSuspended) {
      this.isModalActive = true
      let brandId =
        this.$route.name === "adminBrandUpdatePaymentDetails"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      await this.$store.dispatch("brandPayment/fetchCards", brandId)
    }
  },
  methods: {
    doSettings() {
      this.isModalActive = false
      this.settingVisible = true
    },
    showAddDialog() {
      this.isShowUpdateMethod = true
    },
    receiveCardData(data) {
      this.cardData = data
    },
    hideAddDialog() {
      this.isShowUpdateMethod = false
    },
    clickToPay() {
      if (this.isAdmin) {
        this.$router.push({
          name: "adminBrandSubscription",
          params: {
            isOnlyPay: true
          }
        })
      } else {
        this.$router.push({
          name: "brandSubscription",
          params: {
            isOnlyPay: true
          }
        })
      }
    },
    async doUpdateMethod() {
      //invoke api to save data
      if (!this.cardData) {
        let info = this.$t("Components.Brands.Payment.cardNumberError")
        this.$message.error(info)
        return
      }
      if (this.isCreditCard()) {
        if (this.checkCardInfo()) {
          this.addCardInfo()
        }
      } else {
        this.hideAddDialog()
      }
    },
    async addCardInfo() {
      await this.$store
        .dispatch("brandPayment/updateCardInfo", {
          brandId: this.brandId,
          cardInfo: this.cardData
        })

        .then(response => {
          if (response.error) {
            this.$message.error(response.developerMessage)
          } else {
            let info = this.$t("Components.Brands.Payment.addSuccess")
            this.$message.success(info)
            this.cardData = undefined
            this.hideAddDialog()
          }
        })
    },
    checkCardInfo() {
      if (!this.cardData.cardNumber) {
        let info = this.$t("Components.Brands.Payment.cardNumberEmpty")
        this.$message.error(info)
        return false
      }
      if (!this.cardData.expirationDate) {
        let info = this.$t("Components.Brands.Payment.expirationDateEmpty")
        this.$message.error(info)
        return false
      }
      if (!this.cardData.cardCode) {
        let info = this.$t("Components.Brands.Payment.cvvEmpty")
        this.$message.error(info)
        return false
      }
      if (!Utils.checkCardNumber(this.cardData.cardNumber)) {
        // check number
        let info = this.$t("Components.Brands.Payment.cardNumberError")
        this.$message.error(info)
        return false
      }
      if (!Utils.checkExpiredDate(this.cardData.expirationDate)) {
        let info = this.$t("Components.Brands.Payment.expiredDateFormatError")
        this.$message.error(info)
        return false
      }
      if (Utils.isDateExpired(this.cardData.expirationDate)) {
        let info = this.$t("Components.Brands.Payment.expiredDateExpiredError")
        this.$message.error(info)
        return false
      }
      if (!Utils.checkCardCode(this.cardData.cardCode)) {
        let info = this.$t("Components.Brands.Payment.cardCodeError")
        this.$message.error(info)
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.suspended-Dialog__main {
  .cancel-subscription-new {
    text-align: center;
    padding: 48px 0;
    position: relative;
    overflow: hidden;

    p {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      font-family: "Mr Eaves Mod OT";
    }
  }

  .continue-cancel-style {
    color: #000000;
    cursor: pointer;
  }

  .close-dialog-style {
    padding: 17px 35px;
    color: white;
    background: #f15a42;
    font-size: 14px;
    font-weight: bold;
    border-radius: 100px;
    margin-left: 30px;
    cursor: pointer;
  }

  .cancel-dialog-btn-style {
    width: 100%;
    margin-top: 50px;
    text-align: center;
  }

  .subscription-plan-style {
    background-color: rgba(207, 229, 204, 0.2);
    padding: 42px 41px 42px 47px;
    margin-bottom: 35px;
    border-radius: 10px;
  }

  .flex-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .click-retailer-plan {
    background-color: #f15a42;
    color: #ffffff;
  }

  .update-payment-details__add-new-text {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4a8a5d;
  }

  .update-payment-details__add-new-border {
    padding: 2px 10px;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
    align-items: center;
  }

  .update-payment-details__add-new-icon:before {
    content: "\00a0";
  }

  .update-payment-details__add-new-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
    margin-left: 4px;
    background-image: url("../../assets/img/icon/add_new_payment_btn_card.svg");
  }

  .update-payment-details__pay-btn {
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    color: #4a8a5d !important;
    font-size: 16px !important;
    line-height: 24px !important;
    height: 30px !important;
  }

  .update-payment-dialog {
    width: 501px;
  }

  .update-payment-dialog__title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #262626;
    margin-bottom: 17px;
  }

  .update-payment-dialog__button-row {
    align-items: center;
    margin-top: 20px;
    padding: 0px 25%;
  }

  .update-payment-dialog__button {
    font-family: Proxima Nova !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    border-radius: 2px !important;
    border-color: #4a8a5d !important;
    color: #4a8a5d !important;
  }
}
</style>
