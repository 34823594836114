<template lang="pug">
.retailer-address
  .retailer-address__title {{$t('Components.Retailers.Checkout.RetailerAddress.shippingAddress')}}
   span.retailer-address__edit-form
     a(@click.prevent="displayAddressesModal = true") {{$t('Components.Retailers.Checkout.RetailerAddress.edit')}}
  .retailer-address__address(v-show="selectedAddress.postalCode")
    p.retailer-address__paragraph {{ selectedAddress.addressee }}
    p.retailer-address__paragraph {{ selectedAddress.streetAddressLine_1 }}
    p.retailer-address__paragraph(v-if="selectedAddress.streetAddressLine_2") {{ selectedAddress.streetAddressLine_2}}
    p.retailer-address__paragraph {{ selectedAddress.city }}, {{ selectedAddress.region }} {{ selectedAddress.postalCode }}
    p.retailer-address__paragraph {{ countryNameById(selectedAddress.country.id) }}

  .modal.address-modal(:class="{ 'is-active': displayAddressesModal }")
    .modal-background(@click="displayAddressesModal = false")
    .modal-card.address-modal__content
      header.address-modal__title--centered Addresses
        img.address-modal__close-button(src="@/assets/img/close-button.png" @click="displayAddressesModal = false")
      section.modal-card-body
        .address-modal__address(v-for="address,index in addresses" v-if="!address.hq")
          .columns.is-marginless
            .column.address-modal__paragraphs
              p {{ address.addressee }}
              p {{ address.streetAddressLine_1 }}
              p {{ address.streetAddressLine_2}}
              p {{ address.city }}, {{ address.region }} {{ address.postalCode }}
              p {{ countryNameById(address.country.id) }}
            .column.is-narrow.address-modal__paragraphs.address-modal__text-end
              p.address-modal__action-button(@click="switchSelectedAddress(address)") {{$t('Components.Retailers.Checkout.RetailerAddress.useAddress')}}
              p.address-modal__action-button(@click="editAddress(address)") Edit
              p.address-modal__action-button(@click="removeAddress(index)") Remove
          //- button.button.address-modal__new.is-black(@click="switchFormAddress()") {{$t('Components.Retailers.Checkout.RetailerAddress.addANewAddress')}}
      .modal-card-foot.address-button
          a.button.is-black(@click="switchFormAddress()") {{$t('Components.Retailers.Checkout.RetailerAddress.addANewAddress')}}
      .modal.address-modal(:class="{'is-active': addressFormEditing}" v-if="addressFormEditing")
        .modal-background
        .modal-card.address-modal__content
          header.address-modal__title {{ formHeader() }}
            img.address-modal__back-button(src="@/assets/img/back-arrow.png" @click="addressFormEditing = false")
            img.address-modal__close-button(src="@/assets/img/close-button.png" @click="addressFormEditing = false")
          AddressModal(
            :address="formAddress"
            :addresses="addresses"
            :isEditing="isEditing"
            @addressChange="addAddress"
            @addressEdited="updateAddressesAndSyncWithApi"
            )
</template>

<script>
import AddressModal from "@/components/retailers/checkout/RetailerAddressModal"

export default {
  components: {
    AddressModal
  },
  data() {
    return {
      isEditing: false,
      addresses: [],
      displayAddressesModal: false,
      addressFormEditing: false,
      addressFormCreating: false,
      selectedAddress: {
        label: "",
        addressee: "",
        streetAddressLine_1: "",
        streetAddressLine_2: "",
        city: "",
        region: "",
        postalCode: "",
        country: {
          id: ""
        }
      },
      formAddress: {
        label: "",
        addressee: "",
        streetAddressLine_1: "",
        streetAddressLine_2: "",
        city: "",
        region: "",
        postalCode: "",
        country: {
          id: ""
        }
      }
    }
  },
  computed: {
    retailerId() {
      return this.$route.name == "adminRetailerCheckout"
        ? this.$route.params.retailerId
        : this.$store.getters["user/businessId"]
    },
    countries() {
      return this.$store.getters["reference/countries"]
    },
    addressesForApi() {
      return this.addresses.map(({ id, createdAt, ...rest }) => rest)
    }
  },
  async created() {
    await this.$store.dispatch("retailerAddress/fetchAddresses", this.retailerId)
    this.addresses = this.$store.getters["retailerAddress/addresses"]
    this.selectedAddress = this.$store.getters["retailerAddress/defaultAddress"]
  },

  methods: {
    emitSelectedAddressChange() {
      this.$emit("selectedAddressChange", this.selectedAddress)
    },
    async syncWithApi() {
      try {
        await this.$store.dispatch("retailerAddress/updateAll", {
          retailerId: this.retailerId,
          addresses: this.addressesForApi
        })
      } catch (error) {
        console.error(error)
      }
    },
    countryNameById(countryId) {
      const country = this.countries.find(country => country.id === countryId)
      return country && country.name
    },
    addAddress(updatedAddress) {
      this.addresses.push(updatedAddress)
      this.updateAddressesAndSyncWithApi()
    },
    async updateAddressesAndSyncWithApi() {
      try {
        await this.syncWithApi()
        this.addressFormEditing = false
      } catch (error) {
        console.error(error)
      }
    },
    switchFormAddress() {
      this.isEditing = false
      this.formAddress = this.nullAddress()
      this.addressFormEditing = true
    },
    formHeader() {
      return this.isEditing ? "Edit Address" : "Add New Address"
    },
    switchSelectedAddress(address) {
      this.selectedAddress = address
      this.emitSelectedAddressChange()
      this.syncWithApi()

      this.displayAddressesModal = false
    },
    editAddress(address) {
      this.isEditing = true
      this.formAddress = address
      this.addressFormEditing = true
    },
    removeAddress(index) {
      this.addresses.splice(index, 1)
      this.updateAddressesAndSyncWithApi()
    },
    nullAddress() {
      return {
        label: "",
        addressee: "",
        streetAddressLine_1: "",
        streetAddressLine_2: "",
        city: "",
        region: "",
        postalCode: "",
        country: {
          id: ""
        }
      }
    }
  }
}
</script>

<style lang="sass">
.address-button
    border: none
    justify-content: center !important

.address-modal__new
  position: absolute
  bottom: 43px
  width: 220px
  height: 46px
  left: calc(50% - 110px)

.retailer-address
  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.25px
    margin-bottom: 25px


  &__edit-form
    text-decoration: underline
    color: #161616
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.16px
    line-height: 18px
    margin-left: 11px

  &__paragraph
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 26px

  a
    color: #161616

.address-modal
  &__content
    position: relative
    background-color: #FFFFFF
    padding: 24px 24px 0px 24px
    width: 660px
    height: 600px
    overflow: auto

  &__close-button
    position: absolute
    top: 24px
    right: 24px
    cursor: pointer
    height: 12.55px
    width: 12.74px

  &__title
    font-size: 20px
    font-weight: 600
    padding-top: 44px
    margin-left: 133px
    letter-spacing: 0.28px
    line-height: 24px
    margin-bottom: 28px

    &--centered
      display: flex
      justify-content: center
      font-size: 20px
      font-weight: 600
      padding-top: 44px
      letter-spacing: 0.28px
      line-height: 24px
      margin-bottom: 28px

  &__back-button
    position: absolute
    top: 24px
    left: 24px
    cursor: pointer
    height: 16px
    width: 15px

  &__address:first-child
    border-top: 1px solid #e5e5e5
    border-bottom: 1px solid #e5e5e5
    margin-left: 28px
    margin-right: 28px
  // &__address:last-child
  //     border: none

  &__address
    border-bottom: 1px solid #e5e5e5
    margin-left: 28px
    margin-right: 28px

  &__paragraphs
    margin-top: 14px
    margin-bottom: 14px

  &__text-end
    text-align: end

  &__action-button
    cursor: pointer
</style>
