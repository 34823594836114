<template>
  <a-modal
    v-model="showDialog"
    width="403px"
    :closable="true"
    :destroy-on-close="true"
    :footer="null"
    @cancel="cancelDialog"
  >
    <div class="training-info-dialog__title">{{ title }}</div>
    <div class="training-info-dialog__content">{{ content }}</div>
    <div class="training-info-dialog__action">
      <a-button class="training-info-dialog__btn" @click="clickToAction">{{ btnText }}</a-button>
    </div>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      showDialog: false,
      title: "",
      content: "",
      btnText: ""
    }
  },
  methods: {
    openDialog(titleValue, contentValue, buttonText) {
      this.title = titleValue
      this.content = contentValue
      this.btnText = buttonText
      this.showDialog = true
    },
    cancelDialog() {
      this.title = ""
      this.content = ""
      this.btnText = ""
      this.showDialog = false
    },
    clickToAction() {
      this.$emit("clickBtn")
      this.cancelDialog()
    }
  }
}
</script>
<style lang="scss" scoped>
.training-info-dialog {
  &__title {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    color: #1a1818;
    margin-top: 32px;
    margin-bottom: 14px;
  }
  &__content {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #202020;
    margin-bottom: 48px;
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btn {
    background: #a0d29b;
    border-radius: 7px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 5px 25px;
    border: none;
  }
}
</style>
<style lang="scss">
.training-info-dialog__btn.ant-btn:hover,
.training-info-dialog__btn.ant-btn:focus,
.training-info-dialog__btn.ant-btn:active,
.training-info-dialog__btn.ant-btn.active {
  background: #a0d29b;
  color: white;
  border: none;
}
</style>
