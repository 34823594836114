<template lang="pug">
a-modal.add-paymentCard-Dialog__mian(
  v-model="isShowDialog"
  :footer="null"
  width="600px"
  @cancel="closeModal")
  .guest-payment-component__main-content
    section.payment--form
      p.form--title.heading--5 {{ formTitle }}
      p.form--content.body--normal {{ text }}
      .payment--methods
        .label Payment Methods
        img(src="@/assets/webflowAssets/Payment/method.svg" alt="Methods")
      .card-list-info
        cardList(
          :card-info-list="paymentMethodsData"
          :brand-id="brandId"
          style="margin-bottom: 20px;"
        )
      .add-new-payment-card
        p(@click="openAddPayment") Add New Payment
      .promo--code
        b-field(label="promo code" :type="showCodeText.type" :message="showCodeText.message")
          b-input(v-model="promoCode" placeholder="Insert Code")
        span.body--small.remove--code(@click="removeCode") REMOVE CODE
        span.apply-coupon-code-check(v-if="showApply" @click="applyCode") APPLY
      .total--cost
        span Subscription Cost:
        span ${{ price }} annually
      .text-info-checkout
        b-checkbox(v-model="isCheck")
        p By registering subscriber, you agree to the
          span(@click="jumpToTerms" style="text-decoration: underline;cursor: pointer;")   Landing Terms of Use
          |   and
          span(@click="jumpToPrivacy" style="text-decoration: underline;cursor: pointer;")   Privacy Policy
      .form--footer
        //my-button(variant="is-text" label="Cancel" size="is-large" @onClick="cancelPayment")
        my-button(variant="is-primary" label="Subscribe" size="is-large" @onClick="submit")
</template>

<script>
import MyButton from "@/components/webflowComponent/Button"
import cardList from "@/components/brands/settings/NewCardList"
// import AES from "@/common/AES"
// import Utils from "@/common/Utils"
export default {
  name: "AddPaymentCardDialog",
  components: {
    MyButton,
    cardList
  },
  data() {
    return {
      isShowDialog: false,
      isCheck: false,
      formTitle: "Add Your Payment Details",
      text: "To activate your subscription at the end of your 21-day trial.",
      activeTab: 0,
      firstName: "",
      lastName: "",
      cardNumber: "",
      cvvNumber: "",
      promoCode: "",
      date: null,
      price: 2000,
      showApply: true,
      codeText: [
        {
          type: "is-success",
          message: "This promo code is valid"
        },
        {
          type: "is-danger",
          message: "This promo code is invalid"
        }
      ],
      showCodeText: {},
      amount: 0,
      couponAmount: 0,
      expirationDate: null
    }
  },
  computed: {
    paymentMethodsData() {
      return this.$store.getters["brandPayment/cardInfoList"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    },
    brandSubscriptionStatus() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].status &&
        this.brand.subscriptions[0].status.id
      )
    }
  },
  methods: {
    async openAddCardDialog() {
      this.isShowDialog = true
      let brandId =
        this.$route.name == "adminBrandUpdatePaymentDetails"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      await this.$store.dispatch("brandPayment/fetchCards", brandId)
    },
    cancelPayment() {
      this.isShowDialog = false
    },
    removeCode() {
      this.showApply = true
      this.promoCode = ""
      this.showCodeText = {}
      this.price = 2000
    },
    onChangeShipDate(value, dateStr) {
      console.log("date", value, dateStr)
      this.expirationDate = dateStr
    },
    showSuccessDialog() {
      if (this.$route.name.toString().startsWith("admin")) {
        this.$router.push({ name: "adminBrandPaySubscriptionSuccess" })
      } else {
        this.$router.push({ name: "brandPaySubscriptionSuccess" })
      }
    },
    openAddPayment() {
      this.$emit("openAddPaymentDialog")
      this.isShowDialog = false
    },
    showErrorInfo(message) {
      if (message) {
        this.$message.error(message)
      }
    },
    async submit() {
      if (this.paymentMethodsData && this.paymentMethodsData.length === 0) {
        this.$message.error("Please add card")
      }
      if (this.brandType === "trial" && this.brandSubscriptionStatus === "cancelled") {
        this.submitPayment()
        return
      }
      const modeType = this.brandType === "trial" ? "subscriber" : "trial"
      if (this.promoCode && this.promoCode.length > 0) {
        await this.updateBrandSubscription(modeType)
      } else {
        await this.updateSubscriptionInfo(modeType)
      }
    },
    async updateBrandSubscription(modeType) {
      if (!this.couponAmount) {
        this.$message.error("Please check the Promo Code")
      }
      const params = {
        id: this.brand.id,
        promiseCoupon: {
          code: this.promoCode,
          amount: this.couponAmount
        }
      }
      await this.$store.dispatch("brand/updateBrandPartially", params)
      const param = {
        mode: modeType ? modeType : "trial"
      }
      await this.$store.dispatch("brand/updateBrandSubscriptionNew", param)
      await this.fetchBrandInfo()
      this.isShowDialog = false
      if (this.$route.name !== "brandSettingsSubscription") {
        this.$router.push({
          name: "brandMain"
        })
      }
    },
    async updateSubscriptionInfo(modeType) {
      const param = {
        mode: modeType ? modeType : "trial"
      }
      await this.$store.dispatch("brand/updateBrandSubscriptionNew", param)
      await this.fetchBrandInfo()
      this.isShowDialog = false
      if (this.$route.name !== "brandSettingsSubscription") {
        this.$router.push({
          name: "brandMain"
        })
      }
    },
    submitPayment() {
      if (!this.isCheck) {
        this.$message.error("Please view and check the protocol")
        return
      }
      const list = this.paymentMethodsData.filter(item => item.default)
      const cardNumber = list[0].cardNumber
      let params = {
        amount: 2000,
        cardNumber: cardNumber
        // status: { id: "activated" }
      }
      if (this.promoCode && this.promoCode.length > 0) {
        params["couponCode"] = this.promoCode
      }
      // let mode
      // if (this.brand && this.brand.subscriptions && this.brand.subscriptions.length !== 0) {
      //   const obj = this.brand.subscriptions[0]
      //   // Trial expired
      //   if (obj.type.name === "Trial" && obj.status.id === "expired") {
      //     mode = "subscriber"
      //   } else {
      //     mode = "trial"
      //   }
      // }
      this.$store
        .dispatch("brandPayment/payOrder", {
          brandId: this.brandId,
          param: params
        })
        .then(async response => {
          if (response.error) {
            this.isLoading = false
          } else {
            this.showSuccessDialog()
            this.showErrorInfo()
            // const param = {
            //   mode
            // }
            // await this.$store.dispatch("brand/updateBrandSubscriptionNew", param)
            await this.fetchBrandInfo()
          }
        })
        .catch(response => {
          if (!response.message) {
            this.showErrorInfo(this.$t("Components.Brands.Payment.payFailedServerError"))
          } else {
            let data = response.message
            this.showErrorInfo(data)
          }
        })
      if (this.$route.name !== "brandSettingsSubscription") {
        this.$router.push({
          name: "brandMain"
        })
      }
    },
    closeModal() {
      this.isShowDialog = false
      if (
        this.$route.name === "BrandTraining" ||
        this.$route.name === "brandCleanCheck" ||
        this.$route.name === "brandOrders" ||
        this.$route.name === "BrandSearch" ||
        this.$route.name === "membershipExpiringDialog" ||
        this.$route.name === "brandInventory"
      ) {
        this.$router.push({
          name: "brandMain"
        })
      }
    },
    async fetchBrandInfo() {
      const brandId =
        this.$route.name == "adminBrandSettingsSubscription"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"]
      await this.$store.dispatch("brand/getBrand", brandId)
    },
    applyCode() {
      let params = {
        couponCode: this.promoCode
      }
      this.$store
        .dispatch("adminConsole/getCouponSignUpValue", params)
        .then(res => {
          this.showApply = false
          this.showCodeText = this.codeText[0]
          if (res) {
            this.amount = res.amount
            // this.price = 2000 - this.amount
            this.price = Math.round((2000 - this.amount) * 100) / 100
            this.couponAmount = res.amount
          }
        })
        .catch(e => {
          console.log(e)
          this.showApply = false
          // this.promoCode = ""
          this.showCodeText = this.codeText[1]
        })
    },
    jumpToTerms() {
      window.open("https://www.landinginternational.com/terms-of-use", "_blank")
    },
    jumpToPrivacy() {
      window.open("https://www.landinginternational.com/privacy-policy", "_blank")
    }
  }
}
</script>

<style lang="scss">
.add-paymentCard-Dialog__mian {
  .guest-payment-component__main-content {
    p {
      margin-bottom: 0;
    }
    .input,
    .textarea,
    .input:hover,
    .textarea:hover,
    .input:focus,
    .textarea:focus {
      border: 1px solid #f15a42;
      box-sizing: border-box;
      border-radius: 50px;
      padding: 16px 24px;

      font-size: 20px;
      line-height: 22px;
      color: #000000;

      box-shadow: none;
      height: fit-content;
    }
    .payment--form .form--footer {
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;
      text-align: center;
    }

    .payment--form .form--footer button + button {
      margin-left: 25px;
    }

    .payment--form .form--title {
      text-align: left;
      color: #1a1818;
      font-size: 25px;
    }

    .payment--form .form--content {
      margin-bottom: 34px;
      font-size: 20px;
    }

    .payment--form .promo--code {
      position: relative;
    }
    .apply-coupon-code-check {
      position: absolute;
      right: 23px;
      top: 35px;
      font-size: 20px;
      color: #f15a42;
      cursor: pointer;
    }

    .payment--form .promo--code .field label {
      font-family: Niveau Grotesk;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #000000;
    }

    .payment--form .remove--code {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    .total--cost {
      padding: 17px;
      font-family: "Mr Eaves Mod OT";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      background-color: rgba(241, 90, 66, 0.1);
      border-radius: 100px;
      color: black;
    }

    .total--cost span:nth-child(2) {
      font-weight: 400;
      margin-left: 16px;
    }
    .ant-calendar-picker {
      width: 100%;
    }
    .ant-calendar-picker-input.ant-input {
      width: 100%;
      border: 1px solid #f15a42;
      box-sizing: border-box;
      border-radius: 50px;
      padding: 12px 24px;
      font-size: 20px;
    }
    .ant-input {
      height: auto;
    }
    .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: #f15a42;
    }
    .control.has-icons-right .icon.is-right {
      top: 8px;
    }
  }
  .text-info-checkout {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .add-new-payment-card {
    padding: 20px 0;
    p {
      width: 200px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border: 1px solid #f15a42;
      border-radius: 5px;
      color: #f15a42;
      font-size: 18px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 767px) {
  .payment--form .form--content {
    max-width: 300px;
  }
}
</style>
