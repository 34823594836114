<template>
  <div class="admin-training">
    <a-breadcrumb :routes="routes" class="admin-training-breadcrumb">
      <template slot="itemRender" slot-scope="{ route, paths }">
        <!-- <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.breadcrumbName }}
        </span> -->
        <span
          v-if="routes.indexOf(route) === 0"
          class="admin-training-breadcrumb-content"
          @click="onChangeTab('0')"
        >
          {{ route.breadcrumbName }}
        </span>
        <router-link v-else :to="`${basePath}/${paths.join('/')}`">
          {{ route.breadcrumbName }}
        </router-link>
      </template>
    </a-breadcrumb>
    <!-- <ul class="admin-training-tabs">
      <li id="brand" @click="onChangeTab('0')">Brand</li>
      <li id="retailer" @click="onChangeTab('1')">Retailers</li>
      <li id="users" @click="onChangeTab('2')">Users</li>
      <li id="loginMessage" @click="onChangeTab('3')">
        Log in Messaging
      </li>
    </ul>
    <router-view></router-view> -->
    <a-tabs
      v-model="currentTab"
      class="admin-training-tabs1"
      size="small"
      @change="onChangeTab"
    >
      <a-tab-pane key="0" tab="Brands">
        <router-view></router-view>
      </a-tab-pane>
      <a-tab-pane key="1" tab="Retailers">
        <AdminTrainingRetailer />
      </a-tab-pane>
      <a-tab-pane key="2" tab="Users">
        <admin-training-user />
      </a-tab-pane>
      <a-tab-pane key="3" tab="Log in Messaging">
        <admin-training-login-message />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import AdminTrainingRetailer from "@/views/Admins/AdminTrainingRetailer";
import AdminTrainingUser from "@/views/Admins/AdminTrainingUser";
import AdminTrainingLoginMessage from "@/views/Admins/AdminTrainingLoginMessage";
//import AdminTrainingBrand from "@/components/admin/AdminTrainingBrand"
export default {
  components: {
    //AdminTrainingBrand,
    AdminTrainingRetailer,
    AdminTrainingUser,
    AdminTrainingLoginMessage,
  },
  data() {
    return {
      basePath: "/admin",
      brandRoutes: [
        {
          path: "adminTraining",
          breadcrumbName: "BeautyFluent",
        },
        {
          path: "?tab=0",
          breadcrumbName: "Brands",
        },
      ],
      retailerRoutes: [
        {
          path: "adminTraining",
          breadcrumbName: "BeautyFluent",
        },
        {
          path: "?tab=1",
          breadcrumbName: "Retailers",
        },
      ],
      userRoutes: [
        {
          path: "adminTraining",
          breadcrumbName: "BeautyFluent",
        },
        {
          path: "?tab=2",
          breadcrumbName: "Users",
        },
      ],
      loginRoutes: [
        {
          path: "adminTraining",
          breadcrumbName: "BeautyFluent",
        },
        {
          path: "?tab=3",
          breadcrumbName: "Log in Messaging",
        },
      ],
      routes: [],
      currentTab: "0",
      isSecondLayer: false,
      brandName: "",
      brandShortName: "",
    };
  },
  // computed: {
  //   brand() {
  //     console.log('....brand ....')
  //     console.log(this.brand)
  //     return this.$store.getters["brand/brand"]
  //   }
  // },
  // watch: {
  //   brand() {
  //     if (this.brand && this.brand.name != this.brandName) {
  //       this.brandName = this.brand.name
  //       this.brandShortName = this.brand.shortName
  //       console.log(this.brandName)
  //     }
  //   }
  // },
  watch: {
    $route(to) {
      console.log(to);
      if (to.name === "adminBrandTrainingProduct") {
        if (to.params.name) {
          this.brandName = to.params.name;
          this.brandShortName = to.params.shortName;
        }
        this.routes = [
          {
            path: "adminTraining",
            breadcrumbName: "Training",
          },
          {
            path: "?tab=0",
            breadcrumbName: "Brands",
          },
          {
            path: "",
            breadcrumbName: this.brandName,
          },
        ];
      } else if (to.name === "adminTrainingBrand") {
        let tab = to.query.tab;
        console.log(tab);
        if (tab === "1") {
          this.routes = this.retailerRoutes;
        } else if (tab === "2") {
          this.routes = this.userRoutes;
        } else if (tab === "3") {
          this.routes = this.loginRoutes;
        } else {
          this.routes = this.brandRoutes;
        }
      }
    },
  },
  mounted() {
    this.initTab();
  },
  updated() {
    // if (
    //   this.$route.name === "adminBrandTrainingProduct" &&
    //   this.routes.length < 3 &&
    //   this.currentTab === "0"
    // ) {
    //   if (this.$route.params.name) {
    //     this.brandName = this.$route.params.name
    //     this.brandShortName = this.$route.params.shortName
    //   }
    //   this.routes = [
    //     {
    //       path: "adminTraining",
    //       breadcrumbName: "Training"
    //     },
    //     {
    //       path: "?tab=0",
    //       breadcrumbName: "Brands"
    //     },
    //     {
    //       path: "",
    //       breadcrumbName: this.brandName
    //     }
    //   ]
    // }
  },
  methods: {
    initTab() {
      this.currentTab =
        this.$route.query && this.$route.query.tab
          ? this.$route.query.tab
          : "0";
      if (this.$route.name === "adminBrandTrainingProduct") {
        if (this.$route.params.name) {
          this.brandName = this.$route.params.name;
          this.brandShortName = this.$route.params.shortName;
          this.toBrandProductList();
        } else {
          this.$store
            .dispatch("brand/getBrand", this.$route.params.brandId)
            .then(() => {
              let brand = this.$store.getters["brand/brand"];
              this.brandName = brand.name;
              this.brandShortName = brand.shortName;
              this.toBrandProductList();
            });
        }
      } else {
        this.onChangeTab(this.currentTab);
      }
    },
    toBrandProductList() {
      this.$router.push({
        name: "adminBrandTrainingProduct",
        params: {
          brandId: this.$route.params.brandId,
          name: this.brandName,
          shortName: this.brandShortName,
        },
      });
      this.routes = [
        {
          path: "adminTraining",
          breadcrumbName: "Training",
        },
        {
          path: "?tab=0",
          breadcrumbName: "Brands",
        },
        {
          path: "",
          breadcrumbName: this.brandName,
        },
      ];
    },
    modifyTabBg() {
      var obj = document.getElementsByClassName("admin-training-tabs");
      if (obj != null && obj.length > 0) {
        var childres = obj[0].children;
        if (childres != null && childres.length > 0) {
          for (let i = 0; i < childres.length; i++) {
            childres[i].style.backgroundColor = "#d9d9d9";
          }
        }
      }
    },
    clickProduct(data) {
      this.routes = [
        {
          path: "adminTraining",
          breadcrumbName: "Training",
        },
        {
          path: "?tab=0",
          breadcrumbName: "Brands",
        },
        {
          path: "",
          breadcrumbName: data,
        },
      ];
    },
    onChangeTab(key) {
      this.currentTab = key;
      this.isSecondLayer = false;
      // this.currentTab = key
      //this.modifyTabBg()
      console.log(".....change tab.......");
      if (key === "0") {
        this.routes = this.brandRoutes;
        this.$router.push({
          path: "/admin/adminTraining/",
        });
      } else if (key === "1") {
        this.routes = this.retailerRoutes;
        // this.$router.push({ name: "adminTrainingRetailer" })
        // this.$router.push({
        //   path: "/admin/adminTraining?tab=1"
        // })
        this.$router.push({
          path: `/admin/adminTraining?tab=1`,
        });
      } else if (key === "2") {
        this.routes = this.userRoutes;
        this.$router.push({
          path: `/admin/adminTraining?tab=2`,
        });
        // this.$router.push({ name: "adminTrainingUser" })
      } else if (key === "3") {
        this.routes = this.loginRoutes;
        // this.$router.push({ name: "adminTrainingLoginMessage" })
        this.$router.push({
          path: `/admin/adminTraining?tab=3`,
        });
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.admin-training {
  font-style: normal;
  font-weight: normal;
  padding: 60px 40px;
}
</style>
<style lang="scss">
.admin-training-landing-green-btn {
  border: none !important;
  background-color: #4a8a5d !important;
  color: white !important;
}
.admin-training-landing-green-btn.ant-btn:hover,
.admin-training-landing-green-btn.ant-btn:active,
.admin-training-landing-green-btn.ant-btn:focus {
  border: none !important;
  background-color: #4a8a5d !important;
  color: white !important;
}
.admin-training-landing-red-btn {
  border: none !important;
  background-color: #e19387 !important;
  color: white !important;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  border-radius: 7px;
}
.admin-training-landing-red-border-btn {
  background: #ffffff !important;
  border: 1px solid #e1644c !important;
  border-radius: 7px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #e1644c !important;
}
.admin-training-landing-light-green-btn {
  background: #a0d29b !important;
  border-radius: 7px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff !important;
}
.admin-training-tabs1.ant-tabs.ant-tabs-line
  .ant-tabs-bar
  .ant-tabs-tab-active.ant-tabs-tab {
  background: #3a77a9;
  color: white;
  font-family: "Proxima Nova";
  font-size: 14px;
  height: 30px;
  width: 137px;
  text-align: center;
  line-height: 30px;
  border-radius: 7px 7px 0px 0px;
  padding: 0px;
  margin-right: 5px;
}

.admin-training-tabs1.ant-tabs.ant-tabs-line .ant-tabs-bar .ant-tabs-tab {
  background: #d9d9d9;
  color: white;
  font-family: "Proxima Nova";
  font-size: 14px;
  height: 30px;
  width: 137px;
  text-align: center;
  line-height: 30px;
  border-radius: 7px 7px 0px 0px;
  padding: 0px;
  margin-right: 5px;
}
.admin-training-tabs1.ant-tabs.ant-tabs-line
  .ant-tabs-bar
  .ant-tabs-nav-container {
  height: 30px;
}
.admin-training-tabs1 .ant-tabs-ink-bar {
  color: transparent;
  background-color: transparent;
}
.admin-training-tabs li {
  background: #d9d9d9;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 30px;
  width: 137px;
  text-align: center;
  line-height: 30px;
  border-radius: 7px 7px 0px 0px;
  margin-right: 5px;
  display: inline-block;
  color: white;
  cursor: pointer;
}
.admin-training-tabs li a {
  color: white;
}
.admin-training-breadcrumb.ant-breadcrumb {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  margin-bottom: 30px;
}
.admin-training-breadcrumb-content {
  cursor: pointer;
}
.admin-training-breadcrumb-content:hover {
  color: #40a9ff;
}
.admin-training-switch.ant-switch {
  background-color: #f27c59;
  background-image: none !important;
}
.admin-training-switch.ant-switch-checked {
  background-color: #9fd19a;
}
.admin-training-table {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}
.admin-training-table .admin-training-table-striped td {
  background-color: rgba(211, 228, 206, 0.06);
}
.admin-training-table .ant-table-thead > tr > th {
  background: transparent;
}
.admin-training-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}
.admin-training-table
  .ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  color: #262626;
  letter-spacing: 0.04em;
}
.admin-training-table
  .ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  color: #000;
  letter-spacing: 0.04em;
}
.admin-modal-title {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: block;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: #1a1818;
  margin-bottom: 30px;
}
.admin-modal-content {
  margin-left: 20px;
  margin-right: 20px;
}
.admin-modal-content .ant-form-item {
  margin-bottom: 8px;
}
.admin-modal-click-link-text {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #f27c59;
  cursor: pointer !important;
}
.admin-training-landing-deep-red-btn {
  border: none !important;
  background-color: #f27c59 !important;
  color: white !important;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  border-radius: 7px;
}
.admin-modal-content .admin-training-landing-deep-red-btn {
  margin-top: 20px;
  width: 48%;
}
.admin-modal-content .admin-training-landing-light-green-btn {
  margin-top: 20px;
  margin-left: 8px;
  width: 48%;
}
.admin-training-table
  .ant-table.ant-table-middle
  .ant-table-thead
  .ant-table-column-sorters {
  padding: 0px;
}
.admin-training-switch.ant-switch.ant-switch-disabled {
  background: #8c8c8c;
}
.admin-training-switch.ant-switch.ant-switch-disabled::after {
  opacity: 0.5;
}
.admin-training-active-green {
  width: 18px;
  height: 18px;
  border-radius: 12px;
  background: #9fd19a;
}
.admin-training-deactive-red {
  width: 18px;
  height: 18px;
  border-radius: 12px;
  background: #f27c59;
}
.admin-training-deactive-gray {
  width: 18px;
  height: 18px;
  border-radius: 12px;
  background: #8c8c8c;
}
.admin-training-sort-gray {
  color: #8c8c8c !important;
}
.admin-training-sort-active {
  color: #3977a9 !important;
}
.admin-brand-url {
  cursor: pointer;
}
.admin-brand-url:hover {
  text-decoration: underline;
}
</style>
