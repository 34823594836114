<template>
  <div class="edit-products-modal__main">
    <a-modal
      v-model="editProductVisible"
      width="990px"
      :footer="null"
      :title="null"
      :closable="false"
      :mask-closable="false"
    >
      <div class="modal-main-edit-product">
        <p class="edit-product__title-text">Product</p>
        <table class="table is-fullwidth">
          <thead>
            <th class="retailer-order-table__head">{{ $t("Retailers.RetailerOrder.sKU") }}</th>
            <th class="retailer-order-table__head">Name</th>
            <th class="retailer-order-table__head">{{ $t("Retailers.RetailerOrder.quantity") }}</th>
            <th class="retailer-order-table__head">{{ $t("Retailers.RetailerOrder.testers") }}</th>
            <th class="retailer-order-table__head">{{ $t("Retailers.RetailerOrder.mSRP") }}</th>
            <th class="retailer-order-table__head">
              {{ $t("Retailers.RetailerOrder.unitPrice") }}
            </th>
            <th class="retailer-order-table__head">
              {{ $t("Retailers.RetailerOrder.amount") }}
            </th>
            <!--            <th v-if="isProductEditable" class="retailer-order-table__head">-->
            <!--              {{ $t("Retailers.RetailerOrder.sKU") }}-->
            <!--            </th>-->
            <th v-if="isBrand || isAdmin" class="retailer-order-table__head">Notes</th>
          </thead>
          <tbody v-for="(item, index) in productList" :key="index">
            <tr v-if="item.quantityType && item.quantityType.id === 'purchase'">
              <td class="retailer-order-table__cell">{{ item.product && item.product.sku }}</td>
              <td class="retailer-order-table__cell">{{ item.product && item.product.name }}</td>
              <td class="retailer-order-table__cell">
                <a-input-number
                  v-model="item.adjustedQuantity"
                  :disabled="!item.canInput"
                  :max="checkProductItemStock() && item.stockTotal ? item.stockTotal : Infinity"
                  :min="0"
                  @change="updateProduct(item)"
                />
                <p
                  v-if="
                    checkProductItemStock() &&
                      item.stockTotal < Number(item.adjustedQuantity) + Number(item.editTesterValue)
                  "
                  style="color: #F5222D;width: 100px"
                >
                  <a-icon type="exclamation-circle" theme="filled" />
                  Only {{ item.stockTotal }} in Stock, Edit Quantity or Delete
                </p>
              </td>
              <td class="retailer-order-table__cell">
                <a-input-number
                  v-model="item.editTesterValue"
                  :disabled="!item.canInput"
                  :min="0"
                  :max="checkProductItemStock() && item.stockTotal ? item.stockTotal : Infinity"
                  @change="updateTesterProduct(item)"
                />
                <p
                  v-if="
                    checkProductItemStock() &&
                      item.stockTotal < Number(item.adjustedQuantity) + Number(item.editTesterValue)
                  "
                  style="color: #F5222D;width: 100px"
                >
                  <a-icon type="exclamation-circle" theme="filled" />
                  Only {{ item.stockTotal }} in Stock, Edit Tester or Delete
                </p>
              </td>
              <td class="retailer-order-table__cell">{{ priceFormat(item.recordedMsrpUsd) }}</td>
              <td class="retailer-order-table__cell">
                {{ priceFormat(item.calculatedWholesalePriceUsd) }}
              </td>
              <!--              <td class="retailer-order-table__cell">{{priceFormat(item.calculatedWholesaleCostUsd)}}</td>-->
              <td class="retailer-order-table__cell">
                {{
                  priceFormat(
                    (item.adjustedQuantity ? item.adjustedQuantity : item.quantity) *
                      item.calculatedWholesalePriceUsd
                  )
                }}
              </td>
              <td v-if="isBrand || isAdmin" class="retailer-order-table__cell">
                <a-select
                  v-model="item.noteType.id"
                  style="width: 150px"
                  placeholder="value"
                  @change="updateTesterAndQuantity(item)"
                >
                  <a-select-option v-for="item in notesList" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="button-apply-box">
          <span
            class="edit-product__apply-button_modal"
            :style="{ background: btnColor, marginRight: '15px' }"
            @click="closeProduct"
            >Close</span
          >
          <span
            class="edit-product__apply-button_modal"
            :style="{ background: btnColor }"
            @click="applyProduct"
            >Apply</span
          >
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import constants from "@/config.js"
import { priceFormat } from "@/utils/validate"

export default {
  name: "EditProductModal",
  props: {
    purchaseDetails: { type: Array, default: () => [] },
    buttonColor: { type: String }
  },
  data() {
    return {
      priceFormat,
      editProductVisible: false,
      saveStorageList: [],
      order: {},
      canNotApply: false,
      color: null
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    notesList() {
      return this.$store.getters["reference/orderItemNoteType"]
    },
    btnColor() {
      return this.color ? this.color : this.buttonColor
    },
    productList() {
      let purchaseDetailsList = JSON.parse(JSON.stringify(this.purchaseDetails))
      return purchaseDetailsList.map(item => {
        if (!item.noteType) {
          item.noteType = {
            id: ""
          }
        }
        item.adjustedQuantity =
          item.adjustedQuantity || item.adjustedQuantity === 0
            ? Number(item.adjustedQuantity)
            : Number(item.quantity)
        item.editTesterValue =
          item.adjustedTestersQuantity || item.adjustedTestersQuantity === 0
            ? Number(item.adjustedTestersQuantity || 0)
            : Number(item.testersquantity || 0)
        item.canInput = true
        item.stockTotal =
          (item.stock ? item.stock : 0) + item.adjustedQuantity + item.editTesterValue
        if (item.noteType.id === "oos") {
          item.canInput = false
          item.adjustedQuantity = 0
        }
        return item
      })
    }
  },
  methods: {
    openDialog(order) {
      this.editProductVisible = true
      this.saveStorageList = this.productList
      this.order = order
    },
    updateTesterAndQuantity(value) {
      let editName
      if (this.isAdmin) editName = "landing"
      if (this.isBrand) editName = "brand"
      if (this.isRetailer) editName = "retailer"
      value.adjusterByClientType = {
        id: editName
      }
      this.updateProduct(value)
      this.updateTesterProduct(value)
    },
    updateProduct(value) {
      let editName
      if (this.isAdmin) editName = "landing"
      if (this.isBrand) editName = "brand"
      if (this.isRetailer) editName = "retailer"
      value.adjusterByClientType = {
        id: editName
      }
      if (
        this.checkProductItemStock() &&
        value.stock &&
        value.adjustedQuantity > value.stockTotal
      ) {
        this.canNotApply = true
      } else {
        this.canNotApply = false
      }
      value.calculatedWholesaleCostUsd = value.adjustedQuantity
        ? value.adjustedQuantity * value.calculatedWholesalePriceUsd
        : value.quantity * value.calculatedWholesalePriceUsd
      if (
        this.saveStorageList.some(
          l => l.product.id === value.product.id && l.quantityType.id === "purchase"
        )
      ) {
        this.saveStorageList = this.saveStorageList.map(item => {
          if (item.product.id === value.product.id && item.quantityType.id === "purchase") {
            if (value.noteType.id === "oos") {
              // value.quantity = 0
              value.adjustedQuantity = 0
              value.calculatedWholesaleCostUsd = 0
              value.canInput = false
            } else {
              value.canInput = true
              value.adjustedQuantity =
                value.adjustedQuantity || value.adjustedQuantity === 0
                  ? value.adjustedQuantity
                  : value.quantity
            }
            item = { ...value }
          }
          return item
        })
      } else {
        this.saveStorageList.push(value)
      }
      if (
        this.checkProductItemStock() &&
        this.saveStorageList.filter(item => Number(item.adjustedQuantity) > Number(item.stockTotal))
          .length !== 0
      ) {
        this.color = "#bfbfbf"
      } else {
        this.color = this.buttonColor
      }
    },
    updateTesterProduct(value) {
      let editName
      if (this.isAdmin) editName = "landing"
      if (this.isBrand) editName = "brand"
      if (this.isRetailer) editName = "retailer"
      value.adjusterByClientType = {
        id: editName
      }
      const params = {
        ...value,
        quantity: value.testersquantity || 0,
        adjustedQuantity: value.editTesterValue,
        quantityType: {
          id: "testers"
        }
      }
      if (
        this.saveStorageList.some(
          l => l.product.id === params.product.id && l.quantityType.id === "testers"
        )
      ) {
        this.saveStorageList = this.saveStorageList.map(item => {
          if (item.product.id === value.product.id && item.quantityType.id === "testers") {
            item = { ...params }
            if (value.noteType.id === "oos") {
              // value.quantity = 0
              item.adjustedQuantity = 0
              item.calculatedWholesaleCostUsd = 0
              item.canInput = false
            } else {
              item.canInput = true
              item.adjustedQuantity =
                params.adjustedQuantity || params.adjustedQuantity === 0
                  ? params.adjustedQuantity
                  : params.quantity
            }
          }
          return item
        })
      } else {
        this.saveStorageList.push(params)
      }
      if (
        this.saveStorageList.filter(item => Number(item.adjustedQuantity) > Number(item.stockTotal))
          .length !== 0
      ) {
        this.color = "#bfbfbf"
      } else {
        this.color = this.buttonColor
      }
    },
    applyProduct() {
      if (this.color === "#bfbfbf") {
        return
      }
      let orderMap = new Map()
      if (this.canNotApply) {
        return
      }
      this.editProductVisible = false
      if (this.order.items && this.order.items.length === 0) {
        return
      }
      this.order.items.forEach(item => {
        orderMap.set(`${item.product.id}${item.quantityType.id}`, item)
      })
      this.saveStorageList.forEach(value => {
        orderMap.set(`${value.product.id}${value.quantityType.id}`, value)
      })
      let saveList = []
      orderMap.forEach(value => {
        saveList.push(value)
      })
      this.$emit("getProductEditList", saveList)
    },
    closeProduct() {
      this.editProductVisible = false
      this.saveStorageList = []
      this.canNotApply = false
    },
    checkProductItemStock() {
      if (!(this.order && this.order.retailer && this.order.retailer.id)) {
        return false
      }
      const retailerLogistic = this.brand.logisticTerms.filter(
        item => item.retailerId === this.order.retailer.id
      )
      const retailerStardand = this.brand.logisticTerms.filter(
        item => item.retailerId === "*" && item.brandId === this.brand.id
      )
      return (
        (retailerStardand && retailerStardand.length !== 0 && retailerStardand[0].enabled) ||
        (retailerLogistic && retailerLogistic.length !== 0)
      )
    }
  }
}
</script>

<style lang="scss">
.edit-products-modal__main {
  .modal-main-edit-product {
  }
}
.edit-product__title-text {
  font-size: 24px;
  color: #000000;
  font-weight: 400;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
.button-apply-box {
  text-align: right;
}
.edit-product__apply-button_modal {
  //width: 73px;
  padding: 5px 16px;
  text-align: center;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}
</style>
