<template>
  <a-row class="admin-retailer-search" style="padding: 0px;">
    <a-col :span="24" class="admin-console-brand-retailer-product-table">
      <div style="margin-bottom: 4px;">
        <a-button icon="arrow-left" @click="goBack" />
        <a-button
          class="admin-console-product-search-result__download-btn"
          style="float: right; margin: 0px;"
          @click="clickToDownloadData"
        >
          {{ $t("Admins.AdminInventory.csv") }}
          <a-icon type="download" />
        </a-button>
      </div>
      <div>
        <div class="admin-product-search__left-title">Best Seller</div>
        <div style="float: right; margin-bottom: 6px;" class="admin-product-search__right-view">
          View
          <a-select
            v-model="bestSellerSelect"
            class="admin-product-search__dropdown-view"
            @change="handleChange"
          >
            <a-select-option value="30">
              Last 30 days
            </a-select-option>
            <a-select-option value="90">
              Last 90 days
            </a-select-option>
            <a-select-option value="365">
              Last 365 days
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        bordered
        :data-source="bestSellerResult.list"
        :columns="columns"
        :pagination="false"
        :row-key="record => record.privateId"
      >
        <template slot="productNameValue" slot-scope="text, record">
          <a-tooltip v-if="text && text.length > 20" placement="bottom" :title="text">
            <span class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
              text
            }}</span>
          </a-tooltip>
          <span v-else class="admin-console-brand-link-click" @click="clickToProductPage(record)">{{
            text
          }}</span>
        </template>
        <template slot="msrp" slot-scope="text">
          <span>{{ priceFormat(text) }}</span>
        </template>
        <template slot="brandName">
          <span> Brand Name </span>
          <a-icon type="caret-up" :rotate="isSortAsc ? 0 : 180" @click="doSortByBrandName" />
        </template>
        <template slot="productName">
          <span>Product Name </span>
          <a-icon
            type="caret-up"
            :rotate="isBestProductSortAsc ? 0 : 180"
            @click="doSortByProductBest"
          />
        </template>
        <template slot="sku">
          <span>SKU </span>
          <a-icon type="caret-up" :rotate="isBestSkuSortAsc ? 0 : 180" @click="doSortBySkuBest" />
        </template>
        <template slot="barCode">
          <span>UPC </span>
          <a-icon type="caret-up" :rotate="isBestUpcSortAsc ? 0 : 180" @click="doSortByUpcBest" />
        </template>
        <template slot="size">
          <span>Size </span>
          <a-icon type="caret-up" :rotate="isBestSizeSortAsc ? 0 : 180" @click="doSortBySizeBest" />
        </template>
        <template slot="category">
          <span>Category </span>
          <a-icon
            type="caret-up"
            :rotate="isBestCategorySortAsc ? 0 : 180"
            @click="doSortByCategoryBest"
          />
        </template>
        <template slot="msrpSort">
          <span>MSRP</span>
          <a-icon type="caret-up" :rotate="isBestMsrpSortAsc ? 0 : 180" @click="doSortByMsrpBest" />
        </template>
        <template slot="sizeColumn" slot-scope="text, record">
          <span> {{ `${text} ${record.sizeUnitID}` }}</span>
        </template>
      </a-table>
      <a-pagination
        class="inventoryPagination"
        style="margin-top: 10px; float: right;"
        :default-page-size="pageSize"
        :default-current="1"
        :total="bestSellerResult.total"
        size="small"
        :show-quick-jumper="true"
        @change="getBestSellerData"
      />
    </a-col>
  </a-row>
</template>

<script>
import { priceFormat, downloadCsvDataStr } from "@/utils/validate"
export default {
  data() {
    return {
      priceFormat,
      bestSellerSelect: "30",
      pageSize: 50,
      sortBestParam: {},
      isSortAsc: false,
      isBestMsrpSortAsc: false,
      isBestCategorySortAsc: false,
      isBestSizeSortAsc: false,
      isBestUpcSortAsc: false,
      isBestProductSortAsc: false,
      isBestSkuSortAsc: false,
      columns: [
        {
          dataIndex: "rank",
          title: "#",
          width: "50px"
        },
        {
          dataIndex: "brandName",
          slots: { title: "brandName" },
          width: "15%"
          // scopedSlots: { customRender: 'name' },
        },
        {
          // title: "Product Name",
          dataIndex: "name",
          width: "24%",
          ellipsis: true,
          slots: { title: "productName" },
          scopedSlots: { customRender: "productNameValue" }
        },
        {
          // title: "SKU",
          dataIndex: "sku",
          slots: { title: "sku" },
          width: "10%"
        },
        {
          // title: "UPC",
          dataIndex: "barCode",
          slots: { title: "barCode" },
          width: "15%"
        },
        {
          //title: "Size",
          dataIndex: "size",
          slots: { title: "size" },
          scopedSlots: { customRender: "sizeColumn" },
          width: "10%"
        },
        {
          //title: "Category",
          dataIndex: "category",
          slots: { title: "category" },
          width: "14%"
        },
        {
          //title: "MSRP",
          dataIndex: "msrpUsd",
          width: "10%",
          slots: { title: "msrpSort" },
          scopedSlots: { customRender: "msrp" }
        }
      ],
      selectedRecords: new Set()
    }
  },
  computed: {
    bestSellerResult() {
      return this.$store.getters["adminConsole/bestSellers"]
    }
  },
  created() {
    this.bestSellerSelect = this.$route.params.searchParam
    if (this.bestSellerSelect === undefined) {
      this.bestSellerSelect = this.$store.getters["adminConsole/bestSellersSelect"]
    }
    this.getBestSellerData(1)
  },
  methods: {
    doSortByBrandName() {
      this.isSortAsc = !this.isSortAsc
      this.sortBestParam = {
        "_sort-by": "brand_name",
        "_order-by": this.isSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData(1)
    },
    doSortByProductBest() {
      this.isBestProductSortAsc = !this.isBestProductSortAsc
      this.sortBestParam = {
        "_sort-by": "name",
        "_order-by": this.isBestProductSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData(1)
    },
    doSortBySkuBest() {
      this.isBestSkuSortAsc = !this.isBestSkuSortAsc
      this.sortBestParam = {
        "_sort-by": "sku",
        "_order-by": this.isBestSkuSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData(1)
    },
    doSortByUpcBest() {
      this.isBestUpcSortAsc = !this.isBestUpcSortAsc
      this.sortBestParam = {
        "_sort-by": "bar_code",
        "_order-by": this.isBestUpcSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData(1)
    },
    doSortBySizeBest() {
      this.isBestSizeSortAsc = !this.isBestSizeSortAsc
      this.sortBestParam = {
        "_sort-by": "size",
        "_order-by": this.isBestSizeSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData(1)
    },
    doSortByCategoryBest() {
      this.isBestCategorySortAsc = !this.isBestCategorySortAsc
      this.sortBestParam = {
        "_sort-by": "category_name",
        "_order-by": this.isBestCategorySortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData(1)
    },
    doSortByMsrpBest() {
      this.isBestMsrpSortAsc = !this.isBestMsrpSortAsc
      this.sortBestParam = {
        "_sort-by": " msrp_usd",
        "_order-by": this.isBestMsrpSortAsc ? "PREVIOUS" : "NEXT"
      }
      this.getBestSellerData(1)
    },
    async getBestSellerData(page) {
      await this.$store.dispatch("adminConsole/fetchBestSellers", {
        ...this.sortBestParam,
        _limit: this.pageSize,
        _index: page,
        "last-days": this.bestSellerSelect
      })
    },
    handleChange() {
      this.$store.commit("adminConsole/SET_BEST_SELLER_PARAM", this.bestSellerSelect)
      this.getBestSellerData(1)
    },
    clickToProductPage(item) {
      this.$router.push({
        name: "adminProduct",
        params: {
          id: item.id
        }
      })
    },
    async clickToDownloadData() {
      let page = this.bestSellerResult ? this.bestSellerResult.index : 1
      await this.$store
        .dispatch("adminConsole/downloadBestSellers", {
          ...this.sortBestParam,
          _limit: this.pageSize,
          _index: page,
          "last-days": this.bestSellerSelect
        })
        .then(result => {
          let fileName = `product_best_sellers_result.csv`
          this.download(result, fileName)
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    async download(data, fileName) {
      await downloadCsvDataStr(data, fileName)
        .then(() => {
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
