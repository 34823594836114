<template>
  <div>
    <div v-for="(memberItem, index) in member" :key="index">
      <a-row
        v-if="
          memberItem && memberItem.membershipStatus && memberItem.membershipStatus.name !== 'Delete'
        "
        :key="index"
        v-click-outside="onClickOutsideMember"
        class="membersList"
      >
        <a-col :span="5" class="user-name__block">
          <div class="user-name-status">
            {{ profileIcon(memberItem.user.givenName, memberItem.user.familyName) }}
          </div>
          <p class="table-item-information-name">
            {{ `${memberItem.user.givenName} ${memberItem.user.familyName}` }}
          </p>
        </a-col>
        <a-col :span="5">
          <p class="table-item-information-name">
            {{ memberItem.user.email }}
          </p>
        </a-col>
        <a-col :span="4">
          <p class="table-item-information-name text-padding-left">
            {{ memberItem.user.jobTitle }}
          </p>
        </a-col>
        <a-col :span="5">
          <p class="table-item-information-name col-phone">
            {{ memberItem.user.phoneNumber }}
          </p>
        </a-col>
        <a-col :span="3">
          <p class="table-item-information-name">
            {{ memberItem.membershipStatus.name }}
          </p>
        </a-col>
        <a-col
          v-if="
            (brandType !== 'basic' && isOwner && memberItem.role.id !== 'owner') ||
              (isOwner && isRetailer && memberItem.role.id !== 'owner')
          "
          :span="2"
          class="membersList__table-cell"
        >
          <a-dropdown>
            <a-icon type="more" @click="e => e.preventDefault()" />
            <a-menu slot="overlay">
              <a-menu-item
                v-if="
                  memberItem.membershipStatus.name === 'Removed' ||
                    memberItem.membershipStatus.name === 'Declined'
                "
                @click="inviteMemberAgain(memberItem)"
              >
                <span class="admin-clients__dropdown-button">
                  {{ $t("Components.Brands.Settings.MemberItem.inviteAgain") }}
                </span>
              </a-menu-item>
              <a-menu-item
                v-if="memberItem.membershipStatus.name === 'Added'"
                @click="changeOwner(memberItem)"
              >
                <span class="admin-clients__dropdown-button">
                  {{ $t("Components.Brands.Settings.MemberItem.changeOwner") }}
                </span>
              </a-menu-item>
              <a-menu-item
                v-if="memberItem.membershipStatus.name !== 'Removed'"
                @click="doRemoveAction(memberItem)"
              >
                <span class="admin-clients__dropdown-button">
                  {{ $t("Components.Brands.Settings.MemberItem.remove") }}
                </span>
              </a-menu-item>
              <a-menu-item
                v-if="memberItem.membershipStatus.name !== 'Added'"
                @click="doDeleteAction(memberItem)"
              >
                <span class="admin-clients__dropdown-button">
                  {{ $t("Components.Brands.Settings.MemberItem.delete") }}
                </span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-col>
        <a-col v-if="memberItem.role.id === 'owner'" :span="2" class="membersList__table-cell">
          <a class="owner">
            {{ $t("Components.Brands.Settings.MemberItem.owner") }}
          </a>
        </a-col>
        <a-col
          v-if="!isOwner && brandType !== 'basic' && memberItem.role.id != 'owner'"
          :span="2"
          class="membersList__table-cell"
        >
        </a-col>
        <a-col
          v-if="
            (brandType === 'basic' || (isRetailer && !isOwner)) && memberItem.role.id != 'owner'
          "
          :span="2"
          class="membersList__table-cell"
        >
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import constants from "@/config.js"
import { Row, Col, Dropdown, Menu, Icon } from "ant-design-vue"

export default {
  components: {
    "a-row": Row,
    "a-col": Col,
    "a-dropdown": Dropdown,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-icon": Icon
  },
  props: {
    member: { type: Array, default: () => [] },
    isOwner: { type: Boolean, default: false }
  },
  data() {
    return {
      memberItem: undefined,
      showMemberActions: false,
      memberAdded: false,
      showText: ""
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandType() {
      return (
        this.brand &&
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    },
    retailer() {
      if (this.$route.name == "retailerSettings" || this.$route.name == "adminRetailerSettings") {
        return this.$store.getters["retailer/retailer"]
      }
      return {}
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    }
  },
  methods: {
    onClickOutsideMember() {
      this.showMemberActions = false
    },
    profileIcon(firstName, lastName) {
      if (firstName && lastName) {
        let result = `${firstName.charAt(0)}${lastName.charAt(0)}`
        return result
      }
      return " "
    },
    removeMemberObject() {
      return {
        membershipStatus: {
          id: "removed",
          name: "Removed"
        },
        user: {
          id: this.memberItem.user.id
        }
      }
    },
    deleteMemberObject() {
      return {
        membershipStatus: {
          id: "delete",
          name: "Delete"
        },
        user: {
          id: this.memberItem.user.id
        }
      }
    },
    reInviteMemberObject() {
      return {
        membershipStatus: {
          id: "invited",
          name: "Invited"
        },
        user: {
          id: this.memberItem.user.id
        }
      }
    },
    addedMemberRootObject() {
      return {
        user: {
          givenName: this.memberItem.user.givenName,
          familyName: this.memberItem.user.familyName,
          email: this.memberItem.user.email
        }
      }
    },
    doRemoveAction(memberItem) {
      this.memberItem = memberItem
      this.updateMemberStatus("removed", this.removeMemberObject())
    },
    async updateMemberStatus(status, member) {
      try {
        this.showMemberActions = false
        if (this.memberItem && this.memberItem.membershipStatus.id != status && this.isRetailer) {
          let params = {}
          await this.$store.dispatch("retailer/updateMemberStatus", { member, params })
          this.memberItem.membershipStatus = this.$store.getters["retailer/memberStatus"]
        } else if (
          this.memberItem &&
          this.memberItem.membershipStatus.id != status &&
          this.isBrand
        ) {
          let params = {}
          await this.$store.dispatch("brand/updateMemberStatus", { member, params })
          this.memberItem.membershipStatus = this.$store.getters["brand/memberStatus"]
        }
      } catch (error) {
        console.error(error)
      }
    },
    doDeleteAction(memberItem) {
      this.memberItem = memberItem
      this.updateMemberStatus("delete", this.deleteMemberObject())
    },
    async changeOwner(data) {
      console.log("change owner")
      console.log(data)
      let param = {
        user: {
          id: data.user.id
        },
        role: {
          id: "owner",
          name: "Owner"
        }
      }
      this.$store
        .dispatch("brand/changeOwner", param)
        .then(() => {
          let data = this.$store.getters["brand/changeOwner"]
          this.$emit("refreshData", data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async inviteMemberAgain(item) {
      this.memberItem = item
      try {
        this.showMemberActions = false
        if (
          this.memberItem &&
          (this.memberItem.membershipStatus.id == "removed" ||
            this.memberItem.membershipStatus.id == "declined") &&
          this.isRetailer
        ) {
          let params = {}
          let member = this.reInviteMemberObject()
          await this.$store.dispatch("retailer/updateMemberStatus", { member, params })
          this.memberItem.membershipStatus = this.$store.getters["retailer/memberStatus"]
          this.memberAdded = true
        } else if (
          this.memberItem &&
          (this.memberItem.membershipStatus.id == "removed" ||
            this.memberItem.membershipStatus.id == "declined") &&
          this.isBrand
        ) {
          let params = {}
          let member = this.reInviteMemberObject()
          await this.$store.dispatch("brand/updateMemberStatus", { member, params })
          this.memberItem.membershipStatus = this.$store.getters["brand/memberStatus"]
          this.memberAdded = true
        } else {
          this.memberAdded = false
        }
        if (this.memberAdded) {
          this.$emit("addedMember", this.addedMemberRootObject())
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="sass">

.owner
  font-family: Proxima
  color: black
  font-weight: bold

.membersList__table-cell
  color: #333333 !important
  font-size: 15px
  font-size: 14px
  font-weight: 500
  padding-top: 1px
  text-align: center
  padding-bottom: 10px
  position: relative

.membersList__dropdown
  position: absolute
  width: 135px
  height: auto
  top: 2px
  right: 20px
  z-index: 100
  background-color: #ffffff
  border: 1px solid #DADADA


.table-body
  padding: 6px 35px
  border-bottom: 1px solid #ddd
  display: block !important
  align-items: center
  .membersList
    /*flex: 1!important*/
    margin-bottom: 15px
    width: 100%

  .user-name__block
    display: flex
    align-items: center
    font-family: "Proxima"

    .user-name-status
      width: 31px
      height: 31px
      background-color: #e3d7d7
      border: 1px solid #979797
      color: #535050
      padding: 2px
      border-radius: 100%
      font-size: 16px
      font-weight: 300
      margin-right: 5px
      display: flex
      align-items: center
      justify-content: center

    .brand-settings__account-information_name
      color: #333
      font-size: 14px
      white-space: normal
      word-break: break-all
      margin-bottom: 0px !important
.table-item-information-name
  font-family: Proxima
  color: #333
  font-size: 14px
  white-space: normal
  direction: ltr
  word-break: break-all
  margin-right: 4px
  margin-bottom: 0px !important
.col-phone
  text-align: left
.text-padding-left
  padding-left: 10px
</style>
