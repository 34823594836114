import axios from "@/api/axios";

export default {
  /**
     * _limit: 3
     _page: 1
     productId: mHutdtIcSYC7NWnKERK4WQ
     商品id
     expirationSort: false  true倒序 false正序
     remaining: true true查询库存中有库存的商品
     action
     字典数据中的数据 (action + expirationSort 查出view By EXpiartion)
     brandId: 品牌id
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
  getProductInventoryHistory(params) {
    return axios.get("/getInventoryHistory", { params });
  },
  /**
     * _limit: 3
     _page: 1
     brandId: fgD-bIXrQ5KXZdT6bxo-HA
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
  getBrandReport(params) {
    return axios.get("/getBrandInventoryReport", { params });
  },
  /**
     * _limit: 3
     _page: 1
     brandId: fgD-bIXrQ5KXZdT6bxo-HA
     productId: 2W58S3BSQU-D75U2xBk3ng
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
  getProductReport(params) {
    return axios.get("/getProductInventoryReport", { params });
  },
  /**
     * _limit: 3
     _page: 1
     productId: mHutdtIcSYC7NWnKERK4WQ
     expirationSort: false
     remaining: true
     action
     brandId
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
  downloadInventory(params) {
    return axios.get("/inventoryHistoryToCSV", {
      params: { responseType: "blob", ...params },
    });
  },
  /**
     * _limit: 3
     _page: 1
     brandId: fgD-bIXrQ5KXZdT6bxo-HA
     productId: 2W58S3BSQU-D75U2xBk3ng
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
  downloadProductReport(params) {
    return axios.get("/productInventoryReportToCsv", {
      params: { responseType: "blob", ...params },
    });
  },
  /**
     * _limit: 3
     _page: 1
     brandId: fgD-bIXrQ5KXZdT6bxo-HA
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
  downloadBrandReport(params) {
    return axios.get("/BrandInventoryReportToCSV", {
      params: { responseType: "blob", ...params },
    });
  },

  addReduceInventory(inventory) {
    return axios.post("/create/inventoryhistory", JSON.stringify(inventory));
  },
  updateInventory(inventory) {
    return axios.post("/update/inventoryhistory", JSON.stringify(inventory));
  },
  uploadFile(param) {
    return axios.postForm("/inventory/upload", param);
  },
};
