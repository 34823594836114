<template>
  <div class="clean-pre-check-list">
    <p class="clean-pre-check-list__title">{{ $t("Retailers.RetailerProfile.cleanPreCheck") }}</p>
    <p class="clean-pre-check-list__sub-title">
      {{
        isRetailer
          ? $t("Retailers.RetailerProfile.cleanPreCheckDesc")
          : $t("Components.Brands.CleanPreCheck.cleanPreCheckDesc")
      }}
    </p>
    <a-row style="margin-bottom: 20px;">
      <a-col :span="8">
        <div class="clean-pre-check-list__type-area">
          <div class="clean-pre-check-list__type-area-left">
            <p class="clean-pre-check-list__type-area-title">
              {{ $t("Retailers.RetailerProfile.cleanPercentTitle") }}
            </p>
            <p class="clean-pre-check-list__type-area-desc">
              {{
                isRetailer
                  ? $t("Retailers.RetailerProfile.cleanPercent")
                  : $t("Components.Brands.CleanPreCheck.clean100Desc")
              }}
            </p>
          </div>
          <div class="clean-pre-check-list__type-right_100">
            <img src="@/assets/img/clean-pre-check/yellow.svg" />
          </div>
        </div>
      </a-col>
      <a-col :span="8" style="text-align: center;">
        <div class="clean-pre-check-list__type-area" style="margin: 0 auto;">
          <div class="clean-pre-check-list__type-area-left">
            <p class="clean-pre-check-list__type-area-title">
              {{ $t("Retailers.RetailerProfile.cleanPartialTitle") }}
            </p>
            <p class="clean-pre-check-list__type-area-desc">
              {{
                isRetailer
                  ? $t("Retailers.RetailerProfile.cleanPartialDesc")
                  : $t("Components.Brands.CleanPreCheck.cleanPartialDesc")
              }}
            </p>
          </div>
          <div class="clean-pre-check-list__type-right_partial">
            <img src="@/assets/img/clean-pre-check/green_circle.svg" />
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="clean-pre-check-list__type-area" style="float: right">
          <div class="clean-pre-check-list__type-area-left">
            <p class="clean-pre-check-list__type-area-title">
              {{ $t("Retailers.RetailerProfile.cleanNoTitle") }}
            </p>
            <p class="clean-pre-check-list__type-area-desc">
              {{
                isRetailer
                  ? $t("Retailers.RetailerProfile.cleanNoDesc")
                  : $t("Components.Brands.CleanPreCheck.cleanNotDesc")
              }}
            </p>
          </div>
          <div class="clean-pre-check-list__type-right_no">
            <img src="@/assets/img/clean-pre-check/white_circel.svg" />
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row id="cleanPreCheckCollapse">
      <a-collapse v-model="activeKey" expand-icon-position="right" :bordered="false">
        <template #expandIcon="props">
          <a-icon
            type="down"
            :rotate="props.isActive ? 180 : 0"
            :class="{ 'clean-pre-check-list__panel-arrow-border': props.isActive }"
          />
        </template>
        <a-collapse-panel
          v-for="(item, index) in dataList.list"
          :key="`precheck_list_${index}`"
          :class="item.percent === 100 ? 'clp_p100' : item.percent >= 50 ? 'clp_p50' : 'clp_l50'"
        >
          <template #header>
            <a-row>
              <div
                style="float: left;"
                :class="{ 'clean-pre-check-list__panel-left': !item.logoAssetUrl }"
              >
                <img
                  v-if="item.logoAssetUrl"
                  class="clean-pre-check-list__panel-img"
                  :src="item.logoAssetUrl"
                  @click="clickToProfile(item)"
                />
              </div>
              <div class="clean-pre-check-list__panel-title" @click="clickToProfile(item)">
                {{ item.clientName }}
              </div>
              <div class="clean-pre-check-list__panel-subtitle">
                {{
                  $t("Components.Brands.CleanPreCheck.productFit", {
                    fitNumber: item.clearedProductsSize,
                    productNumber: item.totalProductsSize
                  })
                }}
              </div>
              <div
                class="clean-pre-check-list__panel-right"
                :class="{
                  'clean-pre-check-list__panel-right-100': item.percent === 100,
                  'clean-pre-check-list__panel-right-partial':
                    item.percent < 100 && item.percent >= 50,
                  'clean-pre-check-list__panel-right-no': item.percent < 50
                }"
              >
                <img
                  v-if="item.percent === 100"
                  src="@/assets/img/clean-pre-check/yellow_right.svg"
                />
                <img
                  v-else-if="item.percent < 100 && item.percent >= 50"
                  src="@/assets/img/clean-pre-check/green_circle_36.svg"
                />
                <img v-else src="@/assets/img/clean-pre-check/while_right.svg" />
              </div>
            </a-row>
          </template>
          <a-table
            :columns="columns"
            :data-source="item.products"
            :pagination="false"
            :scroll="item.totalProductsSize > 20 ? { y: 620 } : { y: false }"
            size="small"
            class="clean-pre-check-list__table-head"
            :row-key="record => record.id"
          >
            <template slot="name" slot-scope="text, record">
              <span
                class="clean-pre-check-list__table-content-name"
                @click="clickToProductInCatelog(record.id)"
              >
                <div class="clean-pre-check-list__table-product-mark">
                  <img
                    v-if="record.cleaned"
                    src="@/assets/img/clean-pre-check/product_yellow.svg"
                  />
                </div>
                {{ text }}
              </span>
            </template>
            <span slot="customTitle" class="clean-pre-check-list__table-title-left">
              <a-tooltip
                placement="left"
                :auto-adjust-overflow="false"
                overlay-class-name="cleanPreCheckTooltip"
              >
                <template slot="title">
                  <div>
                    <img
                      src="@/assets/img/clean-pre-check/product_yellow.svg"
                      class="clean-pre-check-list__tooltip-icon"
                    />
                    <span class="clean-pre-check-list__tooltip">{{
                      $t("Retailers.RetailerProfile.cleanToolTip")
                    }}</span>
                  </div>
                </template>
                <a-icon type="exclamation-circle" class="clean-pre-check-list__table-icon" />
              </a-tooltip>
              {{ $t("Components.Brands.CleanPreCheck.productName") }}
            </span>
            <span slot="msrpUsd" slot-scope="text" v-html="countPriceFormat(text)"></span>
            <template slot="footer">
              <div style="width: 100%;" @click="doClick(`precheck_list_${index}`)">
                <a-icon type="up" @click="doClick(`precheck_list_${index}`)" />
              </div>
            </template>
          </a-table>
        </a-collapse-panel>
      </a-collapse>
    </a-row>
    <a-row class="clean-pre-check-list__page-row">
      <a-pagination
        v-if="dataList.total > 25"
        size="small"
        show-quick-jumper
        :current="currentPage"
        :total="dataList.total"
        :default-page-size="25"
        :default-current="1"
        @change="changePage"
      >
      </a-pagination>
    </a-row>
    <a-modal
      id="productModal"
      v-model="isShowProductDialog"
      :footer="null"
      :destroy-on-close="true"
      :body-style="modalBodyStyle"
      width="810px"
    >
      <ProductCard v-if="!isProductLoading" :product="currentProdut" :is-need-add-cart="false" />
      <div v-else class="clean-pre-check-list__modal-spin-div">
        <a-spin style="margin: 0 auto;">
          <a-icon slot="indicator" type="loading" style="font-size: 56px; color: #4A8A5D;" spin />
        </a-spin>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Collapse, Row, Col, Pagination } from "ant-design-vue"
import ProductCard from "@/components/retailers/products/ProductCard"
export default {
  components: {
    "a-collapse": Collapse,
    "a-collapse-panel": Collapse.Panel,
    "a-row": Row,
    "a-col": Col,
    "a-pagination": Pagination,
    ProductCard
  },
  props: {
    isRetailer: { type: Boolean, default: () => true },
    dataList: { type: Object, default: () => {} }
  },
  data() {
    return {
      routerName: "",
      columns: [
        {
          dataIndex: "name",
          key: "name",
          width: 582,
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "name" },
          ellipsis: true
        },
        {
          dataIndex: "sku",
          key: "sku",
          title: this.$t("Components.Brands.CleanPreCheck.sku"),
          width: 143
        },
        {
          dataIndex: "msrpUsd",
          key: "msrpUsd",
          title: this.$t("Components.Brands.CleanPreCheck.msrp"),
          scopedSlots: { customRender: "msrpUsd" }
        }
      ],
      currentPage: 1,
      toolTipStyle: {
        "background-color": "#E5E5E5"
      },
      activeKey: [],
      currentProdut: undefined,
      isShowProductDialog: false,
      isProductLoading: false,
      modalBodyStyle: {
        padding: "0px"
      }
    }
  },
  watch: {
    dataList: {
      handler(newData) {
        if (newData) {
          console.log(this.dataList)
        }
      }
    },
    activeKey(key) {
      if (this.isRetailer) {
        localStorage.setItem("retailer-pre-check", key.map(item => item).join(","))
      } else {
        localStorage.setItem("brand-pre-check", key.map(item => item).join(","))
      }
    }
  },
  created() {
    this.routerName = this.$route.name
    let data = undefined
    if (this.isRetailer) {
      data = localStorage.getItem("retailer-pre-check")
    } else {
      data = localStorage.getItem("brand-pre-check")
    }
    if (data && data.length > 0) {
      this.activeKey = data.split(",")
    }
  },
  methods: {
    doClick(value) {
      let index = this.activeKey.indexOf(value)
      this.activeKey.splice(index, 1)
      this.$forceUpdate()
    },
    clickToProfile(item) {
      if (item.clientType === "Brand") {
        if (this.routerName === "adminRetailerCleanCheck") {
          this.$router.push({
            name: "adminRetailerBrandProfile",
            params: {
              brandId: item.clientId
            }
          })
        } else {
          this.$router.push({
            name: "retailerBrandProfile",
            params: {
              brandId: item.clientId
            }
          })
        }
      } else {
        if (this.routerName === "adminBrandCleanCheck") {
          this.$router.push({
            name: "adminBrandRetailerProfile",
            params: {
              retailerId: item.clientId
            }
          })
        } else {
          this.$router.push({
            name: "brandRetailerProfile",
            params: {
              retailerId: item.clientId
            }
          })
        }
      }
    },
    clickToProductInCatelog(productId) {
      this.isProductLoading = true
      this.isShowProductDialog = true
      this.$store.dispatch("product/getProduct", productId).then(() => {
        this.currentProdut = this.$store.getters["product/product"]
        this.isProductLoading = false
      })
    },
    countPriceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      let priceStr = numberFormat.format(price)
      if (price < 10) {
        let aa = priceStr.slice(0, 1) + "&ensp;&ensp;&ensp;&ensp;" + priceStr.slice(1)
        return aa
      } else if (price < 100) {
        return priceStr.slice(0, 1) + "&ensp;&ensp;&ensp;" + priceStr.slice(1)
      } else if (price < 1000) {
        return priceStr.slice(0, 1) + "&ensp;&ensp;" + priceStr.slice(1)
      }
      return priceStr
    },
    changePage(page) {
      let div_el = document.getElementsByClassName("ant-pagination-options-quick-jumper")
      let input_el = undefined
      if (div_el) {
        input_el = div_el.item(0)
      }
      if (page < 1 || page > this.dataList.pages) {
        if (input_el) {
          input_el.className += " " + "clean-pre-check-list__error-border"
        }
      } else {
        if (input_el) {
          input_el.classList.remove("clean-pre-check-list__error-border")
        }
        this.currentPage = page
        this.$emit("changePage", page)
      }
    }
  }
}
</script>

<style lang="sass">
.clean-pre-check-list
  width: 952px
  &__title
    font-family: Playfair Display
    font-style: normal
    font-weight: bold
    font-size: 48px
    line-height: 64px
    display: flex
    align-items: center
    color: #000000
    margin-bottom: 10px !important
  &__sub-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 20px
    line-height: 24px
    color: #000000
    margin-bottom: 16px !important
  &__type-area
    width: 293px
    height: 88px
    background-color: #FAFAFA
    display: flex
    border-radius: 2px
  &__type-area-left
    width: 220px
    float: left
    padding: 8px 15px 8px 8px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    text-align: left
  &__type-area-title
    color: #262626
    margin-bottom: 4px !important
  &__type-area-desc
    color: #595959
    margin-bottom: 0px !important
  &__type-right_100
    width: 73px
    display: flex
    background-color: #E2EEEC
    img
      width: 42px
      height: 42px
      margin: auto
  &__type-right_partial
    width: 73px
    display: flex
    background-color: #FEF2E2
    img
      margin: auto
      width: 42px
      height: 42px
  &__type-right_no
    width: 73px
    display: flex
    background-color: #E8E8E8
    img
      margin: auto
      width: 42px
      height: 42px
  &__panel
    height: 52px
  &__panel-right
    width: 52px
    height: 52px
    position: absolute
    right: 0px
    display: flex
    img
      margin: auto
      width: 30px
      height: 30px
  &__panel-right-selected
    border: 1px solid #fec206
  &__panel-arrow-border
    border-radius: 2px
    border: 1px solid #1D4BA1
  &__panel-right-100
    background-color: #E2EEEC
  &__panel-right-partial
    background-color: #FEF2E2
  &__panel-right-no
    background-color: #E8E8E8
  &__panel-left
    width: 52px
    height: 52px
    background-color: #F5F5F5
  &__panel-img
    width: 52px
    height: 52px
    margin-right: 0px !important
    border-radius: 50%
  &__panel-title
    float: left
    margin-left: 28px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 52px
    letter-spacing: 0.2px
    color: #262626
    max-width: 509px
    overflow: hidden
    height: 52px
    white-space: nowrap
    text-overflow: ellipsis
  &__panel-subtitle
    float: left
    font-family: Proxima Nova
    font-weight: normal
    font-size: 16px
    line-height: 52px
    height: 52px
    color: #262626
    position: absolute
    left: 634px
  &__table-head
    margin-top: 8px
    margin-right: 34px !important
    margin-left: 34px !important
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 16px !important
    color: #262626
  &__tr-hover
    background-color: #f5f5f5 !important
  &__tr-no-hover
    background-color: #ffffff
  &__table-icon
    padding-left: 1px !important
    color: #BFBFBF !important
    margin-right: 22px
    cursor: pointer
  &__table-content
    margin: 0px 36px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 31px
    color: #262626
    max-height: 582px
    overflow-y: scroll
  &__table-product-mark
    width: 20px
    height: 20px
    background: transparent
    display: inline-block
  &__table-title-left
    float: left
    //width: 582px
    padding-left: 33px !important
    //text-overflow: ellipsis
  &__table-content-name
    margin-right: 12px
    //width: 582px
    padding-left: 33px !important
    //text-overflow: ellipsis
  &__tooltip-icon
    margin: 8px 3px 8px 11px
    border-radius: 50%
    background-color: white
  &__tooltip-text
    color: white
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 15px
    margin: 0px 4px
  &__page-row
    margin-top: 10px
    float: right
  &__error-border
    border-color: #ff6132 !important
  &__modal-spin-div
    padding-top: 45px
    text-align: center
    line-height: 300px
    height: 400px
    width: 100%

.clp_p100 >.ant-collapse-header:hover
  background-color: #FAFAFA
.clp_p100 >.ant-collapse-header:hover .clean-pre-check-list__panel-right
  border: 1px solid #12433d
.clp_p100 >.ant-collapse-header:hover .clean-pre-check-list__panel-title
  text-decoration: underline
.clp_p100.ant-collapse-item-active .clean-pre-check-list__panel-right
  border: 1px solid #12433d
.clp_p100.ant-collapse-item-active >.ant-collapse-header:hover .ant-collapse-arrow
  color: #4a8a5d !important
.clp_p50 >.ant-collapse-header:hover
  background-color: #FAFAFA
.clp_p50 >.ant-collapse-header:hover .clean-pre-check-list__panel-right
  border: 1px solid #fec206
.clp_p50.ant-collapse-item-active .clean-pre-check-list__panel-right
  border: 1px solid #fec206
.clp_p50 >.ant-collapse-header:hover .clean-pre-check-list__panel-title
  text-decoration: underline
.clp_p50.ant-collapse-item-active >.ant-collapse-header:hover .ant-collapse-arrow
  color: #fec206 !important
.clp_l50 >.ant-collapse-header:hover
  background-color: #FAFAFA
.clp_l50 >.ant-collapse-header:hover .clean-pre-check-list__panel-right
  border: 1px solid #595959
.clp_l50.ant-collapse-item-active .clean-pre-check-list__panel-right
  border: 1px solid #595959
.clp_l50 >.ant-collapse-header:hover .clean-pre-check-list__panel-title
  text-decoration: underline
.clp_l50.ant-collapse-item-active >.ant-collapse-header:hover .ant-collapse-arrow
  color: #ffffff !important
#cleanPreCheckCollapse >.ant-collapse-borderless
  background-color: white !important
#cleanPreCheckCollapse >.ant-collapse-borderless > .ant-collapse-item
  border: 2px solid white !important
#cleanPreCheckCollapse >.ant-collapse >.ant-collapse-item .ant-collapse-header
  padding: 0px !important
  display: block
#cleanPreCheckCollapse >.ant-collapse >.ant-collapse-item-active.clp_p100 .ant-collapse-header
  background-color: #E2EEEC !important
#cleanPreCheckCollapse >.ant-collapse >.ant-collapse-item-active.clp_p50 .ant-collapse-header
  background-color: #FEF2E2 !important
#cleanPreCheckCollapse >.ant-collapse >.ant-collapse-item-active.clp_l50 .ant-collapse-header
  background-color: #E8E8E8 !important
#cleanPreCheckCollapse >.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow
  right: 328px !important
.clean-pre-check-list__table-head .ant-table-small
  border: none !important
.clean-pre-check-list__table-head .ant-table.ant-table-small
  font-size: 16px !important
  color: #262626 !important
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content .ant-table-header > table > .ant-table-thead > tr > th
  border: none !important
  padding: 0px 0px !important
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th
  border: none !important
  padding: 0px 0px !important
.clean-pre-check-list__table-head .ant-table-thead
  height: 31px
  background-color: #F5F5F5
  font-family: Proxima Nova
  font-size: 16px !important
  line-height: 31px
  color: #262626
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content .ant-table-body > table > .ant-table-tbody > tr > td
  padding: 0px 0px !important
  font-family: Proxima Nova
  height: 31px
  line-height: 31px
  font-size: 16px !important
  font-weight: normal
  font-style: normal
  border: none !important
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content .ant-table-body > table > .ant-table-tbody > tr.ant-table-row.ant-table-row-level-0:hover
  background: #F5F5F5 !important
.clean-pre-check-list__table-head .ant-table.ant-table-small .ant-table-footer
  padding: 0px 0px !important
  height: 30px
  line-height: 30px
  background: #F5F5F5
  border-radius: 2px
  text-align: center
  color: #5E6871 !important
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr
  //width: calc(100% - 6px)
  border: none !important
.clean-pre-check-list__table-head .ant-table-small .ant-table-content .ant-table-scroll .ant-table-body::-webkit-scrollbar
  width: 6px !important
.clean-pre-check-list__table-head .ant-table-small .ant-table-content .ant-table-scroll .ant-table-body::-webkit-scrollbar-thumb
    background-color: #d9d9d9
    border-radius: 4px
.clean-pre-check-list__table-head .ant-table-small .ant-table-content .ant-table-scroll .ant-table-body::-webkit-scrollbar-track
  background-color: #f5f5f5
.clp_p100 .clean-pre-check-list__table-head .ant-table.ant-table-small .ant-table-footer:hover
  background-color: #E2EEEC
.clp_p50 .clean-pre-check-list__table-head .ant-table.ant-table-small .ant-table-footer:hover
  background-color: #FEF2E2
.clp_l50 .clean-pre-check-list__table-head .ant-table.ant-table-small .ant-table-footer:hover
  background-color: #E8E8E8
.clean-pre-check-list__page-row .ant-pagination-item-active
  background: #1D4BA1 !important
  border-color: #1D4BA1 !important
  border-radius: 2px !important
.clean-pre-check-list__page-row .ant-pagination-item.ant-pagination-item-active a
  color: white !important
.clean-pre-check-list__page-row .ant-pagination-item a
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px !important
  color: #262626 !important
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-options
  margin-left: 50px !important
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-options >.ant-pagination-options-quick-jumper
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px !important
  color: #000000 !important
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-options-quick-jumper input
  height: 18px
  padding: 1px 2px
  width: 35px
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px !important
  color: #262626 !important
  border-radius: 1px !important
.clean-pre-check-list__page-row .ant-pagination-options-quick-jumper input:hover
  border-color: #1B4DA1
  border-right-width: 1px !important
.clean-pre-check-list__page-row .ant-pagination-options-quick-jumper input:focus
  border-color: #1B4DA1
  border-right-width: 1px !important
  box-shadow: none !important
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-prev:hover,.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-next:hover
  background-color: #E2EEEC
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-item:hover
  background-color: #e2eeec
.cleanPreCheckTooltip .ant-tooltip-arrow::before
  background-color: #BFBFBF !important
.cleanPreCheckTooltip .ant-tooltip-inner
  background-color: #BFBFBF !important
.clean-pre-check-list__table-content-name:hover
  text-decoration: underline
#productModal .ant-modal-body >.product-card
  padding: 0px 10px !important
#productModal .ant-modal-body .product-card__details
  padding: 16px 10px 10px 10px
#productModal .ant-modal-body .product-card__selected-image
  width: 460px !important
  height: 460px !important
#productModal .ant-modal-body .product-card__details-header
  margin-top: 10px !important
#productModal .ant-modal-body .product-card__body-info--title
  margin-top: 10px !important
#productModal .ant-modal-body .product-card__body-description
  padding-bottom: 10px !important
  padding-top: 10px !important
#productModal .ant-modal-body .product-card__body
  padding-bottom: 20px !important
#productModal .ant-modal-body .product-card__buttons
  margin-top: 0px
  margin-bottom: 0px
#productModal .ant-modal-body .product-card__data-sheet-link
  margin-top: 10px
#productModal .ant-modal-body p
  margin-bottom: 0px
.clean-pre-check-list__table-head .ant-table-scroll
  overflow: hidden
.clean-pre-check-list__table-head .ant-table-small>.ant-table-content .ant-table-header
  background-color: #f5f5f5
</style>
