import dateUtils from "@/utils/date-utils";
export default class OrderAirShipment {
  constructor(model) {
    this.orderPrivateId = 0;
    this.providerPrivateId = 0;
    this.orderStatusPrivateId = 0;
    this.trackingId = "";
    this.trackingUrl = null;
    this.actualShipDate = null;
    this.totalCartonQuantity = null;
    this.createdAt = new Date();
    this.createdBy = null;
    this.updatedAt = null;
    this.updatedBy = null;
    this.order = null;
    if (model) {
      this.orderPrivateId = model.orderPrivateId;
      this.providerPrivateId = model.providerPrivateId;
      this.orderStatusPrivateId = model.orderStatusPrivateId;
      this.trackingId = model.trackingId;
      this.trackingUrl = model.trackingUrl;
      this.actualShipDate = model.actualShipDate
        ? dateUtils.parseDateOnly(model.actualShipDate)
        : null;
      this.totalCartonQuantity = model.totalCartonQuantity;
      this.createdAt = new Date(model.createdAt);
      this.createdBy = model.createdBy;
      this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null;
      this.updatedBy = model.updatedBy;
    }
  }
}
