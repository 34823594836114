import httpclient from "@/api/http-client";
import { Payment } from "@/models/Payment";
import { SubscriptionTransaction } from "../models/Payment";
import { SubscriptionPricing } from "@/models/SubscriptionPricing";
import { AllSubscription } from "@/models/Subscription";
export default {
  async getSubscriptions(brandPrivateId) {
    return await httpclient
      .get(`/api/Subscriptions/${brandPrivateId}`)
      .then((res) => res.data.map(() => true))
      .catch(() => true);
  },
  async setup(data) {
    return await httpclient.post(`/api/Subscriptions/setup`, data);
  },
  async approveBrandSubscription(data) {
    return await httpclient.post(
      `/api/Subscriptions/ApproveBrandSubscription`,
      data
    );
  },
  async RejectBrandSubscription(brandId) {
    return await httpclient.post(
      `/api/Subscriptions/RejectBrandWithPlatformSubscription?brandId=${brandId}`
    );
  },
  async getPaymentMethods(brandId) {
    return await httpclient
      .get(`/api/Subscriptions/GetPaymentMethods?brandId=${brandId}`)
      .then((res) => res.data.map((d) => new Payment(d)));
  },
  async addPaymentMethod(brandId, isDefaultPaymentMethod, payment) {
    return await httpclient.post(
      `/api/Subscriptions/AddPaymentMethod?brandId=${brandId}&isDefaultPaymentMethod=${isDefaultPaymentMethod}`,
      payment
    );
  },
  async setCustomerPaymentProfileDefault(brandId, customerPaymentProfileId) {
    return await httpclient.post(
      `/api/Subscriptions/setCustomerPaymentProfileDefault?brandId=${brandId}&defaultCustomerPaymentProfileId=${customerPaymentProfileId}`
    );
  },
  async getTransactions(brandId) {
    return await httpclient
      .get(`/api/Subscriptions/GetTransactions?brandId=${brandId}`)
      .then((res) => res.data.map((t) => new SubscriptionTransaction(t)));
  },
  async cancelSubscription(brandId, subscriptionId) {
    return await httpclient.post(
      `/api/Subscriptions/CancelBrandWithPlatformSubscription?brandId=${brandId}&subscriptionId=${subscriptionId}`
    );
  },
  async expiredBrandSubscription(brandId, subscriptionId) {
    return await httpclient.post(
      `/api/Subscriptions/ExpireBrandWithPlatformSubscription?brandId=${brandId}&subscriptionId=${subscriptionId}`
    );
  },
  async getPlans(brandId) {
    let url = "/api/Subscriptions/pricing";
    if (brandId) {
      url += `?brandId=${brandId}`;
    }
    return await httpclient
      .get(url)
      .then((res) => res.data.map((m) => new SubscriptionPricing(m)));
  },
  async signUp(data) {
    return await httpclient.post(`/api/Subscriptions/setup`, data);
  },
  async getAllSubscriptions(brandId) {
    return await httpclient
      .get(`/api/Subscriptions?brandId=${brandId}`)
      .then((res) => new AllSubscription(res.data));
  },
  async getSubscriptionDetail(brandId, subscriptionId, startDate, retailerId) {
    let url = `/api/Subscriptions/details?brandId=${brandId}&subscriptionId=${subscriptionId}&startDate=${startDate}`;
    if (retailerId) {
      url += `&retailerId=${retailerId}`;
    }
    return await httpclient.get(url).then((res) => {
      console.log(res.data);
      return res.data;
    });
  },
  async getAllSubscriptionsMock() {
    const json = {
      brandSubscription: {
        brandPrivateId: 2059,
        typePrivateId: 4,
        statusPrivateId: 4,
        isActive: 1,
        isAutoRenewal: 1,
        hasBeautyFluentSubscription: 0,
        subscriptionId: "9185415",
        paidAmountUsd: 0,
        startDate: "2024-04-04",
        endDate: "2024-05-04",
        anniversaryDate: "2025-04-04",
        unsubscribeTime: null,
        subscriptionTerm: 3,
        createdAt: "2024-04-04T23:36:04",
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        type: null,
        status: null,
        brand: {
          products: null,
          brandCurations: null,
          brandValues: null,
          brandTags: null,
          subscription: null,
          creditCards: null,
          term: null,
          scope: null,
          replica: {
            id: "PQ_uxOHFYkGZ6sqSijdq0A",
            statusPrivateId: 3,
            name: "brand5007@landingintl.com",
            shortName: "VVV",
            nextProductSequenceValue: 100,
            createdAt: "2024-04-04T23:36:02",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            scope: null,
          },
          productCount: null,
          subscriptionHistories: null,
          coupon: null,
          privateId: 2059,
          parentPrivateId: null,
          id: "PQ_uxOHFYkGZ6sqSijdq0A",
          typePrivateId: 3,
          sizePrivateId: null,
          statusPrivateId: 3,
          status: null,
          statusUpdatedAt: null,
          statusUpdatedBy: null,
          name: "brand5007@landingintl.com",
          shortName: "VVV",
          logoAssetId: null,
          logoAssetUrl: null,
          logoAssetDescriptor: null,
          bannerAssetId: null,
          bannerAssetUrl: null,
          bannerAssetDescriptor: null,
          introDeckAssetUrl: null,
          introDeckAssetId: null,
          introDeckAssetDescriptor: null,
          introductionAssetUrl: null,
          introductionAssetId: null,
          introductionAssetDescriptor: null,
          introduction: null,
          about: null,
          website: "brand5007.com",
          foundingDate: null,
          isTermsUpdatePending: 0,
          createdAt: "2024-04-04T23:36:02",
          createdBy: null,
          updatedAt: null,
          updatedBy: null,
          channelTypeId: null,
          locationTypeId: null,
          locationGroupId: null,
          assets: null,
          members: [
            {
              clientId: "PQ_uxOHFYkGZ6sqSijdq0A",
              userId: "GLNrbksfSkugFv5JcWhPIQ",
              rolePrivateId: 2,
              statusPrivateId: 2,
              createdAt: "2024-04-04T23:36:03",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
          ],
          clientPaymentProviderProfile: {
            clientPrivateId: 2059,
            providerProfileId: "919122364",
            createdAt: "2024-04-04T23:36:04",
            updatedAt: null,
            client: null,
            accounts: null,
          },
        },
      },
      brandTrainingSubscriptions: [],
    };
    const data = new AllSubscription(json);

    return new Promise((resolve) => resolve(data));
  },
  // async setCustomerPaymentProfileDefault(
  //   customerProfileId,
  //   defaultCustomerPaymentProfileId
  // ) {
  //   return await httpclient.post(
  //     `/api/Subscriptions/setCustomerPaymentProfileDefault?customerProfileId=${customerProfileId}&defaultCustomerPaymentProfileId=${defaultCustomerPaymentProfileId}`
  //   );
  // },
  async getPlansOld() {
    const mocks = [
      {
        name: "Basic",
        //subscriptionType: enum Basic
        retailerId: null,
        amount: 2000,
        billFrequencies: [
          {
            name: "Yearly",
            subscriptionTerm: 1, // enum annual
            numOfPayment: 1,
            discountPercentage: 0,
          },
          {
            name: "Quarterly",
            subscriptionTerm: 2, // enum quarter
            numOfPayment: 4,
            discountPercentage: 0,
          },
          {
            name: "Monthly",
            subscriptionTerm: 3, // enum monthly
            numOfPayment: 12,
            discountPercentage: 0,
          },
        ],
      },
      {
        name: "Plus",
        //subscriptionType: enum Plus
        retailerId: null,
        amount: 6000,
        billFrequencies: [
          {
            name: "Yearly",
            subscriptionTerm: 1, // enum annual
            numOfPayment: 1,
            discountPercentage: 0.2,
          },
          {
            name: "Quarterly",
            subscriptionTerm: 2, // enum quarter
            numOfPayment: 4,
            discountPercentage: 0.03,
          },
          {
            name: "Monthly",
            subscriptionTerm: 3, // enum monthly
            numOfPayment: 12,
            discountPercentage: 0,
          },
        ],
      },
      {
        name: "Premium JCPenney",
        //subscriptionType: enum Premium
        retailerId: 525,
        amount: 18000,
        billFrequencies: [
          {
            name: "Yearly",
            subscriptionTerm: 1, // enum annual
            numOfPayment: 1,
            discountPercentage: 0.2,
          },
          {
            name: "Quarterly",
            subscriptionTerm: 2, // enum quarter
            numOfPayment: 4,
            discountPercentage: 0.03,
          },
          {
            name: "Monthly",
            subscriptionTerm: 3, // enum monthly
            numOfPayment: 12,
            discountPercentage: 0,
          },
        ],
      },
      {
        name: "Premium Ulta Beauty",
        //subscriptionType: enum Premium
        retailerId: 262,
        amount: 26000,
        billFrequencies: [
          {
            name: "Yearly",
            subscriptionTerm: 1, // enum annual
            numOfPayment: 1,
            discountPercentage: 0.2,
          },
          {
            name: "Quarterly",
            subscriptionTerm: 2, // enum quarter
            numOfPayment: 4,
            discountPercentage: 0.03,
          },
          {
            name: "Monthly",
            subscriptionTerm: 3, // enum monthly
            numOfPayment: 12,
            discountPercentage: 0,
          },
        ],
      },
    ];
    return Promise.resolve(mocks);
  },

  async getPlansNew() {
    const mocks = [
      {
        subscriptionType: {
          privateId: 7,
          id: "premiumnew",
          name: "Premium",
          description: "Platform + BF Preferred Retailer",
          isActive: 1,
          createdAt: "2024-03-12T17:49:35",
          createdBy: null,
          updatedAt: null,
          updatedBy: null,
        },
        retailer: {
          gradeXref: null,
          sellers: null,
          locations: null,
          productTrainingForRetailers: null,
          privateId: 525,
          parentPrivateId: null,
          id: "5QwjyvoVT1aOCxoCGCP1Ow",
          typePrivateId: 4,
          sizePrivateId: null,
          statusPrivateId: 3,
          status: null,
          statusUpdatedAt: null,
          statusUpdatedBy: null,
          name: "JCPenney",
          shortName: "JCP",
          logoAssetId: null,
          logoAssetUrl: null,
          logoAssetDescriptor: null,
          bannerAssetId: null,
          bannerAssetUrl: null,
          bannerAssetDescriptor: null,
          introDeckAssetUrl: null,
          introDeckAssetId: null,
          introDeckAssetDescriptor: null,
          introductionAssetUrl: null,
          introductionAssetId: null,
          introductionAssetDescriptor: null,
          introduction: null,
          about: null,
          website: null,
          foundingDate: null,
          isTermsUpdatePending: 0,
          createdAt: "2020-11-19T19:45:27",
          createdBy: "MdRK9fMKRS-HvKm3t9a3qA",
          updatedAt: "2023-01-09T19:04:35",
          updatedBy: "I7dNzlGXQnG5hkOPrcZbrg",
          channelTypeId: null,
          locationTypeId: null,
          locationGroupId: null,
          assets: null,
          members: null,
          clientPaymentProviderProfile: null,
        },
        amount: 30000,
        billFrequencies: [
          {
            term: {
              privateId: 1,
              id: "annual",
              name: "Annual",
              description: null,
              isActive: 1,
              createdAt: "2022-06-30T05:24:51",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
            discountPercentage: 0.1,
            numberOfPayments: 1,
          },
          {
            term: {
              privateId: 2,
              id: "quarterly",
              name: "Quarterly",
              description: null,
              isActive: 1,
              createdAt: "2022-06-30T05:24:51",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
            discountPercentage: 0.05,
            numberOfPayments: 4,
          },
          {
            term: {
              privateId: 3,
              id: "monthly",
              name: "Monthly",
              description: null,
              isActive: 1,
              createdAt: "2022-06-30T05:24:51",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
            discountPercentage: 0,
            numberOfPayments: 12,
          },
        ],
      },
      {
        subscriptionType: {
          privateId: 6,
          id: "plus",
          name: "Plus",
          description: "Platform + BF General Retailers",
          isActive: 1,
          createdAt: "2024-03-12T17:49:35",
          createdBy: null,
          updatedAt: null,
          updatedBy: null,
        },
        retailer: null,
        amount: 10000,
        billFrequencies: [
          {
            term: {
              privateId: 1,
              id: "annual",
              name: "Annual",
              description: null,
              isActive: 1,
              createdAt: "2022-06-30T05:24:51",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
            discountPercentage: 0.09,
            numberOfPayments: 1,
          },
          {
            term: {
              privateId: 2,
              id: "quarterly",
              name: "Quarterly",
              description: null,
              isActive: 1,
              createdAt: "2022-06-30T05:24:51",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
            discountPercentage: 0.04,
            numberOfPayments: 4,
          },
          {
            term: {
              privateId: 3,
              id: "monthly",
              name: "Monthly",
              description: null,
              isActive: 1,
              createdAt: "2022-06-30T05:24:51",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
            discountPercentage: 0,
            numberOfPayments: 12,
          },
        ],
      },
      {
        subscriptionType: {
          privateId: 5,
          id: "subscriber",
          name: "Basic",
          description: "Platform Only",
          isActive: 1,
          createdAt: "2022-04-23T02:01:59",
          createdBy: null,
          updatedAt: null,
          updatedBy: null,
        },
        retailer: null,
        amount: 2000,
        billFrequencies: [
          {
            term: {
              privateId: 1,
              id: "annual",
              name: "Annual",
              description: null,
              isActive: 1,
              createdAt: "2022-06-30T05:24:51",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
            discountPercentage: 0.09,
            numberOfPayments: 1,
          },
          {
            term: {
              privateId: 2,
              id: "quarterly",
              name: "Quarterly",
              description: null,
              isActive: 1,
              createdAt: "2022-06-30T05:24:51",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
            discountPercentage: 0.04,
            numberOfPayments: 4,
          },
          {
            term: {
              privateId: 3,
              id: "monthly",
              name: "Monthly",
              description: null,
              isActive: 1,
              createdAt: "2022-06-30T05:24:51",
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
            },
            discountPercentage: 0,
            numberOfPayments: 12,
          },
        ],
      },
    ];

    const models = mocks.map((m) => new SubscriptionPricing(m));

    return Promise.resolve(models);
  },
  async signUpMock(data) {
    return new Promise((resolve) => setTimeout(resolve, 300)).then(() =>
      console.log(data)
    );
  },
};
