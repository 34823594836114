import axios from "@/api/axios";
import httpclient from "@/api/http-client";
import ProductTraining from "@/models/ProductTraining";

export default {
  async getBrandTrainingProTips(productId) {
    //console.log(`getBrandTrainingProTips ${productId}`);
    return await httpclient
      .get(`/api/ProductTraining/by-product-id/${productId}`)
      .catch((error) => {
        if (error.status == 404) {
          return null;
        } else {
          throw error;
        }
      })
      .then((res) => (res ? new ProductTraining(res.data) : null));
  },
  // sending the whole obj back does not work as the product is null and this is not allowed.
  // so will just send the protips for now.
  async updateBrandTrainingProTips(productId, productTraining) {
    console.log(
      "updateBrandTrainingProTips with new api...",
      productId,
      productTraining
    );
    return await httpclient
      .patch(
        `/api/ProductTraining/by-product-id/${productId}`,
        {
          proTip1: productTraining.proTip1,
          proTip2: productTraining.proTip2,
          proTip3: productTraining.proTip3,
        }
        //productTraining.toModel())
      )
      .then((res) => new ProductTraining(res.data));
    // //fake promise delay to simulate api call:
    // return new Promise(function(resolve) {
    //   setTimeout(resolve, 1000)
    // })
  },
  /**
   * Get the goods that can create Training
   * @param brandId
   * @returns {Array}
   */
  getTrainingProducts(brandId) {
    return axios.get(
      `/products?brand=${brandId}&_limit=9999&have-training=true`
    );
  },
  /**
   * Create multiple Training goods
   * @param brandId
   * @param params Array
   * @returns
   */
  addTrainingProduct(brandId, params) {
    return axios.put(
      `/products/${brandId}?_skipNulls=true`,
      JSON.stringify(params)
    );
  },
  /**
   * @param brandId
   * @returns
   */
  updateTrainingProduct(brandId, params) {
    return axios.put(`/products/${brandId}?_skipNulls=true`, params);
  },

  async updateTrainingProductAndProTips(brandId, params) {
    const proTips = await this.getBrandTrainingProTips(brandId);
    const result = await this.updateTrainingProduct(brandId, params);
    if (proTips) {
      await this.updateBrandTrainingProTips(brandId, proTips);
    }
    return result;
  },
  /**
   * admin，To search for training
   * @param brandName
   * @param params
   * @returns { Array }
   */
  searchTrainingProduct(brandName, params) {
    return axios.get(
      `/brands/admin/training?brand-product-name=${brandName}&_limit=9990`,
      {
        params,
      }
    );
  },
  /**
   * admin，To search for training
   * @param params
   * @returns { Array }
   */
  searchTrainingBrand(params) {
    return axios.get(`/brands/admin/training`, {
      params,
    });
  },
  /**
   * Remove a single Training item
   * @param id
   */
  deleteTrainingProduct(id) {
    return axios.delete(`/products/${id}/training/delete`);
  },
  getProductList(id) {
    return axios.get(`/products?brand=${id}&_limit=9999&have-training=false`);
  },
  getProductTrainingList(id) {
    return axios.get(`/products?brand=${id}&_limit=9999&have-training=true`);
  },
  /**
   * get guest training list
   * @param id
   * @returns {Promise<Array>}
   */
  getGuestTrainingList(id) {
    return axios.get(
      `/guest/products?have-training=true&brand=${id}&_limit=999`
    );
  },
  /**
   * Gets information about the guest page's brand
   * @param shortName
   * @returns {Promise<Object>}
   */
  getGuestBrandInfo(shortName) {
    return axios.get(`/guest/brands/${shortName}`);
  },
  /**
   * get AWS info(Used to upload videos or images to AWS servers)
   * @param uploadInfo
   * @returns {Promise<axiosResponse<any>>}
   */
  getAWSKeyInfo(uploadInfo) {
    return axios.post(`/assets/pre-create`, JSON.stringify(uploadInfo));
  },
  getAWSKeyInfoInAdmin(uploadInfo, clientId) {
    return axios.post(`/assets/pre-create`, JSON.stringify(uploadInfo), {
      headers: { "X-Client-ID": clientId },
    });
  },
  /**
   * send info
   * @param uploadInfo
   * @returns {Promise<axiosResponse<any>>}
   */
  sendUploadInfo(uploadInfo) {
    return axios.post(`/assets-v2`, JSON.stringify(uploadInfo), {
      headers: { "X-Client-ID": uploadInfo.clientId },
    });
  },
  addBrandClientVideo(brandId, params) {
    return axios.post(`/brands/${brandId}?_skipNulls=true`, params);
  },
  updateBrandTrainingSubscription(brandId, params) {
    return axios.put(`/brands/${brandId}/trainingSubscriptions`, params);
  },
  paymentTrainingSubscription(brandId, params) {
    return axios.post(
      `/brands/${brandId}/paymentForTrainingSubscription/${params.type}?retailer-id=${params.retailerId}`,
      JSON.stringify(params)
    );
  },
  getTrainingRetailer() {
    return axios.get("/training-retailer-statistics");
  },
  getBrandTrainingStatics(brandId) {
    return axios.get(`/brand-training-statistics?brand_id=${brandId}`);
  },
  updateProductTrainingRetailer(productId, params) {
    return axios.put(`/products/${productId}/trainingForRetailers`, params);
  },
  uploadRetailerLocationList(retailerId, file) {
    return axios.postForm(`/retailers/${retailerId}/locations/upload`, file);
  },
  getRetailerInfo(shortName) {
    return axios.get(`/guest/clients/${shortName}`);
  },
  brandPayment(brandId, param) {
    return axios.post(
      `/brands/${brandId}/pay/bf-subscription`,
      JSON.stringify(param)
    );
  },
};
