import axios from "@/api/axios"

export default {
  update(brandId, addresses) {
    return axios.put(`/brands/${brandId}/addresses`, JSON.stringify(addresses))
  },
  fetch(brandId) {
    return axios.get(`/brands/${brandId}/addresses`)
  }
}
