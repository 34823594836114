<template>
  <div class="image-cropper" :style="cssProps">
    <div class="image-upload__profile has-text-centered">
      <div class="image-upload__profile-logo-container">
        <div class="image-upload__profile-logo">
          <LogoOrPlaceholder :url="logoAssetUrl" />
        </div>
        <div class="image-upload__profile--main_icon" @click="openMainImageInput">
          <img src="@/assets/img/icon/camera-icon.svg" />
        </div>
      </div>
      <input
        id="image-upload-main"
        ref="mainImageInput"
        type="file"
        accept="image/png, image/jpeg"
        label="File"
        :show-size="1024"
        @change="setupCropper($event.target.files[0], 50)"
      />
    </div>
    <div class="modal" :class="{ 'is-active': isCropperModalActive }">
      <div class="modal-background" @click="isCropperModalActive = !isCropperModalActive"></div>
      <div class="modal-card image-cropper__modal">
        <header class="modal-card-head">
          <p class="modal-card-title image-cropper__header">
            {{ $t("Components.Cropper.LogoImageCropper.logo") }}
          </p>
          <img
            class="image-cropper__close-icon"
            src="@/assets/img/icon/close-icon.png"
            @click="isCropperModalActive = !isCropperModalActive"
          />
        </header>
        <clipper-basic
          ref="clipper"
          class="clipperContainer"
          :src="objectUrl"
          :rotate="degree"
          layout="corner"
          :ratio="logoAspectRatio"
        />
        <footer class="modal-card-foot image-cropper__footer">
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="image-cropper__modal--straighten-header">
                {{ $t("Components.Cropper.LogoImageCropper.operationDesc") }}
              </div>
            </div>
            <div class="column">
              <button
                class="button is-medium image-cropper__button-change"
                @click="openMainImageInput"
              >
                {{ $t("Components.Cropper.LogoImageCropper.changeImage") }}
              </button>
              <input
                id="image-upload-main1"
                ref="mainImageInput"
                type="file"
                accept="image/png, image/jpeg"
                label="File"
                :show-size="1024"
                @change="setupCropper($event.target.files[0], 50)"
              />
            </div>
            <div class="column">
              <button
                class="button is-black is-medium image-cropper__button-save"
                @click="
                  saveBrandAsset()
                  isCropperModalActive = !isCropperModalActive
                "
              >
                {{ $t("Components.Cropper.LogoImageCropper.saveImage") }}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import assetClient from "@/api/asset-client.js"
import LogoOrPlaceholder from "@/components/cropper/LogoOrPlaceholder"
export default {
  components: {
    LogoOrPlaceholder
  },
  props: {
    initialLogoAssetUrl: { type: String, default: "" },
    clientId: { type: String, default: null }
  },
  data() {
    return {
      cropper: null,
      objectUrl: null,
      isCropperModalActive: false,
      min: 0,
      degree: 0,
      borderRadius: 0,
      logoAssetId: null,
      logoAssetUrl: this.initialLogoAssetUrl,
      logoAspectRatio: 1
    }
  },
  computed: {
    activeScaleWidth() {
      const widthPercentage = parseInt(this.min)
      return `${widthPercentage}%`
    },
    cssProps() {
      return {
        "--border-radius": this.borderRadius + "%"
      }
    }
  },
  methods: {
    emitAsset() {
      this.$emit("logoAssetUpdated", this.logoAssetId, this.logoAssetUrl)
    },
    openMainImageInput() {
      this.$refs.mainImageInput.value = null
      this.$refs.mainImageInput.click()
      this.min = 0
      this.degree = 0
    },
    straightenImage(event) {
      const value = parseInt(event.target.value)
      this.min = value
      this.degree = value * 3.6
    },
    setupCropper(selectedFile, borderRadius) {
      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl)
      }
      if (!selectedFile) {
        this.objectUrl = null
        return
      }
      this.borderRadius = borderRadius
      this.objectUrl = window.URL.createObjectURL(selectedFile)
      this.isCropperModalActive = true
    },
    emitImageSaving(status) {
      this.$emit("imageSaving", status)
    },
    async saveBrandAsset() {
      this.emitImageSaving("saving")
      try {
        const { data } = await assetClient.createAsset(this.fileFromDataUrl(), this.clientId)
        this.logoAssetId = data[0].file.id
        this.logoAssetUrl = data[0].file.url
      } catch (error) {
        this.emitImageSaving("error")
      } finally {
        this.emitImageSaving("saved")
        this.emitAsset()
      }
    },
    fileFromDataUrl() {
      let canvas = this.$refs.clipper.clip()
      let resultURL = canvas.toDataURL("image/jpeg", 1)
      let array = resultURL.split(","),
        mime = array[0].match(/:(.*?);/)[1],
        bstr = atob(array[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], "file", { type: mime })
    }
  }
}
</script>

<style lang="sass">
.image-cropper__zoom-slider--wrapper
  position: relative
  height: 26px
  margin-right: 30px
  width: 135%
.image-cropper__change-slider--wrapper
  position: relative
  height: 26px
  margin-left: 30px
.image-cropper__slider--scale
  height: 6px
  border-radius: 2px
  width: 100%
  background: #E5E5E5
  position: absolute
  left: 0
  top: calc(50% - 3px)
.image-cropper__slider--scale_active
  height: 6px
  border-radius: 2px
  background: #000
  width: 100%
  left: 0
  top: 0
  position: absolute
.image-cropper__slider--input
  position: absolute
  left: 0
  width: 100%
  pointer-events: none
  background: inherit
  -webkit-appearance: none
  height: 26px
  &:focus
    outline: none
  &::-webkit-slider-thumb
    pointer-events: auto
    background: #292929
    width: 12px
    height: 12px
    border: none
    border-radius: 50%
    -webkit-appearance: none
  &::-moz-range-thumb
    pointer-events: auto
    background: #292929
    width: 12px
    height: 12px
    border: none
    border-radius: 50%
  &::-ms-thumb
    pointer-events: auto
    background: #292929
    width: 12px
    height: 12px
    border: none
    border-radius: 50%
.image-cropper
#image-upload-main, #image-upload-banner
  display: none
.image-upload__profile--main
  max-height: 64px
  max-width: 64px
  cursor: pointer
.image-cropper__modal
  display: flex
  justifyContent: center
  alignItems: center
.image-cropper__header
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px
  color: #000000
  padding-left: 25px
.image-cropper__close-icon
  cursor: pointer
.image-cropper__footer
  display: flex
  justify-content: center
.image-cropper__text
  color: #ffffff
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px
  padding-top: 20px
.image-cropper__button-save
  font-size: 14px
  font-weight: 500
  letter-spacing: 0.7px
  margin-top: 30px
  margin-bottom: 20px
.image-upload__profile-logo-container
  position: relative
  display: flex
  justify-content: center
  vertical-align: center
.image-upload__profile-logo
  height: 64px
  width: 64px
  background-color: transparent
.image-upload__profile--main_icon
  position: absolute
  background-color: rgba(74,74,74,0.47)
  border-radius: 50%
  padding: 5px
  left: calc(50% - 20px)
  top: calc(50% - 20px)
  cursor: pointer
.image-upload__profile--banner_icon
  position: absolute
  background-color: rgba(74,74,74,0.47)
  border-radius: 50%
  padding: 5px
  right: 20px
  top: 40px
  cursor: pointer
.image-cropper__button-change
  font-size: 14px
  font-weight: 500
  letter-spacing: 0.7px
  float: right
  margin-top: 30px
  margin-bottom: 20px
.image-cropper__modal--header
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px
.image-cropper__modal--straighten-header
  font-size: 16px
  font-weight: normal
  letter-spacing: 0.28px
  margin-left: 30px
.image-container
  display: inline-block
.crop-container
  background-color: black
  padding: 40px 0px 20px
  overflow: scroll
.clipperContainer
  overflow: scroll
.image-preview
  display: block
  max-height: 229px
</style>
