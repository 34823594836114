<template>
  <div class="modal request-terms-modal-retailer" :class="{ 'is-active': show }">
    <div class="modal-background"></div>
    <div class="modal-card request-terms-modal-retailer__content">
      <header class="request-terms-modal-retailer__header">
        {{ $t("Components.Retailers.Profile.ChangeRequestModal.termsChangeRequest") }}
        <img
          class="request-terms-modal-retailer__close-button"
          src="@/assets/img/icon/close-icon.svg"
          @click="hide"
        />
      </header>
      <section class="modal-card-body">
        <div class="request-terms-modal-retailer__body">
          <div class="view-pending-request__wrap" :class="{ 'bottom-margin': role == 'admin' }">
            <div
              v-if="pendingData.paymentTermBefore.name != pendingData.paymentTermAfter.name"
              class="request-terms-modal-retailer__details-container"
            >
              <div class="payment-block column is-5">
                <div class="request-terms-modal-retailer__subtitles">
                  {{ $t("Components.Retailers.Profile.ChangeRequestModal.currentPaymentTerms") }}
                </div>
                <div class="request-terms-modal-retailer__details">
                  {{ pendingData.paymentTermBefore.name }}
                </div>
              </div>
              <div class="payment-block column is-5">
                <div class="request-terms-modal-retailer__subtitles">
                  {{ $t("Components.Retailers.Profile.ChangeRequestModal.newPaymentTerms") }}
                </div>
                <div class="request-terms-modal-retailer__details">
                  {{ pendingData.paymentTermAfter.name }}
                </div>
              </div>
            </div>
            <div
              v-if="pendingData.marketingCoOpBefore != pendingData.marketingCoOpAfter"
              class="request-terms-modal-retailer__details-container"
            >
              <div class="payment-block column is-5">
                <div class="request-terms-modal-retailer__subtitles">
                  {{ $t("Components.Retailers.Profile.ChangeRequestModal.currentMarketingCoop") }}
                </div>
                <div
                  v-if="pendingData.marketingCoOpBefore"
                  class="request-terms-modal-retailer__details"
                >
                  {{ pendingData.marketingCoOpBefore }}%
                </div>
              </div>
              <div class="payment-block column is-5">
                <div class="request-terms-modal-retailer__subtitles">
                  {{ $t("Components.Retailers.Profile.ChangeRequestModal.newMarketingCoop") }}
                </div>
                <div
                  v-if="pendingData.marketingCoOpAfter"
                  class="request-terms-modal-retailer__details"
                >
                  {{ pendingData.marketingCoOpAfter }}%
                </div>
              </div>
            </div>
            <div
              v-if="pendingData.damagesBudgetBefore != pendingData.damagesBudgetAfter"
              class="request-terms-modal-retailer__details-container"
            >
              <div class="payment-block column is-5">
                <div class="request-terms-modal-retailer__subtitles">
                  {{ $t("Components.Retailers.Profile.ChangeRequestModal.currentDamagesBudget") }}
                </div>
                <div
                  v-if="pendingData.damagesBudgetBefore"
                  class="request-terms-modal-retailer__details"
                >
                  {{ pendingData.damagesBudgetBefore }}%
                </div>
              </div>
              <div class="payment-block column is-5">
                <div class="request-terms-modal-retailer__subtitles">
                  {{ $t("Components.Retailers.Profile.ChangeRequestModal.newDamagesBudget") }}
                </div>
                <div
                  v-if="pendingData.damagesBudgetAfter"
                  class="request-terms-modal-retailer__details"
                >
                  {{ pendingData.damagesBudgetAfter }}%
                </div>
              </div>
            </div>
            <div
              v-if="pendingData.testersBefore != pendingData.testersAfter"
              class="request-terms-modal-retailer__details-container"
            >
              <div class="payment-block column is-5">
                <div class="request-terms-modal-retailer__subtitles">
                  {{ $t("Components.Retailers.Profile.ChangeRequestModal.currentTester") }}
                </div>
                <div v-if="pendingData.testersBefore" class="request-terms-modal-retailer__details">
                  {{ pendingData.testersBefore }}%
                </div>
              </div>
              <div class="payment-block column is-5">
                <div class="request-terms-modal-retailer__subtitles">
                  {{ $t("Components.Retailers.Profile.ChangeRequestModal.newTester") }}
                </div>
                <div v-if="pendingData.testersAfter" class="request-terms-modal-retailer__details">
                  {{ pendingData.testersAfter }}%
                </div>
              </div>
            </div>
          </div>
          <div v-if="role == 'retailer'" class="columns request-term-details-info">
            <div>
              {{ $t("Components.Retailers.Profile.ChangeRequestModal.changeRequestMessage") }}
            </div>
          </div>
        </div>
      </section>
      <div class="modal-card-foot request-terms-modal-retailer__buttons">
        <a v-if="role == 'retailer'" class="button is-black" @click="hide">
          {{ $t("Components.Retailers.Profile.ChangeRequestModal.ok") }}
        </a>
        <a v-if="role == 'admin'" class="button" @click="reject">
          {{ $t("Components.Retailers.Profile.ChangeRequestModal.reject") }}
        </a>
        <a v-if="role == 'admin'" class="button is-black" @click="approve">
          {{ $t("Components.Retailers.Profile.ChangeRequestModal.approve") }}
        </a>
        <!--        <a class="button is-black" @click="showbox">-->
        <!--          Request Changes-->
        <!--        </a>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    pendingData: {
      type: Object,
      default() {
        return {
          marketingCoOpBefore: null,
          marketingCoOpAfter: null,
          damagesBudgetBefore: null,
          damagesBudgetAfter: null,
          paymentTermBefore: {
            id: null,
            name: null
          },
          paymentTermAfter: {
            id: null,
            name: null
          }
        }
      }
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    }
  },
  methods: {
    hide() {
      this.$emit("hideChangeRequestModal")
    },
    approve() {
      this.$emit("approveChanges")
      this.hide()
    },
    reject() {
      this.$emit("rejectChanges")
      this.hide()
    }
  }
}
</script>

<style lang="sass">
.request-terms-modal-retailer
  .modal-card-body
    display: flex
    .view-pending-request__wrap
      margin: 0px 0px 50px 0px
      .request-terms-modal-retailer__details-container
        padding-bottom: 50px
        display: flex
        .payment-block
          .request-terms-modal-retailer__subtitles
            color: #000
            font-weight: 500
          .request-terms-modal-retailer__details
            color: #000
            margin: 18px
        .payment-block + .payment-block
            margin-left: 30px
      :last-child
        padding-bottom: 0px

  .request-term-details-info
    color: #000
    font-size: 14px
    font-weight: 400
    margin: 0px !important

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .request-terms-modal-retailer__close-button
      top: 20px
      width: 14px
      height: 14px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 601px

  .button
      height: 30px
      font-size: 14px
      padding: 16px 20px

  &__body
    padding: 30px 20px
    width: 601px

  &__buttons
    padding: 10px 20px
    display: flex
    justify-content: flex-end

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__details-container
    font-size: 14px
    padding-bottom: 60px

  &__details
    margin: 18px

.bottom-margin
  margin: 0px !important
</style>
