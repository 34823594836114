<template>
  <a-modal
    v-model="showMessageList"
    :width="modalWidth"
    :footer="null"
    :mask="!isSmallStatus"
    :closable="false"
    :body-style="bodyStyle"
    :mask-closable="false"
    :dialog-style="isSmallStatus ? { bottom: '100px', right: '-364px' } : { top: '10px' }"
    @cancel="hideDialog"
  >
    <div
      :style="isSmallStatus ? 'width: 561px;' : 'width: 952px; padding-bottom: 48px;'"
      style="margin: 0 auto;"
    >
      <a-row v-if="!isSmallStatus" class="message-list-modal__title-outer">
        <a-col :span="6">
          <span class="message-list-modal__title">Messaging</span>
        </a-col>
        <a-col :span="18" style="text-align: right;">
          <a-icon
            :type="isSmallStatus ? 'arrows-alt' : 'shrink'"
            style="margin-right:8px; font-size: 14px; font-weight: bold; "
            @click="clickToSwitch"
          />
          <a-icon type="close" @click="hideDialog" />
        </a-col>
      </a-row>
      <div class="message-list-modal">
        <a-row style="padding: 5px 8px; border-bottom: 1px  solid rgba(74, 138, 93, 0.46);">
          <a-col :span="18">
            <span v-if="!isForwordList && !forwardMessage" style="margin-left: 19px;">
              <span
                v-if="
                  messageData &&
                    messageData.messageForwards &&
                    messageData.messageForwards.length > 0
                "
                >Fwd:</span
              >Subject:{{ messageData ? messageData.subject : "" }}
            </span>
            <span v-else>
              To:
              <!-- <a-input v-model="forwardTo" style="width: 80%;" />  -->
              <a-select
                v-model="forwardTo"
                show-search
                :class="
                  isToError
                    ? 'admin-message-brand-create-new-modal__toTarget admin-message-brand-to-error'
                    : 'admin-message-brand-create-new-modal__toTarget'
                "
                style="width: 200px;"
                :filter-option="false"
                @search="handleSearch"
                @change="onSelectToTarget"
              >
                <a-select-option v-for="item in dataSource" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </span>
          </a-col>
          <a-col v-if="isSmallStatus" :span="6" style="text-align: right;">
            <a-icon
              :type="isSmallStatus ? 'arrows-alt' : 'shrink'"
              style="margin-right:8px; font-size: 14px; font-weight: bold; "
              @click="clickToSwitch"
            />
            <a-icon type="close" @click="hideDialog" />
          </a-col>
        </a-row>
        <a-row
          v-if="isForwordList"
          style="padding: 9px 8px 9px 27px; border-bottom: 1px  solid rgba(74, 138, 93, 0.46);"
        >
          <span>Fwd: Subject:{{ messageData ? messageData.subject : "" }} </span>
        </a-row>
        <div
          v-if="messageDetails"
          id="messageReplyList"
          style="height: 343px; overflow-y: auto; padding: 4px 25px; border-bottom: 1px  solid rgba(74, 138, 93, 0.46);"
        >
          <template v-for="message in messageDetails.messages">
            <MessageItem
              v-if="!message.isDelete"
              :key="`message_${message.id}`"
              :is-small-status="isSmallStatus"
              :message="message"
              :no-forward="isForwordList"
              :no-operation="isItemNoOperation"
              @uHeight="updateHeight"
              @deleteMessage="deleteMessageItem"
              @forwardMessage="forwardMessageItem"
            />
            <div
              v-else-if="
                isShowDeleteMessageId &&
                  isShowDeleteMessageId.length > 0 &&
                  isShowDeleteMessageId.includes(message.id)
              "
              :key="`delete-message_${message.id}`"
              style="width: 100%; margin-bottom: 29px;"
            >
              <div class="message-list-modal__delete-msg-area">
                This message has been deleted.
                <span class="message-list-modal__undo" @click="undoDelete(message)">Undo</span>
              </div>
            </div>
          </template>
          <div v-if="isShowWaiting && !isRetailer" class="message-list-modal__waiting-replay">
            <a-spin style="margin-right: 8px;" /> Waiting for Reply......
            <div class="message-list-modal__waiting-replay-info-layer">
              Message was sent successfully!<br />Please wait until the retailer responds to send a
              new message.
            </div>
          </div>
        </div>
        <a-row
          v-if="forwardMessage"
          style="border-bottom: 1px solid rgba(74, 138, 93, 0.46); padding: 12px 10px 9px 30px;"
        >
          <!-- {{ forwardMessage ? 'undefined' : forwardMessage.from }} -->
          <MessageItem :is-forward="true" :message="forwardMessage" />
        </a-row>
        <a-row
          v-if="isForwordList || forwardMessage !== undefined"
          style="padding: 9px 0px 11px 30px;"
        >
          <a-button
            type="primary"
            class="message-list-modal__send-btn"
            :disabled="forwardTo === undefined || forwardTo.length === 0 || isToError"
            @click="sendForwardMessage"
            >Send</a-button
          >
        </a-row>
        <message-input-component
          v-else
          :is-small-status="isSmallStatus"
          :draft-info="draftInfo"
          :generate-content="isShowWaiting && !isRetailer ? 1 : -1"
          @send="sendMessage"
          @sendDraft="sendDraft"
          @hideDialog="hideDialog"
        />
      </div>
      <confirm-dialog ref="confirmClose" @doConfirm="doCloseDialog" />
    </div>
  </a-modal>
</template>
<script>
import MessageItem from "@/components/message/components/MessageItem"
import MessageInputComponent from "@/components/message/MessageInputComponent"
import ConfirmDialog from "@/components/admin/ConfirmDialog.vue"
import constants from "@/config.js"
import { checkBrandRetailerConnection } from "@/utils/validate"
// import AssetClient from "@/api/asset-client"

export default {
  components: {
    MessageItem,
    MessageInputComponent,
    ConfirmDialog
  },
  data() {
    return {
      messageData: undefined,
      messageDetails: undefined,
      isShowWaiting: false,
      isSmallStatus: true,
      isItemNoOperation: false,
      // sendMessageType: 1, // 1: common message, 2: forward one message, 3: forward list message
      forwardMessage: undefined,
      modalWidth: "561px",
      showMessageList: false,
      isForwordList: false,
      forwardTo: "",
      editedMessage: undefined,
      bodyStyle: {
        padding: "0px"
      },
      dataSource: [],
      fromClient: undefined,
      toClient: undefined,
      isShowDeleteMessageId: [],
      firstMessage: undefined,
      isToError: false,
      originReceiverList: [],
      draftInfo: undefined,
      isUpdateScroll: false
    }
  },
  computed: {
    userId() {
      return this.$store.getters["user/businessId"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    retailers() {
      return this.$store.getters["retailer/retailers"]
    },
    brands() {
      return this.$store.getters["brand/brands"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    role() {
      return this.$store.getters["user/role"]
    }
  },
  updated() {
    console.log("....updated....")
    if (this.isUpdateScroll === false) {
      const obj = document.getElementById("messageReplyList")
      if (obj) {
        if (obj.scrollTop !== obj.scrollHeight) {
          this.isUpdateScroll = true
          obj.scrollTop = obj.scrollHeight
        }
      }
    }
  },
  methods: {
    updateHeight() {
      const obj = document.getElementById("messageReplyList")
      if (obj) {
        const objList = obj.children
        var contentHeight = 0
        if (objList && objList.length > 0) {
          for (let i = 0; i < objList.length; i++) {
            contentHeight += objList[i].clientHeight
          }
        }
        if (obj.scrollTop !== obj.scrollHeight) {
          this.isUpdateScroll = true
          obj.scrollTop = obj.scrollHeight
        }
        if (obj.scrollTop === 0 && contentHeight > 340 && contentHeight > obj.scrollHeight) {
          this.isUpdateScroll = true
          obj.scrollTop = contentHeight
        }
        console.log(obj.scrollTop)
        console.log(obj.scrollHeight)
        console.log(contentHeight)
      }
    },
    init(data, isInterMessage, isSmall) {
      this.showMessageList = true
      this.messageData = data
      this.isSmallStatus = isSmall
      this.forwardTo = ""
      this.forwardMessage = undefined
      this.fromClient = this.isAdmin ? "*" : this.userId
      this.isItemNoOperation = isInterMessage
      this.clickToSwitch()
      this.initToOptions()
      if (this.messageData !== undefined) {
        this.getMessageDetailData(this.messageData)
      }
    },
    initToOptions() {
      if (this.isAdmin && this.brands && this.retailers) {
        this.originReceiverList = [...this.brands, ...this.retailers]
      }
      if (this.isRetailer && this.brands) {
        this.originReceiverList = [...this.brands]
      }
      if (this.isBrand && this.retailers) {
        this.originReceiverList = [...this.retailers] //{ id: "*", name: "Landing" },
      }
      this.dataSource = this.originReceiverList.slice(0)
      console.log(this.dataSource)
    },
    setToClientId(messageDataInfo, isInterSendToAdmin) {
      if (this.fromClient === messageDataInfo.senderClientId) {
        this.toClient = []
        messageDataInfo.messageReceivers.forEach(item => this.toClient.push(item.receiverClientId))
      } else {
        this.toClient = [messageDataInfo.senderClientId]
        if (this.fromClient === "*") {
          // admin reply inter-message
          if (
            messageDataInfo.senderClientId !== "*" &&
            messageDataInfo.messageReceivers.length === 1 &&
            messageDataInfo.messageReceivers[0].receiverClientId !== "*"
          ) {
            this.toClient.push(messageDataInfo.messageReceivers[0].receiverClientId)
          }
        } else if (isInterSendToAdmin) {
          this.toClient.push("*")
        }
      }
    },
    openDialog(data, isInterMessage, isSmall = false) {
      this.init(data, isInterMessage, isSmall)
      this.isForwordList = false
    },
    openForwardDialog(data, isInterMessage, isSmall = false) {
      this.isForwordList = true
      this.init(data, isInterMessage, isSmall)
      console.log(this.brands)
      console.log(this.retailers)
    },
    getFindClient(id) {
      let findBrand = undefined
      if (this.brands) {
        findBrand = this.brands.find(item => item.id === id)
      }
      if (!findBrand) {
        findBrand = this.retailers.find(item => item.id === id)
      }
      return findBrand
    },
    getProduct(id) {
      return this.$store.getters["product/find"](id)
    },
    async composeMessageData(data) {
      let isDeleted = false
      let isCurrent =
        data.senderClientId === "*"
          ? this.isAdmin
            ? true
            : false
          : data.senderClientId === this.userId
          ? true
          : false
      data.isCurrentUserSend = isCurrent
      // get from name and avarta
      if (data.senderClientId != "*") {
        let findBrand = this.getFindClient(data.senderClientId)
        // console.log(findBrand)
        data.userHead = findBrand && findBrand.logoAsset ? findBrand.logoAsset.url : undefined
        data.from = findBrand ? findBrand.name : ""
      } else {
        data.from = "Landing"
      }
      if (
        data.messageType &&
        data.messageType.id === "1" &&
        data.messageProducts &&
        data.messageProducts.length > 0
      ) {
        // this is the intro brand product message
        data.messageProducts.forEach(item => {
          let product = this.getProduct(item.product.id)
          if (product) {
            item.product.name = product.name
            item.product.sku = product.sku
            item.product.msrpUsd = product.msrpUsd
            item.product.images = product.images
            item.product.brand = product.brand
            item.product.imageUrl =
              product.images && product.images.length > 0 ? product.images[0].asset.url : undefined
          }
        })
        data.postInfo = `Sincerely,<br/>${data.from}`
      }
      //get receiver name
      if (data.messageReceivers && data.messageReceivers.length > 0) {
        let receiverNames = ""
        let curretClientName = ""
        // console.log(data.privateId)
        for (let i = 0; i < data.messageReceivers.length; i++) {
          if (data.messageReceivers[i].receiverClientId === "*") {
            receiverNames += "Landing"
          } else {
            let find = this.getFindClient(data.messageReceivers[i].receiverClientId)
            if (find) {
              receiverNames += find.name
              // console.log(this.userId)
              // console.log(data.messageReceivers[i].receiverClientId)
              // console.log(this.userId === data.messageReceivers[i].receiverClientId)
              if (this.userId === data.messageReceivers[i].receiverClientId) {
                curretClientName = find.name
                isDeleted = !data.messageReceivers[i].active
              }
            }
          }
          if (i < data.messageReceivers.length - 1) {
            receiverNames += ","
          }
        }
        if (!this.isAdmin && !isCurrent && data.messageReceivers.length > 1) {
          receiverNames = curretClientName
        }
        // console.log(receiverNames)
        data.to = receiverNames
        data.isDelete = isCurrent ? !data.active : isDeleted
      }
    },

    getMessageDetailData(item) {
      const id = item.parentPrivateId ? item.parentPrivateId : item.privateId
      const params = {
        "private-id": id,
        "is-forward": false
      }
      this.getMessageDetailInfo(params, true)
    },
    // getForwardMessageDetail(item) {
    //   const forwardIds =
    //     item.messageForwards && item.messageForwards.length > 0
    //       ? item.messageForwards.map(item => item.forwardMessagePrivateId).join(",")
    //       : undefined
    //   const params = {
    //     "private-id": forwardIds ? forwardIds : id,
    //     "is-forward": forwardIds ? true : false
    //   }
    // },
    getMessageDetailInfo(params, isOuterDetail) {
      this.$store
        .dispatch("adminMessage/getMessageDetail", params)
        .then(res => {
          if (res && res.messages && res.messages.length > 0) {
            let isHasAdmin = false
            res.messages.forEach(data => {
              if (data.senderClientId === "*") {
                isHasAdmin = true
              }
              this.composeMessageData(data)
            })
            // console.log(res)
            if (isOuterDetail) {
              this.firstMessage = res.messages[0]
              let isInterAddAdmin = false
              if (
                this.firstMessage.senderClientId !== "*" &&
                this.firstMessage.messageReceivers.length === 1 &&
                this.firstMessage.messageReceivers[0].receiverClientId !== "*"
              ) {
                // is inter message
                if (isHasAdmin) {
                  isInterAddAdmin = true
                } else {
                  isInterAddAdmin = false
                }
              }
              this.setToClientId(this.firstMessage, isInterAddAdmin)
              // const list = this.getFileName(res.messages)
              // console.log('获取===》', list)
              // this.messageDetails = {
              //   ...res,
              //   messages: res.messages.filter(item => !item.drafts)
              // }
              this.messageDetails = res
              console.log(res)
              // // 如果有draft 信息，直接在message里面显示出来
              // this.draftInfo = this.getDraftMessage(res.messages)
              // console.log("查看draft====》", this.draftInfo)
              if (res.messages && res.messages.length > 0) {
                const lastMessage = res.messages[0]
                // when last message is intro product or intro brand, will waiting retailer reply
                this.isShowWaiting =
                  res.messages.length === 1 &&
                  lastMessage.messageType &&
                  (lastMessage.messageType.id === "1" || lastMessage.messageType.id === "4") &&
                  !this.isForwordList &&
                  lastMessage.messageReceivers[0].receiverClientId !== "*"
              } else {
                this.isShowWaiting = false
              }
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getDraftMessage(message) {
      console.log(99999, message)
      if (message && message.length === 0) {
        return
      }
      const list = message.filter(item => item.drafts)
      console.log("draft信息", list)
      return list[0]
    },
    handleSearch(value) {
      // console.log(value)
      // console.log(option)
      if (!value || value.trim().length === 0) {
        this.dataSource = this.originReceiverList.slice(0)
      } else {
        let result = this.originReceiverList.filter(
          item => item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
        // console.log(result)
        this.dataSource = []
        if (result && result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            this.$set(this.dataSource, i, result[i])
          }
        }
      }
      console.log(this.dataSource)
    },
    onSelectToTarget(data) {
      // console.log(data)
      if (this.isBrand && data !== "*") {
        // console.log(this.retailers)
        // console.log(this.brand)
        const receiverRetailer = this.retailers
          ? this.retailers.find(item => item.id === data)
          : undefined
        this.isToError = !checkBrandRetailerConnection(this.brand, receiverRetailer)
        // console.log(this.isToError)
      }
      this.toClient = [data]
    },
    hideDialog() {
      if (
        this.forwardMessage !== undefined ||
        this.isForwordList ||
        this.editedMessage !== undefined ||
        this.isShowDeleteMessageId.length > 0
      ) {
        this.showCloseConfirm()
      } else {
        // hide dialog
        this.doCloseDialog()
      }
    },
    doCloseDialog() {
      this.showMessageList = false
      this.isSmallStatus = true
      this.messageData = undefined
      this.toClient = undefined
      this.firstMessage = undefined
      this.fromClient = undefined
      this.forwardMessage = undefined
      this.messageDetails = undefined
      this.isShowWaiting = false
      this.isShowDeleteMessageId = []
      this.isToError = false
      this.isItemNoOperation = false
      this.isUpdateScroll = false
    },
    showCloseConfirm() {
      this.$refs["confirmClose"].openDialog(
        "Are you sure you wish to leave? This message has not been saved. ",
        "No",
        "Yes"
      )
    },
    clickToSwitch() {
      if (this.isSmallStatus) {
        this.modalWidth = "1020px"
        this.isSmallStatus = false
      } else {
        this.modalWidth = "561px"
        this.isSmallStatus = true
      }
    },
    getOperationParam(item, isDelete) {
      const params = Object.assign({}, item)
      let curUserId = this.userId
      if (this.isAdmin) {
        if (!this.isRetailer && !this.isBrand) {
          curUserId = "*"
        } else if (this.isRetailer) {
          curUserId = this.$route.params.retailerId
        } else if (this.isBrand) {
          curUserId = this.$route.params.brandId
        }
      }
      if (params.senderClientId === curUserId) {
        params.active = !isDelete
      } else {
        for (let i = 0; i < params.messageReceivers.length; i++) {
          if (params.messageReceivers[i].receiverClientId === curUserId) {
            params.messageReceivers[i].active = !isDelete
            break
          }
        }
      }
      return params
    },
    deleteMessageItem(item) {
      if (this.isForwordList) {
        console.log("delete item in the forward list status")
        item.isDelete = true
        // console.log(this.forwardMessage)
      } else {
        let params = this.getOperationParam(item, true)
        this.isShowDeleteMessageId.push(item.id)
        this.updateMessage(item.id, params, true)
      }
    },
    updateMessage(id, params, isDelete) {
      this.$store
        .dispatch("adminMessage/updateSearchMessage", { id, params })
        .then(res => {
          console.log(res)
          this.$message.success(`${isDelete ? "Delete" : "Undo"} message success!`)
          this.getMessageDetailData(this.messageData)
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    forwardMessageItem(item) {
      // forward one message item
      this.forwardMessage = item
      this.sendMessageType = 2
      this.isForwordList = false
    },
    getFwdAddr(fwdAddr) {
      this.forwardTo = fwdAddr
      this.forwardMessage["fwdTo"] = fwdAddr
    },
    sendForwardMessage() {
      let param = this.composeSendParam()
      param.subject = "Fwd:" + param.subject
      if (this.isForwordList) {
        // forward all the message list, no edit,
        if (this.forwardTo.length > 0) {
          this.forwardAll(param)
        } else {
          this.$message.info("Please input the forward address.")
        }
      } else if (this.forwardMessage) {
        // if forward one message item
        if (!this.forwardTo || this.forwardTo.length === 0) {
          this.$message.info("Please input the forward address.")
          return
        }
        this.doForwardOneMessage(param)
      }
    },
    // send action
    sendMessage(messageStr, fileList) {
      let param = this.composeSendParam(messageStr, fileList)
      this.replayMessage(param)
    },
    sendDraft(messageStr, fileList) {
      let param = this.composeSendParam(messageStr, fileList, true)
      this.replayMessage(param)
    },
    forwardAll(param) {
      console.log("forward all message.....")
      let forwardMessageIds = []
      if (
        this.messageDetails &&
        this.messageDetails.messages &&
        this.messageDetails.messages.length > 0
      ) {
        this.messageDetails.messages.forEach(item => {
          if (!item.isDelete) {
            forwardMessageIds.push({ forwardMessagePrivateId: item.privateId })
          }
        })
      }
      param.messageForwards = forwardMessageIds
      this.createMessage(param)
    },
    doForwardOneMessage(param) {
      //check the forward to can send message? if not have the relation, then can't send message,
      // will change the to address into red color. and info the message.
      // if can send message, then send, if success, info the message.
      param.messageForwards = [{ forwardMessagePrivateId: this.forwardMessage.privateId }]
      // console.log(param)
      this.createMessage(param)
    },
    replayMessage(param) {
      param["parentPrivateId"] = this.firstMessage.parentPrivateId
        ? this.firstMessage.parentPrivateId
        : this.firstMessage.privateId
      // console.log(param)
      this.createMessage(param)
    },
    createMessage(param) {
      this.$store
        .dispatch("adminMessage/createMessage", param)
        .then(() => {
          this.doCloseDialog()
          this.$emit("refresh")
        })
        .catch(err => {
          // console.log(err)
          this.$message.error(err)
        })
    },
    composeSendParam(messageStr, fileList, isDraft) {
      let param = {
        senderClientId: this.fromClient,
        subject: this.messageData.subject,
        message: messageStr ? messageStr : " ",
        archive: false,
        drafts: isDraft ? isDraft : false,
        isActive: true
      }
      let receivers = []
      let filesParam = []
      if (fileList && fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          filesParam.push({
            privateId: i + 1,
            fileAsset: {
              contentType: fileList[i].contentType,
              id: fileList[i].id,
              url: fileList[i].url
            }
          })
        }
      }
      if (filesParam && filesParam.length > 0) {
        param["messageFiles"] = filesParam
      }
      // console.log(this.toClient)
      if (this.toClient && this.toClient.length > 0) {
        for (let i = 0; i < this.toClient.length; i++) {
          receivers.push({
            receiverClientId: this.toClient[i],
            isRead: false,
            isActive: true,
            archive: false
          })
        }
      }
      param["messageReceivers"] = receivers
      if (filesParam && filesParam.length > 0) {
        param["messageFiles"] = filesParam
      }
      if (this.messageData.category) {
        param["category"] = { id: this.messageData.category.description }
      }
      if (this.messageData.messageType) {
        param["messageType"] = { id: this.messageData.messageType.description }
      }
      return param
    },
    undoDelete(message) {
      let index = this.isShowDeleteMessageId.indexOf(message.id)
      this.isShowDeleteMessageId.splice(index, 1)
      this.updateMessage(message.id, this.getOperationParam(message, false))
    }
  }
}
</script>

<style lang="scss" scoped>
.message-list-modal {
  padding: 0px;
  margin: 0px;
  &__title-outer {
    padding: 41px 0px 21px 0px;
  }
  &__title {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    color: #262626;
  }
  &__toolbar-area {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    display: inline-block;
  }
  &__delete-msg-area {
    background: rgba(74, 138, 93, 0.11);
    border-radius: 2px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 50px;
    color: #8c8c8c;
    width: 277px;
    height: 50px;
    margin: 0 auto;
    text-align: center;
  }
  &__subject-txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  &__send-btn {
    background: #4a8a5d;
    width: 89px;
    border-color: #4a8a5d;
    box-shadow: none;
  }
  &__undo {
    color: #389e0d;
    text-decoration: underline;
    cursor: pointer;
  }
  &__waiting-replay {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    color: #8c8c8c;
    text-align: center;
    margin-left: 8px;
  }
  &__waiting-replay-info-layer {
    background: #8c8c8c;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 10px;
    color: #ffffff;
    text-align: center;
    width: 350px;
    padding: 3px 5px 7px 4px;
    margin: 0 auto;
  }
}
</style>

<style lang="scss">
.ant-btn-primary {
  background-color: #4a8a5d;
  border-color: #4a8a5d;
  border-radius: 2px !important;
}
.ant-modal .message-list-modal {
  background: #ffffff;
  border: 1px solid rgba(74, 138, 93, 0.46);
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 0px;
}
.message-list-modal .ant-input {
  border: none;
  background: inherit;
  border-bottom: 1px solid rgba(74, 138, 93, 0.46);
}
.message-list-modal .ant-input:hover,
.message-list-modal .ant-input:focus {
  border-color: rgba(74, 138, 93, 0.46);
  box-shadow: none;
}
.message-list-modal .ant-btn-primary:active,
.message-list-modal .ant-btn-primary:hover,
.message-list-modal .ant-btn-primary:focus {
  color: #fff;
  background: #4a8a5d;
  border-color: #4a8a5d;
  box-shadow: 2px 2px 2px 2px rgb(24 144 255 / 20%);
}
.message-list-modal
  .admin-message-brand-to-error.admin-message-brand-create-new-modal__toTarget.ant-select
  .ant-select-selection-selected-value {
  color: #ff4d4f;
}
</style>
