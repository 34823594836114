import axios from "@/api/axios"

export default {
  getSeller(id) {
    return axios.get(`/sellers/${id}`)
  },
  searchSeller(params = {}) {
    return axios.get("/sellers", { params })
  },
  updateSeller(id, params = {}) {
    return axios.post(`/sellers/${id}?_skipNulls=true`, JSON.stringify(params))
  },
  getSellerStatistics(retailerId) {
    return axios.get(`/sellers/statistics?retailer-id=${retailerId}`)
  },
  getStatisticsRetailer(retailerId) {
    return axios.get(`/sellers-statistics?retailer-id=${retailerId}`)
  },
  searchLoginMessage(params = {}) {
    return axios.get("/login-messages", { params })
  },
  createLoginMessage(params) {
    return axios.post("/login-message", JSON.stringify(params))
  },
  updateLoginMessage(id, params) {
    return axios.post(`/login-messages/${id}`, JSON.stringify(params))
  },
  deleteLoginMessage(ids) {
    return axios.delete(`/login-messages/${ids}`)
  },
  getALoginMessage(id) {
    return axios.get(`/login-messages/${id}`)
  }
}
