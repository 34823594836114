<template>
  <a-modal v-model="isShowMaximumProductsModal"
           :footer="null"
           :destroyOnClose="true"
           class="is-active brand-settings-modal"
           width="601px"
           :bodyStyle="noPadding">
    <template #closeIcon>
      <img class="request-terms-modal__close-button" src="@/assets/img/icon/close-icon.svg" @click="closeProductModal" />
    </template>
    <p class="request-terms-modal__header no-margin">
      {{$t('Brands.BrandSettings.editMaxProducts')}}
    </p>
    <div class="section modal-card-body subscription__form-block padding-small">
      <div class="column-section">
        <label class="label brand-settings-wireinstruction">
          {{$t('Brands.BrandSettings.maxNumberOfProducts')}}
        </label>
        <input class="input brand-settings-wireinstruction"
               v-model="maxProducts"
               :class="{'is-danger': $v.maxProducts.$error}"
               @input="resetValidationFlags"/>
        <p class="help is-danger" v-if="!$v.maxProducts.required">
          {{$t('Brands.BrandSettings.maxProductsCannotBeEmpty')}}
        </p>
        <p class="help is-danger" v-if="!$v.maxProducts.numeric">
          {{$t('Brands.BrandSettings.enterNumericValue')}}
        </p>
      </div>
      <div class="modal-card-foot modal-actions">
        <a class="button is-white" @click ="closeProductModal">
          {{$t('Brands.BrandSettings.cancel')}}
        </a>
        <a class="button is-black" @click ="saveMaxProducts">
          {{$t('Brands.BrandSettings.save')}}
        </a>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import { required, numeric } from "vuelidate/lib/validators"
  import { Modal } from "ant-design-vue"
  export default {
    validations: {
      maxProducts: { required, numeric }
    },
    props: {
      brand: { type: Object, default: () => undefined }
    },
    components: {
      "a-modal": Modal
    },
    data() {
      return {
        isShowMaximumProductsModal: false,
        showValidation: false,
        maxProducts: undefined,
        brandInfo: undefined,
        noPadding: {
          padding: "0px"
        }
      }
    },
    updated() {
      if ( this.brandInfo === undefined && this.brand !== undefined ) {
        this.brandInfo = this.brand
      }
      if ( this.maxProducts === undefined && this.brand !== undefined ) {
        this.maxProducts = this.brand.scopes.maxProducts
      }
    },
    methods: {
      async saveMaxProducts() {
        this.$v.maxProducts.$touch()
        if (this.$v.maxProducts.$invalid) {
          return
        }
        try {
          this.brandInfo.scopes.maxProducts = this.maxProducts
          await this.$store.dispatch("brand/updateSubscriptions", this.brandInfo.scopes)
          this.closeProductModal()
        } catch (error) {
          this.showValidation = true
          console.error(error)
        }
      },
      closeProductModal() {
        this.isShowMaximumProductsModal = false
        this.maxProducts = this.brand.scopes.maxProducts
      },
      openDialog() {
        this.isShowMaximumProductsModal = true
      },
      resetValidationFlags() {
        this.showValidation = false
      }
    }
  }
</script>

<style lang="sass">

</style>
