<template>
  <div ref="editAllProduct" class="edit-product-all-info-modal__main">
    <a-modal
      v-model="editAllProductVisible"
      width="990px"
      :footer="null"
      :title="null"
      :closable="false"
      :mask-closable="false"
      :get-container="() => $refs.editAllProduct"
      @cancel="handleCancel"
    >
      <div class="position-style">
        <a-icon
          class="button-style-top"
          :style="{ color }"
          theme="filled"
          type="close-square"
          @click="closeModal"
        />
      </div>
      <div class="modal-main-edit-product">
        <p class="edit-product__title-text">Product</p>
        <table class="table is-fullwidth">
          <thead>
            <th class="retailer-order-table__head">{{ $t("Retailers.RetailerOrder.sKU") }}</th>
            <th class="retailer-order-table__head">Name</th>
            <th class="retailer-order-table__head">{{ $t("Retailers.RetailerOrder.quantity") }}</th>
            <th class="retailer-order-table__head">{{ $t("Retailers.RetailerOrder.testers") }}</th>
            <th class="retailer-order-table__head">{{ $t("Retailers.RetailerOrder.mSRP") }}</th>
            <th class="retailer-order-table__head">
              {{ $t("Retailers.RetailerOrder.unitPrice") }}
            </th>
            <th class="retailer-order-table__head">
              {{ $t("Retailers.RetailerOrder.amount") }}
            </th>
            <th v-if="isBrand || isAdmin" class="retailer-order-table__head">Notes</th>
          </thead>
          <tbody v-for="(item, index) in productList" :key="index">
            <tr v-if="item.quantityType && item.quantityType.id === 'purchase'">
              <td class="retailer-order-table__cell">{{ item.product && item.product.sku }}</td>
              <td class="retailer-order-table__cell">{{ item.product && item.product.name }}</td>
              <td class="retailer-order-table__cell">
                <a-input-number
                  v-model="item.adjustedQuantity"
                  :disabled="!item.canInput"
                  :min="0"
                  :max="checkProductItemStock() && item.stockTotal ? item.stockTotal : Infinity"
                  @change="updataProduct(item)"
                />
                <span
                  v-if="
                    checkProductItemStock() &&
                      item.stockTotal < Number(item.adjustedQuantity) + Number(item.editTesterValue)
                  "
                  style="color: #F5222D;width: 100px"
                >
                  <a-icon type="exclamation-circle" theme="filled" />
                  Only {{ item.stockTotal }} in Stock, Edit Quantity or Delete
                </span>
              </td>
              <td class="retailer-order-table__cell">
                <a-input-number
                  v-model="item.editTesterValue"
                  :disabled="!item.canInput"
                  :min="0"
                  :max="checkProductItemStock() && item.stockTotal ? item.stockTotal : Infinity"
                  @change="updateTesterProduct(item)"
                />
                <span
                  v-if="
                    checkProductItemStock() &&
                      item.stockTotal < Number(item.adjustedQuantity) + Number(item.editTesterValue)
                  "
                  style="color: #F5222D;width: 100px"
                >
                  <a-icon type="exclamation-circle" theme="filled" />
                  Only {{ item.stockTotal }} in Stock, Edit Tester or Delete
                </span>
              </td>
              <td class="retailer-order-table__cell">{{ priceFormat(item.recordedMsrpUsd) }}</td>
              <td class="retailer-order-table__cell">
                {{ priceFormat(item.calculatedWholesalePriceUsd) }}
              </td>
              <!--              <td class="retailer-order-table__cell">{{priceFormat(item.calculatedWholesaleCostUsd)}}</td>-->
              <td class="retailer-order-table__cell">
                {{
                  priceFormat(
                    (item.adjustedQuantity ? item.adjustedQuantity : item.quantity) *
                      item.calculatedWholesalePriceUsd
                  )
                }}
              </td>
              <td v-if="isBrand || isAdmin" class="retailer-order-table__cell">
                <a-select
                  v-model="item.noteType.id"
                  style="width: 120px"
                  placeholder="value"
                  @change="updataProduct(item)"
                >
                  <a-select-option v-for="item in notesList" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </td>
            </tr>
          </tbody>
        </table>
        <!--        <p class="button-apply-box">-->
        <!--          <span class="edit-product__apply-button_modal" :style="{background: buttonColor}" @click="applyProduct">Apply</span>-->
        <!--        </p>-->
      </div>
      <div v-if="(isAdmin || isRetailer) && isShowRetailerTerms" class="is-transparent"></div>
      <div v-if="(isAdmin || isRetailer) && isShowRetailerTerms" class="modal-main-edit-product">
        <div class="title-flex-style">
          <p class="edit-product__title-text">Retailer Terms</p>
          <a-icon type="close" @click="closeRetailerTerms" />
        </div>
        <table class="table is-fullwidth">
          <thead>
            <th class="retailer-order-table__head">Retailer Terms</th>
            <th class="retailer-order-table__head">Type</th>
            <th class="retailer-order-table__head">Description</th>
            <th class="retailer-order-table__head">Amount</th>
          </thead>
          <tbody v-for="(item, index) in chargeList" :key="index">
            <tr>
              <td v-if="!item.isAddNewValue" class="retailer-order-table__cell">
                {{ item.note || "-" }}
              </td>
              <td v-else class="retailer-order-table__cell">
                <a-input v-model="item.note" @change="modifyRecorded"></a-input>
              </td>
              <td class="retailer-order-table__cell">{{ item.paymentType.name }}</td>
              <td v-if="!item.isAddNewValue" class="retailer-order-table__cell">
                {{ item.recordedValue }}%
              </td>
              <td v-else class="retailer-order-table__cell">
                <a-input-search v-model="item.recordedValue" @change="modifyRecorded">
                  <a-button slot="enterButton" :disabled="true">
                    %
                  </a-button>
                </a-input-search>
              </td>
              <td
                v-if="!item.isAddNewValue && item.paidBy && item.paidBy.id == 'brand'"
                class="retailer-order-table__cell"
              >
                ({{
                  item.paidBy.id === "brand"
                    ? priceFormat(item.calculatedCostUsd)
                    : priceFormat(item.calculatedCostUsd)
                }})
              </td>
              <td v-else class="retailer-order-table__cell">
                <a-input v-model="item.calculatedCostUsd"></a-input>
              </td>
            </tr>
          </tbody>
          <span
            v-if="!isShowAddRetailer"
            class="add-new-terms"
            :style="{ color: color }"
            @click="addNewTerms"
            >+ADD</span
          >
        </table>
      </div>
      <div v-if="isAdmin || isBrand" class="is-transparent"></div>
      <div v-if="isAdmin || isBrand" class="modal-main-edit-product">
        <div class="title-flex-style">
          <p class="edit-product__title-text">Additional Charge</p>
        </div>
        <div class="additional-charge-content">
          <div class="select-or-input">
            <p>Type</p>
            <a-select v-model="typeValue" style="width: 294px" placeholder="Select">
              <a-select-option v-for="item in selectType" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="select-or-input">
            <p>Amount</p>
            <a-input
              v-model="amountValue"
              style="width: 294px"
              placeholder="Input"
              type="number"
            ></a-input>
          </div>
          <div class="select-or-input">
            <p>Description</p>
            <a-textarea
              v-model="descriptionText"
              style="width: 294px"
              :auto-size="{ minRows: 5, maxRows: 7 }"
              placeholder="Enter description"
            ></a-textarea>
          </div>
        </div>
      </div>
      <div class="is-transparent"></div>
      <div class="modal-main-edit-product">
        <div class="title-flex-style">
          <p class="edit-product__title-text">Ship Date</p>
          <a-icon
            :type="showShipContent ? 'down' : 'up'"
            @click="showShipContent = !showShipContent"
          />
        </div>
        <div v-if="showShipContent" class="date-content-style">
          <p class="date-Flexible">
            <a-checkbox
              v-model="checkShipFlexible"
              style="margin-right: 15px"
              @change="changeShipFlexible"
            />
            <a-input v-model="shipValue" :disabled="true" style="width: 256px" />
          </p>
          <div class="date-Flexible">
            <a-checkbox
              v-model="checkshipDate"
              style="margin-right: 15px"
              @change="changeShipDate"
            />
            <a-date-picker
              v-model="shipDate"
              format="YYYY-MM-DD"
              style="width: 256px"
              @change="onChangeStartDate"
            />
          </div>
        </div>
      </div>
      <div class="is-transparent"></div>
      <div class="modal-main-edit-product">
        <div class="title-flex-style">
          <p class="edit-product__title-text">Cancel Date</p>
          <a-icon
            :type="showCancelContent ? 'down' : 'up'"
            @click="showCancelContent = !showCancelContent"
          />
        </div>
        <div v-if="showCancelContent" class="date-content-style">
          <p class="date-Flexible">
            <a-checkbox
              v-model="checkFlexible"
              :style="{ marginRight: '15px' }"
              @change="changeCancelFlexible"
            />
            <a-input v-model="cancelValue" :disabled="true" style="width: 256px" />
          </p>
          <div class="date-Flexible">
            <a-checkbox
              v-model="checkCancelDate"
              style="margin-right: 15px"
              @change="changeCancelDate"
            />
            <a-date-picker
              v-model="cancelDate"
              format="YYYY-MM-DD"
              style="width: 256px"
              @change="onChangeCancelDate"
            />
          </div>
        </div>
      </div>
      <div class="is-transparent"></div>
      <div class="position-style">
        <span class="button-style" :style="{ backgroundColor: color }" @click="applyEdit"
          >Apply</span
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import constants from "@/config.js"
import { priceFormat } from "@/utils/validate"

export default {
  name: "EditProductAllInfoModal",
  props: {
    purchaseDetails: { type: Array, default: () => [] },
    // isProductEditable: { type: Boolean },
    // initialQuantity: { type: Number },
    // alignCenter: { type: Boolean },
    // productEditIndex: { type: Number },
    buttonColor: { type: String }
  },
  data() {
    return {
      priceFormat,
      editAllProductVisible: false,
      isShowAdd: false,
      isShowRetailerTerms: true,
      showShipContent: true,
      showCancelContent: true,
      checkShipFlexible: true,
      checkshipDate: false,
      checkFlexible: true,
      checkCancelDate: false,
      shipValue: "Flexible",
      cancelValue: "Flexible",
      status: "",
      chargeList: [],
      color: "",
      order: {},
      shipDate: null,
      cancelDate: null,
      saveProStorageList: [],
      typeValue: null,
      amountValue: null,
      descriptionText: null,
      selectType: [
        {
          id: "credit",
          name: "Credit"
        },
        {
          id: "debit",
          name: "Debit"
        }
      ],
      canNotApply: false
    }
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    notesList() {
      return this.$store.getters["reference/orderItemNoteType"]
    },
    productList() {
      const list = JSON.parse(JSON.stringify(this.purchaseDetails))
      return list.map(item => {
        if (!item.noteType) {
          item.noteType = {
            id: ""
          }
        }
        item.adjustedQuantity =
          item.adjustedQuantity || item.adjustedQuantity === 0
            ? Number(item.adjustedQuantity)
            : Number(item.quantity)
        item.editTesterValue =
          item.adjustedTestersQuantity || item.adjustedTestersQuantity === 0
            ? Number(item.adjustedTestersQuantity || 0)
            : Number(item.testersquantity || 0)
        item.canInput = true
        item.stockTotal =
          (item.stock ? item.stock : 0) + item.adjustedQuantity + item.editTesterValue
        if (item.noteType.id === "oos") {
          item.canInput = false
          item.adjustedQuantity = 0
        }
        return item
      })
    },
    isShowAddRetailer() {
      const list = this.chargeList.filter(item => item.isAddNewValue)
      return list && list.length !== 0
    },
    additionalCharges() {
      let result
      if (this.isBrand) {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item => item.seenByBrand && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      } else if (this.isRetailer) {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByRetailer && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      } else {
        result =
          this.order.charges &&
          this.order.charges.filter(
            item =>
              item.seenByLanding && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
          )
      }
      return result
    }
  },
  methods: {
    closeModal() {
      this.editAllProductVisible = false
    },
    openDialog(obj, status, buttonColor) {
      this.editAllProductVisible = true
      this.status = status
      this.order = obj
      this.saveProStorageList = this.productList
      const retailerList = []
      this.chargeList = retailerList && retailerList.length !== 0 ? retailerList : obj.charges
      if (this.isRetailer) {
        this.chargeList = this.chargeList.filter(
          item =>
            item.seenByRetailer && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      }
      if (this.isAdmin) {
        this.chargeList = this.chargeList.filter(
          item => item.seenByLanding && item.ownedBy.id === "retailer" && item.paidBy.id === "brand"
        )
      }
      this.color = buttonColor
      if (this.additionalCharges && this.additionalCharges.length !== 0) {
        const list = this.additionalCharges.filter(
          item => item.seenByLanding && item.ownedBy.id === "brand" && item.paidBy.id === "retailer"
        )
        this.amountValue = list[0].calculatedCostUsd
        this.typeValue = list[0].paymentType.id
        this.descriptionText = list[0].note
      }
    },
    updataProduct(value) {
      if (
        this.checkProductItemStock() &&
        value.stock &&
        value.adjustedQuantity > value.stockTotal
      ) {
        this.canNotApply = true
      } else {
        this.canNotApply = false
      }
      value.calculatedWholesaleCostUsd = value.adjustedQuantity
        ? value.adjustedQuantity * value.calculatedWholesalePriceUsd
        : value.quantity * value.calculatedWholesalePriceUsd
      if (
        this.saveProStorageList.some(
          l => l.product.id === value.product.id && l.quantityType.id === "purchase"
        )
      ) {
        this.saveProStorageList = this.saveProStorageList.map(item => {
          if (item.product.id === value.product.id && item.quantityType.id === "purchase") {
            if (value.noteType.id === "oos") {
              // value.quantity = 0
              value.adjustedQuantity = 0
              value.calculatedWholesaleCostUsd = 0
              value.canInput = false
            } else {
              value.canInput = true
              value.adjustedQuantity =
                value.adjustedQuantity || value.adjustedQuantity === 0
                  ? value.adjustedQuantity
                  : value.quantity
            }
            let editName
            if (this.isAdmin) editName = "landing"
            if (this.isBrand) editName = "brand"
            if (this.isRetailer) editName = "retailer"
            value.adjusterByClientType = {
              id: editName
            }
            item = { ...value }
          }
          return item
        })
      } else {
        this.saveProStorageList.push(value)
      }
      if (
        this.checkProductItemStock() &&
        this.saveProStorageList.filter(
          item => Number(item.adjustedQuantity) > Number(item.stockTotal)
        ).length !== 0
      ) {
        this.color = "#bfbfbf"
      } else {
        this.color = this.buttonColor
      }
    },
    updateTesterProduct(value) {
      let editName
      if (this.isAdmin) editName = "landing"
      if (this.isBrand) editName = "brand"
      if (this.isRetailer) editName = "retailer"
      value.adjusterByClientType = {
        id: editName
      }
      const params = {
        ...value,
        quantity: value.testersquantity || 0,
        adjustedQuantity: value.editTesterValue,
        quantityType: {
          id: "testers"
        }
      }
      if (
        this.saveProStorageList.some(
          l => l.product.id === params.product.id && l.quantityType.id === "testers"
        )
      ) {
        this.saveProStorageList = this.saveProStorageList.map(item => {
          if (item.product.id === value.product.id && item.quantityType.id === "testers") {
            item = { ...params }
          }
          return item
        })
      } else {
        this.saveProStorageList.push(params)
      }
      if (
        this.saveProStorageList.filter(
          item => Number(item.adjustedQuantity) > Number(item.stockTotal)
        ).length !== 0
      ) {
        this.color = "#bfbfbf"
      } else {
        this.color = this.buttonColor
      }
    },
    addNewTerms() {
      const newObj = {
        isAddNewValue: true,
        calculatedCostUsd: "",
        ownedBy: {
          id: "retailer"
        },
        paidBy: {
          id: "brand"
        },
        addedBy: {
          id: this.isAdmin ? "landing" : "brand"
        },
        paymentType: {
          id: "credit",
          name: "Credit"
        },
        chargeType: {
          id: "custom"
        },
        note: "",
        percentage: true,
        seenByBrand: true,
        seenByLanding: true,
        seenByRetailer: true
      }
      this.chargeList = this.chargeList
        .map(item => {
          item.isAddNewValue = false
          return item
        })
        .filter(
          item =>
            !(
              item.chargeType.id === "custom" &&
              item.ownedBy.id === "retailer" &&
              item.paidBy.id === "brand"
            )
        )
      this.chargeList.push(newObj)
      this.isShowAdd = true
    },
    closeRetailerTerms() {
      this.isShowRetailerTerms = false
    },
    modifyRecorded() {
      this.chargeList = this.chargeList.map(item => {
        if (item.isAddNewValue) {
          item.calculatedCostUsd = (
            (item.recordedValue / 100) *
            this.order.calculatedWholesaleCostUsd
          ).toFixed(2)
        }
        return item
      })
      // setSesStorage("SAVE_EDIT_RETAILER_TERMS_LIST", this.chargeList)
      // this.$emit("updateRetailerTerms")
    },
    modifyName(value) {
      this.chargeList = this.chargeList.map(item => {
        if (item.isAddNewValue) {
          item = value
        }
        return item
      })
      // setSesStorage("SAVE_EDIT_RETAILER_TERMS_LIST", this.chargeList)
    },
    onChangeStartDate(value, dateStr) {
      console.log("shipDate", value, dateStr)
      this.shipDate = dateStr
    },
    onChangeCancelDate(value, dateStr) {
      console.log("shipDate", value, dateStr)
      this.cancelDate = dateStr
    },
    changeShipFlexible() {
      this.checkshipDate = !this.checkShipFlexible
    },
    changeShipDate() {
      this.checkShipFlexible = !this.checkshipDate
    },
    changeCancelFlexible() {
      this.checkCancelDate = !this.checkFlexible
    },
    changeCancelDate() {
      this.checkFlexible = !this.checkCancelDate
    },
    formatUsersAccepted() {
      let obj = {
        isBrandAccepted: false,
        isRetailerAccepted: false,
        isLandingAccepted: false
      }
      if (this.isAdmin) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = true
      }
      if (this.isBrand) {
        obj.isBrandAccepted = true
        obj.isRetailerAccepted = false
        obj.isLandingAccepted = false
      }
      if (this.isRetailer) {
        obj.isBrandAccepted = false
        obj.isRetailerAccepted = true
        obj.isLandingAccepted = false
      }
      return obj
    },
    formatProductList() {
      let orderMap = new Map()
      this.order.items.forEach(item => {
        orderMap.set(`${item.product.id}${item.quantityType.id}`, item)
      })
      this.saveProStorageList.forEach(value => {
        orderMap.set(`${value.product.id}${value.quantityType.id}`, value)
      })
      let saveList = []
      orderMap.forEach(value => {
        saveList.push(value)
      })
      return saveList
    },
    async applyEdit() {
      if (this.canNotApply) {
        return
      }
      if (this.color === "#bfbfbf") {
        return
      }
      const acceptInfo = this.formatUsersAccepted()
      const items = this.formatProductList()
      const sendChargeList = this.order.charges
        .filter(
          item =>
            !(
              item.chargeType.id === "custom" &&
              item.ownedBy.id === "brand" &&
              item.paidBy.id === "retailer"
            )
        )
        .filter(
          item =>
            !(
              item.chargeType.id === "custom" &&
              item.ownedBy.id === "retailer" &&
              item.paidBy.id === "brand"
            )
        )
      const retailerCharge = this.chargeList.filter(
        item =>
          item.chargeType.id === "custom" &&
          item.ownedBy.id === "retailer" &&
          item.paidBy.id === "brand"
      )
      const additionCharge = this.order.charges.filter(
        item =>
          item.chargeType.id === "custom" &&
          item.ownedBy.id === "brand" &&
          item.paidBy.id === "retailer"
      )
      let order = {
        ...acceptInfo,
        id: this.order.id,
        shipDate: this.checkshipDate ? this.shipDate : null,
        cancelDate: this.checkCancelDate ? this.cancelDate : null,
        version: Number(this.order.version) + 1,
        items,
        charges: [...sendChargeList]
      }
      // if (this.isShowRetailerTerms && (this.isAdmin || this.isRetailer)) {
      order.charges = order.charges.concat(retailerCharge)
      // }
      if (this.isAdmin || this.isBrand) {
        const addition = {
          percentage: false,
          recordedValue: this.amountValue,
          calculatedCostUsd: this.amountValue,
          paymentType: {
            id: this.typeValue
          },
          ownedBy: {
            id: "brand"
          },
          paidBy: {
            id: "retailer"
          },
          addedBy: {
            id: this.isAdmin ? "landing" : "brand"
          },
          chargeType: {
            id: "custom"
          },
          note: this.descriptionText,
          seenByBrand: true,
          seenByRetailer: true,
          seenByLanding: true
        }
        order.charges.push(addition)
      } else {
        order.charges = order.charges.concat(additionCharge)
      }
      // this.$emit("updataEditProduct", params)
      try {
        let params = {}
        if (this.$route.name == "adminBrandOrder") {
          params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
        }
        if (this.$route.name == "adminRetailerOrder") {
          params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
        }
        await this.$store.dispatch("order/updateStatus", { order, params })
        await this.$store.dispatch("order/getOrder", this.$route.params.id)
        this.editAllProductVisible = false
        this.$emit("updateRetailerTerms")
      } catch (error) {
        console.error(error)
      }
    },
    handleCancel() {
      this.editAllProductVisible = false
      this.canNotApply = false
      this.saveProStorageList = []
      this.chargeList = []
    },
    checkProductItemStock() {
      if (!(this.order && this.order.retailer && this.order.retailer.id)) {
        return false
      }
      const retailerLogistic = this.brand.logisticTerms.filter(
        item => item.retailerId === this.order.retailer.id
      )
      const retailerStardand = this.brand.logisticTerms.filter(
        item => item.retailerId === "*" && item.brandId === this.brand.id
      )
      return (
        (retailerStardand && retailerStardand.length !== 0 && retailerStardand[0].enabled) ||
        (retailerLogistic && retailerLogistic.length !== 0)
      )
    }
  }
}
</script>

<style lang="scss">
.edit-product-all-info-modal__main {
  p {
    margin-bottom: 0;
  }
  .is-transparent {
    width: 100%;
    height: 12px;
    opacity: 0;
  }
  .modal-main-edit-product {
    background-color: white;
    padding: 24px;
  }
  .ant-modal-content {
    background-color: rgba(255, 255, 255, 0);
    -webkit-box-shadow: none;
  }
  .title-flex-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .date-content-style {
    margin-left: 345px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .date-Flexible {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }
  .position-style {
    position: relative;
    .button-style {
      padding: 5px 16px;
      color: white;
      cursor: pointer;
      border-radius: 2px;
      position: absolute;
      top: 0;
      right: 0;
    }
    .button-style-top {
      font-size: 24px;
      padding: 5px 16px;
      color: white;
      cursor: pointer;
      border-radius: 2px;
      position: absolute;
      top: -40px;
      right: -18px;
    }
  }
  .additional-charge-content {
    margin-left: 345px;
  }
}
</style>
