<template lang="pug">
.brands-items.columns.is-multiline
  .column.is-3(v-for="brand in brands" :key="brand.id")
    .brand-item(v-if="isAdmin")
      router-link(:to="{ name: isAdminRetailerRoute?'adminRetailerBrandProfile':'brandProfileAdmin', params: { brandId: brand.id }}")   
        .brand-item__thumb
          LogoOrPlaceholder(:url="logoImage(brand)")
      .brand-item__body--title {{ brand.name }}
    .brand-item(v-else)
      router-link(:to="{ name: 'retailerBrandProfile', params: { brandId: brand.id }}")
        .brand-item__thumb
          LogoOrPlaceholder(:url="logoImage(brand)")
      .brand-item__body--title {{ brand.name }}
</template>

<script>
import LogoOrPlaceholder from "@/components/cropper/LogoOrPlaceholder"
import constants from "@/config"

export default {
  components: {
    LogoOrPlaceholder
  },

  props: {
    brands: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    isAdminRetailerRoute() {
      return constants.adminRetailerRoutes.includes(this.$route.name)
    }
  },
  methods: {
    logoImage(brand) {
      return brand.logoAsset && brand.logoAsset.url
    }
  }
}
</script>

<style lang="sass">
.brands-items
  padding-top: 20px

  .brand-item
    text-align: center
    width: 200px
    font-size: 12px
    font-weight: 300
    position: relative

    &__thumb
      background-color: #fff
      text-align: center
      display: flex
      justify-content: center
      align-items: center
      height: 200px

    &__body--title
      color: #000000
      padding-bottom: 30px
      padding-top: 20px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
</style>
