<template lang="pug">
.brand-catalog
    .brand-catalog__banner
      img.banner-img(:src="brand.bannerAsset.url" v-if="brand.bannerAsset.url")
      img.banner-img(v-else src="@/assets/img/default-banner-image.png")
    .brand-catalog__header
      .columns.is-gapless
        .column.is-narrow
          .brand-catalog__logo
            LogoOrPlaceholder(:url="brand.logoAsset.url")
        .column.is-narrow
          p.brand-catalog__name {{ brand.name }}
          p.brand-catalog__country(
            v-if="hq && hq.city"
          ) {{ [hq.city, countryName(hq.country.id)].join(", ") }}
        .column
          .field.is-grouped.is-grouped-right.brand-catalog__icons
            .control(v-for="socialAccount in brand.socialAccounts" :key="socialAccount.platform.id")
              a.is-inline-block(target="_blank" @click="goToSocialMedia(socialAccount)")
                img(v-show="socialAccount.address" target="_blank" :src="require(`@/assets/img/icon/${socialAccount.platform.id}-icon.svg`)" width='23' alt='')
      .columns
        .column.brand-catalog__description
          p {{ brand.introduction }}
          .brand-catalog__website
            a(target="_blank" @click="goToWebsite") {{$t('Brands.BrandCatalog.visitWebsite')}}
        .column.is-4.brand-new-product__add-button(v-show="brand.status && brand.status.id !== 'pending'")
          .productUploadBox(v-show="productCount && maxProducts && !showPlan && !isNewModalOpen && showUploadCard && uploadPercentage>75")
            vs-card
              p.headerTxt Upgrade to upload more products
              vs-progress.uploadProgressBar( :percent="uploadPercentage" color="primary")
              p.bodyTxt(v-if="uploadPercentage<100") {{ 'You have ' + (maxProducts - productCount) + ' out of ' + maxProducts + ' product uploads remaining.'}}
              p.bodyTxt(v-else) {{ 'You have reached your limit.'}}
              .column.subscribeActionCntnr
                a.upgrade-button.button.brand-settings__account-modal-form(v-if="!isAdmin" @click="showDialog") {{$t('Brands.BrandSettings.upgradePlan')}}
          a.addProductBtn(@click="uploadProducts", style="margin-right: 15px")
            span + Upload Products
          a.addProductBtn(v-if="productCount<maxProducts",@click="openModal") + {{$t('Brands.BrandCatalog.addProduct')}}
          .brand-new-product__add-button--disabled(v-else)
            span + {{$t('Brands.BrandCatalog.addProduct')}}
      .brand-new-product__add-button--disabled(v-show="brand.status && brand.status.id === 'pending'" @mouseover="showPopup = true" @mouseout="hidePopup")
        span + {{$t('Brands.BrandCatalog.addProduct')}}
        span
          .brand-catalog-popup
            span.brand-catalog-popuptext(:class="{'show': showPopup}") {{$t('Brands.BrandCatalog.brandNotApprovedMessage')}}
      .modal(:class="{'is-active': isNewModalOpen}")
        BrandProductModal(
          v-if="isNewModalOpen"
          @modalClose="closeModal"
          @addingProduct="addProduct"
          :brandId="brand.id"
        )
    .columns.is-multiline.catalog-content
      .column.is-3.is-paddingless(v-show="!isEmptyCatalog && displayEmptyResult")
        .brand-catalog__categories
          .brand-catalog__categories--header {{$t('Brands.BrandCatalog.shopByCategory')}}
          .brand-catalog__categories--back-to(v-show="activeCategories.length" @click="backToCategories()") < {{$t('backToAllBeauty')}}
          CategoriesFilter(
            v-show="activeCategories.length === 0"
            :categoryFilterItems="categoryFilterItems"
            @filterSwitch="fetchDataWhenClickFilter"
          )
          SubcategoriesProductsFilter(
            v-show="activeCategories.length && activeSubcategories.length === 0"
            :subcategories="subcategoryFilterItems"
            :activeCategories="activeCategories"
            @filterSwitch="fetchDataWhenClickFilter"
          )
          MicroCategoriesProductsFilter(
            v-show="activeSubcategories.length"
            :microCategories="microCategoryFilterItems"
            :activeSubcategories="activeSubcategories"
            @filterSwitch="fetchDataWhenClickFilter"
          )
        .brand-catalog__filter-header {{$t('Brands.BrandCatalog.filterBy')}}
        .brand-catalog__filter
          //ProductsFilter(:title="'Tags'" :items="tagFilterItems" @filterSwitch="fetchProducts")
          ProductsFilter(:title="'Product Value'" :items="healthIngredientsTag" @filterSwitch="fetchDataWhenClickFilter" @option="option")
          ProductsFilter(:title="'Featured'" :items="featuredTag" @filterSwitch="fetchDataWhenClickFilter")

          ProductsFilter(:title="'Type'" :items="typeFilterItems" @filterSwitch="fetchDataWhenClickFilter")
          PriceRangeFilter(
            v-if="activeCategories.length && canShowRange(products)"
            :products="products"
            @filterSwitch="filterProductsByPrice"
            :totalMin="productsMinPrice"
            :totalMax="productsMaxPrice"
          )
      .column.brand-catalog__products.brand-product
        .brand-catalog__no-products(v-show="isEmptyCatalog") {{$t('Brands.BrandCatalog.noProductsInCatalogMessage')}}
        .brand-catalog__not-found(v-show="isResultsEmpty && !isEmptyCatalog")
          p {{$t('Brands.BrandCatalog.noProductsMatchingFilterMessage')}}
          p {{$t('Brands.BrandCatalog.changeFilterToSeeProductMessage')}}

        .columns.is-multiline
          .column.is-3(v-for="product in products" :key="product.id")
            .brand-catalog__product
              BrandProductItem( @beforeProductEdit="beforeProductEdit",@afterProductEdit="afterProductEdit" :product="nullSafeProduct(product)" @removingProduct="removeProduct" @updatingProduct="fetchProducts")
        Pagination(
          v-show="displayEmptyResult && !isEmptyCatalog && !isResultsEmpty"
          :page="page"
          @nextPage="nextPage"
          @previousPage="previousPage"
          @resetPagination="changeLimit"
          @lastPage="lastPage"
          :maxPage="productPageData.pages"
          :isPaginationLoading="isPaginationLoading"
        )
        UpgradePlan(ref="brandTableDialogCatalog")
    uploadProductDialog(ref="uploadProduct" @doUpload="doUpload")
</template>

<script>
import BrandProductItem from "@/components/brands/products/BrandProductItem";
import ProductsFilter from "@/components/filters/ProductsFilter";
import PriceRangeFilter from "@/components/filters/PriceRangeFilter";
import CategoriesFilter from "@/components/filters/CategoriesFilter.vue";
import SubcategoriesProductsFilter from "@/components/filters/SubcategoriesProductsFilter";
import MicroCategoriesProductsFilter from "@/components/filters/MicroCategoriesProductsFilter";
import Pagination from "@/components/Pagination";
import LogoOrPlaceholder from "@/components/cropper/OldLogoOrPlaceholder";
import BannerOrPlaceholder from "@/components/cropper/BannerOrPlaceholder";
import BrandProductModal from "@/components/brands/products/BrandProductModal";
import { nullSafeProduct } from "@/mixins/null-safe-product.js";
import UpgradePlan from "@/components/brands/mainPage/components/BrandTableDialog";
import uploadProductDialog from "@/components/brands/catalog/uploadProductDialog";
import uploadService from "@/services/upload-service";

export default {
  components: {
    BrandProductItem,
    ProductsFilter,
    PriceRangeFilter,
    CategoriesFilter,
    SubcategoriesProductsFilter,
    MicroCategoriesProductsFilter,
    Pagination,
    LogoOrPlaceholder,
    BannerOrPlaceholder,
    BrandProductModal,
    UpgradePlan,
    uploadProductDialog,
  },
  mixins: [nullSafeProduct],
  data() {
    return {
      products: [],
      productPageData: {},
      tagFilterItems: [],
      typeFilterItems: [],
      healthIngredientsTag: [],
      featuredTag: [],
      categoryFilterItems: [],
      subcategoryFilterItems: [],
      microCategoryFilterItems: [],
      visibleItems: [12, 24, 30],
      query: {
        brand: this.brandId,
        published: `*`,
      },
      displayEmptyResult: false,
      isEmptyCatalog: false,
      isNewModalOpen: false,
      showPopup: false,
      isPaginationLoading: false,
      page: 1,
      showPlan: false,
      filterOption: "",
      showUploadCard: true,
    };
  },
  computed: {
    isResultsEmpty() {
      return !this.products.length && this.displayEmptyResult;
    },
    productCount() {
      let brand = this.$store.getters["brand/brand"];
      return (brand && brand.productCount && brand.productCount.count) || 0;
    },
    maxProducts() {
      let brand = this.$store.getters["brand/brand"];
      return (brand && brand.scopes && brand.scopes.maxProducts) || 0;
    },
    uploadPercentage() {
      return (
        (this.maxProducts && (this.productCount / this.maxProducts) * 100) || 0
      );
    },
    productMaxPage() {
      return parseInt(this.$store.getters["product/productsSearchPageCount"]);
    },
    productsMinPrice() {
      return 0;// this.$store.getters["product/productsSearchMinPrice"];
    },
    productsMaxPrice() {
      return 999;// this.$store.getters["product/productsSearchMaxPrice"];
    },
    hq() {
      return (
        this.brand.addresses &&
        this.brand.addresses.find((address) => address.hq)
      );
    },
    brand() {
      return this.$store.getters["brand/brand"];
    },
    brandId() {
      return this.isAdmin
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"];
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"];
    },
    // activeTags() {
    //   return this.tagFilterItems.filter(item => item.checked)
    // },
    activeHealthIngredientsTags() {
      return this.healthIngredientsTag.filter((item) => item.checked);
    },
    activeFeaturedTags() {
      return this.featuredTag.filter((item) => item.checked);
    },
    activeTypes() {
      return this.typeFilterItems.filter((item) => item.checked);
    },
    activeCategories() {
      return this.categoryFilterItems.filter((item) => item.checked);
    },
    activeSubcategories() {
      if (!this.activeCategories.length) {
        return [];
      }
      return this.subcategoryFilterItems.filter((item) => item.checked);
    },
    activeMicroCategories() {
      if (!this.activeSubcategories.length) {
        return [];
      }
      return this.microCategoryFilterItems.filter((item) => item.checked);
    },
    activeFilterItems() {
      return [
        //...this.tagFilterItems,
        ...this.healthIngredientsTag,
        ...this.featuredTag,
        ...this.typeFilterItems,
        ...this.categoryFilterItems,
        ...this.activeSubcategories,
        ...this.activeMicroCategories,
      ].filter((item) => item.checked);
    },
  },

  async created() {
    await this.$store.dispatch("reference/setReference");
    await this.fetchFilters();
    await this.fetchProducts();
    if (!this.products.length) {
      this.isEmptyCatalog = true;
    }
    this.displayEmptyResult = true;
    await this.$store.dispatch("brand/getBrand", this.brandId);
  },
  methods: {
    hide() {
      this.showPlan = false;
    },
    beforeProductEdit() {
      this.showUploadCard = false;
    },
    afterProductEdit() {
      this.showUploadCard = true;
    },
    reloadPage() {
      console.log("Inside the closing of dialog");
      this.$forceUpdate();
    },
    resetPagination() {
      delete this.query["_order-by"];
    },
    changeLimit() {
      this.page = 1;
      this.resetPagination();

      this.fetchProducts();
    },
    nextPage() {
      this.page++;
      this.fetchProducts();
    },
    previousPage() {
      if (this.page === 1) {
        return;
      }
      this.page--;
      this.fetchProducts();
    },
    lastPage() {
      console.log("this is in the last page function ", this.productPageData.pages)
      this.page = this.productPageData.pages;
      this.fetchProducts()
    },
    countryName(id) {
      return this.$store.getters["reference/countryName"](id);
    },
    hidePopup() {
      this.showPopup = false;
    },
    mainImageUrl(product) {
      const asset =
        this.$store.getters["asset/find"](
          this.productMainImage(product).assetId
        ) || {};
      return asset.url;
    },
    isVideo(image) {
      const type = [...image.url.split(".")].pop();
      return !["jpeg", "gif", "png", "jpg"].includes(type);
    },
    productMainImage(product) {
      if (!product.images) {
        return "";
      }

      const mainImage = product.images.find((image) => image.main);
      if (!mainImage) {
        return;
      }
      return mainImage;
    },
    async option(option) {
      this.filterOption = option;
    },
    setQueryKeyByFilterItems(activeFilterItems, key) {
      if (activeFilterItems.length) {
        this.query[key] = activeFilterItems.map((item) => item.id).join(",");
        if (key == "health-ingredients") {
          if (this.filterOption == "All") {
            this.query[key] = "all(" + this.query[key] + ")";
          }
          if (this.filterOption == "Any") {
            this.query[key] = "any(" + this.query[key] + ")";
          }
        }
        if (key == "sell-side-value") {
          console.log(activeFilterItems);
          this.query[key] = activeFilterItems
            .map((item) => item.name)
            .join(",");
        }
      } else {
        delete this.query[key];
      }
    },
    canShowRange(
      //  products
    ) {
      // if (products.length === 1 && !this.query.msrp) {
      //   return false;
      // } else {
      //   return true;
      // }
      return true;
    },
    async backToCategories() {
      this.categoryFilterItems.forEach(
        (category) => (category.checked = false)
      );
      this.subcategoryFilterItems.forEach(
        (subcategory) => (subcategory.checked = false)
      );
      this.microCategoryFilterItems.forEach(
        (microCategory) => (microCategory.checked = false)
      );
      delete this.query.msrp;
      try {
        await this.fetchProducts();
      } catch (error) {
        console.error(error);
      }
    },
    syncQueryWithFilters() {
      //this.setQueryKeyByFilterItems(this.activeTags, "sell_side_tag")
      this.setQueryKeyByFilterItems(
        this.activeHealthIngredientsTags,
        "sell-side-value"
      );
      this.setQueryKeyByFilterItems(this.activeFeaturedTags, "brand-curation");
      this.setQueryKeyByFilterItems(this.activeCategories, "category");
      this.setQueryKeyByFilterItems(this.activeSubcategories, "subcategory");
      this.setQueryKeyByFilterItems(
        this.activeMicroCategories,
        "micro-category"
      );
      this.setQueryKeyByFilterItems(this.activeTypes, "type");
    },
    filterProductsByPrice(min, max) {
      this.page = 1;
      if (min >= 0 && max > 0 && this.activeCategories.length) {
        this.query.msrp = `${min},${max}`;
      } else {
        delete this.query.msrp;
      }

      this.fetchProducts();
    },
    fetchDataWhenClickFilter() {
      this.page = 1;
      this.fetchProducts();
    },
    async fetchProducts() {
      this.isPaginationLoading = true;
      this.syncQueryWithFilters();

      try {
        this.query.brand = this.brandId;
        this.query["_limit"] = 12;
        this.query["_index"] = this.page;
        this.query["_sort-by"] = "sku"; //"created";
        this.query["_order-by"] = "next";
        await this.$store.dispatch("product/getProductsByPage", {
          params: this.query,
        });
        this.productPageData = this.$store.getters[
          "product/getProductPageData"
        ];
        this.products = this.productPageData.products;
      } catch (error) {
        this.products = [];
        console.error(error);
      } finally {
        this.isPaginationLoading = false;
      }
    },
    async fetchFilters() {
      this.tagFilterItems = this.$store.getters["reference/brandTags"];
      this.tagFilterItems.forEach((item) => (item.checked = false));
      this.typeFilterItems = this.$store.getters["reference/typeFilterItems"];
      this.typeFilterItems.forEach((item) => (item.checked = false));
      this.categoryFilterItems = this.$store.getters[
        "reference/categoryFilterItems"
      ];
      this.categoryFilterItems.forEach((item) => (item.checked = false));
      this.subcategoryFilterItems = this.$store.getters[
        "reference/subcategoryFilterItems"
      ];
      this.subcategoryFilterItems.forEach((item) => (item.checked = false));
      this.microCategoryFilterItems = this.$store.getters[
        "reference/microCategoryFilterItems"
      ];
      this.microCategoryFilterItems.forEach((item) => (item.checked = false));
      this.splitTags(this.tagFilterItems);
      this.initBrandValue();
    },
    splitTags(tagFilters) {
      for (var tags in tagFilters) {
        if (tagFilters[tags].name === "Cruelty-free") {
          this.healthIngredientsTag.push(tagFilters[tags]);
        } else if (tagFilters[tags].name === "Paraben-free") {
          this.healthIngredientsTag.push(tagFilters[tags]);
        } else if (tagFilters[tags].name === "Phthalate-free") {
          this.healthIngredientsTag.push(tagFilters[tags]);
        } else if (tagFilters[tags].name === "Sulfate-free") {
          this.healthIngredientsTag.push(tagFilters[tags]);
        } else if (tagFilters[tags].name === "Vegan") {
          this.healthIngredientsTag.push(tagFilters[tags]);
        } else if (tagFilters[tags].name === "Organic") {
          this.healthIngredientsTag.push(tagFilters[tags]);
        } else {
          this.featuredTag.push(tagFilters[tags]);
        }
      }
    },
    initBrandValue() {
      this.healthIngredientsTag = this.$store.getters[
        "reference/brandValue"
      ].map((brand) => {
        return { checked: false, ...brand };
      });
    },
    openModal() {
      this.isNewModalOpen = true;
    },
    closeModal() {
      this.isNewModalOpen = false;
    },
    // upload product
    uploadProducts() {
      this.$refs["uploadProduct"].openDialog();
    },
    doUpload(file) {
      //let adminInfo = "";
      let jwt_userId = "";
      if (this.isAdmin) {
        if (this.brand && this.brand.members && this.brand.members.length > 0) {
          for (let i = 0; i < this.brand.members.length; i++) {
            if (this.brand.members[i].role.id === "owner") {
              jwt_userId = this.brand.members[i].user.id;
              break;
            }
          }
        }
        //adminInfo = `?_authenticated=true&_jwt-c_id=${this.brand.id}&_jwt-c_type=brand&_jwt-u_id=${jwt_userId}`;
      }
      let fileReader = new FileReader();
      const formData = new FormData();
      fileReader.readAsText(file);
      fileReader.onloadend = (e) => {
        let data = new Blob([e.target.result], { type: "text/csv" });
        formData.append("file", data, file.name);
        this.uploading = true;
        uploadService
          .uploadProducts(this.brand.id, jwt_userId, formData)
          .then((error) => {
            if (error === "") {
              this.$refs["uploadProduct"].handleSuccess();
            } else {
              this.$refs["uploadProduct"].showErrorReport(
                error,
                "Error found during products upload. Please click download to view the import status."
              );
            }
            this.fetchProducts();
          })
          .catch((err) => {
            if (err.message) {
              let errorReports = err.downloadMessage;
              let errors = err.message;
              this.$refs["uploadProduct"].showErrorReport(errorReports, errors);
            } else {
              this.$message.error("Upload failed, please check your file");
            }
            this.$refs["uploadProduct"].handleCancelUpload();
          });
        // .finally(() => {
        //   this.$refs["uploadProduct"].handleCancelUpload()
        //   this.fetchProducts()
        // })
      };
    },
    doUploadOld(file) {
      let adminInfo = "";
      if (this.isAdmin) {
        let jwt_userId = "";
        if (this.brand && this.brand.members && this.brand.members.length > 0) {
          for (let i = 0; i < this.brand.members.length; i++) {
            if (this.brand.members[i].role.id === "owner") {
              jwt_userId = this.brand.members[i].user.id;
              break;
            }
          }
        }
        adminInfo = `?_authenticated=true&_jwt-c_id=${this.brand.id}&_jwt-c_type=brand&_jwt-u_id=${jwt_userId}`;
      }
      let fileReader = new FileReader();
      const formData = new FormData();
      fileReader.readAsText(file);
      fileReader.onloadend = (e) => {
        let data = new Blob([e.target.result], { type: "text/csv" });
        formData.append("file", data, file.name);
        this.uploading = true;
        this.$store
          .dispatch("brand/uploadCatalogProducts", {
            adminInfo,
            file: formData,
          })
          .then((res) => {
            if (res.data.states) {
              const errorList = res.data.states.filter((item) => item.error);
              if (errorList && errorList.length === 0) {
                this.$refs["uploadProduct"].handleSuccess();
              } else {
                errorList.forEach((item) => {
                  let errorMsg = "";
                  for (let i = 0; i < item.error.underlying.length; i++) {
                    errorMsg += item.error.underlying[i].developerMessage;
                    if (i < item.error.underlying.length - 1) {
                      errorMsg += ",";
                    }
                  }
                  // this.$message.error(errorMsg)
                  this.$refs["uploadProduct"].showErrorReport("", errorMsg);
                });
              }
            } else if (res.data.reports) {
              let errorReports = "";
              let errors = "";
              res.data.reports.forEach((item) => {
                let index = item.indexOf("cause:");
                if (index > 0) {
                  errorReports += `${item.substring(0, index)}\n`;
                  errors += `${item.substring(0, index)}<br/>`;
                } else {
                  errorReports += `${item}\n`;
                  errors += `${item}<br/>`;
                }
              });
              this.$refs["uploadProduct"].showErrorReport(errorReports, errors);
            }
            // this.handleCancelUpload()
            this.$refs["uploadProduct"].handleCancelUpload();
            this.fetchProducts();
          })
          .catch((err) => {
            if (err.message) {
              let errorReports = err.downloadMessage;
              let errors = err.message;
              this.$refs["uploadProduct"].showErrorReport(errorReports, errors);
            } else {
              this.$message.error("Upload failed, please check your file");
            }
            this.$refs["uploadProduct"].handleCancelUpload();
          });
        // .finally(() => {
        //   this.$refs["uploadProduct"].handleCancelUpload()
        //   this.fetchProducts()
        // })
      };
    },
    addProduct() {
      this.isEmptyCatalog = false;
      this.fetchProducts();
    },
    async removeProduct(productId) {
      await this.$store.dispatch("product/removeProduct", productId);
      this.fetchProducts();
    },
    goToWebsite() {
      window.open("http://" + this.brand.website);
    },
    goToSocialMedia(socialAccount) {
      window.open("http://" + socialAccount.address);
    },
    showDialog() {
      this.$refs["brandTableDialogCatalog"].openDialog();
    },
  },
};
</script>

<style lang="sass">
.vs-progress--background
  z-index: 0!important
.uploadProgressBar
  margin-bottom: 15px!important
.productUploadBox
  width: 400px!important
  .con-vs-card
    padding: 8px 16px 8px 16px
    .vs-card--content
      margin-bottom: 0px
  .headerTxt
    color: #333333
    font-size: 16px
    font-weight: bold
    letter-spacing: 0
    line-height: 24px
    margin-bottom: 8px
  .bodyTxt
    color: #212B36
    font-size: 14px
    letter-spacing: 0
    line-height: 20px
    margin-bottom: 8px
  .upgrade-button
      background-color: #000
      border-color: transparent
      color: white
      min-width: 124px
      height: 30px
      font-size: 14px
      font-weight: 600

.brand-product
 max-width: 1024px
 margin-left: 80px
.brand-new-product__add-button, .brand-new-product__add-button--disabled
  font-size: 14px
  font-weight: 300
  letter-spacing: 0.16px
  display: flex
  justify-content: flex-end
  align-items: flex-end
  //flex-direction: column
  padding-bottom: 8px
  color: #161616
  .addProductBtn
    text-decoration: underline
    align-self: flex-end
  a
    color: #161616
    cursor: pointer
.brand-new-product__add-button--disabled
  cursor: not-allowed
  color: #9E9E9E
  align-items: flex-end
  text-decoration: underline
.catalog-content
  padding: 0 20px 0 30px
.brand-catalog
  color: #000000
  min-height: 1200px

  &__banner
    height: 280px
    margin-bottom: -35px
    background-color: #ffffff
    img
      width: 100%
      height: 100%
      display: none

  &__header
      font-size: 18px
      font-weight: 500
      letter-spacing: 0.25px
      color: #000000
      //padding-left: 15px
      border-bottom: 1px solid #D8D8D8
      padding: 0 20px 0 30px


  &__logo
    display: flex
    justify-content: center
    margin-inline-end: 10px
    margin-left: 30px
    height: 140px
    width: 140px
    border: 1px solid #E5E5E5
    background-color: #ffffff

  &__country
    font-size: 14px

  &__icons
    margin-top: 50px

  &__name
    font-size: 18px
    font-weight: bold
    letter-spacing: 0.25px
    padding-top: 50px

  &__description
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.25px

  &__website
    padding-top: 30px
    text-decoration: underline

    a
      color: #161616

  &__categories
    padding-top: 60px
    padding-bottom: 10px

    &--header
      font-size: 14px
      font-weight: 600

    &--back-to
      cursor: pointer
      font-size: 14px
      font-weight: 300
      padding-top: 10px

  &__filter-header
    font-size: 14px
    font-weight: 600
    text-transform: uppercase

  &__filter
    cursor: pointer
    margin: 10px 48px 0px 0px

  &__product
    max-width: 250px
     margin: 0 auto

  &__products
    padding-top: 30px

  &__not-found
    font-size: 12px
    font-weight: 600
    padding-top: 25px
    padding-left: 10px

  &__no-products
    font-size: 16px
    font-weight: 600
    text-align: center
    padding-top: 25px
</style>

<style>
.brand-catalog-popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.brand-catalog-popup .brand-catalog-popuptext {
  visibility: hidden;
  font-size: 12px;
  width: 140px;
  background-color: #eaeaea;
  color: #000000;
  text-align: center;
  padding: 8px 0;
  position: absolute;
  z-index: 100;
  top: -90px;
  right: 5px;
}

.brand-catalog-popup .brand-catalog-popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #eaeaea transparent transparent transparent;
}

.brand-catalog-popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
