import brandPayment from "@/api/brand-payment.js"
import AES from "@/common/AES.js"
import Utils from "@/common/Utils"

export default {
  namespaced: true,

  state: {
    cardInfoList: [],
    subscriptions: [],
    historyList: [],
    cardNull: {
      payType: "creditCard",
      cardNumber: "",
      cardHolderName: "",
      cardBrand: "",
      expirationDate: "",
      cardCode: "",
      privateId: undefined,
      brandPrivateId: undefined,
      email: undefined,
      customerProfileId: undefined,
      customerPaymentProfileId: undefined,
      createdAt: undefined,
      createdBy: undefined,
      default: false
    }
  },

  getters: {
    cardInfoList(state) {
      return state.cardInfoList
    },
    defaultCard(state, getters) {
      const defaultCardInfo = getters.cardInfoList.find(card => card.default)
      const fallbackCardInfo = getters.cardInfoList.length && getters.cardInfoList[0]
      return defaultCardInfo || fallbackCardInfo || state.cardNull
    },
    subscriptions(state) {
      return state.subscriptions
    },
    history(state) {
      return state.historyList
    }
  },

  mutations: {
    SET_CARD_LIST(state, list) {
      state.cardInfoList = list.map(item => {
        let date = AES.decrypt(item.expirationDate)
        date = date.substring(0, 2) + "/" + date.substring(2)
        return {
          privateId: item.privateId,
          brandPrivateId: item.brandPrivateId,
          cardHolderName: item.cardHolderName,
          cardBrand: item.cardBrand,
          cardNumber: AES.decrypt(item.cardNumber),
          cardCode: AES.decrypt(item.cardCode),
          email: item.email,
          customerProfileId: item.customerProfileId,
          customerPaymentProfileId: item.customerPaymentProfileId,
          expirationDate: date,
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          isExpired: Utils.isDateExpired(date),
          default: item.default
        }
      })
    },
    SET_SUBSCRIPTIONS(state, list) {
      state.subscriptions = list
    },
    SET_HISTORY_LIST(state, list) {
      state.historyList = list
    }
  },

  actions: {
    async updateCardInfo({ commit }, { cardInfo, brandId }) {
      try {
        let params = {
          cardNumber: AES.encrypt(cardInfo.cardNumber),
          cardCode: AES.encrypt(cardInfo.cardCode),
          expirationDate: AES.encrypt(cardInfo.expirationDate.replace("/", "")),
          cardHolderName: cardInfo.cardHolderName,
          eMail: brandId,
          isDefault: cardInfo.default ? 1 : 0
        }
        const { data } = await brandPayment.addPaymentMethod(brandId, params)
        if (!data.error) {
          commit("SET_CARD_LIST", data)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchCards({ commit }, brandId) {
      try {
        const { data } = await brandPayment.getCardInfo(brandId)
        commit("SET_CARD_LIST", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteCardInfo({ commit }, { brandId, param }) {
      try {
        const { data } = await brandPayment.deletePaymentMethod(brandId, param)
        commit("SET_CARD_LIST", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async setCardDefault({ commit }, { brandId, param }) {
      try {
        if (param[0].cardCode !== undefined) {
          param[0].cardCode = AES.encrypt(param[0].cardCode)
        }
        const { data } = await brandPayment.setCardInfoBeDefault(brandId, param)
        if (!data.error) {
          commit("SET_CARD_LIST", data)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateExpireDate({ commit }, { brandId, param }) {
      try {
        param.cardCode = AES.encrypt(param.cardCode)
        param.cardNumber = AES.encrypt(param.cardNumber)
        param.expirationDate = AES.encrypt(param.expirationDate.replace("/", ""))
        const { data } = await brandPayment.updateExpireDate(brandId, param)
        if (!data.error) {
          commit("SET_CARD_LIST", data)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async payOrder({ commit }, { brandId, param }) {
      try {
        param.cardNumber = AES.encrypt(param.cardNumber)
        const { data } = await brandPayment.payOrder(brandId, param)
        if (!data.error) {
          commit("SET_SUBSCRIPTIONS", data)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getHistoryList({ commit }, brandId) {
      try {
        console.log(`brandId${brandId}`)
        const { data } = await brandPayment.getHistoryList(brandId)
        commit("SET_HISTORY_LIST", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getPaymentDownload(_, { brandId, param }) {
      try {
        const response = await brandPayment.getPaymentDownload(brandId, param)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async paymentOfFee(_, { brandId, param }) {
      try {
        param.cardNumber = AES.encrypt(param.cardNumber)
        const response = await brandPayment.paymentOfFee(brandId, param)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
