<template>
  <a-row class="admin-retailer-search">
    <a-col :span="24">
      <a-button
        class="admin-retailer-search__download-btn"
        style="float: right;"
        @click="clickToDownloadData"
      >
        {{ $t("Admins.AdminInventory.csv") }}
        <a-icon type="download" />
      </a-button>
    </a-col>
    <a-col :span="4" style="padding-right: 12px;">
      <SearchItems :items="searchItems" @doSearch="doSearch" />
    </a-col>
    <a-col :span="20" class="admin-console-brand-retailer-product-table">
      <a-table
        bordered
        :data-source="searchResult.list"
        :columns="columns"
        :pagination="false"
        :row-key="(record) => record.id"
      >
        <template slot="name">
          <span> Brand Name </span>
          <a-icon
            type="caret-up"
            :rotate="isSortAsc ? 0 : 180"
            @click="doSortByBrandName"
          />
        </template>
        <template slot="location">
          <span>Location</span>
          <a-icon
            type="caret-up"
            :rotate="isSortLocationAsc ? 0 : 180"
            @click="doSortByLocation"
          />
        </template>
        <template slot="shortName">
          <span>Short Name</span>
          <a-icon
            type="caret-up"
            :rotate="isSortShortNameAsc ? 0 : 180"
            @click="doSortByShortName"
          />
        </template>
        <template slot="date">
          <span>Date Registered</span>
          <a-icon
            type="caret-up"
            :rotate="isSortDateAsc ? 0 : 180"
            @click="doSortByDate"
          />
        </template>

        <template slot="subscription">
          <span>Subscription</span>
          <a-icon
            type="caret-up"
            :rotate="isSortSubscription ? 0 : 180"
            @click="doSortBySubscription"
          />
        </template>
        <template slot="productNumber">
          <span>Number of Products</span>
          <a-icon
            type="caret-up"
            :rotate="isSortProductNumber ? 0 : 180"
            style="margin-left: 12px;"
            @click="doSortByProduct"
          />
        </template>
        <template slot="training">
          <span>Training</span>
          <a-icon
            type="caret-up"
            :rotate="isSortTrainning ? 0 : 180"
            @click="doSortByTraining"
          />
        </template>
        <template slot="changeRequired">
          <span>Change Required</span>
          <a-icon
            type="caret-up"
            :rotate="isSortChange ? 0 : 180"
            @click="doSortByChange"
          />
        </template>

        <template slot="statusTitle">
          <span>Status</span>
          <a-icon
            type="caret-up"
            :rotate="isSortStatusAsc ? 0 : 180"
            @click="doSortByStatus"
          />
        </template>

        <template slot="brandColumn" slot-scope="text, record">
          <span
            class="admin-console-brand-link-click"
            @click="clickToBrandProfile(record)"
          >
            {{ text }}
          </span>
        </template>
        <template slot="regiserDate" slot-scope="text">
          <span>{{ formatDate(text) }}</span>
        </template>
        <template slot="changeRequiredColumn" slot-scope="text, record">
          <span
            v-if="text"
            style="cursor: pointer; "
            @click="showChangeRequestModal(record)"
          >
            {{ $t("Components.Admin.ClientItem.needsReview") }}
          </span>
          <span v-else></span>
        </template>
        <template slot="status" slot-scope="text, record">
          <div style="width: 100%;">
            <span>{{ text }}</span>
            <a-dropdown v-if="showStatusAction(record)" style="float: right;">
              <a-icon
                type="more"
                style="margin-top: 4px;"
                @click="(e) => e.preventDefault()"
              ></a-icon>
              <a-menu
                slot="overlay"
                @click="(event) => handleMenuClick(event, record)"
              >
                <a-menu-item v-for="item in getStatues(record)" :key="item.id">
                  {{ item.name }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </template>
      </a-table>
      <a-pagination
        class="inventoryPagination"
        style="margin-top: 10px; float: right;"
        :default-page-size="pageSize"
        :default-current="1"
        :total="searchResult.total"
        size="small"
        :show-quick-jumper="true"
        @change="getNextPageData"
      />
    </a-col>
    <ApproveModal
      v-if="client"
      :approve-error="approveError"
      :show-error="showError"
      :show="approveModal"
      :initial-short-name="client.shortName"
      @hideApproveModal="hideApproveModal"
      @subscribe="subscribe"
    />
    <ViewPendingChanges
      ref="viewPendingChanges"
      :view-pending-change-request="brand.termsPending"
      @changeTermsPending="changeTermsPending"
    />
  </a-row>
</template>

<script>
import SubscriptionService from "@/services/subscriptions-service";
import SearchItems from "@/components/admin/SearchItems";
import ApproveModal from "@/components/admin/ApproveModal";
import ViewPendingChanges from "@/components/brands/settings/ViewPendingChanges";
import { downloadCsvDataStr } from "@/utils/validate";
export default {
  components: {
    SearchItems,
    ApproveModal,
    ViewPendingChanges,
  },
  data() {
    return {
      status: [
        {
          id: "approved",
          name: "Approved",
          isChecked: false,
        },
        {
          id: "pending",
          name: "Pending",
          isChecked: false,
        },
        {
          id: "rejected",
          name: "Rejected",
          isChecked: false,
        },
        {
          id: "preloaded",
          name: "Preloaded",
          isChecked: false
        }
      ],
      statues: [
        {
          id: "approved",
          name: "Approved",
          isChecked: false,
        },
        {
          id: "pending",
          name: "Pending",
          isChecked: false,
        },
        {
          id: "rejected",
          name: "Rejected",
          isChecked: false,
        },
      ],
      pendingStatues: [],
      isSortAsc: true,
      isSortLocationAsc: true,
      isSortShortNameAsc: true,
      isSortDateAsc: true,
      isSortSubscription: true,
      isSortProductNumber: true,
      isSortTrainning: true,
      isSortChange: true,
      isSortStatusAsc: true,
      isCheckAllRecord: false,
      retailerName: [],
      retailerShortName: [],
      subscriptions: [],
      curations: [],
      brandValues: [],
      searchItems: [],
      sortParam: {},
      searchParam: undefined,
      client: undefined,
      approveModal: false,
      approveError: null,
      showError: false,
      viewBrandChanges: false,
      curPage: 1,
      columns: [
        {
          dataIndex: "rank",
          title: "#",
          width: "50px",
        },
        {
          slots: { title: "name" },
          dataIndex: "brandName",
          scopedSlots: { customRender: "brandColumn" },
          width: "16%",
        },
        {
          //title: "Location",
          dataIndex: "location",
          slots: { title: "location" },
          width: "12%",
        },
        {
          //title: "Short Name",
          dataIndex: "shortName",
          slots: { title: "shortName" },
          width: "10%",
        },

        {
          //title: "Date Registered",
          dataIndex: "createdAt",
          slots: { title: "date" },
          width: "13%",
          scopedSlots: { customRender: "regiserDate" },
        },
        {
          //title: "Subscription",
          dataIndex: "type",
          slots: { title: "subscription" },
          width: "10%",
        },
        {
          // title: "Number of Products",
          dataIndex: "products",
          slots: { title: "productNumber" },
          width: "10%",
        },
        {
          // title: "Tranning",
          dataIndex: "training",
          slots: { title: "training" },
          width: "10%",
        },
        {
          //title: "Change Required",
          dataIndex: "termsUpdatePending",
          slots: { title: "changeRequired" },
          scopedSlots: { customRender: "changeRequiredColumn" },
          width: "10%",
        },
        {
          // title: "Status",
          dataIndex: "status",
          width: "11%",
          slots: { title: "statusTitle" },
          scopedSlots: { customRender: "status" },
        },
      ],
      pageSize: 30,
      selectedRecords: new Set(),
    };
  },
  computed: {
    searchResult() {
      return this.$store.getters["adminConsole/brands"];
    },
    brand() {
      return this.$store.getters["brand/brand"];
    },
  },
  created() {
    this.pendingStatues = this.statues.filter((s) => s.id === "pending");
    this.getReferenceData();
    this.getRetailerName();
    this.searchBrandData();
  },
  methods: {
    getStatues(record) {
      if (record.statusPrivateId == 5) {
        // reject
        return this.pendingStatues;
      }
      return this.statues;
    },
    showStatusAction(record) {
      let show = false;
      switch (record.statusPrivateId) {
        case 2: // pending
        case 5: // reject
          show = true;
          break;
      }
      return show;
    },
    getReferenceData() {
      this.$store.dispatch("reference/setReference").then(() => {
        this.subscriptions = this.$store.getters["reference/subscriptionTypes"];
        this.curations = this.$store.getters["reference/curations"];
        this.brandValues = this.$store.getters["reference/brandValue"];
        this.subscriptions = this.subscriptions.filter(
          (data) => data.id !== "classic"
        );
        if (this.curations && this.curations.length > 0) {
          this.curations.forEach((data) => {
            if (data.description) {
              let name = data.name;
              data.name = data.description;
              data.description = name;
            }
          });
        }
        this.initData(this.subscriptions);
        this.initData(this.curations);
        this.initData(this.brandValues);
        this.initSearchItems();
      });
    },
    initData(data) {
      if (data && data.length > 0) {
        data.forEach((item) => {
          item.isChecked = false;
          item.hasChild = false;
        });
      }
    },
    getRetailerName() {
      this.$store
        .dispatch("brand/fetchAllBrands", {
          params: { fields: "id, name, shortName" },
        })
        .then(() => {
          let retailers = this.$store.getters["brand/brands"];
          if (retailers && retailers.length > 0) {
            this.retailerName = [];
            this.retailerShortName = [];
            retailers.forEach((element) => {
              this.retailerName.push({ name: element.name, id: element.id });
              if (element.shortName) {
                this.retailerShortName.push({
                  name: element.shortName,
                  id: element.id,
                });
              }
            });
            this.initData(this.retailerName);
            this.initData(this.retailerShortName);

            this.initSearchItems();
            //this.searchRetailerData()
          }
        });
    },
    initSearchItems() {
      this.searchItems = [];
      this.searchItems.push({
        name: "Brand Name",
        paramName: "brandId",
        canSearch: true,
        isUseId: true,
        child: this.retailerName,
      });
      this.searchItems.push({
        name: "Short Name",
        paramName: "brandId",
        canSearch: false,
        isUseId: true,
        child: this.retailerShortName,
      });
      this.searchItems.push({
        name: "Subscription",
        paramName: "subscription",
        canSearch: false,
        child: this.subscriptions,
      });
      this.searchItems.push({
        name: "Curations",
        paramName: "curation",
        canSearch: false,
        child: this.curations,
      });
      this.searchItems.push({
        name: "Brand Values",
        paramName: "brandValue",
        canSearch: false,
        child: this.brandValues,
      });
      // this.searchItems.push({
      //   name: "Brand Commission",
      //   paramName: "location",
      //   canSearch: false,
      //   child: this.locations
      // })
      this.searchItems.push({
        name: "Status",
        paramName: "status",
        canSearch: false,
        child: this.status,
      });
    },
    doSearch(param) {
      param["_limit"] = this.pageSize;
      //   param["sortByBrandName"] = this.isSortAsc
      this.searchParam = param;
      param["page-index"] = 1;
      param = Object.assign({}, param, this.sortParam);
      this.searchBrandData(param);
    },
    async searchBrandData(param) {
      if (!param) {
        await this.$store.dispatch("adminConsole/fetchBrands", {
          _limit: this.pageSize,
          sortByBrandName: this.isSortAsc,
          "page-index": 1,
        });
      } else {
        await this.$store.dispatch("adminConsole/fetchBrands", param);
      }
    },
    getNextPageData(page) {
      this.curPage = page;
      let param = undefined;
      if (this.searchParam) {
        param = { ...this.searchParam, "page-index": page };
      } else {
        param = {
          _limit: this.pageSize,
          "page-index": page,
        };
      }
      param = Object.assign({}, param, this.sortParam);
      this.searchBrandData(param);
    },
    doSortByBrandName() {
      this.isSortAsc = !this.isSortAsc;
      this.sortParam = {};
      this.sortParam.sortByBrandName = this.isSortAsc;
      this.getNextPageData(1);
    },
    doSortByLocation() {
      this.isSortLocationAsc = !this.isSortLocationAsc;
      this.sortParam = {};
      this.sortParam.sortByLocation = this.isSortLocationAsc;
      this.getNextPageData(1);
    },
    doSortByShortName() {
      this.isSortShortNameAsc = !this.isSortShortNameAsc;
      this.sortParam = {};
      this.sortParam.sortByShortName = this.isSortShortNameAsc;
      this.getNextPageData(1);
    },
    doSortByDate() {
      this.isSortDateAsc = !this.isSortDateAsc;
      this.sortParam = {};
      this.sortParam.sortByCreatedAt = this.isSortDateAsc;
      this.getNextPageData(1);
    },
    doSortBySubscription() {
      this.isSortSubscription = !this.isSortSubscription;
      this.sortParam = {};
      this.sortParam.sortBySubscriptionType = this.isSortSubscription;
      this.getNextPageData(1);
    },
    doSortByProduct() {
      this.isSortProductNumber = !this.isSortProductNumber;
      this.sortParam = {};
      this.sortParam.sortByProductCount = this.isSortProductNumber;
      this.getNextPageData(1);
    },
    doSortByTraining() {
      this.isSortTrainning = !this.isSortTrainning;
      this.sortParam = {};
      this.sortParam.sortByTraining = this.isSortTrainning;
      this.getNextPageData(1);
    },
    doSortByChange() {
      this.isSortChange = !this.isSortChange;
      this.sortParam = {};
      this.sortParam.sortByChangeRequired = this.isSortChange;
      this.getNextPageData(1);
    },
    doSortByStatus() {
      this.isSortStatusAsc = !this.isSortStatusAsc;
      this.sortParam = {};
      this.sortParam.sortByStatus = this.isSortStatusAsc;
      this.getNextPageData(1);
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value);
        return new Intl.DateTimeFormat("en-US").format(date);
      }
      return " ";
    },
    handleMenuClick(e, item) {
      //console.log("....handle menu click.....");
      //console.log(e);
      //console.log(item);
      this.client = item;
      if (e.key === "approved") {
        this.approveModal = true;
        this.approveError = "";
        this.showError = false;
      } else {
        let payload = { status: { id: e.key }, id: item.id };
        this.updateBrand(payload);
        this.client = undefined;
      }
    },
    hideApproveModal() {
      this.approveModal = false;
    },
    subscribe(shortName) {
      // this.updateBrand({
      //   status: { id: "approved" },
      //   shortName,
      //   id: this.client.id,
      // });
      this.approveBrandSubscription(this.client.id, shortName);
    },
    async approveBrandSubscription(brandId, shortName) {
      const data = {
        brandId,
        shortName,
      };
      try {
        await SubscriptionService.approveBrandSubscription(data);
        this.approveModal = false;
        this.showError = false;
        this.approveError = "";
        this.getNextPageData(this.curPage);
      } catch (error) {
        this.handleApproveError(error);
      }
    },
    async updateBrand(payload) {
      try {
        if (payload.status.id === "rejected") {
          await SubscriptionService.RejectBrandSubscription(payload.id);
        } else {
          await this.$store.dispatch("brand/updateBrandPartially", payload);
        }
        this.approveModal = false;
        this.showError = false;
        this.approveError = "";
        this.getNextPageData(this.curPage);
      } catch (error) {
        this.handleApproveError(error);
      }
    },
    handleApproveError(error) {
      console.log(error);
      if (error.status === 400) {
        this.approveError = error.message;
        this.showError = true;
        return;
      }
    },
    async showChangeRequestModal(record) {
      this.client = record;
      await this.$store.dispatch("brand/getBrand", record.id);
      this.$refs["viewPendingChanges"].openDialog();
    },
    changeTermsPending() {
      this.client.termsUpdatePending = false;
      this.client = undefined;
    },
    clickToBrandProfile(item) {
      this.$router.push({
        name: "adminBrandProfile",
        params: {
          brandId: item.id,
        },
      });
    },
    async clickToDownloadData() {
      let param = Object.assign({}, this.searchParam);
      param["_limit"] = this.searchResult.total;
      param["page-index"] = 1;
      param = Object.assign({}, param, this.sortParam);
      await this.$store
        .dispatch("adminConsole/downloadBrand", param)
        .then((result) => {
          let fileName = `brand_search_result.csv`;
          this.download(result, fileName);
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
        });
    },
    async download(data, fileName) {
      await downloadCsvDataStr(data, fileName)
        .then(() => {
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
        });
    },
  },
};
</script>
