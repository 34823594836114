<template>
  <div ref="paymentHistory" class="payment-history__main">
    <a-row>
      <span class="payment-history__title">
        {{ $t("Components.Brands.Payment.paymentDetails") }}
      </span>
    </a-row>
    <a-row>
      <span class="payment-history__subtitle-desc">
        {{ $t("Components.Brands.Payment.paymentHistory") }}
      </span>
    </a-row>
    <a-row>
      <a-table class="history-list" :columns="columns" :data-source="transactions" :pagination="isMoreThanOnePage">
        <span slot="customTitle" class="payment-history__table-title">
          {{ $t("Components.Brands.Payment.historyDate") }}
        </span>
        <span slot="customDescriptionTitle" class="payment-history__table-title">
          Description
        </span>
        <span slot="customTermsTitle" class="payment-history__table-title">
          Terms
        </span>
        <span slot="customCostTitle" class="payment-history__table-title" style="width: 100px">
          Amount
        </span>
        <span slot="customPaymentTypeTitle" class="payment-history__table-title">
          {{ $t("Components.Brands.Payment.historyPayType") }}
        </span>
        <span slot="customInvoiceTitle" class="payment-history__table-title payment-history__margin-left">
          {{ $t("Components.Brands.Payment.historyInvoice") }}
        </span>
        <!-- <span
          v-if="isAdmin"
          slot="customActive"
          class="payment-history__table-title"
        >
          Refund
        </span> -->
        <span slot="date" slot-scope="date, item" class="payment-history__table-content">
          {{ formatDate(item.createdAt) }}
          <!--          {{ item.createdAt.slice(0, 10) }}-->
        </span>
        <span slot="description" slot-scope="description" class="payment-history__table-content">
          {{ description }}
        </span>
        <span slot="description" slot-scope="terms" class="payment-history__table-content" style="text-align: center">
          {{ terms }}
        </span>
        <span slot="cost" slot-scope="cost, item" class="payment-history__table-content" :class="item.paymentType && item.paymentType.id === 'refund'
          ? 'refunded-style'
          : ''
          " v-html="cost" />
        <span slot="paymentType" slot-scope="paymentType, item" class="payment-history__table-content">
          <span v-if="item.paymentType && item.paymentType.id === 'refund'">
            Refund
          </span>
          <span v-else>
            {{
              paymentType !== "manually"
                ? $t("Components.Brands.Payment.paymentTypeCredit")
                : $t("Components.Brands.Payment.paymentBankTrans")
            }}
          </span>
        </span>
        <template slot="invoice" slot-scope="record">
          <a-button class="payment-history__table-operation" :loading="isLoading && editKey === record"
            @click="downloadInvoice(record)">
            {{ $t("Components.Brands.Payment.historyDownload") }}
          </a-button>
          <a style="visibility: hidden" id="downloadLink" href="#">Download File</a>
        </template>
        <!-- <span v-if="isAdmin" slot="active" slot-scope="text, record">
          <a-button
            v-if="record.paymentType && record.paymentType.id !== 'refund'"
            class="payment-history__table-operation"
            :class="record.refunded ? 'refunded-style' : ''"
            :icon="record.refunded ? '' : 'undo'"
            :disabled="record.refunded"
            @click="openRefund(text, record)"
          >
            {{ record.refunded ? "Refunded" : "Refund" }}
          </a-button>
        </span> -->
      </a-table>
    </a-row>
    <a-modal :visible="showVisible" :footer="null" :destroy-on-close="true" :body-style="{ padding: 15 }"
      :get-container="() => $refs.paymentHistory" @cancel="handleAddRetailerCancel">
      <div slot="title" style="text-align: center;">
        Refund Payment
      </div>
      <div class="can-not-create-training">
        <p v-if="refundText" style="padding-left: 20px">
          Current orders are not refundable
        </p>
        <p v-else-if="isCreated" style="padding-left: 20px">
          The current order is within <span style="color: red">24 hours</span>,
          refund may fail, please exercise caution
        </p>
        <p v-else-if="isAmount">
          The amount of money cannot exceed the original amount
        </p>
        <div class="refund-content">
          <a-input v-model="refundAmount" prefix="$" type="number" />
        </div>
        <div class="button-style">
          <a-button class="btn-style" @click="refundPayment">
            Refund
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Row, Table, Button } from "ant-design-vue";
import { exportOrderExcel } from "@/utils/validate";
import brandPayment from "@/api/brand-payment";
import moment from "moment";
import SubscriptionsService from "@/services/subscriptions-service";
import DocumentsService from "@/services/documents-service";
export default {
  components: {
    "a-row": Row,
    "a-table": Table,
    "a-button": Button,
  },
  data() {
    return {
      isAuthorizeNet: true,
      transactions: [],
      isMoreThanOnePage: false,
      isLoading: false,
      showVisible: false,
      refundText: false,
      isCreated: false,
      isAmount: false,
      refundAmount: "",
      refundInfo: undefined,
      editKey: "",
      columns: [
        {
          dataIndex: "startDate",
          key: "startDate",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "date" },
        },
        {
          dataIndex: "descriptionText",
          key: "descriptionText",
          slots: { title: "customDescriptionTitle" },
          scopedSlots: { customRender: "description" },
        },
        {
          dataIndex: "terms",
          key: "terms",
          slots: { title: "customTermsTitle" },
          scopedSlots: { customRender: "terms" },
        },
        {
          dataIndex: "paidAmount",
          key: "paidAmount",
          slots: { title: "customCostTitle" },
          scopedSlots: { customRender: "cost" },
        },
        {
          dataIndex: "subscriptionId",
          key: "subscriptionId",
          slots: { title: "customPaymentTypeTitle" },
          scopedSlots: { customRender: "paymentType" },
        },
        {
          dataIndex: "privateId",
          key: "privateId",
          slots: { title: "customInvoiceTitle" },
          scopedSlots: { customRender: "invoice" },
        },
        {
          dataIndex: "active",
          key: "active",
          slots: { title: "customActive" },
          scopedSlots: { customRender: "active" },
        },
      ],
    };
  },
  computed: {
    historyList() {
      const list = this.$store.getters["brandPayment/history"];
      return list.map((item) => {
        if (item.paymentType && item.paymentType.id === "refund") {
          item.descriptionText = item.parentPaymentType
            ? item.parentPaymentType.name
            : "";
        } else {
          item.descriptionText = item.paymentType ? item.paymentType.name : "";
        }
        item.terms =
          item.paymentType &&
            item.paymentType.id !== "refund" &&
            item.subscriptionTerms
            ? item.subscriptionTerms.name
            : "--";
        item.paidAmount =
          item.paymentType && item.paymentType.id !== "refund"
            ? this.priceFormat(item.paidAmountUsd)
            : `(${this.priceFormat(item.paidAmountUsd)})`;
        return item;
      });
    },
    brandId() {
      return this.isAdmin
        ? this.$route.params.brandId
        : this.$store.getters["user/businessId"];
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"];
    },
  },
  async mounted() {
    const brandId = this.getBrand();
    await this.$store.dispatch("brandPayment/getHistoryList", brandId);
    this.transactions = await this.getPaymentHistories();
  },
  methods: {
    gethistoryList(list) {
      return list.map((item) => {
        if (item.paymentType && item.paymentType.id === "refund") {
          item.descriptionText = item.parentPaymentType
            ? item.parentPaymentType.name
            : "";
        } else {
          item.descriptionText = item.paymentType ? item.paymentType.name : "";
        }
        item.terms =
          item.paymentType &&
            item.paymentType.id !== "refund" &&
            item.subscriptionTerms
            ? item.subscriptionTerms.name
            : "--";
        item.paidAmount =
          item.paymentType && item.paymentType.id !== "refund"
            ? this.priceFormat(item.paidAmountUsd)
            : `(${this.priceFormat(item.paidAmountUsd)})`;
        return item;
      });
    },
    getBrand() {
      const brandId =
        this.$route.name == "adminBrandPaymentHistory"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"];
      return brandId;
    },
    async getPaymentHistories() {
      const brandId = this.getBrand();
      var history = null;
      try {
        const transactions = await SubscriptionsService.getTransactions(
          brandId
        );
        history = this.toHistories(transactions);
        // console.log('transactions');
        // console.log(transactions);
        // console.log(history);
      } catch (ex) {
        this.isAuthorizeNet = false;
        console.log(ex);
      }

      if (!this.isAuthorizeNet) {
        history = this.$store.getters["brandPayment/history"];
        console.log("list");
      }
      console.log(this.history);
      return this.gethistoryList(history);
    },
    toHistories(subscriptionTransactions) {
      const data = subscriptionTransactions
        .map((t) => this.toHistory(t))
        .reduce((acc, subList) => {
          return acc.concat(subList);
        }, []);
      return data;
    },
    toHistory(subscriptionTransaction) {
      const subscriptionNameParts = subscriptionTransaction.subscriptionName.split('-');
      let subscriptionName = subscriptionNameParts.slice(1).join(' ');

      if (subscriptionName.toLowerCase().includes('basic')) {
        subscriptionName = 'Basic';
      }
      const schedule = subscriptionTransaction.paymentSchedule;
      const interval = schedule.interval;
      var list = subscriptionTransaction.transactions.map((t) => {
        const r = {
          privateId: t.transactionId, // key for the download
          paidAmountUsd: t.amount,
          couponAmount: 0,
          couponCode: "",
          subscriptionId: "",
          transactionId: t.transactionId,
          paymentNumber: t.paymentNumber,
          subscriptionTerms: {
            id: "",
            name: `${interval.length} ${interval.intervalType == 1 ? "Month" : "Day"
              }`,
          },
          retailerId: "",
          paymentType: {
            id: "",
            name: subscriptionName,
          },
          cardNumber: "",
          cardBrand: "",
          refunded: false,
          startDate: t.submitTimeUTC,
          endDate: "",
          paidCount: t.paymentNumber,
          createdAt: t.submitTimeUTC,
        };
        console.log(r);
        return r;
      });
      return list;
    },
    formatDate(value) {
      const time = new Date(value);
      return moment(time).format("MM/DD/YYYY");
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      let priceStr = numberFormat.format(price);
      if (price < 10) {
        let aa = priceStr.slice(0, 1) + priceStr.slice(1);
        return `${aa} USD`;
      } else if (price < 100) {
        return priceStr.slice(0, 1) + priceStr.slice(1) + " USD";
      } else if (price < 1000) {
        return priceStr.slice(0, 1) + priceStr.slice(1) + " USD";
      }
      return `${priceStr} USD`;
    },
    async download(data, fileName) {
      await exportOrderExcel(data, fileName)
        .then(() => {
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
        });
    },
    async downloadInvoice(record) {
      if (this.isAuthorizeNet) {
        console.log(record);
        this.downloadNewInvoice(record);
      } else {
        this.downloadOldInvoice(record);
      }
    },

    async downloadNewInvoice(transactionId) {
      this.isLoading = true;
      this.editKey = transactionId;
      DocumentsService.getPaymentReceipt(transactionId)
        .then((response) => {
          const link = document.getElementById("downloadLink");
          const blobData = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          console.log(blobData);
          link.href = window.URL.createObjectURL(blobData);
          link.download = `PaymentReceipt-${transactionId}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.isLoading = false;
          this.editKey = null;
        });
    },
    async downloadOldInvoice(record) {
      this.editKey = record;
      this.isLoading = true;
      let data = this.historyList.filter((item) => item.privateId === record);
      if (data && data.length > 0) {
        await this.$store
          .dispatch("brandPayment/getPaymentDownload", {
            brandId: this.brandId,
            param: {
              ...data[0],
              refunded: data[0].paymentType.id === "refund" ? true : false,
            },
          })
          .then((result) => {
            let fileName = `${data[0].privateId}_invoice.pdf`;
            this.download(
              new Blob([result.data], {
                type: `${result.headers["content-type"]};base64`,
              }),
              fileName
            );
          })
          .catch((e) => {
            this.isLoading = false;
            console.log(e);
          });
      }
    },
    openRefund(text, record) {
      console.log("table-item", record);
      const nowTime = moment();
      const beforeTime = moment(record.startDate);
      const hours = nowTime.diff(beforeTime, "hours");
      this.showVisible = true;
      this.refundInfo = record;
      if (hours <= 24) {
        this.isCreated = true;
      }
      if (
        record.subscriptionId === "001" ||
        !record.subscriptionId ||
        record.subscriptionId === "manually" ||
        record.paymentType.id === "refund" ||
        !record.cardNumber
      ) {
        this.refundText = true;
      }
    },
    handleAddRetailerCancel() {
      this.showVisible = false;
      this.refundText = false;
      this.isCreated = false;
      this.isAmount = false;
      this.refundInfo = undefined;
      this.refundAmount = "";
    },
    async refundPayment() {
      if (!this.refundAmount) {
        this.$message.error("Please fill in the refund amount");
        return;
      }
      if (this.refundInfo.paidAmountUsd < this.refundAmount) {
        this.isAmount = true;
        return;
      }
      const params = {
        cardNumber: this.refundInfo.cardNumber,
        "private-id": this.refundInfo.privateId,
        amount: this.refundAmount,
      };
      try {
        await brandPayment.paymentRefund(this.brandId, params);
      } catch (e) {
        this.$message.error(e.message);
      }
      const brandId =
        this.$route.name == "adminBrandPaymentHistory"
          ? this.$route.params.brandId
          : this.$store.getters["user/businessId"];
      await this.$store.dispatch("brandPayment/getHistoryList", brandId);
      this.handleAddRetailerCancel();
    },
  },
};
</script>

<style lang="sass">
.payment-history
  &__title
    font-family: Playfair Display
    font-style: normal
    font-weight: bold
    font-size: 48px
    line-height: 38px
    display: flex
    align-items: center
    color: #000000
    margin-bottom: 30px
  &__subtitle-desc
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
    display: flex
    align-items: center
    color: #595959
    margin-bottom: 26px
  &__table-title
    font-family: SF Pro Display
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    color: #262626
  &__margin-left
    margin-left: 14px
  &__table-content
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    //margin-top: 8px
    color: #595959
  &__table-operation
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px !important
    line-height: 22px
    display: flex
    align-items: center
    color: #4A8A5D !important
    cursor: pointer
    background: none !important
    border: none !important
.payment-history__main
  .can-not-create-training
    padding: 15px
    text-align: center
    font-size: 15px
    .title-error-text
      font-weight: bold
    .button-style
      text-align: right
    .btn-style
      background: #9fd19a
      border: 1px solid #9fd19a
      color: #ffffff
    .btn-style:hover
      background: #4a8a5d
      border: 1px solid #4a8a5d
      color: #ffffff
  .refund-content
    width: 300px
    margin: 20px 70px
    text-align: center
  .refunded-style
    color: red !important
</style>
