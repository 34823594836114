<template lang="pug">
.brand-main-page.pending-retailer
  BrandBanners
  BrandMiddleBox(:textInfo="exploreList" :typeNumber="1")
  //BrandNewRetailers
  BrandMiddleBox(:textInfo="learningList" :typeNumber="2")
  BrandBottomBanner

</template>

<script>
import BrandBanners from "@/components/brands/mainPage/BrandBanner"
import BrandMiddleBox from "@/components/brands/mainPage/BrandMiddleBox"
import BrandNewRetailers from "@/components/brands/mainPage/BrandNewRetailers"
import BrandBottomBanner from "@/components/brands/mainPage/BrandBottomBanner"
import constants from "@/config.js"
// import BRBannerButtom from "@/assets/img/brand_and_retailer_new_homepage/B&R_banner_buttom.png"
import BRExploreOne from "@/assets/img/brand_and_retailer_new_homepage/B&R_explore_one.png"
import BRExploreTwo from "@/assets/img/brand_and_retailer_new_homepage/B&R_explore_two.png"
import BExploreThree from "@/assets/img/brand_and_retailer_new_homepage/Brand_explore_three.png"
import BLearningOne from "@/assets/img/brand_and_retailer_new_homepage/Brand_learning_one.png"
import BLearningTwo from "@/assets/img/brand_and_retailer_new_homepage/Brand_learning_two.png"
import BLearningThree from "@/assets/img/brand_and_retailer_new_homepage/Brand_learning_three.png"
import RExploreThree from "@/assets/img/brand_and_retailer_new_homepage/Retailer_explore_three.png"
import RLearningOne from "@/assets/img/brand_and_retailer_new_homepage/Retailer_learning_one.png"
import RLearningTwo from "@/assets/img/brand_and_retailer_new_homepage/Retailer_learning_two.png"
import RLearningThree from "@/assets/img/brand_and_retailer_new_homepage/Retailer_learning_three.png"

export default {
  components: {
    BrandBanners,
    BrandMiddleBox,
    BrandNewRetailers,
    BrandBottomBanner
  },
  data() {
    return {}
  },
  computed: {
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    exploreList() {
      let list
      if (this.isBrand) {
        const textList = this.$t("Components.Brands.mainPage.brandMiddleBoxCard")
        const imgList = [{ img: BRExploreOne }, { img: BRExploreTwo }, { img: BExploreThree }]
        list = textList.map((item, index) => {
          return { ...item, ...imgList[index] }
        })
      }
      if (this.isRetailer) {
        const textList = this.$t("Components.Brands.mainPage.retailerMiddleBoxCard")
        const imgList = [{ img: BRExploreOne }, { img: BRExploreTwo }, { img: RExploreThree }]
        list = textList.map((item, index) => {
          return { ...item, ...imgList[index] }
        })
      }
      return list
    },
    learningList() {
      let list
      if (this.isBrand) {
        const textList = this.$t("Components.Brands.mainPage.brandMiddleBoxCardList")
        const imgList = [{ img: BLearningOne }, { img: BLearningTwo }, { img: BLearningThree }]
        list = textList.map((item, index) => {
          return { ...item, ...imgList[index] }
        })
      }
      if (this.isRetailer) {
        const textList = this.$t("Components.Brands.mainPage.retailerMiddleBoxCardList")
        const imgList = [{ img: RLearningOne }, { img: RLearningTwo }, { img: RLearningThree }]
        list = textList.map((item, index) => {
          return { ...item, ...imgList[index] }
        })
      }
      return list
    }
  },
  async created() {
    // const brandId = this.$route.params.retailerId
    //   ? this.$route.params.retailerId
    //   : this.$store.getters["user/businessId"]
    // await this.$store.dispatch("brand/getBrand", brandId)
    // this.isMemberRemoved()
    // this.checkBrandPlanType()
    // let retailerIds = constants.retailerIds
    // this.$store.dispatch("retailer/fetchretailersByIds", { retailerIds })
  },
  methods: {
    // async checkBrandPlanType() {
    //   if (this.brandPlanType && this.brandPlanType == "basic") {
    //     this.$router.push({ name: "brandProfile" })
    //   } else {
    //     this.showPromoPage = true
    //   }
    // },
    // async isMemberRemoved() {
    //   for (var a in this.brand.members) {
    //     if (this.brand.members[a].user.id == this.userId) {
    //       if (this.brand.members[a].membershipStatus.id == "removed") {
    //         this.$router.push({ name: "memberProfile" })
    //       }
    //     }
    //   }
    // },
    // logoImage(retailer) {
    //   return retailer.logoAsset && retailer.logoAsset.url
    // }
  }
}
</script>

<style lang="sass"></style>
