<template lang="pug">
.admin-clients
  .columns
    .column.is-3
      .admin-clients__filter
        .admin-clients__filter-column-name {{$t('Admins.AdminClients.filter')}}
        .admin-clients__border
        ClientsFilter(:title="'View'" :items="profiles" @filterSwitch="fetchClients")
        ClientsFilter(:title="'Account Status'" :items="statuses" @filterSwitch="fetchClients")
    .column.is-8
      .columns
        .column
          .admin-clients__table-name {{$t('Admins.AdminClients.clients')}}
        .column
          //- .columns.is-marginless
            //- .column.admin-clients__select
              .control
                .select
                  select
                    option(
                      v-for="view in views" :key="view.name" :value="view.value" :selected="view.value === 50" ) {{ view.name }}
            .column.is-narrow
              .field.is-grouped
                .control
                  .select
                    select
                      option(
                        v-for="limit in visibleItems" :selected="limit.value == 12" :value="limit.value" :key="limit.value" ) {{ limit.name }}
          .admin-clients__clients-amount {{$t('Admins.AdminClients.clientsCount')}}

      .columns
        .column.is-1.admin-clients__table-head
        .column.is-1.admin-clients__table-head 
        .column.is-1.admin-clients__table-head Type
        .column.is-2.admin-clients__table-head.client-name {{$t('Admins.AdminClients.clientName')}}
        .column.is-1.admin-clients__table-head.location {{$t('Admins.AdminClients.location')}}
        .column.is-2.admin-clients__table-head.date-registered {{$t('Admins.AdminClients.dateRegistered')}}
        .column.is-1.admin-clients__table-head.status {{$t('Admins.AdminClients.status')}}
        .column.is-2.admin-clients__table-head {{$t('Admins.AdminClients.changesRequired')}}
        .column.is-1.admin-clients__table-head


      ClientItem(v-for="client in clients" :client="client" :key="client.id" v-if="clients.length")
</template>

<script>
import ClientsFilter from "@/components/filters/ClientsFilter"
import ClientItem from "@/components/admin/ClientItem"

export default {
  components: {
    ClientsFilter,
    ClientItem
  },
  data() {
    return {
      profiles: [
        { name: "Brands", id: "brand", checked: false },
        { name: "Retailers", id: "retailer", checked: false }
      ],
      statuses: [
        { name: "Pending", id: "pending", checked: false },
        { name: "Approved", id: "approved", checked: false },
        { name: "Rejected", id: "rejected", checked: false }
      ],
      changeStatuses: [
        { name: "Yes", id: 1, checked: false },
        { name: "No", id: 2, checked: false }
      ],
      views: [
        { name: "View 50", value: 50 },
        { name: "View 100", value: 100 }
      ],
      visibleItems: [
        { name: "View 12", value: 12 },
        { name: "View 24", value: 24 },
        { name: "View 36", value: 36 }
      ],
      query: {}
    }
  },
  computed: {
    clients() {
      return this.$store.getters["admin/clients"]
    },
    activeProfiles() {
      return this.profiles.filter(item => item.checked)
    },
    activeStatuses() {
      return this.statuses.filter(item => item.checked)
    },
    activeFilterItems() {
      return [...this.activeStatuses, ...this.activeBrands].filter(item => item.checked)
    }
  },
  async created() {
    this.fetchClients()
  },
  methods: {
    hideDropdown() {
      this.isDropdownOpen = false
    },
    async fetchClients() {
      this.syncQueryWithFilters()
      await this.$store.dispatch("admin/fetchClients", this.query)
    },
    setQueryKeyByFilterItems(activeFilterItems, key) {
      if (activeFilterItems.length) {
        this.query[key] = activeFilterItems.map(item => item.id).join(",")
      } else {
        delete this.query[key]
      }
    },
    syncQueryWithFilters() {
      this.setQueryKeyByFilterItems(this.activeStatuses, "status")
      this.setQueryKeyByFilterItems(this.activeProfiles, "type")
    }
  }
}
</script>

<style lang="sass">
.admin-clients__status
  text-transform: capitalize

.admin-clients
  padding: 10px 20px 200px 30px

  &__client-name
    font-size: 15px
    color: #333333
    white-space: normal
    word-break: break-all

  &__client-website
    font-size: 13px
    color: #333333
    max-width: 120px
    white-space: pre-wrap
    overflow-wrap: break-word

  &__checkbox
    width: 16px
    height: 16px
    border: 1px solid black

  &__logo-container
    width: 40px
    height: 40px
    border: 0.5px solid #AAAAAA
    display: flex
    justify-content: center
    align-items: center

    img
      width: 100%
      height: 100%

  &__select
    display: flex
    //justify-content: end
    justify-content: flex-end

  &__status-circle
    width: 6px
    height: 6px
    border-radius: 50%
    background-color: #545454
    margin-right: 15px

  &__filter
    padding-right: 70px

  &__table-name
    color: #000000
    font-size: 18px
    font-weight: 500
    letter-spacing: 0.25px
    padding-left: 15px

  &__table-head
    color: #9E9E9E !important
    font-size: 12px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important

  &__table-cell
    color: #333333 !important
    font-size: 15px
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important
    padding-top: 1px
    display: flex
    align-items: center
    padding-bottom: 10px
    position: relative

    &--checkbox
      justify-content: center

  &__filter-column-name
    color: #292929
    font-size: 16px
    font-weight: 300
    margin-bottom: 15px

  &__clients-amount
    display: flex
    justify-content: end
    justify-content: flex-end
    font-size: 12px

  &__border
    border-bottom: 1px solid #E0E0E0

  &__brand-info
    padding-top: 10px
    padding-bottom: 10px
    border-bottom: 1px solid #E0E0E0

  &__dropdown-circles
    width: 30px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

  &__dropdown
    position: absolute
    width: 135px
    height: 132px
    top: 2px
    right: 20px
    z-index: 100
    background-color: #ffffff
    border: 1px solid #DADADA

  &__dropdown-item
    height: 45px
    display: flex
    align-items: center
    padding-left: 10px
    cursor: pointer

  &__review-link
    color: #333333  !important

.client-name
  max-width: 140px

.location
  min-width: 125px
  white-space: normal
  word-break: break-all

.status
  min-width: 135px

.date-registered
  max-width: 130px
</style>
