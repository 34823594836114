<template lang="pug">
.tag-container
    .tag-item(v-for="item in items")
      a-button(:type="item.checked ? 'default' : 'dashed'" size="small" :class="[item.checked ?'choosed':'no-choosed']" @click="switchTag(item)") 
        svg-icon(v-if="!item.checked" icon-class="add" class="icon-components")
        | {{item.name}}
        a-icon(v-if="item.checked" type="close" fill="#595959")
      //- RetailerTag(
      //-   :tagText.sync="item.name", 
      //-   :checked.sync="item.checked", 
      //-   :id.sync="item.id",
      //-    @tagClick="switchTag(item)")

</template>

<script>
import RetailerTag from "@/components/brands/retailers/RetailerTag"
export default {
  components: {
    RetailerTag
  },
  props: {
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      selected: false,
      option: "All"
    }
  },
  computed: {},
  watch: {},
  async created() {},
  methods: {
    async switchTag(item) {
      item.checked = !item.checked
      this.$emit("update:item", item)
      this.$emit("option", this.option)
      this.$emit("tagSwitch")
    },
    async resetAllCheckedTags() {
      console.log(this.items)
      this.items.forEach(item => (item.checked = false))
      this.$emit("tagSwitch")
    }
  }
}
</script>

<style lang="sass">
.tag-container
  padding: 0
  .ant-button
    display: flex
    align-self: center
.tag-item
  display: inline-block
  margin-bottom: 5px
  margin-right: 5px
.choosed
  background-color: #4A8A5D !important
  color: #fff !important
  outline: none
// .icon-components
//   font-size: 16px
</style>
