<template>
  <a-modal
    v-model="isShow"
    width="460px"
    :title="`${isEdit ? 'Edit' : 'Add New'} Promo Code`"
    :closable="true"
    ok-text="Save"
    class="admin-system-edit-coupon-dialog"
    :destroy-on-close="true"
    @cancel="cancelDialog"
    @ok="doSaveNewValue"
  >
    <div>
      <a-row class="admin-system-add-edit-dialog__row-margin">
        <a-col :span="8">
          <span>{{ `${$t("Admins.AdminSystem.promoCodeName")}:` }}</span>
        </a-col>
        <a-col :span="16">
          <a-input
            v-model="promocodeName"
            :class="{ 'is-danger': $v.promoCodeName !== undefined && $v.promoCodeName.$error }"
          />
          <p
            v-if="$v.promoCodeName !== undefined && $v.promoCodeName.$error"
            class="help is-danger"
          >
            {{ $t("Admins.AdminSystem.nameRequired") }}
          </p>
        </a-col>
      </a-row>
      <a-row class="admin-system-add-edit-dialog__row-margin">
        <a-col :span="8">
          <span>{{ `${$t("Admins.AdminSystem.promoCode")}:` }}</span>
        </a-col>
        <a-col :span="16">
          <a-input
            v-model="promoCode"
            :class="{ 'is-danger': $v.promoCode !== undefined && $v.promoCode.$error }"
          />
          <p v-if="$v.promoCode !== undefined && $v.promoCode.$error" class="help is-danger">
            {{ $t("Admins.AdminSystem.codeRequired") }}
          </p>
        </a-col>
      </a-row>
      <a-row class="admin-system-add-edit-dialog__row-margin">
        <a-col :span="8">
          <span>{{ `${$t("Admins.AdminSystem.promoCodeValue")}:` }}</span>
        </a-col>
        <a-col :span="16">
          <a-input
            v-model="promocodeValue"
            style="width: 52%;margin-right: 20px;"
            :class="{ 'is-danger': $v.promocodeValue !== undefined && $v.promocodeValue.$error }"
          />
          <a-select
            v-model="selectedType"
            style="width: 40%;"
            :class="{ 'is-danger': $v.selectedType !== undefined && $v.selectedType.$error }"
            @change="handleChange"
          >
            <a-select-option v-for="item in type" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <p
            v-if="
              $v.promocodeValue !== undefined &&
                !$v.promocodeValue.required &&
                $v.promocodeValue.$anyError
            "
            class="help is-danger"
          >
            {{ $t("Admins.AdminSystem.valueRequired") }}
          </p>
          <p
            v-if="
              $v.promocodeValue !== undefined &&
                !$v.promocodeValue.numeric &&
                $v.promocodeValue.$anyError
            "
            class="help is-danger"
          >
            {{ $t("Admins.AdminSystem.valueNumber") }}
          </p>
          <p v-if="$v.selectedType !== undefined && $v.selectedType.$error" class="help is-danger">
            {{ $t("Admins.AdminSystem.typeRequired") }}
          </p>
        </a-col>
      </a-row>
      <a-row class="admin-system-add-edit-dialog__row-margin">
        <a-col :span="8">
          <span>{{ `${$t("Admins.AdminSystem.startDate")}:` }}</span>
        </a-col>
        <a-col :span="16">
          <a-date-picker
            v-model="startDate"
            :format="dateFormat"
            :get-calendar-container="triggerNode => triggerNode.parentNode"
            :disabled-date="disabledStartDate"
            :class="{ 'is-danger': $v.startDate !== undefined && $v.startDate.$error }"
            @change="onChangeStartDate"
          />
          <p v-if="$v.startDate !== undefined && $v.startDate.$error" class="help is-danger">
            {{ $t("Admins.AdminSystem.startDateRequired") }}
          </p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <span>{{ `${$t("Admins.AdminSystem.expirationDate")}:` }}</span>
        </a-col>
        <a-col :span="16">
          <a-date-picker
            v-model="expirationDate"
            :format="dateFormat"
            :get-calendar-container="triggerNode => triggerNode.parentNode"
            :disabled-date="disabledDate"
            :class="{ 'is-danger': $v.expirationDate !== undefined && $v.expirationDate.$error }"
            @change="onChangeExpirationDate"
          />
          <p
            v-if="$v.expirationDate !== undefined && $v.expirationDate.$error"
            class="help is-danger"
          >
            {{ $t("Admins.AdminSystem.expRequired") }}
          </p>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators"
import moment from "moment"
export default {
  validations: {
    promocodeName: { required },
    promoCode: { required },
    promocodeValue: { required, numeric },
    selectedType: { required },
    startDate: { required },
    expirationDate: { required }
  },
  data() {
    return {
      isShow: false,
      isEdit: false,
      promocodeName: undefined,
      promoCode: undefined,
      dateFormat: "MM/DD/YYYY",
      startDate: undefined,
      expirationDate: undefined,
      startTime: undefined,
      endTime: undefined,
      promocodeValue: undefined,
      isPercent: false,
      selectedType: undefined,
      editData: undefined,
      type: [
        { id: 1, name: "%" },
        { id: 2, name: "$" }
      ]
    }
  },
  methods: {
    handleChange(value) {
      console.log(value)
      if (value === 1) {
        this.isPercent = true
      } else {
        this.isPercent = false
      }
    },
    openAddDialog() {
      this.isShow = true
      this.isEdit = false
      this.promocodeName = undefined
      this.promocodeValue = undefined
      this.promoCode = undefined
      this.selectedType = undefined
      this.startDate = undefined
      this.expirationDate = undefined
      this.editData = undefined
    },
    openEditDialog(data) {
      this.editData = data
      if (this.editData) {
        this.promocodeName = this.editData.name
        this.promocodeValue = this.editData.amount
        this.promoCode = this.editData.code
        this.isPercent = this.editData.isPercentage
        console.log(this.isPercent)
        if (this.isPercent) {
          this.selectedType = 1
        } else {
          this.selectedType = 2
        }
        this.startDate = this.editData.startTime
        this.startTime = moment(this.editData.startTime, "YYYY-MM-DD")
        this.expirationDate = this.editData.endTime
        this.endTime = moment(this.editData.endTime, "YYYY-MM-DD")
      }
      this.isShow = true
      this.isEdit = true
    },
    disabledStartDate(currentDate) {
      console.log("....disable start....")
      if (this.endTime) {
        return currentDate > this.endTime.endOf("day")
      }
      return false
    },
    disabledDate(currentDate) {
      if (this.startTime) {
        return currentDate < this.startTime.endOf("day")
      } else {
        return currentDate <= moment().endOf("day")
      }
    },
    onChangeStartDate(value) {
      console.log("....change start date....")
      this.startTime = value
      console.log(value)
    },
    onChangeExpirationDate(value) {
      console.log("....change expiration date....")
      console.log(value)
      this.endTime = value
    },
    doSaveNewValue() {
      console.log(this.$v)
      this.$v.$touch()
      if (
        this.$v.promocodeName.$anyError ||
        this.$v.promocodeValue.$anyError ||
        this.$v.selectedType.$anyError ||
        this.$v.startDate.$anyError ||
        this.$v.expirationDate.$anyError ||
        this.$v.promoCode.$anyError
      ) {
        return
      }
      let data = {
        name: this.promocodeName,
        amount: this.promocodeValue,
        code: this.promoCode,
        isPercentage: this.isPercent,
        startTime: this.startDate,
        type: 0,
        publishCount: -1,
        isActive: true,
        isEnable: true,
        endTime: this.expirationDate
      }
      if (this.editData) {
        data["privateId"] = this.editData.privateId
      }
      this.$emit("saveNew", data)
      this.isShow = false
    },
    cancelDialog() {
      this.isShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-system-add-edit-dialog {
  &__row-margin {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
.admin-system-edit-coupon-dialog .ant-btn:hover,
.admin-system-edit-coupon-dialog .ant-btn:focus,
.admin-system-edit-coupon-dialog .ant-btn:active {
  color: #4a8a5d;
  border-color: #4a8a5d;
}
.admin-system-edit-coupon-dialog .ant-btn-primary {
  background: #4a8a5d;
  border: #4a8a5d;
  color: white;
}
.admin-system-edit-coupon-dialog .ant-btn-primary:hover,
.admin-system-edit-coupon-dialog .ant-btn-primary:focus,
.admin-system-edit-coupon-dialog .ant-btn-primary:active {
  background: #5b8c00;
  border-color: #5b8c00;
  color: white;
}
</style>
