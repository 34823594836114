<template>
  <div class="retailer-training__main">
    <div class="retailer-training-breadcrumb">
      <a-breadcrumb>
        <a-breadcrumb-item>BeautyFluent</a-breadcrumb-item>
        <a-breadcrumb-item>{{ twoTextRouter }}</a-breadcrumb-item>
        <a-breadcrumb-item v-if="threeTextRouter">{{
          threeTextRouter
        }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="retailer-training-menu">
      <retailerMenu
        :current="current"
        :is-retailer="true"
        @clickToNewTab="clickToNewTab"
      ></retailerMenu>
    </div>
    <div class="retailer-training-tab-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import retailerMenu from "@/components/training/__components/brandRetailerMenu";

export default {
  name: "RetailerTraining",
  components: {
    retailerMenu,
  },
  data() {
    return {
      // current: "",
      tagList: [],
      tableDate: [],
    };
  },
  computed: {
    twoTextRouter() {
      if (
        this.$route.name === "RetailerTrainingBrand" ||
        this.$route.name === "retailerTrainingProduct" ||
        this.$route.name === "AdminRetailerTrainingBrand" ||
        this.$route.name === "AdminRetailerTrainingProduct"
      ) {
        return "Brands";
      } else if (
        this.$route.name === "retailerTrainingLocation" ||
        this.$route.name === "AdminRetailerTrainingLocation"
      ) {
        return "Location";
      } else {
        return "Users";
      }
      // } else if (this.current === "retailerTrainingUsers") {
      //   return "Users"
      // }
    },
    threeTextRouter() {
      if (
        this.$route.name === "retailerTrainingProduct" ||
        this.$route.name === "AdminRetailerTrainingProduct"
      ) {
        return this.$route.query.name;
      } else {
        return "";
      }
    },
    current() {
      console.log(this.$route);
      if (
        this.$route.name === "retailerTrainingProduct" ||
        this.$route.name === "AdminRetailerTrainingProduct" ||
        this.$route.name === "AdminRetailerTrainingBrand"
      ) {
        return "RetailerTrainingBrand";
      } else if (this.$route.name === "AdminRetailerTrainingLocation") {
        return "retailerTrainingLocation";
      } else if (this.$route.name === "AdminRetailerTrainingUsers") {
        return "retailerTrainingUsers";
      } else {
        return this.$route.name;
      }
    },
  },
  // created() {
  //   if (this.$route.name === "retailerTrainingProduct") {
  //     this.current = "RetailerTrainingBrand"
  //   } else {
  //     this.current = this.$route.name
  //   }
  // },
  // mounted() {
  //   this.current = this.$route.name
  // },
  methods: {
    ...mapActions({
      updataTagList: "training/updateBrandTags",
    }),
    clickToNewTab(value) {
      if (this.$route.params.retailerId) {
        let routeName = `Admin${value
          .substring(0, 1)
          .toUpperCase()}${value.substring(1)}`;
        console.log(routeName);
        this.$router.push({
          name: routeName,
        });
      } else {
        this.$router.push({
          name: value,
        });
      }

      // this.current = this.$route.name
    },
  },
};
</script>

<style scoped lang="scss">
.retailer-training__main {
  width: 1200px;
  min-height: 660px;
  margin: 0 auto;
  .retailer-training-breadcrumb {
    margin-top: 50px;
  }
  .retailer-training-tab-content {
    margin-top: 20px;
  }
}
</style>
