import CoreEntity from "@/models/CoreEntity";
import LoginMessageDistrict from "@/models/LoginMessageDistrict";
export default class LoginMessage extends CoreEntity {
  constructor(model) {
    super(model);
    this.id = "";
    if (model) {
      this.id = model.id || "";
      this.sellerId = model.sellerId;
      this.categoryPrivateId = model.categoryPrivateId;
      this.recipientRetailerId = model.recipientRetailerId;
      this.recipientCategoryPrivateId = model.recipientCategoryPrivateId;
      this.title = model.title;
      this.message = model.message;
      this.msgAssetId = model.msgAssetId;
      this.msgAssetUrl = model.msgAssetUrl;
      this.actionText = model.actionText;
      this.actionUrl = model.actionUrl;
      this.startDate = model.startDate ? new Date(model.startDate) : null;
      this.endDate = model.endDate ? new Date(model.endDate) : null;
      this.viewCount = model.viewCount;
      this.isPaid = model.isPaid;
      this.timePrivateId = model.timePrivateId;
      this.isDeleted = model.isDeleted;
      this.appliesTo = model.appliesTo;
      this.districtlistType = model.districtlistType;
      this.districts = (model.retailerTerritoryXrefs || []).map(
        (d) => new LoginMessageDistrict(d)
      );
    }
  }
  toModel() {
    const data = super.toModel();
    data.id = this.id;
    data.sellerId = this.sellerId;
    data.categoryPrivateId = this.categoryPrivateId;
    data.recipientRetailerId = this.recipientRetailerId;
    data.recipientCategoryPrivateId = this.recipientCategoryPrivateId;
    data.title = this.title;
    data.message = this.message;
    data.msgAssetId = this.msgAssetId;
    data.msgAssetUrl = this.msgAssetUrl;
    data.actionText = this.actionText;
    data.actionUrl = this.actionUrl;
    data.startDate = this.toDateString(this.startDate);
    data.endDate = this.toDateString(this.endDate);
    data.viewCount = this.viewCount;
    data.isPaid = this.isPaid;
    data.timePrivateId = this.timePrivateId;
    data.isDeleted = this.isDeleted;
    data.appliesTo = this.appliesTo;
    data.districtlistType = this.districtlistType;
    data.districts = this.districts.toModel();
    return data;
  }
}
