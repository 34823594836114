<template>
  <div class="landing-terms-transaction__main">
    <p class="transaction-style">Transaction Fee</p>
    <div v-if="isShowLoading" class="spin-style-admin">
      <a-spin tip="Loading..." />
    </div>
    <div v-else class="landing-terms-content">
      <div class="check-standard-style">
        <span class="title-second-style">Standard</span>
        <p
          v-if="standardInfo && brand.transactionFeeTerms && brand.transactionFeeTerms.length !== 0"
          class="table-terms-standard"
        >
          <span class="table-terms-standard-title">All Retailers</span>
          <span class="table-terms-standard-title">{{ transactionFeeValue }}%</span>
        </p>
      </div>
      <div>
        <div class="other-title">
          <span class="title-second-style">Other</span>
          <!--          <span class="title-edit-button" @click="otherEditVisible = true">edit</span>-->
        </div>
        <div
          v-if="transactionFeeList && transactionFeeList.length !== 0"
          class="table-terms-standard"
        >
          <div v-for="(item, index) in transactionFeeList" :key="index" class="table-terms-other">
            <span class="table-terms-standard-title">{{ item.name }}</span>
            <div class="tab-right-item">
              <a-checkbox
                v-if="!item.accepted"
                v-model="item.accepted"
                @change="updataTermsOther(item)"
                >Accept</a-checkbox
              >
              <span
                >{{ item.transactionFeeValue }}%<a-icon
                  v-if="item.accepted"
                  type="check"
                  :style="{ color: '#4a8a5d', marginLeft: '10px' }"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderClient from "@/api/order-client"

export default {
  name: "LandingTermsTransaction",
  data() {
    return {
      otherEditVisible: false,
      retailerValue: null,
      feeValue: "5%",
      checkValue: false,
      retailers: [],
      transactionFeeList: [],
      standardInfo: {},
      isShowLoading: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    brandId() {
      return this.isAdmin ? this.$route.params.brandId : this.$store.getters["user/businessId"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    transactionFeeValue() {
      let result = -1
      if (this.brand && this.brand.transactionFeeTerms) {
        let commissionOverrides = this.brand.transactionFeeTerms.filter(
          item => item.brandId.length > 1 && item.standard && item.retailerId === "*"
        )
        if (commissionOverrides.length) {
          result = commissionOverrides[0].transactionFeeValue
        } else {
          let basicCommision = this.brand.transactionFeeTerms.filter(
            item => item.brandId === "*" && item.standard && item.retailerId === "*"
          )
          if (basicCommision.length) {
            result = basicCommision[0].transactionFeeValue
          }
        }
      }
      if (result >= 0) {
        result = +((result / 100) * 100).toFixed(2)
      } else {
        result = 0
      }
      return result
    }
  },
  async created() {
    this.isShowLoading = true
    await this.$store.dispatch("brand/getBrand", this.brandId)
    this.formatParams()
  },
  methods: {
    handleCancelAdd() {
      this.otherEditVisible = false
    },
    formatParams() {
      this.standardInfo = this.brand.transactionFeeTerms.filter(item => item.standard)[0]
      this.brand.transactionFeeTerms
        .filter(value => !value.standard)
        .forEach(async item => {
          this.transactionFeeList = []
          const obj = await this.getRetailer(item.retailerId)
          item.name = obj.name
          this.transactionFeeList.push(item)
        })
      this.isShowLoading = false
    },
    async updataTermsOther(item) {
      const params = [
        {
          ...item,
          accepted: true
        }
      ]
      // const list = this.brand.transactionFeeTerms.filter(
      //   item => item.privateId !== param[0].privateId
      // )
      // const params = param.concat(list)
      const action = "update"
      await OrderClient.updateTransaction(this.brandId, action, params)
      await this.$store.dispatch("brand/getBrand", this.brandId)
      this.formatParams
    },
    async getRetailer(retailerId) {
      let info
      try {
        info = await this.$store.dispatch("retailer/fetchRetailer", retailerId)
      } catch (e) {
        console.log(e)
      }
      return info
    }
  }
}
</script>

<style lang="scss">
.landing-terms-transaction__main {
  .transaction-style {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .landing-terms-content {
    .check-standard-style {
      margin-bottom: 80px;
      span {
        margin-right: 20px;
      }
    }
  }
  .title-second-style {
    font-size: 24px;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .title-edit-button {
    display: inline-block;
    width: 60px;
    height: 24px;
    text-align: center;
    background-color: #f5f5f5;
    border: 1px solid #595959;
    cursor: pointer;
  }
  .table-terms-standard {
    min-height: 46px;
    line-height: 46px;
    margin-bottom: 0;
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    .table-terms-standard-title {
      display: inline-block;
      width: 280px;
    }
    p {
      width: 300px;
      text-align: right;
      margin-bottom: 0;
    }
  }
  .table-terms-other {
    display: flex;
    justify-content: flex-start;
  }
  .other-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tab-right-item {
    display: flex;
    flex-direction: column;
  }
}
</style>
