export var SubscriptionTypeEnum;
(function(SubscriptionTypeEnum) {
  SubscriptionTypeEnum[(SubscriptionTypeEnum["Classic"] = 1)] = "Classic";
  SubscriptionTypeEnum[(SubscriptionTypeEnum["BasicLegacy"] = 2)] =
    "BasicLegacy";
  SubscriptionTypeEnum[(SubscriptionTypeEnum["PremiumBasicLegacy"] = 3)] =
    "PremiumBasicLegacy";
  SubscriptionTypeEnum[(SubscriptionTypeEnum["Trial"] = 4)] = "Trial";
  SubscriptionTypeEnum[(SubscriptionTypeEnum["SubscriberBasic"] = 5)] =
    "SubscriberBasic";
  SubscriptionTypeEnum[(SubscriptionTypeEnum["Plus"] = 6)] = "Plus";
  SubscriptionTypeEnum[(SubscriptionTypeEnum["Premium"] = 7)] = "Premium";
})(SubscriptionTypeEnum || (SubscriptionTypeEnum = {}));
