<template lang="pug">
div
  .retailer-shipping-window__title {{$t('Components.Retailers.Checkout.RetailerShippingWindow.shippingWindow')}}
   span.retailer-shipping-window__edit-form
     a(@click="displayModal = true") {{$t('Components.Retailers.Checkout.RetailerShippingWindow.edit')}}
  .columns
    .column
      .field
        label.label.retailer-shipping-window__date-name {{$t('Components.Retailers.Checkout.RetailerShippingWindow.shipDate')}}
      div {{ orderShipDate || "N/A" }}
    .column
      .field
        label.label.retailer-shipping-window__date-name {{$t('Components.Retailers.Checkout.RetailerShippingWindow.cancelDate')}}
      div {{ orderCancelDate || "N/A" }}

  .modal.shipping-modal(:class="{ 'is-active': displayModal }")
    .modal-background(@click="displayModal = false")
    .modal-card.shipping-modal__content(:class="{ 'shipping-modal__body--extended': isCalendarsOpen }")
      header.shipping-modal__title--centered  {{$t('Components.Retailers.Checkout.RetailerShippingWindow.selectShippingWindow')}}
        img.shipping-modal__close-button(src="@/assets/img/close-button.png" @click="displayModal = false, displayCalendars(false)")
      section.modal-card-body.shipping-modal__body(v-click-outside="hideCalendars")
        .shipping-modal__input-group
          .columns
            .column.is-narrow
              .shipping-modal__checkbox(@click="makeFlexible")
                .shipping-modal__checkbox-dot(v-show="isFlexible")
            .column
              input.input.shipping-modal__input(@click="makeFlexible"  v-bind:placeholder="$t('Components.Retailers.Checkout.RetailerShippingWindow.flexible')" readonly :class="{ 'shipping-modal__input--selected': isFlexible }")
        .shipping-modal__input-group
          .columns
            .column.is-narrow
              .shipping-modal__checkbox(@click="displayCalendars(true)")
                .shipping-modal__checkbox-dot(v-show="isCalendarsOpen || cancelDate || shipDate")
            .column
              .control.has-icons-right
                input.input.shipping-modal__input.shipping-modal__datepicker-input(
                  :class="{ 'shipping-modal__input--selected': isCalendarsOpen || cancelDate || shipDate}"  
                  v-bind:placeholder="$t('Components.Retailers.Checkout.RetailerShippingWindow.selectShipOrCancelDate')" readonly @focus="displayCalendars(true)" :value="formattedDates")
      
                img.is-small.is-right.shipping-modal__calendar-icon(src="~@/assets/img/calendar.png" )

              .datepicker-wrapper
                Datepicker(:date="shipDate" @changeDate="changeShipDate" :isCalendarOpen='isCalendarsOpen')
                Datepicker(:date="cancelDate" :shipmntdate="shipDate" @changeDate="changeCancelDate" :isCalendarOpen='isCalendarsOpen')
        .has-text-centered
          a.button.is-black.shipping-modal__save-button(@click="emitShippingWindowChange") {{$t('Components.Retailers.Checkout.RetailerShippingWindow.save')}}
</template>

<script>
import Datepicker from "@/components/orders/Datepicker"

export default {
  components: {
    Datepicker
  },
  props: {
    orderShipDate: { type: String, default: "" },
    orderCancelDate: { type: String, default: "" }
  },
  data() {
    return {
      shipDate: "",
      cancelDate: "",
      displayModal: false,
      isCalendarsOpen: false,
      isFlexible: false
    }
  },
  computed: {
    retailerId() {
      return this.$store.getters["user/businessId"]
    },
    formattedDates() {
      if (!this.shipDate && !this.cancelDate) {
        return
      }
      return [`ship ${this.shipDate}`, `cancel ${this.cancelDate}`].join(", ")
    }
  },
  methods: {
    displayCalendars(state = true) {
      this.isCalendarsOpen = state
      this.isFlexible = false
    },
    emitShippingWindowChange() {
      this.$emit("shippingWindowChange", this.shipDate, this.cancelDate)
      this.displayModal = false
    },
    changeCancelDate(date) {
      this.cancelDate = date
      this.isCalendarsOpen = false
    },
    changeShipDate(date) {
      this.shipDate = date
    },
    makeFlexible() {
      this.isFlexible = true
      this.isCalendarsOpen = false
      this.shipDate = ""
      this.cancelDate = ""
    },
    hideCalendars() {
      this.isCalendarsOpen = false
    }
  }
}
</script>

<style lang="sass">
.retailer-shipping-window
  &__edit-form
    text-decoration: underline
    color: #161616
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.16px
    line-height: 18px
    margin-left: 11px

    a
      color: #161616

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.25px
    margin-bottom: 25px

  &__date-name
    color: #888888
    font-size: 12px
    text-transform: uppercase
    margin-bottom: 16px
    font-weight: 500

.shipping-modal
  &__content
    position: relative
    background-color: #FFFFFF
    width: 660px
    height: 520px
    overflow: scroll

  &__close-button
    position: absolute
    top: 24px
    right: 24px
    cursor: pointer
    height: 12.55px
    width: 12.74px

  &__title--centered
    display: flex
    justify-content: center
    font-size: 20px
    font-weight: 600
    padding-top: 70px
    letter-spacing: 0.28px
    padding-bottom: 120px

  &__body--extended
    height: 670px

  &__input-group
    padding-left: 160px
    padding-right: 180px
    padding-bottom: 20px

  &__datepicker-input
    padding-left: 20px

  &__input
    height: 40px
    color: #000000
    font-size: 14px
    font-weight: bold

  &__input::placeholder
    color: #000000 !important
    font-size: 14px
    font-weight: bold

  &__input:focus
    outline: none !important
    border: 2px solid #858585
    box-shadow: none !important

  &__input--selected
    border: 2px solid #858585

  &__calendar-icon
    cursor: pointer
    position: absolute
    right: 15px
    top: 10px

  &__checkbox
    width: 22px
    height: 22px
    border-radius: 50%
    border: 1px solid #979797
    margin-top: 10px
    margin-right: 10px

  &__checkbox-dot
    width: 10px
    height: 10px
    border-radius: 50%
    background-color: #000000
    margin-top: 5px
    margin-left: 5px

  &__save-button
    margin-top: 80px
    height: 36px
    width: 83px
    font-size: 12px
    font-weight: bold

.datepicker-wrapper
   margin-left: -160px
   width: 600px
   z-index: 10
   position: absolute
   background: white
   box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3)
   max-height: 1000px
</style>
