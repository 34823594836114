<template>
  <div ref="retailerItemPro" class="retailer-item-product__main">
    <a-modal v-model="showProduct" width="1200px" :footer="null" :get-container="() => $refs.retailerItemPro"
      @cancel="closeDialog">
      <p slot="title" class="item-title-retailer-pro">
        {{ retailerInfo.retailerName }} Product Training List
      </p>
      <div class="item-content-retailer-pro training-page-table">
        <table class="retailer-item-table-style training-table-style">
          <thead>
            <tr class="table-tr-header">
              <th style="width:473px;">
                Name
                <a-icon style="cursor: pointer" :type="isNameSort ? 'caret-down' : 'caret-up'" class="icon-sort-style"
                  @click="sortList('name')"></a-icon>
              </th>
              <th style="width:173px;">
                Hero
                <a-icon style="cursor: pointer" :type="isHeroSort ? 'caret-down' : 'caret-up'" class="icon-sort-style"
                  @click="sortList('hero')"></a-icon>
              </th>
              <th style="width:173px;">
                UPC
                <a-icon style="cursor: pointer" :type="isUPCSort ? 'caret-down' : 'caret-up'" class="icon-sort-style"
                  @click="sortList('upc')"></a-icon>
              </th>
              <th style="width:123px;">
                SKU
                <a-icon style="cursor: pointer" :type="isSKUSort ? 'caret-down' : 'caret-up'" class="icon-sort-style"
                  @click="sortList('sku')"></a-icon>
              </th>
              <th style="width:115px;">
                Date
                <a-icon style="cursor: pointer" :type="isDateSort ? 'caret-down' : 'caret-up'" class="icon-sort-style"
                  @click="sortList('date')"></a-icon>
              </th>
              <th style="width:109px;">
                Public
              </th>
            </tr>
          </thead>
          <tr v-for="item in tableDate" :key="item.id">
            <!--              <td style="width:115px;" class="move data-tab-style">-->
            <!--                {{ item.updatedAt }}-->
            <!--              </td>-->
            <td style="width:473px;" class="name-tab-style">
              {{ item.name }}
            </td>
            <td style="width:173px;">
              <span v-if="item.training.hero" class="hero-product-position">Hero</span>
            </td>
            <td style="width:173px;">{{ item.barcodes[0].code }}</td>
            <td style="width:123px;">{{ item.sku }}</td>
            <td style="width:115px;" class="data-tab-style">
              {{ item.updatedAt }}
            </td>
            <td style="width:109px" class="click-pro-action">
              <div class="editable-row-operations">
                <a-switch v-model="item.public" :loading="loadingPublic" :disabled="!item.training.published"
                  @change="onChange(item)" />
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="retailer-product-item-button">
        <a-button class="close-button-product-item" @click="closeDialog">Close</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "RetailerItemProduct",
  data() {
    return {
      isUpdating: false,
      showProduct: false,
      retailerInfo: {},
      tableDate: [],
      loadingPublic: false,
      isNameSort: false,
      isHeroSort: false,
      isUPCSort: false,
      isSKUSort: false,
      isDateSort: false,
    };
  },
  methods: {
    ...mapActions({
      updateTrainingRetailer: "training/updateTrainingProductRetailer",
    }),
    openDialogPro(retailerItem, tableDate) {
      this.showProduct = true;
      this.retailerInfo = retailerItem;
      this.tableDate = this.formatTableData(
        this.retailerInfo.retailerId,
        tableDate
      );
    },
    formatTableData(retailerId, list) {
      return list.map((item) => {
        let trainingInfo = [];
        if (
          item.trainingForRetailers &&
          item.trainingForRetailers.length !== 0
        ) {
          trainingInfo = item.trainingForRetailers.filter(
            (item) => item.retailerId === retailerId
          );
        }
        if (trainingInfo && trainingInfo.length !== 0) {
          item.public = trainingInfo[0].public;
        } else {
          item.public = false;
        }
        return item;
      });
    },
    closeDialog() {
      this.showProduct = false;
      this.$emit("updateRetailerList");
    },
    async onChange(item) {
      if (this.loadingPublic) {
        return;
      }
      this.loadingPublic = true;
      const params = [
        {
          retailerId: this.retailerInfo.retailerId,
          public: item.public,
        },
      ];
      await this.updateTrainingRetailer({ productId: item.id, params });
      this.$emit("updateRetailerInfo", this.retailerInfo);
      this.loadingPublic = false;
    },
    async sortList(sortValue) {
      const info = this.formatSortValue(sortValue);
      this.handleName(info["_sort-by"], info.isAscend);
      // const sortInfo = this.formatSortValue(sortValue)
      // let params = {
      //   _index: 1,
      //   _limit: this.currentNum,
      //   ...sortInfo
      // }
      // await this.fetchData(params)
    },
    formatSortValue(value) {
      // this.initSort()
      if (value === "name") {
        this.isNameSort = !this.isNameSort;
        this.isHeroSort = false;
        this.isUPCSort = false;
        this.isSKUSort = false;
        this.isDateSort = false;
        return {
          "_order-by": this.isNameSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isNameSort,
        };
      }
      if (value === "hero") {
        this.isHeroSort = !this.isHeroSort;
        this.isNameSort = false;
        this.isUPCSort = false;
        this.isSKUSort = false;
        this.isDateSort = false;
        return {
          "_order-by": this.isHeroSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isHeroSort,
        };
      }
      if (value === "upc") {
        this.isUPCSort = !this.isUPCSort;
        this.isNameSort = false;
        this.isHeroSort = false;
        this.isSKUSort = false;
        this.isDateSort = false;
        return {
          "_order-by": this.isUPCSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isUPCSort,
        };
      }
      if (value === "sku") {
        this.isSKUSort = !this.isSKUSort;
        this.isNameSort = false;
        this.isHeroSort = false;
        this.isUPCSort = false;
        this.isDateSort = false;
        return {
          "_order-by": this.isSKUSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isSKUSort,
        };
      }
      if (value === "date") {
        this.isDateSort = !this.isDateSort;
        this.isNameSort = false;
        this.isHeroSort = false;
        this.isUPCSort = false;
        this.isSKUSort = false;
        return {
          "_order-by": this.isDateSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isDateSort,
        };
      }
    },
    handleName(sorter, isAscend) {
      let index;
      if (sorter === "name") {
        index = 1;
      } else if (sorter === "hero") {
        index = 3;
      } else if (sorter === "upc") {
        index = 2;
      } else if (sorter === "sku") {
        index = 5;
      } else if (sorter === "date") {
        index = 6;
      }
      this.sortData(index, isAscend);
    },
    sortData(index, ascend) {
      if (index == 1) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.name.localeCompare(b.name);
          } else {
            return b.name.localeCompare(a.name);
          }
        });
      } else if (index == 6) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            if (a.updatedAt != null && b.updatedAt != null) {
              return a.updatedAt.localeCompare(b.updatedAt);
            }
            if (a.updatedAt != null && b.updatedAt == null) {
              return a.updatedAt.localeCompare(b.createdAt);
            }
            if (a.updatedAt == null && b.updatedAt != null) {
              return a.createdAt.localeCompare(b.updatedAt);
            }
          } else {
            if (b.updatedAt != null && a.updatedAt != null) {
              return b.updatedAt.localeCompare(a.updatedAt);
            }
            if (b.updatedAt != null && a.updatedAt == null) {
              return b.updatedAt.localeCompare(a.createdAt);
            }
            if (b.updatedAt == null && a.updatedAt != null) {
              return b.createdAt.localeCompare(a.updatedAt);
            }
          }
        });
      } else if (index == 3) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            if (a.training.hero && !b.training.hero) {
              return -1;
            }
            if (!a.training.hero && b.training.hero) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (a.training.hero && !b.training.hero) {
              return 1;
            }
            if (!a.training.hero && b.training.hero) {
              return -1;
            } else {
              return 0;
            }
          }
        });
      } else if (index == 2) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.barcodes[0].code.localeCompare(b.barcodes[0].code);
          } else {
            return b.barcodes[0].code.localeCompare(a.barcodes[0].code);
          }
        });
      } else if (index === 5) {
        this.tableDate.sort((a, b) => {
          if (ascend) {
            return a.sku.localeCompare(b.sku);
          } else {
            return b.sku.localeCompare(a.sku);
          }
        });
      }
    },
  },
};
</script>


<style scoped lang="scss">
.retailer-item-product__main {
  p {
    margin-bottom: 0;
  }

  .item-title-retailer-pro {
    // width: 100%;
    text-align: center;
  }

  .training-page-table {
    // width: 840px;

    //height: 500px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      // width: 100%;
      //max-height: 500px;
      overflow-y: auto;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }

    .training-table-style tr {
      width: 100%;
      position: relative;
    }

    .training-table-style th {
      //border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 10px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
    }

    .training-table-style td {
      height: 56px;
      //border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 10px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .training-table-style tr,
    .training-table-style td.move:hover {
      //cursor: pointer;
    }

    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }

    .name-tab-style {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .data-tab-style {
      //position: relative;
    }
  }

  .hero-product-position {
    padding: 3px 8px;
    color: #ffffff;
    font-size: 12px;
    background-color: #f3ca50;
    border: 1px solid #f3ca50;
    border-radius: 100px;
  }

  .retailer-product-item-button {
    // width: 100%;
    text-align: right;
    padding: 20px;

    .close-button-product-item {
      width: 160px;
      height: 30px;
      border-radius: 7px;
      background-color: #f27c59;
      color: white;
    }
  }

  .item-content-retailer-pro {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .icon-sort-style {
    color: #8c8c8c;
  }
}

.ant-switch {
  background-color: #f27c59;
  background-image: none !important;
}

.ant-switch-checked {
  background-color: #9fd19a;
}
</style>
