import httpclient from "@/api/http-client"
import ProductTrainingBestUsedFor from "@/models/ProductTrainingBestUsedFor"

export default {
  async getBestUsedFors(categoryPrivateId) {
    return await httpclient
      .get(`/api/BestUsedFors/by-category/${categoryPrivateId}`)
      .then((res) => res.data.map((m) => new ProductTrainingBestUsedFor(m)))
  },
  async addBestUsedFor(bestUseFor) {
    return await httpclient
      .post("/api/BestUsedFors", bestUseFor.toModel())
      .then((res) => res.data.map((m) => new ProductTrainingBestUsedFor(m)))
  },
  async addBestUsedFors(bestUseFors) {
    return await httpclient
      .post(
        "/api/BestUsedFors/many",
        bestUseFors.map((s) => s.toModel())
      )
      .then((res) => res.data.map((m) => new ProductTrainingBestUsedFor(m)))
  },
  async deleteBestUsedFor(bestUsedForPrivateId) {
    return await httpclient.delete(`/api/BestUsedFors/${bestUsedForPrivateId}`)
  },
}
