<template>
  <div ref="createLocation" class="create-new-location-dialog__main">
    <a-modal
      v-model="showModal"
      width="550px"
      :footer="null"
      :get-container="() => $refs.createLocation"
      @cancel="closeModal"
    >
      <p v-if="isAdd" slot="title" class="modal-title-location">Add Location</p>
      <p v-else slot="title" class="modal-title-location">Edit Location</p>
      <a-form-model
        :model="locationForm"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        class="form-location-dialog"
      >
        <a-form-model-item>
          <a-input
            v-model="locationForm.storeNumber"
            type="number"
            :max-length="maxLength"
            placeholder="store#"
            @change="inputStoreNumber(locationForm.storeNumber)"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="locationForm.address" placeholder="Address" />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="locationForm.city" placeholder="City" />
        </a-form-model-item>
        <a-form-model-item>
          <a-select v-model="locationForm.state" placeholder="State">
            <a-select-option v-for="item in countries" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="locationForm.postalCode" placeholder="Zip" />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="locationForm.phone" :placeholder="!isAdd ? 'Contact' : 'Telephone'" />
        </a-form-model-item>
        <div v-if="!isAdd" class="location-active-style">
          {{ locationForm.active ? "Location Active" : "Location Deactivated" }}
          <a-switch v-model="locationForm.active" />
        </div>
        <div v-else class="upload-csv-file">
          <p @click="downloadTemplateCSV">Download CSV template</p>
          <p @click="showUploadDialog">Upload CSV</p>
        </div>
        <a-form-model-item :wrapper-col="{ span: 18, offset: 0 }">
          <a-button class="location-save-style" style="background: #F27C59" @click="closeModal">
            Cancel
          </a-button>
          <a-button
            class="location-save-style"
            style="background: #A0D29B;margin-left: 15px"
            @click="OnSaveForm"
          >
            Save
          </a-button>
          <!--          <a-button v-if="!isAdd" class="location-save-style" @click="deactiveLocation">-->
          <!--            Deactivate Location-->
          <!--          </a-button>-->
        </a-form-model-item>
        <p v-if="showError" :style="{ color: showError ? 'red' : '', 'text-align': 'left' }">
          The fields must not be empty
        </p>
        <!--        <a-form-model-item v-if="isAdd" label="Upload Bulk:">-->
        <!--          <a-button class="location-save-style" @click="uploadBulk">-->
        <!--            Attach CSV-->
        <!--          </a-button>-->
        <!--        </a-form-model-item>-->
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="showUpload"
      width="600px"
      :footer="null"
      :get-container="() => $refs.createLocation"
      @cancel="handleCancelUpload"
    >
      <div class="upload-modal__body">
        <a-upload-dragger
          name="file"
          :multiple="false"
          :file-list="fileList"
          :show-upload-list="false"
          :before-upload="beforeUpload"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="cloud-upload" style="color: #4A8a5d" />
          </p>
          <p class="ant-upload-text">
            {{ $t("Admins.AdminOrders.dragUpload") }}
          </p>
          <p class="ant-upload-text">
            {{ $t("Admins.AdminOrders.or") }}
          </p>
          <a-button class="upload-modal__btn">
            {{ $t("Admins.AdminOrders.chooseFile") }}
          </a-button>
        </a-upload-dragger>
        <div v-if="fileList.length > 0" class="upload-modal__body">
          <a-row v-for="(item, index) in fileList" :key="index">
            <a-col :span="1">
              <a-icon type="paper-clip" />
            </a-col>
            <a-col :span="19">
              {{ item.name }}
            </a-col>
            <a-col :span="4">
              <a-icon type="delete" @click="handleRemove(item)" />
            </a-col>
          </a-row>
        </div>
        <div class="retailer-location-upload-modal__action">
          <a-button
            class="upload-modal__btn"
            :disabled="fileList.length === 0"
            :loading="uploading"
            @click="handleUpload"
          >
            {{ $t("Admins.AdminOrders.upload") }}
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { downloadRetailerLocationCsvTemplate } from "@/utils/validate"

export default {
  name: "CreateNewLocationDialog",
  data() {
    return {
      showModal: false,
      showError: false,
      isAdd: true,
      showUpload: false,
      uploading: false,
      maxLength: 8,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      retailerItem: {},
      locationForm: {
        storeNumber: "",
        address: "",
        city: "",
        state: undefined,
        postalCode: "",
        phone: "",
        active: false
      },
      fileList: []
    }
  },
  computed: {
    countries() {
      return this.$store.getters["reference/stateName"]
    },
    role() {
      return this.$store.getters["user/role"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    }
  },
  methods: {
    async openCreateLocation(isShow, isAdd, item) {
      this.showModal = isShow
      this.isAdd = isAdd
      this.retailerItem = item
      if (!isAdd && item) {
        this.locationForm = {
          storeNumber: item.storeNumber,
          address: item.address,
          city: item.city,
          state:
            Object.keys(item.country).length !== 0
              ? item.country.id.toLowerCase()
              : item.state.id.toLowerCase(),
          postalCode: item.postalCode,
          phone: item.phone,
          active: item.active
        }
      }
      await this.$store.dispatch("reference/setReference")
    },
    closeModal() {
      this.showModal = false
      this.showError = false
      this.locationForm = {
        storeNumber: "",
        address: "",
        city: "",
        state: undefined,
        postalCode: "",
        phone: ""
      }
    },
    OnSaveForm() {
      const param = {
        ...this.retailerItem,
        ...this.locationForm,
        state: {
          id: this.locationForm.state
        }
      }
      if (!this.checkForm(param)) {
        this.showError = true
        return
      }
      // if (Object.values(param).filter(item => item))
      this.$emit("createOrUpdate", param, this.isAdd)
      this.closeModal()
    },
    checkForm(param) {
      if (
        !param.storeNumber ||
        !param.address ||
        !param.city ||
        !param.state.id ||
        !param.postalCode ||
        !param.phone
      ) {
        return false
      } else {
        return true
      }
    },
    uploadBulk() {},
    deactiveLocation() {
      const param = {
        ...this.retailerItem,
        ...this.locationForm,
        state: {
          id: this.locationForm.state
        },
        active: false
      }
      this.$emit("createOrUpdate", param, this.isAdd)
      this.showModal = false
    },
    async downloadTemplateCSV() {
      // let csvDate = ["Location Id, Address, City, State, Zip, Telephone"]
      await downloadRetailerLocationCsvTemplate()
    },
    showUploadDialog() {
      this.showUpload = true
      this.showModal = false
      this.fileList = []
    },
    handleCancelUpload() {
      this.showUpload = false
      this.showModal = false
      this.fileList = []
      this.$emit("fetchData")
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = []
      this.fileList.push(file)
      return false
    },
    handleUpload() {
      if (this.fileList && this.fileList.length > 0) {
        let isFormatRight = true
        for (let i = 0; i < this.fileList.length; i++) {
          let name = this.fileList[i].name
          isFormatRight = this.isCsv(name)
          if (!isFormatRight) {
            let info = this.$t("Admins.AdminOrders.uploadFailInfo")
            this.$message.error(info)
            return
          }
        }
        this.doUpload(this.fileList[0])
      }
    },
    isCsv(name) {
      if (name) {
        let index = name.lastIndexOf(".")
        let ext = name.substr(index + 1)
        if (ext.toLowerCase() === "csv") {
          return true
        }
      }
      return false
    },
    doUpload(file) {
      let fileReader = new FileReader()
      const formData = new FormData()
      fileReader.readAsText(file)
      fileReader.onloadend = e => {
        let data = new Blob([e.target.result], { type: "text/csv" })
        formData.append("file", data, file.name)
        this.uploading = true
        this.$store
          .dispatch("training/uploadRetailerLocation", {
            retailerId: this.retailer.id,
            file: formData
          })
          .then(res => {
            if (res.states) {
              this.uploadSuccess = true
            } else if (res.reports) {
              this.errorReports = ""
              this.errors = ""
              res.reports.forEach(item => {
                let index = item.indexOf("cause:")
                if (index > 0) {
                  this.errorReports += `${item.substring(0, index)}\n`
                  this.errors += `${item.substring(0, index)}<br/>`
                } else {
                  this.errorReports += `${item}\n`
                  this.errors += `${item}<br/>`
                }
              })
              // this.showErrorReport()
            }
            this.handleCancelUpload()
          })
          .catch(err => {
            // if (err.data.error.underlying && err.data.error.underlying.length > 0) {
            //   let errorMsg = ""
            //   for (let i = 0; i < err.data.error.underlying.length; i++) {
            //     errorMsg += err.data.error.underlying[i].userMessage
            //     if (i < err.data.error.underlying.length - 1) {
            //       errorMsg += ","
            //     }
            //   }
            //   this.$message.error(errorMsg)
            // }
            this.$message.error(err.message)
          })
          .finally(() => {
            this.uploading = false
            this.isShiping = false
          })
      }
    },
    inputStoreNumber(txt) {
      const reg = /^\d{1,8}$/
      if (reg.test(txt)) {
        this.locationForm.storeNumber = txt
      } else {
        this.locationForm.storeNumber = txt.slice(0, 8)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.create-new-location-dialog__main {
  p {
    margin-bottom: 0;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .form-location-dialog {
    padding-left: 55px;
    text-align: center;
  }
  .ant-form-item {
    text-align: center;
  }
  .location-active-style {
    color: #1a1818;
    padding-right: 114px;
    text-align: right;
    margin-bottom: 10px;
  }
  .location-save-style {
    width: 160px;
    background-color: #4a8a5d;
    color: white;
  }
  .attach-csv-style {
    background-color: #4a8a5d;
  }
  .upload-csv-file {
    padding-right: 114px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #e1644c;
    text-decoration: underline;
    cursor: pointer;
  }
  .ant-switch {
    background: #f27c59;
    background-image: none !important;
  }
  .ant-switch-checked {
    background: #a0d29b;
  }
}
</style>
