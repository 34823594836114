<template>
  <div class="columns retailer-cart__product-border cart-product-group is-marginless">
    <div class="column is-3 retailer-cart__column-with-image">
      <router-link class="retailer-cart__image"
                   :to="{ name: 'retailerProduct', params: { id: cartProduct.id }}">
        <img :src="mainImageUrl(cartProduct.product)" />
      </router-link>
      <a class="cart-product-group__link"
         @click="moveProductToCart(cartProduct, true)">
        {{$t('Components.Retailers.Cart.SavedCartProductGroup.movetoCart')}}
      </a>
    </div>
    <div class="column is-4 retailer-cart__product-info">
      <router-link class="retailer-cart__title"
                   :to="{ name: 'retailerProduct', params: { id: cartProduct.product.id} }">
        {{ cartProduct.product.name }}
      </router-link>
      <p class="retailer-cart__subtitle">
        {{ parseFloat(cartProduct.product.size) }} {{ cartProduct.product.sizeUnits.id }} · {{ cartProduct.product.sku }}
      </p>
    </div>
    <div class="column retailer-cart__prices has-text-right">
      <div class="columns">
        <div class="column is-6 has-text-right">
          <p class="retailer-cart__subtitle_black">
            {{ priceFormat(cartProduct.product.wholesalePriceUsd)  }}
          </p>
          <p class="retailer-cart__subtitle-brown">
            {{ cartProduct.product.margin }}%
          </p>
        </div>
        <div class="column is-1 has-text-left">
          <p class="retailer-cart__subtitle--line-through">
            {{ priceFormat(cartProduct.product.msrpUsd) }}
          </p>
        </div>
        <div class="column is-5 has-text-centered">
          <img class="retailer-cart__close-button"
               @click="removeProductFromCart(cartProduct.id)"
               src="@/assets/img/icon/close-icon.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { cartProduct: { type: Object, default: () => {} } },
  computed: {
    orderTypes() {
      return this.$store.getters["reference/orderTypes"]
    }
  },
  methods: {
    mainImageUrl(product) {
      if (!product.images) {
        return ""
      }

      const mainImage = product.images.find(image => image.main)
      return mainImage && mainImage.asset.url
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    orderTypeName(orderTypeId) {
      const orderType = this.orderTypes.find(orderType => orderType.id === orderTypeId)
      return orderType && orderType.name
    },
    moveProductToCart(cartProduct, isPurchase) {
      this.$emit("moveProductToCart", cartProduct, isPurchase)
    },
    removeProductFromCart(productId) {
      this.$emit("removeProductFromCart", productId)
    }
  }
}
</script>

<style lang="sass"></style>
