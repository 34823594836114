<template>
  <div class="products-filter">
    <div class="products-filter__header-name">
      {{ title }}
      <span class="products-filter__header--icon" @click="openListAndHideExtendedItems()" v-show="!isOpen">
        <img src="@/assets/img/inputs/filters-arrow-icon.svg"/>
      </span>
      <span class="products-filter__header--icon" @click="openListAndHideExtendedItems(), isDropDownOpen=false" v-show="isOpen">
        <img src="@/assets/img/inputs/filters-arrow-icon-up.svg" />
      </span>
      <p class="products-filter__subheader-name" v-if="title === 'Ingredients' || isNeedSubHeader">
        <span>Match</span>
        <span class="filter-dropdown" @click="isDropDownOpen = true">
          {{this.option}}
        </span>
        <span @click="isDropDownOpen=true" v-if="!isDropDownOpen">
          <img class="arrow" src="@/assets/img/inputs/filters-arrow-icon.svg" />
        </span>
        <span @click="isDropDownOpen=false" v-if="isDropDownOpen">
          <img class="arrow" src="@/assets/img/inputs/filters-arrow-icon-up.svg" />
        </span>
        <span>
          filter items
        </span>
      </p>
      <div class="filter-dropdown-list open" v-if="isDropDownOpen">
        <div class="item" @click="isDropDownOpen=false, optionAll()" :class="{ 'is-selected-option': this.option == 'All'}">
          <div class="text-neme">All</div>
        </div>
        <div @click="isDropDownOpen=false, optionAny()" :class="{ 'is-selected-option': this.option == 'Any'}">
          <div class="text-neme">Any</div>
        </div>
      </div>
      <span class="products-filter__header--reset" v-show="isOpen && isAnyItemsChecked" @click="resetAllCheckedItems()">
        {{$t('Components.Filters.ProductsFilter.resetAll')}}
      </span>
    </div>
    <div class="products-filter__body">
      <ul class="products-filter__items products-filter__items--scrollable"
          :class="{ 'products-filter__items--visible': isOpen, 'products-filter__items--scrollable_large': countryAndTagFilters }">
        <li class="products-filter__item"
            v-for="item in items"
            :key="item.id"
            @click="switchItem(item)">
          <span class="products-filter__icon">
            <img src="@/assets/img/checkbox.png"/>
            <img class="products-filter__item--checked" v-show="item.checked" src="@/assets/img/inputs/checked.svg" />
          </span>
          <span class="products-filter__item-name">
            {{item.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    items: { type: Array, default: () => [] },
    isNeedSubHeader: { type: Boolean, default: false }
  },
  data() {
    return {
      isOpen: true,
      isExtended: false,
      option: "All",
      isDropDownOpen: false,
      hasCheckedItems: false
    }
  },
  computed: {
    countryAndTagFilters() {
      return ["Country of Origin", "Tags"].includes(this.title) && this.isExtended
    },
    isAnyItemsChecked() {
      return this.items.filter(item => item.checked).length
    }
  },
  methods: {
    switchItem(item) {
      console.log('switchItem')
      console.log(item)
      item.checked = !item.checked
      this.$emit("option", this.option)
      this.$emit("filterSwitch")
    },
    openListAndHideExtendedItems() {
      this.isOpen = !this.isOpen
      if (this.isExtended === true) {
        this.isExtended = !this.isExtended
      }
    },
    resetAllCheckedItems() {
      this.items.forEach(item => (item.checked = false))
      this.$emit("filterSwitch")
    },
    fetchItemsOnOptionChange() {
      for (var item in this.items) {
        if (this.items[item].checked) {
          this.hasCheckedItems = true
        }
      }
      if (this.hasCheckedItems) {
        this.$emit("option", this.option)
        this.$emit("filterSwitch")
        this.hasCheckedItems = false
      }
    },
    optionAll() {
      this.option = "All"
      this.fetchItemsOnOptionChange()
    },
    optionAny() {
      this.option = "Any"
      this.fetchItemsOnOptionChange()
    }
  }
}
</script>

<style lang="sass">
.arrow
  height: 7px
  max-width: 10px
  margin-right: 2px

.is-selected-option
  color: #4A90E2

.products-filter__item--checked
  position: absolute
  right: 3px
  top: 3px
  width: 18px
  height: 18px

.products-filter
  ::-webkit-scrollbar
    width: 10px

  ::-webkit-scrollbar-track
    border-radius: 10px

  ::-webkit-scrollbar-thumb
    background: #E5E5E5
    border-radius: 10px

  ::-webkit-scrollbar-thumb:hover
    background: #B3B3B3

  &__item
    height: 27px
    font-size: 12px
    font-weight: 300
    position: relative

  &__items
    display: none
    overflow: hidden
    margin-top: 10px

    &--scrollable
      overflow-y: scroll
      max-height: 130px

  &__items--scrollable_large
    max-height: 240px

  &__see-all
    color: #B3B3B3
    font-size: 12px
    font-weight: 300

  &__items--visible
    display: block

  &__icon
    position: absolute
    left: 0
    margin-right: 20px

  &__header-name
    position: relative
    display: block
    cursor: pointer
    border-bottom: 1px solid #EBEFF2
    padding: 16px 0px 16px 0px
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

    .filter-dropdown
      padding-left: 4px
      padding-right: 4px
      display flex
      color: #4A90E2
      position: relative

      button
        padding-left: 4px
        background: none
        border: none
        outline: none
        svg
          fill: #4A90E2
          width: 8px

    .filter-dropdown-list
      background: #f6f6f6
      position: absolute
      left: 18px
      z-index: 11
      width: 80px
      display: none

      .item
        display: flex
        align-items: center
        justify-content: flex-start
        padding: 5px 10px

        &:hover
          background-color: #e4e4e4

    .filter-dropdown-list.open
      display: block


  &__subheader-name
    position: relative
    display: block
    cursor: pointer
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

  &__header--icon
    float: right
    padding-right: 10px

  &__header--reset
    float: right
    color: #B3B3B3

  &__item-name
    margin-left: 24px
    font-size: 14px

.product-card-details-list
      background: #fff
      width: 268px
      //padding: 10px 20px
      position: absolute
      top: 12px
      right: 8px
      border: 1px solid #e5e5e5
      box-shadow: 0px 4px 12px rgba(0,0,0,0.06)
      display: none

      .list-item-block
        display: flex
        align-items: center
        padding: 10px 20px
        cursor: pointer

        &:hover
          background-color: #f6f6f6

        .icon-block
          min-width: 20px

        .text-block
          padding-left: 10px

          p
            color: #212B36
            font-size: 14px

        .filter-dropdown
          display: flex
          p
            color: #4A90E2
</style>
