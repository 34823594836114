var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.from && _vm.toData)?_c('a-row',{staticStyle:{"padding":"12px 20px"}},[_c('div',{staticClass:"admin-message-brand-create-new-modal__intro-brand"},[_c('div',{staticClass:"admin-message-brand-create-new-modal__intro-brand-title"},[_vm._v(" "+_vm._s(_vm.from ? _vm.from.name : "")+" ")]),_c('div',{staticClass:"admin-message-brand-create-new-modal__intro-brand-subtitle"},[_vm._v(" "+_vm._s(`To: ${_vm.toData ? _vm.toData.name : ""}`)+" ")]),_c('div',{staticClass:"admin-message-brand-create-new-modal__intro-brand-divider"}),_c('div',{staticClass:"message-input-content__content"},[_c('img',{staticClass:"admin-message-brand-create-new-modal__intro-brand-img",attrs:{"src":_vm.from && _vm.from.bannerAsset && _vm.from.bannerAsset.url && _vm.from.bannerAsset.url.length > 0
            ? _vm.from.bannerAsset.url
            : ''}}),_c('div',{staticClass:"admin-message-brand-create-new-modal__intro-brand-content"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('Admins.AmdinMessage.introBrand', {
              brandName: _vm.from.name,
              country: _vm.countryAddress,
              tags: _vm.tagsValue,
              retailerName: _vm.toData.name,
              brandId: _vm.from.id,
              profileLink: 'https://landinginternational.com/retailer/brand/' + _vm.from.id,
              aboutUs: _vm.from.introduction,
              brandWebsite: 'http://' + _vm.from.website,
              retailerChannel: 'retailerChannel',
              heroProducts: _vm.heroProductNames
            })
          )}})])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }