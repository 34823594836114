export default class OrderStatus {
  constructor(model) {
    this.privateId = 0;
    this.id = "";
    this.name = "";
    this.description = null;
    this.isActive = 0;
    this.createdAt = new Date();
    this.createdBy = null;
    this.updatedAt = null;
    this.updatedBy = null;
    if (model) {
      this.privateId = model.privateId;
      this.id = model.id;
      this.name = model.name;
      this.description = model.description;
      this.isActive = model.isActive;
      this.createdAt = new Date(model.createdAt);
      this.createdBy = model.createdBy;
      this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null;
      this.updatedBy = model.updatedBy;
    }
  }
}
