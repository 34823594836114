export default {
  status: {
    isLoading: {
      categories: false,
      categoryTrainingSteps: {},
      categoryTrainingBestUsedFors: {},
    },
    isSaving: false,
  },
  categories: [],
  categoryTrainingSteps: {},
  categoryTrainingBestUsedFors: {},
};
