<template>
  <div class="admin-orders">
    <div v-if="isShowPercent" class="progress-download-tips">
      <p>Downloading</p>
      <a-progress
        :percent="percentNumber"
        :stroke-color="{ '0%': '#87d068', '100%': '#1c7430' }"
      ></a-progress>
    </div>
    <div class="modal-container">
      <a-modal v-model="visible" width="600px" @cancel="handleCancle">
        <div slot="title" class="modal-title"></div>
        <div class="modal-body">
          <div class="modal-date-select">
            <div class="modal-filter">
              <span class="modal-date-font" style="margin-right:65px;">
                Date
              </span>
              <a-range-picker
                v-model="rangepicker"
                format="MM-DD-YYYY"
                :disabled-date="disabledDate"
                @change="orderChange"
              />
            </div>
          </div>
          <div class="modal-header">
            <span class="modal-date-font">
              Status
            </span>
            <a-radio-group
              v-model="checkedList"
              :options="StatusOptions"
              @change="handleChange"
            ></a-radio-group>
          </div>
        </div>
        <div slot="footer" class="modal-footer">
          <a-button @click="handleclear">
            {{ $t("Admins.AdminOrders.clearAllFilters") }}
          </a-button>
          <a-button key="back" @click="handleCancle">
            {{ $t("Admins.AdminOrders.cancel") }}
          </a-button>
          <a-button key="submit" type="primary" @click="exportOrderExcel">
            {{ $t("Admins.AdminOrders.apply") }}
          </a-button>
        </div>
      </a-modal>
      <a-modal
        v-model="showUpload"
        width="600px"
        :footer="null"
        @cancel="handleCancelUpload"
      >
        <div class="upload-modal__body">
          <a-upload-dragger
            name="file"
            :multiple="false"
            :file-list="fileList"
            :show-upload-list="false"
            :before-upload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="cloud-upload" />
            </p>
            <p class="ant-upload-text">
              {{ $t("Admins.AdminOrders.dragUpload") }}
            </p>
            <p class="ant-upload-text">
              {{ $t("Admins.AdminOrders.or") }}
            </p>
            <a-button class="upload-modal__btn">
              {{ $t("Admins.AdminOrders.chooseFile") }}
            </a-button>
          </a-upload-dragger>
          <div v-if="fileList.length > 0" class="upload-modal__body">
            <a-row v-for="(item, index) in fileList" :key="index">
              <a-col :span="1">
                <a-icon type="paper-clip" />
              </a-col>
              <a-col :span="19">
                {{ item.name }}
              </a-col>
              <a-col :span="4">
                <a-icon type="delete" @click="handleRemove(item)" />
              </a-col>
            </a-row>
          </div>
          <div class="upload-modal__action">
            <a-button
              class="upload-modal__btn"
              :disabled="fileList.length === 0"
              :loading="uploading"
              @click="handleUpload"
            >
              {{ $t("Admins.AdminOrders.upload") }}
            </a-button>
          </div>
        </div>
      </a-modal>
      <a-modal
        v-model="uploadSuccess"
        width="400px"
        :footer="null"
        @cancel="handleCancelSuccess"
      >
        <a-result
          status="success"
          :title="$t('Admins.AdminOrders.uploadSuccess')"
        />
      </a-modal>
      <a-modal
        v-model="uploadFail"
        width="400px"
        :footer="null"
        @cancel="handleCancelFail"
      >
        <a-result status="warning" :title="$t('Admins.AdminOrders.uploadFail')">
          <template #extra>
            <a-button
              type="primary"
              class="upload-modal__body"
              @click="showErrorReport"
              >{{ $t("Admins.AdminOrders.viewReports") }}</a-button
            >
          </template>
        </a-result>
      </a-modal>
      <a-modal
        v-model="showReport"
        width="600px"
        :footer="null"
        @cancel="handleCancelReport"
      >
        <div class="upload-modal__body">
          <p class="ant-upload-text">
            <a-icon
              type="exclamation-circle"
              class="upload-modal__error-icon"
            />
            {{ $t("Admins.AdminOrders.errorTitle") }}
          </p>
          <p class="ant-upload-text" v-html="errors"></p>
        </div>
        <div style="display: flex">
          <a-button
            class="upload-modal__btn"
            :loading="isLoading"
            @click="downloadReports"
            >{{ $t("Admins.AdminOrders.orderDownload") }}</a-button
          >
        </div>
      </a-modal>
    </div>
    <div class="column">
      <div class="admin-order-top-button">
        <div class="top-left-button">
          <p class="order-upload-admin" @click="showUploadDialog">
            Order Upload
          </p>
          <p
            class="order-upload-admin shipping-upload-style"
            @click="showUploadDialog('ship')"
          >
            Order Shipping Upload
          </p>
          <p
            class="order-upload-admin search-order button-icon-style"
            @click="openSearchOrders"
          >
            <span>Search Orders</span>
            <a-icon class="search-icon-style" type="search" />
          </p>
          <p
            class="clear-search-admin button-icon-style"
            @click="clearSearchOrders"
          >
            <span class="clear-brand-order">Clear Search</span>
          </p>
        </div>
        <div class="fx-spacer"></div>
        <div class="top-right-button">
          <a-menu :item="csvItems" mode="inline"></a-menu>
          <div class="csv-style">
            <div class="button-icon-style" @click="isClickCSV = !isClickCSV">
              CSV<a-icon type="download" />
            </div>
            <p v-if="isClickCSV" class="csv-select">
              <span class="download-csv-select-one" @click="downloadOrderItems"
                >Download Selected</span
              >
              <span class="download-csv-select-two" @click="downloadOrder()"
                >Download All</span
              >
            </p>
          </div>
          <div v-if="tabId === 5" class="csv-style">
            <div
              class="button-icon-style"
              @click="changeStatus('deleted', true)"
            >
              Delete<a-icon type="delete" />
            </div>
          </div>
          <div v-if="tabId === 5" class="csv-style">
            <div
              :class="[canClickChangeStatus ? '' : 'can-not-click']"
              @click="clickOpenStatus"
            >
              Change Status
            </div>
            <p v-if="isChangeStatus" class="csv-select">
              <span
                v-for="item in statusText"
                :key="item.id"
                class="download-csv-select-one"
                :style="{
                  cursor: item.canClick ? 'pointer' : 'not-allowed',
                  color: item.canClick ? '#000000' : '#d9d9d9',
                }"
                @click="changeStatus(item.id, item.canClick)"
                >{{ item.name }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="ant-tabs ant-tabs-top ant-tabs-card">
        <div class="ant-tabs-nav">
          <div class="ant-tabs-nav-wrap">
            <div class="ant-tabs-nav-list">
              <span
                v-for="tab in tabText"
                :key="tab.id"
                :class="[tab.active ? 'ant-tabs-tab-active' : '']"
                class="ant-tabs-tab"
                @click="handleTabs(tab)"
              >
                {{ tab.name }}
              </span>
            </div>
            <div class="fx-spacer"></div>
            <div class="field is-grouped select-text-right">
              <div class="control">
                <div class="select">
                  <select v-model="value" @change="selectPagination(value)">
                    <option
                      v-for="visibleItemsCount in visibleItems"
                      :key="visibleItemsCount.id"
                      :value="visibleItemsCount.number"
                      >{{ visibleItemsCount.name }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OrderList
        :orderFilter="orderFilter"
        :showColumns="showColumns"
        @rowSelectionChanged="rowSelectionChanged"
        @onSortChanged="onSortChanged"
        ref="orderList"
      ></OrderList>
    </div>
    <ModalSearchOrder
      ref="searchOrder"
      :brand-or-retailer="setRetailersInfo"
      :admin-to-brand-info="setBrandsInfo"
      :statuses="orderStatuses"
      :user="3"
      @getOrderParams="getOrderParams"
    ></ModalSearchOrder>
    <TrackingInformationModal
      v-if="showTrackingInfo"
      :show="showTrackingInfo"
      :air-tracking-info="airShipments"
      :ocean-tracking-info="oceanShipments"
      :is-editing="isEditTrackingInfo"
      :tracking-status="trackingStatus"
      @hide="hideTrackingInfo"
    ></TrackingInformationModal>
  </div>
</template>

<script>
// import ProductsFilter from "@/components/filters/ProductsFilter"
import {
  exportOrderExcel,
  getOrderBrandPayAmount,
  getOrderRetailerPayAmount,
  formatLifecycleDate,
} from "@/utils/validate";
import { mapGetters } from "vuex";
import moment from "moment";
import ModalSearchOrder from "@/components/brands/orders/modalSearchOrder";
import { downloadCsvData } from "@/utils/validate";
import { newOrderStatus } from "@/utils/const";
import constants from "@/config.js";
import TrackingInformationModal from "@/components/orders/TrackingInformationModal";
import OrderList from "@/components/orders/OrderList";
import OrderFilter from "@/models/orders/OrderFilter";
const StatusOptions = ["Order Date", "Ship Date", "Cancel Date"];
const orderFilter = new OrderFilter();
const openStatuses = ["pending", "confirmed"];
const invoiceStatuses = [
  "shipped-supply",
  "warehouse",
  "shipped-delivery",
  "delivered",
  "invoiced",
];
const paymentStatuses = ["paid-by-retailer"];
const completedStatuses = ["paid-to-brand"];
const rangepicker = [];
const checkedList = [];
export default {
  components: {
    // ProductsFilter
    ModalSearchOrder,
    TrackingInformationModal,
    OrderList,
  },
  data() {
    return {
      csvItems: [
        { label: "Download Selected", key: 1 },
        { label: "Download All", key: 2 },
      ],

      showColumns: [],
      orderStatusMap: new Map(),
      orderFilter: orderFilter,
      statusText: newOrderStatus.slice(0, 5),
      orderStatus: constants.orderStatus,
      retailers: [],
      orderStatuses: [],
      statuses: [],
      brands: [],
      query: {},
      isClick: false,
      checked: false,
      isChangeStatus: false,
      orderdate: "",
      type: "",
      visible: false,
      showUpload: false,
      isShiping: false,
      uploadSuccess: false,
      uploadFail: false,
      showReport: false,
      errorReports: "",
      errors: "",
      isLoading: false,
      fileList: [],
      uploading: false,
      StatusOptions,
      checkedList,
      rangepicker,
      tabText: [
        {
          id: 1,
          name: "OPEN",
          active: true,
        },
        {
          id: 2,
          name: "INVOICE",
          active: false,
        },
        {
          id: 3,
          name: "PAYMENT",
          active: false,
        },
        {
          id: 4,
          name: "COMPLETE",
          active: false,
        },
        {
          id: 5,
          name: "ALL",
          active: false,
        },
        // {
        //   id: 6,
        //   name: "HISTORY",
        //   active: false
        // }
      ],
      visibleItems: [
        { id: 1, name: "View 50", number: 50 },
        { id: 2, name: "View 100", number: 100 },
      ],
      tabId: 1,
      value: 50,
      isClickCSV: false,
      currentNum: 50,
      adminOrders: [],
      checkList: [],
      canClickChangeStatus: true,
      firstStatus: "",
      trackingStatus: "",
      showTrackingInfo: false,
      isEditTrackingInfo: false,
      airShipments: [],
      oceanShipments: [],
      isSort: true,
      sortValue: {
        "_order-by": "PREVIOUS",
        "_sort-by": "created_at",
      },
      searchInfo: {},
      paginationValue: 1,
      isShowLoading: true,
      // about sort
      isReferenceIdSort: false,
      isRetailerName: false,
      isBrandName: false,
      isSubtotalSort: false,
      isTotalSort: false,
      isOrderDateSort: false,
      isPaymentSort: false,
      isPaymentDateSort: false,
      isShipByDateSort: false,
      isCancelDateSort: false,
      isActualShipDateSort: false,
      isPONumberSort: false,
      isTrackingNumberSort: false,
      isInvoiceSort: false,
      isStatusSort: false,
      searchBrandList: [],
      searchRetailerList: [],
      setRetailersInfo: [],
      setBrandsInfo: [],
      downloadMaxNumber: 2000,
      percentNumber: 0,
      isShowPercent: false,
    };
  },
  computed: {
    // adminOrders() {
    //   return this.$store.getters["order/orders"]
    // },
    activeRetailers() {
      return this.retailers.filter((item) => item.checked);
    },
    activeStatuses() {
      return this.statuses.filter((item) => item.checked);
    },
    activeBrands() {
      return this.brands.filter((item) => item.checked);
    },
    total() {
      //return this.$store.getters["order/pagination"].total;
      return this.orders.total;
    },

    ...mapGetters({
      orders: "orders/getOrders",
    }),
    supplyAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          (item) => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      );
    },
    supplyOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          (item) => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      );
    },
    deliveryAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          (item) => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      );
    },
    deliveryOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          (item) => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      );
    },
    // toOrderDetailLink() {
    //   return order.osvTag === 1 ? 'OldOrderDetails' : 'adminOrder'
    // }
  },
  async created() {
    this.orderStatusMap.set(1, openStatuses);
    this.orderStatusMap.set(2, invoiceStatuses);
    this.orderStatusMap.set(3, paymentStatuses);
    this.orderStatusMap.set(4, completedStatuses);
    this.orderStatusMap.set(5, []);

    this.tabId = Number(this.$route.query.tabId);
    this.updateShowColumns(this.tabId);
    this.orderFilter.statusIds = this.orderStatusMap.get(this.tabId);
    this.orderFilter.pageSize = this.currentNum;
    this.tabText = this.tabText.map((item) => {
      if (item.id === this.tabId) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });

    this.setRetailersInfo = await this.$store.dispatch(
      "retailer/fetchAllRetailers",
      {
        params: { fields: "name,id" },
      }
    );
    this.setBrandsInfo = await this.$store.dispatch("brand/fetchAllBrands", {
      params: { fields: "name,id" },
    });
    this.setRetailers();
    this.setBrands();
    this.setStatuses();
  },
  methods: {
    setOrderFilters(params) {
      const orderFilter = new OrderFilter();
      const retailerIds = params["retailer-cptys"];
      const brandIds = params["brand-cptys"];
      const status = params["status"];
      const dates = params["order-date"];
      const referenceId = params["reference-id"];
      const pageSize = params["_limit"];
      const pageIndex = params["_index"];
      params = params || {};
      if (status) {
        orderFilter.statusIds = status.split(",");
      } else {
        orderFilter.statusIds = this.orderStatusMap.get(this.tabId);
      }
      if (retailerIds) {
        orderFilter.retailerIds = retailerIds.split(",");
      }
      if (brandIds) {
        orderFilter.brandIds = brandIds.split(",");
      }
      if (dates) {
        const dateArray = dates.split(",");
        var startDate = null;
        var endDate = null;
        if (dateArray.length == 2) {
          startDate = dateArray[0];
          endDate = dateArray[1];
        } else if (dateArray.length == 1) {
          startDate = dateArray[0];
        }
        orderFilter.orderDateStart = startDate;
        orderFilter.orderDateEnd = endDate;
      }
      if (referenceId) {
        orderFilter.referenceId = referenceId;
      }
      if (pageSize) {
        orderFilter.pageSize = pageSize;
      }
      if (pageIndex) {
        orderFilter.pageIndex = pageIndex;
      }
      this.orderFilter = orderFilter;
    },
    rowSelectionChanged(selectedOrders) {
      this.checkList = selectedOrders;
    },
    onSortChanged(sorter) {
      console.log(sorter);
    },
    setRetailers() {
      const uniqueRetailersIds = [
        ...new Set(this.adminOrders.map((order) => order.retailer.id)),
      ];
      this.retailers = uniqueRetailersIds.map((retailerId) => {
        return {
          checked: false,
          id: retailerId,
          name: this.retailerNameById(retailerId),
        };
      });
    },
    async selectPagination(currentInfo) {
      this.currentNum = currentInfo;
      const status = this.checkStatusValue();
      const params = {
        _limit: this.currentNum,
        _index: 1,
        status,
        ...this.sortValue,
        ...this.searchInfo,
      };
      this.setOrderFilters(params);
    },

    async clearSearchOrders() {
      this.$refs["searchOrder"].clearSearch();
      const status = this.checkStatusValue();
      const params = {
        _limit: this.currentNum,
        _index: 1,
        status,
        ...this.sortValue,
      };
      this.setOrderFilters(params);
    },
    showModal() {
      this.visible = true;
      this.isClick = true;
    },
    orderChange(date, dateString) {
      this.orderdate = dateString;
      this.rangepicker = [moment(this.orderdate[0]), moment(this.orderdate[1])];
    },
    setBrands() {
      const uniqueBrandsIds = [
        ...new Set(this.adminOrders.map((order) => order.brand.id)),
      ];
      this.brands = uniqueBrandsIds.map((brandId) => {
        return {
          checked: false,
          id: brandId,
          name: this.brandNameById(brandId),
        };
      });
    },
    setStatuses() {
      const uniqueStatuses = [
        ...new Set(this.adminOrders.map((order) => order.status.id)),
      ];
      this.statuses = uniqueStatuses.map((statusId) => {
        return {
          checked: false,
          id: statusId,
          name: this.statusNameById(statusId),
        };
      });
    },
    retailerNameById(retailerId) {
      const retailer = this.$store.getters["retailer/retailerById"](retailerId);
      let name = retailer && retailer.name;
      return name ? name.toString().replace(/,/g, "，") : "";
    },
    brandNameById(brandId) {
      const brand = this.$store.getters["brand/brandById"](brandId);
      let name = brand && brand.name;
      return name ? name.toString().replace(/,/g, "，") : "";
    },
    statusNameById(statusId) {
      return this.adminOrders.find((order) => order.status.id === statusId)
        .status.name;
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return numberFormat.format(price);
    },
    checkOrderStatus(order) {
      let statusText;
      if (order.declined && order.status.id === this.orderStatus.pending) {
        statusText = "Canceled";
      } else if (
        order.status.id === this.orderStatus.supplyShipped ||
        order.status.id === this.orderStatus.warehouse ||
        order.status.id === this.orderStatus.deliverShipped
      ) {
        statusText = "Delivered";
      } else {
        statusText = order.status.name;
      }
      return statusText;
    },
    priceFormatForCsv(price) {
      let priceStr = this.priceFormat(price);
      return priceStr.replace(/,/g, "，");
    },
    async fetchOrders(info) {
      this.adminOrders = [];
      this.isShowLoading = true;
      const params = this.syncQueryWithFilters();
      const data = info ? info : params;
      if (this.tabId === 5) {
        data["osv-tag"] = "1,2";
      }
      try {
        await this.$store.dispatch("order/featOrderListWithPage", {
          params: data,
        });
        this.adminOrders = this.$store.getters["order/orders"];
        this.paginationValue = data["_index"];
        this.isShowLoading = false;
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...data,
            tabId: this.tabId,
          },
        });
      } catch (error) {
        this.adminOrders = [];
      }
    },
    handleCancle() {
      this.visible = false;
      this.checkedList = [];
      this.orderdate = "";
      this.isClick = false;
      this.rangepicker = [];
    },
    showUploadDialog(value) {
      this.showUpload = true;
      this.isShiping = value === "ship";
      this.fileList = [];
    },
    openSearchOrders() {
      this.$refs["searchOrder"].openDialog();
    },
    handleCancelUpload() {
      this.showUpload = false;
      this.isShiping = false;
      this.fileList = [];
    },
    handleCancelSuccess() {
      this.uploadSuccess = false;
    },
    handleCancelFail() {
      this.uploadFail = false;
    },
    handleCancelReport() {
      this.showReport = false;
    },
    showErrorReport() {
      this.handleCancelFail();
      this.showReport = true;
    },
    isCsv(name) {
      if (name) {
        let index = name.lastIndexOf(".");
        let ext = name.substr(index + 1);
        if (ext.toLowerCase() === "csv") {
          return true;
        }
      }
      return false;
    },
    handleUpload() {
      if (this.fileList && this.fileList.length > 0) {
        let isFormatRight = true;
        for (let i = 0; i < this.fileList.length; i++) {
          let name = this.fileList[i].name;
          isFormatRight = this.isCsv(name);
          if (!isFormatRight) {
            let info = this.$t("Admins.AdminOrders.uploadFailInfo");
            this.$message.error(info);
            return;
          }
        }
        this.doUpload(this.fileList[0]);
      }
    },
    doUpload(file) {
      let resUrl;
      if (this.isShiping) {
        resUrl = "order/uploadShipOrder";
      } else {
        resUrl = "order/uploadOrder";
      }
      let fileReader = new FileReader();
      const formData = new FormData();
      fileReader.readAsText(file);
      fileReader.onloadend = (e) => {
        let data = new Blob([e.target.result], { type: "text/csv" });
        formData.append("file", data, file.name);
        this.uploading = true;
        this.$store
          .dispatch(`${resUrl}`, formData)
          .then((res) => {
            if (res.states) {
              this.uploadSuccess = true;
              //this.$refs.orderList.fetch();
              this.refreshOrderList();
            } else if (res.reports) {
              this.errorReports = "";
              this.errors = "";
              res.reports.forEach((item) => {
                let index = item.indexOf("cause:");
                if (index > 0) {
                  this.errorReports += `${item.substring(0, index)}\n`;
                  this.errors += `${item.substring(0, index)}<br/>`;
                } else {
                  this.errorReports += `${item}\n`;
                  this.errors += `${item}<br/>`;
                }
              });
              this.showErrorReport();
            }
            this.handleCancelUpload();
          })
          .catch((error) => {
            this.errors = "";
            if (error.message) {
              this.errors = error.message;
              this.errorReports = error.downloadMessage;
              this.showErrorReport();
              this.handleCancelUpload();
            } else {
              let info = this.$t("Admins.AdminOrders.uploadFailInfo");
              this.$message.error(info);
            }
            console.log(error);
          })
          .finally(() => {
            this.uploading = false;
            this.isShiping = false;
          });
      };
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [];
      this.fileList.push(file);
      return false;
    },
    handleclear() {
      this.checkedList = [];
      this.rangepicker = [];
      this.orderdate = "";
    },
    handleChange(e) {
      switch (e.target.value) {
        case "Cancel Date":
          this.type = "cancel-date";
          break;
        case "Ship Date":
          this.type = "ship-date";
          break;
        case "Order Date":
          this.type = "order-date";
          break;
      }
    },
    refreshOrderList() {
      this.$refs.orderList.fetch();
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    moment,
    // Export order list to excel
    async exportOrderExcel(params) {
      this.visible = false;
      this.isShowPercent = true;
      this.percentNumber = 50;
      // const range =
      //   moment(this.orderdate[0]).format("YYYY-MM-DD") +
      //   "," +
      //   moment(this.orderdate[1]).format("YYYY-MM-DD")
      const blob = await this.$store.dispatch("order/downloadOrderExcel", {
        params: params,
      });
      this.percentNumber = 100;
      setTimeout(() => {
        let fileName = `brand_order_list_${new Date().getTime()}.xls`;
        let aLink = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //Create a link to download
        aLink.href = href;
        aLink.download = fileName; //fileName+time
        document.body.appendChild(aLink);
        aLink.click(); //click to download
        document.body.removeChild(aLink); //Remove the element after downloading
        window.URL.revokeObjectURL(blob); //Release the blob object
        this.isClick = false;
        this.isShowPercent = false;
      }, 1000);
      clearTimeout();
    },
    downloadReports() {
      let data = new Blob([this.errorReports]);
      let fileName = `upload_order_error_reports_${moment().format(
        "YYYY-MM-DD HH23:mm:ss"
      )}`;
      exportOrderExcel(data, fileName).finally(() => {
        this.isLoading = false;
        this.handleCancelReport();
      });
    },
    async handleTabs(tabInfo) {
      //this.$refs["searchOrder"].clearSearch();
      this.tabText = this.tabText.map((item) => {
        if (item.id === tabInfo.id) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });

      this.tabId = tabInfo.id;
      this.orderFilter.statusIds = this.orderStatusMap.get(this.tabId);
      this.searchInfo = null;
      this.updateShowColumns(this.tabId);
      this.updateRoute();
    },
    syncQueryWithFilters(value, isSort) {
      this.query["_limit"] = this.currentNum;
      // when tab is OPEN status
      this.query.status = "pending,confirmed";
      this.query["_order-by"] = "PREVIOUS";
      this.query["_sort-by"] = "created_at";
      if (value) {
        this.query["_order-by"] = isSort ? "NEXT" : "PREVIOUS";
        this.query["_sort-by"] = value;
      }
      return this.query;
    },
    updateRoute() {
      const params = this.syncQueryWithFilters();
      params.status = this.checkStatusValue();
      const data = params;
      let osvTagsFilter = null;
      if (this.tabId === 5) {
        data["osv-tag"] = "1,2";
        osvTagsFilter = [1, 2];
      }
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ...data,
          tabId: this.tabId,
        },
      });

      var orderFilter = { ...this.orderFilter };
      orderFilter.osvTags = osvTagsFilter;
      this.orderFilter = orderFilter;

      //this.$refs.orderList.fetch();
    },
    updateShowColumns(tabId) {
      switch (tabId) {
        case 1:
          this.showColumns = [
            "Reference ID",
            "Retailer",
            "Brand",
            "Subtotal",
            "Order Date",
            "Ship by Date",
            "Cancel Date",
            "PO Number",
            "Status",
          ];
          break;
        case 2:
          this.showColumns = [
            "Reference ID",
            "Retailer",
            "Brand",
            "Total",
            "Order Date",
            "Actual Ship Date",
            "PO Number",
            "Tracking Number",
          ];
          break;
        case 3:
          this.showColumns = [
            "Reference ID",
            "Retailer",
            "Brand",
            "Total",
            "Payment Date",
            "Actual Ship Date",
            "PO Number",
            "Invoice #",
          ];
          break;
        case 4:
          this.showColumns = [
            "Reference ID",
            "Retailer",
            "Brand",
            "Total",
            "Payment",
            "Payment Date",
            "Actual Ship Date",
            "PO Number",
            "Invoice #",
          ];
          break;
        case 5:
          this.showColumns = [
            "Reference ID",
            "Retailer",
            "Brand",
            "Subtotal",
            "Order Date",
            "PO Number",
            "Invoice #",
            "Status",
          ];
          break;
      }
      const status = this.checkStatusValue();
      if (status) {
        this.orderStatuses = status.split(",");
      } else {
        this.orderStatuses = null;
      }
    },

    async downloadOrder() {
      const state = this.checkStatusValue();
      let params = {
        _limit: this.total,
        status: state,
        ...this.sortValue,
        ...this.searchInfo,
      };
      if (this.tabId === 5) {
        params["osv-tag"] = "1,2";
      }
      await this.exportOrderExcel(params);
    },
    getLimitInfo(total) {
      let result = [];
      for (let i = 0; i < Math.ceil(total / this.downloadMaxNumber); i++) {
        const info = {
          _index: i + 1,
          _limit: this.downloadMaxNumber,
        };
        result.push(info);
      }
      return result;
    },
    async downloadStrForOrder(arr) {
      let list = arr && arr.length !== 0 ? arr : this.adminOrders;
      let result;
      if (this.total > this.downloadMaxNumber) {
        this.isShowPercent = true;
        const status = this.checkStatusValue();
        const arr = this.getLimitInfo(this.total);
        const number = Math.ceil(this.total / this.downloadMaxNumber);
        let price = 100 / number;
        this.percentNumber = 10;
        for (let i = 0; i < arr.length; i++) {
          const params = {
            ...arr[i],
            status,
            ...this.sortValue,
          };
          if (this.tabId === 5) {
            params["osv-tag"] = "1,2";
          }
          await this.$store
            .dispatch("order/downloadOrderList", { params })
            .then(async (res) => {
              result = this.downloadItemList(res);
              await downloadCsvData(
                result,
                `brand_order_list_${new Date().getTime()}_${i}.csv`
              );
            });
          price = (i + 1) * price;
          if (price >= 95) {
            price = 90;
          }
          this.percentNumber = price;
        }
        this.percentNumber = 100;
        setTimeout(() => {
          this.isShowPercent = false;
        }, 1000);
        return;
      }
      return this.downloadItemList(list);
    },
    downloadItemList(list) {
      if (list && list.length > 0) {
        if (this.tabId === 1) {
          return this.getOpenedOrderDownloadData(list);
        } else if (this.tabId === 2) {
          return this.getInvoicedOrderDownloadData(list);
        } else if (this.tabId === 3) {
          return this.getPaidOrderDownloadData(list);
        } else if (this.tabId === 4) {
          return this.getCompleteOrderDownloadData(list);
          // } else if (this.tabId === 6) {
          //   return this.getHistoryOrderDownloadData(list)
        } else {
          return this.getAllOrderDownloadData(list);
        }
      }
    },
    getOpenedOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Subtotal, Order Date , Ship by Date, Cancel Date, PO Number, Status",
      ];
      for (let item of list) {
        let str = `${
          item.landingReferenceId ? item.landingReferenceId : "-"
        }, ${this.retailerNameById(item.retailer.id)}, ${this.brandNameById(
          item.brand.id
        )}, ${this.priceFormatForCsv(item.calculatedWholesaleCostUsd)}, ${
          item.orderDate
        }, ${item.shipDate}, ${item.cancelDate}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.status.name}`;
        csvData.push(str);
      }
      return csvData;
    },
    getInvoicedOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Total, Order Date , Actural Ship Date, PO Number, Tracking Number",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedRetailCostUsd
        )}, ${item.orderDate ? item.orderDate : "-"}, ${
          item.shipDate ? item.shipDate : "-"
        }, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${this.formatTackingNumber(item)}`;
        csvData.push(str);
      }
      return csvData;
    },
    getPaidOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Total, Payment Date, Actural Ship Date , PO Number, Invoice #",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedRetailCostUsd
        )},${this.formatPaymentDate(item)}, ${
          item.shipDate ? item.shipDate : "-"
        },  ${item.retailerReferenceId ? item.retailerReferenceId : "-"}, ${
          item.invoiceId ? item.invoiceId : "-"
        }`;
        csvData.push(str);
      }
      console.log(csvData);
      return csvData;
    },
    getCompleteOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Total, Payment, Payment Date, Actural Ship Date, PO Number, Invoice #",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedRetailCostUsd
        )}, $${this.formatPaymentName(item)}, ${this.formatPaymentDate(
          item
        )}, ${item.shipDate ? item.shipDate : "-"},${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.invoiceId ? item.invoiceId : "-"}`;
        csvData.push(str);
      }
      return csvData;
    },
    getHistoryOrderDownloadData(list) {
      let csvData = [
        " Landing Ref ID, Retailer, Brand, Total, order Date, Ship Date, Cancel Date, Reference ID, status",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedRetailCostUsd
        )}, ${item.orderDate ? item.orderDate : "-"}, ${
          item.shipDate ? item.shipDate : "-"
        }, ${item.cancelDate}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.status && item.status.name ? item.status.name : "-"}`;
        csvData.push(str);
      }
      return csvData;
    },
    getAllOrderDownloadData(list) {
      let csvData = [
        "Reference ID, Retailer, Brand, Subtotal, Order Date , PO Number, Invoice #, Status",
      ];
      for (let item of list) {
        let str = `${item.landingReferenceId}, ${this.retailerNameById(
          item.retailer.id
        )}, ${this.brandNameById(item.brand.id)}, ${this.priceFormatForCsv(
          item.calculatedWholesaleCostUsd
        )}, ${item.orderDate ? item.orderDate : "-"}, ${
          item.retailerReferenceId ? item.retailerReferenceId : "-"
        }, ${item.invoiceId ? item.invoiceId : "-"}, ${
          item.status && item.status.name ? item.status.name : "-"
        }`;
        csvData.push(str);
      }
      return csvData;
    },
    formatDate(value) {
      if (value) {
        let date = Date.parse(value);
        return new Intl.DateTimeFormat("en-US").format(date);
      }
      return " ";
    },
    async downloadOrderItems() {
      const state = this.checkStatusValue();
      let list;
      if (this.isAllActive) {
        list = this.adminOrders;
      } else {
        list = this.checkList;
      }
      const listText = list.map((item) => item.id).join(",");
      let params = {
        _limit: this.total,
        status: state,
        ...this.sortValue,
        ...this.searchInfo,
        id: listText,
      };
      await this.exportOrderExcel(params);
    },
    checkStatusValue() {
      let status;
      // OPEN
      if (this.tabId === 1) {
        status = "pending,confirmed";
      }
      // INVOICE
      if (this.tabId === 2) {
        status = "shipped-supply,warehouse,shipped-delivery,delivered,invoiced";
      }
      // PAID
      if (this.tabId === 3) {
        status = "paid-by-retailer";
      }
      // PAID
      if (this.tabId === 4) {
        status = "paid-to-brand";
      }
      if (this.tabId === 5) {
        status = null;
      }
      return status;
    },
    // async handlePagination(page) {
    //   const state = this.checkStatusValue();
    //   this.paginationValue = page;
    //   let params = {
    //     _index: page,
    //     _limit: this.currentNum,
    //     status: state,
    //     ...this.sortValue,
    //     ...this.searchInfo,
    //   };
    //   await this.fetchOrders(params);
    // },
    async getOrderParams(obj, searchInfo) {
      // const params = obj;
      // this.searchInfo = searchInfo;
      // await this.fetchOrders(params);
      console.log(obj);
      console.log(searchInfo);
      this.setOrderFilters(searchInfo);
    },
    clickToOrderDetail(order) {
      this.$router.push({
        name: "adminOrder",
        params: { id: order.id },
      });
    },
    clickOpenStatus() {
      if (this.canClickChangeStatus) {
        this.isChangeStatus = !this.isChangeStatus;
      }
    },
    onChangeAll() {
      this.isAllActive = !this.isAllActive;
      this.adminOrders = this.adminOrders.map((item) => {
        item.checkedActive = this.isAllActive;
        return item;
      });
    },
    deleteOrder() {},
    async changeStatus(orderStatus, canClick) {
      if (!canClick) {
        return;
      }
      if (this.checkList && this.checkList.length === 0) {
        this.$notification.open({
          message: "Prompt information",
          description: "Please select the Order that you want to modify",
        });
        return;
      }
      if (orderStatus === "delivered") {
        this.showUploadDialog("ship");
        return;
      }
      let list;
      if (this.isAllActive) {
        list = this.adminOrders;
      } else {
        list = this.checkList;
      }
      if (orderStatus === this.orderStatus.deleted) {
        for (let i = 0; i < list.length; i++) {
          let order = {
            id: list[i].id,
            status: {
              id: orderStatus,
            },
            version: Number(list[i].version) + 1,
            isBrandAccepted: true,
            isRetailerAccepted: false,
          };
          let params = {};
          await this.$store.dispatch("order/updateStatus", { order, params });
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          let order = {
            id: list[i].id,
            status: {
              id: orderStatus,
            },
            version: Number(list[i].version) + 1,
            isBrandAccepted: true,
            isRetailerAccepted: false,
          };
          let params = {};
          await this.$store.dispatch("order/updateStatus", { order, params });
        }
      }
      //await this.fetchOrders();
      this.refreshOrderList();
    },
    // Choose the tracking number
    formatTackingNumber(item) {
      let num;
      if (item.airShipments) {
        num = item.airShipments[0].trackingId;
      }
      // else if (item.oceanShipments) {
      //   num = item.oceanShipments[0].trackingId
      // }
      else {
        num = "-";
      }
      return num;
    },
    formatPaymentDate(item) {
      const finalArr = item.lifecycle.slice(-1);
      if (finalArr[0].complete) {
        return formatLifecycleDate(finalArr[0].updatedAt);
      } else if (item.lifecycle && item.lifecycle.length >= 2) {
        const list = item.lifecycle.filter((item) => item.complete).slice(-1);
        return list[0].transitionedAt
          ? formatLifecycleDate(list[0].transitionedAt)
          : formatLifecycleDate(list[0].updateAt);
      } else {
        return "-";
      }
    },
    formatPaymentName(item) {
      const total =
        getOrderRetailerPayAmount(item) - getOrderBrandPayAmount(item);
      return total.toFixed(2);
    },
    formatActualShipDate(orderItem) {
      if (
        orderItem &&
        orderItem.airShipments &&
        orderItem.airShipments.length !== 0
      ) {
        return orderItem.airShipments[0].actualShipDate;
      } else {
        return "--";
      }
    },
    async showTrackingInfoModal() {
      if (this.role == "brand") {
        this.trackingStatus = this.orderStatus.supplyShipped;
      } else if (
        this.role == "admin" &&
        (this.supplyAirTrackingInfo || this.supplyOceanTrackingInfo)
      ) {
        this.trackingStatus = this.orderStatus.deliverShipped;
      } else if (
        this.role == "admin" &&
        (this.deliveryAirTrackingInfo || this.deliveryOceanTrackingInfo)
      ) {
        this.trackingStatus = this.orderStatus.deliverShipped;
      } else {
        this.trackingStatus = "";
      }
      this.airShipments = this.checkList[0].airShipments && [
        ...this.order.airShipments.map((item) => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            trackingId: item.trackingId,
          };
        }),
      ];
      this.oceanShipments = this.checkList[0].oceanShipments && [
        ...this.order.oceanShipments.map((item) => {
          return {
            orderStatus: { ...item.orderStatus },
            provider: { ...item.provider },
            ...item,
          };
        }),
      ];
      await this.$store.dispatch("order/getOrder", this.checkList[0].id);
      this.isEditTrackingInfo = false;
      this.showTrackingInfo = true;
    },
    hideTrackingInfo() {
      this.showTrackingInfo = false;
      this.isEditTrackingInfo = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.admin-orders {
  font-family: "Proxima";
  padding: 20px;

  .csv-style {
    min-width: 150px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ff6132;
    background-color: #fffaf9;
    color: #ff6132;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    .csv-select {
      position: absolute;
      top: 30px;
      left: -1px;
      z-index: 1000;
      border: 1px solid #d9d9d9;
      background-color: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }
    .download-csv-select-one {
      display: block;
      color: #000000;
      width: 150px;
      cursor: pointer;
    }
    .download-csv-select-one:hover {
      background-color: #fffaf9;
    }
    .download-csv-select-two {
      display: block;
      color: #000000;
      width: 150px;
      cursor: pointer;
    }

    .download-csv-select-two:hover {
      background-color: #fffaf9;
    }
  }
}
.admin-order-top-button {
  display: flex;
  flex-direction: row;
  .top-left-button,
  .top-right-button {
    display: flex;
    flex-direction: row;

    .button-icon-style {
      > span {
        padding-right: 3px;
      }
    }
  }

  .fx-spacer {
    flex: 1 1 auto;
  }
  .clear-search-admin {
    cursor: pointer;
    text-decoration: underline;
    color: #ff6132;
    padding: 3px 15px;
    font-size: 16px;
  }
  .order-upload-admin {
    cursor: pointer;
    background: #fffaf9;
    border: 1px solid #ff6132;
    color: #ff6132;
    padding: 3px 15px;
    margin-right: 10px;
    font-size: 16px;
  }

  .select-text-right {
    position: absolute;
    right: 10px;
  }

  p {
    margin-bottom: 0;
  }
}
.ant-tabs {
  margin-top: 30px;
}
.ant-tabs-top {
  > .ant-tabs-nav {
    margin: 0;
  }

  .select-text-right {
    position: absolute;
    right: 10px;
  }
}
.ant-tabs-card {
  > .ant-tabs-nav {
    .ant-tabs-tab {
      background: #fff;
      border: 1px none white;
    }
    .ant-tabs-tab-active {
      background: #fafafa;
      color: grey;
      border: 1px solid grey;
    }
  }
}
.upload-modal {
  &__body {
    margin-top: 16px;
  }

  &__action {
    margin-top: 16px;
    display: flex;
  }

  &__btn {
    font-family: "Proxima Nova";
    font-weight: normal;
    font-size: 14px;
    margin: 0 auto;
    padding: 4px 20px;
    color: white !important;
  }

  &__error-icon {
    color: red;
    margin-right: 10px;
  }

  &__action-btn {
    padding: 4px 20px;
    border-radius: 2px;
    background-color: #4a8a5d;
    font-size: 24px;
    color: white;
    cursor: pointer;
    margin-right: 20px;
  }
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #4a8a5d;
}
.ant-btn:last-child {
  background: #4a8a5d;
  border: 1px solid transparent;
}
</style>
