import store from "@/store"
import { message } from "ant-design-vue"

export default class AuthGuard {
  constructor(to, from) {
    this.to = to
    this.from = from
  }

  userRole() {
    return store.getters["user/role"]
  }

  allowedRoles() {
    return this.to.matched.map(node => node.meta.auth).filter(Boolean)
  }

  isPublicRoute() {
    return this.allowedRoles().length === 0
  }

  validate() {
    if (this.isPublicRoute()) {
      return true
    }

    if (this.userRole() === "admin") {
      return true
    }

    return this.allowedRoles().includes(this.userRole())
  }
  async routerLinkPermissions(to) {
    await store.dispatch("asset/getSwitchStatusInfo")
    const routerLinkINfo = store.getters["asset/getRouterPermissions"]
    const orderPermissionsBool = routerLinkINfo.filter(item => item.id === "order")[0].status
    const inventoryPermissionsBool = routerLinkINfo.filter(item => item.id === "inventory")[0]
      .status
    // const marketplacePermissionsBool = routerLinkINfo.filter(item => item.id === "marketplace")[0].status
    const CPCPermissionsBool = routerLinkINfo.filter(item => item.id === "clean-pre-check")[0]
      .status
    const shoppingCartPermissionsBool = routerLinkINfo.filter(
      item => item.id === "shopping-cart"
    )[0].status
    // const trainingPermissionsBool = routerLinkINfo.filter(item => item.id === "training")[0].status
    if (
      !orderPermissionsBool &&
      (to.name === "brandOrders" || to.name === "retailerOrders") &&
      this.userRole() !== "admin"
    ) {
      message.error(
        "Orders is currently being upgraded. Contact support@landingintl.com with any issues or concerns."
      )
      return false
    } else if (
      !inventoryPermissionsBool &&
      this.userRole() !== "admin" &&
      to.name === "brandInventory"
    ) {
      message.error(
        "Inventory is currently being upgraded.. Contact support@landingintl.com with any issues or concerns."
      )
      return false
      // } else if (marketplacePermissions[0].status && to.name === "brandInventory") {
    } else if (
      !CPCPermissionsBool &&
      this.userRole() !== "admin" &&
      (to.name === "brandCleanCheck" || to.name === "retailerCleanCheck")
    ) {
      message.error("Clean Pre Check module not available")
      return false
    } else if (
      !shoppingCartPermissionsBool &&
      this.userRole() !== "admin" &&
      to.name === "retailerShoppingCart"
    ) {
      message.error(
        "Shopping Cart is unavailable due to a system upgrade. Contact support@landingintl.com with any issues or concerns."
      )
      return false
    } else {
      return true
    }
  }
  // Verify that brand subscription is expired and modify operations after Brand sign out (expired status)
  async brandExpiredStatus(to, from, routerList) {
    // 获取router列表里面过期不能查看的路由名称
    const brandRouterList = routerList.filter(item => item.path === "/brand")
    const expiredCanNotClickList = brandRouterList[0].children.filter(
      value => value.meta && value.meta.expiredPermissions
    )
    const expiredCanNotClick = expiredCanNotClickList.map(item => item.name)
    const brandInfo = store.getters["brand/brand"]
    // 如果没有brand的信息，直接进行之前的router操作(next())
    if (!brandInfo) return { isNext: true }
    // 如果点击（不管有没有过期）sign out,返回进入login页面，进行当前(next())的操作
    if (to.name === "login") {
      return { isNext: true }
    }
    // 登录账号时没有过期，在没有退出账号的时候刷新页面过期了(next({path: "/trail-end"}))
    const checkBrandHasExpired = () => {
      return (
        brandInfo &&
        brandInfo.subscriptions &&
        brandInfo.subscriptions.length !== 0 &&
        brandInfo.subscriptions[0].status &&
        brandInfo.subscriptions[0].status.id === "expired"
      )
    }
    const canClickRouter = () => {
      return (
        expiredCanNotClick.indexOf(to.name) !== -1 || expiredCanNotClick.indexOf(from.name) !== -1
      )
    }
    if (canClickRouter() && checkBrandHasExpired()) {
      return { isNext: true, nextTo: "/trial-end" }
    }
    // 如果登录的时候获取到的信息brand已经过期，那么直接跳转到home 页面
    // if (checkBrandHasExpired() && from.name === "login") {
    //   return { isNext: true, nextTo: "/trial-end" }
    // }
    return { isNext: false }
  }
}
