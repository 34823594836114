var CreditCardValidationMode;
(function(CreditCardValidationMode) {
  CreditCardValidationMode[(CreditCardValidationMode["None"] = 0)] = "None";
  CreditCardValidationMode[(CreditCardValidationMode["TestMode"] = 1)] =
    "TestMode";
  CreditCardValidationMode[(CreditCardValidationMode["LiveMode"] = 2)] =
    "LiveMode";
})(CreditCardValidationMode || (CreditCardValidationMode = {}));

export class CreditCard {
  constructor(model) {
    this.cardNumber = "";
    this.cardCode = "";
    this.cardType = "";
    this.expirationDate = "";
    this.billToAddress = "";
    this.validateMode = null;
    this.isDefaultPaymentMethod = false;
    if (model) {
      this.cardNumber = model.cardNumber;
      this.cardCode = model.cardCode;
      this.cardType = model.cardType;
      this.expirationDate = model.expirationDate;
      this.validateMode = model.validateMode;
      this.isDefaultPaymentMethod = model.isDefaultPaymentMethod;
    }
  }
}
