<template>
  <div>
    <div class="platform-model">
      <div class="container" style="max-width:1140px;">
        <div class="platform-section">
          <h1 class="platform-title">{{ text_list.Platformtitle }}</h1>
          <h4 class="platform-subtitle">{{ text_list.Platformsubtitle }}</h4>
          <p class="platform-content">{{ text_list.Platformtextone }}<br /></p>
          <p class="platform-content">{{ text_list.Platformtexttwo }}<br /></p>
          <div class="platform-button">
            <router-link :to="{ name: 'team' }"
              ><a-button shape="round" size="large">{{ text_list.Team }}</a-button></router-link
            >
            <a href="/press"
              ><a-button shape="round" size="large">{{ text_list.Press }}</a-button></a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="platform-model">
      <div class="container" style="max-width:1140px;">
        <div class="platform-section">
          <h1 class="mission-title">{{ text_list.Missiontitle }}</h1>
          <h4 class="platform-subtitle">{{ text_list.Missionsubtitle }}</h4>
          <p class="platform-content">{{ text_list.Missiontext }}<br /></p>
          <div class="platform-button">
            <router-link :to="{ name: 'home' }"
              ><a-button shape="round" size="large">{{ text_list.Brand }}</a-button></router-link
            >
            <router-link :to="{ name: 'guestRetailer' }"
              ><a-button shape="round" size="large">{{ text_list.Retail }}</a-button></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <c-swiper :swiper-list="swiperList" :title="title"></c-swiper>
    <div class="product-list">
      <div class="container" style="max-width:1140px!important;">
        <h1 class="product-title">{{ text_list.Producttitle }}</h1>
        <div class="product-photo">
          <div class="photo-item">
            <img :src="this.imgList[0]" />
          </div>
          <div class="photo-item">
            <img :src="this.imgList[1]" />
          </div>
          <div class="photo-item">
            <img :src="this.imgList[2]" />
          </div>
          <div class="photo-item">
            <img :src="this.imgList[3]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperSlide from "@/components/common/SwiperSlide"
import wwdLogo from "@/assets/img/homepage/about/wwd.svg"
import allureLogo from "@/assets/img/homepage/about/Allure_logo.svg"
import bloombergLogo from "@/assets/img/homepage/about/bloomberg.svg"
import losAngelesTimesLogo from "@/assets/img/homepage/about/Los Angeles Times.svg"
import Photo1 from "@/assets/img/components/Rectangle 6.png"
import Photo2 from "@/assets/img/components/Rectangle 7.png"
import Photo3 from "@/assets/img/components/Rectangle 8.png"
import Photo4 from "@/assets/img/components/Rectangle 4.png"
export default {
  components: {
    "c-swiper": SwiperSlide
  },
  data() {
    return {
      title: this.$t("Auth.About.Brandtitle"),
      swiperList: [
        wwdLogo,
        allureLogo,
        bloombergLogo,
        losAngelesTimesLogo,
        wwdLogo,
        allureLogo,
        bloombergLogo,
        losAngelesTimesLogo
      ],
      imgList: [Photo1, Photo2, Photo3, Photo4]
    }
  },
  computed: {
    text_list() {
      const textlist = this.$t("Auth.About")
      return textlist
    }
  }
}
</script>

<style lang="sass" scoped>
@font-face
  font-family: "Playfair Display Bold"
  src: url("~@/assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype")


.platform-model
    margin-top: 120px

.platform-button
    display: flex
    margin-top: 50px
    justify-content: center
    .ant-btn
        border: 2px solid #000
        margin: 10px 60px
        width: 200px
        color: #000000
        font-size: 16px
        height: 43px
        font-family: 'ProximaNova-Regular', 'Proxima Nova'
        &:hover
          background-color: #12433D
          color: white


.platform-title
    font-family: "Playfair Display Bold"
    font-size: 48px
    color: #12433D
    text-align: center
    margin-bottom: 74px
.mission-title
    font-family: "Playfair Display Bold"
    font-size: 48px
    color: #12433D
    text-align: center
    margin-bottom: 35px
@media (max-width: 900px)
  .platform-title
    font-size: 40px
    margin-bottom: 55px
  .mission-title
    font-size: 40px
    margin-bottom: 35px
@media (max-width: 600px)
  .platform-title
    font-size: 32px
    margin-bottom: 20px
  .mission-title
    font-size: 32px
    margin-bottom: 24px

.platform-section
    display: flex
    flex-direction: column
    max-width: 950px
    margin: 0 auto
    .platform-subtitle
        font-family: 'Playfair Display Bold'
        color: #12433D
        font-size: 24px
        line-height: 52px
        margin-bottom: 8px
    .platform-content
        font-family:  "ProximaNova-Regular"
        font-size: 18px
        line-height: 21px
        margin-bottom: 16px
        color: #000000

.brand-slider
    margin-top: 227px
    margin-bottom: 159px
    text-align: center
    .slider-title
      font-family: Playfair Display
      font-style: normal
      font-weight: bold
      font-size: 48px
      line-height: 52px
      color: #12433D

.product-list
    background-color: #FEF2E2
    text-align: center
    padding-top: 70px
    padding-bottom: 80px
    .product-title
        font-family: Playfair Display
        font-style: normal
        font-weight: bold
        font-size: 48px
        line-height: 52px
        color: #12433D
        margin-bottom: 50px
    @media (max-width: 900px)
        .product-title
            font-size: 40px
    @media (max-width: 600px)
        .product-title
            font-size: 32px
            margin-bottom: 64px
    .product-photo
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        @media (min-width: 992px)
          .photo-item
              position: relative
              width: 100%
              padding: 10px
              flex: 0 0 25%
              max-width: 25%
        @media (max-width: 992px)
          .photo-item
              position: relative
              width: 100%
              padding: 10px
              flex: 0 0 33.3%
              max-width: 33.3%
        @media (max-width: 600px)
          .photo-item
              position: relative
              width: 100%
              padding: 10px
              flex: 0 0 50%
              max-width: 50%
</style>
