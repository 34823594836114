import referenceClient from "@/api/reference-client"
import categoriesService from "@/services/categories-service"

export default {
  namespaced: true,

  state: {
    newData: {},
    countries: [],
    categories: [],
    subcategories: [],
    microCategories: [],
    statuses: [],
    sellSideTags: [],
    buySideTags: [],
    barcodes: [],
    types: [],
    orderTypes: [],
    clientSizes: [],
    productSizeUnits: [],
    paymentTerms: [],
    socialAccounts: [],
    purchaseTypes: ["purchase", "testers"],
    shipmentType: [],
    shippingProvider: [],
    productBestUsedFor: [],
    productStep: [],
    orderChargeType: [],
    channelType: [],
    locationType: [],
    locationGroup: [],
    otherType: [],
    subscriptionTypes: [],
    curation: [],
    brandValue: [],
    inventoryAction: [],
    inventoryLocation: [],
    inventoryReason: [],
    inventoryType: [],
    orderItemNoteType: [],
    isModifySuccess: false,
    retailerConsole: {},
    brandConsole: {},
    productConsole: {},
    trainingConsole: {},
    productCategory: {},
    updateSubItem: undefined,
    updateMicroItem: undefined,
    clientStatus: [],
    clientType: [],
    messageCategory: [],
    messageType: [],
    stateName: [],
    loginMessageCategory: [],
    loginMessageRecipientCategory: [],
    loginMessageTime: [],
    retailerTypes: [],
    subscriptionTerms: []
  },

  getters: {
    subscriptionTerms(state) {
      return state.subscriptionTerms
    },
    updateSubSuccess(state) {
      return state.updateSubItem
    },
    updateMicroSuccess(state) {
      return state.updateMicroItem
    },
    isModifySuccess(state) {
      return state.isModifySuccess
    },
    clientStatus(state) {
      return state.clientStatus
    },
    retailerConsole(state) {
      return state.retailerConsole
    },
    brandConsole(state) {
      return state.brandConsole
    },
    productConsole(state) {
      return state.productConsole
    },
    trainingConsole(state) {
      return state.trainingConsole
    },
    productCategory(state) {
      return state.productCategory
    },
    countries(state) {
      return state.countries && state.countries.sort((a, b) => a.name.localeCompare(b.name))
    },
    stateName(state) {
      return state.stateName
    },
    channelType(state) {
      return state.channelType
    },
    locationType(state) {
      return state.locationType
    },
    locationGroup(state) {
      return state.locationGroup
    },
    otherType(state) {
      return state.otherType
    },
    subscriptionTypes(state) {
      return state.subscriptionTypes
    },
    countryFilterItems(state, getters) {
      // return getters.countries.map(country => {
      //   return { checked: false, ...country }
      // })
      return getters.countries
    },
    clientSizeFilterItems(state, getters) {
      return getters.clientSizes.map(clientSize => {
        return { checked: false, ...clientSize }
      })
    },
    retailerChannelTypeFilterItems(state, getters) {
      // return getters.channelType.map(channel => {
      //   return { checked: false, ...channel }
      // })
      return getters.channelType
    },
    retailerLocationTypeFilterItems(state, getters) {
      // return getters.locationType.map(type => {
      //   return { checked: false, ...type }
      // })
      return getters.locationType
    },
    retailerLocationGroupFilterItems(state, getters) {
      // return getters.locationGroup.map(count => {
      //   return { checked: false, ...count }
      // })
      return getters.locationGroup
    },
    retailerOtherTypeItems(state, getters) {
      // return getters.otherType.map(type => {
      //   return { checked: false, ...type }
      // })
      return getters.otherType
    },

    countryName: state => id => {
      const country = state.countries.find(country => country.id === id)
      return country && country.name
    },
    categoryName: state => id => {
      const category = state.categories.find(category => category.id === id)
      return category && category.name
    },
    clientSizeName: state => id => {
      const clientSize = state.clientSizes.find(clientSize => clientSize.id === id)
      return clientSize && clientSize.name
    },
    paymentTermName: state => id => {
      const paymentTerm = state.paymentTerms.find(paymentTerm => paymentTerm.id === id)
      return paymentTerm && paymentTerm.name
    },
    categories(state) {
      return state.categories && state.categories.sort((a, b) => a.name.localeCompare(b.name))
    },
    categoryFilterItems(state, getters) {
      return getters.categories.map(category => {
        return { checked: false, ...category }
      })
    },
    subcategories(state) {
      return (
        state.subcategories && state.subcategories.sort((a, b) => a.name.localeCompare(b.name))
      )
    },
    subcategoryFilterItems(state, getters) {
      return getters.subcategories.map(subcategory => {
        return { checked: false, ...subcategory }
      })
    },
    microCategories(state) {
      return (
        state.microCategories && state.microCategories.sort((a, b) => a.name.localeCompare(b.name))
      )
    },
    microCategoryFilterItems(state, getters) {
      return getters.microCategories.map(microCategory => {
        return { checked: false, ...microCategory }
      })
    },

    navigationalTags(state, getters) {
      return getters.sellSideTags.filter(tag => tag.navigational)
    },
    tagFilterItems(state, getters) {
      return getters.sellSideTagsWithoutNavigationalTags.map(tag => {
        return { checked: false, ...tag }
      })
    },
    tagHealthFilterItems(state, getters) {
      return getters.sellSideTagsWithNavigationalTags.map(tag => {
        return { checked: false, ...tag }
      })
    },
    statuses(state) {
      return state.statuses
    },
    productSizeUnits(state) {
      return state.productSizeUnits
    },
    statusFilterItems(state, getters) {
      return getters.statuses.map(status => {
        return { checked: false, ...status }
      })
    },
    sellSideTagsWithoutNavigationalTags(state, getters) {
      return getters.sellSideTags.filter(tag => !tag.navigational)
    },
    sellSideTagsWithNavigationalTags(state, getters) {
      return getters.sellSideTags.filter(tag => tag.navigational)
    },
    sellSideTags(state) {
      return (
        (state.sellSideTags && state.sellSideTags.sort((a, b) => a.name.localeCompare(b.name))) ||
        []
      )
    },
    curations(state) {
      return state.curation
    },
    barcodes(state) {
      return state.barcodes && state.barcodes.sort((a, b) => a.name.localeCompare(b.name))
    },

    orderTypes(state) {
      return state.orderTypes && state.orderTypes.sort((a, b) => a.name.localeCompare(b.name))
    },
    purchaseTypes(state, getters) {
      return getters.orderTypes.filter(orderType => getters.isPurchaseOrderType(orderType.id))
    },
    sampleTypes(state, getters) {
      const purchaseTypeIds = getters.purchaseTypes.map(purchaseType => purchaseType.id)
      return state.orderTypes.filter(orderType => !purchaseTypeIds.includes(orderType.id))
    },
    isPurchaseOrderType: state => orderTypeId => {
      return state.purchaseTypes.includes(orderTypeId)
    },

    types(state) {
      return state.types && state.types.sort((a, b) => a.name.localeCompare(b.name))
    },
    socialAccounts(state) {
      return (
        state.socialAccounts.length != 0 &&
        state.socialAccounts.sort((a, b) => a.name.localeCompare(b.name))
      )
    },
    typeFilterItems(state, getters) {
      return getters.types.map(type => {
        return { checked: false, ...type }
      })
    },
    brandTags(state, getters) {
      return getters.sellSideTags
        .filter(tag => tag.brandApplicable)
        .map(tag => {
          return { checked: false, ...tag }
        })
    },

    clientSizes(state) {
      return state.clientSizes
    },
    paymentTerms(state) {
      return state.paymentTerms
    },
    shipmentType(state) {
      return state.shipmentType.filter(item => item.id === "air" || item.id === "ocean")
    },
    shippingProvider(state) {
      return state.shippingProvider
    },
    orderItemNoteType(state) {
      return state.orderItemNoteType
    },
    orderChargeType(state) {
      return state.orderChargeType
    },
    brandValue(state) {
      return state.brandValue
    },
    inventoryAction(state) {
      return state.inventoryAction
    },
    inventoryLocation(state) {
      return state.inventoryLocation
    },
    inventoryReason(state) {
      return state.inventoryReason
    },
    inventoryType(state) {
      return state.inventoryType
    },
    findInventoryLocation: state => id => {
      if (id) {
        return state.inventoryLocation.find(location => location.id === id.toString())
      }
      return ""
    },
    findInventoryType: state => id => {
      if (id) {
        let data = state.inventoryType.find(type => {
          if (type.id === id.toString()) {
            return type
          }
        })
        return data
      }
      return ""
    },
    findInventoryAction: state => id => {
      if (id) {
        return state.inventoryAction.find(type => type.id === id.toString())
      }
      return ""
    },
    findInventoryReason: state => id => {
      if (id) {
        return state.inventoryReason.find(type => type.id === id.toString())
      }
      return ""
    },
    findInventoryReasonByName: state => name => {
      if (name) {
        return state.inventoryReason.find(type => {
          if (type.name.toLowerCase() === name.toLowerCase()) {
            return type
          }
        })
      }
      return ""
    },
    clientType(state) {
      return state.clientType
    },
    messageCategory(state) {
      return state.messageCategory
    },
    messageType(state) {
      return state.messageType
    },
    loginMessageCategory(state) {
      console.log(state.loginMessageCategory)
      return state.loginMessageCategory
    },
    loginMessageRecipientCategory(state) {
      console.log(state.loginMessageRecipientCategory)
      return state.loginMessageRecipientCategory
    },
    loginMessageTime(state) {
      return state.loginMessageTime
    },
    retailerTypes(state) {
      return state.retailerTypes
    }
  },

  mutations: {
    SET_UPDATE_SUBCATE(state, data) {
      state.updateSubItem = data
    },
    SET_UPDATE_MICROCATE(state, data) {
      state.updateMicroItem = data
    },
    SET_RETAILER_CONSOLE(state, data) {
      state.retailerConsole = data
    },
    SET_BRAND_CONSOLE(state, data) {
      state.brandConsole = data
    },
    SET_PRODUCT_CONSOLE(state, data) {
      state.productConsole = data
    },
    SET_PRODUCT_CATEGORY(state, data) {
      state.productCategory = data
    },
    SET_TRAINING_CONSOLE(state, data) {
      state.trainingConsole = data
    },
    SET_MODIFY_STATUS(state, isSuccess) {
      state.isModifySuccess = isSuccess
    },
    RESET_FILTERS(state) {
      state.countries.forEach(item => (item.checked = false))
      state.channelType.forEach(item => (item.checked = false))
      state.locationType.forEach(item => (item.checked = false))
      state.locationGroup.forEach(item => (item.checked = false))
      state.otherType.forEach(item => (item.checked = false))
    },
    SET_REFERENCE(state, payload) {
      state.newData = payload.newData;
      state.countries = payload.country.map(item => {
        let obj = item
        obj.checked = false
        return obj
      })
      state.stateName = payload.state
      state.categories = payload.product_category
      state.subcategories = payload.product_subcategory
      state.microCategories = payload.product_micro_category
      state.statuses = payload.product_status
      state.clientStatus = payload.client_status
      state.sellSideTags = payload["sell_side_tag"]
      state.buySideTags = payload.buy_side_tag
      state.barcodes = payload.product_barcode_type
      state.types = payload.product_type
      state.orderTypes = payload.order_quantity_type
      state.socialAccounts = payload.social_platform
      state.clientSizes = payload.client_size
      state.productSizeUnits = payload.product_size_units
      state.paymentTerms = payload.payment_term
      state.shipmentType = payload.shipment_type
      state.shippingProvider = payload.shipping_provider
      state.orderChargeType = payload.order_charge_type
      state.productBestUsedFor = payload.product_training_best_used_for
      state.productStep = payload.product_training_step
      state.orderItemNoteType = payload.order_item_note_type
      state.loginMessageCategory = payload.login_message_category
      state.loginMessageRecipientCategory = payload.login_message_recipient_category
      state.loginMessageTime = payload.login_message_time
      state.retailerTypes = payload.grade_type
      state.subscriptionTerms = payload.subscription_terms
      state.channelType = payload.channel_type.map(item => {
        let obj = item
        obj.checked = false
        return obj
      })
      state.locationType = payload.location_type.map(item => {
        let obj = item
        obj.checked = false
        return obj
      })
      state.locationGroup = payload.location_group.map(item => {
        let obj = item
        obj.checked = false
        return obj
      })
      state.otherType = payload.other_type.map(item => {
        let obj = item
        obj.checked = false
        return obj
      })
      state.subscriptionTypes = payload.subscription_type
      state.curation = payload.curation
      state.brandValue = payload.sell_side_value
      state.inventoryAction = payload.inventory_action.map(item => {
        item.description =
          item.description.charAt(0).toUpperCase() + item.description.toLowerCase().slice(1)
        return item
      })
      state.inventoryLocation = payload.inventory_location
      state.inventoryReason = payload.inventory_reason.map(item => {
        let value = item.description.toLowerCase().split(" ")
        item.description = ""
        for (let i = 0; i < value.length; i++) {
          item.description += value[i].charAt(0).toUpperCase() + value[i].slice(1)
          if (i < value.length - 1) {
            item.description += " "
          }
        }
        return item
      })
      state.inventoryType = payload.inventory_type.map(item => {
        item.description =
          item.description.charAt(0).toUpperCase() + item.description.toLowerCase().slice(1)
        return item
      })
      state.clientType = payload.client_type
      state.messageCategory = payload.message_category
      state.messageType = payload.message_type
    }
  },

  actions: {
    async setReference({ commit }) {
      try {
        //const reference = await referenceClient.getReference()
       /* update existing product category and add privateId */
        const response = await Promise.all([referenceClient.getReference(), categoriesService.getCategories()]);
        const reference = response[0].data;
        const categories = response[1];
        const categoryLookup = new Map();
        categories.forEach(c => categoryLookup.set(c.id, c));
        reference.newData = {
          productCategories: categories,
          productCategoryLookup: categoryLookup
        }

        commit("SET_REFERENCE", reference)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateLocationType({ commit }, param) {
      commit("SET_MODIFY_STATUS", false)
      try {
        const { data } = await referenceClient.updateLocationType(param)
        console.log(data)
        commit("SET_REFERENCE", data)
        commit("SET_MODIFY_STATUS", true)
        return data
      } catch (error) {
        commit("SET_MODIFY_STATUS", false)
        return Promise.reject(error)
      }
    },
    async updateCurationType({ commit }, param) {
      commit("SET_MODIFY_STATUS", false)
      try {
        const { data } = await referenceClient.updateCurationType(param)
        commit("SET_REFERENCE", data)
        commit("SET_MODIFY_STATUS", true)
        return data
      } catch (error) {
        commit("SET_MODIFY_STATUS", false)
        return Promise.reject(error)
      }
    },
    async updateOtherType({ commit }, param) {
      commit("SET_MODIFY_STATUS", false)
      try {
        const { data } = await referenceClient.updateOtherType(param)
        commit("SET_REFERENCE", data)
        commit("SET_MODIFY_STATUS", true)
        return data
      } catch (error) {
        commit("SET_MODIFY_STATUS", false)
        return Promise.reject(error)
      }
    },
    async updateChannelType({ commit }, param) {
      commit("SET_MODIFY_STATUS", false)
      try {
        const { data } = await referenceClient.updateChannelType(param)
        commit("SET_REFERENCE", data)
        commit("SET_MODIFY_STATUS", true)
        return data
      } catch (error) {
        commit("SET_MODIFY_STATUS", false)
        return Promise.reject(error)
      }
    },
    async updateProductCategory({ commit }, param) {
      commit("SET_MODIFY_STATUS", false)
      try {
        const { data } = await referenceClient.updateProductCategory(param)
        commit("SET_MODIFY_STATUS", true)
        return data
      } catch (error) {
        commit("SET_MODIFY_STATUS", false)
        return Promise.reject(error)
      }
    },
    async updateProductSubCategory({ commit }, param) {
      commit("SET_UPDATE_SUBCATE", {
        privateId: param.productSubcategorys[0].productCategoryPrivateId,
        isSuccess: false
      })
      try {
        const { data } = await referenceClient.updateProductSubCategory(param)
        commit("SET_REFERENCE", data)
        let subItem = {
          privateId: param.productSubcategorys[0].productCategoryPrivateId,
          isSuccess: true
        }
        commit("SET_UPDATE_SUBCATE", subItem)
        return data
      } catch (error) {
        commit("SET_UPDATE_SUBCATE", {
          privateId: param.productSubcategorys[0].productCategoryPrivateId,
          isSuccess: false
        })
        return Promise.reject(error)
      }
    },
    async updateProductMicroCategory({ commit }, param) {
      commit("SET_UPDATE_MICROCATE", {
        privateId: param.productMicroCategorys[0].productSubcategoryPrivateId,
        isSuccess: false
      })
      try {
        const { data } = await referenceClient.updateProductMicroCategory(param)
        commit("SET_REFERENCE", data)
        commit("SET_UPDATE_MICROCATE", {
          privateId: param.productMicroCategorys[0].productSubcategoryPrivateId,
          isSuccess: true
        })
        return data
      } catch (error) {
        commit("SET_UPDATE_MICROCATE", {
          privateId: param.productMicroCategorys[0].productSubcategoryPrivateId,
          isSuccess: false
        })
        return Promise.reject(error)
      }
    },
    async updateProductType({ commit }, param) {
      commit("SET_MODIFY_STATUS", false)
      try {
        const { data } = await referenceClient.updateProductType(param)
        commit("SET_REFERENCE", data)
        commit("SET_MODIFY_STATUS", true)
        return data
      } catch (error) {
        commit("SET_MODIFY_STATUS", false)
        return Promise.reject(error)
      }
    },
    async updateProductValue({ commit }, param) {
      commit("SET_MODIFY_STATUS", false)
      try {
        const { data } = await referenceClient.updateProductValue(param)
        commit("SET_REFERENCE", data)
        commit("SET_MODIFY_STATUS", true)
        return data
      } catch (error) {
        commit("SET_MODIFY_STATUS", false)
        return Promise.reject(error)
      }
    },
    async updateMessageCategory({ commit }, param) {
      console.log(param)
      try {
        const { data } = await referenceClient.updateMessageCategory(param)
        commit("SET_REFERENCE", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getRetailerConsole({ commit }, param) {
      try {
        const { data } = await referenceClient.getRetailerConsole(param)
        commit("SET_RETAILER_CONSOLE", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getBrandConsole({ commit }, param) {
      try {
        const { data } = await referenceClient.getBrandConsole(param)
        commit("SET_BRAND_CONSOLE", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getProductConsole({ commit }, param) {
      try {
        const { data } = await referenceClient.getProductConsole(param)
        commit("SET_PRODUCT_CONSOLE", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getProductCategory({ commit }) {
      try {
        const data = await referenceClient.getProductCategory()
        commit("SET_PRODUCT_CATEGORY", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getTrainingConsole({ commit }) {
      try {
        const data = await referenceClient.getTrainingConsole()
        commit("SET_TRAINING_CONSOLE", data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getSubCategory({ commit }, id) {
      try {
        const { data } = await referenceClient.getSubcategory(id)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getMicroCategory({ commit }, id) {
      try {
        const { data } = await referenceClient.getMicroCategory(id)
        console.log(commit)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
