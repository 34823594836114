<template>
  <div class="retailer-training-brand-product-tab__main">
    <!--    <div class="retailer-training-brand-product-title">-->
    <!--      {{ brandName }}-->
    <!--    </div>-->
    <div class="retailer-training-brand-title__main">
      <p class="view-list-or-grid">
        View <span @click="viewList">List</span> | <span @click="viewGrid">Grid</span>
      </p>
      <p class="view-all-product" @click="ViewAllProduct"><span>View All</span></p>
    </div>
    <div v-if="$route.query.tab === 'grid'" class="retailer-training-content">
      <div v-for="item in productList" :key="item.name" class="products-card">
        <div
          ref="dialogProductModal"
          class="training-product-card__main"
          :class="item.isActive ? 'training-product-card-click__main' : ''"
          @click="clickProduct(item)"
        >
          <div class="prod-img">
            <img
              v-if="item.images && item.images.length !== 0 && item.images[0].asset"
              :src="item.images[0].asset.url"
              alt=""
            />
          </div>
          <div class="prod-content">
            <div class="content-title">{{ item.name }}</div>
            <div class="content-sku">SKU: {{ item.sku }}</div>
            <div class="content-sku">UPC: {{ item.barcodes[0].code || "--" }}</div>
            <div class="content-price">MSRP: ${{ item.msrpUsd }}</div>
            <div class="content-price">Category: {{ item.category.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$route.query.tab === 'list'" class="retailer-training-content">
      <table class="retailer-brand-product-table-style">
        <thead>
          <tr class="table-tr-header">
            <th style="width:273px;">
              Name
              <a-icon
                style="cursor: pointer"
                :type="isNameSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('name')"
              ></a-icon>
            </th>
            <th style="width:123px;">
              Hero
              <a-icon
                style="cursor: pointer"
                :type="isHeroSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('hero')"
              ></a-icon>
            </th>
            <th style="width:115px;">
              SKU
              <a-icon
                style="cursor: pointer"
                :type="isSkuSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('sku')"
              ></a-icon>
            </th>
            <th style="width:115px;">
              UPC
              <a-icon
                style="cursor: pointer"
                :type="isUpcSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('upc')"
              ></a-icon>
            </th>
            <th style="width:109px;">
              Modified
              <a-icon
                style="cursor: pointer"
                :type="isModifiedSort ? 'caret-down' : 'caret-up'"
                class="icon-sort-style"
                @click="sortList('modified')"
              ></a-icon>
            </th>
            <th style="width:136px;">Actions</th>
          </tr>
        </thead>
        <div v-if="productList && productList.length === 0" class="no-table-data">
          <a-empty />
        </div>
        <tbody v-else>
          <tr v-for="item in productList" :key="item.name">
            <td style="width:273px;" class="name-tab-style">
              {{ item.name }}
            </td>
            <td style="width:123px;" class="">
              <span v-if="item.training.hero" class="product-hero-style">Hero</span>
            </td>
            <td style="width:115px;" class="data-tab-style">
              {{ item.sku || "--" }}
            </td>
            <td style="width:115px;" class="data-tab-style">
              {{ item.barcodes[0].code || "--" }}
            </td>
            <td style="width:115px;" class="data-tab-style">
              {{ item.updatedAt.slice(0, 10) || "--" }}
            </td>
            <td style="width:136px" class="click-pro-action">
              <div class="editable-row-operations">
                <span @click="handleProductList(item)">
                  View
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--    <productDetailDialog ref="productDetailDialog"></productDetailDialog>-->
    <productViewDialogNew ref="productViewDialogNew"></productViewDialogNew>
  </div>
</template>

<script>
import { mapActions } from "vuex"
// import productDetailDialog from "@/components/training/__components/openProductDetailDialog"
import productViewDialogNew from "@/components/training/__components/productViewDialogNew"

export default {
  name: "RetailerProductTab",
  components: {
    // productDetailDialog,
    productViewDialogNew
  },
  data() {
    return {
      productList: [],
      brandName: "",
      brand: {},
      isNameSort: false,
      isHeroSort: false,
      isSkuSort: false,
      isUpcSort: false,
      isModifiedSort: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
  },
  async created() {
    if (this.$route.query.id) {
      this.brand = await this.getBrandInfo(this.$route.query.id)
      this.brandName = this.brand.name
      const list = await this.getBrandProductList(this.$route.query.id)
      const arr = list.map(item => {
        item.isActive = false
        return item
      })
      this.productList = arr.filter(item => item.training && item.training.states === 2)
      // this.brandName = data.name
    }
  },
  methods: {
    ...mapActions({
      getBrandProductList: "training/getBrandProduct",
      getBrandInfo: "brand/getBrand"
    }),
    clickProduct(item) {
      this.productList = this.productList.map(value => {
        if (item.name === value.name) {
          value.isActive = true
        } else {
          value.isActive = false
        }
        return value
      })
      const list = [item]
      this.$refs["productViewDialogNew"].openProductDialog(list, this.brandName, this.brand)
    },
    viewList() {
      if (this.$route.params.retailerId) {
        this.$router.push({
          name: "AdminRetailerTrainingProduct",
          query: {
            id: this.$route.query.id,
            name: this.$route.query.name,
            tab: "list"
          }
        })
      } else {
        this.$router.push({
          name: "retailerTrainingProduct",
          query: {
            id: this.$route.query.id,
            name: this.$route.query.name,
            tab: "list"
          }
        })
      }
    },
    viewGrid() {
      if (this.$route.params.retailerId) {
        this.$router.push({
          name: "AdminRetailerTrainingProduct",
          query: {
            id: this.$route.query.id,
            name: this.$route.query.name,
            tab: "grid"
          }
        })
      } else {
        this.$router.push({
          name: "retailerTrainingProduct",
          query: {
            id: this.$route.query.id,
            name: this.$route.query.name,
            tab: "grid"
          }
        })
      }
    },
    handleProductList(product) {
      const list = [product]
      this.$refs["productViewDialogNew"].openProductDialog(list, this.brandName, this.brand)
    },
    ViewAllProduct() {
      this.$router.push({
        name: this.isAdmin ? "adminTrainingProductViewAll" : "TrainingProductViewAll",
        query: {
          id: this.brand.id,
          name: this.brandName
        }
      })
    },
    async sortList(sortValue) {
      const info = this.formatSortValue(sortValue)
      this.handleName(info["_sort-by"], info.isAscend)
      // const sortInfo = this.formatSortValue(sortValue)
      // let params = {
      //   _index: 1,
      //   _limit: this.currentNum,
      //   ...sortInfo
      // }
      // await this.fetchData(params)
    },
    formatSortValue(value) {
      // this.initSort()
      if (value === "name") {
        this.isNameSort = !this.isNameSort
        this.isHeroSort = false
        this.isSkuSort = false
        this.isUpcSort = false
        this.isModifiedSort = false
        return {
          "_order-by": this.isNameSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isNameSort
        }
      }
      if (value === "hero") {
        this.isHeroSort = !this.isHeroSort
        this.isNameSort = false
        this.isSkuSort = false
        this.isUpcSort = false
        this.isModifiedSort = false
        return {
          "_order-by": this.isHeroSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isHeroSort
        }
      }
      if (value === "sku") {
        this.isSkuSort = !this.isSkuSort
        this.isNameSort = false
        this.isHeroSort = false
        this.isUpcSort = false
        this.isModifiedSort = false
        return {
          "_order-by": this.isSkuSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isSkuSort
        }
      }
      if (value === "upc") {
        this.isUpcSort = !this.isUpcSort
        this.isNameSort = false
        this.isHeroSort = false
        this.isSkuSort = false
        this.isModifiedSort = false
        return {
          "_order-by": this.isUpcSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isUpcSort
        }
      }
      if (value === "modified") {
        this.isModifiedSort = !this.isModifiedSort
        this.isNameSort = false
        this.isHeroSort = false
        this.isSkuSort = false
        this.isUpcSort = false
        return {
          "_order-by": this.isModifiedSort ? "PREVIOUS" : "NEXT",
          "_sort-by": value,
          isAscend: this.isModifiedSort
        }
      }
    },
    handleName(sorter, isAscend) {
      let index
      if (sorter === "name") {
        index = 1
      } else if (sorter === "hero") {
        index = 3
      } else if (sorter === "upc") {
        index = 2
      } else if (sorter === "sku") {
        index = 5
      } else if (sorter === "modified") {
        index = 6
      }
      // console.log("开始====>", this.productList)
      this.sortData(index, isAscend)
    },
    sortData(index, ascend) {
      // console.log(23333333, index, ascend)
      if (index == 1) {
        this.productList.sort((a, b) => {
          if (ascend) {
            return a.name.localeCompare(b.name)
          } else {
            return b.name.localeCompare(a.name)
          }
        })
      } else if (index == 6) {
        this.productList.sort((a, b) => {
          if (ascend) {
            if (a.updatedAt != null && b.updatedAt != null) {
              return a.updatedAt.localeCompare(b.updatedAt)
            }
            if (a.updatedAt != null && b.updatedAt == null) {
              return a.updatedAt.localeCompare(b.createdAt)
            }
            if (a.updatedAt == null && b.updatedAt != null) {
              return a.createdAt.localeCompare(b.updatedAt)
            }
          } else {
            if (b.updatedAt != null && a.updatedAt != null) {
              return b.updatedAt.localeCompare(a.updatedAt)
            }
            if (b.updatedAt != null && a.updatedAt == null) {
              return b.updatedAt.localeCompare(a.createdAt)
            }
            if (b.updatedAt == null && a.updatedAt != null) {
              return b.createdAt.localeCompare(a.updatedAt)
            }
          }
        })
      } else if (index == 3) {
        this.productList.sort((a, b) => {
          const heroA = a.training && a.training.hero ? a.training.hero : false
          const heroB = b.training && b.training.hero ? b.training.hero : false
          if (ascend) {
            if (heroA && !heroB) {
              return -1
            }
            if (!heroA && heroB) {
              return 1
            } else {
              return 0
            }
          } else {
            if (heroA && !heroB) {
              return 1
            }
            if (!heroA && heroB) {
              return -1
            } else {
              return 0
            }
          }
        })
      } else if (index == 2) {
        this.productList.sort((a, b) => {
          if (ascend) {
            return a.barcodes[0].code.localeCompare(b.barcodes[0].code)
          } else {
            return b.barcodes[0].code.localeCompare(a.barcodes[0].code)
          }
        })
      } else if (index === 5) {
        this.productList.sort((a, b) => {
          if (ascend) {
            return a.sku.localeCompare(b.sku)
          } else {
            return b.sku.localeCompare(a.sku)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.retailer-training-brand-product-tab__main {
  margin-top: 24px;
  display: flex;
  //justify-content: start;
  justify-content: flex-start;
  flex-wrap: wrap;
  p {
    margin-bottom: 0;
  }
  .retailer-training-brand-title__main {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    .view-list-or-grid {
      color: #e1644c;
      text-decoration: underline;
      span {
        cursor: pointer;
      }
    }
    .view-all-product {
      width: 67px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 7px;
      color: #ffffff;
      background: #e19387;
      cursor: pointer;
    }
  }
  .retailer-training-brand-product-title {
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #000000;
    font-size: 24px;
  }
  .retailer-training-content {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
  }
  .products-card {
    width: 220px;
    margin-right: 24px;
    margin-bottom: 24px;
  }
  .products-card:nth-child(4n) {
    margin-right: 0;
  }
  .training-product-card__main {
    width: 220px;
    height: 364px;
    border: 2px solid #ffffff;
    cursor: pointer;
    .prod-img {
      max-width: 220px;
      height: 264px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        //max-width: 90%;
        //max-height: 90%;
      }
    }
    .prod-content {
      text-align: center;
      font-size: 13px;
      color: #000000;
      font-family: "Proxima Nova";
      .content-title {
        //width: 80%;
        margin: 0 auto;
        text-align: left;
        height: 22px;
        font-weight: bold;
        margin-top: 10px;
        color: #000000;
        word-wrap: break-word;
        word-break: normal;
        //display: block;
        //white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        //line-height: 44px;
      }
      .content-sku {
        text-align: left;
      }
      .content-price {
        text-align: left;
      }
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .name-tab-style {
    position: relative;
    span {
      position: absolute;
      right: 16px;
      top: 15px;
    }
  }
  .retailer-brand-product-table-style {
    overflow: visible;
    width: 100%;
    color: #262626;
    //border: #e8e8e8 solid 1px;
    border-collapse: collapse;
    margin-top: 20px;
  }
  .retailer-brand-product-table-style tr {
    width: 100%;
    position: relative;
  }
  .retailer-brand-product-table-style th {
    border-top: #e8e8e8 solid 1px;
    border-bottom: #e8e8e8 solid 1px;
    padding: 16px;
    //background-color: #fafafa;
    font-size: 14px;
    font-weight: normal;
  }
  .retailer-brand-product-table-style td {
    height: 56px;
    border-bottom: #e8e8e8 solid 1px;
    padding: 17px 16px;
    background-color: #ffffff;
    color: #595959;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .retailer-brand-product-table-style tr,
  .retailer-brand-product-table-style td.move:hover {
    //cursor: move;
  }
  .retailer-brand-product-table-style td.click-pro-action:hover {
    cursor: pointer;
  }
  .product-hero-style {
    display: inline-block;
    width: 39px;
    height: 22px;
    line-height: 22px;
    background: #f3ca50;
    color: #ffffff;
    border-radius: 100px;
    text-align: center;
  }
  .editable-row-operations {
    color: #4a8a5d;
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
}
</style>
