import StoreUtil from "@/store/store-util"
export default {
  getCategories: (state) => state.categories || [],
  getTrainingStepsByCategory: (state) => (categoryId) => {
    return state.categoryTrainingSteps[categoryId]
  },
  getTrainingBestUsedForsByCategory: (state) => (categoryId) => {
    return state.categoryTrainingBestUsedFors[categoryId]
  },
  isLoading: (state) => (properties) => {
    return StoreUtil.getStateProperty(state.status.isLoading, properties)
  },
}
