<template>
  <div class="columns retailer-cart__product-border cart-product-group is-marginless">
    <div class="column retailer-cart__column-with-image">
      <router-link
        class="retailer-cart__image"
        :to="{ name: 'retailerProduct', params: { id: cartProduct.product.id } }"
      >
        <img :src="mainImageUrl(cartProduct.product)" />
      </router-link>
      <a class="cart-product-group__link" @click.prevent="saveProductForLater(cartProduct)">
        {{ $t("Components.Retailers.Cart.CartProductGroup.saveforlater") }}
      </a>
    </div>
    <div class="column retailer-cart__price is-paddingless">
      <router-link :to="{ name: 'retailerProduct', params: { id: cartProduct.product.id } }">
        <p class="retailer-cart__title retailer-cart__title">
          {{ ellipsize(cartProduct.product.name) }}
        </p>
      </router-link>
      <p class="retailer-cart__subtitle">
        {{ parseFloat(cartProduct.product.size) }} {{ cartProduct.product.sizeUnits.id }} ·
        {{ cartProduct.product.sku }}
      </p>
      <p class="retailer-cart__subtitle_black">
        {{ priceFormat(cartProduct.product.wholesalePriceUsd) }}
      </p>
      <p class="retailer-cart__subtitle-crossed-out">
        {{ priceFormat(cartProduct.product.msrpUsd) }}
      </p>
      <p class="retailer-cart__subtitle-brown">{{ cartProduct.product.margin }}%</p>
    </div>
    <div class="column is-6 is-paddingless retailer-cart__column-with-table">
      <div
        v-for="(orderDetail, index) in cartProduct.orderDetails"
        :key="index"
        class="columns is-multiline is-paddingless"
      >
        <div
          class="column is-12 retailer-cart__table-row"
          :class="{ 'retailer-cart__table-border': isPurchase(orderDetail) }"
        >
          <div class="columns">
            <div class="column is-4 has-text-centered retailer-cart__type">
              {{ orderTypeName(orderDetail.orderType.id) }}
            </div>
            <div class="column is-3 has-text-centered">
              <div class="field">
                <div class="control retailer-cart__input_right">
                  <input
                    ref="quantityInput"
                    v-model="orderDetail.quantity"
                    class="input is-small retailer-cart__input"
                    type="number"
                    min="0"
                    step="1"
                    @blur="changeQuantity(orderDetail, index)"
                  />
                  <div
                    class="retailer-cart__input-plus"
                    @click="increaseQuantity(orderDetail, index)"
                  >
                    +
                  </div>
                  <div
                    class="retailer-cart__input-minus"
                    @click="decreaseQuantity(orderDetail, index)"
                  >
                    -
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="isPurchase(orderDetail)"
              class="column is-3 retailer-cart__type-price has-text-centered"
            >
              {{ priceFormat(cartProduct.product.wholesalePriceUsd * orderDetail.quantity) }}
            </div>
            <div v-else class="column is-3 retailer-cart__type-price has-text-centered">
              {{ "$0.00" }}
            </div>
            <div class="column has-text-left">
              <img
                v-if="orderDetail.orderType.id != 'testers'"
                class="retailer-cart__close-button"
                src="@/assets/img/icon/close-icon.png"
                @click="removeProductFromCart(cartProduct.id)"
              />
            </div>
          </div>
          <div
            v-if="
              (isPurchase(orderDetail) && orderDetail.quantity === '0') ||
                orderDetail.quantity === ''
            "
            class="retailer-cart__alert"
          >
            {{ $t("Components.Retailers.Cart.CartProductGroup.alertMessageForQuantityZero") }}
          </div>
          <div
            v-if="
              isLogistic &&
                orderDetail.orderType.id === 'purchase' &&
                Number(orderDetail.quantity) > Number(stock)
            "
            class="retailer-cart__alert"
          >
            <a-icon type="info-circle" theme="filled" />
            {{
              $t("Components.Retailers.Cart.CartProductGroup.alertMessageForQuantity", {
                inventory: Number(stock)
              })
            }}
          </div>

          <div
            v-if="
              isLogistic &&
                orderDetail.orderType.id === 'testers' &&
                Number(orderDetail.quantity) > Number(testStock)
            "
            class="retailer-cart__alert"
          >
            <a-icon type="info-circle" theme="filled" />
            {{
              $t("Components.Retailers.Cart.CartProductGroup.alertMessageForQuantity", {
                inventory: Number(testStock)
              })
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { cartProduct: { type: Object, default: () => {} } },
  data() {
    return {
      stock: 0,
      testStock: 0,
      isLogistic: false
    }
  },
  computed: {
    orderTypes() {
      return this.$store.getters["reference/orderTypes"]
    },
    cartProducts() {
      let data = this.$store.getters["retailer/cartProducts"]
      return data
    },
    brandLogistic() {
      let data = this.$store.getters["retailer/brandLogistics"]
      return data
    }
  },
  watch: {
    brandLogistic(newValue) {
      if (newValue && newValue.length > 0) {
        newValue.forEach(item => {
          if (item.brandId === this.cartProduct.product.brand.id) {
            this.isLogistic = item.isLogistic
          }
        })
      }
    }
  },

  created() {
    this.getProductStock()
  },
  methods: {
    mainImageUrl(product) {
      if (!product.images) {
        return ""
      }

      const mainImage = product.images.find(image => image.main)
      return mainImage && mainImage.asset.url
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      return numberFormat.format(price)
    },
    orderTypeName(orderTypeId) {
      const orderType = this.orderTypes.find(orderType => orderType.id === orderTypeId)
      return orderType && orderType.name
    },
    removeProductFromCart(productId) {
      this.$emit("removeProductFromCart", productId)
    },
    saveProductForLater(cartProduct) {
      this.$emit("saveProductForLater", cartProduct)
    },
    syncCartWithAPI(orderDetail, key, value) {
      orderDetail.quantity = value
      let canUpdateCartNum = false
      if (this.isLogistic) {
        if (orderDetail.orderType.id === "purchase") {
          this.testStock = this.stock - value
          if (this.testStock < 0) {
            this.testStock = 0
          }
          if (Number(value) <= this.stock) {
            canUpdateCartNum = true
            this.$emit("removeOverStockProduct", this.cartProduct.product.id, true)
          } else {
            this.$emit("addOverStockProduct", this.cartProduct.product.id, true)
          }
        } else if (orderDetail.orderType.id === "testers") {
          if (Number(value) <= this.testStock) {
            canUpdateCartNum = true
            this.$emit("removeOverStockProduct", this.cartProduct.product.id, false)
          } else {
            this.$emit("addOverStockProduct", this.cartProduct.product.id, false)
          }
        }
      } else {
        canUpdateCartNum = true
      }
      if (canUpdateCartNum) {
        this.$emit("syncCartWithAPI", orderDetail, key, value)
      }
    },
    isPurchase(orderDetail) {
      return orderDetail.orderType.id === "purchase"
    },
    increaseQuantity(orderDetail, index) {
      this.$refs.quantityInput[index].stepUp()
      if (orderDetail.orderType.id === "testers") {
        orderDetail.orderType.id = "testers"
        this.syncCartWithAPI(orderDetail, "quantity", this.$refs.quantityInput[index].value)
      } else {
        orderDetail.orderType.id = "purchase"
        this.syncCartWithAPI(orderDetail, "quantity", this.$refs.quantityInput[index].value)
      }
    },
    decreaseQuantity(orderDetail, index) {
      this.$refs.quantityInput[index].stepDown()
      if (orderDetail.orderType.id === "testers") {
        orderDetail.orderType.id = "testers"
        this.syncCartWithAPI(orderDetail, "quantity", this.$refs.quantityInput[index].value)
      } else {
        orderDetail.orderType.id = "purchase"
        this.syncCartWithAPI(orderDetail, "quantity", this.$refs.quantityInput[index].value)
      }
    },
    changeQuantity(orderDetail, index) {
      this.syncCartWithAPI(orderDetail, "quantity", this.$refs.quantityInput[index].value)
    },
    ellipsize(value) {
      if (value.length > 30) {
        return value.substr(0, 26) + "..."
      } else {
        return value
      }
    },
    getProductStock() {
      if (this.cartProduct && this.cartProducts) {
        this.cartProducts.forEach(item => {
          if (this.cartProduct.id === item.product.id) {
            if (item.quantityType.id === "purchase") {
              this.stock = item.stock
            }
          }
        })
      }
      if (this.cartProduct && this.cartProduct.orderDetails) {
        this.cartProduct.orderDetails.forEach(item => {
          if (item.orderType.id === "purchase") {
            this.testStock = this.stock
            this.stock = Number(this.stock) + Number(item.quantity)
          }
        })
      }
      this.isLogistic = this.cartProduct.logistic
      //console.log(`productName: ${this.cartProduct.product.name}, stock: ${this.stock}, isLogistic: ${this.isLogistic}`)
    }
  }
}
</script>

<style lang="sass">
input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield
</style>
