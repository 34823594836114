<template>
  <div class="column">
    <div
      :class="
        isRetailer
          ? 'common-overview__card-shadowed'
          : 'common-overview__outlined'
      "
    >
      <div class="common-overview__title">
        {{ $t("Retailers.RetailerProfile.overview") }}
      </div>
      <div v-if="isRetailer" class="common-overview__border" />
      <div
        :class="
          isRetailer
            ? 'common-overview__retailer-about'
            : 'common-overview__brand-about'
        "
      >
        {{ retailer.about }}
        <a-row class="common-overview__content">
          <a-col :span="7">
            <span>
              {{ $t("Retailers.RetailerProfile.headQuarters") }}
            </span>
            <img
              class="common-overview__img_margin"
              src="@/assets/img/profile/headquarters.png"
            />
            <div class="common-overview__detail">
              {{ headquarters.streetAddressLine_1 }}
            </div>
            <div
              v-if="headquarters.streetAddressLine_2"
              class="common-overview__detail"
            >
              {{ headquarters.streetAddressLine_2 }}
            </div>
            <div v-if="headquarters.city" class="common-overview__detail">
              {{ headquarters.city }}, {{ headquarters.region }}
              {{ headquarters.postalCode }}
            </div>
            <div class="common-overview__detail">
              {{ countryName(headquarters.country.id) }}
            </div>
          </a-col>
          <a-col :span="5">
            <a-row>
              <span>
                {{ $t("Retailers.RetailerProfile.founded") }}
              </span>
              <img
                class="common-overview__img_margin"
                src="@/assets/img/profile/clock-circle.svg"
              />
              <div v-if="retailer.foundingDate" class="common-overview__detail">
                {{ retailer.foundingDate.split("-")[0] }}
              </div>
              <div v-else class="common-overview__detail">
                N/A
              </div>
              <div
                v-if="retailer.locationCounts"
                class="common-overview__col_content"
              >
                <span>
                  {{ $t("Retailers.RetailerProfile.locations") }}
                </span>
                <img
                  class="common-overview__img_margin"
                  src="@/assets/img/profile/locations.png"
                />
                <div>
                  <span
                    v-if="retailer.locationCounts"
                    class="common-overview__detail"
                  >
                    {{ getLocationCounts() }}
                  </span>
                  <span v-else class="common-overview__detail">
                    N/A
                  </span>
                </div>
              </div>
            </a-row>
            <a-row
              v-if="retailer.introDeckAsset || videoIntroAssets.length > 0"
            >
              <span>
                {{ $t("Retailers.RetailerBrandProfile.introDeck") }}
                <div
                  v-if="retailer.introDeckAsset"
                  class="common-overview__detail_margin"
                >
                  <a-tooltip
                    v-if="isShowTooltip"
                    placement="topLeft"
                    :title="pdfName"
                  >
                    <div class="asset-link">
                      <a :href="retailer.introDeckAsset.url" target="_blank">
                        {{ `${pdfName}` }}
                      </a>
                      <a-icon type="file-pdf" style="margin-left: 6px;" />
                    </div>
                  </a-tooltip>
                  <div v-else>
                    <div class="asset-link">
                      <a :href="retailer.introDeckAsset.url" target="_blank">{{
                        pdfName
                      }}</a>
                      <a-icon type="file-pdf" style="margin-left: 6px;" />
                    </div>
                  </div>
                </div>

                <div class="assets">
                  <a-tooltip
                    v-for="(asset, index) in videoIntroAssets"
                    :key="index"
                    placement="topLeft"
                    :title="asset.name"
                  >
                    <div class="asset-link">
                      <a :href="asset.url" target="_blank">
                        {{ `${asset.name}` }}
                      </a>
                      <a-icon type="file-pdf" style="margin-left: 6px;" />
                    </div>
                  </a-tooltip>
                </div>
              </span>
            </a-row>
          </a-col>
          <a-col :span="5">
            <span>
              {{ $t("Retailers.RetailerProfile.website") }}
            </span>
            <img
              class="common-overview__img_margin"
              src="@/assets/img/profile/web_site.png"
            />
            <a
              v-if="isRetailer"
              class="common-overview__link"
              @click="goToWebsite"
            >
              {{ retailer.website }}
            </a>
            <a
              v-else
              class="common-overview__link common-overview__gray"
              @click="goToWebsite"
            >
              {{ retailer.website }}
            </a>
            <div v-if="isRetailer" class="common-overview__col_content">
              <span>
                {{ $t("Retailers.RetailerProfile.locationType") }}
              </span>
              <img
                class="common-overview__img_margin"
                src="@/assets/img/profile/location_type.png"
              />
              <div class="common-overview__detail_margin">
                <span
                  v-if="retailer && retailer.locationType"
                  class="common-overview__border_detail"
                >
                  {{ retailer.locationType.name }}
                </span>
                <span v-else class="common-overview__detail">
                  N/A
                </span>
              </div>
            </div>
          </a-col>
          <a-col :span="7" class="common-overview__padding-left">
            <span>
              {{ $t("Retailers.RetailerProfile.companySize") }}
            </span>
            <img
              class="common-overview__img_margin"
              src="@/assets/img/profile/company_size.png"
            />
            <div v-if="retailer.size.id" class="common-overview__detail">
              {{ retailer.size.id }}
            </div>
            <div v-else class="common-overview__detail">
              N/A
            </div>
            <div v-if="isRetailer" class="common-overview__col_content">
              <span>
                {{ $t("Retailers.RetailerProfile.otherType") }}
              </span>
              <img
                class="common-overview__img_margin"
                src="@/assets/img/profile/other_type.png"
              />
              <div class="common-overview__detail_margin">
                <div v-if="retailer.otherTypes">
                  <p
                    v-for="(item, index) in retailer.otherTypes"
                    :key="index"
                    class="common-overview__border_detail"
                  >
                    {{ item.name }}
                  </p>
                </div>
                <span v-else class="common-overview__detail">
                  N/A
                </span>
              </div>
            </div>
          </a-col>
          <!-- <a-col :span="7"></a-col> -->
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    retailer: { type: Object, default: () => {} },
    headquarters: { type: Object, default: () => {} },
    isRetailer: { type: Boolean, default: () => true },
    videoIntroAssets: { type: Array, default: () => [] },
  },
  data() {
    return {
      isShowTooltip: false,
    };
  },
  computed: {
    pdfName() {
      if (
        this.retailer &&
        this.retailer.introDeckAsset &&
        this.retailer.introDeckAsset.id
      ) {
        let data = this.$store.getters["asset/find"](
          this.retailer.introDeckAsset.id
        );
        if (data) {
          return data.originalFileName;
        }
      }
      return "Intro deck.pdf";
    },
  },
  created() {
    console.log(this.videoIntroAssets);
  },
  watch: {
    pdfName() {
      if (this.pdfName && this.pdfName.toString().length > 12) {
        this.isShowTooltip = true;
      } else {
        this.isShowTooltip = false;
      }
    },
  },
  methods: {
    countryName(id) {
      return this.$store.getters["reference/countryName"](id);
    },
    goToWebsite() {
      window.open("http://www." + this.retailer.website);
    },
    getLocationCounts() {
      if (
        this.retailer &&
        this.retailer.locationCounts &&
        this.retailer.locationCounts.length > 0
      ) {
        let count = 0;
        for (let i = 0; i < this.retailer.locationCounts.length; i++) {
          count = count + Number(this.retailer.locationCounts[i].count);
        }
        return count;
      }
      return "N/A";
    },
  },
};
</script>

<style lang="scss">
.asset-link {
  display: flex;
  a {
    text-overflow: ellipsis;
    flex: 1;
    overflow: hidden;
    text-wrap: nowrap;
    width: 100px;
  }
}
</style>
<style lang="sass">
.common-overview
  &__card-shadowed
      -webkit-box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
      box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
      border-radius: 2px
      padding: 24px 20px 16px 17px
  &__outlined
      border: 1px solid rgba(151, 151, 151, 0.234149)
      border-radius: 3px
      padding: 23px 13px 16px 24px
  &__title
      font-size: 24px !important
      font-weight: normal !important
      line-height: 24px !important
      color: #000000 !important
      margin-bottom: 8px !important
  &__border
      margin: 4px 16px 13px 0px !important
      max-width: 342px !important
      border-bottom: 1px solid #E8E8E8
  &__brand-about
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 20px
      color: #000000
      margin-top: 16px
      margin-bottom: 10px
  &__retailer-about
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 20px
      color: #595959
  &__content
    vertical-align: top
    position: relative
    padding: 24px 0px 16px 0px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    display: flex
    color: #262626
  &__padding-left
      padding-left: 20px !important
  &__col_content
      margin-top: 16px !important
  &__detail_margin
    margin-top: 6px !important
  &__detail
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 20px
      display: flex
      align-items: center
      color: #8C8C8C
  &__border_detail
      background: #F5F5F5
      padding: 1px 6px
      border: 1px solid #D9D9D9
      box-sizing: border-box
      border-radius: 2px
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 22px
      color: #595959
      text-align: center
      display: inline-block
      margin-bottom: 4px !important
  &__link
     max-width: 123px
     overflow: hidden
     text-overflow: ellipsis
     font-family: Proxima Nova
     font-style: normal
     font-weight: normal
     font-size: 14px
     line-height: 20px
     display: block
     white-space: nowrap
     align-items: center
     color: #1D4BA1
  &__gray
      color: #8C8C8C !important
  &__img_margin
    margin-left: 4px
.col
    padding: 12px 8px !important
.v-sheet.v-card
    border-radius: 2px !important
.brand-profile__pdf-text
  word-wrap: break-word
  word-break: break-all
  overflow: hidden
  width: 90%
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 1
  -webkit-box-orient: vertical
</style>
