<template>
  <div class="brands-layout">
    <div class="filter-list-layout">
      <div class="filter">
        <SearchItems :items="searchItems" @doSearch="doSearch" />
      </div>
      <div class="list">list</div>
    </div>
  </div>
</template>
<script>
import SearchItems from "@/components/admin/SearchItems";
export default {
  components: {
    SearchItems,
  },
  data() {
    return {
      searchItems: [],
    };
  },
  created() {
    this.getReferenceData();
  },
  methods: {
    getReferenceData() {
      this.$store.dispatch("reference/setReference").then(() => {
        this.subscriptions = this.$store.getters["reference/subscriptionTypes"];
        this.curations = this.$store.getters["reference/curations"];
        this.brandValues = this.$store.getters["reference/brandValue"];
        this.subscriptions = this.subscriptions.filter(
          (data) => data.id !== "classic"
        );
        if (this.curations && this.curations.length > 0) {
          this.curations.forEach((data) => {
            if (data.description) {
              let name = data.name;
              data.name = data.description;
              data.description = name;
            }
          });
        }
        this.initData(this.subscriptions);
        this.initData(this.curations);
        this.initData(this.brandValues);
        this.initSearchItems();
      });
    },
    initData(data) {
      if (data && data.length > 0) {
        data.forEach((item) => {
          item.isChecked = false;
          item.hasChild = false;
        });
      }
    },
    initSearchItems() {
      this.searchItems = [];
      this.searchItems.push({
        name: "Brand Name",
        paramName: "brandId",
        canSearch: true,
        isUseId: true,
        child: this.retailerName,
      });
      this.searchItems.push({
        name: "Short Name",
        paramName: "brandId",
        canSearch: false,
        isUseId: true,
        child: this.retailerShortName,
      });
      this.searchItems.push({
        name: "Subscription",
        paramName: "subscription",
        canSearch: false,
        child: this.subscriptions,
      });
      this.searchItems.push({
        name: "Curations",
        paramName: "curation",
        canSearch: false,
        child: this.curations,
      });
      this.searchItems.push({
        name: "Brand Values",
        paramName: "brandValue",
        canSearch: false,
        child: this.brandValues,
      });
      // this.searchItems.push({
      //   name: "Brand Commission",
      //   paramName: "location",
      //   canSearch: false,
      //   child: this.locations
      // })
      this.searchItems.push({
        name: "Status",
        paramName: "status",
        canSearch: false,
        child: this.status,
      });
      console.log(this.searchItems);
    },
    doSearch(param) {
      console.log(param);
    },
  },
};
</script>
<style lang="scss" scoped>
.brands-layout {
  display: flex;
  flex-direction: column;
  .filter-list-layout {
    display: flex;
    flex-direction: row;
    .filter {
      flex-basis: 20rem;
      flex-grow: 1;
    }

    .list {
      flex-basis: 0;
      flex-grow: 999;
    }
  }
}
</style>
