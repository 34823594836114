<template lang="pug">
.brand-retailers-main-box
  row.brand-new-retailers
    a-col.title.column.is-flex-mobile.is-block-desktop(:span='24')
      | {{ $t( "Components.Brands.mainPage.retailersTitle") }}
    a-col.retailers-box.column.is-block-mobile.is-flex-desktop(:span='24')
      .box-one(v-for='(item, index) in imgList' :key='index')
        img(:src='item.img' alt='img' @click="clickToRetailer(item.code)")

</template>

<script>
import { Row, Col } from "ant-design-vue"
import Anthropologie from "@/assets/img/homepage/brands/Anthropologie_logo.png"
import Dreamstore from "@/assets/img/homepage/brands/dreamstore_logo.png"
import Revolve from "@/assets/img/homepage/brands/revolve_logo.png"
import RetailerTwo from "@/assets/img/homepage/brands/retailer-two.png"
import Urban from "@/assets/img/homepage/brands/Urban_Outfitters_logo.png"
export default {
  components: {
    Row,
    "a-col": Col
  },
  data() {
    return {}
  },
  computed: {
    imgList() {
      return [
        { img: Urban, code: "za0FqVeYS-e6XdL-KVlOXQ" },
        { img: RetailerTwo, code: "_zfT4mViR2anKIDI1u0Ptg" },
        { img: Anthropologie, code: "1D-DwD2DR7-EUPQl3IrmlQ" },
        { img: Dreamstore, code: "qUygAnLpQx6MbbvCH-tbjA" },
        { img: Revolve, code: "mg7jTtGmSNSTRc-xY6wQBw" }
      ]
    }
  },
  methods: {
    clickToRetailer(code) {
      this.$router.push({
        name: "brandRetailerProfile",
        params: {
          retailerId: code
        }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.brand-retailers-main-box
  width: 1082px
  margin: 0 auto
.brand-new-retailers
  width: 100%
  height: 188px
  //padding: 0 10%
  text-align: center
  .title
    font-size: 18px
    height: 40px
    line-height: 40px
    font-weight: 700
    color: #12433D
    font-family: DM Sans
  .retailers-box
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 55px
    .box-one:nth-child(2)
      width: 146px
      height: 38px
    .box-one
      img:hover
        cursor: pointer
</style>
