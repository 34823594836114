<template lang="pug">
.old-retailer-order
  .old-retailer-order-navigation
    span(v-if="order.retailerReferenceId") {{ `${$t('Retailers.RetailerOrder.referenceId')} #${order.retailerReferenceId}` }}
    span(v-if="role=='admin'") {{ `${$t('Retailers.RetailerOrder.batchId')} #${order.batchId}` }}
  .old-retailer-order-id {{$t('Retailers.RetailerOrder.orderId')}}: {{ order.landingReferenceId }}

  OrderStatus(:order="order" @statusUpdated="statusTransition" @showTrackingInfo="showTrackingInfoModal")

  .old-retailer-order-status-banner.old-retailer-order-status-banner__block(v-if="(order.status)")
    .columns.old-retailer-order-status-banner__left-block
      .column.is-narrow.old-retailer-order-status-banner__icon
        img(src="@/assets/img/orders/goal-icon.svg")
      div(v-if="isRetailer && order.retailerAccepted && order.status.id == orderStatus.pending")
        .old-retailer-order-status-banner__title {{$t('Retailers.RetailerOrder.thankYou')}}
        .old-retailer-order-status-banner__details {{$t('Retailers.RetailerOrder.thankYouText')}} {{ brand.name }}. {{ brand.name }} {{$t('Retailers.RetailerOrder.thankYouTextSecondPart')}}
      div(v-if="isBrand && !order.brandAccepted && order.status.id == orderStatus.pending")
        .old-retailer-order-status-banner__title Congratulations! You received a purchase order from {{ retailer.name }}
        .old-retailer-order-status-banner__details If you want to change anything, feel free to request an amendment below as a comment. Once your purchase order update is complete click the "Accept" button in order to request changes to retailer.
      div(v-if="isRetailer && !order.retailerAccepted && order.status.id == orderStatus.pending")
        .old-retailer-order-status-banner__title You received an amended purchase order from {{ brand.name }}
        .old-retailer-order-status-banner__details If you want to change anything, feel free to request an amendment below as a comment. Once your purchase order update is complete click the "Accept" button in order to request changes to brand.
      div(v-if="order.status.id == orderStatus.confirmed && isBrand")
        .old-retailer-order-status-banner__title Please mark as shipped when you ship the terms to supply to the warehouse.
        .old-retailer-order-status-banner__details Click on the mark as shipped when you ship
      div(v-if="order.status.id == orderStatus.canceled && isBrand && !order.brandAccepted")
        .old-retailer-order-status-banner__title Oh no!
        .old-retailer-order-status-banner__details You have declined this order.
      div(v-if="order.status.id == orderStatus.canceled && isBrand && order.brandAccepted")
        .old-retailer-order-status-banner__title Oh no!
        .old-retailer-order-status-banner__details {{retailer.name}} declined this order.
      div(v-if="order.status.id == orderStatus.canceled && isRetailer && order.retailerAccepted")
        .old-retailer-order-status-banner__title Oh no!
        .old-retailer-order-status-banner__details {{brand.name}} declined this order.
      div(v-if="order.status.id == orderStatus.canceled && isRetailer && !order.retailerAccepted")
        .old-retailer-order-status-banner__title Oh no!
        .old-retailer-order-status-banner__details You have declined this order.


    .old-retailer-order-status-banner__button-block(v-if = "order.status")
      button.button.is-white.retailer-settings__saved-addresses-button(@click="statusTransition(orderStatus.supplyShipped)" v-if="isTransition(orderStatus.supplyShipped)") {{$t('Retailers.RetailerOrder.markAsShipped')}}
      button.button.is-white.retailer-settings__saved-addresses-button(v-if="order.status.id==orderStatus.supplyShipped && role == 'brand'" disabled) Marked as shipped
      button.button.is-white.retailer-settings__saved-addresses-button(@click="statusTransition(orderStatus.warehouse)" v-if="isTransition(orderStatus.warehouse)") {{$t('Retailers.RetailerOrder.warehouse')}}
      button.button.is-white.retailer-settings__saved-addresses-button(@click="statusTransition(orderStatus.deliverShipped)" v-if="isTransition(orderStatus.deliverShipped)") {{$t('Retailers.RetailerOrder.deliveryShipped')}}
      button.button.is-white.retailer-settings__saved-addresses-button(@click="statusTransition(orderStatus.delivered)" v-if="isTransition(orderStatus.delivered)") {{$t('Retailers.RetailerOrder.markAsDelivered')}}
      button.button.is-white.retailer-settings__saved-addresses-button(@click="statusTransition(orderStatus.paidByRetailer)" v-if="isTransition(orderStatus.paidByRetailer)") {{$t('Retailers.RetailerOrder.markAsPaid')}}
      button.button.is-white.retailer-settings__saved-addresses-button(@click="statusTransition(orderStatus.paidToBrand)" v-if="isTransition(orderStatus.paidToBrand)") {{$t('Retailers.RetailerOrder.markAsPaidtoBrand')}}
      button.button.is-black.retailer-settings__saved-addresses-button(@click="showTrackingInfoModal" v-if="showTrackingInfoButton") {{$t('Retailers.RetailerOrder.enterTrackingNumber')}}
      button.button.is-white.retailer-settings__saved-addresses-button(@click="statusTransition('canceled')" v-if="order.status.id==orderStatus.pending && !order.brandAccepted && isBrand") {{$t('Retailers.RetailerOrder.decline')}}
      button.button.is-white.retailer-settings__saved-addresses-button(@click="statusTransition('canceled')" v-if="order.status.id==orderStatus.pending && !order.retailerAccepted && isRetailer") {{$t('Retailers.RetailerOrder.decline')}}
      button.button.is-white.retailer-settings__saved-addresses-button(:disabled="true" v-if="order.status.id==orderStatus.canceled && isBrand") {{$t('Retailers.RetailerOrder.declined')}}
      button.button.is-black.retailer-settings__saved-addresses-button(v-if="order.status.id==orderStatus.pending && !order.brandAccepted && isBrand" @click="acceptOrder()") {{$t('Retailers.RetailerOrder.accept')}}
      button.button.is-black.retailer-settings__saved-addresses-button(v-if="order.status.id==orderStatus.pending && !order.retailerAccepted && isRetailer" @click="acceptOrder()") {{$t('Retailers.RetailerOrder.accept')}}


  .columns
    .column.is-two-thirds.old-retailer-order-table__container
      .columns.is-multiline
        .column.is-12
          .old-retailer-order-table.table-border-b-none(v-click-outside="hideProductDropdown")
            p.old-retailer-order-table__name {{$t('Retailers.RetailerOrder.products')}}
            table.table.is-fullwidth
              thead
                tr
                  th.old-retailer-order-table__head {{$t('Retailers.RetailerOrder.sKU')}}
                  th.old-retailer-order-table__head {{$t('Retailers.RetailerOrder.name')}}
                  th.old-retailer-order-table__head {{$t('Retailers.RetailerOrder.quantity')}}
                  th.old-retailer-order-table__head {{$t('Retailers.RetailerOrder.testers')}}
                  th.old-retailer-order-table__head {{$t('Retailers.RetailerOrder.mSRP')}}
                  th.old-retailer-order-table__head {{$t('Retailers.RetailerOrder.unitPrice')}}
                  th.old-retailer-order-table__head(:class="{'alignTextCenter' : alignCenter}") {{$t('Retailers.RetailerOrder.amount')}}
                  th.old-retailer-order-table__head(v-if="isProductEditable")
              tbody
                tr(v-for="(item, index) in purchaseDetails")
                  td.old-retailer-order-table__cell {{item.product && item.product.sku}}
                  td.old-retailer-order-table__cell {{item.product && item.product.name}}
                  td.old-retailer-order-table__cell(v-if="productEditIndex != index") {{item.quantity}}
                  td.old-retailer-order-table__cell(v-if="productEditIndex == index")
                    .field
                      .control.old-retailer-order-quantity-input
                        input.input.is-small.old-retailer-order-input(
                          @input="productQuantityValidation(item)"
                          type="number" step="1" min="1" :max="initialQuantity"   :value="item.quantity"
                        )
                        .old-retailer-order-input-plus(@click="increaseQuantity(item)") +
                        .old-retailer-order-input-minus(@click="decreaseQuantity(item)") -
                  td.old-retailer-order-table__cell(v-if="productEditIndex != index") {{item.testersquantity}}
                  td.old-retailer-order-table__cell(v-if="productEditIndex == index")
                    .field
                     .control.old-retailer-order-quantity-input
                          input.input.is-small.old-retailer-order-input(
                          ref="testers"
                          type="number" min="1" :max="initialTesters"   :value="item.testersquantity"
                       )
                    .old-retailer-order-input-plus(@click="increaseTesters(item)") +
                    .old-retailer-order-input-minus(@click="decreaseTesters(item)") -
                  td.old-retailer-order-table__cell {{priceFormat(item.recordedMsrpUsd)}}
                  td.old-retailer-order-table__cell {{priceFormat(item.calculatedWholesalePriceUsd)}}
                  td.old-retailer-order-table__cell(:class="{'alignTextCenter' : alignCenter}") {{priceFormat(item.calculatedWholesaleCostUsd)}}
                    .product-edit-buttons(v-if="productEditIndex == index")
                      button.button.save-additional-charge(@click="saveProductQuantities(item)" :disabled="isSaveDisabled") {{$t('Components.Orders.OrderDetails.save')}}
                      button.button.cancel-additional-charge(@click="cancelProductEdit(item)") {{$t('Components.Orders.OrderDetails.cancel')}}
                  td.old-retailer-order-table__cell(v-if="isProductEditable")
                    .order-product-item__circles(@click="showEditProductDropDown(index)")
                      img(src="@/assets/img/icon/ellipses.svg")
                    .order-product-item__dropdown(v-if="productIndex == index")
                      .order-product-item__dropdown-item(@click="editProduct(index, item)")
                        span.order-product-item__dropdown-icon
                          img(src="@/assets/img/icon/edit-dropdown-icon.svg")
                        span.order-product-item__edit-button {{$t('Components.Orders.OrderDetails.edit')}}
                      .order-product-item__dropdown-item(@click="removeProduct(item)" v-if="purchaseDetails.length>1")
                        span.order-product-item__dropdown-icon
                          img(src="@/assets/img/icon/remove-dropdown-icon.svg")
                        span.order-product-item__remove-button {{$t('Components.Orders.OrderDetails.remove')}}
                tr
                  td.old-retailer-order-table__cell.table-border-b-none
                  td.old-retailer-order-table__cell.table-border-b-none
                  td.old-retailer-order-table__cell.table-border-b-none
                  td.old-retailer-order-table__cell.table-border-b-none
                  td.old-retailer-order-table__cell.table-border-b-none
                  td.old-retailer-order-table__cell.table-border-b-none.subtotal {{$t('Retailers.RetailerOrder.itemSubtotal')}}
                  td.old-retailer-order-table__cell.table-border-b-none(:class="{'alignTextCenter' : alignCenter}") {{priceFormat(order.calculatedWholesaleCostUsd)}}

          AdditionalCharges(:order="order" v-if="role==='admin'")
        .column.is-12
          OrderActivity(:brand="brand" :retailer="retailer" :order="order",v-if="brand.members && retailer.members")

    .column
      .columns.is-multiline
        .column.is-12(v-if="supplyAirTrackingInfo || supplyOceanTrackingInfo || deliveryAirTrackingInfo || deliveryOceanTrackingInfo")
          .old-retailer-order-information
            .old-retailer-order-information__container(v-if="(role=='admin' || role=='retailer') && (deliveryAirTrackingInfo || deliveryOceanTrackingInfo)")
              .old-retailer-order-information__block
                .shipping-first-block
                  .hipping-first-block__heading
                    .old-retailer-order-information__title(v-if="role=='admin'") {{$t('Components.Orders.OrderDetails.deliveryShipmentInfo')}}
                    .old-retailer-order-information__title(v-else) {{$t('Components.Orders.OrderDetails.shipmentInfo')}}
                    a.edit-shipment-info(@click="editTrackingInfo(orderStatus.deliverShipped)" v-if="role=='admin'") {{$t('Components.Orders.OrderDetails.edit')}}
                  div(v-if="deliveryAirTrackingInfo")
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.shippingCarrier')}}
                    .address.shipment-provider
                      p {{deliveryAirTrackingInfo.provider.name}}
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.trackingNumber')}}
                    .old-retailer-order-information__link {{deliveryAirTrackingInfo.trackingId}}
                  div(v-if="deliveryOceanTrackingInfo")
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.forwarderName')}}
                    .address.shipment-provider
                      p {{deliveryOceanTrackingInfo.forwarderName}}
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.vesselCompany')}}
                    .address.shipment-provider
                      p {{deliveryOceanTrackingInfo.provider.name}}
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.houseBlNumner')}}
                    .old-retailer-order-information__link {{deliveryOceanTrackingInfo.houseBlNumber}}
            .old-retailer-order-information__container(v-if="(role=='admin' || role=='brand') && (supplyAirTrackingInfo || supplyOceanTrackingInfo)")
              .old-retailer-order-information__block
                .shipping-first-block
                  .hipping-first-block__heading
                    .old-retailer-order-information__title(v-if="role=='admin'") {{$t('Components.Orders.OrderDetails.supplyShipmentInfo')}}
                    .old-retailer-order-information__title(v-else) {{$t('Components.Orders.OrderDetails.shipmentInfo')}}
                    a.edit-shipment-info(@click="editTrackingInfo(orderStatus.supplyShipped)" v-if="role=='brand' || role=='admin'") {{$t('Components.Orders.OrderDetails.edit')}}
                  div(v-if="supplyAirTrackingInfo")
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.shippingCarrier')}}
                    .address.shipment-provider
                      p {{supplyAirTrackingInfo.provider.name}}
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.trackingNumber')}}
                    .old-retailer-order-information__link {{supplyAirTrackingInfo.trackingId}}
                  div(v-if="supplyOceanTrackingInfo")
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.forwarderName')}}
                    .address.shipment-provider
                      p {{supplyOceanTrackingInfo.forwarderName}}
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.vesselCompany')}}
                    .address.shipment-provider
                      p {{supplyOceanTrackingInfo.provider.name}}
                    .old-retailer-order-information__header {{$t('Components.Orders.OrderDetails.houseBlNumner')}}
                    .old-retailer-order-information__link {{supplyOceanTrackingInfo.houseBlNumber}}
        .column.is-12
          .old-retailer-order-information
            .old-retailer-order-information__container(v-if="isRetailer")
              .old-retailer-order-information__block
                .shipping-first-block
                  .hipping-first-block__heading
                    .old-retailer-order-information__title {{$t('Retailers.RetailerOrder.shipping')}}
                    .img-block(v-if="!brand.logoAsset.url")
                      img(src="@/assets/img/default-logo-image.png")
                    .img-block(v-if="brand.logoAsset.url")
                      img(:src="brand.logoAsset.url")
                  .old-retailer-order-information__header {{$t('Retailers.RetailerOrder.brand')}}
                  .old-retailer-order-information__link {{brand.name}}
            .old-retailer-order-information__container(v-if="isBrand")
              .old-retailer-order-information__block
                .shipping-first-block
                  .hipping-first-block__heading
                    .old-retailer-order-information__title {{$t('Retailers.RetailerOrder.shipping')}}
                    .img-block(v-if="!retailer.logoAsset.url")
                      img(src="@/assets/img/default-logo-image.png")
                    .img-block(v-if="retailer.logoAsset.url")
                      img(:src="retailer.logoAsset.url")
                  .old-retailer-order-information__header Buyer
                  .old-retailer-order-information__link {{retailer.name}}
            .old-retailer-order-information__container
              .old-retailer-order-information__header
                .order-information-subheading
                  span {{$t('Retailers.RetailerOrder.shippingAddress')}}
                  a.edit-shipment-info(@click="warningOldHistory" v-if="showEditContactAndAddress") Edit
                .adresss(v-if="order.shipToAddress")
                  p {{order.shipToAddress.addressee}}
                  p {{order.shipToAddress.streetAddressLine_1}}
                  p {{order.shipToAddress.streetAddressLine_2}}
                  p(v-if="order.shipToAddress.city") {{order.shipToAddress.city}}, {{order.shipToAddress.region}} {{order.shipToAddress.postalCode}}
                  p(v-if="order.shipToAddress.country") {{countryNameById(order.shipToAddress.country.id)}}
            .old-retailer-order-information__container
              .old-retailer-order-information__header
                .order-information-subheading
                  span {{$t('Retailers.RetailerOrder.contactInformation')}}
                  a.edit-shipment-info(@click="showContactInfoModal" v-if="showEditContactAndAddress") Edit
                .adresss(v-if="order.contact")
                  p {{order.contact.name}}
                  p {{order.contact.phone}}
            .old-retailer-order-information__container
              .old-retailer-order-information__header
                .order-information-subheading
                  span {{$t('Retailers.RetailerOrder.shipDate')}}
                  a.edit-shipment-info(@click="warningOldHistory" v-if="showEditShippingWindow") Edit
                .adresss
                  p {{ order.shipDate }}
            .old-retailer-order-information__container
              .old-retailer-order-information__header {{$t('Retailers.RetailerOrder.cancelDate')}}
                .adresss
                  p {{ order.cancelDate }}
            .old-retailer-order-information__container
              .old-retailer-order-information__header {{$t('Retailers.RetailerOrder.estimatedPaymentDate')}}
                .adresss
                  p {{ order.calculatedPaidToBrandDate }}

        .column.is-12
          .old-retailer-order-information.old-retailer-order-information__terms
            .old-retailer-order-information__container
              .old-retailer-order-information__title {{$t('Retailers.RetailerOrder.terms')}}
            .old-retailer-order-information__container
              .old-retailer-order-information__header {{$t('Retailers.RetailerOrder.paymentTerms')}}
                .adresss(v-if="order.recordedRetailerPaymentTerm")
                  p {{ order.recordedRetailerPaymentTerm.name }}
            .old-retailer-order-information__container
              .old-retailer-order-information__header {{$t('Retailers.RetailerOrder.compliance')}}
                .adresss
                  div(v-for="item in order.compliance")
                    p(v-if="item.id=='ticketing'") {{$t('Retailers.RetailerOrder.productTicketing')}}
                    p(v-if="item.id=='edi'") {{$t('Retailers.RetailerOrder.retailerEDI')}}
                    p(v-if="item.id=='vendor-guide'") {{$t('Retailers.RetailerOrder.vendorGuide')}}
  TrackingInformationModal(
    :show="showTrackingInfo"
    @hide="hideTrackingInfo"
    v-if="showTrackingInfo"
    :airTrackingInfo="airShipments"
    :oceanTrackingInfo="oceanShipments"
    :isEditing="isEditTrackingInfo"
    :trackingStatus="trackingStatus"
  )

  EditAddressModal(
    :show="showEditAddressModal"
    @hideAddressModal="showEditAddressModal=false"
    @updateAddress="updateShippingAddress"
    :address="order.shipToAddress"
    v-if="showEditAddressModal"
  )
  EditContactInformation(
    :show="editContactInfo"
    :contact="contactInfo"
    @hideContactInfo="editContactInfo = false"
    @contactInfoChange="updateContactInfo"
    v-if="editContactInfo"
  )
  EditShippingWindow(
    :show="editShippingWindow"
    :orderShipDate="order.shipDate"
    :orderCancelDate="order.cancelDate"
    @shippingWindowChange="updateShippingWindow"
    @hideShippingWindow="editShippingWindow=false"
    v-if="editShippingWindow"
  )
</template>

<script>
import OrderActivity from "./OrderActivity"
import AdditionalCharges from "./AdditionalCharges"
import OrderStatus from "./OrderStatus"
import constants from "@/config.js"
import TrackingInformationModal from "./OldTrackingInformationModal"
import EditAddressModal from "./EditAddressModal"
import EditContactInformation from "./EditContactInformation"
import EditShippingWindow from "@/components/orders/EditShippingWindow"
export default {
  components: {
    OrderActivity,
    OrderStatus,
    AdditionalCharges,
    TrackingInformationModal,
    EditAddressModal,
    EditContactInformation,
    EditShippingWindow
  },
  prop: {
    orderId: { type: String, default: "" }
  },
  data() {
    return {
      editShippingWindow: false,
      contactInfo: null,
      editContactInfo: false,
      showEditAddressModal: false,
      showTrackingInfo: false,
      initialQuantity: null,
      initialTesters: null,
      alignCenter: false,
      orderStatus: constants.orderStatus,
      showAddChargeFileds: false,
      purchaseDetails: [
        {
          calculatedRetailCostUsd: "",
          calculatedWholesaleCostUsd: "",
          calculatedWholesalePriceUsd: "",
          createdAt: "",
          createdBy: "",
          product: {
            id: "",
            name: "",
            sku: ""
          },
          quantity: "",
          quantityType: {
            id: "",
            name: ""
          },
          testersquantity: "",
          recordedMargin: "",
          recordedMsrpUsd: ""
        }
      ],
      itemIndex: -1,
      editingIndex: -1,
      isEditing: false,
      productIndex: -1,
      productEditIndex: -1,
      isSaveDisabled: true,
      trackingInfo: [],
      isEditTrackingInfo: false,
      airShipments: [],
      oceanShipments: [],
      trackingStatus: ""
    }
  },
  computed: {
    showEditTrackingInfo() {
      return (
        !(
          this.role == "brand" &&
          this.order.status &&
          this.order.status.id == this.orderStatus.deliverShipped
        ) && this.role != "retailer"
      )
    },
    showEditContactAndAddress() {
      return (
        this.order.status && this.order.status.id == this.orderStatus.pending && this.isRetailer
      )
    },
    showEditShippingWindow() {
      return this.order.status && this.order.status.id == this.orderStatus.pending && this.isBrand
    },
    supplyAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          item => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      )
    },
    supplyOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          item => item.orderStatus.id === this.orderStatus.supplyShipped
        )[0]
      )
    },
    deliveryAirTrackingInfo() {
      return (
        this.order.airShipments &&
        this.order.airShipments.filter(
          item => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      )
    },
    deliveryOceanTrackingInfo() {
      return (
        this.order.oceanShipments &&
        this.order.oceanShipments.filter(
          item => item.orderStatus.id === this.orderStatus.deliverShipped
        )[0]
      )
    },
    showTrackingInfoButton() {
      return (
        this.role == "brand" &&
        (this.statusItemsNames.indexOf(this.orderStatus.supplyShipped) <=
          this.statusItemsNames.indexOf(this.order.status.id) ||
          this.order.status.id == this.orderStatus.paidByRetailer) &&
        !this.supplyAirTrackingInfo &&
        !this.supplyOceanTrackingInfo
      )
    },
    statusItemsNames() {
      return this.order.lifecycle && Object.values(this.order.lifecycle).map(item => item.status.id)
    },
    isProductEditable() {
      return (
        this.order.status &&
        (this.role === "brand" || this.$route.name === "adminBrandOrder") &&
        this.order.status.id === this.orderStatus.pending &&
        !this.isEditing
      )
    },
    role() {
      return this.$store.getters["user/role"]
    },
    isRetailer() {
      return this.role === "retailer" || constants.adminRetailerRoutes.includes(this.$route.name)
    },
    isBrand() {
      return this.role === "brand" || constants.adminBrandRoutes.includes(this.$route.name)
    },
    order() {
      return this.$store.getters["order/order"]
    },
    retailer() {
      return this.$store.getters["retailer/retailer"]
    },
    brand() {
      return this.$store.getters["brand/brand"]
    },
    countries() {
      return this.$store.getters["reference/countries"]
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    },
    getProductByIds() {
      return this.$store.getters["product/productsForCart"]
    }
  },
  async created() {
    await this.$store.dispatch("order/getOrder", this.$route.params.id)
    this.purchaseDetails = this.orderDetails()
    this.$store.dispatch("retailer/fetchRetailer", this.order.retailer.id)
    this.$store.dispatch("brand/getBrand", this.order.brand.id)
    this.$store.dispatch("reference/setReference")
  },
  methods: {
    warningOldHistory() {
      this.$message.warning("Old orders cannot be modified")
    },
    editTrackingInfo() {
      this.warningOldHistory()
      // this.trackingStatus = status
      // this.isEditTrackingInfo = true
      // this.airShipments = this.order.airShipments && [
      //   ...this.order.airShipments.map(item => {
      //     return {
      //       orderStatus: { ...item.orderStatus },
      //       provider: { ...item.provider },
      //       trackingId: item.trackingId
      //     }
      //   })
      // ]
      // this.oceanShipments = this.order.oceanShipments && [
      //   ...this.order.oceanShipments.map(item => {
      //     return {
      //       orderStatus: { ...item.orderStatus },
      //       provider: { ...item.provider },
      //       ...item
      //     }
      //   })
      // ]
      // this.showTrackingInfo = true
    },
    showTrackingInfoModal() {
      this.warningOldHistory()
      // if (this.role == "brand") {
      //   this.trackingStatus = this.orderStatus.supplyShipped
      // } else if (
      //   this.role == "admin" &&
      //   (this.supplyAirTrackingInfo || this.supplyOceanTrackingInfo)
      // ) {
      //   this.trackingStatus = this.orderStatus.deliverShipped
      // } else if (
      //   this.role == "admin" &&
      //   (this.deliveryAirTrackingInfo || this.deliveryOceanTrackingInfo)
      // ) {
      //   this.trackingStatus = this.orderStatus.deliverShipped
      // } else {
      //   this.trackingStatus = ""
      // }
      // this.airShipments = this.order.airShipments && [
      //   ...this.order.airShipments.map(item => {
      //     return {
      //       orderStatus: { ...item.orderStatus },
      //       provider: { ...item.provider },
      //       trackingId: item.trackingId
      //     }
      //   })
      // ]
      // this.oceanShipments = this.order.oceanShipments && [
      //   ...this.order.oceanShipments.map(item => {
      //     return {
      //       orderStatus: { ...item.orderStatus },
      //       provider: { ...item.provider },
      //       ...item
      //     }
      //   })
      // ]
      // this.isEditTrackingInfo = false
      // this.showTrackingInfo = true
    },
    orderDetails() {
      let purchase =
        this.order && this.order.items.filter(item => item.quantityType.id == "purchase")
      let testers = this.order && this.order.items.filter(item => item.quantityType.id == "testers")
      if (purchase.length && testers.length) {
        purchase.forEach(purchaseElement => {
          testers.forEach(testerElement => {
            if (purchaseElement.product.id == testerElement.product.id) {
              purchaseElement.testersquantity = testerElement.quantity
            }
          })
        })
      }
      return purchase
    },
    productById(productId) {
      return this.$store.getters["product/findInCartProducts"](productId) || {}
    },
    priceFormat(price) {
      const numberFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      })
      return numberFormat.format(price)
    },
    damagesBudget() {
      return (
        this.retailer.terms.damagesBudget &&
        parseFloat(
          (this.retailer.terms.damagesBudget / 100) * this.order.calculatedWholesaleCostUsd
        )
      )
    },
    finalSubtotal() {
      return parseFloat(this.order.calculatedWholesaleCostUsd) + this.damagesBudget()
    },
    logoUrl(user) {
      return user.logoAsset && user.logoAsset.url
    },
    countryNameById(countryId) {
      const country = this.countries.find(country => country.id === countryId)
      return country && country.name
    },
    async acceptOrder() {
      this.warningOldHistory()
      // if (
      //   (this.isBrand && this.order.retailerAccepted) ||
      //   (this.isRetailer && this.order.brandAccepted)
      // ) {
      //   this.statusTransition(this.orderStatus.confirmed)
      // } else {
      //   let order = {
      //     id: this.order.id,
      //     isBrandAccepted: this.isBrand ? true : this.order.brandAccepted,
      //     isRetailerAccepted: this.isRetailer ? true : this.order.retailerAccepted,
      //     version: Number(this.order.version) + 1
      //   }
      //   let params = {}
      //   try {
      //     if (this.$route.name == "adminBrandOrder") {
      //       params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      //     }
      //     if (this.$route.name == "adminRetailerOrder") {
      //       params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      //     }
      //     await this.$store.dispatch("order/updateStatus", { order, params })
      //   } catch (error) {
      //     console.error(error)
      //   }
      // }
    },
    transitionObject(nextStatus) {
      const transition =
        (this.order.allowedTransitions &&
          this.order.allowedTransitions.filter(item => item.id === nextStatus)) ||
        []
      let result = {
        id: this.order.id,
        status: {
          id: this.isAdmin ? nextStatus : transition[0].id
        },
        version: Number(this.order.version) + 1,
        isBrandAccepted: nextStatus === this.orderStatus.canceled ? this.order.brandAccepted : true,
        isRetailerAccepted:
          nextStatus === this.orderStatus.canceled ? this.order.retailerAccepted : true
      }
      if (nextStatus == this.orderStatus.pending) {
        result.isBrandAccepted = false
        result.isRetailerAccepted = false
      }
      return result
    },
    async statusTransition() {
      this.warningOldHistory()
      // if (
      //   (nextStatus === this.orderStatus.supplyShipped &&
      //     !this.supplyAirTrackingInfo &&
      //     !this.supplyOceanTrackingInfo) ||
      //   (nextStatus === this.orderStatus.deliverShipped &&
      //     !this.deliveryAirTrackingInfo &&
      //     !this.deliveryOceanTrackingInfo)
      // ) {
      //   this.showTrackingInfoModal()
      // }
      // try {
      //   let order = this.transitionObject(nextStatus)
      //   let params = {}
      //   if (this.$route.name == "adminBrandOrder") {
      //     params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      //   }
      //   if (this.$route.name == "adminRetailerOrder") {
      //     params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      //   }
      //   await this.$store.dispatch("order/updateStatus", { order, params })
      // } catch (error) {
      //   console.error(error)
      // }
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity = Number(item.quantity) - 1
        this.isSaveDisabled = false
      }
    },
    increaseQuantity(item) {
      if (item.quantity < this.initialQuantity) {
        item.quantity = Number(item.quantity) + 1
      }
      if (item.quantity == this.initialQuantity && item.testersquantity == this.initialTesters) {
        this.isSaveDisabled = true
      }
    },
    updateQuantitiyObject() {
      return {
        id: this.order.id,
        items: this.order.items,
        version: Number(this.order.version) + 1,
        isRetailerAccepted: false
      }
    },
    async updateOrderQuantity() {
      try {
        let order = this.updateQuantitiyObject()
        let params = {}
        if (this.$route.name == "adminBrandOrder") {
          params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
        }
        await this.$store.dispatch("order/updateStatus", { order, params })
        this.purchaseDetails = this.orderDetails()
        this.productEditIndex = -1
        this.isEditing = false
        this.alignCenter = false
      } catch (error) {
        console.error(error)
      }
    },
    showEditProductDropDown(index) {
      this.productIndex = index
    },
    editProduct(index, item) {
      this.initialQuantity = item.quantity
      this.initialTesters = item.testersquantity
      this.isSaveDisabled = true
      this.productEditIndex = index
      this.hideProductDropdown()
      this.isEditing = true
      this.alignCenter = true
    },
    hideProductDropdown() {
      this.productIndex = -1
    },
    cancelProductEdit(item) {
      item.quantity = this.initialQuantity
      item.testersquantity = this.initialTesters
      this.productEditIndex = -1
      this.isEditing = false
      this.alignCenter = false
    },
    removeProduct(purachasDetails) {
      this.order.items = this.order.items.filter(
        item => item.product.id != purachasDetails.product.id
      )
      this.updateOrderQuantity()
      this.hideProductDropdown()
    },
    saveProductQuantities(purchaseItem) {
      this.order.items.map(item => {
        if (item.product.id === purchaseItem.product.id && item.quantityType.id === "testers") {
          item.quantity = purchaseItem.testersquantity
        }
      })
      this.isSaveDisabled = true
      this.updateOrderQuantity()
    },
    productQuantityValidation(product) {
      if (
        product.quantity < 1 ||
        product.quantity > this.initialQuantity ||
        product.testersquantity < 0 ||
        product.testersquantity > this.initialTesters
      ) {
        this.isSaveDisabled = true
      } else {
        this.isSaveDisabled = false
      }
    },
    decreaseTesters(item) {
      if (item.testersquantity > 0) {
        this.$refs.testers[0].value--
        item.testersquantity = item.testersquantity - 1
        this.isSaveDisabled = false
      }
    },
    increaseTesters(item) {
      if (item.testersquantity < this.initialTesters) {
        this.$refs.testers[0].value++
        item.testersquantity = Number(item.testersquantity) + 1
      }
      if (item.testersquantity == this.initialTesters && item.quantity == this.initialQuantity) {
        this.isSaveDisabled = true
      }
    },
    isTransition(status) {
      return (
        this.order.allowedTransitions &&
        this.order.allowedTransitions.filter(item => item.id === status).length > 0
      )
    },
    hideTrackingInfo() {
      this.showTrackingInfo = false
      this.isEditTrackingInfo = false
    },
    updateShippingAddress(addressModal) {
      let order = {
        id: this.order.id,
        shipToAddress: addressModal,
        isRetailerAccepted: this.order.retailerAccepted,
        isBrandAccepted: false,
        version: Number(this.order.version) + 1
      }
      this.updateShippingDetails(order)
    },
    showContactInfoModal() {
      this.warningOldHistory()
      // this.editContactInfo = true
      // let names = this.order.contact && this.order.contact.name.split(" ")
      // this.contactInfo = {
      //   firstName: names[0],
      //   lastName: names[1],
      //   phone: this.order.contact && this.order.contact.phone
      // }
    },
    updateContactInfo(updatedContact) {
      let order = {
        id: this.order.id,
        contact: updatedContact,
        isRetailerAccepted: this.order.retailerAccepted,
        isBrandAccepted: false,
        version: Number(this.order.version) + 1
      }
      this.updateShippingDetails(order)
    },
    updateShippingWindow(shipDate, cancelDate) {
      let order = {
        id: this.order.id,
        shipDate: shipDate,
        cancelDate: cancelDate,
        version: Number(this.order.version) + 1,
        isBrandAccepted: this.order.brandAccepted,
        isRetailerAccepted: false
      }
      this.updateShippingDetails(order)
    },
    async updateShippingDetails(order) {
      let params = {}
      if (this.$route.name == "adminRetailerOrder") {
        params = { "_jwt-c_id": this.order.retailer.id, "_jwt-c_type": "retailer" }
      }
      if (this.$route.name == "adminBrandOrder") {
        params = { "_jwt-c_id": this.order.brand.id, "_jwt-c_type": "brand" }
      }
      try {
        await this.$store.dispatch("order/updateStatus", { order, params })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="sass">
.old-retailer-order
  //padding-top: 40px
  //padding-bottom: 80px
  padding: 40px 30px 80px 40px
  .old-retailer-order-status-banner__block
    display: flex
    align-items: center
    padding-right: 20px
    .old-retailer-order-status-banner__left-block
      margin-bottom: 0px
    .old-retailer-order-status-banner__button-block
      margin-left: auto
      display: flex
      .button
        margin-bottom: 0px
      .button.button
        margin-left: 10px
  .old-retailer-order-table__container
    .old-retailer-order-table
      padding: 20px 0px
      .reold-retailer-order-table__header-container
        .button[disabled]
          cursor: default
        display: flex
        justify-content: space-between
        .add-charge-button
          margin-right: 10px
      .old-retailer-order-table__cell
        color: #333
        font-size: 14px
        .additional-charge
          width: 100%
          select
            width: 100%
            height: 36px
        .amount-input-container
          display: flex
          margin-bottom: 7px
          .input
            width: 70px
            margin-right: 4px
          .additional-charge-dropdown
            .select
              height: 36px
              select
                height: 100%
        .additional-charge-buttons
          width: 129px
          display: flex
          justify-content: flex-end
          padding: 5px 0
          .button[disabled]
            cursor: default
          .save-additional-charge
            margin-right: 10px
            font-size: 14px
            height: 25px
            width: 50px
          .cancel-additional-charge
            font-size: 14px
            height: 25px
            width: 50px
            text-decoration: underline
            color: #4250C7
            border: none
        .old-retailer-order-quantity-input
          text-align: right !important
          width: 70px
          .old-retailer-order-input
            width: 74px
            height: 41px
            position: relative
            text-align: center
          .old-retailer-order-input-plus
            position: absolute
            top: 0px
            right: 0px
            border-left: 1px solid #E5E5E5
            border-bottom: 1px solid #E5E5E5
            height: 21px
            width: 21px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
          .old-retailer-order-input-minus
            position: absolute
            top: 20px
            right: 0px
            border-left: 1px solid #E5E5E5
            height: 21px
            width: 21px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
        .product-edit-buttons
          display: flex
          justify-content: flex-start
          padding: 25px 0 0 0
          .button[disabled]
            cursor: default
          .save-additional-charge
            margin-right: 10px
            font-size: 14px
            height: 25px
            width: 50px
          .cancel-additional-charge
            font-size: 14px
            height: 25px
            width: 50px
            text-decoration: underline
            color: #4250C7
            border: none
      .table-border-b-none
        border-bottom: none !important
      .old-retailer-order-table__cell.subtotal
        font-weight: 700
      .brand-order-table__cell.subtotal
        font-weight: 700
      .total
        padding-top: 30px
        td
          padding: 30px 16px 16px 16px
    .old-retailer-order-table__cell.table-border-b-none
      border-bottom: none
    .brand-order-table__cell.table-border-b-none.text-right
      text-align: right
    .brand-order-table__cell.table-border-b-none
      border-bottom: none
    .old-retailer-order-table__cell.table-border-b-none.text-right
      text-align: right
    .old-retailer-order-table.table-border-t-none
      padding-top: 46px
      border-top: none
  &-navigation
    font-size: 14px
    color: #4A90E2
    span:first-child
      padding-right: 20px
  &-id
    padding-top: 10px
    padding-bottom: 10px
    font-size: 28px
  &-table
    border: 1px solid #E0E0E0
    padding: 20px
    &__name
      color: #333333
      font-size: 16px
      font-weight: 500
      letter-spacing: 0.25px
      margin-bottom: 30px
      margin-left: 10px
    &__head
      color: #888888 !important
      font-size: 14px
      font-weight: 500
      border-bottom: 1px solid #E0E0E0 !important
    &__cell
      color: #333333 !important
      font-size: 14px
      font-weight: 500
      border-bottom: 1px solid #E0E0E0 !important
      margin-top: 10px
      position: relative
    &__container
      margin-right: 20px
  &-information
    border: 1px solid #E0E0E0
    &__title
      color: #333333
      font-size: 16px
      padding-bottom: 20px
    &__container
      padding: 20px
      border-bottom: 1px solid #E0E0E0
    &__link
      color: #006FBB
      font-weight: 600
      font-size: 14px
    &__header
      text-transform: uppercase
      color: #888888
      font-size: 12px
      padding-bottom: 10px
    &__logo
      width: 30px
      height: 30px
    &__terms
      margin-top: 20px
  &-status-banner
    background-color: #FFFBFA
    border: 1px solid #E0E0E0
    border-radius: 0 0 3px 3px
    border-top: 3px solid #9A8A8A
    min-height: 80px
    margin-bottom: 20px
    padding-left: 20px
    &__title
      padding-top: 10px
      font-size: 16px
      color: #212B36
    &__details
      font-size: 14px
      color: #212B36
    &__icon
      display: flex
      padding-right: 10px
  &-calculations
    &__subtotal
      display: flex
      justify-content: flex-end
      padding-bottom: 20px
      &-amount
        padding-left: 45px
    &__full-subtotal
      display: flex
      justify-content: flex-end
      border-top: 1px solid #E0E0E0
      padding-top: 15px
      &-amount
        padding-left: 45px
        border-top: 1px solid #E0E0E0
        padding-top: 15px
    &__full-subtotal-title
      font-weight: bold
      color: #333333
  .old-retailer-order-information__container
    .shipping-first-block
      width: 100%
      .hipping-first-block__heading
        display: flex
        align-items: center
        .old-retailer-order-information__title
          padding-bottom: 0px
        .img-block
          height: 30px
          margin-left: auto
          img
            height: 30px
    .old-retailer-order-information__header
      color: #888
      .adresss
        padding-top: 16px
        p
          color: #333333
          font_size: 14px
  .order-product-item__dropdown
    position: absolute
    width: 128px
    top: 10px
    right: 10px
    z-index: 100
    background-color: #ffffff
    border: 1px solid #DADADA
  .order-product-item__dropdown-item
    height: 45px
    display: flex
    align-items: center
    padding-left: 10px
    cursor: pointer
  .order-product-item__dropdown-icon
    padding-right: 15px
  .order-product-item__dropdown-item:hover
    background-color: #F5F6F8
  .order-product-item__remove-button
    color: #BF0711
  .order-product-item__circles
    position: absolute
    top: 15px
    right: 0px
    z-index: 1
    cursor: pointer
    height: 30px
    width: 30px
  .alignTextCenter
    text-align: center !important
  .edit-shipment-info
    margin-left: auto
    text-decoration: underline
    color: #333333 !important
    font-size: 14px
  .shipment-provider
    font-size: 14px
    margin-bottom: 17px
  .order-information-subheading
    display: flex
</style>
