<template>
  <div class="brand-info-product-detail__main">
    <div class="training-details-content">
      <div class="company-details">
        <div class="content-right">
          <div class="right-top-text-button">
            <div
              class="right-text-right"
              :style="{
                width: `${
                  userInfo.introductionAsset && Object.keys(userInfo.introductionAsset).length !== 0
                    ? '570px'
                    : '694px'
                }`
              }"
            >
              <!--              <div class="title-name">-->
              <!--                {{ userInfo.name }}-->
              <!--              </div>-->
              <div class="about-content-main">
                <div class="content-instructions">
                  {{ userInfo.about }}
                </div>
              </div>
            </div>
            <!--            <div-->
            <!--                v-if="-->
            <!--                userInfo.introductionAsset && Object.keys(userInfo.introductionAsset).length !== 0-->
            <!--              "-->
            <!--                class="right-button-left"-->
            <!--            >-->
            <!--              <div v-if="userInfo.introductionAsset" class="video-style" @click="clickToVideo">-->
            <!--                <img class="public-start" src="../../assets/training_public_video.jpg" alt="" />-->
            <!--                <video width="250" height="110" style="height: 110px !important;">-->
            <!--                  <source :src="userInfo.introductionAsset.url" type="video/mp4" />-->
            <!--                  Your browser does not support the HTML5 video tag.-->
            <!--                </video>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="other-content">
            <div class="curations">
              <div class="name-curation-value">
                Curations
              </div>
              <div>
                <span v-for="(tag, index) in userInfo.curations" :key="index" class="blue-tag">
                  {{ tag.description }}
                </span>
              </div>
            </div>
            <div class="brand-value">
              <div class="name-curation-value">
                Brand Values
              </div>
              <div v-if="brandValue && brandValue.length !== 0">
                <span v-for="item in brandValue" :key="item.id" class="green-tag">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="brand-info-img-video">
          <div class="img-video-list">
            <div v-for="(item, index) in brandImgAndVideoInfo" :key="index">
              <div v-if="item.isImg" class="item-img-info" @click="showBigImg(item)">
                <img :src="item.asset.url" alt="/" />
              </div>
              <div v-if="item.asset.url && !item.isImg" class="item-img-info">
                <div class="video-style" @click="showBigImg(item)">
                  <img
                    class="public-start"
                    src="../../../assets/training_public_video.png"
                    alt=""
                  />
                  <video width="auto" height="35" style="height: 35px !important;">
                    <source :src="item.asset.url" type="video/mp4" />
                    Your browser does not support the HTML5 video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div class="right-img-and-video-list">
            <div v-if="bigImg.isImg" class="content-left">
              <img :src="bigImg.url" alt="/" />
            </div>
            <div v-else-if="bigImg.contentType.includes('video')" @click="openVideo(bigImg)">
              <div class="content-left" style="cursor: pointer">
                <img
                  class="public-start-button"
                  src="../../../assets/training_public_video.png"
                  alt=""
                  :style="{ display: `${bigImg.isImg ? 'none' : 'block'}` }"
                />
                <video width="auto" height="200" style="margin: 10px auto">
                  <source :src="bigImg.url" type="video/mp4" />
                  Your browser does not support the HTML5 video tag.
                </video>
              </div>
            </div>
            <div v-if="bigImg.canEdit" class="replace-video-button-style">
              <a-upload
                :file-list="fileList"
                :multiple="true"
                name="file"
                :show-upload-list="false"
                :custom-request="handleChange"
                @change="handleChange"
              >
                <a-button>
                  <a-icon type="sync" />
                  {{ bigImg.isImg ? "Replace Image" : "Replace Video" }}
                </a-button>
              </a-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      :visible="modalVisible"
      :footer="null"
      :destroy-on-close="true"
      :body-style="{ padding: 0 }"
      @cancel="handleCancel"
    >
      <a-icon slot="closeIcon" type="close-circle" class="modal-icon-cancel" />
      <div v-if="videoUrl && videoUrl.length !== 0" class="video-main">
        <video id="video1" ref="videoM" controls>
          <source id="videoSrc" :src="videoUrl" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
    </a-modal>
  </div>
</template>

<script>
// import assetClient from "@/api/asset-client"
import trainingClient from "@/api/training-client"
import AWS from "aws-sdk"

export default {
  name: "BrandInfoProductDetailDialog",
  props: {
    userInfo: { tyep: Object, default: () => {} },
    brandImgAndVideoInfo: { tyep: Array, default: () => [] }
  },
  data() {
    return {
      bigImg: {
        url: this.userInfo && this.userInfo.logoAsset ? this.userInfo.logoAsset.url : "",
        contentType: "image/jpeg",
        isImg: true
      },
      modalVisible: false,
      videoUrl: null,
      fileList: [],
      replaceId: ""
    }
  },
  computed: {
    brandValue() {
      return !this.userInfo.brandValues ? [] : this.userInfo.brandValues
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]
    }
    // brandImgAndVideoInfo() {
    //   const logoImg = [
    //     {
    //       asset: {
    //         ...this.userInfo.logoAsset,
    //         contentType: "image/jpeg",
    //         canEdit: false
    //       }
    //     }
    //   ]
    //   let videoImgList =
    //     this.userInfo.assets && this.userInfo.assets.length !== 0 ? this.userInfo.assets : []
    //   if (videoImgList.length !== 0) {
    //     videoImgList = videoImgList.map(item => {
    //       item.canEdit = true
    //       item.uid = item.id
    //       return item
    //     })
    //   }
    //   const list = logoImg.concat(videoImgList)
    //   return list.map(item => {
    //     if (item.asset.contentType.includes("video")) {
    //       item.isImg = false
    //     } else {
    //       item.isImg = true
    //     }
    //     return item
    //   })
    // }
  },
  methods: {
    handleCancel() {
      this.modalVisible = false
      let video = document.getElementById("video1")
      if (video) {
        video.pause()
      }
    },
    openVideo(item) {
      this.modalVisible = true
      this.videoUrl = item.url
      this.$refs["videoM"].play()
    },
    showBigImg(item) {
      this.bigImg = {
        ...item.asset,
        isImg: item.isImg,
        canEdit: item.canEdit
      }
      this.replaceId = item.asset.id
    },
    updateUploadProgress(options) {
      options.onProgress({ percent: 90 })
    },
    async handleChange(files) {
      // const status = info.file.status
      // if (status !== "uploading") {
      //   // if (status === "removed") {
      //   //   this.VideoImgFileList = info.fileList
      //   // }
      // }
      // if (status === "done") {
      // this.percent = info.percent > 90 ? 90 : info.percent
      // } else if (status === "error") {
      //   this.$message.error(`${info.fileList[0].name} file upload failed.`)
      // }
      const file = files.file
      if (file.status === "uploading") {
        await this.getAWSKey(file)
        return
      }
      if (file.status === "done" || file.status === "error") {
        this.$message.success(`${file.fileList[0].name} file uploaded successfully.`)
      }
    },
    // doUploadVideoImg(data) {
    //   this.updateUploadProgress(data)
    //   assetClient
    //     .createAsset(data.file, this.userInfo.id)
    //     .then(async response => {
    //       if (response && response.data && response.data.length > 0) {
    //         let uploadedFile = response.data[0].file
    //         data.onSuccess(response, data.file)
    //         const param = {
    //           ...uploadedFile,
    //           uid: uploadedFile.id,
    //           name: uploadedFile.originalFileName,
    //           status: "done",
    //           isImg: uploadedFile.contentType.includes("video") ? false : true,
    //           canEdit: true
    //         }
    //         const sendList = this.formatParam(param)
    //         const params = {
    //           id: this.userInfo.id,
    //           assets: sendList
    //         }
    //         await this.$store.dispatch("brand/updateBrandPublic", params)
    //         this.$emit("updateBrandInfo", this.userInfo.shortName)
    //         this.bigImg = {
    //           url: this.userInfo.logoAsset.url,
    //           contentType: "image/jpeg",
    //           isImg: true
    //         }
    //       }
    //     })
    //     .catch(err => {
    //       data.onError(err, err.response)
    //       console.info(err)
    //     })
    // },
    formatParam(obj) {
      if (this.userInfo && this.userInfo.assets && this.userInfo.assets.length !== 0) {
        let otherList = this.userInfo.assets.filter(item => item.asset.id !== this.replaceId)
        // const param = this.sendBrandAsset([obj])
        return otherList.concat(obj)
      }
    },
    sendBrandAsset(list) {
      return list.map(item => {
        return {
          asset: {
            id: item.id,
            url: item.url,
            contentType: item.contentType
          },
          type: {
            id: "intro"
          }
        }
      })
    },
    async getAWSKey(fileInfo) {
      const uploadInfo = {
        originalFileName: fileInfo.name,
        characterSet: "UTF-8",
        contentType: fileInfo.type,
        size: fileInfo.size
      }
      const { data } = this.isAdmin
        ? await trainingClient.getAWSKeyInfoInAdmin(
            uploadInfo,
            this.brandId ? this.brandId : this.userInfo.id
          )
        : await trainingClient.getAWSKeyInfo(uploadInfo)
      this.descriptor = data.descriptor
      let s3 = new AWS.S3({
        // region: data.credentialsState.region,
        region: "us-west-2",
        apiVersion: "2006-03-01",
        // authority: data.credentialsState.authority,
        accessKeyId: data.credentialsState.accessKeyId,
        secretAccessKey: data.credentialsState.secretAccessKey,
        sessionToken: data.credentialsState.sessionToken
      })
      let params = {
        Body: fileInfo.originFileObj,
        Bucket: data.credentialsState.bucketName,
        Key: data.descriptor.storageKey
      }
      s3.putObject(params, async (err, data) => {
        if (err) {
          this.$message.error("Upload failed")
        } else {
          this.percent = 90
          this.isShowReplaceBtn = true
          // this.UploadText = "Upload Successful"
          this.UploadText = "Upload"
          const successInfo = await trainingClient.sendUploadInfo(this.descriptor)
          const param = [
            {
              asset: {
                url: successInfo.data.url,
                id: successInfo.data.id,
                contentType: successInfo.data.contentType
              },
              type: {
                id: "intro"
              }
            }
          ]
          const sendList = this.formatParam(param)
          const params = {
            id: this.userInfo.id,
            assets: sendList
          }
          this.percent = 100
          if (this.userInfo.id) {
            await this.$store.dispatch("brand/updateBrandPublic", params)
            this.$emit("updateBrandInfo", this.userInfo.shortName)
            this.bigImg = {
              url: this.userInfo.logoAsset.url,
              contentType: "image/jpeg",
              isImg: true
            }
            this.$message.success(`${fileInfo.name} file uploaded successfully.`)
          } else {
            // successful response
            console.log("error", data)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.brand-info-product-detail__main {
  margin-bottom: 20px;
  .training-details-content {
    width: 100%;
    padding: 0 18px 0 22px;
    margin-top: 20px;
    //height: 22px;
    .company-details {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .brand-info-img-video {
      width: 360px;
      display: flex;
      justify-content: space-between;
      //align-items: center;
    }
    .img-video-list {
      width: 35px;
      height: 222px;
      .item-img-info {
        width: 35px;
        height: 35px;
        border: 0.5px solid #d9d9d9;
        border-radius: 2px;
        margin-bottom: 10px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
    .content-left {
      width: 300px;
      height: 222px;
      line-height: 215px;
      text-align: center;
      border: 0.5px solid #d9d9d9;
      border-radius: 2px;
      position: relative;
      //border: 1px solid #bfbfbf;
      img {
        //width: 100%;
        height: 100%;
      }
      .public-start-button {
        width: 50px;
        //height: 30px;
        height: auto;
        position: absolute;
        top: 80px;
        right: 120px;
        border-radius: 50%;
      }
    }
    .content-right {
      width: 500px;
      position: relative;
      .content-instructions {
        height: 108px;
        font-size: 12px;
        line-height: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 12px;
        color: #262626;
        position: relative;
        word-break: break-word;
        overflow-y: auto;
      }
      .other-content {
        //display: flex;
        //justify-content: flex-start;
      }
      .curations,
      .brand-value {
        width: 100%;
        font-size: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: start;
        span {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          font-size: 12px;
        }
      }
      .name-curation-value {
        font-size: 12px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 8px;
        margin-right: 30px;
        color: #262626;
      }
      .blue-tag {
        padding: 5px 8px;
        color: #ffffff;
        border: 1px solid #f27c59;
        background: #f27c59;
        border-radius: 100px;
        font-size: 10px;
      }
      .green-tag {
        padding: 5px 8px;
        color: #ffffff;
        border: 1px solid #91d395;
        background: #91d395;
        border-radius: 100px;
        font-size: 10px;
      }
      .right-top-text-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right-text-right {
          width: 570px;
        }
        .right-button-left {
          width: 171px;
          margin-left: 30px;
          .upload-video-style {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #4a8a5d;
            background-color: #e2eeec;
            text-align: center;
          }
          .upload-success {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #ffffff;
            background-color: #4a8a5d;
            text-align: center;
          }
        }
      }
    }
  }
  .video-style {
    width: 35px;
    height: 35px;
    margin-top: 6px;
    text-align: center;
    //border: 1px solid #262626;
    cursor: pointer;
    position: relative;
    .video-img {
      //width: 100%;
      height: 100%;
    }
    .public-start {
      width: 15px !important;
      position: absolute;
      top: 4px;
      right: 10px;
      border-radius: 50%;
    }
    .background-style {
      width: 10px;
      height: 10px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      top: 10px;
      right: 10px;
    }
  }
  .right-img-and-video-list {
    position: relative;
    .replace-video-button-style {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }
  }
}
</style>
