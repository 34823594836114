<template>
  <div class="retailer_card" @click="cardClick(item)">
    <div class="retailer_card_a">
      <div class="card-tag">
        {{ item.channelType ? item.channelType.name : "No Type" }}
      </div>
      <div v-if="instanData" class="icon card-icon" @click.stop="goToSocialMedia">
        <img src="@/assets/img/brand/brand-retailer_search_page/insta.svg" />
      </div>
    </div>
    <div class="retailer_card_b">
      <a-avatar :size="64" :src="logoImage(item)" />
    </div>
    <div class="retailer_card_c">{{ item.name }}</div>
    <div class="retailer_card_d">{{ item.about }}</div>
    <div class="retailer_card_e">
      <div class="retailer_card_e_box">
        <span>Website:</span>
        <a @click="goToWebsite(item.website)">{{ item.website | sliceStr }}</a>
      </div>
    </div>
    <div class="retailer_card_f">Locations: {{ getRetailerLocations(item) }}</div>
    <div class="retailer_card_g">
      <svg-icon icon-class="eye" class="svg-icon-base svg-icon-font24 svg-icon-style" />
      <span>view</span>
    </div>
    <BrandTableDialog ref="retailerCardUpgradePlanDialog" :brand-type="brandType" />
  </div>
</template>

<script>
import BrandTableDialog from "@/components/brands/mainPage/components/BrandTableDialog"
export default {
  name: "RetailerCard",
  components: {
    BrandTableDialog
  },
  filters: {
    sliceStr(val) {
      if (!val) return
      return val.slice(0, 10)
    }
  },
  props: {
    item: { type: Object, default: () => {} }
  },
  data() {
    return {
      instanData: undefined
    }
  },
  computed: {
    brand() {
      return this.$store.getters["brand/brand"]
    },
    brandType() {
      return (
        this.brand.subscriptions &&
        this.brand.subscriptions[0].type &&
        this.brand.subscriptions[0].type.id
      )
    }
  },
  created() {
    const brandId = this.$store.getters["user/businessId"]
    this.$store.dispatch("brand/getBrand", brandId)
    if (this.item && this.item.socialAccounts && this.item.socialAccounts.length > 0) {
      for (let i = 0; i < this.item.socialAccounts.length; i++) {
        if (this.item.socialAccounts[i].platform.id === "instagram") {
          this.instanData = this.item.socialAccounts[i]
          break
        }
      }
    }
  },
  methods: {
    showUpdatePlanModal() {
      this.$refs["retailerCardUpgradePlanDialog"].openDialog()
    },
    goToSocialMedia() {
      if (this.instanData.address) {
        window.open("http://" + this.instanData.address)
      }
    },
    cardClick(item) {
      if (this.brandType === "basic") {
        this.showUpdatePlanModal()
      } else {
        this.$router.push({
          name: "brandRetailerProfile",
          params: {
            retailerId: item.id
          }
        })
      }
    },
    logoImage(retailer) {
      return retailer.logoAsset && retailer.logoAsset.url
    },
    goToWebsite(address) {
      if (this.brandType === "basic") {
        this.showUpdatePlanModal()
      } else {
        window.open("http://www." + address)
      }
    },
    getRetailerLocations(retailer) {
      let countries = []
      if (retailer.addresses) {
        let addresses = retailer.addresses

        for (let address in addresses) {
          countries.push(
            this.$store.getters["reference/countryName"](addresses[address].country.id)
          )
        }
        const countryStrs = countries.join(",")
        if (countryStrs.length > 14) {
          return countries.join(",").slice(0, 14) + "..."
        } else {
          return countryStrs
        }
      } else {
        return ""
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.retailer_card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  // height: 350px;
  border: 1px solid #e8e8e8;
  border-radius: 25px;
  box-shadow: 1px 1px 4px 1px #c9c9c9;
  margin-right: 27px;
  margin-bottom: 32px;
  // box-sizing: border-box;
  padding: 17px 0 24px 0;
  &:hover &_c {
    color: #166ed9;
  }
  &_a {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    .card-tag {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: #4a8a5d;
      border: 1px solid #4a8a5d;
      padding: 0 8px;
    }
  }
  &_b {
    margin: 17px 0 8px;
  }
  &_c {
    font-family: SF Pro Display;
    font-weight: 600;
    font-size: 16px;
    color: #262626;
  }
  &_d {
    height: 66px;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    white-space: pre-wrap;
    padding: 0 17px;
    text-align: center;
    font-family: SF Pro Display;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
  }
  &_e {
    display: flex;
    width: 80%;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 4px;
  }
  &_f {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #262626;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &_g {
    margin-top: 16px;
    width: 114px;
    height: 40px;
    //padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fafafa;
    border-radius: 2px;
    font-size: 16px;
    background-color: #4a8a5d;
    .svg-icon-style {
      margin-right: 5px;
    }
  }
}
.retailer_card:nth-of-type(2n) {
  margin-right: 0;
}
</style>
