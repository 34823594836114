<template lang="pug">
.orders-filter
  .orders-filter__header-body
    .orders-filter__header-name(@click="openList()") {{ title }}
      span.orders-filter__header--icon(v-show="!isOpen")
        img(src="@/assets/img/inputs/filters-arrow-icon.svg")
      span.orders-filter__header--icon(v-show="isOpen && !isAnyItemsChecked")
        img(src="@/assets/img/inputs/filters-arrow-icon-up.svg")
      span.orders-filter__header--reset(v-show="isOpen && isAnyItemsChecked" @click="resetAllCheckedItems()") {{$t('Components.Orders.OrdersFilter.resetAll')}}
    ul.orders-filter__items.orders-filter__items--scrollable(:class="{ 'orders-filter__items--visible': isOpen, 'orders-filter__items--nonscrollable_large': statusItems }")
      li.orders-filter__item(
        v-for="item in items"
        :key="item.id"
        @click="switchItem(item)"
      )
        span.orders-filter__icon
          img(src="@/assets/img/checkbox.png")
          img.orders-filter__item--checked(v-show="item.checked" src="@/assets/img/inputs/checked.png")
        span.orders-filter__item-name {{ item.name }}
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      isOpen: true
    }
  },
  computed: {
    isAnyItemsChecked() {
      return this.items.filter(item => item.checked).length
    },
    statusItems() {
      return this.title === "Status"
    }
  },
  methods: {
    switchItem(item) {
      item.checked = !item.checked
      this.$emit("filterSwitch")
    },
    openList() {
      this.isOpen = !this.isOpen
    },
    resetAllCheckedItems() {
      this.items.forEach(item => (item.checked = false))
      this.$emit("filterSwitch")
    }
  }
}
</script>

<style lang="sass">
.orders-filter__item--checked
  position: absolute
  right: 3px
  top: 3px
  width: 18px
  height: 18px

.orders-filter
  &__item
    height: 27px
    font-size: 12px
    font-weight: 300
    position: relative

  &__items
    display: none
    overflow: hidden
    margin-top: 10px

    &--scrollable
      overflow-y: auto
      height: 100%

  &__items--nonscrollable_large
    overflow-y: hidden

  &__see-all
    color: #B3B3B3
    font-size: 12px
    font-weight: 300

  &__items--visible
    display: block

  &__icon
    position: absolute
    left: 0
    margin-right: 20px

  &__header-body
    position: relative
    display: block
    cursor: pointer
    padding: 16px 0px 16px 0px
    border-top: 1px solid #EBEFF2
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

  &__header--icon
    float: right
    padding-right: 10px

  &__header--reset
    float: right
    color: #B3B3B3

  &__item-name
    margin-left: 24px
    font-size: 14px
</style>
