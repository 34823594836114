import axios from "@/api/axios"

export default {
  getReference() {
    return axios.get("/refdata")
  },
  updateLocationType(param) {
    return axios.put("/refdata/location-type", JSON.stringify(param))
  },
  updateCurationType(param) {
    return axios.put("/refdata/curation", JSON.stringify(param))
  },
  updateOtherType(param) {
    return axios.put("/refdata/other-type", JSON.stringify(param))
  },
  updateChannelType(param) {
    console.log(param)
    return axios.put("/refdata/channel-type", JSON.stringify(param))
  },
  updateProductCategory(param) {
    return axios.put("/refdata/product-category", JSON.stringify(param))
  },
  updateProductSubCategory(param) {
    return axios.put("/refdata/product-subcategory", JSON.stringify(param))
  },
  updateProductMicroCategory(param) {
    return axios.put("/refdata/product-microCategory", JSON.stringify(param))
  },
  updateProductType(param) {
    return axios.put("/refdata/product-type", JSON.stringify(param))
  },
  updateProductValue(param) {
    return axios.put("/refdata/product-value", JSON.stringify(param))
  },
  updateMessageCategory(param) {
    return axios.put("/refdata/message-category", JSON.stringify(param))
  },
  getRetailerConsole(param) {
    return axios.get("/refdata/retailer-console", param)
  },
  getBrandConsole(param) {
    return axios.get("/refdata/brand-console", param)
  },
  getProductConsole(param) {
    return axios.get("/refdata/product-console", param)
  },
  getSubcategory(categoryId) {
    return axios.get(`/refdata/product-subCategory?categoryPrivateId=${categoryId}`)
  },
  getMicroCategory(subCategoryId) {
    return axios.get(`/refdata/product-microCategory?subcategoryPrivateId=${subCategoryId}`)
  }
}
