<template>
  <div class="admin-messaging-page">
    <div class="admin-messaging-page__outer">
      <div class="admin-messaging-page__main">
        <div class="brand-message-header-box">
          <div class="message-title">Messaging</div>
          <div class="message-title-button">
            <in-box-operation
              @compose="composeMessage"
              @delete="deleteMessage"
              @archive="archiveMessage"
              @search="searchPannel"
            />
          </div>
        </div>
        <div class="brand-message-table-box">
          <div class="message-table-title">
            <p
              v-for="item in adminMessageTableTitle"
              :key="item.title"
              :class="[item.isActive ? 'active-table-title' : '']"
              @click="changeTableTitleItem(item)"
            >
              {{ item.name }}
            </p>
          </div>
          <div class="message-table-content">
            <template v-for="index in 7">
              <message-table
                v-if="index - 1 === currentTab"
                :key="index"
                :list="tableData[index - 1]"
                :is-loading="isLoadding[index - 1]"
                :is-show-footer="isShowSearchResult[index - 1]"
                :can-archive="index !== 4"
                :can-reply="index !== 7 && index !== 6"
                @onChecked="getSelectedRecord"
                @clearSerach="clearSearchAction"
                @pageChange="getData"
                @sortData="sortData"
                @getMessageData="refreshData"
              />
            </template>
          </div>
        </div>
      </div>
      <search-panel
        v-if="isShow"
        class="admin-messaging-page__search-panel"
        @applySearch="applySearchAction"
      />
    </div>
    <confirm-dialog ref="confirmDelete" @doConfirm="doDeleteMessage" />
  </div>
</template>

<script>
import MessageTable from "@/components/message/components/messageTable"
import InBoxOperation from "@/components/message/InBoxOperation.vue"
import SearchPanel from "@/components/message/SearchPanel.vue"
import ConfirmDialog from "@/components/admin/ConfirmDialog.vue"
import { messageItemizeType } from "@/utils/const"
import { getAdminMessageParam, getAdminMessageBatchOperaiontParam } from "@/utils/validate"
export default {
  components: { MessageTable, InBoxOperation, SearchPanel, ConfirmDialog },
  data() {
    return {
      adminMessageTableTitle: [
        { name: "Inbox", isActive: true, type: messageItemizeType.INBOX_TYPE },
        { name: "Brands", isActive: false, type: messageItemizeType.INBOX_TYPE },
        { name: "Retailers", isActive: false, type: messageItemizeType.INBOX_TYPE },
        { name: "Inter-Messaging", isActive: false, type: messageItemizeType.INTER_MESSAGE_TYPE },
        { name: "Sent", isActive: false, type: messageItemizeType.SENT_TYPE },
        { name: "Drafts", isActive: false, type: messageItemizeType.DRAFTS_TYPE },
        { name: "Archive", isActive: false, type: messageItemizeType.ARCHIVE_TYPE }
      ],
      isShow: false,
      tableData: [{}, {}, {}, {}, {}, {}, {}],
      isLoadding: [false, false, false, false, false, false, false],
      selectedRecords: [[], [], [], [], [], [], []],
      currentPage: [1, 1, 1, 1, 1, 1, 1],
      list: {},
      currentTab: 0,
      isShowSearchResult: [false, false, false, false, false, false, false],
      searchParams: [undefined, undefined, undefined, undefined, undefined, undefined, undefined],
      type: messageItemizeType.INBOX_TYPE,
      sortParam: [undefined, undefined, undefined, undefined, undefined, undefined, undefined]
    }
  },
  created() {
    console.log("...created....")
    this.switchTabToGetData()
    this.$store.dispatch("reference/setReference")
    this.$store.dispatch("retailer/fetchAllRetailers", {
      params: { status: "approved", fields: "name,id,follows,logoAsset" }
    })
    this.$store.dispatch("brand/fetchAllBrands", {
      params: { fields: "id, name, shortName,follows,logoAsset" }
    })
    this.$store.dispatch("product/getProducts", {
      params: { fields: "name,id,msrpUsd,images,sku,brand", _limit: 9000 }
    })
  },
  methods: {
    changeTableTitleItem(item) {
      for (let index = 0; index < this.adminMessageTableTitle.length; index++) {
        if (this.adminMessageTableTitle[index].name === item.name) {
          this.adminMessageTableTitle[index].isActive = true
          this.currentTab = index
          this.type = this.adminMessageTableTitle[index].type
          // change the table data.....
        } else {
          this.adminMessageTableTitle[index].isActive = false
        }
      }
      console.log(this.currentTab)
      console.log(this.type)
      this.switchTabToGetData()
    },
    sortData(param) {
      this.sortParam[this.currentTab] = Object.assign({}, param)
      this.getData(this.currentPage[this.currentTab])
    },
    getData(page) {
      this.currentPage[this.currentTab] = page
      this.getAdminMessage(page, this.currentTab)
    },
    refreshData() {
      this.getAdminMessage(this.currentPage[this.currentTab], this.currentTab)
    },
    switchTabToGetData() {
      console.log(this.currentTab)
      console.log(!this.tableData[this.currentTab] || !this.tableData[this.currentTab].messages)
      if (
        this.currentTab === 6 ||
        !this.tableData[this.currentTab] ||
        !this.tableData[this.currentTab].messages ||
        this.tableData[this.currentTab].messages.length === 0
      ) {
        this.getAdminMessage(this.currentPage[this.currentTab], this.currentTab)
      }
    },
    composeMessage() {
      console.log("to Compose")
      this.$router.push({
        name: "adminComposeMessage"
      })
    },
    deleteMessage() {
      if (this.canDoOperation(true)) {
        this.showDeleteConfirm()
      }
    },
    canDoOperation(isDelete) {
      console.log(this.currentTab)
      // if (isDelete && this.currentTab === 3) {
      //   this.$message.info("Can't delete inter messages.")
      //   return false
      // }
      if (!isDelete) {
        if (this.currentTab === 5 || this.currentTab === 6) {
          this.$message.info(
            `Can't archive ${this.currentTab === 5 ? "drafts" : "archive"} messages.`
          )
          return false
        }
      }
      let data = undefined
      data = this.selectedRecords[this.currentTab]
      if (!data || data.length === 0) {
        this.$message.info("Please choose record first, then can do operaion")
        return false
      }
      return true
    },
    getOperateParam(isDelete) {
      let data = undefined
      data = this.selectedRecords[this.currentTab]
      return getAdminMessageBatchOperaiontParam(this.currentTab, isDelete, data, true)
    },
    doUpdateBatchMessage(params, successMsg, failMessage) {
      this.$store
        .dispatch("adminMessage/updateBatchMessages", params)
        .then(res => {
          console.log(res)
          this.$message.success(successMsg)
          this.refreshData()
        })
        .catch(err => {
          this.$message.error(`${failMessage}, the err:${err.toString()}`)
        })
    },
    doDeleteMessage() {
      console.log("delete selected message....")
      let param = this.getOperateParam(true)
      if (this.currentTab === 3) {
        param.active = true
        param.seenByLanding = false
        param.itemize = "interMessaging"
      }
      this.doUpdateBatchMessage(param, "Delete messages success.", "Delete messages fail")
    },
    archiveMessage() {
      console.log("....archive Message.....")
      if (this.canDoOperation(false)) {
        this.doUpdateBatchMessage(
          this.getOperateParam(false),
          "Archive messages success.",
          "Archive messages fail"
        )
      }
    },
    searchPannel() {
      console.log("....search....")
      this.isShow = !this.isShow
    },
    getSelectedRecord(data) {
      this.selectedRecords[this.currentTab] = data
    },
    showDeleteConfirm() {
      this.$refs["confirmDelete"].openDialogConfirm(
        "Delete Message",
        "Are you sure you wish to delete? This action cannot be undone. ",
        "No",
        "Yes"
      )
    },
    applySearchAction(param) {
      console.log(".... try to do search.....")
      console.log(param)
      this.isShow = false
      this.setSearchParam(param)

      // invoke api to do the searhc
      // if get the result, isShowSearchResult = true,
      // if not , isShowSearchResult = false
      this.$set(this.isShowSearchResult, this.currentTab, true)
    },
    clearSearchAction() {
      this.$set(this.isShowSearchResult, this.currentTab, false)
      // invoke api to get message list again, no search param
      console.log("...get message list...")
      this.setSearchParam()
      // this.searchParams[this.currentTab] = undefined
      // this.getData(1)
    },

    getAdminMessage(page, tab) {
      console.log(page)
      console.log(this.isLoadding[tab])
      this.isLoadding[tab] = true
      let params = getAdminMessageParam(this.type, page, tab)
      console.log(params)
      if (this.searchParams[tab]) {
        params = Object.assign({}, params, this.searchParams[tab])
      }
      if (this.sortParam[tab]) {
        params = Object.assign({}, params, this.sortParam[tab])
      }
      this.$store
        .dispatch("adminMessage/fetchSearchMessage", { params })
        .then(res => {
          this.$set(this.tableData, tab, res)
          this.$set(this.isLoadding, tab, false)
        })
        .catch(() => {
          this.$set(this.isLoadding, tab, false)
        })
    },
    setSearchParam(param) {
      this.searchParams[this.currentTab] = Object.assign({}, param)
      this.getData(1)
    }
  }
}
</script>

<style lang="scss">
.admin-messaging-page {
  width: 100%;
  min-height: 750px !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &__outer {
    margin: 0 auto;
  }
  &__search-panel {
    float: left;
    margin-top: 26px;
  }
}
.admin-messaging-page::after {
  clear: both;
  content: " ";
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}
.admin-messaging-page__main {
  width: 952px;
  margin: 0 auto;
  float: left;
  padding-top: 10px;
  .brand-message-header-box {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .message-title {
      font-family: "Playfair Display Bold", "Playfair Display";
      font-size: 48px;
      color: #000000;
    }
    .message-title-button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        width: 87px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #e3e3e3;
        background-color: #f5f5f5;
        margin-right: 11px;
        text-align: center;
        font-size: 12px;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
  .brand-message-table-box {
    margin-top: 11px;
    .message-table-title {
      display: flex;
      p {
        width: 136px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #f5f5f5;
        cursor: pointer;
        font-size: 14px;
        color: #000000;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 0;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
      }
      p:not(:last-child) {
        margin-right: 2px;
      }
      .active-table-title {
        background-color: #e2eeec;
      }
    }
  }
}
.message-table__main .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0px;
}
.message-table__main .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0px;
}
</style>
